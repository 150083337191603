<template>
  <div :id="tree.element_id"
       class="tree no-default-focus"
       tabindex="-1"
       @keydown="(e) => tree.on_keydown(e)"
       @mousedown="(e) => tree.on_mousedown(e)"
  >
    <template v-for="node in tree.computed.children"
              :key="node.key">
      <Node
          :node="node"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import Node from "./Node.vue";
import { TestaTree } from "./tree";

export default defineComponent({
    components: { Node },
    // <editor-fold desc="PROPS">
    props: {
        tree: {
            type: Object as PropType<TestaTree.Tree>,
            required: true,
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMounted() {
    },
    mounted() {
        window.addEventListener("blur", this.on_page_blur);
    },
    unmounted() {
        window.removeEventListener("blur", this.on_page_blur);
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_node_visit(node: TestaTree.Node, event: MouseEvent) {
            this.tree.visit_node(event, node)
        },
        on_page_blur() {
            if (this.tree.state.is_editing) this.tree.state.editing_node.edit_cancel();
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.tree {
  font-size: 12px;
  width: max-content;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
