import { EnumPlayType } from "../../auto_generated/enums";
import { Scenario } from "../../vue_record/models/scenario";
import { Group } from "../../vue_record/models/group";
import { LoadingData } from "select2";
import { GroupedDataFormat } from "select2";
import { DataFormat } from "select2";
import { Snippet } from "../../vue_record/models/snippet";
import { select2_option_with_icon } from "../dom/select2_option_with_icon";

const sandbox_icon_class = "fa-solid fa-umbrella-beach"

export type PlayTypeGroupId = "scenario" | "group" | "sandbox"

export type PlayTypeGroup = {
    id: PlayTypeGroupId,
    label: string,
    play_types: EnumPlayType[],
    icon_class: string
}


export class PlayType {
    static readonly filter_groups: PlayTypeGroup[] = [
        {
            id: "scenario",
            label: "Scenario",
            play_types: [
                Enum.Play.Type.SCENARIO
            ],
            icon_class: "fa-solid fa-ellipsis-vertical"
        },
        {
            id: "group",
            label: "Group",
            play_types: [
                Enum.Play.Type.GROUP,
                Enum.Play.Type.TMP_GROUP,
                Enum.Play.Type.SCENARIO_FOLDER,
            ],
            icon_class: "fa-solid fa-grip-vertical"
        },
        {
            id: "sandbox",
            label: "Sandbox",
            play_types: [
                Enum.Play.Type.SANDBOX
            ],
            icon_class: sandbox_icon_class
        }
    ]

    static get_filter_groups(play_type: EnumPlayType) {
        return this.filter_groups.filter(group => {
            return group.play_types.some(s => s == play_type)
        })
    }

    static get_types_for_filter_group(play_type_group_id: PlayTypeGroupId) {
        return this.filter_groups.find(group => group.id == play_type_group_id)?.play_types
    }

    static filter_group_option_template(data: LoadingData | GroupedDataFormat | DataFormat) {
        if (data.id == null) return data.text

        const play_type_group = this.filter_groups.find(fg => fg.id == data.id)
        return select2_option_with_icon(
            data.text,
            play_type_group.icon_class
        )
    }

    static label(play_type: EnumPlayType | "Snippet") {
        switch (play_type) {
            case Enum.Play.Type.SCENARIO:
                return "Scenario"
            case Enum.Play.Type.GROUP:
                return "Group"
            case Enum.Play.Type.TMP_GROUP:
                return "Selected Scenarios"
            case Enum.Play.Type.SCENARIO_FOLDER:
                return "Scenario Folder"
            case Enum.Play.Type.SANDBOX:
                return "Sandbox";
            case "Snippet":
                return "Snippet"
            default:
                throw new Error(`No label for ${play_type}`)
        }
    }

    static icon_class(play_type: EnumPlayType | "Snippet") {
        switch (play_type) {
            case Enum.Play.Type.SCENARIO:
                return Scenario.icon_class
            case Enum.Play.Type.GROUP:
            case Enum.Play.Type.TMP_GROUP:
            case Enum.Play.Type.SCENARIO_FOLDER:
                return Group.icon_class
            case Enum.Play.Type.SANDBOX:
                return sandbox_icon_class
            case "Snippet":
                return Snippet.icon_class
            default:
                throw new Error(`No icon for ${play_type}`)
        }
    }
}
