import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Overflowable = _resolveComponent("Overflowable")

  return (_openBlock(), _createBlock(_component_Overflowable, null, {
    default: _withCtx(() => [
      _createElementVNode("ul", {
        ref: "error_info",
        class: _normalizeClass(["error-info", {center: _ctx.center, top: _ctx.side == 'top', right: _ctx.side == 'right', bottom: _ctx.side == 'bottom', left: _ctx.side == 'left'}])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
          return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1 /* TEXT */))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}