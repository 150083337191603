import { VueRecord } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { QuerifyProps } from "../base/vue_record_scope";
import { ScenarioFolderClient } from "../clients/scenario_folder_client";
import { ScenarioFolderScope } from "../scopes/scenario_folder_scope";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { Play } from "../models/play/play";
import { Editor } from "../../components/testa/editor/editor";
import { Schedule } from "../models/schedule";
import { nextTick } from "vue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import UiShowInSidebarData = UiSync.UiShowInSidebarData;
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { Scenario } from "./scenario";
import { ScenarioScope } from "../scopes/scenario_scope";
import { computed } from "../../helpers/vue/computed";

// <editor-fold desc="TYPES">
export interface ScenarioFolderProps extends Props {
    id: number
    project_version_id: number
    name: string
    scenario_folder_id: number
    level: number
    created_at: Date
    updated_at: Date
    archived: boolean
    user_id: number
    color: string
    git_id: string
}

export type QuerifiedScenarioFolderProps = QuerifyProps<ScenarioFolderProps>
export type ScenarioFolderCreateProps = Omit<ScenarioFolderProps, 'id'>
export type ScenarioFolderUpdateProps = Partial<ScenarioFolderProps>

export interface ScenarioFolderState extends State {
}

export interface ScenarioFolderComputed extends Computed {
}

export interface ScenarioFolderStaticState extends StaticState {
    children_promises: Record<string, Promise<ScenarioFolderChildren>>
    load_promises: Record<number | string, Promise<ScenarioFolder>>
}

export type ScenarioFolderChildren = {
    scenarios: Scenario[],
    scenario_folders: ScenarioFolder[]
}

// </editor-fold>

export class ScenarioFolder extends VueRecord {
    ['constructor']: typeof ScenarioFolder

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScenarioFolderClient
    static ScopeClass = ScenarioFolderScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScenarioFolderStaticState = reactive<ScenarioFolderStaticState>({
        children_promises: {},
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "scenario_folder_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ScenarioFolder> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ScenarioFolder>> = {}

    static field_validators: ModelValidatorOpts<ScenarioFolderProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_FOLDER
    static resource_id = Enum.Resource.Id.SCENARIO_FOLDER
    static icon_class = "fa-solid fa-folder"
    static color = () => get_css_var("--scenario-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScenarioFolderClient
    declare props: ScenarioFolderProps;
    declare state: ScenarioFolderState;
    declare computed: ScenarioFolderComputed;

    // </editor-fold>

    // <editor-fold desc="HOOKS">
    after_sync_add() {
        super.after_sync_add();

        if (this.props.user_id == current.user.key()) {
            nextTick(() => {
                const tree = TestaTree.Tree.get_project_tree()
                if (tree?.is_visible()) {
                    const node = tree.get_node_by_key(this.tree_key())
                    if (node?.is_visible()) {
                        node?.edit_start()
                    }
                }
            })
        }
    }

    // </editor-fold>

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return ScenarioFolder.show_in_sidebar(this.props.id, this.props.project_version_id, tree);
    }

    static async show_in_sidebar(scenario_folder_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: number[];
        if (what_is_it(scenario_folder_ids) == "Array") {
            ids = scenario_folder_ids as number[]
        } else {
            ids = [scenario_folder_ids as number]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(scenario_folder_ids).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).each(scenario_folder_id_string => {
                    const scenario_folder_id = parseInt(scenario_folder_id_string)
                    const scenario_folders = promise_response[scenario_folder_id]
                    const keys = [
                        ScenarioFolder.tree_key(project_version_id),
                        ...scenario_folders.map(sf => sf.tree_key()),
                        ScenarioFolder.find(scenario_folder_id as number).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: ScenarioFolder.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="TREE">
    // <editor-fold desc="HOVER_ACTION">
    static _hover_action_data(project_version_id: number): TestaTree.HoverAction<ScenarioScope, ScenarioFolderScope, ScenarioFolder> {
        return {
            icon: {
                class: "fa-solid fa-plus"
            },
            callback: (event, node) => {
                node.set_expanded(true);
                node.show_context_menu(event, ScenarioFolder._tree_new_scenario_contextmenu_items(project_version_id).build);
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<ScenarioScope, ScenarioFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Scenario.ClientClass.create({
                        scenario_folder_id: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    ScenarioFolder.ClientClass.create({
                        scenario_folder_id: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
                keys[`ctrl+return`] = (e: KeyboardEvent, node: TestaTree.Node) => {
                    const records = node.tree
                                        .get_selected()
                                        .map(n => n.record)
                    const scenarios = records.filter(r => r instanceof Scenario)
                    const folders = records.filter(r => r instanceof ScenarioFolder)
                    Scenario.open(this.props.project_version_id, scenarios as Scenario[], folders as ScenarioFolder[], { replace_existing: true })
                }
            }
            return keys;
        })
    }

    static _testa_tree_hotkeys(project_version_id: number) {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<ScenarioScope, ScenarioFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Scenario.ClientClass.create({
                        scenario_folder_id: null,
                        project_version_id
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    ScenarioFolder.ClientClass.create({
                        scenario_folder_id: null,
                        project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXT MENU">
    static _tree_new_scenario_contextmenu_items(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<ScenarioScope, ScenarioFolderScope, ScenarioFolder | Scenario>) => {
                const items: ContextMenu.Items = {}

                items.new_scenario = {
                    name: "Scenario",
                    icon: "fas fa-pencil-alt",
                    color: get_css_var("--button-white"),
                    key: "+",
                    callback: () => {
                        node.set_expanded(true);
                        Scenario.ClientClass.create({
                            scenario_folder_id: node.record?.props?.id,
                            project_version_id
                        });
                    },
                }
                items.new_scenario_folder = {
                    name: "Folder",
                    icon: "fa fa-folder folder",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-+`,
                    callback: () => {
                        node.set_expanded(true);
                        ScenarioFolder.ClientClass.create({
                            scenario_folder_id: node.record?.props?.id,
                            project_version_id
                        });
                    },
                }
                return items
            },
        }
    }

    _testa_tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<ScenarioScope, ScenarioFolderScope, ScenarioFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}
                const get_selected_records = () => node.tree.get_selected().map(n => n.record)

                items.play = Play.contextmenu_item_play(() => Play.show_play_modal(get_selected_records()))

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: ScenarioFolder._tree_new_scenario_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                const scenario_builder_tab = Editor.get_scenario_builder_tab()
                items.add_to_builder = {
                    name: `${scenario_builder_tab == null ? 'Open in' : 'Add to'} builder`,
                    icon: Scenario.icon_class,
                    color: get_css_var("--scenario-color"),
                    key: `${ctrl_or_meta}-enter`,
                    callback: () => {
                        const records = node.tree
                                            .get_selected()
                                            .map(n => n.record)
                        const scenarios = records.filter(r => r instanceof Scenario)
                        const folders = records.filter(r => r instanceof ScenarioFolder)
                        Scenario.open(this.props.project_version_id, scenarios as Scenario[], folders as ScenarioFolder[], { replace_existing: true })
                    }
                }

                items.filter_reports = {
                    name: "Filter Reports",
                    icon: "fa-solid fa-filter",
                    color: Play.color(),
                    callback: () => {
                        if (Play.state.filter.schedule_id != null) {
                            const schedule = Schedule.find(Play.state.filter.schedule_id)
                            if (schedule?.props?.scenario_folder_id != this.key()) {
                                Play.state.filter.schedule_id = null
                            }
                        }
                        Play.state.filter.scenario_id = null
                        Play.state.filter.group_id = null
                        Play.state.filter.scenario_folder_id = this.key();
                        Section.get_reports_section().enable();
                    }
                }

                const more_options: ContextMenu.Items = {}
                more_options.export = TestaTree.Tree.contextmenu_export_item(node)
                more_options.import = TestaTree.Tree.contextmenu_import_item(node)

                return {
                    ...items,
                    ...default_items,
                    more_options: {
                        name: "More",
                        icon: "fa-solid fa-ellipsis",
                        items: more_options
                    }
                }
            }
        }
    }

    static _testa_tree_contextmenu(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<ScenarioScope, ScenarioFolderScope, ScenarioFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: ScenarioFolder._tree_new_scenario_contextmenu_items(project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                const scenario_builder_tab = Editor.get_scenario_builder_tab()
                if (scenario_builder_tab != null) {
                    if (scenario_builder_tab.state.scenario_builder.state.scenarios.length > 0) {
                        items.add_to_builder = {
                            name: "Add to builder",
                            icon: Scenario.icon_class,
                            color: get_css_var("--scenario-color"),
                            key: `${ctrl_or_meta}-enter`,
                            callback: () => {
                                const records = node.tree
                                                    .get_selected()
                                                    .map(n => n.record)
                                const scenarios = records.filter(r => r instanceof Scenario)
                                const folders = records.filter(r => r instanceof ScenarioFolder)
                                Scenario.open(project_version_id, scenarios as Scenario[], folders as ScenarioFolder[], { replace_existing: true })
                            }
                        }
                    }
                }

                const more_options: ContextMenu.Items = {}
                more_options.export = TestaTree.Tree.contextmenu_export_item(node)
                more_options.import = TestaTree.Tree.contextmenu_import_item(node)

                return {
                    ...items,
                    ...default_items,
                    more_options: {
                        name: "More",
                        icon: "fa-solid fa-ellipsis",
                        items: more_options
                    }
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="DND">
    _testa_tree_node_dnd(): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => true),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Scenario || r instanceof ScenarioFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const scenario_folders = dnd.state.records.filter(r => r instanceof ScenarioFolder)
                const scenarios = dnd.state.records.filter(r => r instanceof Scenario)
                ScenarioFolder.ClientClass.move(
                    this.props.project_version_id,
                    this.props.id,
                    scenario_folders.map(s => s.key()),
                    scenarios.map(s => s.key())
                )
            },
            on_over: (e: DragEvent) => {
                e.dataTransfer.dropEffect = "move"
            }
        }
    }

    static _testa_tree_node_dnd(project_version_id: number): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => false),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Scenario || r instanceof ScenarioFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const scenario_folders = dnd.state.records.filter(r => r instanceof ScenarioFolder)
                const scenarios = dnd.state.records.filter(r => r instanceof Scenario)
                ScenarioFolder.ClientClass.move(
                    project_version_id,
                    null,
                    scenario_folders.map(s => s.key()),
                    scenarios.map(s => s.key())
                )
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_node_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const scenario_folder_ids = nodes.map(n => n.record)
                                                 .filter(r => r instanceof ScenarioFolder)
                                                 .map(r => r.key())
                const scenario_ids = nodes.map(n => n.record)
                                          .filter(r => r instanceof Scenario)
                                          .map(r => r.key())

                if (type == "copy") {
                    ScenarioFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.props.id,
                        scenario_folder_ids,
                        scenario_ids
                    )
                } else if (type == "cut") {
                    ScenarioFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.props.id,
                        scenario_folder_ids,
                        scenario_ids
                    )
                }
            }
        }
    }

    static _testa_tree_node_clipboard(project_version_id: number): TestaTree.Clipboard {
        return {
            on_paste: (type, nodes) => {
                const scenario_folder_ids = nodes.map(n => n.record)
                                                 .filter(r => r instanceof ScenarioFolder)
                                                 .map(r => r.key())
                const scenario_ids = nodes.map(n => n.record)
                                          .filter(r => r instanceof Scenario)
                                          .map(r => r.key())

                if (type == "copy") {
                    ScenarioFolder.ClientClass.copy(
                        project_version_id,
                        null,
                        scenario_folder_ids,
                        scenario_ids
                    )
                } else if (type == "cut") {
                    ScenarioFolder.ClientClass.move(
                        project_version_id,
                        null,
                        scenario_folder_ids,
                        scenario_ids
                    )
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<ScenarioScope, ScenarioFolderScope, ScenarioFolder> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.SCENARIO_FOLDER,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            icon: computed(() => {
                return {
                    color: get_css_var("--scenario-color"),
                }
            }),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            folder: {
                open_fn: () => {
                    return this.client.children()
                },
                folder_scope: computed(() => this.scenario_folders.order("name")),
                file_scope: computed(() => this.scenarios.order("name"))
            },
            hover_action: computed(() => ScenarioFolder._hover_action_data(this.props.project_version_id)),
            dnd: this._testa_tree_node_dnd(),
            contextmenu: this._testa_tree_contextmenu(),
            clipboard: this._testa_tree_node_clipboard(),
            hotkeys: this._testa_tree_hotkeys(),
        }
    }

    usages_testa_tree_node_data(promise: Promise<any>): TestaTree.NodeInput<ScenarioScope, ScenarioFolderScope, ScenarioFolder> {
        const node_data = this.testa_tree_node_data()
        promise.then((usages: { scenarios: Scenario[], scenario_folders: ScenarioFolder[]}) => {
            node_data.children = [
                ...usages.scenario_folders.filter(s => s.props.scenario_folder_id == this.key()).map(s => s.usages_testa_tree_node_data(promise)),
                ...usages.scenarios.filter(s => s.props.scenario_folder_id == this.key()).map(s => s.testa_tree_node_data())
            ]
        })
        node_data.key = `usages_${this.tree_key()}`
        node_data.is_expanded = true
        node_data.folder = {
            open_fn: () => promise,
        }
        return node_data
    }

    static testa_tree_node_data(project_version_id: number): TestaTree.NodeInput<ScenarioScope, ScenarioFolderScope, ScenarioFolder> {
        return {
            resource_id: Enum.Resource.Id.SCENARIO_FOLDER,
            folder: {
                open_fn: () => {
                    return ScenarioFolder.ClientClass.children(project_version_id, null)
                },
                file_scope: computed(() => Scenario.where({ archived: false })
                                                   .where({ scenario_folder_id: null })
                                                   .where({ project_version_id })
                                                   .order("name")),
                folder_scope: computed(() => ScenarioFolder.where({ archived: false })
                                                           .where({ scenario_folder_id: null })
                                                           .where({ project_version_id })
                                                           .order("name"))
            },
            icon: computed(() => {
                return {
                    class: Scenario.icon_class,
                    color: get_css_var("--scenario-color"),
                    scale: 0.9,
                }
            }),
            key: this.tree_key(project_version_id),
            title: computed(() => {
                return { template: "Scenarios" }
            }),
            hover_action: computed(() => ScenarioFolder._hover_action_data(project_version_id)),
            dnd: ScenarioFolder._testa_tree_node_dnd(project_version_id),
            clipboard: ScenarioFolder._testa_tree_node_clipboard(project_version_id),
            hotkeys: ScenarioFolder._testa_tree_hotkeys(project_version_id),
            contextmenu: ScenarioFolder._testa_tree_contextmenu(project_version_id)
        }
    }

    static usages_testa_tree_node_data(project_version_id: number, promise: Promise<any>): TestaTree.NodeInput<ScenarioScope, ScenarioFolderScope, ScenarioFolder> {
        const node_data = ScenarioFolder.testa_tree_node_data(project_version_id)

        promise.then((usages: { scenarios: Scenario[], scenario_folders: ScenarioFolder[]}) => {
            node_data.children = [
                ...usages.scenario_folders.filter(s => s.props.scenario_folder_id == null).map(s => s.usages_testa_tree_node_data(promise)),
                ...usages.scenarios.filter(s => s.props.scenario_folder_id == null).map(s => s.testa_tree_node_data())
            ]
        })


        node_data.folder = {
            open_fn: () => promise,
        }
        node_data.key = `usages_${this.tree_key(project_version_id)}`
        node_data.is_expanded = true
        return node_data
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        const names: string[] = []
        let current_folder = this.parent_folder
        while (current_folder != null) {
            names.push(current_folder.name())
            current_folder = current_folder.parent_folder
        }
        if (include_name) names.push(this.name())
        return `/${names.join("/")}`
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
ScenarioFolder.register_resource(ScenarioFolder)
ScenarioFolderClient.ModelClass = ScenarioFolder
ScenarioFolderScope.ModelClass = ScenarioFolder

global_event_bus.$on("after_project_version_unload", () => {
    ScenarioFolder.state.children_promises = {}
    ScenarioFolder.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            ScenarioFolder.unsync()
            if (project_version_id != null) ScenarioFolder.sync(`/sync/project_version/${project_version_id}/scenario_folders`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

if (web.is_main) {
    ui_sync.register_ui_task("show_in_sidebar", ScenarioFolder.resource_id, (sender: string, data: UiShowInSidebarData) => {
        ScenarioFolder.show_in_sidebar(data.id as number, current.project_version.key())
    })
}

declare global {
    interface Window {
        ScenarioFolder: typeof ScenarioFolder
    }
}
window.ScenarioFolder = ScenarioFolder
// </editor-fold>

