const special_keys = {
    8: "backspace",
    9: "tab",
    10: "return",
    13: "return",
    16: "shift",
    17: "ctrl",
    18: "alt",
    19: "pause",
    20: "capslock",
    27: "esc",
    32: "space",
    33: "pageup",
    34: "pagedown",
    35: "end",
    36: "home",
    37: "left",
    38: "up",
    39: "right",
    40: "down",
    45: "insert",
    46: "del",
    59: ";",
    61: "=",
    96: "0",
    97: "1",
    98: "2",
    99: "3",
    100: "4",
    101: "5",
    102: "6",
    103: "7",
    104: "8",
    105: "9",
    106: "*",
    107: "+",
    109: "-",
    110: ".",
    111: "/",
    112: "f1",
    113: "f2",
    114: "f3",
    115: "f4",
    116: "f5",
    117: "f6",
    118: "f7",
    119: "f8",
    120: "f9",
    121: "f10",
    122: "f11",
    123: "f12",
    144: "numlock",
    145: "scroll",
    173: "-",
    186: ";",
    187: "=",
    188: ",",
    189: "-",
    190: ".",
    191: "/",
    192: "`",
    219: "[",
    220: "\\",
    221: "]",
    222: "'",
}


const shift_nums = {
    "`": "~",
    "1": "!",
    "2": "@",
    "3": "#",
    "4": "$",
    "5": "%",
    "6": "^",
    "7": "&",
    "8": "*",
    "9": "(",
    "0": ")",
    "-": "_",
    "=": "+",
    ";": ": ",
    "'": "\"",
    ",": "<",
    ".": ">",
    "/": "?",
    "\\": "|",
}

type KeyActivations = { [key: string]: boolean}

export function key_to_strings(event: KeyboardEvent) {
    // @ts-ignore
    const special = event.type !== "keypress" && special_keys[event.which]
    const character = String.fromCharCode(event.which).toLowerCase()
    let modif = ""
    const possible: KeyActivations = {};

    ["alt", "ctrl", "shift"].forEach((special_key) => {
        // @ts-ignore
        if (event[special_key + 'Key'] && special !== special_key) {
            modif += special_key + '+';
        }
    })

    // metaKey is triggered off ctrlKey erronously
    if (event.metaKey && !event.ctrlKey && special !== "meta") {
        modif += "meta+";
    }

    if (event.metaKey && special !== "meta" && modif.indexOf("alt+ctrl+shift+") > -1) {
        modif = modif.replace("alt+ctrl+shift+", "hyper+");
    }

    if (special) {
        // @ts-ignore
        possible[modif + special] = true;
    } else {
        // @ts-ignore
        possible[modif + character] = true;

        // @ts-ignore
        const nums = shift_nums[character]
        // @ts-ignore
        if (nums != null) possible[modif + nums] = true;

        // "$" can be triggered as "Shift+4" or "Shift+$" or just "$"
        if (modif === "shift+") {
            // @ts-ignore
            possible[shift_nums[character]] = true;
        }
    }

    return possible
}

export function triggered(key: string, e: KeyboardEvent) {
    return key_to_strings(e)[key] == true
}
