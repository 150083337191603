import { Coords } from "../../types/globals";

export const position_relative_to_element = function(elem: HTMLElement, page_x: number, page_y: number): Coords {
    const $elem = $(elem);
    const ePos = $elem.offset()
    const mousePos = {
        x: page_x,
        y: page_y
    };

    mousePos.x -= ePos.left + parseInt($elem.css('paddingLeft')) + parseInt($elem.css('borderLeftWidth'));
    mousePos.y -= ePos.top + parseInt($elem.css('paddingTop')) + parseInt($elem.css('borderTopWidth'));

    return mousePos;
}
