import { User } from "../models/user";
import { UserProject } from "../models/user_project";
import { UserProjectScope } from "../scopes/user_project_scope";
import { SnippetSavepoint } from "../models/snippet_savepoint";
import { SnippetSavepointScope } from "../scopes/snippet_savepoint_scope";
import { UserSetting } from "../models/user_setting";
import { UserSettingScope } from "../scopes/user_setting_scope";
import { DelayedJobScope } from "../scopes/delayed_job_scope";
import { DelayedJob } from "../models/delayed_job";

declare module "../models/user" {
    interface User {
        user_settings: UserSettingScope
        user_projects: UserProjectScope
        snippet_savepoints: SnippetSavepointScope
        delayed_jobs: DelayedJobScope

        // projects: ProjectScope
    }
}

User.has_many("user_settings", UserSetting, "user_id")
User.has_many("user_projects", UserProject, "user_id")
User.has_many("snippet_savepoints", SnippetSavepoint, "user_id")
User.has_many("delayed_jobs", DelayedJob, "user_id")

// dont use this, not applicable for superadmins
// User.has_many_through("projects", "user_projects", "project", Project)
