import MessagePayload = UiSync.MessagePayload;
import { EnumResourceId } from "../../auto_generated/enums";
import UiOpenData = UiSync.UiOpenData;
import WebAddData = UiSync.WebAddData;
import UiMessagePayload = UiSync.UiMessagePayload;
import WebMessagePayload = UiSync.WebMessagePayload;
import { on_dom_visibility_change } from "../events/on_dom_visibility_change";
import { is_dom_visible } from "../events/on_dom_visibility_change";
import WebReplaceData = UiSync.WebReplaceData;
import WebRemoveData = UiSync.WebRemoveData;
import { on_dom_unload } from "../events/on_dom_unload";
import { computed, reactive } from "vue";
import Category = UiSync.Category;
import Task = UiSync.Task;
import TaskHandler = UiSync.TaskHandler;
import WebTask = UiSync.WebTask;
import UiTask = UiSync.UiTask;
import SyncTask = UiSync.SyncTask;
import SyncMessagePayload = UiSync.SyncMessagePayload;
import SyncReceiveData = UiSync.SyncReceiveData;
import { what_is_it } from "../generic/what_is_it";
import { WebType } from "../../types/globals";
import UiShowInSidebarData = UiSync.UiShowInSidebarData;
import UiCreateTabData = UiSync.UiCreateTabData;
import { Consoler } from "../api_wrappers/consoler";
import { generate_uuid } from "../generate/generate_uuid";
import GetMessagePayload = UiSync.GetMessagePayload;
import GetTask = UiSync.GetTask;
import GetData = UiSync.GetData;
import GetResponseData = UiSync.GetResponseData;
import { PlayProps } from "../../vue_record/models/play/play";
import { PlayScenarioProps } from "../../vue_record/models/play/play_scenario";

// <editor-fold desc="TYPES">
export namespace UiSync {
    /**
     * sync - application records synchronization
     * ui - ui related task such as opening / closing snippets
     * web - synchronization of browser tabs / web workers
     * */
    export type Category = "sync" | "ui" | "web" | "get"

    export type SyncTask = "receive" | "subscribe" | "unsubscribe" | "user_signed_in" | "user_signed_out"
    export type UiTask = "open" | "show_in_sidebar" | "create_tab" | "add_log_play"
    export type WebTask = "add" | "replace" | "remove"
    export type GetTask = "get_response" | "get_active_play_scenario_debuggers"

    export type Task = SyncTask | UiTask | WebTask | GetTask

    export type TaskHandler = (sender: string, data: any) => void

    export type UiOpenData = {
        resource_id: EnumResourceId,
        id: string | number
        open_opts: any
    }

    export type UiAddLogPlay = {
        play_props: PlayProps
        play_scenario_props?: PlayScenarioProps
    }

    export type UiShowInSidebarData = {
        resource_id: EnumResourceId,
        id: string | number
    }

    export type UiCreateTabData = {
        type: string,
        input: any
    }

    export type GetTaskData = { get_id: string }
    export type GetResponseData = {
        get_id: string,
        response: any[]
    }

    export type SyncSubscribeData = {
        channel: string,
        resource_id: EnumResourceId
    }

    export type SyncUnsubscribeData = SyncSubscribeData

    export type SyncSignInData = {
        authenticity_token: string
    }

    export type SyncReceiveData = {
        resource_id: EnumResourceId,
        channel: string
        message: any
    }

    export interface WebAddData extends Web {
        is_visible: boolean
        /** weather this is a response from the receiver of this web add task
         * consider the following scenario: TAB A exists, then enters TAB B.
         * TAB B will send Web Add Task to TAB A. Then TAB A knows of TAB B, but not vice versa.
         * Therefore TAB A should respond to TAB B with a WebAddTask that has is_response: true */
        is_response?: boolean
    }

    export type WebReplaceData = WebAddData
    export type WebRemoveData = {
        id: string
    }

    export type SyncData = SyncReceiveData | SyncSubscribeData | SyncUnsubscribeData | SyncSignInData
    export type UiData = UiOpenData | UiShowInSidebarData | UiCreateTabData
    export type WebData = WebAddData | WebReplaceData | WebRemoveData
    export type GetData = GetTaskData | GetResponseData
    export type Data = UiData | SyncData | WebData | GetData

    export type MessagePayload = {
        sender: string
        category: Category
        task: Task
        data: Data | Data[]
    }

    export interface SyncMessagePayload extends MessagePayload {
        category: "sync",
        task: SyncTask
        data: SyncData | SyncData[]
    }

    export interface UiMessagePayload extends MessagePayload {
        category: "ui",
        task: UiTask,
        data: UiData | UiData[]
    }

    export interface GetMessagePayload extends MessagePayload {
        category: "get",
        task: GetTask,
        data: GetData
    }

    export interface WebMessagePayload extends MessagePayload {
        category: "web",
        task: WebTask,
        data: WebData
    }
}

export type Web = {
    id: string,
    label: string,
    project_version_id: number
    is_web_worker: boolean
    is_reports: boolean
    is_main: boolean
    is_single_report: boolean
    is_logs: boolean
    type: WebType
}

export interface WebState extends Web {
    broadcast_channel: BroadcastChannel
    is_visible: boolean
}

export interface WebQuery extends Partial<Web> {
    is_visible?: boolean
}

type State = {
    other_webs: WebState[]
}


type TaskRegistry = {
    [key in Category]?: {
        [key in Task]?: {
            [key in EnumResourceId]?: Array<TaskHandler>
        }
    }
}

// </editor-fold>

const console = new Consoler("warn")
export class UiSync {
    private web: Web;
    private this_broadcast_channel: BroadcastChannel
    private all_broadcast_channel: BroadcastChannel
    private is_visible: boolean = is_dom_visible()
    private task_registry: TaskRegistry = {}

    static state = reactive<State>({
        other_webs: []
    })

    static computed = reactive({
        shared_worker_loaded: computed(() => {
            return this.state.other_webs.some(w => w.is_web_worker)
        }),
        main_web_available: computed(() => {
            return (web.type == "Main" || this.state.other_webs.some(w => w.type == "Main" && w.project_version_id == current_project_version_props.id))
        })
    })

    static getters: {
        [key: string]: {
            data: any[],
            response_countdown: number,
            promise_resolve: (result: any) => void
        }
    } = {}

    constructor(web: Web) {
        this.web = web
        this._init_broadcast_channels()
        this.broadcast_web_add_task();
    }

    register_web_task(task: WebTask, task_handler: TaskHandler) {
        this._register_task("web", task, null, task_handler as any)
    }

    register_ui_task(task: UiTask, resource_id: EnumResourceId, task_handler: TaskHandler) {
        this._register_task("ui", task, resource_id, task_handler as any)
    }

    register_get_task(task: GetTask, task_handler: TaskHandler) {
        this._register_task("get", task, null, task_handler as any)
    }

    register_sync_task(task: SyncTask, resource_id: EnumResourceId, task_handler: TaskHandler) {
        this._register_task("sync", task, resource_id, task_handler)
    }

    register_unsync_task(task: SyncTask, resource_id: EnumResourceId, task_handler: TaskHandler) {
        this._register_task("sync", task, resource_id, task_handler)
    }

    private on_message(event: MessageEvent<MessagePayload>) {
        this.on_message_payload(event.data)
    }

    private on_message_payload(payload: MessagePayload) {
        const category = payload.category
        const task = payload.task

        console.debug("received message: ", payload)
        const handle_message = (message: any): any[] => {
            let resource_id = message?.resource_id
            if (resource_id === undefined) resource_id = null
            const handlers = this.get_task_handlers(category, task, resource_id)
            if (handlers.length == 0 && WEB_TYPE != "test_dom") {
                console.warn(`No task handlers for category:task [resource_id] (${category}:${task} [${resource_id}])`)
                return;
            }

            const result: any[] = []
            handlers.forEach(handler => {
                try {
                    result.push(handler(payload.sender, message))
                } catch (e) {
                    console.error(e)
                }
            })
            return result
        }

        let result: any[] = []
        if (what_is_it(payload.data) == "Array") {
            (payload.data as any[]).forEach(d => result.push(handle_message(d)))
            result = result.flat().compact()
            if (category == "get" && task != "get_response") {
                this.send_get_response(payload.sender, (payload.data as GetData).get_id, result[0])
            }
        } else {
            let result = handle_message(payload.data)
            if (result != null) result = result[0]
            if (category == "get" && task != "get_response") {
                this.send_get_response(payload.sender, (payload.data as GetData).get_id, result)
            }
        }
    }

    private on_message_error(event: MessageEvent<MessagePayload>) {
        console.warn("On Message error", event)
    }

    // <editor-fold desc="SENDER">
    /** Sends message to all other webs */
    private broadcast(payload: MessagePayload) {
        console.debug("Broadcasting: ", payload);
        this.all_broadcast_channel.postMessage(payload)
    }

    /** Sends message to all webs matching the given to object. Including the this web! */
    private send(to: WebQuery, payload: MessagePayload) {
        let webs: WebState[] = [...this.other_webs()];
        webs.push({ ...this.web, broadcast_channel: this.this_broadcast_channel, is_visible: this.is_visible })
        for (const key in to) {
            webs = webs.filter(web => web[key as keyof Web] == to[key as keyof Web])
        }

        if (payload.category != "sync") console.debug("Sending to ", webs, "Payload:", payload);
        webs.forEach(w => {
            if (w.id == web.id) {
                // for current web, invoke the handler directly
                this.on_message_payload(payload)
            } else {
                w.broadcast_channel.postMessage(payload)
            }
        })
    }

    // </editor-fold>

    // <editor-fold desc="WEB">
    // <editor-fold desc="ADD">
    broadcast_web_add_task() {
        const payload: WebMessagePayload = {
            task: "add",
            category: "web",
            sender: this.web.id,
            data: { ...this.web, is_visible: this.is_visible }
        }
        this.broadcast(payload)
    }

    send_web_add_task(to_id: string, data: WebAddData) {
        const payload: WebMessagePayload = {
            task: "add",
            category: "web",
            sender: this.web.id,
            data
        }
        this.send({ id: to_id }, payload)
    }

    receive_web_add_task(data: WebAddData) {
        this.receive_web_replace_task(data)

        if (!data.is_response) {
            this.send_web_add_task(data.id, {
                ...this.web,
                is_visible: this.is_visible,
                is_response: true
            })
        }
    }

    // </editor-fold>

    // <editor-fold desc="REPLACE">
    broadcast_web_replace_task(data: WebReplaceData) {
        const payload: WebMessagePayload = {
            task: "replace",
            category: "web",
            sender: this.web.id,
            data
        }
        this.broadcast(payload)
    }

    receive_web_replace_task(data: WebReplaceData) {
        if (!this.other_webs().some(web => web.id == data.id)) {
            this.other_webs().push({
                id: data.id,
                project_version_id: data.project_version_id,
                label: data.label,
                type: data.type,
                is_visible: data.is_visible,
                is_web_worker: data.is_web_worker,
                is_main: data.type == "Main",
                is_reports: data.type == "Reports",
                is_single_report: data.type == "Single Report",
                is_logs: data.type == "Logs",
                broadcast_channel: new BroadcastChannel(`/ui_sync/${data.id}`)
            })
        } else {
            const other_web = this.other_webs().find(web => web.id == data.id)
            other_web.is_visible = data.is_visible
            other_web.label = data.label
            other_web.project_version_id = data.project_version_id
        }
    }

    // </editor-fold>

    // <editor-fold desc="REMOVE">
    broadcast_web_remove_task() {
        const data: WebRemoveData = { id: this.web.id }
        const payload: WebMessagePayload = {
            task: "remove",
            category: "web",
            sender: this.web.id,
            data
        }
        this.broadcast(payload)
    }

    receive_web_remove_task(data: WebRemoveData) {
        UiSync.state.other_webs = this.other_webs().filter(web => web.id != data.id)
    }

    // </editor-fold>
    // </editor-fold>

    // <editor-fold desc="GET">
    send_get_response(sender_id: string, get_id: string, response: any) {
        const payload: GetMessagePayload = {
            category: "get",
            task: "get_response",
            sender: this.web.id,
            data: {
                get_id,
                response
            }
        }
        this.send({ id: sender_id }, payload)
    }

    send_get_active_play_scenario_debuggers<T>() {
        return new Promise<T[]>((resolve, _reject) => {
            const get_id = generate_uuid();
            const visible_webs_count = 1 + this.other_webs().filter(w => w.is_visible).length

            UiSync.getters[get_id] = {
                data: [],
                response_countdown: visible_webs_count,
                promise_resolve: resolve
            }

            const payload: GetMessagePayload = {
                category: "get",
                task: "get_active_play_scenario_debuggers",
                sender: this.web.id,
                data: { get_id }
            }
            this.send({ is_visible: true }, payload)
        })
    }
    // </editor-fold>

    // <editor-fold desc="UI">
    // <editor-fold desc="OPEN">
    send_ui_open_task(to: Partial<Web>, data: UiOpenData) {
        const payload: UiMessagePayload = {
            category: "ui",
            task: "open",
            sender: this.web.id,
            data
        }
        this.send(to, payload)
    }

    // </editor-fold>

    // <editor-fold desc="SHOW IN SIDEBAR">
    send_ui_show_in_sidebar_task(to: Partial<Web>, data: UiShowInSidebarData | UiShowInSidebarData[]) {
        const payload: UiMessagePayload = {
            category: "ui",
            task: "show_in_sidebar",
            sender: this.web.id,
            data
        }
        this.send(to, payload)
    }

    // </editor-fold>

    send_ui_create_tab_task(to: Partial<Web>, data: UiCreateTabData | UiCreateTabData[]) {
        const payload: UiMessagePayload = {
            category: "ui",
            task: "create_tab",
            sender: this.web.id,
            data
        }
        this.send(to, payload)
    }
    // </editor-fold>

    // <editor-fold desc="SYNC">
    send_sync_user_signed_in_task(authenticity_token: string) {
        const payload: SyncMessagePayload = {
            task: "user_signed_in",
            category: "sync",
            sender: this.web.id,
            data: {
                authenticity_token
            }
        }
        this.send({ is_web_worker: false }, payload)
    }

    broadcast_sync_user_signed_in_task(authenticity_token: string) {
        const payload: SyncMessagePayload = {
            task: "user_signed_in",
            category: "sync",
            sender: this.web.id,
            data: {
                authenticity_token
            }
        }
        this.broadcast(payload)
    }

    send_sync_user_signed_out_task() {
        const payload: SyncMessagePayload = {
            task: "user_signed_out",
            category: "sync",
            sender: this.web.id,
            data: null
        }
        this.send({ is_web_worker: false, is_logs: false }, payload)
    }


    send_sync_subscribe_task(channel: string, resource_id: EnumResourceId) {
        const payload: SyncMessagePayload = {
            task: "subscribe",
            category: "sync",
            sender: this.web.id,
            data: { channel, resource_id }
        }
        this.send({ is_web_worker: true }, payload)
    }

    send_unsync_subscribe_task(channel: string, resource_id: EnumResourceId) {
        const payload: SyncMessagePayload = {
            task: "unsubscribe",
            category: "sync",
            sender: this.web.id,
            data: { channel, resource_id }
        }
        this.send({ is_web_worker: true }, payload)
    }


    send_sync_receive_task(to: string[], data: SyncReceiveData | SyncReceiveData[]) {
        const payload: SyncMessagePayload = {
            task: "receive",
            category: "sync",
            sender: this.web.id,
            data
        }
        to.forEach(id => this.send({ id }, payload))
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    set_visible(state: boolean) {
        this.is_visible = state
        this.broadcast_web_replace_task({
            is_visible: this.is_visible,
            ...this.web
        })
    }

    web_for_reports() {
        if (web.is_reports) return web
        const reports_web = this.other_webs().find(w => w.is_reports && w.is_visible)
        if (reports_web != null) return reports_web;

        if (web.is_main) return web;
        return this.other_webs().find(w => w.is_main);
    }

    web_for_main(project_version_id: number) {
        if (web.is_main && web.project_version_id == project_version_id) return web
        const webs = this.other_webs().filter(w => w.is_main && w.project_version_id == project_version_id);
        return webs[0]
    }

    is_main_web_available(project_version_id: number) {
        return this.web_for_main(project_version_id) != null
    }

    /** if there is a dedicated /reports web */
    is_reports_available() {
        if (web.is_reports) return true
        return this.other_webs().some(w => w.is_reports && w.is_visible)
    }

    is_logs_available() {
        if (web.is_logs) return true
        return this.other_webs().some(w => w.is_logs)
    }

    other_webs() {
        return UiSync.state.other_webs
    }

    static other_webs() {
        return UiSync.state.other_webs
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    private _init_broadcast_channels() {
        this.all_broadcast_channel = new BroadcastChannel("/ui_sync/all")
        this.this_broadcast_channel = new BroadcastChannel(`/ui_sync/${TAB_ID}`)

        this.all_broadcast_channel.onmessage = (e) => this.on_message(e)
        this.this_broadcast_channel.onmessage = (e) => this.on_message(e)

        this.all_broadcast_channel.onmessageerror = (e) => this.on_message_error(e)
        this.this_broadcast_channel.onmessageerror = (e) => this.on_message_error(e)
    }

    private _register_task(category: Category, task: Task, resource_id: EnumResourceId, handler: TaskHandler) {
        if (this.task_registry[category] == null) this.task_registry[category] = {}
        if (this.task_registry[category][task] == null) this.task_registry[category][task] = {}
        if (this.task_registry[category][task][resource_id] == null) this.task_registry[category][task][resource_id] = []

        if (category == "get") {
            // allow only 1 get category task handler per task, per resource
            this.task_registry[category][task][resource_id] = [handler]
        } else {
            this.task_registry[category][task][resource_id].push(handler)
        }
    }

    private get_task_handlers(category: Category, task: Task, resource_id: EnumResourceId) {
        if (this.task_registry[category] == null) return []
        if (this.task_registry[category][task] == null) return []
        if (this.task_registry[category][task][resource_id] == null) return []
        return this.task_registry[category][task][resource_id]
    }

    // </editor-fold>
}

window.web = reactive({
    id: TAB_ID,
    label: WEB_TYPE,
    type: WEB_TYPE,
    project_version_id: globalThis.current_project_version_props?.id,
    is_web_worker: IS_WEB_WORKER,
    is_reports: WEB_TYPE == "Reports",
    is_main: WEB_TYPE == "Main",
    is_single_report: WEB_TYPE == "Single Report",
    is_logs: WEB_TYPE == "Logs"
})

declare global {
    var ui_sync: UiSync
    var web: Web

    interface Window {
        UiSync: typeof UiSync
        web: Web
    }

    interface Current {
        main_web_available: boolean
    }
}

if (web.type != "Docs") {
    window.UiSync = UiSync

    globalThis.ui_sync = new UiSync(web)

    on_dom_visibility_change((visible) => {
        ui_sync.set_visible(visible)
    })

    on_dom_unload(() => {
        ui_sync.broadcast_web_remove_task()
    })

    ui_sync.register_web_task("add", (sender: string, data: WebAddData) => {
        ui_sync.receive_web_add_task(data)
    })

    ui_sync.register_web_task("replace", (sender: string, data: WebReplaceData) => {
        ui_sync.receive_web_replace_task(data)
    })

    ui_sync.register_web_task("remove", (sender: string, data: WebRemoveData) => {
        ui_sync.receive_web_remove_task(data)
    })

    ui_sync.register_get_task("get_response", (_sender: string, data: GetResponseData) => {
        const getter_data = UiSync.getters[data.get_id]
        --getter_data.response_countdown;
        getter_data.data.push(data.response)

        if (getter_data.response_countdown == 0) {
            const result = getter_data.data
            const promise_resolve = getter_data.promise_resolve
            delete UiSync.getters[data.get_id]
            promise_resolve(result)
        }
    })
}
