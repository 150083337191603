import CodeMirror from "codemirror";
import { ch_of_code_start } from "../helpers/ch_of_code_start";

const whitespace_regex = /^[ \t]*$/
export function home(cm: CodeMirror.Editor) {
    const cursor = cm.getCursor();
    const line = cm.getLine(cursor.line)
    if (whitespace_regex.test(line)) {
        // when there is only white space, home goes to ch 0
        cm.setCursor({ line: cursor.line, ch: 0 })
    } else {
        const start_of_code_ch = ch_of_code_start(cm.getLine(cursor.line))
        if (cursor.ch <= start_of_code_ch) {
            // if cursor is before start of code, use built in home
            cm.execCommand("goLineStartSmart")
        } else {
            // any other case, home should position cursor at the start of code
            cm.setCursor({ line: cursor.line, ch: start_of_code_ch })
        }
    }
}
