(function(mod) {
    if (typeof exports == "object" && typeof module == "object") // CommonJS
        mod(require("codemirror"));
    else if (typeof define == "function" && define.amd) // AMD
        define(["codemirror"], mod);
    else // Plain browser env
        mod(CodeMirror);
})(function(CodeMirror) {
    "use strict";

    //regex to match words you typed in code and words in hint list
    //let WORD = /:[\w$]+ {0,1}=> {0,1}|[\w]+:*|@b\.|@d\.|@[\w$]+|[\w$]+[?|!]*|(@)/;
    let WORD = /[\w]+[?!]*|@b\.|@i\.|@d\.|@{1,2}[\w$]+|@/;
    let NUMB = /^[0-9]+/   //regex used to remove numbers from hint list

    // <editor-fold desc="HELPER FUNCTIONS">
    function in_quotations(cm, position){
        return cm.getTokenTypeAt(position) == "string";
    }

    function in_parenthesis(cm, cursor, type = "()"){
        let parenthesisCount = 0
        for (let j = cursor.line; j >= 0; --j) {
            let line =  cm.getLine(j);
            let line_length = line.length;
            if (cursor.line == j) line_length = cursor.ch;
            switch (type) {
                case "()":
                    for (let i = 0; i  < line_length; ++i) {
                        if (line[i] == "(" && !in_quotations(cm, CodeMirror.Pos(j, i))) ++parenthesisCount;
                        if (line[i] == ")" && !in_quotations(cm, CodeMirror.Pos(j, i))) --parenthesisCount;
                    }
                    break;
                case "[]": {
                    for (let i = 0; i  < line_length; ++i) {
                        if (line[i] == "[" && !in_quotations(cm, CodeMirror.Pos(j, i))) ++parenthesisCount;
                        if (line[i] == "]" && !in_quotations(cm, CodeMirror.Pos(j, i))) --parenthesisCount;
                    }
                    break;
                }
                case "{}": {
                    for (let i = 0; i  < line_length; ++i) {
                        if (line[i] == "{" && !in_quotations(cm, CodeMirror.Pos(j, i))) ++parenthesisCount;
                        if (line[i] == "}" && !in_quotations(cm, CodeMirror.Pos(j, i))) --parenthesisCount;
                    }
                    break;
                }
            }

        }
        return parenthesisCount > 0;
    }


    function insert_text(text, cm) {
        let doc = cm.getDoc();
        let cursor = doc.getCursor();
        doc.replaceRange(text, cursor);
    }

    function moveCursorBack(back, cm) {
        let doc = cm.getDoc();
        let cursor = doc.getCursor();
        cursor.ch = cursor.ch - back;
        doc.setCursor(cursor);
    }

    function jumpToLine(cm, i) {
        let t = cm.charCoords({line: i, ch: 0}, "local").top;
        let middleHeight = cm.getScrollerElement().offsetHeight / 2;
        cm.scrollTo(null, t - middleHeight - 5);
    }
    // </editor-fold>

    CodeMirror.registerHelper("hint", "custom", function (editor, options) {
        let list = [], seen = {};
        let word = options && options.word || WORD;
        let language = options && options.language || Enum.Language.RUBY
        let context = options && options.context || "";
        let extra_text = options && options.extraText || "";
        let support;


        let cur = editor.getCursor(), curLine = editor.getLine(cur.line);
        let start = cur.ch, end = start;

        //find end of the typed word
        while (end < curLine.length && word.test(curLine.charAt(end))) ++end;

        //find begging of the typed word
        while (start && word.test(curLine.charAt(start - 1))) --start;

        let curWord = curLine.slice(start, end);
        let in_quotes = in_quotations(editor, cur);
        let in_parenthes = in_parenthesis(editor, cur);




        function get_ruby_context_validity(curLine, start) {
            let valid_context = true;
            if (curWord == "") {
                if (start == 0) {
                    valid_context = false;
                } else {
                    if (curLine.charAt(start - 1) != "." &&
                        (curLine.charAt(start - 1) != " " && curLine.charAt(start - 2) != ",") &&
                        (curLine.charAt(start - 1) != ",")) {
                        valid_context = false;
                    }
                }
            }
            if (in_quotes &&
                curLine.indexOf("find_element_by_image") == -1 &&
                curLine.indexOf("find_elements_by_image") == -1) return false;
            return valid_context;
        }

        function get_jruby_context_validity(curLine, start) {
            let valid_context = true;
            if (curWord == "" && !in_quotes) {
                if (start == 0) {
                    valid_context = false;
                } else {
                    if (curLine.charAt(start - 1) != "." &&
                        (curLine.charAt(start - 1) != " " && curLine.charAt(start - 2) != ",") &&
                        (curLine.charAt(start - 1) != ",")) {
                        valid_context = false;
                    }
                }
            }
            return valid_context;
        }

        function get_ruby_context(start, curLine) {
            support = options && options.support || ["appium", "watir"]
            if (in_quotes) return "quotations"
            if (curWord.indexOf(":") == 0) {
                context = "selector";
            }
            if (start >= 2) {
                if (curLine.charAt(start - 1) == ".") {
                    context = "method";
                    if (start >= 3) {
                        if (curLine.charAt(start - 2) == "d" && curLine.charAt(start - 3) == "@") {
                            support = "appium"
                        }
                        if (curLine.charAt(start - 2) == "b" && curLine.charAt(start - 3) == "@") {
                            support = "watir"
                        }
                    }
                }
                if (curLine.charAt(start - 1) == ":" && curLine.charAt(start - 2) == ":") {
                    context = "class";
                }
            }
            return context;
        }

        function get_jruby_context(curLine) {
            support = options && options.support || ["appium", "watir", "sikuli"]
            if (in_quotes) {

                start = cur.ch
                end = start;

                //find end of the typed word
                while (end < curLine.length && editor.getTokenTypeAt(CodeMirror.Pos(cur.line, end)) == "string") ++end;
                --end;

                //find begging of the typed word
                while (start && editor.getTokenTypeAt(CodeMirror.Pos(cur.line, start - 1)) == "string") --start;

                curWord = curLine.slice(start, end);
                let last_char = curWord.charAt(curWord.length - 1)
                if (last_char == '"' || last_char == "'" || last_char == "`") {
                    --end;
                    curWord = curLine.slice(start, end);
                }

                let i = start;

                let token;
                while (i) {
                    token = editor.getTokenAt(CodeMirror.Pos(cur.line, i - 1))
                    if (token.type == "variable" && token.string == "puts") return "puts_quotations"
                    --i;
                }
                return "quotations"
            }
            if (curWord.indexOf(":") == 0) {
                context = "selector";
            }
            if (start >= 2) {
                if (curLine.charAt(start - 1) == ".") {
                    context = "method";
                    if (start >= 3) {
                        if (curLine.charAt(start - 2) == "d" && curLine.charAt(start - 3) == "@") {
                            support = "appium"
                        }
                        if (curLine.charAt(start - 2) == "b" && curLine.charAt(start - 3) == "@") {
                            support = "watir"
                        }
                    }
                }
                if (curLine.charAt(start - 1) == ":" && curLine.charAt(start - 2) == ":") {
                    context = "ruby_class";
                }

            }

            return context;
        }

        function scan(language) {
            let text = "";
            let context = "";

            switch (language) {
                case Enum.Language.RUBY: {
                    if (get_ruby_context_validity(curLine, start) == false) return;
                    context = get_ruby_context(start, curLine)
                    switch (context) {
                        // just after . is typed
                        case "method": {
                            text += get_ruby_methods();
                            if (support.includes("appium")) text += get_ruby_appium_methods();
                            if (support.includes("watir")) text += get_ruby_watir_methods();
                            break;
                        }
                        // when : is typed
                        case "selector": {
                            if (support.includes("appium")) text += get_ruby_appium_selectors();
                            if (support.includes("watir")) text += get_ruby_watir_selectors();
                            break;
                        }
                        // after :: is typed
                        case "class": {
                            text += get_ruby_classes();
                            if (support.includes("appium")) text += get_ruby_appium_classes();
                            if (support.includes("watir")) text += get_ruby_watir_classes();
                            break;
                        }
                        case "quotations": {
                            for (let i = 0; i < images_hints.length; ++i) {
                                if (images_hints[i].indexOf(curWord) == 0) {
                                    if (!Object.prototype.hasOwnProperty.call(seen, images_hints[i])) {
                                        seen[images_hints[i]] = 0;
                                        list.push(images_hints[i]);
                                    }
                                    ++seen[images_hints[i]];
                                }
                            }

                            return;
                        }
                        default: {
                            text += get_ruby_keywords();
                            text += get_ruby_variables();
                            if (in_parenthes) {
                                if (support.includes("appium")) text += get_ruby_appium_selectors();
                                if (support.includes("watir")) text += get_ruby_watir_selectors();
                            }
                        }
                    }
                    break;
                }
                case Enum.Language.JRUBY: {
                    if (get_jruby_context_validity(curLine, start) == false) return;
                    context = get_jruby_context(curLine);
                    switch (context) {
                        // just after . is typed
                        case "method": {
                            text += get_ruby_methods();
                            if (support.includes("appium")) text += get_ruby_appium_methods();
                            if (support.includes("watir")) text += get_ruby_watir_methods();
                            if (support.includes("sikuli")) text += get_jruby_sikulix_methods();
                            break;
                        }
                        // when : is typed
                        case "selector": {
                            if (support.includes("appium")) text += get_ruby_appium_selectors();
                            if (support.includes("watir")) text += get_ruby_watir_selectors();
                            break;
                        }
                        case "ruby_class": {
                            text += get_ruby_classes();
                            if (support.includes("appium")) text += get_ruby_appium_classes();
                            if (support.includes("watir")) text += get_ruby_watir_classes();
                            break;
                        }
                        case "puts_quotations": {
                            return;
                            // TODO: test rail autocomplete
                            //break;
                        }
                        case "quotations": {
                            for (let i = 0; i < images_hints.length; ++i) {
                                if (images_hints[i].indexOf(curWord) == 0) {
                                    if (!Object.prototype.hasOwnProperty.call(seen, images_hints[i])) {
                                        seen[images_hints[i]] = 0;
                                        list.push(images_hints[i]);
                                    }
                                    ++seen[images_hints[i]];
                                }
                            }

                            return;
                        }
                        default: {
                            text += get_ruby_variables();
                            text += get_ruby_keywords();
                            text += get_jruby_classes();
                            text += get_jruby_sikulix_methods();
                            text += get_jruby_sikulix_classes();
                            text += get_java_classes();
                            text += get_java_keywords();

                            if (in_parenthes) {
                                if (support.includes("appium")) text += get_ruby_appium_selectors();
                                if (support.includes("watir")) text += get_ruby_watir_selectors();
                            }
                        }
                    }
                    break;
                }
            }


            text += editor.getValue();
            text += " " + extra_text;
            let m;
            let re = new RegExp(word.source, "g");

            while (m = re.exec(text)) {
                //for (let i = 0; i < m.length; ++i) {
                    if (m[0] === "@") continue; // exception, do not show @ as hint
                    if (m[0].indexOf(curWord) == 0) {
                        if (!NUMB.test(m[0])) { //this if removes hint list when you type non letters like .  ( ) [0-9]
                            if (!Object.prototype.hasOwnProperty.call(seen, m[0])) {
                                seen[m[0]] = 0;
                                list.push(m[0]);
                            }
                            ++seen[m[0]];
                        }
                    }
                //}

            }

        }


        scan(language);


        if (list.length == 1 && list[0] == curWord) {
            list.pop(); // dont show hints if we have only 1 hint and it is a complete match
        }

        // sort hits, sort order:
        // - time seen, desc
        // - length, asc
        // - alphabetically
        list.sort(function (a, b) {
            let r = seen[b] - seen[a]; // number of times seen
            if (r == 0) {
                r = seen[a].length - seen[b].length
                if (r == 0) {
                    if (seen[b] > seen[a]) r = -1;
                    else r = 1;
                }
                return r
            }
            return r;
        })
        return {list: list, from: CodeMirror.Pos(cur.line, start), to: CodeMirror.Pos(cur.line, end)};
    });
});
