import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { PlayWorkerGroupClient } from "../../clients/play/play_worker_group_client";
import { PlayWorkerGroupScope } from "../../scopes/play/play_worker_group_scope";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export interface PlayWorkerGroupProps extends Props {
    id: number
    play_id: number
    name: string
    worker_variables: string
    duration: number
    current_line_number: number
    total_lines_number: number
    created_at: Date
    updated_at: Date
}
export type QuerifiedPlayWorkerGroupProps = QuerifyProps<PlayWorkerGroupProps>
export type PlayWorkerGroupCreateProps = Omit<PlayWorkerGroupProps, 'id'>
export type PlayWorkerGroupUpdateProps = Partial<PlayWorkerGroupProps>

export interface PlayWorkerGroupState extends State {}
export interface PlayWorkerGroupComputed extends Computed {}
export interface PlayWorkerGroupStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PlayWorkerGroup extends VueRecord {
    ['constructor']: typeof PlayWorkerGroup

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlayWorkerGroupClient
    static ScopeClass = PlayWorkerGroupScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlayWorkerGroupStaticState = reactive<PlayWorkerGroupStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlayWorkerGroup> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlayWorkerGroup>> = {}

    static field_validators: ModelValidatorOpts<PlayWorkerGroupProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_WORKER_GROUP
    static resource_id = Enum.Resource.Id.PLAY_WORKER_GROUP
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlayWorkerGroupClient
    declare props: PlayWorkerGroupProps;
    declare state: PlayWorkerGroupState;
    declare computed: PlayWorkerGroupComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlayWorkerGroup.register_resource(PlayWorkerGroup)
PlayWorkerGroupClient.ModelClass = PlayWorkerGroup
PlayWorkerGroupScope.ModelClass = PlayWorkerGroup


declare global {
    interface Window {
        PlayWorkerGroup: typeof PlayWorkerGroup
    }
}
window.PlayWorkerGroup = PlayWorkerGroup
// </editor-fold>

