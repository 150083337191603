import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9389bac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "vue-settings-item-header" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "vue-settings-item-body" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    id: `${_ctx.id}_container`,
    class: "vue-settings-item-container",
    style: _normalizeStyle({padding: _ctx.padding})
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        id: `settings_title_${_ctx.id}`,
        class: "vue-settings-item-title"
      }, _toDisplayString(_ctx.title), 9 /* TEXT, PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 12 /* STYLE, PROPS */, _hoisted_1))
}