import jQuery from "jquery"
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { HoverZoom } from "../../helpers/dom/hoverzoom";


declare global {
    interface Window {
        $: JQueryStatic
        jQuery: JQueryStatic

    }
}

window.$ = jQuery
window.jQuery = jQuery

require("../../libs/jquery/jquery-contextMenu.js")
require("../../libs/jquery/jquery-ui.min.js")
require("jquery-cropper")
require("jquery-file-download")
require("../../libs/jquery/custom/animations/jquery-wiggle")
require("../../libs/jquery/custom/extensions/focusable")
require("blueimp-file-upload/js/jquery.fileupload.js")
require("../../libs/cytoscape")
require("../../libs/monkey_patches")


// @ts-ignore
window.d3 = require("d3")
// @ts-ignore
window.d3.version = "dummy" // needed for d3pie
window.d3pie = require("d3pie")

on_dom_content_loaded(() => {
    HoverZoom.run()
})
