<template>
  <Table>
    <template #thead>
      <tr>
        <th>Project</th>
        <th style="text-align: center">
          Role
        </th>
        <th v-if="!is_viewer_role && !is_user_role"
            style="text-align: center">
          Remove
        </th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="user_project in user_projects"
          :key="user_project.key()"
      >
        <td>
          <!-- user projects that are already saved, dont show project dropdown -->
          <template v-if="user_project.props.id">
            {{ user_project.project.props.full_name }}
          </template>
          <template v-else>
            <Select2
                v-model="user_project.props.project_id"
                :dropdown_parent="dropdown_parent"
                :disabled="is_viewer_role || is_user_role"
                :for_table="true">
              <template v-for="project in selectable_projects(user_project.project)"
                        :key="project.key()">
                <option :value="project.key()">
                  {{ project.props.full_name }}
                </option>
              </template>
            </Select2>
          </template>
        </td>
        <td>
          <template v-if="role_edit_allowed_project_ids.includes(user_project.props.project_id)">
            <Select2
                v-model="user_project.props.role"
                :dropdown_parent="dropdown_parent"
                :disabled="is_viewer_role || is_user_role"
                :for_table="true"
            >
              <template v-for="role in roles"
                        :key="role"
              >
                <option :value="role">{{ role }}</option>
              </template>
            </Select2>
          </template>
          <template v-else>
            {{ user_project.props.role }}
          </template>
        </td>
        <td v-if="!is_viewer_role && !is_user_role"
            class="action-td">
          <div v-if="role_edit_allowed_project_ids.includes(user_project.props.project_id)"
               class="user-project-remove"
               @click="remove_user_project(user_project)">
            <i class="fa fa-minus-circle"/>
          </div>
        </td>
      </tr>
    </template>
    <template
        v-if="role_edit_allowed_projects.length > 0 && not_on_projects.length > 0 && !is_viewer_role && !is_user_role"
        #tfoot>
      <tr>
        <td colspan="2">
          <span class="add-user-project"
                @click.stop="add_user_project">
            <span>Add project </span>
            <i class="fa fa-plus-circle"
               style=" color: var(--button-green)"/>
          </span>
        </td>
      </tr>
    </template>
  </Table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Select2 from "../../../testa/Select2.vue";
import { PropType } from "vue";
import { UserProject } from "../../../../vue_record/models/user_project";
import { Project } from "../../../../vue_record/models/project";
import { UserProjectProps } from "../../../../vue_record/models/user_project";
import { UserProps } from "../../../../vue_record/models/user";
import { UserCreateProps } from "../../../../vue_record/models/user";
import Table from "../../../testa/Table.vue";

export default defineComponent({
    components: {
        Table,
        Select2,
    },
    // <editor-fold desc="PROPS">
    props: {
        user_projects: {
            type: Array as PropType<UserProject[]>,
            required: true,
        },
        user_props: {
            type: Object as PropType<UserProps | UserCreateProps>,
            required: true,
        },
        dropdown_parent: {
            type: String,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
        stage_id: {
            type: String,
            required: false,
            default: null
        }
    },
    // </editor-fold>
    emits: ['add-user-project', 'remove-user-project'],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        role_edit_allowed_projects() {
            let project_ids: number[] = []
            if (current.user.is_superadmin()) {
                project_ids = Project.get_scope().keys()
            } else {
                project_ids = this.current.user
                                  .user_projects
                                  .toArray()
                                  .filter(up => up.props.role == Enum.User.Role.ADMIN)
                                  .map(up => up.project)
                                  .compact()
                                  .pluck("key")
            }
            return Project.where({ id: project_ids }).order("full_name")
        },
        role_edit_allowed_project_ids() {
            return this.role_edit_allowed_projects.map(p => p.key())
        },
        roles() {
            return Enum.User.Role.admin_level_roles
        },
        taken_projects() {
            return this.user_projects.map(up => up.project)
        },
        not_on_projects() {
            const taken_project_ids = this.taken_projects.map(p => p.key())
            return this.role_edit_allowed_projects.filter(project => {
                return !taken_project_ids.includes(project.key())
            })
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    updated() {
        console.log(this.user_projects.pluck("props").pluck("created_at"))
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        add_user_project() {
            const user_project_props = {
                project_id: this.not_on_projects[0].props.id,
                user_id: (this.user_props as UserProps).id,
                role: Enum.User.Role.VIEWER,
                created_at: new Date()
            } as UserProjectProps
            const record = UserProject.new(user_project_props, {
                is_stage: true,
                stage_id: this.stage_id
            })
            this.$emit("add-user-project", record)
        },
        remove_user_project(user_project: UserProject) {
            user_project.unload()
            this.$emit("remove-user-project", user_project)
        },
        selectable_projects(current_project: Project): Project[] {
            const selectable = this.not_on_projects.slice()
            selectable.push(current_project)
            return selectable.sort_by("props.full_name")
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.add-user-project {
  cursor: pointer;
}

.action-td {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 25px;
    height: 15px;
  }

  .user-project-remove {
    color: var(--button-red);
    cursor: pointer;
  }
}
</style>
