import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b75872e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col s12" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col s12" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col s12" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col s12" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col s12" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col s12" }
const _hoisted_16 = {
  key: 0,
  class: "row"
}
const _hoisted_17 = { class: "col s12" }
const _hoisted_18 = {
  key: 1,
  class: "row",
  style: {"max-height":"75%","height":"100%"}
}
const _hoisted_19 = { class: "col s12" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Input = _resolveComponent("Input")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_UserProjectsTable = _resolveComponent("UserProjectsTable")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "new_user_modal",
    confirm_text: _ctx.new_user ? 'Create' : 'Add',
    show_cancel: true,
    focus_confirm: false,
    cancel_on_escape: true,
    focus_last_active_on_unmount: true,
    form_validator: _ctx.form_validator,
    confirm_action: _ctx.create_user,
    onCancel: _cache[7] || (_cache[7] = $event => (_ctx.$emit('exit')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.new_user)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" New user ")
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(" Add existing user ")
            ], 64 /* STABLE_FRAGMENT */))
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Input, {
              id: "email_input",
              modelValue: _ctx.user_props.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.user_props.email) = $event)),
              validator: _ctx.form_validator.register('email', {..._ctx.User.field_validators.email, callback: _ctx.check_email }),
              label: "Email",
              focus: true,
              throttle_time: 500
            }, null, 8 /* PROPS */, ["modelValue", "validator"])
          ])
        ]),
        (_ctx.new_user)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Input, {
                    id: "first_name_input",
                    modelValue: _ctx.user_props.first_name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.user_props.first_name) = $event)),
                    validator: _ctx.form_validator.register('first_name', _ctx.User.field_validators.first_name),
                    label: "First Name"
                  }, null, 8 /* PROPS */, ["modelValue", "validator"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_Input, {
                    id: "last_name_input",
                    modelValue: _ctx.user_props.last_name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.user_props.last_name) = $event)),
                    validator: _ctx.form_validator.register('last_name', _ctx.User.field_validators.last_name),
                    label: "Last Name"
                  }, null, 8 /* PROPS */, ["modelValue", "validator"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_Input, {
                    id: "username_input",
                    modelValue: _ctx.user_props.username,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.user_props.username) = $event)),
                    validator: _ctx.form_validator.register('username', { ..._ctx.User.field_validators.username, callback: _ctx.check_username }),
                    debounce_time: 400,
                    label: "Username"
                  }, null, 8 /* PROPS */, ["modelValue", "validator"])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_Input, {
                    id: "password_input",
                    modelValue: _ctx.user_props.password,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.user_props.password) = $event)),
                    type: "password",
                    validator: _ctx.form_validator.register('password', {..._ctx.User.field_validators.password, callback: _ctx.trigger_confirm_password_validation } ),
                    label: "Password"
                  }, null, 8 /* PROPS */, ["modelValue", "validator"])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_Input, {
                    id: "confirm_password_input",
                    modelValue: _ctx.user_props.confirm_password,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.user_props.confirm_password) = $event)),
                    type: "password",
                    validator: _ctx.form_validator.register('confirm_password', { callback: _ctx.check_equal_confirm_password }),
                    label: "Password confirmation"
                  }, null, 8 /* PROPS */, ["modelValue", "validator"])
                ])
              ]),
              (_ctx.current.user.is_superadmin())
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_Checkbox, {
                        id: "new_user_superadmin_checkbox",
                        modelValue: _ctx.user_props.superadmin,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.user_props.superadmin) = $event)),
                        label: "Superadmin"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        (!_ctx.user_props.superadmin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_UserProjectsTable, {
                  user_props: _ctx.user_props,
                  user_projects: _ctx.user_projects,
                  is_viewer_role: _ctx.is_viewer_role,
                  is_user_role: _ctx.is_user_role,
                  stage_id: _ctx.stage_id,
                  dropdown_parent: "#new_user_modal"
                }, null, 8 /* PROPS */, ["user_props", "user_projects", "is_viewer_role", "is_user_role", "stage_id"])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["confirm_text", "form_validator", "confirm_action"]))
}