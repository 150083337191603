import { PlayGroup } from "../../vue_record/models/play/play_group";
import { PlayNode } from "../../types/play_node";

export function set_expanded_play_node_states(
    states: Record<string, boolean>,
    play_nodes: Array<PlayNode | PlayGroup>,
    auto_expand: boolean
): Record<string, boolean> {
    const id_fun = function(play_node: PlayNode | PlayGroup) {
        return `${play_node.constructor.name}_${play_node.props.id}`
    }
    const n = play_nodes.length;
    play_nodes.forEach((play_node, index) => {
        const is_expanded = states[id_fun(play_node)]
        if (is_expanded == null) {
            states[id_fun(play_node)] = (play_node.props['running?'] ||
                play_node.props.status == Enum.Play.Status.BOOTING ||
                play_node.props.status == Enum.Play.Status.PENDING ||
                n == 1) && auto_expand;
        } else {
            if ((index + 2) == n) {
                const last_play_node = play_nodes[index + 1];
                const last_is_expanded = states[id_fun(last_play_node)]
                if (last_is_expanded == null) {
                    // we got a new one after this, so close the one before
                    states[id_fun(play_node)] = false
                }
            }
        }
    })
    return states
}
