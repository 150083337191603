import { markRaw } from "vue";

type AllMightyData = {
    container?: HTMLElement,
    event?: AllMightyEventName
}

type ResizeDelay = number

export type AllMightyCallback = (data?: AllMightyData) => void

type AllMightyObserverInputWithCallback = {
    callback: AllMightyCallback
}

type AllMightyObserverInputWithElement = {
    target_element: HTMLElement
} & AllMightyObserverInputWithCallback

type AllMightyEventName =
    "before_resize" |
    "resize" |
    "after_resize" |
    "element_visible" |
    "element_hidden" |
    "element_before_resize" |
    "element_resize" |
    "element_after_resize" |
    "scrollend"

export type AllMightyObserverResizeInput = {
    /** before user starts resizing page or layout */
    before_resize?: boolean
    /** after resize adjustment of page or layout */
    resize?: boolean
    /** after resize has finished of page or layout. If there is no explicit after resize event. It uses the resize_delay */
    after_resize?: boolean
    /** if there is no explicit before / after resize events, this delay is used to trigger after_resize when
     * there is no resize event in the delay duration */
    resize_delay?: ResizeDelay
} & AllMightyObserverInputWithCallback

export type AllMightyObserverElementVisibleInput = {
    /** When element becomes visible. Uses intersection observer to determine visibility */
    element_visible: boolean
} & AllMightyObserverInputWithElement

export type AllMightyObserverElementHiddenInput = {
    /** When element becomes hidden. Uses intersection observer to determine visibility */
    element_hidden: boolean
} & AllMightyObserverInputWithElement

export type AllMightyObserverElementResizeInput = {
    element_before_resize?: boolean
    element_resize?: boolean
    element_after_resize?: boolean
    /** this delay is used to trigger element_after_resize after last element_resize_event */
    element_resize_delay?: number
} & AllMightyObserverInputWithElement

export type AllMightyObserverScrollendInput = {
    scrollend: boolean
} & AllMightyObserverInputWithCallback

export type AllMightyObserverInput = AllMightyObserverResizeInput
    | AllMightyObserverElementVisibleInput
    | AllMightyObserverElementHiddenInput
    | AllMightyObserverElementResizeInput
    | AllMightyObserverScrollendInput

type AllMightyObserverAllInput = AllMightyObserverResizeInput
    & AllMightyObserverElementVisibleInput
    & AllMightyObserverElementHiddenInput
    & AllMightyObserverElementResizeInput
    & AllMightyObserverScrollendInput


export class AllMightyObserver {
    static new(this: typeof AllMightyObserver, ...opts: AllMightyObserverInput[]) {
        return markRaw(new this(...opts))
    }

    default_resize_delay = 250

    before_resize_callbacks: Map<ResizeDelay, AllMightyCallback[]> = new Map()
    resize_callbacks: Map<ResizeDelay, AllMightyCallback[]> = new Map()
    after_resize_callbacks: Map<ResizeDelay, AllMightyCallback[]> = new Map()

    element_before_resize_callbacks: Map<ResizeDelay, Map<HTMLElement, AllMightyCallback[]>> = new Map()
    element_resize_callbacks: Map<ResizeDelay, Map<HTMLElement, AllMightyCallback[]>> = new Map()
    element_after_resize_callbacks: Map<ResizeDelay, Map<HTMLElement, AllMightyCallback[]>> = new Map()

    element_visible_callbacks: Map<HTMLElement, AllMightyCallback[]> = new Map()
    element_hidden_callbacks: Map<HTMLElement, AllMightyCallback[]> = new Map()

    scrollend_callbacks: AllMightyCallback[] = []

    stops: Array<() => void> = []

    constructor(...opts: any[]) {
        this.add_callbacks(opts as AllMightyObserverAllInput[])
        this.start_observers()
    }

    stop() {
        this.stops.forEach(s => s())
    }

    private start_observers() {
        let delays: number[] = []
        delays = delays.concat(Array.from(this.before_resize_callbacks.keys()))
        delays = delays.concat(Array.from(this.resize_callbacks.keys()))
        delays = delays.concat(Array.from(this.after_resize_callbacks.keys()))
        delays.uniq().forEach(delay => this.start_resize_observer(delay))

        delays = []
        delays = delays.concat(Array.from(this.element_before_resize_callbacks.keys()))
        delays = delays.concat(Array.from(this.element_resize_callbacks.keys()))
        delays = delays.concat(Array.from(this.element_after_resize_callbacks.keys()))
        delays.uniq().forEach(delay => this.handle_element_resize_observer(delay))

        let elements: HTMLElement[] = []
        elements = elements.concat(Array.from(this.element_visible_callbacks.keys()))
        elements = elements.concat(Array.from(this.element_hidden_callbacks.keys()))
        elements.uniq().forEach(element => this.start_intersection_observer(element))

        this.start_scrollend_listener()
    }

    private add_callbacks(opts: AllMightyObserverAllInput[]) {
        (opts as AllMightyObserverAllInput[]).forEach(opt => {
            if (opt.resize_delay == null) opt.resize_delay = this.default_resize_delay
            if (opt.element_resize_delay == null) opt.element_resize_delay = this.default_resize_delay

            if (opt.before_resize) this.add_callback(this.before_resize_callbacks, opt.resize_delay, opt.callback)
            if (opt.resize) this.add_callback(this.resize_callbacks, opt.resize_delay, opt.callback)
            if (opt.after_resize) this.add_callback(this.after_resize_callbacks, opt.resize_delay, opt.callback)

            if (opt.element_before_resize) {
                this.check_for_mandatory(opt, "element_before_resize", "target_element")
                this.add_callback2(this.element_before_resize_callbacks, opt.element_resize_delay, opt.target_element, opt.callback)
            }
            if (opt.element_resize) {
                this.check_for_mandatory(opt, "element_resize", "target_element")
                this.add_callback2(this.element_resize_callbacks, opt.element_resize_delay, opt.target_element, opt.callback)
            }
            if (opt.element_after_resize) {
                this.check_for_mandatory(opt, "element_after_resize", "target_element")
                this.add_callback2(this.element_after_resize_callbacks, opt.element_resize_delay, opt.target_element, opt.callback)
            }
            if (opt.element_visible) {
                this.check_for_mandatory(opt, "element_visible", "target_element")
                this.add_callback(this.element_visible_callbacks, opt.target_element, opt.callback)
            }
            if (opt.element_hidden) {
                this.check_for_mandatory(opt, "element_hidden", "target_element")
                this.add_callback(this.element_hidden_callbacks, opt.target_element, opt.callback)
            }

            if (opt.scrollend) {
                this.scrollend_callbacks.push(opt.callback)
            }
        })
    }

    private check_for_mandatory(opt: AllMightyObserverAllInput, event: AllMightyEventName, mandatory_key: keyof AllMightyObserverAllInput) {
        if (opt[mandatory_key] == null) throw new Error(`${mandatory_key} must be provided for ${event}`)
    }

    private add_callback(map: Map<any, AllMightyCallback[]>, key: any, callback: AllMightyCallback) {
        if (key == null) throw new Error("Key cannot be null")
        const value = map.get(key)
        if (value == null) {
            map.set(key, [callback])
        } else {
            value.push(callback)
        }
    }

    private add_callback2(map: Map<ResizeDelay, Map<HTMLElement, AllMightyCallback[]>>, key1: ResizeDelay, key2: HTMLElement, callback: AllMightyCallback) {
        if (key1 == null) throw new Error("Key cannot be null")
        let value = map.get(key1)
        if (value == null) {
            value = new Map()
            map.set(key1, value)
        }
        this.add_callback(value, key2, callback)
    }

    private start_resize_observer(delay: number) {
        let before_callbacks = this.before_resize_callbacks.get(delay)
        let callbacks = this.resize_callbacks.get(delay)
        let after_callbacks = this.after_resize_callbacks.get(delay)
        if (before_callbacks == null) before_callbacks = []
        if (callbacks == null) callbacks = []
        if (after_callbacks == null) after_callbacks = []

        // if resize event with explicit start event is started
        // this is used to prevent non-explicit resize events
        let is_resizing = false
        const resize_before_wrapper = (container: HTMLElement) => {
            is_resizing = true
            const data = this.generate_data(container, "before_resize")
            before_callbacks.forEach(c => this.invoke_callback(c, data))
        }
        const resize_wrapper = (container: HTMLElement) => {
            const data = this.generate_data(container, "resize")
            callbacks.forEach(c => this.invoke_callback(c, data))
        }
        const resize_after_wrapper = (container: HTMLElement) => {
            is_resizing = false;
            const data = this.generate_data(container, "after_resize")
            after_callbacks.forEach(c => this.invoke_callback(c, data))
        }
        global_event_bus.$on("resize-start", resize_before_wrapper)
        global_event_bus.$on("resize", resize_wrapper)
        global_event_bus.$on("resize-end", resize_after_wrapper)

        let timeout: NodeJS.Timeout = null
        const on_window_resize = () => {
            if (timeout == null) {
                if (!is_resizing) before_callbacks.forEach(c => this.invoke_callback(c, this.generate_data(document.body, "before_resize")))
            } else {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() => {
                timeout = null
                if (!is_resizing) after_callbacks.forEach(c => this.invoke_callback(c, this.generate_data(document.body, "after_resize")))
            }, delay)

            if (!is_resizing) callbacks.forEach(c => this.invoke_callback(c, this.generate_data(document.body, "resize")))
        }
        window.addEventListener("custom-resize", on_window_resize)
        window.addEventListener("resize", on_window_resize)
        this.stops.push(() => {
            global_event_bus.$off("resize-start", resize_before_wrapper)
            global_event_bus.$off("resize", resize_wrapper)
            global_event_bus.$off("resize-end", resize_after_wrapper)
            window.removeEventListener("resize", on_window_resize)
            window.removeEventListener("custom-resize", on_window_resize)
        })
    }

    private handle_element_resize_observer(delay: number) {
        let before_map = this.element_before_resize_callbacks.get(delay)
        let map = this.element_resize_callbacks.get(delay)
        let after_map = this.element_after_resize_callbacks.get(delay);
        if (before_map == null) before_map = new Map()
        if (map == null) map = new Map()
        if (after_map == null) after_map = new Map();

        let elements = Array.from(before_map.keys())
        elements = elements.concat(Array.from(map.keys()))
        elements = elements.concat(Array.from(after_map.keys()))
        elements.uniq().forEach(element => this.start_element_resize_observer(delay, element))
    }

    private start_element_resize_observer(delay: number, element: HTMLElement) {
        let before_map = this.element_before_resize_callbacks.get(delay)
        let map = this.element_resize_callbacks.get(delay)
        let after_map = this.element_after_resize_callbacks.get(delay);
        if (before_map == null) before_map = new Map()
        if (map == null) map = new Map()
        if (after_map == null) after_map = new Map();
        let before_callbacks = before_map.get(element)
        let callbacks = map.get(element)
        let after_callbacks = after_map.get(element)
        if (before_callbacks == null) before_callbacks = []
        if (callbacks == null) callbacks = []
        if (after_callbacks == null) after_callbacks = []

        let timeout: NodeJS.Timeout = null
        const resize_observer = new ResizeObserver(() => {
            if (timeout == null) {
                before_callbacks.forEach(c => this.invoke_callback(c, this.generate_data(element, "element_before_resize")))
            } else {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() => {
                timeout = null
                after_callbacks.forEach(c => this.invoke_callback(c, this.generate_data(element, "element_after_resize")))
            }, delay)
            callbacks.forEach(c => this.invoke_callback(c, this.generate_data(element, "element_resize")))
        })
        resize_observer.observe(element)
        this.stops.push(() => resize_observer.disconnect())
    }

    private start_intersection_observer(element: HTMLElement) {
        let visible_callbacks = this.element_visible_callbacks.get(element)
        let hidden_callbacks = this.element_hidden_callbacks.get(element)
        if (visible_callbacks == null) visible_callbacks = []
        if (hidden_callbacks == null) hidden_callbacks = []
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {

                if (entry.intersectionRatio > 0) {
                    const data = this.generate_data(element, "element_visible")
                    visible_callbacks.forEach(c => this.invoke_callback(c, data))
                } else {
                    const data = this.generate_data(element, "element_hidden")
                    hidden_callbacks.forEach(c => this.invoke_callback(c, data))
                }
            });
        })
        observer.observe(element);
        return observer
    }

    private start_scrollend_listener() {
        const wrapper = (e: Event) => {
            this.scrollend_callbacks.forEach(c => this.invoke_callback(c, this.generate_data(e.target as HTMLElement, "scrollend")))
        }
        document.body.addEventListener("scrollend", wrapper)
        this.stops.push(() => {
            document.body.removeEventListener("scrollend", wrapper)
        })
    }

    private generate_data(container: HTMLElement, event: AllMightyEventName): AllMightyData {
        return {
            container,
            event
        }
    }

    private invoke_callback(callback: AllMightyCallback, data: AllMightyData) {
        // for debugging
        // console.log("INVOKING", data, callback.toString());
        try {
            callback(data)
        } catch (e) {
            console.error(e)
        }
    }
}
