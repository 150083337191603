export function color_changed_lines(cm: CodeMirror.Editor, exclude_origin: string[] = []) {
    cm.on("changes", (cm, changes) => {
        const origins = changes.map(c => c.origin).filter(o => !exclude_origin.includes(o))
        if (origins.length == 0) return


        changes.forEach(change => {
            if (change.removed.length == 1) { // this prevent coloring deleted rows
                if (change.from.line == change.to.line && change.text.length > 1) {
                    for (let i = 0, j = change.from.line; i < change.text.length; ++i, ++j) {
                        cm.addLineClass(j, "gutter", "CodeMirror-linechange")
                    }
                } else {
                    for (let j = change.from.line; j <= change.to.line; ++j) {
                        cm.addLineClass(j, "gutter", "CodeMirror-linechange")
                    }
                }
            } else {
                cm.addLineClass(change.from.line, "gutter", "CodeMirror-linechange")
            }
        })
    })
}
