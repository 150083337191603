import CodeMirror from "codemirror";
import _ from "lodash";

/** Return spaces instead of ch offset
 * because, visually number of spaces matches the alignment.
 *
 * For instance, if on line we have \t\ta = 2 then the indentation is only ch: 2 offset
 * but visually that would be 2*tab_size
 */
export function search_for_alignment_spaces(cm: CodeMirror.Editor, from_line: number, state: any) {
    let timeout = 1000
    let context_line_number = from_line
    let alignment_spaces = null
    const tab_size = cm.getOption("tabSize")
    while (alignment_spaces == null && context_line_number > 0) {
        const context_line = cm.getLine(context_line_number)
        const line_length = context_line.length
        for (let i = 0; i < line_length;) {
            const token = cm.getTokenAt({ line: context_line_number, ch: i })
            if (token.type == "operator" && token.string == "." && _.isEqual(token.state.context, state.context)) {
                alignment_spaces = get_spaces_for_alignment(context_line, token.start, tab_size)
                break;
            } else {
                i = token.end + 1;
                --timeout;
                if (timeout < 0) {
                    console.warn("Failed to align, parsed too many tokens.")
                    return null
                }
            }
        }
        --context_line_number;
    }
    return alignment_spaces
}

function get_spaces_for_alignment(line: string, end_on: number, tab_size: number) {
    let spaces = 0;
    for (let i = 0; i < end_on && i < line.length; ++i) {
        if (line[i] == "\t") spaces += tab_size
        else ++spaces
    }
    return spaces
}

