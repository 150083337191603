import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e3dedf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "snapshot-container" }
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ScreenOverlay = _resolveComponent("ScreenOverlay")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "inspector_container",
      class: "snapshot-inspector-container",
      style: _normalizeStyle(_ctx.inspector_container_style)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scenario_setting_phones, (phone) => {
        return (_openBlock(), _createBlock(_component_ScreenOverlay, {
          key: phone.props.id,
          is_live: false,
          is_resizing: false,
          canvas_width: _ctx.image_width,
          canvas_height: _ctx.image_height,
          scenario_setting_phone: phone,
          tab: _ctx.tab,
          snapshot: _ctx.snapshot_for_phone(phone)
        }, null, 8 /* PROPS */, ["canvas_width", "canvas_height", "scenario_setting_phone", "tab", "snapshot"]))
      }), 128 /* KEYED_FRAGMENT */))
    ], 4 /* STYLE */),
    _createElementVNode("img", {
      ref: "image",
      src: _ctx.snapshots[0].screenshot_url,
      class: "no-hover-zoom",
      alt: "Snapshot image",
      onLoad: _cache[0] || (_cache[0] = (...args) => (_ctx.image_loaded && _ctx.image_loaded(...args)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
  ]))
}