globalThis.get_ruby_appium_selectors = () => {
    return `
     checkable:
     checked:
     class:
     clickable:
     desc:
     enabled:
     focusable:
     focused:
     id:
     index:
     long_clickable:
     name:
     package:
     scrollable:
     selected:
     text:
     value:
     uiautomator:
     xpath: 
    `;
}


globalThis.get_ruby_appium_methods = () =>  {
    return `
accessibility_container
accessible?
align
align!
align_bottom
align_bottom!
align_left
align_left!
align_right
align_right!
align_top
align_top!
as_scrollable
back
bounds
button
buttons
cell
cells
check_box
check_boxes
checkable?
checked?
child
children
class_name
clickable?
desc
displayed?
drag_by
drag_to
each
each_down
each_left
each_right
each_up
edit_text
edit_texts
element
elements
enabled?
execute_script
exists?
find_element
find_elements
find_elements_by_image
find_element_by_image
fling_down
fling_left
fling_right
fling_up
focusable?
focused?
following_sibling
following_siblings
frame
frame_layout
frame_layouts
from_parent
get
get_clipboard
hide_keyboard
horizontal_scroll_view
horizontal_scroll_views
id
index
image
images
image_button
image_buttons
image_view
image_views
linear_layout
linear_layouts
list_view
list_views
long_clickable?
long_tap
name
navigation_bar
navigation_bars
other
others
package
page_down
page_left
page_right
page_up
password?
parent
preceding_sibling
preceding_siblings
progress_bar
progress_bars
radio_button
radio_buttons
radio_group
radio_groups
recycler_view
recycler_views
relative_layout
relative_layouts
screenshot
scroll
scroll_down_to
scroll_left_to
scroll_right_to
scroll_to
scroll_to_start
scroll_to_end
scroll_up_to
scroll_view
scroll_views
search_view
search_views
secure_text_field
secure_text_fields
selected?
selection_start
selection_end
scrollable?
scrollables
shell
siblings
spinner
spinners
static_text
static_texts
table
tables
tap
text
text_field
text_fields
text_view
text_views
toast
toasts
toolbar
toolbars
type
value
view
views
view_group
view_groups
view_pager
view_pagers
visible?
wait_until
wait_until_exists
wait_while
wait_while_exists
window
windows
`;
}

globalThis.get_ruby_appium_classes = () => {
    return `
Android
AndroidElements
Bridge
Command
Espresso
Bridge
Uiautomator2
Bridge
Element
Helper
Common
CountElements
HTMLElements
Wait
Device
Driver
Http
Default
Ios
Bridge
CommandError
UITestElementsPrinter
Xcuitest
Bridge
Command
Element
Gesture
Helper
MultiAppHandler
PasteBoard
Source
MultiTouch
TouchAction
`
}
