import { VueRecordScope } from "../base/vue_record_scope";
import { App } from "../models/app";
import { computed } from "../../helpers/vue/computed";

export class AppScope extends VueRecordScope {
    ['constructor']: typeof AppScope
    static ModelClass: typeof App
    declare rthis: AppScope

    apks = computed(() => this.rthis.where({ app_type: Enum.App.ANDROID }))
    zips = computed(() => this.rthis.where({ app_type: Enum.App.SIMULATOR }))
    ipas = computed(() => this.rthis.where({ app_type: Enum.App.IPHONE }))
}
