import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { ScheduleClient } from "../clients/schedule_client";
import { ScheduleScope } from "../scopes/schedule_scope";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import ScheduleTitle from "../../components/schedule/ScheduleTitle.vue";
import { Play } from "./play/play";
import { Section } from "../../components/testa/section_toggler/section";
import { computed } from "../../helpers/vue/computed";
import { Group } from "./group";
import { ScenarioFolder } from "./scenario_folder";
import { Scenario } from "./scenario";
import { SnippetFolder } from "./snippet_folder";
import { what_is_it } from "../../helpers/generic/what_is_it";

// <editor-fold desc="TYPES">
export interface ScheduleProps extends Props {
    id: number
    name: string
    status: string
    run_type: string
    next_run_time: Date
    user_id: number
    project_version_id: number
    created_at: Date
    updated_at: Date
    scenario_id: number
    group_id: number
    scenario_folder_id: number
}
export type QuerifiedScheduleProps = QuerifyProps<ScheduleProps>
export type ScheduleCreateProps = Omit<ScheduleProps, 'id'>
export type ScheduleUpdateProps = Partial<ScheduleProps>

export interface ScheduleState extends State {}
export interface ScheduleComputed extends Computed {}
export interface ScheduleStaticState extends StaticState {}

// </editor-fold>

export class Schedule extends VueRecord {
    ['constructor']: typeof Schedule

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScheduleClient
    static ScopeClass = ScheduleScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScheduleStaticState = reactive<ScheduleStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "run_type", "status"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Schedule> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Schedule>> = {}

    static field_validators: ModelValidatorOpts<ScheduleProps> = {}

    static resource_name = Enum.Resource.Label.SCHEDULE
    static resource_id = Enum.Resource.Id.SCHEDULE
    static icon_class = "fa-solid fa-clock-rotate-left  fa-flip-horizontal"
    static color = () => get_css_var("--schedule-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScheduleClient
    declare props: ScheduleProps;
    declare state: ScheduleState;
    declare computed: ScheduleComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_schedules_tree()): Promise<void> {
        return Schedule.show_in_sidebar(this.props.id, this.props.project_version_id, tree);
    }

    static async show_in_sidebar(schedule_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_schedules_tree()) {
        let ids: number[];
        if (what_is_it(schedule_ids) == "Array") {
            ids = schedule_ids as number[]
        } else {
            ids = [schedule_ids as number]
        }

        if (web.is_main) {
            if (!current.project_version_setting.props.schedule_module_enabled) return;

            Section.get_schedules_section().enable()
            const all_keys: string[][] = []
            ids.each(schedule_id => {
                const keys = [
                    Schedule.find(schedule_id as number).tree_key()
                ]
                all_keys.push(keys)
            })
            await tree.expand_paths(all_keys)
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: Schedule.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="HELPERS">
    path() {
        return `/${this.name()}`
    }

    // </editor-fold>

    // <editor-fold desc="TREE">
    // <editor-fold desc="CONTEXT MENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<ScheduleScope, ScheduleScope, Schedule>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}
                const tree = node.tree
                const selected_records = tree.get_selected_records_array()
                const schedules = selected_records.filter(r => r instanceof Schedule)

                items.run_now = {
                    name: "Run Now",
                    icon: "fa-solid fa-play",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        Schedule.ClientClass.run_now(schedules.map(s => s.key()))
                                .then(response => {
                                    response.play_ids.forEach(play_id => {
                                        Play.ClientClass.load(play_id).then(play => play.open())
                                    })
                                })
                    }
                }

                items.filter_reports = {
                    name: "Filter Reports",
                    icon: "fa-solid fa-filter",
                    color: Play.color(),
                    callback: () => {
                        if (this.props.group_id != null) {
                            Play.state.filter.scenario_folder_id = null
                            Play.state.filter.scenario_id = null
                            if (Play.state.filter.group_id != this.props.group_id) {
                                Play.state.filter.group_id = null
                            }
                        }

                        if (this.props.scenario_folder_id != null) {
                            Play.state.filter.group_id = null
                            Play.state.filter.scenario_id = null
                            if (Play.state.filter.scenario_folder_id != this.props.scenario_folder_id) {
                                Play.state.filter.scenario_folder_id = null
                            }
                        }

                        if (this.props.scenario_id != null) {
                            Play.state.filter.scenario_folder_id = null
                            Play.state.filter.group_id = null
                            if (Play.state.filter.scenario_id != this.props.scenario_id) {
                                Play.state.filter.scenario_id = null
                            }
                        }

                        Play.state.filter.schedule_id = this.key();
                        Section.get_reports_section().enable();
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOVER_ACTION">
    _hover_action_data(): TestaTree.HoverAction<ScheduleScope, ScheduleScope, Schedule> {
        return computed(() => {
            let color;
            if (this.props.group_id != null) {
                color = Group.color()
            } else if (this.props.scenario_folder_id != null) {
                color = ScenarioFolder.color()
            } else {
                color = Scenario.color()
            }
            return {
                icon: {
                    class: "fa-solid fa-bullseye",
                    color,
                },
                callback: (event, node) => {
                    if (this.props.group_id != null) {
                        return this.group.show_in_sidebar()
                    } else if (this.props.scenario_folder_id != null) {
                        return this.scenario_folder.show_in_sidebar()
                    } else {
                        return this.scenario.show_in_sidebar()
                    }
                }
            }
        })
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<ScheduleScope, ScheduleScope, VueRecord>> = {}
            return keys;
        })
    }

    // </editor-fold>


    testa_tree_node_data(): TestaTree.NodeInput<ScheduleScope, ScheduleScope, Schedule> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.SCHEDULE,
            record: this,
            title: computed(() => {
                return {
                    template: this.name(),
                    component: ScheduleTitle,
                    component_props: { schedule: this }
                }
            }),
            duplicable: computed(() => false),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client.update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    return generate_resolved_promise()
                }
            },
            icon: computed(() => {
                return {
                    class: Schedule.icon_class,
                    color: get_css_var("--schedule-color"),
                }
            }),
            hover_action: this._hover_action_data(),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: false,
                is_drop_area: false,
            },
            contextmenu: this._tree_contextmenu(),
        }
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
Schedule.register_resource(Schedule)
ScheduleClient.ModelClass = Schedule
ScheduleScope.ModelClass = Schedule

global_event_bus.$on("after_project_version_unload", () => {
    Schedule.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            Schedule.unsync()
            if (project_version_id != null) Schedule.sync(`/sync/project_version/${project_version_id}/schedules`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})


if (globalThis.schedules_props) {
    schedules_props.forEach(schedule_props => Schedule.new(schedule_props))
}

declare global {
    var schedules_props: ScheduleProps[]

    interface Window {
        Schedule: typeof Schedule
    }
}
window.Schedule = Schedule
// </editor-fold>

