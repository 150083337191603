import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aad288bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Tab = _resolveComponent("Tab")
  const _component_TabContent = _resolveComponent("TabContent")

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.tab_manager.id,
    class: "tab-manager-container",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tabs no-scrollbar", _ctx.tabs_class]),
      onWheelPassive: _cache[0] || (_cache[0] = (...args) => (_ctx.on_tabs_wheel && _ctx.on_tabs_wheel(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tab_manager.computed.sorted_tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_Tab, {
          key: tab.id,
          tab: tab
        }, null, 8 /* PROPS */, ["tab"]))
      }), 128 /* KEYED_FRAGMENT */))
    ], 34 /* CLASS, NEED_HYDRATION */),
    _createElementVNode("div", {
      class: "content",
      onDragenter: _cache[13] || (_cache[13] = (e) => _ctx.tab_manager._emit('dragenter', e)),
      onDragover: _cache[14] || (_cache[14] = (e) => _ctx.tab_manager._emit('dragover', e)),
      onDragleave: _cache[15] || (_cache[15] = (e) => _ctx.tab_manager._emit('dragleave', e))
    }, [
      (_ctx.display_dnd_drop)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["dnd-drop-areas-container", {'scenario-builder-tab_manager': _ctx.is_scenario_builder_tab_manager}]),
            style: _normalizeStyle(_ctx.dnd_style)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["dnd-over-drop-area main", {single: !_ctx.dnd_show_right_and_bottom_area && !_ctx.dnd_show_right_and_bottom_area}]),
              onDragenter: _cache[1] || (_cache[1] = (e) => _ctx.tab_manager._emit('dragenter', e)),
              onDragover: _cache[2] || (_cache[2] = (e) => _ctx.tab_manager._emit('dragover', e)),
              onDragleave: _cache[3] || (_cache[3] = (e) => _ctx.tab_manager._emit('dragleave', e)),
              onDrop: _cache[4] || (_cache[4] = (e) => _ctx.tab_manager.state.active_tab.on_drag_drop(e, 'main'))
            }, null, 34 /* CLASS, NEED_HYDRATION */),
            (_ctx.dnd_show_right_and_bottom_area)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "dnd-over-drop-area right",
                  onDragenter: _cache[5] || (_cache[5] = (e) => _ctx.tab_manager._emit('dragenter', e)),
                  onDragover: _cache[6] || (_cache[6] = (e) => _ctx.tab_manager._emit('dragover', e)),
                  onDragleave: _cache[7] || (_cache[7] = (e) => _ctx.tab_manager._emit('dragleave', e)),
                  onDrop: _cache[8] || (_cache[8] = (e) => _ctx.tab_manager.state.active_tab.on_drag_drop(e, 'right'))
                }, null, 32 /* NEED_HYDRATION */))
              : _createCommentVNode("v-if", true),
            (_ctx.dnd_show_right_and_bottom_area)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "dnd-over-drop-area down",
                  onDragenter: _cache[9] || (_cache[9] = (e) => _ctx.tab_manager._emit('dragenter', e)),
                  onDragover: _cache[10] || (_cache[10] = (e) => _ctx.tab_manager._emit('dragover', e)),
                  onDragleave: _cache[11] || (_cache[11] = (e) => _ctx.tab_manager._emit('dragleave', e)),
                  onDrop: _cache[12] || (_cache[12] = (e) => _ctx.tab_manager.state.active_tab.on_drag_drop(e, 'down'))
                }, null, 32 /* NEED_HYDRATION */))
              : _createCommentVNode("v-if", true)
          ], 6 /* CLASS, STYLE */))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tab_manager.computed.sorted_tabs, (tab) => {
        return _withDirectives((_openBlock(), _createBlock(_component_TabContent, {
          key: tab.id,
          tab: tab,
          onDragenter: (e) => tab._emit('dragenter', e),
          onDragover: (e) => tab._emit('dragover', e),
          onDragleave: (e) => tab._emit('dragleave', e),
          onDrop: (e) => tab._emit('drop', e)
        }, null, 8 /* PROPS */, ["tab", "onDragenter", "onDragover", "onDragleave", "onDrop"])), [
          [_vShow, tab.instance_id == _ctx.tab_manager.state.active_tab?.instance_id]
        ])
      }), 128 /* KEYED_FRAGMENT */))
    ], 32 /* NEED_HYDRATION */)
  ], 12 /* STYLE, PROPS */, _hoisted_1))
}