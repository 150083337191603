import moment from "moment";

function set(m: moment.Moment, el: HTMLElement) {
    el.innerText = m.strftime('%H:%M:%S')
    el.title = m.toString();
}

export var v_time = {
    mounted(el: any, binding: any) {
        if (binding.value == null) return;

        const m = moment(binding.value)
        set(m, el)
    },
    updated(el: any, binding: any, _vnode: any, _prevNode: any) {
        if (binding.value == null) {
            el.innerText = "";
            el.title = null;
            return;
        }
        const m = moment(binding.value)
        set(m, el)
    },
}
