import { VueRecord } from "../base/vue_record";
import { EnumApp } from "../../auto_generated/enums";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { AppScope } from "../scopes/app_scope";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import _ from "lodash";
import { computed } from "../../helpers/vue/computed";
import { AppClient } from "../clients/app_client";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { create_vue_app } from "../../helpers/vue/create_vue_app";
import UploadFromUrl from "../../components/app/UploadFromUrl.vue";
import { get_files_webkit_data_transfer_items } from "../../helpers/files/get_files_ewebkit_data_transfer_list";
import SidebarTitle from "../../components/app/SidebarTitle.vue";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";

// <editor-fold desc="TYPES">
export interface AppProps extends Props {
    id: number,
    name: string,
    package: string,
    version_name: string,
    version_code: string,
    filesize: number,
    project_id: number,
    user_id: number,
    created_at: Date,
    updated_at: Date,
    app_type: EnumApp,
    resigned_from_app_id: number,
    mobile_provisioning_id: number
}

export type AppUpdateProps = Partial<AppProps>

export interface AppState extends State {
}

export interface AppComputed extends Computed {
}

export interface AppStaticState extends StaticState {
    types: EnumApp[],
    order_by_version: boolean
    packages_per_type: { [key in EnumApp]?: string[] }
    upload_progresses: Array<number>
}

// </editor-fold>

const console = new Consoler("warn")
export class App extends VueRecord {
    ['constructor']: typeof App

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = AppClient
    static ScopeClass = AppScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: AppStaticState = reactive<AppStaticState>({
        types: [],
        order_by_version: true,
        packages_per_type: {},
        upload_progresses: [],
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_id", "app_type", "package"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof App> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof App>> = {}

    static field_validators: ModelValidatorOpts<AppProps> = {}

    static resource_name = Enum.Resource.Label.APP
    static resource_id = Enum.Resource.Id.APP
    static icon_class = "fa-solid fa-cube"
    static color = () => get_css_var("--app-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: AppClient
    declare props: AppProps;
    declare state: AppState;
    declare computed: AppComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_apps_tree()): Promise<void> {
        return App.show_in_sidebar(this.props.id, tree.project_version?.key(), tree);
    }

    static async show_in_sidebar(app_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_apps_tree()) {
        let ids: number[];
        if (what_is_it(app_ids) == "Array") {
            ids = app_ids as number[]
        } else {
            ids = [app_ids as number]
        }

        if (web.is_main) {
            Section.get_apps_section().enable()

            const all_keys: string[][] = []
            ids.forEach(app_id => {
                const app = App.find(app_id)
                const keys: string[] = []
                if (App.state.types.length > 1) {
                    keys.push(App.app_tree_key(app.props.app_type, null))
                    if (App.state.packages_per_type[app.props.app_type].length > 1) {
                        keys.push(App.app_tree_key(app.props.app_type, app.props.package))
                    }
                } else if (App.state.packages_per_type[app.props.app_type].length > 1) {
                    keys.push(App.app_tree_key(app.props.app_type, app.props.package))
                }
                keys.push(app.tree_key())
                all_keys.push(keys)
            })
            tree.expand_paths(all_keys)
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: App.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="HELPERS">
    static pretty_app_type_name(app_type: EnumApp) {
        switch (app_type) {
            case Enum.App.ANDROID:
                return "Apk"
            case Enum.App.IPHONE:
                return "Ipa"
            case Enum.App.SIMULATOR:
                return "Zip"
            default:
                return "Unknown"
        }
    }

    static app_type_icon_class(app_type: EnumApp) {
        switch (app_type) {
            case Enum.App.ANDROID:
                return "fa-brands fa-android"
            case Enum.App.IPHONE:
                return "fa-brands fa-apple"
            case Enum.App.SIMULATOR:
                return "fa-solid fa-file-zipper"
            default:
                return VueRecord.icon_class
        }
    }

    path(include_name = false) {
        const name = include_name ? this.name() : ""
        if (App.state.types.length == 0) {
            return `/${name}`
        }

        if (App.state.types.length == 1) {
            const packages = Object.values(App.state.packages_per_type[App.state.types[0]])
            if (packages.length == 1) {
                return `/${name}`
            } else {
                return `/${this.props.package}/${name}`
            }
        } else {
            const packages = Object.values(App.state.packages_per_type[this.props.app_type])
            if (packages.length == 1) {
                return `/${App.pretty_app_type_name(this.props.app_type)}/${name}`
            } else {
                return `/${App.pretty_app_type_name(this.props.app_type)}/${this.props.package}/${name}`
            }
        }
    }

    static app_tree_key(app_type: string, app_package: string) {
        if (app_type == null && app_package == null) {
            return `${this.resource_name.replaceAll(" ", "_")}_root`
        } else if (app_type != null && app_package == null) {
            return `${app_type}_${this.resource_name.replaceAll(" ", "_")}_root`
        } else if (app_type == null && app_package != null) {
            return `${app_package}_${this.resource_name.replaceAll(" ", "_")}_root`
        } else {
            return `${app_type}_${app_package}_${this.resource_name.replaceAll(" ", "_")}_root`
        }
    }

    static setup_file_picker_upload() {
        $("#import_resources_input")
            .attr("accept", ".apk, .ipa, .zip")
            .off()
            .on("change", function() {
                const thiz = this as HTMLInputElement
                for (let i = 0; i < thiz.files.length; ++i) {
                    AppClient.upload(thiz.files[i], i)
                }
            })
            .trigger("click");

        // track progress for all uploading files, so we can have single nanobar
        this.state.upload_progresses = []
    }

    static read_dropped_files_and_upload(items: DataTransferItemList) {
        console.log("items", items);
        get_files_webkit_data_transfer_items(items, "", ["apk", "ipa"])
            .then(files => {
                console.log("files", files);
                let count = 0;
                for (const folder_id in files) {
                    if (!files.hasOwnProperty(folder_id)) continue;

                    files[folder_id].forEach(file => AppClient.upload(file, count++))
                }
            })
        // track progress for all uploading files, so we can have single nanobar
        this.state.upload_progresses = []
    }
    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    download() {
        this.client.download()
    }

    static show_upload_from_url_form() {
        create_vue_app(UploadFromUrl, { project: current.project })
    }
    // </editor-fold>

    // <editor-fold desc="TREE">
    // <editor-fold desc="DND">
    static _testa_tree_node_dnd(): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => false),
            is_drop_area: computed(() => dnd.state.is_files),
            on_drop: (e: DragEvent) => {
                if (dnd.state.is_files) {
                    App.read_dropped_files_and_upload(e.dataTransfer.items)
                }
            },
        }
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXT MENU">
    static _upload_contextmenu_item() {
        return {
            name: "Upload",
            icon: "fa-solid fa-plus",
            color: App.color(),
            key: "+",
            callback: () => {
                App.setup_file_picker_upload()
            },
        }
    }

    static _upload_from_url_contextmenu_item() {
        return {
            name: "Upload from URL",
            icon: "fa-solid fa-link",
            color: App.color(),
            key: "ctrl++",
            callback: () => {
                App.show_upload_from_url_form()
            }
        }
    }

    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<AppScope, AppScope, App>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}


                items.upload = App._upload_contextmenu_item()
                items.upload_from_url = App._upload_from_url_contextmenu_item()

                items.download = {
                    name: "Download",
                    icon: "fa-solid fa-download",
                    color: get_css_var("--button-blue"),
                    callback: () => {
                        this.download();
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    static _testa_tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<AppScope, AppScope, App>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                items.upload = App._upload_contextmenu_item()
                items.upload_from_url = App._upload_from_url_contextmenu_item()

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<AppScope, AppScope, VueRecord>> = {}

            keys["+"] = () => {
                App.setup_file_picker_upload()
            }

            keys["ctrl++"] = () => {
                App.show_upload_from_url_form()
            }
            return keys;
        })
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<AppScope, AppScope, App> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.APP,
            record: this,
            title: computed(() => {
                return {
                    template: this.props.name,
                    component: SidebarTitle,
                    component_props: { app: this }
                }
            }),
            duplicable: computed(() => false),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    // return this.open()
                    return generate_resolved_promise()
                }
            },
            icon: computed(() => {
                return {
                    class: App.app_type_icon_class(this.props.app_type),
                    color: get_css_var("--app-color"),
                }
            }),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: true,
                is_drop_area: computed(() => dnd.state.is_files),
                on_drop: (e: DragEvent) => {
                    if (dnd.state.is_files) {
                        App.read_dropped_files_and_upload(e.dataTransfer.items)
                    }
                },
                on_over: (e: DragEvent) => {
                    // console.log(JSON.parse(JSON.stringify(dnd)));
                    // e.dataTransfer.dropEffect = "move"
                }
            },
            contextmenu: this._tree_contextmenu(),
        }
    }

    static testa_tree_node_data_app_type(app_type: EnumApp, project_id: number): TestaTree.NodeInput<AppScope, AppScope, App> {
        return {
            resource_id: Enum.Resource.Id.APP,
            folder: {
                open_fn: () => generate_resolved_promise(),
            },
            children: computed(() => {
                let app_scope = App.get_scope().where({ project_id }).where({ app_type });
                if (App.state.order_by_version) {
                    app_scope = app_scope.order("version_name", "desc", "version").order("version_code", "desc")
                }
                app_scope = app_scope.order("created_at", "desc", "sensitive")
                const packages = Object.values(App.state.packages_per_type[app_type]).uniq().sort()
                if (packages.length < 2) {
                    return app_scope.toArray().map(app => app.testa_tree_node_data())
                } else {
                    return packages.map(app_package => App.testa_tree_node_data_package(app_type, app_package, project_id))
                }
            }),
            icon: computed(() => {
                return {
                    class: App.app_type_icon_class(app_type),
                    color: get_css_var("--app-color"),
                    scale: 0.9,
                }
            }),
            key: this.app_tree_key(app_type, null),
            title: computed(() => {
                return { template: App.pretty_app_type_name(app_type) }
            }),
            dnd: App._testa_tree_node_dnd(),
            contextmenu: App._testa_tree_contextmenu()
        }
    }

    static testa_tree_node_data_package(app_type: EnumApp, app_package: string, project_id: number): TestaTree.NodeInput<AppScope, AppScope, App> {
        return {
            resource_id: Enum.Resource.Id.APP,
            folder: {
                open_fn: () => generate_resolved_promise(),
                file_scope: computed(() => {
                    let scope = App.where({ project_id })
                                   .where({ package: app_package })
                    if (app_type != null) scope = scope.where({ app_type })
                    if (App.state.order_by_version) {
                        scope = scope.order("version_name", "desc", "version").order("version_code", "desc")
                    }
                    scope = scope.order("created_at", "desc", "sensitive")
                    return scope
                }),
            },
            icon: computed(() => {
                return {
                    class: App.icon_class,
                    color: get_css_var("--app-color"),
                    scale: 0.9,
                }
            }),
            key: this.app_tree_key(app_type, app_package),
            title: computed(() => {
                return { template: app_package }
            }),
            dnd: App._testa_tree_node_dnd(),
            contextmenu: App._testa_tree_contextmenu()
        }
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
App.register_resource(App)
AppClient.ModelClass = App
AppScope.ModelClass = App

if (globalThis.apps_props) {
    apps_props.forEach(app_props => App.new(app_props))
}

on_dom_content_loaded(() => {
    watch(
        () => current.project?.props?.id,
        (project_id) => {
            App.unsync()
            if (project_id != null) App.sync(`/sync/project/${project_id}/apps`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

on_dom_content_loaded(() => {
    App.state.types = computed(() => {
        return _.uniq(App.get_scope().where({ project_id: current.project.key() }).toArray().map(app => app.props.app_type))
    })

    App.state.packages_per_type = computed(() => {
        const packages_per_type: { [key in EnumApp]?: string[] } = {}
        App.state.types.forEach(app_type => {
            packages_per_type[app_type] = App.get_scope()
                                             .where({ app_type })
                                             .toArray()
                                             .pluck("props")
                                             .pluck("package")
                                             .sort()
        })
        return packages_per_type
    })
})


declare global {
    /** List of all apps on project */
    var apps_props: AppProps[]

    interface Window {
        App: typeof App
    }
}
window.App = App
// </editor-fold>
