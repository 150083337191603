import { reactive } from "vue";
import { Inspector } from "./inspector";
import { Coords } from "../../../../../types/globals";
import { TestaTree } from "../../../../testa/tree/tree";
import { generate_resolved_promise } from "../../../../../helpers/generate/generate_resolved_promise";
import { get_css_var } from "../../../../../helpers/generic/get_css_var";
import { InspectorElementAttribute } from "./inspector_element_attribute";
import { SourceAttributes } from "./inspector_element_attribute";
import { BaseAttributeName } from "./inspector_element_attribute";
import { computed } from "../../../../../helpers/vue/computed";
import { AttributeName } from "./inspector_element_attribute";
import { HoveringTarget } from "./inspector";

// <editor-fold desc="SOURCE TYPES">
export interface AndroidInspectorRootElementJsonSource {
    hierarchy: AndroidInspectorHierarchyElementJsonSource
}

export type AndroidInspectorHierarchyElementJsonSource = {
    "@attributes": SourceAttributes
} & {
    [key: string]: AndroidInspectorElementJsonSource |
        AndroidInspectorElementJsonSource[] | SourceAttributes
}

export type AndroidInspectorElementJsonSource = {
    "@attributes": SourceAttributes
} & { [key: string]: AndroidInspectorElementJsonSource | AndroidInspectorElementJsonSource[] }

export type IphoneInspectorElementJsonSource = {
    "@attributes": SourceAttributes
} & { [key: string]: IphoneInspectorElementJsonSource | IphoneInspectorElementJsonSource[] }

export type InspectorElementJsonSource = AndroidInspectorRootElementJsonSource | IphoneInspectorElementJsonSource

export type InspectorJsonSource =
    AndroidInspectorHierarchyElementJsonSource
    | AndroidInspectorElementJsonSource
    | IphoneInspectorElementJsonSource
// </editor-fold>

// <editor-fold desc="TYPES">
interface BaseAttributes {
    bounds: InspectorElementAttribute<number[][]>
    top_left: InspectorElementAttribute<{
        x: number
        y: number
    }>
    center: InspectorElementAttribute<{
        x: number
        y: number
    }>
    bottom_right: InspectorElementAttribute<{
        x: number
        y: number
    }>
    height: InspectorElementAttribute<number>
    width: InspectorElementAttribute<number>
    xpath: InspectorElementAttribute<string>
}

interface AndroidAttributes extends BaseAttributes {
    class: InspectorElementAttribute<string>
    index: InspectorElementAttribute<number>
    rotation: InspectorElementAttribute<number>
    checkable: InspectorElementAttribute<boolean>
    checked: InspectorElementAttribute<boolean>
    clickable: InspectorElementAttribute<boolean>
    displayed: InspectorElementAttribute<boolean>
    enabled: InspectorElementAttribute<boolean>
    focusable: InspectorElementAttribute<boolean>
    focused: InspectorElementAttribute<boolean>
    "long-clickable": InspectorElementAttribute<boolean>
    package: InspectorElementAttribute<string>
    password: InspectorElementAttribute<boolean>
    "resource-id": InspectorElementAttribute<string>
    scrollable: InspectorElementAttribute<boolean>
    selected: InspectorElementAttribute<boolean>
    text: InspectorElementAttribute<string>
    "content-desc": InspectorElementAttribute<string>
}

interface IphoneAttributes extends BaseAttributes {
    type: InspectorElementAttribute<string>
    name: InspectorElementAttribute<string>
    x: InspectorElementAttribute<number>
    y: InspectorElementAttribute<number>
    width: InspectorElementAttribute<number>
    height: InspectorElementAttribute<number>
    visible: InspectorElementAttribute<boolean>
    value: InspectorElementAttribute<string>
    label: InspectorElementAttribute<string>
}

export type Attributes = AndroidAttributes & IphoneAttributes
export type Offset = {
    top: number
    right: number
    bottom: number
    left: number
}

type ElementsFromPoint = Array<ElementsFromPoint | InspectorElement>

// </editor-fold>


export class InspectorElement {
    readonly level: number

    /** @param inspector - Inspector instance
     *  @param parent - InspectorElement instance of parent element
     *  @param array_index - the index of this element in its parent child list of the same type/class.
     *  If parent has only 1 child of the same type/class, this is null
     *  */
    constructor(
        inspector: Inspector,
        parent: InspectorElement,
        array_index: number = null
    ) {
        this.inspector = inspector
        this.parent = parent
        this.array_index = array_index
        if (this.parent != null) this.level = this.parent.level + 1
        else this.level = 0
    }

    static new<T extends typeof InspectorElement = typeof InspectorElement>(
        this: T,
        inspector: Inspector,
        parent: InspectorElement,
        array_index: number,
        json_source: InspectorJsonSource
    ): InstanceType<T> {
        const thiz = reactive(new this(inspector, parent, array_index)) as InstanceType<T>
        thiz.init(json_source)
        return thiz
    }

    inspector: Inspector
    array_index: number
    parent: InspectorElement

    children: InspectorElement[] = []

    // source_attributes: InspectorElementSourceAttributes
    attributes: Attributes

    in_viewport: boolean
    is_hidden: boolean
    is_selected: boolean
    is_hovered: boolean
    is_filter_match: boolean

    // <editor-fold desc="COMPUTED">
    xpath: string
    /** unique element id under single snapshot for a single inspector.
     * Other snapshots within the same inspector can have the same id.  */
    id: string
    /** The HTML inspector element id */
    inspector_id: string
    /** The Node id in the tree view */
    tree_id: string
    parents: InspectorElement[]

    self_and_descendants: InspectorElement[]
    // <editor-fold desc="OFFSET CALC">

    top: number
    left: number
    bottom: number
    right: number
    offset: { top: number, right: number, bottom: number, left: number }
    // </editor-fold>
    // </editor-fold>


    init(json_source: InspectorJsonSource) {
        // @ts-ignore
        this.attributes = {}

        this.xpath = computed(() => {
            let val = ""
            if (this.parent != null) val = `${this.parent.xpath}`
            if (this.array_index != null) val += `/${this.array_index}`
            return val + `/${this.attributes[this.inspector.class_key].value}`
        })

        this.parse_json_source(json_source);

        // <editor-fold desc="COMPUTED">


        this.in_viewport = computed(() => {
            const outside_viewport = (this.attributes.top_left.value.y < 0 && this.attributes.bottom_right.value.y < 0) ||
                (this.attributes.top_left.value.x < 0 && this.attributes.bottom_right.value.x < 0) ||
                (this.attributes.top_left.value.y > this.inspector.phone_max_y_coordinate && this.attributes.bottom_right.value.y > this.inspector.phone_max_y_coordinate) ||
                (this.attributes.top_left.value.x > this.inspector.phone_max_x_coordinate && this.attributes.bottom_right.value.x > this.inspector.phone_max_x_coordinate)
            return !outside_viewport
        })
        this.attributes.xpath = InspectorElementAttribute.new(this, "xpath", this.xpath, true)

        this.id = computed(() => {
            const hashed = hash({
                inspector_id: this.inspector.id,
                xpath: this.xpath,
                text: this.attributes.text?.value
            })
            return `id_${hashed}`
        })

        this.inspector_id = computed(() => {
            return `${this.id}_inspector`
        })

        this.tree_id = computed(() => {
            return `${this.id}_tree`
        })

        this.parents = computed(() => {
            if (this.parent == null) return [] as InspectorElement[]

            return [this.parent, this.parent.parents].flat()
        })

        this.is_hidden = this.inspector.hidden_element_ids.includes(this.id)
        this.is_selected = this.inspector.selected_element_id == this.id
        this.is_hovered = this.inspector.hovered_element_id == this.id

        this.top = computed(() => {
            if (this.parent == null) {
                return (this.attributes.top_left.value.y + this.inspector.active_snapshot.aut_y_offset) * this.inspector.phone_vertical_scale * this.inspector.phone.props.pixel_ratio
            } else {
                return this.attributes.top_left.value.y * this.inspector.phone_vertical_scale * this.inspector.phone.props.pixel_ratio
            }
        })

        this.right = computed(() => {
            if (this.parent == null) {
                const right_offset = (this.inspector.phone.props.display_width / this.inspector.phone.props.pixel_ratio) -
                    (this.attributes.bottom_right.value.x * this.inspector.active_snapshot.aut_scale + this.inspector.active_snapshot.aut_x_offset)
                return right_offset * this.inspector.phone_horizontal_scale * this.inspector.phone.props.pixel_ratio
            } else {
                const right_offset = (this.inspector.phone.props.display_width / this.inspector.phone.props.pixel_ratio) - this.attributes.bottom_right.value.x
                return right_offset * this.inspector.phone_horizontal_scale * this.inspector.phone.props.pixel_ratio
            }
        })

        this.bottom = computed(() => {
            if (this.parent == null) {
                const bottom_offset = (this.inspector.phone.props.display_height / this.inspector.phone.props.pixel_ratio) -
                    (this.attributes.bottom_right.value.y * this.inspector.active_snapshot.aut_scale + this.inspector.active_snapshot.aut_y_offset)
                return bottom_offset * this.inspector.phone_vertical_scale * this.inspector.phone.props.pixel_ratio
            } else {
                const bottom_offset = (this.inspector.phone.props.display_height / this.inspector.phone.props.pixel_ratio) - this.attributes.bottom_right.value.y
                return bottom_offset * this.inspector.phone_vertical_scale * this.inspector.phone.props.pixel_ratio
            }
        })

        this.left = computed(() => {
            if (this.parent == null) {
                return (this.attributes.top_left.value.x + this.inspector.active_snapshot.aut_x_offset) * this.inspector.phone_horizontal_scale * this.inspector.phone.props.pixel_ratio
            } else {
                return this.attributes.top_left.value.x * this.inspector.phone_horizontal_scale * this.inspector.phone.props.pixel_ratio
            }
        })

        this.offset = computed(() => {
            return { top: this.top, right: this.right, bottom: this.bottom, left: this.left }
        })

        this.self_and_descendants = computed(() => {
            let descendants: InspectorElement[] = [this]
            this.children.forEach(c => descendants = descendants.concat(c.self_and_descendants))
            return descendants
        })

        this.is_filter_match = computed(() => {
            return Object.values(this.attributes)
                         .some((attribute: InspectorElementAttribute) => attribute.is_filter_match())
        })
        // </editor-fold>
    }

    // <editor-fold desc="HELPERS">
    name() {
        return this.attributes[this.inspector.class_key].display_value
    }

    find(id: string): InspectorElement {
        if (id == this.id) return this;
        // if (!id.startsWith(this.id)) return null;
        for (let i = 0; i < this.children.length; ++i) {
            const r = this.children[i].find(id)
            if (r != null) return r
        }
        return null
    }

    elements_from_point(point: Coords, only_leaf: boolean): ElementsFromPoint {
        const elements_from_point: ElementsFromPoint = []
        if (this.is_hidden) return []

        this.children.forEach(child => {
            const r = child.elements_from_point(point, only_leaf)
            if (r.length > 0) elements_from_point.push(r)
        })
        if (this.is_over_point(point)) {
            if ((only_leaf && elements_from_point.length == 0) || !only_leaf) elements_from_point.push(this)
        }
        return elements_from_point
    }

    is_over_point(point: Coords) {
        return point.x >= this.attributes.top_left.value.x && point.x <= this.attributes.bottom_right.value.x &&
            point.y >= this.attributes.top_left.value.y && point.y <= this.attributes.bottom_right.value.y
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXTMENU">
    _contextmenu_items() {
        const items: ContextMenu.Items = {}
        let separator_count = 0
        const generate_attribute_item = (attribute: InspectorElementAttribute): ContextMenu.Item => {
            const value = `<span style="color: ${attribute.value_color}">${attribute.display_value}</span>`
            return {
                name: `${attribute.display_name}:`,
                rightText: value,
                icon: "fa-regular fa-clone fa flip-horizontal",
                color: get_css_var("--button-yellow"),
                callback: (itemKey, opt, e: JQuery.TriggeredEvent) => {
                    attribute.copy((e.originalEvent as MouseEvent).ctrlKey)
                },
                onMouseenter: () => {
                    this.inspector.hover_element(this, "contextmenu")
                },
            }
        }

        if (this.attributes.class?.value != null) items.class = generate_attribute_item(this.attributes.class)
        if (this.attributes.type?.value != null) items.type = generate_attribute_item(this.attributes.type)
        if (this.attributes.name?.value != null) items.name = generate_attribute_item(this.attributes.name)
        if (this.attributes["resource-id"]?.value != null) items["resource-id"] = generate_attribute_item(this.attributes["resource-id"])
        if (this.attributes["content-desc"]?.value != null) items["content-desc"] = generate_attribute_item(this.attributes["content-desc"])
        if (this.attributes.text?.value != null) items.text = generate_attribute_item(this.attributes.text)

        // <editor-fold desc="ATTRIBUTES">
        items[`separator${++separator_count}`] = "----------"

        items.attributes = {
            name: "Attributes",
            icon: "fa-solid fa-grip-vertical",
            color: get_css_var("--button-white"),
            items: {},
            onMouseenter: () => {
                this.inspector.hover_element(this, "contextmenu")
            }
        }

        if (this.attributes.hasOwnProperty("resource-id")) {
            items.attributes.items["resource-id"] = generate_attribute_item(this.attributes["resource-id"]);
        }
        if (this.attributes.hasOwnProperty("content-desc")) {
            items.attributes.items["content-desc"] = generate_attribute_item(this.attributes["content-desc"]);
        }
        if (this.attributes.hasOwnProperty("name")) {
            items.attributes.items.name = generate_attribute_item(this.attributes.name);
        }

        const skip_keys = ["resource-id", "content-desc", "name", "top_left", "bottom_right"]
        for (const key in this.attributes) {
            if (skip_keys.includes(key)) continue;
            items.attributes.items[key] = generate_attribute_item(this.attributes[key as AttributeName])
        }
        // </editor-fold>

        // <editor-fold desc="INTERSECTIONS">
        if (this.inspector.intersecting_elements.length > 1) {
            items[`separator${++separator_count}`] = "----------"

            this.inspector.intersecting_elements.forEach((intersecting_element, index) => {
                items[`intersection_${index}`] = {
                    name: intersecting_element.name(),
                    icon: "fa-regular fa-object-ungroup",
                    callback: () => {
                        this.inspector.select_element(intersecting_element)
                    },
                    onMouseenter: () => {
                        this.inspector.hover_element(intersecting_element, "contextmenu")
                    }
                }
            })
        }
        // </editor-fold>

        // <editor-fold desc="PARENTS">
        if (this.parents.length > 0) {
            items[`separator${++separator_count}`] = "----------"
            items.parent_0 = {
                name: this.parents[0].name(),
                icon: "fa-solid fa-level-up-alt fa-rotate-90",
                callback: () => {
                    this.inspector.select_element(this.parents[0])
                },
                onMouseenter: () => {
                    this.inspector.hover_element(this.parents[0], "contextmenu")
                }
            }

            if (this.parents.length > 1) {
                items.parents = {
                    name: "Parents",
                    icon: "fa-solid fa-level-up-alt",
                    color: get_css_var("--button-white"),
                    items: {},
                    onMouseenter: () => {
                        this.inspector.hover_element(this, "contextmenu")
                    }
                }

                this.parents.forEach((parent_element, index) => {
                    items.parents.items[`parent_${index}`] = {
                        name: parent_element.name(),
                        icon: "fa-solid fa-level-up-alt",
                        callback: () => {
                            this.inspector.select_element(parent_element)
                        },
                        onMouseenter: () => {
                            this.inspector.hover_element(parent_element, "contextmenu")
                        }
                    }
                })
            }
        }
        // </editor-fold>

        // <editor-fold desc="CHILDREN">
        if (this.children.length > 0) {
            items[`separator${++separator_count}`] = "----------"
            items.children = {
                name: "Children",
                icon: "fa-solid fa-level-up-alt fa-flip-vertical",
                color: get_css_var("--button-white"),
                items: {},
                onMouseenter: () => {
                    this.inspector.hover_element(this, "contextmenu")
                }
            }

            this.children.forEach((child_element, index) => {
                (items.children as ContextMenu.Item).items[`children_${index}`] = {
                    name: `${index + 1}  ${child_element.name()}`,
                    noWrap: true,
                    callback: () => {
                        this.inspector.select_element(child_element)
                    },
                    onMouseenter: () => {
                        this.inspector.hover_element(child_element, "contextmenu")
                    }
                }
            })
        }
        // </editor-fold>

        // <editor-fold desc="SHOW/HIDE">
        if (!this.is_hidden) {
            items[`separator${++separator_count}`] = "----------"
            items.hide = {
                name: "Hide",
                icon: "fa-regular fa-eye-slash",
                key: "shift + click",
                color: get_css_var("--button-white"),
                callback: () => {
                    this.inspector.hide_element(this)
                },
                onMouseenter: () => {
                    this.inspector.hover_element(this, "contextmenu")
                }
            }
        }

        if (this.inspector.hidden_element_ids.length > 0) {
            items.show_hidden = {
                name: "Show hidden elements",
                icon: "fa-solid fa-eye",
                color: get_css_var("--button-white"),
                callback: () => {
                    this.inspector.hidden_element_ids = []
                },
                onMouseenter: () => {
                    this.inspector.hover_element(this, "contextmenu")
                }

            }
        }
        // </editor-fold>

        return items;
    }

    // </editor-fold>

    // <editor-fold desc="TREE">
    // <editor-fold desc="CONTEXTMENU">
    _testa_tree_contextmenu(): TestaTree.ContextMenu<any, any, any> {
        return {
            build: (node: TestaTree.Node<any, any, any>) => {
                const items: ContextMenu.Items = this._contextmenu_items()

                return { ...items }
            },
            events: {
                show: (options: ContextMenu.Options) => {
                    setTimeout(() => {
                        options.$menu[0].focus()
                        options.$menu.css("z-index", "9999")
                        options.$layer.css("z-index", "9998")
                        this.inspector.hover_element(this, "contextmenu")
                        this.inspector.activate_inspector();
                    }, 20)
                },
                hide: () => {
                    this.inspector.set_side_kit_close_timeout()
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<any, any, any>> = {}

            return keys;
        })
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        let file = null
        let folder = null
        if (this.children.length == 0) {
            file = {
                open_fn: () => generate_resolved_promise()
            }
        } else {
            folder = {
                open_fn: () => generate_resolved_promise()
            }
        }
        return {
            children: computed(() => {
                return this.children.map(c => c.testa_tree_node_data())
            }),
            clipboard: {
                can_copy: computed(() => false),
                can_cut: computed(() => false),
                on_paste: () => {}
            },
            contextmenu: this._testa_tree_contextmenu(),
            deletable: computed(() => false),
            dnd: {
                is_draggable: computed(() => false),
                is_drop_area: computed(() => false)
            },
            duplicable: computed(() => false),
            event_handlers: {
                on_mouse_enter: (e) => {
                    this.on_hover(e, "tree")
                },
                on_click: (_e) => {
                    this.inspector.select_element(this)
                }
            },
            file,
            folder,
            highlight: computed(() => {
                let background = null
                let enabled = false
                if (this.is_hovered && !(this.inspector.hovering_in == "tree")) {
                    enabled = true
                    background = get_css_var("--inspector-tree-hovered-background")
                } else if (this.is_selected) {
                    enabled = true
                    background = get_css_var("--inspector-tree-selected-background")
                }
                return {
                    // color: get_css_var("--button-grey"),
                    enabled,
                    background_color: background
                } as TestaTree.Highlight
            }),
            hotkeys: this._testa_tree_hotkeys(),
            icon: computed(() => {
                return {
                    enabled: false,
                }
            }),
            key: this.tree_id,
            record: null,
            renaming: {
                renameable: computed(() => false)
            },
            resource_id: null,
            title: computed(() => {
                const id = this.attributes["resource-id"]?.value != null
                const name = this.attributes.name?.value != null
                const desc = this.attributes["content-desc"]?.value != null
                const text = this.attributes.text?.value != null
                let title = this.name();
                const attributes: string[] = []
                if (id) attributes.push("[id]")
                if (name) attributes.push("[name]")
                if (desc) attributes.push("[desc]")
                if (text) attributes.push("[text]")
                if (attributes.length > 0) title += ` <span style="color: var(--font-color-secondary); font-size: 0.9em;">${attributes.join(" ")}</span>`

                const is_hidden = this.is_hidden || this.parents.some(p => p.is_hidden)

                let color: string = null
                if (this.inspector.is_filtering && this.is_filter_match) {
                    color = get_css_var("--button-yellow")
                } else if (!this.in_viewport) {
                    color = get_css_var("--button-purple")
                } else if (is_hidden) {
                    color = get_css_var("--font-color-secondary")
                } else if (this.is_selected) {
                    color = get_css_var("--button-blue")
                }

                return {
                    template: title,
                    color
                }
            }),
            is_expanded: computed(() => {
                if (this.inspector.is_filtering) {
                    return this.self_and_descendants.some(ie => ie.is_filter_match)
                } else {
                    if (this.inspector.selected_element_id == null) return null

                    return this.inspector.selected_element?.id == this.id ||
                        this.inspector.selected_element?.parents?.some(p => p.id == this.id) ||
                        this.parent?.id == this.inspector.selected_element_id
                }
            })
        }
    }

    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    on_click(event: MouseEvent) {
        if (event.shiftKey) {
            this.inspector.hide_element(this)
            event.preventDefault();
            event.stopImmediatePropagation();
            return
        }

        this.inspector.on_click(event, this)
    }

    on_hover(event: MouseEvent, hovering_in: HoveringTarget) {
        this.inspector.on_hover(event, this, hovering_in)
    }

    // </editor-fold>

    // <editor-fold desc="PARSING">
    private parse_json_source(json_source: InspectorJsonSource) {
        Object.keys(json_source).forEach(key => {
            if (key == "@attributes") {
                this.parse_source_attributes(json_source["@attributes"] as SourceAttributes)
            } else {
                const value = json_source[key]
                if (value instanceof Array) {
                    value.forEach((child_source, index) => {
                        this.children.push(InspectorElement.new(this.inspector, this, index, child_source))
                    })
                } else {
                    this.children.push(InspectorElement.new(this.inspector, this, null, value as InspectorJsonSource))
                }
            }
        })
    }

    private parse_source_attributes(source_attributes: SourceAttributes) {
        Object.keys(source_attributes).forEach(key => {
            const attribute = key as BaseAttributeName
            // @ts-ignore
            this.attributes[attribute] = InspectorElementAttribute.new(this, attribute, source_attributes[attribute]);
        })

        let bottom_x: number = null
        let bottom_y: number = null
        let center_x: number = null
        let center_y: number = null
        let bounds: number[][] = null
        switch (this.inspector.phone.props.phone_type) {
            case Enum.Phone.Type.ANDROID:
                // handle the case
                if (this.attributes.bounds?.value == null && this.attributes.height?.value != null && this.attributes.width?.value != null) {
                    this.attributes.bounds = InspectorElementAttribute.new(this, "bounds", [[0, 0], [this.attributes.width.value, this.attributes.height.value]], true)
                    this.attributes.top_left = InspectorElementAttribute.new(this, "top_left", { x: 0, y: 0 }, true)
                    this.attributes.bottom_right = InspectorElementAttribute.new(this, "bottom_right", {
                        x: this.attributes.width.value,
                        y: this.attributes.height.value
                    }, true)
                    this.attributes.center = InspectorElementAttribute.new(this, "center", {
                        x: Math.floor(this.attributes.width.value / 2),
                        y: Math.floor(this.attributes.height.value / 2)
                    }, true)
                } else if (this.attributes.bounds?.value != null) {
                    this.attributes.top_left = InspectorElementAttribute.new(this, "top_left", {
                        x: this.attributes.bounds.value[0][0],
                        y: this.attributes.bounds.value[0][1]
                    }, true)
                    this.attributes.bottom_right = InspectorElementAttribute.new(this, "bottom_right", {
                        x: this.attributes.bounds.value[1][0],
                        y: this.attributes.bounds.value[1][1]
                    }, true)
                    const x = Math.floor((this.attributes.bounds.value[0][0] + this.attributes.bounds.value[1][0]) / 2)
                    const y = Math.floor((this.attributes.bounds.value[0][1] + this.attributes.bounds.value[1][1]) / 2)
                    this.attributes.center = InspectorElementAttribute.new(this, "center", { x, y }, true)
                }
                break;
            case Enum.Phone.Type.IPHONE:
            case Enum.Phone.Type.SIMULATOR:
                bottom_x = this.attributes.x.value + this.attributes.width.value
                bottom_y = this.attributes.y.value + this.attributes.height.value
                bounds = [[this.attributes.x.value, this.attributes.y.value], [bottom_x, bottom_y]]
                center_x = Math.floor(this.attributes.x.value + (this.attributes.width.value / 2))
                center_y = Math.floor(this.attributes.y.value + (this.attributes.height.value / 2))
                this.attributes.top_left = InspectorElementAttribute.new(this, "top_left", {
                    x: this.attributes.x.value,
                    y: this.attributes.y.value
                })
                this.attributes.bottom_right = InspectorElementAttribute.new(this, "bottom_right", {
                    x: bottom_x,
                    y: bottom_y
                }, true)
                this.attributes.bounds = InspectorElementAttribute.new(this, "bounds", bounds, true)
                this.attributes.center = InspectorElementAttribute.new(this, "center", {
                    x: center_x,
                    y: center_y
                }, true)
                break;
        }
    }


    // </editor-fold>
}
