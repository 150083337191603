<template>
  <ToggleIcon
      v-model="Play.state.filter_shown"
      enabled_icon_class="fa-solid fa-filter"
      enabled_color_class="white"
      disabled_color_class="grey"
      :title="Play.state.filter_shown ? 'Click to hide filters' : 'Click to show filters'"
      :no_click_focus="true"
      :scale="1"
  />

  <ActionIcon
      v-if="Play.state.filter_result_state.last_response_failed"
      icon_class="fa-solid fa-arrow-rotate-right"
      color_class="blue"
      title="Retry"
      :no_click_focus="true"
      :scale="1.1"
      :allow_concurrent_click_action="false"
      @click="Play.ClientClass.load_filtered"
  />

  <span
      v-if="Play.state.filter_label != null"
      class="play-filter-label"
      @click="Play.state.filter_shown = !Play.state.filter_shown"
  >
    {{ Play.state.filter_label }}
  </span>

  <ActionIcon
      v-if="Play.state.filter_label != null"
      icon_class="fa-solid fa-xmark"
      color_class="red"
      title="Clear filters"
      @click="Play.clear_filters"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ToggleIcon from "../../../ToggleIcon.vue";
import ActionIcon from "../../../ActionIcon.vue";
import { Play } from "../../../../../vue_record/models/play/play";

export default defineComponent({
    components: { ActionIcon, ToggleIcon },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Play() {
            return Play
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.play-filter-label {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
