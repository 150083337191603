/**
 * Wraps the function in a try-catch block and logs the error to the console.
 * @param func
 * @returns the return value of the function or null if an error occurred.
 */
export function log_error(func: Function) {
    try {
        return func()
    } catch (e) {
        console.error(e)
        return null;
    }
}
