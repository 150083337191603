/**
 * @param cm codemirror instance
 * @param line line number starts from 0
 * @param ch if character index is given, cursor will be placed before the character index. starts from 0.
 *  if not given then the whole line will be marked and the cursor will be at the beginning of the line
 */
export function jump_to_line(cm: CodeMirror.Editor, line: number, ch: number = null) {
    let start_ch, end_ch;
    if (ch == null) {
        start_ch = 0
        end_ch = cm.getLine(line).length
    } else {
        start_ch = ch
        end_ch = ch
    }

    const t = cm.charCoords({ line, ch: 0 }, "local").top;
    const middleHeight = cm.getScrollerElement().offsetHeight / 2;
    cm.scrollTo(null, t - middleHeight - 5);
    cm.setCursor({ line, ch: start_ch })


    const from = { line, ch: start_ch }
    const to = { line, ch: end_ch }
    const mark = cm.markText(from, to, { className: "jump-to-highlight-background" })
    setTimeout(function() {
        mark.clear();
    }, 2000)
}
