<template>
  <template v-if="show_table">
    <SettingsItem
        :id="items.superadmin.browsers.id"
        :title="items.superadmin.browsers.title">
      <div class="row"
           style="display: flex">
        <div class="col s8 inline-flex"
             style="display: flex">
          <Button
              id="add_browser_button"
              text="Add Browser"
              :click_action="add_browser"
          />
          <Button
              id="add_driver_button"
              text="Add Driver"
              @click="add_driver"
          />
          <Button
              v-if="deletion_markers.marked().length > 0"
              id="delete_browsers_button"
              text="Delete"
              color_class="red"
              @click="delete_browsers"
          />
        </div>
        <div class="col s4">
          <Input
              id="browsers_filter"
              v-model="filter"
              placeholder="Filter"
              :throttle_time="200"
          />
        </div>
      </div>
      <div class="row"
           style="min-height: 30px">
        <div ref="job_status_container"
             class="col s12 job-status"
             :class="{green: !status_message_is_error, red: status_message_is_error}">
          {{ status_message }}
        </div>
      </div>
      <Table
          v-if="loaded"
          id="browsers_table">
        <template #thead>
          <tr>
            <template v-for="(col, key) in visible_columns"
                      :key="key">
              <th v-if="key == 'delete'"
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  @click.stop="all_marked = !all_marked">
                <Checkbox
                    v-model="all_marked"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </th>
              <th v-else
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  :data-priority="orders.priority(key)"
                  @click="orders.toggle(key)">
                {{ col.name }}
              </th>
            </template>
          </tr>
        </template>

        <template #tbody>
          <tr v-for="row in ordered_rows"
              :key="row.record.props.id"
              class="browser-row"
              @click="show_browser(row.record)"
          >
            <template v-for="(col, index) in row.cols"
                      :key="index">
              <td v-if="col.column_key == 'delete'"
                  :title="col.title"
                  :class="col.classes"
                  @click.stop="deletion_markers.marker(row.record).toggle()">
                <Checkbox
                    v-model="deletion_markers.marker(row.record).value"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </td>
              <td v-else-if="col.column_key == 'active'"
                  :title="col.title"
                  :class="col.classes"
              >
                <Checkbox
                    :id="`browser_active_checkbox_${row.record.props.id}`"
                    v-model="row.record.props.active"
                    label=""
                    :disabled="true"
                    :for_table="true"
                />
              </td>
              <td v-else-if="col.column_key == 'download'"
                  :title="col.title"
                  :class="col.classes"
                  @click.stop="download_browser(row.record)">
                <i v-if="loading_markers.marker(row.record).value"
                   class="fas fa-sync-alt fa fa-spin"
                />
                <i v-else
                   class="fas fa-download"/>
              </td>
              <td v-else
                  v-moment="col.moment"
                  :title="col.title"
                  :class="col.classes"
                  v-html="col.html"/>
            </template>
          </tr>
        </template>
      </Table>
      <Loading
          v-else
          type="rotating_plane"
          :inflate="true"
          :size="5"
      />

      <div v-if="add_browser_promise_resolve != null && add_browser_promise_reject != null">
        <AddBrowser
            :browser_drivers="browser_drivers"
            :jobberman_id="add_browser_jobberman_id"
            @cancel="add_browser_promise_reject()"
        />
      </div>
    </SettingsItem>
  </template>
  <template v-else>
    <BrowserEdit
        :browser="edit_browser"
        :browser_drivers="browser_drivers"
        :loading_marker="loading_markers.marker(edit_browser)"
        :form_validator="form_validator.register_child_form('browser_edit')"
        @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
        @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
        @exit="show_table=true"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Table from "../../../testa/Table.vue";
import Input from "../../../testa/Input.vue";
import SettingsItem from "../../SettingsItem.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Button from "../../../testa/Button.vue"
import AddBrowser from "./AddBrowser.vue";
import BrowserEdit from "./BrowserEdit.vue";
import { PropType } from "vue";
import Loading from "../../../testa/Loading.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { Browser } from "../../../../vue_record/models/browser";
import { BrowserDriver } from "../../../../vue_record/models/browser_driver";
import { orderable_version_value } from "../../../../helpers/generic/orderable_version_value";
import { BrowserDriverScope } from "../../../../vue_record/scopes/browser_driver_scope";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";
import { Jobberman } from "../../../../helpers/jobberman/jobberman";

export default defineComponent({
    components: {
        Loading,
        BrowserEdit,
        AddBrowser,
        Checkbox,
        SettingsItem,
        Input,
        Table,
        Button,
    },
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            authenticity_token,
            show_table: true,
            edit_browser: null as Browser,
            filter: "",
            add_browser_promise_resolve: null,
            add_browser_promise_reject: null,
            add_browser_jobberman_id: null,
            deletion_markers: new RecordMarker([]),
            loading_markers: new RecordMarker([]),
            all_marked: false,
            loaded: false,
            columns: {
                delete: {
                    orderable: false,
                    filterable: false,
                    action: true,
                    classes: "red",
                },
                name: {
                    name: "Name",
                    html: (record) => {
                        return record.props.name
                    },
                },
                label: {
                    name: "Label",
                    html: (record) => {
                        return record.props.label
                    },
                    order_value: (record) => {
                        return orderable_version_value(record.props.label)
                    },
                },
                version: {
                    name: "Version",
                    html: (record) => {
                        return record.props.version
                    },
                    order_value: (record) => {
                        return orderable_version_value(record.props.version)
                    },
                },
                driver_version: {
                    name: "Driver version",
                    html: (record) => {
                        return record.props.driver_version
                    },
                    order_value: (record) => {
                        return orderable_version_value(record.props.driver_version)
                    },
                },
                active: {
                    name: "Active",
                    action: true,
                    html: (record) => record.props.active?.toString(),
                    classes: "active",
                },
                download: {
                    filterable: false,
                    orderable: false,
                    action: true,
                    title: "Download browser to nfs",
                    classes: "green",
                },
            } as RecordColumns<Browser>,
            orders: new RecordOrder([["label", "desc"], ["name", "asc"]]),
            status_message: "",
            status_message_is_error: false,
            job_status_timeout: null as NodeJS.Timer,
        }
    },
    computed: {
        items() {
            return setting_items
        },
        visible_columns() {
            return RecordsTable.visible_columns<Browser>(this.columns);
        },
        records() {
            return Browser.toArray()
        },
        browser_drivers() {
            return BrowserDriver.get_scope() as BrowserDriverScope
        },
        rows() {
            return RecordsTable.generate_rows<Browser>(this.visible_columns, this.records as Browser[])
        },
        filtered_rows() {
            return RecordsTable.filter<Browser>({
                columns: this.visible_columns,
                rows: this.rows,
                filter: this.filter,
            })
        },
        ordered_rows() {
            return RecordsTable.order<Browser>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    watch: {
        'filtered_rows'() {
            this.deletion_markers.set_records(this.filtered_rows.map(r => r.record) as Browser[])
            this.loading_markers.set_records(this.filtered_rows.map(r => r.record) as Browser[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
        "status_message"() {
            clearTimeout(this.job_status_timeout as NodeJS.Timeout);
            this.job_status_timeout = setTimeout(() => {
                this.status_message = ""
            }, 15000);
        },
    },
    mounted() {
        this.$emit("setting-item-mounted", this)
        this.load();
    },
    unmounted() {
        faye.unsubscribe("/jobs/add_browser_job_v2/*")
        this.$emit("setting-item-unmounted", this)
    },
    updated() {
    },
    methods: {
        show_browser(browser: Browser) {
            this.edit_browser = browser
            this.show_table = false
        },

        load() {
            if (this.loaded) return;
            let drivers = false
            let browsers = false
            BrowserDriver.ClientClass
                         .index()
                         .then(() => {
                             drivers = true
                             if (browsers && drivers) this.loaded = true
                         })
                         .catch(() => this.loaded = false)
            Browser.ClientClass
                   .index()
                   .then(() => {
                       browsers = true
                       if (browsers && drivers) this.loaded = true
                   })
                   .catch(() => this.loaded = false)
        },
        download_browser(browser: Browser) {
            browser.client.download();
        },
        delete_browsers() {
            Browser.delete(this.deletion_markers.marked().map(p => p.props.id))
        },
        add_browser() {
            Jobberman.promise({
                run: (jobberman_id, resolve, reject) => {
                    this.add_browser_jobberman_id = jobberman_id
                    this.add_browser_promise_resolve = resolve
                    this.add_browser_promise_reject = reject
                },
                log: (level, message, extras) => {
                    this.status_message = message
                    this.status_message_is_error = level == "error" || level == "fatal"
                }
            })
        },
        add_driver() {
            BrowserDriver.ClientClass
                         .upload()
                         .then(response => {
                             this.status_message_is_error = false
                             this.status_message = response.status
                         })
                         .catch((error) => {
                             this.status_message_is_error = true
                             this.status_message = error.jqXHR.responseJSON.message
                         })
        },
    },
})
</script>

<style lang="scss" scoped>
.browser-row {
  cursor: pointer;

  td {
    &.green {
      color: var(--button-green);
    }

    &.red {
      color: var(--button-red);
    }
  }
}

.job-status {
  &.green {
    color: var(--button-green);
  }

  &.red {
    color: var(--button-red);
  }
}
</style>
