<template>
  <label ref="vue_label"
         class="custom-field vue-label"
         :class="{invalid: validator?.invalid}">
    <span
        :id="id"
        ref="editable"
        class="editable"
        :class="{invalid: validator?.invalid}"
        contenteditable="true"
        tabindex="0"
    ></span>
    <ValidationErrors v-if="validator?.invalid && show_errors"
                      :errors="validator.errors"/>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import KeyUpEvent = JQuery.KeyUpEvent;
import { PropType } from "vue";
import ValidationErrors from "./ValidationErrors.vue";
import { Validator } from "../../helpers/validator/validator";
import { KEY } from "../../types/globals";
import { select_text_in_element } from "../../helpers/dom/select_text_in_element";

export default defineComponent({
    components: { ValidationErrors },
    props: {
        id: {
            type: String,
            required: false,
            default: "",
        },
        modelValue: {
            type: String,
            required: true,
        },
        validator: {
            type: Object as PropType<Validator>,
            required: false,
            default: null,
        },
    },
    emits: ['update:modelValue', 'is-valid', 'blur'],
    data() {
        return {
            initial_value: this.modelValue,
            show_errors: false,
        }
    },
    computed: {},
    watch: {
        modelValue: {
            handler() {
                this.validator?.run(this.modelValue);
            },
            immediate: true,
        },
        'validator.invalid'() {
            if (this.validator.invalid) {
                if ($(this.$refs.input as HTMLInputElement).is(":focus")) {
                    this.show_errors = true;
                }
            }
        }
    },
    mounted() {
        const $span = $(this.$refs.editable as HTMLSpanElement)
        $span.text(this.modelValue)
        $span.on("keyup", (e: KeyUpEvent) => {
            if (e.keyCode == KEY.ENTER && !e.shiftKey) {
                this.$emit('update:modelValue', $span.text())
                $span.trigger("blur")
            } else if (e.keyCode == KEY.ESC) {
                $span.text(this.initial_value)
                this.$emit('update:modelValue', this.initial_value)
                $span.trigger("blur")
            } else {
                this.$emit('update:modelValue', $span.text())
            }
            e.stopImmediatePropagation();
        })
        $span.on("focus", () => {
            this.show_errors = true
        })
        $span.on("blur", (e) => {
            this.$emit('update:modelValue', $span.text())
            this.$emit("blur")
            e.stopImmediatePropagation();
            this.show_errors = false
        })
        this.validator?.bind_element(() => { return this.$refs.vue_label as HTMLElement })
        this.validator?.bind_value(() => { return this.modelValue })
    },
    methods: {
        select_text() {
          select_text_in_element(this.$refs.editable as HTMLElement)
        }
    },
})
</script>

<style lang="scss" scoped>
label {
  .editable {
    cursor: text;
    padding: 0.3em;
  }

  &.invalid {
    .editable {
      color: var(--button-red);
      border: 1px solid var(--button-red);
      border-radius: 5px;
    }
  }
}
</style>
