import { VueRecordClient } from "../base/vue_record_client";
import { GroupFolder } from "../models/group_folder";
import { GroupFolderCreateProps } from "../models/group_folder";
import { GroupFolderChildren } from "../models/group_folder";
import { Group, GroupProps } from "../models/group";
import { GroupFolderProps } from "../models/group_folder";
import { GroupFolderUpdateProps } from "../models/group_folder";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { GroupScope } from "../scopes/group_scope";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { CacheStoreValue } from "../base/vue_record_client";

export class GroupFolderClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof GroupFolder
    declare record: GroupFolder

    /** When copy - pasting we are duplicating to different folder */
    static copy(project_version_id: number, destination_group_folder_id: number, group_folder_ids: number[], group_ids: number[] = []) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/group_folders/copy`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id,
                    destination_group_folder_id,
                    group_ids,
                    group_folder_ids,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static create(group_folder: GroupFolderCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: "/group_folders",
                type: "POST",
                data: {
                    group_folder,
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }

    children(reload = false): Promise<GroupFolderChildren> {
        return GroupFolder.ClientClass.children(this.record.props.project_version_id, this.record.props.id, reload)
    }

    static children(project_version_id: number, group_folder_id: number, reload = false): Promise<GroupFolderChildren> {
        const params_hash = hash({ project_version_id, group_folder_id })
        if (reload) GroupFolder.state.children_promises[params_hash] = null

        if (GroupFolder.state.children_promises[params_hash] == null) {
            type ChildrenResponse = {
                groups: GroupProps[],
                group_folders: GroupFolderProps[],
            }

            const promise: Promise<GroupFolderChildren> = new Promise<GroupFolderChildren>((resolve, reject) => {
                $.ajax({
                    url: `/group_folders/children`,
                    type: "GET",
                    data: {
                        project_version_id,
                        group_folder_id,
                    },
                    success: (data: ChildrenResponse) => {
                        const promise_response: GroupFolderChildren = {
                            groups: [],
                            group_folders: []
                        }
                        data.groups.forEach(group_prop => {
                            promise_response.groups.push(Group.new(group_prop))
                        })
                        data.group_folders.forEach(group_folder_prop => {
                            promise_response.group_folders.push(GroupFolder.new(group_folder_prop))
                        })
                        resolve(promise_response)
                    },
                    error: (error) => {
                        reject(error)
                    },
                    statusCode: ajax_status_codes,
                })
            })
            GroupFolder.state.children_promises[params_hash] = promise
            promise.catch(() => {
                GroupFolder.state.children_promises[params_hash] = null
            })
            return promise
        } else {
            return GroupFolder.state.children_promises[params_hash]
        }
    }

    static move(project_version_id: number, destination_group_folder_id: number, group_folder_ids: number[], group_ids: number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/group_folders/move`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    destination_group_folder_id,
                    group_ids,
                    group_folder_ids,
                    project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(group_folder: GroupFolderUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/group_folders/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    group_folder,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    /** Duplicate single item in current folder */
    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/group_folders/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static batch_path(group_ids: number | number[]) {
        let ids: number[];
        if (what_is_it(group_ids) == "Array") {
            ids = group_ids as number[]
        } else {
            ids = [group_ids as number]
        }

        type PathResponse = {
            [key: string]: {
                target: GroupFolderProps,
                path: GroupFolderProps[]
            }
        }

        type PromiseResponse = {
            [key: string]: GroupFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/group_folders/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    ids,
                    authenticity_token,
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(group_folder_id => {
                        GroupFolder.new(data[group_folder_id].target)
                        const group_folders_props = data[group_folder_id].path;
                        const array: GroupFolder[] = []
                        group_folders_props.forEach(group_folder_props => {
                            array.push(GroupFolder.new(group_folder_props))
                        })
                        promise_response[group_folder_id] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static batch_load_groups(ids: string[] | number[]): Promise<GroupScope> {
        if (ids.length == 0) return generate_resolved_promise<GroupScope>(Group.to_scope([]))

        return new Promise<GroupScope>((resolve, reject) => {
            $.ajax({
                url: `/group_folders/load_groups`,
                type: "GET",
                data: {
                    ids
                },
                statusCode: ajax_status_codes,
                success: (data: GroupProps[]) => {
                    const records: Group[] = []
                    data.forEach(group_props => {
                        records.push(Group.new(group_props))
                    })
                    resolve(Group.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                },
            })
        });
    }
}
