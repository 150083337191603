import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ScenarioFolderSavepoint } from "../models/scenario_folder_savepoint";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ScenarioFolderSavepointClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ScenarioFolderSavepoint
    declare record: ScenarioFolderSavepoint

}
