import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6732c24f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "title", "disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ValidationErrors = _resolveComponent("ValidationErrors")

  return (_openBlock(), _createElementBlock("label", {
    ref: "label",
    class: _normalizeClass(["vue-label testa-select", {'for-table': _ctx.for_table, 'for-header': _ctx.for_header, 'for-report-filter': _ctx.for_report_filter, invalid: _ctx.validator?.invalid}]),
    style: {"position":"relative"}
  }, [
    (_ctx.label != '')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          ref: "label_text",
          class: "label-text"
        }, _toDisplayString(_ctx.label), 513 /* TEXT, NEED_PATCH */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("select", {
      id: _ctx.id,
      ref: "select",
      title: _ctx.title,
      disabled: _ctx.disabled,
      class: "vue-select",
      style: {"width":"100%"},
      onChange: _cache[0] || (_cache[0] = (...args) => (_ctx.on_change && _ctx.on_change(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1),
    (_ctx.validator?.invalid && _ctx.show_errors)
      ? (_openBlock(), _createBlock(_component_ValidationErrors, {
          key: 1,
          no_side: _ctx.errors_no_side,
          errors: _ctx.validator.errors
        }, null, 8 /* PROPS */, ["no_side", "errors"]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}