import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { PhoneProjectClient } from "../clients/phone_project_client";
import { PhoneProjectScope } from "../scopes/phone_project_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface PhoneProjectProps extends Props {
    id: number
    project_id: number
    udid: string
    added_by_id: number
    created_at: Date
    updated_at: Date
}
export type QuerifiedPhoneProjectProps = QuerifyProps<PhoneProjectProps>
export type PhoneProjectCreateProps = Omit<PhoneProjectProps, 'id'>
export type PhoneProjectUpdateProps = Partial<PhoneProjectProps>

export interface PhoneProjectState extends State {}
export interface PhoneProjectComputed extends Computed {}
export interface PhoneProjectStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PhoneProject extends VueRecord {
    ['constructor']: typeof PhoneProject

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PhoneProjectClient
    static ScopeClass = PhoneProjectScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PhoneProjectStaticState = reactive<PhoneProjectStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "udid", "project_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PhoneProject> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PhoneProject>> = {}

    static field_validators: ModelValidatorOpts<PhoneProjectProps> = {}

    static resource_name = Enum.Resource.Label.PHONE_PROJECT
    static resource_id = Enum.Resource.Id.PHONE_PROJECT
    static icon_class = "fa-solid fa-mobile-screen"
    static color = () => get_css_var("--phone-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PhoneProjectClient
    declare props: PhoneProjectProps;
    declare state: PhoneProjectState;
    declare computed: PhoneProjectComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PhoneProject.register_resource(PhoneProject)
PhoneProjectClient.ModelClass = PhoneProject
PhoneProjectScope.ModelClass = PhoneProject


if (globalThis.phone_projects_props) {
    phone_projects_props.forEach(phone_project_props => PhoneProject.new(phone_project_props))
}

global_event_bus.$on("after_project_version_unload", () => {
    PhoneProject.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project?.props?.id,
        (project_id) => {
            PhoneProject.unsync()
            if (project_id != null) PhoneProject.sync(`/sync/project/${project_id}/phone_projects`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})


declare global {
    var phone_projects_props: PhoneProjectProps[]

    interface Window {
        PhoneProject: typeof PhoneProject
    }
}
window.PhoneProject = PhoneProject
// </editor-fold>

