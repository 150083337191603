import { get_css_var } from "../generic/get_css_var";
import { copy_text_to_clipboard } from "../generic/copy_to_clipboard";
import { generate_resolved_promise } from "../generate/generate_resolved_promise";
import { Play } from "../../vue_record/models/play/play";
import { Group } from "../../vue_record/models/group";
import { ScenarioFolder } from "../../vue_record/models/scenario_folder";
import { Scenario } from "../../vue_record/models/scenario";
import { GroupSavepoint } from "../../vue_record/models/group_savepoint";
import { ScenarioFolderSavepoint } from "../../vue_record/models/scenario_folder_savepoint";
import { ScenarioSavepoint } from "../../vue_record/models/scenario_savepoint";

type PlayAction = "pause" | "resume" | "stop" | "replay" | "copy_url" |
    "show_target" | "cycle_on_error" | "delete" | "reports_tab" | "project_tab"

export function play_action_icon(play_action: PlayAction, play: Play = null) {
    switch (play_action) {
        case "pause":
            return "fa-solid fa-pause"
        case "resume":
            return "fa-solid fa-play"
        case "stop":
            return "fa-solid fa-stop"
        case "replay":
            return "fa-solid fa-reply-all"
        case "copy_url":
            return "fa-solid fa-share-alt"
        case "show_target":
            return "fa-solid fa-bullseye"
        case "cycle_on_error":
            switch (play?.play_setting?.props?.on_error) {
                case Enum.Play.Setting.OnError.DEBUG:
                    return "fa-solid fa-bug"
                case Enum.Play.Setting.OnError.NEXT_LINE:
                    return "fa-solid fa-chevron-right"
                case Enum.Play.Setting.OnError.NEXT_SCENARIO:
                    return "fa-solid fa-angle-double-right"
                case Enum.Play.Setting.OnError.STOP:
                    return "fa-solid fa-bomb";
                default:
                    throw new Error("Dont know play setting on error");
            }
        case "delete":
            return "fa-solid fa-trash"
        case "reports_tab":
            return "fa-solid fa-window-restore"
        case "project_tab":
            return "fa-solid fa-window-restore fa-flip-horizontal"
        default:
            throw new Error(`Unknown play action (${play_action})`)
    }
}

export function play_action_color(play_action: PlayAction, play: Play = null) {
    switch (play_action) {
        case "pause":
            return get_css_var("--button-blue");
        case "resume":
            return get_css_var("--button-green");
        case "stop":
            return get_css_var("--button-red");
        case "replay":
            return get_css_var("--button-green");
        case "copy_url":
            return get_css_var("--button-green");
        case "show_target":
            switch (play?.props?.type) {
                case Enum.Play.Type.GROUP:
                    return Group.color();
                case Enum.Play.Type.SCENARIO_FOLDER:
                    return ScenarioFolder.color();
                default:
                    return Scenario.color();
            }
        case "cycle_on_error":
            return get_css_var("--button-red");
        case "delete":
            return get_css_var("--button-red");
        case "reports_tab":
            return get_css_var("--button-green");
        case "project_tab":
            return get_css_var("--button-blue")
        default:
            throw new Error(`Unknown play action (${play_action})`)
    }
}

export function play_action_title(play_action: PlayAction, play: Play = null) {
    switch (play_action) {
        case "pause":
            return "Pause"
        case "resume":
            return "Resume"
        case "stop":
            return "Stop execution"
        case "replay":
            return "Replay"
        case "copy_url":
            return "Copy report link"
        case "show_target":
            switch (play?.props?.type) {
                case Enum.Play.Type.GROUP:
                    return "Show Group"
                case Enum.Play.Type.SCENARIO_FOLDER:
                    return "Show Scenario Folder"
                default:
                    return "Show Scenario"
            }
        case "cycle_on_error":
            switch (play?.play_setting?.props?.on_error) {
                case Enum.Play.Setting.OnError.DEBUG:
                    return "Debug on error. Click to change"
                case Enum.Play.Setting.OnError.NEXT_LINE:
                    return "Continue to next line on error. Click to change"
                case Enum.Play.Setting.OnError.NEXT_SCENARIO:
                    return "Continue to next scenario on error. Click to change"
                case Enum.Play.Setting.OnError.STOP:
                    return "Stop execution on error. Click to change"
                default:
                    throw new Error("Dont know play setting on error");
            }
        case "delete":
            return "Delete"
        case "reports_tab":
            return "Open in reports tab";
        case "project_tab":
            return "Open main project tab"
        default:
            throw new Error(`Unknown play action (${play_action})`)
    }
}

export function run_play_action(play: Play, play_action: PlayAction) {
    let url;
    let uri;
    switch (play_action) {
        case "pause":
            return play.client.pause()
        case "resume":
            return play.client.resume()
        case "stop":
            return play.client.stop()
        case "replay":
            play.show_replay_modal()
            return generate_resolved_promise()
        case "copy_url":
            url = play.props.public_report_url
            if (!url.includes("/")) {
                url = url.replace("/play", "/")
            }
            uri = new URL(url)
            uri.protocol = location.protocol
            copy_text_to_clipboard(uri.toString());
            return generate_resolved_promise()
        case "show_target":
            switch (play?.props?.type) {
                case Enum.Play.Type.GROUP:
                    return GroupSavepoint.ClientClass
                                         .load(play.props.target_group_savepoint_id)
                                         .then(group_savepoint => {
                                             Group.show_in_sidebar(group_savepoint.props.group_id, play.props.project_version_id)
                                         })
                case Enum.Play.Type.SCENARIO_FOLDER:
                    return ScenarioFolderSavepoint.ClientClass
                                                  .load(play.props.target_scenario_folder_savepoint_id)
                                                  .then(scenario_folder_savepoint => {
                                                      ScenarioFolder.show_in_sidebar(scenario_folder_savepoint.props.scenario_folder_id, play.props.project_version_id)
                                                  })
                default:
                    return ScenarioSavepoint.ClientClass
                                            .load(play.props.target_scenario_savepoint_id)
                                            .then(scenario_savepoint => {
                                                Scenario.show_in_sidebar(scenario_savepoint.props.scenario_id, play.props.project_version_id)
                                            })
            }
        case "cycle_on_error":
            return play.client.cycle_on_error()
        case "delete":
            return Play.delete(play.props.project_version_id, play.key())
        case "reports_tab":
            window.open(`/reports/?play_id=${play.key()}`, "_blank")
            play.get_tabs().forEach(t => t.close())
            return generate_resolved_promise()
        case "project_tab":
            window.open(`/?project_version_id=${play.props.project_version_id}`)
            return generate_resolved_promise();
        default:
            throw new Error(`Unknown play action (${play_action})`)
    }
}
