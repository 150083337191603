<template>
  <div :id="tab.content_element_id"
       class="tab-content">
    <template v-if="!tab.state.loaded">
      <Loading
          :size="5"
          :inflate="true"
          type="rotating_plane"
          margin="0"
      />
    </template>
    <template v-else-if="tab.state.error.is_error">
      <div class="error-container">
        <div style="color: var(--button-red)">
          Failed to load
        </div>
        <div>{{ tab.state.error.message }}</div>
        <div v-if="current.user.is_superadmin()"
             class="backtrace">
          <div v-for="trace in tab.state.error.backtrace"
               :key="trace"
          >
            {{ trace }}
          </div>
        </div>
        <ActionIcon
            icon_class="fa-solid fa-repeat"
            color_class="blue"
            title="Retry"
            :click_action="tab.load"
            scale="2"
        />
      </div>
    </template>
    <template v-else>
      <component
          :is="tab.component"
          v-bind="tab.component_props"
          @focus="tab._emit('focused')"
          @click="tab._emit('focused')"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Tab } from "./tab";
import Loading from "../Loading.vue";
import ActionIcon from "../ActionIcon.vue";

export default defineComponent({
    components: { ActionIcon, Loading },
    // <editor-fold desc="PROPS">
    props: {
        tab: {
            type: Object as PropType<Tab>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.tab-content {
  width: 100%;
  height: 100%;

  .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .backtrace {
      max-height: 70%;
      overflow: auto;
    }
  }
}
</style>
