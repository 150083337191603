import moment from "moment";

function get_update_frequency(moment: moment.Moment): number {
	const current = new Date().valueOf()
	const diff = Math.abs(current - moment.valueOf());
	if (diff < 60000) {
		// if less than 1 minute, update every second
		return 1000
	} else if (diff < 3600000) {
		// if less than 1 hour, update every minute
		return 60000
	} else {
		// else update every hour
		return 3600000
	}
}

export var v_moment = {
	mounted(el: any, binding: any) {
		if (binding.value == null) return;

		const m = moment(binding.value)
		el.innerText = m.fromNow();
		el.title = m.toString();
		el.moment_internval = setInterval(() => {
			el.innerText = moment(binding.value).fromNow();
		}, get_update_frequency(m))
	},
	updated(el: any, binding: any, _vnode: any, _prevNode: any) {
		if (el.moment_internval) clearInterval(el.moment_internval)
		if (binding.value == null) return;

		const m = moment(binding.value)
		el.innerText = m.fromNow();
		el.title = m.toString();
		el.moment_internval = setInterval(() => {
			el.innerText = moment(binding.value).fromNow();
		}, get_update_frequency(m))
	},
	unmounted(el: any, _binding: any) {
		if (el.moment_internval) clearInterval(el.moment_internval)
	}
}
