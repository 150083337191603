<template>
  <span class="vue-action-icon-container"
        :style="style"
        :class="clazz"
        :tabindex="action_in_progress && !allow_concurrent_click_action ? null : tab_index"
        @keydown="click_on_enter"
        @click="on_click"
        @mousedown="on_mousedown"
  >
    <span
        v-if="action_in_progress && !allow_concurrent_click_action"
        class="loading-container"
        title="Action in progress"
    >
      <Loading
          :fadein="false"
          margin="0"
          :color="color_class"
          type="fading_circle"
      />
    </span>
    <span
        v-else
        :id="id"
        class="vue-label vue-action-icon"
        :class="clazz"
        :title="title"
        :style="icon_style"
        @keydown="click_on_enter"
        @click="on_click"
        @mousedown="on_mousedown"
    >
      <i :class="icon_class"/>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { ColorClass } from "../../types/color_class";
import ClickEvent = JQuery.ClickEvent;
import Loading from "./Loading.vue";
import { FormValidator } from "../../helpers/validator/form_validator";
import { click_on_enter } from "../../helpers/events/click_on_enter";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { CSSProperties } from "vue";
import { color_class_to_var } from "../../types/color_class";
import { ButtonAction } from "../../types/components/testa/button_action";

export default defineComponent({
    components: { Loading },
    // <editor-fold desc="PROPS">
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        color_class: {
            type: String as PropType<ColorClass | string>,
            required: false,
            default: "white",
        },
        icon_class: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        tab_index: {
            type: Number,
            required: false,
            default: 0,
        },
        margin: {
            type: String,
            required: false,
            default: null,
        },
        padding: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: false,
            default: null,
        },
        click_action: {
            type: Function as PropType<ButtonAction>,
            required: false,
            default: null,
        },
        allow_concurrent_click_action: {
            type: Boolean,
            required: false,
            default: false,
        },
        scale: {
            type: [Number, String],
            required: false,
            default: 1.0
        },
        no_click_focus: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    // </editor-fold>
    emits: ['click', 'action-success', 'action-error', 'action-done'],
    // <editor-fold desc="DATA">
    data() {
        return {
            action_in_progress: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        scale_number(): number {
            if (typeof this.scale == "string") {
                return parseFloat(this.scale)
            } else return this.scale
        },
        color() {
            return this.color_class_to_var(this.color_class)
        },
        fontSize() {
            return `${this.scale_number}em`
        },
        clazz() {
            return {
                disabled: this.disabled,
            }
        },
        style() {
            const style: CSSProperties = {
                margin: this.margin,
                fontSize: this.fontSize,
                padding: this.padding
            }

            if (this.color != null) {
                style.color = this.color
            }

            if (this.form_validator?.invalid) style.color = this.color_class_to_var("red")
            return style;
        },
        icon_style() {
            const style: CSSProperties = {}
            if (this.icon_class.includes("fa-plus")) {
                // better centers the plus icon
                style.alignItems = "flex-end"
            }
            // if (this.icon_class.includes("fa-play")) {
            //     style.justifyContent = "flex-end"
            // }
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    updated() {
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        click_on_enter,
        on_click(e: ClickEvent) {
            e.stopImmediatePropagation()
            e.preventDefault();
            if (this.disabled) return;
            if (this.action_in_progress && !this.allow_concurrent_click_action) return;

            this.form_validator?.on_confirm_click()
            if (this.form_validator == null || this.form_validator.valid) {
                this.$emit('click', e)
                if (this.click_action != null) {
                    const promise = this.click_action();
                    if (promise != null) {
                        this.action_in_progress = true
                        if (what_is_it((promise as Promise<any>).finally) == "Function") {
                            (promise as Promise<any>).then(() => {
                                this.$emit('action-success')
                            }).catch(() => {
                                this.$emit('action-error')
                            }).finally(() => {
                                this.action_in_progress = false
                                this.$emit('action-done')
                            })
                        } else {
                            (promise as JQuery.jqXHR).then(() => {
                                this.$emit('action-success')
                            }).fail(() => {
                                this.$emit('action-error')
                            }).always(() => {
                                this.action_in_progress = false
                                this.$emit('action-done')
                            })
                        }
                    }
                }
            }
        },
        on_mousedown(e: MouseEvent) {
            if (this.no_click_focus) e.preventDefault();
        },
        color_class_to_var
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
span.vue-action-icon-container {
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  aspect-ratio: 1/1;
  margin-inline: 0.1em;
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  .loading-container {
    min-width: 0;
    width: 100%;
    overflow: hidden;
  }

  $box-shadow-half: 3px;

  span.vue-action-icon {
    margin: 0.25em;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: calc(100% - $box-shadow-half);
    height: calc(100% - $box-shadow-half);
    border-radius: 50%;

    &.disabled {
      cursor: not-allowed;
      color: var(--button-disabled) !important;
    }

    &:not(.disabled) {
      &:hover,
      &:focus {
        box-shadow: 0 0 $box-shadow-half 0 rgba(0, 0, 0, 0.55);
        //background-color: color-mix(in srgb, var(--primary-background-color), #000000 10%);
        background-color: var(--primary-background-color);
        filter: brightness(1.3);
      }
    }

    i {
      margin: 0.25em;
    }
  }

  &.default {
    color: var(--secondary-background-color);
  }

  &.grey {
    color: var(--button-grey);
  }

  &.red {
    color: var(--button-red);
  }

  &.green {
    color: var(--button-green);
  }

  &.blue {
    color: var(--button-blue);
  }

  &.white {
    color: var(--button-white);
  }
}


</style>
