import SuccessCallback = JQuery.Ajax.SuccessCallback;
import Faye from "faye"
import toastr_imported from "toastr"
import jqXHR = JQuery.jqXHR;
import { reactive } from "vue";
import { computed } from "vue";
import { on_online } from "../helpers/events/on_online";
import { on_offline } from "../helpers/events/on_offline";
import { EffectScope } from "vue";
import { EnumUserRole } from "../auto_generated/enums";
import { EventBus } from "../helpers/event_bus";
import { effectScope } from "vue";
import _, { LoDashStatic } from "lodash";
import { watch } from "vue";
globalThis.hash = require("object-hash")

require("../helpers/dnd")
require("../components/testa/progress_bar/progress_bar")

export type WebType = "Worker" | "Main" | "Reports" | "Single Report" | "Logs" | "Login" | "Docs" | "test_dom"
export type Coords = {
    x: number
    y: number
}

export type BoundingBox = {
    top_left: Coords,
    bottom_right: Coords
}

export type Size = {
    width: number
    height: number
}

type KEY_SHORTCUTS = {
    TAB: number
    ENTER: number,
    ESC: number,
    SPACE: number,
    LEFT: number
    UP: number
    RIGHT: number
    DOWN: number
    DEL: number
    C: number
    D: number
    F: number
    R: number
    V: number
    X: number
    CONTEXTMENU: number
    F2: number
}

export var KEY: KEY_SHORTCUTS = {
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DEL: 46,
    C: 67,
    D: 68,
    F: 70,
    R: 82,
    V: 86,
    X: 88,
    CONTEXTMENU: 93,
    F2: 113,
}

export type CONSTANTS = {
    IMPORT_EXPORT_VERSION: number
    IS_PROD_ENV: boolean
}

if (globalThis.CONSTANTS == null) {
    globalThis.CONSTANTS = {
        IMPORT_EXPORT_VERSION: 0,
        IS_PROD_ENV: true
    }
}

type MODAL = {
    count: number
    is_shown: boolean
}

export var KeyCode = {
    UP: "ArrowUp",
    DOWN: "ArrowDown",
    LEFT: "ArrowLeft",
    RIGHT: "ArrowRight",
    ENTER: "Enter",
    TAB: "Tab",
    SPACE: "Space",
    ESC: "Escape",
    DELETE: "Delete",
    LSHIFT: "ShiftLeft",
    RSHIFT: "ShiftRight",
    LCTRL: "ControlLeft",
    RCTRL: "ControlRight",
    LMETA: "MetaLeft",
    RMETA: "MetaRight",
    F1: "F1",
    F: "KeyF",
    A: "KeyA",
    R: "KeyR",
    V: "KeyV"
}
export type GlobalEventTypes = "before_project_version_unload" |
    "after_project_version_unload" |
    "after_project_version_load" |
    "resize-start" | "resize" | "resize-end" |
    "relations_established" |
    "editor-font-resize"

declare global {
    var _: LoDashStatic
    var IS_WEB_WORKER: boolean
    var WEB_TYPE: WebType
    let faye: Faye.Client;
    let FAYE_TOKEN: string;
    let authenticity_token: string;
    let is_pc_mac: boolean
    let is_pc_android: boolean
    let is_browser_firefox: boolean
    let is_browser_chrome: boolean
    let ctrl_or_meta: "ctrl" | "cmd"
    let alt_or_option: "alt" | "option"
    let WEB_SOCKET_URL: string
    let moment: typeof moment
    let TAB_ID: string
    var CONSTANTS: CONSTANTS
    let MODAL: MODAL
    var global_event_bus: EventBus<GlobalEventTypes>
    var hash: (obj: any) => string

    let ajax_status_codes: {
        [key: number]: SuccessCallback<null> | JQuery.Ajax.ErrorCallback<null>
    }
    var global_effect_scope: EffectScope
    let current: Current

    // convenience vars
    let active_node: any

    interface Window {
        WEB_SOCKET_URL: string
        CONSTANTS: CONSTANTS
        MODAL: MODAL
        _: LoDashStatic
        faye: Faye.Client;
        ajax_status_codes: {
            [key: number]: SuccessCallback<null> | ErrorCallback
        }
        toastr: Toastr,
        moment: typeof moment
        TAB_ID: string

        FAYE_TOKEN: string;
        authenticity_token: string;

        // @ts-ignore
        d3pie: d3pie.ID3PieClass
        is_pc_mac: boolean
        is_pc_android: boolean
        is_browser_firefox: boolean
        is_browser_chrome: boolean
        ctrl_or_meta: "ctrl" | "cmd"
        alt_or_option: "alt" | "option"

        current: Current
        global_effect_scope: EffectScope

        // convenience vars
        active_node: any
        active_tab: any
        cm: any
        mv: any
        cy: any

        // convenience vars
        computed: any
        reactive: any
        watch: any
    }

    interface Document {
        selection: Selection
    }

    interface Current {
        // models will add others

        network_status: "online" | "offline"
        websocket_status: "online" | "under-load" |"offline"
    }
}

window.computed = computed
window.reactive = reactive
window.watch = watch

let authenticity_token = null
if (!IS_WEB_WORKER) {
    authenticity_token = (document.querySelector("[name='csrf-token']") as HTMLMetaElement)?.content
}

window.MODAL = reactive({
    count: 0,
    is_shown: false,
})

window.MODAL.is_shown = computed(() => {
    return window.MODAL.count > 0
}) as any as boolean


window.authenticity_token = authenticity_token
window.toastr = toastr_imported
window.moment = require('moment-strftime');
window.TAB_ID = Math.random().toString();
window.is_pc_mac = navigator.platform.indexOf('Mac') !== -1
window.is_pc_android = /(android)/i.test(navigator.userAgent);
window.is_browser_firefox = /(firefox)/i.test(navigator.userAgent);
window.is_browser_chrome = /(chrome)/i.test(navigator.userAgent);
window.ctrl_or_meta = is_pc_mac ? "cmd" : "ctrl"
window.alt_or_option = is_pc_mac ? "option" : "alt"
window.WEB_SOCKET_URL = `${window.location.protocol}//${window.location.hostname}/faye`
window.FAYE_TOKEN = "SADASD9A8DS79AS8DZASD8AS6D9AS6DA09SD76AD09A"
window.faye = new Faye.Client(WEB_SOCKET_URL);
window._ = _
window.dmp = null
globalThis.global_event_bus = new EventBus<GlobalEventTypes>()
globalThis.global_effect_scope = effectScope(true)

// @ts-ignore
window.current = reactive({
    user: null,
    role_is_viewer: computed(() => current.role == Enum.User.Role.VIEWER || current.role == null) as any as boolean,
    role_for(_project_id: number): EnumUserRole {
        // will be overwritten in user_project
        return Enum.User.Role.VIEWER
    },
    role_is_viewer_for(project_id: number): boolean {
        const role = current.role_for(project_id)
        return role == null || role == Enum.User.Role.VIEWER
    },
    role: null,
    project: null,
    project_version: null,
    project_version_setting: null,
    theme: computed(() => {
        if (current.user == null) return null
        return current.user.props.dark_mode ? current.user.props.codemirror_dark_theme : current.user.props.codemirror_light_theme
    }) as any as string,
    network_status: "online",
    websocket_status: "offline",
})

on_online(() => current.network_status = "online")
on_offline(() => current.network_status = "offline")

window.ajax_status_codes = {
    400: function() { toastr.error("Bad Request") },
    401: function() { toastr.error("Unauthorized") },
    402: function() { toastr.error("Payment Required") },
    403: function() { toastr.error("Forbidden") },
    404: function() { toastr.error("Not Found") },
    405: function() { toastr.error("Method Not Allowed") },
    406: function() { toastr.error("Not Acceptable") },
    407: function() { toastr.error("Proxy Authentication Required") },
    408: function() { toastr.error("Request Timeout") },
    409: function() { toastr.error("Conflict") },
    410: function() { toastr.error("Gone") },
    411: function() { toastr.error("Length Required") },
    412: function() { toastr.error("Precondition Failed") },
    413: function() { toastr.error("Payload Too Large") },
    414: function() { toastr.error("URI Too Long") },
    415: function() { toastr.error("Unsupported Media Type") },
    416: function() { toastr.error("Range Not Satisfiable") },
    417: function() { toastr.error("Expectation Failed") },
    418: function() { toastr.error("I'm a teapot") },
    422: function() { toastr.error("Unprocessable Entity") },
    425: function() { toastr.error("Too Early") },
    426: function() { toastr.error("Upgrade Required") },
    428: function() { toastr.error("Precondition Required") },
    429: function() { toastr.error("Too Many Requests") },
    431: function() { toastr.error("Request Header Fields Too Large") },
    451: function() { toastr.error("Unavailable For Legal Reasons") },
    500: function(response: jqXHR) {
        try {
            const testa_error = JSON.parse(response.responseText)
            if (testa_error.error?.advice != null) {
                toastr.error(testa_error.error.advice, testa_error.error.message, { timeOut: 10000 })
            } else if (testa_error.error?.message != null) {
                toastr.error(testa_error.error.message, null, { timeOut: 10000 })
            } else {
                toastr.error(testa_error.message, null, { timeOut: 10000 })
            }
        } catch (e) {
            toastr.error("Internal server error", null, { timeOut: 10000 })
        }
    },
    501: function() { toastr.error("Not Implemented") },
    502: function() { toastr.error("Bad Gateway") },
    503: function() { toastr.error("Service Unavailable") },
    504: function() { toastr.error("Gateway Timeout") },
    505: function() { toastr.error("HTTP Version Not Supported") },
    506: function() { toastr.error("Variant Also Negotiates") },
    507: function() { toastr.error("Insufficient Storage") },
    508: function() { toastr.error("Loop Detected") },
    510: function() { toastr.error("Not Extended") },
    511: function() { toastr.error("Network Authentication Required") },
}

