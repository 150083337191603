import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14afaaa0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-scope-container no-scrollbar" }
const _hoisted_2 = { class: "search-input-container" }
const _hoisted_3 = { class: "search-input-overlay" }
const _hoisted_4 = { class: "search-result-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchRecordScope = _resolveComponent("SearchRecordScope")
  const _component_Loading = _resolveComponent("Loading")
  const _component_Input = _resolveComponent("Input")
  const _component_SearchRecordResult = _resolveComponent("SearchRecordResult")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, {
    id: _ctx.id,
    dismissible: true,
    show_confirm: false,
    backdrop_dim_level: 0,
    padding: 0,
    cancel_on_escape: true,
    focus_last_active_on_unmount: true,
    onCancel: _ctx.cancel
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", {
        ref: "search_record_container",
        class: "search-record-container",
        style: _normalizeStyle(_ctx.container_style)
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.available_scopes, (scope) => {
            return (_openBlock(), _createBlock(_component_SearchRecordScope, {
              key: scope,
              name: scope,
              selected: _ctx.selected_scope == scope,
              onSelect: $event => (_ctx.select_scope(scope))
            }, null, 8 /* PROPS */, ["name", "selected", "onSelect"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_Loading, {
                  key: 0,
                  color: "white",
                  type: "fading_circle"
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode(_component_Input, {
            id: "search_record_input",
            ref: "input",
            modelValue: _ctx.search_query,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.search_query) = $event)),
            throttle_time: 500,
            focus: true,
            placeholder: "Search",
            no_round_corners: true,
            onKeydown: _ctx.on_keydown
          }, null, 8 /* PROPS */, ["modelValue", "onKeydown"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result) => {
            return (_openBlock(), _createBlock(_component_SearchRecordResult, {
              key: result.stage_key,
              record: result,
              active: _ctx.active_key == result.stage_key,
              query: _ctx.search_query,
              onClick: () => _ctx.open(result)
            }, null, 8 /* PROPS */, ["record", "active", "query", "onClick"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (!_ctx.all_loaded)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["load-more", {active: _ctx.load_more_active}]),
                onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.load_more && _ctx.load_more(...args)))
              }, " Load more... ", 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ])
      ], 4 /* STYLE */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "onCancel"]))
}