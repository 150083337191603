import { get_state } from "./get_state";
import { search_for_alignment_spaces } from "./search_for_alignment_spaces";
import { ch_of_code_start } from "./ch_of_code_start";

const matching = { "[": "]", "{": "}", "(": ")" };

export function get_indentation_spaces(cm: CodeMirror.Editor, line_number: number) {
    const context_line_number = line_number - 1;
    const line = cm.getLine(line_number);
    const trimmed_line = line.trimStart();
    const start_of_code_ch = ch_of_code_start(line)
    const state = get_state(cm, { line: line_number, ch: start_of_code_ch })

    let search_for_alignment = false

    if (context_line_number >= 0) {
        const context_line = cm.getLine(context_line_number);
        const trimmed_context_line = context_line.trimEnd();
        search_for_alignment = trimmed_line.startsWith(".") || trimmed_context_line.endsWith(".")
    }

    if (search_for_alignment) {
        const ch = search_for_alignment_spaces(cm, context_line_number, state)
        if (ch != null) return ch
    }

    const firstChar = trimmed_line && trimmed_line.charAt(0);
    const ct = state.context;
    // @ts-ignore
    let closed = ct?.type == matching[firstChar] ||
        (ct?.type == "keyword" && /^(?:end|until|else|elsif|when|rescue)\b/.test(trimmed_line));

    if (/^[)}\]]$/.test(trimmed_line)) closed = true

    const indent_unit = cm.getOption("indentUnit")

    const indented = ct?.indented + (closed ? 0 : indent_unit) +
        (state.continuedLine ? indent_unit : 0);

    const indent_with_tabs = cm.getOption("indentWithTabs")
    const tab_size = cm.getOption("tabSize")
    if (indent_with_tabs) {
        return indented * tab_size
    } else {
        return indented
    }
}

