import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppiumScreenControl = _resolveComponent("AppiumScreenControl")
  const _component_InspectorVue = _resolveComponent("InspectorVue")

  return (_ctx.phone != null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "screen-overlay",
        style: _normalizeStyle(_ctx.style)
      }, [
        (_ctx.is_live && _ctx.phone.props.appium_screen_control)
          ? (_openBlock(), _createBlock(_component_AppiumScreenControl, {
              key: 0,
              backend_ws_channel: _ctx.backend_ws_channel,
              scenario_setting_phone: _ctx.scenario_setting_phone,
              vertical_scale: _ctx.vertical_scale,
              horizontal_scale: _ctx.horizontal_scale
            }, null, 8 /* PROPS */, ["backend_ws_channel", "scenario_setting_phone", "vertical_scale", "horizontal_scale"]))
          : _createCommentVNode("v-if", true),
        (!_ctx.MODAL_SHOWN)
          ? (_openBlock(), _createBlock(_component_InspectorVue, {
              key: 1,
              is_resizing: _ctx.is_resizing,
              vertical_scale: _ctx.vertical_scale,
              horizontal_scale: _ctx.horizontal_scale,
              tab: _ctx.tab,
              inspector: _ctx.inspector,
              screen_overlay_width: _ctx.width_px
            }, null, 8 /* PROPS */, ["is_resizing", "vertical_scale", "horizontal_scale", "tab", "inspector", "screen_overlay_width"]))
          : _createCommentVNode("v-if", true)
      ], 4 /* STYLE */))
    : _createCommentVNode("v-if", true)
}