<template>
  <template v-if="show_table">
    <SettingsItem :id="items.superadmin.delayed_jobs.id"
                  :title="items.superadmin.delayed_jobs.title">
      <div class="row"
           style="display: flex">
        <div class="col s8">
          <Button
              v-if="deletion_markers.marked().length > 0"
              text="Delete"
              color_class="red"
              @click="delete_delayed_jobs"
          />
        </div>
        <div class="col s4">
          <Input
              id="delayed_jobs_filter"
              v-model="filter"
              placeholder="Filter"
              :throttle_time="200"
          />
        </div>
      </div>
      <Table
          v-if="loaded"
          id="delayed_jobs_table">
        <template #thead>
          <tr>
            <template v-for="(col, key) in visible_columns"
                      :key="key">
              <th v-if="key == 'delete'"
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  @click.stop="all_marked = !all_marked">
                <Checkbox
                    v-model="all_marked"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </th>
              <th v-else
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  :data-priority="orders.priority(key)"
                  @click="orders.toggle(key)">
                {{ col.name }}
              </th>
            </template>
          </tr>
        </template>

        <template #tbody>
          <tr v-for="row in ordered_rows"
              :key="row.record.props.id"
              class="delayed-job-row"
          >
            <template v-for="(col, index) in row.cols"
                      :key="index">
              <td v-if="col.column_key == 'delete'"
                  :class="col.classes"
                  @click.stop="deletion_markers.marker(row.record).toggle()">
                <Checkbox
                    v-model="deletion_markers.marker(row.record).value"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </td>
              <td v-else
                  v-moment="col.moment"
                  :title="col.title"
                  :class="col.classes"
                  v-html="col.html"/>
            </template>
          </tr>
        </template>
      </Table>
      <Loading
          v-else
          type="rotating_plane"
          :inflate="true"
          :size="5"
      />
    </SettingsItem>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Table from "../../../testa/Table.vue";
import Input from "../../../testa/Input.vue";
import SettingsItem from "../../SettingsItem.vue";
import { DelayedJob } from "../../../../vue_record/models/delayed_job";
import Checkbox from "../../../testa/Checkbox.vue";
import Button from "../../../testa/Button.vue"
import { PropType } from "vue";
import Loading from "../../../testa/Loading.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";

export default defineComponent({
    components: {
        Loading,
        Checkbox,
        SettingsItem,
        Input,
        Table,
        Button,
    },
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            authenticity_token,
            show_table: true,
            edit_delayed_job: null,
            filter: "",
            deletion_markers: new RecordMarker([]),
            all_marked: false,
            loaded: false,
            columns: {
                delete: {
                    filterable: false,
                    orderable: false,
                    action: true,
                    classes: "red",
                },
                project: {
                    name: "Project",
                    html: (record) => {
                        return record.props.project_name
                    },
                },
                name: {
                    name: "Name",
                    html: (record) => {
                        return this.name(record)
                    },
                },
                user: {
                    name: "User",
                    html: (record) => {
                        return record.user?.props?.email
                    },
                },
                queue: {
                    name: "Queue",
                    html: (record) => {
                        return record.props.queue
                    },
                },
                created: {
                    name: "Created",
                    moment: (record) => record.props.created_at,
                    order_value: (record) => new Date(record.props.created_at),
                },
                started: {
                    name: "Started",
                    html: (record) => record.props.started,
                    classes: (record) => {
                        return {
                            green: record.props.started,
                            red: !record.props.started,
                        }
                    },
                },
                worker: {
                    name: "Worker",
                    html: (record) => this.locked_by(record),
                    classes: (record) => {
                        return {
                            green: record.props.started,
                            red: !record.props.started,
                        }
                    },
                },
            } as RecordColumns<DelayedJob>,
            orders: new RecordOrder([["created", "asc"]]),
        }
    },
    computed: {
        items() {
            return setting_items
        },
        visible_columns() {
            return RecordsTable.visible_columns<DelayedJob>(this.columns);
        },
        records() {
            return DelayedJob.toArray();
        },
        rows() {
            return RecordsTable.generate_rows<DelayedJob>(this.visible_columns, this.records as DelayedJob[])
        },
        filtered_rows() {
            return RecordsTable.filter<DelayedJob>({
                columns: this.visible_columns,
                filter: this.filter,
                rows: this.rows,
            })
        },
        ordered_rows() {
            return RecordsTable.order<DelayedJob>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    watch: {
        'filtered_rows'() {
            this.deletion_markers.set_records(this.rows.map(row => row.record) as DelayedJob[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
    },
    mounted() {
        this.$emit("setting-item-mounted", this)
        this.load()
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        show_delayed_job(delayed_job: DelayedJob) {
            this.edit_delayed_job = delayed_job
            this.show_table = false
        },
        delete_delayed_jobs() {
            DelayedJob.delete(this.deletion_markers.marked().map(dj => dj.props.id))
        },
        locked_by(delayed_job: DelayedJob) {
            if (delayed_job.props.locked_by == null) return "-"
            const parts = delayed_job.props.locked_by.split('-');
            return parts[parts.length - 1].split(" ")[0];
        },
        name(delayed_job: DelayedJob) {
            let name = delayed_job.props.play_name
            if (delayed_job.props.play_scenario_id == null) {
                name = `[master] ${name}`
            }
            return name
        },
        load() {
            // because we cannot properly sync delayed_job records
            // unload all, before we load all
            DelayedJob.get_scope().unload()

            DelayedJob.ClientClass.index().then(() => {
                this.loaded = true
            })
        },
    },
})
</script>

<style lang="scss" scoped>
.delayed-job-row {
  cursor: pointer;

  td {
    &.green {
      color: var(--button-green);
    }

    &.red {
      color: var(--button-red);
    }
  }
}
</style>
