import { Consoler } from "../../../../helpers/api_wrappers/consoler";
import { VueRecordClient } from "../../../base/vue_record_client";
import { PlayGroupsPlayScenarios } from "../../../models/play/bridges/play_groups_play_scenarios";
import { CacheStoreValue } from "../../../base/vue_record_client";

const console = new Consoler("warn")
export class PlayGroupsPlayScenariosClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlayGroupsPlayScenarios
    declare record: PlayGroupsPlayScenarios
}
