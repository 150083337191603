import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5efc97e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    ref: "flex",
    class: _normalizeClass(["resizable-flex", {vertical: _ctx.vertical, horizontal: _ctx.horizontal}]),
    style: _normalizeStyle(_ctx.style),
    onMousemove: _cache[1] || (_cache[1] = (...args) => (_ctx.on_mousemove && _ctx.on_mousemove(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areas_array, (area, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: area }, [
        (area.includes('__stick__'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "stick",
              style: _normalizeStyle(_ctx.stick_style),
              onMousedown: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.on_mousedown && _ctx.on_mousedown(...args)), ["self","stop"]))
            }, null, 36 /* STYLE, NEED_HYDRATION */))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "area",
              style: _normalizeStyle(_ctx.area_style(area, index))
            }, [
              _renderSlot(_ctx.$slots, area, {}, undefined, true)
            ], 4 /* STYLE */))
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
}