import { App } from "../models/app";
import { Project } from "../models/project";

declare module "../models/app" {
    interface App {
       project: Project
    }
}

App.belongs_to("project", Project, "project_id")
