import { Phonector } from "../models/phonector";
import { Phone } from "../models/phone";
import { PhoneScope } from "../scopes/phone_scope";

declare module "../models/phonector" {
    interface Phonector {
        phones: PhoneScope
    }
}


Phonector.has_many("phones", Phone, "phonector_id")
