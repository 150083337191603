import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { Device } from "../../models/mobile_provisionings/device";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class DeviceClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Device
    declare record: Device

}
