import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f51d56e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Loading, {
      size: 5,
      inflate: true,
      type: "fading_circle",
      color: "white"
    })
  ]))
}