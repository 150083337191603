import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, setBlockTracking as _setBlockTracking, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b904b25c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "debug-action" }
const _hoisted_2 = {
  id: "frame_selector_abs_wrapper",
  class: "abs-wrapper",
  style: {"z-index":"2"}
}
const _hoisted_3 = { class: "image-capture-and-frame-sel" }
const _hoisted_4 = { class: "frame-sel-container" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "image-capture-container" }
const _hoisted_7 = { class: "debug-action second" }
const _hoisted_8 = {
  class: "abs-wrapper",
  style: {"z-index":"1"}
}
const _hoisted_9 = { class: "submit-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Select2 = _resolveComponent("Select2")
  const _component_ActionIcon = _resolveComponent("ActionIcon")

  return (_openBlock(), _createElementBlock("div", {
    ref: "debug_container",
    class: "debug-container",
    style: _normalizeStyle(_ctx.debug_container_style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.debug_frames.length > 0)
              ? (_openBlock(), _createBlock(_component_Select2, {
                  key: 0,
                  id: `frame_selector_${_ctx.main_play_scenario.props.id}`,
                  modelValue: _ctx.debug_frame_id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.debug_frame_id) = $event)),
                  dropdown_parent: "#frame_selector_abs_wrapper",
                  for_report_filter: true
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.debug_frames, (debug_frame) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: debug_frame.id,
                        value: debug_frame.id
                      }, _toDisplayString(debug_frame.name) + " : " + _toDisplayString(debug_frame.error_line), 9 /* TEXT, PROPS */, _hoisted_5))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["id", "modelValue"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.show_capture_image)
              ? (_openBlock(), _createBlock(_component_ActionIcon, {
                  key: 0,
                  icon_class: "fa-solid fa-camera",
                  color_class: _ctx.Image.color(),
                  title: "Capture image (BROWSER ONLY)",
                  margin: "0",
                  onClick: _ctx.capture_image
                }, null, 8 /* PROPS */, ["color_class", "onClick"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_ActionIcon, {
            icon_class: "fa-solid fa-arrow-alt-circle-right",
            title: "Click to execute",
            scale: 1.6,
            onClick: _ctx.execute_command
          }, null, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[1] || (
        _setBlockTracking(-1),
        _cache[1] = _createElementVNode("textarea", {
          id: `debug_input_${_ctx.main_play_scenario.props.id}`,
          ref: "debug_input"
        }, null, 8 /* PROPS */, ["id"]),
        _setBlockTracking(1),
        _cache[1]
      )
    ])
  ], 4 /* STYLE */))
}