<template>
  <template v-if="show_table">
    <SettingsItem :id="items.project.proxies.id"
                  :title="items.project.proxies.title">
      <div class="row"
           style="display: flex">
        <div class="col s8 inline-flex">
          <Button
              v-if="!is_viewer_role && !is_user_role"
              id="new_proxy_button"
              tab_index="0"
              text="New Proxy"
              @click="new_proxy"
          />
          <Button
              v-if="deletion_markers.marked().length > 0 && !is_viewer_role && !is_user_role"
              id="delete_proxies_button"
              tab_index="0"
              text="Delete"
              color_class="red"
              @click="delete_proxies"
          />
        </div>
        <div class="col s4">
          <Input
              id="proxies_filter"
              v-model="filter"
              placeholder="Filter"
              tab_index="0"
              :throttle_time="200"
          />
        </div>
      </div>
      <Table
          id="proxies_table"
      >
        <template #thead>
          <tr>
            <template v-for="(col, key) in visible_columns"
                      :key="key">
              <th v-if="key == 'delete'"
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  @click.stop="all_marked = !all_marked">
                <Checkbox
                    v-model="all_marked"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </th>
              <th v-else
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  :data-priority="orders.priority(key)"
                  @click="orders.toggle(key)">
                {{ col.name }}
              </th>
            </template>
          </tr>
        </template>

        <template #tbody>
          <tr v-for="row in ordered_rows"
              :key="row.record.props.id"
              class="proxy-row"
              @click="show_proxy(row.record)"
          >
            <template v-for="(col, index) in row.cols"
                      :key="index">
              <td v-if="col.column_key == 'delete'"
                  :title="col.title"
                  :class="col.classes"
                  @click.stop="deletion_markers.marker(row.record).toggle()">
                <Checkbox
                    v-model="deletion_markers.marker(row.record).value"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </td>
              <td v-else
                  v-moment="col.moment"
                  :title="col.title"
                  :class="col.classes"
                  v-html="col.html"/>
            </template>
          </tr>
        </template>
      </Table>

      <div v-if="show_new_proxy_form && !is_viewer_role && !is_user_role">
        <ProxyNew
            :project="project"
            @exit="show_new_proxy_form = false"/>
      </div>
    </SettingsItem>
  </template>
  <template v-else>
    <ProxyEdit
        :proxy="edit_proxy"
        :project="project"
        :form_validator="form_validator.register_child_form('proxy_edit')"
        :is_viewer_role="is_viewer_role"
        :is_user_role="is_user_role"
        @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
        @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
        @exit="show_table=true"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Table from "../../../testa/Table.vue";
import Input from "../../../testa/Input.vue";
import SettingsItem from "../../SettingsItem.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Button from "../../../testa/Button.vue"
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import { Proxy } from "../../../../vue_record/models/proxy";
import ProxyNew from "./ProxyNew.vue"
import ProxyEdit from "./ProxyEdit.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";

export default defineComponent({
    components: {
        ProxyEdit,
        ProxyNew,
        Checkbox,
        SettingsItem,
        Input,
        Table,
        Button,
    },
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            show_new_proxy_form: false,
            show_table: true,
            edit_proxy: null as Proxy,
            filter: "",
            deletion_markers: new RecordMarker([]),
            all_marked: false,
            columns: {
                delete: {
                    filterable: false,
                    orderable: false,
                    action: true,
                    classes: "red",
                    hidden: this.is_viewer_role || this.is_user_role,
                },
                name: {
                    name: "Name",
                    html: (record) => record.props.name,
                },
                ip: {
                    name: "IP",
                    html: (record) => record.props.proxy_ip,
                },
                port: {
                    name: "Port",
                    html: (record) => record.props.proxy_port,
                },
            } as RecordColumns<Proxy>,
            orders: new RecordOrder([["name", "asc"]]),
        }
    },
    computed: {
        items() {
            return setting_items
        },
        visible_columns() {
            return RecordsTable.visible_columns<Proxy>(this.columns);
        },
        records() {
            return this.project.proxies.toArray()
        },
        rows() {
            return RecordsTable.generate_rows<Proxy>(this.visible_columns, this.records as Proxy[])
        },
        filtered_rows() {
            return RecordsTable.filter<Proxy>({
                columns: this.visible_columns,
                rows: this.rows,
                filter: this.filter,
            })
        },
        ordered_rows() {
            return RecordsTable.order<Proxy>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    watch: {
        'filtered_rows'() {
            this.deletion_markers.set_records(this.filtered_rows.map(c => c.record) as Proxy[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
    },
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        show_proxy(proxy: Proxy) {
            this.edit_proxy = proxy
            this.show_table = false
        },
        new_proxy() {
            this.show_new_proxy_form = true
        },
        delete_proxies() {
            Proxy.delete(this.deletion_markers.marked().map(ff => ff.props.id))
        },
    },
})
</script>

<style lang="scss" scoped>

.proxy-row {
  cursor: pointer;
}
</style>
