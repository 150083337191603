<template>
  <div
      v-once
      :ref="(el) => asc.set_container(el)"
      class="asc-overlay"
      tabindex="-1"
      @blur="asc.schedule_perform()"
      @mouseleave="asc.schedule_perform()"
      @contextmenu.prevent
      @mousedown.stop="e => asc.on_mousedown(e)"
      @mouseenter="e => asc.on_mouseenter(e)"
      @mousemove="e => asc.throttled_mouse_move(e, new Date())"
      @mouseup="e => asc.on_mouseup(e)"
      @wheel="e => asc.on_wheel(e)"
      @keydown.stop.prevent="e => asc.on_keydown(e)"
  />
</template>

<script lang="ts">
import { Phone as ScenarioSettingPhone } from '../../../../vue_record/models/scenario_setting/phone.js';
import { defineComponent } from "vue";
import { AppiumScreenControl } from "../../../../helpers/play/appium_screen_control/appium_screen_control";
import { markRaw } from "vue";
import { PropType } from "vue";

export default defineComponent({
    props: {
        backend_ws_channel: {
            type: String,
            required: true
        },
        scenario_setting_phone: {
            type: Object as PropType<ScenarioSettingPhone>,
            required: true,
        },
        vertical_scale: {
            type: Number,
            required: true,
        },
        horizontal_scale: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            asc: markRaw(AppiumScreenControl.new(this.backend_ws_channel, this.scenario_setting_phone)) as AppiumScreenControl
        }
    },
    watch: {
        vertical_scale: {
            handler() {
                this.asc?.set_vertical_scale(this.vertical_scale)
            },
            immediate: true
        },
        horizontal_scale: {
            handler() {
                this.asc?.set_horizontal_scale(this.horizontal_scale)
            },
            immediate: true
        }
    },
    mounted() {
        this.asc.set_vertical_scale(this.vertical_scale)
        this.asc.set_horizontal_scale(this.horizontal_scale)
    },
    updated() {
        this.asc.set_vertical_scale(this.vertical_scale)
        this.asc.set_horizontal_scale(this.horizontal_scale)
    }
})
</script>

<style lang="scss" scoped>
.asc-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  pointer-events: all;
}
</style>
