import { UserProject } from "../models/user_project";
import { User } from "../models/user";
import { Project } from "../models/project";


declare module "../models/user_project" {
    interface UserProject {
        user: User
        project: Project
    }
}

UserProject.belongs_to("user", User, "user_id")
UserProject.belongs_to("project", Project, "project_id")
