<template>
  <Teleport to="#modals">
    <div
        v-if="is_visible"
        class="vue-modal-backdrop"
        :style="backdrop_style"
        @mousedown.self="backdrop_click"
        @keydown="keydown"
    >
      <div v-if="is_loading"
           :style="modal_positioning">
        <Loading
            type="rotating_plane"
            :size="5"
        />
      </div>
      <div v-else
           :id="id"
           ref="modal"
           class="vue-modal"
           :style="modal_style"
      >
        <div class="vue-modal-sidebar">
          <slot name="sidebar"/>
        </div>
        <div class="vue-modal-content">
          <div class="vue-modal-header">
            <slot name="header"/>
          </div>
          <div class="vue-modal-body">
            <slot
                name="body"
                :confirm="confirm"
            />
          </div>
          <div class="footer">
            <slot name="footer"/>
          </div>
          <div class="flex-expander"/>
          <div class="buttons-container">
            <div class="left-box">
              <slot name="footer_left_box"/>
            </div>
            <div class="right-box">
              <slot name="footer_right_box"/>
            </div>
            <div v-if="show_buttons"
                 class="buttons">
              <slot name="extra_buttons_left"/>
              <Button
                  v-if="show_confirm"
                  :id="`${id}_confirm_button`"
                  ref="confirm"
                  tab_index="0"
                  :text="confirm_text"
                  :color_class="confirm_color_class"
                  :min_width="70"
                  :focus="focus_confirm"
                  :form_validator="form_validator"
                  :click_action="confirm_action"
                  @click.stop="$emit('confirm')"
                  @action-success="$emit('confirm-success')"
                  @action-error="(e) => $emit('confirm-error', e)"
                  @action-done="$emit('confirm-done')"
              />
              <Button v-if="show_deny"
                      :id="`${id}_deny_button`"
                      tab_index="0"
                      :text="deny_text"
                      :color_class="deny_color_class"
                      :min_width="70"
                      :click_action="deny_action"
                      @click.stop="on_deny_click"
                      @action-done="$emit('deny-done')"
              />
              <Button v-if="show_cancel"
                      :id="`${id}_cancel_button`"
                      ref="cancel"
                      tab_index="0"
                      :text="cancel_text"
                      :color_class="cancel_color_class"
                      :min_width="70"
                      @click.stop="on_cancel_click"
              />
              <slot name="extra_buttons_right"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loading from "./Loading.vue";
import Button from "./Button.vue";
import { PropType } from "vue";
import { ColorClass } from "../../types/color_class";
import PromiseBase = JQuery.PromiseBase;
import { FormValidator } from "../../helpers/validator/form_validator";
import { Validator } from "../../helpers/validator/validator";
import { KEY } from "../../types/globals";
import { Coords } from "../../types/globals";
import { AllMightyObserver } from "../../helpers/dom/all_mighty_observer";
import { nextTick } from "vue";

export default defineComponent({
    components: { Loading, Button },
    props: {
        show_on_mounted: {
            type: Boolean,
            required: false,
            default: true,
        },
        focus_confirm: {
            type: Boolean,
            required: false,
            default: true,
        },
        show_confirm: {
            type: Boolean,
            require: false,
            default: true,
        },
        confirm_text: {
            type: String,
            required: false,
            default: "OK",
        },
        confirm_color_class: {
            type: String as PropType<ColorClass>,
            required: false,
            default: "default",
        },
        confirm_action: {
            type: Function as PropType<() => Promise<any> | JQuery.jqXHR | PromiseBase<any, any, any, any, any, any, any, any, any, any, any, any>>,
            required: false,
            default: null,
        },
        deny_action: {
            type: Function as PropType<() => Promise<any> | JQuery.jqXHR | PromiseBase<any, any, any, any, any, any, any, any, any, any, any, any>>,
            required: false,
            default: null,
        },
        show_deny: {
            type: Boolean,
            required: false,
            default: false,
        },
        show_cancel: {
            type: Boolean,
            required: false,
            default: false,
        },
        deny_text: {
            type: String,
            required: false,
            default: "No",
        },
        cancel_text: {
            type: String,
            required: false,
            default: "Cancel",
        },
        deny_color_class: {
            type: String as PropType<ColorClass>,
            required: false,
            default: "grey",
        },
        cancel_color_class: {
            type: String as PropType<ColorClass>,
            required: false,
            default: "grey",
        },
        dismissible: {
            type: Boolean,
            required: false,
            default: true,
        },
        initial_width: {
            type: String,
            required: false,
            default: "auto",
        },
        initial_height: {
            type: String,
            required: false,
            default: "fit-content"
        },
        id: {
            type: String,
            required: false,
            default: "",
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: false,
            default: null,
        },
        validator: {
            type: Object as PropType<Validator>,
            required: false,
            default: null,
        },
        padding: {
            type: [Number, String],
            required: false,
            default: 5,
        },
        min_width: {
            type: [Number, String],
            required: false,
            default: null
        },
        is_loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        position: {
            type: Object as PropType<Coords>,
            required: false,
            default: null
        },
        backdrop_dim_level: {
            type: Number,
            required: false,
            default: 25
        },
        cancel_on_escape: {
            type: Boolean,
            required: false,
            default: false,
        },
        focus_last_active_on_unmount: {
            type: Boolean,
            required: false,
            default: false
        },
        resize: {
            type: String as PropType<"none" | "both" | "horizontal" | "vertical">,
            required: false,
            default: "none"
        },
        persist_size: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: ['confirm', 'deny', 'cancel', 'confirm-done', 'confirm-success', 'confirm-error', 'deny-done'],
    data() {
        return {
            confirm_has_run: false,
            is_visible: false,
            previous_active_element: null as HTMLElement,
            amo: null as AllMightyObserver,
            storager: current.storagers.user,
            height: this.initial_height as String | Number,
            width: this.initial_width as String | Number,
        }
    },
    computed: {
        backdrop_style() {
            return {
                background: `rgba(0, 0, 0, 0.${this.backdrop_dim_level})`
            }
        },
        modal_positioning() {
            const style: Record<string, any> = {}
            if (this.position != null) {
                style.position = "absolute"
                style.left = `${this.position.x}px`
                style.top = `${this.position.y}px`
            }
            return style
        },
        show_buttons() {
            return this.show_cancel || this.show_deny || this.show_confirm || this.$slots.extra_buttons_left || this.$slots.extra_buttons_right
        },
        modal_style() {
            return {
                ...this.modal_positioning,
                width: this.width,
                height: this.height,
                padding: this.padding,
                resize: this.resize,
                minWidth: this.min_width,
                overflow: (this.resize != null && this.resize != "none") ? "auto" : null
            }
        },
        visible_and_loaded() {
            return this.is_visible && !this.is_loading
        },
        resizable() {
            return this.resize != null && this.resize != "none"
        }
    },
    watch: {
        is_visible: {
            handler() {
                if (this.is_visible) {
                    MODAL.count++;
                } else {
                    MODAL.count--;
                }
            },
            flush: "sync",
        },
        visible_and_loaded: {
            handler() {
                if (this.visible_and_loaded) {
                    nextTick(() => {
                        this.attach_amo();
                    })
                }
            },
            immediate: true
        }
    },
    // <editor-fold desc="HOOKS">
    beforeMount() {
        if (this.persist_size && this.resizable) this.load_size();
        if (this.show_on_mounted) this.is_visible = true
        // @ts-ignore
        this.previous_active_element = document.activeElement as HTMLElement
        if (this.previous_active_element != null && this.focus_last_active_on_unmount) this.previous_active_element.blur();
    },
    mounted() {

    },
    unmounted() {
        if (this.previous_active_element != null && this.focus_last_active_on_unmount) this.previous_active_element.focus({ preventScroll: true });
        if (this.is_visible) {
            MODAL.count--;
        }
        this.amo?.stop()
    },
    // </editor-fold>
    methods: {
        attach_amo() {
            this.amo?.stop();
            const modal = this.$refs.modal as HTMLElement
            if (modal == null) return;

            this.amo = AllMightyObserver.new({
                element_after_resize: true,
                element_resize_delay: 250,
                element_visible: true,
                target_element: modal,
                callback: () => this.save_size()
            })
        },
        save_size() {
            if (this.id == null) return;
            if (!this.visible_and_loaded) return;
            const modal = this.$refs.modal as HTMLElement
            if (modal == null) return

            if (this.resize != null || this.resize != "none") {
                const computedStyle = getComputedStyle(modal);


                if (this.resize == "both" || this.resize == "horizontal") {
                    let width = modal.clientWidth; // width with padding
                    const scrollbar_width = modal.offsetWidth - modal.clientWidth - 2 * parseFloat(computedStyle.borderWidth);
                    width += scrollbar_width
                    width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

                    this.width = width
                    this.storager.set("play_modal_width", this.width)
                }

                if (this.resize == "both" || this.resize == "vertical") {
                    let height = modal.clientHeight; // height with padding
                    const scrollbar_height = modal.offsetHeight - modal.clientHeight - 2 * parseFloat(computedStyle.borderWidth);
                    height += scrollbar_height
                    height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
                    this.height = height
                    this.storager.set("play_modal_height", this.height)
                }
            }
        },
        load_size() {
            if (this.resize == "both" || this.resize == "horizontal") {
                this.width = this.storager.get("play_modal_width", this.width)
            }

            if (this.resize == "both" || this.resize == "vertical") {
                this.height = this.storager.get("play_modal_height", this.height)
            }
        },
        open() {
            this.is_visible = true
        },
        close() {
            this.is_visible = false
        },
        confirm() {
            if (!this.show_confirm) return;
            const confirm_button = this.$refs.confirm as typeof Button
            confirm_button.click()
        },
        cancel() {
            if (!this.show_cancel) return;
            const cancel_button = this.$refs.cancel as typeof Button
            cancel_button.click()
        },
        on_cancel_click() {
            this.$emit("cancel")
        },
        on_deny_click() {
            this.$emit("deny")
        },
        backdrop_click() {
            if (this.dismissible) {
                this.$emit("cancel")
            }
        },
        keydown(e: KeyboardEvent) {
            if (e.keyCode == KEY.RIGHT || e.keyCode == KEY.LEFT || e.keyCode == KEY.ENTER || e.keyCode == KEY.TAB) {
                const $active_element = $(document.activeElement)
                const $buttons_container = $active_element.parent()
                if ($buttons_container[0].classList.contains("buttons")) {
                    if (e.keyCode == KEY.ENTER) {
                        $active_element.trigger('click')
                        return;
                    }

                    let $other_element = $active_element
                    if (e.keyCode == KEY.RIGHT || (e.keyCode == KEY.TAB && !e.shiftKey)) {
                        $other_element = $active_element.next()
                    } else if (e.keyCode == KEY.LEFT || (e.keyCode == KEY.TAB && e.shiftKey)) {
                        $other_element = $active_element.prev()
                    }

                    if ($other_element.length > 0) {
                        $other_element.trigger("focus")
                        if (e.keyCode == KEY.TAB) e.preventDefault();
                    }
                }
            }
            if (this.cancel_on_escape) {
                if (e.code == "Escape") {
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    this.$emit('cancel')
                }
            }

        },
    },
})
</script>

<style lang="scss" scoped>
.vue-modal-backdrop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .vue-modal {
    background-color: var(--primary-background-color);
    border: 1px solid var(--border-color);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;

    border-collapse: separate;
    overflow: visible; // if hidden, select dropdowns will be cut off, put overflows to header, body, footer
    -webkit-box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.3);
    max-height: 90vh;
    max-width: 90vw;

    display: flex;
    flex-direction: row;
    z-index: 21;

    .vue-modal-sidebar {
      display: flex;
      flex-direction: column;
      background-color: var(--secondary-background-color);
    }

    .vue-modal-content {
      display: flex;
      flex-direction: column;

      width: 100%;
      overflow: auto;

      .vue-modal-header {
        text-align: center;
        font-size: 32px;

        & > div {
          padding: 15px;
        }
      }

      .vue-modal-body {
        overflow: auto;
        display: flex;
        flex-direction: column;
        min-height: 0;
      }

      .buttons-container {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;

        .left-box {
          display: flex;
          height: 100%;
          left: 0;
          position: absolute;
        }

        .buttons {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        .right-box {
          display: flex;
          height: 100%;
          right: 0;
          position: absolute;
        }
      }
    }


  }
}
</style>

<style lang="scss">
.vue-modal-header {
  text-align: center;
  font-size: 32px;

  & > div {
    padding: 15px;
  }
}
</style>
