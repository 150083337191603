import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Phonector } from "../models/phonector";
import { PhonectorScope } from "../scopes/phonector_scope";
import { PhoneScope } from "../scopes/phone_scope";
import { PhoneProps } from "../models/phone";
import { Phone } from "../models/phone";
import { PhonectorProps } from "../models/phonector";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")

export class PhonectorClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Phonector
    declare record: Phonector

    static index() {
        return new Promise<PhonectorScope>((resolve, reject) => {
            $.ajax({
                url: "/settings/superadmin_settings/phonectors",
                type: 'GET',
                success: (data: PhonectorProps[]) => {
                    const records: Phonector[] = []
                    data.forEach(props => {
                        records.push(Phonector.new(props))
                    })
                    resolve(Phonector.to_scope(records))
                },
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }

    load_phones() {
        return new Promise<PhoneScope>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/phonectors/${this.key()}/phones`,
                type: 'GET',
                success: (data: PhoneProps[]) => {
                    const records: Phone[] = [];
                    data.forEach(props => {
                        records.push(Phone.new(props))
                    })
                    resolve(Phone.to_scope(records))
                },
                error: (e) => reject(e),
                statusCode: ajax_status_codes,
            })
        })
    }

    static delete(ids: number | number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/phonectors/destroy`,
                type: "DELETE",
                data: {
                    ids,
                    authenticity_token,
                },
                statusCode: ajax_status_codes,
                success: () => resolve(),
                error: (e) => reject(e)
            });
        })
    }
}
