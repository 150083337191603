<template>
  <div
      :id="id"
      ref="flex"
      class="resizable-flex"
      :class="{vertical, horizontal}"
      :style="style"
      @mousemove="on_mousemove"
  >
    <template v-for="(area, index) in areas_array"
              :key="area">
      <template v-if="area.includes('__stick__')">
        <div class="stick"
             :style="stick_style"
             @mousedown.self.stop="on_mousedown"
        />
      </template>
      <template v-else>
        <div class="area"
             :style="area_style(area, index)">
          <slot :name="area"/>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { CSSProperties } from "vue";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { nextTick } from "vue";
import { AllMightyObserver } from "../../helpers/dom/all_mighty_observer";
import { Consoler } from "../../helpers/api_wrappers/consoler";

const console = new Consoler("debug")
export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        direction: {
            type: String as PropType<"row" | "row-reverse" | "column" | "column-reverse">,
            required: true
        },
        stick_size: {
            type: Number,
            required: false,
            default: 5
        },
        areas: {
            type: String,
            required: true
        },
        item_min_size: {
            type: Number,
            required: false,
            default: 25
        },
        background_color: {
            type: String,
            required: false,
            default: "var(--primary-background-color)",
        },
        initial_area_sizes: {
            type: Array as PropType<Array<number | string>>,
            required: false,
            default: (props: any) => {
                const n = props.areas.split(/ +/).length
                const size = 100.0 / n
                return Array(n).fill(`${size}%`)
            }
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            area_sizes_modified: [] as number[],
            flex_width: 0,
            flex_height: 0,
            is_resizing: false,
            amo: null as AllMightyObserver,
            grabbed_stick: null as HTMLElement,
            grabbed_stick_index: null as number,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        areas_array() {
            return this.areas.split(/ +/).join(" __stick__ ").split(" ")
        },
        vertical() {
            return this.direction == "column" || this.direction == "column-reverse"
        },
        horizontal() {
            return this.direction == "row" || this.direction == "row-reverse"
        },
        style() {
            const style: CSSProperties = {
                flexDirection: this.direction
            }
            return style
        },
        stick_style() {
            const style: CSSProperties = {}
            if (this.horizontal) {
                style.width = `${this.stick_size}px`
            } else {
                style.height = `${this.stick_size}px`
            }
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const flex = this.$refs.flex as HTMLDivElement;
        this.set_flex_size()
        const initial_area_sizes_without_sticks = this.initial_area_sizes.clone()
        for (let i = 0; i < initial_area_sizes_without_sticks.length; ++i) {
            if (i % 2 == 1) initial_area_sizes_without_sticks.splice(i, 0, this.stick_size)
        }
        this.area_sizes_modified = this.area_sizes_to_px(initial_area_sizes_without_sticks)
        this.amo = AllMightyObserver.new(
            {
                element_resize: true,
                element_visible: true,
                target_element: flex,
                callback: () => this.set_flex_size()
            }
        )

        document.body.addEventListener("mouseup", this.on_mouseup)
        document.addEventListener("blur", this.on_blur)
    },
    unmounted() {
        this.amo?.stop();
        // this.visibility_observer?.disconnect();
        document.body.removeEventListener("mouseup", this.on_mouseup)
        document.removeEventListener("blur", this.on_blur)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        area_sizes_to_px(sizes: Array<number | string>): number[] {
            return sizes.map(s => {
                if (what_is_it(s) == "String") {
                    if (this.horizontal) return this.flex_width * (parseFloat(s as string) / 100)
                    else return this.flex_height * (parseFloat(s as string) / 100)
                } else return s as number
            })
        },
        scale_sizes(scale: number, sizes: number[], flex_width_or_height: number,
                              min_size: number,
                              except_index: number,
                              get_fixed_size: (column_or_row: number) => number,
                              stick_check: (index: number) => boolean) {
            let columns_or_rows_for_resize = 0;
            sizes = sizes.map((column_or_row_size, index) => {
                if (stick_check(index)) return this.stick_size;
                if (except_index == index) return column_or_row_size
                const fixed_size = get_fixed_size(index);
                if (fixed_size != null) return fixed_size

                ++columns_or_rows_for_resize;
                return column_or_row_size * scale;
            })

            const new_total_size = sizes.reduce((total, current) => total + current)
            if (new_total_size != flex_width_or_height) {
                const should_shrink = new_total_size > flex_width_or_height;
                let leftover = Math.abs(flex_width_or_height - new_total_size)

                let i = 0;
                while (i < sizes.length && leftover > 0) {
                    if (!stick_check(i) && (get_fixed_size(i) == null || columns_or_rows_for_resize == 0)) {
                        const column_or_row_size = sizes[i];
                        if (should_shrink) {
                            if (column_or_row_size > min_size) {
                                const available = column_or_row_size - min_size;
                                if (available >= leftover) {
                                    sizes[i] -= leftover
                                    leftover = 0;
                                } else {
                                    sizes[i] = min_size;
                                    leftover -= available;
                                }
                            }
                        } else {
                            sizes[i] += leftover
                            leftover = 0;
                        }
                    }
                    ++i;
                }
            }
            return sizes;
        },
        adjust(except_index = -1) {
            if (this.area_sizes_modified.length == 0) return;

            let rows = this.area_sizes_modified
            const total = rows.reduce((total, current) => total + current)
            let scale: number
            if (this.vertical) {
                scale = this.flex_height / total;
                rows = this.scale_sizes(
                    scale,
                    this.area_sizes_modified,
                    this.flex_height,
                    this.item_min_size,
                    except_index,
                    () => null,
                    (index: number) => index % 2 == 1
                )
                this.area_sizes_modified = rows
            } else {
                scale = this.flex_width / total
                rows = this.scale_sizes(
                    scale,
                    this.area_sizes_modified,
                    this.flex_width,
                    this.item_min_size,
                    except_index,
                    () => null,
                    (index: number) => index % 2 == 1
                )
                this.area_sizes_modified = rows
            }
        },
        on_mousedown(e: MouseEvent) {
            const grabbed_stick = e.target as HTMLElement
            const $grabbed_stick = $(grabbed_stick)
            const stick_index = $grabbed_stick.index()
            console.debug("on mouse down grabbed_stick:", grabbed_stick, "stick index: ", stick_index)
            global_event_bus.$emit("resize-start", this.$refs.flex)
            this.is_resizing = true;
            document.body.classList.add("prevent-select")
            this.grabbed_stick = grabbed_stick
            this.grabbed_stick_index = stick_index
        },
        on_mousemove(e: MouseEvent) {
            if (!this.is_resizing) return;

            const offsets = this.cursor_offset_to_grabbed_stick(e)
            console.debug("on mouse move offsets:", offsets, "vertical: ", this.vertical, "horizontal: ", this.horizontal)
            if (this.vertical) {
                const rows = this.handle_directional_move(
                    this.area_sizes_modified,
                    offsets.y,
                    this.grabbed_stick_index,
                    this.item_min_size,
                    this.flex_height,
                    (_index: number) => {
                        return true
                    },
                    (index: number) => index % 2 == 1
                )
                if (rows != null) this.area_sizes_modified = rows
            }

            if (this.horizontal) {
                const columns = this.handle_directional_move(
                    this.area_sizes_modified,
                    offsets.x,
                    this.grabbed_stick_index,
                    this.item_min_size,
                    this.flex_width,
                    (_index: number) => {
                        return true
                    },
                    (index: number) => index % 2 == 1
                )
                console.debug("on mouse move columns: ", columns)
                if (columns != null) this.area_sizes_modified = columns
            }
            global_event_bus.$emit("resize", this.$refs.flex)
        },
        handle_directional_move(columns_or_rows: number[], offset: number, grabbed_stick_index: number, min_size: number, grid_width_or_height: number,
                                is_resizable_check: (index: number) => boolean,
                                stick_check: (index: number) => boolean) {
            // before / after refers to area before the stick starting from left or top of the screen
            const shrink_before = offset < 0;
            const shrink_after = offset > 0;

            // indexes of columns / rows that will be resized
            // one index can be added multiple times to increase the rate at which expands/shrinks
            const before_resize_indexes: number[] = [];
            const after_resize_indexes: number[] = [];

            // total size of areas that will be resized
            // will be used to prevent shrinking when the area is too small
            let total_before_size = 0
            let total_after_size = 0;

            // calculate total before / after size
            columns_or_rows.forEach((column_or_row_size, index) => {
                if (stick_check(index)) return;
                const is_before_side = index < grabbed_stick_index
                const is_after_side = index > grabbed_stick_index

                if (is_before_side) total_before_size += column_or_row_size
                else if (is_after_side) total_after_size += column_or_row_size
            })

            // add indexes that will resized
            if (shrink_before) {
                columns_or_rows.forEach((column_or_row_size, index) => {
                    // do not resize sticks
                    if (stick_check(index)) return;
                    if (!is_resizable_check(index)) return;

                    const is_before_side = index < grabbed_stick_index
                    const is_after_side = index > grabbed_stick_index

                    if (is_before_side) {
                        // here we are shrinking before, therefore allow only shrink if it is larger than min size
                        if (column_or_row_size > min_size) {
                            before_resize_indexes.push(index)
                        }
                    } else if (is_after_side) {
                        after_resize_indexes.push(index)
                        // if the expanding area is very small, speed up the expand rate
                        if (column_or_row_size < 3 * min_size) after_resize_indexes.push(index)
                    }
                })
            } else if (shrink_after) {
                columns_or_rows.forEach((column_or_row_size, index) => {
                    if (stick_check(index)) return;
                    if (!is_resizable_check(index)) return;

                    const is_before_side = index < grabbed_stick_index
                    const is_after_side = index > grabbed_stick_index

                    if (is_after_side) {
                        // here we are shrinking after, therefore allow only shrink if it is larger than min size
                        if (column_or_row_size > min_size) after_resize_indexes.push(index)
                    } else if (is_before_side) {
                        before_resize_indexes.push(index);
                        // if the expanding area is very small, speed up the expand rate
                        if (column_or_row_size < 3 * min_size) before_resize_indexes.push(index)
                    }
                })
            }

            if (before_resize_indexes.length > 0 && after_resize_indexes.length > 0) {
                const can_shrink_before = (total_before_size - offset) > before_resize_indexes.length * min_size
                const can_shrink_after = (total_after_size - offset) > after_resize_indexes.length * min_size;
                // allow shrink only if the all areas are bigger than min size
                if ((shrink_before && can_shrink_before) || (shrink_after && can_shrink_after)) {
                    // evenly distribute offset to both left and right side
                    // some areas will be smaller than min size --> will be handled later
                    columns_or_rows = columns_or_rows.map((column_or_row_size, index) => {
                        const is_before_side = index < grabbed_stick_index
                        const is_after_side = index > grabbed_stick_index

                        const before_count = before_resize_indexes.filter(bi => bi == index).length
                        const after_count = after_resize_indexes.filter(ai => ai == index).length

                        if (shrink_before) {
                            if (is_before_side && before_count > 0) {
                                return column_or_row_size - (Math.abs(offset / before_resize_indexes.length) * before_count)
                            } else if (is_after_side && after_resize_indexes.includes(index)) {
                                return column_or_row_size + (Math.abs(offset / after_resize_indexes.length) * after_count)
                            } else return column_or_row_size
                        } else if (shrink_after) {
                            if (is_after_side && after_resize_indexes.includes(index)) {
                                return column_or_row_size - (Math.abs(offset / after_resize_indexes.length) * after_count)
                            } else if (is_before_side && before_resize_indexes.includes(index)) {
                                return column_or_row_size + (Math.abs(offset / before_resize_indexes.length) * before_count)
                            } else return column_or_row_size
                        } else return column_or_row_size
                    })


                    // areas that are smaller than min size, set it to min size, and apply the diff to bigger areas
                    if (shrink_before) {
                        let carry_over = 0;
                        for (let index = 0; index < grabbed_stick_index; ++index) {
                            if (stick_check(index)) continue;
                            const column_or_row_size = columns_or_rows[index];

                            if (column_or_row_size < min_size) {
                                // find a column that we can additionally shrink
                                carry_over = min_size - column_or_row_size;
                                let j = 0;
                                while (carry_over > 0 && j < grabbed_stick_index) {
                                    if (stick_check(j)) {
                                        ++j;
                                        continue
                                    }
                                    const column_size = columns_or_rows[j];
                                    if (column_size > min_size) {
                                        const available = column_size - min_size;
                                        if (available >= carry_over) {
                                            columns_or_rows[j] -= carry_over
                                            carry_over = 0;
                                        } else {
                                            columns_or_rows[j] = min_size;
                                            carry_over -= available;
                                        }
                                    }
                                    ++j;
                                }
                                columns_or_rows[index] = min_size;
                            }
                        }
                    } else if (shrink_after) {
                        let carry_over = 0;
                        for (let index = grabbed_stick_index + 1; index < columns_or_rows.length; ++index) {
                            if (stick_check(index)) continue;
                            const column_or_row_size = columns_or_rows[index];

                            if (column_or_row_size < min_size) {
                                // find a column that we can additionally shrink
                                carry_over = min_size - column_or_row_size;
                                let j = grabbed_stick_index + 1;
                                while (carry_over > 0 && j < columns_or_rows.length) {
                                    if (stick_check(j)) {
                                        ++j;
                                        continue
                                    }
                                    const column_or_row_size = columns_or_rows[j];
                                    if (column_or_row_size > min_size) {
                                        const available = column_or_row_size - min_size;
                                        if (available >= carry_over) {
                                            columns_or_rows[j] -= carry_over
                                            carry_over = 0;
                                        } else {
                                            columns_or_rows[j] = min_size;
                                            carry_over -= available;
                                        }
                                    }
                                    ++j;
                                }
                                columns_or_rows[index] = min_size;
                            }
                        }
                    }

                    if (shrink_before || shrink_after) {
                        const total_size = columns_or_rows.reduce((total, current) => total + current)
                        if (total_size != grid_width_or_height) {
                            columns_or_rows[0] += grid_width_or_height - total_size
                        }
                        return columns_or_rows
                    }
                }
            }
            return null;
        },
        on_mouseup(e: MouseEvent) {
            if (this.is_resizing) this.stop_resizing();
            return null;
        },
        on_blur(e: FocusEvent) {
            if (this.is_resizing) this.stop_resizing();
            return null;
        },
        stop_resizing() {
            this.is_resizing = false;
            document.body.classList.remove("prevent-select")
            nextTick(() => global_event_bus.$emit("resize-end", this.$refs.flex))
        },
        cursor_offset_to_grabbed_stick(e: MouseEvent) {
            if (!this.is_resizing) return null
            const rect = this.grabbed_stick.getBoundingClientRect();
            const x = e.clientX - (rect.left + rect.width / 2);
            const y = e.clientY - (rect.top + rect.height / 2);
            return { x, y }
        },
        set_flex_size() {
            const flex = this.$refs.flex as HTMLDivElement;
            if (flex == null) return;

            const rect = flex.getBoundingClientRect()
            if (rect.width == 0 || rect.height == 0) return;
            this.flex_width = rect.width;
            this.flex_height = rect.height;
            this.adjust()
        },
        area_style(area: string, index: number) {
            const style: CSSProperties = {
                backgroundColor: this.background_color
            }
            if (this.horizontal) {
                style.width = this.area_sizes_modified[index]
            } else {
                style.height = this.area_sizes_modified[index]
            }

            return style
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.resizable-flex {
  display: flex;

  .area {
    overflow: auto;
    display: flex;
  }

  .stick {
    background-color: var(--secondary-background-color);
  }

  &.vertical {
    height: 100%;

    .area {
      min-height: 0;
    }

    .stick {
      width: 100%;
      cursor: ns-resize;
    }
  }

  &.horizontal {
    width: 100%;

    .area {
      min-width: 0;
    }

    .stick {
      height: 100%;
      cursor: ew-resize;
    }
  }
}
</style>
