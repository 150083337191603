<template>
  <SettingsItem
      :id="setting_items.project.web.id"
      :title="setting_items.project.web.title"
  >
    <div class="row">
      <div class="col s6">
        <Input
            id="page_load_timeout_input"
            v-model="web_props.pageload_timeout"
            :disabled="is_viewer_role || is_user_role"
            label="Page load timeout (in seconds)"
            :validator="form_validator.register('page_load_timeout', ProjectVersionSetting.field_validators.pageload_timeout)"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s6">
        <Input
            id="watir_default_timeout_input"
            v-model="web_props.watir_default_timeout"
            :disabled="is_viewer_role || is_user_role"
            label="Watir default timeout (in seconds)"
            :validator="form_validator.register('watir_default_timeout', ProjectVersionSetting.field_validators.watir_default_timeout)"
        />
      </div>
    </div>
  </SettingsItem>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import SettingsItem from "../../SettingsItem.vue";
import { ProjectVersionSetting } from "../../../../vue_record/models/project_version_setting";
import Input from "../../../testa/Input.vue";
import { WebUpdateProps } from "../../../../vue_record/models/project_version_setting";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Input,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            web_props: {
                pageload_timeout: this.project_version_setting.props.pageload_timeout,
                watir_default_timeout: this.project_version_setting.props.watir_default_timeout,
            } as WebUpdateProps,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        ProjectVersionSetting() {
            return ProjectVersionSetting
        },
        setting_items() {
            return setting_items
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save() {
            return this.apply()
        },
        apply() {
            if (this.is_viewer_role || this.is_user_role) return null;
            this.project_version_setting.client.update(this.web_props)
        },

    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>

</style>
