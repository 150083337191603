import { ResizableFlex } from "../resizable_flex";
import { computed } from "../../../../../helpers/vue/computed";
import { CSSProperties } from "vue";
import { FlexContentArea } from "./flex_content_area";
import { watch } from "vue";

export interface FlexAreaOpts {
    id: string
    background?: string
}

export class FlexArea {
    // <editor-fold desc="PROPERTIES">
    readonly id: string
    readonly resizable_flex: ResizableFlex
    readonly is_stick: boolean

    container: HTMLElement
    background: string = "var(--primary-background-color)"

    protected width: number
    protected height: number

    // <editor-fold desc="COMPUTED">
    /** percentage of container total width */
    protected width_ratio: number

    /** percentage of container total height */
    protected height_ratio: number

    protected is_locked: boolean
    protected style: CSSProperties
    // </editor-fold>
    // </editor-fold>

    constructor(opts: FlexAreaOpts, is_stick: boolean, resizable_flex: ResizableFlex) {
        this.id = opts.id
        this.is_stick = is_stick
        this.resizable_flex = resizable_flex
        this.width = 0
        this.height = 0
        if (opts.background != null) this.background = opts.background
    }

    init(_opts: FlexAreaOpts) {
        this.width_ratio = computed(() => {
            if (this.resizable_flex.get_width() == 0) return 0
            return this.width / this.resizable_flex.get_width()
        })

        this.height_ratio = computed(() => {
            if (this.resizable_flex.get_height() == 0) return 0
            return this.height / this.resizable_flex.get_height()
        })
    }

    // <editor-fold desc="GETTERS">
    get_width() {
        return this.width
    }

    get_height() {
        return this.height
    }

    get_width_ratio() {
        return this.width_ratio
    }

    get_height_ratio() {
        return this.height_ratio
    }

    get_size() {
        return this.resizable_flex.is_row_direction() ? this.width : this.height
    }

    get_size_ratio() {
        if (this.resizable_flex.is_row_direction()) {
            return this.get_width_ratio()
        } else {
            return this.get_height_ratio()
        }
    }

    get_style() {
        return this.style
    }
    // </editor-fold>
}
