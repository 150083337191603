<template>
  <label ref="label"
         class="vue-label testa-select"
         :class="{'for-table': for_table, 'for-header': for_header, 'for-report-filter': for_report_filter, invalid: validator?.invalid}"
         style="position: relative;"
  >
    <span v-if="label != ''"
          ref="label_text"
          class="label-text">
      {{ label }}
    </span>
    <select
        :id="id"
        ref="select"
        :title="title"
        :disabled="disabled"
        class="vue-select"
        style="width: 100%"
        @change="on_change"
    >
      <slot/>
    </select>
    <ValidationErrors
        v-if="validator?.invalid && show_errors"
        :no_side="errors_no_side"
        :errors="validator.errors"/>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import $ from "jquery";
import ValidationErrors from "./ValidationErrors.vue";
import ClassListWatcher from "../../helpers/class_list_watcher";
import { Validator } from "../../helpers/validator/validator";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { Value } from "../../helpers/validator/validator";
import select2 from "select2";
import { LoadingData } from "select2";
import { IdTextPair } from "select2";
import { Options } from "select2";
import { GroupedDataFormat } from "select2";
import { DataFormat } from "select2";
import { nextTick } from "vue";
import { KeyCode } from "../../types/globals";
import { Consoler } from "../../helpers/api_wrappers/consoler";

// @ts-ignore connect select2 to jquery
select2($)

const console = new Consoler("warn")
export default defineComponent({
    components: { ValidationErrors },
    class_watcher: {} as { [key: string]: ClassListWatcher },
    slot_watcher: {} as { [key: string]: MutationObserver },
    // <editor-fold desc="PROPS">
    props: {
        modelValue: {
            type: [String, Array, Number],
            required: false,
            default: null,
        },
        theme: {
            type: String,
            required: false,
            default: "default"
        },
        label: {
            type: String,
            required: false,
            default: ""
        },
        id: {
            type: String,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        search: {
            type: Boolean,
            required: false,
            default: true
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        // Provides support for clearable selections
        allow_clear: {
            type: Boolean,
            required: false,
            default: false
        },
        // Controls whether the dropdown is closed after a selection is made.
        close_on_select: {
            type: Boolean,
            required: false,
            default: true
        },
        dropdown_auto_width: {
            type: Boolean,
            required: false,
            default: false
        },
        dropdown_parent: {
            type: String,
            required: false,
            default: null
        },
        validator: {
            type: Object as PropType<Validator>,
            required: false,
            default: null
        },
        for_table: {
            type: Boolean,
            required: false,
            default: false
        },
        for_header: {
            type: Boolean,
            required: false,
            default: false
        },
        for_report_filter: {
            type: Boolean,
            required: false,
            default: false
        },
        template_result: {
            type: Function as PropType<(result: LoadingData | GroupedDataFormat | DataFormat) => string | JQuery<HTMLElement>>,
            required: false,
            default: null,
        },
        template_selection: {
            type: Function as PropType<(selection: LoadingData | IdTextPair | DataFormat | GroupedDataFormat, container: JQuery<HTMLElement>) => string | JQuery>,
            required: false,
            default: null,
        },
    },
    // </editor-fold>
    emits: ['update:modelValue', 'is-valid', 'search-enter'],
    // <editor-fold desc="DATA">
    data() {
        return {
            instance_id: generate_uuid(),
            minimumResultsForSearch: this.search ? 5 : Infinity,
            show_errors: false,
            errors_no_side: "bottom",
            preparsed_val: this.modelValue,
            open_before_update: false,
            input_focused_before_update: false,
            input_before_update: "" as string | number | string[],
            scroll_top_before_update: 0
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        opts() {
            let $parent;
            if (this.dropdown_parent != null) {
                $parent = $(this.dropdown_parent)
            } else {
                $parent = null
            }
            let theme = this.theme;
            if (this.for_table) {
                theme = "table"
            }
            if (this.for_header) {
                theme = "header"
            }

            if (this.for_report_filter) {
                theme = "report-filter"
            }
            const opts: Partial<Options> = {
                minimumResultsForSearch: this.minimumResultsForSearch,
                disabled: this.disabled,
                multiple: this.multiple,
                dropdownAutoWidth: this.dropdown_auto_width,
                dropdownParent: $parent,
                placeholder: this.placeholder,
                theme,
                allowClear: this.allow_clear // must be false, and placeholder = null to allow null values in options
            }
            if (this.template_selection != null) {
                opts.templateSelection = this.template_selection
            }
            if (this.template_result != null) {
                opts.templateResult = this.template_result
            }

            return opts
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        modelValue: {
            handler() {
                const $select = $(this.$refs.select as HTMLSelectElement)
                this.preparsed_val = this.modelValue
                // @ts-ignore
                $select.val(this.modelValue)
                $select.trigger("change")
                this.validator?.run(this.modelValue as any);
            },
            immediate: true
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const $select = $(this.$refs.select as HTMLSelectElement)
        $select.select2(this.opts)

        // set initial value of select
        // @ts-ignore
        $select.val(this.modelValue)
        $select.trigger("change")
        if (!this.disabled) {
            const val_in_select = this.parse_val($select.val())
            if (val_in_select != this.modelValue) {
                // this can happen when the modelValue holds a value that is not present in select
                // then value in select will be null, and that value should now be in modelValue
                this.$emit('update:modelValue', val_in_select)
            }
        }

        const $select2 = $select.data("select2")
        // listen for change events, propagate it to given model prop
        $select.on("select2:select", () => {
            this.$emit('update:modelValue', this.parse_val($select.val()))

            if (this.title != null) $select2.$selection.find('.select2-selection__rendered').removeAttr('title');
            // this fixes the issue where the dropdown stay on one part of the screen while the select is on the other
            // when the ui is resized on select/clear
            $select.select2(this.opts)
        })
        $select.on("select2:unselect", () => {
            this.$emit('update:modelValue', this.parse_val($select.val()))
            // this fixes the issue where the dropdown stay on one part of the screen while the select is on the other
            // when the ui is resized on select/clear
            $select.select2(this.opts)
        })
        $select.on("select2:clear", () => {
            this.$emit('update:modelValue', this.parse_val($select.val()))
            // this fixes the issue where the dropdown stay on one part of the screen while the select is on the other
            // when the ui is resized on select/clear
            $select.select2(this.opts)
        })

        if (this.title != null) $select2.$selection.find('.select2-selection__rendered').removeAttr('title');
        $select.on("select2:close", () => {
            console.log("SELECT2 close", $select);
            if (!$select.data("select2").$container.hasClass("select2-container--focus")) {
                if (this.label != "") {
                    const $label = $(this.$refs.label_text as HTMLElement)
                    $label.removeClass("focus")
                }
                this.show_errors = false
            }
        })

        $select.on("select2:open", () => {
            const $select2 = $select.data("select2")
            if (this.label != "") {
                const $label = $(this.$refs.label_text as HTMLElement)
                $label.addClass("focus")
            }
            this.show_errors = true
            if ($select2.$dropdown.find(".select2-dropdown--below").length > 0) {
                this.errors_no_side = "bottom"
            } else {
                this.errors_no_side = "top"
            }



            // tried everything everywhere, but somehow always losses focus to <select>
            setTimeout(() => {
                const $search_field = $select2.$dropdown.find(".select2-search__field")
                $search_field.focus()
                $search_field.addClass("no-default-unfocus")
            }, 100)
        })

        const $input = $select2.$selection.find(".select2-search__field")
        $input.on("keydown", (e) => {
           if ((e.originalEvent as KeyboardEvent).code == KeyCode.ENTER) {
               this.$emit("search-enter", $input.val())
           }
        })


        if (this.label != "") {
            // when select is in focus, change also focus (change color) of label
            const $label = $(this.$refs.label_text as HTMLElement)
            const on_focus = () => {
                this.show_errors = true
                $label.addClass("focus")
            }
            const on_blur = () => {
                if (!$select2.isOpen()) {
                    $label.removeClass("focus")
                    this.show_errors = false
                }
            }
            const select2_container = $select.data("select2").$container[0]
            this.$options.class_watcher[this.instance_id] = new ClassListWatcher(select2_container, "select2-container--focus", on_focus, on_blur)
        }
        this.$options.slot_watcher[this.instance_id] = new MutationObserver((mutations) => {
            // will enter infinite loop if we don't do this return check
            if (mutations.every((m) => m.addedNodes.length == 0 && m.removedNodes.length == 0 && m.type != "characterData")) return


            if (mutations.some((m) => m.type == "characterData")) $select.select2(this.opts)
            if (mutations.some((m) => m.type == "childList" && m.target.nodeName == "OPTION")) {
                let only_text_nodes = true
                mutations.forEach(m => {
                    if (!only_text_nodes) return;
                    for (let i = 0; i < m.addedNodes.length; ++i) {
                        if (!only_text_nodes) return;
                        if (m.addedNodes[i].nodeType != 3) {
                            only_text_nodes = false
                            return;
                        }
                    }
                    for (let i = 0; i < m.removedNodes.length; ++i) {
                        if (!only_text_nodes) return;
                        if (m.removedNodes[i].nodeType != 3) {
                            only_text_nodes = false
                            return;
                        }
                    }
                })
                if (only_text_nodes) $select.select2(this.opts)
            }

            const $select2 = $select.data("select2")
            if ($select2.isOpen()) {
                const filter = $select2.$selection.find(".select2-search__field").val()
                const scrollTop = $select2.$results[0].scrollTop;
                $select.select2('close');
                $select.select2('open');

                const $filter = $select2.$selection.find(".select2-search__field")
                if (filter != "") {
                    $filter.val(filter)
                    $filter.trigger("input")
                }
                $select2.$results[0].scrollTop = scrollTop;
                $filter.focus();
            }
            this.$emit('update:modelValue', this.parse_val($select.val()))
        })
        this.$options.slot_watcher[this.instance_id].observe($select[0], {
            attributes: true,
            attributeFilter: ["disabled", "label", "selected", "value"],
            childList: true,
            characterData: true,
            subtree: true
        })
        this.validator?.bind_element(() => {
            return this.$refs.label as HTMLElement
        })
        this.validator?.bind_value(() => {
            return this.modelValue as Value
        })
    },
    beforeUpdate() {
        try {
            const $select = $(this.$refs.select as HTMLSelectElement)
            if ($select.length > 0) {
                const $select2 = $select.data("select2")
                const $dropdown = $select2.$dropdown
                if ($dropdown.length > 0) {
                    const $search_input = $dropdown.find(".select2-search__field")
                    this.open_before_update = $select2.isOpen()
                    this.input_before_update = $search_input.val()
                    this.scroll_top_before_update = $select2.$results[0].scrollTop;
                    this.input_focused_before_update = $search_input.is(":focus")
                    console.debug("storing state before update:", "open: ", this.open_before_update,
                    "input: ", this.input_before_update, "scroll_top: ", this.scroll_top_before_update,
                    "input_focused: ", this.input_focused_before_update)
                }
            }
        } catch (e) {
            console.error(e)
        }
    },
    updated() {
        const $select = $(this.$refs.select as HTMLSelectElement)
        if (this.open_before_update) {
            nextTick(() => {
                $select.select2('open');
                nextTick(() => {
                    const $select = $(this.$refs.select as HTMLSelectElement)
                    const $select2 = $select.data("select2")
                    const $filter = $select2.$dropdown.find(".select2-search__field")

                    $filter.val(this.input_before_update)
                    $filter.trigger("input")
                    if (this.input_focused_before_update) {
                        $filter[0].focus()
                    }
                    $select2.$results[0].scrollTop = this.scroll_top_before_update;
                })
            })
        }
    },
    unmounted() {
        if (this.label != "") {
            this.$options.class_watcher[this.instance_id].disconnect();
        }
        this.$options.slot_watcher[this.instance_id].disconnect();
        this.validator?.unregister()
        const $select = $(this.$refs.select as HTMLSelectElement)
        if ($select.length > 0) {
            const $select2 = $select.data("select2")
            if ($select2.isOpen()) {
                $select.select2('close');
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        parse_val(val: string | number | string[]): number | string | Array<number | string> {
            this.preparsed_val = val;
            if (val instanceof Array) {
                return val.map(v => this.parse_val(v) as number | string)
            } else if (Validator.is_numeric(val)) {
                return parseInt(val as string)
            } else if (val == "") {
                return null
            } else {
                return val
            }
        },
        on_change(event: Event) {
            this.$emit('update:modelValue', this.parse_val((event.target as HTMLSelectElement).value))
        }

    }
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
label {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.vue-label {
    &.testa-select {
      min-height: 30px;

      span.label-text {
        padding: 0 5px;
        margin-left: 8px;
        margin-bottom: 2px;
        font-size: 10px;
      }

      &.for-table {
        min-height: auto;
      }

      &.for-header {
        min-height: auto;
      }

      &.for-report-filter {
        min-height: auto;
      }
    }
  }
}

label:not(.invalid) {
  span.label-text {
    color: var(--font-color-secondary);

    &.focus {
      color: var(--font-color);
    }
  }
}
</style>

<style lang="scss">
label.invalid {
  span.label-text {
    color: var(--button-red);
  }

  .select2-container--default {
    .select2-selection--multiple,
    .select2-selection--single {
      border-color: var(--button-red);
    }
  }
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  cursor: pointer;

  .select2-selection {
    background-color: var(--ternary-background-color);

    /* Show arrow down icon */
    &::before {
      content: "";
      position: absolute;
      right: 7px;
      top: 42%;
      border-top: 5px solid var(--font-color-secondary);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }

  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    overflow: hidden;
    height: 30px;
    padding-left: 5px;
    padding-top: 3px;
    // background-color: transparent;
    border: 1px solid var(--secondary-background-color);
    border-radius: 5px;

    .select2-selection__placeholder {
      color: #999;
    }

    .select2-selection__rendered {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      float: left;
      color: var(--font-color);
      border-color: var(--secondary-background-color);
      border-radius: 5px;
      padding: 3px;
      font-size: 14px;
      width: calc(100% - 15px); // to not overflow over dropdown arrow
    }

    .select2-selection__clear {
      background-color: transparent;
      border: none;
      font-size: 1em;
      cursor: pointer;
      float: right;
      font-weight: bold;
      height: 26px;
      margin-right: 20px;
      padding-right: 0;
    }
  }

  .select2-selection--multiple {
    box-sizing: border-box;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
    background-color: var(--ternary-background-color);
    border: 1px solid var(--secondary-background-color);
    border-radius: 5px;
    cursor: text;
    padding-bottom: 5px;
    padding-right: 5px;
    position: relative;

    &.select2-selection--clearable {
      padding-right: 25px;
    }

    .select2-selection__rendered {
      display: inline;
      list-style: none;
      padding: 0;
      font-size: 14px;
    }

    .select2-selection__clear {
      background-color: transparent;
      border: none;
      font-size: 1em;
      cursor: pointer;
      font-weight: bold;
      height: 20px;
      margin-right: 10px;
      margin-top: 5px;
      position: absolute;
      right: 0;
      padding: 1px;
    }

    .select2-selection__choice {
      background-color: transparent;
      border: 1px solid var(--secondary-background-color);
      border-radius: 4px;
      box-sizing: border-box;
      display: inline-block;
      margin-left: 5px;
      margin-top: 5px;
      padding: 0 0 0 20px;
      position: relative;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: bottom;
      white-space: nowrap;
      line-height: 1.75em;
    }

    .select2-selection__choice__display {
      cursor: default;
      padding-left: 2px;
      padding-right: 5px;
      vertical-align: text-top;
      display: flex;
    }

    .select2-selection__choice__remove {
      background-color: transparent;
      border: none;
      border-right: 1px solid #aaa;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: #999;
      cursor: pointer;
      font-size: 1em;
      font-weight: bold;
      padding: 0 4px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      &:hover,
      &:focus {
        background-color: var(--button-red);
        color: var(--font-color);
        outline: none;
      }
    }
  }

  .select2-search--inline {
    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      margin-left: 5px;
      padding: 0;
      max-width: 100%;
      resize: none;
      height: 18px;
      vertical-align: bottom;
      font-family: sans-serif;
      overflow: hidden;
      word-break: keep-all;
      color: var(--font-color);
      background: transparent;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: textfield;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }

  .select2-search--dropdown {
    display: block;
    padding: 4px;

    .select2-search__field {
      padding: 2px;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid var(--font-color-secondary);
      border-radius: 5px;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }

    input.select2-search__field[tabindex]:not(:focus-visible):not(:focus) {
      border: 1px solid var(--button-grey);
    }

    &.select2-search--hide {
      display: none;
    }
  }

  .select2-results {
    display: block;

    & > .select2-results__options {
      float: left;
      padding-left: 5px;
      padding-top: 2px;
      padding-bottom: 4px;
      max-height: 200px;
      width: calc(100% - 7px);
      text-align: left;
      overflow-y: auto;
    }
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  .select2-results__option {
    overflow-y: hidden;
    /*  height: 12px;*/
    min-height: 1.2em;
    width: 100%;
    font-size: 14px;
    float: left;
    user-select: none;
    -webkit-user-select: none;

    .select2-results__option {
      padding-left: 1em;

      .select2-results__option {
        .select2-results__group {
          padding-left: 0;
        }

        .select2-results__option {
          margin-left: -1em;
          padding-left: 2em;

          .select2-results__option {
            margin-left: -2em;
            padding-left: 3em;

            .select2-results__option {
              margin-left: -3em;
              padding-left: 4em;

              .select2-results__option {
                margin-left: -4em;
                padding-left: 5em;

                .select2-results__option {
                  margin-left: -5em;
                  padding-left: 6em;
                }
              }
            }
          }
        }
      }
    }
  }

  .select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .select2-results__option--disabled {
    color: #999;
  }

  .select2-results__option--highlighted {
    &.select2-results__option--selectable {
      color: var(--font-color-hover);
      filter: brightness(1.2);
      cursor: pointer;
      background-color: var(--confirm-color);
    }
  }

  .select2-results__option--selectable {
    /* changed  */
    cursor: default;
  }

  .select2-results__option--selected {
    color: white;
    // background-color: var(--confirm-color);
    background-color: var(--ternary-background-color);
  }

  .select2-results__option--group {
    padding: 0;

    .select2-results__options--nested {
      overflow-x: hidden;

      li {
        overflow-x: hidden;
      }
    }
  }

  .select2-dropdown {
    background-color: var(--ternary-background-color);
    border: 1px solid var(--font-color);
    border-radius: 5px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    //z-index: 999999;
    z-index: 1051;

    // to negate the border
    // margin-top: -1px;
  }

  &.select2-container--open {
    // without this, the dropdown would flicker over codemirror
    z-index: inherit;

    .select2-dropdown {
      left: 0;
    }

    .select2-dropdown--above {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .select2-dropdown--below {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.select2-container--above {
      .select2-selection--single,
      .select2-selection--multiple {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.select2-container--below {
      .select2-selection--single,
      .select2-selection--multiple {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .select2-selection--single {
      border: solid var(--font-color) 1px;
      outline: 0;

      &::before {
        content: "";
        position: absolute;
        right: 7px;
        top: 42%;
        border-top: 5px solid var(--font-color);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }
  }

  &.select2-container--disabled {
    .select2-selection--single {
      filter: opacity(0.5);
      color: var(--font-color-secondary);
      cursor: default;

      .select2-selection__clear {
        display: none;
      }
    }

    .select2-selection--multiple {
      background-color: #eee;
      cursor: default;
    }

    .select2-selection__choice__remove {
      display: none;
    }
  }

  &.select2-container--focus {
    .select2-selection--single,
    .select2-selection--multiple {
      border: solid var(--focus-color) 1px;
      outline: 0;

      &::before {
        border-top: 5px solid var(--font-color-secondary);
      }
    }
  }

  &:not(.select2-container--disabled):hover {
    filter: brightness(1.1);
  }
}


.select2-container--table {
  $scale-factor: 0.75;
  $font-scale-factor: 0.85;

  .select2-selection {
    /* Show arrow down icon */
    &::before {
      right: calc(7px * $scale-factor);
      border-top-width: calc(5px * $scale-factor);
      border-left-width: calc(4px * $scale-factor);
      border-right-width: calc(4px * $scale-factor);
    }
  }

  .select2-selection--single {
    height: calc(30px * $scale-factor);
    padding-left: calc(5px * $scale-factor);
    padding-top: calc(3px * $scale-factor);

    .select2-selection__rendered {
      padding: calc(3px * $scale-factor);
      font-size: calc(14px * $font-scale-factor);
      width: calc(100% - calc(15px * $scale-factor));
    }

    .select2-selection__clear {
      height: calc(26px * $scale-factor);
      margin-right: calc(20px * $scale-factor);
    }

    .select2-search__field {
      font-size: calc(16px * $font-scale-factor) !important;
      margin-bottom: calc(8px * $scale-factor) !important;
    }
  }

  .select2-selection--multiple {
    min-height: calc(32px * $scale-factor);
    padding-bottom: calc(5px * $scale-factor);
    padding-right: calc(5px * $scale-factor);

    .select2-selection__rendered {
      font-size: calc(14px * $font-scale-factor);
    }

    &.select2-selection--clearable {
      padding-right: calc(25px * $scale-factor);
    }

    .select2-selection__clear {
      height: calc(20px * $scale-factor);
      margin-right: calc(10px * $scale-factor);
      margin-top: calc(5px * $scale-factor);
      padding: calc(1px * $scale-factor);
    }

    .select2-selection__choice {
      margin-left: calc(5px * $scale-factor);
      margin-top: calc(5px * $scale-factor);
      padding: 0 0 0 calc(20px * $scale-factor);
    }

    .select2-selection__choice__display {
      padding-left: calc(2px * $scale-factor);
      padding-right: calc(5px * $scale-factor);
    }

    .select2-selection__choice__remove {
      padding: 0 calc(4px * $scale-factor);
    }

    .select2-search__field {
      font-size: calc(12px * $font-scale-factor) !important;
      margin-bottom: calc(4px * $scale-factor) !important;
    }
  }


  .select2-search--inline {
    .select2-search__field {
      margin-top: calc(5px * $scale-factor);
      margin-left: calc(5px * $scale-factor);
      height: calc(18px * $scale-factor);
    }
  }

  .select2-search--dropdown {
    padding: calc(4px * $scale-factor);

    .select2-search__field {
      padding: calc(4px * $scale-factor);
    }
  }

  .select2-results {
    & > .select2-results__options {
      padding-left: calc(5px * $scale-factor);
      padding-top: calc(2px * $scale-factor);
      padding-bottom: calc(4px * $scale-factor);
      max-height: calc(200px * $scale-factor);
      width: calc(100% - calc(7px * $scale-factor));
    }
  }

  .select2-results__group {
    padding: calc(6px * $scale-factor);
  }

  .select2-results__option {
    font-size: calc(14px * $font-scale-factor);
  }

  &.select2-container--open {
    .select2-selection--single {

      &::before {
        right: calc(7px * $scale-factor);
        border-top-width: calc(5px * $scale-factor);
        border-left-width: calc(4px * $scale-factor);
        border-right-width: calc(4px * $scale-factor);
      }
    }
  }

  &.select2-container--focus {
    .select2-selection--single,
    .select2-selection--multiple {

      &::before {
        border-top-width: calc(5px * $scale-factor);
      }
    }
  }
}

.select2-container--header {
  $scale-factor: 0.86;
  $font-scale-factor: 0.86;

  .select2-selection {
    /* Show arrow down icon */
    &::before {
      right: calc(7px * $scale-factor);
      border-top-width: calc(5px * $scale-factor);
      border-left-width: calc(4px * $scale-factor);
      border-right-width: calc(4px * $scale-factor);
    }
  }

  .select2-selection--single {
    background-color: var(--secondary-background-color);

    height: calc(30px * $scale-factor);
    padding-left: calc(5px * $scale-factor);
    padding-top: calc(3px * $scale-factor);

    .select2-selection__rendered {
      padding: calc(3px * $scale-factor);
      font-size: calc(14px * $font-scale-factor);
      width: calc(100% - calc(15px * $scale-factor));
    }

    .select2-selection__clear {
      height: calc(26px * $scale-factor);
      margin-right: calc(20px * $scale-factor);
    }

    .select2-search__field {
      font-size: calc(16px * $font-scale-factor) !important;
      margin-bottom: calc(8px * $scale-factor) !important;
    }
  }

  .select2-selection--multiple {
    background-color: var(--secondary-background-color);

    min-height: calc(32px * $scale-factor);
    padding-bottom: calc(5px * $scale-factor);
    padding-right: calc(5px * $scale-factor);

    .select2-selection__rendered {
      font-size: calc(14px * $font-scale-factor);
    }

    &.select2-selection--clearable {
      padding-right: calc(25px * $scale-factor);
    }

    .select2-selection__clear {
      height: calc(20px * $scale-factor);
      margin-right: calc(10px * $scale-factor);
      margin-top: calc(5px * $scale-factor);
      padding: calc(1px * $scale-factor);
    }

    .select2-selection__choice {
      margin-left: calc(5px * $scale-factor);
      margin-top: calc(5px * $scale-factor);
      padding: 0 0 0 calc(20px * $scale-factor);
    }

    .select2-selection__choice__display {
      padding-left: calc(2px * $scale-factor);
      padding-right: calc(5px * $scale-factor);
    }

    .select2-selection__choice__remove {
      padding: 0 calc(4px * $scale-factor);
    }

    .select2-search__field {
      font-size: calc(12px * $font-scale-factor) !important;
      margin-bottom: calc(4px * $scale-factor) !important;
    }
  }


  .select2-search--inline {
    .select2-search__field {
      margin-top: calc(5px * $scale-factor);
      margin-left: calc(5px * $scale-factor);
      height: calc(18px * $scale-factor);
    }
  }

  .select2-search--dropdown {
    padding: calc(4px * $scale-factor);

    .select2-search__field {
      padding: calc(4px * $scale-factor);
    }
  }

  .select2-results {
    & > .select2-results__options {
      padding-left: calc(5px * $scale-factor);
      padding-top: calc(2px * $scale-factor);
      padding-bottom: calc(4px * $scale-factor);
      max-height: calc(200px * $scale-factor);
      width: calc(100% - calc(7px * $scale-factor));
    }
  }

  .select2-results__group {
    padding: calc(6px * $scale-factor);
  }

  .select2-results__option {
    font-size: calc(14px * $font-scale-factor);
  }

  &.select2-container--open {
    .select2-selection--single {

      &::before {
        right: calc(7px * $scale-factor);
        border-top-width: calc(5px * $scale-factor);
        border-left-width: calc(4px * $scale-factor);
        border-right-width: calc(4px * $scale-factor);
      }
    }
  }

  &.select2-container--focus {
    .select2-selection--single,
    .select2-selection--multiple {

      &::before {
        border-top-width: calc(5px * $scale-factor);
      }
    }
  }
}

.select2-container--report-filter {
  $scale-factor: 0.75;
  $font-scale-factor: 0.9;

  .select2-selection {
    /* Show arrow down icon */
    &::before {
      right: calc(7px * $scale-factor);
      border-top-width: calc(5px * $scale-factor);
      border-left-width: calc(4px * $scale-factor);
      border-right-width: calc(4px * $scale-factor);
    }
  }

  .select2-selection--single {
    height: calc(30px * $scale-factor);
    padding-left: calc(5px * $scale-factor);
    padding-top: calc(3px * $scale-factor);

    .select2-selection__rendered {
      padding: calc(3px * $scale-factor);
      font-size: calc(14px * $font-scale-factor);
      width: calc(100% - calc(15px * $scale-factor));
    }

    .select2-selection__clear {
      height: calc(26px * $scale-factor);
      margin-right: calc(20px * $scale-factor);
    }

    .select2-search__field {
      font-size: calc(16px * $font-scale-factor) !important;
      margin-bottom: calc(8px * $scale-factor) !important;
    }
  }

  .select2-selection--multiple {
    min-height: calc(32px * $scale-factor);
    padding-bottom: calc(5px * $scale-factor);
    padding-right: calc(5px * $scale-factor);

    .select2-selection__rendered {
      font-size: calc(14px * $font-scale-factor);
    }

    &.select2-selection--clearable {
      padding-right: calc(25px * $scale-factor);
    }

    .select2-selection__clear {
      height: calc(20px * $scale-factor);
      margin-right: calc(10px * $scale-factor);
      margin-top: calc(5px * $scale-factor);
      padding: calc(1px * $scale-factor);
    }

    .select2-selection__choice {
      margin-left: calc(5px * $scale-factor);
      margin-top: calc(5px * $scale-factor);
      padding: 0 0 0 calc(20px * $scale-factor);
    }

    .select2-selection__choice__display {
      padding-left: calc(2px * $scale-factor);
      padding-right: calc(5px * $scale-factor);
    }

    .select2-selection__choice__remove {
      padding: 0 calc(4px * $scale-factor);
    }

    .select2-search__field {
      font-size: calc(12px * $font-scale-factor) !important;
      margin-bottom: calc(4px * $scale-factor) !important;
    }
  }


  .select2-search--inline {
    .select2-search__field {
      margin-top: calc(5px * $scale-factor);
      margin-left: calc(5px * $scale-factor);
      height: calc(18px * $scale-factor);
    }
  }

  .select2-search--dropdown {
    padding: calc(4px * $scale-factor);

    .select2-search__field {
      padding: calc(4px * $scale-factor);
    }
  }

  .select2-results {
    & > .select2-results__options {
      padding-left: calc(5px * $scale-factor);
      padding-top: calc(2px * $scale-factor);
      padding-bottom: calc(4px * $scale-factor);
      max-height: calc(200px * $scale-factor);
      width: calc(100% - calc(7px * $scale-factor));
    }
  }

  .select2-results__group {
    padding: calc(6px * $scale-factor);
  }

  .select2-results__option {
    font-size: calc(14px * $font-scale-factor);
  }

  &.select2-container--open {
    .select2-selection--single {

      &::before {
        right: calc(7px * $scale-factor);
        border-top-width: calc(5px * $scale-factor);
        border-left-width: calc(4px * $scale-factor);
        border-right-width: calc(4px * $scale-factor);
      }
    }
  }

  &.select2-container--focus {
    .select2-selection--single,
    .select2-selection--multiple {

      &::before {
        border-top-width: calc(5px * $scale-factor);
      }
    }
  }
}


.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
</style>
