import { DirectiveBinding } from "vue";
import { pretty_duration } from "../../helpers/pretty/pretty_duration";
import { what_is_it } from "../../helpers/generic/what_is_it";

function get_update_frequency(since: number): number {
    const current = new Date().valueOf()
    const diff = Math.abs(current - since);
    if (diff < 3000) {
        // if less than 3 second, update every 100ms
        return 100
    } else if (diff < 3600000) {
        // if less than 1 hour, update every second because up until 1 hour, we show minutes and seconds
        return 1000
    } else if (diff < 86400000) {
        // if less than 1 day, update every minute, because up until 1 day we show hours and minutes
        return 60000
    } else {
        // else update every hour
        return 3600000
    }
}

function ensure_correct_type(binding: DirectiveBinding<Date>) {
    if (what_is_it(binding.value) == "String") {
        binding.value = new Date(binding.value)
    }
}

export var v_timeout = {
    mounted(el: any, binding: DirectiveBinding<Date>) {
        if (binding.value == null) return;
        ensure_correct_type(binding)

        const end = binding.value.getTime()
        const start = new Date().getTime();

        el.innerText = get_inner_text(end, start)
        install_interval(el, end)
    },
    updated(el: any, binding: DirectiveBinding<Date>, _vnode: any, _prevNode: any) {
        if (el.duration_interval) clearInterval(el.duration_interval)

        if (binding.value == null) return;
        ensure_correct_type(binding)

        const end = binding.value.getTime()
        const start = new Date().getTime();
        el.innerText = get_inner_text(end, start)
        install_interval(el, end)
    },
    unmounted(el: any, _binding: any) {
        if (el.duration_interval) clearInterval(el.duration_interval)
    }
}

function get_inner_text(end: number, start: number) {
    const diff = end - start
    return pretty_duration(Math.max(diff, 0), { round: "ceil", lowest_unit: "second" })
}

function install_interval(el: any, end: number) {
    el.update_frequency = get_update_frequency(end)
    el.duration_interval = setInterval(() => {
        const start = new Date().getTime();
        el.innerText = get_inner_text(end, start)
        if (el.update_frequency != get_update_frequency(end)) {
            clearInterval(el.duration_interval)
            install_interval(el, end)
        }
    }, el.update_frequency)
}
