import CodeMirror from "codemirror";
import { ch_of_code_start } from "./helpers/ch_of_code_start";
import { get_indentation_spaces } from "./helpers/get_indentation_spaces";
import { get_indentation_from_spaces } from "./helpers/get_indentation_from_spaces";
import { Consoler } from "../api_wrappers/consoler";

export const chained_method_align_origin = "chained_method_align"

const console = new Consoler("warn")
export function align_chained_methods_on_change(cm: CodeMirror.Editor) {
    cm.on("change", (cm, change) => {
        console.log(change);
        if (change.from.line == change.to.line &&
            change.origin != chained_method_align_origin &&
            change.origin == "+input") {

            // <editor-fold desc="ALIGN ON NEW LINE BETWEEN CHAINED METHODS">
            if (change.text.length == 2 &&
                change.text[0] == '' &&
                change.text[1] == '') {
                const spaces = get_indentation_spaces(cm, change.to.line + 1)
                const indentation = get_indentation_from_spaces(cm, spaces)
                apply_indentation(cm, indentation, change.to.line + 1)
                return;
            }
            // </editor-fold>

            // <editor-fold desc="ALIGN ON DOT">
            const line = cm.getLine(change.to.line);
            let only_indentation = true;
            for (let i = 0; i < change.to.ch - 1; ++i) {
                const ch = line[i]
                if (ch != " " && ch != "\t") {
                    only_indentation = false;
                    break;
                }
            }
            if (change.text.length == 1 &&
                change.text[0] == "." &&
                only_indentation) {
                const spaces = get_indentation_spaces(cm, change.to.line)
                const indentation = get_indentation_from_spaces(cm, spaces)
                apply_indentation(cm, indentation, change.to.line)
                // eslint-disable-next-line no-useless-return
                return;
            }
            // </editor-fold>
        }
    })
}

export function apply_indentation(cm: CodeMirror.Editor, indentation: string, line: number) {
    const to_align_ch = ch_of_code_start(cm.getLine(line))

    const from = { line, ch: 0 }
    const to = { line, ch: to_align_ch }

    cm.replaceRange(indentation, from, to, chained_method_align_origin)
}
