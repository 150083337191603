import { RubocopOffense } from "./offense";
import { get_css_var } from "../../generic/get_css_var";
import { Snippet } from "../../../vue_record/models/snippet";
import ConfirmDialogue from "../../../components/testa/confirm_dialogue/confirm_dialgue";
import { jump_to_line } from "../helpers/jump_to_line";
import { cm_set_value } from "../cm_set_value";


export function init_offense_contextmenu(left: number, top: number, line_offenses: RubocopOffense[], cm: CodeMirror.Editor, snippet_id: number) {
    // @ts-ignore
    cm.display.input.blur();
    let focus_to_cm: boolean;
    $.contextMenu({
        selector: ".offense-gutter:visible",
        zIndex: 10,
        selectableSubMenu: true,
        position: function(opt: ContextMenu.Options) {
            opt.$menu.css("width", "");
            const context_width = opt.$menu.width() + 6
            const window_overflow = (left + context_width) - $(window).width()
            if (window_overflow > 0) {
                left = left - window_overflow;
            }
            opt.$menu.css({ top, left });
        },
        events: {
            show: function(options) {
                // @ts-ignore
                cm.display.input.blur();
                options.$menu.addClass("offense-context-menu")
                setTimeout(function() {
                    options.$menu.trigger('nextcommand');
                }, 100)
                focus_to_cm = true;
            },
            hide: function() {
                if (focus_to_cm) cm.focus();
            }
        },
        build: function() {


            return {
                callback: function() {
                },
                items: build_offense_contextmenu_items(line_offenses, cm, snippet_id, focus_to_cm),
            };
        },
    });
}

export function build_offense_contextmenu_items(line_offenses: RubocopOffense[], cm: CodeMirror.Editor, snippet_id: number, focus_to_cm: boolean) {
    const menuItems: ContextMenu.Items = {};
    const can_edit = current_role != Enum.User.Role.VIEWER && current_role != Enum.User.Role.USER
    line_offenses.forEach(offense => {
        if (can_edit) {
            menuItems[offense.cop_name] = {
                icon: offense.correctable ? "fas fa-hammer" : "",
                name: offense.error_message,
                color: get_css_var("--button-green"),
                offense,
                items: {}
            }
            if (offense.correctable) {
                menuItems[offense.cop_name].items[offense.cop_name] = {
                    name: "Fix offense",
                    icon: "fas fa-hammer",
                    color: get_css_var("--button-green"),
                    callback: function() {
                        Snippet.find(snippet_id)
                               .client
                               .rubocop_autocorrect(cm.getValue(), offense.cop_name)
                               .then((code) => {
                                   cm_set_value(cm, code, "autocorrect")
                               })
                    }
                }
            }
            menuItems[offense.cop_name].items.open = {
                name: "Edit rule",
                icon: "fas fa-pencil-alt",
                color: get_css_var("--button-blue"),
                callback: function() {
                    const on_config_shown = () => {
                        const handler = (e: CustomEvent) => {
                            const cm = e.detail.codemirror
                            const cur = cm.getSearchCursor(offense.cop_name);
                            if (cur.findNext()) {
                                cm.getDoc().setSelection({ line: cur.from().line, ch: cur.from().ch }, {
                                    line: cur.to().line,
                                    ch: cur.to().ch
                                });
                                jump_to_line(cm, cur.from().line);
                            } else {
                                current.project.client.rubocop_cop_yml(offense.cop_name)
                                       .then(yml => {
                                           const doc = cm.getDoc()
                                           const last_line = doc.lastLine()
                                           const last_position = { line: last_line, ch: last_line.length }
                                           cm.replaceRange(`\n${yml}`, last_position, last_position)
                                           jump_to_line(cm, last_line + 1)
                                       })
                                console.error(`Could not find cop ${offense.cop_name} in config file`)
                            }
                            // @ts-ignore
                            document.removeEventListener("rubocop-config-shown", handler)
                        }
                        return handler
                    }
                    // @ts-ignore
                    document.addEventListener("rubocop-config-shown", on_config_shown())
                    show_settings(setting_items.project.code_lint.id)
                }
            }
            menuItems[offense.cop_name].items.disable = {
                name: "Disable rule",
                icon: "fas fa-ban",
                color: get_css_var("--button-red"),
                callback: function() {
                    focus_to_cm = false;
                    ConfirmDialogue.show({
                        html: `You are about to disable <strong>${offense.cop_name}</strong>!`,
                        show_cancel: true,
                        confirm_action: () => {
                            return current.project.client.disable_rubocop_rule(offense.cop_name)
                        }
                    }).then(() => {

                    })
                }
            }
        } else {
            menuItems[offense.cop_name] = {
                name: offense.error_message,
                key: offense.correctable ? "fix" : "",
                callback: function() {
                    Snippet.find(snippet_id)
                           .client
                           .rubocop_autocorrect(cm.getValue(), offense.cop_name)
                           .then(code => cm_set_value(cm, code, "autocorrect"))
                }
            }
        }
    })
    return menuItems
}
