import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63fcd064"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "row",
  style: {"display":"flex"}
}
const _hoisted_2 = {
  class: "col s8 inline-flex",
  style: {"display":"flex"}
}
const _hoisted_3 = { class: "col s4" }
const _hoisted_4 = {
  class: "row",
  style: {"min-height":"30px"}
}
const _hoisted_5 = ["data-priority", "onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["title", "onClick"]
const _hoisted_8 = ["title"]
const _hoisted_9 = ["title", "onClick"]
const _hoisted_10 = {
  key: 0,
  class: "fas fa-sync-alt fa fa-spin"
}
const _hoisted_11 = {
  key: 1,
  class: "fas fa-download"
}
const _hoisted_12 = ["title", "innerHTML"]
const _hoisted_13 = { key: 2 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_Input = _resolveComponent("Input")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Table = _resolveComponent("Table")
  const _component_Loading = _resolveComponent("Loading")
  const _component_AddBrowser = _resolveComponent("AddBrowser")
  const _component_SettingsItem = _resolveComponent("SettingsItem")
  const _component_BrowserEdit = _resolveComponent("BrowserEdit")
  const _directive_moment = _resolveDirective("moment")

  return (_ctx.show_table)
    ? (_openBlock(), _createBlock(_component_SettingsItem, {
        key: 0,
        id: _ctx.items.superadmin.browsers.id,
        title: _ctx.items.superadmin.browsers.title
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Button, {
                id: "add_browser_button",
                text: "Add Browser",
                click_action: _ctx.add_browser
              }, null, 8 /* PROPS */, ["click_action"]),
              _createVNode(_component_Button, {
                id: "add_driver_button",
                text: "Add Driver",
                onClick: _ctx.add_driver
              }, null, 8 /* PROPS */, ["onClick"]),
              (_ctx.deletion_markers.marked().length > 0)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    id: "delete_browsers_button",
                    text: "Delete",
                    color_class: "red",
                    onClick: _ctx.delete_browsers
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Input, {
                id: "browsers_filter",
                modelValue: _ctx.filter,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.filter) = $event)),
                placeholder: "Filter",
                throttle_time: 200
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              ref: "job_status_container",
              class: _normalizeClass(["col s12 job-status", {green: !_ctx.status_message_is_error, red: _ctx.status_message_is_error}])
            }, _toDisplayString(_ctx.status_message), 3 /* TEXT, CLASS */)
          ]),
          (_ctx.loaded)
            ? (_openBlock(), _createBlock(_component_Table, {
                key: 0,
                id: "browsers_table"
              }, {
                thead: _withCtx(() => [
                  _createElementVNode("tr", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_columns, (col, key) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                        (key == 'delete')
                          ? (_openBlock(), _createElementBlock("th", {
                              key: 0,
                              class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                              onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.all_marked = !_ctx.all_marked), ["stop"]))
                            }, [
                              _createVNode(_component_Checkbox, {
                                modelValue: _ctx.all_marked,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.all_marked) = $event)),
                                label: "",
                                for_table: true,
                                color_class: "red"
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ], 2 /* CLASS */))
                          : (_openBlock(), _createElementBlock("th", {
                              key: 1,
                              class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                              "data-priority": _ctx.orders.priority(key),
                              onClick: $event => (_ctx.orders.toggle(key))
                            }, _toDisplayString(col.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_5))
                      ], 64 /* STABLE_FRAGMENT */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                tbody: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordered_rows, (row) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: row.record.props.id,
                      class: "browser-row",
                      onClick: $event => (_ctx.show_browser(row.record))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cols, (col, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (col.column_key == 'delete')
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 0,
                                title: col.title,
                                class: _normalizeClass(col.classes),
                                onClick: _withModifiers($event => (_ctx.deletion_markers.marker(row.record).toggle()), ["stop"])
                              }, [
                                _createVNode(_component_Checkbox, {
                                  modelValue: _ctx.deletion_markers.marker(row.record).value,
                                  "onUpdate:modelValue": $event => ((_ctx.deletion_markers.marker(row.record).value) = $event),
                                  label: "",
                                  for_table: true,
                                  color_class: "red"
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                              ], 10 /* CLASS, PROPS */, _hoisted_7))
                            : (col.column_key == 'active')
                              ? (_openBlock(), _createElementBlock("td", {
                                  key: 1,
                                  title: col.title,
                                  class: _normalizeClass(col.classes)
                                }, [
                                  _createVNode(_component_Checkbox, {
                                    id: `browser_active_checkbox_${row.record.props.id}`,
                                    modelValue: row.record.props.active,
                                    "onUpdate:modelValue": $event => ((row.record.props.active) = $event),
                                    label: "",
                                    disabled: true,
                                    for_table: true
                                  }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue"])
                                ], 10 /* CLASS, PROPS */, _hoisted_8))
                              : (col.column_key == 'download')
                                ? (_openBlock(), _createElementBlock("td", {
                                    key: 2,
                                    title: col.title,
                                    class: _normalizeClass(col.classes),
                                    onClick: _withModifiers($event => (_ctx.download_browser(row.record)), ["stop"])
                                  }, [
                                    (_ctx.loading_markers.marker(row.record).value)
                                      ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                                      : (_openBlock(), _createElementBlock("i", _hoisted_11))
                                  ], 10 /* CLASS, PROPS */, _hoisted_9))
                                : _withDirectives((_openBlock(), _createElementBlock("td", {
                                    key: 3,
                                    title: col.title,
                                    class: _normalizeClass(col.classes),
                                    innerHTML: col.html
                                  }, null, 10 /* CLASS, PROPS */, _hoisted_12)), [
                                    [_directive_moment, col.moment]
                                  ])
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ], 8 /* PROPS */, _hoisted_6))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createBlock(_component_Loading, {
                key: 1,
                type: "rotating_plane",
                inflate: true,
                size: 5
              })),
          (_ctx.add_browser_promise_resolve != null && _ctx.add_browser_promise_reject != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_component_AddBrowser, {
                  browser_drivers: _ctx.browser_drivers,
                  jobberman_id: _ctx.add_browser_jobberman_id,
                  onCancel: _cache[3] || (_cache[3] = $event => (_ctx.add_browser_promise_reject()))
                }, null, 8 /* PROPS */, ["browser_drivers", "jobberman_id"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["id", "title"]))
    : (_openBlock(), _createBlock(_component_BrowserEdit, {
        key: 1,
        browser: _ctx.edit_browser,
        browser_drivers: _ctx.browser_drivers,
        loading_marker: _ctx.loading_markers.marker(_ctx.edit_browser),
        form_validator: _ctx.form_validator.register_child_form('browser_edit'),
        onSettingItemMounted: _cache[4] || (_cache[4] = (component) => _ctx.$emit('setting-item-mounted', component)),
        onSettingItemUnmounted: _cache[5] || (_cache[5] = (component) => _ctx.$emit('setting-item-unmounted', component)),
        onExit: _cache[6] || (_cache[6] = $event => (_ctx.show_table=true))
      }, null, 8 /* PROPS */, ["browser", "browser_drivers", "loading_marker", "form_validator"]))
}