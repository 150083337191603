import { Snippet } from "../../vue_record/models/snippet";
import { File } from "../../vue_record/models/file";

export function track_not_committed(cm: CodeMirror.Editor, snippet: Snippet) {
    cm.on("changes", () => {
        snippet.state.code_not_committed = cm.getValue();
        try {
            snippet.state.history_not_committed = JSON.stringify(cm.getDoc().getHistory());
        } catch (e) {
            console.error(e);
            snippet.state.history_not_committed = null;
        }
    })
}

export function track_not_committed_file(cm: CodeMirror.Editor, file: File) {
    cm.on("changes", () => {
        file.state.content_not_committed = cm.getValue();
    })
}
