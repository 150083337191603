import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c576aa0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: "play-type-icon-container",
    title: _ctx.title
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.icon_class),
      style: _normalizeStyle(_ctx.style)
    }, null, 6 /* CLASS, STYLE */)
  ], 8 /* PROPS */, _hoisted_1))
}