<template>
  <div :id="`${id}_container`"
       class="vue-settings-item-container"
       :style="{padding}"
  >
    <div class="vue-settings-item-header">
      <div
          :id="`settings_title_${id}`"
          class="vue-settings-item-title">
        {{ title }}
      </div>
    </div>

    <div class="vue-settings-item-body">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        padding: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {}
    },
    computed: {},
})
</script>

<style lang="scss" scoped>
.vue-settings-item-container {
  height: inherit;
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  //position: relative;

  .vue-settings-item-header {
    height: 40px;
    display: flex;
    padding-left: 60px;
    padding-right: 60px;

    .vue-settings-item-title {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
  }

  .vue-settings-item-body {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
  }
}
</style>
<style lang="scss">
.vue-settings-item-container {
  .vue-settings-item-body {
    .row {
      width: 100%;
    }
  }
}
</style>
