import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { PhoneApp } from "../../models/scenario_setting/phone_app";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PhoneAppClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PhoneApp
    declare record: PhoneApp
}
