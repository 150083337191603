import CodeMirror from "codemirror";
import { get_indentation_spaces } from "./helpers/get_indentation_spaces";
import { get_indentation_from_spaces } from "./helpers/get_indentation_from_spaces";
import { get_state } from "./helpers/get_state";
import { ch_of_code_start } from "./helpers/ch_of_code_start";
import { Consoler } from "../api_wrappers/consoler";

export const auto_add_end_origin = "auto_add_end"

const console = new Consoler("warn")

export function auto_add_end(cm: CodeMirror.Editor) {
    cm.on("change", (cm, change) => {
        console.log(change);
        if (change.from.line == change.to.line &&
            change.origin != auto_add_end_origin &&
            change.origin == "+input") {
            if (change.text.length == 2 &&
                change.text[0] == '' &&
                change.text[1] == '') {
                const current_state = get_state(cm, change.from)
                const next_state = get_state(cm, {
                    line: change.from.line + 1,
                    ch: cm.getLine(change.from.line + 1).length
                })
                if (!current_state.continuedLine && !next_state.continuedLine) {
                    if (current_state.indented < next_state.indented) {
                        const last_line = cm.getLine(cm.lastLine())
                        const last_token_state = get_state(cm, { line: cm.lastLine(), ch: last_line.length })
                        if (last_token_state.context.type == "keyword") {
                            const spaces = get_indentation_spaces(cm, change.from.line)
                            const indentation = get_indentation_from_spaces(cm, spaces)
                            const next_line = cm.getLine(change.from.line + 1)
                            cm.replaceRange(`${next_line}\n${indentation}end`, {
                                line: change.from.line + 1,
                                ch: 0
                            }, { line: change.from.line + 1, ch: next_line.length }, auto_add_end_origin)
                            cm.setCursor({ line: change.from.line + 1, ch: ch_of_code_start(next_line) })
                        }
                    }
                }
            }
        }
    })
}
