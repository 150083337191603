import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import _ from "lodash";
import { RubocopOffense } from "../../helpers/code_lint/offense";
import { SyntaxError } from "../../helpers/codemirror/code_lint/syntax_error";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Editor } from "../../components/testa/editor/editor";
import { generate_eid } from "../../helpers/generate/generate_eid";
import { Snippet } from "../models/snippet";
import { SnippetProps } from "../models/snippet";
import { SnippetCreateProps } from "../models/snippet";
import { SnippetUpdateProps } from "../models/snippet";
import { SnippetSavepointScope } from "../scopes/snippet_savepoint_scope";
import { SnippetSavepointProps } from "../models/snippet_savepoint";
import { SnippetSavepoint } from "../models/snippet_savepoint";
import { SnippetFolderProps } from "../models/snippet_folder";
import { SnippetFolder } from "../models/snippet_folder";
import { CacheStoreValue } from "../base/vue_record_client";
import { CodeSearchResult } from "../../types/components/testa/editor/editors/snippet/code_search_result";
import { VariableUsage } from "../../types/components/testa/editor/editors/snippet/variable_usage";
import { ScenarioProps } from "../models/scenario";
import { GroupProps } from "../models/group";
import { Scenario } from "../models/scenario";
import { GroupFolderProps } from "../models/group_folder";
import { Group } from "../models/group";
import { GroupFolder } from "../models/group_folder";
import { ScenarioFolder } from "../models/scenario_folder";

export class SnippetClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Snippet
    declare record: Snippet

    static load(id: string | number, reload = false): Promise<Snippet> {
        if (reload) this.ModelClass.state.load_promises[id] = null

        if (this.ModelClass.state.load_promises[id] == null) {
            const promise = new Promise<Snippet>((resolve, reject) => {
                $.ajax({
                    url: `/snippets/${id}`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: SnippetProps) => {
                        const snippet = Snippet.new(data)
                        resolve(snippet)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
            this.ModelClass.state.load_promises[id] = promise
            promise.catch(() => {
                this.ModelClass.state.load_promises[id] = null
            })
            return promise
        } else {
            return this.ModelClass.state.load_promises[id]
        }
    }

    static create(snippet: SnippetCreateProps) {
        snippet.tab_id = TAB_ID

        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/snippets`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    snippet,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/snippets/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(snippet: SnippetUpdateProps) {
        type SnippetUpdateResponse = {
            updated: boolean
            reason?: string
            snippet_props?: SnippetProps
        }
        snippet.tab_id = TAB_ID

        return new Promise<SnippetUpdateResponse>((resolve, reject) => {
            const code_handlers = _.cloneDeep(ajax_status_codes)
            delete code_handlers[400]
            return $.ajax({
                url: `/snippets/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    snippet,
                    last_save_id: this.record._last_save_id()
                }),
                statusCode: code_handlers,
                success: (data: SnippetUpdateResponse) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
            }) as JQuery.jqXHR<SnippetUpdateResponse>
        })
    }

    format_code(code: string = null) {
        type SnippetFormatResponse = {
            code: string
            offenses: RubocopOffense[]
            syntax_error: SyntaxError
        }
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                url: `/snippets/${this.key()}/format`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    snippet: {
                        code
                    }
                }),
                success: (data: SnippetFormatResponse) => {
                    this.record.props.offenses = data.offenses
                    this.record.props.syntax_error = data.syntax_error
                    resolve(data.code)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    rubocop_autocorrect(code: string, cop_name: string) {
        type SnippetRubocopAutocorrectResponse = {
            code: string
            offenses: RubocopOffense[]
            syntax_error: SyntaxError
        }
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                url: `/snippets/${this.key()}/autocorrect`,
                type: 'POST',
                data: {
                    cop_name,
                    snippet: {
                        code
                    },
                    authenticity_token
                },
                success: (data: SnippetRubocopAutocorrectResponse) => {
                    this.record.props.offenses = data.offenses
                    this.record.props.syntax_error = data.syntax_error
                    resolve(data.code)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes
            })
        })
    }

    load_savepoints() {
        return new Promise<SnippetSavepointScope>((resolve, reject) => {
            $.ajax({
                url: `/snippets/${this.key()}/savepoints`,
                type: "GET",
                statusCode: ajax_status_codes,
                success: (data: SnippetSavepointProps[]) => {
                    data.forEach(snippet_savepoint_props => {
                        SnippetSavepoint.new(snippet_savepoint_props)
                    })
                    resolve(this.record.snippet_savepoints)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static batch_path(snippet_ids: number | number[]) {
        let ids: number[];
        if (what_is_it(snippet_ids) == "Array") {
            ids = snippet_ids as number[]
        } else {
            ids = [snippet_ids as number]
        }

        type PathResponse = {
            [key: string]: {
                target: SnippetProps,
                path: SnippetFolderProps[]
            }
        }

        type PromiseResponse = {
            [key: string]: SnippetFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/snippets/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    ids,
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(snippet_id => {
                        Snippet.new(data[snippet_id].target)
                        const snippet_folders_props = data[snippet_id].path;
                        const array: SnippetFolder[] = []
                        snippet_folders_props.forEach(snippet_folder_props => {
                            array.push(SnippetFolder.new(snippet_folder_props))
                        })
                        promise_response[snippet_id] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    usages() {
        type UsagesResponse = {
            scenarios: ScenarioProps[],
            scenario_folders: SnippetFolderProps[],
            groups: GroupProps[],
            group_folders: GroupFolderProps[]
        }

        type Usages = {
            scenarios: Scenario[],
            scenario_folders: ScenarioFolder[],
            groups: Group[],
            group_folders: GroupFolder[]
        }

        return this.request<UsagesResponse>({
            url: `/snippets/${this.key()}/usages`,
            type: "GET",
        }).then((data) => {
          return {
              scenarios: data.scenarios.map(s => Scenario.new(s)),
              scenario_folders: data.scenario_folders.map(s => ScenarioFolder.new(s)),
              groups: data.groups.map(g => Group.new(g)),
              group_folders: data.group_folders.map(g => GroupFolder.new(g))
          } as Usages
        })
    }

    static variable_usage(project_version_id: number, variable: string) {
        let scenario_ids: number[] = []
        const scenario_builder_tab = Editor.get_scenario_builder_tab()
        if (scenario_builder_tab != null && scenario_builder_tab.state.scenario_builder != null) {
            scenario_ids = scenario_builder_tab.state.scenario_builder.state.scenarios.map(s => s.key())
        }


        type VariableUsageResponse = {
            usages_in_scenarios: VariableUsage[]
            usages_in_other_scenarios: VariableUsage[],
            usages_in_variable_sets: VariableUsage[]
        }

        return new Promise<VariableUsageResponse>((resolve, reject) => {
            $.ajax({
                url: `/snippets/variable_usage`,
                type: 'POST',
                data: {
                    project_version_id,
                    scenario_ids,
                    variable,
                    authenticity_token
                },
                success: (data: VariableUsageResponse) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes
            })
        })
    }

    static code_search(project_version_id: number, query: string, last_timestamp: Date) {
        type CodeSearchResponse = {
            results: CodeSearchResult[]
            all_loaded: boolean
        }
        return new Promise<CodeSearchResponse>((resolve, reject) => {
            $.ajax({
                url: `/snippets/code_search`,
                type: 'POST',
                data: {
                    project_version_id,
                    query,
                    last_timestamp,
                    authenticity_token
                },
                success: (data: CodeSearchResponse) => {
                    data.results.forEach(r => {
                        r.created_at = new Date(r.created_at)
                        r.vue_key = generate_eid();
                    })
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes
            })
        })
    }
}
