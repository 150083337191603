import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-328038df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SnippetDiffThreePane = _resolveComponent("SnippetDiffThreePane")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "snippet_resolve_sync_conflict",
    dismissible: true,
    show_confirm: false,
    padding: 0,
    focus_last_active_on_unmount: true,
    cancel_on_escape: true,
    onCancel: _ctx.cancel
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SnippetDiffThreePane, {
          remote_code: _ctx.snippet.props.code,
          result_code: _ctx.snippet.state.code_before_conflict,
          snippet: _ctx.snippet,
          onApplied: _ctx.cancel
        }, null, 8 /* PROPS */, ["remote_code", "result_code", "snippet", "onApplied"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onCancel"]))
}