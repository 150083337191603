import { Tab } from "../tab";
import SnippetEditor from "../editors/snippet/SnippetEditor.vue";
import { Snippet } from "../../../../vue_record/models/snippet";
import { UnwrapNestedRefs } from "vue";
import { markRaw } from "vue";
import { reactive } from "vue";
import { watchEffect } from "vue";
import { WatchStopHandle } from "vue";
import CodeMirror from "codemirror";
import { Editor } from "../editor";
import { computed } from "../../../../helpers/vue/computed";

namespace SnippetTab {
    export interface Input extends Tab.Input {
        snippet_id: number
    }

    export interface State extends Tab.State {
        record: Snippet,
        cm: CodeMirror.Editor
        error_watcher_stop: WatchStopHandle
    }
}

export class SnippetTab extends Tab {
    static type = "SnippetTab"

    type: string
    snippet_id: number

    declare state: SnippetTab.State

    constructor(data: SnippetTab.Input) {
        super(data)
        this.type = SnippetTab.type
        this.snippet_id = data.snippet_id;
        this.state.icon.class = Snippet.icon_class
        this.component = markRaw(SnippetEditor);

        // if title is known, set it before we load, so that tabs are sorted correctly
        const snippet = Snippet.find(this.snippet_id)
        if (snippet != null) {
            this.computed.title = computed(() => snippet.props.name)
        }
        this._set_and_call_load_function(() => {
            return Snippet.ClientClass
                          .load(this.snippet_id)
                          .then(snippet => {
                              this.state.record = snippet
                              this.on("added", this._add_error_watcher.bind(this))

                              this.state.icon.color = computed(() => `var(${snippet.props.color}`)
                              this.computed.title = computed(() => snippet.props.name)
                              this.component_props = { tab: this, snippet }
                          })
                          .catch((error) => {
                              this._on_load_error(error)
                          })
                          .finally(() => {
                              this.set_loaded(true)
                          })
        })
        this.dnd = reactive({})

        this.on("before_remove", this._remove_error_watcher.bind(this))
        this.on("activated", () => this.highlight_in_builder())
    }

    highlight_in_builder() {
        const scenario_builder_tab = Editor.get_scenario_builder_tab()
        if (scenario_builder_tab != null) {
            scenario_builder_tab.state.scenario_builder?.scroll_to_snippet(this.snippet_id)
        }
    }

    // <editor-fold desc="EVENT HANDLERS">
    _remove_error_watcher() {
        if (this.state.error_watcher_stop != null) {
            this.state.error_watcher_stop()
            this.state.error_watcher_stop = null
        }
    }

    _add_error_watcher() {
        this._remove_error_watcher()
        this.state.error_watcher_stop = watchEffect(() => {
            if (this.state.record.state.conflict_chunks.length > 0) {
                this.set_error_indicator();
            } else {
                this.clear_indicator();
            }
        })
    }

    // </editor-fold>
}

Tab.register_tab_type(SnippetTab)
