import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { VariableSet } from "../models/variable_set";
import { SnippetProps } from "../models/snippet";
import { VariableSetCreateProps } from "../models/variable_set";
import { VariableSetProps } from "../models/variable_set";
import { VariableSetUpdateProps } from "../models/variable_set";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class VariableSetClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof VariableSet
    declare record: VariableSet

    static create(variable_set: VariableSetCreateProps) {
        interface Response extends VariableSetProps {
            snippet: SnippetProps
        }

        return new Promise<VariableSet>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/variable_sets`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    variable_set
                }),
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
                success: (data: Response) => {
                    resolve(VariableSet.new(data))
                }
            })
        })
    }

    update(variable_set: VariableSetUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/variable_sets/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    variable_set
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    destroy() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/variable_sets/${this.key()}`,
                type: "DELETE",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }
}
