<template>
  <div ref="scroll_container"
       class="scroll-container"
       @scroll="on_results_scroll"
       @wheel.passive="on_results_wheel"
  >
    <div class="reports-container">
      <div v-if="Play.state.filter_shown"
           class="row full">

        <!--<editor-fold desc="SCHEDULE FILTER">-->
        <div v-if="Play.state.filter.schedule_id != null"
             class="col s12">
          <div class="single-item-filter-container">
            <ActionIcon icon_class="fa-solid fa-times"
                        color_class="red"
                        @click="Play.state.filter.schedule_id = null"/>
            <span class="icon">
              <i :class="Schedule.icon_class"
                 :style="{color: Schedule.color()}"
                 title="Schedule"
              />
            </span>
            <span class="name">
              {{ Schedule.find(Play.state.filter.schedule_id)?.props?.name }}
            </span>
          </div>
        </div>
        <!--</editor-fold>-->


        <!--<editor-fold desc="GROUP FILTER">-->
        <div v-if="Play.state.filter.group_id != null"
             class="col s12">
          <div class="single-item-filter-container">
            <ActionIcon icon_class="fa-solid fa-times"
                        color_class="red"
                        @click="Play.state.filter.group_id = null"/>
            <span class="icon">
              <i :class="Group.icon_class"
                 :style="{color: Group.color()}"
                 title="Group"
              />
            </span>
            <span class="name">
              {{ Group.find(Play.state.filter.group_id)?.props?.name }}
            </span>
          </div>
        </div>
        <!--</editor-fold>-->

        <!--<editor-fold desc="SCENARIO FOLDER FILTER">-->
        <div v-if="Play.state.filter.scenario_folder_id != null"
             class="col s12">
          <div class="single-item-filter-container">
            <ActionIcon icon_class="fa-solid fa-times"
                        color_class="red"
                        @click="Play.state.filter.scenario_folder_id = null"/>
            <span class="icon">
              <i :class="ScenarioFolder.icon_class"
                 :style="{color: ScenarioFolder.color()}"
                 title="Scenario Folder"
              />
            </span>
            <span class="name">
              {{ ScenarioFolder.find(Play.state.filter.scenario_folder_id)?.props?.name }}
            </span>
          </div>
        </div>
        <!--</editor-fold>-->

        <!--<editor-fold desc="SCENARIO FILTER">-->
        <div v-if="Play.state.filter.scenario_id != null"
             class="col s12">
          <div class="single-item-filter-container">
            <ActionIcon icon_class="fa-solid fa-times"
                        color_class="red"
                        @click="Play.state.filter.scenario_id = null"/>
            <span class="icon">
              <i :class="Scenario.icon_class"
                 :style="{color: Scenario.color()}"
                 title="Scenario"
              />
            </span>
            <span class="name">
              {{ Scenario.find(Play.state.filter.scenario_id)?.props?.name }}
            </span>
          </div>
        </div>
        <!--</editor-fold>-->

        <div v-if="web.is_reports"
             class="col s12">
          <Select2
              id="play_filter_projects_select"
              v-model="Play.state.filter.project_ids"
              title="Filter Projects"
              placeholder="Filter Projects"
              :search="true"
              :multiple="true"
              :for_report_filter="true"
          >
            <template v-for="project in projects"
                      :key="project.key()">
              <option :value="project.key()">{{ project.name() }}</option>
            </template>
          </Select2>
        </div>

        <div v-if="web.is_reports"
             class="col s12"
             style="margin-top: 6px; margin-bottom: 6px;">
          <Select2
              id="play_filter_project_versions_select"
              v-model="Play.state.filter.project_version_ids"
              title="Filter Project Versions"
              placeholder="Filter Project Versions"
              :search="true"
              :multiple="true"
              :for_report_filter="true"
          >
            <template v-for="project_version in project_versions"
                      :key="project_version.key()">
              <option :value="project_version.key()">{{ project_version.name() }}</option>
            </template>
          </Select2>
        </div>

        <div class="col s12">
          <Select2
              id="play_filter_users_select"
              v-model="Play.state.filter.user_ids"
              title="Filter Users"
              placeholder="Filter Users"
              :search="true"
              :multiple="true"
              :for_report_filter="true"
          >
            <template v-for="user in users_on_project"
                      :key="user.key()">
              <option :value="user.key()">
                {{ user.props.email }} {{ user.key() == current.user?.key() ? ' (you)' : '' }}
              </option>
            </template>
          </Select2>
        </div>

        <div class="col s12"
             style="margin-top: 6px"
        >
          <Select2
              id="play_filter_types_select"
              v-model="Play.state.filter.play_types_group_ids"
              title="Filter Type"
              placeholder="Filter Type"
              :multiple="true"
              :for_report_filter="true"
              :template_result="(state) => PlayType.filter_group_option_template(state)"
              :template_selection="(state) => PlayType.filter_group_option_template(state)"
          >
            <template v-for="type_group in play_types_filters"
                      :key="type_group.id">
              <option :value="type_group.id">{{ type_group.label }}</option>
            </template>
          </Select2>
        </div>

        <div class="col s12"
             style="margin-top: 6px"
        >
          <Select2
              id="play_filter_status_select"
              v-model="Play.state.filter.play_status_group_ids"
              title="Filter Status"
              placeholder="Filter Status"
              :multiple="true"
              :for_report_filter="true"
              :template_result="(state) => PlayStatus.filter_group_option_template(state)"
              :template_selection="(state) => PlayStatus.filter_group_option_template(state)"
          >
            <template v-for="status_group in play_status_filters"
                      :key="status_group.id">
              <option :value="status_group.id">{{ status_group.label }}</option>
            </template>
          </Select2>
        </div>
        <div class="col s12">
          <Input
              id="play_filter_name"
              v-model="Play.state.filter.name"
              label="Filter name"
              placeholder="Filter name"
              :throttle_time="1000"
              :clear_with_esc="true"
              :scale="0.9"
          />
        </div>
        <div class="col s6">
          <DatePicker
              id="play_from_datepicker"
              v-model="Play.state.filter.from"
              placeholder="Pick from date"
              label="From Date"
              :max_date="new Date()"
              :scale="0.9"
              :buttons="['clear']"
          />
        </div>
        <div class="col s6">
          <DatePicker
              id="play_to_datepicker"
              v-model="Play.state.filter.to"
              label="To Date"
              :min_date="Play.state.filter.from"
              :max_date="new Date()"
              :scale="0.9"
              :buttons="['clear']"
          />
        </div>
      </div>
      <div class="reports-tree-container">
        <Tree
            v-if="plays_tree.computed.children.length > 0"
            :tree="plays_tree"
        />
        <div v-else-if="!Play.state.filter_result_state.loading"
             class="no-reports-info">
          <div style="margin-bottom: 10px;">
            No reports found
          </div>
          <Button v-if="!Play.state.filter_shown"
                  text="Show filters"
                  icon_class="fa-solid fa-filter"
                  @click="Play.state.filter_shown = true"
          />
        </div>
      </div>
      <Loading
          v-if="Play.state.filter_result_state.loading"
          type="three_bounce"
          margin="10px 0 0 0"
          :size="3"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Tree from "../testa/tree/Tree.vue";
import DatePicker from "../testa/DatePicker.vue";
import Select2 from "../testa/Select2.vue";
import Loading from "../testa/Loading.vue";
import { Play } from "../../vue_record/models/play/play";
import { TestaTree } from "../testa/tree/tree";
import { PlayStatus } from "../../helpers/play/play_status";
import { UserProject } from "../../vue_record/models/user_project";
import { nextTick } from "vue";
import { element_is_scrollable } from "../../helpers/dom/element_is_scrollable";
import { PlayType } from "../../helpers/play/play_type";
import Input from "../testa/Input.vue";
import ActionIcon from "../testa/ActionIcon.vue";
import { Schedule } from "../../vue_record/models/schedule";
import { Group } from "../../vue_record/models/group";
import { ScenarioFolder } from "../../vue_record/models/scenario_folder";
import { Scenario } from "../../vue_record/models/scenario";
import { Project } from "../../vue_record/models/project";
import { ProjectVersion } from "../../vue_record/models/project_version";
import { AllMightyObserver } from "../../helpers/dom/all_mighty_observer";
import Button from "../testa/Button.vue";
import { User } from "../../vue_record/models/user";
import { UserScope } from "../../vue_record/scopes/user_scope";

export default defineComponent({
    components: { Button, ActionIcon, Input, Loading, Select2, DatePicker, Tree },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            allow_show_filter_on_wheel_up: false,
            allow_show_filter_on_wheel_up_timeout: null,
            amo: null as AllMightyObserver,
            in_viewport: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        plays_tree() {
            return TestaTree.Tree.get_plays_tree(current.project_version)
        },
        Scenario() {
            return Scenario
        },
        ScenarioFolder() {
            return ScenarioFolder
        },
        Group() {
            return Group
        },
        Schedule() {
            return Schedule
        },
        Enum() {
            return Enum
        },
        Play() {
            return Play
        },
        PlayStatus() {
            return PlayStatus
        },
        PlayType() {
            return PlayType
        },
        play_status_filters() {
            return PlayStatus.filter_groups
        },
        play_types_filters() {
            return PlayType.filter_groups
        },
        projects() {
            return Project.get_scope().toArray()
        },
        project_versions() {
            let project_versions_scope = ProjectVersion.get_scope();
            if (Play.state.filter.project_ids.length != 0) {
                // just project versions on selected projects
                project_versions_scope = project_versions_scope.where({ project_id: Play.state.filter.project_ids })
            }
            return project_versions_scope.toArray()
        },
        current() {
            return current
        },
        users_on_project() {
            let user_projects_scope = UserProject.get_scope();
            if (Play.state.filter.project_ids.length != 0) {
                // just users on selected projects
                user_projects_scope = user_projects_scope.where({ project_id: Play.state.filter.project_ids })
            }
            let users_scope: UserScope
            if (current.user.is_superadmin()) {
                users_scope = User.get_scope()
            } else {
                users_scope = user_projects_scope.users
            }

            const users: User[] = users_scope.not({ id: current.user.key() })
                                             .order("email", "asc")
                                             .toArray()

            users.unshift(current.user)
            return users
        },
        web() {
            return web
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'Play.state.filter_result_state.loading'(new_val, old_val) {
            if (old_val && !new_val && !Play.state.filter_result_state.all_loaded) {
                nextTick(() => {
                    const scroll_container = this.$refs.scroll_container as HTMLElement
                    if (!element_is_scrollable(scroll_container)) Play.ClientClass.load_filtered()
                })
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const scroll_container = this.$refs.scroll_container as HTMLElement
        this.amo = AllMightyObserver.new(
            {
                element_after_resize: true,
                target_element: scroll_container,
                callback: () => {
                    if (this.in_viewport && !element_is_scrollable(scroll_container)) Play.ClientClass.load_filtered()
                },
            },
            {
                element_visible: true,
                target_element: scroll_container,
                callback: () => this.in_viewport = true
            },
            {
                element_hidden: true,
                target_element: scroll_container,
                callback: () => this.in_viewport = false
            }
        )
    },
    unmounted() {
        this.amo?.stop()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_results_scroll(_e: Event) {
            if (Play.state.filter_result_state.loading) return;
            if (Play.state.filter_result_state.all_loaded) return;

            const scroll_container = this.$refs.scroll_container as HTMLElement;
            const scroll_top = scroll_container.scrollTop
            const scroll_bottom = scroll_top + scroll_container.clientHeight;
            const diff = scroll_container.scrollHeight - scroll_bottom
            if (diff < 50) Play.ClientClass.load_filtered()
        },
        on_results_wheel(_e: WheelEvent) {
            // if (Play.state.filter_result_state.loading) return;
            // if (Play.state.filter_result_state.all_loaded) return;
            //
            // const scroll_container = this.$refs.scroll_container as HTMLElement;
            // const scroll_top = scroll_container.scrollTop
            // const scroll_bottom = scroll_top + scroll_container.clientHeight;
            // const diff = scroll_container.scrollHeight - scroll_bottom
            // if (diff < 50) Play.load_filtered()
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.single-item-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    font-size: 0.7em;
    margin-right: 5px;
  }

  .name {
    font-size: 0.8em;
    white-space: nowrap;
  }
}

.scroll-container {
  height: 100%;
  overflow: auto;
  width: 100%;

  .reports-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .reports-tree-container {
      width: 100%;
      overflow: auto;

      .no-reports-info {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

</style>
