import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Phone } from "../models/phone";
import { ProgressBar } from "../../components/testa/progress_bar/progress_bar";
import { VueRecord } from "../base/vue_record";
import { Data } from "faye";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { PhoneProps } from "../models/phone";
import { PhoneUpdateProps } from "../models/phone";
import { CacheStoreValue } from "../base/vue_record_client";
import { EnumPhoneType } from "../../auto_generated/enums";

const console = new Consoler("warn")
export class PhoneClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Phone
    declare record: Phone

    progress_bars: Record<string, ProgressBar>

    constructor(record: VueRecord) {
        super(record);
        this.progress_bars = {}
    }

    response_channel(request_id: string) {
        return `/phonector/frontend/request/${request_id}`;
    }

    subscribe_to_response_channel(request_id: string, callback: (data: Data) => void) {
        this.progress_bars[request_id] = ProgressBar.new();
        faye.subscribe(this.response_channel(request_id), (data) => {
            callback(data);
            this.handle_progress_percentage(request_id, data);
            if (data.response) this.unsubscribe_from_response_channel(request_id);
        });
    }

    unsubscribe_from_response_channel(request_id: string) {
        faye.unsubscribe(this.response_channel(request_id))
    }

    handle_progress_percentage(request_id: string, data: Data) {
        if (data.hasOwnProperty("progress")) {
            let percentage = data.progress.percentage
            if (percentage < 1) percentage = 1
            this.progress_bars[request_id].go(percentage)
        }
    }

    static load(id: string | number, reload = false): Promise<Phone> {
        if (reload) this.ModelClass.state.load_promises[id] = null

        if (this.ModelClass.state.load_promises[id] == null) {
            const promise = new Promise<Phone>((resolve, reject) => {
                $.ajax({
                    url: `/phones/${id}`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: PhoneProps) => {
                        const phone = Phone.new(data)
                        resolve(phone)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
            this.ModelClass.state.load_promises[id] = promise
            promise.catch(() => {
                this.ModelClass.state.load_promises[id] = null
            })
            return promise
        } else {
            return this.ModelClass.state.load_promises[id]
        }
    }

    update(phone: PhoneUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/phones/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    phone,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static not_in_project(project_id: number) {
        type Response = Array<{ udid: string, name: string, claimed: boolean, phone_type: EnumPhoneType }>
        return new Promise<Response>((resolve, reject) => {
            return $.ajax({
                url: `/phones/not_in_project`,
                type: "GET",
                data: {
                    project_id
                },
                success: (data: Response) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static add_to_project(project_id: number, udid: string) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/phones/add_to_project`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    project_id,
                    udid
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    install(app_ids: number[], response_channel: string) {
        $.ajax({
            url: `/phones/${this.key()}/install`,
            type: "POST",
            processData: false,
            contentType: "application/json",
            data: JSON.stringify({
                app_ids,
                project_version_id: current.project_version.props.id,
                authenticity_token,
                response_channel,
            }),
            statusCode: ajax_status_codes,
        })
    }

    reboot() {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/phones/${this.key()}/reboot`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    install_with_progress(app_ids: Array<number>) {
        const request_id = generate_uuid();
        this.subscribe_to_response_channel(request_id, (data: Data) => {
            console.log(data);
            if (data.hasOwnProperty("response")) {
                if (data.response.data == Enum.Phonector.Codes.Install.SUCCESS) {
                    toastr.success("Installed")
                    if (this.progress_bars[request_id]) {
                        this.progress_bars[request_id].go(100);
                    }
                } else {
                    toastr.error("Failed to install")
                    if (this.progress_bars[request_id]) {
                        this.progress_bars[request_id].stop()
                        delete this.progress_bars[request_id];
                    }
                }
            }
        });

        this.progress_bars[request_id].go(1)
        this.install(app_ids, this.response_channel(request_id))
    }
}
