import { VueRecord } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { TabTargetOpts } from "../../components/testa/editor/tab";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { GroupScope } from "../scopes/group_scope";
import { GroupFolderScope } from "../scopes/group_folder_scope";
import { copy_text_to_clipboard } from "../../helpers/generic/copy_to_clipboard";
import { update_url_parameters } from "../../helpers/generic/update_url_parameter";
import CodeMirror from "codemirror";
import { nextTick } from "vue";
import { resolve_tab_target } from "../../components/testa/editor/tab";
import { unmount_all_modals } from "../../helpers/vue/unmount_all_modals";
import { GroupTab } from "../../components/testa/editor/tabs/group_tab";
import { multi_resource_remove } from "../../helpers/client/core/multi_resource_remove";
import { GroupClient } from "../clients/group_client";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import UiShowInSidebarData = UiSync.UiShowInSidebarData;
import { Props } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { State } from "../base/vue_record";
import { computed } from "../../helpers/vue/computed";
import { GroupFolder } from "./group_folder";
import { Play } from "./play/play";
import { Schedule } from "./schedule";
import { Editor } from "../../components/testa/editor/editor";

// <editor-fold desc="TYPES">
export interface GroupProps extends Props {
    id: number
    name: string
    user_id: number
    project_version_id: number
    created_at: Date
    updated_at: Date
    group_folder_id: number
    archived: boolean
    xray_project_key: string[]
    git_id: string
    xray_test_keys: string[]
}
export type QuerifiedGroupProps = QuerifyProps<GroupProps>
export type GroupCreateProps = Omit<Partial<GroupProps>, 'id'>
export type GroupUpdateProps = Partial<GroupProps>
export interface GroupState extends State {}
export interface GroupComputed extends Computed {}
export interface GroupStaticState extends StaticState {
    load_promises: Record<number | string, Promise<Group>>
}

export interface GroupOpenOpts extends TabTargetOpts {
    close_all_modals?: boolean
}

export type GroupEditorActionItem = "dummy"


export type GroupEditorFeature = "dummy"
// </editor-fold>

const console = new Consoler("warn")
export class Group extends VueRecord {
    ['constructor']: typeof Group

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = GroupClient
    static ScopeClass = GroupScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: GroupStaticState = reactive<GroupStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "group_folder_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Group> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Group>> = {}

    static field_validators: ModelValidatorOpts<GroupProps> = {}

    static resource_name = Enum.Resource.Label.GROUP
    static resource_id = Enum.Resource.Id.GROUP
    static icon_class = "fa-solid fa-grip-vertical"
    static color = () => get_css_var("--group-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: GroupClient
    declare props: GroupProps;
    declare state: GroupState;
    declare computed: GroupComputed;

    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    open(opts: GroupOpenOpts = {}) {
        const tab_target = resolve_tab_target(opts)
        if (opts.close_all_modals) unmount_all_modals();

        const tab = tab_target.create_tab({
            id: `group_tab_${this.props.id}`,
            group_id: this.props.id,
            type: GroupTab.type
        }) as GroupTab

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => {
                resolve(null)
            })
        })
    }

    add_groups_scenarios(scenarios_ids: number[], starting_real_order: number = null) {
        if (starting_real_order == null) {
            const last = this.groups_scenarios.order("real_order", "asc", "sensitive").last()
            if (last != null) {
                starting_real_order = last.props.real_order + 1;
            } else {
                starting_real_order = 0
            }
        }
        let current_real_order = starting_real_order;

        const groups_scenarios = scenarios_ids.map(scenario_id => {
            return {
                scenario_id,
                group_id: this.key(),
                real_order: current_real_order++,
            }
        })

        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/groups_scenarios/batch`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    groups_scenarios,
                    authenticity_token
                }),
                success: () => {
                    resolve()
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    destroy() {
        return multi_resource_remove(this.props.project_version_id, this.to_scoped_map())
    }
    // </editor-fold>

    // <editor-fold desc="HOOKS">
    after_sync_add() {
        super.after_sync_add();

        if (this.props.user_id == current.user.key()) {
            nextTick(() => {
                const tree = TestaTree.Tree.get_project_tree()
                if (tree?.is_visible()) {
                    const node = tree.get_node_by_key(this.tree_key())
                    if (node?.is_visible()) {
                        node?.edit_start()
                    }
                }
            })
        }
    }

    before_unload() {
        super.before_unload();

        this.get_tabs().forEach(tab => tab.close())
    }
    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return Group.show_in_sidebar(this.props.id, this.props.project_version_id, tree);
    }

    static async show_in_sidebar(group_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: number[];
        if (what_is_it(group_ids) == "Array") {
            ids = group_ids as number[]
        } else {
            ids = [group_ids as number]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(group_ids).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).forEach(group_id_string => {
                    const group_id = parseInt(group_id_string)
                    const group_folders = promise_response[group_id]
                    const keys = [
                        GroupFolder.tree_key(project_version_id),
                        ...group_folders.map(sf => sf.tree_key()),
                        Group.find(group_id).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: Group.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="TREE">

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<GroupScope, GroupFolderScope, VueRecord>> = {}

            keys.f10 = (e: KeyboardEvent, node: TestaTree.Node) => {
                Play.show_play_modal(node.tree.get_selected_records_array())
            }

            if (current_role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Group.ClientClass.create({
                        group_folder_id: this.group_folder?.key(),
                        project_version_id: this.props.project_version_id
                    })
                }

                keys["ctrl++"] = () => {
                    GroupFolder.ClientClass.create({
                        group_folder_id: this.group_folder?.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const group_folder_ids = nodes.map(n => n.record)
                                              .filter(r => r instanceof GroupFolder)
                                              .map(r => r.key())
                const group_ids = nodes.map(n => n.record)
                                       .filter(r => r instanceof Group)
                                       .map(r => r.key())

                if (type == "copy") {
                    GroupFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.group_folder?.key(),
                        group_folder_ids,
                        group_ids
                    )
                } else if (type == "cut") {
                    GroupFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.group_folder?.key(),
                        group_folder_ids,
                        group_ids
                    )
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXTMENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<GroupScope, GroupFolderScope, Group>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                items.play = Play.contextmenu_item_play(() => Play.show_play_modal(node.tree.get_selected_records_array()))

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: GroupFolder._tree_new_group_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.open = {
                    name: "Open",
                    icon: Group.icon_class,
                    color: get_css_var("--button-white"),
                    key: `enter`,
                    callback: () => {
                        this.open();
                    },
                }

                items.filter_reports = {
                    name: "Filter Reports",
                    icon: "fa-solid fa-filter",
                    color: Play.color(),
                    callback: () => {
                        if (Play.state.filter.schedule_id != null) {
                            const schedule = Schedule.find(Play.state.filter.schedule_id)
                            if (schedule?.props?.group_id != this.key()) {
                                Play.state.filter.schedule_id = null
                            }
                        }
                        Play.state.filter.scenario_id = null
                        Play.state.filter.scenario_folder_id = null
                        Play.state.filter.group_id = this.key();
                        Section.get_reports_section().enable();
                    }
                }

                const more_options: ContextMenu.Items = {}
                more_options.export = TestaTree.Tree.contextmenu_export_item(node)
                more_options.import = TestaTree.Tree.contextmenu_import_item(node)

                more_options.copy_link = {
                    name: "Copy link",
                    icon: "fa-solid fa-link",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        const params = new URLSearchParams()
                        params.set("project_version_id", this.props.project_version_id.toString())
                        params.set("group_id", this.key().toString())
                        copy_text_to_clipboard(update_url_parameters(window.location.href, params))
                    }
                }

                return {
                    ...items,
                    ...default_items,
                    more_options: {
                        name: "More",
                        icon: "fa-solid fa-ellipsis",
                        items: more_options
                    }
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<GroupScope, GroupFolderScope, Group> {
        return {
            resource_id: Enum.Resource.Id.GROUP,
            key: this.tree_key(),
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    return this.open()
                },
            },
            icon: computed(() => {
                return {
                    color: get_css_var("--group-color"),
                }
            }),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: true,
                is_drop_area: false,
            },
            clipboard: this._testa_tree_clipboard(),
            contextmenu: this._tree_contextmenu(),
            hover_action: {
                icon: {
                    color: get_css_var("--button-green"),
                    class: "fa fa-play",
                    scale: 0.8
                },
                title: "Play",
                callback: () => Play.show_play_modal(this)
            },
        }
    }

    // </editor-fold>

    // <editor-fold desc="EDITOR">
    _editor_contextmenu(cm: CodeMirror.Editor, e: MouseEvent, _exclude_items: GroupEditorActionItem[] = []) {
        const items: ContextMenu.Items = {}
        return items
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        const names: string[] = []
        let current_folder = this.group_folder
        while (current_folder != null) {
            names.push(current_folder.name())
            current_folder = current_folder.parent_folder
        }
        if (include_name) names.push(this.name())
        return `/${names.join("/")}`
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
Group.register_resource(Group)
GroupClient.ModelClass = Group
GroupScope.ModelClass = Group


global_event_bus.$on("after_project_version_unload", () => {
    Group.get_scope().unload()
})


on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            Group.unsync()
            if (project_version_id != null) Group.sync(`/sync/project_version/${project_version_id}/groups`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})


if (web.is_main) {
    ui_sync.register_ui_task("show_in_sidebar", Group.resource_id, (sender: string, data: UiShowInSidebarData) => {
        Group.show_in_sidebar(data.id as number, current.project_version?.key())
    })
}

declare global {
    interface Window {
        Group: typeof Group
    }
}
window.Group = Group
// </editor-fold>
