import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56ea47e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "row",
  style: {"display":"flex"}
}
const _hoisted_2 = { class: "col s8 inline-flex" }
const _hoisted_3 = { class: "col s4" }
const _hoisted_4 = ["data-priority", "onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = ["title", "innerHTML"]
const _hoisted_8 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_Input = _resolveComponent("Input")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Table = _resolveComponent("Table")
  const _component_ProxyNew = _resolveComponent("ProxyNew")
  const _component_SettingsItem = _resolveComponent("SettingsItem")
  const _component_ProxyEdit = _resolveComponent("ProxyEdit")
  const _directive_moment = _resolveDirective("moment")

  return (_ctx.show_table)
    ? (_openBlock(), _createBlock(_component_SettingsItem, {
        key: 0,
        id: _ctx.items.project.proxies.id,
        title: _ctx.items.project.proxies.title
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.is_viewer_role && !_ctx.is_user_role)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    id: "new_proxy_button",
                    tab_index: "0",
                    text: "New Proxy",
                    onClick: _ctx.new_proxy
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true),
              (_ctx.deletion_markers.marked().length > 0 && !_ctx.is_viewer_role && !_ctx.is_user_role)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    id: "delete_proxies_button",
                    tab_index: "0",
                    text: "Delete",
                    color_class: "red",
                    onClick: _ctx.delete_proxies
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Input, {
                id: "proxies_filter",
                modelValue: _ctx.filter,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.filter) = $event)),
                placeholder: "Filter",
                tab_index: "0",
                throttle_time: 200
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _createVNode(_component_Table, { id: "proxies_table" }, {
            thead: _withCtx(() => [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_columns, (col, key) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                    (key == 'delete')
                      ? (_openBlock(), _createElementBlock("th", {
                          key: 0,
                          class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                          onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.all_marked = !_ctx.all_marked), ["stop"]))
                        }, [
                          _createVNode(_component_Checkbox, {
                            modelValue: _ctx.all_marked,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.all_marked) = $event)),
                            label: "",
                            for_table: true,
                            color_class: "red"
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ], 2 /* CLASS */))
                      : (_openBlock(), _createElementBlock("th", {
                          key: 1,
                          class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                          "data-priority": _ctx.orders.priority(key),
                          onClick: $event => (_ctx.orders.toggle(key))
                        }, _toDisplayString(col.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_4))
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            tbody: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordered_rows, (row) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: row.record.props.id,
                  class: "proxy-row",
                  onClick: $event => (_ctx.show_proxy(row.record))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cols, (col, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (col.column_key == 'delete')
                        ? (_openBlock(), _createElementBlock("td", {
                            key: 0,
                            title: col.title,
                            class: _normalizeClass(col.classes),
                            onClick: _withModifiers($event => (_ctx.deletion_markers.marker(row.record).toggle()), ["stop"])
                          }, [
                            _createVNode(_component_Checkbox, {
                              modelValue: _ctx.deletion_markers.marker(row.record).value,
                              "onUpdate:modelValue": $event => ((_ctx.deletion_markers.marker(row.record).value) = $event),
                              label: "",
                              for_table: true,
                              color_class: "red"
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                          ], 10 /* CLASS, PROPS */, _hoisted_6))
                        : _withDirectives((_openBlock(), _createElementBlock("td", {
                            key: 1,
                            title: col.title,
                            class: _normalizeClass(col.classes),
                            innerHTML: col.html
                          }, null, 10 /* CLASS, PROPS */, _hoisted_7)), [
                            [_directive_moment, col.moment]
                          ])
                    ], 64 /* STABLE_FRAGMENT */))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 8 /* PROPS */, _hoisted_5))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.show_new_proxy_form && !_ctx.is_viewer_role && !_ctx.is_user_role)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_ProxyNew, {
                  project: _ctx.project,
                  onExit: _cache[3] || (_cache[3] = $event => (_ctx.show_new_proxy_form = false))
                }, null, 8 /* PROPS */, ["project"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["id", "title"]))
    : (_openBlock(), _createBlock(_component_ProxyEdit, {
        key: 1,
        proxy: _ctx.edit_proxy,
        project: _ctx.project,
        form_validator: _ctx.form_validator.register_child_form('proxy_edit'),
        is_viewer_role: _ctx.is_viewer_role,
        is_user_role: _ctx.is_user_role,
        onSettingItemMounted: _cache[4] || (_cache[4] = (component) => _ctx.$emit('setting-item-mounted', component)),
        onSettingItemUnmounted: _cache[5] || (_cache[5] = (component) => _ctx.$emit('setting-item-unmounted', component)),
        onExit: _cache[6] || (_cache[6] = $event => (_ctx.show_table=true))
      }, null, 8 /* PROPS */, ["proxy", "project", "form_validator", "is_viewer_role", "is_user_role"]))
}