// @ts-nocheck
export type XmlJson = string | number | {
    "@attributes"?: { [key: string]: XmlJson | XmlJson[] }
}

export function xml_to_json(xml: HTMLElement): XmlJson {
    // Create the return object
    let obj: XmlJson = {};

    if (xml.nodeType == 1) { // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (let j = 0; j < xml.attributes.length; j++) {
                const attribute = xml.attributes.item(j);
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType == 3) { // text
        obj = xml.nodeValue;
    }

    // do children
    // If just one text node inside
    if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3) {
        // const item = xml.childNodes.item(0);
        // if (item.nodeName == "#text" && item.nodeValue.trimEnd() == "") continue;
        // const nodeName = item.nodeName;
        // obj[nodeName] = item.nodeValue;
    } else if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
            const item = xml.childNodes.item(i);
            if (item.nodeName == "#text" && item.nodeValue.trimEnd() == "") continue;
            const nodeName = item.nodeName;
            if (typeof (obj[nodeName]) == "undefined") {
                obj[nodeName] = xml_to_json(item as HTMLElement);
            } else {
                if (typeof (obj[nodeName].push) == "undefined") {
                    const old: any = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xml_to_json(item as HTMLElement));
            }
        }
    }
    return obj;
}

export function parse_xml(xml: string): HTMLElement {
    let dom = null;
    if (window.DOMParser) {
        try {
            dom = (new DOMParser()).parseFromString(xml, "text/xml");
        } catch (e) {
            dom = null;
        }
    } else {
        if (window.ActiveXObject) {
            try {
                dom = new ActiveXObject('Microsoft.XMLDOM');
                dom.async = false;
                if (!dom.loadXML(xml)) toastr.error(dom.parseError.reason + dom.parseError.srcText);
            } catch (e) {
                dom = null;
            }
        } else toastr.error("cannot parse xml string!");
    }
    return dom;
}
