import { MobileProvisioning } from "../../models/mobile_provisioning";
import { Device } from "../../models/mobile_provisionings/device";

declare module "../../models/mobile_provisionings/device" {

    interface Device {
        mobile_provisioning: MobileProvisioning
        // phone(): Phone

        // phone_projects(): PhoneProjectScope
    }
}

Device.belongs_to("mobile_provisioning", MobileProvisioning, "mobile_provisioning_id")
// Device.belongs_to("phone", Phone, "phone_udid")

// Device.has_many_through("phone_projects", "phone", "phone_projects", PhoneProject)
