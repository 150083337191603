import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Input = _resolveComponent("Input")

  return (_openBlock(), _createBlock(_component_Input, {
    id: _ctx.id,
    ref: "input",
    modelValue: _ctx.string_value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.string_value) = $event)),
    instance_id: _ctx.instance_id,
    placeholder: "Pick a date",
    label: _ctx.label,
    scale: _ctx.scale,
    readonly: true,
    errors_no_side: 'bottom'
  }, null, 8 /* PROPS */, ["id", "modelValue", "instance_id", "label", "scale"]))
}