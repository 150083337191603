import { PlayScenario } from "../../vue_record/models/play/play_scenario";

export const ReplayOptions = {
    success: "success",
    failed: "failed",
    warned: "warned",
    skipped: "skipped",
    not_finished: "not_finished",
}


export function replay_play_scenario(replay_options: string[], play_scenario: PlayScenario) {
    if (play_scenario.is_success() && replay_options.includes(ReplayOptions.success)) return true
    if (play_scenario.is_faulty() && replay_options.includes(ReplayOptions.failed)) return true
    if (play_scenario.is_warned() && replay_options.includes(ReplayOptions.warned)) return true
    if (play_scenario.is_skipped() && replay_options.includes(ReplayOptions.skipped)) return true
    return !play_scenario.is_finished() && replay_options.includes(ReplayOptions.not_finished);
}
