import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row align-start",
  style: {"height":"100%"}
}
const _hoisted_2 = {
  class: "col s8",
  style: {"height":"100%","padding":"0"}
}
const _hoisted_3 = {
  class: "col s4 vertical",
  style: {"padding-top":"20px","max-height":"100%"}
}
const _hoisted_4 = {
  key: 0,
  class: "row"
}
const _hoisted_5 = { class: "col s12 align-center" }
const _hoisted_6 = {
  class: "row",
  style: {"overflow":"auto","max-height":"100%"}
}
const _hoisted_7 = {
  ref: "action_response",
  class: "col s12 vertical job-statuses-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_SettingsItem = _resolveComponent("SettingsItem")

  return (_openBlock(), _createBlock(_component_SettingsItem, {
    id: _ctx.setting_items.project.gemfile.id,
    title: _ctx.setting_items.project.gemfile.title,
    padding: "10px 0 0 0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("textarea", {
            id: "gemfile_codemirror",
            ref: "gemfile_codemirror",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.gemfile) = $event)),
            name: "gemfile"
          }, null, 512 /* NEED_PATCH */), [
            [_vModelText, _ctx.gemfile]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.is_viewer_role)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    text: "Install",
                    tab_index: "2",
                    click_action: _ctx.install_gemfile
                  }, null, 8 /* PROPS */, ["click_action"])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.job_statuses, (job_status) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: job_status.vue_key,
                  class: _normalizeClass({red: job_status.is_error})
                }, _toDisplayString(job_status.message), 3 /* TEXT, CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512 /* NEED_PATCH */)
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "title"]))
}