// noinspection FallThroughInSwitchStatementJS,JSUnusedLocalSymbols

export type ConsolerLevel = "debug" | "info" | "warn" | "error" | "silence"
type ConsolerLevelNumber = 0 | 1 | 2 | 3 | 4

/**
 * Consoler is a wrapper around console API. It enables:
 * - setting log level per consoler instance (only applies for dev env)
 *
 * How it works:
 * consoler instance should be created in each file with a specific tag.
 * Then on each file / instance, we can set the log level to "debug" while developing,
 * and once development it is finished set the level to "warn"
 *
 * Using consoler should allow us to easily debug code when bugs are found by simply switching the log level without
 * having to rewrite all logs.
 *
 * When the code is served to user in production,
 * it will override the level so that all logs can be captured in production issues
 */
export class Consoler {
    level: ConsolerLevelNumber

    constructor(level: ConsolerLevel = "warn") {
        // for production enable all logs
        // we dont want to miss out on any information when user is reporting a production issue
        if (CONSTANTS.IS_PROD_ENV) level = "warn"
        /* eslint-disable no-console, no-fallthrough */
        switch (level) {
            case "debug":
                this.level = 0
                this.debug = console.debug.bind(window.console)
            case "info":
                this.level = 1
                this.log = console.log.bind(window.console)
            case "warn":
                this.level = 2
                this.warn = console.warn.bind(window.console)
            case "error":
                this.level = 3
                this.error = console.error.bind(window.console)
            case "silence":
                this.level = 4
                break;
        }
        /* eslint-enable no-console, no-fallthrough */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debug(...args: any[]) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    log(...args: any[]) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    warn(...args: any[]) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error(...args: any[]) {}
}

declare global {
    let consoler: Consoler
    interface Window {
        consoler: Consoler
    }
}

window.consoler = new Consoler("debug")
