import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-514d0881"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 1,
  class: "elements-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InspectorSideKit = _resolveComponent("InspectorSideKit")
  const _component_Overflowable = _resolveComponent("Overflowable")
  const _component_InspectorElementVue = _resolveComponent("InspectorElementVue")
  const _component_InspectorControls = _resolveComponent("InspectorControls")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: _ctx.inspector.id,
      ref: "inspector",
      class: "inspector",
      style: _normalizeStyle(_ctx.inspector_style),
      onMouseleave: _cache[0] || (_cache[0] = (...args) => (_ctx.inspector.on_mouse_leave && _ctx.inspector.on_mouse_leave(...args))),
      onMousemove: _cache[1] || (_cache[1] = (...args) => (_ctx.inspector.on_mouse_move && _ctx.inspector.on_mouse_move(...args))),
      onMouseenter: _cache[2] || (_cache[2] = (...args) => (_ctx.inspector.on_mouse_enter && _ctx.inspector.on_mouse_enter(...args)))
    }, [
      (!_ctx.inspector.side_kit_closed && _ctx.inspector.is_active && !_ctx.inspector.is_hidden && _ctx.in_viewport)
        ? (_openBlock(), _createBlock(_component_Overflowable, {
            key: 0,
            auto_z_index: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InspectorSideKit, {
                side: _ctx.side_kit_side,
                inspector: _ctx.inspector,
                max_width: _ctx.side_kit_side == 'left' ? _ctx.space_on_left : _ctx.space_on_right
              }, null, 8 /* PROPS */, ["side", "inspector", "max_width"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (!_ctx.is_resizing && _ctx.inspector.active_snapshot != null && !_ctx.inspector.active_snapshot.is_error && !_ctx.inspector.is_hidden && _ctx.in_viewport)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_InspectorElementVue, {
              ref: "root_inspector_html_element",
              inspector_element: _ctx.inspector.active_snapshot.root_inspector_element
            }, null, 8 /* PROPS */, ["inspector_element"])
          ], 512 /* NEED_PATCH */)), [
            [_vShow, _ctx.inspector.is_active]
          ])
        : _createCommentVNode("v-if", true)
    ], 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1),
    (!_ctx.inspector.is_hidden && _ctx.in_viewport)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "clip-overlay",
          style: _normalizeStyle({clipPath: _ctx.clip_path})
        }, null, 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    (_ctx.inspector.clicked_coordinates != null && !_ctx.inspector.is_hidden && _ctx.inspector.is_active && _ctx.in_viewport)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "clicked-dot",
          style: _normalizeStyle(_ctx.dot_style)
        }, null, 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    (_ctx.inspector.is_live && _ctx.in_viewport)
      ? (_openBlock(), _createBlock(_component_InspectorControls, {
          key: 2,
          inspector: _ctx.inspector
        }, null, 8 /* PROPS */, ["inspector"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}