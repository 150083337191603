export function highlight_merged_code(cm: CodeMirror.Editor) {
    cm.on("changes", (cm, changes) => {
        changes.filter(change => change.origin == "merge")
               .forEach(change => {
                   const from = { line: change.from.line, ch: change.from.ch }
                   const to = { line: from.line + change.text.length - 1, ch: 999 }
                   const mark = cm.markText(from, to, { className: "merged-code-background" })
                   setTimeout(function() {
                       mark.clear();
                   }, 2000)
               })
    })
}
