export function copy_text_to_clipboard(text: string, showNotification = true) {
    if (!navigator.clipboard) {
        fallback_copy_text_to_clipboard(text, showNotification);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
        if (showNotification) {
            toastr.info('Copied', '', {
                timeOut: 500,
                showDuration: 500,
            });
        }
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}

function fallback_copy_text_to_clipboard(text: string, showNotification = true) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        // @ts-ignore
        if (successful == 'successfull') {
            if (showNotification) {
                toastr.info('Copied', '', {
                    timeOut: 500,
                    showDuration: 500,
                });
            }
        }
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
