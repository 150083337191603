<template>
  <div class="container">
    <div
        v-if="dnd.state.is_dragging && dnd.state.is_files"
        class="dnd-over-drop-area"
        @dragover="(e) => on_drag_over(e)"
        @drop="(e) => on_files_drop(e)"
    >
    </div>
    <template v-if="apps_tree.computed.children.length == 0">
      <div class="row"
           style="height: calc(100% - 30px); margin-top: 10px;">
        <div class="col s12 vertical align-center">
          <div class="row">
            <div class="col s12 center">
              <span>No available apps</span>

            </div>
          </div>
          <div class="row">
            <div class="col s12 center">
              <Button
                  text="Upload app"
                  :min_width="170"
                  icon_class="fa-solid fa-plus"
                  @click="App.setup_file_picker_upload()"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s12 center">
              <Button
                  text="Upload from URL"
                  icon_class="fa-solid fa-link"
                  :min_width="170"
                  @click="App.show_upload_from_url_form()"
              />
            </div>
          </div>
          <div class="row"
               style="height: 100%;">
            <div class="col s12 center">
              Or Drag 'n' Drop here
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Tree
          :tree="apps_tree"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Tree from "../../../tree/Tree.vue";
import { TestaTree } from "../../../tree/tree";
import Button from "../../../Button.vue";
import { App } from "../../../../../vue_record/models/app";
import DropEvent = JQuery.DropEvent;

export default defineComponent({
    components: { Button, Tree },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        App() {
            return App
        },
        apps_tree() {
            return TestaTree.Tree.get_apps_tree(current.project_version)
        },
        dnd() {
            return dnd
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_files_drop(e: DragEvent) {
            e.preventDefault();
            e.stopPropagation();
            App.read_dropped_files_and_upload(e.dataTransfer.items)
        },
        on_drag_over(e: DragEvent) {
            e.preventDefault();
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;

  .dnd-over-drop-area {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    position: absolute;
  }
}
</style>
