import { PlaySandbox } from "../../models/play/play_sandbox";
import { PlayLogScope } from "../../scopes/play/play_log_scope";
import { PhoneScope } from "../../scopes/phone_scope";
import { PlayWorkerGroup } from "../../models/play/play_worker_group";
import { Phone } from "../../models/phone";
import { PlayLog } from "../../models/play/play_log";


declare module "../../models/play/play_sandbox" {
    interface PlaySandbox {
        play_worker_group: PlayWorkerGroup
        in_use_phones: PhoneScope

        play_logs: PlayLogScope
    }
}

PlaySandbox.belongs_to("play_worker_group", PlayWorkerGroup, "play_worker_group_id")
PlaySandbox.has_many("in_use_phones", Phone, "in_use_by_play_sandbox_id")


// has_many
PlaySandbox.has_many("play_logs", PlayLog, "play_sandbox_id")
