import { reactive } from "vue";
import { Validator } from "./validator";
import { ValidatorOpts } from "./validator";
import _ from "lodash";
import { ref } from "vue";
import { Ref } from "vue";
import { generate_uuid } from "../generate/generate_uuid";

export interface FormValidatorOpts {
    on_invalid_submit_wiggle?: boolean

    on_invalid_submit?(): boolean | void
}

var default_form_validator_opts: FormValidatorOpts = {
    on_invalid_submit_wiggle: true,
}


export class FormValidator {
    validators: { [key: string]: Validator } = {};
    errors: { [key: string]: string[] } = reactive({})
    valid: boolean = true
    invalid: boolean = false
    parent_form_validator: FormValidator = null;
    child_form_validators: { [key: string]: FormValidator } = {}
    id: string
    opts: FormValidatorOpts

    constructor(id = generate_uuid(), parent_form_validator: FormValidator = null, opts: FormValidatorOpts = default_form_validator_opts) {
        this.set_opts(opts)
        this.parent_form_validator = parent_form_validator
        this.id = id
    }

    set_opts(opts: FormValidatorOpts) {
        this.opts = _.merge(_.cloneDeep(default_form_validator_opts), opts)
    }

    set_errors(id: string, errors: string[]) {
        this.errors[id] = errors
        this.set_validity()
    }

    register(id: string, opts: ValidatorOpts): Validator {
        if (this.validators.hasOwnProperty(id)) {
            const validator = this.validators[id]
            validator.set_opts(opts)
            return validator
        } else {
            const validator = new Validator(id, opts, this)
            this.validators[id] = validator
            return validator
        }
    }

    register_child_form(id: string, opts: FormValidatorOpts = default_form_validator_opts): FormValidator {
        if (this.child_form_validators.hasOwnProperty(id)) {
            const form_validator = this.child_form_validators[id];
            form_validator.set_opts(opts);
            return form_validator
        } else {
            const form_validator = new FormValidator(id, this, opts)
            this.child_form_validators[id] = form_validator
            return form_validator;
        }
    }

    unregister_validator(id: string) {
        delete this.validators[id]
        delete this.errors[id]
        this.set_validity()
    }

    unregister() {
        this.parent_form_validator?.unregister_child_form(this.id)
    }

    unregister_child_form(id: string) {
        delete this.child_form_validators[id]
        this.set_validity()
    }

    set_validity() {
        const child_forms_valid = Object.values(this.child_form_validators)
                                        .map(ch => ch.valid)
                                        .every(v => v)

        this.valid = Object.values(this.errors).flat().length == 0 && child_forms_valid

        this.invalid = !this.valid
        if (this.parent_form_validator != null) this.parent_form_validator.set_validity();
    }

    wiggle() {
        if (this.opts.on_invalid_submit_wiggle) {
            Object.values(this.child_form_validators).forEach(fv => fv.wiggle())

            Object.values(this.validators)
                  .filter(validator => validator.invalid)
                  .map(validator => validator.get_element != null ? validator.get_element() : null)
                  .filter(e => e != null)
                  .map(e => $(e))
                  .forEach($elem => $elem.wiggle())
        }
    }

    on_confirm_click() {
        if (this.valid) return;

        this.wiggle();
        return this.run_on_invalid_submit_callbacks();
    }

    run_on_invalid_submit_callbacks() {
        const r = Object.values(this.child_form_validators).every(fv => {
            return !!fv.run_on_invalid_submit_callbacks()
        })
        if (r == false) return false

        if (this.invalid && this.opts.on_invalid_submit != null) {
            return !!this.opts.on_invalid_submit()
        } else {
            return true
        }
    }
}
