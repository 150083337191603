import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumPlayStatus } from "../../../auto_generated/enums";
import { Browser as ScenarioSettingBrowser } from "../../models/scenario_setting/browser";
import { EnumBrowserValues } from "../../../auto_generated/enums";
import { Phone as ScenarioSettingPhone } from "../../models/scenario_setting/phone";
import { EnumPhoneType } from "../../../auto_generated/enums";
import { PhoneApp as ScenarioSettingPhoneApp } from "../../models/scenario_setting/phone_app";
import { PlayGroupClient } from "../../clients/play/play_group_client";
import { PlayGroupScope } from "../../scopes/play/play_group_scope";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";
import { ScenarioSetting } from "../scenario_setting";

// <editor-fold desc="TYPES">
export interface PlayGroupProps extends Props {
    id: number
    play_id: number
    group_savepoint_id: number
    scenario_folder_savepoint_id: number
    duration: number
    status: EnumPlayStatus
    current_line_number: number
    total_lines_number: number
    name: string

    // from methods
    progress: number
    success_rate: number
    "running?": boolean
    "finished?": boolean
}
export type QuerifiedPlayGroupProps = QuerifyProps<PlayGroupProps>
export type PlayGroupCreateProps = Omit<PlayGroupProps, 'id'>
export type PlayGroupUpdateProps = Partial<PlayGroupProps>

export interface PlayGroupState extends State {}
export interface PlayGroupComputed extends Computed {}
export interface PlayGroupStaticState extends StaticState {}


export interface Grouping {
    replace_id: number
    original_id: number
    scenario_names: string
    save_in_progress: boolean
}

export interface BrowserGrouping extends Grouping {
    browsers: ScenarioSettingBrowser[]
    replace_type: EnumBrowserValues
    replace_emulation_profile_name: string
    replace_is_mobile: boolean
    replace_width: number
    replace_height: number
    original_type: EnumBrowserValues
    original_emulation_profile_name: string
    original_is_mobile: boolean
    original_width: number
    original_height: number
}

export interface PhoneGrouping extends Grouping {
    phones: ScenarioSettingPhone[]
    replace_type: EnumPhoneType
    original_type: EnumPhoneType
}

export interface AppGrouping extends Grouping {
    apps: ScenarioSettingPhoneApp[]
    replace_package: string
    replace_type: string
    original_package: string
    original_type: string
}

export interface ProxyGrouping extends Grouping {
    scenario_settings: ScenarioSetting[]
}
// </editor-fold>

const console = new Consoler("warn")
export class PlayGroup extends VueRecord {
    ['constructor']: typeof PlayGroup

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlayGroupClient
    static ScopeClass = PlayGroupScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlayGroupStaticState = reactive<PlayGroupStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_id", "status"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlayGroup> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlayGroup>> = {}

    static field_validators: ModelValidatorOpts<PlayGroupProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_GROUP
    static resource_id = Enum.Resource.Id.PLAY_GROUP
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlayGroupClient
    declare props: PlayGroupProps;
    declare state: PlayGroupState;
    declare computed: PlayGroupComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlayGroup.register_resource(PlayGroup)
PlayGroupClient.ModelClass = PlayGroup
PlayGroupScope.ModelClass = PlayGroup


declare global {
    interface Window {
        PlayGroup: typeof PlayGroup
    }
}
window.PlayGroup = PlayGroup
// </editor-fold>

