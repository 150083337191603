import { VariableSet } from "../models/variable_set"
import { Snippet } from "../models/snippet";
import { ProjectVersion } from "../models/project_version";


declare module "../models/variable_set" {
    interface VariableSet {
        project_version: ProjectVersion
        snippet: Snippet
    }
}
VariableSet.belongs_to("project_version", ProjectVersion, "project_version_id")
VariableSet.belongs_to("snippet", Snippet, "snippet_id")


