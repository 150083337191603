import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "bar",
    style: _normalizeStyle(_ctx.bar_style)
  }, [
    _createElementVNode("div", {
      class: "progress",
      style: _normalizeStyle(_ctx.progress_style)
    }, null, 4 /* STYLE */)
  ], 4 /* STYLE */))
}