import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "row nowrap",
  style: {"margin-top":"-20px","margin-bottom":"0"}
}
const _hoisted_2 = { class: "col s3" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "col s6" }
const _hoisted_6 = { class: "col s3" }
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  class: "row",
  style: {"height":"calc(100% - 45px)"}
}
const _hoisted_10 = {
  key: 0,
  class: "col s12 vertical",
  style: {"height":"100%","width":"100%","padding":"0"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Select2 = _resolveComponent("Select2")
  const _component_ActionIcon = _resolveComponent("ActionIcon")
  const _component_SnippetEditor = _resolveComponent("SnippetEditor")
  const _component_CreateVariableSetModal = _resolveComponent("CreateVariableSetModal")
  const _component_RenameVariableSetModal = _resolveComponent("RenameVariableSetModal")
  const _component_SettingsItem = _resolveComponent("SettingsItem")

  return (_openBlock(), _createBlock(_component_SettingsItem, {
    id: _ctx.setting_items.project.variables.id,
    title: _ctx.setting_items.project.variables.title,
    padding: "10px 0 0 0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Select2, {
            id: "variable_sets_select",
            modelValue: _ctx.active_variable_set_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.active_variable_set_id) = $event)),
            dropdown_parent: "#vue_settings_modal",
            label: "Variable set"
          }, {
            default: _withCtx(() => [
              _createElementVNode("option", {
                value: _ctx.default_variable_set.props.id
              }, _toDisplayString(_ctx.default_variable_set.props.name), 9 /* TEXT, PROPS */, _hoisted_3),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variable_sets, (variable_set) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: variable_set.props.id,
                  value: variable_set.props.id
                }, _toDisplayString(variable_set.props.name), 9 /* TEXT, PROPS */, _hoisted_4))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ActionIcon, {
            id: "add_variable_set_action",
            icon_class: "fas fa-plus-circle",
            color_class: "green",
            title: "Add Variable Set",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.show_create_modal = true))
          }),
          (!_ctx.active_variable_set?.props.default)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_ActionIcon, {
                  id: "rename_variable_set_action",
                  icon_class: "fas fa-pencil-alt",
                  color_class: "white",
                  title: "Rename Variable Set",
                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.show_rename_modal = true))
                }),
                _createVNode(_component_ActionIcon, {
                  id: "remove_variable_set_action",
                  icon_class: "fas fa-trash-alt",
                  color_class: "red",
                  title: "Delete Variable Set",
                  onClick: _ctx.remove_variable_set
                }, null, 8 /* PROPS */, ["onClick"])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.current.user.props.dark_mode)
            ? (_openBlock(), _createBlock(_component_Select2, {
                key: 0,
                id: "codemirror_themes_select",
                modelValue: _ctx.user_props.codemirror_dark_theme,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.user_props.codemirror_dark_theme) = $event)),
                dropdown_parent: "#vue_settings_modal",
                label: "Theme"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Enum.User.CodeMirrorThemes.DARK, (theme) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: theme.id,
                      value: theme.id
                    }, _toDisplayString(theme.name), 9 /* TEXT, PROPS */, _hoisted_7))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_Select2, {
                key: 1,
                id: "codemirror_themes_select",
                modelValue: _ctx.user_props.codemirror_light_theme,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.user_props.codemirror_light_theme) = $event)),
                dropdown_parent: "#vue_settings_modal",
                label: "Theme"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Enum.User.CodeMirrorThemes.LIGHT, (theme) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: theme.id,
                      value: theme.id
                    }, _toDisplayString(theme.name), 9 /* TEXT, PROPS */, _hoisted_8))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"]))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_variable_sets, (variable_set) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: variable_set.props.id
          }, [
            (_ctx.visited_variable_set_ids.includes(variable_set.props.id))
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (variable_set.snippet != null)
                    ? (_openBlock(), _createBlock(_component_SnippetEditor, {
                        key: 0,
                        snippet: variable_set.snippet,
                        exclude_features: ['debounced_save'],
                        exclude_actions: ['save']
                      }, null, 8 /* PROPS */, ["snippet"]))
                    : _createCommentVNode("v-if", true)
                ], 512 /* NEED_PATCH */)), [
                  [_vShow, variable_set.props.id == _ctx.active_variable_set_id]
                ])
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      (_ctx.show_create_modal)
        ? (_openBlock(), _createBlock(_component_CreateVariableSetModal, {
            key: 0,
            project_version: _ctx.project_version,
            onExit: _ctx.on_create_exit
          }, null, 8 /* PROPS */, ["project_version", "onExit"]))
        : _createCommentVNode("v-if", true),
      (_ctx.show_rename_modal)
        ? (_openBlock(), _createBlock(_component_RenameVariableSetModal, {
            key: 1,
            variable_set: _ctx.active_variable_set,
            onExit: _cache[5] || (_cache[5] = $event => (_ctx.show_rename_modal = false))
          }, null, 8 /* PROPS */, ["variable_set"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "title"]))
}