import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { ScenarioSettingProps } from "../../models/scenario_setting";
import { PlayLogProps } from "../../models/play/play_log";
import { PlaySnippetProps } from "../../models/play/play_snippet";
import { PlayLog } from "../../../vue_record/models/play/play_log";
import { PlaySnippet } from "../../../vue_record/models/play/play_snippet";
import { PlayScenarioProps } from "../../models/play/play_scenario";
import { PlayScenarioModalResponse } from "../../models/play/play_scenario";
import { PlayScenario } from "../../models/play/play_scenario";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PlayScenarioClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlayScenario
    declare record: PlayScenario

    load_associated() {
        type LoadResponse = {
            play_scenario: PlayScenarioProps
            scenario_setting: ScenarioSettingProps
            play_logs: PlayLogProps[]
            play_snippets: PlaySnippetProps[]
            play_scenario_parts: PlayScenarioProps[]
        }
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/play_scenarios/${this.key()}/associated`,
                type: 'GET',
                success: (data: LoadResponse) => {
                    PlayScenario.new(data.play_scenario);

                    data.play_logs.forEach(play_log => {
                        PlayLog.new(play_log)
                    })

                    data.play_scenario_parts.forEach(play_scenario => {
                        PlayScenario.new(play_scenario)
                    })

                    data.play_snippets.forEach(play_snippet => {
                        PlaySnippet.new(play_snippet)
                    })
                    resolve()
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes
            })
        })
    }

    static load(id: string | number, reload = false): Promise<PlayScenario> {
        if (reload) this.ModelClass.state.load_promises[id] = null

        if (this.ModelClass.state.load_promises[id] == null) {
            const promise = new Promise<PlayScenario>((resolve, reject) => {
                $.ajax({
                    url: `/play/play_scenarios/${id}`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: PlayScenarioProps) => {
                        resolve(PlayScenario.new(data))
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
            this.ModelClass.state.load_promises[id] = promise
            promise.catch(() => {
                this.ModelClass.state.load_promises[id] = null
            })
            return promise
        } else {
            return this.ModelClass.state.load_promises[id]
        }
    }

    static play_scenario_modal(scenario_id: number) {
        return new Promise<PlayScenarioModalResponse>((resolve, reject) => {
            $.ajax({
                url: '/play/queues/play_scenario',
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    scenario_id,
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PlayScenarioModalResponse) => resolve(data),
                error: (error) => reject(error)
            })
        })
    }
}
