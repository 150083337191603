<template>
  <Modal
      id="jump_to_modal"
      :dismissible="true"
      :show_confirm="true"
      :focus_confirm="false"
      :show_cancel="true"
      :backdrop_dim_level="0"
      :cancel_on_escape="true"
      @confirm="do_jump"
      @cancel="cancel"
  >
    <template #body>
      <div class="container">
        <span class="title">
          Jump to Line:Column
        </span>
        <Input
            id="jump_to_input"
            v-model="jump_coordinates"
            placeholder="[line][:column]"
            :focus="true"
            :select_on_focus="true"
            @enter="do_jump"
        />
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../Modal.vue";
import { PropType } from "vue";
import Input from "../../../Input.vue";
import CodeMirror from "codemirror";
import { jump_to_line } from "../../../../../helpers/codemirror/helpers/jump_to_line";

export default defineComponent({
    components: { Input, Modal },
    // <editor-fold desc="PROPS">
    props: {
        cm: {
            type: Object as PropType<CodeMirror.Editor>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            jump_coordinates: this.get_jump_coordinates_from_cursor(this.cm.getCursor())
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        jump_coordinates_to_position() {
            const parts = this.jump_coordinates.split(":")
            if (parts.length > 1) return { line: parseInt(parts[0]) - 1, ch: parseInt(parts[1]) }
            return { line: parseInt(parts[0]) - 1, ch: null }
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        cancel() {
            this.$.appContext.app.unmount();
        },
        get_jump_coordinates_from_cursor(cursor: CodeMirror.Position) {
            return `${cursor.line + 1}:${cursor.ch}`
        },
        do_jump(e: KeyboardEvent) {
            if (e != null) e.preventDefault()

            jump_to_line(this.cm, this.jump_coordinates_to_position.line, this.jump_coordinates_to_position.ch)
            this.cm.focus();
            this.cancel();
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin: 5px;
  }
}
</style>
