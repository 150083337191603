import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { FeatureFlagClient } from "../clients/feature_flag_client";
import { FeatureFlagScope } from "../scopes/feature_flag_scope";
import { User } from "./user";
import { ProjectVersion } from "./project_version";
import { QuerifyProps } from "../base/vue_record_scope";
import { UserProps } from "./user";

// <editor-fold desc="TYPES">
type FeatureFlagException = {
    project?: number[]
    project_version?: number[]
    user?: number[]
}

export interface FeatureFlagProps extends Props {
    id: number
    name: string
    description: string
    enabled: boolean
    enabled_for: FeatureFlagException
    disabled_for: FeatureFlagException
    created_at: Date
    updated_at: Date
}
export type QuerifiedFeatureFlagProps = QuerifyProps<FeatureFlagProps>

export interface FeatureFlagState extends State {}
export interface FeatureFlagComputed extends Computed {}
export interface FeatureFlagStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class FeatureFlag extends VueRecord {
    ['constructor']: typeof FeatureFlag

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = FeatureFlagClient
    static ScopeClass = FeatureFlagScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: FeatureFlagStaticState = reactive<FeatureFlagStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "enabled"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof FeatureFlag> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof FeatureFlag>> = {}

    static field_validators: ModelValidatorOpts<FeatureFlagProps> = {}

    static resource_name = Enum.Resource.Label.FEATURE_FLAG
    static resource_id = Enum.Resource.Id.FEATURE_FLAG
    static icon_class = "fa-solid fa-cube"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: FeatureFlagClient
    declare props: FeatureFlagProps;
    declare state: FeatureFlagState;
    declare computed: FeatureFlagComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    static is_enabled(name: string,
                      user: User = current.user,
                      project_version: ProjectVersion = current.project_version,
                      description: string = null) {
        const feature_flag = FeatureFlag.where({ name }).first()
        if (feature_flag == null && current_role == Enum.User.Role.SUPERADMIN) {
            console.warn(`** Feature flag: ${name} is MISSING --> CREATING **`);
            FeatureFlagClient.create(name, description, project_version?.key())
            return false;
        } else if (feature_flag == null) return false

        const check = (feature_flag: FeatureFlag) => {
            let exception_field_name, exception_value;
            if (feature_flag.props.enabled) {
                exception_field_name = "disabled_for"
                exception_value = false
            } else {
                exception_field_name = "enabled_for"
                exception_value = true
            }

            const exceptions = feature_flag.props[exception_field_name]
            if (user) {
                if (exceptions.user?.some((user_id: number) => user_id == user.props.id)) return exception_value
            } else if (project_version) {
                if (exceptions.project_version?.some((pv_id: number) => pv_id == project_version.props.id)) return exception_value
                if (exceptions.project?.some((project_id: number) => project_id == project_version.props.project_id)) return exception_value
            }

            return !exception_value
        }
        const return_value = check(feature_flag);
        console.log(`** Checking feature flag: ${name} ** (${return_value ? "ENABLED" : "DISABLED"})`);
        return return_value;
    }

    // <editor-fold desc="ACTIONS">
    static delete(ids: number | number[]) {
        if (!Array.isArray(ids)) ids = [ids]
        const scope = this.where({ id: ids })
        const content_text = scope.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => this.ClientClass.delete(ids)
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
FeatureFlag.register_resource(FeatureFlag)
FeatureFlagClient.ModelClass = FeatureFlag
FeatureFlagScope.ModelClass = FeatureFlag

on_dom_content_loaded(() => {
    FeatureFlag.sync(`/sync/feature_flags`);
})

if (globalThis.feature_flags_props) {
    feature_flags_props.forEach(feature_flag_props => FeatureFlag.new(feature_flag_props))
}

declare global {
    var feature_flags_props: FeatureFlagProps[]

    interface Window {
        FeatureFlag: typeof FeatureFlag
    }
}
window.FeatureFlag = FeatureFlag
// </editor-fold>

