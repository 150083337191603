import { TabManager } from "./tab_manager";
import _ from "lodash";
import { Editor } from "./editor";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { KEY } from "../../../types/globals";
import { generate_eid } from "../../../helpers/generate/generate_eid";
import { nextTick } from "vue";
import { EventBus } from "../../../helpers/event_bus";
import { SplitterRegion } from "./splitter_region";
import { UiSync } from "../../../helpers/ui_sync/ui_sync";
import UiCreateTabData = UiSync.UiCreateTabData;
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { Splitter } from "./splitter";
import { Snippet } from "../../../vue_record/models/snippet";
import { Group } from "../../../vue_record/models/group";
import { File } from "../../../vue_record/models/file";
import { Image } from "../../../vue_record/models/image";
import { Play } from "../../../vue_record/models/play/play";
import { VueRecord } from "../../../vue_record/base/vue_record";
import { reactive } from "../../../helpers/vue/reactive";
import { computed } from "../../../helpers/vue/computed";

export type TabTarget = Editor | Splitter | TabManager

export type TabTargetOpts = {
    editor?: Editor
    splitter?: Splitter
    tab_manager?: TabManager,
}

export function resolve_tab_target(opts: TabTargetOpts, default_editor = Editor.get_main()) {
    if (opts.tab_manager != null) return opts.tab_manager
    if (opts.splitter != null) return opts.splitter
    if (opts.editor != null) return opts.editor
    return default_editor
}

const console = new Consoler("warn")
export namespace Tab {
    export type Input = {
        id: string
        allow_duplicate?: boolean
        splittable?: boolean

        [key: string]: any
    }

    export interface CreateInput extends Input {
        type: string

        [key: string]: any
    }

    export type State = {
        is_editor_mounted: boolean
        loaded: boolean
        last_activation: Date
        record: VueRecord
        icon: {
            class: string,
            color: string,
            scale: number
        },
        active_indicator: IndicatorStyle
        indicator: Indicator,
        error: {
            is_error: boolean,
            error_object: any
            message: string
            backtrace: string[]
        }
    }

    export type IndicatorStyle = "success" | "error" | "progress" | "none"
    export type Indicator = {
        style: IndicatorStyle
        color: string
        progress?: number
        timeout?: NodeJS.Timeout
    }

    export type Contextmenu = {
        build: (tab: Tab) => ContextMenu.Items
    }

    export interface DragAndDrop {
        on_start?: (e: DragEvent, tab: Tab) => void
        on_end?: (e: DragEvent, tab: Tab) => void
    }

    export type Events =
        "added" // tab is added to tab_manager
        | "before_remove" // before tab is removed from currently assigned tab_manager (is also called when moving tab)
        | "before_close" // before user closes the tab (will no longer exist in any tab_manager). triggered after before_remove
        | "activated"
        | "deactivated"
        | "loaded"
        | "dragstart"
        | "dragend"
        | "keydown"
        | "mousedown"
        | "contextmenu"
        | "editor_mounted"
        | "focused"
        | "dragenter"
        | "dragover"
        | "dragleave"
        | "drop"
}

export const default_tab_input: Tab.Input = {
    id: "unset",
    allow_duplicate: false,
    splittable: true
}

export class Tab {
    ["constructor"]: Tab
    static registered_tab_types: typeof Tab[] = []
    static type = "Tab"
    static tab_component_data_cache: Record<string, { updated_at: Date, data: any }> = {}

    component: any
    component_props: any
    component_instance: any

    tab_manager: TabManager
    contextmenu: Tab.Contextmenu

    // these 3 parameters will persist in local storage
    id: string
    type: string
    input: Tab.CreateInput

    instance_id: string
    content_element_id: string
    state = reactive<Tab.State>({
        active_indicator: "none",
        is_editor_mounted: false,
        loaded: false,
        last_activation: null,
        record: null,
        icon: {
            class: "fa-regular fa-file",
            color: get_css_var("--button-white"),
            scale: 1
        },
        indicator: {
            style: "none",
            color: "var(--button-white)",
            progress: 0,
            timeout: null,
        },
        error: {
            is_error: false,
            error_object: null,
            message: "",
            backtrace: []
        }
    })

    computed: {
        title: string
    }

    load: () => Promise<any>
    dnd: Tab.DragAndDrop

    event_bus: EventBus<Tab.Events, Tab>

    constructor(data: Tab.Input) {
        console.debug("creating new tab with input: ", data)
        this.instance_id = generate_eid();
        this.content_element_id = `tab_content_${this.instance_id}`;
        this.event_bus = new EventBus<Tab.Events, Tab>()
        this._register_default_event_handlers()
        this.id = data.id
        this.input = { ...data, type: this.constructor.type };
        this.computed = reactive({
            title: computed(() => " "),
        })
        this.contextmenu = this._default_contextmenu()

        this.dnd = reactive({})
    }

    static new<T extends typeof Tab>(this: T, data: Tab.Input): InstanceType<T> {
        data = _.merge(_.cloneDeep(default_tab_input), data)
        if (!data.allow_duplicate) {
            const existing_tab = Editor.find_tab(data.id)
            if (existing_tab) {
                existing_tab.set_active(true);
                return existing_tab as unknown as InstanceType<T>
            }
        }
        return new this(data)as unknown as InstanceType<T>
    }

    // <editor-fold desc="ACTIONS">
    close() {
        console.log("closing tab: ", this)
        this.tab_manager?.remove_tab(this)
        this._emit("before_close")
    }

    close_other() {
        this.tab_manager
            ?.state
            ?.tabs
            ?.filter(t => t.id != this.id)
            ?.forEach(t => t.close());
    }

    activate_previous_tab() {
        if (this.tab_manager == null) return

        const index = this.index()
        if (index <= 0) return

        this.tab_manager.computed.sorted_tabs[index - 1].set_active(true)
    }

    activate_next_tab() {
        if (this.tab_manager == null) return

        const index = this.index()
        if (index >= this.tab_manager.computed.sorted_tabs.length) return

        this.tab_manager.computed.sorted_tabs[index + 1].set_active(true)
    }

    show_context_menu(e: KeyboardEvent | MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        const selector = ".tab-manager-container .tab"
        $.contextMenu("destroy", selector);


        if (this.contextmenu == null) return;


        let position_left: number, position_top: number;

        const window_height = $(window).height() - 5;
        const window_width = $(window).width() - 5;
        $.contextMenu({
            selector,
            zIndex: 10,
            events: {
                show: (options) => {
                    setTimeout(() => {
                        this._get_html_element().blur()
                        options.$menu[0].focus()
                    }, 1)
                },
                hide: () => {
                    // this._get_html_element()?.focus()
                },
            },
            position: (opt) => {
                const menu_height = opt.$menu.outerHeight()
                const menu_width = opt.$menu.outerWidth();
                const overflow_height = (position_top + menu_height) - window_height;
                const overflow_width = (position_left + menu_width) - window_width;
                if (overflow_height > 0) position_top = position_top - overflow_height;
                if (overflow_width > 0) position_left = position_left - overflow_width;
                opt.$menu.css({ top: position_top, left: position_left });
            },
            build: () => {
                const items = this.contextmenu.build(this);
                return {
                    items,
                    callback: () => {
                    }
                }
            },
        });

        if (e.type == "mousedown") {
            position_top = (e as MouseEvent).pageY;
            position_left = (e as MouseEvent).pageX;
        } else {
            const ele = this._get_html_element()
            const rect = ele.getBoundingClientRect();
            position_top = rect.top + rect.height / 2;
            position_left = rect.left + rect.width / 2;
            $(this._get_html_element()).trigger('contextmenu')
        }
    }

    split_right() {
        const splitter = this.tab_manager?.parent
        if (splitter == null) throw new Error("Tab not attached to dom.")
        const right_tab_manager = this.tab_manager.get_or_create_right_tab_manager(false)
        this.input.allow_duplicate = true
        right_tab_manager.create_tab(this.input);
    }

    split_down() {
        const splitter = this.tab_manager?.parent
        if (splitter == null) throw new Error("Tab not attached to dom.")
        const down_tab_manager = this.tab_manager.get_or_create_down_tab_manager(false)
        this.input.allow_duplicate = true
        down_tab_manager.create_tab(this.input);
    }

    move_up() {
        const up_tab_manager = this.tab_manager.get_up_tab_manager(true);
        up_tab_manager.add_tab(this);
    }

    move_right() {
        const right_tab_manager = this.tab_manager.get_or_create_right_tab_manager(true)
        right_tab_manager.add_tab(this)
    }

    move_down() {
        const down_tab_manager = this.tab_manager.get_or_create_down_tab_manager(true);
        down_tab_manager.add_tab(this);
    }

    move_left() {
        const left_tab_manager = this.tab_manager.get_left_tab_manager(true);
        left_tab_manager.add_tab(this);
    }


    set_saved_indicator() {
        if (this.state.active_indicator != "error") {
            this.state.indicator.color = "rgb(13 151 12 / 76%)";
            const before = _.cloneDeep(this.state.indicator)
            this.clear_indicator()
            this.state.active_indicator = "success"
            this.state.indicator.style = "success"
            this.state.indicator.timeout = setTimeout(() => {
                this.state.indicator = before
            }, 5000)
        }
    }

    set_error_indicator() {
        this.clear_indicator()
        this.state.active_indicator = "error"
        this.state.indicator.style = "error"
        this.state.indicator.color = "var(--button-red)";
    }

    /** @param progress - number 0 - 100 */
    set_progress_indicator(progress: number) {
        if (this.state.active_indicator == "progress") {
            this.clear_indicator()
            this.state.active_indicator = "progress"
            this.state.indicator.style = "progress"
            this.state.indicator.color = "var(--button-white)";
            this.state.indicator.progress = progress
        }
    }

    clear_indicator() {
        this.state.active_indicator = "none"
        this.state.indicator.style = "none";
        if (this.state.indicator.timeout) clearTimeout(this.state.indicator.timeout)
    }
    // </editor-fold>

    // <editor-fold desc="STATE MANAGEMENT">
    is_active() {
        return this.tab_manager?.state?.active_tab?.instance_id == this.instance_id
    }

    set_active(state: boolean) {
        if (this.tab_manager == null) return;

        if (state) {
            this.tab_manager.set_active_tab(this)
        } else {
            this.tab_manager.set_active_tab(null)
        }
    }

    set_loaded(state: boolean) {
        this.state.loaded = state;
        if (this.state.loaded) this._emit("loaded")
    }

    set_editor_mounted(state: boolean) {
        this.state.is_editor_mounted = state;
        if (this.state.is_editor_mounted) this._emit("editor_mounted")
    }

    set_component_instance(instance: any, restore_data: boolean) {
        this.component_instance = instance
        if (restore_data) {
            const cache = Tab.tab_component_data_cache[this.id]
            if (cache != null) {
                for (const key in cache.data) {
                    instance.$data[key] = cache.data[key]
                }
            }
        }
    }
    // </editor-fold>

    // <editor-fold desc="EVENT HANDLING">
    on(event: Tab.Events, callback: (tab: Tab, e: any) => void) {
        if (event == "loaded" && this.state.loaded) callback(this, null)
        if (event == "editor_mounted" && this.state.is_editor_mounted) callback(this, null)
        this.event_bus.$on(event, callback)
    }

    on_drag_drop(e: DragEvent, area: "main" | "right" | "down") {
        // special case for scenario builder tab.
        if (this.id == Editor.get_scenario_builder_tab()?.id) {
            return; // do nothing, scenario_builder_tab.js has this handled.
        }

        this._emit("drop", e)
        this.tab_manager.set_drag_over(false)
        let tab_manager: TabManager;
        let new_splitter: Splitter
        switch (area) {
            case "main":
                tab_manager = this.tab_manager;
                break;
            case "right":
                new_splitter = this.tab_manager.parent.splitter.split("left_right", true)
                new_splitter._ensure_region_not_null(false, false)
                tab_manager = new_splitter.state.other.object as TabManager
                break;
            case "down":
                new_splitter = this.tab_manager.parent.splitter.split("top_bottom", true)
                new_splitter._ensure_region_not_null(false, false)
                tab_manager = new_splitter.state.other.object as TabManager
                break;
            default:
                tab_manager = this.tab_manager;
        }
        if (dnd.state.tabs.length > 0) {
            dnd.state.tabs.forEach(t => tab_manager.add_tab(t as Tab))
        } else if (dnd.state.records.length > 0) {
            dnd.state.records.forEach(t => {
                switch (t.constructor.resource_id) {
                    case Enum.Resource.Id.SNIPPET:
                    case Enum.Resource.Id.GROUP:
                    case Enum.Resource.Id.FILE:
                    case Enum.Resource.Id.IMAGE:
                    case Enum.Resource.Id.PLAY:
                        (t as unknown as Snippet | Group | File | Image | Play).open({ tab_manager })
                        break;
                }
            })
        }
    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    index() {
        return this.tab_manager?.computed?.sorted_tabs?.indexOf(this)
    }

    scroll_into_view() {
        const html = this._get_html_element();
        const opts: ScrollIntoViewOptions = { behavior: "smooth", block: "nearest", inline: "nearest" }
        if (html == null) {
            nextTick(() => {
                this._get_html_element()?.scrollIntoView(opts);
            })
        } else {
            html.scrollIntoView(opts);
        }
    }

    static register_tab_type(TabClass: typeof Tab) {
        if (Tab.registered_tab_types.includes(TabClass)) {
            throw new Error(`${TabClass.name} tab type already registered`)
        }
        Tab.registered_tab_types.push(TabClass)
    }

    get_editor() {
        return this.tab_manager?.get_editor()
    }

    get_height() {
        if (this.tab_manager?.parent?.get_html_element() == null) return null
        if (this.tab_manager.state.position == "top" || this.tab_manager.state.position == "bottom") {
            return this.tab_manager?.parent?.get_html_element()?.clientHeight - this.tab_manager._get_tabs_html_element().getBoundingClientRect().height
        } else {
            return this.tab_manager?.parent?.get_html_element()?.clientHeight
        }
    }

    get_width() {
        if (this.tab_manager?.parent?.get_html_element() == null) return null
        if (this.tab_manager.state.position == "left" || this.tab_manager.state.position == "right") {
            return this.tab_manager?.parent?.get_html_element()?.clientWidth - this.tab_manager._get_tabs_html_element().getBoundingClientRect().width
        } else {
            return this.tab_manager?.parent?.get_html_element()?.clientWidth
        }
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _set_and_call_load_function(load_fn: () => Promise<any>) {
        this.load = () => {
            this.state.loaded = false
            this.state.error.is_error = false
            this.clear_indicator();
            return load_fn()
        }
        this.load()
    }

    _on_load_error(error: any) {
        this.state.error.is_error = true
        this.state.error.error_object = error
        if (error != null) {
            if (error.hasOwnProperty("message")) {
                this.state.error.message = error?.message
            } else if (error.hasOwnProperty("responseJSON")) {
                const response_json = error.responseJSON as any
                if (response_json.hasOwnProperty("user_message")) {
                    this.state.error.message = response_json.user_message
                } else if (response_json.hasOwnProperty("original_error")) {
                    this.state.error.message = response_json.original_error
                } else if (response_json.hasOwnProperty("message")) {
                    this.state.error.message = response_json.message
                }

                if (response_json.hasOwnProperty("backtrace")) {
                    this.state.error.backtrace = response_json.backtrace
                }
            } else if (error.hasOwnProperty("statusText")) {
                this.state.error.message = error?.statusText
            }
        }

        this.set_error_indicator()

        console.error(error)
    }

    _get_html_element(): HTMLElement {
        if (this.tab_manager == null) return null
        return $(this.tab_manager._get_html_element()).find(`#${this.instance_id}`)[0]
    }

    _get_content_html_element(): HTMLElement {
        if (this.tab_manager == null) return null;
        return this.tab_manager._get_html_element()?.querySelector(`#${this.content_element_id}`)
    }

    _set_tab_manager(tab_manager: TabManager) {
        this.tab_manager = tab_manager
        this._emit("added")
    }

    _default_contextmenu() {
        return {
            build: () => {
                const items: ContextMenu.Items = {}

                // <editor-fold desc="SPLIT n MOVE">
                if (!this.tab_manager.state.single_mode) {
                    items.split_right = {
                        name: "Split Right",
                        icon: "fa-solid fa-divide fa-rotate-90",
                        color: get_css_var("--button-white"),
                        callback: () => {
                            this.split_right()
                        },
                    }

                    items.split_down = {
                        name: "Split Down",
                        icon: "fa-solid fa-divide",
                        color: get_css_var("--button-white"),
                        callback: () => {
                            this.split_down()
                        },
                    }

                    if (this.tab_manager.get_up_tab_manager(true) != null) {
                        items.move_up = {
                            name: `Move Up`,
                            icon: "fa-regular fa-square-caret-up",
                            color: get_css_var("--button-white"),
                            callback: () => {
                                this.move_up();
                            }
                        }
                    }

                    if (this.get_editor()?.get_tabs()?.length > 1) {
                        items.move_right = {
                            name: "Move Right",
                            icon: "fa-regular fa-square-caret-right",
                            color: get_css_var("--button-white"),
                            callback: () => {
                                this.move_right()
                            }
                        }


                        items.move_down = {
                            name: "Move Down",
                            icon: "fa-regular fa-square-caret-down",
                            color: get_css_var("--button-white"),
                            callback: () => {
                                this.move_down();
                            }
                        }
                    }

                    if (this.tab_manager.get_left_tab_manager(true) != null) {
                        items.move_left = {
                            name: `Move Left`,
                            icon: "fa-regular fa-square-caret-left",
                            color: get_css_var("--button-white"),
                            callback: () => {
                                this.move_left();
                            }
                        }
                    }
                }
                // </editor-fold>

                // <editor-fold desc="CLOSING">
                items.close = {
                    name: "Close",
                    icon: "fa-solid fa-xmark",
                    color: get_css_var("--button-red"),
                    key: "middle-click",
                    callback: () => {
                        this.close()
                    }
                }

                if (this.tab_manager?.state?.tabs?.length > 1) {
                    items.close_other = {
                        name: "Close Other",
                        icon: "fa-solid fa-xmark",
                        color: get_css_var("--button-red"),
                        callback: () => {
                            this.close_other()
                        }
                    }
                }
                // </editor-fold>


                return items
            }
        }
    }

    // <editor-fold desc="INTERNAL EVENT HANDLERS">
    _emit(event: Tab.Events, e: any = null) {
        this.event_bus.$emit(event, this, e)
        this.tab_manager?.tab_event_bus?.$emit(event, this, e);
        let splitter = this.tab_manager?.parent?.splitter
        let editor: Editor;
        while (splitter != null) {
            splitter.tab_event_bus.$emit(event, this, e);
            const parent = splitter.parent
            if (parent == null || parent instanceof Editor) {
                splitter = null
                editor = parent as Editor
                break;
            }
            splitter = (parent as SplitterRegion).splitter
        }
        editor?.tab_event_bus?.$emit(event, this, e);
    }

    _on_activated() {
        this.state.last_activation = new Date();
        this.scroll_into_view()
    }

    _on_focused() {
        this.state.last_activation = new Date();
    }

    _on_loaded() {
        if (this.is_active()) {
            nextTick(() => this.scroll_into_view());
        }
    }

    _on_before_remove() {
        // NOTE: do not remove reference to old tab manager,
        // because, when closing tab in context menu, we still reference the tab and tab manager
        // this.tab_manager = null;

        if (this.component_instance != null) {
            Tab.tab_component_data_cache[this.id] = {
                updated_at: new Date(),
                data: this.component_instance.$data
            }
        }
    }

    _on_before_close() {
        delete Tab.tab_component_data_cache[this.id]
    }

    _on_keydown(tab: Tab, e: KeyboardEvent) {
        let intercepted = false;
        switch (e.keyCode) {
            case KEY.ENTER:
                this.set_active(true);
                intercepted = true;
                break;
            case KEY.DEL:
                this.close();
                intercepted = true;
                break;
        }

        if (intercepted) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    _on_dragstart(tab: Tab, e: DragEvent) {
        if (this.tab_manager.state.single_mode) {
            e.preventDefault();
            return;
        }

        console.debug("on drag start")
        dnd.start_with_tabs([this])
        if (this.dnd.on_start != null) this.dnd.on_start(e, this)
    }

    _on_dragend() {
        dnd.end()
    }

    _on_mousedown(tab: Tab, e: MouseEvent) {
        if (e.button == 2) {
            // right button
            this.show_context_menu(e)
        } else if (e.button == 1) {
            // middle button
            e.preventDefault()
            e.stopPropagation();
            this.close()
        }
    }

    _on_contextmenu(tab: Tab, e: MouseEvent) {
        this.show_context_menu(e)
    }

    _register_default_event_handlers() {
        this.on("before_remove", this._on_before_remove.bind(this))
        this.on("before_close", this._on_before_close.bind(this))
        this.on("loaded", this._on_loaded.bind(this))
        this.on("activated", this._on_activated.bind(this))
        this.on("dragstart", this._on_dragstart.bind(this))
        this.on("dragend", this._on_dragend.bind(this))
        this.on("mousedown", this._on_mousedown.bind(this))
        this.on("keydown", this._on_keydown.bind(this))
        this.on("contextmenu", this._on_contextmenu.bind(this))
        this.on("focused", this._on_focused.bind(this))
    }
    // </editor-fold>
    // </editor-fold>
}

ui_sync.register_ui_task("create_tab", null, (_sender: string, data: UiCreateTabData) => {
    Editor.get_main().create_tab({
        type: data.type,
        ...data.input
    })
})
