import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumScenarioNodeType } from "../../auto_generated/enums";
import { ScenarioNodeScope } from "../scopes/scenario_node_scope";
import { ScenarioNodeClient } from "../clients/scenario_node_client";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface ScenarioNodeProps extends Props {
    id: number
    scenario_id: number
    scenario_node_snippet_id: number
    created_at: Date
    updated_at: Date
    previous_scenario_node_id: number
    next_scenario_node_id: number
    x: number
    y: number
    scenario_node_scenario_id: number
    type: EnumScenarioNodeType
    git_id: string
}
export type QuerifiedScenarioNodeProps = QuerifyProps<ScenarioNodeProps>
export type ScenarioNodeCreateProps = Omit<ScenarioNodeProps, 'id'>
export type ScenarioNodeUpdateProps = Partial<ScenarioNodeProps>

export interface ScenarioNodeState extends State {}
export interface ScenarioNodeComputed extends Computed {}
export interface ScenarioNodeStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class ScenarioNode extends VueRecord {
    ['constructor']: typeof ScenarioNode

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScenarioNodeClient
    static ScopeClass = ScenarioNodeScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScenarioNodeStaticState = reactive<ScenarioNodeStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "scenario_id", "type"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ScenarioNode> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ScenarioNode>> = {}

    static field_validators: ModelValidatorOpts<ScenarioNodeProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_NODE
    static resource_id = Enum.Resource.Id.SCENARIO_NODE
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => get_css_var("--scenario-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScenarioNodeClient
    declare props: ScenarioNodeProps;
    declare state: ScenarioNodeState;
    declare computed: ScenarioNodeComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
ScenarioNode.register_resource(ScenarioNode)
ScenarioNodeClient.ModelClass = ScenarioNode
ScenarioNodeScope.ModelClass = ScenarioNode

global_event_bus.$on("after_project_version_unload", () => {
    ScenarioNode.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            ScenarioNode.unsync()
            if (project_version_id != null) ScenarioNode.sync(`/sync/project_version/${project_version_id}/scenario_nodes`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        ScenarioNode: typeof ScenarioNode
    }
}
window.ScenarioNode = ScenarioNode
// </editor-fold>

