import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09853810"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Splitter = _resolveComponent("Splitter")

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.editor.id,
    class: "editor-container"
  }, [
    _createVNode(_component_Splitter, {
      splitter: _ctx.editor.state.splitter
    }, null, 8 /* PROPS */, ["splitter"])
  ], 8 /* PROPS */, _hoisted_1))
}