<template>
  <template v-for="line in lines"
            :key="line">
    <div
        class="codemirror-other-activity-cursor"
        :style="cursor_style(line)"
    >
      <div class="codemirror-other-activity-cursor-names">
        {{ names(line).join(', ') }}
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Snippet } from "../../../../../vue_record/models/snippet";
import { PropType } from "vue";
import _ from "lodash";
import { SnippetCursorData } from "../../../../../vue_record/models/snippet";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        snippet: {
            type: Object as PropType<Snippet>,
            required: true
        },
        cm: {
            type: Object as PropType<CodeMirror.Editor>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        grouped_by_line() {
            const other_cursors = Object.values(this.snippet.state.other_cursors).filter(oc => oc != null)
            const grouped_by_line = _.groupBy(other_cursors, (obj) => obj?.pos?.line)
            delete grouped_by_line.null
            delete grouped_by_line.undefined
            return grouped_by_line as { [key: number]: SnippetCursorData[] }
        },
        lines() {
            return Object.keys(this.grouped_by_line).map(l => parseInt(l))
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        cursor_style(line: number) {
            const max_line = this.cm.lastLine()
            if (line > max_line) line = max_line

            const ch = this.cm.getLine(line).length
            const pos = { line, ch }
            const coords = this.cm.cursorCoords(pos, "local")
            const $cm_wrapper = $(this.cm.display.wrapper)
            let gutter_width = 50;
            try {
                gutter_width = $cm_wrapper.find(".CodeMirror-gutters")[0].clientWidth
            } catch (e) {
                console.warn("failed to find gutter width")
            }
            const $scroll = $cm_wrapper.find(".CodeMirror-scroll")
            const scroll_top = $scroll[0].scrollTop
            const scroll_left = $scroll[0].scrollLeft

            return {
                top: (coords.top - scroll_top),
                left: (coords.left + gutter_width - scroll_left + 3)
            }
        },
        names(line: number) {
            return this.grouped_by_line[line].map(data => data.user.username)
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.codemirror-other-activity-cursor {
  border-left: 2px solid var(--secondary-background-color);
  border-right: none;
  width: 0;
  height: 1.2em;
  position: absolute;
  pointer-events: none;
  z-index: 5;

  .codemirror-other-activity-cursor-names {
    white-space: nowrap;
    background-color: var(--secondary-background-color);
    padding: 2px 4px 2px 4px;
    border-radius: 10px;
    width: min-content;
  }
}

</style>
