export function observe_input_value(element: HTMLElement, callback: (old_value: any, new_value: any) => void) {
    const property = "value";
    const elementPrototype = Object.getPrototypeOf(element);
    if (elementPrototype.hasOwnProperty(property)) {
        const descriptor = Object.getOwnPropertyDescriptor(elementPrototype, property);
        Object.defineProperty(element, property, {
            get: function() {
                // @ts-ignore
                // eslint-disable-next-line
                return descriptor.get.apply(this, arguments);
            },
            set: function() {
                const oldValue = this[property];
                // @ts-ignore
                // eslint-disable-next-line prefer-rest-params
                descriptor.set.apply(this, arguments);
                const newValue = this[property];
                if (typeof callback == "function") {
                    callback(oldValue, newValue)
                }
                return newValue;
            }
        });
    }
}
