<template>
  <div
      :id="node.element_id"
      ref="node"
      class="node"
  >
    <div
        class="content prevent-select"
        :class="content_class"
        draggable="true"
        :style="content_style"
        @mouseenter="node.on_mouse_enter"
        @mouseleave="node.on_mouse_leave"
        @dragstart="node.on_drag_start"
        @dragend="node.on_drag_end"
        @dragenter="node.on_drag_enter"
        @dragover.prevent="node.on_drag_over"
        @dragleave="node.on_drag_leave"
        @drop="node.on_drag_drop"
        @click="node.on_click"
        @dblclick="node.on_dbl_click"
    >
      <div class="level-padding"
           :style="level_padding_style"/>
      <span class="expander">
        <template v-if="node.is_folder">
          <Loading
              v-if="node.state.is_loading"
              color="white"
              type="fading_circle"
          />
          <template v-else>
            <ActionIcon v-if="!node.resolve_is_expanded() && !node.state.is_loaded"
                        icon_class="fa-solid fa-chevron-right"
                        margin="0"
                        @click="() => node.open(true)"
            />
            <ActionIcon v-if="!node.resolve_is_expanded() && node.state.is_loaded"
                        icon_class="fa-solid fa-caret-right fa-lg"
                        margin="0"
                        @click="() => node.open(true)"
            />
            <ActionIcon v-if="node.resolve_is_expanded() && !node.state.is_loaded"
                        icon_class="fa-solid fa-chevron-down"
                        margin="0"
                        @click="() => node.open(true)"
            />
            <ActionIcon v-if="node.resolve_is_expanded() && node.state.is_loaded"
                        icon_class="fa-solid fa-caret-down fa-lg"
                        margin="0"
                        @click="() => node.open(true)"
            />
          </template>
        </template>
      </span>
      <span v-if="node.computed.icon.enabled"
            class="icon-container">
        <template v-if="node.computed.icon.class != null">
          <i :class="node.computed.icon.class"
             :style="icon_style"
          />
        </template>
        <template v-else>
          <component :is="node.computed.icon.component"
                     v-bind="node.computed.icon.component_props"/>
        </template>
      </span>

      <div class="title-container">
        <template v-if="node.state.is_editing">
          <div style="margin-bottom: 1px;">
            <ContentEditable
                ref="content_editable_edit_title"
                v-model="node.state.current_edit_title"
                :style="{whiteSpace: 'nowrap'}"
                @blur="node.set_editing(false)"
            />
          </div>
        </template>
        <template v-else-if="node.computed.title.component != null">
          <component
              :is="node.computed.title.component"
              v-bind="node.computed.title.component_props"
          />
        </template>
        <template v-else>
          <span class="title"
                :style="title_style"
                v-html="node.computed.title.template"
          />
        </template>
      </div>

      <span class="node-content-expander"/>
      <span
          v-if="node.computed.hover_action != null"
          class="hover-action-anchor">
        <span class="hover-action">
          <ActionIcon
              :icon_class="node.computed.hover_action.icon.class"
              :color_class="node.computed.hover_action.icon.color"
              :scale="node.computed.hover_action.icon.scale"
              :title="node.computed.hover_action.title"
              @click="(e) => node.on_hover_action_click(e)"/>
        </span>
      </span>
    </div>
  </div>
  <div v-if="node.is_folder && node.state.is_loaded"
       v-show="node.is_folder && node.resolve_is_expanded()"
       class="children">
    <template v-for="child_node in child_nodes"
              :key="child_node.key">
      <!--suppress VueMissingComponentImportInspection -->
      <Node
          :node="child_node"
      />
    </template>
  </div>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import ActionIcon from "../ActionIcon.vue";
import { Component } from "vue";
import Loading from "../Loading.vue";
import { TestaTree } from "./tree";
import { UnwrapRef } from "vue";
import ContentEditable from "../ContentEditable.vue";
import { nextTick } from "vue";
import { CSSProperties } from "vue";

const LEVEL_PADDING = 15

export default defineComponent({
    components: { ContentEditable, Loading, ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        node: {
            type: Object as PropType<UnwrapRef<TestaTree.Node>>,
            required: true
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        content_class() {
            return {
                "active": this.node.state.is_active,
                "selected": this.node.state.is_selected,
                "no-drop": !this.node.state.is_drop_area && dnd.state.is_dragging,
                "dnd-over-drop-area": this.node.state.is_drag_over,
                "is-cut": this.node.state.in_clipboard && TestaTree.Tree.clipboard.type == "cut",
                "is-copied": this.node.state.in_clipboard && TestaTree.Tree.clipboard.type == "copy"
            }
        },
        content_style() {
            const style: Record<string, string> = {}
            if (!this.node.state.is_active && !this.node.state.is_selected && this.node.computed.highlight?.enabled) {
                if (this.node.computed.highlight.background_color != null) style.backgroundColor = this.node.computed.highlight.background_color
                if (this.node.computed.highlight.color != null) style.color = this.node.computed.highlight.color
            }
            return style
        },
        level_padding_style() {
            return {
                width: LEVEL_PADDING * this.node.level,
            }
        },
        icon_style() {
            return {
                fontSize: `${this.node.computed.icon.scale}em`,
                color: this.node.computed.icon.color,
            }
        },
        title_style() {
            const style: CSSProperties = {
                color: this.node.computed.title.color
            }
            if (this.node.computed.title.scale != null) {
                style.fontSize = `${this.node.computed.title.scale}em`
            }
            return style
        },
        child_nodes() {
            return this.node.children()
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'node.state.is_editing'() {
            if (this.node.state.is_editing) {
                nextTick(() => {
                    (this.$refs.content_editable_edit_title as typeof ContentEditable).select_text()
                })
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMount() {
    },
    mounted() {
        (this.$refs.node as HTMLElement).testa_tree_node = this
        if (this.node.computed.is_expanded == null) {
            let expanded = this.node.computed.storager?.get(TestaTree.Node.storager_state_is_expanded_key, this.node.is_expanded())
            if (expanded == null) expanded = false
            this.node.set_expanded(expanded)
        }
    },
    updated() {
        (this.$refs.node as HTMLElement).testa_tree_node = this
    },
    unmounted() {
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})

// Data which should be exposed to Tree
export interface TreeNodeExposed {
    resolved_icon: TestaTree.Icon,
    is_active: boolean,
    is_selected: boolean,
    is_expanded: boolean
    is_loaded: boolean
    open: () => Promise<any>
    set_expanded: (state: boolean) => void
    toggle_expanded: () => void
}
</script>

<style lang="scss" scoped>
.node {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  .content {
    display: flex;
    align-items: center;
    min-height: 18px;
    margin: 1px; // used for displaying outline properly when dragging-over
    width: 100%;
    background-color: var(--primary-background-color);
    flex-basis: max-content;
    flex-shrink: 0;
    flex-grow: 1;

    &:hover {
      background-color: var(--sidebar-hover);
    }

    &.active {
      background-color: var(--sidebar-selected);
    }

    &.selected {
      background-color: var(--sidebar-selected);
    }

    .level-padding {
      flex-shrink: 0;
    }

    .expander {
      font-size: 0.7em;
      display: flex;
      justify-content: center;
      width: 15px;
      flex-shrink: 0;
    }

    .icon-container {
      display: flex;
      width: 10px;
      justify-content: center;
      padding-left: 3px;
      padding-right: 5px;
      font-size: 0.9em;
      flex-shrink: 0;
    }

    .title {
      white-space: nowrap;
    }

    .node-content-expander {
      flex-shrink: 9999;
      width: 100%;

      // this servers as padding
      min-width: 10px;
    }

    .hover-action-anchor {
      position: sticky;
      display: none;
      justify-content: center;
      align-items: center;
      width: 0;
      right: 0;

      .hover-action {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        margin-left: -30px;
        opacity: 75%;
        border-radius: 50%;
        //padding: 5px;
        background-color: var(--primary-background-color);

        &:hover {
          opacity: 100%;
        }
      }
    }

    &:hover {
      .hover-action-anchor {
        display: flex;
      }
    }


    &.no-drop {
      opacity: 0.3;
    }

    &.is-cut {
      opacity: 0.3;
      border-left: 1px solid var(--button-blue);
    }

    &.is-copied {
      border-left: 1px solid var(--button-blue);
    }
  }
}
.children {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 100%;
}
</style>
