<template>
  <div>
    <!--<editor-fold desc="USER SETTINGS">-->
    <div class="category">
      User Settings
    </div>
    <template
        v-for="item in items.user"
        :key="item.id">
      <div v-if="item.if == null || item.if()"
           :id="item.id"
           class="item"
           :tabindex="0"
           :class="{selected: item.id == selected_item_id}"
           @click.stop="on_item_click(item.id)"
           @keydown="on_keydown"
      >
        {{ item.title }}
      </div>
    </template>
    <!--</editor-fold>-->


    <div class="splitter"/>


    <!--<editor-fold desc="PROJECT SETTINGS">-->
    <div v-if="Object.values(items.project).some(i => i.if == null || i.if())"
         class="category">
      Project Settings
    </div>
    <template
        v-for="item in items.project"
        :key="item.id">
      <div v-if="item.if == null || item.if()"
           :id="item.id"
           class="item"
           :tabindex="0"
           :class="{selected: item.id == selected_item_id}"
           @click.stop="on_item_click(item.id)"
           @keydown="on_keydown"
      >
        {{ item.title }}
      </div>
    </template>
    <!--</editor-fold>-->


    <div class="splitter"/>


    <!--<editor-fold desc="SUPERADMIN SETTINGS">-->
    <template v-if="current.user.is_superadmin()">
      <div class="category">
        Superadmin Settings
      </div>
      <template
          v-for="item in items.superadmin"
          :key="item.id">
        <div v-if="item.if == null || item.if()"
             :id="item.id"
             class="item"
             :tabindex="0"
             :class="{selected: item.id == selected_item_id}"
             @click.stop="on_item_click(item.id)"
             @keydown="on_keydown"
        >
          {{ item.title }}
        </div>
      </template>
    </template>
    <!--</editor-fold>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import KeyDownEvent = JQuery.KeyDownEvent;
import { KeyCode } from "../../types/globals";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        selected_item_id: {
            type: String,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['item-click'],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        items() {
            return setting_items
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        // trigger the initial item
        this.on_item_click(this.selected_item_id);
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_item_click(item_id: string) {
            this.$emit("item-click", item_id)
        },
        on_keydown(e: KeyDownEvent) {
            if (e.code == KeyCode.DOWN || e.code == KeyCode.UP || e.code == KeyCode.ENTER || e.code == KeyCode.TAB) {
                const $active_element = $(e.target as HTMLElement)

                if (e.code == KeyCode.ENTER) {
                    $active_element.trigger('click')
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                let $other_element = $active_element
                if (e.code == KeyCode.DOWN || (e.code == KeyCode.TAB && !e.shiftKey)) {
                    $other_element = this.find_next_or_prev_item($active_element, true)
                } else if (e.code == KeyCode.UP || (e.code == KeyCode.TAB && e.shiftKey)) {
                    $other_element = this.find_next_or_prev_item($active_element, false)
                }

                if ($other_element.length > 0) {
                    $other_element.trigger("focus")
                    if (e.code == KeyCode.TAB) e.preventDefault();
                }
            }
        },
        find_next_or_prev_item($current_item: JQuery, is_next: boolean): JQuery {
            const $other = is_next ? $current_item.next() : $current_item.prev();
            if ($other.hasClass("category") || $other.hasClass("splitter")) return this.find_next_or_prev_item($other, is_next)
            return $other
        }

    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.splitter {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 1px;
  background-color: var(--splitter-background-color);
}

.category {
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--font-color-secondary-dark-mode);
}

.item {
  font-size: 16px;
  line-height: 25px;
  color: var(--font-color);
  border-radius: 10px;
  padding-left: 10px;
  cursor: pointer;

  &.selected,
  &:hover {
    background-color: var(--ternary-background-color);
  }
}
</style>
