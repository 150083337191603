import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { ProxyScope } from "../scopes/proxy_scope";
import { ProxyClient } from "../clients/proxy_client";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { WithRequired } from "../base/utils/with_required";

// <editor-fold desc="TYPES">
export interface ProxyProps extends Props {
    id: number
    name: string
    project_id: number
    created_at: Date
    updated_at: Date
    username: string
    proxy_ip: string
    proxy_port: string
    encrypted_password: string
}
export type QuerifiedProxyProps = QuerifyProps<ProxyProps>
export interface ProxyCreateProps extends Omit<Partial<ProxyProps>, 'id'> {
    password?: string
}
export interface ProxyUpdateProps extends WithRequired<Partial<ProxyProps>, "id"> {
    change_password?: boolean
    password?: string
}

export interface ProxyState extends State {}
export interface ProxyComputed extends Computed {}
export interface ProxyStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class Proxy extends VueRecord {
    ['constructor']: typeof Proxy

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ProxyClient
    static ScopeClass = ProxyScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ProxyStaticState = reactive<ProxyStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_id", "app_type", "package"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Proxy> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Proxy>> = {}

    static field_validators: ModelValidatorOpts<ProxyProps> = {
        name: {
            presence: true,
            length: { max: 100 },
        },
        proxy_ip: { presence: true },
        proxy_port: {
            presence: true,
            numerical: true,
            in_range: [1, 65535],
        },
    }

    static resource_name = Enum.Resource.Label.PROXY
    static resource_id = Enum.Resource.Id.PROXY
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ProxyClient
    declare props: ProxyProps;
    declare state: ProxyState;
    declare computed: ProxyComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    static delete(ids: number | number[]) {
        if (!Array.isArray(ids)) ids = [ids]
        const scope = this.where({ id: ids })
        const content_text = scope.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => {
                return this.ClientClass.delete(ids)
            }
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
Proxy.register_resource(Proxy)
ProxyClient.ModelClass = Proxy
ProxyScope.ModelClass = Proxy

global_event_bus.$on("after_project_version_unload", () => {
    Proxy.get_scope().unload()
})

if (globalThis.proxies_props) {
    proxies_props.forEach(proxy_props => Proxy.new(proxy_props))
}

on_dom_content_loaded(() => {
    watch(
        () => current.project?.props?.id,
        (project_id) => {
            Proxy.unsync()
            if (project_id != null) Proxy.sync(`/sync/project/${project_id}/proxies`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    var proxies_props: ProxyProps[]

    interface Window {
        TestaProxy: typeof Proxy
    }
}
window.TestaProxy = Proxy
// </editor-fold>

