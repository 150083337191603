export function get_cm_mode(mediatype: string, subtype: string) {
    switch (mediatype) {
        case "text":
        case "application":
            switch (subtype) {
                case "x-ruby":
                    return "text/x-ruby"
                case "javascript":
                    return { name: "javascript", json: false }
                case "json":
                    return "application/json"
                case "plain":
                    return "null";
                case "xml":
                case "x-java-jnlp-file":
                    return "text/xml"
                default:
                    return "null"
            }
        default:
            return "null"
    }
}
