import { VueRecordScope } from "../../base/vue_record_scope";
import { PlayGroup } from "../../models/play/play_group";
import { computed } from "../../../helpers/vue/computed";

export class PlayGroupScope extends VueRecordScope {
    ['constructor']: typeof PlayGroupScope
    static ModelClass: typeof PlayGroup
    declare rthis: PlayGroupScope

    started_or_pending = computed(() => this.rthis.where({ status: Enum.Play.Status.started_or_pending_statuses }))
}
