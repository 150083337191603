export function get_files_webkit_data_transfer_items(
    dataTransferItems: DataTransferItemList,
    folder_id: string,
    extension_filter: Array<string> | string = null
): Promise<{[key: string]: Array<File>} > {
    if (extension_filter != null && !Array.isArray(extension_filter)) {
        extension_filter = [extension_filter];
    }

    function traverse_file_tree_promise(item: FileSystemEntry, path = '', extension_filter: Array<string>) {
        return new Promise(resolve => {
            if (item.isFile) {
                if (files[path] == null) {
                    files[path] = []
                }
                (item as FileSystemFileEntry).file(file => {
                    if (extension_filter == null) {
                        files[path].push(file)
                        resolve(file)
                    } else {
                        const name_parts = file.name.split('.');
                        if (extension_filter.includes(name_parts[name_parts.length - 1])) {
                            files[path].push(file)
                            resolve(file)
                        } else {
                            resolve(null);
                        }
                    }
                })
            } else if (item.isDirectory) {
                const dirReader = (item as FileSystemDirectoryEntry).createReader()
                dirReader.readEntries(entries => {
                    const entriesPromises = []
                    for (const entr of entries) {
                        entriesPromises.push(traverse_file_tree_promise(entr, path + item.name + '/', null))
                    }
                    resolve(Promise.all(entriesPromises))
                })
            }
        })
    }

    const files:{[key: string]: Array<File>} = {}
    return new Promise((resolve) => {
        const entriesPromises = []
        // @ts-ignore
        for (const it of dataTransferItems) {
            entriesPromises.push(traverse_file_tree_promise(it.webkitGetAsEntry(), folder_id, extension_filter as Array<string>))
        }
        Promise.all(entriesPromises).then(() => resolve(files))
    })
}
