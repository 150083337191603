import { create_vue_app } from "./create_vue_app";
import SearchRecordModal from "../../components/testa/search_record/SearchRecordModal.vue";
import { ProjectVersion } from "../../vue_record/models/project_version";

export const search_record_modal_id = "search_record_modal"
export function show_search_record(project_version: ProjectVersion) {
    if (document.getElementById(search_record_modal_id) != null) return;
    const selected_text = window.getSelection().toString();
    create_vue_app(SearchRecordModal, { query: selected_text, project_version })
}
