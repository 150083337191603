import { Phone } from "../models/phone";
import { PhoneProjectScope } from "../scopes/phone_project_scope";
import { PhoneProject } from "../models/phone_project";
import { Phonector } from "../models/phonector";
import { PlayWorkerGroup } from "../models/play/play_worker_group";
import { PlayScenario } from "../models/play/play_scenario";
import { PlaySandbox } from "../models/play/play_sandbox";
import { Phone as ScenarioSettingPhone } from "../models/scenario_setting/phone"
import { PhoneScope as ScenarioSettingPhoneScope } from "../scopes/scenario_setting/phone_scope"
import { User } from "../models/user";

declare module "../models/phone" {
    interface Phone {
        phonector: Phonector;
        phone_projects: PhoneProjectScope;
        scenario_setting_phones: ScenarioSettingPhoneScope

        claimed_by_play_worker_group: PlayWorkerGroup
        in_use_by_play_scenario: PlayScenario
        in_use_by_play_sandbox: PlaySandbox
        used_by_user: User
    }
}

Phone.belongs_to("phonector", Phonector, "phonector_id")
Phone.belongs_to("claimed_by_play_worker_group", PlayWorkerGroup, "claimed_by_play_worker_group_id")
Phone.belongs_to("in_use_by_play_scenario", PlayScenario, "in_use_by_play_scenario_id")
Phone.belongs_to("in_use_by_play_sandbox", PlaySandbox, "in_use_by_play_sandbox_id")
Phone.belongs_to("used_by_user", User, "used_by")

// has_many
Phone.has_many("phone_projects", PhoneProject, "udid")

// has_many_through
Phone.has_many_through("scenario_setting_phones", "phone_projects", "scenario_setting_phones", ScenarioSettingPhone)
