import { ModeSpec } from "codemirror";
import { ModeSpecOptions } from "codemirror";
import { what_is_it } from "../../generic/what_is_it";

export function is_lint_supported(mode: string | ModeSpec<ModeSpecOptions>) {
    const supported_modes = ["javascript", "css", "yaml", "application/json"]
    if (what_is_it(mode) == "String") {
        return supported_modes.includes(mode as string)
    } else {
        return supported_modes.includes((mode as ModeSpec<ModeSpecOptions>).name);
    }
}
