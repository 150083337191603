import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumFileType } from "../../auto_generated/enums";
import { ImageClient } from "../clients/image_client";
import { ImageScope } from "../scopes/image_scope";
import { TabTargetOpts } from "../../components/testa/editor/tab";
import { Timeout } from "../../helpers/generic/create_timeout";
import CodeMirror from "codemirror";
import { computed } from "../../helpers/vue/computed";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { create_vue_app } from "../../helpers/vue/create_vue_app";
import JumpToModal from "../../components/testa/editor/editors/codemirror/JumpToModal.vue";
import ImageTitle from "../../components/image/ImageTitle.vue";
import { resolve_tab_target } from "../../components/testa/editor/tab";
import { unmount_all_modals } from "../../helpers/vue/unmount_all_modals";
import { ImageTab } from "../../components/testa/editor/tabs/image_tab";
import { multi_resource_remove } from "../../helpers/client/core/multi_resource_remove";
import { on_shared_worker_loaded } from "../../helpers/ui_sync/on_shared_worker_loaded";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { convert_sync_data_to_new_format } from "../../helpers/files/convert_sync_data_to_new_format";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import SyncReceiveData = UiSync.SyncReceiveData;
import { WithRequired } from "../base/utils/with_required";
import { RecordOpts } from "../base/vue_record";
import { ImageFolder } from "./image_folder";
import { ImageFolderScope } from "../scopes/image_folder_scope";
import { to_scoped_map } from "../base/utils/to_scoped_map";

// <editor-fold desc="TYPES">
export interface ImageProps extends Props {
    path: string
    name: string
    project_version_id: number
    image_folder_path: string
    type: EnumFileType
    size: number
    content: string
    mediatype: string
    subtype: string
    updated_at: Date
    created_at: Date
}
export type QuerifiedImageProps = QuerifyProps<ImageProps>
export type ImageCreateProps = Omit<WithRequired<Partial<ImageProps>, 'content'>, 'path'>
export type ImageUpdateProps = Partial<ImageProps>
export type ImageUploadProps = Omit<ImageCreateProps, "content">

export interface ImageState extends State {
    autosave: Timeout
    content_not_committed: string
}
export interface ImageComputed extends Computed {
    download_path: string
}
export interface ImageStaticState extends StaticState {}

export interface ImageOpenOpts extends TabTargetOpts {
    close_all_modals?: boolean
}

export type ImageEditorActionItem = "dummy"


export type ImageEditorFeature = "dummy"
// </editor-fold>

const console = new Consoler("warn")
export class Image extends VueRecord {
    ['constructor']: typeof Image

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static discard_outdated = false
    static ClientClass = ImageClient
    static ScopeClass = ImageScope
    static readonly primary_key = "path"
    static sync_channels: string[] = []
    static state: ImageStaticState = reactive<ImageStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "image_folder_path"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Image> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Image>> = {}

    static field_validators: ModelValidatorOpts<ImageProps> = {}

    static resource_name = Enum.Resource.Label.IMAGE
    static resource_id = Enum.Resource.Id.IMAGE
    static icon_class = "fa-solid fa-image"
    static color = () => get_css_var("--image-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ImageClient
    declare props: ImageProps;
    declare state: ImageState;
    declare computed: ImageComputed;

    // </editor-fold>

    constructor(props: Props, opts: RecordOpts) {
        super(props, opts);
        this.state.autosave = null
        this.state.content_not_committed = this.props.content;

        this.computed.download_path = computed(() => {
            return `/images/${this.key()}/download?project_version_id=${this.props.project_version_id}`
        })
    }

    // <editor-fold desc="HOOKS">
    before_unload() {
        super.before_unload();

        this.get_tabs().forEach(tab => tab.close())
    }
    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return Image.show_in_sidebar(this.key(), this.props.project_version_id, tree);
    }

    static async show_in_sidebar(image_paths: string | string[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: string[];
        if (what_is_it(image_paths) == "Array") {
            ids = image_paths as string[]
        } else {
            ids = [image_paths as string]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(image_paths).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).forEach(image_path => {
                    const image_folders = promise_response[image_path]
                    const keys = [
                        ImageFolder.tree_key(project_version_id),
                        ...image_folders.map(sf => sf.tree_key()),
                        Image.find(image_path).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: Image.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="ACTIONS">
    show_jump_to(cm: CodeMirror.Editor) {
        create_vue_app(JumpToModal, {
            cm
        })
    }

    open(opts: ImageOpenOpts = {}) {
        const tab_target = resolve_tab_target(opts)
        if (opts.close_all_modals) unmount_all_modals();

        const tab = tab_target.create_tab({
            id: this._image_tab_id(),
            image_path: this.key(),
            type: ImageTab.type,
            project_version_id: this.props.project_version_id
        }) as ImageTab

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => {
                resolve(null)
            })
        })
    }

    destroy() {
        return multi_resource_remove(this.props.project_version_id, this.to_scoped_map())
    }

    static crop_external_image_in_main(image_path: string, project_version_id: number) {
        const web = ui_sync.web_for_main(project_version_id)
        ui_sync.send_ui_create_tab_task(web, {
            type: ImageTab.type,
            input: {
                external: true,
                id: `screenshot_image_cropper_${image_path}`,
                image_path,
                allow_duplicate: true,
                project_version_id,
            }
        })
    }
    // </editor-fold>

    // <editor-fold desc="EDITOR">
    _editor_contextmenu(cm: CodeMirror.Editor, e: MouseEvent, _exclude_items: ImageEditorActionItem[] = []) {
        const items: ContextMenu.Items = {}
        return items
    }

    // </editor-fold>

    // <editor-fold desc="TREE">

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<ImageScope, ImageFolderScope, VueRecord>> = {}
            if (current_role != Enum.User.Role.VIEWER) {
                keys["ctrl++"] = () => {
                    ImageFolder.ClientClass.create({
                        image_folder_path: this.image_folder?.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const scoped_map = to_scoped_map(nodes.map(n => n.record))
                const image_folder_paths = scoped_map.image_folders.pluck("key")
                const image_paths = scoped_map.images.pluck("key")

                if (type == "copy") {
                    ImageFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.image_folder?.key(),
                        image_folder_paths,
                        image_paths
                    )
                } else if (type == "cut") {
                    ImageFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.image_folder?.key(),
                        image_folder_paths,
                        image_paths
                    )
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXTMENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<ImageScope, ImageFolderScope, Image>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: ImageFolder._tree_new_image_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.open = {
                    name: "Open",
                    icon: Image.icon_class,
                    color: get_css_var("--button-white"),
                    key: `enter`,
                    callback: () => {
                        this.open();
                    },
                }

                items.download = {
                    name: "Download",
                    icon: "fa-solid fa-download",
                    color: get_css_var("--button-blue"),
                    callback: () => {
                        const records_scope = node.tree.get_selected_records()
                        if (records_scope.images.count + records_scope.image_folders.count > 1) {
                            Image.ClientClass.batch_download(node.tree.project_version.key(), records_scope.image_folders.toArray(), records_scope.images.toArray())
                        } else {
                            this.client.download();
                        }
                    },
                }

                items.upload = {
                    name: "Upload",
                    icon: "fa-solid fa-upload",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        Image.ClientClass.upload({
                            image_folder_path: this.props.image_folder_path,
                            project_version_id: this.props.project_version_id
                        })
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<ImageScope, ImageFolderScope, Image> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.IMAGE,
            record: this,
            title: computed(() => {
                return {
                    template: this.props.name,
                    component: ImageTitle,
                    component_props: { image: this }
                }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    return this.open()
                },
            },
            icon: computed(() => {
                return {
                    color: get_css_var("--image-color"),
                }
            }),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: true,
                is_drop_area: false,
            },
            clipboard: this._testa_tree_clipboard(),
            contextmenu: this._tree_contextmenu(),
        }
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        if (include_name) {
            return this.props.path
        } else {
            if (this.props.image_folder_path == null) return "/"
            return this.props.image_folder_path
        }
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _image_tab_id() {
        return `image_tab_${this.key()}`
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
Image.register_resource(Image)
ImageClient.ModelClass = Image
ImageScope.ModelClass = Image

global_event_bus.$on("after_project_version_unload", () => {
    Image.get_scope().unload()
})

on_shared_worker_loaded(() => {
    on_dom_content_loaded(() => {
        watch(
            () => current.project_version?.props?.id,
            (project_version_id, old_project_version_id) => {
                if (old_project_version_id != null) ui_sync.send_unsync_subscribe_task(`/sync/project_version/${old_project_version_id}/images`, Image.resource_id)

                if (project_version_id != null) {
                    ui_sync.send_sync_subscribe_task(`/sync/project_version/${project_version_id}/images`, Image.resource_id)
                }
            },
            {
                flush: "sync",
                immediate: true
            }
        )

        // register a handler for sync messages
        ui_sync.register_sync_task("receive", Image.resource_id, (sender: string, data: SyncReceiveData) => {
            const new_format = convert_sync_data_to_new_format(data.message)

            if (data.message.resource == "images") {
                Image.on_sync_data_received(new_format)
            } else {
                ImageFolder.on_sync_data_received(new_format)
            }
        })
    })
})

declare global {
    interface Window {
        TestaImage: typeof Image
    }
}
window.TestaImage = Image
// </editor-fold>

