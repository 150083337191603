<template>
  <div
      ref="container"
      class="rflex"
      :class="{vertical, horizontal, reverse, fill}"
      :data-flex-id="rflex.id"
  >
    <template v-for="area in rflex.get_enabled_areas_with_sticks()"
              :key="area.id">
      <div
          v-if="area.is_stick"
          :ref="(el) => area.container = el"
          class="stick"
          :style="area.get_style()"
          @mousedown.self.stop="(e) => to_stick_area(area).handle_on_mousedown(e)"
          @mouseenter.self="(e) => to_stick_area(area).handle_on_mouseenter(e)"
          @mouseleave.self="(e) => to_stick_area(area).handle_on_mouseleave(e)"
      />
      <div v-else
           :ref="(el) => area.container = el"
           class="area"
           :data-area-id="area.id"
           :style="area.get_style()"
           @mousedown="(e) => to_content_area(area).handle_on_mousedown(e)"
           @mousemove="(e) => to_content_area(area).handle_on_mousemove(e)"
           @mouseleave.self="(e) => to_content_area(area).handle_on_mouseleave(e)"
      >
        <slot :name="area.id"/>
      </div>

    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { ResizableFlex } from "./resizable_flex";
import { FlexArea } from "./area/flex_area";
import { FlexStickArea } from "./area/flex_stick_area";
import { CSSProperties } from "vue";
import { FlexContentArea } from "./area/flex_content_area";

export default defineComponent({
    components: { },
    // <editor-fold desc="PROPS">
    props: {
        rflex: {
            type: Object as PropType<ResizableFlex>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        vertical() {
            return !this.rflex.is_row_direction()
        },
        horizontal() {
            return this.rflex.is_row_direction()
        },
        reverse() {
            return this.rflex.is_reverse()
        },
        fill() {
            return this.rflex.fill_container
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.rflex.bind_container(this.$refs.container as HTMLElement)

        // @ts-ignore
        this.$refs.container.rflex = this.rflex
        document.body.addEventListener("mouseup", this.on_mouseup)
        document.addEventListener("blur", this.on_blur)
    },
    unmounted() {
        document.body.removeEventListener("mouseup", this.on_mouseup)
        document.removeEventListener("blur", this.on_blur)
        this.rflex.unbind()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        to_stick_area(area: FlexArea): FlexStickArea {
              return area as FlexStickArea
        },
        to_content_area(area: FlexArea): FlexContentArea {
            return area as FlexContentArea
        },
        on_mouseup(e: MouseEvent) {
            this.rflex.handle_on_mouseup(e)
        },
        on_blur(e: FocusEvent) {
            this.rflex.handle_on_blur(e)
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.rflex {
  display: flex;
  overflow: hidden;

  .area {
    overflow: auto;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }

  .stick {
    background-color: var(--secondary-background-color);
    flex-shrink: 0;
    transition: background-color 0.3s linear 0.1s;
  }

  &.vertical {
    height: 100%;
    flex-direction: column;

    &.fill {
      width: 100%;
    }

    &.reverse {
      flex-direction: column-reverse;
    }

    .area {
      min-height: 0;
    }

    >.stick {
      width: 100%;
      cursor: ns-resize;
    }
  }

  &.horizontal {
    width: 100%;
    flex-direction: row;

    &.fill {
      height: 100%;
    }

    &.reverse {
      flex-direction: row-reverse;
    }

    .area {
      min-width: 0;
    }

    >.stick {
      height: 100%;
      cursor: ew-resize;
    }
  }
}
</style>
