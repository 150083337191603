<template>
  <div class="container">
    <template v-if="groups_scenarios.length == 0">
      <div
          class="label"
          style="font-size: 1.4em"
      >
        Group is empty. Drag and drop scenarios
      </div>
    </template>
    <template v-else>
      <div class="overlay">
        <ActionIcon
            icon_class="fa-solid fa-play"
            color_class="green"
            title="Play"
            :click_action="play"
        />

        <ActionIcon
            icon_class="fa-solid fa-trash-can"
            color_class="red"
            title="Delete Group"
            :click_action="destroy"
        />
      </div>
      <div class="label">
        Scenarios in group:
      </div>
      <div
          ref="groups_scenarios_container"
          class="groups-scenarios-container"
      >
        <div
            v-if="display_dnd_drop"
            class="dnd-drop-areas-container">
          <div class="dnd-over-drop-area"/>
        </div>
        <template v-for="(groups_scenario, index) in groups_scenarios"
                  :key="groups_scenario.key()">
          <GroupsScenarioVue
              :groups_scenario="groups_scenario"
              :index="index"
              :is_first="index == 0"
              :is_last="index + 1 == groups_scenarios.length"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Group } from "../../../../../vue_record/models/group";
import { GroupTab } from "../../tabs/group_tab";
import { GroupEditorFeature } from "../../../../../vue_record/models/group";
import { GroupEditorActionItem } from "../../../../../vue_record/models/group";
import GroupsScenarioVue from "./GroupsScenario.vue";
import { GroupsScenarioUpdateProps } from "../../../../../vue_record/models/groups_scenario";
import { GroupsScenario } from "../../../../../vue_record/models/groups_scenario";
import ActionIcon from "../../../ActionIcon.vue";
import { Play } from "../../../../../vue_record/models/play/play";

export default defineComponent({
    components: { ActionIcon, GroupsScenarioVue },
    // <editor-fold desc="PROPS">
    props: {
        group: {
            type: Object as PropType<Group>,
            required: true
        },
        tab: {
            type: Object as PropType<GroupTab>,
            required: false,
            default: null
        },
        exclude_features: {
            type: Array as PropType<GroupEditorFeature[]>,
            required: false,
            default: () => {
                return [] as GroupEditorFeature[]
            }
        },
        exclude_actions: {
            type: Array as PropType<GroupEditorActionItem[]>,
            required: false,
            default: () => {
                return [] as GroupEditorActionItem[]
            }
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        groups_scenarios() {
            return this.group.groups_scenarios.order("real_order", "asc", "sensitive").toArray()
        },
        display_dnd_drop() {
            const acceptable_records = dnd.state.records.filter(r => r.constructor.resource_id == Enum.Resource.Id.SCENARIO || r.constructor.resource_id == Enum.Resource.Id.SCENARIO_FOLDER)
            return dnd.state.is_dragging && acceptable_records.length > 0
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.attach_sortable()
        if (this.tab != null) this.tab.set_editor_mounted(true);
    },
    updated() {
        this.attach_sortable()
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        play() {
            Play.show_play_modal(this.group)
        },
        destroy() {
            this.group.destroy().catch(e => e.show_toaster())
        },
        attach_sortable() {
            const $groups_scenarios_container = $(this.$refs.groups_scenarios_container as HTMLElement);
            $groups_scenarios_container
                .sortable({
                    axis: "y",
                    cursor: "move",
                    update: () => {
                        const groups_scenarios_update_props: GroupsScenarioUpdateProps[] = [];
                        $groups_scenarios_container.find(".groups-scenario").each((index, element) => {
                            const group_scenario = this.groups_scenarios.find(groups_scenario => groups_scenario.key().toString() == element.dataset.groups_scenario_id)
                            if (group_scenario.props.real_order != index) {
                                const groups_scenario_update_props: GroupsScenarioUpdateProps = {
                                    id: group_scenario.key(),
                                    real_order: index,
                                }
                                groups_scenarios_update_props.push(groups_scenario_update_props)
                            }
                        })
                        GroupsScenario.ClientClass.batch_update(groups_scenarios_update_props)
                    }
                });
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .label {
    color: var(--font-color-secondary);
    font-size: 0.9em;
    margin-left: 5px;
    padding-block: 5px;
  }

  .overlay {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    z-index: 1;
  }

  .groups-scenarios-container {
    position: relative;
    height: 100%;

    .dnd-drop-areas-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;

      .dnd-over-drop-area {
        outline: none;
        border: 2px solid var(--button-blue);
        height: calc(100% - 4px);
        width: calc(100% - 4px);

        pointer-events: none;
      }
    }
  }
}
</style>
