// get keywords from https://www.rubydoc.info/gems/watir/Watir/Video
// this is for 6.16.5 version

globalThis.get_ruby_watir_selectors = () => {
    return `
    :adjacent =>
     :aria_label =>
     :class => 
     :css => 
     :data_type =>
     :href => 
     :id => 
     :index => 
     :name => 
     :src =>
     :text => 
     :value => 
     :visible => 
     :visible_text =>
     :xpath => 
     adjacent: 
     aria_label: 
     class: 
     css: 
     data_type: 
     href: 
     id: 
     index: 
     name: 
     src: 
     text: 
     value: 
     visible: 
     visible_text: 
     xpath: 
    `;
}

globalThis.get_ruby_watir_methods = () => {
    return `
a
abbr
abbrs
accesskey
address
addresses
area
areas
article
articles
as
aside
asides
attribute
attribute_list
attribute_list
attribute_value
attribute_values
audio
audios
audiotracks
autoplay
b
base
bases
bdi
bdis
bdo
bdos
blockquote
blockquotes
body
bodys
br
browser
brs
bs
buffered
build
button
buttons
cache=
canvas
canvases
caption
captions
center
checkbox
checkboxes
child
children
circle
circles
cite
cites
class_name
classes
click
click
code
codes
col
colgroup
colgroups
cols
content_editable
content_editable
controls
crossorigin
currentsrc
currenttime
data
datalist
datalists
datas
dataset
date_field
date_fields
date_time_field
date_time_fields
dd
dds
defaultmuted
defaultplaybackrate
define_attribute
define_boolean_attribute
define_float_attribute
define_int_attribute
define_string_attribute
defs
defss
del
dels
desc
descs
details
detailses
dfn
dfns
dialog
dialogs
dir
div
divs
dl
dls
double_click
double_click
drag_and_drop_by
drag_and_drop_on
draggable
driver
dt
dts
duration
element
elements
ellipse
ellipses
em
embed
embeds
ems
enabled
ended
error
execute_js
execute_script
exists
extract_selector
field_set
field_sets
fieldset
fieldsets
figcaption
figcaptions
figure
figures
file_field
file_fields
fire_event
flash
focus
focused
following_sibling
following_siblings
font
fonts
footer
footers
foreign_object
foreign_objects
form
forms
frame
frames
frameset
framesets
g
goto
gs
h1
h1s
h2
h2s
h3
h3s
h4
h4s
h5
h5s
h6
h6s
hash
head
header
headers
heads
height
hidden
hidden
hiddens
hover
hr
hrs
html
htmls
i
id
iframe
iframes
image
images
img
imgs
inherit_attributes_from
initialize
inner_html
inner_text
innertext
input
inputs
ins
inses
inspect
is
kbd
kbds
label
labels
lang
legend
legends
li
line
linear_gradient
linear_gradients
lines
link
links
lis
locate
located
location
loop
main
mains
manage
map
maps
mark
marker
markers
marks
meta
metadata
metadatas
metas
meter
meters
method
muted
nav
navs
networkstate
noscript
noscripts
object
objects
obscured
ol
ols
onabort
onblur
oncancel
oncanplay
oncanplaythrough
onchange
onclick
onclose
oncopy
oncuechange
oncut
ondblclick
ondrag
ondragend
ondragenter
ondragexit
ondragleave
ondragover
ondragstart
ondrop
ondurationchange
onemptied
onended
onerror
onfocus
oninput
oninvalid
onkeydown
onkeypress
onkeyup
onload
onloadeddata
onloadedmetadata
onloadstart
onmousedown
onmouseenter
onmouseleave
onmousemove
onmouseout
onmouseover
onmouseup
onpaste
onpause
onplay
onplaying
onprogress
onratechange
onreset
onresize
onscroll
onseeked
onseeking
onselect
onshow
onstalled
onsubmit
onsuspend
ontimeupdate
ontoggle
onvolumechange
onwaiting
onwheel
optgroup
optgroups
option
options
outer_html
output
outputs
p
param
params
parent
path
paths
pattern
patterns
paused
picture
pictures
playbackrate
played
polygon
polygons
polyline
polylines
poster
pre
preceding_sibling
preceding_siblings
preload
pres
present
progress
progresses
ps
q
qs
radial_gradient
radial_gradients
radio
radio_set
radios
rb
rbs
readystate
rect
rects
reset
resize_to
right_click
rp
rps
rt
rtc
rtcs
rts
rubies
ruby
s
samp
samps
script
scripts
scroll
scroll_into_view
section
sections
seekable
seeking
select
select_list
select_lists
select_text
selected_text
selector_string
selects
send_keys
siblings
size
small
smalls
source
sources
span
spans
spellcheck
src
srcobject
ss
stale
stale_in_context
stop
stops
strong
strongs
style
style
styles
sub
subs
summaries
summary
sup
sups
svg
svgs
switch
switches
symbol
symbols
tabindex
table
tables
tag_name
tbody
tbodys
td
tds
template
templates
text
text_content
text_field
text_fields
text_path
text_paths
textarea
textareas
texttracks
tfoot
tfoots
th
thead
theads
ths
time
times
title
title
titles
to_subtype
tr
track
tracks
translate
trs
tspan
tspans
typed_attributes
u
ul
uls
us
use
uses
var
vars
video
videoheight
videos
videotracks
videowidth
view
views
visible
volume
wait_until
wait_until_present
wait_while
wait_while_present
wbr
wbrs
wd
when_enabled
when_present
width
window
`;
}

globalThis.get_ruby_watir_classes = () => {
    return `
Adjacent
AfterHooks
Alert
Anchor
AnchorCollection
Applet
AppletCollection
Area
AreaCollection
AttributeHelper
Audio
AudioCollection
BR
BRCollection
Base
BaseCollection
BaseDecorator
Body
BodyCollection
Browser
Button
ButtonCollection
Canvas
CanvasCollection
Capabilities
Cell
CellCollection
CellContainer
CheckBox
CheckBoxCollection
Circle
CircleCollection
Container
Cookies
DList
DListCollection
Data
DataCollection
DataList
DataListCollection
DateField
DateFieldCollection
DateTimeField
DateTimeFieldCollection
Defs
DefsCollection
Desc
DescCollection
Details
DetailsCollection
Dialog
DialogCollection
Directory
DirectoryCollection
Div
DivCollection
Element
ElementCollection
Ellipse
EllipseCollection
Embed
EmbedCollection
EventuallyPresent
Exception
FieldSet
FieldSetCollection
FileField
FileFieldCollection
Font
FontCollection
ForeignObject
ForeignObjectCollection
Form
FormCollection
Frame
FrameCollection
FrameSet
FrameSetCollection
FramedDriver
G
GCollection
Generator
Geometry
GeometryCollection
Gradient
GradientCollection
Graphics
GraphicsCollection
HR
HRCollection
HTMLElement
HTMLElementCollection
HasWindow
Head
HeadCollection
Heading
HeadingCollection
Hidden
HiddenCollection
Html
HtmlCollection
IFrame
IFrameCollection
Image
ImageCollection
Input
InputCollection
JSExecution
JSSnippets
LI
LICollection
Label
LabelCollection
Legend
LegendCollection
Line
LineCollection
LinearGradient
LinearGradientCollection
List
Locators
Logger
Map
MapCollection
Marker
MarkerCollection
Marquee
MarqueeCollection
Media
MediaCollection
Meta
MetaCollection
Metadata
MetadataCollection
Meter
MeterCollection
Mod
ModCollection
Navigation
OList
OListCollection
Object
ObjectCollection
OptGroup
OptGroupCollection
Option
OptionCollection
Output
OutputCollection
Paragraph
ParagraphCollection
Param
ParamCollection
Path
PathCollection
Pattern
PatternCollection
Picture
PictureCollection
Polygon
PolygonCollection
Polyline
PolylineCollection
Pre
PreCollection
Progress
ProgressCollection
Quote
QuoteCollection
RadialGradient
RadialGradientCollection
Radio
RadioCollection
RadioSet
Rect
RectCollection
Row
RowCollection
RowContainer
SVG
SVGCollection
SVGElement
SVGElementCollection
Screenshot
Script
ScriptCollection
Scroll
Scrolling
Select
SelectCollection
Source
SourceCollection
Span
SpanCollection
Stop
StopCollection
Style
StyleCollection
Switch
SwitchCollection
Symbol
SymbolCollection
TSpan
TSpanCollection
Table
TableCaption
TableCaptionCollection
TableCell
TableCellCollection
TableCol
TableColCollection
TableCollection
TableDataCell
TableDataCellCollection
TableHeaderCell
TableHeaderCellCollection
TableRow
TableRowCollection
TableSection
TableSectionCollection
Template
TemplateCollection
TextArea
TextAreaCollection
TextContent
TextContentCollection
TextField
TextFieldCollection
TextPath
TextPathCollection
TextPositioning
TextPositioningCollection
Time
TimeCollection
Title
TitleCollection
Track
TrackCollection
UList
UListCollection
Unknown
UnknownCollection
Use
UseCollection
UserEditable
Video
VideoCollection
View
ViewCollection
Wait
Waitable
Watir
WhenEnabledDecorator
WhenPresentDecorator
Window
`;
}
