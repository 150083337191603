import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumUserSettingSubtitlePositionValues } from "../../auto_generated/enums";
import { UserSettingClient } from "../clients/user_setting_client";
import { UserSettingScope } from "../scopes/user_setting_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface UserSettingProps extends Props {
    id: number
    user_id: number
    autosave: boolean
    variables: string
    appium_command_timeout: number
    appium_no_reset: boolean
    automation_engine: string
    browser_size_x: number
    browser_size_y: number
    watir_default_timeout: number
    enable_shortcuts: boolean
    no_proxy: string
    project_version_id: number
    pageload_timeout: number
    enable_tor_network: boolean
    disable_image_loading: boolean
    jira_enabled: boolean
    jira_email: string
    jira_token: string
    jira_site: string
    subtitle_position: EnumUserSettingSubtitlePositionValues
    subtitle_color: string
    subtitle_size: string
}
export type QuerifiedUserSettingProps = QuerifyProps<UserSettingProps>
export type UserSettingCreateProps = Omit<UserSettingProps, 'id'>
export type UserSettingUpdateProps = Partial<UserSettingProps>

export interface UserSettingState extends State {}
export interface UserSettingComputed extends Computed {}
export interface UserSettingStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class UserSetting extends VueRecord {
    ['constructor']: typeof UserSetting

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = UserSettingClient
    static ScopeClass = UserSettingScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: UserSettingStaticState = reactive<UserSettingStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "user_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof UserSetting> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof UserSetting>> = {}

    static field_validators: ModelValidatorOpts<UserSettingProps> = {
        subtitle_position: { presence: true, inclusion: Enum.User.Setting.Subtitle.Position.values },
        subtitle_size: { presence: true, min: Enum.User.Setting.Subtitle.Size.MIN, max: Enum.User.Setting.Subtitle.Size.MAX },
        subtitle_color: { presence: true, inclusion: Enum.User.Setting.Subtitle.COLORS }
    }

    static resource_name = Enum.Resource.Label.USER_SETTING
    static resource_id = Enum.Resource.Id.USER_SETTING
    static icon_class = "fa-solid fa-user-gear"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: UserSettingClient
    declare props: UserSettingProps;
    declare state: UserSettingState;
    declare computed: UserSettingComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
UserSetting.register_resource(UserSetting)
UserSettingClient.ModelClass = UserSetting
UserSettingScope.ModelClass = UserSetting

if (globalThis.current_user_setting_props?.id) {
    current.user_setting = UserSetting.new(current_user_setting_props)
}

on_dom_content_loaded(() => {
    watch(
        [() => current.project_version_setting?.props?.id, () => current.user?.props?.id],
        ([project_version_setting_id, user_id]) => {
            UserSetting.unsync();
            if (project_version_setting_id != null && user_id != null) UserSetting.sync(`/sync/project_version/${project_version_setting_id}/user_settings/${user_id}`);
        },
        {
            immediate: true
        }
    )
})

declare global {
    var current_user_setting_props: UserSettingProps

    interface Window {
        UserSetting: typeof UserSetting
    }

    interface Current {
        user_setting: UserSetting
    }
}
window.UserSetting = UserSetting
// </editor-fold>

