import { VueRecordScope } from "../../base/vue_record_scope";
import { computed } from "../../../helpers/vue/computed";
import { PlayLog } from "../../models/play/play_log";

export class PlayLogScope extends VueRecordScope {
    ['constructor']: typeof PlayLogScope
    static ModelClass: typeof PlayLog
    declare rthis: PlayLogScope

    only_in_play = computed(() => {
        return this.rthis.where({ play_snippet_id: null })
                   .where({ play_sandbox_id: null })
                   .where({ play_scenario_id: null })
    })

    only_in_play_scenario = computed(() => {
        return this.rthis.where({ play_snippet_id: null })
                   .where({ play_sandbox_id: null })
    })
}
