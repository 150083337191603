<template>
  <div :id="tab.instance_id"
       class="tab no-default-focus prevent-select"
       :class="tab_class"
       tabindex="0"
       draggable="true"
       @click="tab.set_active(true)"
       @focus="tab._emit('focused')"
       @keydown="e => tab._emit('keydown', e)"
       @mousedown="(e) => tab._emit('mousedown', e)"
       @contextmenu="(e) => tab._emit('contextmenu', e)"
       @dragstart="(e) => tab._emit('dragstart', e)"
       @dragend="(e) => tab._emit('dragend', e)"
  >
    <div class="indicator-bar"
         :class="indicator_class"
         :style="indicator_style"
    >
      <div v-if="tab.state.indicator.style == 'progress'"
           class="progress-bar"
           :style="`width: ${tab.state.indicator.progress}%; background-color: ${tab.state.indicator.color}`"
      />
    </div>
    <div class="insides">
      <span class="icon">
        <i :class="tab.state.icon.class"
           :style="icon_style"/>
      </span>
      <span
          class="title"
          :title="tab.computed.title"
      >
        <template v-if="tab.state.error.is_error">
          Load Failed
        </template>
        <template v-else-if="tab.state.loaded">
          {{ tab.computed.title }}
        </template>
        <template v-else>
          <Loading
              type="three_bounce"
              color="white"
          />
        </template>
      </span>
      <span class="actions"></span>
      <div class="flex-expander"/>
      <span class="close">
        <ActionIcon
            icon_class="fa-solid fa-xmark"
            color_class="red"
            :tab_index="null"
            @click="tab.close()"
        />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Tab } from "./tab";
import ActionIcon from "../ActionIcon.vue";
import Loading from "../Loading.vue";
import { Editor } from "./editor";

export default defineComponent({
    components: { Loading, ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        tab: {
            type: Object as PropType<Tab>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        tab_class() {
            const clazz: Record<string, boolean> = {
                active: this.tab.is_active(),
                underline: Editor.active_tab() == this.tab
            }
            if (this.tab.tab_manager != null) {
                clazz[this.tab.tab_manager.state.position] = true
            }
            return clazz
        },
        icon_style() {
            return {
                fontSize: `${this.tab.state.icon.scale}em`,
                color: this.tab.state.icon.color,
            }
        },
        indicator_class() {
            return {
                bar: this.tab.state.indicator.style == "success",
                progress: this.tab.state.indicator.style == "progress",
                pulsing: this.tab.state.indicator.style == "error",
                none: this.tab.state.indicator.style == "none"
            }
        },
        indicator_style() {
            let background_color = this.tab.state.indicator.color
            if (this.tab.state.indicator.style == "progress") background_color = null;
            return {
                backgroundColor: background_color
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  font-size: 0.85em;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .indicator-bar {
    height: 1px;
    position: absolute;
    width: 100%;
    overflow-x: hidden;

    &.bar {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    }

    &.pulsing {
      animation-name: PulsingBackground;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    @keyframes PulsingBackground {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    &.progress {
      .progress-bar {
        height: 1px;
        transition: width ease-in-out 0.5s;
      }
    }

    &.none {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;
    }
  }

  .insides {
    padding-left: 3px;
    padding-top: 1px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    .icon {
      font-size: 0.7em;
      padding-inline: 3px;
    }

    .title {
      margin-left: 2px;
      white-space: nowrap;
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .actions {

    }

    .close {
      font-size: 0.85em;
      padding-left: 2px;
    }
  }

  &.active {
    background-color: var(--primary-background-color);
  }

  &.top {
    border-bottom: 1px solid transparent;

    &.underline {
      border-bottom: 1px solid var(--focus-color);
    }

    &:focus {
      border-bottom: 1px solid var(--focus-color);
    }
  }

  &.right {
    border-left: 1px solid transparent;

    &.underline {
      border-left: 1px solid var(--focus-color);
    }

    &:focus {
      border-left: 1px solid var(--focus-color);
    }
  }

  &.bottom {
    border-top: 1px solid transparent;

    &.underline {
      border-top: 1px solid var(--focus-color);
    }

    &:focus {
      border-top: 1px solid var(--focus-color);
    }
  }

  &.left {
    border-right: 1px solid transparent;

    &.underline {
      border-right: 1px solid var(--focus-color);
    }

    &:focus {
      border-right: 1px solid var(--focus-color);
    }
  }

  &:hover {
    background-color: var(--primary-background-color);
  }
}
</style>
