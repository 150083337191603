import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ebf1aff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = {
  key: 0,
  class: "fa-solid fa-equals"
}
const _hoisted_3 = { class: "name" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "usage",
    class: "usage no-default-focus",
    tabindex: "0",
    onKeydown: _cache[0] || (_cache[0] = (...args) => (_ctx.on_keydown && _ctx.on_keydown(...args))),
    onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('click', _ctx.variable_usage)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.variable_usage.is_assign)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.variable_usage.name) + ":" + _toDisplayString(_ctx.variable_usage.line + 1), 1 /* TEXT */),
    _createElementVNode("div", {
      class: "code",
      innerHTML: _ctx.variable_usage.html_code.trim()
    }, null, 8 /* PROPS */, _hoisted_4)
  ], 544 /* NEED_HYDRATION, NEED_PATCH */))
}