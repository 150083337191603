import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { Phone } from "../../models/scenario_setting/phone";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PhoneClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Phone
    declare record: Phone
}
