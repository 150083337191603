import { VueRecord } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumPhoneStatus } from "../../auto_generated/enums";
import { EnumPhoneType } from "../../auto_generated/enums";
import { PhoneClient } from "../clients/phone_client";
import { PhoneScope } from "../scopes/phone_scope";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { PhoneProject } from "../models/phone_project";
import { create_vue_app } from "../../helpers/vue/create_vue_app";
import AddPhoneModal from "../../components/phone/AddPhoneModal.vue";
import { computed } from "../../helpers/vue/computed";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { on_user_load } from "../../helpers/events/on_user_load";
import { on_dom_unload } from "../../helpers/events/on_dom_unload";
import { LoadingData } from "select2";
import { GroupedDataFormat } from "select2";
import { DataFormat } from "select2";
import { select2_option_with_icon } from "../../helpers/dom/select2_option_with_icon";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import UiShowInSidebarData = UiSync.UiShowInSidebarData;
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { App } from "./app";
import SidebarTitle from "../../components/phone/SidebarTitle.vue";

// <editor-fold desc="TYPES">
export interface PhoneProps extends Props {
    udid: string,
    name: string,
    brand: string,
    model: string,
    hardware: string,
    sdk: string,
    release: string,
    owner: string,
    imei_1: string,
    imei_2: string,
    rooted: boolean,
    on_wifi: boolean,
    build_number: string
    baseband_version: string
    software_version: string
    kernel_version: string
    battery_level: number
    charging: boolean
    status: EnumPhoneStatus
    last_update: Date
    created_at: Date
    updated_at: Date
    adb_id: string
    display_height: number
    display_width: number
    phone_type: EnumPhoneType
    user_id: number
    private: boolean
    used_by: number
    phonector_id: string
    appium_screen_control: boolean
    state: string
    product_type: string
    pixel_ratio: number
    claimed_by_play_worker_group_id: number
    in_use_by_play_scenario_id: number
    in_use_by_play_sandbox_id: number
}
export type QuerifiedPhoneProps = QuerifyProps<PhoneProps>
export type PhoneCreateProps = Omit<PhoneProps, 'id'>
export type PhoneUpdateProps = Partial<PhoneProps>

export interface PhoneState extends State {}
export interface PhoneComputed extends Computed {}
export interface PhoneStaticState extends StaticState {
    sidebar_types: EnumPhoneType[]
    load_promises: Record<number | string, Promise<Phone>>
    sidebar_show_offline: boolean
}

// </editor-fold>

const console = new Consoler("warn")
export class Phone extends VueRecord {
    ['constructor']: typeof Phone

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PhoneClient
    static ScopeClass = PhoneScope
    static readonly primary_key = "udid"
    static sync_channels: string[] = []
    static state: PhoneStaticState = reactive<PhoneStaticState>({
        sidebar_types: [],
        load_promises: {},
        sidebar_show_offline: true,
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "status", "state", "phonector_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Phone> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Phone>> = {}

    static field_validators: ModelValidatorOpts<PhoneProps> = {}

    static resource_name = Enum.Resource.Label.PHONE
    static resource_id = Enum.Resource.Id.PHONE
    static icon_class = "fa-solid fa-mobile-screen"
    static color = () => get_css_var("--phone-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PhoneClient
    declare props: PhoneProps;
    declare state: PhoneState;
    declare computed: PhoneComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_devices_tree()) {
        return Phone.show_in_sidebar(this.key(), tree.project_version.key(), tree);
    }

    static async show_in_sidebar(phone_udids: string | string[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_devices_tree()) {
        let udids: string[];
        if (what_is_it(phone_udids) == "Array") {
            udids = phone_udids as string[]
        } else {
            udids = [phone_udids as string]
        }

        if (web.is_main) {
            Section.get_devices_section().enable()
            const all_keys: string[][] = []
            udids.forEach(udid => {
                const phone = Phone.find(udid)
                const keys: string[] = []
                if (Phone.state.sidebar_types.length > 1) {
                    keys.push(this.phone_tree_key(phone.props.phone_type))
                }
                keys.push(phone.tree_key())
                all_keys.push(keys)
            })
            tree.expand_paths(all_keys)
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, udids.map(udid => {
                return {
                    resource_id: Phone.resource_id,
                    id: udid
                }
            }))
        }
    }

    // <editor-fold desc="TREE">
    // <editor-fold desc="DND">
    static _testa_tree_node_dnd(): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => false),
            is_drop_area: computed(() => false),
        }
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXT MENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<PhoneScope, PhoneScope, Phone>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        icon: Phone.icon_class,
                        color: get_css_var("--button-white"),
                        key: "+",
                        callback: () => {
                            Phone.show_add_to_project();
                        },
                    }

                    if (this.props.status == Enum.Phone.Status.IN_USE ||
                        this.props.status == Enum.Phone.Status.CLAIMED ||
                        current.user?.is_superadmin()) {
                        items.set_online = {
                            name: "Set Online",
                            icon: "fa-solid fa-circle",
                            color: Phone.status_color(Enum.Phone.Status.ONLINE),
                            callback: () => {
                                this.client.update({
                                    status: Enum.Phone.Status.ONLINE,
                                    claimed_by_play_worker_group_id: null
                                })
                            }
                        }
                    }

                    if (this.props.status == Enum.Phone.Status.ONLINE) {
                        items.set_online = {
                            name: "Reboot",
                            icon: "fas fa-power-off",
                            callback: () => {
                                this.reboot()
                            },
                        }
                    }
                }


                return { ...items, ...default_items }
            }
        }
    }

    static _testa_tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<PhoneScope, PhoneScope, Phone>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        icon: Phone.icon_class,
                        color: get_css_var("--button-white"),
                        key: "+",
                        callback: () => {
                            Phone.show_add_to_project()
                        },
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<PhoneScope, PhoneScope, VueRecord>> = {}
            if (current_role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Phone.show_add_to_project()
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<PhoneScope, PhoneScope, Phone> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.PHONE,
            record: this,
            title: computed(() => {
                return {
                    template: this.props.name,
                    component: SidebarTitle,
                    component_props: { phone: this }
                }
            }),
            duplicable: computed(() => false),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    // return this.open()
                    return generate_resolved_promise()
                }
            },
            icon: computed(() => {
                return {
                    class: "fa-solid fa-circle",
                    color: this.status_color(),
                }
            }),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: false,
                is_drop_area: true,
                on_drop: (_e: DragEvent) => {
                    const apps = dnd.state.records.filter(r => r instanceof App) as unknown as App[]
                    this.install_apps(apps)
                },
                on_over: (e: DragEvent) => {
                    e.dataTransfer.dropEffect = "move"
                }
            },
            contextmenu: this._tree_contextmenu(),
        }
    }

    static testa_tree_node_data(phone_type: EnumPhoneType, project_id: number): TestaTree.NodeInput<PhoneScope, PhoneScope, Phone> {
        return {
            resource_id: Enum.Resource.Id.PHONE,
            folder: {
                open_fn: () => generate_resolved_promise(),
                file_scope: computed(() => {
                    let scope = PhoneProject.where({ project_id })
                                            .phones
                                            .where({ phone_type })
                    if (!Phone.state.sidebar_show_offline) scope = scope.not({ status: Enum.Phone.Status.OFFLINE })
                    return scope.order("name", "asc", "insensitive")
                }),
            },
            icon: computed(() => {
                return {
                    class: Phone.icon_class,
                    color: get_css_var("--phone-color"),
                    scale: 0.9,
                }
            }),
            key: this.phone_tree_key(phone_type),
            title: computed(() => {
                return { template: Phone.phone_type_pretty_name(phone_type) }
            }),
            dnd: Phone._testa_tree_node_dnd(),
            contextmenu: Phone._testa_tree_contextmenu()
        }
    }


    // </editor-fold>

    // <editor-fold desc="HELPERS">
    static phone_tree_key(phone_type: string) {
        return `${phone_type}_${this.resource_name.replaceAll(" ", "_")}_root`
    }

    static phone_type_icon_class(phone_type: EnumPhoneType) {
        switch (phone_type) {
            case Enum.Phone.Type.ANDROID:
                return "fa-brands fa-android"
            case Enum.Phone.Type.IPHONE:
                return "fa-brands fa-apple"
            case Enum.Phone.Type.SIMULATOR:
                return "fa-solid fa-file-zipper"
            default:
                return VueRecord.icon_class
        }
    }

    static phone_type_icon_color(phone_type: EnumPhoneType) {
        switch (phone_type) {
            case Enum.Phone.Type.ANDROID:
                return get_css_var("--android-color")
            case Enum.Phone.Type.IPHONE:
            case Enum.Phone.Type.SIMULATOR:
                return get_css_var("--ios-color")
            default:
                return VueRecord.icon_class
        }
    }

    static phone_type_option_template(data: LoadingData | GroupedDataFormat | DataFormat) {
        if (data.id == null) return data.text

        const phone_type: EnumPhoneType = Enum.Phone.Type.values.find(pt => pt == data.id)
        return select2_option_with_icon(
            data.text,
            Phone.phone_type_icon_class(phone_type),
            Phone.phone_type_icon_color(phone_type)
        )
    }

    status_color() {
        return Phone.status_color(this.props.status)
    }

    static status_color(status: EnumPhoneStatus) {
        switch (status) {
            case Enum.Phone.Status.IN_USE:
                return get_css_var("--phone-in-use-color")
            case Enum.Phone.Status.CLAIMED:
                return get_css_var("--phone-claimed-color")
            case Enum.Phone.Status.ONLINE:
                return get_css_var("--phone-online-color")
            case Enum.Phone.Status.CONFIGURING:
                return get_css_var("--phone-configuring-color")
            case Enum.Phone.Status.CONFIGURE_PENDING:
                return get_css_var("--phone-configure-pending-color")
            case Enum.Phone.Status.OFFLINE:
                return get_css_var("--phone-offline-color")
            default:
                return get_css_var("--phone-unknown-color")
        }
    }

    path(include_path = false) {
        const name = include_path ? this.name() : ""
        if (Phone.state.sidebar_types.length < 2) {
            return `/${name}`
        } else {
            return `/${Phone.phone_type_pretty_name(this.props.phone_type)}/${name}`
        }
    }

    static phone_type_pretty_name(phone_type: EnumPhoneType) {
        switch (phone_type) {
            case Enum.Phone.Type.ANDROID:
                return "Androids"
            case Enum.Phone.Type.IPHONE:
                return "iPhones"
            case Enum.Phone.Type.SIMULATOR:
                return "Simulators"
            default:
                return "Unknown"
        }
    }

    is_android() {
        return this.props.phone_type == Enum.Phone.Type.ANDROID
    }

    is_iphone() {
        return this.props.phone_type == Enum.Phone.Type.IPHONE
    }

    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    static show_add_to_project() {
        create_vue_app(AddPhoneModal, {})
    }

    install_apps(apps: App[]) {
        this.client.install_with_progress(apps.pluck("props").pluck("id"))
    }

    reboot() {
        this.client.reboot()
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
Phone.register_resource(Phone)
PhoneClient.ModelClass = Phone
PhoneScope.ModelClass = Phone

Phone.state.sidebar_types = computed(() => {
    let scope = Phone.get_scope()
    if (!Phone.state.sidebar_show_offline) scope = scope.not({ status: Enum.Phone.Status.OFFLINE })
    return scope.toArray().map(phone => phone.props.phone_type).uniq() as EnumPhoneType[]
})

global_event_bus.$on("after_project_version_unload", () => {
    Phone.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project?.props?.id,
        (project_id) => {
            Phone.unsync()
            if (project_id != null) Phone.sync(`/sync/project/${project_id}/phones`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )

    const show_offline_devices_key = "sidebar_show_offline_devices"
    on_user_load(() => {
        Phone.state.sidebar_show_offline = current.user.storager.get(show_offline_devices_key, true)
    })

    on_dom_unload(() => {
        current.user?.storager?.set(show_offline_devices_key, Phone.state.sidebar_show_offline)
    })
})

if (web.is_main) {
    ui_sync.register_ui_task("show_in_sidebar", Phone.resource_id, (sender: string, data: UiShowInSidebarData) => {
        Phone.show_in_sidebar(data.id as string, current.project_version.key())
    })
}

declare global {
    interface Window {
        Phone: typeof Phone
    }
}
window.Phone = Phone
// </editor-fold>

