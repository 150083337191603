import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f1089d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "breadcrumb" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512 /* NEED_PATCH */),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "container",
            class: "overflowable",
            style: _normalizeStyle({zIndex: _ctx.z_index, top: _ctx.y, left: _ctx.x, width: _ctx.width, height: _ctx.height})
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 4 /* STYLE */))
        : _createCommentVNode("v-if", true)
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}