import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edd8bc2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Node = _resolveComponent("Node")

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.tree.element_id,
    class: "tree no-default-focus",
    tabindex: "-1",
    onKeydown: _cache[0] || (_cache[0] = (e) => _ctx.tree.on_keydown(e)),
    onMousedown: _cache[1] || (_cache[1] = (e) => _ctx.tree.on_mousedown(e))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tree.computed.children, (node) => {
      return (_openBlock(), _createBlock(_component_Node, {
        key: node.key,
        node: node
      }, null, 8 /* PROPS */, ["node"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}