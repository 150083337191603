<template>
  <button
      :id="id"
      ref="button"
      :tabindex="tab_index"
      class="vue-button no-default-unfocus"
      :class="clazz"
      :style="styles"
      @click.stop="on_click"
      @keydown="click_on_enter"
  >
    {{ text }}
    <i v-if="icon_class != null"
       :class="icon_class"/>
    <span
        v-if="action_in_progress"
        class="loading-container">
      <Loading
          :size="0.75"
          :fadein="true"
          margin="0"
          color="white"
      />
    </span>
    <slot/>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ClickEvent = JQuery.ClickEvent;
import { PropType } from "vue";
import { ColorClass } from "../../types/color_class";
import { FormValidator } from "../../helpers/validator/form_validator";
import { what_is_it } from "../../helpers/generic/what_is_it";
import Loading from "./Loading.vue";
import { click_on_enter } from "../../helpers/events/click_on_enter";
import { Validator } from "../../helpers/validator/validator";
import { ButtonAction } from "../../types/components/testa/button_action";



export default defineComponent({
    components: { Loading },
    props: {
        text: {
            type: String,
            required: true,
        },
        icon_class: {
            type: String,
            required: false,
            default: null
        },
        tab_index: {
            type: [String, Number],
            required: false,
            default: 0,
        },
        color_class: {
            type: String as PropType<ColorClass>,
            required: false,
            default: "default",
        },
        min_width: {
            type: [Number, String],
            required: false,
            default: null,
        },
        focus: {
            type: Boolean,
            required: false,
            default: false,
        },
        id: {
            type: String,
            required: false,
            default: "",
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        click_action: {
            type: Function as PropType<ButtonAction>,
            required: false,
            default: null,
        },
        allow_concurrent_click_action: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['click', 'action-done', 'action-success', 'action-error'],
    data() {
        return {
            action_in_progress: false,
        }
    },
    computed: {
        styles() {
            let style = ""
            if (this.form_validator?.invalid) {
                style += "cursor: not-allowed;"
            }
            if (this.min_width != null) {
                if (Validator.is_numeric(this.min_width)) {
                    style += `min-width: ${this.min_width}px;`
                } else {
                    style += `min-width: ${this.min_width};`
                }
            }
            return style
        },
        clazz() {
            return {
                red: this.color_class == "red",
                default: this.color_class == "default",
                blue: this.color_class == "blue",
                green: this.color_class == "green",
                grey: this.color_class == "grey",
                white: this.color_class == "white",
                disabled: this.disabled,
            }
        },
    },
    // <editor-fold desc="HOOKS">
    mounted() {
        if (this.focus) {
            $(this.$refs.button as HTMLElement).trigger('focus')
        }
    },
    // </editor-fold>
    methods: {
        click_on_enter,
        click() {
            const button = this.$refs.button as HTMLButtonElement
            button.dispatchEvent(new Event('click'))
        },
        on_click(e: MouseEvent) {
            e.stopImmediatePropagation()
            e.preventDefault();
            if (this.disabled) return;
            if (this.action_in_progress && !this.allow_concurrent_click_action) return;


            this.form_validator?.on_confirm_click()
            if (this.form_validator == null || this.form_validator.valid) {
                this.$emit('click', e)
                if (this.click_action != null) {
                    const promise = this.click_action();
                    if (promise == null) {
                        this.$emit('action-done')
                        return;
                    }

                    this.action_in_progress = true
                    if (what_is_it((promise as Promise<any>).finally) == "Function") {
                        (promise as Promise<any>).then(() => {
                            this.$emit('action-success')
                        }).catch((e) => {
                            console.error(e)
                            this.$emit('action-error', e)
                        }).finally(() => {
                            this.action_in_progress = false
                            this.$emit('action-done')
                        })
                    } else {
                        (promise as JQuery.jqXHR).then(() => {
                            this.$emit('action-success')
                        }).fail((e) => {
                            this.$emit('action-error', e)
                        }).always(() => {
                            this.action_in_progress = false
                            this.$emit('action-done')
                        })
                    }
                }
            }
        },
        keydown(e: KeyboardEvent) {
        },
    },
})
</script>

<style lang="scss" scoped>
.vue-button {
  white-space: nowrap;
  border-radius: 5px;
  height: fit-content;
  color: var(--font-color);
  padding: 5px 9px 5px;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  overflow: hidden;
  align-items: center;

  i {
    margin-left: 0.3em;
  }

  @keyframes buttonLoadSlideIn {
    from {
      margin-left: 0;
      padding-inline: 0;
      width: 0;
    }

    to {
      margin-left: 5px;
      padding-inline: 2px;
      width: 100%;
    }
  }

  .loading-container {
    min-width: 0;
    margin-left: 0;
    padding-inline: 0;
    width: 0;
    animation: buttonLoadSlideIn 0.5s ease-in 0.5s forwards;
    overflow: hidden;
  }

  &:not(:focus) {
    border: 1px solid var(--border-color-dark);
  }

  &:not(.disabled) {
    &:hover {
      filter: brightness(1.2);
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.default {
    background-color: var(--secondary-background-color);
  }

  &.grey {
    background-color: var(--button-grey);
  }

  &.red {
    background-color: var(--button-red);
  }

  &.green {
    background-color: var(--button-green);
  }

  &.blue {
    background-color: var(--button-blue);
  }

  &.white {
    background-color: var(--button-white);
  }
}
</style>
