<template>
  <div
      class="inspector-side-kit"
      :class="{left: side == 'left', right: side == 'right'}"
      :style="style"
      @mouseleave="inspector.on_mouse_leave"
      @mouseenter="inspector.on_mouse_enter"
      @wheel.stop.passive
  >
    <div class="title-container">
      <span class="title"
            :title="title">
        {{ title }}
      </span>
      <div class="flex-expander"/>
      <span class="close">
        <ActionIcon
            icon_class="fa-solid fa-times"
            color_class="red"
            :click_action="hide_side_kit"
        />
      </span>
    </div>

    <div v-if="inspector.active_snapshot != null && !inspector.active_snapshot.is_error"
         class="side-kit-content">
      <ResizableFlex
          id="inspector_side_kit_resizable_flex"
          areas="info tree"
          direction="column"
          :initial_area_sizes="['60%', '40%']"
      >
        <template #info>
          <div class="info-grid-area">
            <div class="filter">
              <div class="snapshot-info-container">
                <div class="snapshot-info">
                  <span class="snapshot-info-label">
                    Snapshots:
                  </span>
                  <span class="snapshot-info-value">
                    {{ inspector.get_snapshots_count() }}
                  </span>
                </div>
                <div v-if="inspector.is_filtering"
                     class="snapshot-info">
                  <span class="snapshot-info-label"
                        style="color: var(--button-yellow);">
                    Matches:
                  </span>
                  <span class="snapshot-info-value">
                    {{ inspector.filtered_elements.length }}
                  </span>
                </div>

                <div class="snapshot-info">
                  <span class="snapshot-info-label">
                    Elements:
                  </span>
                  <span class="snapshot-info-value">
                    {{ inspector.active_snapshot?.root_inspector_element?.self_and_descendants?.length }}
                  </span>
                </div>
              </div>
              <Input
                  :id="`${inspector.id}_filter`"
                  v-model="inspector.filter"
                  placeholder="Filter"
                  :delayed_debounce_time="200"
                  :scale="0.9"
                  :show_clear_action="true"
              />
            </div>

            <div class="mouse-n-click-information">
              <span v-if="inspector.scaled_cursor_coordinates != null && inspector.hovering_in == 'inspector'"
                    class="coords-container">
                <span class="coords-label">
                  Cursor:
                </span>
                <span class="coords-info">
                  {{
                    parseInt(inspector.scaled_cursor_coordinates.x.toString())
                  }} , {{ parseInt(inspector.scaled_cursor_coordinates.y.toString()) }}
                </span>
              </span>
              <span class="flex-expander"/>
              <span v-if="inspector.scaled_clicked_coordinates != null"
                    class="coords-container"
                    @click="copy_coords(inspector.scaled_clicked_coordinates)"
              >
                <span class="copy-icon">
                  <i v-if="coords_copied"
                     class="fa-solid fa-check"
                     style="color: var(--button-yellow)"
                  />
                  <i v-else
                     class="fa-solid fa-clone"/>
                </span>
                <span class="coords-label">
                  Clicked:
                </span>
                <span class="coords-info">
                  {{
                    parseInt(inspector.scaled_clicked_coordinates.x.toString())
                  }} , {{ parseInt(inspector.scaled_clicked_coordinates.y.toString()) }}
                </span>
              </span>
            </div>

            <template v-if="inspector.is_filtering">
              <div class="info-container">
                <InspectorElementCollectionInfo
                    title="Filtered"
                    :elements="inspector.filtered_elements"
                    :minimized_elements="5"
                />
              </div>
            </template>
            <template v-else>
              <div v-if="inspector.selected_element"
                   class="info-container">
                <InspectorElementInfo
                    type="selected"
                    :element="inspector.selected_element"
                />
              </div>

              <div v-if="inspector.intersecting_elements.length > 1"
                   class="info-container">
                <InspectorElementCollectionInfo
                    title="Intersections"
                    :elements="inspector.intersecting_elements"
                    :minimized_elements="10"
                />
              </div>
            </template>

            <div class="flex-expander"/>

            <div v-if="inspector.hovered_element"
                 class="info-container">
              <InspectorElementInfo
                  type="hovered"
                  :element="inspector.hovered_element"
              />
            </div>
          </div>
        </template>
        <template #tree>
          <div class="tree-area"
               @mouseleave="inspector.throttled_hover_element(null, null)">
            <Tree v-if="inspector.active_snapshot != null"
                  :tree="inspector.active_snapshot.tree"/>
          </div>
        </template>
      </ResizableFlex>
    </div>

    <div v-else
         class="snapshot-error-container">
      <div v-if="inspector.active_snapshot == null"
           class="error-message">
        Snapshot is missing
      </div>
      <div v-else-if="inspector.active_snapshot.is_error"
           class="error-message">
        <div>Snapshot parsing failed:</div>
        <div>{{ inspector.active_snapshot.error_message }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Inspector } from "../inspector";
import Input from "../../../../../testa/Input.vue";
import InspectorElementInfo from "./InspectorElementInfo.vue";
import InspectorElementCollectionInfo from "./InspectorElementCollectionInfo.vue";
import ResizableFlex from "../../../../../testa/ResizableFlex.vue";
import Tree from "../../../../../testa/tree/Tree.vue";
import { CSSProperties } from "vue";
import { Coords } from "../../../../../../types/globals";
import { copy_text_to_clipboard } from "../../../../../../helpers/generic/copy_to_clipboard";
import ActionIcon from "../../../../../testa/ActionIcon.vue";

export default defineComponent({
    components: { ActionIcon, Tree, ResizableFlex, InspectorElementCollectionInfo, InspectorElementInfo, Input },
    // <editor-fold desc="PROPS">
    props: {
        side: {
            type: String as PropType<"left" | "right">,
            required: true
        },
        inspector: {
            type: Object as PropType<Inspector>,
            required: true
        },
        max_width: {
            type: Number,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            coords_copied: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        title() {
            return `${this.inspector.phone.name()} Inspector`
        },
        style() {
            const maxWidth = `${this.max_width}px`
            const style: CSSProperties = {
                maxWidth,
            }
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        copy_coords(coords: Coords) {
            this.coords_copied = true
            copy_text_to_clipboard(`${coords.x}, ${coords.y}`)
            setTimeout(() => {
                this.coords_copied = false
            }, 3000)
        },
        hide_side_kit() {
            this.inspector.side_kit_closed = true
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.inspector-side-kit {
  position: absolute;
  pointer-events: auto;
  height: calc(100% + 3px);
  top: -2px;

  //background: linear-gradient(90deg, transparent 0%, var(--ternary-background-color) 20%);
  background: var(--primary-background-color);

  // inspector-controls is 9996
  // inspector-contextmenu-layer is 9998
  // inspector-contextmenu is 9999
  z-index: 9997;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  $border: 2px solid var(--border-color);
  &.left,
  &.right {
    width: 100%;
    border-top: $border;
    border-bottom: $border;
  }


  &.left {
    right: calc(100% + 2px);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: $border;

    -webkit-box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.25);
    box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  }

  &.right {
    left: calc(100% + 2px);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: $border;

    -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  }

  $title_height: 20px;
  $title_padding: 5px;

  .title-container {
    font-size: 0.85em;
    white-space: nowrap;
    padding: $title_padding;
    height: $title_height;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .close {
      width: 25px;
      justify-content: end;
      align-items: start;
      flex-shrink: 0;
    }
  }

  .side-kit-content {
    height: calc(100% - $title_height - calc(2 * $title_padding));
    flex-shrink: 0;

    .info-grid-area {
      $padding: 5px;
      padding: $padding 0 $padding $padding;
      width: calc(100% - $padding);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .snapshot-info-container {
        font-size: 10px;
        width: calc(100% - 6px);
        height: 12px;
        display: flex;
        flex-direction: row;
        padding-inline: 3px;
        justify-content: space-between;

        .snapshot-info {
          .snapshot-info-label {
            color: var(--font-color-secondary);
          }

          .snapshot-info-value {
            color: var(--font-color)
          }
        }

      }

      .filter {
        margin-bottom: 10px;
      }

      .info-container {
        margin-block: 5px;
        padding-block: 5px;
        padding-inline: 4px;
        border-radius: 8px;
        background: var(--ternary-background-color);
      }

      .mouse-n-click-information {
        height: 15px;
        font-size: 10px;
        display: flex;
        flex-direction: row;
        padding-inline: 3px;


        .coords-container {
          cursor: pointer;

          &:hover {
            .copy-icon {
              display: inline-flex;
            }
          }

          .copy-icon {
            display: none;
            margin-right: 3px;
          }

          .coords-label {
            color: var(--font-color-secondary);
            font-size: 0.9em;
            margin-right: 3px;
          }

          .coords-info {

          }
        }

      }
    }

    .tree-area {
      display: flex;
      flex-direction: row;
      width: fit-content;
      flex-grow: 1;
    }
  }

  .snapshot-error-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    justify-content: center;
    align-items: center;

    .error-message {
      color: var(--button-red);
    }
  }
}
</style>
