<template>
  <Modal
      id="snippet_history_modal"
      :dismissible="true"
      :show_confirm="false"
      :backdrop_dim_level="0"
      :padding="0"
      :is_loading="is_loading"
      :cancel_on_escape="true"
      :focus_last_active_on_unmount="true"
      @cancel="cancel"
  >
    <template #body>
      <div class="snippet-history-container">
        <RFlex :rflex="rflex">
          <template #items>
            <div class="items-container">
              <Loading
                  v-if="is_loading"
                  color="primary"
                  type="rotating_plane"
              />
              <template v-else>
                <template v-for="savepoint in savepoints"
                          :key="savepoint.stage_key">
                  <div
                      :ref="active_savepoint == savepoint ? 'active_savepoint': 'not_active_savepoint'"
                      class="result"
                      :class="active_savepoint == savepoint ? `active`: ''"
                      tabindex="0"
                      @keydown="click_on_enter"
                      @click="active_savepoint = savepoint"
                  >
                    <div class="datetime-info">
                      <div v-datetime="savepoint.props.updated_at"/>
                      <span class="separator">
                        -
                      </span>
                      <div v-moment="savepoint.props.updated_at"/>
                    </div>

                    <div class="snippet-name">
                      {{ savepoint.props.name }}
                    </div>
                    <div class="user-info">
                      <template v-if="savepoint.user == null">
                        user deleted
                      </template>
                      <div v-else>
                        {{ savepoint.user.props.email }}
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>

          <template #code>
            <SnippetDiffTwoPane
                v-if="active_savepoint != null"
                :key="active_savepoint.props.id"
                :other_code="active_savepoint.props.code"
                :other_label="other_label()"
                :snippet="snippet"
                :show_apply_buttons="false"
            />
          </template>
        </RFlex>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../Modal.vue";
import { PropType } from "vue";
import { Snippet } from "../../../../../vue_record/models/snippet";
import { SnippetSavepoint } from "../../../../../vue_record/models/snippet_savepoint";
import Loading from "../../../Loading.vue";
import { click_on_enter } from "../../../../../helpers/events/click_on_enter";
import { nextTick } from "vue";
import SnippetDiffTwoPane from "./SnippetDiffTwoPane.vue";
import moment from "moment";
import { ResizableFlex } from "../../../resizable/resizable_flex/resizable_flex";
import RFlex from "../../../resizable/resizable_flex/RFlex.vue";

export default defineComponent({
    components: { RFlex, SnippetDiffTwoPane, Loading, Modal },
    // <editor-fold desc="PROPS">
    props: {
        snippet: {
            type: Object as PropType<Snippet>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            is_loading: true,
            active_savepoint: null as SnippetSavepoint,
            rflex: ResizableFlex.new({
                id: "snippet_history_resizable_rflex",
                direction: "row",
                fill_container: true,
                areas: [
                    {
                        id: "items",
                        min_px_size: 150,
                        initial_px_size: 250,
                        max_ratio_size: 0.5
                    },
                    {
                        id: "code",
                        grow_rate: 9,
                        shrink_rate: 9

                    }
                ]
            })
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        moment() {
          return moment
        },
        savepoints(): SnippetSavepoint[] {
            return this.snippet.snippet_savepoints.order("updated_at", "desc").toArray()
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        savepoints() {
            if (this.active_savepoint != null) return;
            if (this.savepoints.length == 0) return
            this.active_savepoint = this.savepoints[0]
        },
        active_savepoint() {
            if (this.active_savepoint != null) {
                nextTick(() => {
                    const active_savepoints = this.$refs.active_savepoint as HTMLElement[]
                    if (active_savepoints == null) return;
                    active_savepoints[0].focus()
                })
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        Snippet.ClientClass.load(this.snippet.key()).then(() => {
            this.snippet.client.load_savepoints().then(() => this.is_loading = false)
        })
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        click_on_enter,
        cancel() {
            this.$.appContext.app.unmount();
        },
        other_label() {
            const m = moment(this.active_savepoint.props.updated_at)
            const datetime = m.strftime('%H:%M:%S %d.%m.%Y')
            const ago = m.fromNow();
            return `${datetime}  -  ${ago}`
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.snippet-history-container {
  width: 85vw;
  height: 80vh;
  max-height: 80vh;
  max-width: 85vw;
  resize: both;
  overflow: auto;

  .items-container {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: var(--ternary-background-color);
    padding: 5px;
    overflow: auto;


    .result {
      border-radius: 5px;
      padding: 5px;
      margin-top: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      .datetime-info {
        font-size: 0.7em;
        display: flex;
        flex-direction: row;

        .separator {
          margin-inline: 5px;
        }
      }

      .snippet-name {
        margin-top: 2px;
        margin-bottom: 2px;
      }

      .user-info {
        color: var(--font-color-secondary);
        font-size: 0.65em;
        display: flex;
        flex-direction: column;
      }


      &.active {
        background-color: var(--primary-background-color);
      }

      &:hover {
        background-color: var(--primary-background-color);
      }
    }
  }
}
</style>
