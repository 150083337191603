import { PlaySnippet } from "../../models/play/play_snippet";
import { PlayScenario } from "../../models/play/play_scenario";
import { PlayLog } from "../../models/play/play_log";
import { SnippetSavepoint } from "../../models/snippet_savepoint";
import { PlayLogScope } from "../../scopes/play/play_log_scope";


declare module "../../models/play/play_snippet" {
    interface PlaySnippet {
        play_scenario: PlayScenario
        snippet_savepoint: SnippetSavepoint

        play_logs: PlayLogScope
    }
}

PlaySnippet.belongs_to("play_scenario", PlayScenario, "play_scenario_id")
PlaySnippet.belongs_to("snippet_savepoint", SnippetSavepoint, "snippet_savepoint_id")

// has many
PlaySnippet.has_many("play_logs", PlayLog, "play_snippet_id", { dependent_unload: true })

