import { ScenarioScope } from "../scopes/scenario_scope";
import { ScenarioFolderScope } from "../scopes/scenario_folder_scope";
import { User } from "../models/user";
import { ProjectVersion } from "../models/project_version";
import { ScenarioFolder } from "../models/scenario_folder";
import { Scenario } from "../models/scenario";
import { ScenarioFolderSavepoint } from "../models/scenario_folder_savepoint";

declare module "../models/scenario_folder" {
    interface ScenarioFolder {
        user: User
        project_version: ProjectVersion
        parent_folder: ScenarioFolder

        current_savepoint: ScenarioFolderSavepoint

        scenarios: ScenarioScope
        scenario_folders: ScenarioFolderScope
    }
}

ScenarioFolder.belongs_to("user", User, "user_id")
ScenarioFolder.belongs_to("project_version", ProjectVersion, "project_version_id")
ScenarioFolder.belongs_to("parent_folder", ScenarioFolder, "scenario_folder_id")


ScenarioFolder.has_many("scenarios", Scenario, "scenario_folder_id")
ScenarioFolder.has_many("scenario_folders", ScenarioFolder, "scenario_folder_id")

ScenarioFolder.has_one("current_savepoint", ScenarioFolderSavepoint, "scenario_folder_id")
