import { VueRecordClient } from "../base/vue_record_client";
import { App } from "../models/app";
import { AppUpdateProps } from "../models/app";
import { CacheStoreValue } from "../base/vue_record_client";
import { Jobberman } from "../../helpers/jobberman/jobberman";

export class AppClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof App
    declare record: App

    download() {
        $.fileDownload(`/apps/${this.key()}/download`);
    }

    update(app: AppUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/apps/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    app,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static upload_from_url(project_id: number, app_url: string) {
        return Jobberman.promise({
            run: (jobberman_id, resolve, reject) => {
                $.ajax({
                    url: `/apps/upload_from_url`,
                    type: "POST",
                    processData: false,
                    contentType: "application/json",
                    data: JSON.stringify({
                        project_id,
                        app_url,
                        jobberman_id,
                        authenticity_token,
                    }),
                    error: (e) => {
                        reject(e)
                    },
                    statusCode: ajax_status_codes,
                })
            }
        })
    }

    static upload(file: File, index: number) {
        App.state.upload_progresses.push(0);
        const formData = new FormData();
        formData.append("authenticity_token", authenticity_token)
        formData.append("file", file)
        formData.append("project_id", current.project.props.id.toString())
        $.ajax({
            url: "/apps/upload",
            data: formData,
            type: "POST",
            contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
            processData: false, // NEEDED, DON'T OMIT THIS
            xhr: function() {
                const xhr = new window.XMLHttpRequest();
                xhr.addEventListener("load", function() {
                    $("#import_resources_input").val("");
                })
                xhr.addEventListener("error", function() {
                    $("#import_resources_input").val("");
                })
                // noinspection DuplicatedCode
                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable) {
                        App.state.upload_progresses[index] = (evt.loaded / evt.total) * 100;
                        const summed_progress = App.state.upload_progresses.reduce((a, b) => a + b)
                        const progress = summed_progress / (App.state.upload_progresses.length * 100)
                        progress_bar.go(progress * 100)
                    }
                }, false);
                return xhr;
            },
            statusCode: ajax_status_codes
        });
    }
}
