<template>
  <div v-if="!inspector_element.is_hidden && inspector_element.in_viewport"
       :id="inspector_element.inspector_id"
       ref="element"
       class="element"
       :class="klass"
       :style="style"
       @click.stop="(e) => inspector_element.on_click(e)"
       @contextmenu="on_contextmenu"
       @mouseenter.stop="(e) => inspector_element.on_hover(e, 'inspector')"
  >
  </div>
  <template v-if="!inspector_element.is_hidden">
    <template v-for="child_element in inspector_element.children"
              :key="child_element.id">
      <InspectorElement
          :inspector_element="child_element"
      />
    </template>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { PropType } from "vue";
import { InspectorElement } from "./inspector_element";

export default defineComponent({
    props: {
        inspector_element: {
            type: Object as PropType<InspectorElement>,
            required: true
        },
    },
    emits: [],
    data() {
        return {}
    },
    computed: {
        style() {
            return {
                zIndex: this.inspector_element.level + 9,
                top: `${this.inspector_element.top}px`,
                bottom: `${this.inspector_element.bottom}px`,
                left: `${this.inspector_element.left}px`,
                right: `${this.inspector_element.right}px`
            }
        },
        inspector() {
            return this.inspector_element.inspector
        },
        klass() {
            const selected = (this.inspector.selected_element_id == this.inspector_element.id)
            const highlighted = (this.inspector.hovered_element_id == this.inspector_element.id && !(this.inspector.hovering_in == 'inspector'))

            const search_match = this.inspector.is_filtering && this.inspector_element.is_filter_match
            return {
                selected,
                highlighted,
                "filter-match": search_match,
                "filter-nomatch": this.inspector.is_filtering && !search_match
            }
        }
    },
    watch: {
        is_hidden() {
            // nextTick(() => this.attach_objects_to_inspector_element())
        }
    },
    created() {
    },
    mounted() {
    },
    updated() {
    },
    methods: {
        on_contextmenu(e: MouseEvent) {
            console.log(e);

            e.preventDefault();
            e.stopPropagation();
            $.contextMenu("destroy", ".inspector");

            const position_context_menu = (opt: ContextMenu.Options) => {
                let new_x;
                const $element = $(this.$refs.element as HTMLElement);
                opt.$menu.css("width", "");
                new_x = $element.offset().left + $element.width();
                let top = $element.offset().top + ($element.height() / 2);

                const context_width = opt.$menu.width() + 6
                const window_overflow = (new_x + context_width) - $(window).width()
                if (window_overflow > 0) {
                    new_x = new_x - window_overflow;
                    top = $element.offset().top + $element.height();
                }

                opt.$menu.css({ top, left: new_x });
            }

            $.contextMenu({
                selector: ".inspector",
                build: () => {
                    const items = this.inspector_element._contextmenu_items()
                    return {
                        className: "inspector-element-modal",
                        position: position_context_menu,
                        events: {
                            show: (options) => {
                                setTimeout(() => {
                                    options.$menu[0].focus()
                                    options.$menu.css("z-index", "9999")
                                    options.$layer.css("z-index", "9998")
                                    this.inspector_element.inspector.hover_element(this.inspector_element, "contextmenu")
                                    this.inspector_element.inspector.activate_inspector();
                                }, 1)
                            },
                            hide: () => {

                            },
                        },
                        items,
                        callback: () => {
                        }
                    }
                },
            });

            $(this.$refs.element as HTMLElement).trigger('contextmenu')
        }
    }
})
</script>

<style lang="scss">

.inspector-element-modal {
  position: fixed;
  display: inline-block;
  padding: .25em 0;
  margin: .3em;
  font-family: inherit;
  font-size: 13px;
  list-style-type: none;
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-contrast-color);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
}

.inspector-element-modal-item {
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: .2em 0.75em 0.2em 2em;
  color: var(--font-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--secondary-background-color);
}

.inspector-element-modal-item:hover {
  filter: brightness(1.1);
  background-color: var(--primary-background-color);
}

.inspector-element-modal-item-icon {
  position: absolute;
  top: .3em;
  left: .5em;
}

.inspector-element-modal-item-key {
  color: var(--font-color-secondary);
  float: right;
  font-size: 11px;
  text-shadow: none;
}

</style>

<style lang="scss" scoped>
.element {
  position: absolute;
  pointer-events: all;
  border: 1px solid transparent;
  outline: 2px solid transparent;

  &:hover, &.highlighted {
    /*background-color: rgba(255, 255, 0, 0.24);*/
    border: 1px solid var(--secondary-light-background-color);
    outline: 2px solid var(--secondary-dark-background-color);
  }

  &.filter-match {
    outline: 1px solid var(--button-yellow) !important;
    pointer-events: all;
  }

  &.filter-nomatch {
    pointer-events: none;
  }

  &.selected {
    /*background-color: rgba(113, 11, 255, 0.38);*/
    //border: 1px solid var(--secondary-light-background-color);
    outline: 2px solid var(--button-blue);
  }
}
</style>
<script setup lang="ts">
</script>
