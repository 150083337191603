<template>
  <Teleport :to="`#${teleport_id}`">
    <div class="search-container">
      <Input
          id="codemirror_search_input"
          ref="codemirror_search_input"
          v-model="search_query"
          placeholder="Search"
          :focus="true"
          :throttle_time="150"
          :no_round_corners="true"
          :no_focus_border="true"
          :scale="0.867"
          :clear_with_esc="false"
          @keydown="on_keydown"
      />

      <span class="match-counter">
        {{ current_match }} / {{ total_matches }}
      </span>

      <ActionIcon
          icon_class="fa-solid fa-arrow-up"
          color_class="white"
          title="Previous Occurrence"
          :scale="0.85"
          :no_click_focus="true"
          @click="find_previous"
      />

      <ActionIcon
          icon_class="fa-solid fa-arrow-down"
          color_class="white"
          title="Next Occurrence"
          :scale="0.85"
          :no_click_focus="true"
          @click="find_next"
      />

      <ToggleIcon
          v-model="case_sensitive"
          enabled_icon_class="fa-solid fa-text-height"
          title="Match case"
          :scale="0.85"
          :no_click_focus="true"
          @click="focus_input"
      />

      <div class="flex-expander"/>

      <ActionIcon
          icon_class="fa-solid fa-times"
          color_class="red"
          :click_action="cancel"
          margin="0 9px 0 4px"
          :scale="0.85"
      />
    </div>
  </Teleport>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { generate_eid } from "../../../../../helpers/generate/generate_eid";
import Input from "../../../Input.vue";
import ActionIcon from "../../../ActionIcon.vue";
import ToggleIcon from "../../../ToggleIcon.vue";
import { MarkerRange } from "codemirror";
import { TextMarker } from "codemirror";
import { jump_to_line } from "../../../../../helpers/codemirror/helpers/jump_to_line";
import { KeyCode } from "../../../../../types/globals";

export default defineComponent({
    components: { ToggleIcon, ActionIcon, Input },
    // <editor-fold desc="PROPS">
    props: {
        cm: {
            type: Object as PropType<CodeMirror.Editor>,
            required: true,
        },
        search: {
            type: String,
            required: false,
            default: ""
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            teleport_id: generate_eid(),
            search_query: this.search,
            case_sensitive: false,
            current_cursor: this.cm.getCursor(),
            match_markers: [] as TextMarker<MarkerRange>[],
            current_result_marker: null as TextMarker<MarkerRange>,
            search_cursor: null as CodeMirror.SearchCursor,
            current_match: 0,
            total_matches: 0,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        search_query: {
            handler() {
                this.do_search()
            },
            immediate: true
        },
        case_sensitive() {
            this.do_search();
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMount() {
        const wrapper = this.cm.display.wrapper
        const container = document.createElement("div")
        container.style.width = "100%"
        container.classList.add("codemirror-search-teleport-container")
        container.id = this.teleport_id
        wrapper.prepend(container)
    },
    mounted() {

    },
    unmounted() {
      this.cm.refresh()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_keydown(e: KeyboardEvent) {
            switch (e.code) {
                case KeyCode.ESC:
                    e.preventDefault();
                    e.stopPropagation();
                    this.cancel();
                    break;
                case KeyCode.UP:
                    this.find_previous();
                    break;

                case KeyCode.DOWN:
                    this.find_next();
                    break;

                case KeyCode.ENTER:
                    if (e.shiftKey) {
                        this.find_previous()
                    } else {
                        this.find_next();
                    }
                    break;
            }
        },
        focus_input() {
            (this.$refs.codemirror_search_input as typeof Input).$refs.input.focus()
        },
        cancel() {
            this.match_markers.forEach(m => m.clear())
            this.current_result_marker?.clear();
            this.cm.focus();
            this.$.appContext.app.unmount();
            return null;
        },
        do_search() {
            this.match_markers.forEach(m => m.clear())
            this.current_result_marker?.clear();
            this.cm.setSelection(this.current_cursor, this.current_cursor)
            if (this.search_query == "") return;

            const all_search_cursor = this.cm.getSearchCursor(this.search_query, { line: 0, ch: 0 }, !this.case_sensitive);
            this.search_cursor = this.cm.getSearchCursor(this.search_query, this.current_cursor, !this.case_sensitive);
            this.total_matches = 0
            this.current_match = 0;
            let matches_before_current_cursor = 0;
            while (all_search_cursor.find(false)) {
                const from = all_search_cursor.from();
                const to = all_search_cursor.to()
                ++this.total_matches;
                if (from.line < this.current_cursor.line || (from.line == this.current_cursor.line && from.ch < this.current_cursor.ch)) {
                    ++matches_before_current_cursor;
                }
                this.match_markers.push(this.cm.markText(from, to, { className: "cm-search-match" }))
            }
            this.current_match = matches_before_current_cursor;
            this.find_next();
        },
        find_next() {
          if (this.search_cursor == null) return;

          const result = this.search_cursor.findNext();
          if (result) {
              const from = this.search_cursor.from()
              const to = this.search_cursor.to();
              this.current_cursor = to

              this.current_result_marker?.clear();
              this.current_result_marker = this.cm.markText(from, to, { className: "cm-search-match-current" })
              this.cm.setSelection(from, to)
              jump_to_line(this.cm, to.line, to.ch)
              ++this.current_match;
          } else if (this.total_matches > 0) {
              this.cm.setSelection(this.current_cursor, this.current_cursor)
              this.search_cursor = this.cm.getSearchCursor(this.search_query, { line: 0, ch: 0 }, !this.case_sensitive);
              this.current_match = 0
          }
        },
        find_previous() {
            if (this.search_cursor == null) return;

            const result = this.search_cursor.findPrevious();
            if (result) {
                const from = this.search_cursor.from()
                const to = this.search_cursor.to();
                this.current_cursor = to

                this.current_result_marker?.clear();
                this.current_result_marker = this.cm.markText(from, to, { className: "cm-search-match-current" })
                this.cm.setSelection(from, to)
                jump_to_line(this.cm, to.line, to.ch)
                --this.current_match;
            } else if (this.total_matches > 0) {
                const pos = { line: this.cm.lastLine(), ch: this.cm.getLine(this.cm.lastLine()).length }
                this.search_cursor = this.cm.getSearchCursor(this.search_query, pos, !this.case_sensitive);
                this.current_match = this.total_matches
            }
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss">
.cm-search-match {
  background-color: var(--snippet-code-search-highlight);
}
.cm-search-match-current {
  border: 1px solid var(--button-white);
}
</style>

<style lang="scss" scoped>
.search-container {
  width: 100%;
  height: 25px;
  border-bottom: 1px solid var(--secondary-background-color);
  background-color: var(--ternary-background-color);

  display: flex;
  flex-direction: row;
  align-items: center;

  .match-counter {
    white-space: nowrap;
    min-width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
  }
}
</style>
