import { PlayGroupsPlayScenariosScope } from "../../../scopes/play/bridges/play_groups_play_scenarios_scope";
import { PlayGroupsPlayScenariosClient } from "../../../clients/play/bridges/play_groups_play_scenarios_client";
import { Props, StaticState } from "../../../base/vue_record";
import { QuerifyProps } from "../../../base/vue_record_scope";
import { State } from "../../../base/vue_record";
import { Computed } from "../../../base/vue_record";
import { Consoler } from "../../../../helpers/api_wrappers/consoler";
import { VueRecord } from "../../../base/vue_record";
import { reactive } from "../../../../helpers/vue/reactive";
import { BelongsToAssociations } from "../../../base/vue_record";
import { HasManyAssociations } from "../../../base/vue_record";
import { HasOneAssociations } from "../../../base/vue_record";
import { HasManyThroughAssociations } from "../../../base/vue_record";
import { VueRecordIndex } from "../../../base/vue_record_index";
import { VueRecordStore } from "../../../base/vue_record_store";
import { ModelValidatorOpts } from "../../../../helpers/validator/validator";
import { get_css_var } from "../../../../helpers/generic/get_css_var";
import { TestaTree } from "../../../../components/testa/tree/tree";

// <editor-fold desc="TYPES">
export interface PlayGroupsPlayScenariosProps extends Props {
    id: number
    play_group_id: number
    play_scenario_id: number
}
export type QuerifiedPlayGroupsPlayScenariosProps = QuerifyProps<PlayGroupsPlayScenariosProps>
export type PlayGroupsPlayScenariosCreateProps = Omit<PlayGroupsPlayScenariosProps, 'id'>
export type PlayGroupsPlayScenariosUpdateProps = Partial<PlayGroupsPlayScenariosProps>

export interface PlayGroupsPlayScenariosState extends State {}
export interface PlayGroupsPlayScenariosComputed extends Computed {}
export interface PlayGroupsPlayScenariosStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PlayGroupsPlayScenarios extends VueRecord {
    ['constructor']: typeof PlayGroupsPlayScenarios

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlayGroupsPlayScenariosClient
    static ScopeClass = PlayGroupsPlayScenariosScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlayGroupsPlayScenariosStaticState = reactive<PlayGroupsPlayScenariosStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_group_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlayGroupsPlayScenarios> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlayGroupsPlayScenarios>> = {}

    static field_validators: ModelValidatorOpts<PlayGroupsPlayScenariosProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_GROUPS_PLAY_SCENARIOS
    static resource_id = Enum.Resource.Id.PLAY_GROUPS_PLAY_SCENARIOS
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlayGroupsPlayScenariosClient
    declare props: PlayGroupsPlayScenariosProps;
    declare state: PlayGroupsPlayScenariosState;
    declare computed: PlayGroupsPlayScenariosComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlayGroupsPlayScenarios.register_resource(PlayGroupsPlayScenarios)
PlayGroupsPlayScenariosClient.ModelClass = PlayGroupsPlayScenarios
PlayGroupsPlayScenariosScope.ModelClass = PlayGroupsPlayScenarios


declare global {
    interface Window {
        PlayGroupsPlayScenarios: typeof PlayGroupsPlayScenarios
    }
}
window.PlayGroupsPlayScenarios = PlayGroupsPlayScenarios
// </editor-fold>

