<template>
  <div :id="inspector.id"
       ref="inspector"
       class="inspector"
       :style="inspector_style"
       @mouseleave="inspector.on_mouse_leave"
       @mousemove="inspector.on_mouse_move"
       @mouseenter="inspector.on_mouse_enter"
  >
    <Overflowable
        v-if="!inspector.side_kit_closed && inspector.is_active && !inspector.is_hidden && in_viewport"
        :auto_z_index="false"
    >
      <InspectorSideKit
          :side="side_kit_side"
          :inspector="inspector"
          :max_width="side_kit_side == 'left' ? space_on_left : space_on_right"
      />
    </Overflowable>


    <div v-if="!is_resizing && inspector.active_snapshot != null && !inspector.active_snapshot.is_error && !inspector.is_hidden && in_viewport"
         v-show="inspector.is_active"
         class="elements-container">
      <InspectorElementVue
          ref="root_inspector_html_element"
          :inspector_element="inspector.active_snapshot.root_inspector_element"
      />
    </div>
  </div>

  <div v-if="!inspector.is_hidden && in_viewport"
       class="clip-overlay"
       :style="{clipPath: clip_path}"/>

  <div v-if="inspector.clicked_coordinates != null && !inspector.is_hidden && inspector.is_active && in_viewport"
       class="clicked-dot"
       :style="dot_style"/>

  <InspectorControls
      v-if="inspector.is_live && in_viewport"
      :inspector="inspector"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InspectorElementVue from "./InspectorElement.vue";
import { PropType } from "vue";
import { Tab } from "../../../../testa/editor/tab";
import { Inspector } from "./inspector";
import { InspectorElement } from "./inspector_element";
import InspectorSideKit from "./InspectorSideKit/InspectorSideKit.vue";
import Overflowable from "../../../../testa/Overflowable.vue";
import { Offset } from "./inspector_element";
import InspectorControls from "./InspectorControls.vue";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";


export interface InspectorSearchParams {
    text?: string
    "content-desc"?: string
    "class"?: string
    type?: string
    bounds?: string
    "resource-id"?: string
    package?: string
    name?: string
    _match_all_query_attributes?: boolean

    [key: string]: any
}

export default defineComponent({
    components: { InspectorControls, Overflowable, InspectorSideKit, InspectorElementVue },
    props: {
        vertical_scale: {
            type: Number,
            required: true,
        },
        horizontal_scale: {
            type: Number,
            required: true,
        },
        is_resizing: {
            type: Boolean,
            required: true,
        },
        inspector: {
            type: Object as PropType<Inspector>,
            required: true,
        },
        tab: {
            type: Object as PropType<Tab>,
            required: false,
            default: null
        },
        screen_overlay_width: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            space_on_left: 0,
            space_on_right: 0,
            in_viewport: true,
            amo: null as AllMightyObserver,
        }
    },
    computed: {
        clip_path() {
            let inspector_element: InspectorElement
            if (this.selected_element) inspector_element = this.selected_element
            else return "none"

            const inspector_html = this.$refs.inspector as HTMLElement
            if (inspector_html == null) return "none";

            const offset = inspector_element.offset
            const height = inspector_html.clientHeight
            const width = inspector_html.clientWidth

            const offset_percentage: Offset = {
                left: (offset.left / width) * 100,
                top: (offset.top / height) * 100,
                right: (1 - (offset.right / width)) * 100,
                bottom: (1 - (offset.bottom / height)) * 100
            }

            const points: string[] = []
            points.push("0 0") // top left
            points.push("0 100%") // bottom left
            points.push(`${offset_percentage.left}% 100%`) // align left with element on the bottom
            points.push(`${offset_percentage.left}% ${offset_percentage.top}%`) // element top left
            points.push(`${offset_percentage.right}% ${offset_percentage.top}%`) // element top right
            points.push(`${offset_percentage.right}% ${offset_percentage.bottom}%`) // element bottom right
            points.push(`${offset_percentage.left}% ${offset_percentage.bottom}%`) // element bottom left
            points.push(`${offset_percentage.left}% 100%`) // align left with element on the bottom
            points.push(`100% 100%`) // bottom right
            points.push(`100% 0`) // top right
            return `polygon(${points.join(",")})`
        },
        side_kit_side(): "left" | "right" {
            const default_side = () => {
                if (this.space_on_right >= this.screen_overlay_width) {
                    return "right"
                } else if (this.space_on_right > this.space_on_left) {
                    return "right"
                } else {
                    return "left"
                }
            }

            const phones = this.scenario_setting.phones.count
            if (phones > 1) {
                if (this.scenario_setting_phone.props.index >= 1 && this.space_on_right >= this.screen_overlay_width) {
                    return "right"
                } else if (this.scenario_setting_phone.props.index == 0 && this.space_on_left >= this.screen_overlay_width) {
                    return "left"
                } else if (this.space_on_left > this.space_on_right) {
                    return "left"
                } else {
                    return "right"
                }
            } else {
                return default_side()
            }
        },
        phone() {
            return this.inspector.phone
        },
        scenario_setting_phone() {
            return this.inspector.scenario_setting_phone
        },
        scenario_setting() {
            return this.scenario_setting_phone.scenario_setting
        },
        hovered_element() {
            return this.inspector.hovered_element
        },
        selected_element() {
            return this.inspector.selected_element
        },
        dot_style() {
            if (this.inspector.clicked_coordinates == null) return {}

            const inspector_html = (this.$refs.inspector as HTMLElement)
            if (inspector_html == null) return {}

            const height = inspector_html.clientHeight
            const width = inspector_html.clientWidth

            return {
                left: `${((this.inspector.clicked_coordinates.x - 4) / width) * 100}%`,
                top: `${((this.inspector.clicked_coordinates.y - 4) / height) * 100}%`,
            }
        },
        inspector_style() {
            const pointer_events = this.inspector.is_hidden ? "none" : "all";
            if (this.inspector.live_background) {
                return {
                    pointerEvents: pointer_events,
                    background: "transparent"
                }
            } else {
                const background_width = this.scenario_setting.props.xvfb_width * this.horizontal_scale
                const background_height = this.scenario_setting.props.xvfb_height * this.vertical_scale
                return {
                    pointerEvents: pointer_events,
                    background: `url(${this.inspector.screenshot_url})`,
                    backgroundSize: `${background_width}px ${background_height}px`,
                    backgroundPositionX: `-${this.horizontal_scale * this.scenario_setting_phone.props.top_left_x}px`,
                    backgroundPositionY: `-${this.vertical_scale * this.scenario_setting_phone.props.top_left_y}px`,
                }
            }
        },
    },
    watch: {
        'inspector.active_snapshot_id'() {
            this.flash("flash-white");
        },
        vertical_scale: {
            handler() {
                this.inspector.set_vertical_scale(this.vertical_scale)
            },
            immediate: true
        },
        horizontal_scale: {
            handler() {
                this.inspector.set_horizontal_scale(this.horizontal_scale)
            },
            immediate: true
        }
    },
    mounted() {
        const inspector_element = this.$refs.inspector as HTMLElement
        this.amo = AllMightyObserver.new(
            {
                element_visible: true,
                target_element: inspector_element,
                callback: () => {
                    this.in_viewport = true;
                    this.set_space_on_left_and_right();
                }
            },
            {
                element_hidden: true,
                target_element: inspector_element,
                callback: () => this.in_viewport = false
            }, {
                element_after_resize: true,
                after_resize: true,
                target_element: inspector_element,
                callback: () => {
                    this.set_space_on_left_and_right()
                }
            }
        )
    },
    unmounted() {
        this.amo?.stop();
    },
    methods: {
        set_space_on_left_and_right() {
            if (this.in_viewport) {
                const inspector = this.$refs.inspector as HTMLElement
                if (inspector == null) return;

                const rect = inspector.getBoundingClientRect()
                const window_width = $(window).width()
                this.space_on_left = rect.left
                this.space_on_right = window_width - rect.right
            }
        },
        flash(flash_class: string) {
            const insp = this.$refs.inspector as HTMLElement
            insp.classList.add(flash_class)
            setTimeout(() => {
                insp.classList.add("fade")
                setTimeout(() => {
                    insp.classList.remove("fade")
                    insp.classList.remove(flash_class)
                }, 750)
            }, 750)
        },
    }
})
</script>

<style lang="scss" scoped>
.inspector {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 8;

  &.flash-white {
    transition: box-shadow 0.75s;
    transition-timing-function: ease-in;
    box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.91);
  }

  &.fade {
    transition: box-shadow 0.75s;
    transition-timing-function: ease-in;
    box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0) !important;
  }

  &.flash-blue {
    transition: box-shadow 0.5s;
    transition-timing-function: ease-in;
    box-shadow: 0 0 6px 3px rgba(0, 83, 203, 0.91);
  }

  .elements-container {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  }
}

.clip-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: var(--overlay-background-color);
}

.clicked-dot {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 1px solid var(--button-white);
  background: var(--button-disabled);
  position: absolute;
  z-index: 9999;
}
</style>
