import { VueRecordScope } from "../base/vue_record_scope";
import { PhoneProject } from "../models/phone_project";
import { computed } from "../../helpers/vue/computed";
import { Phone } from "../models/phone";

export class PhoneProjectScope extends VueRecordScope {
    ['constructor']: typeof PhoneProjectScope
    static ModelClass: typeof PhoneProject
    declare rthis: PhoneProjectScope

    phones = computed(() => {
        return Phone.to_scope(this.rthis.pluck("phone").compact().uniq_by(r => r.key()))
    })

    simulators = computed(() => {
        return this.rthis.where({ phone: { phone_type: Enum.Phone.Type.SIMULATOR } })
    })

    iphones = computed(() => {
        return this.rthis.where({ phone: { phone_type: Enum.Phone.Type.IPHONE } })
    })

    androids = computed(() => {
        return this.rthis.where({ phone: { phone_type: Enum.Phone.Type.ANDROID } })
    })

    online = computed(() => {
        return this.rthis.where({ phone: { status: Enum.Phone.Status.ONLINE } })
    })
}
