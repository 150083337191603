import { VueRecord } from "./vue_record";
import { reactive } from "../../helpers/vue/reactive";


export type ColumnIndexInput<TModel extends VueRecord = VueRecord> = {
    column: keyof TModel['props']
    hash?: boolean
}
export type ColumnIndexOpts<TModel extends VueRecord = VueRecord> = {
    column: keyof TModel['props']
    hash?: boolean
}

const hash_by_default = false

export class VueRecordIndex<TModel extends VueRecord = VueRecord> {
    id: string
    primary_key: keyof TModel['props']
    column_index_props: ColumnIndexOpts<TModel>[] = []

    constructor(primary_key: keyof TModel['props'], ...columns: Array<keyof TModel['props'] | ColumnIndexInput<TModel>>) {
        this.id = null
        this.primary_key = primary_key
        const handle_column = (column: keyof TModel['props'] | ColumnIndexInput<TModel>) => {
            if (column instanceof String || typeof column === "string") {
                if (this.id == null) this.id = column.toString()
                else this.id += `||${column.toString()}`

                this.column_index_props.push({
                    column: column as keyof TModel['props'],
                    hash: hash_by_default
                })
            } else {
                const column_index_prop = column as ColumnIndexInput<TModel>
                if (this.id == null) this.id = column_index_prop.column.toString()
                else this.id += `||${column_index_prop.column.toString()}`
                this.column_index_props.push({
                    column: column_index_prop.column,
                    hash: column_index_prop.hash == null ? hash_by_default : column_index_prop.hash
                })
            }
        }
        columns.forEach(handle_column)

        if (this.id == null) this.id = this.primary_key.toString()
        else this.id += `||${this.primary_key.toString()}`

        this.column_index_props.push({
            column: this.primary_key,
            hash: hash_by_default
        })
    }

    static new<TModel extends VueRecord = VueRecord>(ModelClass: any, ...columns: Array<keyof TModel['props'] | ColumnIndexInput<TModel>>) {
        const primary_key = ModelClass.primary_key
        const rthis = reactive(new this<TModel>(primary_key, ...columns))
        rthis.init()
        return rthis
    }

    init() {}
}
