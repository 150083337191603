<template>
  <div class="element-info">
    <div class="label">
      <span class="dot"
            :style="dot_style"/>
      <span class="type">
        {{ type }}
      </span>


      <div v-if="element.in_viewport"
           class="hide-element-action"
           title="Hide element to make inspector easier to navigate"
           @click="hide_element">
        Hide Element
      </div>
      <div v-else
           class="not-in-viewport"
           title="The element coordinates are outside the viewport. Scroll the screen to reposition the element"
      >
        Not in viewport
      </div>

      <div class="attributes-toggle">
        <span class="btn"
              @click="show_all_attributes = !show_all_attributes">
          {{ show_all_attributes ? "Minimize" : "Expand" }}
        </span>
      </div>
    </div>

    <InspectorAttributeInfo
        :attribute="element.attributes[element.inspector.class_key]"
    />

    <InspectorAttributeInfo
        v-if="element.attributes[element.inspector.id_key]?.value != null || (show_all_attributes && element.attributes[element.inspector.id_key] != null)"
        :attribute="element.attributes[element.inspector.id_key]"
    />

    <InspectorAttributeInfo
        v-if="element.attributes.text?.value != null || (show_all_attributes && element.attributes.text != null)"
        :attribute="element.attributes.text"
    />
    <InspectorAttributeInfo
        v-if="(element.attributes.label?.value != null || (show_all_attributes && element.attributes.label != null)) && element.attributes.label.value != element.attributes[element.inspector.id_key]?.value"
        :attribute="element.attributes.label"
    />

    <InspectorAttributeInfo
        v-if="element.attributes['content-desc']?.value != null || (show_all_attributes && element.attributes['content-desc'] != null)"
        :attribute="element.attributes['content-desc']"
    />

    <InspectorAttributeInfo
        :attribute="element.attributes.bounds"
    />

    <template v-for="(value, attribute) in other_attributes"
              :key="attribute">
      <InspectorAttributeInfo
          v-if="show_all_attributes && value != null"
          :attribute="value"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { InspectorElement } from "../inspector_element";
import { get_css_var } from "../../../../../../helpers/generic/get_css_var";
import _ from "lodash";
import InspectorAttributeInfo from "./InspectorAttributeInfo.vue";
import { AttributeName } from "../inspector_element_attribute";

export default defineComponent({
    components: { InspectorAttributeInfo },
    // <editor-fold desc="PROPS">
    props: {
        element: {
            type: Object as PropType<InspectorElement>,
            required: true,
        },
        type: {
            type: String as PropType<"clicked" | "selected" | "hovered">,
            required: true
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            show_all_attributes: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        dot_style() {
            let dot_color;
            let outline_color;
            switch (this.type) {
                case "clicked":
                    dot_color = get_css_var("--button-yellow");
                    outline_color = dot_color
                    break;
                case "hovered":
                    dot_color = get_css_var("--secondary-light-background-color");
                    outline_color = get_css_var("--secondary-dark-background-color");
                    break;
                case "selected":
                    dot_color = get_css_var("--button-blue");
                    outline_color = dot_color
                    break;
                default:
                    dot_color = get_css_var("--button-white");
                    outline_color = dot_color
                    break;
            }
            return { backgroundColor: dot_color, borderColor: outline_color }
        },
        other_attributes() {
            const ignore = ["type", "class", "resource-id", "name", "text", "content-desc", "bounds", "top_left", "bottom_right"] as const
            const attributes = _.clone(this.element.attributes)
            ignore.forEach(i => delete attributes[i])
            return attributes
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        hide_element() {
            const inspector = this.element.inspector
            inspector.hide_element(this.element)
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.element-info {
  display: flex;
  flex-direction: column;

  .label {
    display: flex;
    flex-direction: row;
    font-size: 0.85em;
    align-items: baseline;
    overflow: hidden;

    .dot {
      width: 0.3em;
      height: 0.3em;
      border-radius: 50%;
      border-width: 0.2em;
      border-style: solid;
      flex-shrink: 0;
    }

    .type {
      margin-left: 5px;
    }

    .hide-element-action {
      width: 100%;
      font-size: 0.9em;
      text-align: center;
      color: var(--font-color-secondary);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: var(--font-color);
      }
    }

    .not-in-viewport {
      color: var(--button-purple);
      width: 100%;
      font-size: 0.9em;
      text-align: center;
    }

    .attributes-toggle {
      text-align: right;

      .btn {
        cursor: pointer;
        font-size: 0.9em;
        color: var(--font-color-secondary);

        &:hover {
          text-decoration: underline;
          color: var(--font-color);
        }
      }
    }
  }


}
</style>
