import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ToggleIcon = _resolveComponent("ToggleIcon")
  const _component_ActionIcon = _resolveComponent("ActionIcon")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ToggleIcon, {
      modelValue: _ctx.App.state.order_by_version,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.App.state.order_by_version) = $event)),
      enabled_icon_class: "fa-solid fa-arrow-down-1-9",
      enabled_color_class: "white",
      disabled_color_class: "grey",
      title: _ctx.App.state.order_by_version ? 'Click to sort by upload time' : 'Click to sort by version',
      no_click_focus: true,
      scale: 1.1
    }, null, 8 /* PROPS */, ["modelValue", "title"]),
    _createVNode(_component_ActionIcon, {
      icon_class: "fa-solid fa-plus",
      title: "Upload app",
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.App.setup_file_picker_upload()))
    }),
    _createVNode(_component_ActionIcon, {
      icon_class: "fa-solid fa-link",
      title: "Upload app from URL",
      onClick: _cache[2] || (_cache[2] = $event => (_ctx.App.show_upload_from_url_form()))
    })
  ], 64 /* STABLE_FRAGMENT */))
}