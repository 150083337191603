import { copy_text_to_clipboard } from "../generic/copy_to_clipboard";
import { PlaySettingProps } from "../../vue_record/models/play/play_setting";
import { EnumPlayType } from "../../auto_generated/enums";

export function copy_curl_request(token: string, id: number, play_type: EnumPlayType, play_setting: PlaySettingProps) {

    const curl_params = []
    for (const key in play_setting) {
        if (key == "id") continue;
        let value;
        if (key == "workers" || key == "scenario_setting_mappings") {
            value = JSON.stringify(play_setting[key])
        } else {
            value = String(play_setting[key])
        }
        curl_params.push(`-F '${key}="${value.replace(/"/g, `\\"`)}"' `)
    }
    const parts = [
        "curl -X POST",
        `-H 'Authorization: Bearer ${token}'`,
        `-F 'play_type="${play_type}"'`,
        `-F 'id=${id}'`,
        curl_params,
        `${window.location.protocol}//${window.location.hostname}/ci/run`,
    ].flat()
    const req = parts.join(" \\\n")

    copy_text_to_clipboard(req, false)
}
