import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumFileType } from "../../auto_generated/enums";
import { ImageFolderClient } from "../clients/image_folder_client";
import { ImageFolderScope } from "../scopes/image_folder_scope";
import { Image } from "../models/image";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { ImageScope } from "../scopes/image_scope";
import { fix_image_paths } from "../../helpers/files/fix_file_paths";
import { StaticState } from "../base/vue_record";
import { computed } from "../../helpers/vue/computed";

// <editor-fold desc="TYPES">
export interface ImageFolderProps extends Props {
    __old_path?: string
    path: string
    project_version_id: number
    name: string
    size: number
    image_folder_path: string
    type: EnumFileType
    updated_at: Date
    created_at: Date
}
export type QuerifiedImageFolderProps = QuerifyProps<ImageFolderProps>
export type ImageFolderCreateProps = Omit<ImageFolderProps, 'id'>
export type ImageFolderUpdateProps = Partial<ImageFolderProps>

export interface ImageFolderState extends State {}
export interface ImageFolderComputed extends Computed {}
export interface ImageFolderStaticState extends StaticState {
    children_promises: Record<string, Promise<ImageFolderChildren>>
}

export type ImageFolderChildren = {
    images: Image[],
    image_folders: ImageFolder[]
}
// </editor-fold>

const console = new Consoler("warn")
export class ImageFolder extends VueRecord {
    ['constructor']: typeof ImageFolder

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static discard_outdated = false
    static ClientClass = ImageFolderClient
    static ScopeClass = ImageFolderScope
    static readonly primary_key = "path"
    static sync_channels: string[] = []
    static state: ImageFolderStaticState = reactive<ImageFolderStaticState>({
        children_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "image_folder_path"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ImageFolder> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ImageFolder>> = {}

    static field_validators: ModelValidatorOpts<ImageFolderProps> = {}

    static resource_name = Enum.Resource.Label.IMAGE_FOLDER
    static resource_id = Enum.Resource.Id.IMAGE_FOLDER
    static icon_class = "fa-solid fa-folder"
    static color = () => get_css_var("--image-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ImageFolderClient
    declare props: ImageFolderProps;
    declare state: ImageFolderState;
    declare computed: ImageFolderComputed;

    // </editor-fold>


    // <editor-fold desc="HOOKS">
    after_update(new_props: ImageFolderProps, old_props: ImageFolderProps, changes: (keyof ImageFolderProps)[]) {
        super.after_update(new_props, old_props, changes);

        // <editor-fold desc="PATH">
        if (changes.includes("path")) {
            fix_image_paths(this, old_props.path, new_props.path)
        }
        // </editor-fold>
    }
    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return ImageFolder.show_in_sidebar(this.key(), this.props.project_version_id, tree);
    }

    static async show_in_sidebar(image_folder_paths: string | string[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: string[];
        if (what_is_it(image_folder_paths) == "Array") {
            ids = image_folder_paths as string[]
        } else {
            ids = [image_folder_paths as string]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(image_folder_paths).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).forEach(image_folder_path => {
                    const image_folders = promise_response[image_folder_path]
                    const keys = [
                        ImageFolder.tree_key(project_version_id),
                        ...image_folders.map(sf => sf.tree_key()),
                        ImageFolder.find(image_folder_path).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: ImageFolder.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="TREE">
    // <editor-fold desc="HOVER_ACTION">
    static _hover_action_data(project_version_id: number): TestaTree.HoverAction<ImageScope, ImageFolderScope, ImageFolder> {
        return {
            icon: {
                class: "fa-solid fa-plus"
            },
            callback: (event, node) => {
                node.set_expanded(true);
                node.show_context_menu(event, ImageFolder._tree_new_image_contextmenu_items(project_version_id).build);
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<ImageScope, ImageFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys[`${ctrl_or_meta}++`] = () => {
                    ImageFolder.ClientClass.create({
                        image_folder_path: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
            }
            return keys;
        })
    }

    static _testa_tree_hotkeys(project_version_id: number) {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<ImageScope, ImageFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys[`${ctrl_or_meta}++`] = () => {
                    ImageFolder.ClientClass.create({
                        image_folder_path: null,
                        project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXT MENU">
    static _tree_new_image_contextmenu_items(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<ImageScope, ImageFolderScope, ImageFolder | Image>) => {
                const items: ContextMenu.Items = {}
                items.new_image_folder = {
                    name: "Folder",
                    icon: "fa fa-folder folder",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-+`,
                    callback: () => {
                        node.set_expanded(true);
                        ImageFolder.ClientClass.create({
                            image_folder_path: node.record?.key(),
                            project_version_id
                        });
                    },
                }
                return items
            },
        }
    }

    _testa_tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<ImageScope, ImageFolderScope, ImageFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: ImageFolder._tree_new_image_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }

                    items.upload = {
                        name: "Upload",
                        icon: "fa-solid fa-upload",
                        color: get_css_var("--button-green"),
                        callback: () => {
                            Image.ClientClass.upload({
                                image_folder_path: this.props.path,
                                project_version_id: this.props.project_version_id
                            })
                        }
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    static _testa_tree_contextmenu(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<ImageScope, ImageFolderScope, ImageFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: ImageFolder._tree_new_image_contextmenu_items(project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }

                    items.upload = {
                        name: "Upload",
                        icon: "fa-solid fa-upload",
                        color: get_css_var("--button-green"),
                        callback: () => {
                            Image.ClientClass.upload({
                                image_folder_path: null,
                                project_version_id
                            })
                        }
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="DND">
    _testa_tree_node_dnd(): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => true),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Image || r instanceof ImageFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const image_folders = dnd.state.records.filter(r => r instanceof ImageFolder)
                const images = dnd.state.records.filter(r => r instanceof Image)
                ImageFolder.ClientClass.move(
                    this.props.project_version_id,
                    this.key(),
                    image_folders.map(s => s.key()),
                    images.map(s => s.key())
                )
            },
            on_over: (e: DragEvent) => {
                e.dataTransfer.dropEffect = "move"
            }
        }
    }

    static _testa_tree_node_dnd(project_version_id: number): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => false),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Image || r instanceof ImageFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const image_folders = dnd.state.records.filter(r => r instanceof ImageFolder)
                const images = dnd.state.records.filter(r => r instanceof Image)
                ImageFolder.ClientClass.move(
                    project_version_id,
                    null,
                    image_folders.map(s => s.key()),
                    images.map(s => s.key())
                )
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_node_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const image_folder_paths = nodes.map(n => n.record)
                                                .filter(r => r instanceof ImageFolder)
                                                .map(r => r.key())
                const image_paths = nodes.map(n => n.record)
                                         .filter(r => r instanceof Image)
                                         .map(r => r.key())

                if (type == "copy") {
                    ImageFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.key(),
                        image_folder_paths,
                        image_paths
                    )
                } else if (type == "cut") {
                    ImageFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.key(),
                        image_folder_paths,
                        image_paths
                    )
                }
            }
        }
    }

    static _testa_tree_node_clipboard(project_version_id: number): TestaTree.Clipboard {
        return {
            on_paste: (type, nodes) => {
                const image_folder_paths = nodes.map(n => n.record)
                                                .filter(r => r instanceof ImageFolder)
                                                .map(r => r.key())
                const image_paths = nodes.map(n => n.record)
                                         .filter(r => r instanceof Image)
                                         .map(r => r.key())

                if (type == "copy") {
                    ImageFolder.ClientClass.copy(
                        project_version_id,
                        null,
                        image_folder_paths,
                        image_paths
                    )
                } else if (type == "cut") {
                    ImageFolder.ClientClass.move(
                        project_version_id,
                        null,
                        image_folder_paths,
                        image_paths
                    )
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<ImageScope, ImageFolderScope, ImageFolder> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.IMAGE_FOLDER,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            icon: computed(() => {
                return {
                    color: get_css_var("--image-color"),
                }
            }),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            folder: {
                open_fn: () => {
                    return this.client.children()
                },
                folder_scope: computed(() => this.image_folders.order("name")),
                file_scope: computed(() => this.images.order("name"))
            },
            hover_action: computed(() => ImageFolder._hover_action_data(this.props.project_version_id)),
            dnd: this._testa_tree_node_dnd(),
            contextmenu: this._testa_tree_contextmenu(),
            clipboard: this._testa_tree_node_clipboard(),
            hotkeys: this._testa_tree_hotkeys(),
        }
    }

    static testa_tree_node_data(project_version_id: number): TestaTree.NodeInput<ImageScope, ImageFolderScope, ImageFolder> {
        return {
            resource_id: Enum.Resource.Id.IMAGE_FOLDER,
            folder: {
                open_fn: () => {
                    return ImageFolder.ClientClass.children(project_version_id, null)
                },
                file_scope: computed(() => Image.where({ image_folder_path: null, project_version_id }).order("name")),
                folder_scope: computed(() => ImageFolder.where({ image_folder_path: null, project_version_id }).order("name"))
            },
            icon: computed(() => {
                return {
                    class: Image.icon_class,
                    color: get_css_var("--image-color"),
                    scale: 0.9,
                }
            }),
            key: this.tree_key(project_version_id),
            title: computed(() => {
                return { template: "Images" }
            }),
            hover_action: computed(() => ImageFolder._hover_action_data(project_version_id)),
            dnd: ImageFolder._testa_tree_node_dnd(project_version_id),
            clipboard: ImageFolder._testa_tree_node_clipboard(project_version_id),
            hotkeys: ImageFolder._testa_tree_hotkeys(project_version_id),
            contextmenu: ImageFolder._testa_tree_contextmenu(project_version_id)
        }
    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        if (include_name) {
            return this.props.path
        } else {
            if (this.props.image_folder_path == null) return "/"
            return this.props.image_folder_path
        }
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
ImageFolder.register_resource(ImageFolder)
ImageFolderClient.ModelClass = ImageFolder
ImageFolderScope.ModelClass = ImageFolder

global_event_bus.$on("after_project_version_unload", () => {
    ImageFolder.state.children_promises = {}
    ImageFolder.get_scope().unload()
})

declare global {
    interface Window {
        ImageFolder: typeof ImageFolder
    }
}
window.ImageFolder = ImageFolder
// </editor-fold>

