import { GroupsScenario } from "../models/groups_scenario";
import { Group } from "../models/group";
import { Scenario } from "../models/scenario";

declare module "../models/groups_scenario" {
    interface GroupsScenario {
        group: Group
        scenario: Scenario
    }
}

GroupsScenario.belongs_to("group", Group, "group_id")
GroupsScenario.belongs_to("scenario", Scenario, "scenario_id")
