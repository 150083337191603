import { App } from "../models/app";
import { Project } from "../models/project";
import { AppScope } from "../scopes/app_scope";
import { UserProjectScope } from "../scopes/user_project_scope";
import { PhoneProjectScope } from "../scopes/phone_project_scope";
import { ProjectVersionScope } from "../scopes/project_version_scope";
import { ProxyScope } from "../scopes/proxy_scope";
import { UserScope } from "../scopes/user_scope";
import { PhoneScope } from "../scopes/phone_scope";
import { MobileProvisioning } from "../models/mobile_provisioning";
import { UserProject } from "../models/user_project";
import { ProjectVersion } from "../models/project_version";
import { PhoneProject } from "../models/phone_project";
import { Proxy } from "../models/proxy";
import { User } from "../models/user";
import { Phone } from "../models/phone";
import { Device } from "../models/mobile_provisionings/device";
import { MobileProvisioningScope } from "../scopes/mobile_provisioning_scope";


declare module "../models/project" {
    interface Project {
        webdriver_mobile_provisioning: MobileProvisioning

        apps: AppScope
        user_projects: UserProjectScope
        phone_projects: PhoneProjectScope
        project_versions: ProjectVersionScope
        mobile_provisionings: MobileProvisioningScope
        proxies: ProxyScope

        users: UserScope
        phones: PhoneScope
    }
}


Project.belongs_to("webdriver_mobile_provisioning", MobileProvisioning, "webdriver_mobile_provisioning_id")

Project.has_many("apps", App, "project_id")
Project.has_many("user_projects", UserProject, "project_id")
Project.has_many("project_versions", ProjectVersion, "project_id")
Project.has_many("proxies", Proxy, "project_id")
Project.has_many("phone_projects", PhoneProject, "project_id")
Project.has_many("mobile_provisionings", MobileProvisioning, "project_id")

Project.has_many_through("users", "user_projects", "user", User)
Project.has_many_through("phones", "phone_projects", "phone", Phone)
Project.has_many_through("mobile_provisioning_devices", "mobile_provisionings", "devices", Device)
