import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  ref: "play_group",
  class: "play-node-container"
}
const _hoisted_2 = {
  key: 0,
  class: "fas fa-chevron-down"
}
const _hoisted_3 = {
  key: 1,
  class: "fas fa-chevron-right"
}
const _hoisted_4 = { class: "title-item icon" }
const _hoisted_5 = { class: "title-item icon" }
const _hoisted_6 = { class: "title title-item" }
const _hoisted_7 = { class: "action-container" }
const _hoisted_8 = { class: "play-content-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PlayStatusIcon = _resolveComponent("PlayStatusIcon")
  const _component_PlayTypeIcon = _resolveComponent("PlayTypeIcon")
  const _component_ActionIcon = _resolveComponent("ActionIcon")
  const _component_Loading = _resolveComponent("Loading")
  const _component_PlayScenarioReport = _resolveComponent("PlayScenarioReport")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "row-container",
      style: _normalizeStyle(_ctx.row_container_style)
    }, [
      _createElementVNode("div", {
        class: "expander click-item",
        onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.toggle_expanded && _ctx.toggle_expanded(...args)), ["stop"]))
      }, [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : (_openBlock(), _createElementBlock("i", _hoisted_3))
      ]),
      _createElementVNode("div", {
        class: "title-container click-item",
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.toggle_expanded && _ctx.toggle_expanded(...args)))
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_PlayStatusIcon, {
            play_status: _ctx.props.status
          }, null, 8 /* PROPS */, ["play_status"])
        ]),
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_PlayTypeIcon, {
            play_type: _ctx.play.props.type
          }, null, 8 /* PROPS */, ["play_type"])
        ]),
        _createElementVNode("div", {
          class: "title-item progress-container",
          style: _normalizeStyle(_ctx.progress_background())
        }, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.props.name), 1 /* TEXT */)
        ], 4 /* STYLE */)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.current.user.is_superadmin())
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 0,
              icon_class: "fa-solid fa-indent",
              title: "Show superadmin logs",
              onClick: _ctx.show_logs
            }, null, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.main_web_available)
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 1,
              icon_class: "fa-solid fa-bullseye",
              title: "Show group in sidebar",
              color_class: "var(--group-color)",
              margin: "0",
              onClick: _ctx.show_target
            }, null, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ])
    ], 4 /* STYLE */),
    _withDirectives(_createElementVNode("div", _hoisted_8, [
      (!_ctx.data_loaded)
        ? (_openBlock(), _createBlock(_component_Loading, {
            key: 0,
            type: "three_bounce",
            size: 4
          }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.started_or_pending_main_play_scenarios, (main_play_scenario) => {
            return (_openBlock(), _createBlock(_component_PlayScenarioReport, {
              key: main_play_scenario.props.id,
              play_scenario: main_play_scenario,
              play: _ctx.play,
              expanded: _ctx.expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`] === true,
              play_setting: _ctx.play_setting,
              main_web_available: _ctx.main_web_available,
              role: _ctx.role,
              is_viewer: _ctx.is_viewer,
              is_single_worker: _ctx.is_single_worker,
              parents: 1,
              onToggleExpand: () => _ctx.expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`] = !_ctx.expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`],
              onPlayLogMounted: _ctx.on_play_log_mounted,
              onDisableStickyScroll: _cache[2] || (_cache[2] = $event => (_ctx.$emit('disable-sticky-scroll'))),
              onPlayDebuggerMounted: _cache[3] || (_cache[3] = (play_snippet) => _ctx.$emit('play-debugger-mounted', play_snippet))
            }, null, 8 /* PROPS */, ["play_scenario", "play", "expanded", "play_setting", "main_web_available", "role", "is_viewer", "is_single_worker", "onToggleExpand", "onPlayLogMounted"]))
          }), 128 /* KEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.expanded]
    ])
  ], 512 /* NEED_PATCH */))
}