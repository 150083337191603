<template>
  <a :href="url"
     class="no-default-focus"
     target="_blank">
    <i class="fa fa-camera"
       style="color: var(--button-blue); font-size: 0.9em;"/>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Image } from "../../../vue_record/models/image"
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { PropType } from "vue";
import { ProjectVersionSetting } from "../../../vue_record/models/project_version_setting";
import { EnumUserRole } from "../../../auto_generated/enums";

export default defineComponent({
    props: {
        url: {
            type: String,
            required: true
        },
        role: {
            type: String as PropType<EnumUserRole>,
            required: false,
            default: null
        },
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true
        },
        main_web_available: {
            type: Boolean,
            required: true
        }
    },
    mounted() {
        $.contextMenu({
            selector: `a.no-default-focus[href='${this.url}']`,
            zIndex: 10,
            build: (_$trigger: JQuery, _e: JQuery.MouseDownEvent) => {
                const items: ContextMenu.Items = {}
                if (this.project_version_setting.props.sikuli_module_enabled &&
                    this.role != Enum.User.Role.VIEWER &&
                    this.role != null &&
                    this.main_web_available) {
                  items.crop = {
                      name: "Crop",
                      icon: "fa-solid fa-crop",
                      color: Image.color(),
                      callback: () => {
                          Image.crop_external_image_in_main(this.url, this.project_version_setting.props.project_version_id)
                      }
                  }
                }

                items.open = {
                    name: "Open",
                    icon: "fa-solid fa-arrow-up-right-from-square",
                    color: get_css_var("--button-white"),
                    callback: () => {
                        window.open(this.url, "_blank")
                    }
                }

                return {
                    callback: function() {
                    },
                    items
                }
            }
        })
    }
})
</script>

<style/>

