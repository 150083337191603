import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ScenarioFolder, ScenarioFolderCreateProps } from "../models/scenario_folder";
import { ScenarioProps } from "../models/scenario";
import { ScenarioFolderProps } from "../models/scenario_folder";
import { Scenario } from "../models/scenario";
import { ScenarioFolderUpdateProps } from "../models/scenario_folder";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { ScenarioScope } from "../scopes/scenario_scope";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { ScenarioFolderChildren } from "../models/scenario_folder";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ScenarioFolderClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ScenarioFolder
    declare record: ScenarioFolder

    static load(id: number): Promise<ScenarioFolder> {
        return this.request<ScenarioFolderProps>({
            url: `/scenario_folders/${id}`,
            type: "GET",
            cache: true
        }).then(props => ScenarioFolder.new(props))
    }

    /** When copy - pasting we are duplicating to different folder */
    static copy(project_version_id: number, destination_scenario_folder_id: number, scenario_folder_ids: number[], scenario_ids: number[] = []) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/scenario_folders/copy`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id,
                    destination_scenario_folder_id,
                    scenario_ids,
                    scenario_folder_ids,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static create(scenario_folder: ScenarioFolderCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: "/scenario_folders",
                type: "POST",
                data: {
                    scenario_folder,
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }

    children(reload = false): Promise<ScenarioFolderChildren> {
        return ScenarioFolder.ClientClass.children(this.record.props.project_version_id, this.key(), reload)
    }

    static children(project_version_id: number, scenario_folder_id: number, reload = false): Promise<ScenarioFolderChildren> {
        const params_hash = hash({ project_version_id, scenario_folder_id })
        if (reload) ScenarioFolder.state.children_promises[params_hash] = null

        if (ScenarioFolder.state.children_promises[params_hash] == null) {
            type ChildrenResponse = {
                scenarios: ScenarioProps[],
                scenario_folders: ScenarioFolderProps[],
            }

            const promise: Promise<ScenarioFolderChildren> = new Promise<ScenarioFolderChildren>((resolve, reject) => {
                $.ajax({
                    url: `/scenario_folders/children`,
                    type: "GET",
                    data: {
                        project_version_id,
                        scenario_folder_id,
                    },
                    success: (data: ChildrenResponse) => {
                        const promise_response: ScenarioFolderChildren = {
                            scenarios: [],
                            scenario_folders: []
                        }
                        data.scenarios.forEach(scenario_prop => {
                            promise_response.scenarios.push(Scenario.new(scenario_prop))
                        })
                        data.scenario_folders.forEach(scenario_folder_prop => {
                            promise_response.scenario_folders.push(ScenarioFolder.new(scenario_folder_prop))
                        })
                        resolve(promise_response)
                    },
                    error: (error) => {
                        reject(error)
                    },
                    statusCode: ajax_status_codes,
                })
            })
            ScenarioFolder.state.children_promises[params_hash] = promise
            promise.catch(() => {
                ScenarioFolder.state.children_promises[params_hash] = null
            })
            return promise
        } else {
            return ScenarioFolder.state.children_promises[params_hash]
        }
    }

    static move(project_version_id: number, destination_scenario_folder_id: number, scenario_folder_ids: number[], scenario_ids: number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/scenario_folders/move`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    destination_scenario_folder_id,
                    scenario_ids,
                    scenario_folder_ids,
                    project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(scenario_folder: ScenarioFolderUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/scenario_folders/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    scenario_folder,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    /** Duplicate single item in current folder */
    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/scenario_folders/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static batch_path(scenario_ids: number | number[]) {
        let ids: number[];
        if (what_is_it(scenario_ids) == "Array") {
            ids = scenario_ids as number[]
        } else {
            ids = [scenario_ids as number]
        }

        type PathResponse = {
            [key: string]: {
                target: ScenarioFolderProps,
                path: ScenarioFolderProps[]
            }
        }

        type PromiseResponse = {
            [key: string]: ScenarioFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/scenario_folders/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    ids,
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(scenario_folder_id => {
                        ScenarioFolder.new(data[scenario_folder_id].target)
                        const scenario_folders_props = data[scenario_folder_id].path;
                        const array: ScenarioFolder[] = []
                        scenario_folders_props.forEach(scenario_folder_props => {
                            array.push(ScenarioFolder.new(scenario_folder_props))
                        })
                        promise_response[scenario_folder_id] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static batch_show_scenarios(ids: string[] | number[]): Promise<ScenarioScope> {
        if (ids.length == 0) return generate_resolved_promise<ScenarioScope>(Scenario.to_scope([]))

        return new Promise<ScenarioScope>((resolve, reject) => {
            $.ajax({
                url: `/scenario_folders/batch/show_scenarios`,
                type: "GET",
                data: {
                    ids
                },
                statusCode: ajax_status_codes,
                success: (data: ScenarioProps[]) => {
                    const records: Scenario[] = []
                    data.forEach(scenario_props => {
                        records.push(Scenario.new(scenario_props))
                    })
                    resolve(Scenario.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                },
            })
        });
    }

    static batch_scenario_ids(ids: number[]): Promise<number[]> {
        if (ids.length == 0) return generate_resolved_promise<number[]>([])

        return new Promise<number[]>((resolve, reject) => {
            $.ajax({
                url: `/scenario_folders/batch/scenario_ids`,
                type: "GET",
                data: {
                    ids
                },
                statusCode: ajax_status_codes,
                success: (data: number[]) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
            })
        });
    }
}
