<template>
  <Modal id="upload_apple_certificate_signing_request_modal"
         :show_cancel="false"
         :show_confirm="false"
         :cancel_on_escape="true"
         :focus_last_active_on_unmount="true"
         @cancel="$emit('exit')"
  >
    <template #header>
      <div style="font-size: 26px">
        Upload Certificate Signing Request (.csr) and Key
      </div>
    </template>

    <template #body>
      <div class="container">
        <div class="row">
          <div class="col s12 center align-center">
            <Button
                id="upload_csr_button"
                text="Upload CSR"
                @click="upload_csr"
            />

            <div class="csr-upload-status">
              <template v-if="csr_status == 'in_progress'">
                <i class="fa fa-spinner fa-spin white"/>
              </template>
              <template v-else-if="csr_status == 'success'">
                <i class="fa fa-check green"/>
              </template>
              <template v-else-if="csr_status == 'failed'">
                <i class="fa fa-times red"/>
              </template>
            </div>

            <Button
                id="upload_csr_key_button"
                text="Upload Key"
                @click="upload_key"
            />

            <div class="csr-upload-status">
              <template v-if="key_status == 'in_progress'">
                <i class="fa fa-spinner fa-spin white"/>
              </template>
              <template v-else-if="key_status == 'success'">
                <i class="fa fa-check green"/>
              </template>
              <template v-else-if="key_status == 'failed'">
                <i class="fa fa-times red"/>
              </template>
            </div>

          </div>
        </div>
      </div>

    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Button from "../../../testa/Button.vue";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";

type Status = "success" | "in_progress" | "failed"

export default defineComponent({
    components: {
        Button,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        }
    },
    // </editor-fold>
    emits: ['exit'],
    // <editor-fold desc="DATA">
    data() {
        return {
            csr_status: null as Status,
            key_status: null as Status,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        csr_status() {
            this.exit_if_success()
        },
        key_status() {
            this.exit_if_success();
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        exit_if_success() {
            if (this.csr_status == "success" && this.key_status == "success") {
                setTimeout(() => {
                    this.$emit("exit")
                }, 1500)
            }
        },
        upload_csr() {
            this.csr_status = "in_progress"
            this.project.client.upload_apple_certificate_signing_request().then(() => {
                this.csr_status = "success"
            }).catch(() => {
                this.csr_status = "failed"
            })
        },
        upload_key() {
            this.project.client.upload_apple_certificate_signing_request_key().then(() => {
                this.key_status = "success"
            }).catch(() => {
                this.key_status = "failed"
            })
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.csr-upload-status {
  width: 30px;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  margin-right: 5px;

}
</style>
