<template>
  <Tree
      :tree="project_tree"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Tree from "../../../tree/Tree.vue";
import { TestaTree } from "../../../tree/tree";

export default defineComponent({
    components: { Tree },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        project_tree() {
            return TestaTree.Tree.get_project_tree(current.project_version)
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
