import { watch } from "vue";

export function on_project_version_load(callback: () => void) {
    return watch(() => current.project_version?.key(),
        (new_project_version_id) => {
        if (new_project_version_id != null) {
            try { callback() } catch (e) { console.error(e) }
        }
        },
        {
            immediate: true,
            flush: "sync"
        }
        )
}
