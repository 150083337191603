import { Tab } from "../tab";
import { Group } from "../../../../vue_record/models/group";
import { markRaw } from "vue";
import { reactive } from "vue";
import { computed } from "../../../../helpers/vue/computed";
import { Snippet } from "../../../../vue_record/models/snippet";
import { Scenario } from "../../../../vue_record/models/scenario";
import UsagesEditor from "../editors/usages/UsagesEditor.vue";

namespace UsagesTab {
    export interface Input extends Tab.Input {
        snippet_id: number
        scenario_id: number
        group_id: number
    }

    export interface State extends Tab.State {
        record: Snippet | Scenario | Group,
    }
}

export class UsagesTab extends Tab {
    static type = "UsagesTab"

    type: string

    snippet: Snippet
    scenario: Scenario
    group: Group

    declare state: UsagesTab.State

    constructor(data: UsagesTab.Input) {
        super(data)
        this.type = UsagesTab.type

        const ids_length = [data.snippet_id, data.scenario_id, data.group_id].compact().length;
        if (ids_length != 1) throw new Error("Only one of snippet_id, scenario_id, or group_id must be set");

        this.state.icon.class = "fa-solid fa-people-carry-box"
        this.component = markRaw(UsagesEditor);
        this._set_and_call_load_function(() => {
            const promises: Promise<any>[] = []

            if (data.snippet_id != null) {
                promises.push(Snippet.find_or_load(data.snippet_id).then(snippet => {
                    this.snippet = snippet
                    this.state.record = snippet
                    return snippet
                }))
            }
            if (data.scenario_id != null) {
                promises.push(Scenario.find_or_load(data.scenario_id).then(scenario => {
                    this.scenario = scenario
                    this.state.record = scenario
                    return scenario
                }))
            }
            if (data.group_id != null) {
                promises.push(Group.find_or_load(data.group_id).then(group => {
                    this.group = group
                    this.state.record = group
                    return group
                }))
            }


            return Promise.all(promises)
                        .then(() => {
                            this.state.icon.color = computed(() => `var(--button-green)`)
                            this.computed.title = computed(() => `Usages of ${this.state.record.name()}`)
                            this.component_props = {
                                snippet: this.snippet,
                                scenario: this.scenario,
                                group: this.group,
                                tab: this
                            }
                        })
                        .catch((e) => {
                            this._on_load_error(e)
                        })
                        .finally(() => {
                            this.set_loaded(true)
                        })
        })
        this.dnd = reactive({})
    }

    _register_default_event_handlers() {
        super._register_default_event_handlers();
    }
}

Tab.register_tab_type(UsagesTab)
