import { VueRecordClient } from "../base/vue_record_client";
import { ProjectVersion } from "../models/project_version";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { QuerifiedProjectVersionProps } from "../models/project_version";
import { CacheStoreValue } from "../base/vue_record_client";
import { Jobberman } from "../../helpers/jobberman/jobberman";
import _ from "lodash"

export class ProjectVersionClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ProjectVersion
    declare record: ProjectVersion

    static create(project_version_props: QuerifiedProjectVersionProps, branch_from_project_version_id: number) {
        const url = `/settings/project_settings/project_versions`
        const type = "POST"
        const data = {
            authenticity_token,
            project_version: project_version_props,
            branch_from_project_version_id,
        }

        if (branch_from_project_version_id == null) {
            // <editor-fold desc="WITHOUT BRANCHING">
            return new Promise<void>((resolve, reject) => {
                $.ajax({
                    url,
                    type,
                    data: JSON.stringify(data),
                    success: () => resolve(),
                    error: (error) => reject(error),
                    processData: false,
                    contentType: 'application/json',
                    statusCode: ajax_status_codes,
                })
            })
            // </editor-fold>
        } else {
            // <editor-fold desc="WITH BRANCHING">
            return Jobberman.promise({
                run: (jobberman_id, _resolve, reject) => {
                    $.ajax({
                        url,
                        type,
                        data: JSON.stringify(_.merge(data, { jobberman_id })),
                        error: (error) => {
                            reject(error)
                        },
                        processData: false,
                        contentType: 'application/json',
                        statusCode: ajax_status_codes,
                    })
                }
            })
            // </editor-fold>
        }
    }

    update(project_version_props: QuerifiedProjectVersionProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/project_versions/${this.key()}`,
                type: 'PATCH',
                data: JSON.stringify({
                    authenticity_token,
                    project_version: project_version_props,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }

    delete() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/project_versions/${this.key()}`,
                type: "DELETE",
                data: { authenticity_token },
                statusCode: ajax_status_codes,
                success: () => {
                    ConfirmDialogue.show({
                        title: "Deletion scheduled",
                        show_cancel: false,
                    })
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
            });
        })
    }
}
