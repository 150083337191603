type NaturalSize = {
    width: number
    height: number
}

export class HoverZoom {
    static run() {
        $("body").on("mouseenter", "img, a", function(evt) {
            if (this.parentNode.className == "hover-zoom") return;
            if (this.classList.contains("no-hover-zoom")) return;

            const dfd = $.Deferred();
            const $hoverzoom = HoverZoom.displayHoverzoom(this, dfd);
            if ($hoverzoom != null) {
                const hover_element = this;
                const image_element = $hoverzoom[0].childNodes[0];
                $(this).on("mouseleave", function() {
                    $hoverzoom.remove();
                })

                dfd.then(function(naturalSize) {
                    HoverZoom.position_hover_zoom(naturalSize, evt, $hoverzoom, image_element as HTMLImageElement);
                    $hoverzoom.show();
                    $(hover_element).off("mousemove")
                    $(hover_element).on("mousemove", function(e) {
                        $hoverzoom.show();
                        HoverZoom.position_hover_zoom(naturalSize, e, $hoverzoom, image_element as HTMLImageElement)
                    })
                }, function() {
                    $hoverzoom.remove();
                })
            }
        });
    }

    static position_hover_zoom(natural_size: NaturalSize,
                               e: JQuery.MouseMoveEvent | JQuery.MouseEnterEvent,
                               $hoverzoom: JQuery,
                               image_element: HTMLImageElement) {
        const window_height = $(window).height();
        const window_width = $(window).width();

        const x = e.originalEvent.clientX;
        const y = e.originalEvent.clientY;

        let right = 0
        let left = 0
        let above = 0
        let bellow = 0

        function natural_size_percentage(max_width: number, max_height: number, natural_size: NaturalSize): number {
            let width, height;
            if (max_width > natural_size.width) width = natural_size.width;
            else width = max_width;

            if (max_height > natural_size.height) height = natural_size.height;
            else height = max_height;

            const area = width * height;
            const natural_area = natural_size.height * natural_size.width;

            return area / natural_area;
        }

        if (x > window_width / 2) {
            left = natural_size_percentage(x, window_height, natural_size)
        } else {
            right = natural_size_percentage((window_width - x), window_height, natural_size)
        }

        if (y > window_height / 2) {
            bellow = natural_size_percentage(window_width, y, natural_size)
        } else {
            above = natural_size_percentage(window_width, window_height - y, natural_size);
        }


        if (left >= right && left >= above && left >= bellow) {
            // display hoverzoom to left of cursor
            let top;
            if (natural_size.height > window_height) {
                $hoverzoom.css("top", 0)
            } else if ((y - natural_size.height / 2 - 10) + natural_size.height <= window_height && (y - natural_size.height / 2 - 10) >= 0) {
                top = y - natural_size.height / 2 - 10;
                $hoverzoom.css("top", top)
            } else {
                if ((y - natural_size.height / 2 - 10) + natural_size.height > window_height) {
                    $hoverzoom.css("bottom", 0)
                } else {
                    $hoverzoom.css("top", 0)
                }
            }
            $(image_element).css("max-height", window_height)
            $(image_element).css("max-width", x)
            $hoverzoom.css("right", window_width - x + 10);
        } else if (right >= left && right >= above && right >= bellow) {
            let top;
            if (natural_size.height > window_height) {
                $hoverzoom.css("top", 0)
            } else if ((y - natural_size.height / 2 - 10) + natural_size.height <= window_height && (y - natural_size.height / 2 - 10) >= 0) {
                top = y - natural_size.height / 2 - 10;
                $hoverzoom.css("top", top)
            } else {
                if ((y - natural_size.height / 2 - 10) + natural_size.height > window_height) {
                    $hoverzoom.css("bottom", 0)
                } else {
                    $hoverzoom.css("top", 0)
                }
            }
            $(image_element).css("max-height", window_height)
            $(image_element).css("max-width", window_width - x)

            $hoverzoom.css("left", x + 10);
        } else if (above >= bellow && above >= right && above >= left) {
            let left;
            if (natural_size.width > window_width) {
                left = 0;
            } else if ((x - natural_size.width / 2 - 10) + natural_size.width <= window_width && (x - natural_size.width / 2 - 10) >= 0) {
                left = x - natural_size.width / 2 - 10;
                console.log("4.1");
            } else {
                if ((x - natural_size.width / 2 - 10) + natural_size.width > window_width) {
                    left = (window_width - natural_size.width) / 2 - (window_width - (x - natural_size.width / 2 - 10) + natural_size.width)
                    console.log("4.2");
                } else {
                    left = 0
                }
            }
            $(image_element).css("max-height", window_height - y)
            $(image_element).css("max-width", window_width)
            $hoverzoom.css("top", y + 10)
            $hoverzoom.css("left", left);
        } else {
            let left;
            if (natural_size.width > window_width) {
                left = 0;
            } else if ((x - natural_size.width / 2 - 10) + natural_size.width <= window_width && (x - natural_size.width / 2 - 10) >= 0) {
                left = x - natural_size.width / 2 - 10;
            } else {
                left = (window_width - natural_size.width) / 2;
            }
            $(image_element).css("max-height", y)
            $(image_element).css("max-width", window_width)
            $hoverzoom.css("bottom", window_height - y + 10)
            $hoverzoom.css("left", left);
        }
    }

    static displayHoverzoom(html_element: HTMLElement, dfd: JQuery.Deferred<any>) {
        let src;
        switch (html_element.tagName) {
            case "A":
                src = (html_element as HTMLAnchorElement).href;
                break;
            case "IMG":
                src = (html_element as HTMLImageElement).src;
                break;
            default:
                return null;
        }
        const img = $(`<img src="${src}" alt="hoverzoom - missing image">`)
        img.on("load", function() {
            const image = this as HTMLImageElement
            dfd.resolve({ width: image.naturalWidth, height: image.naturalHeight })
        })
        img.on("error", function() {
            dfd.reject();
        })
        const hoverzoom = $(`<div class="hover-zoom" style="display: none;"></div>`)
        hoverzoom.append(img)

        $("body").append(hoverzoom);
        return hoverzoom;
    }
}
