import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { PhonectorClient } from "../clients/phonector_client";
import { PhonectorScope } from "../scopes/phonector_scope";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface PhonectorProps extends Props {
    id: number
    name: string
    phonector_version: string
    appium_version: string
    last_update: Date
    host: string
    phonector_port: number
    appium_port: number
    ssl: boolean
    username: string
    password: string
}
export type QuerifiedPhonectorProps = QuerifyProps<PhonectorProps>
export type PhonectorCreateProps = Omit<PhonectorProps, 'id'>
export type PhonectorUpdateProps = Partial<PhonectorProps>

export interface PhonectorState extends State {}
export interface PhonectorComputed extends Computed {}
export interface PhonectorStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class Phonector extends VueRecord {
    ['constructor']: typeof Phonector

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PhonectorClient
    static ScopeClass = PhonectorScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PhonectorStaticState = reactive<PhonectorStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Phonector> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Phonector>> = {}

    static field_validators: ModelValidatorOpts<PhonectorProps> = {}

    static resource_name = Enum.Resource.Label.PHONECTOR
    static resource_id = Enum.Resource.Id.PHONECTOR
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => get_css_var("--phone-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PhonectorClient
    declare props: PhonectorProps;
    declare state: PhonectorState;
    declare computed: PhonectorComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    static delete(ids: number | number[]) {
        if (!Array.isArray(ids)) ids = [ids]
        const scope = this.where({ id: ids })
        const content_text = scope.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => {
                return this.ClientClass.delete(ids)
            },
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
Phonector.register_resource(Phonector)
PhonectorClient.ModelClass = Phonector
PhonectorScope.ModelClass = Phonector

on_dom_content_loaded(() => {
    watch(
        () => current.user?.props?.superadmin,
        (superadmin) => {
            Phonector.unsync()
            if (superadmin != null && superadmin) Phonector.sync(`/sync/phonectors`);
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        Phonector: typeof Phonector
    }
}
window.Phonector = Phonector
// </editor-fold>

