<template>
  <div :id="`play_container_${props.id}`"
       ref="play"
       class="container">

    <!--<editor-fold desc="ACTIONS">-->
    <div class="actions">
      <div :id="`play_top_actions_${props.id}`"
           class="top">
        <ActionIcon v-if="props['finished?']"
                    :disabled="!props.report_created"
                    :icon_class="play_action_icon('replay')"
                    :title="props.report_created ? play_action_title('replay') : 'Cannot be replayed.'"
                    :color_class="play_action_color('replay')"
                    @click="run_play_action(play, 'replay')"/>
        <ActionIcon v-if="!(props['master_comms_ready'] && !props['finished?']) && !is_viewer"
                    :icon_class="play_action_icon('delete')"
                    :title="play_action_title('delete')"
                    :color_class="play_action_color('delete')"
                    @click="run_play_action(play, 'delete').catch(e => e.show_toaster())"/>
        <ActionIcon v-if="props['master_comms_ready'] && !props['finished?']"
                    :icon_class="play_action_icon('stop')"
                    :title="play_action_title('stop')"
                    :color_class="play_action_color('stop')"
                    @click="run_play_action(play, 'stop')"/>
        <ActionIcon
            v-if="!play.is_in_debugging() && props['master_comms_ready'] && !props['finished?']"
            :icon_class="play_action_icon('pause')"
            :title="play_action_title('pause')"
            :color_class="play_action_color('pause')"
            @click="run_play_action(play, 'pause')"/>
        <ActionIcon v-if="play.is_in_debugging()"
                    :icon_class="play_action_icon('resume')"
                    :title="play_action_title('resume')"
                    :color_class="play_action_color('resume')"
                    @click="run_play_action(play, 'resume')"/>
        <ToggleIcon v-model="show_details"
                    enabled_icon_class="fa-solid fa-info-circle"
                    :title="show_details ? 'Hide details' : 'Show details'"/>
        <ToggleIcon v-if="play_stream_manager != null && !web.is_single_report"
                    v-model="play_stream_manager.state.decoupled"
                    enabled_icon_class="fa-solid fa-object-group"
                    disabled_icon_class="fa-solid fa-object-ungroup"
                    disabled_color_class="white"
                    :title="play_stream_manager.state.decoupled ? 'Show video streams in this tab' : 'Show video streams in other tabs'"
        />
        <ActionIcon v-if="play_stream_manager != null && !play_stream_manager.state.decoupled && rflex.areas.stream.enabled"
                    :icon_class="play_stream_manager.get_coupled_orientation_icon()"
                    title="Change stream position"
                    @click="play_stream_manager.change_coupled_orientation()"
        />
        <ActionIcon :icon_class="play_action_icon('copy_url')"
                    :title="play_action_title('copy_url')"
                    :color_class="play_action_color('copy_url')"
                    @click="run_play_action(play, 'copy_url')"/>

        <ActionIcon
            v-if="play.is_running() && play.is_group_or_folder_type()"
            :icon_class="play_action_icon('cycle_on_error', play)"
            :title="play_action_title('cycle_on_error', play)"
            :color_class="play_action_color('cycle_on_error', play)"
            @click="run_play_action(play, 'cycle_on_error')"/>
      </div>
      <div class="padding-middle"/>
      <div class="id-tag">
        <span>{{ props.id }}</span>
      </div>
      <div class="bottom">
        <ActionIcon
            v-if="!web.is_main && !main_web_available"
            :icon_class="play_action_icon('project_tab')"
            :title="play_action_title('project_tab', play)"
            :color_class="play_action_color('project_tab', play)"
            @click="run_play_action(play, 'project_tab')"/>
        <ActionIcon
            v-if="web.is_main && !reports_web_available"
            :icon_class="play_action_icon('reports_tab')"
            :title="play_action_title('reports_tab', play)"
            :color_class="play_action_color('reports_tab', play)"
            @click="run_play_action(play, 'reports_tab')"/>
        <ActionIcon
            icon_class="fa-solid fa-sort-amount-up"
            title="Scroll to top"
            @click="scroll_to_top"
        />
        <ActionIcon
            icon_class="fa-solid fa-sort-amount-down-alt"
            :title="sticky_scroll_enabled ? 'Disable sticky scroll' : 'Enable sticky scroll'"
            :color_class="sticky_scroll_enabled ? get_css_var('--button-white') : get_css_var('--button-disabled')"
            @click="toggle_sticky_scroll"
        />
      </div>
    </div>
    <!--</editor-fold>-->

    <!--<editor-fold desc="BEFORE START STATUS">-->
    <div v-if="!play.is_running() && !play.is_finished() && !play.is_booting_status()"
         class="status-container">
      Execution is in {{ props['status'] }} status.
      <div class="play-logs-container">
        <PlayLogReport
            v-for="play_log in play_play_logs"
            :key="play_log.props.id"
            :play="play"
            :play_log="play_log"
            :role="role"
            :main_web_available="main_web_available"
            :project_version_setting="project_version_setting"
        />
      </div>
    </div>
    <!--</editor-fold>-->

    <!--<editor-fold desc="FAILED TO START">-->
    <div v-else-if="is_in_error"
         class="status-container error">
      Execution failed to start
      <div class="play-logs-container">
        <PlayLogReport
            v-for="play_log in play_play_logs"
            :key="play_log.props.id"
            :play="play"
            :role="role"
            :main_web_available="main_web_available"
            :project_version_setting="project_version_setting"
            :play_log="play_log"
        />
      </div>
    </div>
    <!--</editor-fold>-->

    <!--<editor-fold desc="BOOTING">-->
    <div v-else-if="started_or_pending_main_play_scenarios.length == 0"
         ref="content_container"
         class="content-container">
      <div class="status-container">
        <template v-if="play_play_logs.length == 0">
          Execution is in {{ props['status'] }} status.
        </template>
        <div v-else
             class="play-logs-container">
          <PlayLogReport
              v-for="play_log in play_play_logs"
              :key="play_log.props.id"
              :play="play"
              :role="role"
              :main_web_available="main_web_available"
              :project_version_setting="project_version_setting"
              :play_log="play_log"
          />
        </div>
      </div>
    </div>
    <!--</editor-fold>-->

    <!--<editor-fold desc="RUNNING">-->
    <div v-else
         ref="content_container"
         class="content-container"
         @wheel.stop.passive="on_play_log_scroll">
      <RFlex :rflex="rflex">
        <template #stream>
          <div class="stream-content">
            <VideoPlayer
                v-if="play_stream_manager?.has_coupled_recording()"
                :stream_id="play_stream_manager.active_play_scenario().key().toString()"
                :url="play_stream_manager.active_play_scenario().props.video_url"/>
            <NoVnc
                v-else-if="play_stream_manager?.has_coupled_vnc()"
                :url="play_stream_manager.active_play_scenario().vnc_url()"
                :stream_id="play_stream_manager.active_play_scenario().key().toString()"
                :is_inspector_shown="show_inspector(play_stream_manager.active_play_scenario())"
                :scenario_setting_id="play_stream_manager.active_play_scenario().props.scenario_setting_id"
                :backend_ws_channel="play_stream_manager.active_play_scenario()?.props.backend_ws_channel"
                :tab="play_tab"
                @novnc-connected="resize_stream_to_fit"
            />
          </div>
        </template>

        <template #logs>
          <div ref="plays_container"
               class="plays-container">
            <div v-if="show_details"
                 class="details-container">
              <PlayReportDetails
                  :play="play"
                  :main_play_scenarios="main_play_scenarios"
                  :main_web_available="main_web_available"/>
            </div>
            <template v-if="play.is_scenario_type()">
              <PlayScenarioReport
                  v-for="main_play_scenario in started_or_pending_main_play_scenarios"
                  :key="main_play_scenario.props.id"
                  :play_scenario="main_play_scenario"
                  :play="play"
                  :expanded="expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`] === true"
                  :play_setting="play_setting"
                  :play_stream_manager="play_stream_manager"
                  :is_single_worker="is_single_worker"
                  :main_web_available="main_web_available"
                  :role="role"
                  :is_viewer="is_viewer"
                  :parents="0"
                  @toggle-expand="() => expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`] = !expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`]"
                  @play-log-mounted="on_play_log_mounted"
                  @disable-sticky-scroll="sticky_scroll_enabled = false"
                  @play-debugger-mounted="on_play_debugger_mounted"
              />
            </template>
            <template v-else-if="play.is_group_or_folder_type()">
              <PlayGroupReport
                  v-for="play_group in started_or_pending_play_groups()"
                  :key="play_group.props.id"
                  :play="play"
                  :play_setting="play_setting"
                  :expanded="expanded_play_groups[`${play_group.constructor.name}_${play_group.props.id}`] === true"
                  :play_group="play_group"
                  :auto_expand_play_scenarios="auto_expand_play_scenarios"
                  :play_stream_manager="play_stream_manager"
                  :is_single_worker="is_single_worker"
                  :main_web_available="main_web_available"
                  :role="role"
                  :is_viewer="is_viewer"
                  @play-log-mounted="on_play_log_mounted"
                  @toggle-expand="() => expanded_play_groups[`${play_group.constructor.name}_${play_group.props.id}`] = !expanded_play_groups[`${play_group.constructor.name}_${play_group.props.id}`]"
                  @play-debugger-mounted="on_play_debugger_mounted"
              />
            </template>
          </div>
        </template>
      </RFlex>
    </div>
    <!--</editor-fold>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Play } from "../../../../vue_record/models/play/play";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import { PlaySnippet } from "../../../../vue_record/models/play/play_snippet";
import { PlayProps } from "../../../../vue_record/models/play/play";
import { PlayLog } from "../../../../vue_record/models/play/play_log";
import { set_expanded_play_node_states } from "../../../../helpers/play/set_expanded_play_node_states";
import ActionIcon from "../../../testa/ActionIcon.vue";
import ToggleIcon from "../../../testa/ToggleIcon.vue";
import PlayReportDetails from "./PlayReportDetails.vue";
import VideoPlayer from "../../../testa/VideoPlayer.vue";
import PlayLogReport from "../play_log/PlayLogReport.vue";
import PlayScenarioReport from "../play_scenario/PlayScenarioReport.vue";
import PlayGroupReport from "../play_group/PlayGroupReport.vue";
import NoVnc from "../play_scenario/NoVnc.vue";
import { PlayTab } from "../../../testa/editor/tabs/play_tab";
import { play_action_title } from "../../../../helpers/play/play_action";
import { play_action_color } from "../../../../helpers/play/play_action";
import { play_action_icon } from "../../../../helpers/play/play_action";
import { run_play_action } from "../../../../helpers/play/play_action";
import { EnumUserRole } from "../../../../auto_generated/enums";
import { get_css_var } from "../../../../helpers/generic/get_css_var";
import { ResizableFlex } from "../../../testa/resizable/resizable_flex/resizable_flex";
import RFlex from "../../../testa/resizable/resizable_flex/RFlex.vue";
import { computed } from "../../../../helpers/vue/computed";


export interface FrontendCommand {
    command: string
    play_scenario_id: number
    play_snippet_id: number
}

export default defineComponent({
    components: {
        RFlex,
        ToggleIcon,
        ActionIcon,
        PlayReportDetails,
        VideoPlayer,
        PlayLogReport,
        PlayScenarioReport,
        PlayGroupReport,
        NoVnc,
    },
    // <editor-fold desc="PROPS">
    props: {
        play: {
            type: Object as PropType<Play>,
            required: true,
        },
        play_tab: {
            type: Object as PropType<PlayTab>,
            required: false,
            default: null
        }
    },
    // </editor-fold>
    // <editor-fold desc="DATA">
    data() {
        return {
            show_details: this.play.props["finished?"],
            sticky_scroll_enabled: true,
            expanded_play_scenarios: {} as Record<string, boolean>,
            expanded_play_groups: {} as Record<string, boolean>,
            data_loaded: false,
            rflex: global_effect_scope.run(() => ResizableFlex.new({
                id: "play_report_grid",
                direction: computed(() => this.play.state.play_stream_manager.get_coupled_orientation()),
                fill_container: true,
                areas: [
                    {
                        id: "stream",
                        min_ratio_size: 0.1,
                        initial_ratio_size: 0.5,
                        enabled: computed(() => {
                            const play_stream_manager = this.play.state.play_stream_manager
                            return !play_stream_manager.state.decoupled &&
                                play_stream_manager.state.last_active_play_scenario != null &&
                                play_stream_manager.state.last_active_play_scenario.has_stream()
                        })
                    }, {
                        id: "logs",
                        min_ratio_size: 0.1,
                    }
                ]
            }))
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        project_version() {
            return this.play.project_version
        },
        project_version_setting() {
            return this.project_version.project_version_setting
        },
        Enum() {
            return Enum
        },
        play_stream_manager() {
            return this.play.state.play_stream_manager
        },
        main_play_scenarios() {
            return this.play.main_play_scenarios
        },
        play_groups() {
            return this.play.play_groups
        },
        play_worker_groups() {
            return this.play.play_worker_groups
        },
        play_setting() {
            return this.play.play_setting
        },
        play_play_logs() {
            return this.play.play_logs.only_in_play.toArray()
        },
        PlaySnippet() {
            return PlaySnippet
        },
        props(): PlayProps {
            return this.play.props
        },
        is_in_error() {
            return this.props.status == Enum.Play.Status.ERROR && this.started_or_pending_main_play_scenarios.length == 0
        },
        auto_expand_play_scenarios() {
            const auto_expand = current.user?.props?.auto_expand_play_scenarios
            if (auto_expand == null) {
                return true
            } else {
                return auto_expand;
            }
        },
        started_or_pending_main_play_scenarios() {
            return this.main_play_scenarios.started_or_pending.toArray();
        },
        grid_areas() {
            if (this.play_stream_manager?.has_coupled_stream()) {
                return `'stream' 'logs'`
            } else {
                return `'logs'`
            }
        },
        grid_rows() {
            if (this.play_stream_manager?.has_coupled_stream()) {
                return ["50%", "50%"]
            } else {
                return ["100%"]
            }
        },
        is_single_worker() {
            return this.play.play_worker_groups.count < 2
        },
        main_web_available() {
            return ui_sync.is_main_web_available(this.play.props.project_version_id)
        },
        reports_web_available() {
            return ui_sync.is_reports_available()
        },
        web() {
            return web
        },
        role(): EnumUserRole {
            return this.play.computed.role
        },
        is_viewer() {
            return this.play.computed.role_is_viewer
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'play_stream_manager.state.decoupled'() {
            // when manually changing decouple state, set the new state as default until page is refreshed
            // or setting is saved
            current.user.props.decouple_streams = this.play_stream_manager.state.decoupled
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    created() {
        if (web.is_single_report) this.play.sync_relations()
        if (this.play_tab != null) {
            this.play_tab.set_component_instance(this, true)
        }
    },
    beforeMount() {
        this.play
            .client
            .load_associated()
            .then(() => {
                if (this.play_worker_groups.count == 1) this.play_stream_manager.set_auto_show_vnc(true)
                this.data_loaded = true;
            })

        this.set_expanded_play_scenarios_state()
        this.set_expanded_play_groups_state()
    },
    mounted() {
        // @ts-ignore - is assigned to div for easier referencing
        this.$refs.play.play = this.play

        if (this.play_tab != null) {
            this.play_tab.set_editor_mounted(true)
        }

        // when closing the play_tab, remove all associated streams
        this.play_tab?.on("before_close", () => {
            this.play_stream_manager?.close_all_tabs()
        })
        this.play.on("before_unload", this.close)
    },
    unmounted() {
        if (web.is_single_report) this.play.unsync_relations()
        this.play.off("before_unload", this.close)
    },
    beforeUpdate() {
        this.set_expanded_play_scenarios_state();
        this.set_expanded_play_groups_state()
    },
    updated() {
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        get_css_var,
        run_play_action,
        play_action_icon,
        play_action_color,
        play_action_title,
        show_inspector(play_scenario: PlayScenario) {
            return play_scenario.state.is_inspector_shown
        },
        started_or_pending_play_groups() {
            return this.play_groups.started_or_pending.toArray();
        },
        show_replay_modal() {
            this.play.show_replay_modal();
        },
        toggle_sticky_scroll() {
            this.sticky_scroll_enabled = !this.sticky_scroll_enabled
            if (this.sticky_scroll_enabled) {
                this.scroll_to_bottom();
            }
        },
        on_play_log_scroll(event: WheelEvent) {
            if (event.deltaY > 0 && !this.sticky_scroll_enabled) {
                const ele = this.$refs.plays_container as HTMLElement
                if (ele.scrollHeight - ele.scrollTop - ele.clientHeight <= 0) {
                    this.sticky_scroll_enabled = true
                }
            }

            if (this.sticky_scroll_enabled && event.deltaY < 0) {
                const ele = this.$refs.plays_container as HTMLElement
                const is_scrollable = ele.scrollHeight > ele.offsetHeight;
                if (is_scrollable) this.sticky_scroll_enabled = false
            }
        },
        on_play_log_mounted(_play_log: PlayLog) {
            if (this.sticky_scroll_enabled) {
                this.scroll_to_bottom();
            }
        },
        on_play_debugger_mounted(_play_snippet: PlaySnippet) {
            if (this.sticky_scroll_enabled) {
                this.scroll_to_bottom();
            }
        },
        scroll_to_top() {
            this.sticky_scroll_enabled = false
            const plays_container = this.$refs.plays_container as HTMLElement
            plays_container.scrollTop = 0
        },
        scroll_to_bottom() {
            const plays_container = this.$refs.plays_container as HTMLElement
            plays_container.scrollTop = plays_container.scrollHeight;
        },
        set_expanded_play_scenarios_state() {
            const play_scenarios = this.started_or_pending_main_play_scenarios
            set_expanded_play_node_states(this.expanded_play_scenarios, play_scenarios, true);
        },
        set_expanded_play_groups_state() {
            const play_groups = this.started_or_pending_play_groups()
            set_expanded_play_node_states(this.expanded_play_groups, play_groups, true);
        },
        resize_stream_to_fit() {
            const scenario_setting = this.play_stream_manager.state.last_active_play_scenario?.scenario_setting
            if (scenario_setting == null) return;
            const xvfb_aspect_ratio = scenario_setting.props.xvfb_width / scenario_setting.props.xvfb_height

            const width = this.rflex.get_width();
            const height = this.rflex.get_height();

            if (this.rflex.is_row_direction()) {
                // height is fixed
                let new_width = xvfb_aspect_ratio * height
                if (new_width > width / 2) new_width = width / 2
                this.rflex.areas.stream.set_size(new_width)
            } else {
                // width is fixed
                let new_height = width / xvfb_aspect_ratio
                if (new_height > height / 2) new_height = height / 2
                this.rflex.areas.stream.set_size(new_height)
            }
        },
        close() {
            if (this.play_tab != null) {
                this.play_tab.close()
            }
            if (web.is_single_report) {
                window.close()
            }
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

$actions-width: 27px;
$actions-border-width: 2px;

.actions {
  font-size: 0.95em;
  width: $actions-width;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  border-right: $actions-border-width solid var(--secondary-background-color);
  align-items: center;
  overflow: auto;

  // to show scrollbar on left
  direction: rtl;

  /*----- Scrollbar CSS  -----*/
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    width: 0;
    border: 0 solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0;
    background-color: white;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .top {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .padding-middle {
    height: 100%;
    width: 100%;
    flex-shrink: 1;
  }

  .id-tag {
    flex-shrink: 0;
    color: var(--font-color-secondary);
    padding-top: 8px;
    padding-bottom: 4px;
    transform: rotate(90deg);
    /* Safari */
    -webkit-transform: rotate(90deg);
    /* Firefox */
    -moz-transform: rotate(90deg);
    font-size: 1.1em;
  }

  .bottom {
    flex-shrink: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .action {
    font-size: 16px;
    margin-top: 12px;
    cursor: pointer;

    &:hover {
      filter: brightness(1.3);
    }

    &.stop, &.destroy {
      color: var(--button-red);
    }

    &.pause {
      color: var(--button-blue);
    }

    &.play, &.replay {
      color: var(--button-green)
    }

    &.decouple-streams, &.details {
      color: var(--button-disabled);

      &.enabled {
        color: var(--button-green);
      }
    }

    &.share {
      color: var(--button-green);
    }

    &.on-error-cycle {
      color: var(--button-red);
    }

    &.scroll-top {
    }

    &.sticky-scroll {
      color: var(--button-white);

      &.disabled {
        color: var(--button-disabled);
      }
    }
  }
}


.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  font-size: 30px;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &.error {
    color: var(--button-red);
  }

  .play-logs-container {
    display: flex;
    flex-direction: column;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - $actions-width - $actions-border-width);

  .plays-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .details-container {
    //height: 300px;
    padding: 10px;

    .details-table {
      tr > td {
        width: 90px;
      }
    }
  }
}

.stream-content {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
