import _ from "lodash";

type Round = "floor" | "ceil" | "round"
type LowestUnit = "millisecond" | "second" | "minute" | "hour" | "day"
type Options = {
    round?: Round
    lowest_unit?: LowestUnit
}

const default_options = {
    round: "floor",
    lowest_unit: "millisecond"
} as const

const unit_order = ["millisecond", "second", "minute", "hour", "day"]

/**
 * @param milli in milliseconds
 * @param options
 */
export function pretty_duration(milli: number, options: Options = default_options) {
    options = _.merge(_.cloneDeep(default_options), options)
    if (milli == null) return 0

    const unit_level = unit_order.indexOf(options.lowest_unit)

    if (milli < 1000 && unit_level <= unit_order.indexOf("millisecond")) {
        const seconds = (milli / 1000).toFixed(1)
        return `${seconds}s`
    }

    const seconds = round(milli / 1000, options.round);
    if (seconds < 60 && unit_level <= unit_order.indexOf("second")) return `${seconds}s`

    const minutes = round(seconds / 60, options.round);
    if (minutes < 60 && unit_level <= unit_order.indexOf("minute")) return `${minutes}m ${seconds % 60}s`

    const hours = round(minutes / 60, options.round);
    if (hours < 60 && unit_level <= unit_order.indexOf("hour")) return `${hours}h ${minutes % 60}m`

    const days = round(hours / 24, options.round);
    return `${days}d ${hours % 24}h`
}

export function round(duration: number, how: Round) {
    switch (how) {
        case "round":
            return Math.round(duration)
        case "ceil":
            return Math.ceil(duration)
        case "floor":
            return Math.floor(duration)
        default:
            throw new Error(`Unknown round type: ${how}`)
    }
}
