import { ScenarioSetting } from "../models/scenario_setting";
import { Proxy } from "../models/proxy"
import { Scenario } from "../models/scenario";
import { ScenarioSavepoint } from "../models/scenario_savepoint";
import { PlayScenario } from "../models/play/play_scenario";
import { PlaySandboxScope } from "../scopes/play/play_sandbox_scope";
import { PlayScope } from "../scopes/play/play_scope";
import { Play } from "../models/play/play";
import { PlaySandbox } from "../models/play/play_sandbox";
import { Browser } from "../models/scenario_setting/browser";
import { Phone } from "../models/scenario_setting/phone";
import { PhoneAppScope } from "../scopes/scenario_setting/phone_app_scope";
import { PhoneApp } from "../models/scenario_setting/phone_app";
import { PhoneScope } from "../scopes/scenario_setting/phone_scope";
import { BrowserScope } from "../scopes/scenario_setting/browser_scope";



declare module "../models/scenario_setting" {
    interface ScenarioSetting {
        proxy: Proxy
        scenario: Scenario
        scenario_savepoint: ScenarioSavepoint
        play_scenario: PlayScenario
        play_sandboxes: PlaySandboxScope
        browsers: BrowserScope
        phones: PhoneScope
        apps: PhoneAppScope
        target_plays: PlayScope
    }
}

// belongs_to
ScenarioSetting.belongs_to("scenario", Scenario, "scenario_id")
ScenarioSetting.belongs_to("proxy", Proxy, "proxy_id")
ScenarioSetting.belongs_to("scenario_savepoint", ScenarioSavepoint, "scenario_savepoint_id")

ScenarioSetting.has_one("play_scenario", PlayScenario, "scenario_setting_id")

// has many
ScenarioSetting.has_many("target_plays", Play, "target_scenario_setting_id")
ScenarioSetting.has_many("play_sandboxes", PlaySandbox, "scenario_setting_id")
ScenarioSetting.has_many("browsers", Browser, "scenario_setting_id")
ScenarioSetting.has_many("phones", Phone, "scenario_setting_id")

// has many through
ScenarioSetting.has_many_through(
    "apps",
    "phones",
    "apps",
    PhoneApp
)
