import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ScenarioSavepoint } from "../models/scenario_savepoint";
import { ScenarioSavepointProps } from "../models/scenario_savepoint";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ScenarioSavepointClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ScenarioSavepoint
    declare record: ScenarioSavepoint

    static load(id: string | number, reload = false): Promise<ScenarioSavepoint> {
        if (reload) this.ModelClass.state.load_promises[id] = null

        if (this.ModelClass.state.load_promises[id] == null) {
            const promise = new Promise<ScenarioSavepoint>((resolve, reject) => {
                $.ajax({
                    url: `/scenario_savepoints/${id}`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: ScenarioSavepointProps) => {
                        const scenario_savepoint = ScenarioSavepoint.new(data)
                        resolve(scenario_savepoint)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
            this.ModelClass.state.load_promises[id] = promise
            promise.catch(() => {
                this.ModelClass.state.load_promises[id] = null
            })
            return promise
        } else {
            return this.ModelClass.state.load_promises[id]
        }
    }
}
