import { SnippetScope } from "../../scopes/snippet_scope"
import { VueRecord } from "../vue_record";
import { PlayScope } from "../../scopes/play/play_scope";
import { ScheduleScope } from "../../scopes/schedule_scope";
import { AppScope } from "../../scopes/app_scope";
import { PhoneScope } from "../../scopes/phone_scope";
import { ImageFolderScope } from "../../scopes/image_folder_scope";
import { ImageScope } from "../../scopes/image_scope";
import { FileFolderScope } from "../../scopes/file_folder_scope";
import { FileScope } from "../../scopes/file_scope";
import { GroupFolderScope } from "../../scopes/group_folder_scope";
import { GroupScope } from "../../scopes/group_scope";
import { ScenarioFolderScope } from "../../scopes/scenario_folder_scope";
import { ScenarioScope } from "../../scopes/scenario_scope";
import { SnippetFolderScope } from "../../scopes/snippet_folder_scope";
import { Snippet } from "../../models/snippet";
import { Image } from "../../models/image";
import { File } from "../../models/file";
import { SnippetFolder } from "../../models/snippet_folder";
import { Scenario } from "../../models/scenario";
import { ScenarioFolder } from "../../models/scenario_folder";
import { Group } from "../../models/group";
import { GroupFolder } from "../../models/group_folder";
import { FileFolder } from "../../models/file_folder";
import { ImageFolder } from "../../models/image_folder";
import { Phone } from "../../models/phone";
import { App } from "../../models/app";
import { Schedule } from "../../models/schedule";
import { Play } from "../../models/play/play";
import { VueRecordScope } from "../vue_record_scope";
import { EMPTY_ARRAY } from "../vue_record_scope";
import { LogFile } from "../../models/non_db/log_file";
import { LogFileClient } from "../../clients/non_db/log_file_client";
import { LogFileFolder } from "../../models/non_db/log_file_folder";
import { LogFileScope } from "../../scopes/non_db/log_file_scope";
import { LogFileFolderScope } from "../../scopes/non_db/log_file_folder_scope";

export type ScopedRecordsMap = {
    snippets: SnippetScope,
    snippet_folders: SnippetFolderScope,
    scenarios: ScenarioScope,
    scenario_folders: ScenarioFolderScope,
    groups: GroupScope,
    group_folders: GroupFolderScope,
    files: FileScope,
    file_folders: FileFolderScope,
    images: ImageScope,
    image_folders: ImageFolderScope,
    phones: PhoneScope,
    apps: AppScope,
    schedules: ScheduleScope,
    plays: PlayScope,
    log_files: LogFileScope,
    log_file_folders: LogFileFolderScope
}

export type ScopedIdsMap = {
    snippet_ids: number[],
    snippet_folder_ids: number[],
    scenario_ids: number[],
    scenario_folder_ids: number[],
    group_ids: number[],
    group_folder_ids: number[],
    file_paths: string[],
    file_folder_paths: string[],
    image_paths: string[],
    image_folder_paths: string[],
    phone_udids: string[],
    app_ids: number[],
    schedule_ids: number[],
    play_ids: number[]
}


export function to_scoped_map(records: VueRecord[]): ScopedRecordsMap {
    const grouped = records.group_by(r => r.constructor.resource_id)
    const keys = [
        "snippet", "snippet_folder", "scenario", "scenario_folder", "group", "group_folder", "file", "file_folder",
        "image", "image_folder", "phone", "app", "schedule", "play", "log_file", "log_file_folder"
    ]
    keys.each(key => {
        if (grouped[key] == null) grouped[key] = EMPTY_ARRAY as VueRecord[]
    })
    return {
        snippets: Snippet.to_scope(grouped.snippet as Snippet[]),
        snippet_folders: SnippetFolder.to_scope(grouped.snippet_folder as SnippetFolder[]),
        scenarios: Scenario.to_scope(grouped.scenario as Scenario[]),
        scenario_folders: ScenarioFolder.to_scope(grouped.scenario_folder as ScenarioFolder[]),
        groups: Group.to_scope(grouped.group as Group[]),
        group_folders: GroupFolder.to_scope(grouped.group_folder as GroupFolder[]),
        files: File.to_scope(grouped.file as File[]),
        file_folders: FileFolder.to_scope(grouped.file_folder as FileFolder[]),
        images: Image.to_scope(grouped.image as Image[]),
        image_folders: ImageFolder.to_scope(grouped.image_folder as ImageFolder[]),
        phones: Phone.to_scope(grouped.phone as Phone[]),
        apps: App.to_scope(grouped.app as App[]),
        schedules: Schedule.to_scope(grouped.schedule as Schedule[]),
        plays: Play.to_scope(grouped.play as Play[]),
        log_files: LogFile.to_scope(grouped.log_file as LogFile[]),
        log_file_folders: LogFileFolder.to_scope(grouped.log_file_folder as LogFileFolder[]),
    }
}

declare module "../vue_record" {
    interface VueRecord {
        to_scoped_map(): ScopedRecordsMap
    }
}

declare module "../vue_record_scope" {
    interface VueRecordScope {
        to_scoped_map(): ScopedRecordsMap
    }
}

VueRecord.prototype.to_scoped_map = function() {
    return this.constructor.to_scope([this]).to_scoped_map()
}

VueRecordScope.prototype.to_scoped_map = function() {
    return to_scoped_map(this.toArray())
}
