import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumUserRole } from "../../auto_generated/enums";
import { UserProjectClient } from "../clients/user_project_client";
import { UserProjectScope } from "../scopes/user_project_scope";
import { on_relations_established } from "../../helpers/events/on_relations_established";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface UserProjectProps extends Props {
    id: number
    user_id: number
    project_id: number
    role: EnumUserRole
    created_at: Date
    updated_at: Date
}
export type QuerifiedUserProjectProps = QuerifyProps<UserProjectProps>
export type UserProjectCreateProps = Omit<UserProjectProps, 'id'>
export type UserProjectUpdateProps = Partial<UserProjectProps>

export interface UserProjectState extends State {}
export interface UserProjectComputed extends Computed {}
export interface UserProjectStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class UserProject extends VueRecord {
    ['constructor']: typeof UserProject

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = UserProjectClient
    static ScopeClass = UserProjectScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: UserProjectStaticState = reactive<UserProjectStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_id", "user_id", "role"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof UserProject> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof UserProject>> = {}

    static field_validators: ModelValidatorOpts<UserProjectProps> = {}

    static resource_name = Enum.Resource.Label.USER_PROJECT
    static resource_id = Enum.Resource.Id.USER_PROJECT
    static icon_class = "fa-solid fa-chalkboard-user"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: UserProjectClient
    declare props: UserProjectProps;
    declare state: UserProjectState;
    declare computed: UserProjectComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="HOOKS">
    after_create() {
        super.after_create();
        on_relations_established(() => {
            if (this.props.user_id == current.user?.key()) {
                if (current.user.projects.count == 1 && current.project == null) {
                    current.project_version = current.user.projects.first().project_versions.main
                }
            }
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
UserProject.register_resource(UserProject)
UserProjectClient.ModelClass = UserProject
UserProjectScope.ModelClass = UserProject

if (globalThis.current_user_project_props?.id) {
    current.user_project = UserProject.new(current_user_project_props)
}

if (globalThis.user_projects_props) {
    user_projects_props.forEach(user_project_props => UserProject.new(user_project_props))
}

on_dom_content_loaded(() => {
    watch(
        [() => current.user?.key(), () => current.user?.props?.superadmin, () => current.user_project?.props?.role, () => current.user?.props?.project_id],
        ([user_id, superadmin, role, project_id]) => {
            UserProject.unsync()

            if (superadmin != null && superadmin) {
                UserProject.sync(`/sync/user_projects`);
            } else if (role != null && role == Enum.User.Role.ADMIN && project_id != null) {
                UserProject.sync(`/sync/project/${project_id}/user_projects`);
            } else if (user_id != null) {
                UserProject.sync(`/sync/user/${user_id}/user_projects`);
            }
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    /** Pre-populated list of user-project assignments */
    var user_projects_props: UserProjectProps[]
    var current_user_project_props: UserProjectProps

    interface Window {
        UserProject: typeof UserProject
    }

    interface Current {
        user_project: UserProject
        role_for(project_id: number): EnumUserRole
        role_is_viewer_for(project_id: number): boolean
    }
}
window.UserProject = UserProject
current.role_for = (project_id: number) => {
    if (current.user?.is_superadmin()) return Enum.User.Role.SUPERADMIN

    return UserProject.where({ project_id, user_id: current.user?.key() }).first()?.props?.role
}
// </editor-fold>

