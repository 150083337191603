import { Project } from "../models/project";
import { MobileProvisioning } from "../models/mobile_provisioning";
import { DeviceScope } from "../scopes/mobile_provisionings/device_scope";
import { Device } from "../models/mobile_provisionings/device";
import { PhoneScope } from "../scopes/phone_scope";
import { Phone } from "../models/phone";

declare module "../models/mobile_provisioning" {
    interface MobileProvisioning {
        project: Project
        webdriver_project: Project

        // apps(): AppScope
        devices: DeviceScope
        testa_phones: PhoneScope
    }
}

MobileProvisioning.belongs_to("project", Project, "project_id")
MobileProvisioning.has_one("webdriver_project", Project, "webdriver_mobile_provisioning_id", {
    dependent_unload: false,
    dependent_nullify: true
})

// MobileProvisioning.has_many("apps", App, "mobile_provisioning_id")
MobileProvisioning.has_many("devices", Device, "mobile_provisioning_id", {
    dependent_unload: true
})
MobileProvisioning.has_many_through("testa_phones", "devices", "phone", Phone)

