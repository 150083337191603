import { PlaySetting } from "../../models/play/play_setting";
import { Play } from "../../models/play/play";
import { VariableSet } from "../../models/variable_set";

declare module "../../models/play/play_setting" {
    interface PlaySetting {
        variable_set: VariableSet

        play: Play
    }
}

PlaySetting.belongs_to("variable_set", VariableSet, "variable_set_id")

PlaySetting.has_one("play", Play, "play_setting_id")
