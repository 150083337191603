import { setBlockTracking as _setBlockTracking, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _cache[0] || (
    _setBlockTracking(-1),
    _cache[0] = _createElementVNode("div", {
      ref: (el) => _ctx.asc.set_container(el),
      class: "asc-overlay",
      tabindex: "-1",
      onBlur: $event => (_ctx.asc.schedule_perform()),
      onMouseleave: $event => (_ctx.asc.schedule_perform()),
      onContextmenu: _withModifiers(() => {}, ["prevent"]),
      onMousedown: _withModifiers(e => _ctx.asc.on_mousedown(e), ["stop"]),
      onMouseenter: e => _ctx.asc.on_mouseenter(e),
      onMousemove: e => _ctx.asc.throttled_mouse_move(e, new Date()),
      onMouseup: e => _ctx.asc.on_mouseup(e),
      onWheel: e => _ctx.asc.on_wheel(e),
      onKeydown: _withModifiers(e => _ctx.asc.on_keydown(e), ["stop","prevent"])
    }, null, 40 /* PROPS, NEED_HYDRATION */, ["onBlur", "onMouseleave", "onContextmenu", "onMousedown", "onMouseenter", "onMousemove", "onMouseup", "onWheel", "onKeydown"]),
    _setBlockTracking(1),
    _cache[0]
  )
}