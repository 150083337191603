export function orderable_version_value(version: string) {
    if (version == null) return 0
    version = replace_letters_with_numbers(version)
    let power = 1
    const parts = version.split(".")
    let value = 0
    for (let i = parts.length - 1; i >= 0; --i) {
        const part = parseInt(parts[i]);
        const coef = Math.pow(10, power)
        value += coef * part
        power += 5
    }
    return value
}

function replace_letters_with_numbers(str: string) {
    return str.replace(/[a-zA-Z]/g, function(match) {
        const charCode = match.toLowerCase().charCodeAt(0);
        return (charCode - 96).toString();
    });
}
