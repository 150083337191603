import { computed, reactive } from "vue";
import { TabManager } from "./tab_manager";
import { Tab } from "./tab";
import { Editor } from "./editor";
import { generate_eid } from "../../../helpers/generate/generate_eid";
import SplitHow = Splitter.SplitHow;
import { SplitterRegion } from "./splitter_region";
import { EventBus } from "../../../helpers/event_bus";
import { Snippet } from "../../../vue_record/models/snippet";
import { Group } from "../../../vue_record/models/group";
import { File } from "../../../vue_record/models/file";
import { Image } from "../../../vue_record/models/image";
import { Play } from "../../../vue_record/models/play/play";
import { ResizableFlex } from "../resizable/resizable_flex/resizable_flex";
import { FeatureFlag } from "../../../vue_record/models/feature_flag";

export namespace Splitter {
    export type SplitHow = "left_right" | "top_bottom"

    export type Input = {
        id: string,
        parent: Editor | SplitterRegion

        main?: TabManager | Splitter
        other?: TabManager | Splitter
        split?: SplitHow
    }

    export type State = {
        is_drag_over: boolean
        main: SplitterRegion
        other: SplitterRegion
        split: SplitHow
    }

    export type Events =
        "removed"
        | "active_tab_changed"
        | "region_removed"
        | "dragenter"
        | "dragleave"
        | "dragover"
        | "drop"
}

export class Splitter {
    parent: Editor | SplitterRegion
    id: string

    state: Splitter.State
    computed: {
        tabs: Tab[]
        active_tab: Tab,
        tab_managers: TabManager[]
    }

    resizable_flex: ResizableFlex
    event_bus: EventBus<Splitter.Events, Splitter>

    // for nested splitters
    splitter_event_bus: EventBus<Splitter.Events, Splitter>
    tab_event_bus: EventBus<Tab.Events, Tab>
    tab_manager_event_bus: EventBus<TabManager.Events, TabManager>

    constructor(data: Splitter.Input) {
        this.id = data.id;
        this.state = reactive<Splitter.State>({
            is_drag_over: false,
            main: new SplitterRegion({
                id: generate_eid(),
                splitter: this,
                is_main: true,
                object: data.main
            }),
            other: new SplitterRegion({
                id: generate_eid(),
                splitter: this,
                is_main: false,
                object: data.other
            }),
            split: "left_right",
        }) as Splitter.State

        this.set_parent(data.parent)
        if (data.split != null) this.state.split = data.split;
        this.event_bus = new EventBus<Splitter.Events, Splitter>()
        this.splitter_event_bus = new EventBus<Splitter.Events, Splitter>()
        this.tab_event_bus = new EventBus<Tab.Events, Tab>()
        this.tab_manager_event_bus = new EventBus<TabManager.Events, TabManager>();
        this._register_default_event_handlers();

        const tab_managers = computed(() => {
            let tab_managers: TabManager[] = [];
            if (this.state.main.object instanceof TabManager) tab_managers.push(this.state.main.object)
            if (this.state.other.object instanceof TabManager) tab_managers.push(this.state.other.object)

            if (this.state.main.object instanceof Splitter) tab_managers = tab_managers.concat(this.state.main.object.get_tab_managers())
            if (this.state.other.object instanceof Splitter) tab_managers = tab_managers.concat(this.state.other.object.get_tab_managers())
            return tab_managers
        })

        this.computed = reactive({
            tabs: computed(() => {
                let tabs: Tab[] = [];
                if (this.state.main.object != null) {
                    if (this.state.main.object instanceof Splitter) {
                        tabs = tabs.concat(this.state.main.object.computed.tabs)
                    } else {
                        tabs = tabs.concat(this.state.main.object.state.tabs)
                    }
                }
                if (this.state.other.object != null) {
                    if (this.state.other.object instanceof Splitter) {
                        tabs = tabs.concat(this.state.other.object.computed.tabs)
                    } else {
                        tabs = tabs.concat(this.state.other.object.state.tabs)
                    }
                }
                return tabs;
            }),
            active_tab: computed(() => {
                let main_active_tab;
                if (this.state.main.object != null) {
                    if (this.state.main.object instanceof Splitter) {
                        main_active_tab = this.state.main.object.computed.active_tab
                    } else {
                        main_active_tab = this.state.main.object.state.active_tab
                    }
                }
                let other_active_tab;
                if (this.state.other.object != null) {
                    if (this.state.other.object instanceof Splitter) {
                        other_active_tab = this.state.other.object.computed.active_tab
                    } else {
                        other_active_tab = this.state.other.object.state.active_tab
                    }
                }
                if (main_active_tab == null) return other_active_tab
                if (other_active_tab == null) return main_active_tab
                if (main_active_tab.state.last_activation > other_active_tab.state.last_activation) return main_active_tab
                return other_active_tab
            }),
            tab_managers
        })
    }

    // <editor-fold desc="ACTIONS">
    remove() {
        if (this.parent instanceof SplitterRegion) {
            this.parent.splitter.remove_region(this)
            this.set_parent(null)
        }
    }

    change_orientation() {
        if (this.state.split == "left_right") {
            this.state.split = "top_bottom"
        } else {
            this.state.split = "left_right"
        }
    }

    /** parent is replaced when it is useless
     * for example, when parent only contains 1 splitter, then that splitter can be replaced with current splitter
     *     Editor
     *       |
     *     Splitter A
     *     /
     * Splitter B
     *   /  \
     * TM   TM 2
     *
     * Splitter A can be replaced with Splitter B, because Splitter A does not have other
     * */
    replace_parent() {
        if (this.parent instanceof Editor) throw new Error("Cannot replace parent editor with splitter")
        const parent_splitter_region = this.parent as SplitterRegion
        const parent_splitter = parent_splitter_region.splitter;

        this.set_parent(parent_splitter.parent);

        if (this.parent instanceof SplitterRegion && this.parent.splitter.is_useless()) {
            this.replace_parent()
        }
    }

    split(how: SplitHow, keep_contents_in_main = true) {
        const new_parent_splitter = new Splitter({
            id: generate_eid(),
            parent: this.parent,
            split: how
        })

        if (keep_contents_in_main) {
            new_parent_splitter.state.other.object = new_parent_splitter._generate_tab_manager(new_parent_splitter.state.other)
            this.set_parent(new_parent_splitter.state.main)
        } else {
            new_parent_splitter.state.main.object = new_parent_splitter._generate_tab_manager(new_parent_splitter.state.main)
            this.set_parent(new_parent_splitter.state.other)
        }

        return new_parent_splitter
    }

    remove_region(region: TabManager | Splitter) {
        if (this.state.main.object?.id == region.id) {
            this.state.main.object = null
        } else if (this.state.other.object?.id == region.id) {
            this.state.other.object = null;
        }
        if (region instanceof TabManager) {
            region._emit("removed")
        } else if (region instanceof Splitter) {
            region._emit("removed")
        }
        this._emit("region_removed")
    }

    // </editor-fold>

    // <editor-fold desc="EVENT HANDLERS">
    on(event: Splitter.Events, callback: (splitter: Splitter, e: any) => void) {
        this.event_bus.$on(event, callback)
    }

    on_splitter(event: Splitter.Events, callback: (splitter: Splitter, e: Event) => void) {
        this.splitter_event_bus.$on(event, callback)
    }

    on_tab(event: Tab.Events, callback: (tab: Tab, e: Event) => void) {
        this.tab_event_bus.$on(event, callback)
    }

    on_tab_manager(event: TabManager.Events, callback: (tab_manager: TabManager, e: Event) => void) {
        this.tab_manager_event_bus.$on(event, callback)
    }

    // </editor-fold>

    // <editor-fold desc="TAB MANAGEMENT">
    add_tab(tab: Tab, to_main = true) {
        const in_single_mode = this.get_editor().is_scenario()

        if (to_main) {
            this._ensure_region_not_null(true, in_single_mode)
            this.state.main.object.add_tab(tab)
        } else {
            this._ensure_region_not_null(false, in_single_mode)
            this.state.other.object.add_tab(tab)
        }
    }

    create_tab(data: Tab.CreateInput, to_main = true): Tab {
        const in_single_mode = this.get_editor().is_scenario()
        if (to_main) {
            this._ensure_region_not_null(true, in_single_mode)
            return this.state.main.object.create_tab(data)
        } else {
            this._ensure_region_not_null(false, in_single_mode)
            return this.state.other.object.create_tab(data)
        }
    }

    find_tab(id: string): Tab {
        let found;
        found = this.state?.main?.object?.find_tab(id);
        if (found) return found

        found = this.state?.other?.object?.find_tab(id);
        if (found) return found

        return null
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    get_html_element() {
        return document.getElementById(this.id)
    }

    get_width() {
        return this.get_html_element()?.getBoundingClientRect()?.width
    }

    get_or_create_tab_manager(in_area: "main" | "other" | "any"): TabManager {
        // only for first, allow other, all nested splitter should look in any
        if (in_area == "any") {
            return this._find_a_tab_manager()
        } else {
            const other = this.state[in_area].object
            const parent_region = in_area == "other" ? this.state.other : this.state.main
            if (other == null) {
                parent_region.object = this._generate_tab_manager(parent_region);
                return parent_region.object as TabManager
            }
            if (other instanceof TabManager) return this.state[in_area].object as TabManager
            else return (other as Splitter)._find_a_tab_manager()
        }
    }

    set_parent(parent: Editor | SplitterRegion) {
        if (this.parent != null) {
            if (this.parent instanceof Editor && this.parent.state.splitter.id == this.id) {
                this.parent.state.splitter = null;
            } else if (this.parent instanceof SplitterRegion) {
                if (this.parent.object != null && this.parent.object.id == this.id) {
                    this.parent.object = null
                }
            }
        }
        if (parent != null) {
            if (parent instanceof Editor) {
                parent.state.splitter = this
            } else if (parent instanceof SplitterRegion) {
                parent.object = this;
            }
        }
        this.parent = parent
    }

    is_useless() {
        // either main or other must have a tab manager
        // or both should have splitter
        if (this.state.main.object instanceof TabManager || this.state.other.object instanceof TabManager) return false
        // noinspection RedundantIfStatementJS
        if (this.state.main.object instanceof Splitter && this.state.other.object instanceof Splitter) return false

        return true
    }

    get_editor(): Editor {
        if (this.parent == null) return null;
        if (this.parent instanceof Editor) return this.parent
        else if (this.parent instanceof SplitterRegion) return this.parent.get_editor()
    }

    get_tab_managers(): TabManager[] {
        return this.computed.tab_managers
    }

    // </editor-fold>

    // <editor-fold desc="STATE MANAGEMENT">
    set_drag_over(state: boolean) {
        this.state.is_drag_over = state;
    }

    set_resizable_flex(rflex: ResizableFlex) {
        this.resizable_flex = rflex
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _ensure_region_not_null(main = true, single_mode: boolean = false) {
        if (main && this.state.main.object == null) this.state.main.object = this._generate_tab_manager(this.state.main, single_mode)
        if (!main && this.state.other.object == null) this.state.other.object = this._generate_tab_manager(this.state.other, single_mode);
    }

    _generate_tab_manager(parent: SplitterRegion, single_mode: boolean = false) {
        return new TabManager({
            id: generate_eid(),
            parent,
            single_mode,
            position: FeatureFlag.is_enabled("tab-manager-tabs-position-right", current.user, current.project_version, "When enabled, editor tabs will be placed on the right side.") ? "right" : "top"
        })
    }

    _find_a_tab_manager(): TabManager {
        if (this.state.main.object instanceof TabManager) return this.state.main.object
        if (this.state.other.object instanceof TabManager) return this.state.other.object
        let found = (this.state.main.object as Splitter)._find_a_tab_manager()
        if (found != null) return found
        found = (this.state.other.object as Splitter)._find_a_tab_manager()
        if (found != null) return found
        return null
    }

    _emit(event: Splitter.Events, e: any = null) {
        this.event_bus.$emit(event, this, e)
        let editor: Editor;
        let splitter: Splitter;
        if (this.parent instanceof Editor) {
            editor = this.parent
        } else {
            splitter = this.parent?.splitter
        }

        while (splitter != null) {
            splitter.splitter_event_bus.$emit(event, this, e);
            const parent = splitter.parent
            if (parent instanceof Editor) {
                splitter = null
                editor = parent
            }
            splitter = (parent as SplitterRegion).splitter
        }
        editor?.splitter_event_bus?.$emit(event, this, e);
    }

    _on_region_removed() {
        // move other to main if only other is left
        if (this.state.main.object == null && this.state.other.object != null) {
            this.state.main = this.state.other
            this.state.main.is_main = true;
            this.state.other = new SplitterRegion({
                id: generate_eid(),
                splitter: this,
                is_main: false,
                object: null
            })
        }

        // if both main and other are removed, also remove this splitter
        if (this.state.main.object == null && this.state.other?.object == null) {
            this.remove();
        }

        // check if parent is splitter,that has only this splitter --> parent is useless
        if (this.parent instanceof SplitterRegion && this.parent.splitter.is_useless()) {
            this.replace_parent();
        }
    }

    _on_drag_enter(_splitter: Splitter, e: DragEvent) {
        e.stopImmediatePropagation()
        this.set_drag_over(true)
    }

    _on_drag_over(_splitter: Splitter, e: DragEvent) {
        this.set_drag_over(true)
        e.preventDefault();
        e.stopImmediatePropagation()
    }

    _on_drag_leave(_splitter: Splitter, e: DragEvent) {
        // this.set_drag_over(false)
        e.stopImmediatePropagation()
    }

    _on_drag_drop(_splitter: Splitter, _e: DragEvent) {
        this.set_drag_over(false)
        if (dnd.state.tabs.length > 0) {
            dnd.state.tabs.forEach(t => this.add_tab(t as Tab))
        } else {
            dnd.state.records.forEach(t => {
                switch (t.constructor.resource_id) {
                    case Enum.Resource.Id.SNIPPET:
                    case Enum.Resource.Id.GROUP:
                    case Enum.Resource.Id.FILE:
                    case Enum.Resource.Id.IMAGE:
                    case Enum.Resource.Id.PLAY:
                        (t as Snippet | Group | File | Image | Play).open({
                            splitter: this
                        })
                        break;
                }
            })
        }
    }

    _register_default_event_handlers() {
        this.on("region_removed", this._on_region_removed.bind(this))
        this.on("dragenter", this._on_drag_enter.bind(this))
        this.on("dragover", this._on_drag_over.bind(this))
        this.on("dragleave", this._on_drag_leave.bind(this))
        this.on("drop", this._on_drag_drop.bind(this))
    }

    // </editor-fold>
}
