import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { MobileProvisioning } from "../models/mobile_provisioning";
import { MobileProvisioningScope } from "../scopes/mobile_provisioning_scope";
import { file_upload } from "../../helpers/generic/file_upload";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { MobileProvisioningProps } from "../models/mobile_provisioning";
import { MobileProvisioningCreateProps } from "../models/mobile_provisioning";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")

export class MobileProvisioningClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof MobileProvisioning
    declare record: MobileProvisioning

    static index() {
        type Response = MobileProvisioningProps[]
        return new Promise<MobileProvisioningScope>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/mobile_provisionings`,
                type: "GET",
                data: {
                    project_id: current.project.props.id,
                },
                success: (data: Response) => {
                    const mobile_provisions: MobileProvisioning[] = []
                    data.forEach(props => {
                        mobile_provisions.push(MobileProvisioning.new(props))
                    })
                    resolve(MobileProvisioning.to_scope(mobile_provisions))
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static upload(props: MobileProvisioningCreateProps) {
        return new Promise<void>((resolve, reject) => {
            file_upload({
                    url: `/settings/project_settings/mobile_provisionings`,
                    formData: {
                        mobile_provisioning: JSON.stringify(props),
                    },
                    progressall: (e, data) => {
                        progress_bar.go(data.loaded / data.total * 100);
                    },
                    fail: (e, data) => {
                        reject(new Error(data.jqXHR.responseJSON.message))
                    },
                    done: () => resolve(),
                },
                ".mobileprovision")
        });
    }

    download() {
        return $.fileDownload(
            `/settings/project_settings/mobile_provisionings/${this.key()}/download`,
        )
    }

    static delete(ids: number | number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/mobile_provisionings/destroy`,
                type: "DELETE",
                data: {
                    ids,
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }
}
