<template>
  <Modal
      id="create_variable_set_modal"
      confirm_text="Create"
      :show_cancel="true"
      :focus_confirm="false"
      :cancel_on_escape="true"
      :form_validator="form_validator"
      :focus_last_active_on_unmount="true"
      :confirm_action="create_variable_set"
      @cancel="$emit('exit')"
  >
    <template #header>
      <div>Create Variable Set</div>
    </template>
    <template #body="modal">
      <div class="flex-row">
        <div class="flex-col s12">
          <Input
              id="create_variable_set_name_input"
              v-model="variable_set_props.name"
              label="Name"
              :focus="true"
              :validator="form_validator.register('name', VariableSet.field_validators.name)"
              @enter="modal.confirm()"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Input from "../../../testa/Input.vue";
import { PropType } from "vue";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { VariableSet } from "../../../../vue_record/models/variable_set";

export default defineComponent({
    components: {
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['exit', 'submit'],
    // <editor-fold desc="DATA">
    data() {
        return {
            form_validator: new FormValidator(),
            variable_set_props: {
                name: "",
                project_version_id: this.project_version.props.id
            },
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        VariableSet() {
            return VariableSet
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        create_variable_set() {
            return VariableSet.ClientClass.create(this.variable_set_props).then(variable_set => {
                this.$emit('exit', variable_set)
            })
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
