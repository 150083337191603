<template>
  <Modal
      id="snippet_resolve_sync_conflict"
      :dismissible="true"
      :show_confirm="false"
      :padding="0"
      :focus_last_active_on_unmount="true"
      :cancel_on_escape="true"
      @cancel="cancel"
  >
    <template #body>
      <div class="container">
        <SnippetDiffThreePane
            :remote_code="snippet.props.code"
            :result_code="snippet.state.code_before_conflict"
            :snippet="snippet"
            @applied="cancel"
        />
      </div>
    </template>
  </Modal>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../Modal.vue";
import SnippetDiffThreePane from "./SnippetDiffThreePane.vue";
import { PropType } from "vue";
import { Snippet } from "../../../../../vue_record/models/snippet";

export default defineComponent({
    components: { SnippetDiffThreePane, Modal },
    // <editor-fold desc="PROPS">
    props: {
        snippet: {
            type: Object as PropType<Snippet>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        cancel() {
            this.$.appContext.app.unmount();
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  width: 90vw;
  height: 90vh;
  max-width: 90vw;
  max-height: 90vh;
  resize: both;
  overflow: auto;
}
</style>
