<template>
  <div
      ref="vue_table_container"
      class="vue-table-container">
    <div
        ref="vue_thead_container"
        class="vue-thead-container"
        @wheel.passive="on_thead_scroll"
        @scroll="on_thead_scroll"
    >
      <div class="vue-thead"
           :class="{'underline-header': underline_header}">
        <slot name="thead"/>
      </div>
    </div>
    <div class="vue-table-scroll"
         @wheel.passive="on_table_scroll"
         @scroll="on_table_scroll"
    >
      <table
          :id="id"
          ref="table"
          class="vue-table"
      >
        <thead>
          <slot name="thead"/>
        </thead>
        <tbody ref="tbody">
          <slot name="tbody"/>
        </tbody>
        <tfoot>
          <slot name="tfoot"/>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { AllMightyObserver } from "../../helpers/dom/all_mighty_observer";

export default defineComponent({
    components: {},
    slot_observer: {} as { [key: string]: MutationObserver },

    props: {
        underline_header: {
            type: Boolean,
            required: false,
            default: true,
        },
        id: {
            type: String,
            required: false,
            default: "",
        }
    },
    data() {
        return {
            instance_id: generate_uuid(),
            thead_height: 0,
            amo: null as AllMightyObserver
        }
    },
    computed: {},
    mounted() {
        this.set_th_width();

        this.amo = AllMightyObserver.new({
            resize: true,
            element_resize: true,
            element_visible: true,
            target_element: this.$refs.vue_table_container as HTMLElement,
            callback: () => this.set_th_width(),
        })


        this.$options.slot_observer[this.instance_id] = new MutationObserver(() => {
            this.set_th_width();
        })
        this.$options.slot_observer[this.instance_id].observe(this.$refs.tbody as HTMLElement, {
            attributes: false,
            childList: true,
            characterData: true,
            subtree: false,
        })
    },
    unmounted() {
        this.amo?.stop()
    },
    methods: {
        set_th_width() {
            const $table = $(this.$refs.table as HTMLTableElement)
            const $thead_container = $(this.$refs.vue_thead_container as HTMLElement)

            // access the invisible thead that regulates td width in the actual table
            const $fake_thead_tr = $table.find('thead tr')
            const $bodyCells = $fake_thead_tr.children()
            const $thead_tr = $thead_container.find('.vue-thead tr')
            const $theadCells = $thead_tr.children();

            const num_of_cells = Math.min($bodyCells.length, $theadCells.length);
            const tr_width = $fake_thead_tr.width()
            $thead_tr.width(tr_width)
            $thead_tr.parent().width(tr_width)
            for (let i = 0; i < num_of_cells; ++i) {
                const td_width = $($bodyCells[i]).width();
                $($theadCells[i]).width(td_width)
            }
        },
        on_thead_scroll() {
            const thead = this.$refs.vue_thead_container as HTMLElement
            (this.$refs.table as HTMLElement).parentElement.scrollLeft = thead.scrollLeft;
        },
        on_table_scroll() {
            const table_container = (this.$refs.table as HTMLElement).parentElement;
            (this.$refs.vue_thead_container as HTMLElement).scrollLeft = table_container.scrollLeft;
        }
    },
})
</script>

<style lang="scss">
// NOTE: the table is expected to be in a flex container
// therefore height/width 100% should fill the available space
// and th min-height: 0 should ensure that it does not overflow
.vue-table-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .action-width {
    width: 40px !important;
  }

  .vue-thead-container {
    position: relative;
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    flex-shrink: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .vue-thead {
    tr {
      display: inline-flex;

      th {
        flex-shrink: 0;
        position: relative;

        &.orderable {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        &.asc {
          &::before {
            content: attr(data-priority);
            position: absolute;
            right: 7px;
            top: 0;
            //border-bottom: 3px solid var(--font-color);
            //border-left: 2px solid transparent;
            //border-right: 2px solid transparent;
            border-bottom: 0.5em solid var(--font-color);
            border-left: 0.5em solid transparent;
            border-right: 0.5em solid transparent;
            font-size: 0.75em;
          }
        }

        &.desc {
          &::before {
            content: attr(data-priority);
            position: absolute;
            right: 7px;
            top: 10%;
            //border-top: 3px solid var(--font-color);
            //border-left: 2px solid transparent;
            //border-right: 2px solid transparent;
            border-top: 0.5em solid var(--font-color);
            border-left: 0.5em solid transparent;
            border-right: 0.5em solid transparent;
            font-size: 0.75em;
          }
        }


      }
    }
  }

  .vue-thead,
  thead {
    // position: absolute;
    width: 100%;

    &.underline-header {
      border-bottom: 1px solid var(--font-color)
    }

    tr {
      // display: flex;

      th {
        overflow: auto; // important to get scroll width
        vertical-align: bottom;
        padding-right: 5px;
        font-size: 14px;
        text-align: left;

        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .vue-table-scroll {
    width: 100%;
    height: 100%;
    overflow: auto;
    min-height: 0;

    .vue-table {
      width: 100%;
      border-collapse: collapse;

      thead {
        border: none;
        visibility: collapse;
        color: transparent;
        background: transparent;
        filter: opacity(0);
        pointer-events: none;
      }

      tbody {

        tr {
          // display: flex;

          &:hover {
            background-color: var(--primary-background-color);
            filter: brightness(1.2);
            border-radius: 2px;
          }

          .filter-highlight {
            background-color: var(--table-filter-highlight);
          }
        }
      }
    }
  }

}

</style>
