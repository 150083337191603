<template>
  <span @click="on_click">
    <i class="fa-solid fa-hdd"
       style="color: var(--button-blue); font-size: 0.9em;"/>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { PropType } from "vue";
import { PlayLogExtrasSnapshot } from "../../../vue_record/models/play/play_log";
import { Editor } from "../../testa/editor/editor";
import { SnapshotTab } from "../../testa/editor/tabs/snapshot_tab";
import { ScenarioSetting } from "../../../vue_record/models/scenario_setting";

export default defineComponent({
    props: {
        snapshots: {
            type: Array as PropType<Array<PlayLogExtrasSnapshot>>,
            required: true
        },
        scenario_setting: {
            type: Object as PropType<ScenarioSetting>,
            required: true
        }
    },
    methods: {
        on_click(e: MouseEvent) {
            Editor.get_main()
                  .add_tab(SnapshotTab.new({
                      id: this.snapshots[0].id,
                      scenario_setting_id: this.scenario_setting.key(),
                      snapshots: this.snapshots
                  }))
        }
    }
})
</script>

<style/>

