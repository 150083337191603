import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ScheduleUpdateProps } from "../models/schedule";
import { Schedule } from "../models/schedule";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ScheduleClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Schedule
    declare record: Schedule

    update(schedule: ScheduleUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/schedules/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    schedule,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static run_now(ids: number[]) {
        type Response = {
            play_ids: number[]
        }
        return new Promise<Response>((resolve, reject) => {
            return $.ajax({
                url: `/schedules/batch/run_now`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    ids,
                    authenticity_token,
                }),
                success: (data: Response) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }
}
