import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a4b85e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "tabindex", "disabled", "checked"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["vue-label", {'for-table': _ctx.for_table, disabled: _ctx.disabled}]),
    style: {"padding-left":"3px"},
    for: `#${_ctx.id}`,
    onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => (_ctx.toggle && _ctx.toggle(...args)), ["stop","self"]))
  }, [
    _createCommentVNode("suppress TypeScriptValidateTypes "),
    _createElementVNode("input", {
      id: _ctx.id,
      value: _ctx.modelValue,
      type: "checkbox",
      class: "vue-input",
      tabindex: _ctx.for_table ? '-1' : '0',
      disabled: _ctx.disabled ? 'disabled' : null,
      checked: _ctx.modelValue ? 'checked' : null,
      onKeydown: _cache[0] || (_cache[0] = (...args) => (_ctx.on_keydown && _ctx.on_keydown(...args)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.color_class),
      onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_ctx.toggle && _ctx.toggle(...args)), ["stop","self"]))
    }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}