import { Play } from "../../models/play/play";
import { User } from "../../models/user";
import { ProjectVersion } from "../../models/project_version";
import { PlaySetting } from "../../models/play/play_setting";
import { PlayGroupScope } from "../../scopes/play/play_group_scope";
import { PlayGroup } from "../../models/play/play_group";
import { PlayLog } from "../../models/play/play_log";
import { PlayLogScope } from "../../scopes/play/play_log_scope";
import { GroupSavepoint } from "../../models/group_savepoint";
import { ScenarioFolderSavepoint } from "../../models/scenario_folder_savepoint";
import { PlayWorkerGroupScope } from "../../scopes/play/play_worker_group_scope";
import { ScenarioSavepoint } from "../../models/scenario_savepoint";
import { PlayScenarioScope } from "../../scopes/play/play_scenario_scope";
import { DelayedJob } from "../../models/delayed_job";
import { PlayWorkerGroup } from "../../models/play/play_worker_group";
import { PlayScope } from "../../scopes/play/play_scope";
import { PlayScenario } from "../../models/play/play_scenario";
import { ScenarioSetting } from "../../models/scenario_setting";

declare module "../../models/play/play" {
    interface Play {
        user: User
        terminated_by_user: User
        project_version: ProjectVersion
        play_setting: PlaySetting
        replayed_from: Play
        replays: PlayScope
        play_groups: PlayGroupScope
        play_logs: PlayLogScope

        play_worker_groups: PlayWorkerGroupScope
        target_scenario_setting: ScenarioSetting
        target_scenario_savepoint: ScenarioSavepoint
        target_scenario_folder_savepoint: ScenarioFolderSavepoint
        target_group_savepoint: GroupSavepoint

        delayed_job: DelayedJob

        play_scenarios: PlayScenarioScope
        main_play_scenarios: PlayScenarioScope
    }
}

Play.belongs_to("user", User, "user_id")
Play.belongs_to("terminated_by_user", User, "terminated_by_user_id")
Play.belongs_to("project_version", ProjectVersion, "project_version_id")
Play.belongs_to("play_setting", PlaySetting, "play_setting_id")
Play.belongs_to("replayed_from", Play, "replay_from_play_id")
Play.belongs_to("target_scenario_setting", ScenarioSetting, "target_scenario_setting_id")
Play.belongs_to("target_scenario_savepoint", ScenarioSavepoint, "target_scenario_savepoint_id")
Play.belongs_to("target_scenario_folder_savepoint", ScenarioFolderSavepoint, "target_scenario_folder_savepoint_id")
Play.belongs_to("target_group_savepoint", GroupSavepoint, "target_group_savepoint_id")

Play.has_one("delayed_job", DelayedJob, "play_id")

// has many
Play.has_many("replays", Play, "replay_from_play_id")
Play.has_many("play_groups", PlayGroup, "play_id")
Play.has_many("play_logs", PlayLog, "play_id")
Play.has_many("play_worker_groups", PlayWorkerGroup, "play_id")

// has many through
Play.has_many_through("play_scenarios", "play_worker_groups", "play_scenarios", PlayScenario)
Play.has_many_through(
    "main_play_scenarios",
    "play_worker_groups",
    "play_scenarios",
    PlayScenario,
    "main_play_scenarios")
