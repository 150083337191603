import { VueRecordScope } from "../base/vue_record_scope";
import { BrowserDriver } from "../models/browser_driver";
import { computed } from "../../helpers/vue/computed";

export class BrowserDriverScope extends VueRecordScope {
    ['constructor']: typeof BrowserDriverScope
    static ModelClass: typeof BrowserDriver
    declare rthis: BrowserDriverScope

    geckodrivers = computed(() => this.rthis.where({ name: Enum.Browser.Driver.GECKODRIVER }))
    chromedrivers = computed(() => this.rthis.where({ name: Enum.Browser.Driver.CHROMEDRIVER }))
}
