import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, withMemo as _withMemo, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fas fa-chevron-down"
}
const _hoisted_2 = {
  key: 1,
  class: "fas fa-chevron-right"
}
const _hoisted_3 = { class: "title-item icon" }
const _hoisted_4 = { class: "title-item icon" }
const _hoisted_5 = { class: "title title-item" }
const _hoisted_6 = { class: "title-item status-container" }
const _hoisted_7 = {
  key: 0,
  class: "title-item status-screenshot"
}
const _hoisted_8 = {
  key: 0,
  class: "title-item start-time"
}
const _hoisted_9 = {
  key: 1,
  class: "title-item start-date"
}
const _hoisted_10 = ["title"]
const _hoisted_11 = { class: "action-container" }
const _hoisted_12 = {
  key: 0,
  class: "collapsed-play-scenario-logs",
  style: {"flex-direction":"row","display":"flex"}
}
const _hoisted_13 = { class: "play-scenario-logs" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PlayStatusIcon = _resolveComponent("PlayStatusIcon")
  const _component_PlayTypeIcon = _resolveComponent("PlayTypeIcon")
  const _component_ScreenshotIcon = _resolveComponent("ScreenshotIcon")
  const _component_ActionIcon = _resolveComponent("ActionIcon")
  const _component_Loading = _resolveComponent("Loading")
  const _component_PlayScenarioMobilePerformanceMetrics = _resolveComponent("PlayScenarioMobilePerformanceMetrics")
  const _component_PlayLogReport = _resolveComponent("PlayLogReport")
  const _component_ScenarioSettingInfo = _resolveComponent("ScenarioSettingInfo")
  const _component_PlayScenarioReport = _resolveComponent("PlayScenarioReport", true)
  const _component_PlaySnippetReport = _resolveComponent("PlaySnippetReport")
  const _directive_time = _resolveDirective("time")
  const _directive_datetime = _resolveDirective("datetime")
  const _directive_duration = _resolveDirective("duration")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["play-node-container", {'in-debug': _ctx.is_in_debugging()}]),
    onMousedown: _cache[12] || (_cache[12] = (...args) => (_ctx.activate_debug_scenario && _ctx.activate_debug_scenario(...args)))
  }, [
    _createElementVNode("div", {
      ref: "play_scenario",
      class: "row-container",
      style: _normalizeStyle(_ctx.row_container_style)
    }, [
      _createElementVNode("div", {
        class: "expander click-item",
        onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.toggle_expanded && _ctx.toggle_expanded(...args)), ["stop"]))
      }, [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("i", _hoisted_1))
          : (_openBlock(), _createElementBlock("i", _hoisted_2))
      ]),
      _createElementVNode("div", {
        class: "title-container click-item",
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.toggle_expanded && _ctx.toggle_expanded(...args)))
      }, [
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_PlayStatusIcon, {
            play_status: _ctx.props.status
          }, null, 8 /* PROPS */, ["play_status"])
        ]),
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_PlayTypeIcon, {
            play_type: _ctx.Enum.Play.Type.SCENARIO
          }, null, 8 /* PROPS */, ["play_type"])
        ]),
        _createElementVNode("div", {
          class: "title-item progress-container",
          style: _normalizeStyle(_ctx.progress_background())
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.props.name), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_6, [
            _createElementVNode("span", {
              class: "status-note",
              style: _normalizeStyle([`color: ${_ctx.status_color()};`, {"margin-right":"4px"}])
            }, _toDisplayString(_ctx.props.status_note), 5 /* TEXT, STYLE */)
          ]),
          (_ctx.props.status_screenshot_url)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createVNode(_component_ScreenshotIcon, {
                  url: _ctx.props.status_screenshot_url,
                  main_web_available: _ctx.main_web_available,
                  role: _ctx.role,
                  project_version_setting: _ctx.project_version_setting
                }, null, 8 /* PROPS */, ["url", "main_web_available", "role", "project_version_setting"])
              ]))
            : _createCommentVNode("v-if", true)
        ], 4 /* STYLE */),
        (_ctx.props.start_time)
          ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_8, null, 512 /* NEED_PATCH */)), [
              [_directive_time, _ctx.props.start_time]
            ])
          : _createCommentVNode("v-if", true),
        (_ctx.props.start_time && _ctx.props['main?'])
          ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_9, null, 512 /* NEED_PATCH */)), [
              [_directive_datetime, {datetime: _ctx.props.start_time, strftime: '%a, %-d %b \'%y'}]
            ])
          : _createCommentVNode("v-if", true),
        (_ctx.props.start_time)
          ? _withDirectives((_openBlock(), _createElementBlock("span", {
              key: 2,
              class: "title-item duration",
              title: _ctx.moment(_ctx.props.end_time).strftime('%H:%M:%S %a, %-d %b \'%y')
            }, null, 8 /* PROPS */, _hoisted_10)), [
              [_directive_duration, {start: _ctx.props.start_time, end: _ctx.props.end_time}]
            ])
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.props['main?'] && _ctx.has_stream())
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 0,
              icon_class: _ctx.stream_shown ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash',
              title: `Show ${_ctx.props['finished?'] ? 'video' : 'stream'}`,
              margin: "0",
              onClick: _cache[2] || (_cache[2] = $event => (_ctx.toggle_stream()))
            }, null, 8 /* PROPS */, ["icon_class", "title"]))
          : _createCommentVNode("v-if", true),
        (_ctx.props['main?'])
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 1,
              icon_class: "fa-solid fa-align-left",
              title: "Download logs",
              color_class: "blue",
              margin: "0",
              onClick: _ctx.download_logs
            }, null, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.props['main?'] && _ctx.play.is_group_type() && !_ctx.props['finished?'])
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 2,
              icon_class: "fa-solid fa-step-forward",
              title: "Skip scenario",
              margin: "0",
              onClick: _ctx.skip
            }, null, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.props['main?'] && (!_ctx.is_in_debugging() && !_ctx.props['finished?']) && !_ctx.is_single_worker)
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 3,
              icon_class: "fa-solid fa-pause",
              title: "Pause execution",
              color_class: "blue",
              margin: "0",
              onClick: _ctx.pause
            }, null, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.props['main?'] && _ctx.is_in_debugging() && !_ctx.is_single_worker)
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 4,
              icon_class: "fa-solid fa-play",
              title: "Resume execution",
              color_class: "green",
              margin: "0",
              onClick: _ctx.pause
            }, null, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true),
        (_ctx.main_web_available)
          ? (_openBlock(), _createBlock(_component_ActionIcon, {
              key: 5,
              icon_class: "fa-solid fa-bullseye",
              title: "Show scenario in sidebar",
              color_class: "var(--scenario-color)",
              margin: "0",
              onClick: _ctx.show_target
            }, null, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ])
    ], 4 /* STYLE */),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["play-content-container", {'in-debug': _ctx.is_in_debugging()}])
        }, [
          (!_ctx.data_loaded)
            ? (_openBlock(), _createBlock(_component_Loading, {
                key: 0,
                type: "three_bounce",
                size: 4
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.props['main?'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _withMemo([_ctx.props.status], () => (_openBlock(), _createElementBlock("div", {
                        style: _normalizeStyle(`color: ${_ctx.scenario_logs_collapsed_color}`),
                        onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.toggle_expanded_play_scenario_logs && _ctx.toggle_expanded_play_scenario_logs(...args)))
                      }, [
                        _createTextVNode(" ( info ) ")
                      ], 4 /* STYLE */)), _cache, 4),
                      _withDirectives(_createElementVNode("div", {
                        style: `color: pink`,
                        onClick: _cache[5] || (_cache[5] = $event => (_ctx.toggle_show_mobile_performance_metrics()))
                      }, " ( memory ) ", 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.has_performance_data]
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.props['main?'] && _ctx.data_loaded)
                  ? _withDirectives((_openBlock(), _createBlock(_component_PlayScenarioMobilePerformanceMetrics, {
                      key: 1,
                      metrics_shown: _ctx.show_mobile_performance_metrics,
                      play_scenario: _ctx.play_scenario,
                      onChartCountChanged: _cache[6] || (_cache[6] = (count) => _ctx.has_performance_data = count > 0)
                    }, null, 8 /* PROPS */, ["metrics_shown", "play_scenario"])), [
                      [_vShow, _ctx.show_mobile_performance_metrics]
                    ])
                  : _createCommentVNode("v-if", true),
                (_ctx.expanded_play_scenario_logs || _ctx.started_play_nodes.length == 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "expanded-play-scenario-logs-info-container",
                      style: {"cursor":"pointer"},
                      onClick: _cache[7] || (_cache[7] = (...args) => (_ctx.toggle_expanded_play_scenario_logs && _ctx.toggle_expanded_play_scenario_logs(...args)))
                    }, [
                      _createElementVNode("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayable_play_logs, (play_log) => {
                          return (_openBlock(), _createBlock(_component_PlayLogReport, {
                            key: play_log.props.id,
                            play: _ctx.play,
                            play_scenario: _ctx.play_scenario,
                            play_log: play_log,
                            role: _ctx.role,
                            main_web_available: _ctx.main_web_available,
                            project_version_setting: _ctx.project_version_setting,
                            onPlayLogMounted: _ctx.on_play_log_mounted
                          }, null, 8 /* PROPS */, ["play", "play_scenario", "play_log", "role", "main_web_available", "project_version_setting", "onPlayLogMounted"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _createCommentVNode("    added style=\"display: block\" because to float into to top"),
                      _createCommentVNode("    else it inherits display flex and floats to bottom :) "),
                      (_ctx.scenario_setting != null && _ctx.props['main?'] && _ctx.data_loaded)
                        ? (_openBlock(), _createBlock(_component_ScenarioSettingInfo, {
                            key: 0,
                            class: "play-scenario-info",
                            style: {"display":"block"},
                            scenario_setting: _ctx.scenario_setting,
                            play_setting: _ctx.play_setting,
                            status: _ctx.props.status
                          }, null, 8 /* PROPS */, ["scenario_setting", "play_setting", "status"]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.started_play_nodes, (play_node) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: _ctx.play_node_id(play_node)
                  }, [
                    (_ctx.play_node_is_scenario(play_node))
                      ? (_openBlock(), _createBlock(_component_PlayScenarioReport, {
                          key: 0,
                          ref_for: true,
                          ref: `play_scenario_${play_node.props.id}`,
                          play: _ctx.play,
                          play_scenario: _ctx.play_node_to_play_scenario(play_node),
                          parent_play_scenario: _ctx.play_scenario,
                          expanded: _ctx.expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] === true,
                          play_setting: _ctx.play_setting,
                          role: _ctx.role,
                          is_viewer: _ctx.is_viewer,
                          parents: _ctx.parents + 1,
                          main_web_available: _ctx.main_web_available,
                          is_single_worker: "is_single_worker",
                          onToggleExpand: () => _ctx.expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] = !_ctx.expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`],
                          onPlayLogMounted: _ctx.on_play_log_mounted,
                          onDisableStickyScroll: _cache[8] || (_cache[8] = $event => (_ctx.$emit('disable-sticky-scroll'))),
                          onPlayDebuggerMounted: _cache[9] || (_cache[9] = (play_snippet) => _ctx.$emit('play-debugger-mounted', play_snippet))
                        }, null, 8 /* PROPS */, ["play", "play_scenario", "parent_play_scenario", "expanded", "play_setting", "role", "is_viewer", "parents", "main_web_available", "onToggleExpand", "onPlayLogMounted"]))
                      : _createCommentVNode("v-if", true),
                    (!_ctx.play_node_is_scenario(play_node))
                      ? (_openBlock(), _createBlock(_component_PlaySnippetReport, {
                          key: 1,
                          ref_for: true,
                          ref: `play_snippet_${play_node.props.id}`,
                          play_scenario: _ctx.play_scenario,
                          play_snippet: _ctx.play_node_to_play_snippet(play_node),
                          play: _ctx.play,
                          main_web_available: _ctx.main_web_available,
                          project_version_setting: _ctx.project_version_setting,
                          role: _ctx.role,
                          is_viewer: _ctx.is_viewer,
                          parents: _ctx.parents + 1,
                          expanded: _ctx.expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] === true,
                          onToggleExpand: () => _ctx.expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] = !_ctx.expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`],
                          onPlayLogMounted: _ctx.on_play_log_mounted,
                          onDisableStickyScroll: _cache[10] || (_cache[10] = $event => (_ctx.$emit('disable-sticky-scroll'))),
                          onFinished: _ctx.on_finish_auto_collapse,
                          onPlayDebuggerMounted: _cache[11] || (_cache[11] = (play_snippet) => _ctx.$emit('play-debugger-mounted', play_snippet))
                        }, null, 8 /* PROPS */, ["play_scenario", "play_snippet", "play", "main_web_available", "project_version_setting", "role", "is_viewer", "parents", "expanded", "onToggleExpand", "onPlayLogMounted", "onFinished"]))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ], 64 /* STABLE_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 34 /* CLASS, NEED_HYDRATION */))
}