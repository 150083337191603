import { Proxy } from "../models/proxy";
import { Project } from "../models/project";


declare module "../models/proxy" {
    interface Proxy {
        project: Project

        // scenario_settings(): ScenarioSettingScope;
    }
}

Proxy.belongs_to("project", Project, "project_id")

// has_many
// Proxy.has_many("scenario_settings", ScenarioSetting, "proxy_id")
