import { Snippet } from "../../vue_record/models/snippet";
import { event_target_coords } from "../events/event_target_coords";

export function attach_usage_click_listener(cm: CodeMirror.Editor, snippet: Snippet) {
    const cm_set_underline = function(this: HTMLElement, e: MouseEvent) {
        if (e.ctrlKey) {
            this.style.textDecoration = "underline";
            this.style.cursor = "pointer";
        } else {
            this.style.textDecoration = "";
            this.style.cursor = "";
        }
    }

    const cm_clear_underline = function(this: HTMLElement) {
        this.style.textDecoration = "";
        this.style.cursor = "";
    }
    // @ts-ignore
    $(cm.display.wrapper).on("mouseenter mousemove", ".cm-variable-2, .cm-variable", cm_set_underline)
    // @ts-ignore
    $(cm.display.wrapper).on("mouseleave", ".cm-variable-2, .cm-variable", cm_clear_underline)

    const cm_variable_click = function(this: HTMLElement, e: JQuery.ClickEvent) {
        if (e.ctrlKey) {
            e.stopImmediatePropagation();
            e.preventDefault();

            this.style.textDecoration = "";
            this.style.cursor = "";

            const cursor = cm.getDoc().getCursor();
            const line = cursor.line;
            const range = cm.findWordAt(cursor);
            const word = cm.getRange(range.anchor, range.head);

            snippet.show_variable_usages(event_target_coords(e.originalEvent, "center", "end"), word, line)
        }
    }
    // @ts-ignore
    $(cm.display.wrapper).on("click", ".cm-variable-2, .cm-variable", cm_variable_click)
}
