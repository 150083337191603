import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { GroupsScenarioClient } from "../clients/groups_scenario_client";
import { GroupsScenarioScope } from "../scopes/groups_scenario_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { WithRequired } from "../base/utils/with_required";

// <editor-fold desc="TYPES">
export interface GroupsScenarioProps extends Props {
    id: number
    group_id: number
    scenario_id: number
    real_order: number
    created_at: Date
    updated_at: Date
    git_id: string
}
export type QuerifiedGroupsScenarioProps = QuerifyProps<GroupsScenarioProps>
export type GroupsScenarioCreateProps = Omit<GroupsScenarioProps, 'id'>
export type GroupsScenarioUpdateProps = WithRequired<Omit<Partial<GroupsScenarioProps>, "group_id" | "git_id" | "scenario_id" | "updated_at" | "created_at">, "id">

export interface GroupsScenarioState extends State {}
export interface GroupsScenarioComputed extends Computed {}
export interface GroupsScenarioStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class GroupsScenario extends VueRecord {
    ['constructor']: typeof GroupsScenario

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = GroupsScenarioClient
    static ScopeClass = GroupsScenarioScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: GroupsScenarioStaticState = reactive<GroupsScenarioStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_id", "app_type", "package"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof GroupsScenario> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof GroupsScenario>> = {}

    static field_validators: ModelValidatorOpts<GroupsScenarioProps> = {}

    static resource_name = Enum.Resource.Label.GROUPS_SCENARIO
    static resource_id = Enum.Resource.Id.GROUPS_SCENARIO
    static icon_class = "fa-solid fa-grip-vertical"
    static color = () => get_css_var("--group-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: GroupsScenarioClient
    declare props: GroupsScenarioProps;
    declare state: GroupsScenarioState;
    declare computed: GroupsScenarioComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    move_up() {
        const groups_scenarios = this.group.groups_scenarios.order("real_order", "asc", "sensitive").toArray();
        for (let i = 0; i < groups_scenarios.length; ++i) {
            if (groups_scenarios[i].key() == this.key()) {
                if (i == 0) return;
                GroupsScenario.ClientClass.batch_update([
                    {
                        id: this.key(),
                        real_order: groups_scenarios[i - 1].props.real_order
                    },
                    {
                        id: groups_scenarios[i - 1].key(),
                        real_order: this.props.real_order
                    }
                ])
                break;
            }
        }
    }

    move_down() {
        const groups_scenarios = this.group.groups_scenarios.order("real_order", "asc", "sensitive").toArray();
        for (let i = 0; i < groups_scenarios.length; ++i) {
            if (groups_scenarios[i].key() == this.key()) {
                if (i + 1 == groups_scenarios.length) return;

                GroupsScenario.ClientClass.batch_update([
                    {
                        id: this.key(),
                        real_order: groups_scenarios[i + 1].props.real_order
                    },
                    {
                        id: groups_scenarios[i + 1].key(),
                        real_order: this.props.real_order
                    }
                ])
                break;
            }
        }
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
GroupsScenario.register_resource(GroupsScenario)
GroupsScenarioClient.ModelClass = GroupsScenario
GroupsScenarioScope.ModelClass = GroupsScenario

global_event_bus.$on("after_project_version_unload", () => {
    GroupsScenario.get_scope().unload()
})


on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            GroupsScenario.unsync()
            if (project_version_id != null) GroupsScenario.sync(`/sync/project_version/${project_version_id}/groups_scenarios`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        GroupsScenario: typeof GroupsScenario
    }
}
window.GroupsScenario = GroupsScenario
// </editor-fold>

