import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { SpinnerSetting } from "../models/spinner_setting";
import { QuerifiedSpinnerSettingProps } from "../models/spinner_setting";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class SpinnerSettingClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof SpinnerSetting
    declare record: SpinnerSetting

    static update(spinner_setting: QuerifiedSpinnerSettingProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/settings/superadmin_settings/workers/save_spinner_setting',
                type: 'POST',
                data: JSON.stringify({
                    spinner_setting,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            });
        })
    }
}
