import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Xray = _resolveComponent("Xray")
  const _component_Tabs = _resolveComponent("Tabs")
  const _component_SettingsItem = _resolveComponent("SettingsItem")

  return (_openBlock(), _createBlock(_component_SettingsItem, {
    id: _ctx.setting_items.project.integrations.id,
    title: _ctx.setting_items.project.integrations.title,
    padding: "10px 0 10px 0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Tabs, {
        ref: "tabs",
        tabs: _ctx.tabs,
        round: false
      }, {
        xray: _withCtx(() => [
          _createVNode(_component_Xray, {
            form_validator: _ctx.xray_form_validator,
            project_version_setting: _ctx.project_version_setting,
            is_viewer_role: _ctx.is_viewer_role,
            is_user_role: _ctx.is_user_role,
            onSettingItemMounted: _cache[0] || (_cache[0] = (component) => _ctx.$emit('setting-item-mounted', component)),
            onSettingItemUnmounted: _cache[1] || (_cache[1] = (component) => _ctx.$emit('setting-item-unmounted', component))
          }, null, 8 /* PROPS */, ["form_validator", "project_version_setting", "is_viewer_role", "is_user_role"])
        ]),
        test_rail: _withCtx(() => []),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["tabs"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "title"]))
}