export function on_dom_visibility_change(callback: (visible: boolean) => void) {
    if (IS_WEB_WORKER) return;

    let visibilityChange: string;
    if (typeof document.hidden !== "undefined") {
        visibilityChange = "visibilitychange";
    } else { // @ts-ignore
        if (typeof document.mozHidden !== "undefined") {
            visibilityChange = "mozvisibilitychange";
        } else { // @ts-ignore
            if (typeof document.msHidden !== "undefined") {
                visibilityChange = "msvisibilitychange";
            } else { // @ts-ignore
                if (typeof document.webkitHidden !== "undefined") {
                    visibilityChange = "webkitvisibilitychange";
                }
            }
        }
    }
    // Add a listener that constantly changes the title
    document.addEventListener(visibilityChange, () => {
        callback(is_dom_visible())
    }, false);
}

export function is_dom_visible() {
    if (IS_WEB_WORKER) return false

    // Adapted slightly from Sam Dutton
    // Set name of hidden property and visibility change event
    // since some browsers only offer vendor-prefixed support

    let state: string
    if (typeof document.hidden !== "undefined") {
        state = "visibilityState";
    } else { // @ts-ignore
        if (typeof document.mozHidden !== "undefined") {
            state = "mozVisibilityState";
        } else { // @ts-ignore
            if (typeof document.msHidden !== "undefined") {
                state = "msVisibilityState";
            } else { // @ts-ignore
                if (typeof document.webkitHidden !== "undefined") {
                    state = "webkitVisibilityState";
                }
            }
        }
    }

    // @ts-ignore
    return document[state] != "hidden"
}
