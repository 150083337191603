import { VueRecordScope } from "../base/vue_record_scope";
import { Browser } from "../models/browser";
import { computed } from "../../helpers/vue/computed";

export class BrowserScope extends VueRecordScope {
    ['constructor']: typeof BrowserScope
    static ModelClass: typeof Browser
    declare rthis: BrowserScope

    is_active = computed(() => this.rthis.where({ active: true }))
}
