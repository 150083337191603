<template>
  <div ref="container"
       class="snippet-editor-container no-padded-scrollbar"
       :style="container_style"
  >
    <SnippetOtherCursors
        v-if="cm != null && !exclude_features.includes('sync_cursor')"
        :key="invalidate_cursor_key"
        :snippet="snippet"
        :cm="cm"
    />
    <div class="overlay">
      <div v-if="snippet.state.conflict_chunks.length > 0"
           class="conflicts">
        <ActionIcon
            icon_class="fa-solid fa-triangle-exclamation"
            title="Click to resolve conflicts with server"
            color_class="red"
            @click="resolve_conflicts"
        />
      </div>
    </div>
    <textarea ref="textarea"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Snippet } from "../../../../../vue_record/models/snippet";
import { PropType } from "vue";
import { generate_uuid } from "../../../../../helpers/generate/generate_uuid";
import { init_codemirror } from "../../../../../helpers/codemirror/init_codemirror";
import { attach_hint_listener } from "../../../../../helpers/codemirror/attach_hint_listener";
import { font_resize_on_wheel } from "../../../../../helpers/codemirror/font_resize_on_wheel";
import { color_changed_lines } from "../../../../../helpers/codemirror/color_changed_lines";
import { highlight_merged_code } from "../../../../../helpers/codemirror/highlight_merged_code";
import { attach_codemirror_contextmenu } from "../../../../../helpers/codemirror/attach_codemirror_contextmenu";
import { debouce_changes } from "../../../../../helpers/codemirror/debounced_changes";
import { attach_usage_click_listener } from "../../../../../helpers/codemirror/attach_usage_click_listener";
import { sync_cursor_position } from "../../../../../helpers/codemirror/sync_cursor_position";
import { add_snippet_offenses } from "../../../../../helpers/codemirror/code_lint/add_offenses";
import { handle_snippet_syntax_error } from "../../../../../helpers/codemirror/code_lint/handle_syntax_error";
import ActionIcon from "../../../ActionIcon.vue";
import { track_local_changes } from "../../../../../helpers/codemirror/track_local_changes";
import { track_not_committed } from "../../../../../helpers/codemirror/track_not_committed";
import { create_vue_app } from "../../../../../helpers/vue/create_vue_app";
import SnippetResolveSyncConflict from "./SnippetResolveSyncConflict.vue";
import SnippetOtherCursors from "./SnippetOtherCursors.vue";
import { align_chained_methods_on_change } from "../../../../../helpers/codemirror/align_chained_methods";
import { SnippetEditorActionItem } from "../../../../../vue_record/models/snippet";
import { SnippetEditorFeature } from "../../../../../vue_record/models/snippet";
import { watch_style_changes } from "../../../../../helpers/codemirror/watch_style_changes";
import { SnippetTab } from "../../tabs/snippet_tab";
import { Editor } from "../../editor";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";
import { auto_add_end } from "../../../../../helpers/codemirror/auto_add_end";
import { CSSProperties } from "vue";
import { delayed_debounce } from "../../../../../helpers/generic/delayed_debounce";
import { is_visible } from "../../../../../helpers/dom/is_visible";
import { storager_snippet_editor_font_size_key } from "../../../../../helpers/codemirror/font_resize_on_wheel";
import { set_font_size } from "../../../../../helpers/codemirror/helpers/set_font_size";

export default defineComponent({
    components: { SnippetOtherCursors, ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        snippet: {
            type: Object as PropType<Snippet>,
            required: true
        },
        tab: {
            type: Object as PropType<SnippetTab>,
            required: false,
            default: null
        },
        exclude_features: {
            type: Array as PropType<SnippetEditorFeature[]>,
            required: false,
            default: () => {
                return [] as SnippetEditorFeature[]
            }
        },
        exclude_actions: {
            type: Array as PropType<SnippetEditorActionItem[]>,
            required: false,
            default: () => {
                return [] as SnippetEditorActionItem[]
            }
        }
    },
    // </editor-fold>
    emits: ['cm'],
    // <editor-fold desc="DATA">
    data() {
        return {
            cm: null as CodeMirror.Editor,
            amo: null as AllMightyObserver,
            cursor_sync_stop_handler: null as Function,
            offenses_stop_handler: null as Function,
            syntax_error_stop_handler: null as Function,
            style_watcher_stop_handler: null as Function,
            invalidate_cursor_key: generate_uuid(),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        project_version() {
            return this.snippet.project_version
        },
        project() {
            return this.project_version.project
        },
        container_style() {
            const style: CSSProperties = {}
            if (this.tab != null) {
                style.paddingLeft = "3px"
                style.width = "calc(100% - 3px)"
            }
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'current.theme'() {
            this.cm.setOption("theme", this.current.theme)
        },
        'snippet.computed.role_is_viewer'() {
            this.cm.setOption("readOnly", this.snippet.computed.role_is_viewer)
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const textarea = this.$refs.textarea as HTMLTextAreaElement
        textarea.innerHTML = this.snippet.state.code_not_committed;
        let doc = null;
        const cms = this.snippet.state.codemirrors
        if (cms.length > 0 && !this.exclude_features.includes("doc_link")) {
            doc = cms[0].getDoc().linkedDoc({ sharedHist: true });
        }
        const gutters = [];
        if (!this.exclude_features.includes("offenses")) gutters.push("offenses")
        if (!this.exclude_features.includes("syntax_errors")) gutters.push("syntax-error")
        const options = {
            history: this.snippet.state.history_not_committed,
            readOnly: this.snippet.computed.role_is_viewer,
            gutters
        }
        const cm = init_codemirror(textarea, this.project, options)
        if (doc != null) cm.swapDoc(doc)

        this.cm = cm
        cm.setSize('100%', '100%')
        this.snippet.state.codemirrors.push(cm)

        if (!this.exclude_features.includes("hints")) {
            attach_hint_listener(cm, () => {
                const scenario_builder_tab = Editor.get_scenario_builder_tab();
                if (scenario_builder_tab != null) {
                    const scenario_builder = scenario_builder_tab.state.scenario_builder;
                    const scenarios = scenario_builder.state.scenarios;
                    const snippet_ids = scenarios.map(scenario => scenario.nodes.toArray())
                                                 .flat()
                                                 .filter(n => n.props.type == Enum.Scenario.Node.Type.SNIPPET)
                                                 .map(n => n.props.scenario_node_snippet_id);
                    snippet_ids.forEach(snippet_id => Snippet.ClientClass.load(snippet_id))

                    let content_text = ""
                    Snippet.where({ id: snippet_ids }).each(snippet => {
                        if (snippet.props.code != null) {
                            content_text += snippet.props.code
                        }
                    })
                    return content_text
                }
                return ""
            })
        }

        if (!this.exclude_features.includes("align_chained_methods")) align_chained_methods_on_change(cm);
        if (!this.exclude_features.includes("auto_add_end")) auto_add_end(cm);

        track_not_committed(cm, this.snippet)
        track_local_changes(cm, this.snippet)
        this.style_watcher_stop_handler = watch_style_changes(cm)

        if (!this.exclude_features.includes("offenses")) this.offenses_stop_handler = add_snippet_offenses(cm, this.snippet)
        if (!this.exclude_features.includes("syntax_errors")) this.syntax_error_stop_handler = handle_snippet_syntax_error(cm, this.snippet)

        const font_size = current.user.storager.get(storager_snippet_editor_font_size_key, null)
        if (font_size != null) set_font_size(cm, font_size)
        global_event_bus.$on("editor-font-resize", this.invalidate_cursor_on_font_resize)
        font_resize_on_wheel(cm, storager_snippet_editor_font_size_key, () => this.snippet.state.codemirrors)

        color_changed_lines(cm, ["merge"])
        highlight_merged_code(cm)

        this.cursor_sync_stop_handler = sync_cursor_position(cm, this.snippet)
        if (!this.exclude_features.includes("usage")) attach_usage_click_listener(cm, this.snippet)
        if (!this.exclude_features.includes("contextmenu")) {
            attach_codemirror_contextmenu(cm, (cm, e) => {
                return this.snippet._editor_contextmenu(cm, e)
            })
        }

        if (!this.exclude_features.includes("sync_cursor")) {
            cm.on("scroll", () => {
                this.invalidate_cursor_key = generate_uuid();
            })
            cm.on("change", () => {
                this.invalidate_cursor_key = generate_uuid();
            })
        }

        if (!this.exclude_features.includes("debounced_save")) {
            // after every changes event, capture current code and history
            // then set timeout to save that code and history after the delay
            // NOTE: capturing code and history after delay might be too late if user closes codemirror or tab
            debouce_changes(cm, 1000, () => {
                    const props = this.snippet._extract_codemirror_props(cm);
                    return () => {
                        this.snippet._save_codemirror_code(props)
                    }
                },
                (timeout) => {
                    this.snippet.state.autosave = timeout
                },
                ["merge"]
            )
        }

        if (!this.exclude_features.includes("keymap")) {
            cm.addKeyMap(this.snippet._editor_keymap(cm))
        }

        this.$emit("cm", cm)
        if (this.tab != null) {
            this.tab.state.cm = cm;
            this.tab.set_editor_mounted(true);
        }

        const delayed_debouce_refresh = delayed_debounce(() => {
            if (is_visible(this.$refs.container as HTMLElement)) cm.refresh();
        }, 200)

        this.amo = AllMightyObserver.new(
            {
                element_visible: true,
                after_resize: true,
                element_after_resize: true,
                target_element: this.$refs.container as HTMLElement,
                callback: () => delayed_debouce_refresh()
            }
        )
    },
    unmounted() {
        this.snippet.state.codemirrors = this.snippet.state.codemirrors.filter(cm => cm != this.cm)
        this.amo?.stop()
        if (this.cursor_sync_stop_handler != null) this.cursor_sync_stop_handler()
        if (this.offenses_stop_handler != null) this.offenses_stop_handler()
        if (this.syntax_error_stop_handler != null) this.syntax_error_stop_handler()
        if (this.style_watcher_stop_handler != null) this.style_watcher_stop_handler()
        global_event_bus.$off("editor-font-resize", this.invalidate_cursor_on_font_resize)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        resolve_conflicts() {
            create_vue_app(SnippetResolveSyncConflict, {
                snippet: this.snippet
            })
        },
        invalidate_cursor_on_font_resize() {
            this.invalidate_cursor_key = generate_uuid()
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss">
.cm-searching::selection {
  background-color: var(--button-blue) !important;
  color: pink !important;
}

.codemirror-syntax-error {
  text-decoration: underline;
  text-decoration-color: var(--button-red);
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;
}

.codemirror-offense {
  text-decoration: underline;
  text-decoration-color: gray;
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;

}

.offense_tooltip {
  z-index: 999;
  position: absolute;
  display: inline-block;
  min-width: 13em;
  /*max-width: 26em;*/
  margin: 8px;
  padding: 8px;
  font-family: inherit;
  font-size: 12px;
  list-style-type: none;
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-contrast-color);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
}

.CodeMirror-hints {
  background-color: var(--primary-background-color) !important;
}


.merged-code-background {
  background: var(--merged-code-background-color);
  border-radius: 2px;
  animation: merged-code-background 1s 1;
  -webkit-animation: merged-code-background 1s 1;
  animation-fill-mode: forwards;

  animation-delay: 1s;
  -webkit-animation-delay: 1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}

@keyframes merged-code-background {
  from {
    background: var(--merged-code-background-color);
  }
  to {
    background: transparent;
  }
}

@-webkit-keyframes merged-code-background {
  from {
    background: var(--merged-code-background-color);
  }
  to {
    background: transparent;
  }
}

.jump-to-highlight-background {
  background: var(--jump-to-highlight-background-color);
  border-radius: 2px;
  animation: jump-to-highlight-background 1s 1;
  -webkit-animation: jump-to-highlight-background 1s 1;
  animation-fill-mode: forwards;

  animation-delay: 1s;
  -webkit-animation-delay: 1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}
@keyframes jump-to-highlight-background {
  from {
    background: var(--jump-to-highlight-background-color);
  }
  to {
    background: transparent;
  }
}

@-webkit-keyframes jump-to-highlight-background {
  from {
    background: var(--jump-to-highlight-background-color);
  }
  to {
    background: transparent;
  }
}

.offense-gutter {
  color: yellow;
  font-size: 0.5em;
  cursor: pointer;
  padding-left: 0;
  margin-top: 0.8em;
  opacity: 0.3;
}

.offense-context-menu {
  font-size: 0.75em;

  .context-menu-list {
    font-size: 0.9em;
  }

  .context-menu-key {
    width: auto;
    padding-left: 5px;
    color: var(--button-green);
  }
}
</style>

<style lang="scss" scoped>
.snippet-editor-container {
  position: relative;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    right: 6px; // width of scrollbar
    top: 3px;
    z-index: 5;
  }
}
</style>
