import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { QuerifyProps } from "../base/vue_record_scope";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { nextTick } from "vue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { GroupFolderScope } from "../scopes/group_folder_scope";
import { computed } from "../../helpers/vue/computed";
import { GroupScope } from "../scopes/group_scope";
import { GroupFolderClient } from "../clients/group_folder_client";
import { Group } from "./group";

// <editor-fold desc="TYPES">
export interface GroupFolderProps extends Props {
    id: number
    project_version_id: number
    name: string
    group_folder_id: number
    level: number
    created_at: Date
    updated_at: Date
    archived: boolean
    user_id: number
    color: string
    git_id: string
}
export type QuerifiedGroupFolderProps = QuerifyProps<GroupFolderProps>
export type GroupFolderCreateProps = Omit<GroupFolderProps, 'id'>
export type GroupFolderUpdateProps = Partial<GroupFolderProps>

export interface GroupFolderState extends State {}
export interface GroupFolderComputed extends Computed {}
export interface GroupFolderStaticState extends StaticState {
    children_promises: Record<string, Promise<GroupFolderChildren>>
}


export type GroupFolderChildren = {
    groups: Group[],
    group_folders: GroupFolder[]
}
// </editor-fold>

export class GroupFolder extends VueRecord {
    ['constructor']: typeof GroupFolder

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = GroupFolderClient
    static ScopeClass = GroupFolderScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: GroupFolderStaticState = reactive<GroupFolderStaticState>({
        children_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "group_folder_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof GroupFolder> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof GroupFolder>> = {}

    static field_validators: ModelValidatorOpts<GroupFolderProps> = {}

    static resource_name = Enum.Resource.Label.GROUP_FOLDER
    static resource_id = Enum.Resource.Id.GROUP_FOLDER
    static icon_class = "fa-solid fa-folder"
    static color = () => get_css_var("--group-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: GroupFolderClient
    declare props: GroupFolderProps;
    declare state: GroupFolderState;
    declare computed: GroupFolderComputed;

    // </editor-fold>

    // <editor-fold desc="HOOKS">
    after_sync_add() {
        super.after_sync_add();

        if (this.props.user_id == current.user.key()) {
            nextTick(() => {
                const tree = TestaTree.Tree.get_project_tree()
                if (tree?.is_visible()) {
                    const node = tree.get_node_by_key(this.tree_key())
                    if (node?.is_visible()) {
                        node?.edit_start()
                    }
                }
            })
        }
    }
    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return GroupFolder.show_in_sidebar(this.props.id, this.props.project_version_id, tree);
    }

    static async show_in_sidebar(group_folder_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: number[];
        if (what_is_it(group_folder_ids) == "Array") {
            ids = group_folder_ids as number[]
        } else {
            ids = [group_folder_ids as number]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(group_folder_ids).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).forEach(group_folder_id_string => {
                    const group_folder_id = group_folder_id_string as unknown as number
                    const group_folders = promise_response[group_folder_id]
                    const keys = [
                        GroupFolder.tree_key(project_version_id),
                        ...group_folders.map(sf => sf.tree_key()),
                        GroupFolder.find(group_folder_id).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: GroupFolder.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="TREE">
    // <editor-fold desc="HOVER_ACTION">
    static _hover_action_data(project_version_id: number): TestaTree.HoverAction<GroupScope, GroupFolderScope, GroupFolder> {
        return {
            icon: {
                class: "fa-solid fa-plus"
            },
            callback: (event, node) => {
                node.set_expanded(true);
                node.show_context_menu(event, GroupFolder._tree_new_group_contextmenu_items(project_version_id).build);
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<GroupScope, GroupFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Group.ClientClass.create({
                        group_folder_id: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    GroupFolder.ClientClass.create({
                        group_folder_id: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
            }
            return keys;
        })
    }

    static _testa_tree_hotkeys(project_version_id: number) {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<GroupScope, GroupFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Group.ClientClass.create({
                        group_folder_id: null,
                        project_version_id
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    GroupFolder.ClientClass.create({
                        group_folder_id: null,
                        project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXT MENU">
    static _tree_new_group_contextmenu_items(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<GroupScope, GroupFolderScope, GroupFolder | Group>) => {
                const items: ContextMenu.Items = {}
                items.new_group = {
                    name: "Group",
                    icon: "fas fa-pencil-alt",
                    color: get_css_var("--button-white"),
                    key: "+",
                    callback: () => {
                        node.set_expanded(true);
                        Group.ClientClass.create({
                            group_folder_id: node.record?.props?.id,
                            project_version_id
                        });
                    },
                }
                items.new_group_folder = {
                    name: "Folder",
                    icon: "fa fa-folder folder",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-+`,
                    callback: () => {
                        node.set_expanded(true);
                        GroupFolder.ClientClass.create({
                            group_folder_id: node.record?.props?.id,
                            project_version_id
                        });
                    },
                }
                return items
            },
        }
    }

    static _testa_tree_contextmenu(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<GroupScope, GroupFolderScope, GroupFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: GroupFolder._tree_new_group_contextmenu_items(project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                const more_options: ContextMenu.Items = {}
                more_options.export = TestaTree.Tree.contextmenu_export_item(node)
                more_options.import = TestaTree.Tree.contextmenu_import_item(node)

                return {
                    ...items,
                    ...default_items,
                    more_options: {
                        name: "More",
                        icon: "fa-solid fa-ellipsis",
                        items: more_options
                    }
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="DND">
    _testa_tree_node_dnd(): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => true),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Group || r instanceof GroupFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const group_folders = dnd.state.records.filter(r => r instanceof GroupFolder)
                const groups = dnd.state.records.filter(r => r instanceof Group)
                GroupFolder.ClientClass.move(
                    this.props.project_version_id,
                    this.props.id,
                    group_folders.map(s => s.key()),
                    groups.map(s => s.key())
                )
            },
            on_over: (e: DragEvent) => {
                e.dataTransfer.dropEffect = "move"
            }
        }
    }

    static _testa_tree_node_dnd(project_version_id: number): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => false),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Group || r instanceof GroupFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const group_folders = dnd.state.records.filter(r => r instanceof GroupFolder)
                const groups = dnd.state.records.filter(r => r instanceof Group)
                GroupFolder.ClientClass.move(
                    project_version_id,
                    null,
                    group_folders.map(s => s.key()),
                    groups.map(s => s.key())
                )
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_node_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const group_folder_ids = nodes.map(n => n.record)
                                              .filter(r => r instanceof GroupFolder)
                                              .map(r => r.key())
                const group_ids = nodes.map(n => n.record)
                                       .filter(r => r instanceof Group)
                                       .map(r => r.key())

                if (type == "copy") {
                    GroupFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.props.id,
                        group_folder_ids,
                        group_ids
                    )
                } else if (type == "cut") {
                    GroupFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.props.id,
                        group_folder_ids,
                        group_ids
                    )
                }
            }
        }
    }

    static _testa_tree_node_clipboard(project_version_id: number): TestaTree.Clipboard {
        return {
            on_paste: (type, nodes) => {
                const group_folder_ids = nodes.map(n => n.record)
                                              .filter(r => r instanceof GroupFolder)
                                              .map(r => r.key())
                const group_ids = nodes.map(n => n.record)
                                       .filter(r => r instanceof Group)
                                       .map(r => r.key())

                if (type == "copy") {
                    GroupFolder.ClientClass.copy(
                        project_version_id,
                        null,
                        group_folder_ids,
                        group_ids
                    )
                } else if (type == "cut") {
                    GroupFolder.ClientClass.move(
                        project_version_id,
                        null,
                        group_folder_ids,
                        group_ids
                    )
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<GroupScope, GroupFolderScope, GroupFolder> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.GROUP_FOLDER,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            icon: computed(() => {
                return {
                    color: get_css_var("--group-color"),
                }
            }),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            folder: {
                open_fn: () => {
                    return this.client.children()
                },
                folder_scope: computed(() => this.group_folders.order("name")),
                file_scope: computed(() => this.groups.where({ temp: false }).order("name"))
            },
            hover_action: computed(() => GroupFolder._hover_action_data(this.props.project_version_id)),
            dnd: this._testa_tree_node_dnd(),
            contextmenu: GroupFolder._testa_tree_contextmenu(this.props.project_version_id),
            clipboard: this._testa_tree_node_clipboard(),
            hotkeys: this._testa_tree_hotkeys(),
        }
    }

    usages_testa_tree_node_data(promise: Promise<any>): TestaTree.NodeInput<GroupScope, GroupFolderScope, GroupFolder> {
        const node_data = this.testa_tree_node_data()
        promise.then((usages: { groups: Group[], group_folders: GroupFolder[]}) => {
            node_data.children = [
                ...usages.group_folders.filter(s => s.props.group_folder_id == this.key()).map(s => s.usages_testa_tree_node_data(promise)),
                ...usages.groups.filter(s => s.props.group_folder_id == this.key()).map(s => s.testa_tree_node_data())
            ]
        })
        node_data.key = `usages_${this.tree_key()}`
        node_data.is_expanded = true
        node_data.folder = {
            open_fn: () => promise,
        }
        return node_data
    }

    static testa_tree_node_data(project_version_id: number): TestaTree.NodeInput<GroupScope, GroupFolderScope, GroupFolder> {
        return {
            resource_id: Enum.Resource.Id.GROUP_FOLDER,
            folder: {
                open_fn: () => {
                    return GroupFolder.ClientClass.children(project_version_id, null)
                },
                file_scope: computed(() => Group.where({ group_folder_id: null }).where({ project_version_id }).order("name")),
                // file_scope: computed(() => Group.where({ project_version_id }).order("name")),
                folder_scope: computed(() => GroupFolder.where({ group_folder_id: null }).where({ project_version_id }).order("name"))
            },
            icon: computed(() => {
                return {
                    class: Group.icon_class,
                    color: get_css_var("--group-color"),
                    scale: 0.9,
                }
            }),
            key: this.tree_key(project_version_id),
            title: computed(() => {
                return { template: "Groups" }
            }),
            hover_action: computed(() => GroupFolder._hover_action_data(project_version_id)),
            dnd: GroupFolder._testa_tree_node_dnd(project_version_id),
            clipboard: GroupFolder._testa_tree_node_clipboard(project_version_id),
            hotkeys: GroupFolder._testa_tree_hotkeys(project_version_id),
            contextmenu: GroupFolder._testa_tree_contextmenu(project_version_id)
        }
    }

    static usages_testa_tree_node_data(project_version_id: number, promise: Promise<any>): TestaTree.NodeInput<GroupScope, GroupFolderScope, GroupFolder> {
        const node_data = GroupFolder.testa_tree_node_data(project_version_id)

        promise.then((usages: { groups: Group[], group_folders: GroupFolder[]}) => {
            node_data.children = [
                ...usages.group_folders.filter(s => s.props.group_folder_id == null).map(s => s.usages_testa_tree_node_data(promise)),
                ...usages.groups.filter(s => s.props.group_folder_id == null).map(s => s.testa_tree_node_data())
            ]
        })

        node_data.folder = {
            open_fn: () => promise
        }
        node_data.key = `usages_${this.tree_key(project_version_id)}`
        node_data.is_expanded = true
        return node_data
    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        const names: string[] = []
        let current_folder = this.parent_folder
        while (current_folder != null) {
            names.push(current_folder.name())
            current_folder = current_folder.parent_folder
        }
        if (include_name) names.push(this.name())
        return `/${names.join("/")}`
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
GroupFolder.register_resource(GroupFolder)
GroupFolderClient.ModelClass = GroupFolder
GroupFolderScope.ModelClass = GroupFolder

global_event_bus.$on("after_project_version_unload", () => {
    GroupFolder.state.children_promises = {}
    GroupFolder.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            GroupFolder.unsync()
            if (project_version_id != null) GroupFolder.sync(`/sync/project_version/${project_version_id}/group_folders`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        GroupFolder: typeof GroupFolder
    }
}
window.GroupFolder = GroupFolder
// </editor-fold>

