import { generate_uuid } from "../generate/generate_uuid";
import { Timeout } from "../generic/create_timeout";
import { create_timeout } from "../generic/create_timeout";

declare global {
    let debounced_changes: {[key: string]: Timeout}
    interface Window {
        debounced_changes: {[key: string]: Timeout}
    }
}
window.debounced_changes = {}

export function debouce_changes(cm: CodeMirror.Editor,
                                delay: number,
                                on_debounced_change_set_callback: (cm: CodeMirror.Editor) => () => void,
                                on_timeout_set: (timeout: Timeout) => void,
                                exclude_origin: string[]) {
    const id = generate_uuid();
    debounced_changes[id] = null
    cm.on("changes", (cm, changes) => {
        const origins = changes.map(c => c.origin).filter(o => !exclude_origin.includes(o))
        if (origins.length > 0) {
            debounced_changes[id]?.clear();
            debounced_changes[id] = create_timeout(on_debounced_change_set_callback(cm), delay)
            on_timeout_set(debounced_changes[id])
        }
    });
}

window.addEventListener("load", () => {
    $(window).on('unload beforeunload', function() {
        Object.values(debounced_changes)
              .filter(debounced_change => debounced_change != null)
              .forEach(debounced_change => debounced_change.trigger())
    });
});
