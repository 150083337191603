import { Tab } from "../tab";
import ImageEditor from "../editors/image/ImageEditor.vue";
import { Image } from "../../../../vue_record/models/image";
import { markRaw } from "vue";
import { reactive } from "vue";
import { WatchStopHandle } from "vue";
import CodeMirror from "codemirror";
import { watch } from "vue";
import { computed } from "../../../../helpers/vue/computed";

namespace ImageTab {
    export interface Input extends Tab.Input {
        image_path: string
        external: boolean
        project_version_id: number
    }

    export interface State extends Tab.State {
        record: Image,
        cm: CodeMirror.Editor
        key_change_watcher: WatchStopHandle
    }
}

export class ImageTab extends Tab {
    static type = "ImageTab"

    type: string
    image_path: string
    external: boolean
    project_version_id: number

    declare state: ImageTab.State

    constructor(data: ImageTab.Input) {
        super(data)
        this.type = ImageTab.type
        this.external = data.external
        this.image_path = data.image_path;
        this.project_version_id = data.project_version_id
        this.state.icon.class = "fa-solid fa-image"
        this.state.icon.color = Image.color()
        this.component = markRaw(ImageEditor);

        if (this.external) {
            this.computed.title = computed(() => "Crop Image")
            this.component_props = {
                external_image_url: this.image_path,
                tab: this,
                project_version_id: this.project_version_id
            }
            this.set_loaded(true)
        } else {
            // if title is known, set it before we load, so that tabs are sorted correctly
            const image = Image.find(this.image_path)
            if (image != null) {
                this.computed.title = computed(() => image.props.name)
            }

            this._set_and_call_load_function(() => {
                return Image.ClientClass
                            .load(this.image_path)
                            .then(image => {
                                this.state.record = image

                                this.computed.title = computed(() => image.props.name)
                                this.component_props = { image, tab: this, project_version_id: this.project_version_id }

                                this.state.key_change_watcher = watch(() => image.props.path, () => {
                                    this.id = image._image_tab_id()
                                    this.input.id = image._image_tab_id();
                                    this.image_path = image.key();
                                    this.input.image_path = image.key();
                                })

                                this.on("before_close", () => this.state.key_change_watcher())
                            })
                            .catch((error) => {
                                this._on_load_error(error)
                            })
                            .finally(() => {
                                this.set_loaded(true)
                            })
            })
        }
        this.dnd = reactive({})
    }
}

Tab.register_tab_type(ImageTab)

