<template>
  <div class="row">
    <div class="add-remove-item-container"
         :class="{padded: index == 0}">
      <ActionIcon
          v-if="index == 0"
          id="add_browser_action"
          color_class="green"
          title="Add Browser"
          icon_class="fa-solid fa-plus-circle"
          @click="() => $emit('add-browser')"
      />
      <ActionIcon
          v-else
          :id="`remove_browser_action_${index}`"
          title="Remove Browser"
          color_class="red"
          icon_class="fa-solid fa-minus-circle"
          @click="() => $emit('remove-browser')"
      />
    </div>
    <div :class="`col ${browser_type_cols}`">
      <Select2 v-model="scenario_setting_browser.type"
               dropdown_parent="#play_modal"
               :label="index == 0 ? 'Browsers' : null"
               :validator="form_validator.register(`browser_type_${index}`, {presence: true})"
      >
        <option v-for="browser_type in Enum.Browser.values"
                :key="browser_type"
                :value="browser_type">
          {{ browser_name(browser_type) }}
        </option>
      </Select2>
    </div>
    <div :class="`col ${browser_id_cols}`">
      <Select2
          v-if="!scenario_setting_browser.is_mobile || !advanced_settings"
          v-model="scenario_setting_browser.browser_id"
          dropdown_parent="#play_modal"
          :label="index == 0 ? 'Version' : null"
      >
        <option value="">
          Latest
        </option>
        <option v-for="browser in browsers[scenario_setting_browser.type]"
                :key="browser.props.id"
                :value="browser.props.id"
        >
          {{ browser.props.label }}
        </option>
      </Select2>
      <Select2
          v-else
          v-model="scenario_setting_browser.emulation_profile_name"
          dropdown_parent="#play_modal"
          :label="index == 0 ? 'Emulation profile' : null"
          :validator="form_validator.register(`browser_emulation_profile_${index}`,{presence: true, inclusion: Browser.emulation_profiles.map(p => p.name)})"
      >
        <option v-for="emulation_profile in Browser.emulation_profiles"
                :key="emulation_profile.name"
                :value="emulation_profile.name">
          {{ emulation_profile.name }}
        </option>
      </Select2>
    </div>
    <div v-if="advanced_settings"
         class="col s2"
         :class="{'first-row-label-padding': index == 0}"
    >
      <Input v-model="scenario_setting_browser.width"
             type="number"
             :label="index == 0 ? 'Width' : null"
             :min="100"
             :max="Enum.Scenario.Setting.Browser.MAX_WIDTH"
             :validator="form_validator.register(`browser_width_${index}`,{presence: true, min: 100, max: Enum.Scenario.Setting.Browser.MAX_WIDTH})"
      />
    </div>
    <div v-if="advanced_settings"
         class="col s2"
         :class="{'first-row-label-padding': index == 0}"
    >
      <Input v-model="scenario_setting_browser.height"
             type="number"
             :label="index == 0 ? 'Height' : null"
             :min="100"
             :max="Enum.Scenario.Setting.Browser.MAX_HEIGHT"
             :validator="form_validator.register(`browser_height_${index}`, {presence: true, min: 100, max: Enum.Scenario.Setting.Browser.MAX_HEIGHT})"
      />
    </div>
    <div v-if="advanced_settings"
         class="col vertical s1">
      <label v-show="advanced_settings && index == 0"
             style="font-size: 10px; text-align: center">
        Emulate
      </label>
      <ToggleIcon
          v-model="scenario_setting_browser.is_mobile"
          enabled_icon_class="fa-solid fa-mobile-alt"
          :title="scenario_setting_browser.is_mobile ? 'Disable mobile emulation' : 'Enable mobile emulation'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Select2 from "../../../testa/Select2.vue";
import ToggleIcon from "../../../testa/ToggleIcon.vue";
import Input from "../../../testa/Input.vue";
import ActionIcon from "../../../testa/ActionIcon.vue";
import { PropType } from "vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { PlayModalBrowserProps } from "../../../../vue_record/models/play/play_scenario";
import { Dictionary } from "../../../../libs/monkey_patches/array";
import { Browser } from "../../../../vue_record/models/browser";

export default defineComponent({
    components: { ActionIcon, Input, ToggleIcon, Select2 },
    // <editor-fold desc="PROPS">
    props: {
        browser_type_cols: {
            type: String,
            required: true
        },
        browser_id_cols: {
            type: String,
            required: true
        },
        advanced_settings: {
            type: Boolean,
            required: true
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        scenario_setting_browser: {
            type: Object as PropType<PlayModalBrowserProps>,
            required: true,
        },
        index: {
            type: Number,
            required: true
        },
        browsers: {
            type: Object as PropType<Dictionary<Browser[]>>,
            required: true
        }
    },
    // </editor-fold>
    emits: ["add-browser", "remove-browser"],
    // <editor-fold desc="DATA">
    data() {
        return {
            non_mobile_width: this.scenario_setting_browser.is_mobile ? null : this.scenario_setting_browser.width,
            non_mobile_height: this.scenario_setting_browser.is_mobile ? null : this.scenario_setting_browser.height,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Enum() {
            return Enum
        },
        Browser() {
            return Browser
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'scenario_setting_browser.width': {
            flush: "sync",
            handler() {
                if (!this.scenario_setting_browser.is_mobile) {
                    this.non_mobile_width = this.scenario_setting_browser.width
                }
            }
        },
        'scenario_setting_browser.height': {
            flush: "sync",
            handler() {
                if (!this.scenario_setting_browser.is_mobile) {
                    this.non_mobile_height = this.scenario_setting_browser.height
                }
            }
        },
        'scenario_setting_browser.is_mobile': {
            flush: "sync",
            handler() {
                if (!this.scenario_setting_browser.is_mobile) {
                    if (this.non_mobile_width != null) this.scenario_setting_browser.width = this.non_mobile_width
                    if (this.non_mobile_height != null) this.scenario_setting_browser.height = this.non_mobile_height
                } else {
                    this.set_size_based_on_emulation_profile()
                }
            }
        },
        'scenario_setting_browser.emulation_profile_name': {
            flush: "sync",
            handler() {
                if (this.scenario_setting_browser.is_mobile) this.set_size_based_on_emulation_profile()
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        set_size_based_on_emulation_profile() {
            const emulation_profile = Browser.emulation_profiles.find(e => e.name == this.scenario_setting_browser.emulation_profile_name)
            if (emulation_profile) {
                this.scenario_setting_browser.width = emulation_profile.viewport.x
                this.scenario_setting_browser.height = emulation_profile.viewport.y
            }
        },
        browser_name(browser_type: string) {
            switch (browser_type) {
                case Enum.Browser.FIREFOX:
                    return "Firefox";
                case Enum.Browser.CHROME:
                    return "Chrome";
                default:
                    return browser_type
            }
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
