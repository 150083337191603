import { ProjectVersion } from "../models/project_version";
import { User } from "../models/user";
import { GroupFolder } from "../models/group_folder";
import { Group } from "../models/group";
import { GroupSavepointScope } from "../scopes/group_savepoint_scope";
import { GroupSavepoint } from "../models/group_savepoint";
import { GroupsScenarioScope } from "../scopes/groups_scenario_scope";
import { GroupsScenario } from "../models/groups_scenario";


declare module "../models/group" {
    interface Group {
        user: User
        project_version: ProjectVersion
        group_folder: GroupFolder

        current_savepoint: GroupSavepoint

        group_savepoints: GroupSavepointScope
        groups_scenarios: GroupsScenarioScope
    }
}

Group.belongs_to("user", User, "user_id")
Group.belongs_to("project_version", ProjectVersion, "project_version_id")
Group.belongs_to("group_folder", GroupFolder, "group_folder_id")

Group.has_many("group_savepoints", GroupSavepoint, "group_id")
Group.has_many("groups_scenarios", GroupsScenario, "group_id")

Group.has_one("current_savepoint", GroupSavepoint, "group_id")

