<template>
  <div ref="debug_container"
       class="debug-container"
       :style="debug_container_style"
  >
    <div class="debug-action">
      <div id="frame_selector_abs_wrapper"
           class="abs-wrapper"
           style="z-index: 2">
        <div class="image-capture-and-frame-sel">
          <div class="frame-sel-container">
            <Select2
                v-if="debug_frames.length > 0"
                :id="`frame_selector_${main_play_scenario.props.id}`"
                v-model="debug_frame_id"
                dropdown_parent="#frame_selector_abs_wrapper"
                :for_report_filter="true">
              <template
                  v-for="debug_frame in debug_frames"
                  :key="debug_frame.id">
                <option :value="debug_frame.id">
                  {{ debug_frame.name }} : {{ debug_frame.error_line }}
                </option>
              </template>
            </Select2>
          </div>
          <div class="image-capture-container">
            <ActionIcon
                v-if="show_capture_image"
                icon_class="fa-solid fa-camera"
                :color_class="Image.color()"
                title="Capture image (BROWSER ONLY)"
                margin="0"
                @click="capture_image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="debug-action second">
      <div class="abs-wrapper"
           style="z-index: 1">
        <div class="submit-container">
          <ActionIcon
              icon_class="fa-solid fa-arrow-alt-circle-right"
              title="Click to execute"
              :scale="1.6"
              @click="execute_command"
          />
        </div>
      </div>
    </div>
    <div>
      <textarea
          v-once
          :id="`debug_input_${main_play_scenario.props.id}`"
          ref="debug_input"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as CodeMirror from "codemirror";
import { PropType } from "vue";
import { nextTick } from "vue";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import { DebugFrame } from "../../../../vue_record/models/play/play_snippet";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";
import { SnippetSavepoint } from "../../../../vue_record/models/snippet_savepoint";
import { Snippet } from "../../../../vue_record/models/snippet";
import { init_codemirror } from "../../../../helpers/codemirror/init_codemirror";
import { attach_hint_listener } from "../../../../helpers/codemirror/attach_hint_listener";
import ActionIcon from "../../../testa/ActionIcon.vue";
import Select2 from "../../../testa/Select2.vue";
import { send_ws } from "../../../../helpers/generic/send_ws";
import { Image } from "../../../../vue_record/models/image"
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import { auto_add_end } from "../../../../helpers/codemirror/auto_add_end";
import { align_chained_methods_on_change } from "../../../../helpers/codemirror/align_chained_methods";
import { Play } from "../../../../vue_record/models/play/play";
import { get_selected_or_line_code } from "../../../../helpers/codemirror/helpers/get_selected_or_line_code";
import { PlayTab } from "../../../testa/editor/tabs/play_tab";
import { Tab } from "../../../testa/editor/tab";

export default defineComponent({
    components: { Select2, ActionIcon },
    props: {
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true
        },
        main_play_scenario: {
            type: Object as PropType<PlayScenario>,
            required: true,
        },
        debug_frames: {
            type: Array as PropType<Array<DebugFrame>>,
            required: true,
        },
        main_web_available: {
            type: Boolean,
            required: true,
        }
    },
    emits: ['play-debugger-mounted'],
    data() {
        return {
            component_id: generate_uuid(),
            min_height: 70,
            debug_frame_id: null,
            ignore_next_debug_frame_update: false,
            cm: null as CodeMirror.Editor
            // NOTE: DO NOT! put cm as reactive, it will eat up characters and cm.refresh hardly helps
            // cm: null as CodeMirror.Editor
        }
    },
    computed: {
        debug_container_style() {
            nextTick(() => {
                // to trigger sticky if enabled
                this.$emit("play-debugger-mounted")
            })
            return { minHeight: `${this.min_height}px` }
        },
        current() {
            return current
        },
        Image() {
            return Image
        },
        show_capture_image() {
            return this.main_play_scenario.has_vnc() &&
                this.main_web_available &&
                this.project_version.project_version_setting.props.sikuli_module_enabled &&
                this.main_play_scenario.scenario_setting.browsers.count > 0
        },
        play_tab(): Tab {
            return this.main_play_scenario.plays?.first()?.get_tabs()?.first()
        }
    },
    watch: {
        debug_frames: {
            handler() {
                this.ignore_next_debug_frame_update = true
                if (this.debug_frames.length > 0) {
                    this.debug_frame_id = this.debug_frames[0].id
                } else {
                    this.debug_frame_id = null
                }
            },
            deep: true
        },
        debug_frame_id() {
            if (this.ignore_next_debug_frame_update) {
                this.ignore_next_debug_frame_update = false
                return;
            }
            if (this.main_play_scenario != null) {
                this.main_play_scenario.state.debugger_frame_id = this.debug_frame_id
            }

            if (this.debug_frame_id == null) return;

            const debug_frame = this.debug_frames.find(df => df.id == parseInt(this.debug_frame_id));
            if (debug_frame) {
                SnippetSavepoint.ClientClass.load(debug_frame.error_in_snippet_savepoint_id, false)
                                .then(snippet_savepoint => {
                                    Snippet.ClientClass.load(snippet_savepoint.props.snippet_id).then((snippet) => {
                                        const snippet_tabs = snippet.get_tabs()
                                        const play_tab_editor_id = this.play_tab?.get_editor().id
                                        if (snippet_tabs.length == 0 || snippet_tabs.every(t => t.get_editor().id != play_tab_editor_id)) {
                                            snippet.open_in_main({ jump_to: { line: parseInt(debug_frame.error_line), ch: 0 } })
                                        }
                                    })
                                })
            }
        }
    },
    mounted() {
        // @ts-ignore
        (this.$refs.debug_container as HTMLElement).main_play_scenario = this.main_play_scenario;
        this.init_debug_codemirror();
        this.$emit("play-debugger-mounted")
        if (this.main_play_scenario != null) {
            this.main_play_scenario.state.debugger_container = this.$refs.debug_container as HTMLElement
            this.main_play_scenario.state.debugger_last_activation = new Date()
        }
    },
    updated() {
        // @ts-ignore
        (this.$refs.debug_container as HTMLElement).main_play_scenario = this.main_play_scenario;
        if (this.main_play_scenario != null) {
            this.main_play_scenario.state.debugger_container = this.$refs.debug_container as HTMLElement
        }
    },
    methods: {
        capture_image() {
            send_ws(this.main_play_scenario.props.backend_ws_channel, {
                action: Enum.Play.Action.REMOTE_SCREENSHOT,
                tab_id: TAB_ID,
            })
        },
        execute_command() {
            const command = this.cm.getValue().trim();
            const main_play_scenario = this.main_play_scenario;
            let debug_frame_id = null
            if (this.$refs.frame_selector) {
                debug_frame_id = parseInt((this.$refs.frame_selector as HTMLSelectElement).value)
            }
            main_play_scenario.execute_command(command, debug_frame_id);
            this.cm.setValue('');
        },
        init_debug_codemirror() {
            this.main_play_scenario.request_execution_data();
            this.cm = init_codemirror(this.$refs.debug_input as HTMLTextAreaElement, this.project_version.project, {
                rulers: [],
                scrollbarStyle: 'null',
                styleActiveLine: false,
                scrollPastEnd: false,
                foldGutter: false,
                lineNumbers: false,
                placeholder: 'type a command here (ctrl + enter to execute, ctrl + up/down to cycle history)',
                extraKeys: {
                    'Ctrl-Space': 'autocomplete',
                },
            }) as CodeMirror.Editor
            const cm = this.cm as CodeMirror.Editor

            const ctrl_enter = () => {
                this.execute_command();
            }

            const ctrl_up = (cm: CodeMirror.Editor) => {
                const main_play_scenario = this.main_play_scenario;
                if (main_play_scenario.state.command_history.length > (main_play_scenario.state.debug_command_index + 1)) {
                    ++main_play_scenario.state.debug_command_index;
                    cm.setValue(main_play_scenario.state.command_history[main_play_scenario.state.debug_command_index]);

                    // set the cursor at the end
                    cm.getDoc().setCursor({
                        line: 0,
                        ch: cm.getDoc().getLine(0).length,
                    });
                }
            }
            const ctrl_down = (cm: CodeMirror.Editor) => {
                const main_play_scenario = this.main_play_scenario;
                if (main_play_scenario.state.debug_command_index >= 1) {
                    --main_play_scenario.state.debug_command_index;
                    cm.setValue(main_play_scenario.state.command_history[main_play_scenario.state.debug_command_index]);

                    // set the cursor at the end
                    cm.getDoc().setCursor({
                        line: 0,
                        ch: cm.getDoc().getLine(0).length,
                    });
                } else if (main_play_scenario.state.debug_command_index == 0) {
                    --main_play_scenario.state.debug_command_index;
                    cm.setValue('');
                }
            }

            let Ctrl_or_Cmd = "Ctrl"
            if (is_pc_mac) Ctrl_or_Cmd = "Cmd"
            const map: CodeMirror.KeyMap = {};
            map[`${Ctrl_or_Cmd}-Enter`] = ctrl_enter
            map[`${Ctrl_or_Cmd}-Up`] = ctrl_up
            map[`${Ctrl_or_Cmd}-Down`] = ctrl_down
            map[`${Ctrl_or_Cmd}-R`] = () => {
                Play.execute_code(get_selected_or_line_code(cm))
            }

            cm.addKeyMap(map);
            cm.on("focus", () => {
                cm.refresh()
                if (this.main_play_scenario != null) {
                    this.main_play_scenario.state.debugger_last_activation = new Date()
                }
            })

            attach_hint_listener(cm as CodeMirror.Editor, () => this.main_play_scenario.state.command_history.join("\n"))
            auto_add_end(cm)
            align_chained_methods_on_change(cm)

            // shrink and expand codemirror container based on its size
            cm.on("change", () => {
                nextTick(() => {
                    const cm_height = parseInt(cm.display.sizer.style.minHeight);
                    if (cm_height > 60) {
                        this.min_height = cm_height + 10;
                    } else {
                        this.min_height = 70;
                    }
                })
            })
        },
    },
})
</script>

<style lang="scss">
.debug-container {
  .CodeMirror-placeholder {
    padding-left: 4px;
    font-size: 0.9em;
  }
}
</style>

<style lang="scss" scoped>
$submit_button_width: 33px;

.debug-container {
  width: calc(100% - $submit_button_width);
  height: 100%;
  min-height: 50px;
  padding-right: $submit_button_width;
  border-top: 1px solid var(--font-color-secondary);
}

.debug-action {
  $debug-actions-width: 300px;
  position: relative;
  float: right;
  margin-top: -22px;
  padding-right: $debug-actions-width;
  margin-right: -$submit_button_width;

  &.second {
    margin-top: 0;
  }

  .abs-wrapper {
    position: absolute;
    width: $debug-actions-width;
    text-align: right;
    z-index: 11;
    height: 0;
    margin-top: -5px;

    .image-capture-and-frame-sel {
      display: flex;
      flex-direction: row;
      justify-content: end;

      .image-capture-container {

      }

      .frame-sel-container {
        padding: 1px 6px 1px 6px;
        max-width: $debug-actions-width;
        float: right;
        width: 100px;

        select {
          height: 20px;
          font-size: 10px;
          max-width: calc($debug-actions-width - 12px);
          background: var(--primary-background-color);
        }
      }
    }


    .submit-container {
      display: flex;
      float: right;
      align-items: center;
      justify-content: right;
      cursor: pointer;
    }
  }

}
</style>
