import { VueRecordScope } from "../base/vue_record_scope";
import { ProjectVersion } from "../models/project_version";
import { computed } from "../../helpers/vue/computed";

export class ProjectVersionScope extends VueRecordScope {
    ['constructor']: typeof ProjectVersionScope
    static ModelClass: typeof ProjectVersion
    declare rthis: ProjectVersionScope

    main = computed(() => this.rthis.where({ main: true }).first())
}
