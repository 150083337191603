<template>
  <span
      class="play-type-icon-container"
      :title="title"
  >
    <i
        :class="icon_class"
        :style="style"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { CSSProperties } from "vue";
import { EnumPlayType } from "../../../auto_generated/enums";
import { PlayType } from "../../../helpers/play/play_type";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        play_type: {
            type: String as PropType<EnumPlayType | "Snippet">,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        icon_class() {
            return PlayType.icon_class(this.play_type)
        },
        title() {
            return PlayType.label(this.play_type)
        },
        style() {
            const style: CSSProperties = {}
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.play-type-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
}
</style>
