import { createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c14d58"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "double-bounce"
}
const _hoisted_2 = {
  key: 2,
  class: "wave"
}
const _hoisted_3 = {
  key: 3,
  class: "wandering-cubes"
}
const _hoisted_4 = {
  key: 5,
  class: "chasing-dots"
}
const _hoisted_5 = {
  key: 6,
  class: "three-bounce"
}
const _hoisted_6 = {
  key: 7,
  class: "circle-bounce"
}
const _hoisted_7 = {
  key: 8,
  class: "cube-grid"
}
const _hoisted_8 = {
  key: 9,
  class: "fading-circle"
}
const _hoisted_9 = {
  key: 10,
  class: "folding-cube"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "loading_wrapper",
    class: _normalizeClass(["loading-wrapper", {inflate: _ctx.inflate, fadein: _ctx.fadein}]),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createCommentVNode("<editor-fold desc=\"rotating_plane\">"),
    (_ctx.type == 'rotating_plane')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["rotating-plane", _ctx.color_class])
        }, null, 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"double_bounce\">"),
    (_ctx.type == 'double_bounce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["child double-bounce-1", _ctx.color_class])
          }, null, 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["child double-bounce-2", _ctx.color_class])
          }, null, 2 /* CLASS */)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"wave\">"),
    (_ctx.type == 'wave')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: _normalizeClass(`rect-${i} rect ${_ctx.color_class}`)
            }, null, 2 /* CLASS */)
          }), 64 /* STABLE_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"wandering_cubes\">"),
    (_ctx.type == 'wandering_cubes')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["cube cube-1", _ctx.color_class])
          }, null, 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["cube cube-2", _ctx.color_class])
          }, null, 2 /* CLASS */)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"spinner_pulse\">"),
    (_ctx.type == 'spinner_pulse')
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["spinner spinner-pulse", _ctx.color_class])
        }, null, 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"chasing_dots\">"),
    (_ctx.type == 'chasing_dots')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["child dot-1", _ctx.color_class])
          }, null, 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["child dot-2", _ctx.color_class])
          }, null, 2 /* CLASS */)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"three_bounce\">"),
    (_ctx.type == 'three_bounce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: _normalizeClass(`bounce-${i} child ${_ctx.color_class}`)
            }, null, 2 /* CLASS */)
          }), 64 /* STABLE_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"circle_bounce\">"),
    (_ctx.type == 'circle_bounce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: _normalizeClass(`circle-${i} child ${_ctx.color_class_before}`)
            }, null, 2 /* CLASS */)
          }), 64 /* STABLE_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"cube_grid\">"),
    (_ctx.type == 'cube_grid')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: _normalizeClass(`cube-${i} cube ${_ctx.color_class}`)
            }, null, 2 /* CLASS */)
          }), 64 /* STABLE_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"fading_circle\">"),
    (_ctx.type == 'fading_circle')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: _normalizeClass(`circle-${i} circle ${_ctx.color_class_before}`)
            }, null, 2 /* CLASS */)
          }), 64 /* STABLE_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>"),
    _createCommentVNode("<editor-fold desc=\"folding_cube\">"),
    (_ctx.type == 'folding_cube')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", {
            class: _normalizeClass(["cube cube-1", _ctx.color_class_before])
          }, null, 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["cube cube-2", _ctx.color_class_before])
          }, null, 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["cube cube-4", _ctx.color_class_before])
          }, null, 2 /* CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["cube cube-3", _ctx.color_class_before])
          }, null, 2 /* CLASS */)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("</editor-fold>")
  ], 6 /* CLASS, STYLE */))
}