import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { File as TestaFile } from "../models/file"
import _ from "lodash";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { FileFolderProps } from "../models/file_folder";
import { FileFolder } from "../models/file_folder";
import { FileProps } from "../models/file";
import { FileCreateProps } from "../models/file";
import { FileUpdateProps } from "../models/file";
import { CacheStoreValue } from "../base/vue_record_client";
import { App } from "../models/app";

const console = new Consoler("warn")
export class FileClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof TestaFile
    declare record: TestaFile

    static load(path: string | number, _reload = false): Promise<TestaFile> {
        return new Promise<TestaFile>((resolve, reject) => {
            $.ajax({
                url: `/files/${path}`,
                type: "GET",
                data: {
                    project_version_id: current.project_version.key(),
                },
                statusCode: ajax_status_codes,
                success: (data: FileProps) => {
                    const file = TestaFile.new(data)
                    resolve(file)
                },
                error: (error) => {
                    reject(error)
                },
            })
        });
    }

    static create(file: FileCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/files`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    file,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/files/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id: this.record.props.project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(file: FileUpdateProps) {
        type FileUpdateResponse = {
            updated: boolean
            reason?: string
            file_props?: FileProps
        }
        file.project_version_id = this.record.props.project_version_id;

        return new Promise<FileUpdateResponse>((resolve, reject) => {
            const code_handlers = _.cloneDeep(ajax_status_codes)
            delete code_handlers[400]
            return $.ajax({
                url: `/files/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    file,
                }),
                statusCode: code_handlers,
                success: (data: FileUpdateResponse) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
            }) as JQuery.jqXHR<FileUpdateResponse>
        })
    }

    static batch_path(file_paths: string | string[]) {
        let paths: string[];
        if (what_is_it(file_paths) == "Array") {
            paths = file_paths as string[]
        } else {
            paths = [file_paths as string]
        }

        type PathResponse = {
            [key: string]: FileFolderProps[]
        }

        type PromiseResponse = {
            [key: string]: FileFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/files/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    paths,
                    authenticity_token,
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(file_path => {
                        const file_folders_props = data[file_path];
                        const array: FileFolder[] = []
                        file_folders_props.forEach(file_folder_props => {
                            array.push(FileFolder.new(file_folder_props))
                        })
                        promise_response[file_path] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static batch_download(project_version_id: number, file_folders: FileFolder[], files: TestaFile[]) {
        const search_params = new URLSearchParams({
            project_version_id: project_version_id.toString(),
            file_paths: JSON.stringify(files.map(f => f.props.path)),
            file_folder_paths: JSON.stringify(file_folders.map(ff => ff.props.path)),
        })

        return $.fileDownload(`/files/batch/download?${search_params.toString()}`)
    }

    download() {
        return $.fileDownload(this.record.computed.download_path)
    }

    static upload(project_version_id: number, file_folder_path: string, file: File, index: number) {
        TestaFile.state.upload_progresses.push(0);
        const formData = new FormData();
        formData.append("authenticity_token", authenticity_token)
        formData.append("file", file)
        formData.append("project_version_id", project_version_id.toString())
        formData.append("file_folder_path", file_folder_path == null ? "" : file_folder_path)
        $.ajax({
            url: "/files/upload",
            data: formData,
            type: "POST",
            contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
            processData: false, // NEEDED, DON'T OMIT THIS
            xhr: function() {
                const xhr = new window.XMLHttpRequest();
                xhr.addEventListener("load", function() {
                    $("#import_resources_input").val("");
                })
                xhr.addEventListener("error", function() {
                    $("#import_resources_input").val("");
                })
                // noinspection DuplicatedCode
                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable) {
                        TestaFile.state.upload_progresses[index] = (evt.loaded / evt.total) * 100;
                        const summed_progress = TestaFile.state.upload_progresses.reduce((a, b) => a + b)
                        const progress = summed_progress / (TestaFile.state.upload_progresses.length * 100)
                        progress_bar.go(progress * 100)
                    }
                }, false);
                return xhr;
            },
            statusCode: ajax_status_codes
        });
    }
}
