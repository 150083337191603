<template>
  <Modal
      id="rename_variable_set_modal"
      confirm_text="Rename"
      :show_cancel="true"
      :focus_confirm="false"
      :cancel_on_escape="true"
      :form_validator="form_validator"
      :focus_last_active_on_unmount="true"
      :confirm_action="rename_variable_set"
      @cancel="$emit('exit')"
  >
    <template #header>
      <div>Rename Variable Set</div>
    </template>
    <template #body="modal">
      <div class="flex-row">
        <div class="flex-col s12">
          <Input
              id="create_variable_set_name_input"
              v-model="variable_set_props.name"
              label="Name"
              :focus="true"
              :validator="form_validator.register('name', VariableSet.field_validators.name)"
              @enter="modal.confirm()"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Input from "../../../testa/Input.vue";
import { PropType } from "vue";
import { VariableSet } from "../../../../vue_record/models/variable_set";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        variable_set: {
            type: Object as PropType<VariableSet>,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['exit'],
    // <editor-fold desc="DATA">
    data() {
        return {
            form_validator: new FormValidator(),
            variable_set_props: {
                name: this.variable_set.props.name,
            },
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        VariableSet() {
            return VariableSet
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        rename_variable_set() {
            return this.variable_set
                       .client
                       .update(this.variable_set_props)
                       .then(() => {
                           this.$emit('exit')
                       })
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
