<template>
  <SettingsItem
      :id="`edit_project_version_${project_version.props.version}`"
      :title="`Edit ${project_version.props.version}`">
    <div id="project_version_edit"
         class="project-version-edit">
      <div class="header">
        <Button
            text="Back"
            @click="back"
        />
        <Button
            v-if="!is_viewer_role && !is_user_role"
            text="Save"
            :form_validator="form_validator"
            :click_action="save"
        />
        <div class="flex-expander"/>
        <Button
            v-if="!is_viewer_role && !is_user_role"
            text="Delete"
            color_class="red"
            @click="delete_project_version"
        />
      </div>
      <div class="body">
        <div class="row">
          <div class="col s6">
            <Input id="project_version_name"
                   v-model="update_props.version"
                   label="Name"
                   :disabled="is_viewer_role || is_user_role"
                   :validator="form_validator.register('project_version_name', ProjectVersion.field_validators.version)"
            />
          </div>

          <div class="col s6">
            <Checkbox
                v-model="update_props.main"
                label="Is main"
                :disabled="project_version.props.main || is_viewer_role || is_user_role"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s6">
            <Input id="project_version_merge_approvals_required"
                   v-model="update_props.merge_approvals_required"
                   label="Merge Approvals"
                   :min="0"
                   type="number"
                   :disabled="is_viewer_role || is_user_role"
                   :validator="form_validator.register('project_version_merge_approvals_required', ProjectVersion.field_validators.merge_approvals_required)"
            />
          </div>
          <div v-if="users.length > 1"
               class="col s6">
            <Select2
                id="project_version_visibility"
                v-model="update_props.user_id"
                :disabled="is_viewer_role || is_user_role"
                dropdown_parent="#project_version_edit"
                label="Visibility">
              <option value="">Public</option>
              <template
                  v-for="user in users"
                  :key="user.props.id"
              >
                <option :value="user.props.id">{{ user.props.email }}</option>
              </template>
            </Select2>
          </div>
        </div>
      </div>
    </div>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import Button from "../../../testa/Button.vue";
import Input from "../../../testa/Input.vue";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import Checkbox from "../../../testa/Checkbox.vue";
import Select2 from "../../../testa/Select2.vue";
import SettingsItem from "../../SettingsItem.vue";
import toastr from "toastr";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { QuerifiedProjectVersionProps } from "../../../../vue_record/models/project_version";

export default defineComponent({
    components: {
        SettingsItem,
        Select2,
        Checkbox,
        Input,
        Button,
    },
    props: {
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['exit'],
    data() {
        return {
            update_props: {
                id: this.project_version.props.id,
                version: this.project_version.props.version,
                main: this.project_version.props.main,
                is_private: this.project_version.props.is_private,
                user_id: this.project_version.props.user_id,
                merge_approvals_required: this.project_version.props.merge_approvals_required,
            } as QuerifiedProjectVersionProps,
            project: this.project_version.project,
            users: this.project_version.project.user_projects
                       .toArray().map(user_project => user_project.user),
        }
    },
    computed: {
        ProjectVersion() {
            return ProjectVersion
        },
    },
    watch: {
        'update_props.user_id'() {
            this.update_props.is_private = this.update_props.user_id == null
        },
    },
    unmounted() {
        this.form_validator.unregister()
    },
    methods: {
        save() {
            const promise = this.apply()
            $.when(promise).then(() => {
                this.back()
            })
            return promise
        },
        apply() {
            if (this.is_viewer_role || this.is_user_role) return null;

            return this.project_version
                       .client
                       .update(this.update_props)
                       .catch(() => toastr.error("Failed to save project version"))
        },
        delete_project_version() {
            this.project_version.delete().then((is_confirmed: Boolean) => {
                if (is_confirmed) this.back()
            })
        },
        back() {
            this.$emit('exit')
        },
    },
})
</script>

<style lang="scss" scoped>
.project-version-edit {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .body {
    margin-top: 10px;
  }
}

</style>
