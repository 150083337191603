import { AppiumScreenControl } from "./appium_screen_control";
import { send_ws } from "../../generic/send_ws";
import { AscAction } from "./asc_action";

export class AscSpecialAction extends AscAction {
    asc: AppiumScreenControl
    type: string

    constructor(asc: AppiumScreenControl, type: string) {
        super()
        this.asc = asc;
        this.type = type
    }

    perform() {
        const data = {
            udid: this.asc.phone.props.udid,
            action: Enum.Play.Action.SCREEN_CONTROL,
            screen_action: {
                type: this.type,
            }
        };

        send_ws(this.asc.backend_ws_channel, data)
    }
}
