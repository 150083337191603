import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c2d3b28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overlay" }
const _hoisted_2 = {
  key: 0,
  class: "conflicts"
}
const _hoisted_3 = { ref: "textarea" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SnippetOtherCursors = _resolveComponent("SnippetOtherCursors")
  const _component_ActionIcon = _resolveComponent("ActionIcon")

  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: "snippet-editor-container no-padded-scrollbar",
    style: _normalizeStyle(_ctx.container_style)
  }, [
    (_ctx.cm != null && !_ctx.exclude_features.includes('sync_cursor'))
      ? (_openBlock(), _createBlock(_component_SnippetOtherCursors, {
          key: _ctx.invalidate_cursor_key,
          snippet: _ctx.snippet,
          cm: _ctx.cm
        }, null, 8 /* PROPS */, ["snippet", "cm"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.snippet.state.conflict_chunks.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ActionIcon, {
              icon_class: "fa-solid fa-triangle-exclamation",
              title: "Click to resolve conflicts with server",
              color_class: "red",
              onClick: _ctx.resolve_conflicts
            }, null, 8 /* PROPS */, ["onClick"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("textarea", _hoisted_3, null, 512 /* NEED_PATCH */)
  ], 4 /* STYLE */))
}