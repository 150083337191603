<template>
  <template v-if="show_table">
    <SettingsItem :id="items.superadmin.feature_flags.id"
                  :title="items.superadmin.feature_flags.title">
      <div class="row"
           style="display: flex">
        <div class="col s8">
          <Button
              v-if="deletion_markers.marked().length > 0"
              id="delete_feature_flags_button"
              text="Delete"
              color_class="red"
              @click="delete_feature_flags"
          />
        </div>
        <div class="col s4">
          <Input
              id="feature_flags_filter"
              v-model="filter"
              placeholder="Filter"
              :throttle_time="200"
          />
        </div>
      </div>
      <Table
          id="feature_flags_table"
      >
        <template #thead>
          <tr>
            <template v-for="(col, key) in visible_columns"
                      :key="key">
              <th v-if="key == 'delete'"
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  @click.stop="all_marked = !all_marked">
                <Checkbox
                    v-model="all_marked"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </th>
              <th v-else
                  :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  :data-priority="orders.priority(key)"
                  @click="orders.toggle(key)">
                {{ col.name }}
              </th>
            </template>
          </tr>
        </template>

        <template #tbody>
          <tr v-for="row in ordered_rows"
              :key="row.record.props.id"
              class="feature-flag-row"
              @click="show_feature_flag(row.record)"
          >
            <template v-for="(col, index) in row.cols"
                      :key="index">
              <td v-if="col.column_key == 'delete'"
                  :class="col.classes"
                  @click.stop="deletion_markers.marker(row.record).toggle()">
                <Checkbox
                    v-model="deletion_markers.marker(row.record).value"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </td>
              <td v-else
                  v-moment="col.moment"
                  :title="col.title"
                  :class="col.classes"
                  v-html="col.html"/>
            </template>
          </tr>
        </template>
      </Table>
    </SettingsItem>
  </template>
  <template v-else>
    <FeatureFlagEdit
        :feature_flag="edit_feature_flag"
        :form_validator="form_validator.register_child_form('feature_flag_edit')"
        @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
        @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
        @exit="show_table=true"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FeatureFlag } from "../../../../vue_record/models/feature_flag";
import Table from "../../../testa/Table.vue";
import FeatureFlagEdit from "./FeatureFlagEdit.vue";
import Input from "../../../testa/Input.vue";
import SettingsItem from "../../SettingsItem.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Button from "../../../testa/Button.vue"
import { PropType } from "vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";

export default defineComponent({
    components: {
        Checkbox,
        SettingsItem,
        Input,
        FeatureFlagEdit,
        Table,
        Button,
    },
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            show_table: true,
            edit_feature_flag: null as FeatureFlag,
            filter: "",
            deletion_markers: new RecordMarker([]),
            all_marked: false,
            columns: {
                delete: {
                    filterable: false,
                    orderable: false,
                    action: true,
                    classes: "red",
                },
                name: {
                    name: "Name",
                    html: (record) => record.props.name,
                },
                enabled: {
                    name: "Enabled",
                    html: (record) => record.props.enabled.toString(),
                    classes: (record) => {
                        return { green: record.props.enabled, red: !record.props.enabled }
                    }
                },
            } as RecordColumns<FeatureFlag>,
            orders: new RecordOrder([["name", "asc"]]),
        }
    },
    computed: {
        items() {
            return setting_items
        },
        visible_columns() {
            return RecordsTable.visible_columns<FeatureFlag>(this.columns);
        },
        records() {
            return FeatureFlag.toArray()
        },
        rows() {
            return RecordsTable.generate_rows<FeatureFlag>(this.visible_columns, this.records as FeatureFlag[])
        },
        filtered_rows() {
            return RecordsTable.filter<FeatureFlag>({
                columns: this.visible_columns,
                rows: this.rows,
                filter: this.filter,
            })
        },
        ordered_rows() {
            return RecordsTable.order<FeatureFlag>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    watch: {
        'filtered_rows'() {
            this.deletion_markers.set_records(this.filtered_rows.map(c => c.record) as FeatureFlag[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
    },
    mounted() {
        FeatureFlag.is_enabled("test_feature_flag")
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        show_feature_flag(feature_flag: FeatureFlag) {
            this.edit_feature_flag = feature_flag
            this.show_table = false
        },
        delete_feature_flags() {
            FeatureFlag.delete(this.deletion_markers.marked().map(ff => ff.props.id))
        },
    },
})
</script>

<style lang="scss" scoped>

.feature-flag-row {
  cursor: pointer;

  td {
    &.green {
      color: var(--button-green);
    }

    &.red {
      color: var(--button-red);

    }
  }
}
</style>
