import { EventBus } from "../../../helpers/event_bus";
import { ComputedRef } from "vue";
import { generate_resolved_promise } from "../../../helpers/generate/generate_resolved_promise";
import { triggered } from "../../../helpers/events/keyboard_event_to_string";
import { KEY } from "../../../types/globals";
// @ts-ignore
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { multi_resource_remove } from "../../../helpers/client/core/multi_resource_remove";
import { Editor } from "../editor/editor";
import { nextTick } from "vue";
import { ScenarioBuilderTab } from "../editor/tabs/scenario_builder_tab";
import _ from "lodash";
import { clear_selection } from "../../../helpers/dom/clear_selection";
import { effectScope } from "vue";
import { export_resources } from "../../../helpers/client/core/export_resources";
import { import_resources } from "../../../helpers/client/core/import_resources";
import { EnumResourceId } from "../../../auto_generated/enums";
import { Storager } from "../../../helpers/api_wrappers/storager";
import { VueRecordScope } from "../../../vue_record/base/vue_record_scope";
import { VueRecord } from "../../../vue_record/base/vue_record";
import { reactive } from "../../../helpers/vue/reactive";
import { computed } from "../../../helpers/vue/computed";
import { ProjectVersion } from "../../../vue_record/models/project_version";
import { Scenario } from "../../../vue_record/models/scenario";
import { ScopedIdsMap } from "../../../vue_record/base/utils/to_scoped_map";
import { ScopedRecordsMap } from "../../../vue_record/base/utils/to_scoped_map";
import { to_scoped_map } from "../../../vue_record/base/utils/to_scoped_map";
import { computed_ref } from "../../../helpers/vue/computed";
import { SnippetFolder } from "../../../vue_record/models/snippet_folder";
import { ScenarioFolder } from "../../../vue_record/models/scenario_folder";
import { GroupFolder } from "../../../vue_record/models/group_folder";
import { FileFolder } from "../../../vue_record/models/file_folder";
import { ImageFolder } from "../../../vue_record/models/image_folder";
import { Phone } from "../../../vue_record/models/phone";
import { PhoneProject } from "../../../vue_record/models/phone_project";
import { App } from "../../../vue_record/models/app";
import { Schedule } from "../../../vue_record/models/schedule";
import { Play } from "../../../vue_record/models/play/play";
import { WithRequired } from "../../../vue_record/base/utils/with_required";
import { LogFile } from "../../../vue_record/models/non_db/log_file";
import { LogFileFolder } from "../../../vue_record/models/non_db/log_file_folder";

export namespace TestaTree {
    // <editor-fold desc="TREE">
    type Computed = {
        children: TestaTree.Node[]
        all_nodes: TestaTree.Node[]
        storager: Storager
    }

    export class Tree {
        key: string;
        element_id: string;
        event_bus: EventBus<any, TestaTree.Node>;
        project_version: ProjectVersion

        static trees: { [key: string]: TestaTree.Tree } = reactive({})
        static effect_scope = effectScope(true)

        static clipboard = reactive({
            type: null as "cut" | "copy",
            nodes: [] as Node[]
        })

        persist: boolean

        state = reactive({
            active_node: null as Node,
            selected_nodes: [] as Node[],
            is_editing: false,
            editing_node: null as Node,

            children: {} as Record<string, Node>,
        })

        computed: Computed = reactive({
            children: [] as TestaTree.Node[],
            all_nodes: [] as TestaTree.Node[],
            storager: null as Storager
        }) as Computed

        constructor(key: string, project_version: ProjectVersion, root_nodes: ComputedRef<NodeInput<any, any, any>[]>, persist = true) {
            this.project_version = project_version
            this.persist = persist
            TestaTree.Tree.effect_scope.run(() => {
                this.key = key
                this.element_id = `id_${this.key}`
                this.event_bus = new EventBus()
                this.computed = reactive({
                    children: computed(() => {
                        const created_nodes: Node[] = []
                        const state_children = {} as Record<string, Node>
                        root_nodes.value.forEach(data => {
                            const previous_node = this.state.children[data.key]
                            if (previous_node == null) {
                                const node = new Node(data, null, this)
                                created_nodes.push(node)
                                state_children[data.key] = node
                            } else {
                                created_nodes.push(previous_node)
                                state_children[previous_node.key] = previous_node
                            }
                        })

                        this.state.children = state_children;
                        return created_nodes
                    }),
                    all_nodes: computed<TestaTree.Node[]>(() => {
                        let nodes: TestaTree.Node[] = [...this.computed.children]
                        this.computed.children.forEach(child => {
                            nodes = nodes.concat(child.children(true))
                        })
                        return nodes
                    }),
                    storager: computed(() => {
                        return this.persist ? current.storagers.project_version.new_scope("testa_tree", this.key) : null
                    })
                })

                TestaTree.Tree.trees[this.key] = this;
                this.event_bus.$on('visit', (node: TestaTree.Node, e: MouseEvent | KeyboardEvent | DragEvent) => {
                    this.visit_node(e, node)
                });
            })
        }

        // <editor-fold desc="HELPERS">
        is_visible() {
            return $(this._get_html_element()).is(":visible")
        }

        show_active_tab() {
            const active_tab = Editor.active_tab();

            if (active_tab == null) return;
            if (active_tab instanceof ScenarioBuilderTab) {
                Scenario.show_in_sidebar(active_tab.scenario_ids, this.project_version?.key())
            } else {
                active_tab.state.record.show_in_sidebar(this);
            }
        }

        collapse_all() {
            this.folders(true).forEach(n => n.set_expanded(false))
        }

        get_scoped_nodes() {
            const selected = this.get_selected()
            return {
                snippets: selected.filter(n => n.resource_id == Enum.Resource.Id.SNIPPET),
                snippet_folders: selected.filter(n => n.resource_id == Enum.Resource.Id.SNIPPET_FOLDER),
                scenarios: selected.filter(n => n.resource_id == Enum.Resource.Id.SCENARIO),
                scenario_folders: selected.filter(n => n.resource_id == Enum.Resource.Id.SCENARIO_FOLDER),
                groups: selected.filter(n => n.resource_id == Enum.Resource.Id.GROUP),
                group_folders: selected.filter(n => n.resource_id == Enum.Resource.Id.GROUP_FOLDER),
                files: selected.filter(n => n.resource_id == Enum.Resource.Id.FILE),
                file_folders: selected.filter(n => n.resource_id == Enum.Resource.Id.FILE_FOLDER),
                images: selected.filter(n => n.resource_id == Enum.Resource.Id.IMAGE),
                image_folders: selected.filter(n => n.resource_id == Enum.Resource.Id.IMAGE_FOLDER),
                apps: selected.filter(n => n.resource_id == Enum.Resource.Id.APP),
                schedules: selected.filter(n => n.resource_id == Enum.Resource.Id.SCHEDULE),
                phones: selected.filter(n => n.resource_id == Enum.Resource.Id.PHONE),
                plays: selected.filter(n => n.resource_id == Enum.Resource.Id.PLAY),
            }
        }

        get_scoped_ids(root_folders_as_null = true) {
            const scoped_nodes = this.get_scoped_nodes();
            const scoped_ids: ScopedIdsMap = {
                snippet_ids: scoped_nodes.snippets.map(n => n.record?.key()),
                snippet_folder_ids: scoped_nodes.snippet_folders.map(n => n.record?.key()),
                scenario_ids: scoped_nodes.scenarios.map(n => n.record?.key()),
                scenario_folder_ids: scoped_nodes.scenario_folders.map(n => n.record?.key()),
                group_ids: scoped_nodes.groups.map(n => n.record?.key()),
                group_folder_ids: scoped_nodes.group_folders.map(n => n.record?.key()),
                file_paths: scoped_nodes.files.map(n => n.record?.key()),
                file_folder_paths: scoped_nodes.file_folders.map(n => n.record?.key()),
                image_paths: scoped_nodes.images.map(n => n.record?.key()),
                image_folder_paths: scoped_nodes.image_folders.map(n => n.record?.key()),
                app_ids: scoped_nodes.apps.map(n => n.record?.key()),
                schedule_ids: scoped_nodes.schedules.map(n => n.record?.key()),
                phone_udids: scoped_nodes.phones.map(n => n.record?.key()),
                play_ids: scoped_nodes.plays.map(n => n.record?.key()),
            }
            if (!root_folders_as_null) {
                for (const key in scoped_ids) {
                    // @ts-ignore
                    scoped_ids[key] = scoped_ids[key].filter(id => id != null)
                }
            }

            return scoped_ids
        }

        get_selected_records(): ScopedRecordsMap {
            const records = this.get_selected_records_array()

            return to_scoped_map(records)
        }

        get_selected_deletable_records(): ScopedRecordsMap {
            const deletable_records = this.get_selected()
                                          .filter(n => n.computed.deletable && n.record != null)
                                          .map(n => n.record)

            return to_scoped_map(deletable_records)
        }

        get_selected_records_array(): VueRecord[] {
            return this.get_selected()
                       .map(n => n.record)
                       .filter(r => r != null)
        }

        static get_node(obj: Event): TestaTree.Node {
            if ((obj.target as HTMLElement)?.id == "context-menu-layer") {
                const event = obj as MouseEvent
                const elements = [...document.elementsFromPoint(event.pageX, event.pageY)].reverse() as HTMLElement[]
                for (let i = 0; i < elements.length; ++i) {
                    if (elements[i].testa_tree_node) {
                        return (elements[i].testa_tree_node as any).node;
                    }
                }
            } else if (obj.target != null) {
                let element: HTMLElement = obj.target as HTMLElement;
                while (element) {
                    if (element.testa_tree_node) {
                        return (element.testa_tree_node as any).node;
                    }
                    element = element.parentElement
                }
            }
            return null;
        }

        get_active_node() {
            return this.state.active_node
        }

        get_active_record() {
            return this.get_active_node()?.record
        }

        get_node_by_key(key: string) {
            return this.computed.all_nodes.find(n => n.key == key)
        }

        unload() {
            delete TestaTree.Tree.trees[this.key]
        }


        async expand_paths(paths: string[][]) {
            const to_select: Node[] = []
            for (let j = 0; j < paths.length; ++j) {
                const keys = paths[j];
                const last_key = keys.pop()
                for (let i = 0; i < keys.length; ++i) {
                    await this.get_node_by_key(keys[i]).set_expanded(true)
                }

                const node = this.get_node_by_key(last_key)
                this.set_active(node)
                to_select.push(node)
                node.scroll_into_view()
            }
            this.set_selected(to_select)
        }

        open(node: Node, is_manual: boolean) {
            if (node.batch_open_fn != null) {
                node.batch_open_fn(this.get_selected())
            } else {
                this.get_selected().forEach(n => n.open(is_manual))
            }
        }

        // </editor-fold>

        // <editor-fold desc="STATIC GETTERS">
        static get_project_tree(project_version: ProjectVersion = current.project_version) {
            const id = `project_tree_${project_version?.key()}`
            const tree = TestaTree.Tree.trees[id]
            if (tree != null) return tree

            const project_version_id = project_version?.key()
            const project_version_setting = project_version?.project_version_setting

            return new TestaTree.Tree(id, project_version, computed_ref(() => {
                const root_nodes: NodeInput<any, any, any>[] = [
                    SnippetFolder.testa_tree_node_data(project_version_id),
                    ScenarioFolder.testa_tree_node_data(project_version_id),
                ]

                if (project_version_setting?.props?.group_module_enabled) {
                    root_nodes.push(GroupFolder.testa_tree_node_data(project_version_id))
                }

                if (project_version_setting?.props?.file_module_enabled) {
                    root_nodes.push(FileFolder.testa_tree_node_data(project_version_id))
                }

                if (project_version_setting?.props?.sikuli_module_enabled) {
                    root_nodes.push(ImageFolder.testa_tree_node_data(project_version_id))
                }
                return root_nodes
            }))
        }

        static get_logs_tree() {
            const id = `logs_tree`
            const tree = TestaTree.Tree.trees[id]
            if (tree != null) return tree

            return new TestaTree.Tree(id, null, computed_ref(() => {
                const root_nodes: NodeInput<any, any, any>[] = [
                    LogFile.find("/live").testa_tree_node_data(),
                    LogFileFolder.find("/backendoor").testa_tree_node_data(),
                    LogFileFolder.find("/core").testa_tree_node_data(),
                    LogFileFolder.find("/dj").testa_tree_node_data(),
                    LogFileFolder.find("/filewatcher").testa_tree_node_data(),
                    LogFileFolder.find("/spinner").testa_tree_node_data(),
                    LogFileFolder.find("/web").testa_tree_node_data(),
                    LogFileFolder.find("/ws").testa_tree_node_data(),
                ]
                return root_nodes
            }))
        }


        static get_devices_tree(project_version: ProjectVersion = current.project_version) {
            const id = `devices_tree_${project_version?.key()}`
            const tree = TestaTree.Tree.trees[id]
            if (tree != null) return tree;

            const project_id = project_version?.props?.project_id

            return new TestaTree.Tree(id, project_version, computed_ref(() => {
                const phone_types = _.sortBy(Phone.state.sidebar_types)
                if (phone_types.length < 2) {
                    let scope = PhoneProject.where({ project_id })
                                            .phones
                    if (!Phone.state.sidebar_show_offline) scope = scope.not({ status: Enum.Phone.Status.OFFLINE })
                    return scope.order("name", "asc", "insensitive").map(phone => phone.testa_tree_node_data())
                } else {
                    return phone_types.map(phone_type => Phone.testa_tree_node_data(phone_type, project_id))
                }
            }))
        }

        static get_apps_tree(project_version: ProjectVersion = current.project_version) {
            const id = `apps_tree_${project_version?.props?.id}`
            const tree = TestaTree.Tree.trees[id]
            if (tree != null) return tree;

            const project_id = project_version?.props?.project_id

            return new TestaTree.Tree(id, project_version, computed_ref(() => {
                const app_types = _.sortBy(App.state.types.map(app_type => {
                    return {
                        pretty: App.pretty_app_type_name(app_type),
                        value: app_type
                    }
                }), (obj) => obj.pretty)

                if (app_types.length == 0) return []

                if (app_types.length == 1) {
                    let app_scope = App.get_scope().where({ project_id });
                    const packages = Object.values(App.state.packages_per_type).flat().uniq().sort()

                    if (packages.length == 1) {
                        if (App.state.order_by_version) {
                            app_scope = app_scope.order("version_name", "desc", "version").order("version_code", "desc")
                        }
                        app_scope = app_scope.order("created_at", "desc", "sensitive")
                        return app_scope.toArray().map(app => app.testa_tree_node_data())
                    } else {
                        return packages.map(app_package => App.testa_tree_node_data_package(app_types[0].value, app_package, project_id))
                    }
                } else {
                    return app_types.map(app_type => App.testa_tree_node_data_app_type(app_type.value, project_id))
                }
            }))
        }

        static get_schedules_tree(project_version: ProjectVersion = current.project_version) {
            const id = Schedule.tree_key(project_version.key())
            const tree = TestaTree.Tree.trees[id]
            if (tree != null) return tree;

            return new TestaTree.Tree(id, project_version, computed_ref(() => {
                return Schedule.get_scope()
                               .where({ project_version_id: project_version?.key() })
                               .order("name", "asc")
                               .map(schedule => schedule.testa_tree_node_data())
            }))
        }

        static get_plays_tree(project_version: ProjectVersion = current.project_version) {
            const id = `plays_tree_${project_version?.key()}`
            const tree = TestaTree.Tree.trees[id]
            if (tree != null) return tree;

            return new TestaTree.Tree(id, project_version, computed_ref(() => {
                return Play.state.filter_play_scope.map(play => play.testa_tree_node_data())
            }))
        }

        // </editor-fold>

        // <editor-fold desc="EVENT HANDLERS">
        on_mousedown(e: MouseEvent) {
            if (e.button != 2) return;
            const node = Tree.get_node(e);
            if (node) {
                node.show_context_menu(e)
            }
        }

        on_keydown(e: KeyboardEvent) {
            if (this.state.is_editing) {
                switch (e.keyCode) {
                    case KEY.ENTER:
                        e.preventDefault()
                        e.stopPropagation()
                        this.state.editing_node.edit_done();
                        break;
                    case KEY.ESC:
                        e.preventDefault();
                        e.stopPropagation();
                        this.state.editing_node.edit_cancel();
                        break;
                }
                return;
            }
            const node = this.state.active_node as Node
            if (node == null) return;
            if (node.on_hotkey(e)) return;

            let intercepted = false;
            if (!e.altKey && !e.ctrlKey && !e.shiftKey && !e.metaKey) {
                switch (e.keyCode) {
                    case KEY.UP:
                        node.navigate_up(e)
                        intercepted = true
                        break;
                    case KEY.DOWN:
                        node.navigate_down(e)
                        intercepted = true
                        break;
                    case KEY.LEFT:
                        node.navigate_left(e)
                        intercepted = true
                        break;
                    case KEY.RIGHT:
                        node.navigate_right(e);
                        intercepted = true
                        break;
                    case KEY.ENTER:
                        this.open(node, true)
                        intercepted = true
                        break;
                    case KEY.CONTEXTMENU:
                        node.show_context_menu(e);
                        intercepted = true
                        break;
                    case KEY.DEL:
                        intercepted = true;
                        multi_resource_remove(this.project_version.key(), node.tree.get_selected_deletable_records())
                            .catch(e => e.show_toaster())
                        break;
                    case KEY.F2:
                        if (node.computed.renaming.renameable) {
                            intercepted = true;
                            node.edit_start()
                        }
                        break;
                }
            }

            if (!e.altKey && (e.ctrlKey || e.metaKey) && !e.shiftKey) {
                switch (e.keyCode) {
                    case KEY.C:
                        intercepted = true
                        Tree.copy(this.get_selected())
                        break;
                    case KEY.X:
                        intercepted = true
                        Tree.cut(this.get_selected())
                        break;
                    case KEY.V:
                        intercepted = true
                        Tree.paste(node)
                        break;
                    case KEY.D:
                        intercepted = true
                        this.get_selected()
                            .filter(n => n.computed.duplicable)
                            .map(n => n.record)
                            .filter((r: any) => r != null && r.client.duplicate != null)
                            .forEach((r: any) => r.client.duplicate())
                        break;
                }
            }
            if (intercepted) {
                e.preventDefault();
                e.stopPropagation();
            }
        }

        // </editor-fold>

        // <editor-fold desc="CLIPBOARD">
        static _set_clipboard_nodes(nodes: Node[]) {
            const in_clipboard_keys = this.clipboard.nodes.map(n => n.key)
            const new_node_keys = nodes.map(n => n.key)
            const no_longer_in_clipboard = this.clipboard.nodes.filter(n => !new_node_keys.includes(n.key))
            const new_in_clipboard = nodes.filter(n => !in_clipboard_keys.includes(n.key))
            no_longer_in_clipboard.forEach(n => n.set_in_clipboard(false))
            new_in_clipboard.forEach(n => n.set_in_clipboard(true))
            this.clipboard.nodes = nodes;
        }

        static copy(nodes: Node[]) {
            this.clipboard.type = "copy"
            this._set_clipboard_nodes(nodes.filter(n => n.computed.clipboard.can_copy))
        }

        static cut(nodes: Node[]) {
            this.clipboard.type = "cut"
            this._set_clipboard_nodes(nodes.filter(n => n.computed.clipboard.can_cut))
        }

        static paste(node: Node) {
            node.computed.clipboard?.on_paste(Tree.clipboard.type, Tree.clipboard.nodes as Node[])
            this.clipboard.type = null
            this._set_clipboard_nodes([])
        }

        // </editor-fold>

        // <editor-fold desc="CONTEXTMENU">
        static build_default_contextmenu(node: Node) {
            const items: ContextMenu.Items = {}

            const selected_nodes = node.tree.get_selected();
            const folders = selected_nodes.filter(n => n.is_folder)
            if (folders.some(n => !n.is_expanded())) items.open = TestaTree.Tree.contextmenu_open_item(node);
            if (folders.some(n => n.is_expanded())) items.close = TestaTree.Tree.contextmenu_close_item(node);


            if (selected_nodes.some(n => n.computed.clipboard.can_copy)) {
                items.copy = {
                    name: "Copy",
                    icon: "fa-solid fa-copy",
                    color: get_css_var("--button-yellow"),
                    key: `${ctrl_or_meta}-c`,
                    callback: () => {
                        TestaTree.Tree.copy(selected_nodes)
                    },
                }
            }

            if (selected_nodes.some(n => n.computed.clipboard.can_cut)) {
                items.cut = {
                    name: "Cut",
                    icon: "fa-solid fa-cut",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-x`,
                    callback: () => {
                        TestaTree.Tree.cut(selected_nodes)
                    },
                }
            }

            if (selected_nodes.some(n => n.computed.duplicable)) {
                items.duplicate = {
                    name: "Duplicate",
                    icon: "fa-solid fa-clone",
                    color: get_css_var("--button-yellow"),
                    key: `${ctrl_or_meta}-d`,
                    callback() {
                        selected_nodes.filter(n => n.computed.duplicable)
                                      .map(n => n.record)
                                      .filter((r: any) => r != null && r.client.duplicate != null)
                                      .forEach((r: any) => r.client.duplicate())
                    }
                }
            }

            if (Tree.clipboard.nodes.some(n => n.computed.clipboard.can_cut || n.computed.clipboard.can_copy)) {
                items.paste = {
                    name: "Paste",
                    icon: "fa-solid fa-paste",
                    color: get_css_var("--button-blue"),
                    key: `${ctrl_or_meta}-v`,
                    callback() {
                        TestaTree.Tree.paste(node)
                    },
                }
            }

            if (node.computed.renaming.renameable) {
                items.rename = {
                    name: "Rename",
                    icon: "fa-solid fa-pencil-alt",
                    color: get_css_var("--button-white"),
                    key: "f2",
                    callback: () => {
                        node.edit_start()
                    }
                }
            }

            if (selected_nodes.some(n => n.computed.deletable)) {
                items.delete = {
                    name: "Delete",
                    icon: "fa-solid fa-trash",
                    color: get_css_var("--button-red"),
                    key: `del`,
                    callback() {
                        multi_resource_remove(node.tree.project_version?.key(), node.tree.get_selected_deletable_records())
                            .catch(e => e.show_toaster())
                    },
                }
            }
            return items
        }

        static contextmenu_open_item(node: Node) {
            return {
                name: "Open",
                icon: "fa-regular fa-folder-open",
                color: get_css_var("--button-white"),
                key: "→",
                callback: () => {
                    node.tree.get_selected().every(n => n.set_expanded(true));
                }
            }
        }

        static contextmenu_close_item(node: Node) {
            return {
                name: "Close",
                icon: "fa-regular fa-folder",
                color: get_css_var("--button-white"),
                key: "←",
                callback: () => {
                    node.tree.get_selected().every(n => n.set_expanded(false));
                },
            }
        }

        static contextmenu_export_item(node: Node) {
            return {
                name: "Export",
                icon: "fa-solid fa-file-export",
                color: get_css_var("--button-blue"),
                callback: () => {
                    const tree = node.tree
                    const scoped_ids = tree.get_scoped_ids();
                    export_resources(tree.project_version.key(), scoped_ids)
                }
            }
        }

        static contextmenu_import_item(node: Node) {
            return {
                name: "Import",
                icon: "fa-solid fa-file-import",
                color: get_css_var("--button-green"),
                callback: () => {
                    import_resources(node.tree.project_version.key())
                        .catch(e => e.show_toaster())
                }
            }
        }

        // </editor-fold>

        // <editor-fold desc="NAVIGATION">
        visit_node(e: MouseEvent | KeyboardEvent | DragEvent, node: Node) {
            const previous_active_node = this.state.active_node;
            this.set_active(node)

            if (e.shiftKey && this.state.active_node != null && !e.ctrlKey) {
                const between_nodes = this._between_nodes(previous_active_node as Node, node)
                this.set_selected(this.get_selected().concat(between_nodes))
            } else if (e.ctrlKey) {
                if (node.state.is_selected) {
                    this.set_selected(this.get_selected().filter(n => n.key != node.key))
                    // when ctrl clicking to deselect, do not set it as active
                    if (this.get_selected().length > 0) this.set_active(null)
                } else {
                    const new_selected = this.get_selected()
                    new_selected.push(node)
                    this.set_selected(new_selected)
                }
            } else {
                this.set_selected([node])
            }
        }

        children(deep = false) {
            if (deep) {
                let children: Node[] = this.computed.children as Node[]
                this.computed.children.forEach(c => {
                    children = children.concat(c.children(true))
                })
                return children
            } else {
                return this.computed.children
            }
        }

        files(deep = false) {
            return this.children(deep).filter(n => n.is_file)
        }

        folders(deep = false) {
            return this.children(deep).filter(n => n.is_folder)
        }

        // </editor-fold>

        // <editor-fold desc="STATE MANAGEMENT">
        set_active(node: Node) {
            if (this.state.active_node != null) this.state.active_node.set_active(false);
            this.state.active_node = node
            window.active_node = node
            this.state.active_node?.set_active(true);
        }

        get_selected(): TestaTree.Node[] {
            return this.state.selected_nodes as TestaTree.Node[]
        }

        set_selected(nodes: Node[]) {
            if (nodes == null) nodes = []
            const new_selected_keys = nodes.map(n => n.key)
            this.state.selected_nodes
                .filter(n => !new_selected_keys.includes(n.key))
                .forEach(n => {
                    n.set_selected(false)
                });

            this.state.selected_nodes = nodes
            this.state.selected_nodes.forEach(n => {
                n.set_selected(true)
            });
        }

        set_editing(state: boolean, node: Node = null) {
            this.state.is_editing = state;
            if (state) this.state.editing_node = node
            else this.state.editing_node = null
        }

        // </editor-fold>

        // <editor-fold desc="INTERNAL">
        _get_html_element(): HTMLElement {
            return document.getElementById(this.element_id)
        }

        _common_parent(node_a: Node, node_b: Node): Node {
            const a_parents = node_a.parents();
            const b_parents = node_b.parents();
            for (let i = 0; i < a_parents.length; ++i) {
                for (let j = 0; j < b_parents.length; ++j) {
                    if (a_parents[i].key == b_parents[j].key) return a_parents[i]
                }
            }
            return null;
        }

        _between_nodes(node_a: Node, node_b: Node) {
            const common_parent = this._common_parent(node_a, node_b);
            let common_parent_child_a = node_a
            let common_parent_child_b = node_b


            if (common_parent != node_a.parent()) {
                common_parent_child_a = node_a.parent(common_parent.level + 1)
                common_parent_child_b = node_b.parent(common_parent.level + 1)
            }

            let between_nodes: Node[] = [node_a, node_b]
            const common_parent_child_a_index = common_parent_child_a.index()
            const common_parent_child_b_index = common_parent_child_b.index();
            if (Math.abs(common_parent_child_b_index - common_parent_child_a_index) > 1) {
                // get all nodes in between
                const min = Math.min(common_parent_child_a_index, common_parent_child_b_index) + 1
                const max = Math.max(common_parent_child_a_index, common_parent_child_b_index)
                const common_parent_child_siblings = common_parent_child_a.siblings()
                for (let i = min; i < max; ++i) {
                    between_nodes.push(common_parent_child_siblings[i])
                    between_nodes = between_nodes.concat(common_parent_child_siblings[i].children(true))
                }
            }

            const nodes_after_until_level = (level: number, node: Node): Node[] => {
                if (node == null) return []
                if (node.level == level) return []
                let nodes: Node[] = node.following_siblings();
                nodes.filter(n => n.is_folder)
                     .forEach(n => {
                         nodes = nodes.concat(n.children(true))
                     })

                return nodes.concat(nodes_after_until_level(level, node.parent()))
            }

            const nodes_before_until_level = (level: number, node: Node): Node[] => {
                if (node == null) return []
                if (node.level == level) return []
                let nodes: Node[] = node.previous_siblings();
                nodes.filter(n => n.is_folder)
                     .forEach(n => {
                         nodes = nodes.concat(n.children(true))
                     })
                return nodes.concat(nodes_before_until_level(level, node.parent()))
            }
            if (common_parent_child_a_index < common_parent_child_b_index) {
                between_nodes = between_nodes.concat(nodes_after_until_level(common_parent_child_a.level, node_a))
                between_nodes = between_nodes.concat(nodes_before_until_level(common_parent_child_b.level, node_b))
            } else {
                between_nodes = between_nodes.concat(nodes_before_until_level(common_parent_child_a.level, node_a))
                between_nodes = between_nodes.concat(nodes_after_until_level(common_parent_child_b.level, node_b))
            }

            return between_nodes;
        }

        // </editor-fold>
    }

    // </editor-fold>

    // <editor-fold desc="NODE">
    // <editor-fold desc="TYPES">

    export interface Icon {
        enabled?: boolean
        class?: string
        color?: string
        scale?: number
        component?: any
        component_props?: any
    }

    export type IconInput = Partial<Icon>

    export interface Folder<FileScope extends VueRecordScope = VueRecordScope, FolderScope extends VueRecordScope = VueRecordScope> {
        open_fn: () => Promise<any>
        file_scope?: FileScope
        folder_scope?: FolderScope
    }

    export interface File {
        open_fn: () => Promise<any>
        batch_open_fn?: (nodes: Node[]) => Promise<any>
    }

    export interface HoverAction<FileScope extends VueRecordScope = VueRecordScope, FolderScope extends VueRecordScope = VueRecordScope, Model extends VueRecord = VueRecord> {
        icon?: WithRequired<Partial<Icon>, "class">
        title?: string
        callback: (event: KeyboardEvent | MouseEvent, node: Node<FileScope, FolderScope, Model>) => void
    }

    export type HotkeysCallback<FileScope extends VueRecordScope = VueRecordScope, FolderScope extends VueRecordScope = VueRecordScope, Model extends VueRecord = VueRecord> = (event: KeyboardEvent, node: Node<FileScope, FolderScope, Model>) => void

    export interface Hotkeys<FileScope extends VueRecordScope = VueRecordScope, FolderScope extends VueRecordScope = VueRecordScope, Model extends VueRecord = VueRecord> {
        [key: string]: HotkeysCallback<FileScope, FolderScope, Model>
    }

    export interface DragAndDrop {
        is_draggable?: boolean
        is_drop_area?: boolean
        on_start?: (e: DragEvent, node: Node) => void
        on_end?: (e: DragEvent, node: Node) => void

        /** Event called on node that receives the dragging objects.
         * To access what objects are being dragged refer to dnd.state object */
        on_drop?: (e: DragEvent, node: Node) => void
        on_enter?: (e: DragEvent, node: Node) => void
        on_leave?: (e: DragEvent, node: Node) => void
        on_over?: (e: DragEvent, node: Node) => void
    }

    export interface ContextMenu<FileScope extends VueRecordScope = VueRecordScope, FolderScope extends VueRecordScope = VueRecordScope, Model extends VueRecord = VueRecord> {
        build: (node: Node<FileScope, FolderScope, Model>) => ContextMenu.Items
        events?: {
            show?: (options?: ContextMenu.Options) => void
            hide?: (options?: ContextMenu.Options) => void
        }
    }

    export interface Clipboard {
        /** false by default */
        can_copy?: boolean
        /** false by default */
        can_cut?: boolean
        on_paste: (type: "copy" | "cut", nodes: Node[]) => void
    }

    export interface Renaming {
        renameable: boolean
        on_rename?: (node: Node, new_name: string) => void
    }

    export interface Highlight {
        enabled: boolean
        color?: string
        background_color?: string
    }

    export interface Title {
        template?: string
        color?: string
        scale?: number
        component?: any
        component_props?: Record<string, any>
    }

    export interface EventHandlers {
        on_mouse_enter?: (e: MouseEvent) => void
        on_mouse_leave?: (e: MouseEvent) => void
        on_focus?: (e: FocusEvent) => void
        on_blur?: (e: FocusEvent) => void
        on_click?: (e: MouseEvent) => void
    }

    export interface NodeInput<FileScope extends VueRecordScope = VueRecordScope, FolderScope extends VueRecordScope = VueRecordScope, Model extends VueRecord = VueRecord> {
        key: string
        resource_id: EnumResourceId
        record?: Model
        title: Title
        deletable?: boolean
        duplicable?: boolean
        children?: NodeInput<any, any, any>[]
        children_loaded?: boolean
        folder?: Folder<FileScope, FolderScope>
        file?: File
        icon?: IconInput
        hover_action?: HoverAction<FileScope, FolderScope, Model>
        renaming?: Renaming
        hotkeys?: Hotkeys<FileScope, FolderScope, Model>
        dnd?: DragAndDrop
        contextmenu?: ContextMenu<FileScope, FolderScope, Model>
        clipboard?: Clipboard
        highlight?: Highlight
        event_handlers?: EventHandlers

        /** allows setting expanded/collapsed states through reactivity.
         * manually expanded folder will override this value.
         * returning null will fallback to last expanded/collapsed state */
        is_expanded?: boolean
    }

    // </editor-fold>
    export class Node<FileScope extends VueRecordScope = VueRecordScope, FolderScope extends VueRecordScope = VueRecordScope, Model extends VueRecord = VueRecord> {
        static storager_state_is_expanded_key = "state_is_expanded"
        state = reactive({
            is_selected: false,
            is_active: false,
            is_editing: false,
            is_draggable: false, // can be override with computed from dnd input
            is_drop_area: false, // can be override with computed from dnd input
            is_drag_over: false,
            before_edit_title: null as string,
            current_edit_title: null as string,

            // clipboard
            in_clipboard: false,

            // folder
            is_expanded: false,
            manually_toggled_expanded: false,
            is_loading: false,
            is_loaded: false,

            children: {} as Record<string, Node>,
        })

        computed: {
            children: any[] | TestaTree.Node[];
            folder_scope: FolderScope;
            icon: Icon;
            file_scope: FileScope;
            title: Title;
            duplicable: boolean,
            deletable: boolean,
            renaming: Renaming,
            hover_action: TestaTree.HoverAction<FileScope, FolderScope, Model>
            hotkeys: TestaTree.Hotkeys<FileScope, FolderScope, Model>,
            clipboard: Clipboard
            highlight: Highlight,
            is_expanded: boolean,
            storager: Storager
        }

        key: string
        element_id: string
        open_fn: () => Promise<void>
        batch_open_fn: (nodes: Node[]) => Promise<void>

        is_folder: boolean
        is_file: boolean
        tree: Tree = null
        level: number
        _parent: Node;
        dnd: DragAndDrop
        contextmenu: ContextMenu<FileScope, FolderScope, Model>

        edit_resolve: (value: string) => void
        edit_reject: (reason?: any) => void

        record: Model
        resource_id: EnumResourceId

        event_handlers: EventHandlers

        // <editor-fold desc="CONSTRUCTOR">

        constructor(data: NodeInput<FileScope, FolderScope, Model>, parent: Node, tree: Tree) {
            TestaTree.Tree.effect_scope.run(() => {
                const rdata = reactive(data)
                this.key = data.key
                this.element_id = `id_${this.key}`
                this._parent = parent
                this.tree = tree
                this.level = parent == null ? 0 : parent.level + 1
                this.is_folder = data.folder != null
                this.is_file = !this.is_folder
                this.record = data.record
                this.resource_id = data.resource_id

                this.record?.on("before_unload", () => {
                    if (this.state.is_active && this.parent() != null) {
                        this.tree.set_active(this.parent())
                    }
                })
                const hover_action = data.hover_action
                const hotkeys = data.hotkeys
                const highlight = data.highlight
                this._parse_dnd_input(data)
                this.contextmenu = data.contextmenu


                let clipboard;
                if (data.clipboard != null) {
                    clipboard = reactive(data.clipboard)
                } else {
                    clipboard = reactive({
                        can_copy: computed(() => false),
                        can_cut: computed(() => false),
                        on_paste: () => {
                        },
                    })
                }

                const duplicable = data.duplicable ? data.duplicable : computed(() => false)
                const deletable = data.deletable ? data.deletable : computed(() => false)
                const renaming = data.renaming ? data.renaming : { renameable: computed(() => false) }
                const is_expanded = data.is_expanded ? data.is_expanded : computed(() => null)

                this.computed = reactive({
                    title: data.title,
                    hover_action,
                    hotkeys,
                    duplicable,
                    deletable,
                    renaming,
                    clipboard,
                    highlight,
                    is_expanded,
                    icon: computed(() => {
                        let enabled = true;
                        if (rdata.icon?.enabled === false) enabled = false;

                        if (rdata.icon?.component != null) {
                            return {
                                enabled,
                                component: rdata.icon?.component,
                                component_props: rdata.icon?.component_props
                            }
                        } else {
                            const scale = rdata.icon?.scale
                            const color = rdata.icon?.color;
                            let clazz = rdata.icon?.class

                            if (clazz == null) {
                                if (this.is_folder) {
                                    clazz = this.is_expanded() ? "fa-solid fa-folder-open" : "fa-solid fa-folder"
                                } else {
                                    clazz = "fa-regular fa-file"
                                }
                            }

                            return {
                                enabled,
                                class: clazz,
                                scale: scale == null ? 1 : scale,
                                color: color == null ? "var(--button-white)" : color,
                            }
                        }
                    }),
                    file_scope: this.is_folder ? data.folder.file_scope : null,
                    folder_scope: this.is_folder ? data.folder.folder_scope : null,
                    children: computed(() => {
                        if (this.is_file) return []

                        let children_input: NodeInput[];
                        if (data.children == null) {
                            const folder_scope = rdata.folder?.folder_scope as VueRecordScope
                            let folders = folder_scope?.toArray()?.map(r => r.testa_tree_node_data())
                            if (folders == null) folders = []

                            const file_scope = rdata.folder?.file_scope as VueRecordScope
                            let files = file_scope?.toArray()?.map(r => r.testa_tree_node_data())
                            if (files == null) files = []

                            children_input = folders.concat(files)
                        } else {
                            children_input = rdata.children
                        }

                        const created_nodes: Node[] = []
                        const state_children = {} as Record<string, Node>
                        children_input.forEach(d => {
                            const previous_node = this.state.children[d.key]
                            if (previous_node == null) {
                                const node = new Node(d, this as unknown as TestaTree.Node, this.tree)
                                created_nodes.push(node)
                                state_children[d.key] = node
                            } else {
                                created_nodes.push(previous_node)
                                state_children[previous_node.key] = previous_node
                            }
                        })

                        this.state.children = state_children
                        return created_nodes
                    }),
                    storager: computed(() => this.tree.computed.storager?.new_scope("node", this.persisted_key()))
                })

                if (this.is_folder) {
                    this.open_fn = data.folder.open_fn
                    if (data.children != null) {
                        const loaded = data.children_loaded == null ? true : data.children_loaded
                        this.set_loaded(loaded);
                    }
                } else {
                    this.open_fn = data.file.open_fn
                    this.batch_open_fn = data.file.batch_open_fn
                }
                this.event_handlers = data.event_handlers
                if (this.event_handlers == null) this.event_handlers = {}
            })
        }

        // </editor-fold>

        // <editor-fold desc="ACTIONS">
        open(is_manual: boolean) {
            if (this.is_folder) {
                return this.toggle_expanded(is_manual)
            } else {
                return this.open_fn()
            }
        }

        scroll_into_view(opts: ScrollIntoViewOptions = { behavior: "smooth", block: "center", inline: "nearest" }) {
            const html = this._get_html_title_element();
            if (html == null) {
                nextTick(() => {
                    this._get_html_title_element()?.scrollIntoView(opts);
                })
            } else {
                html.scrollIntoView(opts);
            }
        }

        close() {
            if (this.is_file) return;
            this.set_expanded(false);
        }

        visit_node(e: Event) {
            this.tree.event_bus.$emit('visit', this, e)
        }

        edit_start() {
            this.tree.set_selected([this])
            this.tree.set_active(this)
            this.set_editing(true);
            this.scroll_into_view()
            return new Promise<string>((resolve, reject) => {
                this.edit_resolve = resolve
                this.edit_reject = reject
            }).then((new_name) => {
                this.computed.renaming.on_rename(this, new_name)
            }).catch((e) => {
                console.error(e)
            })
        }

        edit_cancel() {
            this.set_editing(false);
            this.edit_reject()
        }

        edit_done() {
            this.edit_resolve(this.state.current_edit_title)
            this.set_editing(false);
        }

        show_context_menu(e: MouseEvent | KeyboardEvent, build: (node: Node<any, any, any>) => ContextMenu.Items = null) {
            e.preventDefault();
            e.stopPropagation();
            $.contextMenu("destroy", ".tree");

            if (!this.state.is_selected) {
                this.visit_node(e)
            }
            if (this.contextmenu == null && build == null) return;

            let position_left: number, position_top: number;
            const window_height = $(window).height() - 5;
            const window_width = $(window).width() - 5;

            $.contextMenu({
                selector: ".tree",
                trigger: 'none',
                zIndex: 10,
                events: {
                    show: (options) => {
                        try {
                            if (this.contextmenu?.events?.show != null) this.contextmenu?.events?.show(options)
                        } catch (e) {
                            console.error(e);
                        }
                        setTimeout(() => {
                            this.tree?._get_html_element()?.blur()
                            options.$menu[0].focus()
                            options.$layer[0].addEventListener("contextmenu", function(e) {
                                options.$trigger.contextMenu("destroy")
                                setTimeout(() => {
                                    Tree.get_node(e)?.show_context_menu(e)
                                }, 10)
                            })
                        }, 1)
                    },
                    hide: (options) => {
                        this.tree?._get_html_element()?.focus({ preventScroll: true })
                        try {
                            if (this.contextmenu?.events?.hide != null) this.contextmenu?.events?.hide(options)
                        } catch (e) {
                            console.error(e);
                        }
                    },
                },
                position: (opt) => {
                    const menu_height = opt.$menu.outerHeight()
                    const menu_width = opt.$menu.outerWidth();
                    const overflow_height = (position_top + menu_height) - window_height;
                    const overflow_width = (position_left + menu_width) - window_width;
                    if (overflow_height > 0) position_top = position_top - overflow_height;
                    if (overflow_width > 0) position_left = position_left - overflow_width;
                    opt.$menu.css({ top: position_top, left: position_left });
                },
                build: () => {
                    const items = build == null ? this.contextmenu.build(this) : build(this);
                    return {
                        items,
                        callback: () => {
                        }
                    }
                },
            });

            if (e.type == "mousedown" || ((e as MouseEvent).pageY != null && (e as MouseEvent).pageX != null)) {
                position_top = (e as MouseEvent).pageY;
                position_left = (e as MouseEvent).pageX;
                $(this.tree._get_html_element()).trigger('contextmenu')
            } else {
                const ele = this._get_html_element()
                const rect = ele.getBoundingClientRect();
                position_top = rect.top + rect.height / 2;
                position_left = rect.left + rect.width / 2;
                $(this.tree._get_html_element()).trigger('contextmenu')
            }
        }

        // </editor-fold>

        // <editor-fold desc="NAVIGATION">
        navigate_up(e: KeyboardEvent) {
            const new_node = this._get_key_up_target();
            this.tree.visit_node(e, new_node)
            new_node.scroll_into_view({ behavior: "auto", block: "nearest", inline: "nearest" })
        }

        navigate_down(e: KeyboardEvent) {
            const new_node = this._get_key_down_target();
            this.tree.visit_node(e, new_node)
            new_node.scroll_into_view({ behavior: "auto", block: "nearest", inline: "nearest" })
        }

        navigate_left(e: KeyboardEvent) {
            if (this.is_file) {
                this.parent()?.visit_node(e)
            } else if (this.is_folder) {
                if (this.is_expanded()) {
                    this.set_expanded(false)
                } else {
                    this.parent()?.visit_node(e)
                }
            }
        }

        navigate_right(e: KeyboardEvent) {
            if (this.is_file) return;
            if (this.is_expanded()) {
                this.children()[0]?.visit_node(e)
            } else {
                this.set_expanded(true)
            }
        }

        /**
         * If at_level is not provided. It will return the first parent
         * @param at_level
         */
        parent(at_level: number = null): Node {
            if (at_level == null) at_level = this.level - 1;

            const find_parent_at_level = (node: Node): Node => {
                if (node == null) return null
                if (node.level == at_level) return node
                return find_parent_at_level(node._parent)
            }
            return find_parent_at_level(this as Node)
        }

        parents() {
            const array: Node[] = []
            let current = this.parent();
            while (current != null) {
                array.push(current)
                current = current.parent()
            }
            return array;
        }

        siblings() {
            if (this._parent == null) {
                return this.tree.computed.children
            } else {
                return this._parent.computed.children
            }
        }

        index() {
            const siblings = this.siblings();
            const sibling_keys = siblings.map(c => c.key)
            return sibling_keys.indexOf(this.key);
        }

        following_sibling() {
            const index = this.index();

            return this.siblings()[index + 1]
        }

        following_siblings(): Node[] {
            const index = this.index();
            return this.siblings().filter((_s, sibling_index) => sibling_index > index)
        }

        previous_sibling() {
            const index = this.index();
            return this.siblings()[index - 1]
        }

        previous_siblings(): Node[] {
            const index = this.index();
            return this.siblings().filter((_s, sibling_index) => sibling_index < index)
        }

        children(deep = false): Node[] {
            if (deep) {
                const children: Node[] = []
                const add_node_children = (node: Node, children: Node[]): Node[] => {
                    children = children.concat(node.computed.children);
                    node.computed.children.forEach(child => {
                        children = add_node_children(child, children)
                    })
                    return children
                }
                return add_node_children(this as Node, children)
            } else {
                return this.computed.children
            }
        }

        // </editor-fold>

        // <editor-fold desc="HELPERS">
        is_visible() {
            return $(this._get_html_element()).is(":visible")
        }

        resolve_is_expanded() {
            if ((this.state.manually_toggled_expanded && this.computed.is_expanded !== true) || this.computed.is_expanded == null) {
                return this.state.is_expanded
            } else {
                this.set_expanded(this.computed.is_expanded)
                return this.computed.is_expanded
            }
        }

        // </editor-fold>

        // <editor-fold desc="EVENT HANDLERS">
        on_drag_start(e: DragEvent) {
            if (!this.state.is_draggable) {
                e.preventDefault();
                return;
            }

            if (!this.state.is_selected) this.tree.visit_node(e, this)
            dnd.start_with_records(this.tree.get_selected_records_array())
            if (this.dnd.on_start != null) this.dnd.on_start(e, this)
        }

        on_drag_enter(e: DragEvent) {
            if (!this.state.is_drop_area) return;
            this.set_drag_over(true)
            if (this.dnd.on_enter != null) this.dnd.on_enter(e, this as Node)
        }

        on_drag_over(e: DragEvent) {
            if (!this.state.is_drop_area) return;
            this.set_drag_over(true)
            if (this.dnd.on_over != null) this.dnd.on_over(e, this as Node)
        }

        on_drag_leave(e: DragEvent) {
            if (!this.state.is_drop_area) return;
            this.set_drag_over(false)

            if (this.dnd.on_leave != null) this.dnd.on_leave(e, this as Node)
        }

        on_drag_drop(e: DragEvent) {
            if (!this.state.is_drop_area) return;
            e.preventDefault();
            this.set_drag_over(false)
            if (this.dnd.on_drop != null) this.dnd.on_drop(e, this as Node)
        }

        on_drag_end(e: DragEvent) {
            if (this.state.is_drop_area) {
                if (this.dnd.on_end != null) this.dnd.on_end(e, this as Node)
            }
            dnd.end()
        }

        on_mouse_enter(e: MouseEvent) {
            this._handle_event("on_mouse_enter", e)
        }

        on_mouse_leave(e: MouseEvent) {
            this._handle_event("on_mouse_leave", e)
        }

        on_click(e: MouseEvent) {
            e.stopPropagation()
            e.preventDefault()
            this._handle_event("on_click", e)

            this.visit_node(e)
        }

        on_dbl_click(e: MouseEvent) {
            e.stopPropagation()
            e.preventDefault()

            if (this.is_folder) {
                this.toggle_expanded(true)
            } else {
                this.open(true);
            }

            clear_selection()
        }

        on_hover_action_click(e: MouseEvent | KeyboardEvent) {
            e.stopImmediatePropagation();
            this.computed.hover_action.callback(e, this)
        }

        on_hotkey(e: KeyboardEvent) {
            if (this.computed.hotkeys == null) return false
            let intercepted = false;

            Object.keys(this.computed.hotkeys).forEach(hotkey => {
                if (triggered(hotkey, e)) {
                    if (!intercepted) {
                        e.preventDefault();
                        e.stopPropagation();
                        intercepted = true;
                    }
                    this.computed.hotkeys[hotkey](e, this)
                }
            })
            return intercepted;
        }

        // </editor-fold>

        // <editor-fold desc="STATE MANAGEMENT">
        persisted_key() {
            if (this.record == null) return `${Enum.Resource.Id.PROJECT_VERSION}[${this.tree.project_version?.key()}]__key[${this.key}]`
            return `${Enum.Resource.Id.PROJECT_VERSION}[${this.tree.project_version?.key()}]__${this.record.constructor.resource_id}[${this.record.key().toString()}]`
        }

        set_active(state: boolean) {
            this.state.is_active = state
        }

        set_selected(state: boolean) {
            this.state.is_selected = state
        }

        toggle_expanded(is_manual: boolean) {
            if (is_manual) this.set_manually_toggled_expanded(true);
            return this.set_expanded(!this.is_expanded())
        }

        is_expanded() {
            return this.state.is_expanded
        }

        set_expanded(state: boolean) {
            this.state.is_expanded = state

            this.computed.storager?.set(Node.storager_state_is_expanded_key, this.is_expanded())
            if (this.is_expanded() && !this.state.is_loaded) {
                this.set_loading(true)
                return this.open_fn().then(() => {
                    this.set_loading(false)
                    this.set_loaded(true);
                }).catch((e) => {
                    console.error(e)
                    if (this.state.manually_toggled_expanded) {
                        // if manually expanded, revert because expand failed
                        // if not manually, do nothing, because it could cause to endlessly fail
                        this.set_loading(false)
                        this.set_expanded(false)
                    }
                })
            } else {
                return generate_resolved_promise();
            }
        }

        set_manually_toggled_expanded(state: boolean) {
            this.state.manually_toggled_expanded = state;
        }

        set_loading(state: boolean) {
            this.state.is_loading = state
        }

        set_loaded(state: boolean) {
            this.state.is_loaded = state
        }

        set_in_clipboard(state: boolean) {
            this.state.in_clipboard = state;
        }

        set_editing(state: boolean) {
            if (state) {
                this.state.current_edit_title = this.computed.title.template;
                this.state.before_edit_title = this.computed.title.template;
            }
            this.state.is_editing = state;
            this.tree.set_editing(state, this as Node<FileScope, FolderScope, Model>)

            // when disabling editing, the content editable element is removed, and so is the tree focus
            nextTick(() => {
                if (document.activeElement == document.body) {
                    this.tree._get_html_element()?.focus({ preventScroll: true })
                }
            })
        }

        set_drag_over(state: boolean) {
            this.state.is_drag_over = state;
        }

        // </editor-fold>

        // <editor-fold desc="INTERNAL">
        _handle_event(name: keyof EventHandlers, event: Event) {
            if (this.event_handlers[name] != null) {
                try {
                    this.event_handlers[name](event as any)
                } catch (e) {
                    console.error("Error handling event:", e)
                }
            }
        }

        _get_html_element(): HTMLElement {
            return this.tree._get_html_element()?.querySelector(`#${this.element_id}`)
        }

        _get_html_title_element(): HTMLElement {
            return this._get_html_element()?.querySelector(".title-container")
        }

        _get_key_up_target(): Node {
            const previous = this.previous_sibling();
            if (previous == null) {
                const parent = this.parent();
                return parent == null ? this as Node : parent
            }

            if (!previous.is_expanded()) return previous;

            const last_expanded_child = (node: Node): Node => {
                if (node.is_file) return node
                if (!node.is_expanded() || node.children().length == 0) return node
                const children = node.children();
                return last_expanded_child(children[children.length - 1])
            }
            return last_expanded_child(previous)
        }

        _get_key_down_target(): Node {
            if (this.is_folder && this.is_expanded() && this.children().length > 0) {
                return this.children()[0]
            } else {
                const next = this.following_sibling();
                if (next != null) return next;

                const parent_following_sibling = (node: Node): Node => {
                    const parent = node.parent();
                    if (parent == null) return node
                    const following = parent.following_sibling();
                    if (following == null) return parent_following_sibling(parent)
                    return following
                }
                const target = parent_following_sibling(this as Node)
                if (target == null) return this as Node
                return target
            }
        }

        _parse_dnd_input(data: NodeInput<FileScope, FolderScope, Model>) {
            this.dnd = data.dnd
            if (data?.dnd?.is_drop_area != null) {
                this.state.is_drop_area = data.dnd.is_drop_area
            }
            if (data?.dnd?.is_draggable != null) {
                this.state.is_draggable = data.dnd.is_draggable
            }
        }

        // </editor-fold>
    }

    // </editor-fold>
}

global_event_bus.$on("before_project_version_unload", () => {
    TestaTree.Tree.get_project_tree().unload();
    TestaTree.Tree.get_devices_tree().unload();
    TestaTree.Tree.get_apps_tree().unload();
    TestaTree.Tree.get_plays_tree().unload();
})

declare global {
    interface Window {
        Tree: typeof TestaTree.Tree
    }
}
window.Tree = TestaTree.Tree
