import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumPlayStatus } from "../../../auto_generated/enums";
import { PlaySnippetClient } from "../../clients/play/play_snippet_client";
import { PlaySnippetScope } from "../../scopes/play/play_snippet_scope";
import { Props } from "../../base/vue_record";
import { RecordOpts } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export interface DebugFrame {
    id: number // maybe is string
    name: string
    error_line: string
    error_in_snippet_savepoint_id: number
}

export interface PlaySnippetProps extends Props {
    id: number
    play_scenario_id: number
    snippet_savepoint_id: number
    start_time: Date
    end_time: Date
    duration: number
    status: EnumPlayStatus
    current_line_number: number
    total_lines_number: number
    execution_index: number
    created_at: Date
    updated_at: Date
    name: string

    // from methods
    progress: number
    "running?": boolean
    "finished?": boolean
}
export type QuerifiedPlaySnippetProps = QuerifyProps<PlaySnippetProps>
export type PlaySnippetCreateProps = Omit<PlaySnippetProps, 'id'>
export type PlaySnippetUpdateProps = Partial<PlaySnippetProps>

export interface PlaySnippetState extends State {
    debug_frames: DebugFrame[]
}
export interface PlaySnippetComputed extends Computed {}
export interface PlaySnippetStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PlaySnippet extends VueRecord {
    ['constructor']: typeof PlaySnippet

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlaySnippetClient
    static ScopeClass = PlaySnippetScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlaySnippetStaticState = reactive<PlaySnippetStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_scenario_id", "status"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlaySnippet> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlaySnippet>> = {}

    static field_validators: ModelValidatorOpts<PlaySnippetProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_SNIPPET
    static resource_id = Enum.Resource.Id.PLAY_SNIPPET
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlaySnippetClient
    declare props: PlaySnippetProps;
    declare state: PlaySnippetState;
    declare computed: PlaySnippetComputed;

    // </editor-fold>

    constructor(props: Props, opts: RecordOpts) {
        super(props, opts);
        this.state.debug_frames = []
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlaySnippet.register_resource(PlaySnippet)
PlaySnippetClient.ModelClass = PlaySnippet
PlaySnippetScope.ModelClass = PlaySnippet


declare global {
    interface Window {
        PlaySnippet: typeof PlaySnippet
    }
}
window.PlaySnippet = PlaySnippet
// </editor-fold>

