import { generate_uuid } from "../generate/generate_uuid";
import * as Faye from "faye";
import { EnumJobMessage } from "../../auto_generated/enums";
import { TestaError } from "../../libs/testa/testa_error";
import { log_error } from "../generic/log_error";
import { create_vue_app } from "../vue/create_vue_app";
import JobbermanTextInput from "../../components/testa/jobberman/JobbermanTextInput.vue";
import { send_ws } from "../generic/send_ws";
import { ValidatorOpts } from "../validator/validator";


export namespace Jobberman {
    export namespace Callbacks {
        export type Progress = (progress: number) => void
        export type Log = (level: Jobberman.LogLevel, log: string, extras: any) => void
        export type Success = () => void
        export type Error = (error: TestaError) => void
        export type Done = (success: boolean, error: TestaError) => void
    }

    export interface Opts {
        run: (jobberman_id: string, resolve: () => void, reject: (error: any) => void) => void
        progress?: Callbacks.Progress
        log?: Callbacks.Log

        /** called when job is successful, but not when aborted */
        success?: Callbacks.Success

        /** called when job fails, but not when aborted */
        error?: Callbacks.Error

        /** called when the job is done regardless if success/abort/error */
        done?: Callbacks.Done
    }

    export type LogLevel = "debug" | "info" | "warn" | "error" | "fatal" | "unknown"

    export interface ProgressData {
        /** number between 0.0-1.0 */
        progress?: number
        log?: string
        level?: LogLevel
        extras?: any
    }

    export interface InputRequestTextOption {
        type: "text"
        validator?: ValidatorOpts
    }

    export interface InputRequestSelectOption {
        type: "select"
        options: string[][]
        validator?: ValidatorOpts
    }

    export interface InputRequestConfirmationOption {
        type: "confirmation",
        confirm: string
        cancel: string
        validator?: ValidatorOpts
    }

    export interface InputRequestNumberOption {
        type: "number",
        validator?: ValidatorOpts
    }

    export interface InputRequestData {
        message: string
        timeout_at: string
        options: InputRequestTextOption | InputRequestSelectOption | InputRequestConfirmationOption | InputRequestNumberOption
    }

    export interface DoneData {
        success: boolean
        aborted: boolean
        error?: TestaError
    }

    export type Message = {
        type: EnumJobMessage
        job_name: string
        data: ProgressData | InputRequestData | DoneData
    }

    export interface InputResponseData {
        abort_job: boolean
        responder: {
            user_id: number
            web_id: string
        }
        response: {
            value: any
        }
    }
}

/**
 * Jobberman - class for handling backend jobs.
 * What it does:
 * - creates promise that resolves when job is done
 * - subscribes to job channel
 * - handles job messages (progress, input, success/error/done)
 * - provides callback for job messages
 * - cancels ws subscription on job done
 */
export class Jobberman {
    private readonly id: string;
    private readonly channel: string;

    private opts: Jobberman.Opts;
    private sub: Faye.Subscription;

    promise: Promise<void>
    resolve: () => void;
    reject: (error: any) => void
    job_name: string;

    constructor(opts: Jobberman.Opts) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
        this.id = generate_uuid()
        this.opts = opts
        this.channel = `/jobs/${this.id}`
        this.init()
    }

    static promise(opts: Jobberman.Opts) {
        return new Jobberman(opts).promise
    }

    abort_job() {
        return this.respond(null, true)
    }

    respond(value: any, abort_job = false) {
        const payload: Jobberman.InputResponseData = {
            abort_job,
            responder: {
                user_id: current.user?.key(),
                web_id: web.id
            },
            response: {
                value
            }
        }
        return send_ws(`${this.channel}/input`, payload)
    }

    private init() {
        this.sub = faye.subscribe(this.channel, (data: Jobberman.Message) => this.handle_message(data))
        // on job run fail, cancel the subscription
        this.promise.catch(() => this.sub.cancel())

        // when subscribed to channel, run the job
        this.sub.then(() => {
            console.log(`Jobberman listening on: ${this.channel}`);
            progress_bar.go(0.1)
            this.opts.run(this.id, this.resolve, this.reject)
        })

        // when failing to subscribe to channel, reject the promise
        this.sub._promise.catch((e) => {
            toastr.error("Failed to subscribe to job channel")
            console.error(e)
            progress_bar.go(0.0)
            this.reject(e)
        })
    }

    private handle_message(data: Jobberman.Message) {
        if (this.job_name == null) this.job_name = data.job_name
        console.log(data);
        switch (data.type) {
            case Enum.Job.Message.INPUT_REQUEST:
                this.handle_input_request(data.data as Jobberman.InputRequestData)
                break;
            case Enum.Job.Message.PROGRESS:
                this.handle_progress(data.data as Jobberman.ProgressData)
                break;
            case Enum.Job.Message.DONE:
                this.handle_done(data.data as Jobberman.DoneData)
                break;
        }
    }

    private handle_input_request(data: Jobberman.InputRequestData) {
        if (data.options.validator == null) data.options.validator = {}
        switch (data.options.type) {
            case "text":
                create_vue_app(JobbermanTextInput, {
                    jobberman: this,
                    validator_opts: data.options.validator,
                    message: data.message,
                    timeout_at: new Date(data.timeout_at)
                })
                break
            default:
                throw new Error(`Invalid input request type: ${data.options.type}`)
        }
    }

    private handle_progress(data: Jobberman.ProgressData) {
        progress_bar.go(data.progress * 100)
        if (this.opts.progress && data.progress != null) {
            log_error(() => this.opts.progress(data.progress))
        }
        if (this.opts.log && (data.log != null || data.extras != null)) {
            log_error(() => this.opts.log(data.level, data.log, data.extras))
        }
    }

    private handle_done(data: Jobberman.DoneData) {
        const error = data.success ? null : new TestaError(data.error.message, data.error.user_message, data.error.advice)

        if (data.success && this.opts.success) {
            log_error(() => this.opts.success())
        } else if (!data.aborted && this.opts.error) {
            log_error(() => this.opts.error(error))
        }
        if (this.opts.done) {
            log_error(() => this.opts.done(data.success, error))
        }
        progress_bar.go(100)

        if (data.success || data.aborted) {
            this.resolve()
        } else {
            this.reject(error)
        }
    }
}
