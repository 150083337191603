import { file_upload } from "../../generic/file_upload";
import ConfirmDialogue from "../../../components/testa/confirm_dialogue/confirm_dialgue";
import { Jobberman } from "../../jobberman/jobberman";

export function import_resources(project_version_id: number) {
        return Jobberman.promise({
            run: (jobberman_id, resolve, reject) => {
                file_upload({
                        autoUpload: true,
                        url: `/core/import_resources`,
                        type: "POST",
                        add(e: JQuery.Event, data: JQuery.UploadData) {
                            ConfirmDialogue.show({
                                confirm_text: "Overwrite",
                                confirm_color_class: "red",
                                deny_text: "Import",
                                show_deny: true,
                                deny_color_class: "default",
                                cancel_text: "Cancel",
                                title: "Overwrite?",
                                html: "<strong>Overwrite</strong>: Will overwrite existing items if they are of the same origin." +
                                    "<br><strong>Import</strong>: Will create new copies of all items.",
                                confirm_action: () => {
                                    progress_bar.go(1);
                                    data.formData = {
                                        project_version_id,
                                        jobberman_id,
                                        overwrite: true,
                                        authenticity_token,
                                    }
                                    return data.submit()
                                }
                            })
                        },
                        progressall: (e) => {
                        },
                        fail: () => reject(new Error("Unknown error")),
                        done: () => resolve(),
                    },
                    `.testa_export_${CONSTANTS.IMPORT_EXPORT_VERSION}`
                )
            }
        })
}
