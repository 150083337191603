import { PlayLog } from "../../models/play/play_log";
import { Play } from "../../models/play/play";
import { PlayScenario } from "../../models/play/play_scenario";
import { PlaySandbox } from "../../models/play/play_sandbox";
import { PlaySnippet } from "../../models/play/play_snippet";


declare module "../../models/play/play_log" {
    interface PlayLog {
        play: Play
        play_snippet: PlaySnippet
        play_scenario: PlayScenario
        play_sandbox: PlaySandbox
    }
}

PlayLog.belongs_to("play", Play, "play_id")
PlayLog.belongs_to("play_snippet", PlaySnippet, "play_snippet_id")
PlayLog.belongs_to("play_scenario", PlayScenario, "play_scenario_id")
PlayLog.belongs_to("play_sandbox", PlaySandbox, "play_sandbox_id")
