import { User } from "../models/user";
import { ProjectVersion } from "../models/project_version";
import { UserSetting } from "../models/user_setting";

declare module "../models/user_setting" {
    interface UserSetting {
        user: User
        project_version: ProjectVersion
    }
}

UserSetting.belongs_to("user", User, "user_id")
UserSetting.belongs_to("project_version", ProjectVersion, "project_version_id")
