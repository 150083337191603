import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1029b103"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: "play-status-icon-container",
    title: _ctx.title,
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.icon_class)
    }, null, 2 /* CLASS */)
  ], 12 /* STYLE, PROPS */, _hoisted_1))
}