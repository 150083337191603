import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    ref: "slider",
    class: _normalizeClass(_ctx.klass),
    style: _normalizeStyle(_ctx.style)
  }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
}