import { Tab } from "../tab";
import GroupEditor from "../editors/group/GroupEditor.vue";
import { Group } from "../../../../vue_record/models/group";
import { markRaw } from "vue";
import { reactive } from "vue";
import { Scenario } from "../../../../vue_record/models/scenario";
import { ScenarioFolder } from "../../../../vue_record/models/scenario_folder";
import { computed } from "../../../../helpers/vue/computed";

namespace GroupTab {
    export interface Input extends Tab.Input {
        group_id: number
    }

    export interface State extends Tab.State {
        record: Group,
    }
}

export class GroupTab extends Tab {
    static type = "GroupTab"

    type: string
    group_id: number

    declare state: GroupTab.State

    constructor(data: GroupTab.Input) {
        super(data)
        this.type = GroupTab.type
        this.group_id = data.group_id;
        this.state.icon.class = Group.icon_class
        this.component = markRaw(GroupEditor);

        // if title is known, set it before we load, so that tabs are sorted correctly
        const group = Group.find(this.group_id)
        if (group != null) {
            this.computed.title = computed(() => group.props.name)
        }

        this._set_and_call_load_function(() => {
            return Group.ClientClass
                        .load(this.group_id)
                        .then(group => {
                            this.state.record = group

                            this.state.icon.color = computed(() => `var(--group-color)`)
                            this.computed.title = computed(() => group.props.name)
                            this.component_props = { group }
                        })
                        .catch((error) => {
                            this._on_load_error(error)
                        })
                        .finally(() => {
                            this.set_loaded(true)
                        })
        })
        this.dnd = reactive({})
    }

    _register_default_event_handlers() {
        super._register_default_event_handlers();

        this.on("dragover", (tab: Tab, e: DragEvent) => {
            if (!this.state.loaded) return

            const scenarios = dnd.state.records.filter(r => r instanceof Scenario) as Scenario[]
            const scenario_folders = dnd.state.records.filter(r => r instanceof ScenarioFolder) as ScenarioFolder[]

            if (scenario_folders.length + scenarios.length > 0) {
                // TODO: show ghost element where will be dropped
            }
        })

        this.on("dragleave", (_tab: Tab, _e: DragEvent) => {
            if (!this.state.loaded) return

            // TODO: remove ghost element
        })

        this.on("drop", (_tab: Tab, e: DragEvent) => {
            if (!this.state.loaded) return

            const scenarios = dnd.state.records.filter(r => r instanceof Scenario) as Scenario[]
            const scenario_folders = dnd.state.records.filter(r => r instanceof ScenarioFolder) as ScenarioFolder[]

            if (scenario_folders.length + scenarios.length > 0) {
                ScenarioFolder.ClientClass
                              .batch_scenario_ids(scenario_folders.map(f => f.key()))
                              .then((folder_scenario_ids) => {
                                  this.state.record.add_groups_scenarios(
                                      scenarios.map(s => s.key())
                                               .concat(folder_scenario_ids));
                              })
            }
        })
    }
}

Tab.register_tab_type(GroupTab)
