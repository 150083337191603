<template>
  <div
      class="bar"
      :style="bar_style"
  >
    <div
        class="progress"
        :style="progress_style"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ProgressBar } from "./progress_bar";
import { PropType } from "vue";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        progress_bar: {
            type: Object as PropType<ProgressBar>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        bar_style() {
            return {
                display: this.progress_bar.progress.value > 0 ? "flex" : "none",
                height: `${this.progress_bar.bar.height}px`,
                backgroundColor: this.progress_bar.bar.color,
            }
        },
        progress_style() {
            return {
                width: `${this.progress_bar.progress.value}%`,
                backgroundColor: this.progress_bar.progress.color,
            }
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.bar {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 6;

  .progress {
    width: 0;
    height: 100%;
    transition: height 0.3s, width 0.2s;
  }
}
</style>
