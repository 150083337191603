<template>
  <Modal
      id="new_user_modal"
      :confirm_text="new_user ? 'Create' : 'Add'"
      :show_cancel="true"
      :focus_confirm="false"
      :cancel_on_escape="true"
      :focus_last_active_on_unmount="true"
      :form_validator="form_validator"
      :confirm_action="create_user"
      @cancel="$emit('exit')"
  >
    <template #header>
      <div class="title">
        <template v-if="new_user">
          New user
        </template>
        <template v-else>
          Add existing user
        </template>
      </div>
    </template>

    <template #body>
      <div class="container">
        <div class="row">
          <div class="col s12">
            <Input
                id="email_input"
                v-model="user_props.email"
                :validator="form_validator.register('email', {...User.field_validators.email, callback: check_email })"
                label="Email"
                :focus="true"
                :throttle_time="500"
            />
          </div>
        </div>


        <div v-if="new_user">
          <div class="row">
            <div class="col s12">
              <Input
                  id="first_name_input"
                  v-model="user_props.first_name"
                  :validator="form_validator.register('first_name', User.field_validators.first_name)"
                  label="First Name"
              />
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <Input
                  id="last_name_input"
                  v-model="user_props.last_name"
                  :validator="form_validator.register('last_name', User.field_validators.last_name)"
                  label="Last Name"
              />
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <Input
                  id="username_input"
                  v-model="user_props.username"
                  :validator="form_validator.register('username', { ...User.field_validators.username, callback: check_username })"
                  :debounce_time="400"
                  label="Username"
              />
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <Input
                  id="password_input"
                  v-model="user_props.password"
                  type="password"
                  :validator="form_validator.register('password', {...User.field_validators.password, callback: trigger_confirm_password_validation } )"
                  label="Password"
              />
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <Input
                  id="confirm_password_input"
                  v-model="user_props.confirm_password"
                  type="password"
                  :validator="form_validator.register('confirm_password', { callback: check_equal_confirm_password })"
                  label="Password confirmation"
              />
            </div>
          </div>

          <div v-if="current.user.is_superadmin()"
               class="row">
            <div class="col s12">
              <Checkbox
                  id="new_user_superadmin_checkbox"
                  v-model="user_props.superadmin"
                  label="Superadmin"
              />
            </div>
          </div>
        </div>

        <div v-if="!user_props.superadmin"
             class="row"
             style="max-height: 75%; height: 100%;"
        >
          <div class="col s12">
            <UserProjectsTable
                :user_props="user_props"
                :user_projects="user_projects"
                :is_viewer_role="is_viewer_role"
                :is_user_role="is_user_role"
                :stage_id="stage_id"
                dropdown_parent="#new_user_modal"
            />
          </div>
        </div>
      </div>
    </template>

  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Input from "../../../testa/Input.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import UserProjectsTable from "./UserProjectsTable.vue";
import { UserProject } from "../../../../vue_record/models/user_project";
import { User } from "../../../../vue_record/models/user";
import { UserCreateProps } from "../../../../vue_record/models/user";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import { UserProjectProps } from "../../../../vue_record/models/user_project";
import _ from "lodash";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";

export default defineComponent({
    components: {
        UserProjectsTable,
        Checkbox,
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        project: {
            type: Object as PropType<Project>,
            required: false,
            default: null,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['exit'],
    // <editor-fold desc="DATA">
    data() {
        const stage_id = generate_uuid();
        return {
            stage_id,
            form_validator: new FormValidator(),
            user_props: {
                first_name: "",
                last_name: "",
                username: "",
                email: "",
                password: "",
                confirm_password: "",
                superadmin: false,
            } as UserCreateProps,
            user_projects_scope: UserProject.get_scope(stage_id),
            user_projects_store: UserProject.get_store(stage_id),
            new_user: true,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        User() {
            return User
        },
        current() {
            return current
        },
        user_projects() {
            return this.user_projects_scope.toArray()
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        if (this.project != null) {
            const user_project_props = {
                project_id: this.project.props.id,
                user_id: this.user_props.id,
                role: Enum.User.Role.VIEWER,
            } as UserProjectProps
            UserProject.new(user_project_props, {
                is_stage: true,
                stage_id: this.stage_id
            })
        }
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        create_user() {
            let user_props = _.cloneDeep(this.user_props) as UserCreateProps;
            if (!this.new_user) {
                // if we are just adding existing user, don't send the other empty props
                user_props = { email: user_props.email }
            }
            return User.ClientClass.create(user_props, this.user_projects.map(up => up.props))
                       .then(() => this.$emit("exit"));
        },
        check_username(username: string) {
            if (username == "") return null
            return new Promise<string>((resolve, reject) => {
                User.ClientClass.check_uniqueness({ username }).then((data) => {
                    if (!data.username) {
                        resolve("Username is already taken")
                    } else {
                        resolve(null)
                    }
                }).catch(() => {
                    reject(new Error("Ajax failed"))
                })
            })
        },
        check_email(email: string) {
            if (email == "") return null
            return new Promise<string>((resolve, reject) => {
                User.ClientClass.check_uniqueness({ email }).then((data) => {
                    if (this.project == null) {
                        // if creating user on as superadmin, check if user already exists
                        if (!data.email) {
                            resolve("User with the given email already exists")
                        } else {
                            resolve(null)
                        }
                    } else {
                        // if creating user on a project, switch between new user form, and add user to project form
                        this.new_user = data.email
                        resolve(null);
                    }
                }).catch(() => {
                    reject(new Error("Ajax failed"))
                })
            })
        },
        check_equal_confirm_password() {
            if (this.user_props.password == this.user_props.confirm_password) return null
            return "Passwords do not match"
        },
        trigger_confirm_password_validation() {
            this.form_validator.validators.confirm_password.run(this.user_props.confirm_password)
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  min-width: 450px;
  max-width: 900px;
  display: flex;
  flex-direction: column;

  .row {
    width: 100%;
  }
}
</style>
