<template>
  <SettingsItem
      :id="setting_items.project.integrations.id"
      :title="setting_items.project.integrations.title"
      padding="10px 0 10px 0"
  >
    <Tabs
        ref="tabs"
        :tabs="tabs"
        :round="false"
    >
      <!--<editor-fold desc="XRAY">-->
      <template #xray>
        <Xray
            :form_validator="xray_form_validator"
            :project_version_setting="project_version_setting"
            :is_viewer_role="is_viewer_role"
            :is_user_role="is_user_role"
            @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
            @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
        />
      </template>
      <!--</editor-fold>-->

      <!--<editor-fold desc="TEST RAIL">-->
      <template #test_rail/>
      <!--</editor-fold>-->
    </Tabs>

  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsItem from "../../SettingsItem.vue";
import { PropType } from "vue";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import Xray from "./Xray.vue";
import Tabs from "../../../testa/Tabs.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";


const tabs = {
    xray: {
        title: "Xray",
        id: "xray",
    },
    test_rail: {
        title: "Test Rail",
        id: "test_rail",
        disabled: true,
    },
    jira: {
        title: "Jira",
        id: "jira",
        disabled: true,
    },
    slack: {
        title: "Slack",
        id: "slack",
        disabled: true,
    },
}

export default defineComponent({
    components: {
        Xray,
        Tabs,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            tabs: Object.values(tabs),
            xray_form_validator: this.form_validator.register_child_form('xray', {
                on_invalid_submit: () => (this.$refs.tabs as typeof Tabs).activate_tab(tabs.xray)
            }),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Tabs() {
            return Tabs
        },
        project_version_setting() {
            return this.project_version.project_version_setting;
        },
        setting_items() {
            return setting_items
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    created() {

    },
    beforeMount() {

    },
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.tab {
  padding: 10px;
}
</style>
