<template>
  <SettingsItem
      :id="setting_items.project.code_lint.id"
      :title="`${setting_items.project.code_lint.title} (RuboCop Config)`"
  >
    <div v-show="loaded"
         class="container">
      <div class="help-container">
        For more information visit
        <a href="https://docs.rubocop.org/rubocop/cops.html"
           target="_blank">
          rubocop docs
        </a>
        <div class="flex-expander"/>
        <Button
            v-if="!reverted"
            text="Revert"
            title="Revert rubocop to default rules"
            @click="revert_to_default"
        />
      </div>
      <div class="lint-container">
        <textarea id="rubocop_config_codemirror"
                  ref="rubocop_config_codemirror"
                  name="rubocop_config"/>
      </div>
    </div>
  </SettingsItem>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import SettingsItem from "../../SettingsItem.vue";
import CodeMirror from "codemirror";
import { nextTick } from "vue";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";
import { init_codemirror } from "../../../../helpers/codemirror/init_codemirror";
import Button from "../../../testa/Button.vue";
import ConfirmDialogue from "../../../testa/confirm_dialogue/confirm_dialgue";

export default defineComponent({
    components: {
        Button,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            component_id: generate_uuid(),
            loaded: false,
            cm: null as CodeMirror.Editor,
            reverted: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        setting_items() {
            return setting_items
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.init_codemirror();
        this.project.client.load_code_lint().then(() => {
            this.cm.setValue(this.project.props.rubocop_config);
            this.loaded = true
            nextTick(() => {
                this.cm.refresh()
                document.dispatchEvent(new CustomEvent('rubocop-config-shown', {
                    detail: {
                        codemirror: this.cm,
                    }
                }))
            })
        })
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save() {
            return this.apply()
        },
        apply() {
            if (!this.loaded) return null;
            if (this.is_viewer_role || this.is_user_role) return null;

            return this.project.client.update_code_lint(this.cm.getValue())
                       .catch(() => toastr.error("Failed to save code lint"))
        },
        init_codemirror() {
            const cm = init_codemirror(this.$refs.rubocop_config_codemirror as HTMLTextAreaElement, this.project, {
                mode: 'text/x-yaml',
                placeholder: 'You can define rubocop rules here',
                readOnly: this.is_viewer_role || this.is_user_role,
                theme: current.theme,
                gutters: ["CodeMirror-lint-markers"],
                lint: true
            })
            cm.setSize('100%', '100%')
            cm.on("change", () => {
                this.reverted = false
            })
            this.cm = cm
        },
        revert_to_default() {
            return ConfirmDialogue.show({
                html: "You are about to revert the current configuration to the default configuration.",
                confirm_color_class: "red",
                confirm_text: "Revert",
                confirm_action: () => {
                    return this.project.client.code_lint_revert_to_default()
                               .then((data) => {
                                   this.cm.setValue(data.rubocop_config)
                                   this.reverted = true
                               })
                }
            })
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .help-container {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 100%;

    a {
      color: var(--button-blue);

    }
  }

  .lint-container {
    height: 100%;
    width: 100%;
    flex-shrink: 1;
  }
}
</style>
