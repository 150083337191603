<template>
  <div class="time-container">
    {{ time }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            time: "",
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        let last_message_under_load_timeout: NodeJS.Timeout = null
        let last_message_offline_timeout: NodeJS.Timeout = null
        faye.subscribe('/time', (data) => {
            this.time = JSON.parse(data).msg

            if (current.websocket_status != "online") current.websocket_status = "online"
            if (last_message_under_load_timeout != null) clearTimeout(last_message_under_load_timeout)
            if (last_message_offline_timeout != null) clearTimeout(last_message_offline_timeout)

            last_message_under_load_timeout = setTimeout(() => {
                current.websocket_status = "under-load"
            }, 3 * 1000)

            last_message_offline_timeout = setTimeout(() => {
                current.websocket_status = "offline"
            }, 30 * 1000)
        });
    },
    unmounted() {
        faye.unsubscribe("/time")
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.time-container {
  margin-inline: 4px;
}
</style>
