import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { Browser } from "../../models/scenario_setting/browser";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class BrowserClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Browser
    declare record: Browser
}
