<template>
  <Modal
      id="play_modal"
      ref="modal"
      :show_cancel="true"
      :is_loading="!loaded"
      :confirm_text="confirm_text"
      :initial_width="modal_width"
      resize="horizontal"
      :form_validator="form_validator"
      min_width="300px"
      :confirm_action="confirm"
      :cancel_on_escape="true"
      @cancel="cancel"
  >
    <!--<editor-fold desc="HEADER">-->
    <template v-if="loaded"
              #header>
      <div class="header">
        <div class="scenarios-count"/>
        <div class="title">
          <ContentEditable
              v-model="play_setting.name"
              :validator="form_validator.register('title', {length: {min: 1, max: 200}})"
          />
        </div>
        <div class="modules-activation-container">
          <div class="modules-activation">
            <ToggleIcon
                v-model="scenario_setting.web_module_active"
                enabled_icon_class="fa-solid fa-globe"
                title="Enable Selenium / Browser testing"
            />

            <ToggleIcon
                v-model="scenario_setting.mobile_module_active"
                enabled_icon_class="fa-solid fa-mobile-alt"
                title="Enable Appium / Phones"
            />
          </div>
        </div>
      </div>
    </template>
    <!--</editor-fold>-->

    <!--<editor-fold desc="BODY">-->
    <template v-if="loaded"
              #body>
      <div
          class="body"
          :class="{'advanced-play': advanced_settings}">

        <!--<editor-fold desc="WEB MODULE">-->
        <div v-if="scenario_setting.web_module_active">
          <div class="field">
            <template
                v-for="(scenario_setting_browser, index) in scenario_setting?.browsers"
                :key="scenario_setting_browser.vue_key">
              <BrowserComponent
                  :browser_type_cols="browser_type_cols"
                  :browser_id_cols="browser_id_cols"
                  :advanced_settings="advanced_settings"
                  :form_validator="form_validator"
                  :scenario_setting_browser="scenario_setting_browser"
                  :index="index"
                  :browsers="browsers"
                  @add-browser="add_browser"
                  @remove-browser="() => remove_browser(scenario_setting_browser)"
              />
            </template>

          </div>
        </div>
        <!--</editor-fold>-->

        <!--<editor-fold desc="MOBILE MODULE">-->
        <div v-if="scenario_setting.mobile_module_active">
          <div class="field">
            <template
                v-for="(scenario_setting_phone, index) in scenario_setting?.phones"
                :key="scenario_setting_phone.vue_key">
              <div class="row align-center">
                <div class="add-remove-item-container"
                     :class="{padded: index == 0}">
                  <ActionIcon
                      v-if="index == 0"
                      id="add_phone_action"
                      color_class="green"
                      title="Add Phone"
                      icon_class="fa-solid fa-plus-circle"
                      @click="add_phone(scenario_setting_phone.type, scenario_setting_phone.apps[0].package, scenario_setting_phone.apps[0].app_id)"
                  />
                  <ActionIcon
                      v-else
                      :id="`remove_phone_action_${index}`"
                      title="Remove Phone"
                      color_class="red"
                      icon_class="fa-solid fa-minus-circle"
                      @click="remove_phone(scenario_setting_phone)"
                  />
                </div>
                <div class="col s3"
                     style="display: flex; flex-direction: column">
                  <Select2
                      v-model="scenario_setting_phone.type"
                      dropdown_parent="#play_modal"
                      :label="index == 0 ? 'Type' : null"
                      :template_result="(state) => Phone.phone_type_option_template(state)"
                      :template_selection="(state) => Phone.phone_type_option_template(state)"
                  >
                    <template v-for="type in Enum.Phone.Type.values.sort()"
                              :key="type">
                      <option :value="type">{{ type }}</option>
                    </template>
                  </Select2>
                </div>
                <div :class="`col ${phone_cols}`">
                  <Select2
                      v-model="scenario_setting_phone.phone_project_id"
                      dropdown_parent="#play_modal"
                      :label="index == 0 ? 'Phones' : null"
                  >

                    <template v-if="scenario_setting_phone.type == Enum.Phone.Type.ANDROID">
                      <option value="">
                        Any Android
                      </option>
                      <option
                          v-for="phone_project in available_phone(android_phone_projects, scenario_setting_phone.phone_project_id)"
                          :key="phone_project.props.id"
                          :value="phone_project.props.id">
                        {{ phone_project.phone.props.name }}
                      </option>
                    </template>
                    <template v-if="scenario_setting_phone.type == Enum.Phone.Type.IPHONE">
                      <option value="">
                        Any iPhone
                      </option>
                      <option
                          v-for="phone_project in available_phone(iphone_phone_projects, scenario_setting_phone.phone_project_id)"
                          :key="phone_project.props.id"
                          :value="phone_project.props.id">
                        {{ phone_project.phone.props.name }}
                      </option>
                    </template>
                    <template v-if="scenario_setting_phone.type == Enum.Phone.Type.SIMULATOR">
                      <option value="">
                        Any Simulator
                      </option>
                      <option
                          v-for="phone_project in available_phone(simulator_phone_projects, scenario_setting_phone.phone_project_id)"
                          :key="phone_project.props.id"
                          :value="phone_project.props.id">
                        {{ phone_project.phone.props.name }}
                      </option>
                    </template>
                  </Select2>
                </div>
                <div :class="`col vertical ${phone_app_cols} phone-apps-container`">
                  <template
                      v-for="(scenario_setting_phone_app, app_index) in scenario_setting_phone.apps"
                      :key="scenario_setting_phone_app.vue_key">
                    <div class="row">
                      <div class="add-remove-item-container"
                           :class="{padded: app_index == 0}">
                        <ActionIcon
                            v-if="app_index == 0"
                            :id="`add_phone_${index}_app`"
                            icon_class="fa-solid fa-plus-circle"
                            title="Add App"
                            color_class="green"
                            @click="add_phone_app(scenario_setting_phone)"
                        />
                        <ActionIcon
                            v-else
                            icon_class="fa-solid fa-minus-circle"
                            title="Remove App"
                            color_class="red"
                            @click="remove_phone_app(scenario_setting_phone, scenario_setting_phone_app)"
                        />
                      </div>
                      <div class="col s6"
                           style="padding-left: 0">
                        <Select2 v-model="scenario_setting_phone_app.package"
                                 dropdown_parent="#play_modal"
                                 :label="(app_index == 0 && index == 0) ? 'Package' : null"
                                 :validator="form_validator.register(`phone_app_package_${index}`, {presence: true})"
                        >
                          <template v-if="scenario_setting_phone.type == Enum.Phone.Type.ANDROID">
                            <option v-for="app_package in android_packages"
                                    :key="app_package"
                                    :value="app_package">
                              {{ app_package }}
                            </option>
                          </template>
                          <template v-if="scenario_setting_phone.type == Enum.Phone.Type.IPHONE">
                            <option v-for="app_package in iphone_packages"
                                    :key="app_package"
                                    :value="app_package">
                              {{ app_package }}
                            </option>
                          </template>
                          <template v-if="scenario_setting_phone.type == Enum.Phone.Type.SIMULATOR">
                            <option v-for="app_package in simulator_packages"
                                    :key="app_package"
                                    :value="app_package">
                              {{ app_package }}
                            </option>
                          </template>
                        </Select2>
                      </div>
                      <div class="col s6">
                        <Select2 v-model="scenario_setting_phone_app.app_id"
                                 dropdown_parent="#play_modal"
                                 :label="(app_index == 0 && index == 0) ? 'Version' : null"
                        >
                          <option value="">Latest</option>
                          <template v-if="scenario_setting_phone.type == Enum.Phone.Type.ANDROID">
                            <option
                                v-for="android_apps in android_versions[scenario_setting_phone_app.package]"
                                :key="android_apps.props.id"
                                :value="android_apps.props.id">
                              {{ android_apps.props.name }}
                            </option>
                          </template>
                          <template v-if="scenario_setting_phone.type == Enum.Phone.Type.IPHONE">
                            <option
                                v-for="iphone_apps in iphone_versions[scenario_setting_phone_app.package]"
                                :key="iphone_apps.props.id"
                                :value="iphone_apps.props.id">
                              {{ iphone_apps.props.name }}
                            </option>
                          </template>
                          <template v-if="scenario_setting_phone.type == Enum.Phone.Type.SIMULATOR">
                            <option
                                v-for="simulator_apps in simulator_versions[scenario_setting_phone_app.package]"
                                :key="simulator_apps.props.id"
                                :value="simulator_apps.props.id">
                              {{ simulator_apps.props.name }}
                            </option>
                          </template>
                        </Select2>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--</editor-fold>-->

        <div class="row play-settings">
          <!--<editor-fold desc="ON ERROR">-->
          <div class="col s6">
            <Select2
                v-model="play_setting.on_error"
                dropdown_parent="#play_modal"
                label="On Error"
                :validator="form_validator.register('on_error', {presence: true})"
            >
              <option v-for="on_error in Enum.Play.Setting.OnError.options_for_scenario"
                      :key="on_error[1]"
                      :value="on_error[1]">
                {{ on_error[0] }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="VARIABLE SETS">-->
          <div class="col s6">
            <Select2 v-model="play_setting.variable_set_id"
                     dropdown_parent="#play_modal"
                     label="Variable set"
                     :validator="form_validator.register('variable_set', {presence: true})"
            >
              <option v-for="variable_set in variable_sets"
                      :key="variable_set.props.id"
                      :value="variable_set.props.id">
                {{ variable_set.props.name }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="SELECTED NO RESET">-->
          <div v-show="scenario_setting.mobile_module_active"
               class="col s6">
            <Select2 v-model="scenario_setting.selected_no_reset"
                     dropdown_parent="#play_modal"
                     label="Application reset"
                     :validator="form_validator.register('selected_no_reset', {presence: true})"
            >
              <option v-for="reset in Enum.Scenario.Setting.SelectedNoReset.options"
                      :key="reset[1]"
                      :value="reset[1]">
                {{ reset[0] }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="LOCALE">-->
          <div v-show="scenario_setting.mobile_module_active"
               class="col s6">
            <Select2 v-model="locale"
                     dropdown_parent="#play_modal"
                     label="Locale">
              <option value="">-- Default --</option>
              <option v-for="locale_option in Enum.Locale.values"
                      :key="locale_option"
                      :value="locale_option">
                {{ locale_option }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->

          <!--<editor-fold desc="ADVANCED SETTINGS">-->
          <div class="col s6">
            <Checkbox v-model="advanced_settings"
                      label="Advanced Settings"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="PROXY">-->
          <div v-show="show_proxy_dropdown && (scenario_setting.proxy_id != null || advanced_settings)"
               class="col s6 advanced-item">
            <Select2 v-model="scenario_setting.proxy_id"
                     dropdown_parent="#play_modal"
                     label="Proxy">
              <option value="">
                -- No Proxy --
              </option>
              <option v-for="proxy in proxies"
                      :key="proxy.props.id"
                      :value="proxy.props.id"
              >
                {{ proxy.props.name }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="VIDEO RECORDING">-->
          <div v-show="web_or_mobile_enabled && (play_setting.enable_video_recording || advanced_settings)"
               class="col s6 advanced-item">
            <Checkbox
                v-model="play_setting.enable_video_recording"
                label="Video Recording"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="SLACK CHANNEL">-->
          <div v-show="advanced_settings || play_setting.slack_channel_id"
               class="col s6 advanced-item">
            <Select2 v-model="play_setting.slack_channel_id"
                     dropdown_parent="#play_modal"
                     label="Slack channel">
              <option value="">
                -- Don't Report --
              </option>
              <option v-for="slack_channel in slack_channels"
                      :key="slack_channel.id">
                {{ slack_channel.name }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="REUSE BROWSER PROFILE">-->
          <div v-show="advanced_settings"
               class="col s6 advanced-item">
            <Checkbox v-model="play_setting.reuse_browsers"
                      label="Reuse Browser Profile">
              <span class="beta-testing-feature"
                    title="Experimental feature">
                <i class="fas fa-vial"/>
              </span>
            </Checkbox>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="REPORT_TO_XRAY">-->
          <div v-if="project_version_setting.props.xray_enabled"
               v-show="advanced_settings"
               class="col s6 advanced-item">
            <Checkbox v-model="play_setting.report_to_xray"
                      label="Report to xray"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="PERFORM_LATER">-->
          <div v-if="project_version_setting.props.schedule_module_enabled"
               v-show="advanced_settings"
               class="col s6 advanced-item">
            <Checkbox v-model="play_setting.perform_later"
                      label="Perform later"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="SCHEDULE SETTINGS">-->
          <div v-if="play_setting.perform_later"
               v-show="advanced_settings"
               class="col s12 advanced-item schedule-settings">
            <div class="row"
                 style="margin: 0"
            >
              <div class="col s12"
                   style="padding-inline: 0"
              >
                <div class="schedule-setting-item"
                     style="margin-left: 0"
                >
                  <Select2 v-model="play_setting.delay_preposition"
                           dropdown_parent="#play_modal"
                           :validator="form_validator.register('delay_preposition', {presence: true})"
                  >
                    <option :value="Enum.Play.Setting.DelayPreposition.IN">
                      {{
                        Enum.Play.Setting.DelayPreposition.IN
                      }}
                    </option>
                    <option :value="Enum.Play.Setting.DelayPreposition.ON">
                      {{
                        Enum.Play.Setting.DelayPreposition.ON
                      }}
                    </option>
                  </Select2>
                </div>
                <div v-if="play_setting.delay_preposition == Enum.Play.Setting.DelayPreposition.IN"
                     class="schedule-setting-item">
                  <Input v-model="play_setting.play_in_amount"
                         type="number"
                         :min="0"
                         :max="Enum.Play.Setting.PlayInAmount.MAX_VALUE"
                         :validator="form_validator.register('play_in_amount', {min: 0, max: Enum.Play.Setting.PlayInAmount.MAX_VALUE, presence: true})"
                  />
                </div>
                <div v-if="play_setting.delay_preposition == Enum.Play.Setting.DelayPreposition.IN"
                     class="schedule-setting-item">
                  <Select2 v-model="play_setting.play_in_time_units"
                           dropdown_parent="#play_modal"
                           :validator="form_validator.register('play_in_time_units', {presence: true})"
                  >
                    <option :value="Enum.Play.Setting.PlayInTimeUnits.MINUTES">
                      {{
                        Enum.Play.Setting.PlayInTimeUnits.MINUTES
                      }}
                    </option>
                    <option :value="Enum.Play.Setting.PlayInTimeUnits.HOURS">
                      {{
                        Enum.Play.Setting.PlayInTimeUnits.HOURS
                      }}
                    </option>
                    <option :value="Enum.Play.Setting.PlayInTimeUnits.DAYS">
                      {{
                        Enum.Play.Setting.PlayInTimeUnits.DAYS
                      }}
                    </option>
                  </Select2>
                </div>
                <div v-if="play_setting.delay_preposition == Enum.Play.Setting.DelayPreposition.ON"
                     class="schedule-setting-item">
                  <DatePicker
                      v-model="play_setting.perform_target_datetime"
                      :timepicker="true"
                      :validator="form_validator.register('perform_target_datetime', {presence: true})"
                  />
                </div>
                <div class="schedule-setting-item">
                  <Checkbox v-model="play_setting.repeat"
                            label="Every"/>
                </div>
                <div v-if="play_setting.repeat"
                     class="schedule-setting-item">
                  <Input v-model="play_setting.repeat_every_amount"
                         type="number"
                         :min="0"
                         :max="Enum.Play.Setting.RepeatEveryAmount.MAX_VALUE"
                         :validator="form_validator.register('repeat_every_amount', {min: 0, max: Enum.Play.Setting.PlayInAmount.MAX_VALUE, presence: true})"
                  />
                </div>
                <div v-if="play_setting.repeat"
                     class="schedule-setting-item">
                  <Select2 v-model="play_setting.repeat_every_time_units"
                           dropdown_parent="#play_modal"
                           :validator="form_validator.register('repeat_every_time_units', {presence: true})"
                  >
                    <option :value="Enum.Play.Setting.RepeatEveryTimeUnits.MINUTES">
                      {{ Enum.Play.Setting.RepeatEveryTimeUnits.MINUTES }}
                    </option>
                    <option :value="Enum.Play.Setting.RepeatEveryTimeUnits.HOURS">
                      {{ Enum.Play.Setting.RepeatEveryTimeUnits.HOURS }}
                    </option>
                    <option :value="Enum.Play.Setting.RepeatEveryTimeUnits.DAYS">
                      {{ Enum.Play.Setting.RepeatEveryTimeUnits.DAYS }}
                    </option>
                  </Select2>
                </div>
              </div>
            </div>

          </div>
          <!--</editor-fold>-->


        </div>
      </div>
    </template>
    <!--</editor-fold>-->

    <!--<editor-fold desc="FOOTER">-->
    <template v-if="loaded"
              #footer_left_box>
      <div
          v-if="form_validator.valid && replay_play == null"
          class="curl-request"
          @click.stop="copy_curl_request">
        cURL request
        <span v-if="curl_copied"
              style="color: var(--button-green)">
          - Copied
        </span>
      </div>
    </template>
    <template v-if="loaded"
              #footer_right_box>
      <ActionIcon
          v-if="scenario_setting_changed || play_setting_changed"
          icon_class="fa-regular fa-save"
          title="Save settings"
          :form_validator="form_validator"
          color_class="green"
          :click_action="save_settings"
      />
    </template>
    <!--</editor-fold>-->
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import { PlaySettingProps } from "../../../vue_record/models/play/play_setting";
import { ExtendedScenarioSettingProps } from "../../../vue_record/models/scenario_setting";
import Select2 from "../../testa/Select2.vue"
import DatePicker from "../../testa/DatePicker.vue";
import Input from "../../testa/Input.vue";
import Checkbox from "../../testa/Checkbox.vue";
import ContentEditable from "../../testa/ContentEditable.vue";
import Modal from "../../testa/Modal.vue";
import { Play } from "../../../vue_record/models/play/play";
import { Proxy } from "../../../vue_record/models/proxy"
import { PhoneProject } from "../../../vue_record/models/phone_project";
import { Browser } from "../../../vue_record/models/browser";
import { VariableSet } from "../../../vue_record/models/variable_set";
import { FormValidator } from "../../../helpers/validator/form_validator";
import { App } from "../../../vue_record/models/app";
import { ScenarioSetting } from "../../../vue_record/models/scenario_setting";
import { PlaySetting } from "../../../vue_record/models/play/play_setting";
import { Scenario } from "../../../vue_record/models/scenario";
import { generate_uuid } from "../../../helpers/generate/generate_uuid";
import { EnumPhoneType } from "../../../auto_generated/enums";
import { PlayScenario } from "../../../vue_record/models/play/play_scenario";
import {
    slack_joined_channels
} from "../../../helpers/client/settings/project_settings/integrations/slack/joined_channels";
import { PlayScenarioModalResponse } from "../../../vue_record/models/play/play_scenario";
import { PlayModalExtendedScenarioSettingProps } from "../../../vue_record/models/play/play_scenario";
import { PlayModalBrowserProps } from "../../../vue_record/models/play/play_scenario";
import { PlayModalPhoneProps } from "../../../vue_record/models/play/play_scenario";
import { PlayModalPhoneAppProps } from "../../../vue_record/models/play/play_scenario";
import { copy_curl_request } from "../../../helpers/play/copy_curl_request";
import ToggleIcon from "../../testa/ToggleIcon.vue";
import ActionIcon from "../../testa/ActionIcon.vue";
import { ProjectVersion } from "../../../vue_record/models/project_version";
import { ProjectVersionSetting } from "../../../vue_record/models/project_version_setting";
import { Phone } from "../../../vue_record/models/phone";
import BrowserComponent from "./play_scenario/Browser.vue";
import { Dictionary } from "../../../libs/monkey_patches/array";

type SlackChannel = {
    name: string,
    id: string
}


export default defineComponent({
    components: {
        BrowserComponent,
        ActionIcon,
        ToggleIcon,
        DatePicker,
        Input,
        Checkbox,
        Select2,
        ContentEditable,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        scenario_id: {
            type: Number,
            required: false,
            default: null,
        },
        replay_play: {
            type: Object as PropType<Play>,
            required: false,
            default: null,
        },
    },
    // </editor-fold>
    // <editor-fold desc="DATA">
    data() {
        return {
            advanced_settings: false,
            confirm_text: "Play",
            loaded: false,
            data: null as PlayScenarioModalResponse,
            original_scenario_setting: null as PlayModalExtendedScenarioSettingProps,
            scenario_setting: null as PlayModalExtendedScenarioSettingProps,
            original_play_setting: null as PlaySettingProps,
            play_setting: null as PlaySettingProps,
            browser_type_cols: "s6",
            browser_id_cols: "s6",
            phone_cols: "s3",
            phone_app_cols: "s6",
            modal_width: "1000px",
            all_browsers: Browser.get_scope(),
            locale: null,
            slack_channels: [] as SlackChannel[],
            form_validator: new FormValidator(),
            curl_copied: false,
            scenario_setting_changed: false,
            play_setting_changed: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Phone() {
            return Phone
        },
        project_version_id() {
            if (this.replay_play != null) return this.replay_play.props.project_version_id
            else return Scenario.find(this.scenario_id).props.project_version_id
        },
        project_id() {
            return ProjectVersion.find(this.project_version_id).props.project_id
        },
        project_version_setting(): ProjectVersionSetting {
            return ProjectVersionSetting.where({ project_version_id: this.project_version_id }).first()
        },
        Enum() {
            return Enum
        },
        Browser() {
            return Browser
        },
        proxies() {
            return Proxy.get_scope().where({ project_id: this.project_id }).order("name").toArray()
        },
        variable_sets() {
            return VariableSet.get_scope().where({ project_version_id: this.project_version_id }).order("name").toArray()
        },
        browsers(): Dictionary<Browser[]> {
            return this.all_browsers.is_active.order("label", "desc", "version").group_by("props.name")
        },
        phone_projects() {
            return PhoneProject.where({ project_id: this.project_id })
        },
        simulator_phone_projects(): PhoneProject[] {
            return this.phone_projects.simulators.online.order(".phone.props.name").toArray()
        },
        iphone_phone_projects(): PhoneProject[] {
            return this.phone_projects.iphones.online.order(".phone.props.name").toArray()
        },
        simulator_packages() {
            return this.apps.zips.pluck("props").pluck("package").uniq().sort()
        },
        simulator_versions() {
            return this.apps
                       .zips
                       .order("version_name", "desc", "version")
                       .order("version_code", "desc")
                       .group_by("props.package")
        },
        iphone_packages() {
            return this.apps.ipas.pluck("props").pluck("package").uniq().sort()
        },
        iphone_versions() {
            return this.apps
                       .ipas
                       .order("version_name", "desc", "version")
                       .order("version_code", "desc")
                       .group_by("props.package")
        },
        apps() {
            return App.get_scope().where({ project_id: this.project_id })
        },
        android_packages() {
            return this.apps.apks.pluck("props").pluck("package").uniq().sort()
        },
        android_versions() {
            return this.apps
                       .apks
                       .order("version_name", "desc", "version")
                       .order("version_code", "desc")
                       .group_by("props.package")
        },
        android_phone_projects(): PhoneProject[] {
            return this.phone_projects.androids.online.order(".phone.props.name").toArray()
        },
        web_or_mobile_enabled() {
            return this.scenario_setting.web_module_active || this.scenario_setting.mobile_module_active
        },
        show_proxy_dropdown() {
            return this.web_or_mobile_enabled && this.project_version_setting.props.proxies_module_enabled
        },
        sanitized_scenario_setting() {
            const scenario_setting = _.cloneDeep(this.scenario_setting)

            if (scenario_setting.mobile_module_active) {
                scenario_setting.phones.forEach(phone => {
                    delete phone.vue_key
                    phone.apps.forEach(app => delete app.vue_key)
                })
            } else {
                // do not send phones if the module is disabled
                scenario_setting.phones = []
            }

            if (scenario_setting.web_module_active) {
                scenario_setting.browsers.forEach(browser => {
                    delete browser.vue_key
                    if (browser.is_mobile) {
                        let emulation_profile = Browser.emulation_profiles.find(e => e.name == browser.emulation_profile_name)
                        if (emulation_profile == null) emulation_profile = Browser.emulation_profiles[0]
                        browser.pixel_ratio = emulation_profile.pixel_ratio
                        browser.user_agent = emulation_profile.user_agent
                    }
                })
            } else {
                // do not send browsers if the module is disabled
                scenario_setting.browsers = []
            }

            return scenario_setting as ExtendedScenarioSettingProps
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'advanced_settings'() {
            if (this.advanced_settings) {
                this.browser_type_cols = "s3"
                this.browser_id_cols = "s3"
            } else {
                this.browser_type_cols = "s6"
                this.browser_id_cols = "s6"
            }
        },
        'locale'() {
            this.scenario_setting?.phones?.forEach(phone => {
                phone.locale = this.locale
            })
            this.scenario_setting?.browsers?.forEach(browser => {
                browser.locale = this.locale
            })
        },
        'scenario_setting.web_module_active': {
            immediate: true,
            handler(new_val) {
                if (new_val && this.scenario_setting.browsers.length == 0) this.add_browser();
            },
        },
        'scenario_setting.mobile_module_active': {
            immediate: true,
            handler(new_val) {
                if (new_val && this.scenario_setting.phones.length == 0) {
                    if (this.android_packages.length > 0) {
                        this.add_phone(Enum.Phone.Type.ANDROID, this.android_packages[0], null);
                    } else if (this.iphone_packages.length > 0) {
                        this.add_phone(Enum.Phone.Type.IPHONE, this.iphone_packages[0], null);
                    } else {
                        this.add_phone(Enum.Phone.Type.SIMULATOR, this.simulator_packages[0], null);
                    }
                }
            },
        },
        'scenario_setting.phones': {
            deep: true,
            immediate: true,
            handler() {
                this.scenario_setting?.phones?.forEach((phone, index) => {
                    phone.index = index
                    if (phone.phone_project_id == null) {
                        switch (phone.type) {
                            case Enum.Phone.Type.ANDROID:
                                phone.scope = Enum.Scenario.Setting.Phone.Scope.ANY_ANDROID
                                break;
                            case Enum.Phone.Type.IPHONE:
                                phone.scope = Enum.Scenario.Setting.Phone.Scope.ANY_IPHONE
                                break;
                            case Enum.Phone.Type.SIMULATOR:
                                phone.scope = Enum.Scenario.Setting.Phone.Scope.ANY_SIMULATOR
                                break;
                            default:
                                throw new Error(`Unsupported phone type ${phone.type}`)
                        }
                    } else {
                        phone.scope = Enum.Scenario.Setting.Phone.Scope.SPECIFIC
                    }

                    phone.apps.forEach((app, index) => {
                        app.index = index
                        if (app.app_id == null) {
                            app.scope = Enum.Scenario.Setting.Phone.App.Scope.LATEST
                        } else {
                            app.scope = Enum.Scenario.Setting.Phone.App.Scope.SPECIFIC
                        }
                        switch (phone.type) {
                            case Enum.Phone.Type.ANDROID:
                                app.type = Enum.App.ANDROID
                                break;
                            case Enum.Phone.Type.IPHONE:
                                app.type = Enum.App.IPHONE
                                break;
                            case Enum.Phone.Type.SIMULATOR:
                                app.type = Enum.App.SIMULATOR
                                break;
                            default:
                                throw new Error(`Unsupported phone type ${phone.type}`)
                        }
                    })
                })
            },
        },
        'scenario_setting.browsers': {
            deep: true,
            immediate: true,
            handler() {
                this.scenario_setting?.browsers?.forEach((browser, index) => {
                    browser.index = index
                    if (browser.browser_id == null) {
                        if (browser.type == Enum.Browser.CHROME) {
                            browser.scope = Enum.Scenario.Setting.Browser.Scope.LATEST_CHROME
                        } else if (browser.type == Enum.Browser.FIREFOX) {
                            browser.scope = Enum.Scenario.Setting.Browser.Scope.LATEST_FIREFOX
                        }
                    } else {
                        browser.scope = Enum.Scenario.Setting.Browser.Scope.SPECIFIC
                    }
                })
            },
        },
        'play_setting.perform_later'() {
            if (this.play_setting?.perform_later) {
                this.confirm_text = "Schedule"
            } else {
                this.confirm_text = "Play"
            }
        },
        "scenario_setting": {
            deep: true,
            handler(_new_value, old_value) {
                if (old_value == null) return
                if (!this.loaded) return
                const is_equal = _.isEqual(this.scenario_setting, this.original_scenario_setting)
                this.scenario_setting_changed = !is_equal
            },
        },
        "play_setting": {
            deep: true,
            handler(_new_value, old_value) {
                if (old_value == null) return
                if (!this.loaded) return
                const is_equal = _.isEqual(this.play_setting, this.original_play_setting)
                this.play_setting_changed = !is_equal
            },
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.load_data()
        this.load_slack_channels()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        load_data() {
            let promise: Promise<PlayScenarioModalResponse>;
            if (this.scenario_id) {
                promise = PlayScenario.ClientClass.play_scenario_modal(this.scenario_id)
            } else {
                promise = this.replay_play.client.replay() as Promise<PlayScenarioModalResponse>
            }

            promise.then(data => {
                this.data = data

                Scenario.new(data.scenario)
                PlaySetting.new(data.play_setting)
                ScenarioSetting.new(data.scenario_setting)

                this.scenario_setting = data.scenario_setting
                delete this.scenario_setting.proxy

                this.play_setting = data.play_setting

                this.play_setting.perform_later = false
                if (this.replay_play == null) this.play_setting.name = data.scenario.name
                if (this.play_setting.variable_set_id == null) {
                    this.play_setting.variable_set_id = VariableSet.get_scope().default.first()?.props?.id
                }
                this.original_play_setting = JSON.parse(JSON.stringify(data.play_setting))
                this.scenario_setting?.browsers?.forEach(browser_props => {
                    browser_props.vue_key = generate_uuid()
                    this.locale = browser_props.locale
                })
                this.scenario_setting?.phones?.forEach(phone_props => {
                    phone_props.vue_key = generate_uuid()
                    this.locale = phone_props.locale

                    phone_props.apps.forEach(app_props => {
                        app_props.vue_key = generate_uuid()
                    })
                })

                this.original_scenario_setting = JSON.parse(JSON.stringify(data.scenario_setting))
                this.loaded = true;
            }).catch(() => {
                this.cancel()
            })
        },
        load_slack_channels() {
            slack_joined_channels().then((data) => {
                $.each(data, (_i, item) => {
                    this.slack_channels.push({
                        name: item[1],
                        id: item[0],
                    })
                });
            })
        },
        async confirm() {
            const data = await Play.ClientClass.add_to_queue(this.data.id, Enum.Play.Type.SCENARIO, this.play_setting, this.play_setting.id, this.replay_play?.key(), this.sanitized_scenario_setting);
            if (data.success == true) {
                toastr.success(data.response);
                this.cancel();
            } else {
                toastr.error(data.response);
            }
        },
        cancel() {
            this.$.appContext.app.unmount();
        },
        copy_curl_request() {
            $.get("/api/generate_new_token", (data) => {
                copy_curl_request(data.token, this.data.scenario.id, Enum.Play.Type.SCENARIO, this.play_setting)
                this.curl_copied = true
                setTimeout(() => {
                    this.curl_copied = false
                }, 3000);
            });
        },
        available_phone(phone_projects: PhoneProject[], current_phone_project_id: number) {
            const taken_phone_project_ids = this.scenario_setting.phones
                                                .map(scenario_setting_phone => scenario_setting_phone.phone_project_id)
                                                .filter(phone_project_id => phone_project_id != null)
                                                .filter(phone_project_id => phone_project_id != current_phone_project_id)
            return phone_projects.filter(phone_project => !taken_phone_project_ids.includes(phone_project.props.id))
        },
        add_browser() {
            this.scenario_setting.browsers.push({
                pixel_ratio: 0,
                vue_key: generate_uuid(),
                id: null,
                scenario_setting_id: this.scenario_setting.id,
                type: Enum.Browser.CHROME,
                browser_id: null,
                index: null,
                is_mobile: false,
                scope: Enum.Scenario.Setting.Browser.Scope.LATEST_CHROME,
                width: 1366,
                height: 768,
                emulation_profile_name: null,
                user_agent: null,
                top_left_x: null,
                top_left_y: null,
                network_log_path: null,
                javascript_log_path: null,
                locale: null,
            })
        },
        remove_browser(scenario_setting_browser: PlayModalBrowserProps) {
            this.scenario_setting.browsers = this.scenario_setting
                                                 .browsers
                                                 .filter(browser => browser.vue_key != scenario_setting_browser.vue_key)
        },
        add_phone(type: EnumPhoneType, app_package: string, app_id: number) {
            let app_type;
            switch (type) {
                case Enum.Phone.Type.ANDROID:
                    app_type = Enum.App.ANDROID
                    break;
                case Enum.Phone.Type.IPHONE:
                    app_type = Enum.App.IPHONE
                    break;
                case Enum.Phone.Type.SIMULATOR:
                    app_type = Enum.App.SIMULATOR
                    break;
                default:
                    throw new Error(`Unsupported phone type (${type})`)
            }
            this.scenario_setting.phones.push({
                downscaled_height: null,
                downscaled_width: null,
                id: null,
                index: null,
                locale: null,
                phone_project_id: null,
                scale: null,
                scenario_setting_id: this.scenario_setting.id,
                scope: null,
                top_left_x: null,
                top_left_y: null,
                type,
                vue_key: generate_uuid(),
                apps: [
                    {
                        id: null,
                        scenario_setting_phone_id: null,
                        index: null,
                        package: app_package,
                        scope: null,
                        app_id,
                        vue_key: generate_uuid(),
                        type: app_type,
                    },
                ],
            })
        },
        add_phone_app(scenario_setting_phone: PlayModalPhoneProps) {
            let app_type;
            switch (scenario_setting_phone.type) {
                case Enum.Phone.Type.ANDROID:
                    app_type = Enum.App.ANDROID
                    break;
                case Enum.Phone.Type.IPHONE:
                    app_type = Enum.App.IPHONE
                    break;
                case Enum.Phone.Type.SIMULATOR:
                    app_type = Enum.App.SIMULATOR
                    break;
                default:
                    throw new Error(`Unsupported phone type (${scenario_setting_phone.type})`)
            }
            scenario_setting_phone.apps.push({
                app_id: 0,
                id: null,
                index: null,
                package: "",
                scenario_setting_phone_id: scenario_setting_phone.id,
                scope: null,
                type: app_type,
                vue_key: generate_uuid(),
            });
        },
        remove_phone_app(scenario_setting_phone: PlayModalPhoneProps, scenario_setting_phone_app: PlayModalPhoneAppProps) {
            scenario_setting_phone.apps = scenario_setting_phone.apps.filter(app => app.vue_key != scenario_setting_phone_app.vue_key)
        },
        remove_phone(scenario_setting_phone: PlayModalPhoneProps) {
            this.scenario_setting.phones = this.scenario_setting
                                               .phones
                                               .filter(phone => phone.vue_key != scenario_setting_phone.vue_key)
        },
        async save_settings() {
            await Play.ClientClass.save_settings(this.data.id, Enum.Play.Type.SCENARIO, this.play_setting, this.sanitized_scenario_setting);
            this.scenario_setting_changed = false;
            this.play_setting_changed = false;
            this.original_play_setting = JSON.parse(JSON.stringify(this.play_setting));
            this.original_scenario_setting = JSON.parse(JSON.stringify(this.scenario_setting));
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss">
#play_modal {
  .header {
    display: flex;
    flex-direction: row;
    padding: 0;

    .scenarios-count {
      flex-shrink: 0;
      width: 50px;
    }

    .title {
      text-align: center;
      font-size: 25px;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .modules-activation-container {
      position: relative;
      flex-shrink: 0;
      width: 50px;
      display: flex;
      align-items: center;
      margin-right: 15px;

      .modules-activation {
        right: 0;
        position: absolute;
        display: flex;
        font-size: 18px;
        align-items: baseline;

        span {
          cursor: pointer;
          margin-inline: 4px;
          font-size: 20px;
        }
      }
    }
  }

  $side-item-width: 40px;
  $add-remove-item-width: 25px;
  .body {
    .phone-apps-container {
      .row {
        margin-bottom: 0;

        .add-remove-item-container {
          left: calc($add-remove-item-width * -1 - 5px);
        }
      }
    }

    .row {
      margin-bottom: 10px;
      position: relative;

      //.advanced-item {
      //  display: none;
      //}

      .add-remove-item-container {
        display: none;
        height: 100%;
        width: $add-remove-item-width;
        position: absolute;
        left: -$add-remove-item-width;
        justify-content: center;
        align-items: end;
      }

      &.play-settings {
        .col {
          min-height: 55px;
          align-items: end;
          // do not put display property here
        }

        .schedule-settings {
          flex-direction: row;
          align-items: center;

          div.schedule-setting-item {
            margin-inline: 5px;
            width: 100%;
          }
        }
      }
    }

    .edit-variable-set {
      cursor: pointer;
      aspect-ratio: 1;
      display: flex;
      margin-top: 18px;
      font-size: 1.3em;
    }
  }

  .body {
    &.advanced-play {
      .row {
        margin-left: $add-remove-item-width;
        width: calc(100% - $add-remove-item-width);

        .add-remove-item-container {
          display: flex;
          align-items: center;

          &.padded {
            top: 5px;
          }
        }

        .advanced-item {
          display: flex;
        }
      }


    }
  }

  .curl-request {
    color: var(--font-color-secondary);
    font-size: 0.6em;
    cursor: pointer;
    align-self: end;
  }

  .first-row-label-padding {
    padding-top: 7px;
  }

  .browser-is-mobile-toggle,
  .mobile-type-toggle {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    //padding: 3px;
    font-size: 1.3em;
  }

  .save-icon {
    padding-right: 12px;
    font-size: 1.2em;

    &:not(.invalid) {
      cursor: pointer;
      color: var(--button-green);
    }

    &.invalid {
      cursor: not-allowed;
      color: var(--button-red)
    }
  }
}
</style>
