import { AppiumScreenControl } from "./appium_screen_control";
import { send_ws } from "../../generic/send_ws";
import { AscAction } from "./asc_action";

type MoveAction = {
    time: Date,
    coords: Coords
}

type Coords = {
    x: number,
    y: number
}

export class AscMouseAction extends AscAction {
    type = Enum.Play.ScreenAction.MOUSE;

    move: MoveAction[] = [];

    down_time: Date
    down_coords: Coords

    up_coords: Coords = null;
    up_time: Date = null;

    asc: AppiumScreenControl

    constructor(asc: AppiumScreenControl, coords: Coords) {
        super()
        this.asc = asc;
        this.down_time = new Date();
        this.down_coords = coords;
    }

    perform() {
        const data = {
            udid: this.asc.phone.props.udid,
            action: Enum.Play.Action.SCREEN_CONTROL,
            screen_action: {
                type: this.type,
                down: {
                    time: this.down_time,
                    coords: this.down_coords
                },
                move: this.move.sort(function(a, b) {
                    return a.time.getTime() - b.time.getTime()
                }),
                up: {
                    time: this.up_time,
                    coords: this.up_coords,
                }
            }
        };

        send_ws(this.asc.backend_ws_channel, data)
    }

    add_move(coords: Coords, time: Date) {
        if (this.down_time.getTime() - time.getTime() > 0) return;
        if (this.up_time != null && this.up_time.getTime() - time.getTime() < 0) return;
        this.move.push({ time, coords })
    }

    add_up(coords: Coords) {
        this.up_time = new Date();
        this.up_coords = coords;
    }
}
