import { RecordOpts, VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { ProjectVersionClient } from "../clients/project_version_client";
import { ProjectVersionScope } from "../scopes/project_version_scope";
import { Storager } from "../../helpers/api_wrappers/storager";
import { computed } from "../../helpers/vue/computed";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { watch } from "vue";
import { ProjectVersionSetting } from "./project_version_setting";
import { Play } from "./play/play";

// <editor-fold desc="TYPES">
export interface ProjectVersionProps extends Props {
    id: number
    project_id: number
    version: string
    created_at: Date
    updated_at: Date
    is_private: boolean
    user_id: number
    main: boolean
    merge_approvals_required: number
}
export type QuerifiedProjectVersionProps = QuerifyProps<ProjectVersionProps>
export type ProjectVersionCreateProps = Omit<ProjectVersionProps, 'id'>
export type ProjectVersionUpdateProps = Partial<ProjectVersionProps>

export interface ProjectVersionState extends State {}
export interface ProjectVersionComputed extends Computed {}
export interface ProjectVersionStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class ProjectVersion extends VueRecord {
    ['constructor']: typeof ProjectVersion

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ProjectVersionClient
    static ScopeClass = ProjectVersionScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ProjectVersionStaticState = reactive<ProjectVersionStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_id", "user_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ProjectVersion> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ProjectVersion>> = {}

    static field_validators: ModelValidatorOpts<ProjectVersionProps> = {
        version: {
            presence: true,
            length: {
                min: 1,
                max: 100,
            },
        },
        merge_approvals_required: {
            min: 0,
            presence: true,
        },
    }

    static resource_name = Enum.Resource.Label.PROJECT_VERSION
    static resource_id = Enum.Resource.Id.PROJECT_VERSION
    static icon_class = "fa-solid fa-cube"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ProjectVersionClient
    declare props: ProjectVersionProps;
    declare state: ProjectVersionState;
    declare computed: ProjectVersionComputed;
    declare storager: Storager;
    declare web_type_storager: Storager;
    // </editor-fold>

    constructor(props: ProjectVersionProps, opts: RecordOpts) {
        super(props, opts);
        this.storager = computed(() => {
            if (current.project == null) {
                return Storager.global.new_scope("project_version", this.key())
            } else {
                return current.project.storager.new_scope("project_version", this.key())
            }
        })
        this.web_type_storager = computed(() => {
            if (current.project == null) {
                return Storager.web_type.new_scope("project_version", this.key())
            } else {
                return current.project.web_type_storager.new_scope("project_version", this.key())
            }
        })
    }

    // <editor-fold desc="HOOKS">
    after_create() {
        super.after_create();
        on_dom_content_loaded(() => {
            ProjectVersionSetting.sync(`/sync/project_version/${this.key()}/project_version_settings`)
            if (web.is_reports) {
                Play.sync(`/sync/project_version/${this.key()}/plays/*`);
            }
        })
    }

    before_unload() {
        on_dom_content_loaded(() => {
            ProjectVersionSetting.unsync(`/sync/project_version/${this.key()}/project_version_settings`)
            if (web.is_reports) {
                Play.unsync(`/sync/project_version/${this.key()}/plays/*`);
            }
        })
    }

    // </editor-fold>

    name() {
        return this.props.version
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    delete() {
        const content_text = this.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => this.client.delete(),
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
ProjectVersion.register_resource(ProjectVersion)
ProjectVersionClient.ModelClass = ProjectVersion
ProjectVersionScope.ModelClass = ProjectVersion

if (globalThis.current_project_version_props?.id) {
    current.project_version = ProjectVersion.new(current_project_version_props)
}

if (globalThis.project_versions_props) {
    project_versions_props.forEach(project_version_props => ProjectVersion.new(project_version_props))
}

on_dom_content_loaded(() => {
    watch(
        [() => current.project?.props?.id, () => current.user?.props.superadmin],
        ([project_id, superadmin]) => {
            ProjectVersion.unsync()
            if (superadmin != null && superadmin) ProjectVersion.sync(`/sync/project_versions`);
            else if (project_id != null) {
                ProjectVersion.sync(`/sync/project/${project_id}/project_versions`);
            }
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    var current_project_version_props: ProjectVersionProps

    /** All projects versions available for user to switch, not just for current project */
    var project_versions_props: ProjectVersionProps[]
    var project_versions: ProjectVersion[]

    interface Window {
        ProjectVersion: typeof ProjectVersion
    }

    interface Current {
        project_version: ProjectVersion
    }
}
window.ProjectVersion = ProjectVersion
// </editor-fold>

