import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "row",
  style: {"display":"flex"}
}
const _hoisted_2 = {
  class: "col s8",
  style: {"display":"flex"}
}
const _hoisted_3 = { class: "col s4" }
const _hoisted_4 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_Input = _resolveComponent("Input")
  const _component_ProjectVersionsTable = _resolveComponent("ProjectVersionsTable")
  const _component_NewProjectVersionForm = _resolveComponent("NewProjectVersionForm")
  const _component_SettingsItem = _resolveComponent("SettingsItem")
  const _component_ProjectVersionEdit = _resolveComponent("ProjectVersionEdit")

  return (_ctx.show_table)
    ? (_openBlock(), _createBlock(_component_SettingsItem, {
        key: 0,
        id: "project_versions",
        title: "Project Versions"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.is_viewer_role && !_ctx.is_user_role)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    text: "New Version",
                    onClick: _ctx.new_project_version
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Input, {
                modelValue: _ctx.filter,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.filter) = $event)),
                placeholder: "Filter"
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _createVNode(_component_ProjectVersionsTable, {
            id: "project_versions_table",
            project: _ctx.project,
            filter: _ctx.filter,
            is_viewer_role: _ctx.is_viewer_role,
            is_user_role: _ctx.is_user_role,
            onProjectVersionClick: _ctx.project_version_click
          }, null, 8 /* PROPS */, ["project", "filter", "is_viewer_role", "is_user_role", "onProjectVersionClick"]),
          (_ctx.show_new_project_version)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_NewProjectVersionForm, {
                  for_project: _ctx.project,
                  onClose: _cache[1] || (_cache[1] = $event => (_ctx.show_new_project_version = false))
                }, null, 8 /* PROPS */, ["for_project"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }))
    : (_openBlock(), _createBlock(_component_ProjectVersionEdit, {
        key: 1,
        project_version: _ctx.edit_project_version,
        is_viewer_role: _ctx.is_viewer_role,
        is_user_role: _ctx.is_user_role,
        form_validator: _ctx.form_validator.register_child_form('project_version_edit'),
        onExit: _cache[2] || (_cache[2] = $event => (_ctx.show_table=true)),
        onSettingItemMounted: _cache[3] || (_cache[3] = (component) => _ctx.$emit('setting-item-mounted', component)),
        onSettingItemUnmounted: _cache[4] || (_cache[4] = (component) => _ctx.$emit('setting-item-unmounted', component))
      }, null, 8 /* PROPS */, ["project_version", "is_viewer_role", "is_user_role", "form_validator"]))
}