import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3f108c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "type", "min", "max", "value", "placeholder", "disabled", "tabindex", "data-instance-id", "readonly", "autocomplete", "autofocus"]
const _hoisted_2 = {
  key: 0,
  class: "clear-action"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ActionIcon = _resolveComponent("ActionIcon")
  const _component_ValidationErrors = _resolveComponent("ValidationErrors")

  return (_openBlock(), _createElementBlock("label", {
    ref: "label",
    class: _normalizeClass(["input-label", {invalid: _ctx.validator?.invalid}]),
    style: _normalizeStyle(_ctx.label_style)
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      ref: "input",
      type: _ctx.type,
      min: _ctx.min,
      max: _ctx.max,
      value: _ctx.preparsed_value,
      placeholder: _ctx.focused || _ctx.label == '' ? _ctx.placeholder : '',
      disabled: _ctx.disabled,
      tabindex: _ctx.tab_index,
      class: _normalizeClass(["vue-input no-default-unfocus", {dirty: _ctx.preparsed_value, 'no-default-focus': _ctx.no_focus_border}]),
      "data-instance-id": _ctx.instance_id,
      style: _normalizeStyle(_ctx.input_style),
      readonly: _ctx.readonly,
      autocomplete: _ctx.autocomplete,
      autofocus: _ctx.autofocus,
      onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.on_input && _ctx.on_input(...args))),
      onFocusin: _cache[1] || (_cache[1] = (...args) => (_ctx.on_focusin && _ctx.on_focusin(...args))),
      onKeydown: _cache[2] || (_cache[2] = (...args) => (_ctx.on_keydown && _ctx.on_keydown(...args))),
      onBlur: _cache[3] || (_cache[3] = (...args) => (_ctx.on_blur && _ctx.on_blur(...args))),
      onFocus: _cache[4] || (_cache[4] = (...args) => (_ctx.display_validation_error && _ctx.display_validation_error(...args)))
    }, null, 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(["placeholder", {disabled: _ctx.disabled}]),
      style: _normalizeStyle(_ctx.span_style)
    }, _toDisplayString(_ctx.label), 7 /* TEXT, CLASS, STYLE */),
    (_ctx.show_clear_action && _ctx.preparsed_value != '' && _ctx.preparsed_value != null)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_ActionIcon, {
            icon_class: "fa-solid fa-times",
            color_class: "red",
            onClick: _cache[5] || (_cache[5] = $event => (_ctx.on_value_updated('')))
          })
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.show_errors && _ctx.validator?.invalid)
      ? (_openBlock(), _createBlock(_component_ValidationErrors, {
          key: 1,
          no_side: _ctx.errors_no_side,
          errors: _ctx.validator.errors
        }, null, 8 /* PROPS */, ["no_side", "errors"]))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}