<template>
  <div class="container">
    <Loading
        :size="5"
        :inflate="true"
        type="fading_circle"
        color="white"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loading from "../Loading.vue";
import { PropType } from "vue";
import { AnimationType } from "../Loading.vue";

export default defineComponent({
    components: { Loading },
    // <editor-fold desc="PROPS">
    props: {
        is_loading: {
            type: Boolean,
            required: true,
        },
        type: {
            type: String as PropType<AnimationType>,
            required: false,
            default: "chasing_dots",
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        is_loading() {
            console.log("IS LOADING CHANGED", this.is_loading);
            if (!this.is_loading) this.cancel();
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        cancel() {
            this.$.appContext.app.unmount();
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  z-index: 99999;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  overflow: hidden;
}
</style>
