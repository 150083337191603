import { ProjectVersion } from "../models/project_version";
import { ProjectVersionSetting } from "../models/project_version_setting";

declare module "../models/project_version_setting" {
    interface ProjectVersionSetting {
        project_version: ProjectVersion
    }
}

ProjectVersionSetting.belongs_to("project_version", ProjectVersion, "project_version_id")
