import _ from "lodash";
import { FileFolder } from "../../vue_record/models/file_folder";
import { File } from "../../vue_record/models/file"
import { ImageFolder } from "../../vue_record/models/image_folder";
import { Image } from "../../vue_record/models/image";
import { VueRecord } from "../../vue_record/base/vue_record";
import { Consoler } from "../api_wrappers/consoler";

let is_fixing = false
const console = new Consoler("warn")
export function fix_file_paths(changed_record: VueRecord, old_path_substring: string, new_path_substring: string) {
    // to prevent cascading changes
    if (is_fixing) {
        console.log("preventing cascade");
        return
    }

    is_fixing = true
    console.log("----------- FIX START ---------------");
    try {
    abstract_fix_path({
        old_path_substring,
        new_path_substring,
        model_class: FileFolder,
        primary_key_name: "path",
        folder_key_name: "file_folder_path",
        changed_record
    })
    abstract_fix_path({
        old_path_substring,
        new_path_substring,
        model_class: File,
        primary_key_name: "path",
        folder_key_name: "file_folder_path",
        changed_record
    })
    } finally {
        is_fixing = false
        console.log("----------- FIX END ---------------");
    }
}

export function fix_image_paths(changed_record: VueRecord, old_path_substring: string, new_path_substring: string) {
    abstract_fix_path({
        old_path_substring,
        new_path_substring,
        model_class: ImageFolder,
        primary_key_name: "path",
        folder_key_name: "image_folder_path",
        changed_record
    })
    abstract_fix_path({
        old_path_substring,
        new_path_substring,
        model_class: Image,
        primary_key_name: "path",
        folder_key_name: "image_folder_path",
        changed_record
    })
}

type AbstractFixParams = {
    old_path_substring: string,
    new_path_substring: string,
    model_class: typeof VueRecord,
    primary_key_name: string,
    folder_key_name: string
    changed_record: VueRecord
}

function abstract_fix_path(args: AbstractFixParams) {
    const changed_record_key = args.changed_record.key()
    args.model_class.get_scope().each(app_record => {
        if (app_record.key() == changed_record_key) return;

        if (app_record.props[args.primary_key_name].startsWith(args.old_path_substring) ||
            app_record.props[args.folder_key_name]?.startsWith(args.old_path_substring)) {
            const new_props = _.cloneDeep(app_record.props)
            if (app_record.props[args.primary_key_name].startsWith(args.old_path_substring)) {
                new_props[`__old_${args.primary_key_name}`] = app_record.props[args.primary_key_name]
                new_props[args.primary_key_name] = app_record.props[args.primary_key_name].replace(args.old_path_substring, args.new_path_substring)
            }

            if (app_record.props[args.folder_key_name]?.startsWith(args.old_path_substring)) {
                new_props[args.folder_key_name] = app_record.props[args.folder_key_name].replace(args.old_path_substring, args.new_path_substring)
            }

            app_record._update_model(new_props)
        }
    })
}
