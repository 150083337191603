import { AppiumScreenControl } from "./appium_screen_control";
import { send_ws } from "../../generic/send_ws";
import { AscAction } from "./asc_action";

type Coords = {
    x: number,
    y: number
}

type WheelAction = {
    /** delta x */
    x: number,

    /** delta y */
    y: number,
    coords: Coords
}

export class AscWheelAction extends AscAction {
    type = Enum.Play.ScreenAction.WHEEL;

    actions: WheelAction[] = []
    perform_timeout: Date = null;

    asc: AppiumScreenControl

    constructor(asc: AppiumScreenControl, delta_x: number, delta_y: number, coords: Coords) {
        super()
        this.asc = asc;
        this.add_wheel_action(AscWheelAction.generate_action_object(delta_x, delta_y, coords))
    }

    perform() {
        const data = {
            udid: this.asc.phone.props.udid,
            action: Enum.Play.Action.SCREEN_CONTROL,
            screen_action: {
                type: this.type,
                actions: this.actions
            }
        };

        send_ws(this.asc.backend_ws_channel, data)
    }

    add_wheel_action(action: WheelAction) {
        if (this.actions.length > 0) {
            const previous_action = this.actions[this.actions.length - 1]
            if (this.are_in_same_area(previous_action, action)) {
                this.merge_actions(previous_action, action);
            } else {
                this.actions.push(action);
            }
        } else {
            this.actions.push(action);
        }
    }

    are_in_same_area(previous_action: WheelAction, current_action: WheelAction) {
        const width = this.asc.phone.props.display_width / this.asc.phone.props.pixel_ratio;
        const height = this.asc.phone.props.display_height / this.asc.phone.props.pixel_ratio;
        const max_width_offset = width * 0.05;
        const max_height_offset = height * 0.05;
        return (Math.abs(previous_action.coords.x - current_action.coords.x) < max_width_offset &&
            Math.abs(previous_action.coords.y - current_action.coords.y) < max_height_offset)
    }

    merge_actions(previous_action: WheelAction, current_action: WheelAction) {
        previous_action.coords.x = (previous_action.coords.x + current_action.coords.x) / 2;
        previous_action.coords.y = (previous_action.coords.y + current_action.coords.y) / 2;
        previous_action.x = previous_action.x + current_action.x;
        previous_action.y = previous_action.y + current_action.y;
    }

    static generate_action_object(delta_x: number, delta_y: number, coords: Coords): WheelAction {
        // all that matters is in what direction we are scrolling, not how much
        if (delta_y > 0) delta_y = 1
        if (delta_y < 0) delta_y = -1
        if (delta_x > 0) delta_x = 1
        if (delta_x < 0) delta_x = -1

        return {
            x: delta_x,
            y: delta_y,
            coords
        }
    }
}
