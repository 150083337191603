<template>
  <div class="container">
    <Header/>
    <div class="application-container">
      <template v-if="((current.project != null || web.is_reports) && current.user != null && current.user.projects.count != 0) || web.is_logs">
        <SectionTogglerVue :section_toggler="left_section_toggler"/>

        <RFlex :rflex="main_rflex">
          <template #sidebar>
            <SectionTogglerContents :section_toggler="left_section_toggler"/>
          </template>
          <template #content>
            <RFlex :rflex="editor_1_rflex">
              <template #scenario_builder>
                <Editor
                    :editor="scenario_editor"
                    @set-size="(size) => on_scenario_builder_editor_set_size(size)"
                />
              </template>
              <template #main_and_footer>
                <RFlex :rflex="editor_2_rflex">
                  <template #main>
                    <Editor :editor="main_editor"/>
                  </template>
                  <template #footer>
                    <Editor :editor="footer_editor"/>
                  </template>
                </RFlex>
              </template>
            </RFlex>
          </template>

          <template #rightbar>
            <SectionTogglerContents :section_toggler="right_section_toggler"/>
          </template>
        </RFlex>

        <SectionTogglerVue :section_toggler="right_section_toggler"/>
      </template>
      <!--<editor-fold desc="NO PROJECT">-->

      <template v-else-if="current.user != null && current.user.projects.count == 0">
        <div class="no-project-info-container">
          <div class="icon">
            <i class="fa-solid fa-chalkboard-user"/>
          </div>
          <div class="message">
            You are not assigned to any project.<br/>
            Ask your team leader to assign you to a project.
          </div>
        </div>
      </template>
      <!--</editor-fold>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "./Header.vue";
import { Editor as EditorClass } from "../testa/editor/editor";
import Editor from "../testa/editor/Editor.vue";
import { Size } from "../../types/globals";
import SectionTogglerVue from "../testa/section_toggler/SectionToggler.vue";
import { SectionToggler } from "../testa/section_toggler/section_toggler";
import { Section } from "../testa/section_toggler/section";
import SectionTogglerContents from "../testa/section_toggler/SectionTogglerContents.vue";
import { SnippetTab } from "../testa/editor/tabs/snippet_tab";
import { Scenario } from "../../vue_record/models/scenario";
import { GroupTab } from "../testa/editor/tabs/group_tab";
import { update_url_parameters } from "../../helpers/generic/update_url_parameter";
import { ResizableFlex } from "../testa/resizable/resizable_flex/resizable_flex";
import { computed } from "../../helpers/vue/computed";
import RFlex from "../testa/resizable/resizable_flex/RFlex.vue";

export default defineComponent({
    components: { RFlex, SectionTogglerContents, SectionTogglerVue, Editor, Header },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            left_section_toggler: new SectionToggler({
                id: "left_section_toggler",
                position: "left",
                start_sections: this.left_start_sections(),
                end_sections: this.left_end_sections(),
            }),
            right_section_toggler: new SectionToggler({
                id: "right_section_toggler",
                position: "right",
                start_sections: [Section.get_notifications_section(), Section.get_databases_section()],
                end_sections: []
            }),
            main_rflex: ResizableFlex.new({
                id: "main_rflex",
                resize_behaviour: "only_adjacent",
                direction: "row",
                areas: [
                    {
                        id: "sidebar",
                        initial_px_size: 310,
                        min_px_size: 75,
                        max_ratio_size: 0.5,
                        shrink_rate: 0,
                        grow_rate: 0
                    },
                    {
                        id: "content",
                    },
                    {
                        id: "rightbar",
                        min_px_size: 75,
                        max_ratio_size: 0.5,
                        initial_px_size: 300,
                        shrink_rate: 0,
                        grow_rate: 0
                    }
                ]
            }),
            editor_1_rflex: ResizableFlex.new({
                id: "editor_1_rflex",
                direction: "row",
                areas: [
                    {
                        id: "scenario_builder",
                        min_px_size: 100,
                        initial_px_size: 190,
                        max_ratio_size: 0.5,
                        shrink_rate: 0,
                        grow_rate: 0
                    },
                    {
                        id: "main_and_footer",
                    }
                ]
            }),
            editor_2_rflex: ResizableFlex.new({
                id: "editor_2_rflex",
                direction: "column",
                areas: [
                    {
                        id: "main",
                        min_px_size: 200,
                        initial_ratio_size: 0.75
                    },
                    {
                        id: "footer",
                        min_px_size: 200,
                        initial_ratio_size: 0.25
                    }
                ]
            })
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        web() {
            return web
        },
        main_editor() {
            return EditorClass.get_main()
        },
        scenario_editor() {
            return EditorClass.get_scenario()
        },
        footer_editor() {
            return EditorClass.get_footer()
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMount() {
        this.main_rflex.areas.sidebar.enabled = computed(() => {
            return this.left_section_toggler.enabled_sections().length > 0
        })
        this.main_rflex.areas.rightbar.enabled = computed(() => {
            return this.right_section_toggler.enabled_sections().length > 0
        })
        this.editor_1_rflex.areas.scenario_builder.enabled = computed(() => {
            return this.scenario_editor.computed.tabs.length > 0
        })
        this.editor_2_rflex.areas.footer.enabled = computed(() => {
            return this.footer_editor.computed.tabs.length > 0
        })
    },
    mounted() {
        if (current.user != null) this.open_url_items()
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        open_url_items() {
            const query_string = window.location.search;
            const url_params = new URLSearchParams(query_string);
            const snippet_id = url_params.get("snippet_id")
            const scenario_id = url_params.get("scenario_id")
            const group_id = url_params.get("group_id")
            if (snippet_id != null) {
                url_params.delete("snippet_id")
                EditorClass.get_main().add_tab(SnippetTab.new({
                    id: `snippet_tab_${snippet_id}`,
                    snippet_id: parseInt(snippet_id),
                    allow_duplicate: false
                }))
            }
            if (scenario_id != null) {
                url_params.delete("scenario_id")
                Scenario.ClientClass
                        .load(parseInt(scenario_id))
                        .then(scenario => {
                            Scenario.open(scenario.props.project_version_id, [scenario], [])
                        })
            }
            if (group_id != null) {
                url_params.delete("group_id")
                EditorClass.get_main().add_tab(GroupTab.new({
                    id: `group_tab_${group_id}`,
                    group_id: parseInt(group_id),
                    allow_duplicate: false
                }))
            }
            window.history.pushState('', '', update_url_parameters(window.location.href, url_params))
        },
        left_start_sections() {
            if (web.is_reports) {
                return [Section.get_reports_section(), Section.get_logs_section()]
            }
            if (web.is_logs) {
                return [Section.get_logs_section()]
            }
            return [Section.get_project_section(), Section.get_logs_section()]
        },
        left_end_sections() {
            if (web.is_reports || web.is_logs) return []

            return [Section.get_devices_section(), Section.get_apps_section(), Section.get_schedules_section(), Section.get_reports_section()]
        },
        on_scenario_builder_editor_set_size(size: Size) {
            this.editor_1_rflex.areas.scenario_builder.set_size(size.width)
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  .application-container {
    height: 100%;
    min-height: 0;
    background-color: var(--primary-background-color);
    display: flex;
    flex-direction: row;

    .no-project-info-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2em;
      gap: 1em;

      .icon {
        font-size: 5em;
      }

      .message {
        text-align: center;
      }
    }
  }
}

</style>
