<template>
  <Modal
      id="new_project_version_form"
      ref="modal"
      :show_cancel="true"
      :focus_confirm="false"
      :focus_last_active_on_unmount="true"
      confirm_text="Create"
      :cancel_on_escape="true"
      :form_validator="form_validator"
      :confirm_action="confirm"
      @cancel="cancel"
  >
    <template #header>
      <div class="title">
        New Project Version
      </div>
    </template>

    <template #body="modal">
      <div v-if="for_project == null"
           class="row">
        <div class="col s12">
          <Select2
              id="new_project_version_from_project"
              v-model="project_version_props.project_id"
              label="For Project"
              :validator="form_validator.register('for_project', { presence: true })"
              dropdown_parent="#new_project_version_form"
          >
            <template
                v-for="project in projects"
                :key="project.props.id">
              <option :value="project.props.id">{{ project.props.full_name }}</option>
            </template>
          </Select2>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <Checkbox id="new_project_version_branch_from_checkbox"
                    v-model="branch_from_existing_version"
                    label="Branch from existing version"/>
        </div>
      </div>

      <div
          v-if="branch_from_existing_version"
          class="row">
        <div class="col s12">
          <Select2
              id="new_project_version_branch_from_version"
              v-model="branch_from_project_version_id"
              label="From Version"
              :validator="form_validator.register('from_version', { presence: true })"
              dropdown_parent="#new_project_version_form"
          >
            <template
                v-for="version in project_versions"
                :key="version.props.id">
              <option :value="version.props.id">{{ version.props.version }}</option>
            </template>
          </Select2>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <Input
              id="new_project_version_version"
              v-model="project_version_props.version"
              label="Version"
              :validator="form_validator.register('version', ProjectVersion.field_validators.version)"
              @enter="modal.confirm()"
          />
        </div>
      </div>

      <div v-if="project_users.length > 1"
           class="row">
        <div class="col s12">
          <Checkbox
              id="new_project_version_is_private_checkbox"
              v-model="project_version_props.is_private"
              label="Private"/>
        </div>
      </div>

      <div v-if="project_version_props.is_private"
           class="row">
        <div class="col s12">
          <Select2
              id="new_project_version_is_private_user"
              v-model="project_version_props.user_id"
              label="Private for user"
              :validator="form_validator.register('private_for_user', { presence: true })"
              dropdown_parent="#new_project_version_form"
          >
            <template
                v-for="user in project_users"
                :key="user.props.id">
              <option :value="user.props.id">{{ user.props.email }}</option>
            </template>
          </Select2>
        </div>
      </div>
    </template>

  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Input from "../../../testa/Input.vue";
import { Project } from "../../../../vue_record/models/project";
import Select2 from "../../../testa/Select2.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import { UserProject } from "../../../../vue_record/models/user_project";
import { PropType } from "vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { QuerifiedProjectVersionProps } from "../../../../vue_record/models/project_version";

export default defineComponent({
    components: {
        Checkbox,
        Select2,
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        for_project: {
            type: Object as PropType<Project>,
            required: false,
            default: null,
        },
    },
    // </editor-fold>
    emits: ['close'],
    // <editor-fold desc="DATA">
    data() {
        return {
            projects: Project.get_scope().toArray(),
            form_validator: new FormValidator(),
            project_version_props: {
                is_private: false,
                project_id: this.for_project?.props?.id,
            } as QuerifiedProjectVersionProps,
            branch_from_existing_version: true,
            branch_from_project_version_id: null as number,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        ProjectVersion() {
            return ProjectVersion
        },
        project_versions() {
            return ProjectVersion.where({ project_id: this.project_version_props.project_id })
                                 .toArray()
                                 .filter(version => {
                                     return !version.props.is_private ||
                                         (version.props.user_id == current.user.props.id) ||
                                         current.role_for(version.props.project_id) == Enum.User.Role.ADMIN ||
                                         current.user.is_superadmin()
                                 })
        },
        project_users() {
            return UserProject.where({ project_id: this.project_version_props.project_id })
                              .toArray()
                              .map(user_project => user_project.user)
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'branch_from_existing_version'() {
            if (!this.branch_from_existing_version) {
                // ensure that when deselected, we don't actually send branch from id to backend
                this.branch_from_project_version_id = null;
            }
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        confirm() {
            return ProjectVersion.ClientClass
                                 .create(this.project_version_props, this.branch_from_project_version_id)
                                 .then(() => this.cancel())
                                 .catch(e => e.show_toaster())
        },
        cancel() {
            this.$emit("close");

            (this.$refs.modal as typeof Modal).close()
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.title {
  width: 400px;
}
</style>
