<template>
  <SettingsItem
      id="modules"
      title="Modules">
    <div class="row">
      <div class="col s4">
        <Checkbox id="web_module_checkbox"
                  v-model="project_version_setting_update.web_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Web / Browsers"/>
      </div>
      <div class="col s4">
        <Checkbox id="groups_module_checkbox"
                  v-model="project_version_setting_update.group_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Groups"/>
      </div>
      <div class="col s4">
        <Checkbox id="sikuli_module_checkbox"
                  v-model="project_version_setting_update.sikuli_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Images"/>
      </div>
    </div>
    <div class="row">
      <div class="col s4">
        <Checkbox id="mobile_module_checkbox"
                  v-model="project_version_setting_update.android_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Mobile / Phones"/>
      </div>
      <div class="col s4">
        <Checkbox id="jira_module_checkbox"
                  v-model="project_version_setting_update.jira_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Jira"/>
      </div>
      <div class="col s4">
        <Checkbox id="vcs_module_checkbox"
                  v-model="project_update.vcs_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="VCS"/>
      </div>
    </div>
    <div class="row">
      <div class="col s4">
        <Checkbox id="schedules_module_checkbox"
                  v-model="project_version_setting_update.schedule_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Schedules"/>
      </div>
      <div class="col s4">
        <Checkbox id="proxies_module_checkbox"
                  v-model="project_version_setting_update.proxies_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Proxies"/>
      </div>
      <div class="col s4">
        <Checkbox id="files_module_checkbox"
                  v-model="project_version_setting_update.file_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Files"/>
      </div>
    </div>
    <div class="row">
      <div class="col s4">
        <Checkbox id="code_lint_module_checkbox"
                  v-model="project_version_setting_update.code_lint_module_enabled"
                  :disabled="is_viewer_role || is_user_role"
                  label="Code Lint"/>
      </div>
    </div>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsItem from "../../SettingsItem.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import toastr from "toastr";
import { PropType } from "vue";
import { ProjectVersionSetting } from "../../../../vue_record/models/project_version_setting";
import { Project } from "../../../../vue_record/models/project";
import { ModuleUpdateProps } from "../../../../vue_record/models/project_version_setting";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Checkbox,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true,
        },
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            project_version_setting_update: {
                jira_module_enabled: this.project_version_setting.props.jira_module_enabled,
                schedule_module_enabled: this.project_version_setting.props.schedule_module_enabled,
                file_module_enabled: this.project_version_setting.props.file_module_enabled,
                sikuli_module_enabled: this.project_version_setting.props.sikuli_module_enabled,
                android_module_enabled: this.project_version_setting.props.android_module_enabled,
                group_module_enabled: this.project_version_setting.props.group_module_enabled,
                web_module_enabled: this.project_version_setting.props.web_module_enabled,
                proxies_module_enabled: this.project_version_setting.props.proxies_module_enabled,
                code_lint_module_enabled: this.project_version_setting.props.code_lint_module_enabled,
            } as ModuleUpdateProps,
            project_update: {
                vcs_module_enabled: this.project.props.vcs_module_enabled
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMount() {},
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save() {
            return this.apply()
        },
        apply() {
            if (this.is_viewer_role || this.is_user_role) return null;

            const project_version_setting_promise = this.project_version_setting.client.update(this.project_version_setting_update)
            const project_promise = this.project.client.update(this.project_update)
            return Promise.all([project_version_setting_promise, project_promise]).catch(() => {
                toastr.error("Failed to save modules")
            })
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
