import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ab2bc86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-collection-info-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["onMouseover", "onClick"]
const _hoisted_4 = { class: "index" }
const _hoisted_5 = { class: "attributes" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InspectorAttributeInfo = _resolveComponent("InspectorAttributeInfo")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, " -- " + _toDisplayString(_ctx.title) + " -- ", 1 /* TEXT */),
      (_ctx.elements.length > _ctx.minimized_elements)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "toggle-show",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.show_all = !_ctx.show_all))
          }, _toDisplayString(_ctx.show_all ? "Hide" : "Show all"), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: element.id
      }, [
        (_ctx.show_all || index < _ctx.minimized_elements)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "element",
              onMouseover: (e) => element.on_hover(e, 'side-kit-info'),
              onClick: (e) => _ctx.on_click(e, element)
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(index + 1) + ". ", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_InspectorAttributeInfo, {
                  attribute: element.attributes[element.inspector.class_key]
                }, null, 8 /* PROPS */, ["attribute"]),
                (element.attributes[element.inspector.id_key]?.value != null)
                  ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
                      key: 0,
                      attribute: element.attributes[element.inspector.id_key]
                    }, null, 8 /* PROPS */, ["attribute"]))
                  : _createCommentVNode("v-if", true),
                (element.attributes.text?.value != null)
                  ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
                      key: 1,
                      attribute: element.attributes.text
                    }, null, 8 /* PROPS */, ["attribute"]))
                  : _createCommentVNode("v-if", true),
                (element.attributes['content-desc']?.value != null)
                  ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
                      key: 2,
                      attribute: element.attributes['content-desc']
                    }, null, 8 /* PROPS */, ["attribute"]))
                  : _createCommentVNode("v-if", true)
              ])
            ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_3))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}