<template>
  <div class="play-node-container"
       :class="{'in-debug': (in_debugging && !is_viewer)}">
    <div ref="play_snippet"
         class="row-container"
         :style="row_container_style"
    >
      <div class="expander click-item"
           @click.stop="toggle_expanded">
        <i v-if="expanded"
           class="fas fa-chevron-down"/>
        <i v-else
           class="fas fa-chevron-right"/>
      </div>


      <div class="title-container click-item"
           @click="toggle_expanded">
        <span class="title-item icon">
          <PlayStatusIcon :play_status="props.status"/>
        </span>
        <span class="title-item icon">
          <PlayTypeIcon play_type="Snippet"/>
        </span>
        <div
            class="title-item progress-container"
            :style="progress_background()"
        >
          <span class="title title-item">
            {{ props.name }}
          </span>
          <span class="title-item flex-expander"/>
        </div>
        <span v-if="props.start_time"
              v-time="props.start_time"
              class="title-item start-time"
        />
        <span v-if="props.start_time"
              v-duration="{start: props.start_time, end: props.end_time}"
              class="title-item duration"
        />
      </div>


      <div class="action-container">
        <ActionIcon
            v-if="main_web_available"
            icon_class="fa-solid fa-bullseye"
            title="Show snippet in sidebar"
            color_class="var(--snippet-color)"
            margin="0"
            @click="show_target"
        />
      </div>
    </div>


    <div v-show="expanded"
         ref="logs_container"
         class="play-content-container logs-container"
         :class="{'in-debug': (in_debugging && !is_viewer)}">
      <Loading
          v-if="!data_loaded"
          type="three_bounce"
          :size="4"
      />
      <template v-else>
        <PlayLogReport
            v-for="play_log in displayable_play_logs"
            :key="play_log.props.id"
            :play="play"
            :play_scenario="play_scenario"
            :play_snippet="play_snippet"
            :play_log="play_log"
            :role="role"
            :error_line="debugger_error_play_log_id == play_log.props.id ? debugger_error_line : null"
            :main_web_available="main_web_available"
            :project_version_setting="project_version_setting"
            @play-log-mounted="on_play_log_mounted"
            @debug-trace-click="on_debug_trace_click"
        />
        <Debugger
            v-if="in_debugging && !is_viewer"
            :main_play_scenario="main_play_scenario"
            :debug_frames="debug_frames"
            :project_version="project_version"
            :main_web_available="main_web_available"
            @play-debugger-mounted="on_play_debugger_mounted"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import PlayLogReport from '../play_log/PlayLogReport.vue';

import { defineComponent, PropType } from 'vue';
import { PlaySnippet } from "../../../../vue_record/models/play/play_snippet";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import { Play } from "../../../../vue_record/models/play/play";
import PlayTypeIcon from "../../other/PlayTypeIcon.vue";
import { PlayLog } from "../../../../vue_record/models/play/play_log";
import PlayStatusIcon from "../../other/PlayStatusIcon.vue";
import Debugger from "./Debugger.vue";
import Loading from "../../../testa/Loading.vue";
import ActionIcon from "../../../testa/ActionIcon.vue";
import { ProjectVersionSetting } from "../../../../vue_record/models/project_version_setting";
import { EnumUserRole } from "../../../../auto_generated/enums";
import { SnippetSavepoint } from "../../../../vue_record/models/snippet_savepoint";
import { Snippet } from "../../../../vue_record/models/snippet";
import { CSSProperties } from "vue";

export default defineComponent({
    components: {
        ActionIcon,
        Loading,
        PlayStatusIcon,
        Debugger,
        PlayLogReport,
        PlayTypeIcon,
    },
    // <editor-fold desc="PROPS">
    props: {
        play_snippet: {
            type: Object as PropType<PlaySnippet>,
            required: true
        },
        play_scenario: {
            type: Object as PropType<PlayScenario>,
            required: true,
        },
        play: {
            type: Object as PropType<Play>,
            required: true,
        },
        expanded: {
            type: Boolean,
            require: true,
        },
        main_web_available: {
            type: Boolean,
            required: true
        },
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true
        },
        role: {
            type: String as PropType<EnumUserRole>,
            required: false,
            default: null
        },
        is_viewer: {
            type: Boolean,
            required: true
        },
        parents: {
            type: Number,
            required: true
        }
    },
    // </editor-fold>
    emits: ['toggle-expand', 'play-log-mounted', 'disable-sticky-scroll', 'on-finish-auto-collapse', 'play-debugger-mounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            data_loaded: false,
            debug_frames: this.play_snippet.state.debug_frames,
            debugger_error_play_log_id: null,
            debugger_error_line: null
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        play_logs() {
            return this.play_snippet.play_logs
        },
        props() {
            return this.play_snippet.props;
        },
        displayable_play_logs() {
            return this.play_logs
                       .not({ type: Enum.Play.Log.COMMAND })
                       .order("created_at", "asc")
                       .toArray();
        },
        main_play_scenario() {
            return this.play_scenario.main_play_scenario;
        },
        in_debugging() {
            return this.props.status == Enum.Play.Status.DEBUGGING;
        },
        snippet_savepoint() {
            return this.play_snippet.snippet_savepoint;
        },
        current() {
            return current
        },
        project_version() {
            return this.play.project_version
        },
        row_container_style() {
            const style: CSSProperties = {}
            if (this.expanded) {
                style.position = "sticky"
                style.top = this.parents * 27
                style.zIndex = Math.max(9 - this.parents, 1)
                // style.background = "linear-gradient(0deg,var(--snippet-color) 0,var(--primary-background-color)  20%)"
                style.borderBottom = "solid 1px var(--snippet-color)"
            }
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'play_snippet.props.finished?'(new_val, old_val) {
            if (old_val == false && new_val == true) this.$emit("on-finish-auto-collapse", this.play_snippet)
        },
        expanded: {
            handler(new_val, old_val) {
                console.log(`Expanded for play snippet changed from ${old_val} to ${new_val}`);
                if (this.expanded && !this.data_loaded) this.load_data()
            },
            flush: "sync",
            immediate: true,
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeCreate() {
    },
    beforeMount() {
    },
    mounted() {
        // @ts-ignore - is assigned to div for easier referencing
        this.$refs.play_snippet.play_snippet = this.play_snippet
    },
    updated() {
    },
    unmounted() {
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_play_log_mounted(play_log: PlayLog) {
            this.$emit("play-log-mounted", play_log)
        },
        on_debug_trace_click(play_log_id: number, line: number) {
            this.debugger_error_play_log_id = play_log_id
            this.debugger_error_line = line
        },
        on_play_debugger_mounted() {
            this.$emit("play-debugger-mounted", this.play_snippet)
        },
        toggle_expanded() {
            this.$emit('disable-sticky-scroll')
            this.$emit('toggle-expand')
        },
        show_target() {
            SnippetSavepoint.find_or_load(this.play_snippet.props.snippet_savepoint_id)
                .then(snippet_savepoint => {
                    Snippet.show_in_sidebar(snippet_savepoint.props.snippet_id, this.project_version.key())
                })
        },
        load_data() {
            this.play_snippet
                .client
                .load_associated()
                .then(() => this.data_loaded = true)
        },
        progress_background() {
            if (this.props['finished?']) return 'background: transparent';

            const perc = this.props.progress * 100
            return `background: linear-gradient(90deg, var(--play-progress-color) 0 ${perc}%, transparent ${perc}% 100%);`
        },
    }
    // </editor-fold>

})
</script>

<style lang="scss" scoped>
//.logs-container{
//  max-height: 500px;
//  overflow: auto;
//}
</style>
