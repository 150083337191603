import { createApp } from "vue";
import { Component } from "vue";
import { v_moment } from "../../components/directives/v-moment";
import { v_time } from "../../components/directives/v-time";
import { v_date } from "../../components/directives/v-date";
import { v_duration } from "../../components/directives/v-duration";
import { v_datetime } from "../../components/directives/v-datetime";
import { v_timeout } from "../../components/directives/v-timeout";

export function create_vue_app(Component: any, props: any, target: HTMLElement = null) {
    const app = createApp(Component, props)
    app.directive('moment', v_moment)
    app.directive('time', v_time)
    app.directive('date', v_date)
    app.directive('datetime', v_datetime)
    app.directive('duration', v_duration)
    app.directive("timeout", v_timeout)

    // app.config.globalProperties.$ = $
    // app.config.globalProperties.Enum = Enum
    // app.config.globalProperties.window = window

    if (target != null) {
        app.mount(target)
    } else {
        const apps_container = document.getElementById("vue_apps")
        const app_holder = document.createElement("div")
        apps_container.appendChild(app_holder)
        app.mount(app_holder)
    }
    return app
}
