import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23e40de8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col s12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col s12" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col s12" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col s6" }
const _hoisted_10 = { class: "col s6" }
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col s12 align-center" }
const _hoisted_16 = { class: "integration-test-status" }
const _hoisted_17 = {
  key: 1,
  class: "fas fa-check",
  style: {"color":"var(--button-green)"}
}
const _hoisted_18 = {
  key: 2,
  class: "fas fa-times",
  style: {"color":"var(--button-red)"}
}
const _hoisted_19 = {
  key: 0,
  class: "row"
}
const _hoisted_20 = { class: "col s12 vertical" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Input = _resolveComponent("Input")
  const _component_Select2 = _resolveComponent("Select2")
  const _component_Button = _resolveComponent("Button")
  const _component_Loading = _resolveComponent("Loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Checkbox, {
          id: "xray_enabled_checkbox",
          modelValue: _ctx.xray_params.xray_enabled,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.xray_params.xray_enabled) = $event)),
          disabled: _ctx.is_viewer_role || _ctx.is_user_role,
          label: "Enabled"
        }, null, 8 /* PROPS */, ["modelValue", "disabled"])
      ])
    ]),
    (_ctx.xray_params.xray_enabled)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Input, {
                id: "xray_client_id_input",
                modelValue: _ctx.xray_params.xray_client_id,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.xray_params.xray_client_id) = $event)),
                label: "Client ID",
                disabled: _ctx.is_viewer_role || _ctx.is_user_role,
                validator: _ctx.form_validator.register('xray_client_id', _ctx.ProjectVersionSetting.field_validators.xray_client_id)
              }, null, 8 /* PROPS */, ["modelValue", "disabled", "validator"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Input, {
                id: "xray_client_id_input",
                modelValue: _ctx.xray_params.xray_client_secret,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.xray_params.xray_client_secret) = $event)),
                label: "Client Secret",
                disabled: _ctx.is_viewer_role || _ctx.is_user_role,
                validator: _ctx.form_validator.register('xray_client_secret', _ctx.ProjectVersionSetting.field_validators.xray_client_secret)
              }, null, 8 /* PROPS */, ["modelValue", "disabled", "validator"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Input, {
                id: "xray_project_keys_input",
                modelValue: _ctx.xray_params.xray_project_keys,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.xray_params.xray_project_keys) = $event)),
                label: "Project keys (separated by ;)",
                disabled: _ctx.is_viewer_role || _ctx.is_user_role,
                validator: _ctx.form_validator.register('xray_project_keys', _ctx.ProjectVersionSetting.field_validators.xray_project_keys)
              }, null, 8 /* PROPS */, ["modelValue", "disabled", "validator"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Select2, {
                id: "xray_automatic_report_generation_select",
                modelValue: _ctx.xray_params.xray_automatic_report_generation,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.xray_params.xray_automatic_report_generation) = $event)),
                validator: _ctx.form_validator.register('xray_automatic_report_generation', _ctx.ProjectVersionSetting.field_validators.xray_automatic_report_generation),
                disabled: _ctx.is_viewer_role || _ctx.is_user_role,
                label: "Automatic reports"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("option", {
                    value: _ctx.Enum.XrayAutomaticReportGeneration.SCENARIO
                  }, "Scenarios and groups", 8 /* PROPS */, _hoisted_11),
                  _createElementVNode("option", {
                    value: _ctx.Enum.XrayAutomaticReportGeneration.GROUP,
                    selected: "selected"
                  }, " Only for groups ", 8 /* PROPS */, _hoisted_12),
                  _createElementVNode("option", {
                    value: _ctx.Enum.XrayAutomaticReportGeneration.DISABLED
                  }, "Disabled", 8 /* PROPS */, _hoisted_13)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "validator", "disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_Button, {
                id: "xray_test_integration_button",
                form_validator: _ctx.form_validator,
                text: "Test Integration",
                onClick: _ctx.test
              }, null, 8 /* PROPS */, ["form_validator", "onClick"]),
              _createElementVNode("div", _hoisted_16, [
                (_ctx.test_in_progress)
                  ? (_openBlock(), _createBlock(_component_Loading, {
                      key: 0,
                      color: "white"
                    }))
                  : (_ctx.tested && _ctx.test_response.success)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                    : (_ctx.tested && !_ctx.test_response.success)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                      : _createCommentVNode("v-if", true)
              ])
            ])
          ]),
          (_ctx.tested)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  (_ctx.test_response.success)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.test_response.tests_retrieved, (tests, project) => {
                        return (_openBlock(), _createElementBlock("div", { key: project }, _toDisplayString(tests) + " for project " + _toDisplayString(project), 1 /* TEXT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.test_response.error), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}