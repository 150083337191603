import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { ScenarioClient } from "../clients/scenario_client";
import { ScenarioScope } from "../scopes/scenario_scope";
import { ScenarioBuilder } from "../../components/testa/editor/editors/scenario/scenario_builder";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { Editor } from "../../components/testa/editor/editor";
import { GroupTab } from "../../components/testa/editor/tabs/group_tab";
import { copy_text_to_clipboard } from "../../helpers/generic/copy_to_clipboard";
import { update_url_parameters } from "../../helpers/generic/update_url_parameter";
import CodeMirror from "codemirror";
import { nextTick } from "vue";
import { ScenarioBuilderTab } from "../../components/testa/editor/tabs/scenario_builder_tab";
import { create_vue_app } from "../../helpers/vue/create_vue_app";
import _ from "lodash";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { computed } from "../../helpers/vue/computed";
import { ComputedRole } from "../base/vue_record";
import { TabManager } from "../../components/testa/editor/tab_manager";
import { RecordOpts } from "../base/vue_record";
import { unmount_all_modals } from "../../helpers/vue/unmount_all_modals";
import { ScenarioFolder } from "./scenario_folder";
import { ScenarioHistory } from "./scenario_history";
import { generate_eid } from "../../helpers/generate/generate_eid";
import { ScenarioFolderScope } from "../scopes/scenario_folder_scope";
import { Play } from "./play/play";
import { Group } from "./group";
import { Schedule } from "./schedule";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import UiShowInSidebarData = UiSync.UiShowInSidebarData;
import { TabTargetOpts } from "../../components/testa/editor/tab";
import { resolve_tab_target } from "../../components/testa/editor/tab";
import { UsagesTab } from "../../components/testa/editor/tabs/usages_tab";

// <editor-fold desc="TYPES">
export interface ScenarioProps extends Props {
    id: number
    name: string
    user_id: number
    project_version_id: number
    created_at: Date
    updated_at: Date
    scenario_folder_id: number
    archived: boolean
    xray_project_key: string[]
    git_id: string
    xray_test_keys: string[]
}

export type QuerifiedScenarioProps = QuerifyProps<ScenarioProps>
export type ScenarioCreateProps = Omit<ScenarioProps, 'id'>
export type ScenarioUpdateProps = Partial<ScenarioProps>

export interface ScenarioState extends State {
    highlighted_in_builder: boolean;
    invalid_scenario_builder_path: boolean;
    scenario_builders: ScenarioBuilder[]
}

export interface ScenarioComputed extends ComputedRole {
}

export interface ScenarioStaticState extends StaticState {
    load_promises: Record<number | string, Promise<Scenario>>
}

export interface ScenarioOpenOpts {
    editor?: Editor
    tab_manager?: TabManager
    close_all_modals?: boolean
    replace_existing?: boolean
    merged?: boolean
}

export type ScenarioEditorActionItem =
    "dummy"


export type ScenarioEditorFeature =
    "dummy"
// </editor-fold>

const console = new Consoler("warn")

export class Scenario extends VueRecord {
    ['constructor']: typeof Scenario

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScenarioClient
    static ScopeClass = ScenarioScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScenarioStaticState = reactive<ScenarioStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "scenario_folder_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Scenario> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Scenario>> = {}

    static field_validators: ModelValidatorOpts<ScenarioProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO
    static resource_id = Enum.Resource.Id.SCENARIO
    static icon_class = "fa-solid fa-ellipsis-vertical"
    static color = () => get_css_var("--scenario-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScenarioClient
    declare props: ScenarioProps;
    declare state: ScenarioState;
    declare computed: ScenarioComputed;

    // </editor-fold>
    constructor(props: Props, opts: RecordOpts) {
        super(props, opts);
        this.state.scenario_builders = [];
        this.state.highlighted_in_builder = false;
        this.state.invalid_scenario_builder_path = false; // scenario builder should update this
    }

    // <editor-fold desc="HOOKS">
    after_create() {
        super.after_create();

        this.init_computed_role(() => this.project_version?.props?.project_id)
    }

    after_sync_add() {
        super.after_sync_add();

        if (this.props.user_id == current.user.key()) {
            nextTick(() => {
                const tree = TestaTree.Tree.get_project_tree()
                if (tree?.is_visible()) {
                    const node = tree.get_node_by_key(this.tree_key())
                    if (node?.is_visible()) {
                        node?.edit_start()
                    }
                }
            })
        }
    }

    before_unload() {
        super.before_unload();
        console.log("SCENARIO BEFORE UNLOAD");

        const scenario_builder_tabs = this.get_tabs().filter(tab => tab instanceof ScenarioBuilderTab) as ScenarioBuilderTab[]
        scenario_builder_tabs.forEach(tab => {
            const scenario_builder = tab.state.scenario_builder
            if (scenario_builder != null) {
                if (scenario_builder.state.scenarios.some(s => s.key() == this.key())) {
                    scenario_builder.remove_scenario(this)

                    if (scenario_builder.state.scenarios.length == 0) tab.close();
                }
            }
        })
    }

    // </editor-fold>

    get_tabs() {
        const scenario_builder_tabs = window.Editor
                                            .get_tabs()
                                            .filter(tab => tab instanceof ScenarioBuilderTab) as ScenarioBuilderTab[]

        return scenario_builder_tabs.filter(tab => tab.state.scenario_builder.state.scenarios.pluck("key").includes(this.key()))
    }

    // <editor-fold desc="ACTIONS">
    open(opts: ScenarioOpenOpts = {}) {
        return Scenario.open(this.props.project_version_id, [this], [], opts)
    }

    static open(project_version_id: number, scenarios: Scenario[], scenario_folders: ScenarioFolder[], opts: ScenarioOpenOpts = {}) {
        let editor_or_tab_manager: Editor | TabManager = opts.editor
        if (editor_or_tab_manager == null && opts.tab_manager != null) editor_or_tab_manager = opts.tab_manager
        if (editor_or_tab_manager == null) editor_or_tab_manager = Editor.get_scenario()
        if (opts.close_all_modals) unmount_all_modals();


        if (opts.replace_existing == null) opts.replace_existing = true

        let tab = editor_or_tab_manager.get_tabs()[0] as ScenarioBuilderTab

        if (opts.merged == null) {
            if (tab == null) opts.merged = true;
            else if (tab.state.scenario_builder == null) opts.merged = true
            else opts.merged = tab.state.scenario_builder.options.merged
        }
        const add_scenarios = (scenario_scope: ScenarioScope) => {
            if (tab == null) return;
            tab.state.scenario_builder.add_scenarios(scenario_scope.toArray())
            return scenario_scope
        }

        if (tab != null && tab.state.scenario_builder != null) {
            const scenarios_promise = Scenario.ClientClass.batch_load(scenarios.map(s => s.key()))
            const folders_promise = ScenarioFolder.ClientClass.batch_show_scenarios(scenario_folders.map(sf => sf.key()))
            return Promise.all([scenarios_promise, folders_promise]).then(values => {
                const scenarios = values[0].toArray().concat(values[1].toArray())
                if (opts.replace_existing) {
                    tab.state.scenario_builder.replace_with_scenarios(scenarios)
                } else {
                    tab.state.scenario_builder.add_scenarios(scenarios)
                }
            })
        } else {
            tab = Scenario.create_scenario_builder_tab(project_version_id, scenarios.pluck("key"), opts.merged)
            editor_or_tab_manager.add_tab(tab)
            return new Promise<void>((resolve, _reject) => {
                tab.on("editor_mounted", () => {
                    ScenarioFolder.ClientClass
                                  .batch_show_scenarios(scenario_folders.map(sf => sf.key()))
                                  .then(add_scenarios)
                                  .then(() => resolve(null))
                })
            })
        }
    }

    show_history() {
        create_vue_app(ScenarioHistory, {
            scenario: this
        })
    }

    find_usages(opts: TabTargetOpts = {}) {
        const tab_target = resolve_tab_target(opts)

        const tab = UsagesTab.new({
            id: `usages_tab_scenario_${this.props.id}`,
            scenario_id: this.props.id,
            type: UsagesTab.type,
        })
        tab_target.add_tab(tab)

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => resolve(null))
        })
    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    static create_scenario_builder_tab(project_version_id: number, scenario_ids: number[], merged: boolean) {
        const id = generate_eid();
        return ScenarioBuilderTab.new({
            id: `scenario_tab_${id}`,
            scenario_ids,
            merged,
            project_version_id
        })
    }

    path(include_path = false) {
        const names: string[] = []
        let current_folder = this.scenario_folder
        while (current_folder != null) {
            names.push(current_folder.name())
            current_folder = current_folder.parent_folder
        }
        if (include_path) names.push(this.name())
        return `/${names.join("/")}`
    }

    // </editor-fold>

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return Scenario.show_in_sidebar(this.props.id, this.props.project_version_id, tree);
    }

    static async show_in_sidebar(scenario_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: number[];
        if (what_is_it(scenario_ids) == "Array") {
            ids = scenario_ids as number[]
        } else {
            ids = [scenario_ids as number]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(scenario_ids).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).each(scenario_id_string => {
                    const scenario_id = parseInt(scenario_id_string)
                    const scenario_folders = promise_response[scenario_id]
                    const keys = [
                        ScenarioFolder.tree_key(project_version_id),
                        ...scenario_folders.map(sf => sf.tree_key()),
                        Scenario.find(scenario_id).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: Scenario.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="TREE">

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<ScenarioScope, ScenarioFolderScope, VueRecord>> = {}
            if (current_role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Scenario.ClientClass.create({
                        scenario_folder_id: this.scenario_folder?.key(),
                        project_version_id: this.props.project_version_id
                    })
                }

                keys["ctrl++"] = () => {
                    ScenarioFolder.ClientClass.create({
                        scenario_folder_id: this.scenario_folder?.key(),
                        project_version_id: this.props.project_version_id
                    })
                }

                keys[`ctrl+return`] = (e: KeyboardEvent, node: TestaTree.Node) => {
                    const records = node.tree
                                        .get_selected()
                                        .map(n => n.record)
                    const scenarios = records.filter(r => r instanceof Scenario)
                    const folders = records.filter(r => r instanceof ScenarioFolder)
                    Scenario.open(this.props.project_version_id, scenarios as Scenario[], folders as ScenarioFolder[], { replace_existing: true })
                }

                keys.f10 = (e: KeyboardEvent, node: TestaTree.Node) => {
                    Play.show_play_modal(node.tree.get_selected_records_array())
                }

                keys.insert = () => {
                    const scenario_builder_tab = Editor.get_scenario_builder_tab();
                    if (scenario_builder_tab != null && scenario_builder_tab.state.scenario_builder != null) {
                        const scenario_builder = scenario_builder_tab.state.scenario_builder
                        scenario_builder.import_new_node(null, this, null)
                    }
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const scenario_folder_ids = nodes.map(n => n.record)
                                                 .filter(r => r instanceof ScenarioFolder)
                                                 .map(r => r.key())
                const scenario_ids = nodes.map(n => n.record)
                                          .filter(r => r instanceof Scenario)
                                          .map(r => r.key())

                if (type == "copy") {
                    ScenarioFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.scenario_folder?.key(),
                        scenario_folder_ids,
                        scenario_ids
                    )
                } else if (type == "cut") {
                    ScenarioFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.scenario_folder?.key(),
                        scenario_folder_ids,
                        scenario_ids
                    )
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXTMENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<ScenarioScope, ScenarioFolderScope, Scenario>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}
                const get_selected_records = () => node.tree.get_selected().map(n => n.record)

                items.play = Play.contextmenu_item_play(() => Play.show_play_modal(get_selected_records()))

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: ScenarioFolder._tree_new_scenario_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.open = {
                    name: "Open",
                    icon: Scenario.icon_class,
                    color: get_css_var("--button-white"),
                    key: `enter`,
                    callback: () => {
                        const records = get_selected_records()
                        const scenarios = records.filter(r => r instanceof Scenario)
                        const folders = records.filter(r => r instanceof ScenarioFolder)
                        Scenario.open(this.props.project_version_id, scenarios as Scenario[], folders as ScenarioFolder[], { replace_existing: true })
                    },
                }


                const scenario_builder_tab = Editor.get_scenario_builder_tab()
                if (scenario_builder_tab != null) {
                    if (scenario_builder_tab.state.scenario_builder.state.scenarios.length > 0) {
                        items.add_to_builder = {
                            name: "Add to builder",
                            icon: Scenario.icon_class,
                            color: get_css_var("--scenario-color"),
                            key: `${ctrl_or_meta}-enter`,
                            callback: () => {
                                const records = get_selected_records()
                                const scenarios = records.filter(r => r instanceof Scenario)
                                const folders = records.filter(r => r instanceof ScenarioFolder)
                                Scenario.open(this.props.project_version_id, scenarios as Scenario[], folders as ScenarioFolder[], { replace_existing: false })
                            }
                        }
                    }
                }


                if (!this.computed.role_is_viewer) {
                    const group_editor_tabs = Editor.get_active_tabs().filter(tab => tab instanceof GroupTab)
                    if (group_editor_tabs.length == 1) {
                        const group_editor_tab = group_editor_tabs[0] as GroupTab
                        const group = group_editor_tab.state.record as Group;
                        items.add_to_builder = {
                            name: "Add to group",
                            icon: Group.icon_class,
                            color: get_css_var("--group-color"),
                            callback: () => {
                                const records = get_selected_records()
                                const scenarios = records.filter(r => r instanceof Scenario) as Scenario[]
                                const folders = records.filter(r => r instanceof ScenarioFolder) as ScenarioFolder[]

                                ScenarioFolder.ClientClass.batch_scenario_ids(folders.map(f => f.key()))
                                              .then((folder_scenario_ids) => {
                                                  group.add_groups_scenarios(
                                                      scenarios.map(s => s.key())
                                                               .concat(folder_scenario_ids));
                                              })
                            }
                        }
                    }
                }

                items.find_usages = {
                    name: "Find usages",
                    icon: "fa-solid fa-people-carry-box",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        this.find_usages();
                    },
                }

                items.history = {
                    name: "History",
                    icon: "fas fa-history",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        this.show_history();
                    }
                }

                items.filter_reports = {
                    name: "Filter Reports",
                    icon: "fa-solid fa-filter",
                    color: Play.color(),
                    callback: () => {
                        if (Play.state.filter.schedule_id != null) {
                            const schedule = Schedule.find(Play.state.filter.schedule_id)
                            if (schedule?.props?.scenario_id != this.key()) {
                                Play.state.filter.schedule_id = null
                            }
                        }
                        Play.state.filter.scenario_folder_id = null
                        Play.state.filter.group_id = null
                        Play.state.filter.scenario_id = this.key();
                        Section.get_reports_section().enable();
                    }
                }

                const more_options: ContextMenu.Items = {}
                more_options.export = TestaTree.Tree.contextmenu_export_item(node)
                more_options.import = TestaTree.Tree.contextmenu_import_item(node)

                more_options.copy_link = {
                    name: "Copy link",
                    icon: "fa-solid fa-link",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        const params = new URLSearchParams()
                        params.set("project_version_id", this.props.project_version_id.toString())
                        params.set("scenario_id", this.key().toString())
                        copy_text_to_clipboard(update_url_parameters(window.location.href, params))
                    }
                }

                return {
                    ...items,
                    ...default_items,
                    more_options: {
                        name: "More",
                        icon: "fa-solid fa-ellipsis",
                        items: more_options
                    }
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<ScenarioScope, ScenarioFolderScope, Scenario> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.SCENARIO,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            highlight: computed(() => {
                const active_tab = Editor.active_tab()
                const is_group_tab = active_tab?.type == GroupTab.type
                if (is_group_tab) {
                    const group_tab = active_tab as GroupTab
                    const enabled = group_tab.state
                                             ?.record
                                             ?.groups_scenarios
                                             ?.some(gs => {
                                                 return gs.props.scenario_id == this.key()
                                             })
                    return {
                        enabled,
                        background_color: get_css_var("--sidebar-highlighted-group"),
                    }
                } else {
                    return {
                        enabled: this.state.scenario_builders.length > 0,
                        color: this.state.highlighted_in_builder ? get_css_var("--button-yellow") : null,
                        background_color: get_css_var("--sidebar-highlighted-scenario"),
                    }
                }
            }),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    return this.open()
                },
                batch_open_fn: (nodes) => {
                    const records = nodes.map(n => n.record)
                    const scenarios = records.filter(r => r instanceof Scenario)
                    const folders = records.filter(r => r instanceof ScenarioFolder)
                    return Scenario.open(this.props.project_version_id, scenarios as Scenario[], folders as ScenarioFolder[], { replace_existing: true })
                }
            },
            icon: computed(() => {
                return {
                    color: get_css_var("--scenario-color"),
                }
            }),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: true,
                is_drop_area: false,
            },
            hover_action: {
                icon: {
                    color: get_css_var("--button-green"),
                    class: "fa fa-play",
                    scale: 0.8
                },
                title: "Play",
                callback: () => Play.show_play_modal(this)
            },
            clipboard: this._testa_tree_clipboard(),
            contextmenu: this._tree_contextmenu(),
            event_handlers: {
                on_mouse_enter: () => {
                    if (this.state.scenario_builders.length > 0) {
                        this.state.scenario_builders.forEach(sb => {
                            sb.state.scenarios.forEach(s => s.state.highlighted_in_builder = false)
                        })
                        this.state.highlighted_in_builder = true
                        this.state.scenario_builders.forEach(sb => sb.color_scenario_builder())
                    }
                },
                on_mouse_leave: () => {
                    this.state.scenario_builders.forEach(sb => {
                        sb.state.scenarios.forEach(s => s.state.highlighted_in_builder = false)
                        sb.highlight_scenarios_for_cy_nodes(sb.get_selected_cy_nodes())
                    })
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="EDITOR">
    _editor_contextmenu(cm: CodeMirror.Editor, e: MouseEvent, _exclude_items: ScenarioEditorActionItem[] = []) {
        const items: ContextMenu.Items = {}
        return items
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
Scenario.register_resource(Scenario)
ScenarioClient.ModelClass = Scenario
ScenarioScope.ModelClass = Scenario

global_event_bus.$on("after_project_version_unload", () => {
    const scenarios = Scenario.get_scope().toArray()
    _.defer(() => scenarios.forEach(scenario => scenario.unload()))
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            Scenario.unsync()
            if (project_version_id != null) Scenario.sync(`/sync/project_version/${project_version_id}/scenarios`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

if (web.is_main) {
    ui_sync.register_ui_task("show_in_sidebar", Scenario.resource_id, (sender: string, data: UiShowInSidebarData) => {
        Scenario.show_in_sidebar(data.id as number, current.project_version.key())
    })
}

declare global {
    interface Window {
        Scenario: typeof Scenario
    }
}
window.Scenario = Scenario
// </editor-fold>

