import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb1b1215"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "file-editor-container no-padded-scrollbar"
}
const _hoisted_2 = { ref: "textarea" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", _hoisted_2, null, 512 /* NEED_PATCH */)
  ], 512 /* NEED_PATCH */))
}