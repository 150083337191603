import { Tab } from "../tab";
import { markRaw } from "vue";
import { reactive } from "vue";
import { Play } from "../../../../vue_record/models/play/play";
import PlayReport from "../../../play/reports/play/PlayReport.vue";
import { computed } from "../../../../helpers/vue/computed";

namespace PlayTab {
    export interface Input extends Tab.Input {
        play_id: number
    }

    export interface State extends Tab.State {
        record: Play,
    }
}

export class PlayTab extends Tab {
    static type = "PlayTab"

    type: string
    play_id: number

    declare state: PlayTab.State

    constructor(data: PlayTab.Input) {
        super(data)
        this.type = PlayTab.type
        this.play_id = data.play_id;
        this.component = markRaw(PlayReport);

        // if title is known, set it before we load, so that tabs are sorted correctly
        const play = Play.find(this.play_id)
        if (play != null) {
            this.computed.title = computed(() => play.props.name)
        }

        this._set_and_call_load_function(() => {
            return Play.ClientClass
                       .load(this.play_id)
                       .then(play => {
                           this.state.record = play
                           play.sync_relations();

                           this.state.icon.class = Play.icon_class
                           this.state.icon.color = computed(() => `var(--play-color)`)
                           this.computed.title = computed(() => play.props.name)

                           this.component_props = computed(() => {
                               return {
                                   play,
                                   play_tab: this
                               }
                           })

                           this.state.indicator.progress = computed(() => {
                               const play = this.state.record
                               if (play.is_finished()) return 0
                               if (!play.is_running()) return 1
                               return play.props.progress * 100
                           }) as any as number

                           this.state.indicator.style = computed(() => {
                               const play = this.state.record
                               if (play.is_finished()) return "none"
                               else return "progress"
                           }) as any as "none" | "progress"
                       })
                       .catch((error) => {
                           this._on_load_error(error)
                       })
                       .finally(() => {
                           this.set_loaded(true)
                       })
        })
        this.dnd = reactive({})

        this.on("before_close", () => {
            this.state.record?.unsync_relations()
        })
    }
}

Tab.register_tab_type(PlayTab)
