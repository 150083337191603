
import { Tab } from "../components/testa/editor/tab";
import { on_dom_content_loaded } from "./events/dom_content_loaded";
import { VueRecord } from "../vue_record/base/vue_record";
import { reactive } from "./vue/reactive";

// mouse move is not triggered when dragging file
function mousemove_file_dragging_stop() {
    if (dnd.state.is_dragging && dnd.state.is_files) {
        dnd.end()
        document.body.removeEventListener("mousemove", mousemove_file_dragging_stop)
    }
}

export class DnD {
    state = reactive({
        is_dragging: false,

        is_files: false,
        tabs: [] as Tab[],
        records: [] as VueRecord[],
        elements: [] as HTMLElement[],
    })

    start(records: VueRecord[], elements: HTMLElement[], is_files: boolean, tabs: Tab[]) {
        this.state.is_dragging = true
        this.state.is_files = is_files
        this.state.records = records;
        // @ts-ignore
        this.state.elements = elements;
        this.state.tabs = tabs;
    }

    end() {
        this.state.is_dragging = false;
        this.state.records = [];
        this.state.elements = [];
        this.state.is_files = false;
    }

    start_with_records(records: VueRecord[]) {
        this.start(records, [], false, [])
    }

    start_with_tabs(tabs: Tab[]) {
        const records = tabs.map(tab => tab.state.record).filter(r => r != null)
        this.start(records, [], false, tabs)
    }

    start_with_elements(elements: HTMLElement[]) {
        this.start([], elements, false, [])
    }

    start_with_files() {
        this.start([], [], true, [])
        document.body.addEventListener("mousemove", mousemove_file_dragging_stop)
    }

    static is_external_file(data_transfer: DataTransfer) {
        if (data_transfer != null && data_transfer.constructor != null) {
            // Source detection for Safari v5.1.7 on Windows.
            if (typeof Clipboard != 'undefined') {
                if (data_transfer.constructor == Clipboard) {
                    // @ts-ignore
                    return data_transfer.files.length > 0
                }
            }

            // Source detection for Firefox on Windows.
            if (typeof DOMStringList != 'undefined') {
                const drag_data_type = data_transfer.types;
                if (drag_data_type.constructor == DOMStringList) {
                    // @ts-ignore
                    return drag_data_type.contains('Files')
                }
            }

            // Source detection for Chrome on Windows.
            if (typeof Array != 'undefined') {
                const drag_data_type = data_transfer.types;
                if (drag_data_type.constructor == Array) {
                    // @ts-ignore
                    return drag_data_type.indexOf('Files') != -1
                }
            }
        } else return false;
    }
}

declare global {
    let dnd: DnD

    interface Window {
        dnd: DnD
    }
}

window.dnd = new DnD()

if (!IS_WEB_WORKER) {
    on_dom_content_loaded(() => {
        document.body.addEventListener("dragenter", (event) => {
            if (!dnd.state.is_dragging) {
                if (DnD.is_external_file(event.dataTransfer)) {
                    dnd.start_with_files()
                }
            }
        })



        document.body.addEventListener("dragend", (event) => {
            if (DnD.is_external_file(event.dataTransfer)) {
                dnd.end();
            }
        })
    })
}
