<template>
  <div class="title-container">
    <div class="title-primary-info">
      <a class="no-default-focus"
         :href="image.computed.download_path"
         style="width: 100%;text-decoration: none;"
         @click.prevent
      >
        <div class="name">
          <span>{{ image.props.name }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Image } from "../../vue_record/models/image";

export default defineComponent({
    components: {},
    // <editor-fold desc="PROPS">
    props: {
        image: {
            type: Object as PropType<Image>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        get_css_var,
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;

  .title-primary-info {
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
    align-items: baseline;
    width: 100%;

    .play-type-icon-container {
      font-size: 0.9em;
      margin-right: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    div.name {
      color: var(--font-color);
      margin-right: 5px;
      white-space: nowrap;
      flex-shrink: 0;

      span {
        color: var(--font-color);
      }

      span.highlight {
        background: var(--sidebar-selected-contrast);
      }
    }

    div.target {

    }
  }

  .title-secondary-info {
    color: var(--font-color-secondary);
    font-size: 0.9em;
  }
}
</style>
