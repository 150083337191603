<template>
  <div
      ref="novnc_container"
      class="novnc-container"
      @mouseenter="reset_debugger_timeout"
  >
    <template v-if="is_inspector_shown && is_connected && scenario_setting_loaded">
      <div class="screen-overlay-container">
        <div class="canvas-layer"
             :style="`height: ${canvas_height}px; width: ${canvas_width}px;`">
          <ScreenOverlay v-for="phone in scenario_setting_phones"
                         :key="phone.props.id"
                         :backend_ws_channel="backend_ws_channel"
                         :is_live="true"
                         :is_resizing="is_resizing"
                         :canvas_width="canvas_width"
                         :canvas_height="canvas_height"
                         :scenario_setting_phone="phone"
                         :tab="tab"
          />
        </div>
      </div>
    </template>
    <template v-else-if="is_disconnected">
      <div class="screen-overlay-container">
        <div class="disconnected-container">
          <span>VNC Disconnected</span>
          <ActionIcon
              icon_class="fa-solid fa-repeat"
              color_class="blue"
              title="Reconnect"
              :scale="2"
              @click="reconnect"
          />
        </div>
      </div>
    </template>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import { ScenarioSetting } from "../../../../vue_record/models/scenario_setting";
import { PropType } from "vue";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";
import { Phone } from "../../../../vue_record/models/scenario_setting/phone";
import { copy_text_to_clipboard } from "../../../../helpers/generic/copy_to_clipboard";
import { send_ws } from "../../../../helpers/generic/send_ws";
import { markRaw } from "vue";
import ScreenOverlay from "./ScreenOverlay.vue";
import { Tab } from "../../../testa/editor/tab";
import ActionIcon from "../../../testa/ActionIcon.vue";
import { AllMightyObserver } from "../../../../helpers/dom/all_mighty_observer";
import { on_dom_content_loaded } from "../../../../helpers/events/dom_content_loaded";
import { Consoler } from "../../../../helpers/api_wrappers/consoler";
import { KeyCode } from "../../../../types/globals";


let NoVNC: any
on_dom_content_loaded(() => {
    NoVNC = require("../../../../libs/novnc/core/rfb.js").default
})

const console = new Consoler("warn")
export default defineComponent({
    components: { ActionIcon, ScreenOverlay },
    props: {
        url: {
            type: String,
            required: true
        },
        backend_ws_channel: {
            type: String,
            required: false,
            default: null,
        },
        is_inspector_shown: {
            type: Boolean,
            required: true
        },
        view_only: {
            type: Boolean,
            required: false,
            default: false,
        },
        scenario_setting_id: {
            type: Number,
            required: true,
        },
        stream_id: {
            type: String,
            required: true
        },
        tab: {
            type: Object as PropType<Tab>,
            required: false,
            default: null
        }
    },
    emits: ['novnc-connected'],
    data() {
        return {
            instance_id: generate_uuid(),
            target: null as HTMLElement,
            rfb: null as any,
            desktop_name: 'novnc',
            retried: false,
            canvas_width: 0,
            canvas_height: 0,
            amo: null as AllMightyObserver,
            is_connected: false,
            is_resizing: false,
            last_activity: 0,
            canvas_size_observer: null as ResizeObserver,
            window_resize_timeout: null as NodeJS.Timeout,
            is_disconnected: false,
            scenario_setting: ScenarioSetting.find(this.scenario_setting_id)
        }
    },
    computed: {
        scenario_setting_loaded() {
            return this.scenario_setting != null
        },
        phones() {
            return this.scenario_setting?.phones
        },
        ws_url() {
            if (this.url == null) return ""
            const url = new URL(this.url);
            const host = url.host;
            const path = url.pathname;
            let protocol;

            let port = url.port;
            if (port == '') {
                port = window.location.port
                if (port == "") {
                    if (window.location.protocol == "http:") {
                        port = "80"
                    } else {
                        port = "443"
                    }
                }
            }
            if (url.protocol == 'https:') {
                protocol = 'wss:'
            } else {
                protocol = 'ws:'
            }
            return `${protocol}//${host}:${port}${path}`
        },
        scenario_setting_phones(): Phone[] {
            if (!this.scenario_setting.props.mobile_module_active || !this.scenario_setting_loaded) return [];

            return this.phones.toArray();
        },
    },
    watch: {
        'ws_url'() {
            this.rfb?.disconnect();
            this.start();
        }
    },
    created() {
    },
    beforeMount() {
        if (!this.scenario_setting_loaded) {
            ScenarioSetting.load(this.scenario_setting_id)
                .then((scenario_setting) => this.scenario_setting = scenario_setting)
        }
    },
    mounted() {
        this.target = this.$refs.novnc_container as HTMLElement;
        this.start();
    },
    unmounted() {
        if (this.rfb) {
            try {
                this.rfb.disconnect();
            } catch (e) {
                // ignored
            }
        }
        try {
            this.amo?.stop()
        } catch (e) {
            console.error(e)
        }
    },
    methods: {
        start() {
            try {
                this.is_disconnected = false;
                // RFB api https://github.com/novnc/noVNC/blob/master/docs/API.md
                this.rfb = markRaw(new NoVNC(this.target, this.ws_url, { credentials: { password: null } })) as any
                this.rfb.addEventListener('connect', this.connectedToServer);
                this.rfb.addEventListener('disconnect', this.disconnectedFromServer);
                this.rfb.addEventListener('credentialsrequired', this.credentialsAreRequired);
                this.rfb.addEventListener('desktopname', this.updateDesktopName);
                this.rfb.addEventListener('clipboard', function(e: CustomEvent) {
                    if (e.detail.hasOwnProperty('text') && e.detail.text.trim() != "") {
                        copy_text_to_clipboard(e.detail.text, false)
                    }
                })

                // Set parameters that can be changed on an active connection
                this.rfb.viewOnly = this.view_only;
                this.rfb.scaleViewport = true;
                this.rfb.resizeSession = true;
                this.rfb.background = 'transparent';
            } catch (e) {
                console.error(e);
            }
        },
        connectedToServer(e: CustomEvent) {
            this.canvas_width = this.rfb._canvas.clientWidth;
            this.canvas_height = this.rfb._canvas.clientHeight;
            const canvas = this.rfb._canvas as HTMLCanvasElement
            const canvas_container = this.$refs.novnc_container as HTMLElement
            this.amo = AllMightyObserver.new(
                {
                    before_resize: true,
                    element_before_resize: true,
                    target_element: canvas_container,
                    callback: () => {
                        this.is_resizing = true
                        if (canvas_container != null) canvas_container.style.pointerEvents = "none"
                    },
                },
                {
                    // resize: true,
                    element_resize: true,
                    element_visible: true,
                    target_element: canvas_container,
                    callback: () => {
                        this.canvas_width = canvas.clientWidth;
                        this.canvas_height = canvas.clientHeight;
                        this.rfb?._eventHandlers?.windowResize()
                    }
                },
                {
                    after_resize: true,
                    callback: (data) => {
                        const novnc_container = this.$refs.novnc_container as HTMLElement
                        // element is removed from dom or something.
                        if (novnc_container == null) return;

                        if (data.container && (novnc_container.contains(data.container) || data.container.contains(novnc_container))) {
                            this.is_resizing = false
                            const canvas_container = this.$refs.novnc_container as HTMLElement
                            if (canvas_container != null) canvas_container.style.pointerEvents = "auto"

                            // element resize is not triggered when page is maximized for some reason
                            this.canvas_width = canvas.clientWidth;
                            this.canvas_height = canvas.clientHeight;
                            this.rfb?._eventHandlers?.windowResize()
                        }
                    }
                },
                {
                    element_after_resize: true,
                    target_element: canvas_container,
                    callback: () => {
                        this.is_resizing = false
                        if (canvas_container != null) canvas_container.style.pointerEvents = "auto"
                        this.canvas_width = canvas.clientWidth;
                        this.canvas_height = canvas.clientHeight;
                        this.rfb?._eventHandlers?.windowResize()
                    }
                },
            )

            this.is_connected = true;
            canvas.parentElement.style.overflow = "hidden";
            canvas.addEventListener("keydown", (e: KeyboardEvent) => {
                if (e.code == KeyCode.A && ((e.ctrlKey && !is_pc_mac) || (e.metaKey && is_pc_mac))) {
                    console.debug(`preventing novnc ${ctrl_or_meta}-A to leak to web`)
                    e.stopPropagation()
                    e.preventDefault()
                }
            })
            this.$emit("novnc-connected")
        },
        disconnectedFromServer(e: CustomEvent) {
            this.is_connected = false
            this.is_disconnected = true
            this.amo?.stop()
            if (e.detail.clean) {
                console.log('Disconnected from', this.url);
            } else {
                console.log('Something went wrong, connection is closed, trying to reconnect to ', this.url);
                if (!this.retried) {
                    setTimeout(() => {
                        this.retried = true
                        this.start()
                    }, 1000)
                }
            }
        },
        credentialsAreRequired(e: CustomEvent) {
            const password = prompt('Password Required:');
            this.rfb.sendCredentials({ password });
        },
        updateDesktopName(e: CustomEvent) {
            this.desktop_name = e.detail.name;
        },
        sendCtrlAltDel() {
            this.rfb.sendCtrlAltDel();
            return false;
        },
        reset_debugger_timeout() {
            const current_time = new Date().getTime();
            if (current_time > (this.last_activity + 1000 * 30)) {
                this.last_activity = current_time;
                if (!this.view_only) {
                    send_ws(this.backend_ws_channel, { action: Enum.Play.Action.DEBUGGER_TIMEOUT_RESET })
                }
            }
        },
        reconnect() {
            this.is_disconnected = false
            try {
                this.rfb.disconnect()
            } catch (e) {
                console.error(e)
            }
            this.start()
        },
    }
})
</script>

<style lang="scss" scoped>
.novnc-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .screen-overlay-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: none;
    overflow: hidden;

    .canvas-layer {
      width: 0;
      height: 0;
      margin: auto;
      position: relative;
    }

    .disconnected-container {
      width: 100%;
      font-size: 2em;
      display: flex;
      flex-direction: column;
      pointer-events: auto;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
