import { ComputedRef } from "vue";
import { reactive } from "vue";
import { markRaw } from "vue";
import { SectionToggler } from "./section_toggler";
import ProjectSectionActions from "./sections/project/ProjectSectionActions.vue";
import ProjectSectionContent from "./sections/project/ProjectSectionContent.vue";
import DevicesSectionContent from "./sections/devices/DevicesSectionContent.vue";
import DevicesSectionActions from "./sections/devices/DevicesSectionActions.vue";
import ReportsSectionActions from "./sections/reports/ReportsSectionActions.vue";
import ReportsSectionContent from "./sections/reports/ReportsSectionContent.vue";
import SchedulesSectionContent from "./sections/schedules/SchedulesSectionContent.vue";
import SchedulesSectionActions from "./sections/schedules/SchedulesSectionActions.vue";
import AppsSectionContent from "./sections/apps/AppsSectionContent.vue";
import AppsSectionActions from "./sections/apps/AppsSectionActions.vue";
import { on_dom_unload } from "../../../helpers/events/on_dom_unload";
import { FeatureFlag } from "../../../vue_record/models/feature_flag";
import NotificationsSectionContent from "./sections/notifications/NotificationsSectionContent.vue";
import NotificationsSectionActions from "./sections/notifications/NotificationsSectionActions.vue";
import DatabasesSectionActions from "./sections/databases/DatabasesSectionActions.vue";
import DatabasesSectionContent from "./sections/databases/DatabasesSectionContent.vue";
import { ProjectVersion } from "../../../vue_record/models/project_version";
import { Storager } from "../../../helpers/./api_wrappers/storager";
import { computed } from "../../../helpers/vue/computed";
import { computed_ref } from "../../../helpers/vue/computed";
import LogsSectionActions from "./sections/logs/LogsSectionActions.vue";
import LogsSectionContent from "./sections/logs/LogsSectionContent.vue";

export type SectionInput = {
    id: string,
    label: string,
    enabled: boolean,
    shown: ComputedRef<boolean>
    actions: {
        component: any,
        props: any
    },
    content: {
        component: any,
        props: any
    }
}

export class Section {
    readonly id: string;
    readonly label: string;

    section_toggler: SectionToggler
    actions: {
        component: any,
        props: any
    }

    content: {
        component: any,
        props: any
    }

    private state = reactive({
        enabled: false
    })

    private computed = reactive({
        shown: null as ComputedRef<boolean>,
        storager: null as Storager
    })

    constructor(input: SectionInput) {
        this.id = input.id;
        this.label = input.label;
        this.state.enabled = input.enabled

        this.computed.shown = input.shown as any as boolean
        this.computed.storager = computed(() => {
            return current.storagers.project_version_web_type.new_scope("section_toggler", this.id)
        })

        this.actions = markRaw(input.actions)
        this.content = markRaw(input.content)
        this._init_persistence();
    }

    toggle() {
        this.state.enabled = !this.state.enabled
    }

    enable() {
        this.state.enabled = true
    }

    is_enabled() {
        return this.state.enabled
    }

    is_shown() {
        return this.computed.shown
    }

    // <editor-fold desc="INTERNAL">
    private _init_persistence() {
        const key = "state_enabled"
        on_dom_unload(() => {
            this.computed.storager.set(key, this.state.enabled)
        })

        this.state.enabled = this.computed.storager.get(key, this.is_enabled())
    }
    // </editor-fold>

    // <editor-fold desc="PREDEFINED SECTIONS">
    static defined_sections: Record<string, Section> = {}

    static get_project_section(project_version: ProjectVersion = current.project_version) {
        const id = "project"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Project",
            enabled: true,
            shown: computed_ref(() => web.is_main && current.project != null),
            actions: {
                component: ProjectSectionActions,
                props: {
                    project_version
                },
            },
            content: {
                component: ProjectSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }

    static get_logs_section() {
        const id = "logs"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Logs",
            enabled: true,
            shown: computed_ref(() => current.user?.is_superadmin()),
            actions: {
                component: LogsSectionActions,
                props: {},
            },
            content: {
                component: LogsSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }

    static get_devices_section() {
        const id = "devices"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Devices",
            enabled: false,
            shown: computed_ref(() => current.project_version_setting?.props?.android_module_enabled && web.is_main),
            actions: {
                component: DevicesSectionActions,
                props: {},
            },
            content: {
                component: DevicesSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }

    static get_apps_section() {
        const id = "apps"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Apps",
            enabled: false,
            shown: computed_ref(() => current.project_version_setting?.props?.android_module_enabled && web.is_main),
            actions: {
                component: AppsSectionActions,
                props: {},
            },
            content: {
                component: AppsSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }

    static get_schedules_section() {
        const id = "schedules"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Schedules",
            enabled: false,
            shown: computed_ref(() => current.project_version_setting?.props?.schedule_module_enabled && web.is_main),
            actions: {
                component: SchedulesSectionActions,
                props: {},
            },
            content: {
                component: SchedulesSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }

    static get_reports_section() {
        const id = "reports"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Reports",
            enabled: false,
            shown: computed_ref(() => current.user != null && (current.project_version != null || web.is_reports)),
            actions: {
                component: ReportsSectionActions,
                props: {},
            },
            content: {
                component: ReportsSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }

    static get_notifications_section() {
        const id = "notifications"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Notifications",
            enabled: false,
            shown: computed_ref(() => FeatureFlag.is_enabled("notifications", current.user, current.project_version) && web.is_main),
            actions: {
                component: NotificationsSectionActions,
                props: {},
            },
            content: {
                component: NotificationsSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }

    static get_databases_section() {
        const id = "databases"
        if (this.defined_sections[id] != null) return this.defined_sections[id]

        const section = new Section({
            id,
            label: "Databases",
            enabled: false,
            shown: computed_ref(() => FeatureFlag.is_enabled("database-connections", current.user, current.project_version) && web.is_main),
            actions: {
                component: DatabasesSectionActions,
                props: {},
            },
            content: {
                component: DatabasesSectionContent,
                props: {},
            }
        })
        this.defined_sections[id] = section

        return section
    }
    // </editor-fold>
}

declare global {
    interface Window {
        Section: typeof Section
    }
}

window.Section = Section
