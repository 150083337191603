import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02d13b4d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row align-start" }
const _hoisted_3 = { class: "col s12 usages-target" }
const _hoisted_4 = { class: "label-name" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "col s12" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Tree = _resolveComponent("Tree")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label_name), 1 /* TEXT */),
        (_ctx.snippet != null)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("i", {
                class: _normalizeClass([_ctx.Snippet.icon_class, "target-icon"]),
                style: _normalizeStyle(`color: ${_ctx.Snippet.color()};`)
              }, null, 6 /* CLASS, STYLE */),
              _createElementVNode("div", null, _toDisplayString(_ctx.snippet.name()), 1 /* TEXT */)
            ], 64 /* STABLE_FRAGMENT */))
          : (_ctx.scenario != null)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("i", {
                  class: _normalizeClass([_ctx.Scenario.icon_class, "target-icon"]),
                  style: _normalizeStyle(`color: ${_ctx.Scenario.color()};`)
                }, null, 6 /* CLASS, STYLE */),
                _createElementVNode("div", null, _toDisplayString(_ctx.scenario.name()), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : (_ctx.group != null)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("i", {
                    class: _normalizeClass([_ctx.Group.icon_class, "target-icon"]),
                    style: _normalizeStyle(`color: ${_ctx.Group.color()};`)
                  }, null, 6 /* CLASS, STYLE */),
                  _createElementVNode("div", null, _toDisplayString(_ctx.group.name()), 1 /* TEXT */)
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
      ])
    ]),
    (_ctx.usages_tree != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Tree, { tree: _ctx.usages_tree }, null, 8 /* PROPS */, ["tree"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}