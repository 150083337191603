import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c6d9212"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-info" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "type" }
const _hoisted_4 = {
  key: 1,
  class: "not-in-viewport",
  title: "The element coordinates are outside the viewport. Scroll the screen to reposition the element"
}
const _hoisted_5 = { class: "attributes-toggle" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InspectorAttributeInfo = _resolveComponent("InspectorAttributeInfo")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "dot",
        style: _normalizeStyle(_ctx.dot_style)
      }, null, 4 /* STYLE */),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.type), 1 /* TEXT */),
      (_ctx.element.in_viewport)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "hide-element-action",
            title: "Hide element to make inspector easier to navigate",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.hide_element && _ctx.hide_element(...args)))
          }, " Hide Element "))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, " Not in viewport ")),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          class: "btn",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.show_all_attributes = !_ctx.show_all_attributes))
        }, _toDisplayString(_ctx.show_all_attributes ? "Minimize" : "Expand"), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_InspectorAttributeInfo, {
      attribute: _ctx.element.attributes[_ctx.element.inspector.class_key]
    }, null, 8 /* PROPS */, ["attribute"]),
    (_ctx.element.attributes[_ctx.element.inspector.id_key]?.value != null || (_ctx.show_all_attributes && _ctx.element.attributes[_ctx.element.inspector.id_key] != null))
      ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
          key: 0,
          attribute: _ctx.element.attributes[_ctx.element.inspector.id_key]
        }, null, 8 /* PROPS */, ["attribute"]))
      : _createCommentVNode("v-if", true),
    (_ctx.element.attributes.text?.value != null || (_ctx.show_all_attributes && _ctx.element.attributes.text != null))
      ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
          key: 1,
          attribute: _ctx.element.attributes.text
        }, null, 8 /* PROPS */, ["attribute"]))
      : _createCommentVNode("v-if", true),
    ((_ctx.element.attributes.label?.value != null || (_ctx.show_all_attributes && _ctx.element.attributes.label != null)) && _ctx.element.attributes.label.value != _ctx.element.attributes[_ctx.element.inspector.id_key]?.value)
      ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
          key: 2,
          attribute: _ctx.element.attributes.label
        }, null, 8 /* PROPS */, ["attribute"]))
      : _createCommentVNode("v-if", true),
    (_ctx.element.attributes['content-desc']?.value != null || (_ctx.show_all_attributes && _ctx.element.attributes['content-desc'] != null))
      ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
          key: 3,
          attribute: _ctx.element.attributes['content-desc']
        }, null, 8 /* PROPS */, ["attribute"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_InspectorAttributeInfo, {
      attribute: _ctx.element.attributes.bounds
    }, null, 8 /* PROPS */, ["attribute"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.other_attributes, (value, attribute) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: attribute }, [
        (_ctx.show_all_attributes && value != null)
          ? (_openBlock(), _createBlock(_component_InspectorAttributeInfo, {
              key: 0,
              attribute: value
            }, null, 8 /* PROPS */, ["attribute"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}