import { Core, EdgeHandlesApi } from "cytoscape";
import { Scenario } from "../../../../../vue_record/models/scenario";
import { ScenarioNode } from "../../../../../vue_record/models/scenario_node";
import { NodeSingular } from "cytoscape";
import { get_css_var } from "../../../../../helpers/generic/get_css_var";
import { generate_uuid } from "../../../../../helpers/generate/generate_uuid";
import { Snippet } from "../../../../../vue_record/models/snippet";
import { EnumScenarioNodeType } from "../../../../../auto_generated/enums";
import { EdgeSingular } from "cytoscape";
import _ from "lodash";
import { EventBus } from "../../../../../helpers/event_bus";
import { watch } from "vue";
import { generate_eid } from "../../../../../helpers/generate/generate_eid";
import { Coords } from "../../../../../types/globals";
import { NodeCollection } from "cytoscape";
import { markRaw } from "vue";
import { KeyCode } from "../../../../../types/globals";
import { delayed_debounce } from "../../../../../helpers/generic/delayed_debounce";
import { on_dom_content_loaded } from "../../../../../helpers/events/dom_content_loaded";
import { defer } from "lodash";
import { Play } from "../../../../../vue_record/models/play/play";
import { ProjectVersion } from "../../../../../vue_record/models/project_version";
import { Consoler } from "../../../../../helpers/api_wrappers/consoler";
import { default_cytoscape_zoom_level } from "../../../../../helpers/cytoscape/init_cytoscape";
import { nextTick } from "vue";
import { reactive } from "../../../../../helpers/vue/reactive";
import { computed } from "../../../../../helpers/vue/computed";
import { effectScope } from "vue";
import { EffectScope } from "vue";


type MatchingData = {
    score: number,
    matches: Record<number, number>
}

type NodeData = {
    id: string
    parent: string
    snippet?: Snippet
    scenario?: Scenario
    nodes: ScenarioNode[],
    type: EnumScenarioNodeType
}

type EdgeData = {
    id: string
    source: string
    target: string
    scenarios: Scenario[]
    width: number
}

type CompoundData = {
    id: string,
    scenario: Scenario,
    label: string
}

type AutoconnectNodeData = {
    next_node?: NodeSingular
    previous_node?: NodeSingular
    scenario_ids?: number[]
}

type EdgeHandleData = {
    removed_ele: EdgeSingular
}

export type ScenarioBuilderOptions = {
    merged: boolean
    layout: "dagre" | "klay" | "freeform",
    global_panning: boolean
    direction: "TB" | "LR" | "BT" | "RL"
}

const default_scenario_builder_options: ScenarioBuilderOptions = {
    merged: true,
    layout: "dagre",
    global_panning: true,
    direction: "TB"
}

type OnUpdateActions = {
    layout?: boolean
    center?: boolean
    fit?: boolean
    restore_zoom?: boolean
}
type Events = "operation" | "scenario_added" | "scenario_removed" | "merge_changed" | "layoutstop"

const console = new Consoler("debug")

export class ScenarioBuilder {
    static scenario_builders: ScenarioBuilder[] = []

    private cy: cytoscape.Core;
    private project_version: ProjectVersion

    computed: {
        start_nodes?: ScenarioNode[];
        paths?: ScenarioNode[][];
        can_undo?: boolean
        can_redo?: boolean
        loading?: boolean
        role_is_viewer?: boolean
    }

    state: {
        scenarios: Scenario[]
        mutations_count: number
        undo_redo_locked: boolean
        undos_count: number
        _mutating: boolean
        _operating: number
        _update_actions_pending: OnUpdateActions[]
        _move_in_progress: boolean
    }

    private id = generate_eid()

    options: ScenarioBuilderOptions

    /** key is scenario_node_id, value is cy node */
    private cy_node_bindings: Record<number, NodeSingular> = {}

    /** key is scenario_id, value is cy array of edge */
    private cy_edge_bindings: Record<number, EdgeSingular[]> = {}
    private eh: EdgeHandlesApi;

    private edge_handle: EdgeHandleData = null;
    private event_bus: EventBus<Events, ScenarioBuilder>;

    private effect_scope: EffectScope;

    /** key is scenario_id, value is stop function top stop watching node count changes */
    private scenario_nodes_watchers: Record<number, Function> = {};
    private scenario_name_watchers: Record<number, Function> = {};
    private node_prev_watchers: Record<number, Function> = {};
    private node_next_watchers: Record<number, Function> = {};
    private node_color_watchers: Record<number, Function> = {};
    private node_name_watchers: Record<number, Function> = {};

    private allow_unmerged_view_change = true

    private contextmenu_selectors: {
        container: string
        node: string
    }

    private readonly debounced_run_layout: (animation_duration?: number) => void

    constructor(cy: Core, project_version: ProjectVersion, opts: Partial<ScenarioBuilderOptions> = {}) {
        opts = _.merge(_.cloneDeep(default_scenario_builder_options), opts)
        this.cy = markRaw(cy)
        this.project_version = project_version
        this.effect_scope = effectScope(true)
        this.debounced_run_layout = delayed_debounce(this.run_layout, 300)
        this.state = reactive({
            scenarios: [],
            undos_count: 0,
            mutations_count: 0,
            undo_redo_locked: false,
            _mutating: false,
            _operating: 0,
            _move_in_progress: false,
            _update_actions_pending: []
        })
        this.event_bus = new EventBus<Events, ScenarioBuilder>()

        this.options = opts as ScenarioBuilderOptions

        this.contextmenu_selectors = {
            container: `.__________cytoscape_container[data-cy_id='${this.id}'] canvas[data-cy_id='${this.id}']`,
            node: `.__________cytoscape_container[data-cy_id='${this.id}'] .scenario-snippet-label, .__________cytoscape_container[data-cy_id='${this.id}'] .scenario-scenario-label`
        }

        this.init_edgehandles();
        this.init_node_html_labels();
        this.init_computed();
        this.init_event_listeners();

        ScenarioBuilder.scenario_builders.push(this)
    }

    // <editor-fold desc="ACTIONS">
    run_layout(animation_duration = 300) {
        let options: any;
        // <editor-fold desc="LAYOUT OPTIONS">
        let direction: "DOWN" | "RIGHT" | "LEFT" | "UP";
        switch (this.options.layout) {
            case "dagre":
                options = {
                    name: "dagre",
                    // dagre algo options, uses default value on undefined
                    nodeSep: 20, // the separation between adjacent nodes in the same rank
                    edgeSep: 10, // the separation between adjacent edges in the same rank
                    rankSep: 30, // the separation between adjacent nodes in the same rank
                    rankDir: this.options.direction, // 'TB' for top to bottom flow, 'LR' for left to right
                    ranker: "longest-path", // Type of algorithm to assign a rank to each node in the input graph. Possible values: 'network-simplex', 'tight-tree' or 'longest-path'
                    minLen: (_edge: EdgeSingular) => {
                        return 1;
                    }, // number of ranks to keep between the source and target of the edge
                    edgeWeight: function(_edge: EdgeSingular) {
                        return 1;
                    }, // higher weight edges are generally made shorter and straighter than lower weight edges

                    // general layout options
                    fit: false, // whether to fit to viewport
                    padding: 10, // fit padding
                    animate: true, // whether to transition the node positions
                    animationDuration: animation_duration, // duration of animation in ms if enabled
                    animationEasing: undefined, // easing of animation if enabled
                    boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
                    ready: function() {
                    }, // on layoutready
                    stop: function() {
                    } // on layoutstop
                }
                break;
            case "klay":
                switch (this.options.direction) {
                    case "TB":
                        direction = "DOWN"
                        break;
                    case "LR":
                        direction = "RIGHT"
                        break;
                    case "BT":
                        direction = "UP"
                        break;
                    case "RL":
                        direction = "LEFT"
                        break;
                    default:
                        console.error(`UNSUPPORTED graph direction (${this.options.direction})`)
                }
                options = {
                    name: "klay",
                    nodeDimensionsIncludeLabels: false, // Boolean which changes whether label dimensions are included when calculating node dimensions
                    fit: false, // Whether to fit
                    padding: 20, // Padding on fit
                    animate: true, // Whether to transition the node positions
                    animateFilter: function animateFilter(_node: NodeSingular, _i: any) {
                        return true;
                    }, // Whether to animate specific nodes when animation is on; non-animated nodes immediately go to their final positions
                    animationDuration: animation_duration, // Duration of animation in ms if enabled
                    animationEasing: undefined, // Easing of animation if enabled
                    transform: function transform(_node: NodeSingular, pos: any) {
                        return pos;
                    }, // A function that applies a transform to the final node position
                    ready: undefined, // Callback on layoutready
                    stop: undefined, // Callback on layoutstop
                    klay: {
                        // Following descriptions taken from http://layout.rtsys.informatik.uni-kiel.de:9444/Providedlayout.html?algorithm=de.cau.cs.kieler.klay.layered
                        addUnnecessaryBendpoints: false, // Adds bend points even if an edge does not change direction.
                        aspectRatio: 0.1, // The aimed aspect ratio of the drawing, that is the quotient of width by height
                        borderSpacing: 40, // Minimal amount of space to be left to the border
                        compactComponents: false, // Tries to further compact components (disconnected sub-graphs).
                        crossingMinimization: 'LAYER_SWEEP', // Strategy for crossing minimization.
                        /* LAYER_SWEEP The layer sweep algorithm iterates multiple times over the layers, trying to find node orderings that minimize the number of crossings. The algorithm uses randomization to increase the odds of finding a good result. To improve its results, consider increasing the Thoroughness option, which influences the number of iterations done. The Randomization seed also influences results.
                        INTERACTIVE Orders the nodes of each layer by comparing their positions before the layout algorithm was started. The idea is that the relative order of nodes as it was before layout was applied is not changed. This of course requires valid positions for all nodes to have been set on the input graph before calling the layout algorithm. The interactive layer sweep algorithm uses the Interactive Reference Point option to determine which reference point of nodes are used to compare positions. */
                        cycleBreaking: 'GREEDY', // Strategy for cycle breaking. Cycle breaking looks for cycles in the graph and determines which edges to reverse to break the cycles. Reversed edges will end up pointing to the opposite direction of regular edges (that is, reversed edges will point left if edges usually point right).
                        /* GREEDY This algorithm reverses edges greedily. The algorithm tries to avoid edges that have the Priority property set.
                        INTERACTIVE The interactive algorithm tries to reverse edges that already pointed leftwards in the input graph. This requires node and port coordinates to have been set to sensible values. */
                        direction, // Overall direction of edges: horizontal (right / left) or vertical (down / up)
                        /* UNDEFINED, RIGHT, LEFT, DOWN, UP */
                        edgeRouting: 'ORTHOGONAL', // Defines how edges are routed (POLYLINE, ORTHOGONAL, SPLINES)
                        // edgeRouting: 'SPLINES', // Defines how edges are routed (POLYLINE, ORTHOGONAL, SPLINES)
                        // edgeSpacingFactor: 0.5, // Factor by which the object spacing is multiplied to arrive at the minimal spacing between edges.
                        edgeSpacingFactor: 1, // Factor by which the object spacing is multiplied to arrive at the minimal spacing between edges.
                        feedbackEdges: false, // Whether feedback edges should be highlighted by routing around the nodes.
                        fixedAlignment: 'NONE', // Tells the BK node placer to use a certain alignment instead of taking the optimal result.  This option should usually be left alone.
                        /* NONE Chooses the smallest layout from the four possible candidates.
                        LEFTUP Chooses the left-up candidate from the four possible candidates.
                        RIGHTUP Chooses the right-up candidate from the four possible candidates.
                        LEFTDOWN Chooses the left-down candidate from the four possible candidates.
                        RIGHTDOWN Chooses the right-down candidate from the four possible candidates.
                        BALANCED Creates a balanced layout from the four possible candidates. */
                        inLayerSpacingFactor: 1.0, // Factor by which the usual spacing is multiplied to determine the in-layer spacing between objects.
                        layoutHierarchy: false, // Whether the selected layouter should consider the full hierarchy
                        // layoutHierarchy: true, // Whether the selected layouter should consider the full hierarchy
                        linearSegmentsDeflectionDampening: 0.3, // Dampens the movement of nodes to keep the diagram from getting too large.
                        mergeEdges: false, // Edges that have no ports are merged so they touch the connected nodes at the same points.
                        mergeHierarchyCrossingEdges: true, // If hierarchical layout is active, hierarchy-crossing edges use as few hierarchical ports as possible.
                        // nodeLayering: 'NETWORK_SIMPLEX', // Strategy for node layering.
                        nodeLayering: 'NETWORK_SIMPLEX', // Strategy for node layering.
                        /* NETWORK_SIMPLEX This algorithm tries to minimize the length of edges. This is the most computationally intensive algorithm. The number of iterations after which it aborts if it hasn't found a result yet can be set with the Maximal Iterations option.
                        LONGEST_PATH A very simple algorithm that distributes nodes along their longest path to a sink node.
                        INTERACTIVE Distributes the nodes into layers by comparing their positions before the layout algorithm was started. The idea is that the relative horizontal order of nodes as it was before layout was applied is not changed. This of course requires valid positions for all nodes to have been set on the input graph before calling the layout algorithm. The interactive node layering algorithm uses the Interactive Reference Point option to determine which reference point of nodes are used to compare positions. */
                        // nodePlacement: 'BRANDES_KOEPF', // Strategy for Node Placement
                        nodePlacement: 'SIMPLE', // Strategy for Node Placement
                        /* BRANDES_KOEPF Minimizes the number of edge bends at the expense of diagram size: diagrams drawn with this algorithm are usually higher than diagrams drawn with other algorithms.
                        LINEAR_SEGMENTS Computes a balanced placement.
                        INTERACTIVE Tries to keep the preset y coordinates of nodes from the original layout. For dummy nodes, a guess is made to infer their coordinates. Requires the other interactive phase implementations to have run as well.
                        SIMPLE Minimizes the area at the expense of... well, pretty much everything else. */
                        randomizationSeed: 1, // Seed used for pseudo-random number generators to control the layout algorithm; 0 means a new seed is generated
                        routeSelfLoopInside: false, // Whether a self-loop is routed around or inside its node.
                        separateConnectedComponents: true, // Whether each connected component should be processed separately
                        spacing: 35, // Overall setting for the minimal amount of space to be left between objects
                        thoroughness: 8 // How much effort should be spent to produce a nice layout..
                    },
                    priority: function priority(_edge: EdgeSingular): void {
                        return null;
                    } // Edges with a non-nil value are skipped when geedy edge cycle breaking is enabled
                }
                break;
            case "freeform":
                return;
            default:
                throw new Error("Unknown layout " + this.options.layout)
        }
        // </editor-fold>

        console.debug("running layout with options: ", options)
        this.cy.layout(options).run()
    }

    color_scenario_builder() {
        const highlight_color = get_css_var('--sb-highlight');
        const invalid_color = get_css_var('--sb-invalid');
        const selected_color = get_css_var('--sb-selected');
        const edge_color = cy_cfg.edge.color
        this.cy.edges().forEach(edge => {
            const data = edge.data() as EdgeData
            if (edge.selected()) {
                edge.style("line-color", selected_color)
                edge.style("target-arrow-color", selected_color)
            } else if (data.scenarios.some(s => s.state.invalid_scenario_builder_path)) {
                edge.style("line-color", invalid_color)
                edge.style("target-arrow-color", invalid_color)
            } else if (this.state.scenarios.length > 1 && data.scenarios.some(s => s.state.highlighted_in_builder)) {
                edge.style("line-color", highlight_color)
                edge.style("target-arrow-color", highlight_color)
            } else {
                edge.style("line-color", edge_color)
                edge.style("target-arrow-color", edge_color)
            }
        })
    }

    replace_with_scenarios(scenarios: Scenario[]) {
        this._do_update(() => {
            const remove_scenarios = this.state.scenarios.filter(s => !scenarios.some(s2 => s2.key() == s.key())) as Scenario[]
            this.remove_scenarios(remove_scenarios)
            this.add_scenarios(scenarios)
        }, { layout: true })
    }

    // noinspection JSUnusedGlobalSymbols
    toggle_scenario(scenario: Scenario) {
        this._do_update(() => {
            if (this.state.scenarios.some(s => s.key() == scenario.key())) {
                this.remove_scenario(scenario)
            } else {
                this.add_scenario(scenario)
            }
        }, { layout: true })
    }

    add_scenarios(scenarios: Scenario[]) {
        this._do_update(() => {
            scenarios.forEach(scenario => this.add_scenario(scenario))
        }, { layout: true })
    }

    add_scenario(scenario: Scenario) {
        this._do_update(() => {
            if (this.state.scenarios.some(s => s.key() == scenario.key())) return;

            console.log(`adding scenario: `, scenario.log());

            if (!this.options.merged || this.state.scenarios.length == 0) {
                // if there are no scenarios in builder, and we are adding this single scenario
                // then make a scenario compound (group all nodes under this scenario) --> this will show scenario label at the top
                this.cy.add({
                    group: "nodes",
                    classes: "scenario multiline-auto",
                    data: {
                        id: "scenario_" + scenario.key(),
                        scenario,
                        label: scenario.props.name,
                    }
                })
            } else if (this.options.merged) {
                // we have multiple scenarios, and they are merged
                // we cannot show scenario title at the top, so remove all child nodes from each compound
                // and then remove the empty compounds
                const scenario_compound = this.cy.nodes(".scenario")
                scenario_compound.children().forEach(node => {
                    node.move({ parent: null })
                })
                scenario_compound.remove();
            }

            this._add_scenario_nodes_in_cy(scenario.nodes.toArray())
            this.effect_scope.run(() => {
                this.scenario_name_watchers[scenario.key()] = watch(() => scenario.props.name,
                    () => {
                        this.cy.nodes(".scenario").forEach(scenario_compound_node => {
                            const data = scenario_compound_node.data()
                            if (data.scenario.props.id == scenario.key()) {
                                scenario_compound_node.data('label', scenario.props.name)
                            }
                        })
                    })
                this.scenario_nodes_watchers[scenario.key()] = watch(
                    () => scenario.nodes.toArray(),
                    (new_scenario_nodes, old_scenario_nodes) => {
                        try {
                            const added = _.differenceBy(new_scenario_nodes, old_scenario_nodes, (obj) => obj.key())
                            const removed = _.differenceBy(old_scenario_nodes, new_scenario_nodes, (obj) => obj.key());
                            console.log(`scenario_watcher triggered on scenario: ${scenario.log()}`,
                                "added: ", added.map(a => a.log()),
                                "removed: ", removed.map(r => r.log()));

                            this._remove_scenario_nodes_in_cy(removed)

                            // added.forEach(scenario_node => {
                            //     const previous_node = this.cy_node_bindings[scenario_node.props.previous_scenario_node_id]
                            //     const path = this._find_path_for_scenario_node(Object.values(new_nodes), scenario_node)
                            //     const max_matches = this._find_matches_for_path(path)
                            //     this._add_scenario_node_in_cy(previous_node, scenario_node, max_matches)
                            // })
                            this._add_scenario_nodes_in_cy(added)
                            this.debounced_run_layout()
                        } catch (e) {
                            console.error(e)
                        }
                    },
                    { flush: "sync" }
                )
            })

            this.state.scenarios.push(scenario)
            scenario.state.scenario_builders.push(this)
            this.event_bus.$emit("scenario_added", this, scenario);
            this._set_mutations_count()
            this._set_undos_count()
        })
    }

    remove_scenarios(scenarios: Scenario[]) {
        this._do_update(() => {
            scenarios.forEach(scenario => this.remove_scenario(scenario))
        }, { layout: true })
    }

    remove_scenario(scenario: Scenario) {
        this._do_update(() => {
            console.log("removing scenario", scenario.log());
            this._remove_scenario_nodes_in_cy(scenario.nodes.toArray())

            // remove compound
            this.cy.nodes(".scenario").forEach(function(node) {
                const data = node.data() as CompoundData
                if (data.scenario.key() == scenario.key()) node.remove();
            })

            this.state.scenarios = this.state.scenarios.filter(s => s.key() != scenario.key());
            scenario.state.scenario_builders = scenario.state.scenario_builders.filter(sb => sb != this)
            scenario.state.highlighted_in_builder = false
            this.scenario_nodes_watchers[scenario.key()]()
            delete this.scenario_nodes_watchers[scenario.key()]

            this.scenario_name_watchers[scenario.key()]()
            delete this.scenario_name_watchers[scenario.key()]

            this.event_bus.$emit("scenario_removed", this, scenario)
            this._set_mutations_count()
            this._set_undos_count();
        }, { layout: true })
    }

    set_merged(state: boolean) {
        if (this.options.merged == state) return

        this.options.merged = state;

        const scenarios = this.state.scenarios as Scenario[]
        this.remove_scenarios(scenarios)
        this.add_scenarios(scenarios)

        this.event_bus.$emit("merge_changed", this, this.options.merged)
    }

    disconnect_and_remove(cy_nodes: NodeSingular[], cy_edges: EdgeSingular[]) {
        if (this.state._mutating) return

        let source_scenario_node_ids: number[] = [];
        let target_scenario_node_ids: number[] = [];
        let scenario_ids: number[] = [];
        cy_edges.forEach(edge => {
            const data = edge.data() as EdgeData
            scenario_ids = scenario_ids.concat(data.scenarios.map(s => s.key()));

            const source_node_data = edge.source().data() as NodeData
            const target_node_data = edge.target().data() as NodeData

            const source_scenario_nodes = source_node_data.nodes.filter(t => scenario_ids.some(i => i == t.props.scenario_id))
            const target_scenario_nodes = target_node_data.nodes.filter(t => scenario_ids.some(i => i == t.props.scenario_id))
            source_scenario_node_ids = source_scenario_node_ids.concat(source_scenario_nodes.map(k => k.key()))
            target_scenario_node_ids = target_scenario_node_ids.concat(target_scenario_nodes.map(k => k.key()))
        })
        const disconnect_data = {
            scenario_ids,
            source_scenario_node_ids,
            target_scenario_node_ids
        }

        // nodes
        const remove_scenario_node_data: { x: number, y: number, id: number }[] = []
        cy_nodes.forEach(node => {
            const data = node.data() as NodeData
            data.nodes.forEach(scenario_node => {
                if (!remove_scenario_node_data.some(remove_data => remove_data.id == scenario_node.key())) {
                    remove_scenario_node_data.push({
                        x: node.position().x,
                        y: node.position().y,
                        id: scenario_node.key()
                    });
                }
            })
        });

        if (remove_scenario_node_data.length > 0 || scenario_ids.length > 0) {
            this.state._mutating = true

            $.ajax({
                url: '/scenario_builders/disconnect_and_remove',
                type: 'POST',
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id: this.project_version.key(),
                    remove_scenario_node_data,
                    disconnect_data,
                    shift_mode: false,
                    authenticity_token,
                }),
                success: () => {
                    this.state.mutations_count++;
                    this.state._mutating = false
                },
                error: () => {
                    this.state._mutating = false
                },
                statusCode: ajax_status_codes,
            })
        }
    }

    move_up(scenario_nodes: ScenarioNode[]) {
        this._move_up_or_down(scenario_nodes, "up")
    }

    move_down(scenario_nodes: ScenarioNode[]) {
        this._move_up_or_down(scenario_nodes, "down")
    }

    import_new_node(snippet: Snippet, scenario: Scenario, coords: Coords) {
        type NewNodeData = { scenario_id: number, previous_scenario_node_id?: number, next_scenario_node_id?: number }
        const autoconnect_node = this.cy.nodes(".autoconnect-node").toArray()[0]
        const new_nodes_data: NewNodeData[] = []
        if (autoconnect_node != null) {
            const autoconnect_data = autoconnect_node.data() as AutoconnectNodeData
            if (autoconnect_data.scenario_ids) {
                // autoconnect is edge
                autoconnect_data.scenario_ids.forEach(scenario_id => {
                    const prev_node_data = autoconnect_data.previous_node.data() as NodeData;
                    const next_node_data = autoconnect_data.next_node.data() as NodeData;
                    new_nodes_data.push({
                        scenario_id,
                        previous_scenario_node_id: prev_node_data.nodes.find(scenario_node => scenario_node.props.scenario_id == scenario_id)?.props?.id,
                        next_scenario_node_id: next_node_data.nodes.find(scenario_node => scenario_node.props.scenario_id == scenario_id)?.props?.id
                    });
                })
            } else if (autoconnect_data.hasOwnProperty("next_node")) {
                const next_node_data = autoconnect_data.next_node.data() as NodeData
                next_node_data.nodes.forEach(scenario_node => {
                    new_nodes_data.push({
                        scenario_id: scenario_node.props.scenario_id,
                        next_scenario_node_id: scenario_node.props.id
                    });
                })
            } else if (autoconnect_data.hasOwnProperty("previous_node")) {
                const prev_node_data = autoconnect_data.previous_node.data() as NodeData;
                prev_node_data.nodes.forEach(scenario_node => {
                    new_nodes_data.push({
                        scenario_id: scenario_node.props.scenario_id,
                        previous_scenario_node_id: scenario_node.props.id
                    });
                })
            }
        } else {
            this.state.scenarios.forEach(scenario => {
                new_nodes_data.push({
                    scenario_id: scenario.key()
                })
            })
        }

        if (coords == null) {
            let x: number = 0;
            let y: number = 0;
            this.cy.nodes().forEach(n => {
                const position = n.position()
                if (position.x > x) x = position.x
                if (position.y > y) y = position.y
            })

            coords = {
                y: y + cy_cfg.node.height * 1.7,
                x: x / 2 + cy_cfg.node.width / 3
            }
        }
        this.remove_autoconnect_dot();
        this.state._mutating = true
        $.ajax({
            url: '/scenario_builders/add_node',
            type: 'POST',
            processData: false,
            contentType: "application/json",
            data: JSON.stringify({
                snippet_id: snippet?.key(),
                scenario_id: scenario?.key(),
                new_nodes_data,
                x: coords.x,
                y: coords.y,
                authenticity_token
            }),
            success: () => {
                this.state.mutations_count++;
                this.state._mutating = false
            },
            error: () => {
                this.state._mutating = false
            },
            statusCode: ajax_status_codes
        })
    }

    play() {
        Play.show_play_modal(this.state.scenarios)
    }

    zoom_in(position: Coords, duration = 300, diff = 0.125) {
        this._zoom(position, duration, diff)
    }

    zoom_out(position: Coords, duration = 300, diff = -0.125) {
        this._zoom(position, duration, diff)
    }

    center(duration = 300) {
        this._animate({ center: true }, duration)
    }

    fit(duration = 300) {
        this._animate({ fit: true }, duration)
    }

    restore_zoom(duration = 300) {
        this._animate({ restore_zoom: true }, duration)
    }

    _animate(what: { center?: boolean, fit?: boolean, restore_zoom?: boolean }, duration = 300) {
        const animate_object: any = {}
        if (what.center) {
            animate_object.center = {
                eles: this.cy.nodes(".scenario-snippet, .scenario-scenario")
            }
        }
        if (what.fit) {
            animate_object.fit = {
                eles: this.cy.nodes(".scenario-snippet, .scenario-scenario"),
                padding: 20
            }
        }

        if (what.restore_zoom) {
            // Get the current zoom level and pan position
            const zoom = this.cy.zoom();
            const pan = this.cy.pan();

            // Calculate the center of the viewport
            const centerX = (window.innerWidth / 2 - pan.x) / zoom;
            const centerY = (window.innerHeight / 2 - pan.y) / zoom;
            animate_object.zoom = {
                level: default_cytoscape_zoom_level,
                position: {
                    x: centerX,
                    y: centerY
                }
            }
        }
        this.cy.animate(animate_object,
            { duration });
    }

    redo() {
        if (this.state.undo_redo_locked) return;
        if (this.state.scenarios.length <= 0) return;
        this.state.undos_count--;

        this.state.undo_redo_locked = true
        $.ajax({
            url: `/scenario_builders/redo`,
            type: "POST",
            processData: false,
            contentType: "application/json",
            data: JSON.stringify({
                authenticity_token,
                scenario_ids: this.state.scenarios.map(s => s.key()),
                step: 1
            }),
            statusCode: ajax_status_codes,
            success: () => {
                this.state.undo_redo_locked = false
            },
            error: () => {
                this.state.undo_redo_locked = false
            }
        })
    }

    undo() {
        if (this.state.undo_redo_locked) return;
        if (this.state.scenarios.length <= 0) return;
        this.state.undos_count++;

        this.state.undo_redo_locked = true
        $.ajax({
            url: `/scenario_builders/undo`,
            type: "POST",
            processData: false,
            contentType: "application/json",
            data: JSON.stringify({
                authenticity_token,
                scenario_ids: this.state.scenarios.map(s => s.key()),
                step: 1
            }),
            statusCode: ajax_status_codes,
            success: () => {
                this.state.undo_redo_locked = false
            },
            error: () => {
                this.state.undo_redo_locked = false
            }
        })
    }

    snippetize() {
        return new Promise<void>((resolve, _reject) => {
            resolve(null);
        })
    }


    add_other_scenarios(nodes: ScenarioNode[]) {
        const snippet_ids: number[] = nodes.filter(n => n.props.type == Enum.Scenario.Node.Type.SNIPPET).map(n => n.props.scenario_node_snippet_id)
        const scenario_ids: number[] = nodes.filter(n => n.props.type == Enum.Scenario.Node.Type.SCENARIO).map(n => n.props.scenario_node_scenario_id)

        Scenario.ClientClass
                .scenarios_with(snippet_ids, scenario_ids, this.state.scenarios.pluck("key"))
                .then(scenario_scope => this.add_scenarios(scenario_scope.toArray()))
                .catch(() => toastr.error("Failed to fetch other scenarios"))
    }


    destroy_self() {
        console.log("scenario builder - destroying self");
        this.state.scenarios.forEach(scenario => {
            scenario.state.scenario_builders = scenario.state.scenario_builders.filter(sb => sb != this)
        })
        ScenarioBuilder.scenario_builders = ScenarioBuilder.scenario_builders.filter(sb => sb != this)
        this.effect_scope.stop()
        $.contextMenu('destroy', this.contextmenu_selectors.node)
        $.contextMenu('destroy', this.contextmenu_selectors.container)
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    allow_undo() {
        return !this.state.undo_redo_locked &&
            !this.computed.role_is_viewer &&
            this.state.scenarios.length > 0 &&
            this.computed.can_undo &&
            !this.computed.loading
    }

    allow_redo() {
        return !this.state.undo_redo_locked &&
            !this.computed.role_is_viewer &&
            this.state.scenarios.length > 0 &&
            this.computed.can_redo &&
            !this.computed.loading
    }

    highlight_scenarios_for_cy_nodes(cy_nodes: NodeCollection) {
        cy_nodes = cy_nodes.nodes()
        if (cy_nodes.length == 0) return
        if (this.state.scenarios.length > 1) {
            this.state.scenarios.forEach(s => s.state.highlighted_in_builder = false)
            cy_nodes.forEach((n: NodeSingular) => {
                const data = n.data() as NodeData
                data.nodes.forEach(n => {
                    // SOMEHOW the nodes in node.data() lose reactivity, therefore we must use rthis
                    n = n.get_reactive_object()
                    try {
                        n.in_scenario.state.highlighted_in_builder = true
                    } catch (e) {
                        console.error(e)
                    }
                })
            })
        }

        this.color_scenario_builder();
    }

    get_selected_cy_nodes() {
        return this.cy.nodes(".scenario-snippet:selected,.scenario-scenario:selected")
    }

    get_selected_scenario_nodes() {
        return this.get_selected_cy_nodes()
                   .toArray()
                   .map(n => {
                       const data = n.data() as NodeData
                       return data.nodes
                   })
                   .flat()
    }

    get_selected_cy_edges() {
        return this.cy.edges(":selected")
    }

    scroll_to_snippet(snippet_id: number, duration = 800) {
        const cy_nodes = this.cy.nodes(".scenario-snippet").filter((node) => {
            const data = node.data() as NodeData
            return data.snippet?.key() == snippet_id
        })
        this.cy.$(":selected").unselect();
        cy_nodes.select();

        /* disabling scroll, just selecting
        let ele_center_pan = this.cy.getCenterPan(cy_nodes);
        let pan = this.cy.pan()
        if (cy_nodes.length == 1) {
            this.cy.animate({
                    pan: {
                        x: pan.x,
                        y: ele_center_pan.y
                    },
                    zoom: this.cy.zoom()
                },
                {
                    duration: duration
                });
        } else if (cy_nodes.length > 1) {
            this.cy.animate({
                    fit: {
                        eles: cy_nodes,
                        padding: 30,
                    }
                },
                {
                    duration: duration
                });
        }
        */
    }

    open_or_toggle_scenario_nodes(nodes: ScenarioNode[]) {
        nodes.filter(n => n.props.type == Enum.Scenario.Node.Type.SNIPPET)
             .forEach(n => n.snippet.open())


        const scenario_ids = nodes.filter(n => n.props.type == Enum.Scenario.Node.Type.SCENARIO)
                                  .map(n => n.props.scenario_node_scenario_id)

        if (scenario_ids.every(scenario_id => this.state.scenarios.some(s => s.key() == scenario_id))) {
            const scenarios = this.state.scenarios.filter(s => scenario_ids.includes(s.key())) as Scenario[]
            this.remove_scenarios(scenarios)
        } else {
            Scenario.ClientClass.batch_load(scenario_ids).then(scenario_scope => {
                this.add_scenarios(scenario_scope.toArray())
                this.set_merged(false)
            })
        }
    }

    remove_autoconnect_dot() {
        this.cy.nodes(".autoconnect-node").remove()
    }

    show_dragover_autoconnect_dot(coords: Coords) {
        const x = coords.x
        const y = coords.y

        const closest = this._find_closest_element_by_coords(x, y)
        this.remove_autoconnect_dot()
        if (closest.node != null) {
            if (this.options.direction == "TB") {
                let data: AutoconnectNodeData;
                let y: number;
                if (closest.position == "left_top" || closest.position == "right_top") {
                    data = {
                        next_node: closest.node
                    }
                    y = closest.node.position().y - cy_cfg.node.height / 2
                } else {
                    data = {
                        previous_node: closest.node
                    }
                    y = closest.node.position().y + cy_cfg.node.height / 2
                }
                this.cy.add({
                    group: "nodes",
                    classes: "autoconnect-node",
                    position: {
                        x: closest.node.position().x,
                        y
                    },
                    data
                })
            }
        }
        if (closest.edge != null) {
            const x1 = closest.edge.source().position().x;
            const y1 = closest.edge.source().position().y;
            const x2 = closest.edge.target().position().x;
            const y2 = closest.edge.target().position().y;
            const middle_x = (x2 + x1) / 2;
            const middle_y = (y2 + y1) / 2;
            const data: AutoconnectNodeData = {
                next_node: closest.edge.target(),
                previous_node: closest.edge.source(),
                scenario_ids: closest.edge.data().scenarios.pluck("props").pluck("id")
            }
            this.cy.add({
                group: "nodes",
                classes: "autoconnect-node",
                data,
                position: {
                    x: middle_x,
                    y: middle_y
                }
            })
        }
    }

    get_coords_from_event(e: DragEvent | MouseEvent) {
        const canvas = this.cy._private.container.querySelector("canvas")
        const $canvas = $(canvas)
        const canvas_offset = $canvas.offset()
        let x = e.pageX - canvas_offset.left
        let y = e.pageY - canvas_offset.top

        const pan = this.cy.pan();
        const zoom = this.cy.zoom();

        x = (x - pan.x) / zoom
        y = (y - pan.y) / zoom

        return { x, y } as Coords
    }

    get_rendered_bounding_box() {
        return this.cy.nodes().renderedBoundingBox();
    }

    nodes_count() {
        return this.cy.nodes(".scenario-snippet, .scenario-scenario").length
    }

    // </editor-fold>

    // <editor-fold desc="EXTENSIONS">
    init_edgehandles() {
        this.eh = this.cy.edgehandles(
            {
                preview: false, // whether to show added edges preview before releasing selection
                hoverDelay: 150, // time spent hovering over a target node before it is considered selected
                handleNodes: 'node', // selector/filter function for whether edges can be made from a given node
                snap: false, // when enabled, the edge can be drawn by just moving close to a target node
                snapThreshold: 50, // the target node must be less than or equal to this many pixels away from the cursor/finger
                snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
                noEdgeEventsInDraw: false, // set events:no to edges during draws, prevents mouseouts on compounds
                disableBrowserGestures: true, // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
                handlePosition: function(_node) {
                    return 'middle bottom'; // sets the position of the handle in the format of "X-AXIS Y-AXIS" such as "left top", "middle top"
                },
                handleInDrawMode: false, // whether to show the handle in draw mode
                edgeType: function(_sourceNode, _targetNode) {
                    // can return 'flat' for flat edges between nodes or 'node' for intermediate node between them
                    // returning null/undefined means an edge can't be added between the two nodes
                    return 'flat';
                },
                loopAllowed: function(_node) {
                    // for the specified node, return whether edges from itself to itself are allowed
                    return false;
                },
                nodeLoopOffset: -50, // offset for edgeType: 'node' loops
                nodeParams: function(_sourceNode, _targetNode) {
                    // for edges between the specified source and target
                    // return element object to be passed to cy.add() for intermediary node
                    return {};
                },
                edgeParams: (sourceNode, _targetNode, _i) => {
                    // for edges between the specified source and target
                    // return element object to be passed to cy.add() for edge
                    // NB: i indicates edge index in case of edgeType: 'node'

                    let source_scenarios: Scenario[];
                    if (this.edge_handle == null) {
                        const data = sourceNode.data() as NodeData;
                        source_scenarios = data.nodes.map(n => n.in_scenario).uniq_by(s => s.key())
                    } else {
                        const data = this.edge_handle.removed_ele.data() as EdgeData
                        source_scenarios = data.scenarios
                    }
                    if (typeof source_scenarios == "undefined") source_scenarios = []
                    return {
                        data: {
                            scenarios: source_scenarios,
                            width: source_scenarios.length + 1
                        }
                    };
                },
                ghostEdgeParams: () => {
                    let width = 1
                    if (this.edge_handle != null) {
                        width = this.edge_handle.removed_ele.data().width > 10 ? 10 : this.edge_handle.removed_ele.data().width;
                    }
                    // return element object to be passed to cy.add() for the ghost edge
                    // (default classes are always added for you)
                    return { data: { width } };
                },
                show: function(_sourceNode) {
                    // fired when handle is shown
                },
                hide: function(_sourceNode) {
                    // fired when the handle is hidden
                },
                start: function(_sourceNode) {
                    // fired when edgehandles interaction starts (drag on handle)
                },
                complete: (sourceNode, targetNode, drawnEdge) => {
                    const edge_data = drawnEdge.data() as EdgeData
                    const scenarios = edge_data.scenarios

                    drawnEdge.style("width", scenarios.length + 1 > 10 ? 10 : scenarios.length + 1);
                    drawnEdge.remove(); // will be added with sync

                    this._connect_scenario_nodes(sourceNode.data() as NodeData, targetNode.data() as NodeData, scenarios.map(s => s.key()))
                },
                stop: function(_sourceNode) {
                    // fired when edgehandles interaction is stopped (either complete with added edges or incomplete)
                },
                cancel: (_sourceNode, _cancelledTargets) => {
                    if (this.edge_handle != null) {
                        this.cy.add(this.edge_handle.removed_ele)
                    }
                    // fired when edgehandles are cancelled (incomplete gesture)
                },
                hoverover: function(_sourceNode, _targetNode) {
                    // fired when a target is hovered
                },
                hoverout: function(_sourceNode, _targetNode) {
                    // fired when a target isn't hovered anymore
                },
                previewon: function(_sourceNode, _targetNode, _previewEles) {
                    // fired when preview is shown
                },
                previewoff: function(_sourceNode, _targetNode, _previewEles) {
                    // fired when preview is hidden
                },
                drawon: function() {
                    // fired when draw mode enabled
                },
                drawoff: function() {
                    // fired when draw mode disabled
                }
            }
        )
        this.eh.disable();
    }

    init_node_html_labels() {
        this.cy.nodeHtmlLabel([{
            query: '.scenario-snippet',
            valign: 'center',
            halign: 'center',
            halignBox: 'center',
            valignBox: 'center',
            cssClass: 'scenario-snippet-label',
            tpl: (data: NodeData) => {
                return `<span data-cy_id="${this.id}" data-snippet_id="${data.snippet.props.id}" data-node_id="${data.id}">${data.snippet.props.name}</span>`;
            }
        }])


        this.cy.nodeHtmlLabel([{
            query: '.scenario',
            valign: 'top',
            halign: 'center',
            halignBox: 'center',
            valignBox: 'top',
            cssClass: 'scenario-label',
            tpl: (data: CompoundData) => {
                return `<span data-cy_id="${this.id}" data-scenario_id="${data.scenario.key()}" data-node_id="${data.id}">${data.label}</span>`;
            }
        }])

        this.cy.nodeHtmlLabel([{
            query: '.scenario-scenario',
            valign: 'center',
            halign: 'center',
            halignBox: 'center',
            valignBox: 'center',
            cssClass: 'scenario-scenario-label',
            tpl: (data: NodeData) => {
                return `<span data-cy_id="${this.id}" data-scenario_id="${data.scenario.props.id}" data-node_id="${data.id}">${data.scenario.props.name}</span>`;
            }
        }])
    }

    // </editor-fold>

    // <editor-fold desc="COMPUTED">
    init_computed() {
        this.effect_scope.run(() => {
            this.computed = reactive({})
            this.compute_starting_nodes();
            this.compute_paths();
            this.compute_undo_redo()
            this.compute_loading()
            this.compute_role_is_viewer();
        })
    }

    compute_starting_nodes() {
        // @ts-ignore
        this.computed.start_nodes = computed<ScenarioNode[]>(() => {
            let starting: ScenarioNode[] = []
            this.state.scenarios.forEach(scenario => {
                starting = starting.concat(this._filter_starting_nodes(scenario.nodes.toArray()))
            })
            return starting
        })
    }

    compute_paths() {
        // @ts-ignore
        this.computed.paths = computed(() => {
            return this._paths_from_starting_scenario_nodes(this.computed.start_nodes, this.state.scenarios.pluck("nodes").pluck("toArray").flat())
        })
    }

    compute_undo_redo() {
        // @ts-ignore
        this.computed.can_undo = computed(() => {
            if (this.state.scenarios.length > 1) {
                return this.state.mutations_count > 0
            } else if (this.state.scenarios.length == 1) {
                return this.state.scenarios[0].histories.undoable.count > 0
            } else return false
        })

        // @ts-ignore
        this.computed.can_redo = computed(() => {
            if (this.state.scenarios.length > 1) {
                return this.state.undos_count > 0
            } else if (this.state.scenarios.length == 1) {
                return this.state.scenarios[0].histories.redoable.count > 0
            } else return false
        })
    }

    compute_loading() {
        // @ts-ignore
        this.computed.loading = computed(() => {
            return this.state._mutating || this.state._operating > 0
        })
    }

    compute_role_is_viewer() {
        this.computed.role_is_viewer = computed(() => current.role_is_viewer_for(this.project_version.props.project_id))
    }

    // </editor-fold>

    // <editor-fold desc="EVENT LISTENERS">
    on(event: Events, callback: (scenario_builder: ScenarioBuilder, e: any) => void) {
        this.event_bus.$on(event, callback)
    }

    on_key_down(e: KeyboardEvent) {
        let intercepted = false;
        switch (e.code) {
            case KeyCode.DELETE:
                this.disconnect_and_remove(this.get_selected_cy_nodes().toArray(), this.get_selected_cy_edges().toArray())
                intercepted = true;
                break;
            case KeyCode.ENTER:
                this.open_or_toggle_scenario_nodes(this.get_selected_scenario_nodes())
                intercepted = true
                break;
            case KeyCode.UP:
                if (e.shiftKey) {
                    this.move_up(this.get_selected_scenario_nodes())
                    intercepted = true
                }
                break;
            case KeyCode.DOWN:
                if (e.shiftKey) {
                    this.move_down(this.get_selected_scenario_nodes())
                    intercepted = true
                }
                break;
        }

        if (intercepted) {
            e.preventDefault()
            e.stopPropagation()
        }
    }

    init_event_listeners() {
        this.init_scenario_highlighting()
        this.init_double_tap_event()
        this.init_double_tap_listener()
        this.init_tap_events()
        this.init_contextmenu()
        this.init_layout_stop()
        this.init_wheel();
        this.init_global_panning();
        this.init_key_events();
    }

    init_key_events() {
        const container = this.cy._private.container
        container.addEventListener("keydown", (e) => this.on_key_down(e))
        // container.addEventListener("keyup", (e) => this.on_key_up(e))

        this.cy.on("tap", () => {
            container.focus();
        })
    }

    init_scenario_highlighting() {
        this.cy.on("tap", ".scenario-snippet,.scenario-scenario", (e) => {
            this.highlight_scenarios_for_cy_nodes(e.target)
        })
    }

    init_double_tap_event() {
        let tappedBefore: NodeJS.Timeout;
        let tappedTimeout: NodeJS.Timeout;
        this.cy.on('tap', function(event) {
            const tappedNow = event.target;
            if (tappedTimeout && tappedBefore) {
                clearTimeout(tappedTimeout);
            }
            if (tappedBefore === tappedNow) {
                tappedNow.trigger('doubleTap');
                tappedBefore = null;
            } else {
                tappedTimeout = setTimeout(function() {
                    tappedBefore = null;
                }, 300);
                tappedBefore = tappedNow;
            }
        });
    }

    init_double_tap_listener() {
        this.cy.on('doubleTap', 'node', (event) => {
            const data = event.target.data() as NodeData;
            this.open_or_toggle_scenario_nodes(data.nodes)
        });
    }

    init_tap_events() {
        this.cy.on("tapstart", "edge", (evt) => {
            // ctrl click on edge to edit connection
            if (evt.originalEvent.ctrlKey && current_role != Enum.User.Role.VIEWER) {
                ScenarioBuilder._enable_node_connecting();
                const source = evt.target.source();
                evt.target[0]._private.pannable = false

                this.edge_handle = {
                    removed_ele: evt.target
                }

                evt.target.remove();
                this.eh.start(source);
            }
        })

        this.cy.on("cxttapend", () => {
            defer(() => this.color_scenario_builder());

        })
        this.cy.on("tapend", () => {
            defer(() => this.color_scenario_builder());
        })
    }

    init_contextmenu() {
        const container = this.cy._private.container
        container.dataset.cy_id = this.id
        const canvas = this.cy._private.container.querySelector("canvas")
        canvas.dataset.cy_id = this.id

        const container_build = ($trigger: JQuery, e: JQuery.MouseDownEvent) => {
            const items: ContextMenu.Items = {}
            items.play = Play.contextmenu_item_play(() => this.play())

            items.zoom_in = this._zoom_in_contextmenu_item($trigger, e);
            items.zoom_out = this._zoom_out_contextmenu_item($trigger, e);

            items.center = this._center_contextmenu_item();
            items.fit = this._fit_contextmenu_item();


            if (this.allow_redo()) items.redo = this._redo_contextmenu_item()
            if (this.allow_undo()) items.undo = this._undo_contextmenu_item()

            if (this.allow_unmerged_view_change && this.state.scenarios.length > 1) {
                if (this.options.merged) {
                    items.unmerge = this._unmerge_contextmenu_item()
                } else {
                    items.merge = this._merge_contextmenu_item()
                }
            }

            items.layouts = this._layouts_contextmenu_item()

            return {
                callback: function() {
                },
                items
            }
        }

        const edge_build = ($trigger: JQuery, e: JQuery.MouseDownEvent) => {
            const items: ContextMenu.Items = {}
            const selected_cy_nodes = this.get_selected_cy_nodes()
            const selected_cy_edges = this.get_selected_cy_edges()

            const selected_cy_nodes_array = selected_cy_nodes.toArray();
            const selected_cy_edges_array = selected_cy_edges.toArray();

            items.play = Play.contextmenu_item_play(() => this.play())

            if (!this.computed.role_is_viewer) {
                items.remove = this._remove_contextmenu_item(selected_cy_nodes_array, selected_cy_edges_array)
            }

            items.zoom_in = this._zoom_in_contextmenu_item($trigger, e);
            items.zoom_out = this._zoom_out_contextmenu_item($trigger, e);

            items.center = this._center_contextmenu_item();
            items.fit = this._fit_contextmenu_item();

            if (this.allow_redo()) items.redo = this._redo_contextmenu_item()
            if (this.allow_undo()) items.undo = this._undo_contextmenu_item()

            if (this.allow_unmerged_view_change && this.state.scenarios.length > 1) {
                if (this.options.merged) {
                    items.unmerge = this._unmerge_contextmenu_item()
                } else {
                    items.merge = this._merge_contextmenu_item()
                }
            }

            items.layouts = this._layouts_contextmenu_item()

            return {
                callback: function() {
                },
                items
            }
        }

        const node_build = ($trigger: JQuery, e: JQuery.MouseDownEvent) => {
            const node_id = $trigger[0].querySelector("span").dataset.node_id
            if (node_id) {
                // selects the target cy node if not already selecte
                const target_cy_node = this.cy.$(`#${node_id}`)
                const selected_cy_nodes = this.cy.$(".scenario-snippet:selected, .scenario-scenario:selected").toArray()
                const target_not_in_selected = typeof selected_cy_nodes.find(node => node.data().id == target_cy_node.data().id) == "undefined"
                if (target_not_in_selected) {
                    this.cy.$(":selected").deselect()
                    target_cy_node.select();
                }
            }
            const selected_cy_nodes = this.get_selected_cy_nodes().toArray()
            const selected_cy_edges = this.get_selected_cy_edges().toArray()
            const selected_nodes = selected_cy_nodes.map(n => {
                const data = n.data() as NodeData
                return data.nodes
            }).flat()

            const selected_snippets = selected_nodes.filter(n => n.props.type == Enum.Scenario.Node.Type.SNIPPET).map(n => n.snippet).uniq_by(s => s.key())
            const selected_scenarios = selected_nodes.map(n => n.in_scenario).uniq_by(s => s.key())
            const selected_scenario_parts = selected_nodes.filter(n => n.props.type == Enum.Scenario.Node.Type.SCENARIO).map(n => n.scenario).uniq_by(s => s.key())

            const items: ContextMenu.Items = {}
            items.play = Play.contextmenu_item_play(() => this.play())

            if (selected_snippets.length > 0) items.show_snippet = this._show_snippet_contextmenu_item(selected_snippets)
            if (selected_scenarios.length > 0) items.show_scenario = this._show_scenario_contextmenu_item(selected_scenarios)
            if (selected_scenario_parts.length > 0) items.show_scenario_part = this._show_scenario_part_contextmenu_item(selected_scenario_parts)

            items.toggle = this._toggle_contextmenu_item(selected_nodes);

            if (!this.computed.role_is_viewer) {
                items.remove = this._remove_contextmenu_item(selected_cy_nodes, selected_cy_edges);

                if (selected_cy_nodes.length == 1) {
                    items.move_up = this._move_up_contextmenu_item(selected_nodes);
                    items.move_down = this._move_down_contextmenu_item(selected_nodes);
                }
            }

            if (selected_cy_nodes.length == 1) {
                items.add_other = this._add_other_contextmenu_item(selected_nodes)
            }

            items.zoom_in = this._zoom_in_contextmenu_item($trigger, e);
            items.zoom_out = this._zoom_out_contextmenu_item($trigger, e);

            items.center = this._center_contextmenu_item();
            items.fit = this._fit_contextmenu_item();


            if (this.allow_redo()) items.redo = this._redo_contextmenu_item()
            if (this.allow_undo()) items.undo = this._undo_contextmenu_item()


            if (this.allow_unmerged_view_change && this.state.scenarios.length > 1) {
                if (this.options.merged) {
                    items.unmerge = this._unmerge_contextmenu_item()
                } else {
                    items.merge = this._merge_contextmenu_item()
                }
            }

            items.layouts = this._layouts_contextmenu_item()

            return {
                callback: function() {
                },
                items
            }
        }

        $.contextMenu({
            selector: this.contextmenu_selectors.node,
            zIndex: 10,
            build: node_build
        })

        this.cy.on("cxttapstart", (evt) => {
            const e: MouseEvent = evt.originalEvent
            $.contextMenu("destroy", this.contextmenu_selectors.container);
            evt.preventDefault()
            evt.stopPropagation();

            const y = e.pageY;
            const x = e.pageX;

            const position_ctx = (x: number, y: number, opt: ContextMenu.Options) => {
                const window_height = $(window).height() - 5;
                const window_width = $(window).width() - 5;

                const menu_height = opt.$menu.outerHeight()
                const menu_width = opt.$menu.outerWidth();
                const overflow_height = (y + menu_height) - window_height;
                const overflow_width = (x + menu_width) - window_width;
                if (overflow_height > 0) y = y - overflow_height;
                if (overflow_width > 0) x = x - overflow_width;
                opt.$menu.css({ top: y, left: x });
            }

            if (evt.target.length > 0 && evt.target.group() == "edges") {
                const target_edges = evt.target.edges().toArray() as EdgeSingular[]
                const selected_cy_edges = this.get_selected_cy_edges().toArray();
                if (target_edges.some(edge => !selected_cy_edges.some(selected_cy_edge => selected_cy_edge.data().id == edge.data().id))) {
                    this.cy.$(":selected").deselect()
                    target_edges.forEach(edge => edge.select())
                }
                $.contextMenu({
                    selector: this.contextmenu_selectors.container,
                    zIndex: 10,
                    build: edge_build,
                    position: (opt) => position_ctx(x, y, opt)

                })
            } else {
                $.contextMenu({
                    selector: this.contextmenu_selectors.container,
                    zIndex: 10,
                    build: container_build,
                    position: (opt) => position_ctx(x, y, opt)
                })
            }
        })
    }

    init_layout_stop() {
        this.cy.on("layoutstop", () => {
            this.event_bus.$emit("layoutstop", this, this.cy.nodes().renderedBoundingBox())
        })
    }

    init_wheel() {
        const container = this.cy._private.container
        const canvas = container.querySelector("canvas")
        container.addEventListener("wheel", (e) => {
                if (e.ctrlKey) {
                    let diff = (e.wheelDeltaY / 1000) * 0.3;
                    const needsWheelFix = e.deltaMode === 1;
                    if (needsWheelFix) {
                        // fixes slow wheel events on ff/linux and ff/windows
                        diff *= 33;
                    }
                    const x = e.pageX - $(canvas).offset().left
                    const y = e.pageY - $(canvas).offset().top

                    var newZoom = this.cy.zoom() * Math.pow(10, diff);
                    this.cy.zoom({
                        level: newZoom,
                        renderedPosition: {
                            x,
                            y
                        }
                    });

                    e.preventDefault()
                } else {
                    if (!e.shiftKey) {
                        this.cy.panBy({
                            x: (e.wheelDeltaX) / 2,
                            y: (e.wheelDeltaY) / 2
                        });
                    } else {
                        // while holding shift reverse x and y
                        this.cy.panBy({
                            x: (e.wheelDeltaY) / 2,
                            y: (e.wheelDeltaX) / 2
                        });
                    }
                }
            }, {
                passive: false
            }
        )
    }

    init_global_panning() {
        let startPosition: any = null;
        this.cy.on('mousedown', 'node, edge', (evt) => {
            console.log(evt);
            const e = evt.originalEvent;
            if (this.options.global_panning && e.button === 0) {
                startPosition = evt.position;
            }
        });
        this.cy.on('mouseup', function(evt) {
            const e = evt.originalEvent;
            if (e.button === 0) {
                startPosition = null;
            }
        });
        this.cy.on('mousemove', (evt) => {
            if (startPosition) {
                const zoom = this.cy.zoom();
                const relativePosition = {
                    x: (evt.position.x - startPosition.x) * zoom,
                    y: (evt.position.y - startPosition.y) * zoom,
                };
                this.cy.panBy(relativePosition);
            }
        });
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    private _find_closest_element_by_coords(x: number, y: number) {
        const zone_size = 120;
        let closest_node: NodeSingular = null
        let closest_edge: EdgeSingular = null
        let closest_distance = zone_size + 1;
        type Position = "left_top" | "right_top" | "left_bottom" | "right_bottom"
        let position: Position = null;

        this.cy.nodes(".scenario-snippet, .scenario-scenario").each(node => {
            // if starting node
            if (node.incomers().length == 0) {
                const node_x = node.position().x;
                const node_y = node.position().y
                const delta_x = x - node_x;
                const delta_y = y - node_y;
                const distance = Math.sqrt(delta_x * delta_x + delta_y * delta_y);
                if (distance <= zone_size) {
                    if (x <= node_x && y <= node_y) {
                        position = "left_top"
                        closest_distance = distance;
                        closest_node = node;
                    } else if (x > node_x && y <= node_y) {
                        position = "right_top"
                        closest_distance = distance;
                        closest_node = node;
                    }
                }
            }
            // if ending node
            if (node.outgoers().length == 0) {
                const node_x = node.position().x;
                const node_y = node.position().y
                const delta_x = x - node_x;
                const delta_y = y - node_y;
                const distance = Math.sqrt(delta_x * delta_x + delta_y * delta_y);
                if (distance <= zone_size) {
                    if (x <= node_x && y > node_y) {
                        closest_distance = distance;
                        closest_node = node;
                        position = "left_bottom"
                    } else {
                        closest_distance = distance;
                        closest_node = node;
                        position = "right_bottom"
                    }
                }
            }
        })

        this.cy.edges().each(edge => {
            const x1 = edge.source().position().x;
            const y1 = edge.source().position().y;
            const x2 = edge.target().position().x;
            const y2 = edge.target().position().y;
            let distance = zone_size + 1;

            // distance of a point from a line
            // d = (|kx0 + l - y0|)/sqrt(1 + k*k)
            let y_on_direction;
            if (x2 != x1) {
                const k = (y2 - y1) / (x2 - x1)
                const l = y1 - (y2 * x1 - y1 * x1) / (x2 - x1)

                const x_on_direction = (y - l) / k
                y_on_direction = k * x_on_direction + l;

                if (y_on_direction <= Math.max(y2, y1) && y_on_direction >= Math.min(y2, y1)) {
                    if (y_on_direction <= Math.max(y2, y1) && y_on_direction >= Math.min(y2, y1)) {
                        distance = Math.abs(k * x + l - y) / Math.sqrt(1 + k * k);
                    }
                }
            } else {
                if (y <= Math.max(y2, y1) && y >= Math.min(y2, y1)) {
                    distance = Math.abs(x2 - x)
                }
            }
            if (distance <= zone_size) {
                if (closest_distance > distance) {
                    closest_distance = distance;
                    closest_edge = edge;
                    closest_node = null;
                    position = null;
                }
            }
        })

        return {
            node: closest_node,
            position,
            distance: closest_distance,
            edge: closest_edge
        }
    }

    private _on_update(actions: OnUpdateActions) {
        _.defer(() => {
            if (actions.layout) {
                this.run_layout()

                const layoutstop_handler = () => {
                    // in next tick scenario builder tab should expand / shrink based on layout
                    // TODO: this does not work well
                    nextTick(() => {
                        _.defer(() => this._animate({
                            center: actions.center,
                            fit: actions.fit,
                            restore_zoom: actions.restore_zoom
                        }, 0))
                    })
                    this.cy.off("layoutstop", layoutstop_handler)
                }
                this.cy.on("layoutstop", layoutstop_handler)
            } else {
                this._animate({ center: actions.center, fit: actions.fit, restore_zoom: actions.restore_zoom }, 0)
            }
        })
        this.color_scenario_builder()
    }

    private _move_up_or_down(scenario_nodes: ScenarioNode[], dir: "up" | "down") {
        const scenario_ids = _.uniq(scenario_nodes.map(scenario_node => scenario_node.props.scenario_id))
        if (scenario_ids.length < scenario_nodes.length) {
            toastr.error("Only one node per scenario can be moved")
            return;
        }
        if (this.state._move_in_progress) return
        this.state._move_in_progress = true

        $.ajax({
            url: `/scenario_builders/move_${dir}`,
            type: 'POST',
            data: {
                scenario_node_ids: scenario_nodes.map(scenario_node => scenario_node.key()),
                authenticity_token
            },
            success: () => {
                ++this.state.mutations_count;
                this.state._move_in_progress = false
            },
            error: () => {
                this.state._move_in_progress = false
                toastr.error('Failed to move scenario_node')
            },
            statusCode: ajax_status_codes
        })
    }

    private _zoom(position: Coords, duration: number, diff: number) {
        const newZoom = this.cy.zoom() * Math.pow(10, diff);
        this.cy.animate({
                zoom: {
                    level: newZoom,
                    position
                }
            },
            {
                duration
            })
    }

    private _filter_starting_nodes(nodes: ScenarioNode[]) {
        return nodes.filter(sn => sn.props.previous_scenario_node_id == null)
    }

    private _paths_from_starting_scenario_nodes(nodes: ScenarioNode[], available_nodes: ScenarioNode[]) {
        const paths: ScenarioNode[][] = [];
        nodes.forEach(starting_scenario_node => {
            paths.push(this._path_from_starting_scenario_node(starting_scenario_node, available_nodes));
        })
        return paths
    }

    private _path_from_starting_scenario_node(scenario_node: ScenarioNode, available_nodes: ScenarioNode[]) {
        const scenario_node_path: ScenarioNode[] = [];
        const scenario_node_ids_visited: number[] = [];
        const available_node_keys = available_nodes.pluck("key")
        let current_scenario_node = scenario_node;
        while (current_scenario_node != null &&
        !scenario_node_ids_visited.includes(current_scenario_node.key()) &&
        available_node_keys.includes(current_scenario_node.key())) {
            scenario_node_path.push(current_scenario_node);
            scenario_node_ids_visited.push(current_scenario_node.key());
            current_scenario_node = current_scenario_node.next_scenario_node
        }
        return scenario_node_path;
    }

    /** if the importing scenario snippet is isolated (without connections), do not match it with a scenario snippet with connections */
    private _are_scenario_nodes_matching(scenario_node: ScenarioNode, importing_scenario_node: ScenarioNode) {
        if (scenario_node.props.type == Enum.Scenario.Node.Type.SNIPPET) {
            return scenario_node.props.type == importing_scenario_node.props.type &&
                scenario_node.snippet.key() == importing_scenario_node.snippet.key() &&
                (scenario_node.props.scenario_id != importing_scenario_node.props.scenario_id ||
                    scenario_node.key() == importing_scenario_node.key());
        } else if (scenario_node.props.type == Enum.Scenario.Node.Type.SCENARIO) {
            return scenario_node.props.type == importing_scenario_node.props.type &&
                scenario_node.scenario.key() == importing_scenario_node.scenario.key() &&
                (scenario_node.props.scenario_id != importing_scenario_node.props.scenario_id ||
                    scenario_node.key() == importing_scenario_node.key());
        } else return false
    }

    private _find_matches(scenario_nodes_path: ScenarioNode[], last_match_index: number, importing_scenario_nodes: ScenarioNode[], j: number, score: number, matches: Record<number, number>): MatchingData {
        if (last_match_index < scenario_nodes_path.length) {
            for (j = j + 1; j < importing_scenario_nodes.length; ++j) {
                for (let i = last_match_index + 1; i < scenario_nodes_path.length; ++i) {
                    if (this._are_scenario_nodes_matching(scenario_nodes_path[i], importing_scenario_nodes[j])) {
                        matches[importing_scenario_nodes[j].key()] = scenario_nodes_path[i].key();
                        return this._find_matches(scenario_nodes_path, i, importing_scenario_nodes, j, score + 1, matches)
                    }
                }
            }
        }
        return { score, matches }
    }

    private _do_update(operation: Function, actions: OnUpdateActions = {}) {
        // TODO: check here if all nodes are out of viewport, if they are, then center
        let result: any = null
        this.state._update_actions_pending.push(actions)
        ++this.state._operating;
        try {
            result = operation()
        } catch (e) {
            console.error(e)
        } finally {
            --this.state._operating
            if (this.state._operating == 0) {
                let actions: OnUpdateActions = {}
                this.state._update_actions_pending.reverse().each(a => {
                    actions = _.merge(actions, a)
                })
                this.state._update_actions_pending = []
                this._on_update(actions);
                this.event_bus.$emit("operation", this)
            }
        }

        return result
    }

    _page_coords_to_cy_coords(coords: Coords): Coords {
        const canvas = this.cy._private.container.querySelector("canvas")
        const x = coords.x - $(canvas).offset().left
        const y = coords.y - $(canvas).offset().top

        const pan = this.cy.pan();
        const zoom = this.cy.zoom();

        return {
            x: (x - pan.x) / zoom,
            y: (y - pan.y) / zoom
        }
    }

    _add_scenario_nodes_in_cy(scenario_nodes: ScenarioNode[] | ScenarioNode) {
        let importing_scenario_nodes: ScenarioNode[] = scenario_nodes as ScenarioNode[];
        if (!(scenario_nodes instanceof Array)) importing_scenario_nodes = [scenario_nodes];

        const importing_starting_nodes = this._filter_starting_nodes(importing_scenario_nodes)
        let importing_paths = this._paths_from_starting_scenario_nodes(importing_starting_nodes, importing_scenario_nodes);

        let leftover_importing_scenario_nodes: ScenarioNode[] = []
        do {
            leftover_importing_scenario_nodes = importing_scenario_nodes.reject(n => importing_paths.some(p => p.some(pn => pn.key() == n.key())))
            const leftover_paths = this._paths_from_starting_scenario_nodes([leftover_importing_scenario_nodes[0]], leftover_importing_scenario_nodes)
            leftover_paths.each(leftover_path => importing_paths.push(leftover_path))
        } while (leftover_importing_scenario_nodes.length > 1)

        console.debug("importing paths", importing_paths);
        importing_paths.forEach(importing_starting_node => {
            if (importing_starting_node.length == 0) return;

            // <editor-fold desc="MERGING">
            let max_matches: Record<number, number> = {};
            if (this.options.merged) {
                max_matches = this._find_matches_for_path(importing_starting_node)
            }
            // </editor-fold>

            // <editor-fold desc="ADDING TO BUILDER">
            let previous_node: NodeSingular = this.cy_node_bindings[importing_starting_node[0].props.previous_scenario_node_id];
            importing_starting_node.forEach(importing_scenario_node => {
                previous_node = this._add_scenario_node_in_cy(previous_node, importing_scenario_node, max_matches)
            })
            // </editor-fold>
        })
    }

    // _find_path_for_scenario_node(scenario_nodes: ScenarioNode[], scenario_node: ScenarioNode) {
    //     const starting_nodes = this._filter_starting_nodes(scenario_nodes);
    //     const paths = this._paths_from_starting_scenario_nodes(starting_nodes);
    //     let path = paths.find(path => path.some(n => n.key() == scenario_node.key()))
    //     if (path == null) path = [scenario_node]
    //     return path
    // }

    _find_matches_for_path(path: ScenarioNode[]) {
        let max_matches_score = 0;
        let max_matches: Record<number, number> = {};
        this.computed.paths.forEach(path_in_builder => {
            path_in_builder.forEach((scenario_node_path, k) => {
                path.forEach((scenario_node, j) => {
                    if (this._are_scenario_nodes_matching(scenario_node_path, scenario_node)) {
                        const matches: Record<number, number> = {}
                        matches[scenario_node.key()] = scenario_node_path.key()
                        const matching_data = this._find_matches(path_in_builder, k, path, j, 1, matches);
                        if (matching_data.score > max_matches_score) {
                            max_matches_score = matching_data.score;
                            max_matches = matching_data.matches;
                        }
                    }
                })
            })
        })
        return max_matches
    }

    _add_scenario_node_in_cy(previous_node: NodeSingular, scenario_node: ScenarioNode, max_matches: Record<number, number>) {
        // check if we have existing scenario snippet already in builder
        const scenario_node_id_in_builder = max_matches[scenario_node.key()]
        let current_node: NodeSingular;
        if (scenario_node_id_in_builder != null) current_node = this.cy_node_bindings[scenario_node_id_in_builder]
        if (scenario_node_id_in_builder != null && current_node != null) {
            // add importing_scenario_node to node scenario_nodes
            const data = current_node.data() as NodeData
            if (!CONSTANTS.IS_PROD_ENV) {
                if (data.nodes.some(n => n.props.id == scenario_node.props.id)) {
                    throw new Error("Adding duplicate scenario nodes to cy nodes")
                }
            }
            data.nodes.push(scenario_node);
        } else {
            let classes: string;
            if (scenario_node.props.type == Enum.Scenario.Node.Type.SNIPPET) {
                classes = "scenario-snippet"
            } else {
                classes = "scenario-scenario"
            }
            const data: NodeData = {
                id: generate_uuid(),
                parent: "scenario_" + scenario_node.props.scenario_id,
                snippet: scenario_node.snippet,
                scenario: scenario_node.scenario,
                nodes: [scenario_node],
                type: scenario_node.props.type,
            };
            current_node = this.cy.add({
                group: "nodes",
                classes,
                data,
                position: {
                    x: scenario_node.props.x,
                    y: scenario_node.props.y,
                }
            }).toArray()[0] as NodeSingular;
            if (scenario_node.props.type == Enum.Scenario.Node.Type.SNIPPET) {
                const snippet = current_node.data().snippet
                current_node.style("background-color", get_css_var(snippet.props.color))
            }
        }
        this.cy_node_bindings[scenario_node.key()] = current_node
        this._add_scenario_node_edge_in_cy(scenario_node, previous_node, current_node)


        previous_node = current_node;
        this.effect_scope.run(() => {
            this.node_prev_watchers[scenario_node.key()] = watch(
                () => scenario_node.props.previous_scenario_node_id,
                (new_prev, old_prev) => {
                    try {
                        console.debug(`node_prev_watcher triggered on scenario_node[${scenario_node.key()}] (${scenario_node.snippet?.name()})`, current_node);
                        if (old_prev != null) {
                            const prev_node = ScenarioNode.find(old_prev)
                            console.debug(`removing connection with scenario_node[${prev_node?.key()}] (${prev_node?.snippet?.name()})`);
                            this._remove_edge_between_scenario_nodes(prev_node, scenario_node)
                        }

                        if (new_prev != null) {
                            const scenario_node = ScenarioNode.find(new_prev)
                            const node = this.cy_node_bindings[scenario_node.key()]
                            this._add_scenario_node_edge_in_cy(scenario_node, node, current_node);
                            console.debug(`adding connection to scenario_node[${scenario_node.key()}] (${scenario_node.snippet?.name()})`);
                        }

                        this.debounced_run_layout()
                    } catch (e) {
                        console.error(e)
                    }
                },
                { flush: "sync" }
            )
            this.node_next_watchers[scenario_node.key()] = watch(
                () => scenario_node.props.next_scenario_node_id,
                (new_next, old_next) => {
                    try {
                        console.debug(`node_next_watcher triggered on scenario_node[${scenario_node.key()}] (${scenario_node.snippet?.name()})`, current_node);
                        if (old_next != null) {
                            const old_node = ScenarioNode.find(old_next)
                            console.debug(`removing connection with scenario_node[${old_node?.key()}] (${old_node?.snippet?.name()})`);
                            this._remove_edge_between_scenario_nodes(scenario_node, old_node)
                        }

                        if (new_next != null) {
                            const scenario_node = ScenarioNode.find(new_next)
                            const node = this.cy_node_bindings[scenario_node.key()]
                            this._add_scenario_node_edge_in_cy(scenario_node, current_node, node);
                            console.debug(`adding connection to scenario_node[${scenario_node.key()}] (${scenario_node.snippet?.name()})`);
                        }

                        this.debounced_run_layout()
                    } catch (e) {
                        console.error(e)
                    }
                },
                { flush: "sync" }
            )

            this.node_name_watchers[scenario_node.key()] = watch(
                () => {
                    if (scenario_node.props.type == Enum.Scenario.Node.Type.SNIPPET) {
                        return scenario_node.snippet.props.name
                    } else {
                        return scenario_node.scenario.props.name
                    }
                },
                () => {
                    // by modifying any data it will trigger node html labels to rerender new name
                    this.cy_node_bindings[scenario_node.key()].data('trigger_render', generate_uuid())
                }
            )

            if (scenario_node.props.type == Enum.Scenario.Node.Type.SNIPPET) {
                this.node_color_watchers[scenario_node.key()] = watch(
                    () => scenario_node.snippet.props.color,
                    (new_color) => {
                        current_node.style("background-color", get_css_var(new_color))
                    }
                )
            }
        })

        return previous_node
    }

    _add_scenario_node_edge_in_cy(scenario_node: ScenarioNode, prev_node: NodeSingular, current_node: NodeSingular) {
        if (prev_node != null) {
            let edge = prev_node.edgesTo(current_node)[0];
            if (typeof edge != "undefined") {
                const data = edge.data() as EdgeData
                if (!data.scenarios.some(s => s.key() == scenario_node.props.scenario_id)) {
                    data.scenarios.push(scenario_node.in_scenario);
                    data.width = data.scenarios.length
                }
                edge.style("width", data.width > 10 ? 10 : edge.data().width);
            } else {
                const data: EdgeData = {
                    id: generate_uuid(),
                    source: prev_node.data().id,
                    target: current_node.data().id,
                    width: 1,
                    scenarios: [scenario_node.in_scenario]
                }
                edge = this.cy.add({
                    group: "edges",
                    data
                }).toArray()[0] as EdgeSingular;

                // wierd visual bug without this
                edge.style("width", data.width > 10 ? 10 : edge.data().width);
            }
            if (this.cy_edge_bindings[scenario_node.props.scenario_id] == null) {
                this.cy_edge_bindings[scenario_node.props.scenario_id] = [edge]
            } else {
                this.cy_edge_bindings[scenario_node.props.scenario_id].push(edge)
            }
        }
    }

    /** Removes a scenario node, by first removing its connections/edges and then the node itself */
    _remove_scenario_nodes_in_cy(scenario_nodes: ScenarioNode[]) {
        scenario_nodes.forEach(scenario_node => {
            const node = this.cy_node_bindings[scenario_node.key()];

            // remove edges from node
            node.incomers().nodes().forEach(prev_node => {
                const prev_data = prev_node.data() as NodeData;
                prev_data.nodes
                         .filter(n => n.props.scenario_id == scenario_node.props.scenario_id)
                         .forEach(prev_scenario_node => {
                             this._remove_edge_between_scenario_nodes(prev_scenario_node, scenario_node)
                         })
            })
            node.outgoers().nodes().forEach(next_node => {
                const next_data = next_node.data() as NodeData;
                next_data.nodes
                         .filter(n => n.props.scenario_id == scenario_node.props.scenario_id)
                         .forEach(next_scenario_node => {
                             this._remove_edge_between_scenario_nodes(scenario_node, next_scenario_node)
                         })
            })

            // remove node
            const data = node.data() as NodeData;
            data.nodes = data.nodes.filter(n => n.key() != scenario_node.key())
            this.node_prev_watchers[scenario_node.key()]()
            this.node_next_watchers[scenario_node.key()]()
            this.node_name_watchers[scenario_node.key()]()
            if (scenario_node.props.type == Enum.Scenario.Node.Type.SNIPPET) this.node_color_watchers[scenario_node.key()]()

            delete this.node_prev_watchers[scenario_node.key()]
            delete this.node_next_watchers[scenario_node.key()]
            delete this.node_name_watchers[scenario_node.key()]
            if (scenario_node.props.type == Enum.Scenario.Node.Type.SNIPPET) delete this.node_color_watchers[scenario_node.key()]

            if (data.nodes.length == 0) node.remove();
            delete this.cy_node_bindings[scenario_node.key()];
        })
    }

    /** Removes the connection between scenario nodes */
    _remove_edge_between_scenario_nodes(from_scenario_node: ScenarioNode, to_scenario_node: ScenarioNode) {
        this.cy_node_bindings[from_scenario_node.key()]
            .edgesTo(this.cy_node_bindings[to_scenario_node.key()])
            .forEach(edge => this._remove_scenario_from_edge_in_cy(to_scenario_node.props.scenario_id, edge))
    }

    /**
     * Removes a scenario from edge.
     * In merged mode, if it is the only scenario that connects two nodes the connection/edge will be removed,
     * otherwise the connection will be thinner.
     * @param scenario_id
     * @param edge
     */
    _remove_scenario_from_edge_in_cy(scenario_id: number, edge: EdgeSingular) {
        const data = edge.data() as EdgeData
        if (!data.scenarios.some(s => s.key() == scenario_id)) return;
        data.scenarios = data.scenarios.filter(s => s.key() != scenario_id)
        data.width = data.scenarios.length
        edge.style("width", edge.data().width > 10 ? 10 : edge.data().width);

        this.cy_edge_bindings[scenario_id] = this.cy_edge_bindings[scenario_id].filter(e => e != edge)
        if (data.scenarios.length == 0) edge.remove();
        if (this.cy_edge_bindings[scenario_id].length == 0) delete this.cy_edge_bindings[scenario_id]
    }

    /**
     * If scenario builder has only 1 scenario, then we can undo until the very beginning.
     * But in case if it has multiple scenarios, we can undo only the changes that are made with the same scenarios.
     * In other words, track the number of changes/mutations with the same scenarios, when the scenarios number change,
     * do not allow any redo/undo
     */
    _set_mutations_count() {
        if (this.state.scenarios.length == 1) {
            this.state.mutations_count = this.state.scenarios[0].histories.count
        } else {
            this.state.mutations_count = 0
        }
    }

    /** If scenario builder has only 1 scenario, set the number of undos as the number of revertable actions.
     *  If it has more, then track the number of undo actions that have been done.
     *  Undos count is used to allow / disallow redo action
     */
    _set_undos_count() {
        if (this.state.scenarios.length == 1) {
            this.state.undos_count = this.state.scenarios[0].histories.redoable.count
        } else {
            this.state.undos_count = 0
        }
    }

    private _connect_scenario_nodes(source_data: NodeData, target_data: NodeData, scenario_ids: number[]) {
        const source_scenario_node_ids = source_data.nodes.map(t => t.key())
        const target_scenario_node_ids = target_data.nodes.map(t => t.key())

        this.state._mutating = true
        $.ajax({
            url: '/scenario_builders/connect_scenario_nodes',
            type: 'POST',
            data: {
                scenario_ids,
                source_snippet_id: source_data.snippet?.key(),
                target_snippet_id: target_data.snippet?.key(),
                source_scenario_id: source_data.scenario?.key(),
                target_scenario_id: target_data.scenario?.key(),
                source_scenario_node_ids,
                target_scenario_node_ids,
                authenticity_token
            },
            success: () => {
                this.state._mutating = false
                this.state.mutations_count++;
                // sync should update the ui
                this.edge_handle = null;
            },
            error: () => {
                this.state._mutating = false
                if (this.edge_handle != null) {
                    this.cy.add(this.edge_handle.removed_ele)
                    this.edge_handle = null;
                }
            },
            statusCode: ajax_status_codes
        })
    }

    static _enable_node_connecting() {
        this.scenario_builders.forEach(sb => {
            sb.eh.enable();
            sb.eh.enableDrawMode();
            sb.cy.autoungrabify(true)
        })
    }

    static _disable_node_connecting() {
        this.scenario_builders.forEach(sb => {
            sb.eh.disable();
            sb.eh.disableDrawMode();
            sb.cy.autoungrabify(false)
        })
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXTMENU ITEMS">
    private _redo_contextmenu_item(): ContextMenu.Item {
        return {
            name: "Redo",
            icon: "fas fa-redo",
            color: get_css_var("--button-white"),
            callback: () => {
                this.redo()
            },
        }
    }

    private _undo_contextmenu_item(): ContextMenu.Item {
        return {
            name: "Undo",
            icon: "fas fa-undo",
            color: get_css_var("--button-white"),
            callback: () => {
                this.undo();
            }
        }
    }


    private _merge_contextmenu_item(): ContextMenu.Item {
        return {
            name: "Merge",
            icon: "fas fa-code-branch fa-flip-vertical",
            color: get_css_var("--button-white"),
            callback: () => {
                this.set_merged(true)
            }
        }
    }

    private _unmerge_contextmenu_item(): ContextMenu.Item {
        return {
            name: "Unmerge",
            icon: "fas fa-equals fa-rotate-90",
            color: get_css_var("--button-white"),
            callback: () => {
                this.set_merged(false)
            }
        }
    }

    private _zoom_in_contextmenu_item($trigger: JQuery, event: JQuery.Event): ContextMenu.Item {
        return {
            name: "Zoom in",
            icon: "fas fa-search-plus",
            color: get_css_var("--button-yellow"),
            key: `${ctrl_or_meta}-wheel`,
            callback: () => {
                this.zoom_in(this._page_coords_to_cy_coords({ x: event.pageX, y: event.pageY }))
            }
        }
    }

    private _zoom_out_contextmenu_item($trigger: JQuery, event: JQuery.Event): ContextMenu.Item {
        return {
            name: "Zoom out",
            icon: "fas fa-search-minus",
            color: get_css_var("--button-yellow"),
            key: `${ctrl_or_meta}-wheel`,
            callback: () => {
                this.zoom_out(this._page_coords_to_cy_coords({ x: event.pageX, y: event.pageY }))
            }
        }
    }

    private _center_contextmenu_item(): ContextMenu.Item {
        return {
            name: "Center",
            icon: "fa-regular fa-life-ring",
            color: get_css_var("--button-yellow"),
            callback: () => {
                this.center();
            }
        }
    }

    private _fit_contextmenu_item() {
        return {
            name: "Fit",
            icon: "fas fa-arrows-alt",
            color: get_css_var("--button-yellow"),
            callback: () => {
                this.fit();
            }
        }
    }

    private _layouts_contextmenu_item() {
        return {
            name: "Layouts",
            icon: "fas fa-code-branch",
            color: get_css_var("--button-white"),
            items: {
                // layout_freeform: {
                //     name: "Freeform",
                //     color: get_css_var("--button-white"),
                //     icon: this.options.layout == "freeform" ? "fa-solid fa-check" : null,
                //     callback: () => {
                //         this.options.layout = "freeform"
                //         this.run_layout()
                //     }
                // },
                layout_dagre: {
                    name: "Dagre",
                    color: get_css_var("--button-white"),
                    icon: this.options.layout == "dagre" ? "fa-solid fa-check" : null,
                    callback: () => {
                        this.options.layout = "dagre"
                        this.run_layout()
                    }
                },
                layout_klay: {
                    name: "Klay",
                    color: get_css_var("--button-white"),
                    icon: this.options.layout == "klay" ? "fa-solid fa-check" : null,
                    callback: () => {
                        this.options.layout = "klay"
                        this.run_layout()
                    }
                }
            }
        }
    }

    private _remove_contextmenu_item(cy_nodes: NodeSingular[], cy_edges: EdgeSingular[] = []) {
        return {
            name: "Remove",
            icon: "fa fa-times",
            color: get_css_var("--button-red"),
            key: "del",
            callback: () => {
                this.disconnect_and_remove(cy_nodes, cy_edges)
            }
        }
    }

    private _move_up_contextmenu_item(nodes: ScenarioNode[]) {
        return {
            name: "Move Up",
            icon: "fas fa-arrow-up",
            color: get_css_var("--button-blue"),
            key: "shift + ↑",
            callback: () => {
                this.move_up(nodes);
            }
        }
    }

    private _move_down_contextmenu_item(nodes: ScenarioNode[]) {
        return {
            name: "Move Down",
            icon: "fas fa-arrow-down",
            color: get_css_var("--button-blue"),
            key: "shift + ↓",
            callback: () => {
                this.move_down(nodes);
            }
        }
    }

    private _show_snippet_contextmenu_item(snippets: Snippet[]) {
        return {
            name: `Show snippet${snippets.length > 1 ? 's' : ''}`,
            icon: "fa-solid fa-bullseye",
            color: Snippet.color(),
            callback: () => {
                Snippet.show_in_sidebar(snippets.map(s => s.key()), snippets[0].props.project_version_id)
            }
        }
    }

    private _show_scenario_contextmenu_item(scenarios: Scenario[]) {
        return {
            name: `Show scenario${scenarios.length > 1 ? 's' : ''}`,
            icon: "fa-solid fa-bullseye",
            color: Scenario.color(),
            callback: () => {
                Scenario.show_in_sidebar(scenarios.map(s => s.key()), scenarios[0].props.project_version_id)
            }
        }
    }

    private _show_scenario_part_contextmenu_item(scenarios: Scenario[]) {
        return {
            name: `Show scenario part${scenarios.length > 1 ? 's' : ''}`,
            icon: "fa-solid fa-bullseye",
            color: Scenario.color(),
            callback: () => {
                Scenario.show_in_sidebar(scenarios.map(s => s.key()), scenarios[0].props.project_version_id)
            }
        }
    }

    private _toggle_contextmenu_item(nodes: ScenarioNode[]) {
        const scenario_ids = nodes.map(n => n.props.scenario_node_scenario_id).filter(id => id != null)
        const snippet_ids = nodes.map(n => n.props.scenario_node_snippet_id).filter(id => id != null)
        let name = "Open"
        if (snippet_ids.length == 0 && scenario_ids.every(s_id => this.state.scenarios.some(s => s.key() == s_id))) {
            name = "Toggle"
        }
        let icon = "fas fa-pencil-alt"
        if (snippet_ids.length == 0 && scenario_ids.length > 0) {
            icon = "fa-solid fa-diagram-project"
        }
        return {
            name,
            icon,
            color: get_css_var("--button-white"),
            key: "enter",
            callback: () => {
                this.open_or_toggle_scenario_nodes(nodes)
            }
        }
    }

    private _add_other_contextmenu_item(nodes: ScenarioNode[]) {
        return {
            name: "Add other scenarios",
            color: get_css_var("--button-white"),
            callback: () => {
                this.add_other_scenarios(nodes)
            }
        }
    }

    // </editor-fold>
}


on_dom_content_loaded(() => {
    document.addEventListener("keydown", (e) => {
        if (is_pc_mac) {
            switch (e.code) {
                case KeyCode.LMETA:
                case KeyCode.RMETA:
                    ScenarioBuilder._enable_node_connecting()
                    break;
            }
        } else {
            switch (e.code) {
                case KeyCode.LCTRL:
                case KeyCode.RCTRL:
                    ScenarioBuilder._enable_node_connecting()
                    break;
            }
        }
    })

    document.addEventListener("keyup", (e) => {
        if (is_pc_mac) {
            switch (e.code) {
                case KeyCode.LMETA:
                case KeyCode.RMETA:
                    ScenarioBuilder._disable_node_connecting()
                    break;
            }
        } else {
            switch (e.code) {
                case KeyCode.LCTRL:
                case KeyCode.RCTRL:
                    ScenarioBuilder._disable_node_connecting()
                    break;
            }
        }
    })
})
