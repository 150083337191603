import { ScenarioFolderSavepoint } from "../models/scenario_folder_savepoint";
import { PlayGroup } from "../models/play/play_group";
import { PlayGroupScope } from "../scopes/play/play_group_scope";
import { Play } from "../models/play/play";
import { PlayScope } from "../scopes/play/play_scope";


declare module "../models/scenario_folder_savepoint" {
    interface ScenarioFolderSavepoint {
        play_groups: PlayGroupScope
        target_plays: PlayScope
    }
}

ScenarioFolderSavepoint.has_many("play_groups", PlayGroup, "scenario_folder_savepoint_id")
ScenarioFolderSavepoint.has_many("target_plays", Play, "target_scenario_folder_savepoint_id")
