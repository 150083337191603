import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { ScenarioFolderSavepointClient } from "../clients/scenario_folder_savepoint_client";
import { ScenarioFolderSavepointScope } from "../scopes/scenario_folder_savepoint_scope";

// <editor-fold desc="TYPES">
export interface ScenarioFolderSavepointProps extends Props {
    id: number
    scenario_folder_id: number
    user_id: number
    amendable: boolean
    save_id: string
    archived: boolean
    name: string
    parent_scenario_folder_git_id: string
    color: string
    created_at: Date
    updated_at: Date
}
export type QuerifiedScenarioFolderSavepointProps = QuerifyProps<ScenarioFolderSavepointProps>
export type ScenarioFolderSavepointCreateProps = Omit<ScenarioFolderSavepointProps, 'id'>
export type ScenarioFolderSavepointUpdateProps = Partial<ScenarioFolderSavepointProps>

export interface ScenarioFolderSavepointState extends State {}
export interface ScenarioFolderSavepointComputed extends Computed {}
export interface ScenarioFolderSavepointStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class ScenarioFolderSavepoint extends VueRecord {
    ['constructor']: typeof ScenarioFolderSavepoint

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScenarioFolderSavepointClient
    static ScopeClass = ScenarioFolderSavepointScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScenarioFolderSavepointStaticState = reactive<ScenarioFolderSavepointStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "scenario_folder_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ScenarioFolderSavepoint> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ScenarioFolderSavepoint>> = {}

    static field_validators: ModelValidatorOpts<ScenarioFolderSavepointProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_FOLDER_SAVEPOINT
    static resource_id = Enum.Resource.Id.SCENARIO_FOLDER_SAVEPOINT
    static icon_class = "fa-solid fa-folder"
    static color = () => get_css_var("--scenario-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScenarioFolderSavepointClient
    declare props: ScenarioFolderSavepointProps;
    declare state: ScenarioFolderSavepointState;
    declare computed: ScenarioFolderSavepointComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
ScenarioFolderSavepoint.register_resource(ScenarioFolderSavepoint)
ScenarioFolderSavepointClient.ModelClass = ScenarioFolderSavepoint
ScenarioFolderSavepointScope.ModelClass = ScenarioFolderSavepoint

global_event_bus.$on("after_project_version_unload", () => {
    ScenarioFolderSavepoint.get_scope().unload()
})

declare global {
    interface Window {
        ScenarioFolderSavepoint: typeof ScenarioFolderSavepoint
    }
}
window.ScenarioFolderSavepoint = ScenarioFolderSavepoint
// </editor-fold>

