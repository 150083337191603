<template>
  <SettingsItem
      :id="`edit_proxy_${proxy.props.name}`"
      :title="`Edit ${proxy.props.name}`">
    <div id="proxy_edit"
         class="proxy-edit">
      <div class="header">
        <Button
            text="Back"
            tab_index="1"
            @click="back"
        />
        <Button text="Test"
                tab_index="2"
                :form_validator="form_validator"
                @click="test"
        />
        <Button v-if="!is_viewer_role && !is_user_role"
                text="Save"
                tab_index="3"
                :form_validator="form_validator"
                :click_action="save"
        />
        <div class="flex-expander"/>
        <Button v-if="!is_viewer_role && !is_user_role"
                text="Delete"
                tab_index="4"
                color_class="red"
                @click="delete_proxy"
        />
      </div>
      <div class="body">
        <div class="row">
          <div class="col s12">
            <Input id="proxy_name_input"
                   v-model="update_props.name"
                   :disabled="is_viewer_role || is_user_role"
                   label="Name"
                   :validator="form_validator.register('proxy_name', Proxy.field_validators.name)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s6">
            <Input id="proxy_ip_input"
                   v-model="update_props.proxy_ip"
                   :disabled="is_viewer_role || is_user_role"
                   label="Proxy IP"
                   :validator="form_validator.register('proxy_ip', Proxy.field_validators.proxy_ip)"
            />
          </div>

          <div class="col s6">
            <Input id="proxy_port_input"
                   v-model="update_props.proxy_port"
                   :disabled="is_viewer_role || is_user_role"
                   label="Proxy port"
                   :validator="form_validator.register('proxy_port', Proxy.field_validators.proxy_port)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s6">
            <Input id="proxy_username_input"
                   v-model="update_props.username"
                   :disabled="is_viewer_role || is_user_role"
                   label="Username"
                   :validator="form_validator.register('username', Proxy.field_validators.username)"
            />
          </div>

          <div class="col s6">
            <Checkbox
                v-if="!is_viewer_role && !is_user_role"
                v-model="update_props.change_password"
                label="Change password"
            />
          </div>
        </div>

        <div v-if="update_props.change_password"
             class="row">
          <div class="col s6">
            <Input id="proxy_password_input"
                   v-model="update_props.password"
                   :disabled="is_viewer_role || is_user_role"
                   label="Password"
                   type="password"
            />
          </div>
        </div>
      </div>
    </div>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import Button from "../../../testa/Button.vue";
import Input from "../../../testa/Input.vue";
import { Proxy } from "../../../../vue_record/models/proxy";
import SettingsItem from "../../SettingsItem.vue";
import toastr from "toastr";
import { Project } from "../../../../vue_record/models/project";
import { ProxyUpdateProps } from "../../../../vue_record/models/proxy";
import Checkbox from "../../../testa/Checkbox.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Checkbox,
        SettingsItem,
        Input,
        Button,
    },
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        proxy: {
            type: Object as PropType<Proxy>,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['exit'],
    data() {
        return {
            update_props: {
                id: this.proxy.props.id,
                name: this.proxy.props.name,
                proxy_ip: this.proxy.props.proxy_ip,
                proxy_port: this.proxy.props.proxy_port,
                username: this.proxy.props.username,
                change_password: false,
                password: "",
            } as ProxyUpdateProps,
        }
    },
    computed: {
        Proxy() {
            return Proxy
        },
    },
    watch: {},
    unmounted() {
        this.form_validator.unregister()
    },
    methods: {
        save() {
            const promise = this.apply()
            $.when(promise).then(() => {
                this.back()
            })
            return promise
        },
        apply() {
            if (this.is_viewer_role || this.is_user_role) return null;

            return this.proxy
                       .client
                       .update(this.update_props)
                       .catch(() => toastr.error("Failed to save proxy"))
        },
        test() {
            Proxy.ClientClass.test(this.update_props, true)
        },
        delete_proxy() {
            Proxy.delete(this.proxy.props.id).then((is_confirmed: Boolean) => {
                if (is_confirmed) this.back()
            })
        },
        back() {
            this.$emit('exit')
        },
    },
})
</script>

<style lang="scss" scoped>
.proxy-edit {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .body {
    margin-top: 10px;
  }
}

</style>
