<template>
  <div class="title-container">
    <div class="title-primary-info">
      <span class="play-type-icon-container">
        <PlayTypeIcon :play_type="play.props.type"/>
      </span>
      <div class="name">
        <template v-for="part in name_parts"
                  :key="part.key">
          <span :class="{highlight: part.match}">
            {{ part.text }}
          </span>
        </template>
      </div>
    </div>
    <div class="title-secondary-info">
      <span v-moment="play.props.created_at"/>
      <span v-if="user_label != null"
            :title="user_first_last"
      >
        {{ user_label }}
      </span>
      <span v-if="play.props.schedule_id != null"
            style="margin-left: 2px;">
        (scheduled)
      </span>
      <template v-if="play.is_running()">
        <span> - </span>
        <span>{{ (play.props.progress * 100.0).toFixed(1) }}%</span>
      </template>
    </div>
    <div v-if="show_project_info || show_project_version_info"
         class="title-secondary-info">
      <span v-if="show_project_version_info && show_project_info">
        On {{ project.name() }} project, {{ project_version.name() }} version
      </span>
      <span v-else-if="show_project_info">
        On {{ project.name() }} project
      </span>
      <span v-else>
        On {{ project_version.name() }} version
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Play } from "../../../vue_record/models/play/play";
import PlayTypeIcon from "../other/PlayTypeIcon.vue";
import { split_by_query_match } from "../../../helpers/pretty/split_by_query_match";

export default defineComponent({
    components: { PlayTypeIcon },
    // <editor-fold desc="PROPS">
    props: {
        play: {
            type: Object as PropType<Play>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        user_label() {
            const user = this.play.user
            if (user == null) return " by unknown user";
            if (user.key() == current.user.key()) return " by you"
            return ` by ${user.props.email}`
        },
        user_first_last() {
            const user = this.play.user
            if (user == null) return null
            return `${user.props.first_name} ${user.props.last_name}`
        },
        name_parts() {
            return split_by_query_match(Play.state.filter.name, this.play.name())
        },
        show_project_info() {
            return web.is_reports && (Play.state.filter.project_ids.length == 0 || Play.state.filter.project_ids.length > 1)
        },
        show_project_version_info() {
            return web.is_reports && (Play.state.filter.project_version_ids.length == 0 || Play.state.filter.project_version_ids.length > 1)
        },
        project_version() {
            return this.play.project_version
        },
        project() {
            return this.project_version.project
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 2px;
  padding-bottom: 2px;

  .title-primary-info {
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
    align-items: baseline;

    .play-type-icon-container {
      font-size: 0.9em;
      margin-right: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    div.name {
      color: var(--font-color);
      margin-right: 5px;
      white-space: nowrap;
      flex-shrink: 0;

      span.highlight {
        background: var(--sidebar-selected-contrast);
      }
    }
  }

  .title-secondary-info {
    color: var(--font-color-secondary);
    font-size: 0.9em;
    margin-left: 4px;
  }
}

</style>
