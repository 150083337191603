import { ImageScope } from "../scopes/image_scope";
import { ImageFolderScope } from "../scopes/image_folder_scope";
import { ProjectVersion } from "../models/project_version";
import { ImageFolder } from "../models/image_folder";
import { Image } from "../models/image";

declare module "../models/image_folder" {
    interface ImageFolder {
        project_version: ProjectVersion
        image_folder: ImageFolder

        images: ImageScope
        image_folders: ImageFolderScope
    }
}

ImageFolder.belongs_to("project_version", ProjectVersion, "project_version_id")
ImageFolder.belongs_to("image_folder", ImageFolder, "image_folder_path")


ImageFolder.has_many("images", Image, "image_folder_path")
ImageFolder.has_many("image_folders", ImageFolder, "image_folder_path")
