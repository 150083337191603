import { reactive } from "vue";
import { TestaLog } from "./logs_manager";
import { WebSocketLog } from "./logs_manager";
import { generate_uuid } from "../../helpers/generate/generate_uuid";

// eslint-disable-next-line no-control-regex
const strip_ansi_color_code_regex = /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g
const testa_log_parser_regex = /(?<datetime>\d{4}-\d\d-\d\d \d\d:\d\d:\d\d\.\d{3} \+\d{4}) (?<level>DEBUG|INFO |WARN |ERROR|FATAL|ANY {2}|ALL {2}) -- (?<progname>[^:]*): (?<tags>\[.*] )* (?<message>.*)/
const tag_parser_regex = /\[([^\]]+)]/g

export class Log {
    app: string
    group: string
    subgroup?: string
    datetime: Date
    level: number
    tags: string[]
    log: string

    message: string
    uuid: string

    computed: {
        stitched: string,
        rich: string
    }

    constructor(testa_log: TestaLog) {
        this.app = testa_log.app
        this.group = testa_log.group
        this.subgroup = testa_log.subgroup
        this.datetime = new Date(testa_log.time)
        if (isNaN(this.datetime.getTime())) this.datetime = null
        this.level = testa_log.level
        this.tags = testa_log.tags
        if (this.tags == null) this.tags = []
        this.log = testa_log.log
        this.message = testa_log.message
        this.uuid = generate_uuid()
    }

    static from_string(log: string) {
        const plain = log.replace(strip_ansi_color_code_regex, "")
        const match = plain.match(testa_log_parser_regex)
        let tags: string[] = []
        if (match && match.groups.tags != null) {
            tags = match.groups.tags.match(tag_parser_regex).map(t => t.slice(1, -1))
        }
        let level_int = 0
        switch (match?.groups?.level) {
            case "DEBUG":
                level_int = 0;
                break;
            case "INFO ":
                level_int = 1;
                break;
            case "WARN ":
                level_int = 2;
                break;
            case "ERROR":
                level_int = 3;
                break
            case "FATAL":
                level_int = 4;
                break;
            case "ANY  ":
                level_int = 5;
                break;
        }
        return new this({
            app: null,
            group: null,
            subgroup: null,
            log,
            time: match?.groups?.datetime,
            level: level_int,
            message: match?.groups?.message,
            tags
        })
    }

    static from_websocket_log(log: WebSocketLog): Log {
        const plain = log.log.replace(strip_ansi_color_code_regex, "")
        const match = plain.match(testa_log_parser_regex)
        let tags: string[] = []
        if (match && match.groups.tags != null) {
           tags = match.groups.tags.match(tag_parser_regex).map(t => t.slice(1, -1))
        }
        let level_int = 0
        switch (match.groups.level) {
            case "DEBUG":
                level_int = 0;
                break;
            case "INFO ":
                level_int = 1;
                break;
            case "WARN ":
                level_int = 2;
                break;
            case "ERROR":
                level_int = 3;
                break
            case "FATAL":
                level_int = 4;
                break;
            case "ANY  ":
                level_int = 5;
                break;
        }
        return new this({
            app: log.app,
            group: log.group,
            subgroup: log.subgroup,
            log: log.log,
            time: match.groups.datetime,
            level: level_int,
            message: match.groups.message,
            tags
        })
    }
}
