export function objects_diff<O1 = any, O2 = any>(o1: O1, o2: O2, skip_keys: string[] = []): [keyof O1 | keyof O2, keyof O1 | keyof O2] {
    const typeObject = function(o: any) {
        if (o === null) return false
        return typeof o === 'object';
    };
    const diff = function(o1: any, o2: any) {
        const result: { [key: string]: any } = {};
        // if first is item is not object
        if (!typeObject(o1) && typeObject(o2)) {
            return o2;

            // if second is item is not object
        } else if (typeObject(o1) && !typeObject(o2)) {
            return undefined;

            // if they are equal
        } else if (Object.is(o1, o2)) {
            return undefined;
        }

        const keys = Object.keys(o2);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (skip_keys.includes(key)) continue;

            // if both are objects
            if (typeObject(o1[key]) && typeObject(o2[key])) {
                // if equal, return nothing
                if (Object.is(o1[key], o2[key])) {
                    // do nothing
                } else if (o1[key] === o2[key]) {
                    // do nothing
                } else {
                    result[key] = diff(o1[key], o2[key]);
                }
            } else if (o1[key] !== o2[key]) {
                result[key] = o2[key];
            } else {
                // do nothing
            }
        }
        return result;
    };
    return [
        diff(o1, o2),
        diff(o2, o1),
    ];
}
