<template>
  <div class="container">
    <div class="info-grid">
      <div class="label">
        Status:
      </div>
      <div class="value"
           :style="`color: ${status_color()};`">
        {{ status }}
      </div>

      <div class="label">
        Variable set:
      </div>
      <div class="value">
        {{ variable_set?.props?.name }}
      </div>

      <template v-if="proxy != null">
        <div class="label">
          Proxy:
        </div>
        <div class="value">
          {{ proxy.props.name }} - {{ proxy.props.proxy_ip }}:{{ proxy.props.proxy_port }}
        </div>
      </template>

      <template v-for="browser in browsers"
                :key="browser.props.id">
        <div class="label">
          Browser[{{ browser.props.index }}]:
        </div>
        <div class="value"/>

        <div class="label">
          Name:
        </div>
        <div class="value">
          <span v-if="browser.browser">
            {{ browser.browser.props.name }} {{ browser.browser.props.label }}
          </span>
          <span v-else>
            {{ browser.props.scope }}
          </span>
        </div>

        <div class="label">
          Driver:
        </div>
        <div class="value">
          <span v-if="browser.browser">
            {{ browser.browser.props.driver_version }}
          </span>
        </div>

        <div class="label">
          Is mobile:
        </div>
        <div class="value">
          <i v-if="browser.props.is_mobile"
             class="fas fa-check"
             style="color: var(--button-green);"/>
          <i v-else
             class="fas fa-times"
             style="color: var(--button-red);"/>
        </div>

        <div class="label">
          Size:
        </div>
        <div class="value">
          {{ browser.props.width }}x{{ browser.props.height }}
        </div>

        <template v-if="browser.props.is_mobile">
          <div class="label">
            Emulation:
          </div>
          <div class="value">
            {{ browser.props.emulation_profile_name }}
          </div>

          <!--          <div class="group"/>-->
          <!--          <div class="label">
          User Agent:
          </div>-->
          <!--          <div class="value">
          {{ browser.props.user_agent }}
          </div>-->
        </template>
        <template v-if="browser.props.locale">
          <div class="label">
            Locale:
          </div>
          <div class="value">
            {{ browser.props.locale }}
          </div>
        </template>
      </template>

      <template v-for="phone in phones"
                :key="phone.props.id">
        <div class="label">
          Phone[{{ phone.props.index }}]:
        </div>
        <div class="value"/>


        <div class="label">
          Name:
        </div>
        <div class="value">
          <span v-if="phone.phone_project">
            {{ phone.phone_project.phone.props.name }}
          </span>
          <span v-else>
            {{ phone.props.scope }}
          </span>
        </div>

        <template v-if="phone.props.locale">
          <div class="label">
            Locale:
          </div>
          <div class="value">
            {{ phone.props.locale }}
          </div>
        </template>

        <template v-for="app in phone.apps.toArray()"
                  :key="app.props.id">
          <div class="label">
            {{ `${app.props.index == 0 ? 'Apps:' : ''}` }}
          </div>
          <div class="value">
            <span v-if="app.app">
              {{ app.app.props.name }}
            </span>
            <span v-else>
              {{ app.props.scope }}
            </span>
          </div>
        </template>
      </template>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { ScenarioSetting } from "../../../../vue_record/models/scenario_setting";
import { PlaySetting } from "../../../../vue_record/models/play/play_setting";
import { PlayStatus } from "../../../../helpers/play/play_status";
import { EnumPlayStatus } from "../../../../auto_generated/enums";
import { Phone } from "../../../../vue_record/models/scenario_setting/phone";
import { Browser } from "../../../../vue_record/models/scenario_setting/browser";

export default defineComponent({
  props: {
    status: {
      type: String as PropType<EnumPlayStatus>,
      required: true,
    },
    scenario_setting: {
      type: Object as PropType<ScenarioSetting>,
      required: true
    },
    play_setting: {
      type: Object as PropType<PlaySetting>,
      required: false,
      default: null
    }
  },
  computed: {
    props() {
      return this.scenario_setting.props;
    },
    browsers() {
      if (!this.scenario_setting.props.web_module_active) return [] as Browser[];

      return this.scenario_setting.browsers.toArray();
    },
    proxy() {
      return this.scenario_setting.proxy;
    },
    phones() {
      if (!this.scenario_setting.props.mobile_module_active) return [] as Phone[];

      return this.scenario_setting.phones.toArray();
    },
    variable_set() {
      return this.play_setting?.variable_set
    }
  },
  watch: {
  },
  methods: {
    status_color() {
      return PlayStatus.color(this.status)
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: end;
  flex-direction: column;

  .info-grid {
    display: grid;
    width: 100%;
    justify-content: center;
    //grid-template-rows: repeat(auto-fill, 20px);
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    font-size: 0.8em;
  }

  .label {
    align-self: center;
    display: flex;
    text-align: right;
  }

  .value {
    text-align: left;
    display: flex;
    align-items: center;
  }
}
</style>
