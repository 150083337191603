import { get_css_var } from "../generic/get_css_var";
import { Stylesheet } from "cytoscape";

export function cytoscape_style(): Stylesheet[] {
    const sb_node = get_css_var('--sb-node')
    const sb_edge = cy_cfg.edge.color
    const sb_connect = get_css_var("--sb-connect")
    const button_blue = get_css_var("--button-blue")
    const primary = get_css_var("--primary-background-color")

    return [ // the stylesheet for the graph
        {
            selector: '.scenario-snippet',
            style: {
                'background-color': sb_node,
                'shape': "round-rectangle",
                'width': `${cy_cfg.node.width}px`,
                'height': `${cy_cfg.node.height}px`,
            }
        },
        {
            selector: '.scenario-scenario',
            style: {
                'background-color': sb_node,
                'shape': "diamond",
                'width': `${cy_cfg.node.width * 1.2}px`,
                'height': `${cy_cfg.node.height * 1.4}px`,
            }
        },
        {
            selector: ':active',
            css: {
                'overlay-color': 'white',
                'overlay-padding': '2px',
                'overlay-opacity': 0
            }
        },
        {
            selector: 'edge',
            style: {
                'width': 'data(width)',
                'line-color': sb_edge,
                'target-arrow-shape': 'triangle',
                'curve-style': 'bezier',
            }
        },
        {
            selector: 'edge:selected',
            style: {
                'width': 'data(width)',
                'line-color': button_blue,
                'target-arrow-shape': 'triangle',
                'curve-style': 'bezier',
            }
        },
        {
            selector: '.autoconnect-node',
            style: {
                'background-color': sb_connect,
                'width': 8,
                'height': 8,
                'shape': 'ellipse',
            }
        },
        // some style for the extension

        {
            selector: '.eh-handle',
            style: {
                'background-color': sb_connect,
                'width': 12,
                'height': 12,
                'shape': 'ellipse',
                'overlay-opacity': 0,
                'border-width': 12, // makes the handle easier to hit
                'border-opacity': 0
            }
        },
        {
            selector: '.eh-hover',
            style: {
                'background-color': sb_connect
            }
        },
        {
            selector: '.eh-source',
            style: {
                'border-width': 2,
                'border-color': sb_connect
            }
        },
        {
            selector: '.eh-target',
            style: {
                'border-width': 2,
                'border-color': sb_connect
            }
        },
        {
            selector: '.eh-preview, .eh-ghost-edge',
            style: {
                'background-color': sb_connect,
                'line-color': sb_connect,
                'width': 'data(width)',
                'target-arrow-color': sb_connect,
                'source-arrow-color': sb_connect
            }
        },
        {
            selector: '.eh-ghost-edge.eh-preview-active',
            style: {
                opacity: 0
            }
        },
        {
            selector: '.scenario-snippet:selected',
            css: {
                'border-width': 2,
                'border-color': button_blue
            }
        },
        {
            selector: '.scenario-scenario:selected',
            css: {
                'border-width': 2,
                'border-color': button_blue
            }
        },
        {
            selector: ".scenario",
            css: {
                'background-color': primary,
                'border-width': 0,
                'border-color': primary,

            }
        }

    ]
}
