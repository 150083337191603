import { VueRecordScope } from "../base/vue_record_scope";
import { UserProject } from "../models/user_project";
import { computed } from "../../helpers/vue/computed";
import { User } from "../models/user";
import { Project } from "../models/project";

export class UserProjectScope extends VueRecordScope {
    ['constructor']: typeof UserProjectScope
    static ModelClass: typeof UserProject
    declare rthis: UserProjectScope

    users = computed(() => {
        return User.to_scope(this.rthis.toArray().pluck("user").compact())
    })

    projects = computed(() => {
        return Project.to_scope(this.rthis.toArray().pluck("project").compact())
    })
}
