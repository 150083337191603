import { SnippetScope } from "../scopes/snippet_scope";
import { SnippetFolderScope } from "../scopes/snippet_folder_scope";
import { User } from "../models/user";
import { ProjectVersion } from "../models/project_version";
import { SnippetFolder } from "../models/snippet_folder";
import { Snippet } from "../models/snippet";

declare module "../models/snippet_folder" {
    interface SnippetFolder {
        user: User
        project_version: ProjectVersion
        parent_folder: SnippetFolder

        snippets: SnippetScope
        snippet_folders: SnippetFolderScope
    }
}

SnippetFolder.belongs_to("user", User, "user_id")
SnippetFolder.belongs_to("project_version", ProjectVersion, "project_version_id")
SnippetFolder.belongs_to("parent_folder", SnippetFolder, "snippet_folder_id")


SnippetFolder.has_many("snippets", Snippet, "snippet_folder_id")
SnippetFolder.has_many("snippet_folders", SnippetFolder, "snippet_folder_id")
