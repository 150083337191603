import { Play } from "../../../../vue_record/models/play/play";
import { computed, reactive } from "vue";
import { UnwrapNestedRefs } from "vue";
import { ComputedRef } from "vue";
import { watch } from "vue";
import { Editor } from "../../../testa/editor/editor";
import { PlayScenarioNovncTab } from "../../../testa/editor/tabs/play_scenario_novnc_tab";
import { PlayScenarioRecordingTab } from "../../../testa/editor/tabs/play_scenario_recording_tab";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import { PlayTab } from "../../../testa/editor/tabs/play_tab";
import { FlexDirection } from "../../../testa/resizable/resizable_flex/resizable_flex";


export type PlayStreamTab = PlayScenarioNovncTab | PlayScenarioRecordingTab

/** Class for managing all play streams inside a play report.
 * The logic around when should be shown or hidden, tracking states and decoupling/coupling */
export class PlayStreamManager {
    play: Play;

    state = reactive({
        /** auto show is enabled by default when there is a single play worker */
        auto_show_vnc: true,

        /** if decoupled, the stream is shown in separate tab */
        decoupled: PlayStreamManager._resolve_decoupled_state(),

        /**
         * Initially, the coupled play report stream orientation will be
         * determined automatically (coupled_orientation_override is set to null).
         * But user has the option to override this with FlexDirection */
        coupled_orientation_override: null as FlexDirection,

        /** last shown play stream, used to show play stream when changing decoupled state */
        last_active_play_scenario: null as PlayScenario,
    })

    private computed: UnwrapNestedRefs<{
        active_play_stream_tabs: ComputedRef<PlayStreamTab[]>,
        sorted_by_last_shown_play_stream_tabs: ComputedRef<PlayStreamTab[]>,
        play_report_tab: ComputedRef<PlayTab>,
        coupled_orientation: ComputedRef<FlexDirection>,
        coupled_orientation_icon: ComputedRef<string>
    }>;

    private watchers: Function[]

    /** @NOTE - use the static new method */
    constructor(play: Play) {
        this.play = play
        this.play.state.play_stream_manager = this;

        this._init_computed()
        this._init_watchers()
    }

    static new(play: Play) {
        return global_effect_scope.run(() => {
            if (play.state.play_stream_manager != null) return play.state.play_stream_manager
            else return new this(play)
        })
    }

    // <editor-fold desc="ACTIONS">
    change_coupled_orientation() {
        switch (this.computed.coupled_orientation) {
            case "row":
                this.state.coupled_orientation_override = "column"
                break;
            case "column":
                this.state.coupled_orientation_override = "row-reverse"
                break
            case "row-reverse":
                this.state.coupled_orientation_override = "column-reverse"
                break;
            case "column-reverse":
                this.state.coupled_orientation_override = "row"
                break;
        }
    }

    close_all_tabs() {
        this.computed.active_play_stream_tabs.forEach(play_stream_tab => play_stream_tab.close())
    }

    stop_watchers() {
        this.watchers.forEach(w => w())
    }

    set_active_play_scenario(play_scenario: PlayScenario) {
        this.state.last_active_play_scenario = play_scenario
    }

    toggle_stream(play_scenario: PlayScenario) {
        if (this.is_play_scenario_stream_shown(play_scenario)) {
            this.end_stream(play_scenario)
        } else {
            this.show_stream(play_scenario)
        }
    }

    show_stream(play_scenario: PlayScenario) {
        if (play_scenario == null) return;
        if (!play_scenario.has_stream()) return;

        let play_stream_tab: PlayStreamTab;
        if (this.state.decoupled && !web.is_single_report) {
            if (play_scenario.is_finished()) {
                play_stream_tab = PlayScenarioRecordingTab.new({
                    play_id: this.play.key(),
                    play_scenario_id: play_scenario.key(),
                    allow_duplicate: false,
                    id: `play_scenario_${play_scenario.key()}_play_${this.play.key()}_recording`
                })
            } else {
                play_stream_tab = PlayScenarioNovncTab.new({
                    play_id: this.play.key(),
                    play_scenario_id: play_scenario.key(),
                    allow_duplicate: false,
                    id: `play_scenario_${play_scenario.key()}_play_${this.play.key()}_novnc`
                })
            }

            this.rearrange_tabs(play_stream_tab)
        } else {
            this.set_active_play_scenario(play_scenario)
        }
    }

    end_stream(play_scenario: PlayScenario) {
        if (this.state.decoupled) {
            this.get_play_stream_tab(play_scenario, this.play)?.close()
        } else {
            if (this.state.last_active_play_scenario?.key() == play_scenario.key()) this.state.last_active_play_scenario = null
        }
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    has_coupled_stream() {
        return !this.state.decoupled && (this.state.last_active_play_scenario?.has_recording() || this.state.last_active_play_scenario?.has_vnc())
    }

    has_coupled_recording() {
        return !this.state.decoupled && this.state.last_active_play_scenario?.has_recording()
    }

    has_coupled_vnc() {
        return !this.state.decoupled && this.state.last_active_play_scenario?.has_vnc()
    }

    active_play_scenario(): PlayScenario {
        return this.state.last_active_play_scenario as PlayScenario
    }

    get_play_stream_tab(play_scenario: PlayScenario, play: Play) {
        return this.computed.active_play_stream_tabs.find(tab => tab.play_id == play.key() && tab.play_scenario_id == play_scenario.key())
    }

    is_play_scenario_stream_shown(play_scenario: PlayScenario) {
        if (this.state.decoupled) {
            return this.get_play_stream_tab(play_scenario, this.play) != null
        } else {
            return this.state.last_active_play_scenario?.key() == play_scenario.key()
        }
    }

    // </editor-fold>

    // <editor-fold desc="SETTERS">
    set_auto_show_vnc(state: boolean) {
        this.state.auto_show_vnc = state
    }

    // </editor-fold>

    // <editor-fold desc="GETTERS">
    get_coupled_orientation() {
        return this.computed.coupled_orientation
    }

    get_coupled_orientation_icon() {
        return this.computed.coupled_orientation_icon
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    private static _resolve_decoupled_state() {
        if (web.is_single_report) return false
        return current.user ? current.user.props.decouple_streams : false
    }

    private rearrange_tabs(play_stream_tab: PlayStreamTab) {
        this.rearrange_play_report_tab()
        this.rearrange_play_stream_tab(play_stream_tab)
    }

    private rearrange_play_report_tab() {
        if (this.computed.play_report_tab != null) {
            if (this.state.decoupled) {
                if (this.computed.play_report_tab.get_editor().id != Editor.get_footer().id) {
                    Editor.get_footer().get_or_create_tab_manager().add_tab(this.computed.play_report_tab)
                }
            } else {
                // if not in main, move to main only if it is the only tab in the editor
                const editor = this.computed.play_report_tab.get_editor()
                if (editor.id != Editor.get_main().id && editor.get_tabs().length == 1) {
                    Editor.get_main().get_or_create_tab_manager().add_tab(this.computed.play_report_tab)
                }
            }
        }
    }

    private rearrange_play_stream_tab(play_stream_tab: PlayStreamTab) {
        if (play_stream_tab != null) {
            if (play_stream_tab.get_editor()?.id != Editor.get_main().id) {
                let tab_manager = Editor.get_main().get_or_create_tab_manager()
                if (tab_manager.get_tabs().length != 0) {
                    tab_manager = tab_manager.get_or_create_right_tab_manager(true);
                }
                tab_manager.add_tab(play_stream_tab)
            }
        }
    }

    private _init_computed() {
        // instead of manually holding array of play stream tabs,
        // this is better because we dont have to bother to manually add/remove items from array
        const active_play_stream_tabs = computed(() => {
            const all_play_scenarios = this.play.main_play_scenarios.keys()
            return Editor.get_play_stream_tabs().filter(tab => {
                return tab.play_id == this.play.key() && all_play_scenarios.includes(tab.play_scenario_id)
            })
        })

        const sorted_by_last_shown_play_stream_tabs = computed(() => {
            return active_play_stream_tabs.value.sort_by((obj) => obj.state.last_activation).reverse()
        })

        const play_report_tab = computed(() => {
            return this.play.get_tabs()[0] as PlayTab
        })

        const coupled_orientation = computed(() => {
            if (this.state.coupled_orientation_override != null) return this.state.coupled_orientation_override

            const scenario_setting = this.play.state.play_stream_manager.state.last_active_play_scenario?.scenario_setting
            if (scenario_setting == null) return "column"
            const aspect_ratio = scenario_setting.props.xvfb_width / scenario_setting.props.xvfb_height
            if (9 / 16 <= aspect_ratio) return "column"
            return "row-reverse"
        })

        const coupled_orientation_icon = computed(() => {
            switch (this.computed.coupled_orientation) {
                case "column":
                    return "fa-solid fa-mattress-pillow fa-rotate-90"
                case "column-reverse":
                    return "fa-solid fa-mattress-pillow fa-rotate-270"
                case "row":
                    return "fa-solid fa-mattress-pillow"
                case "row-reverse":
                    return "fa-solid fa-mattress-pillow fa-flip-horizontal"
                default:
                    return "fa-solid fa-mattress-pillow"
            }
        })

        this.computed = reactive({
            active_play_stream_tabs,
            sorted_by_last_shown_play_stream_tabs,
            play_report_tab,
            coupled_orientation,
            coupled_orientation_icon
        })
    }

    private _init_watchers() {
        this.watchers = [
            watch(() => this.state.decoupled, () => {
                // if no longer decoupled, close all associated tabs
                if (!this.state.decoupled) this.close_all_tabs();

                this.show_stream(this.state.last_active_play_scenario as PlayScenario)

                this.rearrange_play_report_tab();
                this.computed.active_play_stream_tabs.forEach(tab => this.rearrange_play_stream_tab(tab))
            }),
            watch(() => this.computed.sorted_by_last_shown_play_stream_tabs, () => {
                if (this.state.decoupled && this.computed.sorted_by_last_shown_play_stream_tabs.length > 0) {
                    PlayScenario.ClientClass
                                .load(this.computed.sorted_by_last_shown_play_stream_tabs[0].play_scenario_id)
                                .then(play_scenario => this.state.last_active_play_scenario = play_scenario)
                }
            }),
            watch(() => this.play.play_worker_groups, () => {
                this.state.auto_show_vnc = this.play.play_worker_groups.count > 1
            })
        ]
    }

    // </editor-fold>
}
