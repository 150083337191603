import { Coords } from "../../types/globals";

export type ElementCoordsOffset = "start" | "center" | "end"
export function element_coords(element: HTMLElement, x_offset: ElementCoordsOffset = "center", y_offset: ElementCoordsOffset = "center") {

    const rect = element.getBoundingClientRect()

    const position: Coords = {
        x: rect.x,
        y: rect.y,
    }

    switch (x_offset) {
        case "start":
            // do nothing here, it is already at start;
            break;
        case "center":
            position.x += rect.width / 2;
            break;
        case "end":
            position.x += rect.width;
            break;
        default:
            throw new Error("Offset type unsupported")
    }

    switch (y_offset) {
        case "start":
            // do nothing here, it is already at start;
            break;
        case "center":
            position.y += rect.height / 2;
            break;
        case "end":
            position.y += rect.height;
            break;
        default:
            throw new Error("Offset type unsupported")
    }

    return position
}

