import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b06eb445"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-priority", "onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title", "innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Table = _resolveComponent("Table")
  const _directive_moment = _resolveDirective("moment")

  return (_openBlock(), _createBlock(_component_Table, { id: _ctx.id }, {
    thead: _withCtx(() => [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_columns, (col, key) => {
          return (_openBlock(), _createElementBlock("th", {
            key: key,
            class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
            "data-priority": _ctx.orders.priority(key),
            onClick: $event => (_ctx.orders.toggle(key))
          }, _toDisplayString(col.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    tbody: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordered_rows, (row) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: row.record.props.id,
          class: "project-version-row",
          onClick: $event => (_ctx.$emit('project-version-click', row.record))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cols, (col, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (col.column_key == 'is_main')
                ? (_openBlock(), _createElementBlock("td", {
                    key: 0,
                    title: col.title,
                    class: _normalizeClass(col.classes)
                  }, [
                    _createVNode(_component_Checkbox, {
                      modelValue: row.record.props.main,
                      "onUpdate:modelValue": $event => ((row.record.props.main) = $event),
                      label: "",
                      disabled: true,
                      for_table: true
                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                  ], 10 /* CLASS, PROPS */, _hoisted_3))
                : _withDirectives((_openBlock(), _createElementBlock("td", {
                    key: 1,
                    title: col.title,
                    class: _normalizeClass(col.classes),
                    innerHTML: col.html
                  }, null, 10 /* CLASS, PROPS */, _hoisted_4)), [
                    [_directive_moment, col.moment]
                  ])
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 8 /* PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"]))
}