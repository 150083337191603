<template>
  <ToggleIcon
      v-model="App.state.order_by_version"
      enabled_icon_class="fa-solid fa-arrow-down-1-9"
      enabled_color_class="white"
      disabled_color_class="grey"
      :title="App.state.order_by_version ? 'Click to sort by upload time' : 'Click to sort by version'"
      :no_click_focus="true"
      :scale="1.1"
  />

  <ActionIcon
      icon_class="fa-solid fa-plus"
      title="Upload app"
      @click="App.setup_file_picker_upload()"
  />

  <ActionIcon
      icon_class="fa-solid fa-link"
      title="Upload app from URL"
      @click="App.show_upload_from_url_form()"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ToggleIcon from "../../../ToggleIcon.vue";
import { App } from "../../../../../vue_record/models/app";
import ActionIcon from "../../../ActionIcon.vue";

export default defineComponent({
    components: { ActionIcon, ToggleIcon },
    // <editor-fold desc="PROPS">
    props: {
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        App() {
            return App
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
