  <template>
  <div ref="play_group"
       class="play-node-container">
    <div
        class="row-container"
        :style="row_container_style"
    >
      <div class="expander click-item"
           @click.stop="toggle_expanded">
        <i v-if="expanded"
           class="fas fa-chevron-down"/>
        <i v-else
           class="fas fa-chevron-right"/>
      </div>

      <div class="title-container click-item"
           @click="toggle_expanded">
        <span class="title-item icon">
          <PlayStatusIcon :play_status="props.status"/>
        </span>
        <span class="title-item icon">
          <PlayTypeIcon :play_type="play.props.type"/>
        </span>
        <div
            class="title-item progress-container"
            :style="progress_background()"
        >
          <span
              class="title title-item"
          >
            {{ props.name }}
          </span>
        </div>
      </div>

      <div class="action-container">
        <ActionIcon
            v-if="current.user.is_superadmin()"
            icon_class="fa-solid fa-indent"
            title="Show superadmin logs"
            @click="show_logs"
        />

        <ActionIcon
            v-if="main_web_available"
            icon_class="fa-solid fa-bullseye"
            title="Show group in sidebar"
            color_class="var(--group-color)"
            margin="0"
            @click="show_target"
        />
      </div>
    </div>

    <div v-show="expanded"
         class="play-content-container">
      <Loading
          v-if="!data_loaded"
          type="three_bounce"
          :size="4"
      />
      <template v-else>
        <PlayScenarioReport
            v-for="main_play_scenario in started_or_pending_main_play_scenarios"
            :key="main_play_scenario.props.id"
            :play_scenario="main_play_scenario"
            :play="play"
            :expanded="expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`] === true"
            :play_setting="play_setting"
            :main_web_available="main_web_available"
            :role="role"
            :is_viewer="is_viewer"
            :is_single_worker="is_single_worker"
            :parents="1"
            @toggle-expand="() => expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`] = !expanded_play_scenarios[`${main_play_scenario.constructor.name}_${main_play_scenario.props.id}`]"
            @play-log-mounted="on_play_log_mounted"
            @disable-sticky-scroll="$emit('disable-sticky-scroll')"
            @play-debugger-mounted="(play_snippet) => $emit('play-debugger-mounted', play_snippet)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import PlayScenarioReport from '../play_scenario/PlayScenarioReport.vue';
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Play } from '../../../../vue_record/models/play/play';
import { PlaySetting } from '../../../../vue_record/models/play/play_setting';
import { PlayGroup } from '../../../../vue_record/models/play/play_group';
import PlayTypeIcon from "../../other/PlayTypeIcon.vue";
import PlayStatusIcon from "../../other/PlayStatusIcon.vue";
import { PlaySnippet } from "../../../../vue_record/models/play/play_snippet";
import { PlayLog } from "../../../../vue_record/models/play/play_log";
import { set_expanded_play_node_states } from "../../../../helpers/play/set_expanded_play_node_states";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import Loading from "../../../testa/Loading.vue";
import ActionIcon from "../../../testa/ActionIcon.vue";
import { EnumUserRole } from "../../../../auto_generated/enums";
import { ScenarioFolderSavepoint } from "../../../../vue_record/models/scenario_folder_savepoint";
import { ScenarioFolder } from "../../../../vue_record/models/scenario_folder";
import { GroupSavepoint } from "../../../../vue_record/models/group_savepoint";
import { Group } from "../../../../vue_record/models/group";
import { CSSProperties } from "vue";

export default defineComponent({
    components: {
        ActionIcon,
        Loading,
        PlayStatusIcon,
        PlayTypeIcon,
        PlayScenarioReport,
    },
    props: {
        play: {
            type: Object as PropType<Play>,
            required: true,
        },
        play_setting: {
            type: Object as PropType<PlaySetting>,
            required: false,
            default: null,
        },
        play_group: {
            type: Object as PropType<PlayGroup>,
            required: true,
        },
        expanded: {
            type: Boolean,
            required: true,
        },
        auto_expand_play_scenarios: {
            type: Boolean,
            required: true,
        },
        is_single_worker: {
            type: Boolean,
            required: true
        },
        main_web_available: {
            type: Boolean,
            required: true
        },
        role: {
            type: String as PropType<EnumUserRole>,
            required: false,
            default: null
        },
        is_viewer: {
            type: Boolean,
            required: true
        }
    },
    emits: ['toggle-expand', 'play-log-mounted', 'disable-sticky-scroll', 'play-debugger-mounted'],
    data() {
        return {
            expanded_play_scenarios: {} as Record<string, boolean>,
            pretty_duration: '0s',
            data_loaded: false,
        }
    },
    computed: {
        project_version_id() {
            return this.play.props.project_version_id
        },
        PlaySnippet() {
            return PlaySnippet
        },
        main_play_scenarios() {
            return this.play_group.main_play_scenarios
        },
        props() {
            return this.play_group.props;
        },
        type() {
            return this.play.props.type;
        },
        current() {
            return current
        },
        started_or_pending_main_play_scenarios() {
            return this.main_play_scenarios.started_or_pending.order("job_started_at", "asc", "sensitive").toArray();
        },
        row_container_style() {
            const style: CSSProperties = {}
            if (this.expanded) {
                style.position = "sticky"
                style.top = 0
                style.zIndex = 9
                style.borderBottom = "solid 1px var(--group-color)"
            }
            return style
        }
    },
    watch: {
        expanded: {
            handler(new_val, old_val) {
                console.log(`Expanded for play group changed from ${old_val} to ${new_val}`);
                if (this.expanded && !this.data_loaded) this.load_data()
            },
            flush: "sync",
            immediate: true,
        }
    },
    beforeMount() {
        this.set_expanded_play_scenarios_state()
    },
    mounted() {
        // @ts-ignore - is assigned to div for easier referencing
        this.$refs.play_group.play_group = this.play_group
    },
    beforeUpdate() {
        this.set_expanded_play_scenarios_state();
    },
    methods: {
        load_data() {
            console.log("LOADING GROUP DATA");
            this.play_group
                .client
                .load_associated()
                .then(() => {
                    this.data_loaded = true
                })
        },
        on_play_log_mounted(play_log: PlayLog) {
            this.$emit('play-log-mounted', play_log)
        },
        progress_background() {
            if (this.props['finished?']) return 'background: transparent';

            const perc = this.props.progress * 100
            return `background: linear-gradient(90deg, var(--play-progress-color) 0 ${perc}%, transparent ${perc}% 100%);`
        },
        toggle_expanded() {
            this.$emit('disable-sticky-scroll')
            this.$emit('toggle-expand')
        },
        show_target() {
            if (this.type == Enum.Play.Type.GROUP) {
                GroupSavepoint.find_or_load(this.play_group.props.group_savepoint_id)
                              .then(group_savepoint => {
                                  Group.show_in_sidebar(group_savepoint.props.group_id, this.project_version_id)
                              })
            } else {
                ScenarioFolderSavepoint.find_or_load(this.play_group.props.scenario_folder_savepoint_id)
                                       .then(scenario_folder_savepoint => {
                                           ScenarioFolder.show_in_sidebar(scenario_folder_savepoint.props.scenario_folder_id, this.project_version_id)
                                       })
            }
        },
        show_logs() {
            console.log("showing logs");
            if (!ui_sync.is_logs_available()) {
                window.open("/testa/logs", "_blank")
            }
        },
        set_expanded_play_scenarios_state() {
            const play_scenarios = this.started_or_pending_main_play_scenarios
            set_expanded_play_node_states(this.expanded_play_scenarios, play_scenarios, this.auto_expand_play_scenarios);
        },
        on_finish_auto_collapse(play_scenario: PlayScenario) {
            this.expanded_play_scenarios[`${PlayScenario.name}_${play_scenario.props.id}`] = false
        },
    },
})
</script>

<style>

</style>
