import { PhoneProject } from '../models/phone_project';
import { Phone } from "../models/phone"
import { PhoneScope as ScenarioSettingPhoneScope } from "../scopes/scenario_setting/phone_scope"
import { Phone as ScenarioSettingPhone } from "../models/scenario_setting/phone"

declare module "../models/phone_project" {
    interface PhoneProject {
        phone: Phone
        scenario_setting_phones: ScenarioSettingPhoneScope
    }
}

PhoneProject.belongs_to("phone", Phone, "udid", {
    dependent_unload: true
})

PhoneProject.has_many("scenario_settings_phones", ScenarioSettingPhone, "phone_project_id")
