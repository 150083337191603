<template>
  <SettingsItem
      id="phonectors"
      :title="setting_items.superadmin.phonectors.title"
  >
    <div class="row"
         style="display: flex">
      <div class="col s8">
        <Button
            v-if="deletion_markers.marked().length > 0"
            id="delete_phonectors_button"
            text="Delete"
            color_class="red"
            @click="delete_phonectors"
        />
      </div>
      <div class="col s4">
        <Input
            id="phonectors_filter"
            v-model="filter"
            placeholder="Filter"
            :throttle_time="200"
        />
      </div>
    </div>
    <Table
        id="phonectors_table">
      <template #thead>
        <tr>
          <template v-for="(col, key) in visible_columns"
                    :key="key">
            <th v-if="key == 'delete'"
                :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                @click.stop="all_marked = !all_marked">
              <Checkbox
                  v-model="all_marked"
                  label=""
                  :for_table="true"
                  color_class="red"
              />
            </th>
            <th v-else
                :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                :data-priority="orders.priority(key)"
                @click="orders.toggle(key)">
              {{ col.name }}
            </th>
          </template>
        </tr>
      </template>

      <template #tbody>
        <template v-for="row in ordered_rows"
                  :key="row.record.props.id">
          <tr
              class="phonector-row"
              @click="phonector_click(row.record)"
          >
            <template v-for="(col, index) in row.cols"
                      :key="index">
              <td v-if="col.column_key == 'delete'"
                  :class="col.classes"
                  @click.stop="deletion_markers.marker(row.record).toggle()">
                <Checkbox
                    v-model="deletion_markers.marker(row.record).value"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </td>
              <td v-else
                  v-moment="col.moment"
                  :title="col.title"
                  :class="col.classes"
                  v-html="col.html"/>
            </template>
          </tr>
          <template v-if="row.record.props.id == selected_phonector?.props?.id">
            <tr>
              <td :colspan="row.cols.length"
                  class="expanded-td">
                <Table>
                  <template #tbody>
                    <tr v-for="phone in phonector_phones"
                        :key="phone.props.udid">
                      <td>{{ phone.props.udid }}</td>
                      <td>{{ phone.props.name }}</td>
                      <td>{{ phone.props.phone_type }}</td>
                    </tr>
                  </template>
                </Table>
              </td>
            </tr>
          </template>
        </template>
      </template>
    </Table>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Input from "../../../testa/Input.vue";
import Table from "../../../testa/Table.vue";
import { Phone } from "../../../../vue_record/models/phone";
import SettingsItem from "../../SettingsItem.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Button from "../../../testa/Button.vue"
import { PropType } from "vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { Phonector } from "../../../../vue_record/models/phonector";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";

export default defineComponent({
    components: {
        Checkbox,
        SettingsItem,
        Input,
        Table,
        Button,
    },
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            authenticity_token,
            filter: "",
            selected_phonector: null as Phonector,
            phonector_phones: [] as Phone[],
            deletion_markers: new RecordMarker([]),
            all_marked: false,
            columns: {
                delete: {
                    filterable: false,
                    orderable: false,
                    action: true,
                    classes: "red"
                },
                name: {
                    name: "Name",
                    html: (record) => record.props.name,
                },
                host: {
                    name: "Host",
                    html: (record) => record.props.host,
                },
            } as RecordColumns<Phonector>,
            orders: new RecordOrder([["name", "asc"]]),
        }
    },
    computed: {
        setting_items() {
            return setting_items
        },
        visible_columns() {
            return RecordsTable.visible_columns<Phonector>(this.columns);
        },
        records() {
            return Phonector.toArray();
        },
        rows() {
            return RecordsTable.generate_rows<Phonector>(this.visible_columns, this.records as Phonector[])
        },
        filtered_rows() {
            return RecordsTable.filter<Phonector>({
                columns: this.visible_columns,
                rows: this.rows,
                filter: this.filter,
            })
        },
        ordered_rows() {
            return RecordsTable.order<Phonector>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    watch: {
        selected_phonector() {
            if (this.selected_phonector != null) {
                this.load_phonector_phones(this.selected_phonector as Phonector)
            }
        },
        'filtered_rows'() {
            this.deletion_markers.set_records(this.filtered_rows.map(c => c.record) as Phonector[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
    },
    mounted() {
        this.load_phonectors()
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        load_phonectors() {
            Phonector.ClientClass.index();
        },
        load_phonector_phones(phonector: Phonector) {
            phonector.client.load_phones().then(phones => {
                this.phonector_phones = phones.toArray();
            })
        },
        phonector_click(phonector: Phonector) {
            if (this.selected_phonector?.props?.id == phonector.props.id) {
                this.selected_phonector = null
            } else {
                this.selected_phonector = phonector
            }
        },
        delete_phonectors() {
            Phonector.delete(this.deletion_markers.marked().map(p => p.props.id))
        },
    },
})
</script>

<style lang="scss" scoped>
.phonector-row {
  cursor: pointer;

  .action {
    cursor: pointer;
    width: 10px;
    display: flex;
    justify-content: center;

    &.delete {
      color: var(--button-red)
    }
  }
}

.expanded-td {
  padding-left: 20px;
}
</style>
