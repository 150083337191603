import { cytoscape_style } from "./cytoscape_style";
import { reactive } from "vue";
import { get_css_var } from "../generic/get_css_var";
import cytoscape from "cytoscape";
import { CytoscapeOptions } from "cytoscape";
import _ from "lodash";
import { markRaw } from "vue";

type CyCfg = {
    node: {
        width: number,
        height: number
    }
    edge: {
        color: string
    }
}

declare global {
    let cy_cfg : CyCfg
    interface Window {
        cy_cfg: CyCfg
    }
}
export const default_cytoscape_zoom_level = 1.4
let default_cytoscape_options: CytoscapeOptions = null
function load_default_options() {
    window.cy_cfg = reactive({
        node: {
            height: parseInt(get_css_var("--sb-node-height")),
            width: parseInt(get_css_var("--sb-node-width"))
        },
        edge: {
            color: get_css_var("--sb-edge")
        }
    })

    default_cytoscape_options = {
        boxSelectionEnabled: true,
        elements: [],
        autoungrabify: true,
        userZoomingEnabled: false,
        layout: {
            name: "dagre",
        },
        pan: {
            x: cy_cfg.node.width / 4,
            y: cy_cfg.node.height * 1.5,
        },
        zoom: default_cytoscape_zoom_level,
        style: cytoscape_style(),
    } as CytoscapeOptions
}

export function init_cytoscape(container: HTMLElement, opts: CytoscapeOptions) {
    if (default_cytoscape_options == null) load_default_options()

    opts = _.merge(_.cloneDeep(default_cytoscape_options), opts)
    const cy = markRaw(cytoscape({
        container,
        boxSelectionEnabled: opts.boxSelectionEnabled,
        elements: opts.elements,
        autoungrabify: opts.autoungrabify,
        userZoomingEnabled: opts.userZoomingEnabled,
        layout: {
            name: opts.layout.name,
        },
        pan: {
            x: opts.pan.x,
            y: opts.pan.y,
        },
        wheelSensitivity: opts.wheelSensitivity,
        zoom: opts.zoom,
        style: opts.style,
    }))
    window.cy = cy
    return cy
}
