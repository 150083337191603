import { EditorChange } from "codemirror";
import { Editor } from "codemirror";
import CodeMirror from "codemirror";
import { chained_method_align_origin } from "./align_chained_methods";
import { auto_add_end_origin } from "./auto_add_end";
import { home } from "./keybinds/home";
import { end } from "./keybinds/end";
export function attach_hint_listener(cm: Editor, context_code: () => string) {
    cm.on("change", function(cm: Editor, change: EditorChange) {
        if (!current_user_props.show_hints_as_you_type) return
        if (change.origin == "merge") return
        if (change.origin == "setValue") return;
        if (change.origin == "complete") return
        if (change.origin == "paste") return
        if (change.origin == "+delete") return
        if (change.origin == chained_method_align_origin) return;
        if (change.origin == auto_add_end_origin) return;
        if (change.text[0] == "@b.") return
        if (change.text[0] == "@d.") return
        if (change.text[0] == "@i.") return
        if (change.text[0] == ",") return
        if (change.text[0] == "{" || change.text[0] == "}" || change.text[0] == "{}") return
        if (change.text[0] == "(" || change.text[0] == ")" || change.text[0] == "()") return
        if (change.text[0] == "[" || change.text[0] == "]" || change.text[0] == "[]") return


        let any_changes = false;
        for (let i = 0; i < change.text.length; ++i) {
            if (change.text[i].trim() != "") {
                any_changes = true;
                break;
            }
        }
        if (!any_changes) return;


        if (current.user.props.show_hints_as_you_type && cm.hasFocus()) {
            cm.showHint({
                completeSingle: false,
                supportsSelection: true,

                // custom property for custom-hint.js
                extraText: context_code(),

                // tells cm to use custom-hint.js
                hint: CodeMirror.hint.custom,
                extraKeys: {
                    Home: home,
                    End: end
                },
                container: cm.getWrapperElement()
            })
            // const handler = (e: KeyboardEvent) => {
            //     cm.triggerOnKeyDown({ type: "keydown", keyCode: e.keyCode })
            //     document.body.removeEventListener("keydown", handler)
            // }
            // document.body.addEventListener("keydown", handler)
        }
    })
}

window.CodeMirror = CodeMirror

