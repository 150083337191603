
import { DeviceClient } from "../../clients/mobile_provisionings/device_client";
import { DeviceScope } from "../../scopes/mobile_provisionings/device_scope";
import { Props } from "../../base/vue_record";
import { QuerifyProps } from "../../base/vue_record_scope";
import { State } from "../../base/vue_record";
import { Computed } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { VueRecord } from "../../base/vue_record";
import { reactive } from "../../../helpers/vue/reactive";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { VueRecordIndex } from "../../base/vue_record_index";
import { VueRecordStore } from "../../base/vue_record_store";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { TestaTree } from "../../../components/testa/tree/tree";

// <editor-fold desc="TYPES">
export interface DeviceProps extends Props {
    id: number
    mobile_provisioning_id: number
    phone_udid: string
    created_at: Date
    updated_at: Date
}
export type QuerifiedDeviceProps = QuerifyProps<DeviceProps>
export type DeviceCreateProps = Omit<DeviceProps, 'id'>
export type DeviceUpdateProps = Partial<DeviceProps>

export interface DeviceState extends State {}
export interface DeviceComputed extends Computed {}
export interface DeviceStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class Device extends VueRecord {
    ['constructor']: typeof Device

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = DeviceClient
    static ScopeClass = DeviceScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: DeviceStaticState = reactive<DeviceStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "mobile_provisioning_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Device> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Device>> = {}

    static field_validators: ModelValidatorOpts<DeviceProps> = {}

    static resource_name = Enum.Resource.Label.MOBILE_PROVISIONING_DEVICE
    static resource_id = Enum.Resource.Id.MOBILE_PROVISIONING_DEVICE
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: DeviceClient
    declare props: DeviceProps;
    declare state: DeviceState;
    declare computed: DeviceComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
Device.register_resource(Device)
DeviceClient.ModelClass = Device
DeviceScope.ModelClass = Device


declare global {
    interface Window {
        MobileProvisioningDevice: typeof Device
    }
}
window.MobileProvisioningDevice = Device
// </editor-fold>

