<!--suppress BadExpressionStatementJS -->
<template>
  <template v-if="show_table">
    <SettingsItem
        id="project_versions"
        title="Project Versions">
      <div class="row"
           style="display: flex;">
        <div class="col s8"
             style="display: flex;">
          <Button
              v-if="!is_viewer_role && !is_user_role"
              text="New Version"
              @click="new_project_version"
          />
        </div>
        <div class="col s4">
          <Input
              v-model="filter"
              placeholder="Filter"
          />
        </div>
      </div>
      <ProjectVersionsTable
          id="project_versions_table"
          :project="project"
          :filter="filter"
          :is_viewer_role="is_viewer_role"
          :is_user_role="is_user_role"
          @project-version-click="project_version_click"
      />
      <div v-if="show_new_project_version">
        <NewProjectVersionForm
            :for_project="project"
            @close="show_new_project_version = false"/>
      </div>
    </SettingsItem>
  </template>
  <template v-else>
    <ProjectVersionEdit
        :project_version="edit_project_version"
        :is_viewer_role="is_viewer_role"
        :is_user_role="is_user_role"
        :form_validator="form_validator.register_child_form('project_version_edit')"
        @exit="show_table=true"
        @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
        @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Input from "../../../testa/Input.vue";
import Button from "../../../testa/Button.vue";
import moment from "moment/moment";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import SettingsItem from "../../SettingsItem.vue";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import ProjectVersionEdit from "./ProjectVersionEdit.vue";
import NewProjectVersionForm from "./NewProjectVersionForm.vue";
import ProjectVersionsTable from "./ProjectVersionsTable.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";


export default defineComponent({
    components: {
        SettingsItem,
        ProjectVersionEdit,
        NewProjectVersionForm,
        ProjectVersionsTable,
        Button,
        Input,
    },
    props: {
        project: {
          type: Object as PropType<Project>,
          required: true
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            show_table: true,
            show_new_project_version: false,
            edit_project_version: null,
            filter: "",
        }
    },
    computed: {
        moment() {
            return moment
        },
    },
    watch: {},
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        new_project_version() {
            this.show_new_project_version = true
        },
        project_version_click(project_version: ProjectVersion) {
            this.show_table = false
            this.edit_project_version = project_version
        },
    },
})
</script>

<style lang="scss" scoped>
</style>
