// ############################################### DIFF / MERGE ########################################################
import CodeMirror from "codemirror";
import { Snippet } from "../../vue_record/models/snippet";

type Diff = { 0: number, 1: string }

export function merge_snippets(cm: CodeMirror.Editor, snippet: Snippet, remote_code: string) {
    const diff = get_diff(remote_code, cm.getValue(), false);

    const chunks = get_chunks(diff);
    let has_conflicts = false;

    const remote_cm = CodeMirror(document.createElement("div"), {
        value: remote_code
    });

    snippet.state.conflict_chunks = get_and_remove_conflict_chunks(snippet.state.local_changes, chunks, cm, remote_cm);

    if (snippet.state.conflict_chunks.length > 0) {
        snippet.state.conflict_remote_code = remote_code
        has_conflicts = true
    }
    snippet.state.has_conflicts = has_conflicts;


    for (let i = 0; i < chunks.length; ++i) {
        let origStart;
        let origLines = 0;
        const origEnd = CodeMirror.Pos(chunks[i].origTo, 999)
        if (chunks[i].origTo > remote_cm.lastLine()) {
            origStart = CodeMirror.Pos(chunks[i].origFrom - 1)
            origLines = chunks[i].origTo - chunks[i].origFrom;
        } else {
            origStart = CodeMirror.Pos(chunks[i].origFrom, 0)
            origLines = chunks[i].origTo - chunks[i].origFrom + 1;
        }

        let editStart;
        let editLines;
        const editEnd = CodeMirror.Pos(chunks[i].editTo, 999)
        if (chunks[i].editTo > cm.lastLine()) {
            editStart = CodeMirror.Pos(chunks[i].editFrom - 1);
            editLines = chunks[i].editTo - chunks[i].editFrom;
        } else {
            editStart = CodeMirror.Pos(chunks[i].editFrom, 0)
            editLines = chunks[i].editTo - chunks[i].editFrom + 1;
        }
        const replacement_text = remote_cm.getRange(origStart, origEnd);
        cm.replaceRange(replacement_text, editStart, editEnd, "merge")

        const addLines = origLines - editLines;
        if (addLines != 0) {
            for (let j = i; j < chunks.length; ++j) {
                chunks[j].editFrom += addLines
                chunks[j].editTo += addLines
            }
        }
    }

    return has_conflicts;
}

function get_diff(code_a: string, code_b: string, ignoreWhitespace: boolean, splitByLines = true) {
    if (!dmp) dmp = new diff_match_patch();


    const diff: Diff[] = dmp.diff_main(code_a, code_b);
    // The library sometimes leaves in empty parts, which confuse the algorithm
    for (let i = 0; i < diff.length; ++i) {
        const part = diff[i];
        if (ignoreWhitespace ? !/[^ \t]/.test(part[1]) : !part[1]) {
            diff.splice(i--, 1);
        } else if (i && diff[i - 1][0] == part[0]) {
            diff.splice(i--, 1);
            diff[i][1] += part[1];
        }
    }
    if (splitByLines) {
        let i = 0;
        while (i < diff.length) {
            if (diff[i][0] != -1) {
                const lines = diff[i][1].split("\n")
                if (lines.length != 1) {
                    const type = diff[i][0];
                    diff.splice(i, 1);
                    for (let j = i, k = 0; k < lines.length; ++j, ++k) {
                        if (k + 1 == lines.length && lines[k] == "") continue;
                        let string = lines[k];
                        if (k + 1 != lines.length) string += "\n"
                        diff.splice(j, 0, { 0: type, 1: string })
                        if (k == 0) i = j;
                    }
                }
            }
            ++i;
        }
    }
    return diff;
}

function get_and_remove_conflict_chunks(local_changes: CodeMirror.EditorChange[], chunks: Chunk[], local_cm: CodeMirror.Editor, remote_cm: CodeMirror.Editor) {
    const are_the_same_code = (local_from: CodeMirror.Position, local_to: CodeMirror.Position, remote_from: CodeMirror.Position, remote_to: CodeMirror.Position) => {
        const local_code = local_cm.getRange(local_from, local_to)
        const remote_code = remote_cm.getRange(remote_from, remote_to)
        console.log("COMPARING CODE", local_code, remote_code);
        return local_code == remote_code
    }
    const conflict_chunks: Chunk[] = [];
    for (let i = 0; i < local_changes.length; ++i) {
        for (let j = 0; j < chunks.length; ++j) {
            if (local_changes[i].removed.length > 1) {
                if (local_changes[i].from.line >= chunks[j].editFrom && (local_changes[i].from.line) <= chunks[j].editTo) {
                    if (!are_the_same_code(
                        { line: chunks[j].editFrom, ch: 0 },
                        { line: chunks[j].editTo, ch: local_cm.getLine(chunks[j].editTo).length },
                        { line: chunks[j].origFrom, ch: 0 },
                        { line: chunks[j].origTo, ch: remote_cm.getLine(chunks[j].origTo).length }
                    )) {
                        conflict_chunks.push(chunks.splice(j, 1)[0]);
                        --j;
                    }
                }
            } else if (local_changes[i].from.line >= chunks[j].editFrom &&
                // +text.length because when adding lines to codemirror, to.line is not increased
                // text is an array where each element represents a line, so if 5 lines are added text will have 5 elements
                (local_changes[i].to.line + local_changes[i].text.length - 1) <= chunks[j].editTo) {

                if (!are_the_same_code(
                    { line: chunks[j].editFrom, ch: 0 },
                    { line: chunks[j].editTo, ch: local_cm.getLine(chunks[j].editTo).length },
                    { line: chunks[j].origFrom, ch: 0 },
                    { line: chunks[j].origTo, ch: remote_cm.getLine(chunks[j].origTo).length }
                )) {
                    conflict_chunks.push(chunks.splice(j, 1)[0]);
                    --j;
                }
            }
        }
    }
    console.log(conflict_chunks);
    return conflict_chunks;
}

export type Chunk = {
    origFrom: number,
    origTo: number,
    editFrom: number,
    editTo: number
}

function get_chunks(diff: Diff[]) {
    const chunks: Chunk[] = [];
    if (!diff.length) return chunks;
    let startEdit = 0;
    let startOrig = 0;
    const edit = CodeMirror.Pos(0, 0);
    const orig = CodeMirror.Pos(0, 0);
    let should_add = false;
    for (let i = 0; i < diff.length; ++i) {
        const part = diff[i];
        const tp = part[0];

        if (tp == DIFF_EQUAL) {
            const startOff = edit.line < startEdit || orig.line < startOrig ? 1 : 0;
            const cleanFromEdit = edit.line + startOff;
            const cleanFromOrig = orig.line + startOff;
            move_over(edit, part[1], null, orig);
            const endOff = 0;// endOfLineClean(diff, i) ? 1 : 0;
            const cleanToEdit = edit.line + endOff;
            const cleanToOrig = orig.line + endOff;
            if (cleanToEdit > cleanFromEdit) {
                if (should_add) {
                    // console.log("----pushing chunk 0")
                    chunks.push({
                        origFrom: startOrig,
                        origTo: cleanFromOrig,
                        editFrom: startEdit,
                        editTo: cleanFromEdit
                    });
                    should_add = false;
                }
                startEdit = cleanToEdit;
                startOrig = cleanToOrig;
            }
        } else {
            should_add = true;
            // console.log("--MOVE OVER INSERT:")
            move_over(tp == DIFF_INSERT ? edit : orig, part[1]);
        }
    }
    if (startEdit <= edit.line || startOrig <= orig.line) {
        if (should_add) {
            chunks.push({
                origFrom: startOrig,
                origTo: orig.line + 1,
                editFrom: startEdit,
                editTo: edit.line + 1
            });
        }
    }
    return chunks;
}

function move_over(pos: CodeMirror.Position, str: string, copy = false, other: CodeMirror.Position = null) {
    const out = copy ? CodeMirror.Pos(pos.line, pos.ch) : pos;
    let at = 0;
    for (; ;) {
        var nl = str.indexOf("\n", at);
        if (nl == -1) break;
        ++out.line;
        if (other) ++other.line;
        at = nl + 1;
    }
    out.ch = (at ? 0 : out.ch) + (str.length - at);
    if (other) other.ch = (at ? 0 : other.ch) + (str.length - at);

    return out;
}

// #####################################################################################################################
