import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f172ccf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = {
  key: 0,
  class: "loading-container",
  title: "Action in progress"
}
const _hoisted_3 = ["id", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading")

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["vue-action-icon-container", _ctx.clazz]),
    style: _normalizeStyle(_ctx.style),
    tabindex: _ctx.action_in_progress && !_ctx.allow_concurrent_click_action ? null : _ctx.tab_index,
    onKeydown: _cache[3] || (_cache[3] = (...args) => (_ctx.click_on_enter && _ctx.click_on_enter(...args))),
    onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.on_click && _ctx.on_click(...args))),
    onMousedown: _cache[5] || (_cache[5] = (...args) => (_ctx.on_mousedown && _ctx.on_mousedown(...args)))
  }, [
    (_ctx.action_in_progress && !_ctx.allow_concurrent_click_action)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_Loading, {
            fadein: false,
            margin: "0",
            color: _ctx.color_class,
            type: "fading_circle"
          }, null, 8 /* PROPS */, ["color"])
        ]))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          id: _ctx.id,
          class: _normalizeClass(["vue-label vue-action-icon", _ctx.clazz]),
          title: _ctx.title,
          style: _normalizeStyle(_ctx.icon_style),
          onKeydown: _cache[0] || (_cache[0] = (...args) => (_ctx.click_on_enter && _ctx.click_on_enter(...args))),
          onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.on_click && _ctx.on_click(...args))),
          onMousedown: _cache[2] || (_cache[2] = (...args) => (_ctx.on_mousedown && _ctx.on_mousedown(...args)))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.icon_class)
          }, null, 2 /* CLASS */)
        ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_3))
  ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
}