import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { PlayLog } from "../../models/play/play_log";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")

export class PlayLogClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlayLog
    declare record: PlayLog

    delete() {
        return PlayLog.delete(this.key())
    }

    static delete(ids: number | number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/play_logs/batch/`,
                type: "DELETE",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    play_log_ids: ids,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }
}
