import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col s6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col s6" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Input = _resolveComponent("Input")
  const _component_SettingsItem = _resolveComponent("SettingsItem")

  return (_openBlock(), _createBlock(_component_SettingsItem, {
    id: _ctx.setting_items.project.web.id,
    title: _ctx.setting_items.project.web.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Input, {
            id: "page_load_timeout_input",
            modelValue: _ctx.web_props.pageload_timeout,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.web_props.pageload_timeout) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Page load timeout (in seconds)",
            validator: _ctx.form_validator.register('page_load_timeout', _ctx.ProjectVersionSetting.field_validators.pageload_timeout)
          }, null, 8 /* PROPS */, ["modelValue", "disabled", "validator"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Input, {
            id: "watir_default_timeout_input",
            modelValue: _ctx.web_props.watir_default_timeout,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.web_props.watir_default_timeout) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Watir default timeout (in seconds)",
            validator: _ctx.form_validator.register('watir_default_timeout', _ctx.ProjectVersionSetting.field_validators.watir_default_timeout)
          }, null, 8 /* PROPS */, ["modelValue", "disabled", "validator"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "title"]))
}