import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumFileType } from "../../auto_generated/enums";
import { Timeout } from "../../helpers/generic/create_timeout";
import CodeMirror from "codemirror";
import { Chunk } from "../../helpers/codemirror/merge";
import { TabTargetOpts } from "../../components/testa/editor/tab";
import { RecordOpts } from "../base/vue_record";
import { computed } from "../../helpers/vue/computed";
import { ComputedRole } from "../base/vue_record";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { create_vue_app } from "../../helpers/vue/create_vue_app";
import JumpToModal from "../../components/testa/editor/editors/codemirror/JumpToModal.vue";
import { FileScope } from "../scopes/file_scope";
import { on_shared_worker_loaded } from "../../helpers/ui_sync/on_shared_worker_loaded";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { convert_sync_data_to_new_format } from "../../helpers/files/convert_sync_data_to_new_format";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import SyncReceiveData = UiSync.SyncReceiveData;
import { FileClient } from "../clients/file_client";
import { resolve_tab_target } from "../../components/testa/editor/tab";
import { unmount_all_modals } from "../../helpers/vue/unmount_all_modals";
import { FileTab } from "../../components/testa/editor/tabs/file_tab";
import { multi_resource_remove } from "../../helpers/client/core/multi_resource_remove";
import { SnippetCursorData } from "./snippet";
import { FileFolder } from "./file_folder";
import { FileFolderScope } from "../scopes/file_folder_scope";
import { SnippetProps } from "./snippet";

// <editor-fold desc="TYPES">
export interface FileProps extends Props {
    path: string
    name: string
    project_version_id: number
    file_folder_path: string
    type: EnumFileType
    size: number
    content: string
    mediatype: string
    subtype: string
    updated_at: Date
    created_at: Date
}
export type QuerifiedFileProps = QuerifyProps<FileProps>
export type FileCreateProps = Omit<Partial<FileProps>, 'id'>
export type FileUpdateProps = Partial<FileProps>

export interface FileState extends State {
    codemirrors: any[]
    autosave: Timeout
    has_conflicts: boolean
    local_changes: CodeMirror.EditorChange[]
    conflict_chunks: Chunk[]
    conflict_remote_code: string
    other_cursors: Record<string, SnippetCursorData>
    code_before_conflict: string,
    history_before_conflict: any
    content_not_committed: string
}

export interface FileComputed extends ComputedRole {
    download_path: string
}

export interface FileStaticState extends StaticState {
    upload_progresses: Array<number>
}
export interface FileOpenOpts extends TabTargetOpts {
    close_all_modals?: boolean
}

export type FileEditorActionItem =
    "copy"
    | "cut"
    | "paste"
    | "undo"
    | "redo"
    | "fullscreen"
    | "run"
    | "show"
    | "save"
    | "jump"


export type FileEditorFeature = "doc_link"
    | "hints"
    | "contextmenu"
    | "debounced_save"
    | "keymap"

// </editor-fold>

const console = new Consoler("warn")
export class File extends VueRecord {
    ['constructor']: typeof File

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static discard_outdated = false
    static ClientClass = FileClient
    static ScopeClass = FileScope
    static readonly primary_key = "path"
    static sync_channels: string[] = []
    static state: FileStaticState = reactive<FileStaticState>({
        upload_progresses: []
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "file_folder_path"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof File> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof File>> = {}

    static field_validators: ModelValidatorOpts<FileProps> = {}

    static resource_name = Enum.Resource.Label.FILE
    static resource_id = Enum.Resource.Id.FILE
    static icon_class = "fa-regular fa-file"
    static color = () => get_css_var("--file-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: FileClient
    declare props: FileProps;
    declare state: FileState;
    declare computed: FileComputed;

    // </editor-fold>

    constructor(props: FileProps, opts: RecordOpts) {
        super(props, opts);

        this.state.codemirrors = [];
        this.state.autosave = null
        this.state.local_changes = [];
        this.state.content_not_committed = this.props.content;
        this.computed.download_path = computed(() => `/files/${this.key()}/download?project_version_id=${this.props.project_version_id}`)
    }

    after_create() {
        super.after_create();

        this.init_computed_role(() => this.project_version?.props?.project_id)
    }

    after_update(new_props: FileProps, old_props: FileProps, changes: (keyof FileProps)[]) {
        super.after_update(new_props, old_props, changes);

        // <editor-fold desc="CONTENT">
        if (this.state.content_not_committed == null) {
            this.state.content_not_committed = this.props.content
        }
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()): Promise<void> {
        return File.show_in_sidebar(this.key(), this.props.project_version_id, tree);
    }

    static async show_in_sidebar(file_paths: string | string[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: string[];
        if (what_is_it(file_paths) == "Array") {
            ids = file_paths as string[]
        } else {
            ids = [file_paths as string]
        }

        if (web.is_main) {
            // TODO: refactor this so that it expands folder by folder, an then once all folders are expanded, select all target elements
            // Section.get_project_section().enable()
            // const all_keys: string[][] = []
            // const promises: Promise<any>[] = []
            // ids.each(file_path => {
            //     let current_path = "/"
            //     const keys = [
            //         FileFolder.tree_key(project_version_id)
            //     ]
            //     const parts = file_path.split("/")
            //     parts.splice(0, 1)
            //     parts.each(path_part => {
            //         current_path += path_part
            //         if (current_path == file_path) {
            //             const f = File.find(current_path)
            //             keys.push(f?.tree_key())
            //         } else {
            //             const ff = FileFolder.find(current_path)
            //             keys.push(ff?.tree_key())
            //         }
            //     })
            //     all_keys.push(keys)
            //     tree.expand_paths(all_keys)
            // })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: File.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="HOOKS">
    before_unload() {
        super.before_unload();

        this.get_tabs().forEach(tab => tab.close())
    }
    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    show_jump_to(cm: CodeMirror.Editor) {
        create_vue_app(JumpToModal, { cm })
    }

    open(opts: FileOpenOpts = {}) {
        const tab_target = resolve_tab_target(opts)
        if (opts.close_all_modals) unmount_all_modals();

        const tab = tab_target.create_tab({
            id: this._file_tab_id(),
            file_path: this.key(),
            type: FileTab.type
        }) as FileTab

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => {
                resolve(null)
            })
        })
    }

    destroy() {
        return multi_resource_remove(this.project_version.key(), this.to_scoped_map())
    }

    // </editor-fold>

    // <editor-fold desc="TREE">

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<FileScope, FileFolderScope, VueRecord>> = {}
            if (current_role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    File.ClientClass.create({
                        file_folder_path: this.file_folder?.key(),
                        project_version_id: this.props.project_version_id
                    })
                }

                keys["ctrl++"] = () => {
                    FileFolder.ClientClass.create({
                        file_folder_path: this.file_folder?.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const file_folder_paths = nodes.map(n => n.record)
                                               .filter(r => r instanceof FileFolder)
                                               .map(r => r.key())
                const file_paths = nodes.map(n => n.record)
                                        .filter(r => r instanceof File)
                                        .map(r => r.key())

                if (type == "copy") {
                    FileFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.file_folder?.key(),
                        file_folder_paths,
                        file_paths
                    )
                } else if (type == "cut") {
                    FileFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.file_folder?.key(),
                        file_folder_paths,
                        file_paths
                    )
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXTMENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<FileScope, FileFolderScope, File>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: FileFolder._tree_new_file_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.open = {
                    name: "Open",
                    icon: File.icon_class,
                    color: get_css_var("--button-white"),
                    key: `enter`,
                    callback: () => {
                        this.open();
                    },
                }

                items.download = {
                    name: "Download",
                    icon: "fa-solid fa-download",
                    color: get_css_var("--button-blue"),
                    callback: () => {
                        const records_scope = node.tree.get_selected_records()
                        if (records_scope.files.count + records_scope.file_folders.count > 1) {
                            File.ClientClass.batch_download(node.tree.project_version.key(), records_scope.file_folders.toArray(), records_scope.files.toArray())
                        } else {
                            this.client.download();
                        }
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<FileScope, FileFolderScope, File> {
        return {
            resource_id: Enum.Resource.Id.FILE,
            key: this.tree_key(),
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    return this.open()
                },
            },
            icon: computed(() => {
                return {
                    color: get_css_var("--file-color"),
                }
            }),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: true,
                is_drop_area: false,
            },
            clipboard: this._testa_tree_clipboard(),
            contextmenu: this._tree_contextmenu(),
        }
    }

    // </editor-fold>

    // <editor-fold desc="EDITOR">
    _editor_contextmenu(cm: CodeMirror.Editor, e: MouseEvent, _exclude_items: FileEditorActionItem[] = []) {
        const items: ContextMenu.Items = {}
        return items
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _file_tab_id() {
        return `file_tab_${this.key()}`
    }

    _extract_codemirror_props(cm: CodeMirror.Editor) {
        const snippet_props: FileUpdateProps = {
            project_version_id: this.props.project_version_id,
            content: cm.getValue(),
        }
        return snippet_props
    }

    _save_codemirror_content(file_props: FileUpdateProps) {
        this.state.autosave?.clear()
        this.state.autosave = null

        return this.client.update(file_props).then(() => {
            const tabs = this.get_tabs();
            tabs.forEach(t => t.set_saved_indicator());
            this.state.local_changes = []

            this.state.codemirrors.forEach(cm => {
                for (let j = 0; j <= cm.lastLine(); ++j) {
                    cm.removeLineClass(j, "gutter", "CodeMirror-linechange")
                }
            })
        })
    }

    _editor_keymap(cm: CodeMirror.Editor, exclude_items: FileEditorActionItem[] = []) {
        let Ctrl_or_Cmd = "Ctrl"
        if (is_pc_mac) Ctrl_or_Cmd = "Cmd"
        const key_map: Record<string, (cm: CodeMirror.Editor) => void> = {}
        if (!exclude_items.includes("fullscreen")) {
            key_map.F11 = () => {
                cm.setOption("fullScreen", !cm.getOption("fullScreen"))
            }
        }
        if (!exclude_items.includes("save")) {
            key_map[`${Ctrl_or_Cmd}-S`] = () => {
                if (!this.computed.role_is_viewer) this._save_codemirror_content(this._extract_codemirror_props(cm))
            }
        }

        if (!exclude_items.includes("jump")) {
            key_map[`${Ctrl_or_Cmd}-G`] = (cm) => {
                this.show_jump_to(cm)
            }
        }

        key_map[`Shift-${Ctrl_or_Cmd}-F`] = () => {
            // prevent default replace action
        }
        return key_map
    }

    static _upload_contextmenu_item(node: TestaTree.Node<FileScope, FileFolderScope, FileFolder>) {
        return {
            name: "Upload",
            icon: "fa-solid fa-upload",
            color: get_css_var("--button-green"),
            key: "+",
            callback: () => {
                const active_node = node.tree.get_active_node()
                if (active_node.resource_id == Enum.Resource.Id.FILE) {
                    File._setup_file_picker_upload(node.tree.project_version.key(), active_node.record.props.file_folder_path)
                } else if (active_node.resource_id == Enum.Resource.Id.FILE_FOLDER) {
                    File._setup_file_picker_upload(node.tree.project_version.key(), active_node?.record?.props?.path)
                }
            },
        }
    }

    static _setup_file_picker_upload(project_version_id: number, file_folder_path: string) {
        $("#import_resources_input")
            // .attr("accept", ".*")
            // .attr("webkitdirectory", "true")
            // .attr("directory", "true")
            .attr("multiple", "true")
            .off()
            .on("change", function() {
                const thiz = this as HTMLInputElement
                for (let i = 0; i < thiz.files.length; ++i) {
                    File.ClientClass.upload(project_version_id, file_folder_path, thiz.files[i], i)
                }
            })
            .trigger("click");

        // track progress for all uploading files, so we can have single nanobar
        this.state.upload_progresses = []
    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        if (include_name) {
            return this.props.path
        } else {
            if (this.props.file_folder_path == null) return "/"
            return this.props.file_folder_path
        }
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
File.register_resource(File)
FileClient.ModelClass = File
FileScope.ModelClass = File


global_event_bus.$on("after_project_version_unload", () => {
    File.get_scope().unload()
})

on_shared_worker_loaded(() => {
    on_dom_content_loaded(() => {
        watch(
            () => current.project_version?.props?.id,
            (project_version_id, old_project_version_id) => {
                if (old_project_version_id != null) ui_sync.send_unsync_subscribe_task(`/sync/project_version/${old_project_version_id}/files`, File.resource_id)

                if (project_version_id != null) {
                    ui_sync.send_sync_subscribe_task(`/sync/project_version/${project_version_id}/files`, File.resource_id)
                }
            },
            {
                flush: "sync",
                immediate: true
            }
        )

        // register a handler for sync messages
        ui_sync.register_sync_task("receive", File.resource_id, (sender: string, data: SyncReceiveData) => {
            const new_format = convert_sync_data_to_new_format(data.message)

            if (data.message.resource == "files") {
                File.on_sync_data_received(new_format)
            } else {
                FileFolder.on_sync_data_received(new_format)
            }
        })
    })
})

declare global {
    interface Window {
        TestaFile: typeof File
    }
}
window.TestaFile = File
// </editor-fold>

