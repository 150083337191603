import _ from "lodash";
import { Ref } from "vue";
import { ref } from "vue";
import { what_is_it } from "../../../helpers/generic/what_is_it";
import { escape_html } from "../../../helpers/dom/escape_html";
import { RecordOrder } from "./record_order";
import { CSSProperties } from "vue";
import { reactive } from "vue";
import { computed } from "../../../helpers/vue/computed";

type VueCssClass = string | Record<string, boolean>

export type RecordFilterOpts<Record> = {
    rows: RecordRow<Record>[]
    columns: RecordColumns<Record>
    filter: string[] | string
    case_sensitive?: boolean
}


export type RecordColumn<Record> = {
    name?: string
    action?: boolean
    orderable?: boolean
    filterable?: boolean
    hidden?: boolean
    moment?: string | Date | number | ((record: Record) => string | Date | number)
    html?: (record: Record) => any
    order_value?: (record: Record) => any
    classes?: VueCssClass | ((record: Record) => VueCssClass);
    style?: CSSProperties | ((record: Record) => CSSProperties);
    title?: string | ((record: Record) => string);
}
export type RecordColumns<Record> = { [key: string]: RecordColumn<Record> }

export type RecordRowCol<Record> = {
    raw: any
    html: string
    order_value: any
    classes?: VueCssClass
    style?: CSSProperties
    title?: string
    moment?: string | Date | number
    column_key: string
    column_definition: RecordColumn<Record>
}

export type RecordRow<Record> = {
    record: Record,
    cols: RecordRowCol<Record>[]
    // raw: any[],
    // html: string[]
    // order_values: any[]
    // column_definition: RecordColumn<Record>[]
    // column_keys: string[]
    // classes: VueCssClass[]
    // titles: string[]
    reactor: Ref
}

// 1. set columns -> strip hidden columns
// 2. set records -> generate raw
// 3. set_filter -> generate html -> filter
// 4. set order -> generate order_values -> order

export class RecordsTable {
    static visible_columns<Record>(columns: RecordColumns<Record>) {
        const visible_columns: RecordColumns<Record> = {}
        // remove columns that are hidden
        for (const key in columns) {
            const column = columns[key]
            if (column.filterable == null) column.filterable = true
            if (column.orderable == null) column.orderable = true
            if (column.action == null) column.action = false
            if (column.hidden) continue;
            visible_columns[key] = column;
        }
        return visible_columns
    }

    static generate_rows<Record>(columns: RecordColumns<Record>, records: Record[]) {
        const rows: RecordRow<Record>[] = []
        const column_definitions = Object.values(columns)
        const column_keys = Object.keys(columns)
        records.forEach(record => {
            const cols: RecordRowCol<Record>[] = []
            // const raws: string[] = []
            // const htmls: string[] = []
            // const order_values: any[] = []
            // const row_classes: VueCssClass[] = []
            // const titles: string[] = []

            column_definitions.forEach((column_definition, index) => {
                let raw = ""
                if (column_definition.html != null) {
                    raw = escape_html(column_definition.html(record))
                }
                const col: RecordRowCol<Record> = reactive({
                    raw,
                    html: raw,
                    order_value: raw,
                    column_definition,
                    column_key: column_keys[index]
                })

                if (column_definition.classes != null) {
                    if (what_is_it(column_definition.classes) == "Function") {
                        col.classes = computed(() => {
                            return (column_definition.classes as ((record: Record) => VueCssClass))(record)
                        })
                    } else {
                        col.classes = column_definition.classes as VueCssClass
                    }
                }

                if (column_definition.style != null) {
                    if (what_is_it(column_definition.style) == "Function") {
                        col.style = computed(() => {
                            return (column_definition.style as ((record: Record) => CSSProperties))(record)
                        })
                    } else {
                        col.style = column_definition.style as CSSProperties
                    }
                }

                if (column_definition.action) {
                    const what = what_is_it(col.classes)
                    if (what == "String") {
                        col.classes = `${col.classes} action-width`
                    } else if (what == "Object") {
                        (col.classes as any)['action-width'] = true
                    }
                }

                if (column_definition.title != null) {
                    if (what_is_it(column_definition.title) == "Function") {
                        col.title = (column_definition.title as ((record: Record) => string))(record)
                    } else {
                        col.title = column_definition.title as string
                    }
                }

                if (column_definition.moment != null) {
                    if (what_is_it(column_definition.moment) == "Function") {
                        col.moment = (column_definition.moment as ((record: Record) => string))(record)
                    } else {
                        col.moment = column_definition.moment as string
                    }
                }

                cols.push(col)
            })
            rows.push({
                reactor: ref(0),
                record,
                cols
            })
        })
        return rows;
    }

    static filter<Record>(opts: RecordFilterOpts<Record>) {
        if (opts.case_sensitive == null) opts.case_sensitive = false;

        let rows = opts.rows;
        let column_filter: string[] = []
        const columns_array = Object.values(opts.columns);

        let filter_by_col = false
        if (opts.filter == null) {
            column_filter = Object.keys(opts.columns).map(_ => "")
        } else if (what_is_it(opts.filter) != "Array") {
            column_filter = Object.keys(opts.columns).map(_ => (opts.filter as string))
        } else {
            filter_by_col = true
            column_filter = opts.filter as string[]
        }
        column_filter = column_filter.map(f => f == null ? "" : f)


        if (!opts.case_sensitive) {
            column_filter = column_filter.map(f => f.toLowerCase())
        }

        if (!column_filter.every(f => f == "")) {
            // skipp filtering if everything is ""
            rows = rows.filter(row => {
                let any_found = false
                let all_found = true
                for (let column_index = 0; column_index < row.cols.length; ++column_index) {
                    const column = columns_array[column_index]
                    if (column.filterable) {
                        const filter_for_col = column_filter[column_index]
                        if (filter_for_col == "") {
                            any_found = true
                            continue
                        }

                        let string = row.cols[column_index].raw
                        if (!opts.case_sensitive) string = string.toLowerCase()
                        if (string.includes(filter_for_col)) {
                            any_found = true
                        } else {
                            all_found = false
                        }
                    }
                }
                if (filter_by_col) {
                    return all_found
                } else {
                    return any_found;
                }
            })
        }

        let regex_flags = "g"
        if (!opts.case_sensitive) regex_flags = `${regex_flags}i`


        const replace_regex_array = column_filter.map(f => new RegExp(f, regex_flags))
        rows.forEach(item => {
            item.cols.forEach((col, column_index) => {
                if (col.column_definition.filterable) {
                    const filter_string = column_filter[column_index]
                    const replace_regex = replace_regex_array[column_index]
                    if (filter_string != "") {
                        col.html = col.raw.replace(replace_regex, "<span class='filter-highlight'>$&</span>")
                    } else {
                        col.html = col.raw
                    }
                } else {
                    col.html = col.raw
                }
            })
        })
        return rows;
    }

    static order<Record>(columns: RecordColumns<Record>, rows: RecordRow<Record>[], record_order: RecordOrder) {
        // set the order_values
        // if a column does not have order value function, the value is taken from raw
        rows.forEach(row => {
            row.cols.forEach(col => {
                if (col.column_definition.order_value == null) {
                    col.order_value = col.raw
                } else {
                    col.order_value = col.column_definition.order_value(row.record)
                }
            })
        })


        let ordered = rows
        for (let i = record_order.orders.length - 1; i >= 0; --i) {
            const order = record_order.orders[i]
            const column_index = Object.keys(columns).indexOf(order[0])
            ordered = _.orderBy(ordered, (obj) => obj.cols[column_index].order_value, order[1])
        }
        return ordered
    }
}
