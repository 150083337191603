import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { EnumBrowser } from "../../auto_generated/enums";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { BrowserClient } from "../clients/browser_client";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { BrowserScope } from "../scopes/browser_scope";
import { Consoler } from "../../helpers/api_wrappers/consoler";

// <editor-fold desc="TYPES">
export interface BrowserProps extends Props {
    id: number
    name: EnumBrowser
    label: string
    version: string
    driver_version: string
    active: boolean
}

export interface BrowserState extends State {}
export interface BrowserComputed extends Computed {}
export interface BrowserStaticState extends StaticState {}
export type BrowserEmulationProfile = {
    name: string,
    viewport: {
        x: number
        y: number
    },
    resolution: {
        x: number
        y: number
    },
    screen_size: number
    pixel_ratio: number
    density_ppi: number
    css_ppi: number
    user_agent: string
}
// </editor-fold>

const console = new Consoler("warn")
export class Browser extends VueRecord {
    ['constructor']: typeof Browser

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = BrowserClient
    static ScopeClass = BrowserScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: BrowserStaticState = reactive<BrowserStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "active", "name", "version"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Browser> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Browser>> = {}
    static resource_name = Enum.Resource.Label.BROWSER
    static resource_id = Enum.Resource.Id.BROWSER
    static icon_class = "fa-solid fa-cube"
    static color = () => "white"
    static field_validators: ModelValidatorOpts<BrowserProps> = {}
    static emulation_profiles: BrowserEmulationProfile[] = []
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: BrowserClient
    declare props: BrowserProps;
    declare state: BrowserState;
    declare computed: BrowserComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        return generate_resolved_promise()
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    static delete(ids: number | number[]) {
        if (!Array.isArray(ids)) ids = [ids]
        const scope = this.where({ id: ids })
        const content_text = scope.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => {
                return this.ClientClass.delete(ids)
            },
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
Browser.register_resource(Browser)
BrowserClient.ModelClass = Browser
BrowserScope.ModelClass = Browser

on_dom_content_loaded(function() {
    Browser.sync(`/sync/browsers`);
});

if (globalThis.browsers_props) {
    browsers_props.forEach(browser_props => Browser.new(browser_props))
}

if (globalThis.emulation_profiles) {
    Browser.emulation_profiles = emulation_profiles
}


declare global {
    var browsers_props: BrowserProps[]
    var emulation_profiles: BrowserEmulationProfile[]

    interface Window {
        Browser: typeof Browser
    }
}
window.Browser = Browser
// </editor-fold>

