import { VueRecordClient } from "../base/vue_record_client";
import { DelayedJob } from "../models/delayed_job";
import { DelayedJobScope } from "../scopes/delayed_job_scope";
import { DelayedJobProps } from "../models/delayed_job";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class DelayedJobClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof DelayedJob
    declare record: DelayedJob

    static index() {
        return new Promise<DelayedJobScope>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/delayed_jobs`,
                type: 'GET',
                success: (data: DelayedJobProps[]) => {
                    const records: DelayedJob[] = []
                    data.forEach(props => records.push(DelayedJob.new(props)))
                    resolve(DelayedJob.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static delete(ids: number | number[]) {
        return new Promise<boolean>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/delayed_jobs/destroy`,
                type: "DELETE",
                data: {
                    ids,
                    authenticity_token,
                },
                statusCode: ajax_status_codes,
                success: () => {
                    resolve(true)
                },
                error: (e) => {
                    reject(e)
                }
            });
        })
    }
}
