import { generate_eid } from "../generate/generate_eid";


export function attach_codemirror_contextmenu(cm: CodeMirror.Editor, item_build_callback: (cm: CodeMirror.Editor, e: MouseEvent) => ContextMenu.Items) {
    const wrapper = cm.display.wrapper
    const eid = generate_eid()
    wrapper.dataset.contextmenu_id = eid
    $.contextMenu({
        selector: `.CodeMirror[data-contextmenu_id='${eid}']`,
        zIndex: 10,
        build: function($trigger, e: JQuery.MouseDownEvent) {
            const cm = $trigger[0].CodeMirror
            cm.display.input.blur();

            const items = item_build_callback(cm, e.originalEvent as MouseEvent)


            return {
                callback: function() {
                },
                items
            }
        }
    })
}
