import { VueRecord } from "./vue_record";
import { VueRecordScope } from "./vue_record_scope";
import { Consoler } from "../../helpers/api_wrappers/consoler";

export type RequestOptions = {
    url: string
    type: "POST" | "GET" | "DELETE" | "PATCH" | "PUT"
    processData?: boolean,
    contentType?: "application/json" | "multipart/form-data"
    data?: Record<string, any>
    success?: (data: any) => void
    error?: (error: any) => void

    // custom
    cache?: boolean
    force?: boolean
}

export type CacheStoreValue = {
    requested_at: Date
    returned_at: Date
    promise: Promise<any>
}

const console = new Consoler("warn")
export abstract class VueRecordClient {
    static ModelClass: typeof VueRecord
    record: VueRecord
    cache_store: Record<string, CacheStoreValue> = {}
    static cache_store: Record<string, CacheStoreValue> = {}

    constructor(record: VueRecord) {
        this.record = record
    }

    static load<T extends typeof VueRecordClient>(this: T, primary_key: InstanceType<T['ModelClass']>['props'][T['ModelClass']['primary_key']]): Promise<InstanceType<T>['record']> {
        throw new Error(`Implement Load for ${this.ModelClass.resource_id}`)
    }

    static batch_load<T extends typeof VueRecordClient>(this: T, primary_keys: InstanceType<T['ModelClass']>['props'][T['ModelClass']['primary_key']][]): Promise<VueRecordScope> {
        throw new Error(`Implement Batch Load for ${this.ModelClass.resource_id}`)
    }

    key() {
        return this.record.key()
    }

    request<T>(options: RequestOptions) {
        return VueRecordClient.request<T>(options, this)
    }

    static request<T>(options: RequestOptions, client: VueRecordClient = null) {
        const cache = options.cache
        const force = options.force

        const cache_store = client == null ? this.cache_store : client.cache_store

        const cache_id = this.cache_id(options)
        if (force) cache_store[cache_id] = null

        const on_success = options.success
        const on_error = options.error

        if ((cache && cache_store[cache_id] == null) || !cache) {
            console.debug(`executing: ${options.type} ${options.url}. Data: `, options.data)
            let data: any = options.data
            if (options.type != "GET") data.authenticity_token = authenticity_token

            delete options.cache
            delete options.success
            delete options.error
            delete options.force

            if (options.contentType == "application/json") data = JSON.stringify(data)

            const promise = new Promise<T>((resolve, reject) => {
                return $.ajax({
                    ...options,
                    data,
                    success: (response) => {
                        console.debug(`success: ${options.type} ${options.url}. Data: `, options.data, "Response: ", response)
                        if (on_success != null) on_success(response)
                        resolve(response)
                    },
                    error: (e) => {
                        if (on_error != null) on_error(e)
                        reject(e)
                    },
                    statusCode: ajax_status_codes,
                })
            })
            if (cache) {
                cache_store[cache_id] = {
                    promise,
                    requested_at: new Date(),
                    returned_at: null
                }
                promise.catch(() => {
                    cache_store[cache_id] = null
                })
                promise.then(() => {
                    cache_store[cache_id].returned_at = new Date()
                })
            }
            return promise as Promise<T>
        } else {
            console.debug(`waiting: ${options.type} ${options.url}. Data: `, options.data)
            if (on_success != null) cache_store[cache_id].promise.then((data: any) => on_success(data))
            if (on_error != null) cache_store[cache_id].promise.catch((e: any) => on_error(e))
            return cache_store[cache_id].promise as Promise<T>
        }
    }

    private static cache_id(options: RequestOptions) {
        if (options.data != null) {
            return `${options.url}_${hash(options.data)}`
        } else {
            return options.url
        }
    }
}
