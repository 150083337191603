
import { Browser as ScenarioSettingBrowser } from "../../models/scenario_setting/browser";
import { ScenarioSetting } from "../../models/scenario_setting";
import { Browser } from "../../models/browser";

declare module "../../models/scenario_setting/browser" {
    interface Browser {
        scenario_setting: ScenarioSetting
        browser: Browser
    }
}

// belongs_to
ScenarioSettingBrowser.belongs_to('scenario_setting', ScenarioSetting, 'scenario_setting_id')
ScenarioSettingBrowser.belongs_to("browser", Browser, "browser_id")
