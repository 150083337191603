import { reactive } from "vue";
import { InspectorElement } from "./inspector_element";
import { Coords } from "../../../../../types/globals";
import { what_is_it } from "../../../../../helpers/generic/what_is_it";
import { copy_text_to_clipboard } from "../../../../../helpers/generic/copy_to_clipboard";
import { get_css_var } from "../../../../../helpers/generic/get_css_var";
import { computed } from "../../../../../helpers/vue/computed";
import { markRaw } from "vue";

export type AndroidAttributeName = "bounds"
    | "index"
    | "rotation"
    | "height"
    | "width"
    | "checkable"
    | "checked"
    | "clickable"
    | "displayed"
    | "enabled"
    | "focusable"
    | "focused"
    | "long-clickable"
    | "password"
    | "scrollable"
    | "selected"
    | "class"
    | "package"
    | "text"
    | "content-desc"
    | "resource-id"

export type IosAttributeName = "x"
    | "y"
    | "index"
    | "rotation"
    | "height"
    | "width"
    | "enabled"
    | "visible"
    | "type"
    | "value"
    | "label"
    | "name"

export type BaseAttributeName = AndroidAttributeName | IosAttributeName

export type ComputedAttributeName = "top_left" | "bottom_right" | "center" | "xpath"

export type AttributeName = BaseAttributeName | ComputedAttributeName

export type SourceAttributes = Record<BaseAttributeName, string>


export class InspectorElementAttribute<ValueType = any> {
    inspector_element: InspectorElement;
    readonly name: AttributeName;
    readonly source_value: any;
    private readonly is_source_parsed: boolean;

    constructor(inspector_element: InspectorElement, name: AttributeName, value: any, is_parsed: boolean) {
        this.inspector_element = inspector_element
        this.name = name
        this.source_value = value
        this.is_source_parsed = is_parsed;
    }

    static new(
        inspector_element: InspectorElement,
        name: AttributeName,
        value: any,
        is_value_parsed = false
    ): InspectorElementAttribute {
        const thiz = markRaw(new InspectorElementAttribute(inspector_element, name, value, is_value_parsed)) as InspectorElementAttribute
        thiz.init();
        return thiz
    }


    // <editor-fold desc="COMPUTED">
    can_copy: boolean

    display_name: string
    copy_name: string

    value: ValueType
    copy_value: string | number | boolean
    display_value: string
    title_value: string

    value_color: string
    // </editor-fold>

    init() {
        const get_value = () => {
            if (this.is_source_parsed) return this.source_value

            switch (this.name) {
                case "bounds":
                    return this.parse_bounds(this.source_value)
                case "y":
                case "x":
                case "index":
                case "rotation":
                case "height":
                case "width":
                    return parseInt(this.source_value)
                case "checkable":
                case "checked":
                case "clickable":
                case "displayed":
                case "enabled":
                case "focusable":
                case "focused":
                case "long-clickable":
                case "password":
                case "scrollable":
                case "selected":
                case "visible":
                    return this.source_value == "true"
                case "type":
                case "class":
                case "package":
                case "text":
                case "content-desc":
                case "name":
                case "resource-id":
                case "label":
                case "value":
                    return this.parse_string(this.source_value)
                case "top_left":
                case "bottom_right":
                case "center":
                case "xpath":
                    return this.source_value
                default:
                    console.warn(`Unknown inspector element attribute ${this.name}`)
                    return this.source_value
            }
        }
        this.value = get_value() as ValueType

        const get_value_color = () => {
            switch (what_is_it(this.value)) {
                case "Boolean":
                    if (this.value) {
                        return get_css_var("--button-green");
                    } else {
                        return get_css_var("--button-red");
                    }
                case "Number":
                    return get_css_var("--button-blue")
                default:
                    return get_css_var("--font-color");
            }
        }
        this.value_color = get_value_color()

        const get_display_value = () => {
            let text_value: string = null
            let text_parts: string[] = []
            let coords: Coords = null
            let bounds: number[][] = null
            switch (this.name) {
                case "bounds":
                    bounds = this.value as number[][]
                    return `[ [${bounds[0][0]}, ${bounds[0][1]}], [${bounds[1][0]}, ${bounds[1][1]}]]`
                case "type":
                    text_value = this.value as string
                    if (text_value.startsWith("XCUIElementType")) text_value = text_value.replace("XCUIElementType", "")
                    return text_value;
                case "class":
                    if (this.value == null) return ""
                    if (this.inspector_element.inspector.phone.props.phone_type == Enum.Phone.Type.ANDROID) {
                        const parts = (this.value as string).split(".")
                        return parts[parts.length - 1]
                    } else {
                        return this.value.toString()
                    }
                case "resource-id":
                    text_value = this.value as string
                    if (text_value.indexOf(":") !== -1) {
                        text_value = text_value.substring(text_value.indexOf(":") + 4)
                    }
                    return text_value
                case "top_left":
                case "bottom_right":
                case "center":
                    coords = this.value as Coords
                    return `x: ${coords.x}, y: ${coords.y}`
                case "x":
                case "y":
                case "index":
                case "rotation":
                case "height":
                case "width":
                case "checkable":
                case "checked":
                case "clickable":
                case "displayed":
                case "enabled":
                case "focusable":
                case "focused":
                case "long-clickable":
                case "password":
                case "scrollable":
                case "selected":
                case "package":
                case "text":
                case "content-desc":
                case "name":
                case "value":
                case "label":
                case "visible":
                case "xpath":
                    if (this.value == null) return ""
                    return this.value.toString()
                default:
                    console.warn(`Unknown inspector element attribute ${this.name}`)
                    if (this.value == null) return ""
                    return this.value.toString()
            }
        }
        this.display_value = get_display_value()

        const get_title_value = () => {
            if (this.value == null) return null;

            switch (this.name) {
                case "bounds":
                case "top_left":
                case "bottom_right":
                case "center":
                    return this.display_value
                case "x":
                case "y":
                case "index":
                case "rotation":
                case "height":
                case "width":
                case "checkable":
                case "checked":
                case "clickable":
                case "displayed":
                case "enabled":
                case "focusable":
                case "focused":
                case "long-clickable":
                case "password":
                case "scrollable":
                case "selected":
                case "package":
                case "text":
                case "content-desc":
                case "name":
                case "type":
                case "class":
                case "resource-id":
                case "visible":
                case "label":
                case "value":
                case "xpath":
                    return this.source_value
                default:
                    console.warn(`Unknown inspector element attribute ${this.name}`)
                    return this.source_value
            }
        }
        this.title_value = get_title_value()

        const get_copy_value = () => {
            let text_value: string = null
            let coords: Coords = null
            switch (this.name) {
                case "bounds":
                   return ""
                case "resource-id":
                    text_value = this.value as string
                    if (text_value.indexOf(":") !== -1) {
                        text_value = text_value.substring(text_value.indexOf(":") + 4)
                    } else {
                        text_value = `=${text_value}`
                    }
                    return text_value
                case "top_left":
                case "bottom_right":
                case "center":
                    coords = this.value as Coords
                    return `${coords.x}, ${coords.y}`
                case "type":
                case "class":
                    return this.source_value
                case "x":
                case "y":
                case "index":
                case "rotation":
                case "height":
                case "width":
                case "checkable":
                case "checked":
                case "clickable":
                case "displayed":
                case "enabled":
                case "focusable":
                case "focused":
                case "long-clickable":
                case "password":
                case "scrollable":
                case "selected":
                case "package":
                case "text":
                case "content-desc":
                case "name":
                case "value":
                case "label":
                case "visible":
                case "xpath":
                    if (this.value == null) return ""
                    text_value = this.value.toString()
                    return text_value
                default:
                    console.warn(`Unknown inspector element attribute ${this.name}`)
                    if (this.value == null) return ""
                    text_value = this.value.toString()
                    if (what_is_it(this.value) == "String") {
                        text_value = `"${text_value}"`
                    }
                    return text_value
            }
        }
        this.copy_value = get_copy_value();

        const get_can_copy = () => {
            switch (this.name) {
                case "top_left":
                case "bottom_right":
                case "center":
                case "bounds":
                    return false
                case "resource-id":
                case "x":
                case "y":
                case "index":
                case "rotation":
                case "height":
                case "width":
                case "checkable":
                case "checked":
                case "clickable":
                case "displayed":
                case "enabled":
                case "focusable":
                case "focused":
                case "long-clickable":
                case "password":
                case "scrollable":
                case "selected":
                case "package":
                case "text":
                case "content-desc":
                case "name":
                case "type":
                case "class":
                case "visible":
                case "label":
                case "value":
                case "xpath":
                    return true
                default:
                    console.warn(`Unknown inspector element attribute ${this.name}`)
                    return false
            }
        }
        this.can_copy = get_can_copy()

        const get_display_name = () => {
            switch (this.name) {
                case "resource-id":
                    return "id";
                case "content-desc":
                    return "desc";
                case "bounds":
                   return this.name
                case "top_left":
                    return "top-left"
                case "bottom_right":
                    return "bottom-right";
                case "center":
                case "x":
                case "y":
                case "index":
                case "rotation":
                case "height":
                case "width":
                case "checkable":
                case "checked":
                case "clickable":
                case "displayed":
                case "enabled":
                case "focusable":
                case "focused":
                case "long-clickable":
                case "password":
                case "scrollable":
                case "selected":
                case "package":
                case "text":
                case "name":
                case "value":
                case "label":
                case "visible":
                case "class":
                case "type":
                case "xpath":
                    return this.name
                default:
                    console.warn(`Unknown inspector element attribute ${this.name}`)
                    return this.name
            }
        }
        this.display_name = get_display_name()

        const get_copy_name = () => {
            switch (this.name) {
                case "resource-id":
                    return "id";
                case "content-desc":
                    return "desc";
                case "bounds":
                    return this.name
                case "top_left":
                    return "top-left"
                case "bottom_right":
                    return "bottom-right";
                case "center":
                case "x":
                case "y":
                case "index":
                case "rotation":
                case "height":
                case "width":
                case "checkable":
                case "checked":
                case "clickable":
                case "displayed":
                case "enabled":
                case "focusable":
                case "focused":
                case "long-clickable":
                case "password":
                case "scrollable":
                case "selected":
                case "package":
                case "text":
                case "name":
                case "type":
                case "class":
                case "visible":
                case "label":
                case "value":
                case "xpath":
                    return this.name
                default:
                    console.warn(`Unknown inspector element attribute ${this.name}`)
                    return this.name
            }
        }
        this.copy_name = get_copy_name()
    }

    is_filter_match() {
        if (this.source_value == null) return false
        const filter_against = this.prepare_filter_against(this.source_value)
        return this.inspector_element.inspector.prepared_filter_query.every(f => filter_against.some(fa => fa.indexOf(f) !== -1))
    }

    prepare_filter_against(value: any): string[] {
        let filter_against: string[] = []
        switch (what_is_it(value)) {
            case "Array":
                (value as Array<any>).forEach(v => filter_against = filter_against.concat(this.prepare_filter_against(v)))
                break;
            case "Boolean":
            case "String":
            case "Number":
                filter_against.push(value.toString().toLowerCase());
                break;
            case "Object":
                Object.values(value).forEach(v => filter_against = filter_against.concat(this.prepare_filter_against(v)))
                break;
        }

        return filter_against
    }

    copy(as_command: boolean) {
        if (as_command) {
            let val = this.copy_value
            if (what_is_it(this.copy_value) == "String") {
                val = `"${val}"`
            }
            let driver = ""
            switch (this.inspector_element.inspector.phone.props.phone_type) {
                case Enum.Phone.Type.ANDROID:
                    driver = "@d";
                    break;
                case Enum.Phone.Type.IPHONE:
                case Enum.Phone.Type.SIMULATOR:
                    driver = "@i";
                    break;
                default:
                    driver = ""
            }
            copy_text_to_clipboard(`${driver}.element(${this.copy_name}: ${val})`)
        } else {
            copy_text_to_clipboard(this.copy_value.toString())
        }
    }

    // <editor-fold desc="INTERNAL">
    private parse_bounds(bounds_string: string) {
        const match = bounds_string.match(/\[(\d+),(\d+)]\[(\d+),(\d+)]/)
        return [[parseInt(match[1]), parseInt(match[2])], [parseInt(match[3]), parseInt(match[4])]]
        // this.attributes.top_left = {
        //     x: this.attributes.bounds[0][0],
        //     y: this.attributes.bounds[0][1]
        // }
        // this.attributes.bottom_right = {
        //     x: this.attributes.bounds[1][0],
        //     y: this.attributes.bounds[1][1]
        // }
        // this.attributes.width = Math.abs(this.attributes.bottom_right.y - this.attributes.top_left.y)
        // this.attributes.height = Math.abs(this.attributes.bottom_right.x - this.attributes.top_left.x)
    }

    private parse_string(value_string: string) {
        if (value_string?.trim() == "") value_string = null
        return value_string
    }
    // </editor-fold>
}

