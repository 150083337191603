<template>
  <div ref="container"
       class="scenario-editor-container">
    <div v-if="scenario_builder != null"
         class="overlay">
      <div class="left-corner">
        <Loading
            v-if="scenario_builder.computed.loading"
            type="fading_circle"
            color="white"
            :size="1.1"
            margin="4px"
        />
      </div>
      <div class="right-corner">
        <ActionIcon
            margin="0"
            icon_class="fa-solid fa-rotate-left"
            title="Undo"
            :disabled="!scenario_builder.allow_undo()"
            :click_action="() => scenario_builder.undo()"
        />
        <ActionIcon
            margin="0"
            icon_class="fa-solid fa-rotate-right"
            title="Redo"
            :disabled="!scenario_builder.allow_redo()"
            :click_action="() => scenario_builder.redo()"
        />
      </div>
    </div>
    <div ref="canvas_target"
         class="canvas-target no-default-focus"
         tabindex="-1"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { generate_uuid } from "../../../../../helpers/generate/generate_uuid";
import { ScenarioEditorActionItem } from "../../../../../vue_record/models/scenario";
import { ScenarioEditorFeature } from "../../../../../vue_record/models/scenario";
import { ScenarioScope } from "../../../../../vue_record/scopes/scenario_scope";
import { init_cytoscape } from "../../../../../helpers/cytoscape/init_cytoscape";
import { Scenario } from "../../../../../vue_record/models/scenario";
import _ from "lodash";
import { ScenarioBuilder } from "./scenario_builder";
import { ScenarioBuilderTab } from "../../tabs/scenario_builder_tab";
import ActionIcon from "../../../ActionIcon.vue";
import Loading from "../../../Loading.vue";
import { ProjectVersion } from "../../../../../vue_record/models/project_version";

export default defineComponent({
    components: { Loading, ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        scenarios: {
            type: Object as PropType<ScenarioScope>,
            required: true
        },
        tab: {
            type: Object as PropType<ScenarioBuilderTab>,
            required: false,
            default: null
        },
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true
        },
        merged: {
            type: Boolean,
            required: true
        },
        exclude_features: {
            type: Array as PropType<ScenarioEditorFeature[]>,
            required: false,
            default: () => {
                return [] as ScenarioEditorFeature[]
            }
        },
        exclude_actions: {
            type: Array as PropType<ScenarioEditorActionItem[]>,
            required: false,
            default: () => {
                return [] as ScenarioEditorActionItem[]
            }
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            editor: undefined,
            component_id: generate_uuid(),
            scenario_builder: null as ScenarioBuilder,
            cy: null as cytoscape.Core
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        scenario() {
            return this.scenarios.toArray()[0]
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        scenarios: {
            handler(new_scenarios_scope, old_scenarios_scope) {
                if (this.scenario_builder == null) return;

                const new_scenarios = new_scenarios_scope.toArray() as Scenario[]
                const old_scenarios = old_scenarios_scope.toArray() as Scenario[]

                const added = _.differenceBy(new_scenarios, old_scenarios, (obj) => obj.key())
                const removed = _.differenceBy(old_scenarios, new_scenarios, (obj) => obj.key());
                this.scenario_builder.remove_scenarios(removed)
                this.scenario_builder.add_scenarios(added)
            }
        },
        merged() {
            if (this.scenario_builder == null) return;
            this.scenario_builder.set_merged(this.merged);
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const target = this.$refs.canvas_target as HTMLElement
        this.cy = init_cytoscape(target, {})
        this.scenario_builder = new ScenarioBuilder(this.cy as cytoscape.Core, this.project_version, {
            merged: this.merged,
        })

        this.scenario_builder.add_scenarios(this.scenarios.toArray())
        if (this.tab != null) {
            this.tab.state.scenario_builder = this.scenario_builder as ScenarioBuilder;
            this.tab.set_editor_mounted(true);
        }
    },
    unmounted() {
      this.scenario_builder?.destroy_self()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {

    },
    // </editor-fold>
})
</script>

<style lang="scss">
.scenario-scenario-label,
.scenario-snippet-label{
  color: var(--sb-label);
  font-size: 8px;
  width: calc(var(--sb-node-width) - 3px);
  height: var(--sb-node-height);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  white-space: normal;
}
</style>

<style lang="scss" scoped>
.scenario-editor-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .overlay {
    position: absolute;
    pointer-events: none;
    display: flex;
    width: 100%;
    z-index: 5;

    .left-corner {
      pointer-events: auto;
      position: absolute;
      display: flex;
      left: 0
    }

    .right-corner {
      pointer-events: auto;
      display: flex;
      position: absolute;
      right: 0;
    }
  }

  .canvas-target {
    padding-inline: 4.5px; // this is to negate the stick grab area
    width: calc(100% - 9px);
    height: 100%;
  }
}
</style>
