import { what_is_it } from "../../../helpers/generic/what_is_it";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { VueRecordClient } from "../../base/vue_record_client";
import { CacheStoreValue } from "../../base/vue_record_client";
import { LogFile } from "../../models/non_db/log_file";
import { LogFileProps } from "../../models/non_db/log_file";
import { LogFileFolderProps } from "../../models/non_db/log_file_folder";
import { LogFileFolder } from "../../models/non_db/log_file_folder";
import { generate_resolved_promise } from "../../../helpers/generate/generate_resolved_promise";


const console = new Consoler("warn")

export class LogFileClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof LogFile
    declare record: LogFile

    static load(path: string, _reload = false): Promise<LogFile> {
        if (LogFile.is_live(path)) {
            return generate_resolved_promise(LogFile.find(path))
        } else {
            return new Promise<LogFile>((resolve, reject) => {
                $.ajax({
                    url: `/testa/logs/${path}`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: LogFileProps) => {
                        const file = LogFile.new(data)
                        resolve(file)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
        }
    }

    load_partial_content(offset: number, chunk_size: number, tail = false) {
        this.record.state.chunk_data[offset].loading = true

        return new Promise<any>((resolve, reject) => {
            $.ajax({
                url: `/testa/logs/${this.key()}`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    offset,
                    chunk_size,
                    tail
                }),
                statusCode: ajax_status_codes,
                success: (data: { data: string, size: number, extra_bytes: number }) => {
                    console.log(data);
                    this.record.props.size = data.size
                    this.record.state.chunk_data[offset].raw_data = data.data
                    this.record.state.chunk_data[offset].loading = false
                    this.record.state.chunk_data[offset].loaded_at = new Date()
                    this.record.state.chunk_data[offset].loaded = true

                    this.record.event_bus.$emit("chunk_loaded", this.record.state.chunk_data[offset])
                    resolve(data)
                },
                error: (error) => {
                    this.record.state.chunk_data[offset].loading = false
                    reject(error)
                },
            })
        })
    }


    static batch_path(file_paths: string | string[]) {
        let paths: string[];
        if (what_is_it(file_paths) == "Array") {
            paths = file_paths as string[]
        } else {
            paths = [file_paths as string]
        }

        type PathResponse = {
            [key: string]: LogFileFolderProps[]
        }

        type PromiseResponse = {
            [key: string]: LogFileFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            // $.ajax({
            //     url: `/log_files/batch/path`,
            //     type: "POST",
            //     processData: false,
            //     contentType: "application/json",
            //     data: JSON.stringify({
            //         paths,
            //         authenticity_token,
            //     }),
            //     statusCode: ajax_status_codes,
            //     success: (data: PathResponse) => {
            //         const promise_response: PromiseResponse = {}
            //         Object.keys(data).forEach(file_path => {
            //             const log_file_folders_props = data[file_path];
            //             const array: LogFileFolder[] = []
            //             log_file_folders_props.forEach(file_folder_props => {
            //                 array.push(LogFileFolder.new(file_folder_props))
            //             })
            //             promise_response[file_path] = array
            //         })
            //         resolve(promise_response)
            //     },
            //     error: (error) => {
            //         reject(error)
            //     },
            // })
        })
    }

    static batch_download(log_file_folders: LogFileFolder[], log_files: LogFile[]) {
        const search_params = new URLSearchParams({
            log_file_paths: JSON.stringify(log_files.map(f => f.props.path)),
            log_file_folder_paths: JSON.stringify(log_file_folders.map(ff => ff.props.path)),
        })

        return $.fileDownload(`/log_files/batch/download?${search_params.toString()}`)
    }

    download() {
        return $.fileDownload(this.record.computed.download_path)
    }
}
