<template>
  <Modal
      id="confirmation_dialogue"
      ref="modal"
      :focus_confirm="true"
      :show_cancel="show_cancel"
      :show_deny="show_deny"
      :cancel_text="cancel_text"
      :deny_text="deny_text"
      :confirm_text="confirm_text"
      :confirm_color_class="confirm_color_class"
      :deny_color_class="deny_color_class"
      :show_on_mounted="false"
      :cancel_on_escape="true"
      :confirm_action="confirm_action"
      :deny_action="deny_action"
      :dismissible="dismissible"
      :focus_last_active_on_unmount="true"
      @cancel="_cancel"
      @confirm="_confirm"
      @deny="_deny"
      @confirm-success="_confirm_success"
      @confirm-error="_confirm_error"
      @confirm-done="_confirm_success"
      @deny-done="_deny_done"
  >
    <template #header>
      <div class="header">
        {{ title }}
      </div>
    </template>
    <template #body>
      <div class="body"
           :class="{center}"
           v-html="html"
      />
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../Modal.vue";
import { ColorClass } from "../../../types/color_class";
import { ConfirmDialogueOpts } from "./confirm_dialgue";


export default defineComponent({
    components: { Modal },
    // <editor-fold desc="PROPS">
    props: {
        dismissible: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            title: "",
            cancel_text: "",
            show_deny: false,
            show_cancel: true,
            confirm_text: "",
            deny_text: "",
            html: "",
            center: true,
            confirm_color_class: "" as ColorClass,
            deny_color_class: "" as ColorClass,
            cancel_color_class: "" as ColorClass,
            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
            confirm_action: undefined,
            deny_action: undefined
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        show(opts: ConfirmDialogueOpts = {}) {
            this.title = opts.title
            this.confirm_text = opts.confirm_text
            this.deny_text = opts.deny_text
            this.show_cancel = opts.show_cancel;
            this.show_deny = opts.show_deny;
            this.cancel_text = opts.cancel_text;
            this.html = opts.html;
            this.center = opts.center;
            this.confirm_color_class = opts.confirm_color_class;
            this.cancel_color_class = opts.cancel_color_class;
            this.deny_color_class = opts.deny_color_class;
            this.confirm_action = opts.confirm_action;
            this.deny_action = opts.deny_action;

            (this.$refs.modal as typeof Modal).open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        _confirm() {
            if (this.confirm_action == null) {
                this.resolvePromise(true)
                this.$.appContext.app.unmount();
            }
        },

        _deny() {
            if (this.deny_action == null) {
                this.resolvePromise(false)
                this.$.appContext.app.unmount();
            }
        },
        _deny_done() {
            this.resolvePromise(false)
            this.$.appContext.app.unmount();
        },


        _cancel() {
            this.resolvePromise(false)
            this.$.appContext.app.unmount();
            // Or you can throw an error
            // this.rejectPromise(new Error('User canceled the dialogue'))
        },
        _confirm_success() {
            this.resolvePromise(true)
            this.$.appContext.app.unmount();
        },
        _confirm_error(error: Error) {
            this.rejectPromise(error)
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  font-size: 26px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.body {
  padding: 0 15px 10px;
  font-size: 20px;

  &.center {
    text-align: center;
  }
}
</style>
