import { VueRecordScope } from "../base/vue_record_scope";
import { ScenarioHistory } from "../models/scenario_history";
import { computed } from "../../helpers/vue/computed";

export class ScenarioHistoryScope extends VueRecordScope {
    ['constructor']: typeof ScenarioHistoryScope
    static ModelClass: typeof ScenarioHistory
    declare rthis: ScenarioHistoryScope

    undoable = computed(() => this.rthis.where({ reverted: false }))
    redoable = computed(() => this.rthis.where({ reverted: true }))
}
