// require("./codemirror.cjs")
// require("./custom_codemirror_functions.cjs")
// require("./cm-resize.cjs")
// // require("./modes")
// // require("./keymap")
// require("./addons")

require("./addons")
require("./modes")
require("./keymap")
