import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f071345"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "codemirror-other-activity-cursor-names" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, (line) => {
    return (_openBlock(), _createElementBlock("div", {
      key: line,
      class: "codemirror-other-activity-cursor",
      style: _normalizeStyle(_ctx.cursor_style(line))
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.names(line).join(', ')), 1 /* TEXT */)
    ], 4 /* STYLE */))
  }), 128 /* KEYED_FRAGMENT */))
}