<template>
  <div :id="splitter.id"
       class="splitter-container">
    <div
        v-if="display_dnd_drop"
        class="dnd-drop-areas-container">
      <div class="dnd-over-drop-area main"
           @dragenter="(e) => splitter._emit('dragenter', e)"
           @dragover="(e) => splitter._emit('dragover', e)"
           @dragleave="(e) => splitter._emit('dragleave', e)"
           @drop="(e) => splitter._on_drag_drop(splitter, e)"
      />
    </div>
    <RFlex :rflex="rflex">
      <template v-if="splitter.state.main.object != null"
                #main>
        <div :id="splitter.state.main.id"
             style="width: 100%; height: 100%;"
        >
          <template v-if="splitter.state.main.object instanceof Splitter">
            <Splitter :splitter="splitter.state.main.object"/>
          </template>
          <template v-else-if="splitter.state.main.object instanceof TM ">
            <TabManager :tab_manager="splitter.state.main.object"/>
          </template>
        </div>
      </template>

      <template v-if="splitter.state.other.object != null"
                #other>
        <div :id="splitter.state.other.id"
             style="width: 100%; height: 100%;"
        >
          <template v-if="splitter.state.other.object instanceof Splitter">
            <Splitter :splitter="splitter.state.other.object"/>
          </template>
          <template v-else-if="splitter.state.other.object instanceof TM ">
            <TabManager :tab_manager="splitter.state.other.object"/>
          </template>
        </div>
      </template>
    </RFlex>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Splitter } from "./splitter";
import { PropType } from "vue";
import TabManager from "./TabManager.vue";
import { TabManager as TM } from "./tab_manager";
import { ResizableFlex } from "../resizable/resizable_flex/resizable_flex";
import { computed } from "../../../helpers/vue/computed";
import RFlex from "../resizable/resizable_flex/RFlex.vue";
import { filter_records_that_can_be_tabbed } from "../../../vue_record/base/utils/record_tabber";

export default defineComponent({
    components: { RFlex, TabManager },
    // <editor-fold desc="PROPS">
    props: {
        splitter: {
            type: Object as PropType<Splitter>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            rflex: ResizableFlex.new({
                id: `splitter_${this.splitter.id}_rflex`,
                direction: computed(() => this.splitter.state.split == "left_right" ? "row" : "column"),
                areas: [
                    {
                        id: "main",
                        min_ratio_size: 0.1,
                        initial_ratio_size: 0.5,
                        enabled: computed(() => this.splitter.state.main?.object != null)
                    },
                    {
                        id: "other",
                        min_ratio_size: 0.1,
                        initial_ratio_size: 0.5,
                        enabled: computed(() => this.splitter.state.other?.object != null)
                    }
                ]
            })
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        TM() {
            return TM
        },
        Splitter() {
            return Splitter
        },
        display_dnd_drop() {
            const acceptable_records = filter_records_that_can_be_tabbed(dnd.state.records)
            return dnd.state.is_dragging && (dnd.state.tabs.length > 0 || acceptable_records.length > 0) && this.splitter.get_editor().get_tabs().length == 0
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'rflex.areas.main.enabled': {
            handler() {
                this.rflex.resize_evenly()
            },
            immediate: true
        },
        'rflex.areas.other.enabled': {
            handler() {
                this.rflex.resize_evenly()
            },
            immediate: true
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.splitter.set_resizable_flex(this.rflex as ResizableFlex)
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.splitter-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.dnd-drop-areas-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;

  .dnd-over-drop-area {
    outline: none;
    border: 2px solid var(--button-blue);

    &.main {
      height: calc(100% - 4px);
      width: calc(100% - 4px);

    }
  }
}
</style>
