import { PlayGroupsPlayScenarios } from "../../../models/play/bridges/play_groups_play_scenarios";
import { PlayGroup } from "../../../models/play/play_group";
import { PlayScenario } from "../../../models/play/play_scenario";


declare module "../../../models/play/bridges/play_groups_play_scenarios" {
    interface PlayGroupsPlayScenarios {
        play_group: PlayGroup
        play_scenario: PlayScenario
    }
}

PlayGroupsPlayScenarios.belongs_to("play_group", PlayGroup, "play_group_id")
PlayGroupsPlayScenarios.belongs_to("play_scenario", PlayScenario, "play_scenario_id")
