import { computed, reactive } from "vue";
import { Section } from "./section";


type Position = "left" | "right" | "top" | "bottom"
type SectionTogglerInput = {
    id: string,
    start_sections: Section[],
    end_sections: Section[],
    position: Position
}


export class SectionToggler {
    readonly id: string;

    private state = reactive({
        start_sections: [] as Section[],
        end_sections: [] as Section[],
        position: null as Position
    })

    private computed = reactive({
        enabled_sections: [] as Section[],
        shown_sections: [] as Section[],
    })

    constructor(input: SectionTogglerInput) {
        this.id = input.id
        this.state.start_sections = input.start_sections;
        this.state.end_sections = input.end_sections;
        this.state.position = input.position

        this.state.start_sections.forEach(section => section.section_toggler = this)
        this.state.start_sections.forEach(section => section.section_toggler = this)

        const shown_sections = computed(() => {
            const shown_start_sections = this.start_sections().filter(s => s.is_shown())
            const shown_end_sections = this.end_sections().filter(s => s.is_shown())
            return [...shown_start_sections, ...shown_end_sections]
        })

        this.computed.shown_sections = shown_sections as any as Section[]

        this.computed.enabled_sections = computed(() => {
            return shown_sections.value.filter(s => s.is_enabled())
        }) as any as Section[]
    }

    is_vertical() {
        return this.state.position == "left" || this.state.position == "right"
    }

    position() {
        return this.state.position
    }

    start_sections() {
        return this.state.start_sections
    }

    end_sections() {
        return this.state.end_sections
    }

    enabled_sections() {
        return this.computed.enabled_sections
    }

    shown_sections() {
        return this.computed.shown_sections
    }
}
