import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { PlaySandboxClient } from "../../clients/play/play_sandbox_client";
import { PlaySandboxScope } from "../../scopes/play/play_sandbox_scope";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export interface PlaySandboxProps extends Props {
    id: number
    play_worker_group_id: number
    scenario_setting_id: number
    start_time: Date
    end_time: Date
    duration: number
}
export type QuerifiedPlaySandboxProps = QuerifyProps<PlaySandboxProps>
export type PlaySandboxCreateProps = Omit<PlaySandboxProps, 'id'>
export type PlaySandboxUpdateProps = Partial<PlaySandboxProps>

export interface PlaySandboxState extends State {}
export interface PlaySandboxComputed extends Computed {}
export interface PlaySandboxStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PlaySandbox extends VueRecord {
    ['constructor']: typeof PlaySandbox

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlaySandboxClient
    static ScopeClass = PlaySandboxScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlaySandboxStaticState = reactive<PlaySandboxStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_worker_group_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlaySandbox> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlaySandbox>> = {}

    static field_validators: ModelValidatorOpts<PlaySandboxProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_SANDBOX
    static resource_id = Enum.Resource.Id.PLAY_SANDBOX
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlaySandboxClient
    declare props: PlaySandboxProps;
    declare state: PlaySandboxState;
    declare computed: PlaySandboxComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlaySandbox.register_resource(PlaySandbox)
PlaySandboxClient.ModelClass = PlaySandbox
PlaySandboxScope.ModelClass = PlaySandbox


declare global {
    interface Window {
        PlaySandbox: typeof PlaySandbox
    }
}
window.PlaySandbox = PlaySandbox
// </editor-fold>

