export function interpolate_color(start_color: string, end_color: string, factor: number) {
    // Extract RGBA components from start and end colors
    const startRGBA = start_color.match(/\d+(\.\d+)?/g);
    const endRGBA = end_color.match(/\d+(\.\d+)?/g);

    // Interpolate the RGBA components based on the factor
    const interpolatedRGBA = [];
    for (let i = 0; i < 4; i++) {
        const startValue = parseFloat(startRGBA[i]);
        const endValue = parseFloat(endRGBA[i]);
        interpolatedRGBA[i] = startValue + factor * (endValue - startValue);
    }

    // Create the interpolated color string
    return `rgba(${interpolatedRGBA[0]}, ${interpolatedRGBA[1]}, ${interpolatedRGBA[2]}, ${interpolatedRGBA[3]})`;
}
