<template>
  <label ref="label"
         class="input-label"
         :class="{invalid: validator?.invalid}"
         :style="label_style"
  >
    <input
        :id="id"
        ref="input"
        :type="type"
        :min="min"
        :max="max"
        :value="preparsed_value"
        :placeholder="focused || label == '' ? placeholder : ''"
        :disabled="disabled"
        :tabindex="tab_index"
        class="vue-input no-default-unfocus"
        :class="{dirty: preparsed_value, 'no-default-focus': no_focus_border}"
        :data-instance-id="instance_id"
        :style="input_style"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        @input="on_input"
        @focusin="on_focusin"
        @keydown="on_keydown"
        @blur="on_blur"
        @focus="display_validation_error"
    >
    <span class="placeholder"
          :class="{disabled}"
          :style="span_style"
    >
      {{ label }}
    </span>
    <span v-if="show_clear_action && preparsed_value != '' && preparsed_value != null"
          class="clear-action">
      <ActionIcon
          icon_class="fa-solid fa-times"
          color_class="red"
          @click="on_value_updated('')"
      />
    </span>
    <ValidationErrors v-if="show_errors && validator?.invalid"
                      :no_side="errors_no_side"
                      :errors="validator.errors"/>
  </label>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import BlurEvent = JQuery.BlurEvent;
import { PropType } from "vue";
import ValidationErrors from "./ValidationErrors.vue";
import { debounce } from "lodash";
import { throttle } from "lodash";
import KeyDownEvent = JQuery.KeyDownEvent;
import { observe_input_value } from "../../helpers/dom/observe_input_value";
import { KEY } from "../../types/globals";
import { Validator } from "../../helpers/validator/validator";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { delayed_debounce } from "../../helpers/generic/delayed_debounce";
import ActionIcon from "./ActionIcon.vue";
import { CSSProperties } from "vue";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { nextTick } from "vue";

const console = new Consoler("warn")
export default defineComponent({
    components: { ActionIcon, ValidationErrors },
    // <editor-fold desc="PROPS">
    props: {
        modelValue: {
            type: [String, Number],
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        id: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: " ", // must be empty string for default
        },
        type: {
            type: String,
            required: false,
            default: null,
        },
        min: {
            type: Number,
            required: false,
            default: null,
        },
        max: {
            type: Number,
            required: false,
            default: null,
        },
        validator: {
            type: Object as PropType<Validator>,
            required: false,
            default: null,
        },
        delayed_debounce_time: {
            type: Number,
            required: false,
            default: 0
        },
        debounce_time: {
            type: Number,
            required: false,
            default: 0,
        },
        /** Throttle how often should the value update event be emitted. Useful when the value is used
         * to filter large collection of records */
        throttle_time: {
            type: Number,
            required: false,
            default: 0,
        },
        clear_with_esc: {
            type: Boolean,
            required: false,
            default: true,
        },
        focus: {
            type: Boolean,
            required: false,
            default: false
        },
        tab_index: {
            type: [Number, String],
            required: false,
            default: 0
        },
        instance_id: {
            type: String,
            required: false,
            default: generate_uuid()
        },
        select_on_focus: {
            type: Boolean,
            required: false,
            default: false
        },
        scale: {
            type: [Number, String],
            required: false,
            default: 1
        },
        no_round_corners: {
            type: Boolean,
            required: false,
            default: false,
        },
        no_focus_border: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        errors_no_side: {
            type: String as PropType<"top" | "right" | "bottom" | "left">,
            require: false,
            default: null
        },
        show_clear_action: {
            type: Boolean,
            required: false,
            default: false
        },
        autocomplete: {
            type: String,
            required: false,
            default: null
        },
        autofocus: {
            type: String,
            required: false,
            default: null
        }
    },
    // </editor-fold>
    emits: ['update:modelValue', 'enter'],
    // <editor-fold desc="DATA">
    data() {
        return {
            preparsed_value: this.modelValue?.toString(),
            parsed_value: this.modelValue,
            show_errors: false,
            debounced_on_value_update: undefined,
            throttled_on_value_update: undefined,
            delayed_debounced_on_value_update: undefined,
            ignore_next_model_value_update: false,
            focused: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        scale_number(): number {
            if (typeof this.scale == "string") {
                return parseFloat(this.scale)
            } else return this.scale
        },
        /* to ensure that label is shown when input is in focus */
        ensure_margin_top() {
            return !Validator.is_empty(this.label)
        },
        label_style() {
            let marginTop = "0";
            if (this.ensure_margin_top) {
                marginTop = `${this.scale_number * 7}px`
            }
            return {
                marginTop,
                height: `${30 * this.scale_number}px`,
                fontSize: `${14 * this.scale_number}px`,
            }
        },
        input_style() {
            const style: Record<string, string> = {}
            if (this.no_round_corners) style.borderRadius = "0"
            return style
        },
        span_style() {
            const style: CSSProperties = {}
            if ((this.preparsed_value == null || this.preparsed_value == "") && !this.focused) {
                // while there is no input
                // label that is over the input should have transparent background
                // this will make the password keepers autofill more pretty
                style.backgroundColor = "transparent"
            }
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        parsed_value: {
            handler() {
                this.validator?.run(this.parsed_value);
            },
            immediate: true,
        },
        throttle_time: {
            immediate: true,
            handler() {
                this.throttled_on_value_update = throttle(function() {
                    // @ts-ignore
                    this.emit(this.parse_val(this.preparsed_value))
                }, this.throttle_time)
            },
        },
        debounce_time: {
            immediate: true,
            handler() {
                this.debounced_on_value_update = debounce(function() {
                    // @ts-ignore
                    this.emit(this.parse_val(this.preparsed_value))
                }, this.debounce_time)
            },
        },
        delayed_debounce_time: {
            immediate: true,
            handler() {
                this.delayed_debounced_on_value_update = delayed_debounce(function() {
                    console.log("RUNNING DELAYED DEBOUCNE");
                    // @ts-ignore
                    this.emit(this.parse_val(this.preparsed_value))
                }, this.delayed_debounce_time)
            }
        },
        modelValue() {
            if (this.ignore_next_model_value_update) {
                this.ignore_next_model_value_update = false
                return;
            }
            this.preparsed_value = this.modelValue?.toString()
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        // INPUT UPDATES THROUGH JAVASCRIPT input.value = "" is not detected any other way than this custom method
        observe_input_value(this.$refs.input as HTMLElement, (old_value, new_value) => {
            // no need to emit updates when the value has not changed.
            // if removed, it will break datepicker updating modelValue outside datepicker
            if (old_value == new_value) return;
            this.on_value_updated(new_value)
        })
        this.validator?.bind_element(() => {
            return this.$refs.label as HTMLElement
        })
        this.validator?.bind_value(() => {
            return this.parsed_value
        })

        if (this.focus) {
            (this.$refs.input as HTMLElement).focus();
            if (this.select_on_focus) {
                (this.$refs.input as HTMLInputElement).select();
            }
        }
    },
    unmounted() {
        this.validator?.unregister()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_blur(event: BlurEvent) {
            this.focused = false
            this.check_dirty(event);
            this.remove_validation_error();
        },
        on_focusin(_event: FocusEvent) {
            if (this.select_on_focus) {
                (this.$refs.input as HTMLInputElement).select();
            }
            this.focused = true;
        },
        check_dirty(event: BlurEvent) {
            if (event.target.value) {
                event.target.classList.add('dirty');
            } else {
                event.target.classList.remove('dirty');
            }
        },
        on_input(event: InputEvent) {
            this.on_value_updated((event.target as HTMLInputElement).value)
        },
        on_keydown(event: KeyDownEvent) {
            if (this.clear_with_esc && event.keyCode == KEY.ESC && event.target.value != "") {
                this.preparsed_value = ""
                event.stopPropagation();
            }
            if (event.keyCode == KEY.ENTER) {
                this.$emit('enter', event);
                // do not stop propagation, code search enter will break
                // event.stopPropagation();
            }
        },
        display_validation_error() {
            this.show_errors = true
        },
        remove_validation_error() {
            this.show_errors = false
        },
        parse_val(val: string | number | string[]): number | string | Array<number | string> {
            let parsed: any = val
            if (val instanceof Array) {
                parsed = val.map(v => this.parse_val(v) as number | string)
            } else if (this.type == "number" && Validator.is_numeric(val)) {
                if ((val as string).includes(".")) {
                    parsed = parseFloat(val as string)
                } else {
                    parsed = parseInt(val as string)
                }
            }
            this.parsed_value = parsed
            return parsed;
        },
        on_value_updated(value: any) {
            this.preparsed_value = value
            if (this.throttle_time > 0) {
                this.throttled_on_value_update()
            } else if (this.debounce_time > 0) {
                this.debounced_on_value_update()
            } else if (this.delayed_debounce_time > 0) {
                this.delayed_debounced_on_value_update()
            } else {
                this.emit(this.parse_val(this.preparsed_value))
            }
        },
        emit(value: any) {
            this.ignore_next_model_value_update = true
            this.$emit('update:modelValue', value)
            nextTick(() => this.ignore_next_model_value_update = false)
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
label.input-label {
  position: relative;
  min-height: 0;
  // set in js
  //height: 30px;
  //font-size: 14px;
  display: flex;
  $field-inline-padding: 8px;
  width: 100%;

  &:hover,
  &:focus-within {
    filter: brightness(1.075);
  }

  $background-color: var(--ternary-background-color);

  input {
    color: var(--font-color);
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $background-color;
    padding: 0.2em $field-inline-padding;
    border-radius: 5px;
    width: 100%;
    //height: 40px;
    outline: none;
    line-height: normal;
    font-size: 1em;
    height: 100%;
    //transition: border-color 0.3s ease;
    border-width: 1px;
    border-style: solid;

    // HIDE NUMBER ARROWS  Chrome, Safari, Edge, Opera
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // HIDE NUMBER ARROWS Firefox
    &[type=number] {
      -moz-appearance: textfield;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }


  .placeholder {
    background-color: $background-color;
    position: absolute;
    left: $field-inline-padding;
    width: calc(100% - ($field-inline-padding * 2) - 5px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    top: 1em;
    cursor: text;
    line-height: 100%;
    pointer-events: none;
    transform: translateY(-50%);
    transition: top 0.3s ease,
    color 0.3s ease,
    font-size 0.3s ease;

    &.disabled {
      cursor: not-allowed;
    }
  }
  .clear-action {
    top: 2px;
    right: -2px;
    font-size: 1.25em;
    height: calc(100% - 4px);
    position: absolute;
    color: var(--button-red);
  }

  input + .placeholder {
    left: 8px;
    padding: 0 5px;
  }

  // enabled
  input:not(:focus).dirty {
    //transition-delay: 0.1s
  }

  input:not(:placeholder-shown),
  input:focus {
    //transition-delay: 0.1s
  }

  input.dirty + .placeholder,
  input:not(:placeholder-shown) + .placeholder,
  input:focus + .placeholder {
    top: 0;
    font-size: 0.72em;
    width: auto;
    cursor: auto;
  }


  // disabled
  input:disabled.dirty,
  input:disabled:not(:placeholder-shown),
  input:disabled:focus {
    border-color: var(--font-color-secondary);
    transition-delay: 0.1s
  }

  input:disabled.dirty + .placeholder,
  input:disabled:not(:placeholder-shown) + .placeholder,
  input:disabled:focus + .placeholder {
    top: 0;
    font-size: 0.72em;
    color: var(--font-color-secondary);
    width: auto
  }

  // <editor-fold desc="VALID COLORS">
  &:not(.invalid) {

    input {
      border-color: var(--secondary-background-color);
    }

    .placeholder {
      color: var(--font-color-secondary);
    }

    input:not(:focus).dirty {
      border-color: var(--secondary-background-color);
    }

    input:not(:placeholder-shown),
    input:focus {
      //border-color: var(--focus-color);
    }

    input.dirty + .placeholder,
    input:not(:placeholder-shown) + .placeholder,
    input:focus + .placeholder {
      color: var(--font-color);
    }

  }

  // </editor-fold>

  // <editor-fold desc="INVALID COLORS">
  &.invalid {

    input {
      border-color: var(--button-red);
    }

    .placeholder {
      color: var(--button-red);
    }

    input.dirty + .placeholder,
    input:not(:placeholder-shown) + .placeholder,
    input:focus + .placeholder {
      color: var(--button-red)
    }
  }
  // </editor-fold>
}


</style>
