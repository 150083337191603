import { VueRecordScope } from "../base/vue_record_scope";
import { MobileProvisioning } from "../models/mobile_provisioning";
import { computed } from "../../helpers/vue/computed";

export class MobileProvisioningScope extends VueRecordScope {
    ['constructor']: typeof MobileProvisioningScope
    static ModelClass: typeof MobileProvisioning
    declare rthis: MobileProvisioningScope

    not_embedded = computed(() => this.rthis.where({ embedded: false }))
    app_provisionings = computed(() => this.rthis.not_embedded.where({ type: Enum.MobileProvisioning.Type.APP }))
    embedded = computed(() => this.rthis.where({ embedded: true }))
    wda_provisionings = computed(() => this.rthis.not_embedded.where({ type: Enum.MobileProvisioning.Type.WEBDRIVER }))
}
