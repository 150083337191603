type Response = Array<[string, string]>

export function slack_joined_channels() {
    return new Promise<Response>((resolve, reject) => {
        $.ajax({
            url: '/settings/project_settings/integrations/slack/joined_channels',
            type: 'GET',
            success: (data: Response) => resolve(data),
            error: (error) => reject(error),
            statusCode: ajax_status_codes,
        })
    })
}
