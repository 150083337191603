import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "vue_table_container",
  class: "vue-table-container"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = { ref: "tbody" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "vue_thead_container",
      class: "vue-thead-container",
      onWheelPassive: _cache[0] || (_cache[0] = (...args) => (_ctx.on_thead_scroll && _ctx.on_thead_scroll(...args))),
      onScroll: _cache[1] || (_cache[1] = (...args) => (_ctx.on_thead_scroll && _ctx.on_thead_scroll(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["vue-thead", {'underline-header': _ctx.underline_header}])
      }, [
        _renderSlot(_ctx.$slots, "thead")
      ], 2 /* CLASS */)
    ], 544 /* NEED_HYDRATION, NEED_PATCH */),
    _createElementVNode("div", {
      class: "vue-table-scroll",
      onWheelPassive: _cache[2] || (_cache[2] = (...args) => (_ctx.on_table_scroll && _ctx.on_table_scroll(...args))),
      onScroll: _cache[3] || (_cache[3] = (...args) => (_ctx.on_table_scroll && _ctx.on_table_scroll(...args)))
    }, [
      _createElementVNode("table", {
        id: _ctx.id,
        ref: "table",
        class: "vue-table"
      }, [
        _createElementVNode("thead", null, [
          _renderSlot(_ctx.$slots, "thead")
        ]),
        _createElementVNode("tbody", _hoisted_3, [
          _renderSlot(_ctx.$slots, "tbody")
        ], 512 /* NEED_PATCH */),
        _createElementVNode("tfoot", null, [
          _renderSlot(_ctx.$slots, "tfoot")
        ])
      ], 8 /* PROPS */, _hoisted_2)
    ], 32 /* NEED_HYDRATION */)
  ], 512 /* NEED_PATCH */))
}