import { createApp } from "vue";
import { ColorClass } from "../../../types/color_class";
import ConfirmDialogueVue from "./ConfirmDialogueVue.vue";
import { v_moment } from "../../directives/v-moment";
import { v_time } from "../../directives/v-time";
import { v_date } from "../../directives/v-date";
import { v_duration } from "../../directives/v-duration";
import _ from "lodash";
import { v_datetime } from "../../directives/v-datetime";
import { ButtonAction } from "../../../types/components/testa/button_action";

export interface ConfirmDialogueOpts {
    title?: string
    cancel_text?: string
    deny_text?: string
    confirm_text?: string,
    html?: string
    center?: boolean
    confirm_color_class?: ColorClass
    cancel_color_class?: ColorClass
    deny_color_class?: ColorClass
    show_cancel?: boolean
    show_deny?: boolean
    confirm_action?: ButtonAction,
    deny_action?: ButtonAction,
    dismissible?: boolean
}

export default class ConfirmDialogue {
    static default_opts: ConfirmDialogueOpts = {
        title: "Are you sure?",
        cancel_text: "Cancel",
        confirm_text: "OK",
        html: "",
        center: true,
        confirm_color_class: "default",
        cancel_color_class: "grey",
        deny_color_class: "grey",
        show_cancel: true,
        show_deny: false,
        confirm_action: null,
        dismissible: true,
    }

    static show(opts: ConfirmDialogueOpts = {}): Promise<Boolean> {
        opts = _.merge(_.cloneDeep(this.default_opts), opts)
        const app = createApp(ConfirmDialogueVue as any, { dismissible: opts.dismissible })
        app.directive('moment', v_moment)
        app.directive('time', v_time)
        app.directive('date', v_date)
        app.directive('datetime', v_datetime)
        app.directive('duration', v_duration)
        // @ts-ignore
        const promise = app.mount(document.getElementById("confirmation_dialogues")).$root.show(opts)
        promise.then(() => {
            app.unmount()
        })
        return promise
    }
}
