import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Proxy } from "../models/proxy"
import toastr from "toastr";
import { ProxyCreateProps } from "../models/proxy";
import { ProxyUpdateProps } from "../models/proxy";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ProxyClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Proxy
    declare record: Proxy

    static create(proxy: ProxyCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/proxies/`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    proxy,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(proxy_params: ProxyUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/proxies/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    proxy: proxy_params
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static test(proxy_props: ProxyUpdateProps | ProxyCreateProps, show_notification = false) {
        interface Response {
            success: boolean
        }

        let notification: JQuery;
        if (show_notification) {
            notification = toastr.info("Testing...", null, {
                timeOut: 6000,
                progressBar: true,
            })
        }
        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/proxies/test`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    proxy: proxy_props,
                }),
                success(test: Response) {
                    resolve(test)
                    if (!show_notification) return;
                    notification.remove();
                    if (test.success) {
                        toastr.success("Proxy is working!")
                    } else {
                        toastr.error("Proxy is not working!")
                    }
                },
                error(error) {
                    reject(error)
                    if (!show_notification) return;
                    notification.remove();
                    toastr.error("Proxy is not working!")
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static delete(ids: number | number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/proxies/destroy`,
                type: "DELETE",
                data: {
                    ids,
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }
}
