import { AppiumScreenControl } from "./appium_screen_control";
import { KEY } from "../../../types/globals";
import { send_ws } from "../../generic/send_ws";
import { AscAction } from "./asc_action";

type KeyAction = {
    down: boolean,
    character: string,
    special: string
}

export class AscKeyboardAction extends AscAction {
    type = Enum.Play.ScreenAction.KEYBOARD;
    keys: KeyAction[] = []

    static no_handle_key = {
        20: "capslock",
        93: "contextmenu",
        144: "numlock",
        145: "scroll",
    }

    static perform_now_key = [
        KEY.ENTER, // return
        KEY.ESC, // escape
        KEY.SPACE, // space
        KEY.DEL, // del
    ]

    specialKeys = {
        8: "backspace",
        9: "tab",
        10: "return",
        13: "return",
        16: "shift",
        17: "control",
        18: "alt",
        19: "pause",
        27: "escape",
        33: "page_up",
        34: "page_down",
        35: "end",
        36: "home",
        37: "left",
        38: "up",
        39: "right",
        40: "down",
        45: "insert",
        46: "delete",
        59: "semicolon",
        61: "equals",
        96: "numpad0",
        97: "numpad1",
        98: "numpad2",
        99: "numpad3",
        100: "numpad4",
        101: "numpad5",
        102: "numpad6",
        103: "numpad7",
        104: "numpad8",
        105: "numpad9",
        106: "multiply",
        107: "add",
        109: "subtract",
        110: "separator",
        111: "divide",
        112: "f1",
        113: "f2",
        114: "f3",
        115: "f4",
        116: "f5",
        117: "f6",
        118: "f7",
        119: "f8",
        120: "f9",
        121: "f10",
        122: "f11",
        123: "f12",
        173: "subtract",
        186: "semicolon",
        187: "equals",
        189: "subtract",
        190: "separator",
        191: "divide",
    }

    asc: AppiumScreenControl

    constructor(asc: AppiumScreenControl, keycode: number, shift: boolean, caps_lock: boolean) {
        super()
        this.asc = asc;
        this.add_key(keycode, true, shift, caps_lock)
    }

    perform() {
        const data = {
            udid: this.asc.phone.props.udid,
            action: Enum.Play.Action.SCREEN_CONTROL,
            screen_action: {
                type: this.type,
                keys: this.keys
            }
        };

        send_ws(this.asc.backend_ws_channel, data)
    }

    add_key(keycode: number, down: boolean, shift: boolean, caps_lock: boolean) {
        let character = String.fromCharCode(keycode).toLowerCase();
        if ((shift && !caps_lock) || (!shift && caps_lock)) character = character.toUpperCase();
        // @ts-ignore
        const special = this.specialKeys[keycode];
        this.keys.push(this.generate_object(character, special, true));
        this.keys.push(this.generate_object(character, special, false));

        // if (down) {
        //     this.keys.push(this.generate_object(character, special, down));
        // } else {
        //     // only add up keys that already have down key
        //     if (this.keys.some(k => k.down && k.character == character)) {
        //         this.keys.push(this.generate_object(character, special, down));
        //     }
        // }

        if (AscKeyboardAction.perform_now_key.includes(keycode)) {
            this.asc.perform_action();
        }
    }

    generate_object(character: string, special: string, down: boolean): KeyAction {
        return { down, character, special }
    }
}
