import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col s4" }
const _hoisted_3 = { class: "col s4" }
const _hoisted_4 = { class: "col s4" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col s4" }
const _hoisted_7 = { class: "col s4" }
const _hoisted_8 = { class: "col s4" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col s4" }
const _hoisted_11 = { class: "col s4" }
const _hoisted_12 = { class: "col s4" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col s4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_SettingsItem = _resolveComponent("SettingsItem")

  return (_openBlock(), _createBlock(_component_SettingsItem, {
    id: "modules",
    title: "Modules"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Checkbox, {
            id: "web_module_checkbox",
            modelValue: _ctx.project_version_setting_update.web_module_enabled,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.project_version_setting_update.web_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Web / Browsers"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Checkbox, {
            id: "groups_module_checkbox",
            modelValue: _ctx.project_version_setting_update.group_module_enabled,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.project_version_setting_update.group_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Groups"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Checkbox, {
            id: "sikuli_module_checkbox",
            modelValue: _ctx.project_version_setting_update.sikuli_module_enabled,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.project_version_setting_update.sikuli_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Images"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Checkbox, {
            id: "mobile_module_checkbox",
            modelValue: _ctx.project_version_setting_update.android_module_enabled,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.project_version_setting_update.android_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Mobile / Phones"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Checkbox, {
            id: "jira_module_checkbox",
            modelValue: _ctx.project_version_setting_update.jira_module_enabled,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.project_version_setting_update.jira_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Jira"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Checkbox, {
            id: "vcs_module_checkbox",
            modelValue: _ctx.project_update.vcs_module_enabled,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.project_update.vcs_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "VCS"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Checkbox, {
            id: "schedules_module_checkbox",
            modelValue: _ctx.project_version_setting_update.schedule_module_enabled,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.project_version_setting_update.schedule_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Schedules"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Checkbox, {
            id: "proxies_module_checkbox",
            modelValue: _ctx.project_version_setting_update.proxies_module_enabled,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.project_version_setting_update.proxies_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Proxies"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_Checkbox, {
            id: "files_module_checkbox",
            modelValue: _ctx.project_version_setting_update.file_module_enabled,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.project_version_setting_update.file_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Files"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_Checkbox, {
            id: "code_lint_module_checkbox",
            modelValue: _ctx.project_version_setting_update.code_lint_module_enabled,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.project_version_setting_update.code_lint_module_enabled) = $event)),
            disabled: _ctx.is_viewer_role || _ctx.is_user_role,
            label: "Code Lint"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}