import { Splitter } from "./splitter";
import { TabManager } from "./tab_manager";
import { Editor } from "./editor";
import { nextTick } from "vue";

export namespace SplitterRegion {
    export type Input = {
        id: string
        is_main: boolean
        splitter: Splitter,
        object: TabManager | Splitter
    }
}
export class SplitterRegion {
    id: string
    splitter: Splitter;
    is_main: boolean;
    object: TabManager | Splitter;

    constructor(data: SplitterRegion.Input) {
        this.id = data.id
        this.is_main = data.is_main
        this.splitter = data.splitter
        this.object = data.object
    }

    // <editor-fold desc="ACTIONS">
    set_width(size: number) {
        const area = this.is_main ? "main" : "other"
        this.set_size(area, size)
    }

    set_height(size: number) {
        const area = this.is_main ? "main" : "other"
        this.set_size(area, size)
    }

    set_size(area: string, size: number) {
        if (this.splitter.resizable_flex == null) {
            nextTick(() => {
                if (this.splitter.resizable_flex == null) {
                    console.error("Splitter resizable flex is null")
                    return
                }
                this.splitter.resizable_flex.areas[area].set_size(size)
            })
        } else {
            this.splitter.resizable_flex.areas[area].set_size(size)
        }

    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    get_up_tab_manager(look_in_parents: boolean) {
        const region = this.get_up_region(look_in_parents)?.object

        if (region instanceof TabManager) return region;
        if (region instanceof Splitter) return region._find_a_tab_manager();
    }

    get_right_tab_manager(look_in_parents: boolean) {
        const region = this.get_right_region(look_in_parents)?.object

        if (region instanceof TabManager) return region;
        if (region instanceof Splitter) return region._find_a_tab_manager();
    }

    get_down_tab_manager(look_in_parents: boolean) {
        const region = this.get_down_region(look_in_parents)?.object

        if (region instanceof TabManager) return region;
        if (region instanceof Splitter) return region._find_a_tab_manager();
    }

    get_left_tab_manager(look_in_parents: boolean) {
        const region = this.get_left_region(look_in_parents)?.object

        if (region instanceof TabManager) return region;
        if (region instanceof Splitter) return region._find_a_tab_manager();
    }

    get_up_region(look_in_parents: boolean): SplitterRegion {
        let region;
        if (!this.is_main && this.splitter.state.split == "top_bottom") {
            region = this.splitter.state.main;
        } else if (look_in_parents) {
            if (this.splitter.parent instanceof Editor) return null;
            region = this.splitter.parent.get_up_region(look_in_parents);
        }
        return region;
    }

    get_right_region(look_in_parents: boolean): SplitterRegion {
        let region;
        if (this.is_main && this.splitter.state.split == "left_right") {
            region = this.splitter.state.other;
        } else if (look_in_parents) {
            if (this.splitter.parent instanceof Editor) return null;
            region = this.splitter.parent.get_right_region(look_in_parents);
        }
        return region;
    }

    get_down_region(look_in_parents: boolean): SplitterRegion {
        let region;
        if (this.is_main && this.splitter.state.split == "top_bottom") {
            region = this.splitter.state.other;
        } else if (look_in_parents) {
            if (this.splitter.parent instanceof Editor) return null;
            region = this.splitter.parent.get_down_region(look_in_parents);
        }
        return region;
    }

    get_left_region(look_in_parents: boolean): SplitterRegion {
        let region;
        if (!this.is_main && this.splitter.state.split == "left_right") {
            region = this.splitter.state.main;
        } else if (look_in_parents) {
            if (this.splitter.parent instanceof Editor) return null;
            region = this.splitter.parent.get_left_region(look_in_parents);
        }
        return region;
    }

    opposite_region() {
        if (this.is_main) {
            return this.splitter.state.other
        } else {
            return this.splitter.state.main
        }
    }

    first_parent_opposite(): SplitterRegion {
        const opposite = this.opposite_region();
        if (opposite.object == null) {
            const parent = this.splitter.parent
            if (parent instanceof Editor) return null
            return parent.first_parent_opposite()
        }
        return opposite
    }

    get_editor() {
        return this.splitter.get_editor()
    }

    get_html_element(): HTMLElement {
        return document.getElementById(this.id)
    }
    // </editor-fold>
}
