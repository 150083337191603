import { Tab } from "../tab";
import { markRaw } from "vue";
import { reactive } from "vue";
import { WatchStopHandle } from "vue";
import CodeMirror from "codemirror";
import { watch } from "vue";
import { LogFile } from "../../../../vue_record/models/non_db/log_file";
import LogViewer from "../editors/logs/LogViewer.vue";
import { computed } from "../../../../helpers/vue/computed";

namespace LogFileTab {
    export interface Input extends Tab.Input {
        log_file_path: string
    }

    export interface State extends Tab.State {
        record: LogFile,
        cm: CodeMirror.Editor
        key_change_watcher: WatchStopHandle
    }
}

export class LogFileTab extends Tab {
    static type = "LogFileTab"

    type: string
    log_file_path: string

    declare state: LogFileTab.State

    constructor(data: LogFileTab.Input) {
        super(data)
        this.type = LogFileTab.type
        this.log_file_path = data.log_file_path;
        this.state.icon.class = "fa-regular fa-file"
        this.component = markRaw(LogViewer);

        // if title is known, set it before we load, so that tabs are sorted correctly
        const log_file = LogFile.find(this.log_file_path)
        if (log_file != null) {
            this.computed.title = computed(() => log_file.props.name)
        }

        this._set_and_call_load_function(() => {
            return LogFile.ClientClass
                       .load(this.log_file_path)
                       .then(log_file => {
                           this.state.record = log_file

                           this.computed.title = computed(() => log_file.props.name)
                           this.component_props = { log_file }
                           this.state.key_change_watcher = watch(() => log_file.props.path, () => {
                               this.id = log_file._log_file_tab_id()
                               this.input.id = this.id
                               this.log_file_path = log_file.key();
                               this.input.log_file_path = log_file.key();
                           })

                           this.on("before_close", () => this.state.key_change_watcher())
                       })
                       .catch((error) => {
                           this._on_load_error(error)
                       })
                       .finally(() => {
                           this.set_loaded(true)
                       })
        })
        this.dnd = reactive({})
    }
}

Tab.register_tab_type(LogFileTab)
