import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { PlayWorkerGroup } from "../../models/play/play_worker_group";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PlayWorkerGroupClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlayWorkerGroup
    declare record: PlayWorkerGroup
}
