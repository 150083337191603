<template>
  <div class="title-container">
    <div class="title-primary-info">
      <div class="name">
        <span>{{ schedule.props.name }}</span>
      </div>
    </div>
    <div class="title-secondary-info">
      <span :style="schedule.props.status == 'Finished' ? `color: ${get_css_var('--play-color')}` : ''">
        {{ schedule.props.status }}
      </span>
      <span> - </span>
      <span v-moment="schedule.props.next_run_time"/>
      <template v-if="schedule.props.run_type != 'Once'">
        <span> - </span>
        <span>
          {{ schedule.props.run_type }}
        </span>
      </template>
    </div>
    <div class="title-secondary-info">
      <span>By {{ scheduled_by }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Schedule } from "../../vue_record/models/schedule";
import { get_css_var } from "../../helpers/generic/get_css_var";

export default defineComponent({
    components: { },
    // <editor-fold desc="PROPS">
    props: {
        schedule: {
            type: Object as PropType<Schedule>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        scheduled_by() {
            const user = this.schedule.user
            if (user == null) return "unknown user"
            return user.props.email
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        get_css_var,
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  padding-top: 2px;
  padding-bottom: 2px;

  .title-primary-info {
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
    align-items: baseline;

    .play-type-icon-container {
      font-size: 0.9em;
      margin-right: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    div.name {
      color: var(--font-color);
      margin-right: 5px;
      white-space: nowrap;
      flex-shrink: 0;

      span.highlight {
        background: var(--sidebar-selected-contrast);
      }
    }

    div.target {

    }
  }

  .title-secondary-info {
    color: var(--font-color-secondary);
    font-size: 0.9em;
  }
}
</style>
