import { ScenarioHistory } from "../models/scenario_history";
import { Scenario } from "../models/scenario";

declare module "../models/scenario_history" {
    interface ScenarioHistory {
        scenario: Scenario
    }
}

ScenarioHistory.belongs_to("scenario", Scenario, "scenario_id")
