import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { UserProject } from "../models/user_project";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class UserProjectClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof UserProject
    declare record: UserProject
}
