import { watch } from "vue";

export function on_user_load(callback: () => void) {
    return watch(() => current.user?.key(),
        (new_user_id) => {
            if (new_user_id != null) {
                try { callback() } catch (e) { console.error(e) }
            }
        },
        {
            immediate: true,
            flush: "sync"
        }
    )
}
