<template>
  <div class="container">
    <div class="row align-start">
      <div class="col s12 usages-target">
        <div class="label-name">
          {{ label_name }}
        </div>
        <template v-if="snippet != null">
          <i :class="Snippet.icon_class"
             class="target-icon"
             :style="`color: ${Snippet.color()};`"
          />
          <div>{{ snippet.name() }}</div>
        </template>
        <template v-else-if="scenario != null">
          <i :class="Scenario.icon_class"
             class="target-icon"
             :style="`color: ${Scenario.color()};`"
          />
          <div>{{ scenario.name() }}</div>
        </template>
        <template v-else-if="group != null">
          <i :class="Group.icon_class"
             class="target-icon"
             :style="`color: ${Group.color()};`"
          />
          <div>{{ group.name() }}</div>
        </template>
      </div>
    </div>
    <div v-if="usages_tree != null"
         class="row">
      <div class="col s12">
        <Tree
            :tree="usages_tree"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { UsagesTab } from "../../tabs/usages_tab";
import { Scenario } from "../../../../../vue_record/models/scenario";
import { Group } from "../../../../../vue_record/models/group";
import { Snippet } from "../../../../../vue_record/models/snippet";
import Tree from "../../../tree/Tree.vue";
import { computed_ref } from "../../../../../helpers/vue/computed";
import { ScenarioFolder } from "../../../../../vue_record/models/scenario_folder";
import { GroupFolder } from "../../../../../vue_record/models/group_folder";

import { TestaTree } from "../../../tree/tree";

export default defineComponent({
    components: { Tree },
    // <editor-fold desc="PROPS">
    props: {
        snippet: {
            type: Object as PropType<Snippet>,
            required: false,
            default: null
        },
        scenario: {
            type: Object as PropType<Scenario>,
            required: false,
            default: null
        },
        group: {
            type: Object as PropType<Group>,
            required: false,
            default: null
        },
        tab: {
            type: Object as PropType<UsagesTab>,
            required: false,
            default: null
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            usages_tree: null,
            find_scenario_usages_promise: null,
            find_group_usages_promise: null,
            load_promise: null,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Snippet() {
            return Snippet
        },
        Scenario() {
            return Scenario
        },
        Group() {
            return Group
        },
        record() {
            if (this.snippet != null) return this.snippet
            if (this.scenario != null) return this.scenario
            if (this.group != null) return this.group
            return null
        },
        label_name() {
            if (this.snippet != null) {
                return "Snippet:";
            } else if (this.scenario != null) {
                return "Scenario:";
            } else if (this.group != null) {
                return "Group:";
            } else {
                return "Unknown";
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const id = `usages_tree_${this.record?.constructor?.name}_${this.record?.key()}`
        const project_version = this.record?.project_version
        const project_version_id = project_version?.key()
        if (this.snippet != null) {
            this.load_promise = this.snippet.client.usages()
        } else if (this.scenario != null) {
            this.load_promise = this.scenario.client.usages()
        }
        this.usages_tree = new TestaTree.Tree(id, project_version, computed_ref(() => {
            const root_nodes: TestaTree.NodeInput<any, any, any>[] = [
                ScenarioFolder.usages_testa_tree_node_data(project_version_id, this.load_promise),
                GroupFolder.usages_testa_tree_node_data(project_version_id, this.load_promise),
            ]
            return root_nodes
        }), false)
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {

    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  padding-top: 10px;
  flex-direction: column;

  .usages-target {
    align-items: baseline;

    .label-name {
      color: var(--font-color-secondary);
      font-size: 0.8em;
      margin-right: 5px;
    }

    .target-icon {
      font-size: 0.9em;
      margin-inline: 5px;
    }
  }
}
</style>
