import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumXrayAutomaticReportGeneration } from "../../auto_generated/enums";
import { EnumLanguage } from "../../auto_generated/enums";
import { ProjectVersionSettingClient } from "../clients/project_version_setting_client";
import { ProjectVersionSettingScope } from "../scopes/project_version_setting_scope";
import { on_relations_established } from "../../helpers/events/on_relations_established";
import { computed } from "vue";

// <editor-fold desc="TYPES">
interface XrayProps extends Props {
    xray_enabled: boolean
    xray_project_keys: string[]
    xray_client_id: string
    xray_client_secret: string
    xray_automatic_report_generation: EnumXrayAutomaticReportGeneration
}

interface MobileProps extends Props {
    appium_command_timeout: number
}

interface WebProps extends Props {
    pageload_timeout: number
    watir_default_timeout: number
}

interface ModuleProps extends Props {
    jira_module_enabled: boolean
    schedule_module_enabled: boolean
    file_module_enabled: boolean
    sikuli_module_enabled: boolean
    android_module_enabled: boolean
    group_module_enabled: boolean
    web_module_enabled: boolean
    proxies_module_enabled: boolean
    code_lint_module_enabled: boolean
}

export interface ProjectVersionSettingProps extends Props, XrayProps, MobileProps, WebProps, ModuleProps {
    id: number
    project_version_id: number
    variables: string
    http_proxy: string
    https_proxy: string
    no_proxy: string
    test_apk_id: number
    demo_apk_id: number
    preprod_apk_id: number
    appium_no_reset: boolean
    automation_engine: string
    test_url: string
    demo_url: string
    preprod_url: string
    default_language: EnumLanguage
    code_lint_enabled: boolean
}
export type QuerifiedProjectVersionSettingProps = QuerifyProps<ProjectVersionSettingProps>
export type ProjectVersionSettingCreateProps = Omit<ProjectVersionSettingProps, 'id'>
export type ProjectVersionSettingUpdateProps = Partial<ProjectVersionSettingProps>

export type XrayUpdateProps = Partial<XrayProps>
export type MobileUpdateProps = Partial<MobileProps>
export type WebUpdateProps = Partial<WebProps>
export type ModuleUpdateProps = Partial<ModuleProps>

export interface ProjectVersionSettingState extends State {}
export interface ProjectVersionSettingComputed extends Computed {}
export interface ProjectVersionSettingStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class ProjectVersionSetting extends VueRecord {
    ['constructor']: typeof ProjectVersionSetting

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ProjectVersionSettingClient
    static ScopeClass = ProjectVersionSettingScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ProjectVersionSettingStaticState = reactive<ProjectVersionSettingStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ProjectVersionSetting> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ProjectVersionSetting>> = {}

    static field_validators: ModelValidatorOpts<ProjectVersionSettingProps> = {
        xray_client_id: { presence: true },
        xray_client_secret: { presence: true },
        xray_project_keys: { presence: true },
        xray_automatic_report_generation: { presence: true, inclusion: Enum.XrayAutomaticReportGeneration.values },
        appium_command_timeout: { min: 0, max: 300, presence: true, numerical: true },
        pageload_timeout: { min: 0, max: 300, presence: true, numerical: true },
        watir_default_timeout: { min: 0, max: 300, presence: true, numerical: true },
    }

    static resource_name = Enum.Resource.Label.PROJECT_VERSION_SETTING
    static resource_id = Enum.Resource.Id.PROJECT_VERSION_SETTING
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ProjectVersionSettingClient
    declare props: ProjectVersionSettingProps;
    declare state: ProjectVersionSettingState;
    declare computed: ProjectVersionSettingComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
ProjectVersionSetting.register_resource(ProjectVersionSetting)
ProjectVersionSettingClient.ModelClass = ProjectVersionSetting
ProjectVersionSettingScope.ModelClass = ProjectVersionSetting

on_relations_established(() => {
    current.project_version_setting = computed(() => current.project_version?.project_version_setting) as any as ProjectVersionSetting
})

if (globalThis.current_project_version_setting_props?.id) {
    ProjectVersionSetting.new(current_project_version_setting_props)
}


if (globalThis.project_version_settings_props) {
    project_version_settings_props.forEach(project_version_setting_props => ProjectVersionSetting.new(project_version_setting_props))
}

declare global {
    var current_project_version_setting_props: ProjectVersionSettingProps
    var project_version_settings_props: ProjectVersionSettingProps[]

    interface Window {
        ProjectVersionSetting: typeof ProjectVersionSetting
    }

    interface Current {
        project_version_setting: ProjectVersionSetting
    }
}
window.ProjectVersionSetting = ProjectVersionSetting
// </editor-fold>

