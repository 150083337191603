<template>
  <Modal
      id="play_modal"
      ref="modal"
      :is_loading="!loaded"
      :show_cancel="true"
      :confirm_text="confirm_text"
      :initial_width="modal_width"
      :form_validator="form_validator"
      min_width="300px"
      :confirm_action="confirm"
      :cancel_on_escape="true"
      resize="horizontal"
      @cancel="cancel"
  >

    <!--<editor-fold desc="HEADER">-->
    <template v-if="loaded"
              #header>
      <div class="header">
        <div
            class="scenarios-count"
            title="Number of scenarios"
        >
          {{ scenario_count }}
        </div>
        <div class="title">
          <ContentEditable
              v-model="play_setting.name"
              :validator="form_validator.register('title', {length: {min: 1, max: 200}})"
          />
        </div>
        <div class="modal-complexity-switcher">
          <Checkbox
              v-model="play_setting.simple_modal"
              label="Simple"
          />
        </div>
      </div>
    </template>
    <!--</editor-fold>-->

    <!--<editor-fold desc="BODY">-->
    <template v-if="loaded"
              #body>
      <div
          class="body"
          :class="{'advanced-play': advanced_settings}">

        <template v-if="simple_modal">
          <!--<editor-fold desc="WEB">-->
          <div v-if="has_web"
               class="grouping">
            <div class="row align-center">
              <div class="reduced-inline-row">
                <div :class="`col ${browser_type_cols}`">
                  <Select2 v-model="simple_browser_type"
                           dropdown_parent="#play_modal"
                           label="Browser"
                           :validator="form_validator.register(`simple_browser_type`, {presence: true})"
                  >
                    <option v-for="bt in Enum.Browser.values"
                            :key="bt"
                            :value="bt">
                      {{ browser_name(bt) }}
                    </option>
                  </Select2>
                </div>
                <div :class="`col ${browser_id_cols}`">
                  <Select2
                      v-model="simple_browser_id"
                      label="Version"
                      dropdown_parent="#play_modal">
                    <option value="">
                      Latest
                    </option>
                    <option v-for="browser in browsers[simple_browser_type]"
                            :key="browser.props.id"
                            :value="browser.props.id"
                    >
                      {{ browser.props.label }}
                    </option>
                  </Select2>
                </div>
                <div v-if="advanced_settings"
                     class="col s3"
                     style="padding-top: 13px"
                >
                  <Input v-model="simple_browser_width"
                         type="number"
                         placeholder="Width"
                         :min="100"
                         :max="Enum.Scenario.Setting.Browser.MAX_WIDTH"
                         :validator="form_validator.register(`simple_browser_width`,{min: 100, max: Enum.Scenario.Setting.Browser.MAX_WIDTH})"
                  />
                </div>
                <div v-if="advanced_settings"
                     class="col s3"
                     style="padding-top: 13px"
                >
                  <Input v-model="simple_browser_height"
                         type="number"
                         placeholder="Height"
                         :min="100"
                         :max="Enum.Scenario.Setting.Browser.MAX_HEIGHT"
                         :validator="form_validator.register(`simple_browser_height`, {min: 100, max: Enum.Scenario.Setting.Browser.MAX_HEIGHT})"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--</editor-fold>-->

          <!--<editor-fold desc="MOBILE">-->
          <div v-if="has_mobile"
               class="grouping">
            <div v-if="Object.keys(grouped_phones).length > 1"
                 class="row"
                 style="color: var(--button-orange); justify-content: center; font-size: 0.9em;">
              Group has more than one device type, all scenarios will be executed on selected type!
            </div>
            <div class="row align-center">
              <div class="reduced-inline-row">
                <div class="col s3">
                  <Select2
                      v-model="simple_mobile_type"
                      dropdown_parent="#play_modal"
                      label="Type"
                      :template_result="(state) => Phone.phone_type_option_template(state)"
                      :template_selection="(state) => Phone.phone_type_option_template(state)"
                  >
                    <template v-for="type in Enum.Phone.Type.values.sort()"
                              :key="type">
                      <option :value="type">{{ type }}</option>
                    </template>
                  </Select2>
                </div>
                <div class="col s3">
                  <Select2
                      v-model="simple_mobile_phone_project_id"
                      dropdown_parent="#play_modal"
                      label="Phones"
                  >
                    <option
                        v-if="simple_mobile_type == Enum.Phone.Type.ANDROID"
                        value="">
                      Any Android
                    </option>
                    <option
                        v-if="simple_mobile_type == Enum.Phone.Type.IPHONE"
                        value="">
                      Any iPhone
                    </option>
                    <option
                        v-if="simple_mobile_type == Enum.Phone.Type.SIMULATOR"
                        value="">
                      Any Simulator
                    </option>
                    <option
                        v-for="phone_project in available_phones(simple_mobile_type)"
                        :key="phone_project.props.id"
                        :value="phone_project.props.id">
                      {{ phone_project.phone.props.name }}
                    </option>
                  </Select2>
                </div>

                <div class="col s3">
                  <Select2
                      v-model="simple_mobile_package"
                      dropdown_parent="#play_modal"
                      :validator="form_validator.register(`simple_app_package`, {presence: true})"
                      label="Package"
                  >
                    <option
                        v-for="app_package_option in available_packages(simple_app_type)"
                        :key="app_package_option"
                        :value="app_package_option">
                      {{ app_package_option }}
                    </option>
                  </Select2>
                </div>
                <div class="col s3">
                  <Select2
                      v-model="simple_app_id"
                      dropdown_parent="#play_modal"
                      label="Version"
                  >
                    <option value="">Latest</option>
                    <option
                        v-for="app in available_apps(simple_app_type, simple_mobile_package)"
                        :key="app.props.id"
                        :value="app.props.id">
                      {{ app.props.name }}
                    </option>
                  </Select2>
                </div>
              </div>
            </div>
          </div>
          <!--</editor-fold>-->

          <!--<editor-fold desc="PROXY">-->
          <div class="grouping">
            <div class="row align-center">
              <div class="reduced-inline-row">
                <div class="col s6">
                  <Select2
                      v-model="simple_proxy_id"
                      dropdown_parent="#play_modal"
                      label="Proxy"
                  >
                    <option value="">
                      -- No Proxy --
                    </option>
                    <option v-for="proxy in proxies"
                            :key="proxy.props.id"
                            :value="proxy.props.id"
                    >
                      {{ proxy.props.name }}
                    </option>
                  </Select2>
                </div>
              </div>
            </div>
          </div>

          <!--</editor-fold>-->


        </template>
        <template v-else>
          <!--<editor-fold desc="DETECTED BROWSERS">-->
          <template
              v-for="(mobility, is_mobile) in grouped_browsers"
              :key="is_mobile">
            <template
                v-for="(types, type) in mobility"
                :key="type">
              <div class="grouping">
                <template
                    v-for="(browser_grouping, browser_id, index) in types"
                    :key="browser_id">
                  <div class="row label-row">

                    <div :class="`col ${browser_detected_cols}`">
                      <label v-if="index == 0">
                        Detected {{ is_mobile == 'true' ? 'mobile' : '' }} browsers
                      </label>
                    </div>

                    <div :class="`col ${browser_type_cols}`">
                      <label v-if="index == 0">
                        Change to
                      </label>
                    </div>
                  </div>


                  <div class="row align-center">
                    <div class="reduced-inline-row">
                      <div :class="`col ${browser_detected_cols}`">
                        <Select2
                            dropdown_parent="#play_modal"
                            :title="browser_grouping.scenario_names"
                            :disabled="true">
                          <option selected="selected"
                                  value="">
                            <template v-if="browser_grouping.original_id == null">
                              {{ browser_name(browser_grouping.original_type) }} Latest
                            </template>
                            <template v-else>
                              {{ browser_name(browser_grouping.original_type) }}
                              {{ Browser.find(browser_grouping.original_id).props.label }}
                            </template>
                          </option>
                        </Select2>
                      </div>
                      <div :class="`col ${browser_type_cols}`">
                        <Select2 v-model="browser_grouping.replace_type"
                                 dropdown_parent="#play_modal"
                                 :validator="form_validator.register(`browser_type_${is_mobile}_${type}_${index}`, {presence: true})"
                        >
                          <option v-for="browser_type in Enum.Browser.values"
                                  :key="browser_type"
                                  :value="browser_type">
                            {{ browser_name(browser_type) }}
                          </option>
                        </Select2>
                      </div>
                      <div :class="`col ${browser_id_cols}`">
                        <Select2
                            v-if="!browser_grouping.replace_is_mobile || !advanced_settings"
                            v-model="browser_grouping.replace_id"
                            dropdown_parent="#play_modal"
                        >
                          <option value="">
                            Latest
                          </option>
                          <option v-for="browser in browsers[browser_grouping.replace_type]"
                                  :key="browser.props.id"
                                  :value="browser.props.id"
                          >
                            {{ browser.props.label }}
                          </option>
                        </Select2>
                        <Select2
                            v-else
                            v-model="browser_grouping.replace_emulation_profile_name"
                            dropdown_parent="#play_modal"
                            :validator="form_validator.register(`browser_emulation_profile_${is_mobile}_${type}_${index}`,{presence: true, inclusion: Browser.emulation_profiles.map(p => p.name)})"
                        >
                          <option v-for="emulation_profile in Browser.emulation_profiles"
                                  :key="emulation_profile.name"
                                  :value="emulation_profile.name">
                            {{ emulation_profile.name }}
                          </option>
                        </Select2>
                      </div>
                      <div v-if="advanced_settings"
                           class="col s2"
                      >
                        <Input v-model="browser_grouping.replace_width"
                               type="number"
                               placeholder="Width"
                               :min="100"
                               :max="Enum.Scenario.Setting.Browser.MAX_WIDTH"
                               :validator="form_validator.register(`browser_width_${is_mobile}_${type}_${index}`,{min: 100, max: Enum.Scenario.Setting.Browser.MAX_WIDTH})"
                        />
                      </div>
                      <div v-if="advanced_settings"
                           class="col s2"
                      >
                        <Input v-model="browser_grouping.replace_height"
                               type="number"
                               placeholder="Height"
                               :min="100"
                               :max="Enum.Scenario.Setting.Browser.MAX_HEIGHT"
                               :validator="form_validator.register(`browser_height_${is_mobile}_${type}_${index}`, {min: 100, max: Enum.Scenario.Setting.Browser.MAX_HEIGHT})"
                        />
                      </div>
                      <div v-if="advanced_settings"
                           class="col align-center s1">
                        <span class="browser-is-mobile-toggle"
                              style="font-size: 1.4em; margin-top: 0">
                          <i v-if="browser_grouping.replace_is_mobile"
                             class="fas fa-mobile-alt"
                             title="Disable mobile emulation"
                             @click="browser_grouping.replace_is_mobile = !browser_grouping.replace_is_mobile"
                          />
                          <i v-else
                             class="fas fa-mobile-alt"
                             title="Enable mobile emulation"
                             style="color: var(--button-disabled)"
                             @click="browser_grouping.replace_is_mobile = !browser_grouping.replace_is_mobile"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="row-save">
                      <template v-if="is_browser_grouping_changed(browser_grouping)">
                        <ActionIcon
                            icon_class="far fa-save"
                            color_class="green"
                            title="Apply to detected scenarios"
                            :form_validator="form_validator"
                            :click_action="() => apply_browser_grouping(browser_grouping)"
                        />
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <!--</editor-fold>-->

          <!--<editor-fold desc="DETECTED PHONES">-->
          <template
              v-for="(phone_types, phone_type) in grouped_phones"
              :key="phone_type">
            <div class="grouping">
              <template
                  v-for="(phone_grouping, phone_project_id, index) in phone_types"
                  :key="phone_project_id">
                <div class="row label-row">
                  <div :class="`col s4`">
                    <label v-if="index == 0">
                      Detected {{ phone_type }} phones
                    </label>
                  </div>

                  <div :class="`col s8`">
                    <label v-if="index == 0">
                      Change to
                    </label>
                  </div>
                </div>
                <div class="row align-center">
                  <div class="reduced-inline-row">
                    <div class="col s4">
                      <Select2
                          dropdown_parent="#play_modal"
                          :title="phone_grouping.scenario_names"
                          :disabled="true">
                        <template v-if="phone_grouping.original_id == null">
                          <option
                              v-if="phone_grouping.original_type == Enum.Phone.Type.ANDROID"
                              selected="selected"
                              value="">
                            Any Android
                          </option>
                          <option
                              v-else-if="phone_grouping.original_type == Enum.Phone.Type.IPHONE"
                              selected="selected"
                              value="">
                            Any iPhone
                          </option>
                          <option
                              v-else-if="phone_grouping.original_type == Enum.Phone.Type.SIMULATOR"
                              selected="selected"
                              value="">
                            Any Simulator
                          </option>
                        </template>
                        <template v-else>
                          <option selected="selected"
                                  value="">
                            {{ PhoneProject.find(phone_grouping.original_id)?.phone?.props?.name }}
                          </option>
                        </template>
                      </Select2>
                    </div>
                    <div class="col s4">
                      <Select2
                          v-model="phone_grouping.replace_type"
                          dropdown_parent="#play_modal"
                          :template_result="(state) => Phone.phone_type_option_template(state)"
                          :template_selection="(state) => Phone.phone_type_option_template(state)"
                      >
                        <template v-for="type in Enum.Phone.Type.values.sort()"
                                  :key="type">
                          <option :value="type">{{ type }}</option>
                        </template>
                      </Select2>
                    </div>
                    <div class="col s4">
                      <Select2
                          v-model="phone_grouping.replace_id"
                          dropdown_parent="#play_modal"
                      >
                        <option
                            v-if="phone_grouping.replace_type == Enum.Phone.Type.ANDROID"
                            value="">
                          Any Android
                        </option>
                        <option
                            v-if="phone_grouping.replace_type == Enum.Phone.Type.IPHONE"
                            value="">
                          Any iPhone
                        </option>
                        <option
                            v-if="phone_grouping.replace_type == Enum.Phone.Type.SIMULATOR"
                            value="">
                          Any Simulator
                        </option>
                        <option
                            v-for="phone_project in available_phones(phone_grouping.replace_type)"
                            :key="phone_project.props.id"
                            :value="phone_project.props.id">
                          {{ phone_project.phone.props.name }}
                        </option>
                      </Select2>
                    </div>
                  </div>
                  <div class="row-save">
                    <template v-if="is_phone_grouping_changed(phone_grouping)">
                      <ActionIcon
                          icon_class="far fa-save"
                          color_class="green"
                          title="Apply to detected scenarios"
                          :form_validator="form_validator"
                          :click_action="() => apply_phone_grouping(phone_grouping)"
                      />
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <!--</editor-fold>-->

          <!--<editor-fold desc="DETECTED APPS">-->
          <template
              v-for="(app_types, app_type) in grouped_apps"
              :key="app_type">
            <div class="grouping">
              <template
                  v-for="(app_packages, app_package, index) in app_types"
                  :key="app_package">

                <template
                    v-for="(app_grouping, app_id) in app_packages"
                    :key="app_id">
                  <div class="row label-row">
                    <div :class="`col s6`">
                      <label v-if="index == 0">
                        Detected {{ app_type }} packages
                      </label>
                    </div>

                    <div :class="`col s6`">
                      <label v-if="index == 0">
                        Change to
                      </label>
                    </div>
                  </div>
                  <div class="row align-center">
                    <div class="reduced-inline-row">
                      <div class="col s6">
                        <Select2
                            dropdown_parent="#play_modal"
                            :title="app_grouping.scenario_names"
                            :disabled="true">
                          <option selected="selected"
                                  value="">
                            <template v-if="app_grouping.original_id == null">
                              {{ app_grouping.original_package }} Latest
                            </template>
                            <template v-else>
                              {{ App.find(app_grouping.original_id)?.props?.name }}
                            </template>
                          </option>
                        </Select2>
                      </div>
                      <div class="col s3">
                        <Select2
                            v-model="app_grouping.replace_package"
                            dropdown_parent="#play_modal"
                            :validator="form_validator.register(`app_package_${app_type}_${app_package}_${app_id}`, {presence: true})"
                        >
                          <option
                              v-for="app_package_option in available_packages(app_type)"
                              :key="app_package_option"
                              :value="app_package_option">
                            {{ app_package_option }}
                          </option>
                        </Select2>
                      </div>
                      <div class="col s3">
                        <Select2
                            v-model="app_grouping.replace_id"
                            dropdown_parent="#play_modal"
                        >
                          <option value="">Latest</option>
                          <option
                              v-for="app in available_apps(app_type, app_grouping.replace_package)"
                              :key="app.props.id"
                              :value="app.props.id">
                            {{ app.props.name }}
                          </option>
                        </Select2>
                      </div>
                    </div>
                    <div class="row-save">
                      <template v-if="is_app_grouping_changed(app_grouping)">
                        <ActionIcon
                            icon_class="far fa-save"
                            color_class="green"
                            title="Apply to detected scenarios"
                            :form_validator="form_validator"
                            :click_action="() => apply_app_grouping(app_grouping)"
                        />
                      </template>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </template>
          <!--</editor-fold>-->

          <!--<editor-fold desc="DETECTED PROXIES">-->
          <template v-if="advanced_settings">
            <div class="grouping">
              <template
                  v-for="(proxy_grouping, proxy_id, index) in grouped_proxies"
                  :key="proxy_id">
                <div class="row label-row">
                  <div :class="`col s6`">
                    <label v-if="index == 0">
                      Detected proxies
                    </label>
                  </div>

                  <div :class="`col s6`">
                    <label v-if="index == 0">
                      Change to
                    </label>
                  </div>
                </div>
                <div class="row align-center">
                  <div class="reduced-inline-row">
                    <div class="col s6">
                      <Select2
                          dropdown_parent="#play_modal"
                          :title="proxy_grouping.scenario_names"
                          :disabled="true">
                        <option selected="selected"
                                value="">
                          <template v-if="proxy_id == 'null'">
                            -- No Proxy --
                          </template>
                          <template v-else>
                            {{ Proxy.find(parseInt(proxy_id))?.props?.name }}
                          </template>
                        </option>
                      </Select2>
                    </div>
                    <div class="col s6">
                      <Select2
                          v-model="proxy_grouping.replace_id"
                          dropdown_parent="#play_modal"
                      >
                        <option value="">
                          -- No Proxy --
                        </option>
                        <option v-for="proxy in proxies"
                                :key="proxy.props.id"
                                :value="proxy.props.id"
                        >
                          {{ proxy.props.name }}
                        </option>
                      </Select2>
                    </div>
                  </div>
                  <div class="row-save">
                    <template v-if="is_proxy_grouping_changed(proxy_grouping)">
                      <ActionIcon
                          icon_class="far fa-save"
                          color_class="green"
                          title="Apply to detected scenarios"
                          :form_validator="form_validator"
                          :click_action="() => apply_proxy_grouping(proxy_grouping)"
                      />
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <!--</editor-fold>-->
        </template>

        <div class="row play-settings">
          <!--<editor-fold desc="ON ERROR">-->
          <div class="col s6">
            <Select2
                v-model="play_setting.on_error"
                dropdown_parent="#play_modal"
                label="On Error"
                :validator="form_validator.register('on_error', {presence: true})"
            >
              <option v-for="on_error in Enum.Play.Setting.OnError.options_for_group"
                      :key="on_error[1]"
                      :value="on_error[1]">
                {{ on_error[0] }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="VARIABLE SETS">-->
          <div class="col s6">
            <Select2 v-model="play_setting.variable_set_id"
                     dropdown_parent="#play_modal"
                     label="Variable set"
                     :validator="form_validator.register('variable_set', {presence: true})"
            >
              <option v-for="variable_set in variable_sets"
                      :key="variable_set.props.id"
                      :value="variable_set.props.id">
                {{ variable_set.props.name }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="SELECTED NO RESET">-->
          <div v-show="has_mobile"
               class="col s6">
            <Select2 v-model="play_setting.selected_no_reset"
                     dropdown_parent="#play_modal"
                     label="Application reset"
                     :validator="form_validator.register('selected_no_reset', {presence: true})"
            >
              <option v-for="reset in Enum.Play.Setting.SelectedNoReset.options"
                      :key="reset[1]"
                      :value="reset[1]">
                {{ reset[0] }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="LOCALE">-->
          <div v-show="has_mobile || has_web"
               class="col s6">
            <Select2 v-model="locale"
                     dropdown_parent="#play_modal"
                     label="Locale">
              <option value="">-- Default --</option>
              <option v-for="locale_option in Enum.Locale.values"
                      :key="locale_option"
                      :value="locale_option">
                {{ locale_option }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="REPLAY">-->
          <div v-show="replay_play != null"
               class="col s12">
            <Select2 v-model="replay_options"
                     dropdown_parent="#play_modal"
                     :multiple="true"
                     :template_result="(state) => PlayStatus.replay_group_option_template(state)"
                     :template_selection="(state) => PlayStatus.replay_group_option_template(state)"
                     label="Replay">
              <option value="success">Successful</option>
              <option value="warned">Warned</option>
              <option value="skipped"
                      :selected="true">
                Skipped
              </option>
              <option value="failed"
                      :selected="true">
                Failed
              </option>
              <option value="not_finished"
                      :selected="true">
                Not Finished
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->

          <!--<editor-fold desc="ADVANCED SETTINGS">-->
          <div class="col s6">
            <Checkbox v-model="advanced_settings"
                      label="Advanced Settings"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="AUTO REPLAY FAILED">-->
          <div class="col s6 advanced-item">
            <Checkbox
                v-model="play_setting.auto_replay_failed"
                label="Auto Replay Failed"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="VIDEO RECORDING">-->
          <div v-show="has_mobile || has_web"
               class="col s6 advanced-item">
            <Checkbox
                v-model="play_setting.enable_video_recording"
                label="Video Recording"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="SLACK CHANNEL">-->
          <div class="col s6 advanced-item">
            <Select2 v-model="play_setting.slack_channel_id"
                     dropdown_parent="#play_modal"
                     label="Slack channel">
              <option value="">
                -- Don't Report --
              </option>
              <option v-for="slack_channel in slack_channels"
                      :key="slack_channel.id">
                {{ slack_channel.name }}
              </option>
            </Select2>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="REUSE BROWSER PROFILE">-->
          <div class="col s6 advanced-item">
            <Checkbox v-model="play_setting.reuse_browsers"
                      label="Reuse Browser Profile">
              <span class="beta-testing-feature"
                    title="Experimental feature">
                <i class="fas fa-vial"/>
              </span>
            </Checkbox>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="REPORT_TO_XRAY">-->
          <div v-if="project_version_setting.props.xray_enabled"
               class="col s6 advanced-item">
            <Checkbox v-model="play_setting.report_to_xray"
                      label="Report to xray"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="PERFORM_LATER">-->
          <div v-if="project_version_setting.props.schedule_module_enabled"
               class="col s6 advanced-item">
            <Checkbox v-model="play_setting.perform_later"
                      label="Perform later"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="SCHEDULE SETTINGS">-->
          <div v-if="play_setting.perform_later"
               class="col s12 advanced-item schedule-settings">
            <div class="row"
                 style="margin: 0"
            >
              <div class="col s12"
                   style="padding-inline: 0"
              >
                <div class="schedule-setting-item"
                     style="margin-left: 0"
                >
                  <Select2 v-model="play_setting.delay_preposition"
                           dropdown_parent="#play_modal"
                           :validator="form_validator.register('delay_preposition', {presence: true})"
                  >
                    <option :value="Enum.Play.Setting.DelayPreposition.IN">
                      {{
                        Enum.Play.Setting.DelayPreposition.IN
                      }}
                    </option>
                    <option :value="Enum.Play.Setting.DelayPreposition.ON">
                      {{
                        Enum.Play.Setting.DelayPreposition.ON
                      }}
                    </option>
                  </Select2>
                </div>
                <div v-if="play_setting.delay_preposition == Enum.Play.Setting.DelayPreposition.IN"
                     class="schedule-setting-item">
                  <Input v-model="play_setting.play_in_amount"
                         type="number"
                         :min="0"
                         :max="Enum.Play.Setting.PlayInAmount.MAX_VALUE"
                         :validator="form_validator.register('play_in_amount', {min: 0, max: Enum.Play.Setting.PlayInAmount.MAX_VALUE, presence: true})"
                  />
                </div>
                <div v-if="play_setting.delay_preposition == Enum.Play.Setting.DelayPreposition.IN"
                     class="schedule-setting-item">
                  <Select2 v-model="play_setting.play_in_time_units"
                           dropdown_parent="#play_modal"
                           :validator="form_validator.register('play_in_time_units', {presence: true})"
                  >
                    <option :value="Enum.Play.Setting.PlayInTimeUnits.MINUTES">
                      {{
                        Enum.Play.Setting.PlayInTimeUnits.MINUTES
                      }}
                    </option>
                    <option :value="Enum.Play.Setting.PlayInTimeUnits.HOURS">
                      {{
                        Enum.Play.Setting.PlayInTimeUnits.HOURS
                      }}
                    </option>
                    <option :value="Enum.Play.Setting.PlayInTimeUnits.DAYS">
                      {{
                        Enum.Play.Setting.PlayInTimeUnits.DAYS
                      }}
                    </option>
                  </Select2>
                </div>
                <div v-if="play_setting.delay_preposition == Enum.Play.Setting.DelayPreposition.ON"
                     class="schedule-setting-item">
                  <DatePicker
                      v-model="play_setting.perform_target_datetime"
                      :timepicker="true"
                      :validator="form_validator.register('perform_target_datetime', {presence: true})"
                  />
                </div>
                <div class="schedule-setting-item">
                  <Checkbox v-model="play_setting.repeat"
                            label="Every"/>
                </div>
                <div v-if="play_setting.repeat"
                     class="schedule-setting-item">
                  <Input v-model="play_setting.repeat_every_amount"
                         type="number"
                         :min="0"
                         :max="Enum.Play.Setting.RepeatEveryAmount.MAX_VALUE"
                         :validator="form_validator.register('repeat_every_amount', {min: 0, max: Enum.Play.Setting.PlayInAmount.MAX_VALUE, presence: true})"
                  />
                </div>
                <div v-if="play_setting.repeat"
                     class="schedule-setting-item">
                  <Select2 v-model="play_setting.repeat_every_time_units"
                           dropdown_parent="#play_modal"
                           :validator="form_validator.register('repeat_every_time_units', {presence: true})"
                  >
                    <option :value="Enum.Play.Setting.RepeatEveryTimeUnits.MINUTES">
                      {{ Enum.Play.Setting.RepeatEveryTimeUnits.MINUTES }}
                    </option>
                    <option :value="Enum.Play.Setting.RepeatEveryTimeUnits.HOURS">
                      {{ Enum.Play.Setting.RepeatEveryTimeUnits.HOURS }}
                    </option>
                    <option :value="Enum.Play.Setting.RepeatEveryTimeUnits.DAYS">
                      {{ Enum.Play.Setting.RepeatEveryTimeUnits.DAYS }}
                    </option>
                  </Select2>
                </div>
              </div>
            </div>

          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="PARALLEL EXECUTION">-->
          <div class="col s6 advanced-item">
            <Checkbox v-model="play_setting.parallel_execution"
                      label="Parallel execution"/>
          </div>
          <!--</editor-fold>-->
          <!--<editor-fold desc="WORKERS SETTINGS">-->
          <div v-if="play_setting.parallel_execution"
               class="col s12 vertical advanced-item worker-settings">
            <template v-for="(worker, index) in play_setting.workers"
                      :key="worker.vue_key">
              <div class="row"
                   style="margin: 0">
                <div class="col s5">
                  <Input
                      v-model="worker.name"
                      :disabled="true"
                      :label="index == 0 ? 'Name' : null"
                  />
                </div>
                <div class="col vertical s1">
                  <span v-if="index == 0"
                        style="font-size: 10px">
                    Load
                  </span>
                  <span>{{ worker.load }}</span>
                </div>
                <div class="col s5">
                  <Input
                      v-model="worker.extra_variables"
                      placeholder="Extra variables"
                      :label="index == 0 ? 'Extra variables' : null"
                  />
                </div>
                <div class="col s1">
                  <ActionIcon
                      v-if="index == 0"
                      icon_class="fas fa-plus"
                      color_class="green"
                      title="Add worker"
                      :disabled="play_setting.workers.length >= scenario_count"
                      @click="add_worker"
                  />
                  <ActionIcon
                      v-else
                      icon_class="fas fa-times"
                      color_class="red"
                      title="Remove worker"
                      @click="remove_worker(worker)"
                  />
                </div>
              </div>
            </template>
          </div>
          <!--</editor-fold>-->
        </div>
      </div>
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="FOOTER">-->
    <template v-if="loaded"
              #footer_left_box>
      <div
          v-if="form_validator.valid && replay_play == null"
          class="curl-request"
          @click.stop="copy_curl_request">
        cURL request
        <span v-if="curl_copied"
              style="color: var(--button-green)">
          - Copied
        </span>
      </div>
    </template>
    <template v-if="loaded"
              #footer_right_box>
      <ActionIcon
          v-if="show_save_icon"
          title="Save settings"
          color_class="green"
          :form_validator="form_validator"
          icon_class="fa-regular fa-save"
          margin="0 10px 0 0"
          :click_action="save_settings"
      />
    </template>
    <!--</editor-fold>-->
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Browser as ScenarioSettingBrowser } from "../../../vue_record/models/scenario_setting/browser"
import { Phone as ScenarioSettingPhone } from "../../../vue_record/models/scenario_setting/phone"
import { PhoneApp as ScenarioSettingPhoneApp } from "../../../vue_record/models/scenario_setting/phone_app"
import { Proxy } from "../../../vue_record/models/proxy"
import _ from "lodash";
import { ScenarioSetting } from "../../../vue_record/models/scenario_setting";
import Modal from "../../testa/Modal.vue";
import ActionIcon from "../../testa/ActionIcon.vue";
import DatePicker from "../../testa/DatePicker.vue";
import Input from "../../testa/Input.vue";
import Checkbox from "../../testa/Checkbox.vue";
import { EnumBrowserValues, EnumPhoneType } from "../../../auto_generated/enums";
import { Scenario } from "../../../vue_record/models/scenario";
import { PlaySettingProps } from "../../../vue_record/models/play/play_setting";
import { ScenarioSettingMapping } from "../../../vue_record/models/play/play_setting";
import { BrowserMapping } from "../../../vue_record/models/play/play_setting";
import { MobileAppMapping } from "../../../vue_record/models/play/play_setting";
import { ReplaceBrowser } from "../../../vue_record/models/play/play_setting";
import { PlayScenario } from "../../../vue_record/models/play/play_scenario";
import ContentEditable from "../../testa/ContentEditable.vue";
import Select2 from "../../testa/Select2.vue"
import {
    Play,
    PlayModalPlaySettingProps,
    PlayModalResponse,
    PlayModalWorkerProps
} from "../../../vue_record/models/play/play";
import { PlayScenarioScope } from "../../../vue_record/scopes/play/play_scenario_scope";
import { PhoneProject } from "../../../vue_record/models/phone_project";
import { Browser } from "../../../vue_record/models/browser";
import { VariableSet } from "../../../vue_record/models/variable_set";
import { FormValidator } from "../../../helpers/validator/form_validator";
import { App } from "../../../vue_record/models/app";
import { ScenarioSettingScope } from "../../../vue_record/scopes/scenario_setting_scope";
import { generate_uuid } from "../../../helpers/generate/generate_uuid";
import { Validator } from "../../../helpers/validator/validator";
import { what_is_it } from "../../../helpers/generic/what_is_it";
import { replay_play_scenario } from "../../../helpers/play/replay_play_scenario";
import { AppGrouping, BrowserGrouping, PlayGroup, ProxyGrouping } from "../../../vue_record/models/play/play_group";
import { PhoneGrouping } from "../../../vue_record/models/play/play_group";
import {
    slack_joined_channels
} from "../../../helpers/client/settings/project_settings/integrations/slack/joined_channels";
import { copy_curl_request } from "../../../helpers/play/copy_curl_request";
import { markRaw } from "vue";
import { ProjectVersion } from "../../../vue_record/models/project_version";
import { Group } from "../../../vue_record/models/group";
import { ScenarioFolder } from "../../../vue_record/models/scenario_folder";
import { ProjectVersionSetting } from "../../../vue_record/models/project_version_setting";
import { PlayStatus } from "../../../helpers/play/play_status";
import { EnumApp } from "../../../auto_generated/enums";
import { Phone } from "../../../vue_record/models/phone";
import { Storager } from "../../../helpers/api_wrappers/storager";


// <editor-fold desc="TYPES">
type SlackChannel = {
    name: string,
    id: string
}


type BrowserIdGrouping = {
    [key: string]: BrowserGrouping
}

type BrowserTypeGrouping = {
    chrome?: BrowserIdGrouping
    firefox?: BrowserIdGrouping

    [key: string]: BrowserIdGrouping
}

type GroupedBrowsers = {
    true?: BrowserTypeGrouping
    false?: BrowserTypeGrouping

    [key: string]: BrowserTypeGrouping
}

type PhoneIdGrouping = {
    [key: string]: PhoneGrouping
}
type GroupedPhones = {
    [Enum.Phone.Type.ANDROID]?: PhoneIdGrouping
    [Enum.Phone.Type.IPHONE]?: PhoneIdGrouping
    [Enum.Phone.Type.SIMULATOR]?: PhoneIdGrouping

    [key: string]: PhoneIdGrouping
}

type AppIdGrouping = {
    [key: string]: AppGrouping
}

type AppPackageGrouping = {
    [key: string]: AppIdGrouping
}

type GroupedApps = {
    [Enum.App.ANDROID]?: AppPackageGrouping
    [Enum.App.IPHONE]?: AppPackageGrouping
    [Enum.App.SIMULATOR]?: AppPackageGrouping

    [key: string]: AppPackageGrouping
}

type GroupedProxies = {
    [key: string]: ProxyGrouping
}
// </editor-fold>

export default defineComponent({
    components: {
        ActionIcon,
        DatePicker,
        Input,
        Checkbox,
        Select2,
        ContentEditable,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        scenario_ids: {
            type: Array as PropType<number[]>,
            require: false,
            default: null,
        },
        scenario_folder_ids: {
            type: Array as PropType<number[]>,
            required: false,
            default: null,
        },
        scenario_folder_id: {
            type: Number,
            required: false,
            default: null,
        },
        group_id: {
            type: Number,
            required: false,
            default: null,
        },
        replay_play: {
            type: Object as PropType<Play>,
            required: false,
            default: null,
        },
    },
    // </editor-fold>
    // <editor-fold desc="DATA">
    data() {
        const stage_id = generate_uuid()
        return {
            stage_id,
            play_type: null, // will be set in load_data
            advanced_settings: false,
            confirm_text: "Play",
            loaded: false,
            data: null as PlayModalResponse,
            scenarios: [] as Scenario[],
            replay_from_main_play_scenarios: [] as PlayScenario[],
            original_play_setting: null as PlaySettingProps,
            play_setting: null as PlayModalPlaySettingProps,
            modal_width: "1000px",
            all_browsers: Browser.get_scope(),
            locale: null,
            slack_channels: [] as SlackChannel[],
            form_validator: new FormValidator(),
            curl_copied: false,
            show_save_icon: false,
            save_loading: false,
            browser_mobility_cols: "s1",
            grouped_browsers: {} as GroupedBrowsers,
            grouped_phones: {} as GroupedPhones,
            grouped_apps: {} as GroupedApps,
            grouped_proxies: {} as GroupedProxies,
            replay_options: ["skipped", "failed", "not_finished"] as string[],
            scenario_count: 0,

            simple_browser_type: null,
            simple_browser_id: null,
            simple_browser_width: null,
            simple_browser_height: null,
            simple_mobile_type: null,
            simple_mobile_phone_project_id: null,
            simple_mobile_package: null,
            simple_app_id: null,
            simple_proxy_id: null,
            simple_modal_defaults_set: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        simple_modal() {
            return this.play_setting?.simple_modal
        },
        browser_type_cols() {
            if (this.advanced_settings) {
                if (this.simple_modal) {
                    return "s3"
                } else {
                    return "s2"
                }
            } else {
                if (this.simple_modal) {
                    return "s6"
                } else {
                    return "s4"
                }
            }
        },
        browser_id_cols() {
            if (this.advanced_settings) {
                if (this.simple_modal) {
                    return "s3"
                } else {
                    return "s2"
                }
            } else {
                if (this.simple_modal) {
                    return "s6"
                } else {
                    return "s4"
                }
            }
        },
        browser_detected_cols() {
            if (this.advanced_settings) {
                return "s3"
            } else {
                return "s4"
            }
        },
        simple_app_type() {
            switch (this.simple_mobile_type) {
                case Enum.Phone.Type.ANDROID:
                    return Enum.App.ANDROID
                case Enum.Phone.Type.IPHONE:
                    return Enum.App.IPHONE
                case Enum.Phone.Type.SIMULATOR:
                    return Enum.App.SIMULATOR
                default:
                    return Enum.App.ANDROID
            }
        },
        Phone() {
            return Phone
        },
        PlayStatus() {
            return PlayStatus
        },
        project_version_id() {
            if (this.replay_play != null) return this.replay_play.props.project_version_id
            else {
                if (this.group_id != null) {
                    return Group.find(this.group_id).props.project_version_id
                } else if (this.scenario_folder_id != null) {
                    return ScenarioFolder.find(this.scenario_folder_id).props.project_version_id
                } else if (this.scenario_folder_ids.length > 0) {
                    return ScenarioFolder.find(this.scenario_folder_ids[0]).props.project_version_id
                } else {
                    return Scenario.find(this.scenario_ids[0]).props.project_version_id
                }
            }
        },
        project_id() {
            return ProjectVersion.find(this.project_version_id).props.project_id
        },
        project_version_setting() {
            return ProjectVersionSetting.where({ project_version_id: this.project_version_id }).first()
        },
        Enum() {
            return Enum
        },
        Browser() {
            return Browser
        },
        Proxy() {
            return Proxy
        },
        App() {
            return App
        },
        PhoneProject() {
            return PhoneProject
        },
        phone_projects() {
            return PhoneProject.get_scope().where({ project_id: this.project_id })
        },
        play_scenarios_for_replay(): PlayScenarioScope {
            if (this.replay_play == null || !this.loaded) return PlayScenario.to_scope([]);
            const play_scenarios_for_replay = (this.replay_from_main_play_scenarios as PlayScenario[])
                .filter(ps => replay_play_scenario(this.replay_options, ps))
            return PlayScenario.to_scope(play_scenarios_for_replay)
        },
        scenario_settings_for_play(): ScenarioSettingScope {
            if (!this.loaded) return ScenarioSetting.to_scope([])

            if (this.replay_play == null) {
                return Scenario.to_scope(this.scenarios as Scenario[]).scenario_settings as ScenarioSettingScope
            } else {
                const scope = ScenarioSetting.get_scope(this.stage_id)
                this.play_scenarios_for_replay.each(play_scenario => {
                    play_scenario.scenario_savepoint.scenario.scenario_setting.stage(this.stage_id)
                })
                return scope;
            }
        },
        proxies() {
            return Proxy.get_scope().where({ project_id: this.project_id }).order("name").toArray()
        },
        variable_sets() {
            return VariableSet.get_scope().where({ project_version_id: this.project_version_id }).order("name").toArray()
        },
        browsers() {
            return this.all_browsers.is_active.order("label", "desc", "version").group_by("props.name")
        },
        apps() {
            return App.get_scope().where({ project_id: this.project_id })
        },
        simulator_phone_projects() {
            return this.phone_projects.simulators.online.order(".phone.props.name").toArray()
        },
        simulator_packages() {
            return this.apps.zips.pluck("props").pluck("package").uniq().sort()
        },
        simulator_versions() {
            return this.apps.zips.order("version_name", "desc", "version").order("version_code", "desc").group_by("props.package")
        },
        iphone_phone_projects() {
            return this.phone_projects.iphones.online.order(".phone.props.name").toArray()
        },
        iphone_packages() {
            return this.apps.ipas.pluck("props").pluck("package").uniq().sort()
        },
        iphone_versions() {
            return this.apps.ipas.order("version_name", "desc", "version").order("version_code", "desc").group_by("props.package")
        },
        android_packages() {
            return this.apps.apks.pluck("props").pluck("package").uniq().sort()
        },
        android_versions() {
            return this.apps.apks.order("version_name", "desc", "version").order("version_code", "desc").group_by("props.package")
        },
        android_phone_projects() {
            return this.phone_projects.androids.online.order(".phone.props.name").toArray()
        },
        has_mobile() {
            return Object.keys(this.grouped_phones).length > 0
        },
        has_web() {
            return Object.keys(this.grouped_browsers).length > 0
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'advanced_settings'() {
            if (this.advanced_settings) {
                this.browser_detected_cols = "s3"
            } else {
                this.browser_detected_cols = "s4"
            }
        },
        'locale'() {
            if (this.play_setting == null) return;

            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        'play_setting.perform_later'() {
            if (this.play_setting?.perform_later) {
                this.confirm_text = "Schedule"
            } else {
                this.confirm_text = "Play"
            }
        },
        replay_options: {
            immediate: true,
            handler() {
                if (this.play_setting == null) return;
                this.play_setting.replay_failed = this.replay_options.includes("failed");
                this.play_setting.replay_success = this.replay_options.includes("success");
                this.play_setting.replay_warned = this.replay_options.includes("warned");
                this.play_setting.replay_skipped = this.replay_options.includes("skipped");
                this.play_setting.replay_not_finished = this.replay_options.includes("not_finished");
            },
        },
        play_setting: {
            deep: true,
            handler(_new_value, old_value) {
                if (old_value == null) return
                if (!this.loaded) return
                const is_equal = _.isEqual(this.play_setting, this.original_play_setting)
                this.show_save_icon = !is_equal
            },
        },
        play_scenarios_for_replay() {
            if (this.replay_play != null) {
                this.scenario_count = this.play_scenarios_for_replay.toArray().length
            }
        },
        grouped_browsers: {
            deep: true,
            handler() {
                if (this.play_setting == null) return;

                this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
            },
        },
        grouped_phones: {
            deep: true,
            handler() {
                if (this.play_setting == null) return;

                this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
                Object.values(this.grouped_phones as GroupedPhones).each(phone_id_grouping => {
                    Object.values(phone_id_grouping as PhoneIdGrouping).each(phone_grouping => {
                        phone_grouping.phones.forEach(phone_1 => {
                            phone_1.apps.toArray().forEach(app => {
                                if (app.props.type != Enum.App.ANDROID && phone_grouping.replace_type == Enum.Phone.Type.ANDROID) {
                                    app.props.type = Enum.App.ANDROID;
                                }
                                if (app.props.type != Enum.App.IPHONE && phone_grouping.replace_type == Enum.Phone.Type.IPHONE) {
                                    app.props.type = Enum.App.IPHONE;
                                }
                                if (app.props.type != Enum.App.SIMULATOR && phone_grouping.replace_type == Enum.Phone.Type.SIMULATOR) {
                                    app.props.type = Enum.App.SIMULATOR;
                                }
                            });
                        });
                    })
                })
                this.group_apps()
            },
        },
        grouped_apps: {
            deep: true,
            handler() {
                if (this.play_setting == null) return;

                this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
            },
        },
        grouped_proxies: {
            deep: true,
            handler() {
                if (this.play_setting == null) return;

                this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
            },
        },
        scenario_settings_for_play() {
            if (this.loaded) {
                this.group_browsers();
                this.group_phones();
                this.group_apps();
                this.group_proxies();
                this.set_defaults_for_simple()
            }
        },
        scenario_count() {
            this.update_worker_load();
        },
        simple_browser_type() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        simple_browser_id() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        simple_mobile_type() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        simple_mobile_phone_project_id() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        simple_mobile_package() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        simple_app_type() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        simple_app_id() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        },
        simple_proxy_id() {
            this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.load_data()
        this.load_slack_channels()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        load_data() {
            let promise: Promise<PlayModalResponse>;
            if (this.scenario_ids != null || this.scenario_folder_ids != null) {
                promise = PlayGroup.ClientClass.play_scenarios_modal(this.scenario_ids, this.scenario_folder_ids)
            } else if (this.scenario_folder_id != null) {
                promise = PlayGroup.ClientClass.play_scenario_folder_modal(this.scenario_folder_id)
            } else if (this.group_id != null) {
                promise = PlayGroup.ClientClass.play_group_modal(this.group_id)
            } else if (this.replay_play != null) {
                promise = this.replay_play.client.replay() as Promise<PlayModalResponse>
            }
            promise.then(data => {
                this.play_type = data.play_type
                this.data = data

                this.scenarios = data.scenarios.map(props => Scenario.new(props))
                this.replay_from_main_play_scenarios = data.replay_from_main_play_scenarios.map(props => PlayScenario.new(props))

                let locale: any = data.play_setting.scenario_setting_mappings?.locale
                if (locale != null) locale = locale["*"]
                if (locale != null) this.locale = locale

                if (data.play_setting.workers == null) data.play_setting.workers = []
                data.play_setting.workers.forEach(worker => {
                    worker.vue_key = generate_uuid()
                })
                this.play_setting = data.play_setting

                if (this.play_setting.workers.length == 0) this.add_worker();

                if (this.replay_play == null) {
                    this.play_setting.name = data.name
                    this.scenario_count = data.scenario_count
                }
                this.play_setting.perform_later = false
                if (this.play_setting.variable_set_id == null) {
                    this.play_setting.variable_set_id = VariableSet.get_scope().default.first()?.key()
                }

                // this.play_setting.scenario_setting_mappings = this.get_scenario_setting_mappings();
                this.original_play_setting = JSON.parse(JSON.stringify(data.play_setting));
                this.loaded = true
            }).catch(() => {
                this.cancel()
            })
        },
        load_slack_channels() {
            slack_joined_channels().then(data => {
                $.each(data, (i, item) => {
                    this.slack_channels.push({
                        name: item[1],
                        id: item[0],
                    })
                });
            })
        },
        is_browser_grouping_changed(browser_grouping: BrowserGrouping) {
            return browser_grouping.original_id != browser_grouping.replace_id ||
                browser_grouping.original_type != browser_grouping.replace_type ||
                browser_grouping.original_emulation_profile_name != browser_grouping.replace_emulation_profile_name ||
                browser_grouping.original_is_mobile != browser_grouping.replace_is_mobile ||
                browser_grouping.original_width != browser_grouping.replace_width ||
                browser_grouping.original_height != browser_grouping.replace_height
        },
        is_phone_grouping_changed(phone_grouping: PhoneGrouping) {
            return phone_grouping.original_id != phone_grouping.replace_id ||
                phone_grouping.original_type != phone_grouping.replace_type
        },
        is_app_grouping_changed(app_grouping: AppGrouping) {
            return app_grouping.original_id != app_grouping.replace_id ||
                app_grouping.original_package != app_grouping.replace_package
        },
        is_proxy_grouping_changed(proxy_grouping: ProxyGrouping) {
            return proxy_grouping.original_id != proxy_grouping.replace_id
        },
        set_defaults_for_simple() {
            if (!this.loaded) return;
            if (this.simple_modal_defaults_set) return;

            if (this.has_web && this.simple_browser_type == null) {
                const browsers = this.scenario_settings_for_play
                                     .filter(scenario_setting => scenario_setting.props.web_module_active)
                                     .map(scenario_setting => scenario_setting.browsers.toArray())
                                     .flat()
                const chromes = browsers.filter(b => b.props.type == Enum.Browser.CHROME)
                const firefoxes = browsers.filter(b => b.props.type == Enum.Browser.FIREFOX)
                if (chromes.length >= firefoxes.length) {
                    this.simple_browser_type = Enum.Browser.CHROME
                } else {
                    this.simple_browser_type = Enum.Browser.FIREFOX
                }
            }
            if (this.has_mobile) {
                const phones = this.scenario_settings_for_play
                                   .filter(scenario_setting => scenario_setting.props.mobile_module_active)
                                   .map(scenario_setting => scenario_setting.phones.toArray())
                                   .flat()
                const androids = phones.filter(p => p.props.type == Enum.Phone.Type.ANDROID)
                const iphones = phones.filter(p => p.props.type == Enum.Phone.Type.IPHONE)
                const simulators = phones.filter(p => p.props.type == Enum.Phone.Type.SIMULATOR)
                if (this.simple_mobile_type == null) {
                    if (androids.length >= iphones.length && androids.length >= simulators.length) {
                        this.simple_mobile_type = Enum.Phone.Type.ANDROID
                    } else if (iphones.length >= androids.length && iphones.length >= simulators.length) {
                        this.simple_mobile_type = Enum.Phone.Type.IPHONE
                    } else {
                        this.simple_mobile_type = Enum.Phone.Type.SIMULATOR
                    }
                }

                if (this.simple_mobile_package == null) {
                    const apps = this.scenario_settings_for_play
                                     .filter(scenario_setting => scenario_setting.props.mobile_module_active)
                                     .map(scenario_setting => scenario_setting.phones.toArray())
                                     .flat()
                                     .map(phone => phone.apps.toArray())
                                     .flat()
                    const grouped: any = apps.group_by("props.package")
                    let max_package: string = null;
                    let max_count = -1
                    Object.keys(grouped).forEach(pack => {
                        if (grouped[pack].length > max_count) {
                            max_count = grouped[pack].length
                            max_package = pack
                        }
                    })
                    this.simple_mobile_package = max_package
                }
            }

            if (this.simple_proxy_id == null) {
                const grouped = this.scenario_settings_for_play.group_by("props.proxy_id")
                let max_proxy_id: number = null;
                let max_count = -1
                Object.keys(grouped).forEach(proxy_id => {
                    if (grouped[proxy_id].length > max_count) {
                        max_count = grouped[proxy_id].length
                        max_proxy_id = parseInt(proxy_id)
                    }
                })
                this.simple_proxy_id = max_proxy_id
            }
            this.simple_modal_defaults_set = true;
        },
        async apply_browser_grouping(browser_grouping: BrowserGrouping) {
            if (this.form_validator.invalid) return null;

            browser_grouping.save_in_progress = true
            try {
                await PlayGroup.ClientClass.apply_browser_mappings(browser_grouping);
                browser_grouping.browsers.forEach(browser => {
                    browser.props.browser_id = browser_grouping.replace_id;
                    browser.props.type = browser_grouping.replace_type;
                    browser.props.is_mobile = browser_grouping.replace_is_mobile;
                });
                browser_grouping.original_id = browser_grouping.replace_id;
                browser_grouping.original_type = browser_grouping.replace_type;
                browser_grouping.original_is_mobile = browser_grouping.replace_is_mobile;
                browser_grouping.original_emulation_profile_name = browser_grouping.replace_emulation_profile_name;
                browser_grouping.original_width = browser_grouping.replace_width;
                browser_grouping.original_height = browser_grouping.replace_height;
            } finally {
                browser_grouping.save_in_progress = false;
            }
        },
        apply_phone_grouping(phone_grouping: PhoneGrouping) {
            if (this.form_validator.invalid) return null;

            phone_grouping.save_in_progress = true
            return PlayGroup.ClientClass.apply_phone_mappings(phone_grouping)
                            .then(() => {
                                phone_grouping.phones.forEach(phone => {
                                    phone.props.phone_project_id = phone_grouping.replace_id;
                                    phone.props.type = phone_grouping.replace_type;
                                });
                                phone_grouping.original_id = phone_grouping.replace_id;
                                phone_grouping.original_type = phone_grouping.replace_type;

                                phone_grouping.phones.forEach(phone_1 => {
                                    phone_1.apps.toArray().forEach(app => {
                                        if (app.props.type != Enum.App.ANDROID && phone_1.props.type == Enum.Phone.Type.ANDROID) {
                                            app.props.type = Enum.App.ANDROID;
                                        }
                                        if (app.props.type != Enum.App.IPHONE && phone_1.props.type == Enum.Phone.Type.IPHONE) {
                                            app.props.type = Enum.App.IPHONE;
                                        }
                                        if (app.props.type != Enum.App.SIMULATOR && phone_1.props.type == Enum.Phone.Type.SIMULATOR) {
                                            app.props.type = Enum.App.SIMULATOR;
                                        }
                                    });
                                });
                                // because on some apps, type might change when we change phone type
                                this.group_apps();
                            })
                            .catch((e) => {
                                console.error(e)
                            })
                            .finally(() => {
                                phone_grouping.save_in_progress = false;
                            })
        },
        apply_app_grouping(app_grouping: AppGrouping) {
            if (this.form_validator.invalid) return null;

            app_grouping.save_in_progress = true
            return PlayGroup.ClientClass.apply_app_mappings(app_grouping)
                            .then(() => {
                                app_grouping.original_id = app_grouping.replace_id;
                                app_grouping.original_package = app_grouping.replace_package;
                            }).finally(() => {
                    app_grouping.save_in_progress = false;
                })
        },
        async apply_proxy_grouping(proxy_grouping: ProxyGrouping) {
            if (this.form_validator.invalid) return null;

            proxy_grouping.save_in_progress = true
            try {
                await PlayGroup.ClientClass.apply_proxy_mappings(proxy_grouping);
                proxy_grouping.original_id = proxy_grouping.replace_id;
            } finally {
                proxy_grouping.save_in_progress = false;
            }
        },
        get_scenario_setting_mappings() {
            const mapping: ScenarioSettingMapping = {}
            if (this.simple_modal === true && this.simple_modal_defaults_set) {
                if (this.has_web) {
                    let version = this.simple_browser_id
                    if (version == null) {
                        if (this.simple_browser_type == Enum.Browser.CHROME) {
                            version = Enum.Scenario.Setting.Browser.Scope.LATEST_CHROME
                        } else {
                            version = Enum.Scenario.Setting.Browser.Scope.LATEST_FIREFOX
                        }
                    }
                    const browser_mapping: any = { version }
                    if (this.simple_browser_width != null) browser_mapping.width = this.simple_browser_width
                    if (this.simple_browser_height != null) browser_mapping.height = this.simple_browser_height
                    mapping.browsers = { "*": browser_mapping }
                }
                if (this.has_mobile) {
                    let replace_with = this.simple_mobile_phone_project_id
                    if (replace_with == null) {
                        switch (this.simple_mobile_type) {
                            case Enum.Phone.Type.ANDROID:
                                replace_with = Enum.Scenario.Setting.Phone.Scope.ANY_ANDROID
                                break;
                            case Enum.Phone.Type.IPHONE:
                                replace_with = Enum.Scenario.Setting.Phone.Scope.ANY_IPHONE
                                break;
                            case Enum.Phone.Type.SIMULATOR:
                                replace_with = Enum.Scenario.Setting.Phone.Scope.ANY_SIMULATOR
                                break;
                            default:
                                throw new Error(`Unknown phone type (${this.simple_mobile_type})`)
                        }
                    }
                    const phone_mapping: any = {}
                    phone_mapping[Enum.Scenario.Setting.Phone.Scope.ALL_ANDROID] = replace_with
                    phone_mapping[Enum.Scenario.Setting.Phone.Scope.ALL_IPHONE] = replace_with
                    phone_mapping[Enum.Scenario.Setting.Phone.Scope.ALL_SIMULATOR] = replace_with
                    mapping.phones = phone_mapping

                    const replace_app_package_with = this.simple_mobile_package
                    let replace_app_version_with = this.simple_app_id
                    if (replace_app_version_with == null) replace_app_version_with = Enum.Scenario.Setting.Phone.App.Scope.LATEST
                    const replace_app_with = { "*_*": { version: replace_app_version_with, package: replace_app_package_with } }
                    const app_mapping: any = {}
                    app_mapping[Enum.App.ANDROID] = replace_app_with
                    app_mapping[Enum.App.IPHONE] = replace_app_with
                    app_mapping[Enum.App.SIMULATOR] = replace_app_with
                    mapping.mobile_apps = app_mapping
                }

                mapping.proxies = { "*": this.simple_proxy_id }
            } else {
                for (const is_mobile in this.grouped_browsers) {
                    const browser_mapping: BrowserMapping = {}
                    if (is_mobile == "true") {
                        mapping.mobile_browsers = browser_mapping
                    } else {
                        mapping.browsers = browser_mapping
                    }
                    const mobility = this.grouped_browsers[is_mobile]
                    for (const type in mobility) {
                        const types = mobility[type]
                        for (const browser_id in types) {
                            const grouping = types[browser_id]
                            if (this.is_browser_grouping_changed(grouping)) {
                                let replace_with: string | number = grouping.replace_id
                                if (replace_with == null) {
                                    if (grouping.replace_type == Enum.Browser.CHROME) {
                                        replace_with = Enum.Scenario.Setting.Browser.Scope.LATEST_CHROME
                                    } else {
                                        replace_with = Enum.Scenario.Setting.Browser.Scope.LATEST_FIREFOX
                                    }
                                }

                                let replace_id: string | number = grouping.original_id
                                if (replace_id == null) {
                                    if (grouping.original_type == Enum.Browser.CHROME) {
                                        replace_id = Enum.Scenario.Setting.Browser.Scope.LATEST_CHROME
                                    } else {
                                        replace_id = Enum.Scenario.Setting.Browser.Scope.LATEST_FIREFOX
                                    }
                                }

                                browser_mapping[replace_id] = {
                                    version: replace_with,
                                    is_mobile: grouping.replace_is_mobile,
                                    width: grouping.replace_width,
                                    height: grouping.replace_height,
                                    type: grouping.replace_type,
                                    emulation_profile_name: grouping.replace_emulation_profile_name,
                                }
                            }
                        }
                    }
                }
                for (const type in this.grouped_phones) {
                    if (mapping.phones == null) mapping.phones = {}
                    const types = this.grouped_phones[type]
                    for (const phone_project_id in types) {
                        const grouping = types[phone_project_id]
                        if (this.is_phone_grouping_changed(grouping)) {
                            let replace_with: string | number = grouping.replace_id
                            if (replace_with == null) {
                                switch (grouping.replace_type) {
                                    case Enum.Phone.Type.ANDROID:
                                        replace_with = Enum.Scenario.Setting.Phone.Scope.ANY_ANDROID
                                        break;
                                    case Enum.Phone.Type.IPHONE:
                                        replace_with = Enum.Scenario.Setting.Phone.Scope.ANY_IPHONE
                                        break;
                                    case Enum.Phone.Type.SIMULATOR:
                                        replace_with = Enum.Scenario.Setting.Phone.Scope.ANY_SIMULATOR
                                        break;
                                    default:
                                        throw new Error(`Unknown phone type (${grouping.replace_type})`)
                                }
                            }

                            let replace_id: string | number = grouping.original_id
                            if (replace_id == null) {
                                switch (grouping.original_type) {
                                    case Enum.Phone.Type.ANDROID:
                                        replace_id = Enum.Scenario.Setting.Phone.Scope.ANY_ANDROID
                                        break;
                                    case Enum.Phone.Type.IPHONE:
                                        replace_id = Enum.Scenario.Setting.Phone.Scope.ANY_IPHONE
                                        break;
                                    case Enum.Phone.Type.SIMULATOR:
                                        replace_id = Enum.Scenario.Setting.Phone.Scope.ANY_SIMULATOR
                                        break;
                                    default:
                                        throw new Error(`Unknown phone type (${grouping.original_type})`)
                                }
                            }

                            mapping.phones[replace_id] = replace_with
                        }
                    }
                }

                for (const type in this.grouped_apps) {
                    const types = this.grouped_apps[type]
                    const app_mapping: MobileAppMapping = {}
                    if (mapping.mobile_apps == null) mapping.mobile_apps = {}
                    mapping.mobile_apps[type as EnumApp] = app_mapping

                    for (const app_package in types) {
                        const app_packages = types[app_package]
                        for (const app_id in app_packages) {
                            const grouping = app_packages[app_id]
                            if (this.is_app_grouping_changed(grouping)) {
                                let replace_with: string | number = grouping.replace_id
                                if (replace_with == null) replace_with = Enum.Scenario.Setting.Phone.App.Scope.LATEST

                                let replace_id = `${grouping.original_package}_${grouping.original_id}`
                                if (grouping.original_id == null) {
                                    replace_id = `${grouping.original_package}_latest`
                                }

                                app_mapping[replace_id] = {
                                    version: replace_with,
                                    package: grouping.replace_package,
                                }
                            }
                        }
                    }
                }

                for (const proxy_id in this.grouped_proxies) {
                    const grouping = this.grouped_proxies[proxy_id]
                    if (this.is_proxy_grouping_changed(grouping)) {
                        if (mapping.proxies == null) mapping.proxies = {}
                        let replace_id: string | number = grouping.original_id
                        if (replace_id == null) replace_id = "null"
                        mapping.proxies[replace_id] = grouping.replace_id
                    }
                }
            }


            mapping.locale = {
                "*": this.locale,
            }

            return mapping
        },
        async confirm() {
            const res = await Play.ClientClass.add_to_queue(this.data.id, this.play_type, this.play_setting,
                this.play_setting.id, this.replay_play?.props?.id);
            if (res.success == true) {
                toastr.success(res.response);
                this.cancel();
            } else {
                toastr.error(res.response);
            }
        },
        cancel() {
            this.$.appContext.app.unmount();
        },
        copy_curl_request() {
            $.get("/api/generate_new_token", (data) => {
                copy_curl_request(data.token, this.data.api_id, this.play_type, this.play_setting)
                this.curl_copied = true
                setTimeout(() => {
                    this.curl_copied = false
                }, 3000);
            });
        },
        browser_name(browser_type: string) {
            switch (browser_type) {
                case Enum.Browser.FIREFOX:
                    return "Firefox";
                case Enum.Browser.CHROME:
                    return "Chrome";
                default:
                    return browser_type
            }
        },
        save_settings() {
            const play_setting = _.cloneDeep(this.play_setting)
            play_setting.workers.forEach(worker => {
                delete worker.vue_key
            })
            this.save_loading = true
            Play.ClientClass.save_settings(this.data.id, this.play_type, play_setting)
                .then(() => {
                    this.original_play_setting = JSON.parse(JSON.stringify(this.play_setting))
                })
                .finally(() => {
                    this.save_loading = false
                    this.show_save_icon = false
                })
        },
        available_phones(phone_type: string): PhoneProject[] {
            switch (phone_type) {
                case Enum.Phone.Type.ANDROID:
                    return this.android_phone_projects
                case Enum.Phone.Type.IPHONE:
                    return this.iphone_phone_projects
                case Enum.Phone.Type.SIMULATOR:
                    return this.simulator_phone_projects
                default:
                    // if something is saved wrong, user wont be able to fix it if we raise error
                    console.error(`Unsupported phone type (${phone_type})`)
                    return []
            }
        },
        available_packages(app_type: string) {
            switch (app_type) {
                case Enum.App.ANDROID:
                    return this.android_packages
                case Enum.App.IPHONE:
                    return this.iphone_packages
                case Enum.App.SIMULATOR:
                    return this.simulator_packages
                default:
                    // if something is saved wrong, user wont be able to fix it if we raise error
                    console.error(`Unsupported app type (${app_type})`)
                    return []
            }
        },
        available_apps(app_type: string, app_package: string) {
            switch (app_type) {
                case Enum.App.ANDROID:
                    return this.android_versions[app_package]
                case Enum.App.IPHONE:
                    return this.iphone_versions[app_package]
                case Enum.App.SIMULATOR:
                    return this.simulator_versions[app_package]
                default:
                    // if something is saved wrong, user wont be able to fix it if we raise error
                    console.error(`Unsupported app type (${app_type})`)
                    return []
            }
        },
        group_browsers() {
            const browsers = this.scenario_settings_for_play
                                 .filter(scenario_setting => scenario_setting.props.web_module_active)
                                 .map(scenario_setting => scenario_setting.browsers.toArray())
                                 .flat()
                                 .map(browser => markRaw(browser))

            const grouped_browsers: GroupedBrowsers = {}

            // first group is mobile / is not mobile
            const mobilities = browsers.group_by("props.is_mobile")
            for (const is_mobile in mobilities) {
                grouped_browsers[is_mobile] = {}
                const mobility_browsers = mobilities[is_mobile]

                // secondly group by type
                const types = mobility_browsers.group_by("props.type")
                for (const type in types) {
                    grouped_browsers[is_mobile][type] = {}
                    const type_browsers = types[type]

                    // thirdly, group by browser id
                    const browser_ids = type_browsers.group_by("props.browser_id")
                    for (const browser_id in browser_ids) {
                        const browsers = browser_ids[browser_id]

                        const scenario_names = ScenarioSettingBrowser
                            .where({ id: browsers.map(browser => browser.props.id) })
                            .map(browser => browser.scenario_setting.scenario?.props?.name)
                            .filter(name => !Validator.is_empty(name))
                            .join("\n")


                        const replace_browser: ReplaceBrowser = {
                            version: browser_id == "null" ? null : parseInt(browser_id),
                            emulation_profile_name: Browser.emulation_profiles[0].name,
                            height: null,
                            width: null,
                            is_mobile: is_mobile == "true",
                            type: type as EnumBrowserValues,
                        }
                        const saved_mappings = this.play_setting.scenario_setting_mappings
                        const saved_browser_mappings = is_mobile == "true" ? saved_mappings.mobile_browsers : saved_mappings.browsers
                        if (saved_browser_mappings != null) {
                            let replace_mapping_id = browser_id
                            if (browser_id == "null") {
                                if (type == Enum.Browser.CHROME) {
                                    replace_mapping_id = Enum.Scenario.Setting.Browser.Scope.LATEST_CHROME
                                } else {
                                    replace_mapping_id = Enum.Scenario.Setting.Browser.Scope.LATEST_FIREFOX
                                }
                            }
                            const saved_browser_mapping: string | ReplaceBrowser = saved_browser_mappings[replace_mapping_id];
                            if (saved_browser_mapping != null) {
                                if (what_is_it(saved_browser_mapping) == "String") {
                                    replace_browser.version = null
                                } else {
                                    Object.assign(replace_browser, saved_browser_mapping)
                                }
                            }
                        }

                        // for backward compatibility
                        // before types were added, version would accept string such as "latest_chrome"
                        // now, those strings just cast to null
                        const replace_id = what_is_it(replace_browser.version) == "String" ? null : replace_browser.version as number

                        grouped_browsers[is_mobile][type][browser_id] = {
                            browsers,
                            replace_id,
                            replace_emulation_profile_name: replace_browser.emulation_profile_name,
                            replace_height: replace_browser.height,
                            replace_is_mobile: replace_browser.is_mobile,
                            replace_type: replace_browser.type,
                            replace_width: replace_browser.width,
                            original_id: browser_id == "null" ? null : parseInt(browser_id),
                            original_emulation_profile_name: Browser.emulation_profiles[0].name,
                            original_height: null,
                            original_is_mobile: is_mobile == "true",
                            original_type: type as EnumBrowserValues,
                            original_width: null,
                            save_in_progress: false,
                            scenario_names,
                        }
                    }
                }
            }

            this.grouped_browsers = grouped_browsers;
        },
        group_phones() {
            const phones = this.scenario_settings_for_play
                               .filter(scenario_setting => scenario_setting.props.mobile_module_active)
                               .map(scenario_setting => scenario_setting.phones.toArray())
                               .flat()
                               .map(phone => markRaw(phone))

            const grouped_phones: GroupedPhones = {}
            // first group by type
            const types = phones.group_by("props.type")
            for (const type in types) {
                grouped_phones[type] = {}
                const type_phones = types[type]

                // secondly group by phone_project_id
                const phone_ids = type_phones.group_by("props.phone_project_id")
                for (const phone_project_id in phone_ids) {
                    const phones = phone_ids[phone_project_id]

                    const scenario_names = ScenarioSettingPhone
                        .where({ id: phones.map(phone => phone.props.id) })
                        .map(phone => phone.scenario_setting.scenario?.props?.name)
                        .filter(name => !Validator.is_empty(name))
                        .join("\n")


                    let replace_id = phone_project_id == "null" ? null : parseInt(phone_project_id)
                    let replace_type = type

                    const saved_phone_mappings = this.play_setting.scenario_setting_mappings.phones
                    if (saved_phone_mappings != null) {
                        let replace_phone: string | number = null;
                        if (phone_project_id == "null") {
                            switch (type) {
                                case Enum.Phone.Type.ANDROID:
                                    replace_phone = Enum.Scenario.Setting.Phone.Scope.ANY_ANDROID
                                    break;
                                case Enum.Phone.Type.IPHONE:
                                    replace_phone = Enum.Scenario.Setting.Phone.Scope.ANY_IPHONE
                                    break;
                                case Enum.Phone.Type.SIMULATOR:
                                    replace_phone = Enum.Scenario.Setting.Phone.Scope.ANY_SIMULATOR
                                    break;
                                default:
                                    throw new Error(`Unsupported phone type (${type})`)
                            }
                        } else {
                            replace_phone = parseInt(phone_project_id)
                        }
                        const saved_phone_mapping = saved_phone_mappings[replace_phone]
                        if (saved_phone_mapping != null) {
                            switch (saved_phone_mapping) {
                                case Enum.Scenario.Setting.Phone.Scope.ANY_ANDROID:
                                    replace_id = null;
                                    replace_type = Enum.Phone.Type.ANDROID;
                                    break;
                                case Enum.Scenario.Setting.Phone.Scope.ANY_IPHONE:
                                    replace_id = null;
                                    replace_type = Enum.Phone.Type.IPHONE;
                                    break;
                                case Enum.Scenario.Setting.Phone.Scope.ANY_SIMULATOR:
                                    replace_id = null;
                                    replace_type = Enum.Phone.Type.SIMULATOR;
                                    break;
                                default:
                                    replace_id = parseInt(saved_phone_mapping as string);
                                    replace_type = PhoneProject.find(replace_id)?.phone?.props?.phone_type
                            }
                        }
                    }

                    grouped_phones[type][phone_project_id] = {
                        phones,
                        replace_id,
                        replace_type: replace_type as EnumPhoneType,
                        original_id: phone_project_id == "null" ? null : parseInt(phone_project_id),
                        original_type: type as EnumPhoneType,
                        save_in_progress: false,
                        scenario_names,
                    }
                }
            }

            this.grouped_phones = grouped_phones;
        },
        group_apps() {
            const apps = this.scenario_settings_for_play
                             .filter(scenario_setting => scenario_setting.props.mobile_module_active)
                             .map(scenario_setting => scenario_setting.phones.toArray())
                             .flat()
                             .map(phone => phone.apps.toArray())
                             .flat()
                             .map(app => markRaw(app))

            const grouped_apps: GroupedApps = {}
            // first group by type
            const types = apps.group_by("props.type")

            for (const type in types) {
                grouped_apps[type] = {}
                const type_apps = types[type]

                // secondly group by package
                const packages = type_apps.group_by("props.package")
                for (const app_package in packages) {
                    grouped_apps[type][app_package] = {}
                    const package_apps = packages[app_package];

                    // thirdly group by app_id
                    const app_ids = package_apps.group_by("props.app_id")
                    for (const app_id in app_ids) {
                        const apps = app_ids[app_id]

                        const scenario_names = ScenarioSettingPhoneApp
                            .where({ id: apps.map(app => app.props.id) })
                            .map(app => {
                                const scenario_setting = app.phone?.scenario_setting
                                if (scenario_setting == null) return null;

                                return scenario_setting.scenario?.props?.name
                            })
                            .filter(name => !Validator.is_empty(name))
                            .join("\n")

                        let replace_id = app_id == "null" ? null : parseInt(app_id)
                        let replace_package = app_package
                        const replace_type = type

                        const saved_app_mappings = this.play_setting.scenario_setting_mappings.mobile_apps
                        if (saved_app_mappings != null) {
                            const saved_app_type_mappings = saved_app_mappings[type as EnumPhoneType]
                            if (saved_app_type_mappings != null) {
                                let replace_app_mapping_id: string | number = null
                                if (app_id == "null") {
                                    replace_app_mapping_id = `${app_package}_latest`
                                } else {
                                    replace_app_mapping_id = `${app_package}_${app_id}`
                                }
                                const saved_app_type_mapping = saved_app_type_mappings[replace_app_mapping_id]
                                if (saved_app_type_mapping != null) {
                                    if (saved_app_type_mapping.version == Enum.Scenario.Setting.Phone.App.Scope.LATEST) {
                                        replace_id = null
                                    } else {
                                        replace_id = saved_app_type_mapping.version as number
                                    }
                                    replace_package = saved_app_type_mapping.package
                                }
                            }
                        }

                        grouped_apps[type][app_package][app_id] = {
                            apps,
                            replace_id,
                            replace_package,
                            replace_type,
                            original_id: app_id == "null" ? null : parseInt(app_id),
                            original_package: app_package,
                            original_type: type,
                            save_in_progress: false,
                            scenario_names,
                        }
                    }
                }
            }

            this.grouped_apps = grouped_apps
        },
        group_proxies() {
            const scenario_settings = this.scenario_settings_for_play.toArray().map(setting => markRaw(setting))

            const grouped_proxies: GroupedProxies = {}

            const proxy_ids = scenario_settings.group_by("props.proxy_id")
            for (const proxy_id in proxy_ids) {
                const scenario_settings = proxy_ids[proxy_id]

                const scenario_names = ScenarioSetting
                    .where({ id: scenario_settings.map(scenario_setting => scenario_setting.props.id) })
                    .map(scenario_setting => scenario_setting.scenario?.props?.name)
                    .filter(name => !Validator.is_empty(name))
                    .join("\n")

                let replace_id = proxy_id == "null" ? null : parseInt(proxy_id)
                const saved_proxy_mappings = this.play_setting.scenario_setting_mappings.proxies
                if (saved_proxy_mappings != null) {
                    if (saved_proxy_mappings.hasOwnProperty(proxy_id)) {
                        replace_id = saved_proxy_mappings[proxy_id]
                    }
                }

                grouped_proxies[proxy_id] = {
                    // scenario_settings: markRaw(scenario_settings),
                    scenario_settings,
                    replace_id,
                    original_id: proxy_id == "null" ? null : parseInt(proxy_id),
                    save_in_progress: false,
                    scenario_names,
                }
            }

            this.grouped_proxies = grouped_proxies;
        },
        add_worker() {
            const worker: PlayModalWorkerProps = {
                name: "Worker",
                extra_variables: "",
                vue_key: generate_uuid(),
                load: this.scenario_count,
            }
            this.play_setting.workers.push(worker)
            this.play_setting.workers.forEach((worker, index) => {
                worker.name = `Worker ${index + 1}`
            })
            this.update_worker_load()
        },
        remove_worker(worker: PlayModalWorkerProps) {
            this.play_setting.workers = this.play_setting.workers.filter(w => w.vue_key != worker.vue_key)
            this.play_setting.workers.forEach((worker, index) => {
                worker.name = `Worker ${index + 1}`
            })
            this.update_worker_load()
        },
        update_worker_load() {
            const worker_count = this.play_setting.workers.length
            const load_per_worker = Math.floor(this.scenario_count / worker_count)
            let leftover_scenarios = this.scenario_count % worker_count
            this.play_setting.workers.forEach(worker => {
                worker.load = load_per_worker
                if (leftover_scenarios > 0) {
                    worker.load += 1
                    --leftover_scenarios;
                }
            })
        },
    },
// </editor-fold>
})
</script>
<style lang="scss">
#play_modal {
  .vue-modal-content {
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  padding: 0;

  .scenarios-count {
    flex-shrink: 0;
    width: 50px;
    color: var(--font-color-secondary);
    font-size: 13px;
    text-align: left;
  }

  .title {
    text-align: center;
    font-size: 25px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .modal-complexity-switcher {
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
    white-space: nowrap;
  }
}

$side-item-width: 40px;
#play_modal {
  .body {
    .grouping {
      margin-bottom: 20px;

    }

    .row {
      margin-bottom: 10px;
      position: relative;

      &.label-row {
        margin-bottom: 3px;
        width: calc(100% - 45px);
        margin-left: 0;

        div.col {
          margin: 0;
        }
      }

      .reduced-inline-row {
        width: calc(100% - 45px);
        display: flex;
        flex-direction: row;
      }

      .row-save {
        width: 30px;
        font-size: 0.95em;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .advanced-item {
        display: none;
      }

      .side-item-container {
        display: none;
        position: absolute;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        font-size: 1.4em;
        align-items: center;
        width: $side-item-width;
        right: -$side-item-width;

        .side-item {
          width: 40px;
          height: 40px;
          cursor: pointer;
          display: flex;

          &.add {
            color: var(--button-green);
            margin-top: 30px;
          }

          &.remove {
            margin-top: 17px;
            color: var(--button-red);
          }
        }
      }

      &.play-settings {
        div.col {
          min-height: 55px;
          align-items: end;
        }

        .schedule-settings {
          flex-direction: row;
          align-items: center;

          div.schedule-setting-item {
            margin-inline: 5px;
            width: 100%;
          }
        }

        .worker-settings {
          padding-top: 10px;
          padding-inline: 0;

          div.col {
            min-height: 0;
          }
        }
      }
    }

    .edit-variable-set {
      cursor: pointer;
      aspect-ratio: 1;
      display: flex;
      margin-top: 18px;
      font-size: 1.3em;
    }
  }

  .body {
    &.advanced-play {
      .row {
        margin-right: $side-item-width;

        .side-item-container {
          display: flex;
        }

        .advanced-item {
          display: flex;
        }
      }
    }
  }
}

.curl-request {
  color: var(--font-color-secondary);
  font-size: 0.6em;
  cursor: pointer;
  align-self: end;
}

.first-row-label-padding {
  padding-top: 14px;
}

.browser-is-mobile-toggle,
.mobile-type-toggle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  //padding: 3px;
  font-size: 1.3em;
}
</style>
