import moment from "moment";

function set(m: moment.Moment, el: HTMLElement, strftime: string) {
    el.innerText = m.strftime(strftime)
    el.title = m.toString();
}

function parse_binding(binding: any) {
    let date = binding.value
    let strftime = "%H:%M:%S %d.%m.%Y"
    if (binding.value.strftime != null) {
        date = binding.value.datetime
        strftime = binding.value.strftime
    }
    return { date, strftime }
}

// strftime
// https://apidock.com/ruby/DateTime/strftime
export var v_datetime = {
    mounted(el: any, binding: any) {
        if (binding.value == null) return;

        const obj = parse_binding(binding)
        const m = moment(obj.date)
        set(m, el, obj.strftime)
    },
    updated(el: any, binding: any, _vnode: any, _prevNode: any) {
        if (binding.value == null) {
            el.innerText = "";
            el.title = null;
            return;
        }
        const obj = parse_binding(binding)
        const m = moment(obj.date)
        set(m, el, obj.strftime)
    },
}
