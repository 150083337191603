<template>
  <Table :id="id">
    <template #thead>
      <tr>
        <template v-for="(col, key) in visible_columns"
                  :key="key">
          <th v-if="key == 'delete'"
              :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
              style="cursor: pointer"
              @click.stop="all_marked = !all_marked">
            <Checkbox
                v-model="all_marked"
                label=""
                :for_table="true"
                color_class="red"
            />
          </th>
          <th v-else
              :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
              :data-priority="orders.priority(key)"
              @click="orders.toggle(key)">
            {{ col.name }}
          </th>
        </template>
      </tr>
    </template>

    <template #tbody>
      <tr v-for="row in ordered_rows"
          :key="row.record.props.id"
          class="user-row"
          @click="$emit('user-click', row.record)">
        <template v-for="(col, index) in row.cols"
                  :key="index">
          <td v-if="col.column_key == 'delete'"
              :title="col.title"
              :class="col.classes"
              @click.stop="deletion_markers.marker(row.record).toggle()">
            <Checkbox
                v-model="deletion_markers.marker(row.record).value"
                label=""
                :for_table="true"
                color_class="red"
            />
          </td>
          <td v-else
              v-moment="col.moment"
              :title="col.title"
              :class="col.classes"
              v-html="col.html"/>
        </template>
      </tr>
    </template>
  </Table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { User } from "../../../../vue_record/models/user";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import Table from "../../../testa/Table.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";

export default defineComponent({
    components: { Checkbox, Table },
    // <editor-fold desc="PROPS">
    props: {
        id: {
            type: String,
            required: true,
        },
        filter: {
            type: String,
            required: true,
        },
        users: {
            type: Array as PropType<User[]>,
            required: true
        },
        project: {
            type: Object as PropType<Project>,
            required: false,
            default: null,
        },
        deletion_markers: {
            type: Object as PropType<RecordMarker>,
            required: true
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['user-click'],
    // <editor-fold desc="DATA">
    data() {
        return {
            all_marked: false,
            columns: {
                delete: {
                    orderable: false,
                    filterable: false,
                    action: true,
                    classes: "red",
                    hidden: this.is_viewer_role || this.is_user_role,
                },
                name: {
                    name: "Name",
                    html: (record) => record.props.first_name,
                },
                surname: {
                    name: "Surname",
                    html: (record) => record.props.last_name,
                },
                email: {
                    name: "Email",
                    html: (record) => record.props.email,
                },
                status: {
                    name: "Status",
                    html: (record) => record.props.confirmed_at == null ? 'Unconfirmed' : 'Active',
                },
                username: {
                    name: "Username",
                    html: (record) => record.props.username,
                },
                role: {
                    name: "Role",
                    html: (record) => record.user_projects
                                     .where({ project_id: this.project.props.id })
                                     .first()?.props?.role,
                    hidden: this.project == null,
                },
            } as RecordColumns<User>,
            orders: new RecordOrder([["email", "asc"]])
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        visible_columns() {
            return RecordsTable.visible_columns<User>(this.columns);
        },
        rows() {
            return RecordsTable.generate_rows<User>(this.visible_columns, this.users as User[])
        },
        filtered_rows() {
            return RecordsTable.filter<User>({
                columns: this.visible_columns,
                filter: this.filter,
                rows: this.rows,
            })
        },
        ordered_rows() {
            return RecordsTable.order<User>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'filtered_rows'() {
            this.deletion_markers.set_records(this.filtered_rows.map(c => c.record) as User[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.user-row {
  cursor: pointer;
}
</style>
