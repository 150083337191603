import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { SnippetSavepointClient } from "../clients/snippet_savepoint_client";
import { SnippetSavepointScope } from "../scopes/snippet_savepoint_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface SnippetSavepointProps extends Props {
    id: number
    name: string
    created_at: Date
    updated_at: Date
    snippet_id: number
    user_id: number
    code: string
    save_id: string
    amendable: boolean
    archived: boolean
    snippet_folder_git_id: string
}
export type QuerifiedSnippetSavepointProps = QuerifyProps<SnippetSavepointProps>
export type SnippetSavepointCreateProps = Omit<SnippetSavepointProps, 'id'>
export type SnippetSavepointUpdateProps = Partial<SnippetSavepointProps>

export interface SnippetSavepointState extends State {}
export interface SnippetSavepointComputed extends Computed {}
export interface SnippetSavepointStaticState extends StaticState {
    load_promises: Record<number | string, Promise<SnippetSavepoint>>
}

// </editor-fold>

const console = new Consoler("warn")
export class SnippetSavepoint extends VueRecord {
    ['constructor']: typeof SnippetSavepoint

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = SnippetSavepointClient
    static ScopeClass = SnippetSavepointScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: SnippetSavepointStaticState = reactive<SnippetSavepointStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "snippet_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof SnippetSavepoint> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof SnippetSavepoint>> = {}

    static field_validators: ModelValidatorOpts<SnippetSavepointProps> = {}

    static resource_name = Enum.Resource.Label.SNIPPET_SAVEPOINT
    static resource_id = Enum.Resource.Id.SNIPPET_SAVEPOINT
    static icon_class = "fa-solid fa-pencil"
    static color = () => get_css_var("--snippet-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: SnippetSavepointClient
    declare props: SnippetSavepointProps;
    declare state: SnippetSavepointState;
    declare computed: SnippetSavepointComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
SnippetSavepoint.register_resource(SnippetSavepoint)
SnippetSavepointClient.ModelClass = SnippetSavepoint
SnippetSavepointScope.ModelClass = SnippetSavepoint

global_event_bus.$on("after_project_version_unload", () => {
    SnippetSavepoint.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            SnippetSavepoint.unsync()
            if (project_version_id != null) SnippetSavepoint.sync(`/sync/project_version/${project_version_id}/snippet_savepoints`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        SnippetSavepoint: typeof SnippetSavepoint
    }
}
window.SnippetSavepoint = SnippetSavepoint
// </editor-fold>

