import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7058d35"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-container" }
const _hoisted_2 = { class: "title-primary-info" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = {
  key: 0,
  class: "title-secondary-info"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.phone.name()), 1 /* TEXT */)
      ])
    ]),
    (_ctx.show_secondary_info)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.phone.props.used_by)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.user_message), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.phone.props.status == _ctx.Enum.Phone.Status.IN_USE)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.phone.props.in_use_by_play_scenario_id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.play_scenario_message), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (_ctx.phone.props.status == _ctx.Enum.Phone.Status.CLAIMED)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.phone.props.claimed_by_play_worker_group_id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.claimed_message), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}