<template>
  <div class="title-container">
    <div class="title-primary-info">
      <div class="name">
        <span>{{ phone.name() }}</span>
      </div>
    </div>
    <div v-if="show_secondary_info"
         class="title-secondary-info">
      <div v-if="phone.props.used_by">
        <span>{{ user_message }}</span>
      </div>
      <template v-if="phone.props.status == Enum.Phone.Status.IN_USE">
        <div v-if="phone.props.in_use_by_play_scenario_id">
          <span>{{ play_scenario_message }}</span>
        </div>
      </template>
      <template v-if="phone.props.status == Enum.Phone.Status.CLAIMED">
        <div v-if="phone.props.claimed_by_play_worker_group_id">
          <span>{{ claimed_message }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Phone } from "../../vue_record/models/phone";
export default defineComponent({
    components: {},
    // <editor-fold desc="PROPS">
    props: {
        phone: {
            type: Object as PropType<Phone>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Enum() {
            return Enum
        },
        show_secondary_info() {
            return (this.phone.props.used_by != null ||
                this.phone.props.in_use_by_play_scenario_id != null ||
                this.phone.props.in_use_by_play_sandbox_id != null ||
                this.phone.props.claimed_by_play_worker_group_id != null) &&
                (this.phone.props.status == Enum.Phone.Status.IN_USE || this.phone.props.status == Enum.Phone.Status.CLAIMED)
        },
        user_message() {
            let prefix_word = "Used "
            if (this.phone.props.status == Enum.Phone.Status.CLAIMED) prefix_word = "Claimed "
            if (this.phone.used_by_user == null) {
                return `${prefix_word} by unknown user`
            } else if (this.phone.used_by_user.key() == current.user?.key()) {
                return `${prefix_word} by you`
            } else {
                let user_name = ""
                if (Enum.Phone.Status.CLAIMED == this.phone.props.status) {
                    user_name = this.phone.claimed_by_play_worker_group?.play?.user?.name()
                } else {
                    user_name = this.phone.used_by_user.name()
                }
                if (user_name == null) user_name = "unknown user"
                return `${prefix_word} by ${user_name}`
            }
        },
        play_scenario_message() {
            if (this.phone.in_use_by_play_scenario == null) {
                return "In unknown scenario"
            } else {
                return `In ${this.phone.in_use_by_play_scenario.name()}`
            }
        },
        claimed_message() {
            if (this.phone.claimed_by_play_worker_group?.play == null) {
                return "In unknown play"
            } else {
                return `In ${this.phone.claimed_by_play_worker_group.play.name()}`
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 2px;
  padding-bottom: 2px;

  .title-primary-info {
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
    align-items: baseline;

    .play-type-icon-container {
      font-size: 0.9em;
      margin-right: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    div.name {
      color: var(--font-color);
      margin-right: 5px;
      white-space: nowrap;
      flex-shrink: 0;

      span.highlight {
        background: var(--sidebar-selected-contrast);
      }
    }
  }

  .title-secondary-info {
    color: var(--font-color-secondary);
    font-size: 0.9em;
  }
}

</style>
