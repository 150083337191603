<template>
  <SettingsItem
      :id="setting_items.user.preferences.id"
      :title="setting_items.user.preferences.title"
  >
    <div class="row align-start">
      <div class="col s6 vertical">
        <Checkbox
            id="decouple_streams_checkbox"
            v-model="user_props.decouple_streams"
            label="Decouple streams from reports"
        />
        <Checkbox
            id="snippet_line_wrap_checkbox"
            v-model="user_props.snippet_line_wrap"
            label="Wrap snippet lines"
        />
        <Checkbox
            id="show_hints_as_you_type_checkbox"
            v-model="user_props.show_hints_as_you_type"
            label="Show hits as you type"
        />
        <Checkbox
            id="dark_mode_checkbox"
            v-model="user_props.dark_mode"
            label="Dark mode"
        />
        <Checkbox
            id="hover_zoom_checkbox"
            v-model="user_props.hover_zoom_enabled"
            label="Hover zoom"
        />
        <Checkbox
            id="auto_expand_play_scenarios_checkbox"
            v-model="user_props.auto_expand_play_scenarios"
            label="Auto-expand play scenarios"
        />
      </div>
      <div class="col s6 vertical">
        <div class="row">
          <div class="col s12">
            <ColorPicker
                v-model="user_setting_props.subtitle_color"
                label="Subtitle color"
                :colors="Enum.User.Setting.Subtitle.COLORS"
                :allow_blank="false"
                :validator="form_validator.register('subtitle_color', UserSetting.field_validators.subtitle_color)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <Select2
                id="subtitle_position_select"
                v-model="user_setting_props.subtitle_position"
                label="Subtitle Position"
                :validator="form_validator.register('subtitle_position', UserSetting.field_validators.subtitle_position)"
                dropdown_parent="#vue_settings_modal"
            >
              <option :value="Enum.User.Setting.Subtitle.Position.TOP">
                {{
                  Enum.User.Setting.Subtitle.Position.TOP
                }}
              </option>
              <option :value="Enum.User.Setting.Subtitle.Position.BOTTOM">
                {{
                  Enum.User.Setting.Subtitle.Position.BOTTOM
                }}
              </option>
            </Select2>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <Select2
                id="subtitle_size_select"
                v-model="user_setting_props.subtitle_size"
                label="Subtitle Size"
                :validator="form_validator.register('subtitle_size', UserSetting.field_validators.subtitle_size)"
                dropdown_parent="#vue_settings_modal"
            >
              <template v-for="index in subtitle_size_range"
                        :key="index">
                <option :value="index">{{ index }}</option>
              </template>
            </Select2>
          </div>
        </div>
      </div>
    </div>
  </SettingsItem>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import SettingsItem from "../../SettingsItem.vue";
import { ProjectVersionSetting } from "../../../../vue_record/models/project_version_setting";
import Select2 from "../../../testa/Select2.vue";
import { UserSetting } from "../../../../vue_record/models/user_setting";
import _ from "lodash";
import ColorPicker from "../../../testa/ColorPicker.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        ColorPicker,
        Select2,
        Checkbox,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true,
        },
        user_setting: {
            type: Object as PropType<UserSetting>,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            user_props: {
                decouple_streams: current.user.props.decouple_streams,
                snippet_line_wrap: current.user.props.snippet_line_wrap,
                show_hints_as_you_type: current.user.props.show_hints_as_you_type,
                dark_mode: current.user.props.dark_mode,
                hover_zoom_enabled: current.user.props.hover_zoom_enabled,
                auto_expand_play_scenarios: current.user.props.auto_expand_play_scenarios,
            },
            user_setting_props: {
                subtitle_position: this.user_setting.props.subtitle_position,
                subtitle_size: this.user_setting.props.subtitle_size,
                subtitle_color: this.user_setting.props.subtitle_color,
            },
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        UserSetting() {
            return UserSetting
        },
        ProjectVersionSetting() {
            return ProjectVersionSetting
        },
        setting_items() {
            return setting_items
        },
        Enum() {
            return Enum
        },
        subtitle_size_range() {
            return _.range(Enum.User.Setting.Subtitle.Size.MIN, Enum.User.Setting.Subtitle.Size.MAX)
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {

        save() {
            return this.apply()
        },
        apply() {
            const user_update_promise = current.user.client.update(this.user_props, null)
            const user_setting_promise = this.user_setting.client.update(this.user_setting_props)
            return Promise.all([user_setting_promise, user_update_promise]).catch(() => {
                toastr.error("Failed to save preferences")
            })
        },

    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>

</style>
