import { FileScope } from "../scopes/file_scope";
import { FileFolderScope } from "../scopes/file_folder_scope";
import { ProjectVersion } from "../models/project_version";
import { FileFolder } from "../models/file_folder";
import { File } from "../models/file";

declare module "../models/file_folder" {
    interface FileFolder {
        project_version: ProjectVersion
        file_folder: FileFolder

        files: FileScope
        file_folders: FileFolderScope
    }
}

FileFolder.belongs_to("project_version", ProjectVersion, "project_version_id")
FileFolder.belongs_to("file_folder", FileFolder, "file_folder_path")


FileFolder.has_many("files", File, "file_folder_path")
FileFolder.has_many("file_folders", FileFolder, "file_folder_path")
