import { VueRecord } from "../vue_record";

interface MarkerState {
    [key: string | number]: Marker
}

export class Marker {
    value: boolean

    constructor(value: boolean) {
        this.value = value
    }

    toggle() {
        this.value = !this.value
    }
}

/**
 * For given array of records, remember which records are marked.
 * Used to track checkbox states
 */
export class RecordMarker {
    records: VueRecord[]
    default_state: boolean

    state: MarkerState = {}
    obsolete_states: MarkerState = {}

    constructor(records: VueRecord[], default_state = false) {
        this.default_state = default_state
        this.set_records(records)
    }

    set_records(records: VueRecord[]) {
        this.records = records;
        this.set_states()
    }

    set_all(value: boolean) {
        for (const id in this.state) {
            const marker = this.state[id];
            marker.value = value
        }
    }

    marker(record: VueRecord) {
        let marker = this.state[record.key()]
        if (marker == null) {
            marker = new Marker(this.default_state);
            this.state[record.key()] = marker
            this.records.push(record)
        }
        return marker
    }

    set_states() {
        // store all current to obsolete
        Object.assign(this.obsolete_states, this.state)

        this.state = {}
        this.records.forEach(record => {
            const key = record.key()
            const marker = this.obsolete_states[key]
            if (marker == null) {
                this.state[key] = new Marker(this.default_state)
                this.records.push(record)
            } else {
                this.state[key] = marker
            }
        })
    }

    marked() {
        const marked = []
        for (const id in this.state) {
            const marker = this.state[id];
            if (marker.value) {
                marked.push(this.records.find(record => record.key() == id))
            }
        }
        return marked
    }
}
