import { setBlockTracking as _setBlockTracking, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  ref: "video",
  controls: "",
  playsinline: "",
  autoplay: "",
  style: {"height":"100%","width":"100%"}
}
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _cache[0] || (
    _setBlockTracking(-1),
    _cache[0] = _createElementVNode("div", { ref: "container" }, [
      _createElementVNode("video", _hoisted_1, [
        _createElementVNode("source", {
          src: _ctx.url,
          type: "video/mp4"
        }, null, 8 /* PROPS */, _hoisted_2)
      ], 512 /* NEED_PATCH */)
    ], 512 /* NEED_PATCH */),
    _setBlockTracking(1),
    _cache[0]
  )
}