import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ac8ed05"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-flex-id"]
const _hoisted_2 = ["onMousedown", "onMouseenter", "onMouseleave"]
const _hoisted_3 = ["data-area-id", "onMousedown", "onMousemove", "onMouseleave"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: _normalizeClass(["rflex", {vertical: _ctx.vertical, horizontal: _ctx.horizontal, reverse: _ctx.reverse, fill: _ctx.fill}]),
    "data-flex-id": _ctx.rflex.id
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rflex.get_enabled_areas_with_sticks(), (area) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: area.id
      }, [
        (area.is_stick)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref_for: true,
              ref: (el) => area.container = el,
              class: "stick",
              style: _normalizeStyle(area.get_style()),
              onMousedown: _withModifiers((e) => _ctx.to_stick_area(area).handle_on_mousedown(e), ["self","stop"]),
              onMouseenter: _withModifiers((e) => _ctx.to_stick_area(area).handle_on_mouseenter(e), ["self"]),
              onMouseleave: _withModifiers((e) => _ctx.to_stick_area(area).handle_on_mouseleave(e), ["self"])
            }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              ref_for: true,
              ref: (el) => area.container = el,
              class: "area",
              "data-area-id": area.id,
              style: _normalizeStyle(area.get_style()),
              onMousedown: (e) => _ctx.to_content_area(area).handle_on_mousedown(e),
              onMousemove: (e) => _ctx.to_content_area(area).handle_on_mousemove(e),
              onMouseleave: _withModifiers((e) => _ctx.to_content_area(area).handle_on_mouseleave(e), ["self"])
            }, [
              _renderSlot(_ctx.$slots, area.id, {}, undefined, true)
            ], 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_3))
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}