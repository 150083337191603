import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ActionIcon = _resolveComponent("ActionIcon")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ActionIcon, {
      icon_class: "fa-solid fa-plus",
      color_class: "white",
      title: "Add device",
      no_click_focus: true,
      scale: 1.1,
      allow_concurrent_click_action: false,
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.Phone.show_add_to_project()))
    }),
    _createVNode(_component_ActionIcon, {
      icon_class: _ctx.Phone.state.sidebar_show_offline ? 'fa-solid fa-slash' : 'fa-solid fa-circle',
      title: "Toggle show offline phones",
      scale: 0.9,
      onClick: _cache[1] || (_cache[1] = () => _ctx.Phone.state.sidebar_show_offline = !_ctx.Phone.state.sidebar_show_offline)
    }, null, 8 /* PROPS */, ["icon_class"])
  ], 64 /* STABLE_FRAGMENT */))
}