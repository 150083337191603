import { Tab } from "../tab";
import { markRaw } from "vue";
import { reactive } from "vue";
import { Play } from "../../../../vue_record/models/play/play";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import NoVnc from "../../../play/reports/play_scenario/NoVnc.vue";
import { computed } from "../../../../helpers/vue/computed";

namespace PlayScenarioNovncTab {
    export interface Input extends Tab.Input {
        play_scenario_id: number
        play_id: number
    }

    export interface State extends Tab.State {
        record: PlayScenario
    }
}

export class PlayScenarioNovncTab extends Tab {
    static type = "PlayScenarioNovncTab"

    type: string
    play_scenario_id: number
    play_id: number

    declare state: PlayScenarioNovncTab.State

    constructor(data: PlayScenarioNovncTab.Input) {
        super(data)
        this.type = PlayScenarioNovncTab.type
        this.play_scenario_id = data.play_scenario_id;
        this.play_id = data.play_id
        this.state.icon.class = "fa-solid fa-eye"

        // if title is known, set it before we load, so that tabs are sorted correctly
        const play_scenario = PlayScenario.find(this.play_scenario_id)
        if (play_scenario != null) {
            this.computed.title = computed(() => play_scenario.props.name)
        }

        this.component = markRaw(NoVnc);
        this._set_and_call_load_function(() => {
            return PlayScenario.ClientClass
                               .load(this.play_scenario_id)
                               .then(play_scenario => {
                                   if (!play_scenario.has_vnc()) {
                                       this.close();
                                       return;
                                   }
                                   this.state.record = play_scenario

                                   this.state.icon.color = computed(() => Play.color())
                                   this.computed.title = computed(() => play_scenario.props.name)

                                   const project_id = Play.find(this.play_id).project_version.props.project_id
                                   this.component_props = computed(() => {
                                       return {
                                           stream_id: `play_scenario_${play_scenario.key()}_novnc_stream`,
                                           play_scenario,
                                           tab: this,
                                           scenario_setting_id: play_scenario.props.scenario_setting_id,
                                           view_only: current.role_is_viewer_for(project_id),
                                           is_inspector_shown: play_scenario.state.is_inspector_shown,
                                           backend_ws_channel: play_scenario.state.backend_ws_channel,
                                           url: play_scenario.vnc_url(),
                                           onNovncConnected: () => {
                                               if (this.tab_manager?.parent?.splitter == null) return;

                                               const xvfb_aspect_ratio = play_scenario.scenario_setting.props.xvfb_width / play_scenario.scenario_setting.props.xvfb_height
                                               const tab_height = this.get_height();
                                               let target_width = tab_height * xvfb_aspect_ratio

                                               const splitter_width = this.tab_manager.parent.splitter.get_width()
                                               if (target_width > (splitter_width / 2)) target_width = splitter_width / 2
                                               this.tab_manager?.parent?.set_width(target_width)
                                           }
                                       }
                                   })
                               })
                               .catch((error) => {
                                   this._on_load_error(error)
                               })
                               .finally(() => {
                                   this.set_loaded(true)
                               })
        })
        this.dnd = reactive({})
    }
}

Tab.register_tab_type(PlayScenarioNovncTab)
