import { Snippet } from "../../vue_record/models/snippet";
import { throttle } from "lodash";
import { send_ws } from "../generic/send_ws";
import CodeMirror from "codemirror";
import { SnippetCursorData } from "../../vue_record/models/snippet";

interface CursorSyncData extends SnippetCursorData {
    snippet_id: number
}

export function sync_cursor_position(cm: CodeMirror.Editor, snippet: Snippet) {
    const on_cursor_activity = (action: "update" | "remove") => {
        let pos = null
        if (action == "update") pos = cm.getCursor();
        const data: CursorSyncData = {
            action,
            snippet_id: snippet.key(),
            user: {
                id: current.user.props.id,
                email: current.user.props.email,
                username: current.user.props.username,
                first_name: current.user.props.first_name,
                last_name: current.user.props.last_name
            },
            tab_id: TAB_ID,
            pos,
        }

        send_ws(`/sync/codemirror_cursor_activity/${snippet.key()}`, data)
    }

    const on_window_unload = () => {
        on_cursor_activity("remove")
    }

    $(window).on('unload beforeunload', on_window_unload)

    const throttled_on_cursor_activity = throttle(on_cursor_activity, 500)
    cm.on("focus", () => {
        throttled_on_cursor_activity("update")
    })
    cm.on("cursorActivity", (cm) => {
        if (cm.hasFocus()) throttled_on_cursor_activity("update")
    })

    cm.on("blur", () => {
        throttled_on_cursor_activity("remove")
    })

    const subscription = faye.subscribe(`/sync/codemirror_cursor_activity/${snippet.key()}`, (data: CursorSyncData) => {
        if (data.tab_id != TAB_ID) {
            snippet.state.other_cursors[data.tab_id] = data
        }
    })
    return () => {
        subscription.cancel()
        $(window).off('unload beforeunload', on_window_unload)
    }
}


