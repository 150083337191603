<template>
  <Table :id="id">
    <template #thead>
      <tr>
        <template v-for="(col, key) in visible_columns"
                  :key="key">
          <th :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
              :data-priority="orders.priority(key)"
              @click="orders.toggle(key)">
            {{ col.name }}
          </th>
        </template>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="row in ordered_rows"
          :key="row.record.props.id"
          class="project-version-row"
          @click="$emit('project-version-click', row.record)"
      >
        <template v-for="(col, index) in row.cols"
                  :key="index">
          <td v-if="col.column_key == 'is_main'"
              :title="col.title"
              :class="col.classes">
            <Checkbox
                v-model="row.record.props.main"
                label=""
                :disabled="true"
                :for_table="true"
            />
          </td>
          <td v-else
              v-moment="col.moment"
              :title="col.title"
              :class="col.classes"
              v-html="col.html"/>
        </template>
      </tr>
    </template>
  </Table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Table from "../../../testa/Table.vue";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import Checkbox from "../../../testa/Checkbox.vue";
import moment from "moment/moment";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";

export default defineComponent({
    components: {
        Checkbox,
        Table,
    },
    // <editor-fold desc="PROPS">
    props: {
        id: {
            type: String,
            required: true,
        },
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        filter: {
            type: String,
            required: false,
            default: "",
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['project-version-click'],
    // <editor-fold desc="DATA">
    data() {
        return {
            columns: {
                is_main: {
                    name: "Main",
                    filterable: false,
                    action: true,
                    order_value: (record) => {
                        return record.props.main ? 1 : 0
                    },
                },
                version: {
                    name: "Version",
                    html: (record) => {
                        return record.props.version
                    },
                },
                created: {
                    name: "Created",
                    moment: (record) => record.props.created_at,
                    order_value: (record) => new Date(record.props.created_at),
                },
                visibility: {
                    name: "User",
                    html: (record) => {
                        let user = record.user?.props?.email
                        if (user == null) user = "Public"
                        return user
                    },
                },
            } as RecordColumns<ProjectVersion>,
            orders: new RecordOrder([["is_main", "desc"], ["version", "asc"]]),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        moment() {
            return moment
        },
        visible_columns() {
            const visible_columns = RecordsTable.visible_columns<ProjectVersion>(this.columns);
            if (this.users.length <= 1) {
                visible_columns.visibility.hidden = true
            }
            return visible_columns
        },
        users() {
            return this.project.user_projects.toArray().map(user_project => user_project.user)
        },
        project_versions() {
            return this.project.project_versions.toArray()
        },
        rows() {
            return RecordsTable.generate_rows<ProjectVersion>(this.visible_columns, this.project_versions as ProjectVersion[])
        },
        filtered_rows() {
            return RecordsTable.filter<ProjectVersion>({
                columns: this.visible_columns,
                filter: this.filter,
                rows: this.rows,
            })
        },
        ordered_rows() {
            return RecordsTable.order<ProjectVersion>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {},
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.project-version-row {
  cursor: pointer;
}
</style>
