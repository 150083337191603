
import Settings from "../../components/settings/Settings.vue";
import { create_vue_app } from "../vue/create_vue_app";

type SettingCategory = {
    id: string
    title: string
    if?(): boolean
}
type SettingItems = {
    user: { [key in "account" | "preferences" ]: SettingCategory }
    project: { [key in "variables" | "gemfile" | "modules" | "web" | "mobile" | "integrations" | "proxies" | "code_lint" | "users" | "project_versions"]: SettingCategory }
    superadmin: { [key in "all_users" | "all_projects" | "browsers" | "phonectors" | "workers" | "delayed_jobs" | "feature_flags"]: SettingCategory }
}
const setting_items: SettingItems = {
    user: {
        account: {
            id: "settings_user_account",
            title: "Account",
        },
        preferences: {
            id: "settings_user_preferences",
            title: "Preferences",
            if: () => current.project_version_setting != null
        },
        // integrations: {
        //     id: "settings_user_integrations",
        //     title: "Integrations",
        // },
    },
    project: {
        variables: {
            id: "settings_project_variables",
            title: "Variables",
            if: () => current.project_version != null
        },
        gemfile: {
            id: "settings_project_gemfile",
            title: "Gemfile",
            if: () => current.project_version != null
        },
        modules: {
            id: "settings_project_modules",
            title: "Modules",
            if: () => current.project_version != null
        },
        web: {
            id: "settings_project_web",
            title: "Web",
            if: () => current.project_version_setting?.props?.web_module_enabled
        },
        mobile: {
            id: "settings_project_mobile",
            title: "Mobile",
            if: () => current.project_version_setting?.props?.android_module_enabled
        },
        integrations: {
            id: "settings_project_integrations",
            title: "Integrations",
            if: () => current.project_version != null
        },
        proxies: {
            id: "settings_project_proxies",
            title: "Proxies",
            if: () => current?.project_version_setting?.props?.proxies_module_enabled
        },
        code_lint: {
            id: "settings_project_code_lint",
            title: "Code Lint",
            if: () => current?.project_version_setting?.props?.code_lint_module_enabled
        },
        users: {
            id: "settings_project_users",
            title: "Users",
            if: () => current.project_version != null
        },
        project_versions: {
            id: "settings_project_project_versions",
            title: "Project Versions",
            if: () => current.project_version != null
        },
    },
    superadmin: {
        all_users: {
            id: "settings_superadmin_users",
            title: "All Users",
        },
        all_projects: {
            id: "settings_superadmin_projects",
            title: "All Projects",
        },
        browsers: {
            id: "settings_superadmin_browsers",
            title: "Browsers",
        },
        phonectors: {
            id: "settings_superadmin_phonectors",
            title: "Phonectors",
        },
        workers: {
            id: "settings_superadmin_workers",
            title: "Workers",
        },
        delayed_jobs: {
            id: "settings_superadmin_delayed_jobs",
            title: "Delayed Jobs",
        },
        feature_flags: {
            id: "settings_superadmin_feature_flags",
            title: "Feature Flags",
        },
    }
}
const settings_user_ids = Object.values(setting_items.user).map(i => i.id)
const settings_project_ids = Object.values(setting_items.project).map(i => i.id)
const settings_superadmin_ids = Object.values(setting_items.superadmin).map(i => i.id)

function find_item_by_id(id: string) {
    if (settings_user_ids.some(i => i == last_setting_item_id)) {
        return Object.values(setting_items.user).find(i => i.id == last_setting_item_id)
    }

    if (settings_project_ids.some(i => i == last_setting_item_id)) {
        return Object.values(setting_items.project).find(i => i.id == last_setting_item_id)
    }

    if (settings_superadmin_ids.some(i => i == last_setting_item_id)) {
        return Object.values(setting_items.superadmin).find(i => i.id == last_setting_item_id)
    }
    return null
}

const show_settings = (item = last_setting_item_id) => {
    if (item != null) last_setting_item_id = item

    const setting_item = find_item_by_id(last_setting_item_id)
    if (setting_item == null || (setting_item.if != null && !setting_item.if())) {
        last_setting_item_id = setting_items.user.account.id
    }


    create_vue_app(Settings, {
        project_version: current.project_version,
        initial_setting_id: last_setting_item_id
    }, document.getElementById("settings"))
}

declare global {
    let last_setting_item_id: string
    let show_settings: (item?: string) => void
    let setting_items: SettingItems

    interface Window {
        last_setting_item_id: string
        setting_items: SettingItems
        show_settings(item?: string): void
    }
}
window.last_setting_item_id = setting_items.project.variables.id
window.show_settings = show_settings
window.setting_items = setting_items
