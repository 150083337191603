import { HasManyThroughAssociations, Props } from "../../base/vue_record";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumFileType } from "../../../auto_generated/enums";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";
import { TabTargetOpts } from "../../../components/testa/editor/tab";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { VueRecord } from "../../base/vue_record";
import { reactive } from "../../../helpers/vue/reactive";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { VueRecordIndex } from "../../base/vue_record_index";
import { VueRecordStore } from "../../base/vue_record_store";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { TestaTree } from "../../../components/testa/tree/tree";
import { what_is_it } from "../../../helpers/generic/what_is_it";
import JumpToModal from "../../../components/testa/editor/editors/codemirror/JumpToModal.vue";
import { create_vue_app } from "../../../helpers/vue/create_vue_app";
import { resolve_tab_target } from "../../../components/testa/editor/tab";
import { unmount_all_modals } from "../../../helpers/vue/unmount_all_modals";
import { LogFileFolderScope } from "../../scopes/non_db/log_file_folder_scope";
import { computed } from "../../../helpers/vue/computed";
import { LogFileClient } from "../../clients/non_db/log_file_client";
import { LogFileScope } from "../../scopes/non_db/log_file_scope";
import { LogFileTab } from "../../../components/testa/editor/tabs/log_file_tab";
import { RecordOpts } from "../../base/vue_record";
import { EventBus } from "../../../helpers/event_bus";
import { AnsiObject } from "../../../libs/ansispan";
import { Log } from "../../../components/logs/log";
import { WebSocketLog } from "../../../components/logs/logs_manager";
import { markRaw } from "vue";
import { ansi_objects } from "../../../libs/ansispan";
import { copy_text_to_clipboard } from "../../../helpers/generic/copy_to_clipboard";
import { update_url_parameters } from "../../../helpers/generic/update_url_parameter";

// <editor-fold desc="TYPES">
export interface LogFileProps extends Props {
    path: string
    name: string
    log_file_folder_path: string
    type: EnumFileType
    size: number
    content: string
    mediatype: string
    subtype: string
    updated_at: Date
    created_at: Date
}
export type QuerifiedLogFileProps = QuerifyProps<LogFileProps>
export type LogFileCreateProps = Omit<Partial<LogFileProps>, 'id'>
export type LogFileUpdateProps = Partial<LogFileProps>

export type LineData = {
    raw: string
    ansi_objects: AnsiObject[]
    log: Log
    plain: string
    is_rendered: boolean
}
export type ChunkData = {
    raw_data: string
    lines: Array<LineData>
    loaded_at: Date
    loaded: boolean
    loading: boolean
    index: number
}

export type LiveData = {
    lines: Array<LineData>
    message_buffer: LineData[]
    message_buffer_flush_interval: NodeJS.Timeout
}

export interface LogFileState extends State {
    codemirrors: any[]
    chunk_data: ChunkData[]
    live_data: LiveData
    min_datetime: Date
    max_datetime: Date
    tags: string[]
}

export interface LogFileComputed extends Computed {
    download_path: string
}

export interface LogFileStaticState extends StaticState {
}
export interface LogFileOpenOpts extends TabTargetOpts {
    close_all_modals?: boolean
}

export type LogFileEditorActionItem =
    "copy"
    | "fullscreen"
    | "jump"


export type LogFileEditorFeature = "contextmenu"
export type LogFileEvents = "chunk_loaded" | "live_line_data"
export type ChunkInformation = {
    offset: number
    content: string
}

export const CHUNK_SIZE = 1024 * 512 // 512 kB
// </editor-fold>

export class LogFile extends VueRecord {
    ['constructor']: typeof LogFile

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static discard_outdated = false
    static ClientClass = LogFileClient
    static ScopeClass = LogFileScope
    static readonly primary_key = "path"
    static sync_channels: string[] = []
    static state: LogFileStaticState = reactive<LogFileStaticState>({
        upload_progresses: []
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "file_folder_path"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof LogFile> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof LogFile>> = {}

    static field_validators: ModelValidatorOpts<LogFileProps> = {}

    static resource_name = Enum.Resource.Label.LOG_FILE
    static resource_id = Enum.Resource.Id.LOG_FILE
    static icon_class = "fa-regular fa-file"
    static color = () => get_css_var("--file-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    event_bus: EventBus<LogFileEvents>
    declare client: LogFileClient
    declare props: LogFileProps;
    declare state: LogFileState;
    declare computed: LogFileComputed;
    // </editor-fold>

    constructor(props: LogFileProps, opts: RecordOpts) {
        super(props, opts);

        this.state.codemirrors = []
        this.state.tags = []
        this.event_bus = new EventBus<LogFileEvents>();

        if (this.props.size != null || this.is_live()) {
            this.init_live_data()
            this.init_chunk_data()
        }
    }

    is_live() {
        return LogFile.is_live(this.props.path)
    }

    static is_live(path: string) {
        return path == "/live"
    }

    after_create() {
        super.after_create();
    }

    after_update(new_props: LogFileProps, old_props: LogFileProps, changes: (keyof LogFileProps)[]) {
        super.after_update(new_props, old_props, changes);

        if (changes.includes("size")) {
            if (this.state.chunk_data == null) {
               this.init_chunk_data()
            } else {
                const new_number_of_chunks = Math.ceil(this.props.size / CHUNK_SIZE)
                const added_number_of_chunks = new_number_of_chunks - this.state.chunk_data.length
                if (added_number_of_chunks > 0) {
                    const old_number_of_chunks = this.state.chunk_data.length
                    const new_chunk_data = Array(added_number_of_chunks).fill(null)
                    new_chunk_data.each((chunk, index) => {
                        new_chunk_data[index] = {
                            loaded: false,
                            loading: false,
                            loaded_at: null,
                            lines: null,
                            raw_data: null,
                            index: index + old_number_of_chunks
                        }
                    })
                    this.state.chunk_data.concat(new_chunk_data)
                }
            }
        }
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_logs_tree()): Promise<void> {
        return LogFile.show_in_sidebar(this.key(), this.props.project_version_id, tree);
    }

    static async show_in_sidebar(file_paths: string | string[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_logs_tree()) {
        let ids: string[];
        if (what_is_it(file_paths) == "Array") {
            ids = file_paths as string[]
        } else {
            ids = [file_paths as string]
        }

        if (web.is_main) {
            // TODO: refactor this so that it expands folder by folder, an then once all folders are expanded, select all target elements
            // Section.get_project_section().enable()
            // const all_keys: string[][] = []
            // const promises: Promise<any>[] = []
            // ids.each(file_path => {
            //     let current_path = "/"
            //     const keys = [
            //         LogFileFolder.tree_key(project_version_id)
            //     ]
            //     const parts = file_path.split("/")
            //     parts.splice(0, 1)
            //     parts.each(path_part => {
            //         current_path += path_part
            //         if (current_path == file_path) {
            //             const f = LogFile.find(current_path)
            //             keys.push(f?.tree_key())
            //         } else {
            //             const ff = LogFileFolder.find(current_path)
            //             keys.push(ff?.tree_key())
            //         }
            //     })
            //     all_keys.push(keys)
            //     tree.expand_paths(all_keys)
            // })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: LogFile.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="HOOKS">
    before_unload() {
        super.before_unload();

        this.get_tabs().forEach(tab => tab.close())
    }

    on_chunk_loaded(cb: (data:ChunkData) => void) {
        this.event_bus.$on("chunk_loaded", cb)
    }

    on_live_line_data(cb: (line_data: LineData[], start_index: number) => void) {
        this.event_bus.$on("live_line_data", cb)
    }

    off_chunk_loaded(cb: (data:ChunkData) => void) {
        this.event_bus.$off("chunk_loaded", cb)
    }

    off_live_line_data(cb: (line_data: LineData[], start_index: number) => void) {
        this.event_bus.$off("live_line_data", cb)
    }
    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    show_jump_to(cm: CodeMirror.Editor) {
        create_vue_app(JumpToModal, { cm })
    }

    open(opts: LogFileOpenOpts = {}) {
        const tab_target = resolve_tab_target(opts)
        if (opts.close_all_modals) unmount_all_modals();

        const tab = tab_target.create_tab({
            id: this._log_file_tab_id(),
            log_file_path: this.key(),
            type: LogFileTab.type
        }) as LogFileTab

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => {
                resolve(null)
            })
        })
    }

    load_content_inc(chunk: number) {
        if (chunk >= this.state.chunk_data.length) return

        this.client.load_partial_content(chunk, CHUNK_SIZE)
            .then(() => {
                if (chunk + 1 < this.state.chunk_data.length &&
                    !this.state.chunk_data[chunk + 1].loading &&
                    !this.state.chunk_data[chunk + 1].loaded) {
                    this.load_content_inc(chunk + 1)
                }
            })
    }

    load_content_deinc(chunk: number) {
        if (chunk < 0) return

        this.client.load_partial_content(chunk, CHUNK_SIZE)
                   .then(() => {
                       if (chunk - 1 >= 0 &&
                           !this.state.chunk_data[chunk - 1].loading &&
                           !this.state.chunk_data[chunk - 1].loaded) {
                           this.load_content_deinc(chunk - 1)
                       }
                   })
    }

    subscribe_to_logs_live() {
        this.state.live_data.message_buffer = []
        this.state.live_data.message_buffer_flush_interval = setInterval(() => {
            const line_data: LineData[] = []
            const start_index = this.state.live_data.lines.length
            this.state.live_data.message_buffer.forEach(data => {
                this.state.live_data.lines.push(data)
                line_data.push(data)

                if (data.plain != " ") {
                    if (data.log.datetime != null) {
                        if (this.state.min_datetime == null || this.state.min_datetime.getTime() > data.log.datetime.getTime()) {
                            this.state.min_datetime = data.log.datetime
                        }

                        if (this.state.max_datetime == null || this.state.max_datetime.getTime() < data.log.datetime.getTime()) {
                            this.state.max_datetime = data.log.datetime
                        }
                    }
                    data.log.tags.each(tag => {
                        if (!this.state.tags.includes(tag)) this.state.tags.push(tag)
                    })
                }
            })
            this.event_bus.$emit("live_line_data", line_data, start_index)
            this.state.live_data.message_buffer = []
        }, 500)
        faye.unsubscribe("/logs/live/**")
        faye.subscribe("/logs/live/**", (data: WebSocketLog) => {
            data.log.split("\n").each(line => {
                if (line == "") return
                let prefix = `[${data.app}|${data.group}`
                if (data.subgroup != null) {
                    prefix += `|${data.subgroup}`
                }
                prefix += "] "

                const ao = ansi_objects(prefix + line);
                const plain = ao.map(a => a.str).join("")
                // text must not be empty. because if it is empty then we cannot apply CodeMirror markText to it
                const log = Log.from_string(line)

                const line_data = {
                    ansi_objects: ao,
                    log,
                    raw: `${prefix}${line}\n`,
                    plain,
                    is_rendered: null as boolean,
                }

                this.state.live_data.message_buffer.push(markRaw(line_data))
            })
        })
    }

    // </editor-fold>

    // <editor-fold desc="TREE">

    // <editor-fold desc="CONTEXTMENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<LogFileScope, LogFileFolderScope, LogFile>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                items.open = {
                    name: "Open",
                    icon: LogFile.icon_class,
                    color: get_css_var("--button-white"),
                    key: `enter`,
                    callback: () => {
                        this.open();
                    },
                }

                items.copy_link = {
                    name: "Copy link",
                    icon: "fa-solid fa-link",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        const params = new URLSearchParams()
                        params.set("log_file_path", this.key().toString())
                        copy_text_to_clipboard(update_url_parameters(window.location.href, params))
                    }
                }

                items.download = {
                    name: "Download",
                    icon: "fa-solid fa-download",
                    color: get_css_var("--button-blue"),
                    callback: () => {
                        const records_scope = node.tree.get_selected_records()
                        if (records_scope.log_files.count + records_scope.log_file_folders.count > 1) {
                            LogFile.ClientClass.batch_download(records_scope.log_file_folders.toArray(), records_scope.log_files.toArray())
                        } else {
                            this.client.download();
                        }
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<LogFileScope, LogFileFolderScope, LogFile> {
        return {
            resource_id: Enum.Resource.Id.FILE,
            key: this.tree_key(),
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            file: {
                open_fn: () => {
                    return this.open()
                },
            },
            icon: computed(() => {
                return {
                    color: get_css_var("--file-color"),
                }
            }),
            dnd: {
                is_draggable: true,
                is_drop_area: false,
            },
            contextmenu: this._tree_contextmenu(),
        }
    }

    // </editor-fold>

    // <editor-fold desc="EDITOR">
    _editor_contextmenu(cm: CodeMirror.Editor, e: MouseEvent, _exclude_items: LogFileEditorActionItem[] = []) {
        const items: ContextMenu.Items = {}
        return items
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _log_file_tab_id() {
        return `log_file_tab_${this.key()}`
    }

    _editor_keymap(cm: CodeMirror.Editor, exclude_items: LogFileEditorActionItem[] = []) {
        let Ctrl_or_Cmd = "Ctrl"
        if (is_pc_mac) Ctrl_or_Cmd = "Cmd"
        const key_map: Record<string, (cm: CodeMirror.Editor) => void> = {}
        if (!exclude_items.includes("fullscreen")) {
            key_map.F11 = () => {
                cm.setOption("fullScreen", !cm.getOption("fullScreen"))
            }
        }

        if (!exclude_items.includes("jump")) {
            key_map[`${Ctrl_or_Cmd}-G`] = (cm) => {
                this.show_jump_to(cm)
            }
        }

        key_map[`Shift-${Ctrl_or_Cmd}-F`] = () => {
            // prevent default replace action
        }
        return key_map
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        if (include_name) {
            return this.props.path
        } else {
            if (this.props.file_folder_path == null) return "/"
            return this.props.file_folder_path
        }
    }

    init_live_data() {
        clearInterval(this.state.live_data?.message_buffer_flush_interval)
        this.state.live_data = {
            lines: [],
            message_buffer: [],
            message_buffer_flush_interval: null
        }
    }

    init_chunk_data() {
        if (this.is_live()) {
            this.state.chunk_data = []
        } else {
            const chunk_count = Math.ceil(this.props.size / CHUNK_SIZE)
            this.state.chunk_data = Array(chunk_count).fill(null)
        }
        this.state.chunk_data.each((chunk, index) => {
            this.state.chunk_data[index] = {
                loaded: false,
                loading: false,
                loaded_at: null,
                lines: null,
                raw_data: null,
                index
            }
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
LogFile.register_resource(LogFile)
LogFileClient.ModelClass = LogFile
LogFileScope.ModelClass = LogFile
LogFile.new({ path: "/live", name: "live", log_file_folder_path: null })


declare global {
    interface Window {
        LogFile: typeof LogFile
    }
}
window.LogFile = LogFile
// </editor-fold>

