export function navigate_with_arrows(e: KeyboardEvent) {
    const tabbable = $("input,textarea,[tabindex]:not([tabindex='-1'])").toArray();
    let index = tabbable.indexOf(e.target as HTMLElement);
    if (e.code == "ArrowLeft" || e.code == "ArrowUp" || e.code == "ArrowRight" || e.code == "ArrowDown") {
        if (e.code == "ArrowLeft" || e.code == "ArrowUp") {
            index--;
        } else {
            index++;
        }
        if (index < 0) index = 0;
        if (index >= tabbable.length) index = tabbable.length - 1;
        tabbable[index].focus();
        return false
    }
    return true
}
