import { set_font_size } from "./helpers/set_font_size";

export const storager_snippet_editor_font_size_key = "snippet-font-size"
export const storager_file_editor_font_size_key = "file-font-size"
export const storager_log_viewer_font_size_key = "log-viewer-font-size"


export function font_resize_on_wheel(
    cm: CodeMirror.Editor,
    storager_key: string,
    apply_to_codemirrors_cb: () => Array<CodeMirror.Editor>) {
    const $container = $(cm.getTextArea()).parent();
    $container[0].addEventListener("wheel", (e) => {
        if (e.ctrlKey) {
            const current_size = parseInt($container.css("font-size").replace("px", ""))
            let new_size = current_size;
            if (e.wheelDeltaY > 0) {
                new_size = current_size + 2;
                if (new_size > 64) new_size = 64
            } else {
                new_size = current_size - 2;
                if (new_size < 4) new_size = 4
            }

            apply_to_codemirrors_cb().each(cm => {
                set_font_size(cm, new_size)
            })

            current.user.storager.set(storager_key, new_size)
            e.preventDefault();
            global_event_bus.$emit("editor-font-resize")
        }
    }, {
        passive: false
    })
}
