import { reactive } from "vue";
import { create_vue_app } from "../../../helpers/vue/create_vue_app";
import GlobalLoader from "./GlobalLoader.vue";

export type GlobalLoaderHandler = { stop: () => void }

export function show_global_loader() {
    const obj = reactive({
        is_loading: true
    })
    const app = create_vue_app(GlobalLoader, obj)
    return {
        stop: () => {
            app.unmount();
        }
    }
}
