import { VueRecord } from "./vue_record";
import { VueRecordIndex } from "./vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { QueryChain } from "./vue_record_scope";
import { QueryLinkType } from "./vue_record_scope";
import { Props } from "./vue_record";
import { QuerifyProps } from "./vue_record_scope";
import { VueRecordScope } from "./vue_record_scope";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ColumnIndexOpts } from "./vue_record_index";

type ColumnIndexValues<TModel extends VueRecord = VueRecord> = Record<number | string, ColumnIndexStore<TModel> | TModel>
type ColumnIndexStore<TModel extends VueRecord = VueRecord> = {
    [key in keyof TModel['props']]?: ColumnIndexValues<TModel>
}

type MergedQueryChain<TModel extends typeof VueRecord> = Partial<Record<QueryLinkType, QuerifyProps<InstanceType<TModel>['props']>>>

const console = new Consoler("warn")

export class VueRecordStore<TModel extends typeof VueRecord = typeof VueRecord> {
    ModelClass: TModel
    index_store: Record<string, ColumnIndexStore<InstanceType<TModel>>> = {}
    indexes: Record<string, VueRecordIndex<InstanceType<TModel>>> = {}
    is_stage: boolean
    stage_id: string

    constructor(ModelClass: TModel, stage_id: string) {
        this.ModelClass = ModelClass
        this.is_stage = stage_id != null
        this.stage_id = stage_id
        ModelClass.indexes.each(index => {
            if (this.indexes[index.id] != null) {
                throw new Error(`Index with id ${index.id} already exist`)
            }
            this.indexes[index.id] = index
            this.index_store[index.id] = {}
        })
    }

    static new<TModel extends typeof VueRecord = typeof VueRecord>(ModelClass: TModel, stage_id: string = null) {
        return reactive(new this(ModelClass, stage_id))
    }

    find(primary_key: InstanceType<TModel>['props'][TModel['primary_key']]): InstanceType<TModel> {
        const index_by_name = this.index_store[this.ModelClass.primary_key]
        if (index_by_name == null) return null

        const index_for_column = index_by_name[this.ModelClass.primary_key]
        if (index_for_column == null) return null

        return index_for_column[primary_key] as InstanceType<TModel>
    }

    execute_query_chain(query_chain: QueryChain): InstanceType<TModel>[] {
        const merged_query_chain: MergedQueryChain<TModel> = {}
        query_chain.filter(query_link => query_link.value != null)
                   .each(query_link => {
            let value = merged_query_chain[query_link.type]
            if (value == null) {
                value = _.cloneDeep(query_link.value)
            } else {
                const cloned = _.cloneDeep(query_link.value)
                Object.keys(cloned).each(key_string => {
                    const key = key_string as keyof QuerifyProps<InstanceType<TModel>["props"]>
                    if (value[key] instanceof Object && !(value[key] instanceof Array)) {
                        // if the value is Object, then the key is probably referencing an association
                        // TODO: implement proper merge for association querying
                        value[key] = _.mergeWith(value[key], cloned[key_string])
                    } else {
                        value[key] = cloned[key_string]
                    }
                })
            }
            merged_query_chain[query_link.type] = value
        })

        const index = this.best_index_for_query_columns(merged_query_chain)
        const index_store = this.index_store[index.id]
        let records: InstanceType<TModel>[] = []
        this.query_index_store(index, 0, index_store, merged_query_chain, records)

        if (Object.keys(merged_query_chain).length > 0) {
            records = this.query_records_array(records, merged_query_chain)
        }
        return records
    }


    add(record: InstanceType<TModel>) {
        Object.values(this.indexes).each(index => this.add_to_index(record, index))
    }

    remove(record: InstanceType<TModel>) {
        Object.values(this.indexes).each(index => this.remove_from_index(record, record.props, index))
    }

    update(record: InstanceType<TModel>, old_props: InstanceType<TModel>['props']) {
        Object.values(this.indexes).each(index => {
            this.remove_from_index(record, old_props, index)
            this.add_to_index(record, index)
        })
    }

    get_scope() {
        return this.ModelClass.ScopeClass.new({
            parent_scope: null,
            is_stage: this.is_stage,
            stage_id: this.stage_id
        })
    }

    revert_staged() {
        if (this.is_stage) {
            const records: InstanceType<TModel>[] = []
            this.add_index_store_records_to_array(this.index_store[this.ModelClass.primary_key], records)
            this.index_store = {}
            records.each(r => {
                this.add(r.unstaged())
            })
        }
    }

    private query_records_array(records: InstanceType<TModel>[], merged_query: MergedQueryChain<TModel>) {
        Object.keys(merged_query).each(key => {
            const operator = key as QueryLinkType
            records = records.filter(r => this.query_record_columns(r, operator, merged_query[operator]))
        })
        return records
    }

    private query_record_columns(record: InstanceType<TModel>, operator: QueryLinkType, query_columns: QuerifyProps<InstanceType<TModel>['props']>): boolean {
        return Object.keys(query_columns).every(key => {
            const query_column_or_association = key as keyof InstanceType<TModel>['props']
            const value = query_columns[query_column_or_association]

            if (value instanceof Object) {
                if (record.constructor.has_one_associations.some(assoc => assoc.relation == query_column_or_association) ||
                    record.constructor.belongs_to_associations.some(assoc => assoc.relation == query_column_or_association)) {
                    const has_one_or_belongs_to = record[query_column_or_association] as any
                    if (has_one_or_belongs_to == null) return false
                    return this.query_record_columns(has_one_or_belongs_to, operator, value as any)
                } else if (record.constructor.has_many_associations.some(assoc => assoc.relation == query_column_or_association) ||
                    record.constructor.has_many_through_associations.some(assoc => assoc.relation == query_column_or_association)) {
                    const has_many = record[query_column_or_association] as VueRecordScope
                    return has_many.some((r: any) => this.query_record_columns(r, operator, value as any))
                }
            }

            return this.query_record_column(record, operator, query_column_or_association, value)
        })
    }

    private query_record_column(record: InstanceType<TModel>, operator: QueryLinkType, column: keyof InstanceType<TModel>['props'], query_value: any | any[]): boolean {
        if (query_value instanceof Array) {
            return query_value.some(v => this.query_record_column(record, operator, column, v))
        } else {
            const record_value = record.props[column as keyof Props]
            switch (operator) {
                case "equal":
                    return record_value == query_value
                case "not_equal":
                    return query_value != record_value
                case "greater":
                    return query_value < record_value
                case "greater_or_equal":
                    return query_value <= record_value
                case "lesser":
                    return query_value > record_value
                case "lesser_or_equal":
                    return query_value >= record_value
                case "match":
                    return (query_value as RegExp).test(record_value)
                default:
                    throw new Error(`Implement operator ${operator} for query record column`);
            }
        }
    }

    private get_record_column_value(record: VueRecord, column: ColumnIndexOpts<InstanceType<TModel>>): any {
        if (record.is_stage && column.column == record.constructor.primary_key) {
            return record.key()
        } else {
            return record.props[column.column as keyof Props]
        }
    }

    /** Index stores column values in specific way.
     * For instance, values can be hashed or convert undefined values to null */
    private convert_column_value_to_index_value(value: any, column: ColumnIndexOpts<InstanceType<TModel>>) {
        if (typeof value === 'undefined') value = null
        if (column.hash && value != null) {
            value = hash(value)
        }
        return value
    }

    private add_to_index(record: InstanceType<TModel>, index: VueRecordIndex<InstanceType<TModel>>) {
        const current_column_index_store = this.index_store[index.id]
        this.add_to_index_store(record, index, current_column_index_store, 0)
    }

    private add_to_index_store(
        record: InstanceType<TModel>,
        index: VueRecordIndex<InstanceType<TModel>>,
        current_column_index_store: ColumnIndexStore<InstanceType<TModel>>,
        i: number) {
        const column = index.column_index_props[i]
        let column_index = current_column_index_store[column.column];
        let add_column_index_to_store = false
        if (column_index == null) {
            column_index = {}
            add_column_index_to_store = true
        }

        const value = this.convert_column_value_to_index_value(this.get_record_column_value(record, column), column)
        if (i + 1 == index.column_index_props.length) {
            // last in chain is always primary key
            // this means value index is actually record
            column_index[value] = record
            if (add_column_index_to_store) current_column_index_store[column.column] = column_index
        } else {
            // another column index
            let value_index = column_index[value]
            if (value_index == null) {
                value_index = {}
                this.add_to_index_store(record, index, value_index as ColumnIndexStore<InstanceType<TModel>>, i + 1)
                column_index[value] = value_index
            } else {
                this.add_to_index_store(record, index, value_index as ColumnIndexStore<InstanceType<TModel>>, i + 1)
            }
            if (add_column_index_to_store) current_column_index_store[column.column] = column_index
        }
    }

    private remove_from_index(
        record: InstanceType<TModel>,
        props: InstanceType<TModel>['props'],
        index: VueRecordIndex<InstanceType<TModel>>) {
        let current_column_index_store = this.index_store[index.id]
        index.column_index_props.each((column, i) => {
            let column_index = current_column_index_store[column.column];
            if (column_index == null) {
                column_index = {}
                current_column_index_store[column.column] = column_index
            }

            let column_value: any
            if (record.is_stage && column.column == record.constructor.primary_key) {
                column_value = record.key()
            } else {
                column_value = props[column.column as keyof Props]
            }
            const value = this.convert_column_value_to_index_value(column_value, column)
            if (i + 1 == index.column_index_props.length) {
                // last in chain is always primary key
                // this means value index is actually record
                delete column_index[value]
            } else {
                // another column index
                let value_index = column_index[value]
                if (value_index == null) {
                    value_index = {}
                    column_index[value] = value_index
                }
                current_column_index_store = value_index as ColumnIndexStore<InstanceType<TModel>>
            }
        })
    }

    private columns_for_index_query(merged_query_chain: MergedQueryChain<TModel>) {
        let columns: string[] = []
        const equal = merged_query_chain.equal
        if (equal != null) columns = Object.keys(equal)

        const not_equal = merged_query_chain.not_equal
        if (not_equal != null) columns = columns.concat(Object.keys(not_equal))
        return columns.uniq()
    }

    private best_index_for_query_columns(merged_query: MergedQueryChain<TModel>) {
        const columns = this.columns_for_index_query(merged_query)
        let best_index_id: string = null
        let best_index_points: number = -1;

        const max_bonus_points = Object.values(this.indexes)
                                       .map(index => index.column_index_props.map(i => i.column).length)
                                       .max()

        Object.values(this.indexes).each(index => {
            const index_columns = index.column_index_props.map(i => i.column)
            let bonus_points = max_bonus_points
            let points_for_index = 0;
            index_columns.each((index_column) => {
                // give more points to columns that start the index
                // for example, if index is on project_id, app_type, package
                // then give more points to project_id than to app_type
                --bonus_points;
                if (columns.includes(index_column.toString())) points_for_index += 1 + bonus_points;
                else points_for_index -= 0.1 // take away few points if column is not indexed
            })
            if (points_for_index > best_index_points) {
                best_index_points = points_for_index
                best_index_id = index.id
            }
        })
        console.debug(`Resolved best index to ${best_index_id} for: `, columns)
        return this.indexes[best_index_id]
    }

    private add_index_store_records_to_array(index_store: ColumnIndexStore<InstanceType<TModel>>, records: InstanceType<TModel>[]) {
        if (index_store == null) return;

        const add = (index_store: ColumnIndexStore<InstanceType<TModel>>) => {
            const key = Object.keys(index_store).first()
            if (key == null) return;

            if (key == this.ModelClass.primary_key) {
                (Object.values(index_store[key]) as InstanceType<TModel>[]).each(r => records.push(r))
            } else {
                Object.values(index_store[key]).each(v => {
                    add(v as ColumnIndexStore<InstanceType<TModel>>)
                })
            }
        }
        add(index_store)
    }

    private query_index_store(
        index: VueRecordIndex<InstanceType<TModel>>,
        level: number,
        index_store: ColumnIndexStore<InstanceType<TModel>>,
        merged_query: MergedQueryChain<TModel>,
        records: InstanceType<TModel>[]) {
        console.debug("Querying index store: ", index_store, "for query: ", _.cloneDeep(merged_query));

        const column = Object.keys(index_store).first() as keyof InstanceType<TModel>["props"]// index store should always have only 1 key, the column that is indexed
        if (column == null) return // store is empty

        const is_last = column == this.ModelClass.primary_key
        const query_columns = Object.values(merged_query).map(q => Object.keys(q)).flat().uniq() as Array<keyof InstanceType<TModel>['props']>
        let filtered_index_store = index_store;
        if (query_columns.includes(column)) {
            const column_props = index.column_index_props[level]
            Object.keys(merged_query).each(key => {
                const operator = key as QueryLinkType
                if (operator == "equal" || operator == "not_equal") {
                    const query = merged_query[operator]
                    if (Object.keys(query).includes(column.toString())) {
                        const value = this.convert_column_value_to_index_value(query[column], column_props)
                        delete query[column]
                        if (Object.keys(query).length == 0) delete merged_query[operator]
                        filtered_index_store = this.filter_index_store(filtered_index_store, column, value, operator, records)
                    }
                }
            })
        } else if (!is_last) {
            let aggregated: ColumnIndexStore<InstanceType<TModel>> = {}
            Object.values(index_store[column]).each(v => {
                aggregated = _.mergeWith(aggregated, v)
            })
            filtered_index_store = aggregated
        }

        if (is_last) {
            console.debug("reached end of index, query: ", _.cloneDeep(merged_query));
            this.add_index_store_records_to_array(filtered_index_store, records)
            return
        }

        if (filtered_index_store == null) {
            console.debug("reached end of index. Store empty, query: ", _.cloneDeep(merged_query));
            return;
        }

        // in not_equal queries we do not navigate to the child index
        // i.e. not({status: "Offline"}) we end up with filtered_index_store with all statuses except Offline
        // then to properly navigate index further, we have to aggregate all other statuses
        if (filtered_index_store.hasOwnProperty(column)) {
            let aggregated: ColumnIndexStore<InstanceType<TModel>> = {}
            Object.values(filtered_index_store[column]).each(v => {
                aggregated = _.mergeWith(aggregated, v)
            })
            filtered_index_store = aggregated
        }

        if (Object.keys(merged_query).length > 0) {
            console.debug("query still not resolved, query: ", _.cloneDeep(merged_query));
            this.query_index_store(index, level + 1, filtered_index_store, merged_query, records)
        } else {
            console.debug("query resolved", filtered_index_store);
            this.add_index_store_records_to_array(filtered_index_store, records)
        }
    }

    private filter_index_store(index_store: ColumnIndexStore<InstanceType<TModel>>,
                               column: keyof InstanceType<TModel>['props'],
                               value: any | any[],
                               operator: QueryLinkType,
                               records: InstanceType<TModel>[]): ColumnIndexStore<InstanceType<TModel>> {
        switch (operator) {
            case "equal":
                return this.filter_index_store_for_equal(index_store, column, value, records)
            case "not_equal":
                return this.filter_index_store_for_not_equal(index_store, column, value, records)
            default:
                throw new Error(`Operator ${operator} not available for filter index store`);
        }
    }

    private filter_index_store_for_equal(index_store: ColumnIndexStore<InstanceType<TModel>>,
                                         column: keyof InstanceType<TModel>['props'],
                                         value: any | any[],
                                         records: InstanceType<TModel>[]): ColumnIndexStore<InstanceType<TModel>> {
        if (value instanceof Array) {
            let filtered: ColumnIndexStore<InstanceType<TModel>> = {}
            value.each(v => {
                const r = this.filter_index_store_for_equal(index_store, column, v, records)
                if (r != null) {
                    filtered = _.mergeWith(filtered, r)
                }
            })
            return filtered
        } else {
            const convert_to_object = (returned: any): ColumnIndexStore<InstanceType<TModel>> => {
                if (this.ModelClass.resource_id == Enum.Resource.Id.SCENARIO_NODE) {
                    console.log("debug");
                }
                if (returned instanceof VueRecord) {
                    records.push(returned as InstanceType<TModel>)
                    return null
                }
                return returned
            }
            if (index_store == null || index_store[column] == null) {
                for (let i = 0; i < records.length; ++i) {
                    if (!this.query_record_column(records[i], "equal", column, value)) {
                        records.splice(i, 1);
                        --i;
                    }
                }
            } else {
                const record_or_index_store = index_store[column][value]
                if (record_or_index_store == null) {
                    // record for value is missing, touch all keys so that computed re-triggers when a new key is added
                    return null
                } else {
                    return convert_to_object(record_or_index_store)
                }
            }
        }
    }

    private filter_index_store_for_not_equal(index_store: ColumnIndexStore<InstanceType<TModel>>,
                                             column: keyof InstanceType<TModel>['props'],
                                             value: any | any[],
                                             records: InstanceType<TModel>[]): ColumnIndexStore<InstanceType<TModel>> {
        if (!(value instanceof Array)) {
            return this.filter_index_store_for_not_equal(index_store, column, [value], records)
        } else {
            if (index_store == null || index_store[column] == null) {
                for (let i = 0; i < records.length; ++i) {
                    if (!this.query_record_column(records[i], "not_equal", column, value)) {
                        records.splice(i, 1);
                        --i;
                    }
                }
            } else {
                const cloned_index_records = _.clone(index_store[column])
                value.each(v => {
                    delete cloned_index_records[v]
                })
                const obj: ColumnIndexStore<InstanceType<TModel>> = {}
                obj[column] = cloned_index_records
                return obj
            }
        }
    }
}
