import { Tab } from "../tab";
import { markRaw } from "vue";
import { reactive } from "vue";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import VideoPlayer from "../../VideoPlayer.vue";
import { get_css_var } from "../../../../helpers/generic/get_css_var";
import { computed } from "../../../../helpers/vue/computed";

namespace PlayScenarioRecordingTab {
    export interface Input extends Tab.Input {
        play_scenario_id: number
        play_id: number
    }

    export interface State extends Tab.State {
        record: PlayScenario
    }
}

export class PlayScenarioRecordingTab extends Tab {
    static type = "PlayScenarioRecordingTab"

    type: string
    play_scenario_id: number
    play_id: number

    declare state: PlayScenarioRecordingTab.State

    constructor(data: PlayScenarioRecordingTab.Input) {
        super(data)
        this.type = PlayScenarioRecordingTab.type
        this.play_scenario_id = data.play_scenario_id;
        this.play_id = data.play_id
        this.state.icon.class = "fa-solid fa-eye"

        // if title is known, set it before we load, so that tabs are sorted correctly
        const play_scenario = PlayScenario.find(this.play_scenario_id)
        if (play_scenario != null) {
            this.computed.title = computed(() => play_scenario.props.name)
        }

        this.component = markRaw(VideoPlayer);
        this._set_and_call_load_function(() => {
            return PlayScenario.ClientClass
                               .load(this.play_scenario_id)
                               .then(play_scenario => {
                                   if (!play_scenario.has_recording()) {
                                       this.close();
                                       return;
                                   }
                                   this.state.record = play_scenario

                                   this.state.icon.color = computed(() => get_css_var("--button-white"))
                                   this.computed.title = computed(() => play_scenario.props.name)

                                   this.component_props = computed(() => {
                                       return {
                                           stream_id: `play_scenario_${play_scenario.key()}_video_recording`,
                                           tab: this,
                                           url: play_scenario.props.video_url,
                                       }
                                   })
                               })
                               .catch((error) => {
                                   this._on_load_error(error)
                               })
                               .finally(() => {
                                   this.set_loaded(true)
                               })
        })
        this.dnd = reactive({})
    }
}

Tab.register_tab_type(PlayScenarioRecordingTab)
