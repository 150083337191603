
/** @template EventNameType */
export class EventBus<EventNameType = any, EventTargetType = any> {
    eventListeners: Map<EventNameType, Array<{callback: (object: EventTargetType, e: any) => any, once: boolean}>>;
    constructor() {
        /**
         * @type {Map<EventNameType, Array<{ callback: Function, once: boolean }>>}
         */
        this.eventListeners = new Map()
    }

    /**
     * @param {EventNameType} eventName
     * @param {Function} callback
     * @param {boolean} [once]
     * @private
     */
    registerEventListener(eventName: EventNameType, callback: (object: EventTargetType, e: any) => any, once = false) {
        if (!this.eventListeners.has(eventName)) {
            this.eventListeners.set(eventName, [])
        }

        const eventListeners = this.eventListeners.get(eventName)
        eventListeners.push({ callback, once })
    }

    /**
     * See: https://v2.vuejs.org/v2/api/#vm-on
     *
     * @param {EventNameType} eventName
     * @param {Function} callback
     */
    $on(eventName: EventNameType, callback: (object: EventTargetType, e: any) => any) {
        this.registerEventListener(eventName, callback)
    }

    /**
     * See: https://v2.vuejs.org/v2/api/#vm-once
     *
     * @param {EventNameType} eventName
     * @param {Function} callback
     */
    $once(eventName: EventNameType, callback: (object: EventTargetType, e: any) => any) {
        const once = true
        this.registerEventListener(eventName, callback, once)
    }

    /**
     * Removes all event listeners for the given event name or names.
     *
     * When provided with a callback function, removes only event listeners matching the provided function.
     *
     * See: https://v2.vuejs.org/v2/api/#vm-off
     *
     * @param {EventNameType | EventNameType[]} eventNameOrNames
     * @param {Function} [callback]
     */
    $off(eventNameOrNames: EventNameType | EventNameType[], callback: (object: EventTargetType, e: any) => any = undefined) {
        const eventNames = Array.isArray(eventNameOrNames) ? eventNameOrNames : [eventNameOrNames]

        for (const eventName of eventNames) {
            const eventListeners = this.eventListeners.get(eventName)

            if (eventListeners === undefined) {
                continue
            }

            if (typeof callback === 'function') {
                for (let i = eventListeners.length - 1; i >= 0; i--) {
                    if (eventListeners[i].callback === callback) {
                        eventListeners.splice(i, 1)
                    }
                }
            } else {
                this.eventListeners.delete(eventName)
            }
        }
    }

    /**
     * See: https://v2.vuejs.org/v2/api/#vm-emit
     *
     * @param {EventNameType} eventName
     * @param object
     * @param e
     */
    $emit(eventName: EventNameType, object: EventTargetType = null, e: any = null) {
        if (!this.eventListeners.has(eventName)) {
            return
        }

        const eventListeners = this.eventListeners.get(eventName)
        const eventListenerIndexesToDelete = []
        for (const [eventListenerIndex, eventListener] of eventListeners.entries()) {
            eventListener.callback(object, e)

            if (eventListener.once) {
                eventListenerIndexesToDelete.push(eventListenerIndex)
            }
        }

        for (let i = eventListenerIndexesToDelete.length - 1; i >= 0; i--) {
            eventListeners.splice(eventListenerIndexesToDelete[i], 1)
        }
    }
}
