import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { VariableSetClient } from "../clients/variable_set_client";
import { VariableSetScope } from "../scopes/variable_set_scope";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { SnippetUpdateProps } from "./snippet";

// <editor-fold desc="TYPES">
export interface VariableSetProps extends Props {
    id: number
    name: string
    variables: string
    deleted: boolean
    private: boolean
    project_version_id: number
    created_at: Date
    updated_at: Date
    default: boolean
    snippet_id: number
}
export type QuerifiedVariableSetProps = QuerifyProps<VariableSetProps>
export interface VariableSetCreateProps extends Omit<Partial<VariableSetProps>, 'id'> {
    project_version_id: number
}
export interface VariableSetUpdateProps extends Partial<VariableSetProps> {
    snippet_attributes?: SnippetUpdateProps
}


export interface VariableSetState extends State {}
export interface VariableSetComputed extends Computed {}
export interface VariableSetStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class VariableSet extends VueRecord {
    ['constructor']: typeof VariableSet

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = VariableSetClient
    static ScopeClass = VariableSetScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: VariableSetStaticState = reactive<VariableSetStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof VariableSet> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof VariableSet>> = {}

    static field_validators: ModelValidatorOpts<VariableSetProps> = {}

    static resource_name = Enum.Resource.Label.VARIABLE_SET
    static resource_id = Enum.Resource.Id.VARIABLE_SET
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: VariableSetClient
    declare props: VariableSetProps;
    declare state: VariableSetState;
    declare computed: VariableSetComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    destroy() {
        const content_text = this.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => {
                return this.client.destroy()
            }
        })
    }
}

// <editor-fold desc="INIT">
VariableSet.register_resource(VariableSet)
VariableSetClient.ModelClass = VariableSet
VariableSetScope.ModelClass = VariableSet

if (globalThis.variable_sets_props) {
    variable_sets_props.forEach(variable_set => VariableSet.new(variable_set))
}

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (value) => {
            VariableSet.unsync()
            if (value != null) VariableSet.sync(`/sync/project_version/${value}/variable_sets`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    var variable_sets_props: VariableSetProps[]

    interface Window {
        VariableSet: typeof VariableSet
    }
}
window.VariableSet = VariableSet
// </editor-fold>

