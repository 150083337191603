<template>
  <Input :id="id"
         ref="input"
         v-model="string_value"
         :instance_id="instance_id"
         placeholder="Pick a date"
         :label="label"
         :scale="scale"
         :readonly="true"
         :errors_no_side="'bottom'"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { markRaw } from "vue";
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import Input from "./Input.vue";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { PropType } from "vue";
import _ from "lodash";

export type DatePickerButtons = "today" | "clear"

export default defineComponent({
    components: { Input },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        modelValue: {
            type: Date,
            required: false,
            default: null,
        },
        inline: {
            type: Boolean,
            default: false,
            required: false,
        },
        timepicker: {
            type: Boolean,
            default: false,
            required: false,
        },
        auto_close: {
            type: Boolean,
            required: false,
            default: (props: any) => !props.timepicker, // might be timepicker dependant
        },
        min_date: {
            type: Date,
            default: null,
            required: false,
        },
        max_date: {
            type: Date,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        scale: {
            type: [String, Number],
            required: false,
            default: 1
        },
        buttons: {
            type: Array as PropType<DatePickerButtons[]>,
            required: false,
            default: () => [] as string[],
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            instance_id: generate_uuid(),
            datepicker: null as AirDatepicker,

            string_value: this.date_to_string(this.modelValue),
            date_value: this.modelValue,
        }
    },
    computed: {
        selected_dates() {
            let selected_dates: Date[] = []
            if (this.date_value != null) selected_dates = [this.date_value]
            return selected_dates;
        }
    },
    watch: {
        date_value() {
            this.$emit('update:modelValue', this.date_value)
        },
        modelValue(new_val, old_val) {
            console.log("watch modelValue");
            console.log(`${this.instance_id} WATCH modelValue:`, this.modelValue, this.date_value, this.string_value);
            const new_time = new_val?.getTime();
            const old_time = old_val?.getTime();
            if (new_time == old_time) return

            console.log(`${this.instance_id} WATCH 2 modelValue:`, this.modelValue, this.date_value, this.string_value);
            this.date_value = this.modelValue
        },
        selected_dates(new_val, old_val) {
            if (_.isEqual(new_val, old_val)) return;

            if (this.selected_dates.length == 0) {
                this.datepicker.clear()
            } else {
                this.datepicker.selectDate(this.selected_dates)
            }
        },
        min_date() {
            this.datepicker.update({ minDate: this.sanitize_min_max_date(this.min_date) })
        },
        max_date() {
            this.datepicker.update({ maxDate: this.sanitize_min_max_date(this.max_date) })
        }
    },
    mounted() {
        this.datepicker = markRaw(new AirDatepicker(`input[data-instance-id="${this.instance_id}"]`, {
            inline: this.inline,
            timepicker: this.timepicker,
            buttons: this.buttons,
            locale: localeEn,
            autoClose: this.auto_close,
            selectedDates: this.selected_dates,
            timeFormat: "HH:mm",
            dateFormat: "d. MM. yyyy.",
            minDate: this.sanitize_min_max_date(this.min_date),
            maxDate: this.sanitize_min_max_date(this.max_date),
            onSelect: (data) => {
                this.date_value = data.date as Date
            },
        })) as AirDatepicker
    },
    updated() {
    },
    methods: {
        date_to_string(date: Date): string {
            if (date == null) return ""
            return moment(date).strftime("%d. %m. %Y. %H:%M")
        },
        string_to_date(date: string): Date {
            if (date == null) return null
            if (date.trim() == "") return null;
            return moment(date, "DD.MM.YYYY hh:mm").toDate()
        },
        sanitize_min_max_date(date: any) {
            if (what_is_it(date) == "String") date = this.string_to_date(date)
            if (date == null) return ""

            return date;
        },
    },
})
</script>

<!--suppress CssInvalidFunction -->
<style lang="scss">

// <editor-fold desc="OBJECT">
%otherMonth {
  color: var(--adp-color-other-month);

  &:hover {
    color: var(--adp-color-other-month-hover);
  }

  &.-disabled- {
    &.-focus- {
      color: var(--adp-color-other-month);
    }
  }

  &.-selected- {
    color: #fff;
    background: var(--adp-background-color-selected-other-month);

    &.-focus- {
      background: var(--adp-background-color-selected-other-month-focused);
    }
  }

  &.-in-range- {
    background-color: var(--adp-background-color-in-range);
    color: var(--adp-color);

    &.-focus- {
      background-color: var(--adp-background-color-in-range-focused);
    }
  }


  &:empty {
    background: none;
    border: none;
  }
}

// </editor-fold>


// <editor-fold desc="DATEPICKER CELL">
.air-datepicker-cell {
  border-radius: var(--adp-cell-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 1; // ?

  &.-focus- {
    background: var(--adp-cell-background-color-hover);
  }

  &.-current- {
    color: var(--adp-color-current-date);

    &.-focus- {
      color: var(--adp-color);
    }

    &.-in-range- {
      color: var(--adp-color-current-date);
    }
  }

  &.-disabled- {
    cursor: default;
    color: var(--adp-color-disabled);

    &.-focus- {
      color: var(--adp-color-disabled);
    }

    &.-in-range- {
      color: var(--adp-color-disabled-in-range);
    }

    &.-current- {
      &.-focus- {
        color: var(--adp-color-disabled);
      }
    }
  }

  &.-in-range- {
    background: var(--adp-cell-background-color-in-range);
    border-radius: 0;

    &:hover {
      background: var(--adp-cell-background-color-in-range-hover);
    }
  }

  &.-range-from- {
    border: 1px solid var(--adp-cell-border-color-in-range);
    background-color: var(--adp-cell-background-color-in-range);
    border-radius: var(--adp-cell-border-radius) 0 0 var(--adp-cell-border-radius);
  }

  &.-range-to- {
    border: 1px solid var(--adp-cell-border-color-in-range);
    background-color: var(--adp-cell-background-color-in-range);
    border-radius: 0 var(--adp-cell-border-radius) var(--adp-cell-border-radius) 0;
  }

  &.-range-to-.-range-from- {
    border-radius: var(--adp-cell-border-radius);
  }

  &.-selected- {
    color: #fff;
    border: none;
    background: var(--adp-cell-background-color-selected);

    &.-current- {
      color: #fff;
      background: var(--adp-cell-background-color-selected);
    }

    &.-focus- {
      background: var(--adp-cell-background-color-selected-hover);
    }
  }
}


/*  Day cell
   ------------------------------------------------- */

.air-datepicker-cell.-day- {
  &.-other-month-, {
    @extend %otherMonth;
  }
}

/*  Year cell
   ------------------------------------------------- */

.air-datepicker-cell.-year- {
  &.-other-decade-, {
    @extend %otherMonth;
  }
}

// </editor-fold>


// <editor-fold desc="DATEPICKER BUTTONS">
.air-datepicker-buttons {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

.air-datepicker-button {
  display: inline-flex;
  color: var(--adp-btn-color);
  border-radius: var(--adp-btn-border-radius);
  cursor: pointer;
  height: var(--adp-btn-height);
  border: none;
  background: rgba(255, 255, 255, 0);

  &:hover {
    color: var(--adp-btn-color-hover);
    background: var(--adp-btn-background-color-hover);
  }

  &:focus {
    color: var(--adp-btn-color-hover);
    background: var(--adp-btn-background-color-hover);
    outline: none;
  }

  &:active {
    background: var(--adp-btn-background-color-active);
  }

  span {
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

// </editor-fold>


// <editor-fold desc="DATEPICKER BODY">

.air-datepicker-body {
  transition: all var(--adp-transition-duration) var(--adp-transition-ease);

  &.-hidden- {
    display: none;
  }
}


/*  Day names
   ------------------------------------------------- */

.air-datepicker-body--day-names {
  display: grid;
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  margin: 8px 0 3px;
}

.air-datepicker-body--day-name {
  color: var(--adp-day-name-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em;

  &.-clickable- {
    cursor: pointer;

    &:hover {
      color: var(--adp-day-name-color-hover);
    }
  }
}

/*  Cells container
   ------------------------------------------------- */

.air-datepicker-body--cells {
  display: grid;

  &.-days- {
    grid-template-columns: repeat(7, var(--adp-day-cell-width));
    grid-auto-rows: var(--adp-day-cell-height);
  }

  &.-months- {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: var(--adp-month-cell-height);
  }

  &.-years- {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: var(--adp-year-cell-height);
  }
}

// </editor-fold>


// <editor-fold desc="DATEPICKER">

.air-datepicker {
  background: var(--adp-background-color);
  border: 1px solid var(--adp-border-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  border-radius: var(--adp-border-radius);
  box-sizing: content-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, max-content);
  grid-template-areas: var(--adp-grid-areas);
  font-family: var(--adp-font-family), sans-serif;
  font-size: var(--adp-font-size);
  color: var(--adp-color);
  width: var(--adp-width);
  position: absolute;
  transition: opacity var(--adp-transition-duration) var(--adp-transition-ease),
  transform var(--adp-transition-duration) var(--adp-transition-ease);
  z-index: var(--adp-z-index);

  &:not(.-custom-position-) {
    opacity: 0;
  }

  &.-from-top- {
    transform: translateY(calc(var(--adp-transition-offset) * -1));
  }

  &.-from-right- {
    transform: translateX(var(--adp-transition-offset));
  }

  &.-from-bottom- {
    transform: translateY(var(--adp-transition-offset));
  }

  &.-from-left- {
    transform: translateX(calc(var(--adp-transition-offset) * -1));
  }

  &.-active- {
    &:not(.-custom-position-) {
      transform: translate(0, 0);
      opacity: 1;
    }

    &.-custom-position- {
      transition: none
    }
  }

  &.-inline- {
    border-color: var(--adp-border-color-inline);
    box-shadow: none;
    position: static;
    left: auto;
    right: auto;
    opacity: 1;
    transform: none;

    .air-datepicker--pointer {
      display: none;
    }
  }

  &.-is-mobile- {
    --adp-font-size: var(--adp-mobile-font-size);
    --adp-day-cell-height: var(--adp-mobile-day-cell-height);
    --adp-month-cell-height: var(--adp-mobile-month-cell-height);
    --adp-year-cell-height: var(--adp-mobile-year-cell-height);
    --adp-nav-height: var(--adp-mobile-nav-height);
    --adp-nav-action-size: var(--adp-mobile-nav-height);

    position: fixed;
    width: var(--adp-mobile-width);
    border: none;

    * {
      -webkit-tap-highlight-color: transparent;
    }

    .air-datepicker--pointer {
      display: none;
    }

    &:not(.-custom-position-) {
      transform: translate(-50%, calc(-50% + var(--adp-transition-offset)));
    }

    &.-active- {
      &:not(.-custom-position-) {
        transform: translate(-50%, -50%);
      }
    }
  }

  &.-custom-position- {
    transition: none;
  }
}

.air-datepicker-global-container {
  position: absolute;
  left: 0;
  top: 0;
}

.air-datepicker--pointer {
  --pointer-half-size: calc(var(--adp-pointer-size) / 2);

  position: absolute;
  width: var(--adp-pointer-size);
  height: var(--adp-pointer-size);
  z-index: -1;

  &:after {
    content: '';
    position: absolute;
    background: var(--ternary-background-color);
    border-top: 1px solid var(--adp-border-color-inline);
    border-right: 1px solid var(--adp-border-color-inline);
    border-top-right-radius: var(--adp-poiner-border-radius);
    width: var(--adp-pointer-size);
    height: var(--adp-pointer-size);
    box-sizing: border-box;
  }

  //  Main axis
  // -------------------------

  .-top-left- &,
  .-top-center- &,
  .-top-right- &,
  [data-popper-placement^='top'] & {
    top: calc(100% - var(--pointer-half-size) + 1px);

    &:after {
      transform: rotate(135deg);
    }
  }

  .-right-top- &,
  .-right-center- &,
  .-right-bottom- &,
  [data-popper-placement^='right'] & {
    right: calc(100% - var(--pointer-half-size) + 1px);

    &:after {
      transform: rotate(225deg);
    }
  }

  .-bottom-left- &,
  .-bottom-center- &,
  .-bottom-right- &,
  [data-popper-placement^='bottom'] & {
    bottom: calc(100% - var(--pointer-half-size) + 1px);

    &:after {
      transform: rotate(315deg);
    }
  }

  .-left-top- &,
  .-left-center- &,
  .-left-bottom- &,
  [data-popper-placement^='left'] & {
    left: calc(100% - var(--pointer-half-size) + 1px);

    &:after {
      transform: rotate(45deg);
    }
  }

  //  Secondary axis
  // -------------------------

  .-top-left- &, .-bottom-left- & {
    left: var(--adp-pointer-offset);
  }

  .-top-right- &, .-bottom-right- & {
    right: var(--adp-pointer-offset);
  }

  .-top-center- &, .-bottom-center- & {
    left: calc(50% - var(--adp-pointer-size) / 2);
  }

  .-left-top- &, .-right-top- & {
    top: var(--adp-pointer-offset);
  }

  .-left-bottom- &, .-right-bottom- & {
    bottom: var(--adp-pointer-offset);
  }

  .-left-center- &, .-right-center- & {
    top: calc(50% - var(--adp-pointer-size) / 2);
  }
}


.air-datepicker--navigation {
  grid-area: nav;
}

.air-datepicker--content {
  box-sizing: content-box;
  padding: var(--adp-padding);
  grid-area: body;

  .-only-timepicker- & {
    display: none;
  }
}

.air-datepicker--time {
  grid-area: timepicker;
}

.air-datepicker--buttons {
  grid-area: buttons;
}

.air-datepicker--buttons,
.air-datepicker--time {
  padding: var(--adp-padding);
  border-top: 1px solid var(--adp-border-color-inner);
}


/*  Overlay
   ------------------------------------------------- */

.air-datepicker-overlay {
  position: fixed;
  background: var(--adp-overlay-background-color);
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease),
  left 0s,
  height 0s,
  width 0s;
  transition-delay: 0s,
  var(--adp-overlay-transition-duration),
  var(--adp-overlay-transition-duration),
  var(--adp-overlay-transition-duration);
  z-index: var(--adp-overlay-z-index);


  &.-active- {
    opacity: 1;
    width: 100%;
    height: 100%;

    transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease),
    height 0s,
    width 0s;
  }
}

// </editor-fold>


// <editor-fold desc="DATEPICKER NAV">

.air-datepicker-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--adp-border-color-inner);
  min-height: var(--adp-nav-height);
  padding: var(--adp-padding);
  box-sizing: content-box;

  .-only-timepicker- & {
    display: none;
  }
}

.air-datepicker-nav--title,
.air-datepicker-nav--action {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

/*  Navigation action
   ------------------------------------------------- */

.air-datepicker-nav--action {
  width: var(--adp-nav-action-size);
  border-radius: var(--adp-border-radius);
  user-select: none;

  &:hover {
    background: var(--adp-background-color-hover);
  }

  &:active {
    background: var(--adp-background-color-active);
  }

  &.-disabled- {
    visibility: hidden;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  path {
    fill: none;
    stroke: var(--adp-nav-arrow-color);
    stroke-width: 2px;
  }
}

/*  Navigation title
   ------------------------------------------------- */

.air-datepicker-nav--title {
  border-radius: var(--adp-border-radius);
  padding: 0 8px;

  i {
    font-style: normal;
    color: var(--adp-nav-color-secondary);
    margin-left: .3em;
  }

  &:hover {
    background: var(--adp-background-color-hover);
  }

  &:active {
    background: var(--adp-background-color-active);
  }

  &.-disabled- {
    cursor: default;
    background: none;
  }
}

// </editor-fold>


// <editor-fold desc="TIMEPICKER">

$rangeTrackBg: #dedede;
$rangeThumbSize: 12px;

@mixin trackSelector {
  &::-webkit-slider-runnable-track {
    @content;
  }

  &::-moz-range-track {
    @content;
  }

  &::-ms-track {
    @content;
  }
}

@mixin thumbSelector {
  &::-webkit-slider-thumb {
    @content;
  }

  &::-moz-range-thumb {
    @content;
  }

  &::-ms-thumb {
    @content;
  }
}

@mixin thumb {
  box-sizing: border-box;
  height: $rangeThumbSize;
  width: $rangeThumbSize;
  border-radius: 3px;
  border: 1px solid var(--adp-time-track-color);
  background: #fff;
  cursor: pointer;

  transition: background var(--adp-transition-duration);
}

@mixin track {
  border: none;
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: transparent;
  background: transparent;
}

.air-datepicker-time {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 12px;
  align-items: center;
  position: relative;
  padding: 0 var(--adp-time-padding-inner);

  .-only-timepicker- & {
    border-top: none;
  }
}

.air-datepicker-time--sliders {
}

.air-datepicker-time--current {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  text-align: center;
}

.air-datepicker-time--current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.air-datepicker-time--current-hours,
.air-datepicker-time--current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    background: var(--adp-background-color-hover);
    border-radius: var(--adp-border-radius);
    position: absolute;
    left: -2px;
    top: -3px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    opacity: 0;
  }

  &.-focus- {
    &:after {
      opacity: 1;
    }
  }
}

.air-datepicker-time--current-ampm {
  text-transform: uppercase;
  align-self: flex-end;
  color: var(--adp-time-day-period-color);
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

.air-datepicker-time--row {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, var(--adp-time-track-color), var(--adp-time-track-color)) left 50%/100% var(--adp-time-track-height) no-repeat;

  &:first-child {
    margin-bottom: 4px;
  }

  input[type='range'] {
    background: none;
    cursor: pointer;
    flex: 1;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-ms-tooltip {
      display: none;
    }

    &:hover {
      @include thumbSelector() {
        border-color: var(--adp-time-track-color-hover);
      }
    }

    &:focus {
      outline: none;

      @include thumbSelector() {
        background: var(--adp-cell-background-color-selected);
        border-color: var(--adp-cell-background-color-selected);
      }
    }

    //  Thumb
    // -------------------------------------------------

    @include thumbSelector() {
      @include thumb;
    }

    &::-webkit-slider-thumb {
      margin-top: calc(var(--adp-time-thumb-size) / 2 * -1);
    }

    //  Track
    // -------------------------------------------------
    @include trackSelector() {
      @include track;
    }

    &::-ms-fill-lower {
      background: transparent;
    }

    &:focus::-ms-fill-lower {

    }

    &::-ms-fill-upper {
      background: transparent;
    }

    &:focus::-ms-fill-upper {

    }
  }
}

// </editor-fold>


// <editor-fold desc="DATEPICKER VARS">

.air-datepicker {
  --adp-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --adp-font-size: 14px;
  --adp-width: 246px; // Day cell width will be equal to 34px
  --adp-z-index: 100;
  --adp-padding: 4px;
  --adp-grid-areas: 'nav' 'body' 'timepicker' 'buttons';

  --adp-transition-duration: .3s;
  --adp-transition-ease: ease-out;
  --adp-transition-offset: 8px;

  --adp-background-color: var(--ternary-background-color);
  --adp-background-color-hover: var(--secondary-background-color);
  --adp-background-color-active: var(--secondary-background-color);
  --adp-background-color-in-range: rgba(92, 196, 239, .1);
  --adp-background-color-in-range-focused: rgba(92, 196, 239, .2);
  --adp-background-color-selected-other-month-focused: #8ad5f4;
  --adp-background-color-selected-other-month: #a2ddf6;

  --adp-color: var(--font-color);
  --adp-color-secondary: var(--font-color-secondary);
  --adp-accent-color: var(--button-blue);
  --adp-color-current-date: var(--adp-accent-color);
  --adp-color-other-month: var(--font-color-secondary);
  --adp-color-disabled: var(--font-color-secondary);
  --adp-color-disabled-in-range: #939393;
  --adp-color-other-month-hover: #c5c5c5;

  --adp-border-color: var(--secondary-background-color);
  --adp-border-color-inner: var(--secondary-background-color);
  --adp-border-radius: 4px;
  --adp-border-color-inline: var(--secondary-background-color);

  --adp-nav-height: 32px;
  --adp-nav-arrow-color: var(--adp-color-secondary);
  --adp-nav-action-size: 32px;
  --adp-nav-color-secondary: var(--adp-color-secondary);

  --adp-day-name-color: #ff9a19;
  --adp-day-name-color-hover: #8ad5f4;

  --adp-day-cell-width: 1fr;
  --adp-day-cell-height: 32px;
  --adp-month-cell-height: 42px;
  --adp-year-cell-height: 56px;

  --adp-pointer-size: 10px;
  --adp-poiner-border-radius: 2px;
  --adp-pointer-offset: 14px;

  --adp-cell-border-radius: 4px;
  --adp-cell-background-color-hover: var(--adp-background-color-hover);
  --adp-cell-background-color-selected: var(--button-blue);
  --adp-cell-background-color-selected-hover: #45bced;
  --adp-cell-background-color-in-range: rgba(92, 196, 239, 0.1);
  --adp-cell-background-color-in-range-hover: rgba(92, 196, 239, 0.2);
  --adp-cell-border-color-in-range: var(--adp-cell-background-color-selected);

  --adp-btn-height: 32px;
  --adp-btn-color: var(--adp-accent-color);
  --adp-btn-color-hover: var(--adp-color);
  --adp-btn-border-radius: var(--adp-border-radius);
  --adp-btn-background-color-hover: var(--adp-background-color-hover);
  --adp-btn-background-color-active: var(--adp-background-color-active);

  --adp-time-track-height: 1px;
  --adp-time-track-color: #dedede;
  --adp-time-track-color-hover: #b1b1b1;
  --adp-time-thumb-size: 12px;
  --adp-time-padding-inner: 10px;
  --adp-time-day-period-color: var(--adp-color-secondary);

  --adp-mobile-font-size: 16px;
  --adp-mobile-nav-height: 40px;
  --adp-mobile-width: 320px;
  --adp-mobile-day-cell-height: 38px;
  --adp-mobile-month-cell-height: 48px;
  --adp-mobile-year-cell-height: 64px;
}

.air-datepicker-overlay {
  --adp-overlay-background-color: rgba(0, 0, 0, .3);
  --adp-overlay-transition-duration: .3s;
  --adp-overlay-transition-ease: ease-out;
  --adp-overlay-z-index: 99;
}

// </editor-fold>


</style>
