<template>
  <div class="element-collection-info-container">
    <div class="title">
      <span>
        -- {{ title }} --
      </span>
      <span v-if="elements.length > minimized_elements"
            class="toggle-show"
            @click="show_all = !show_all">
        {{ show_all ? "Hide" : "Show all" }}
      </span>
    </div>
    <template v-for="(element, index) in elements"
              :key="element.id">
      <div v-if="show_all || index < minimized_elements"
           class="element"
           @mouseover="(e) => element.on_hover(e, 'side-kit-info')"
           @click="(e) => on_click(e, element)">
        <div class="index">
          {{ index + 1 }}.
        </div>
        <div class="attributes">
          <InspectorAttributeInfo
              :attribute="element.attributes[element.inspector.class_key]"
          />
          <InspectorAttributeInfo
              v-if="element.attributes[element.inspector.id_key]?.value != null"
              :attribute="element.attributes[element.inspector.id_key]"
          />

          <InspectorAttributeInfo
              v-if="element.attributes.text?.value != null"
              :attribute="element.attributes.text"
          />

          <InspectorAttributeInfo
              v-if="element.attributes['content-desc']?.value != null"
              :attribute="element.attributes['content-desc']"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { InspectorElement } from "../inspector_element";
import { PropType } from "vue";
import InspectorAttributeInfo from "./InspectorAttributeInfo.vue";

export default defineComponent({
    components: { InspectorAttributeInfo },
    // <editor-fold desc="PROPS">
    props: {
        title: {
            type: String,
            required: true
        },
        elements: {
            type: Array as PropType<InspectorElement[]>,
            required: true
        },
        minimized_elements: {
            type: Number,
            required: false,
            default: 4
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            show_all: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        attribute_present(value: string) {
            if (value == null) return false
            return value.trim() != "";
        },
        on_click(e: MouseEvent, element: InspectorElement) {
            if (this.title == "Intersections") {
                const inspector = element.inspector
                inspector.select_element(element, inspector.clicked_coordinates)
            }
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.element-collection-info-container {
  display: flex;
  flex-direction: column;

  .title {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    position: relative;

    .toggle-show {
      right: 2px;
      cursor: pointer;
      position: absolute;
      font-size: 0.8em;
      color: var(--font-color-secondary);

      &:hover {
        text-decoration: underline;
        color: var(--font-color);
      }
    }
  }


  .element {
    display: flex;
    flex-direction: row;
    background: var(--ternary-background-color);
    border-radius: 5px;
    padding-inline: 5px;
    padding-block: 1px;
    margin-block: 2px;
    width: calc(100% - 10px);
    cursor: pointer;

    &:hover {
      filter: brightness(1.3);
    }

    .index {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
      margin-right: 8px;
      margin-left: 3px;
    }

    .attributes {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 0;
    }
  }
}
</style>
