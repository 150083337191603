import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Image } from "../models/image"
import _ from "lodash";
import { ImageFolder } from "../models/image_folder";
import { file_upload } from "../../helpers/generic/file_upload";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { ImageFolderProps } from "../models/image_folder";
import { ImageProps } from "../models/image";
import { ImageCreateProps } from "../models/image";
import { ImageUpdateProps } from "../models/image";
import { ImageUploadProps } from "../models/image";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ImageClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Image
    declare record: Image

    static load(path: string | number, _reload = false): Promise<Image> {
        return new Promise<Image>((resolve, reject) => {
            $.ajax({
                url: `/images/${path}`,
                type: "GET",
                data: {
                    project_version_id: current.project_version.key(),
                },
                statusCode: ajax_status_codes,
                success: (data: ImageProps) => {
                    const image = Image.new(data)
                    resolve(image)
                },
                error: (error) => {
                    reject(error)
                },
            })
        });
    }

    static save(image: ImageCreateProps, overwrite = false) {
        type Response = {
            ask_overwrite_confirmation: boolean
            saved: boolean
        }

        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: `/images`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    image,
                    overwrite,
                    authenticity_token,
                }),
                success: (data) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/images/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id: this.record.props.project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(image: ImageUpdateProps) {
        type ImageUpdateResponse = {
            updated: boolean
            reason?: string
            image_props?: ImageProps
        }
        image.project_version_id = this.record.props.project_version_id

        return new Promise<ImageUpdateResponse>((resolve, reject) => {
            const code_handlers = _.cloneDeep(ajax_status_codes)
            delete code_handlers[400]
            return $.ajax({
                url: `/images/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    image,
                }),
                statusCode: code_handlers,
                success: (data: ImageUpdateResponse) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
            }) as JQuery.jqXHR<ImageUpdateResponse>
        })
    }

    static batch_download(project_version_id: number, image_folders: ImageFolder[], images: Image[]) {
        const search_params = new URLSearchParams({
            project_version_id: project_version_id.toString(),
            image_paths: JSON.stringify(images.map(f => f.props.path)),
            image_folder_paths: JSON.stringify(image_folders.map(ff => ff.props.path)),
        })

        return $.fileDownload(`/images/batch/download?${search_params.toString()}`)
    }

    download() {
        return $.fileDownload(this.record.computed.download_path)
    }

    static upload(image: ImageUploadProps) {
        return new Promise<any>((resolve, reject) => {
            file_upload({
                url: "/images/upload",
                type: "POST",
                formData: {
                    image: JSON.stringify(image),
                    authenticity_token,
                },
                progressall: (e, data) => {
                    progress_bar.go(data.loaded / data.total * 100);
                },
                fail: (e, data) => {
                    reject(data)
                },
                done: (e, data) => {
                    resolve(data.jqXHR.responseJSON as Response)
                },
            }, ".png", true)
        })
    }

    static batch_path(image_paths: string | string[]) {
        let paths: string[];
        if (what_is_it(image_paths) == "Array") {
            paths = image_paths as string[]
        } else {
            paths = [image_paths as string]
        }

        type PathResponse = {
            [key: string]: ImageFolderProps[]
        }

        type PromiseResponse = {
            [key: string]: ImageFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/images/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    paths,
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(image_path => {
                        const image_folders_props = data[image_path];
                        const array: ImageFolder[] = []
                        image_folders_props.forEach(image_folder_props => {
                            array.push(ImageFolder.new(image_folder_props))
                        })
                        promise_response[image_path] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

}
