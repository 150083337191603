import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { ScenarioSavepointClient } from "../clients/scenario_savepoint_client";
import { ScenarioSavepointScope } from "../scopes/scenario_savepoint_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface ScenarioSavepointProps extends Props {
    id: number
    scenario_id: number
    user_id: number
    save_id: string
    amendable: boolean
    scenario_nodes: any
    archived: boolean
    name: string
    scenario_folder_git_id: string
    xray_project_key: string[]
    created_at: Date
    updated_at: Date
    xray_test_keys: string[]
}
export type QuerifiedScenarioSavepointProps = QuerifyProps<ScenarioSavepointProps>
export type ScenarioSavepointCreateProps = Omit<ScenarioSavepointProps, 'id'>
export type ScenarioSavepointUpdateProps = Partial<ScenarioSavepointProps>

export interface ScenarioSavepointState extends State {}
export interface ScenarioSavepointComputed extends Computed {}
export interface ScenarioSavepointStaticState extends StaticState {
    load_promises: Record<number | string, Promise<ScenarioSavepoint>>
}

// </editor-fold>

const console = new Consoler("warn")
export class ScenarioSavepoint extends VueRecord {
    ['constructor']: typeof ScenarioSavepoint

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScenarioSavepointClient
    static ScopeClass = ScenarioSavepointScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScenarioSavepointStaticState = reactive<ScenarioSavepointStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "scenario_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ScenarioSavepoint> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ScenarioSavepoint>> = {}

    static field_validators: ModelValidatorOpts<ScenarioSavepointProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_SAVEPOINT
    static resource_id = Enum.Resource.Id.SCENARIO_SAVEPOINT
    static icon_class = "fa-solid fa-ellipsis-vertical"
    static color = () => get_css_var("--scenario-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScenarioSavepointClient
    declare props: ScenarioSavepointProps;
    declare state: ScenarioSavepointState;
    declare computed: ScenarioSavepointComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
ScenarioSavepoint.register_resource(ScenarioSavepoint)
ScenarioSavepointClient.ModelClass = ScenarioSavepoint
ScenarioSavepointScope.ModelClass = ScenarioSavepoint

global_event_bus.$on("after_project_version_unload", () => {
    ScenarioSavepoint.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            ScenarioSavepoint.unsync()
            if (project_version_id != null) ScenarioSavepoint.sync(`/sync/project_version/${project_version_id}/scenario_savepoints`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        ScenarioSavepoint: typeof ScenarioSavepoint
    }
}
window.ScenarioSavepoint = ScenarioSavepoint
// </editor-fold>

