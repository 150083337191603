import { Consoler } from "../../../../helpers/api_wrappers/consoler";
import { VueRecordClient } from "../../../base/vue_record_client";
import { PlayScenariosPlayWorkerGroups } from "../../../models/play/bridges/play_scenarios_play_worker_groups";
import { CacheStoreValue } from "../../../base/vue_record_client";

const console = new Consoler("warn")
export class PlayScenariosPlayWorkerGroupsClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlayScenariosPlayWorkerGroups
    declare record: PlayScenariosPlayWorkerGroups
}
