import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { SnippetFolderClient } from "../clients/snippet_folder_client";
import { SnippetFolderScope } from "../scopes/snippet_folder_scope";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { nextTick } from "vue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import UiShowInSidebarData = UiSync.UiShowInSidebarData;
import { SnippetProps } from "./snippet";
import { Snippet } from "./snippet";
import { SnippetScope } from "../scopes/snippet_scope";
import { computed } from "../../helpers/vue/computed";
import { ProjectVersion } from "./project_version";

// <editor-fold desc="TYPES">
export interface SnippetFolderProps extends Props {
    id: number
    project_version_id: number
    name: string
    snippet_folder_id: number
    level: number
    created_at: Date
    updated_at: Date
    archived: boolean
    user_id: number
    color: string
    git_id: string
}
export type QuerifiedSnippetFolderProps = QuerifyProps<SnippetFolderProps>
export type SnippetFolderCreateProps = Omit<SnippetFolderProps, 'id'>
export type SnippetFolderUpdateProps = Partial<SnippetFolderProps>

export interface SnippetFolderState extends State {}
export interface SnippetFolderComputed extends Computed {}
export interface SnippetFolderStaticState extends StaticState {
    children_promises: Record<string, Promise<SnippetFolderChildren>>
}

export type SnippetFolderChildren = {
    snippets: Snippet[],
    snippet_folders: SnippetFolder[]
}
// </editor-fold>

const console = new Consoler("warn")
export class SnippetFolder extends VueRecord {
    ['constructor']: typeof SnippetFolder

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = SnippetFolderClient
    static ScopeClass = SnippetFolderScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: SnippetFolderStaticState = reactive<SnippetFolderStaticState>({
        children_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "snippet_folder_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof SnippetFolder> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof SnippetFolder>> = {}

    static field_validators: ModelValidatorOpts<SnippetFolderProps> = {}

    static resource_name = Enum.Resource.Label.SNIPPET_FOLDER
    static resource_id = Enum.Resource.Id.SNIPPET_FOLDER
    static icon_class = "fa-solid fa-folder"
    static color = () => get_css_var("--snippet-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: SnippetFolderClient
    declare props: SnippetFolderProps;
    declare state: SnippetFolderState;
    declare computed: SnippetFolderComputed;

    // </editor-fold>

    after_sync_add() {
        super.after_sync_add();

        if (this.props.user_id == current.user.key()) {
            nextTick(() => {
                const tree = TestaTree.Tree.get_project_tree()
                if (tree?.is_visible()) {
                    const node = tree.get_node_by_key(this.tree_key())
                    if (node?.is_visible()) {
                        node?.edit_start()
                    }
                }
            })
        }
    }

    after_update(new_props: SnippetProps, old_props: SnippetProps, changes: (keyof SnippetProps)[]) {
        super.after_update(new_props, old_props, changes);

        // color change
        if (this.props.snippets_color && this.props.colored_snippet_ids) {
            Snippet.where({ id: this.props.colored_snippet_ids })
                   .toArray()
                   .each(s => s.props.color = this.props.snippets_color)

            delete this.props.snippets_color
            delete this.props.colored_snippet_ids
        }
    }


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return SnippetFolder.show_in_sidebar(this.props.id, this.props.project_version_id, tree);
    }

    static async show_in_sidebar(snippet_folder_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: number[];
        if (what_is_it(snippet_folder_ids) == "Array") {
            ids = snippet_folder_ids as number[]
        } else {
            ids = [snippet_folder_ids as number]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(snippet_folder_ids).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).each(snippet_folder_id_string => {
                    const snippet_folder_id = parseInt(snippet_folder_id_string)
                    const snippet_folders = promise_response[snippet_folder_id]
                    const keys = [
                        SnippetFolder.tree_key(project_version_id),
                        ...snippet_folders.map(sf => sf.tree_key()),
                        SnippetFolder.find(snippet_folder_id).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: SnippetFolder.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="ACTIONS">
    change_color(color: string) {
        this.client.update({ color })
    }

    // </editor-fold>

    // <editor-fold desc="TREE">
    // <editor-fold desc="HOVER_ACTION">
    static _hover_action_data(project_version_id: number): TestaTree.HoverAction<SnippetScope, SnippetFolderScope, SnippetFolder> {
        return {
            icon: {
                class: "fa-solid fa-plus"
            },
            callback: (event, node) => {
                node.set_expanded(true);
                node.show_context_menu(event, SnippetFolder._tree_new_snippet_contextmenu_items(project_version_id).build);
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<SnippetScope, SnippetFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Snippet.ClientClass.create({
                        snippet_folder_id: this.key(),
                        project_version_id: this.props.project_version_id,
                        type: this.project_version.project_version_setting.props.default_language
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    SnippetFolder.ClientClass.create({
                        snippet_folder_id: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
            }
            return keys;
        })
    }

    static _testa_tree_hotkeys(project_version_id: number) {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<SnippetScope, SnippetFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    const project_version = ProjectVersion.find(project_version_id)
                    Snippet.ClientClass.create({
                        snippet_folder_id: null,
                        project_version_id,
                        type: project_version.project_version_setting.props.default_language
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    SnippetFolder.ClientClass.create({
                        snippet_folder_id: null,
                        project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXT MENU">
    static _tree_new_snippet_contextmenu_items(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<SnippetScope, SnippetFolderScope, SnippetFolder | Snippet>) => {
                const items: ContextMenu.Items = {}
                items.new_snippet = {
                    name: "Snippet",
                    icon: "fas fa-pencil-alt",
                    color: get_css_var("--button-white"),
                    key: "+",
                    callback: () => {
                        node.set_expanded(true);
                        const project_version = ProjectVersion.find(project_version_id)
                        Snippet.ClientClass.create({
                            snippet_folder_id: node.record?.props?.id,
                            project_version_id,
                            type: project_version.project_version_setting.props.default_language
                        });
                    },
                }
                items.new_snippet_folder = {
                    name: "Folder",
                    icon: "fa fa-folder folder",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-+`,
                    callback: () => {
                        node.set_expanded(true);
                        SnippetFolder.ClientClass.create({
                            snippet_folder_id: node.record?.props?.id,
                            project_version_id
                        });
                    },
                }
                return items
            },
        }
    }

    static _testa_tree_contextmenu(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<SnippetScope, SnippetFolderScope, SnippetFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: SnippetFolder._tree_new_snippet_contextmenu_items(project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.color = {
                    name: "Color",
                    items: {
                        color_blue: {
                            name: "Blue",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-blue"),
                            callback: () => node.record.change_color("--folder-color-blue")
                        },
                        color_light_grey: {
                            name: "Grey",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-light-grey"),
                            callback: () => node.record.change_color("--folder-color-light-grey")
                        },
                        color_purple: {
                            name: "Purple",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-purple"),
                            callback: () => node.record.change_color("--folder-color-purple")
                        },
                        color_teal: {
                            name: "Teal",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-teal"),
                            callback: () => node.record.change_color("--folder-color-teal")
                        },
                        color_green: {
                            name: "Green",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-green"),
                            callback: () => node.record.change_color("--folder-color-green")
                        },
                        color_red: {
                            name: "Red",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-red"),
                            callback: () => node.record.change_color("--folder-color-red")
                        },
                        color_orange: {
                            name: "Orange",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-orange"),
                            callback: () => node.record.change_color("--folder-color-orange")
                        },
                        color_blue_grey: {
                            name: "Blue-Grey",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-blue-grey"),
                            callback: () => node.record.change_color("--folder-color-blue-grey")
                        },
                        color_brown: {
                            name: "Brown",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-brown"),
                            callback: () => node.record.change_color("--folder-color-brown")
                        },
                        color_yellow: {
                            name: "Yellow",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-yellow"),
                            callback: () => node.record.change_color("--folder-color-yellow")
                        },
                        color_pink: {
                            name: "Pink",
                            icon: "fa-solid fa-circle",
                            color: get_css_var("--folder-color-pink"),
                            callback: () => node.record.change_color("--folder-color-pink")
                        },
                        color_clear: {
                            name: "Clear",
                            icon: "fa-solid fa-times",
                            color: get_css_var("--font-color-secondary"),
                            callback: () => node.record.change_color(null)
                        },

                    },
                    icon: "fa-solid fa-palette",
                    color: get_css_var("--button-white")
                }

                const more_options: ContextMenu.Items = {}
                more_options.export = TestaTree.Tree.contextmenu_export_item(node)
                more_options.import = TestaTree.Tree.contextmenu_import_item(node)

                return {
                    ...items,
                    ...default_items,
                    more_options: {
                        name: "More",
                        icon: "fa-solid fa-ellipsis",
                        items: more_options
                    }
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="DND">
    _testa_tree_node_dnd(): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => true),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Snippet || r instanceof SnippetFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const snippet_folders = dnd.state.records.filter(r => r instanceof SnippetFolder)
                const snippets = dnd.state.records.filter(r => r instanceof Snippet)
                SnippetFolder.ClientClass.move(this.props.project_version_id, this.props.id, snippet_folders.map(s => s.key()), snippets.map(s => s.key()))
            },
            on_over: (e: DragEvent) => {
                e.dataTransfer.dropEffect = "move"
            }
        }
    }

    static _testa_tree_node_dnd(project_version_id: number): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => false),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return false;
                return dnd.state.records.some(r => r instanceof Snippet || r instanceof SnippetFolder)
            }),
            on_drop: (_e: DragEvent) => {
                const snippet_folders = dnd.state.records.filter(r => r instanceof SnippetFolder)
                const snippets = dnd.state.records.filter(r => r instanceof Snippet)
                SnippetFolder.ClientClass.move(project_version_id, null, snippet_folders.map(s => s.key()), snippets.map(s => s.key()))
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_node_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const snippet_folder_ids = nodes.map(n => n.record)
                                                .filter(r => r instanceof SnippetFolder)
                                                .map(r => r.key())
                const snippet_ids = nodes.map(n => n.record)
                                         .filter(r => r instanceof Snippet)
                                         .map(r => r.key())

                if (type == "copy") {
                    SnippetFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.props.id,
                        snippet_folder_ids,
                        snippet_ids
                    )
                } else if (type == "cut") {
                    SnippetFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.props.id,
                        snippet_folder_ids,
                        snippet_ids
                    )
                }
            }
        }
    }

    static _testa_tree_node_clipboard(project_version_id: number): TestaTree.Clipboard {
        return {
            on_paste: (type, nodes) => {
                const snippet_folder_ids = nodes.map(n => n.record)
                                                .filter(r => r instanceof SnippetFolder)
                                                .map(r => r.key())
                const snippet_ids = nodes.map(n => n.record)
                                         .filter(r => r instanceof Snippet)
                                         .map(r => r.key())

                if (type == "copy") {
                    SnippetFolder.ClientClass.copy(
                        project_version_id,
                        null,
                        snippet_folder_ids,
                        snippet_ids
                    )
                } else if (type == "cut") {
                    SnippetFolder.ClientClass.move(
                        project_version_id,
                        null,
                        snippet_folder_ids,
                        snippet_ids
                    )
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<SnippetScope, SnippetFolderScope, SnippetFolder> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.SNIPPET_FOLDER,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            folder: {
                open_fn: () => {
                    return this.client.children()
                },
                folder_scope: computed(() => {
                    return this.snippet_folders.where({ archived: false }).order("name")
                }),
                file_scope: computed(() => {
                    return this.snippets.where({ archived: false, version_variables: false }).order("name")
                })
            },
            icon: computed(() => {
                let icon_color = get_css_var(this.props.color)
                if (icon_color == "") icon_color = get_css_var("--snippet-color")
                return {
                    color: icon_color,
                }
            }),
            hover_action: computed(() => SnippetFolder._hover_action_data(this.props.project_version_id)),
            dnd: this._testa_tree_node_dnd(),
            contextmenu: SnippetFolder._testa_tree_contextmenu(this.props.project_version_id),
            clipboard: this._testa_tree_node_clipboard(),
            hotkeys: this._testa_tree_hotkeys(),
        }
    }

    static testa_tree_node_data(project_version_id: number): TestaTree.NodeInput<SnippetScope, SnippetFolderScope, SnippetFolder> {
        return {
            resource_id: Enum.Resource.Id.SNIPPET_FOLDER,
            folder: {
                open_fn: () => {
                    return SnippetFolder.ClientClass.children(project_version_id, null)
                },
                file_scope: computed(() => {
                    return Snippet.where({
                        snippet_folder_id: null,
                        archived: false,
                        project_version_id,
                        version_variables: false
                    }).order("name")
                }),
                folder_scope: computed(() => SnippetFolder.where({
                    snippet_folder_id: null,
                    archived: false,
                    project_version_id
                }).order("name"))
            },
            icon: computed(() => {
                return {
                    class: Snippet.icon_class,
                    color: get_css_var("--snippet-color"),
                    scale: 0.9,
                }
            }),
            key: this.tree_key(project_version_id),
            title: computed(() => {
                return { template: "Snippets" }
            }),
            hover_action: computed(() => SnippetFolder._hover_action_data(project_version_id)),
            dnd: SnippetFolder._testa_tree_node_dnd(project_version_id),
            clipboard: SnippetFolder._testa_tree_node_clipboard(project_version_id),
            hotkeys: SnippetFolder._testa_tree_hotkeys(project_version_id),
            contextmenu: SnippetFolder._testa_tree_contextmenu(project_version_id)
        }
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        const names: string[] = []
        let current_folder = this.parent_folder
        while (current_folder != null) {
            names.push(current_folder.name())
            current_folder = current_folder.parent_folder
        }
        if (include_name) names.push(this.name())
        return `/${names.join("/")}`
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
SnippetFolder.register_resource(SnippetFolder)
SnippetFolderClient.ModelClass = SnippetFolder
SnippetFolderScope.ModelClass = SnippetFolder

global_event_bus.$on("after_project_version_unload", () => {
    SnippetFolder.state.children_promises = {}
    SnippetFolder.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            SnippetFolder.unsync()
            if (project_version_id != null) SnippetFolder.sync(`/sync/project_version/${project_version_id}/snippet_folders`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

if (web.is_main) {
    ui_sync.register_ui_task("show_in_sidebar", SnippetFolder.resource_id, (sender: string, data: UiShowInSidebarData) => {
        SnippetFolder.show_in_sidebar(data.id as number, current.project_version.key())
    })
}

declare global {
    interface Window {
        SnippetFolder: typeof SnippetFolder
    }
}
window.SnippetFolder = SnippetFolder
// </editor-fold>

