<template>
  <span class="toggle-icon-container"
        :style="container_style"
        :tabindex="tab_index"
        @keydown="click_on_enter"
        @click="on_click"
        @mousedown="on_mousedown"
  >
    <span
        :id="id"
        class="vue-label toggle-icon"
        :title="title"
        @keydown="click_on_enter"
        @click="on_click"
        @mousedown="on_mousedown"
    >
      <i :class="icon_class"/>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { ColorClass } from "../../types/color_class";
import { click_on_enter } from "../../helpers/events/click_on_enter";
import { get_css_var } from "../../helpers/generic/get_css_var";

export default defineComponent({
    components: { },
    // <editor-fold desc="PROPS">
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
        enabled_color_class: {
            type: String as PropType<ColorClass>,
            required: false,
            default: "white",
        },
        disabled_color_class: {
            type: String as PropType<ColorClass>,
            required: false,
            default: "grey",
        },
        enabled_icon_class: {
            type: String,
            required: true,
        },
        disabled_icon_class: {
            type: String,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        tab_index: {
            type: Number,
            required: false,
            default: 0,
        },
        margin: {
            type: String,
            required: false,
            default: null,
        },
        scale: {
            type: [Number, String],
            required: false,
            default: 1.0
        },
        no_click_focus: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    // </editor-fold>
    emits: ['update:modelValue', 'click', "change"],
    // <editor-fold desc="DATA">
    data() {
        return {
            disabled_color_class_resolved: this.resolve_disabled_icon_class(),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        scale_number(): Number {
            if (typeof this.scale == "string") {
                return parseFloat(this.scale)
            } else return this.scale
        },
        container_style() {
            const color_class = this.modelValue ? this.enabled_color_class : this.disabled_color_class
            let color: string;
            switch (color_class) {
                case "red":
                    color = get_css_var("--button-red")
                    break;
                case "blue":
                    color = get_css_var("--button-blue")
                    break;
                case "white":
                    color = get_css_var("--button-white")
                    break;
                case "primary":
                    color = get_css_var("--primary-background-color")
                    break;
                case "green":
                    color = get_css_var("--button-green")
                    break;
                case "default":
                    color = get_css_var("--secondary-background-color")
                    break;
                case "grey":
                    color = get_css_var("--button-grey")
                    break;
            }

            return {
                fontSize: `${this.scale_number}em`,
                margin: this.margin,
                color
            }
        },
        icon_class() {
            if (this.modelValue) return this.enabled_icon_class
            else return this.disabled_color_class_resolved
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        disabled_icon_class() {
            this.disabled_color_class_resolved = this.resolve_disabled_icon_class();
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        click_on_enter,
        on_click(e: MouseEvent) {
            e.stopImmediatePropagation();
            this.$emit('update:modelValue', !this.modelValue)
            this.$emit('change', this.modelValue)
            this.$emit("click", e)
        },
        resolve_disabled_icon_class() {
            return this.disabled_icon_class == null ? this.enabled_icon_class : this.disabled_icon_class
        },
        on_mousedown(e: MouseEvent) {
            if (this.no_click_focus) e.preventDefault();
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
span.toggle-icon-container {
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  aspect-ratio: 1/1;
  margin-inline: 0.1em;
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;


  $box-shadow-half: 3px;
  span.toggle-icon {
    margin: 0.25em;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: calc(100% - $box-shadow-half);
    height: calc(100% - $box-shadow-half);
    border-radius: 50%;

    // this gives more space to the background when hovering, so that the circle is not cut (is round)
    //margin: -0.1;

    &:hover,
    &:focus {
      box-shadow: 0 0 $box-shadow-half 0 rgba(0,0,0,0.55);
      background-color: var(--primary-background-color);
      filter: brightness(1.3);
    }

    i {
      margin: 0.25em;
    }
  }
}
</style>
