<template>
  <div ref="usage"
       class="usage no-default-focus"
       tabindex="0"
       @keydown="on_keydown"
       @click="$emit('click', variable_usage)"
  >
    <div class="icon">
      <i v-if="variable_usage.is_assign"
         class="fa-solid fa-equals"/>
    </div>
    <div class="name">
      {{ variable_usage.name }}:{{ variable_usage.line + 1 }}
    </div>
    <div class="code"
         v-html="variable_usage.html_code.trim()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { click_on_enter } from "../../../../../helpers/events/click_on_enter";
import { navigate_with_arrows } from "../../../../../helpers/events/navigate_with_arrows";
import { VariableUsage } from "../../../../../types/components/testa/editor/editors/snippet/variable_usage";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        variable_usage: {
            type: Object as PropType<VariableUsage>,
            required: true
        },
        is_active: {
            type: Boolean,
            default: false,
        }
    },
    // </editor-fold>
    emits: ['click'],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_keydown(e: KeyboardEvent) {
            if (!click_on_enter(e)) return false
            if (!navigate_with_arrows(e)) return true
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss">
.snippet-context-result-highlight {
  background: var(--sidebar-selected-contrast);
}
</style>

<style lang="scss" scoped>
.usage{
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  align-items: center;
  color: var(--font-color-secondary);
  font-size: 12px;
  font-family: monospace;

  &:hover,
  &:focus {
    color: var(--font-color);
    background-color: var(--sidebar-selected);
  }

  .icon{
    text-align: center;
    color: var(--button-red);
    display: flex;
    width: 12px;
    flex-shrink: 0;
    font-size: 8px;
  }

  .name{
    text-align: left;
    display: flex;
    white-space: pre;
    margin-right: 30px;
    overflow: hidden;
    max-width: 75%;
    min-width: 25%;
    flex-shrink: 0;
  }

  .code{
    justify-content: flex-end;
    display: flex;
    white-space: pre;
    min-width: 25%;
    color: var(--font-color);

    overflow: hidden;
    font-size: 0.8em;
    width: 100%;
    padding-right: 4px;
  }
}
</style>
