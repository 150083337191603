import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4e4d2e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["id", "title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: "toggle-icon-container",
    style: _normalizeStyle(_ctx.container_style),
    tabindex: _ctx.tab_index,
    onKeydown: _cache[3] || (_cache[3] = (...args) => (_ctx.click_on_enter && _ctx.click_on_enter(...args))),
    onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.on_click && _ctx.on_click(...args))),
    onMousedown: _cache[5] || (_cache[5] = (...args) => (_ctx.on_mousedown && _ctx.on_mousedown(...args)))
  }, [
    _createElementVNode("span", {
      id: _ctx.id,
      class: "vue-label toggle-icon",
      title: _ctx.title,
      onKeydown: _cache[0] || (_cache[0] = (...args) => (_ctx.click_on_enter && _ctx.click_on_enter(...args))),
      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.on_click && _ctx.on_click(...args))),
      onMousedown: _cache[2] || (_cache[2] = (...args) => (_ctx.on_mousedown && _ctx.on_mousedown(...args)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.icon_class)
      }, null, 2 /* CLASS */)
    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
  ], 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
}