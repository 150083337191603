<template>
  <Modal
      id="vue_settings_modal"
      ref="modal"
      :show_cancel="false"
      :show_deny="false"
      confirm_text="Save"
      :dismissible="false"
      :focus_last_active_on_unmount="true"
      :form_validator="form_validator"
      :padding="0"
      :confirm_action="save"
  >
    <template #sidebar>
      <div class="sidebar">
        <div class="container">
          <SettingsSidebar
              :selected_item_id="current_item_id"
              @item-click="change_item"
          />
        </div>
      </div>
    </template>
    <template #body>
      <div class="body">
        <div ref="content"
             class="content">
          <!--<editor-fold desc="USER_SETTINGS">-->
          <UserEdit
              v-if="visited_item_ids.includes(items.user.account.id)"
              v-show="current_item_id == items.user.account.id"
              :user="current.user"
              :navigated_from_table="false"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('account', { on_invalid_submit: () => { change_item(items.user.account.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />

          <Preferences
              v-if="visited_item_ids.includes(items.user.preferences.id)"
              v-show="current_item_id == items.user.preferences.id"
              :project_version_setting="project_version_setting"
              :user_setting="user_setting"
              :form_validator="form_validator.register_child_form('preferences', { on_invalid_submit: () => { change_item(items.user.preferences.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <!--</editor-fold>-->

          <!--<editor-fold desc="PROJECT SETTINGS">-->
          <Variables
              v-if="visited_item_ids.includes(items.project.variables.id)"
              v-show="current_item_id == items.project.variables.id"
              :project_version="project_version"
              :form_validator="form_validator.register_child_form('variables', { on_invalid_submit: () => { change_item(items.project.variables.id) }})"
              :is_viewer_role="is_viewer_role"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />

          <Gemfile
              v-if="visited_item_ids.includes(items.project.gemfile.id)"
              v-show="current_item_id == items.project.gemfile.id"
              :project="project"
              :is_viewer_role="is_viewer_role"
              :form_validator="form_validator.register_child_form('gemfile', { on_invalid_submit: () => { change_item(items.project.gemfile.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"/>


          <Modules
              v-if="visited_item_ids.includes(items.project.modules.id)"
              v-show="current_item_id == items.project.modules.id"
              :project="project"
              :project_version_setting="project_version_setting"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('modules', { on_invalid_submit: () => { change_item(items.project.modules.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"/>

          <WebSettings
              v-if="visited_item_ids.includes(items.project.web.id)"
              v-show="current_item_id == items.project.web.id"
              :project_version_setting="project_version_setting"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('web_settings', { on_invalid_submit: () => { change_item(items.project.web.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />

          <MobileSettings
              v-if="visited_item_ids.includes(items.project.mobile.id)"
              v-show="current_item_id == items.project.mobile.id"
              :project_version_setting="project_version_setting"
              :project_version="project_version"
              :project="project"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('mobile_settings', { on_invalid_submit: () => { change_item(items.project.mobile.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />

          <Integrations
              v-if="visited_item_ids.includes(items.project.integrations.id)"
              v-show="current_item_id == items.project.integrations.id"
              :project_version="project_version"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('integrations', { on_invalid_submit: () => { change_item(items.project.integrations.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />


          <Proxies
              v-if="visited_item_ids.includes(items.project.proxies.id)"
              v-show="current_item_id == items.project.proxies.id"
              :project="project"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('proxies', { on_invalid_submit: () => { change_item(items.project.proxies.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />

          <CodeLint
              v-if="visited_item_ids.includes(items.project.code_lint.id)"
              v-show="current_item_id == items.project.code_lint.id"
              :project="project"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('code_lint', { on_invalid_submit: () => { change_item(items.project.code_lint.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />

          <Users
              v-if="visited_item_ids.includes(items.project.users.id)"
              v-show="current_item_id == items.project.users.id"
              :project="project"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('users', { on_invalid_submit: () => { change_item(items.project.users.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <ProjectVersions
              v-if="visited_item_ids.includes(items.project.project_versions.id)"
              v-show="current_item_id == items.project.project_versions.id"
              :project="project"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('project_versions', { on_invalid_submit: () => { change_item(items.project.project_versions.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <!--</editor-fold>-->

          <!--<editor-fold desc="SUPERADMIN SETTINGS">-->
          <Users
              v-if="visited_item_ids.includes(items.superadmin.all_users.id)"
              v-show="current_item_id == items.superadmin.all_users.id"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('all_users', { on_invalid_submit: () => { change_item(items.superadmin.all_users.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <Projects
              v-if="visited_item_ids.includes(items.superadmin.all_projects.id)"
              v-show="current_item_id == items.superadmin.all_projects.id"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form('all_projects', { on_invalid_submit: () => { change_item(items.superadmin.all_projects.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <Browsers
              v-if="visited_item_ids.includes(items.superadmin.browsers.id)"
              v-show="current_item_id == items.superadmin.browsers.id"
              :form_validator="form_validator.register_child_form('browsers', { on_invalid_submit: () => { change_item(items.superadmin.browsers.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <Phonectors
              v-if="visited_item_ids.includes(items.superadmin.phonectors.id)"
              v-show="current_item_id == items.superadmin.phonectors.id"

              :form_validator="form_validator.register_child_form('phonectors', { on_invalid_submit: () => { change_item(items.superadmin.phonectors.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <Workers
              v-if="visited_item_ids.includes(items.superadmin.workers.id)"
              v-show="current_item_id == items.superadmin.workers.id"
              :form_validator="form_validator.register_child_form('workers', { on_invalid_submit: () => { change_item(items.superadmin.workers.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <DelayedJobs
              v-if="visited_item_ids.includes(items.superadmin.delayed_jobs.id)"
              v-show="current_item_id == items.superadmin.delayed_jobs.id"
              :form_validator="form_validator.register_child_form('delayed_jobs', { on_invalid_submit: () => { change_item(items.superadmin.delayed_jobs.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <FeatureFlags
              v-if="visited_item_ids.includes(items.superadmin.feature_flags.id)"
              v-show="current_item_id == items.superadmin.feature_flags.id"
              :form_validator="form_validator.register_child_form('feature_flags', { on_invalid_submit: () => { change_item(items.superadmin.feature_flags.id) }})"
              @setting-item-mounted="on_item_mounted"
              @setting-item-unmounted="on_item_unmounted"
          />
          <!--</editor-fold>-->
        </div>
      </div>
    </template>
    <template #extra_buttons_right>
      <Button
          id="apply_settings_button"
          text="Apply"
          :style="form_validator.valid ? '' : 'cursor: not-allowed'"
          :form_validator="form_validator"
          :click_action="apply"
      />
      <Button
          id="cancel_settings_button"
          text="Cancel"
          color_class="grey"
          @click="cancel"
      />
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { ProjectVersion } from "../../vue_record/models/project_version";
import Modal from "../testa/Modal.vue";
import Button from "../testa/Button.vue";
import { Component } from "vue";

import { nextTick } from "vue";
import { FormValidator } from "../../helpers/validator/form_validator";
import SettingsSidebar from "./SettingsSidebar.vue";
import Preferences from "./user_settings/preferences/Preferences.vue";
import UserEdit from "./project_settings/users/UserEdit.vue";
import Users from "./project_settings/users/Users.vue";
import Variables from "./project_settings/variables/Variables.vue";
import Gemfile from "./project_settings/gemfile/Gemfile.vue";
import Modules from "./project_settings/modules/Modules.vue";
import WebSettings from "./project_settings/web_settings/WebSettings.vue";
import MobileSettings from "./project_settings/mobile_settings/MobileSettings.vue";
import Integrations from "./project_settings/integrations/Integrations.vue";
import CodeLint from "./project_settings/code_lint/CodeLint.vue";
import Proxies from "./project_settings/proxies/Proxies.vue";
import ProjectVersions from "./project_settings/project_versions/ProjectVersions.vue";
import Projects from "./superadmin_settings/projects/Projects.vue";
import Browsers from "./superadmin_settings/browsers/Browsers.vue";
import Phonectors from "./superadmin_settings/phonectors/Phonectors.vue";
import Workers from "./superadmin_settings/workers/Workers.vue";
import DelayedJobs from "./superadmin_settings/delayed_jobs/DelayedJobs.vue";
import FeatureFlags from "./superadmin_settings/feature_flags/FeatureFlags.vue";

export default defineComponent({
    components: {
        UserEdit,
        Preferences,
        Variables,
        WebSettings,
        MobileSettings,
        Integrations,
        Proxies,
        CodeLint,
        Users,
        Browsers,
        Workers,
        DelayedJobs,
        Modules,
        Gemfile,
        Phonectors,
        ProjectVersions,
        Projects,
        FeatureFlags,
        SettingsSidebar,
        Button,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true,
        },
        initial_setting_id: {
            type: String,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['last-setting-item'],
    // <editor-fold desc="DATA">
    data() {
        return {
            form_validator: new FormValidator(),
            current_item_id: this.initial_setting_id,
            visited_item_ids: [] as string[],
            components_mounted: [] as Component[],
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        $() {
          return $
        },
        items() {
            return setting_items
        },
        current() {
            return current
        },
        project() {
            return this.project_version?.project
        },
        project_version_setting() {
            return this.project_version?.project_version_setting;
        },
        user_setting() {
            return this.project_version?.user_settings?.where({ user_id: current.user.props.id })?.first()
        },
        is_viewer_role() {
            return current_role == Enum.User.Role.VIEWER;
        },
        is_user_role() {
            return current_role == Enum.User.Role.USER;
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save() {
            const promise = this.apply()
            if (promise == null) return
            return promise.done((_values) => {
                this.cancel()
            })
        },
        apply() {
            if (this.form_validator.invalid) return null

            const ajaxes: Promise<any>[] = []
            this.components_mounted.forEach((component: any) => {
                if (component.apply != null) ajaxes.push(component.apply())
            })
            return $.when(...ajaxes)
        },
        cancel() {
            (this.$refs.modal as typeof Modal).close()
            this.$.appContext.app.unmount();
        },
        change_item(new_item_id: string) {
            if (!this.visited_item_ids.includes(new_item_id)) this.visited_item_ids.push(new_item_id);
            else {
                nextTick(() => {
                    nextTick(() => {
                    $(this.$refs.content).find(':focusable').eq(0).trigger("focus")
                })
                })
            }
            this.current_item_id = new_item_id;
            last_setting_item_id = new_item_id
        },
        on_item_mounted(component: Component) {
            // @ts-ignore
            if (this.components_mounted.find(c => component.$.uid == c.$.uid) != null) return
            $(this.$refs.content).find('[tabindex]:not([tabindex="-1"])').eq(0).trigger("focus")
            this.components_mounted.push(component)
        },
        on_item_unmounted(component: Component) {
            // make sure to only unmount components that no longer need saving
            // @ts-ignore
            this.components_mounted = this.components_mounted.filter(c => component.$.uid != c.$.uid)
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
$max-height: 60vh;

.sidebar {
  width: 200px;
  display: flex;
  flex-direction: column;

  .container {
    height: 100%;
    padding: 20px;
    display: flex;
    background-color: var(--secondary-background-color);
    flex-direction: column;

    max-height: $max-height;
    overflow: auto;
  }
}

.body {
  width: 65vw;
  height: $max-height;
  overflow: auto;

  .content {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}
</style>
