import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { SnippetSavepointProps } from "../models/snippet_savepoint";
import { SnippetSavepoint } from "../models/snippet_savepoint";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class SnippetSavepointClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof SnippetSavepoint
    declare record: SnippetSavepoint

    static load(id: string | number, reload = false): Promise<SnippetSavepoint> {
        if (reload) this.ModelClass.state.load_promises[id] = null

        if (this.ModelClass.state.load_promises[id] == null) {
            const promise = new Promise<SnippetSavepoint>((resolve, reject) => {
                $.ajax({
                    url: `/snippet_savepoints/${id}`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: SnippetSavepointProps) => {
                        const snippet_savepoint = SnippetSavepoint.new(data)
                        resolve(snippet_savepoint)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
            this.ModelClass.state.load_promises[id] = promise
            promise.catch(() => {
                this.ModelClass.state.load_promises[id] = null
            })
            return promise
        } else {
            return this.ModelClass.state.load_promises[id]
        }
    }
}
