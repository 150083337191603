import { computed as vue_computed } from "vue";
import { ComputedGetter } from "vue";
import { DebuggerOptions } from "vue";
import { ComputedRef } from "vue";

export function computed<T>(getter: ComputedGetter<T>, debugOptions?: DebuggerOptions): T {
    return vue_computed(getter, debugOptions) as any as T
}

export function computed_ref<T>(getter: ComputedGetter<T>, debugOptions?: DebuggerOptions): ComputedRef<T> {
    return vue_computed(getter, debugOptions)
}
