<template>
  <div v-if="phone != null"
       class="screen-overlay"
       :style="style">
    <AppiumScreenControl
        v-if="is_live && phone.props.appium_screen_control"
        :backend_ws_channel="backend_ws_channel"
        :scenario_setting_phone="scenario_setting_phone"
        :vertical_scale="vertical_scale"
        :horizontal_scale="horizontal_scale"
    />
    <InspectorVue
        v-if="!MODAL_SHOWN"
        :is_resizing="is_resizing"
        :vertical_scale="vertical_scale"
        :horizontal_scale="horizontal_scale"
        :tab="tab"
        :inspector="inspector"
        :screen_overlay_width="width_px"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ScenarioSetting } from "../../../../vue_record/models/scenario_setting";
import { Phone as ScenarioSettingPhone } from "../../../../vue_record/models/scenario_setting/phone"
import InspectorVue from "./inspector/Inspector.vue";
import AppiumScreenControl from "./AppiumScreenControl.vue";
import { PropType } from "vue";
import { Tab } from "../../../testa/editor/tab";
import { Inspector } from "./inspector/inspector";
import { PlayLogExtrasSnapshot } from "../../../../vue_record/models/play/play_log";

export default defineComponent({
    components: {
        AppiumScreenControl,
        InspectorVue,
    },
    props: {
        is_live: {
            type: Boolean,
            required: true
        },
        canvas_width: {
            type: Number,
            required: true,
        },
        canvas_height: {
            type: Number,
            required: true,
        },
        is_resizing: {
            type: Boolean,
            required: true,
        },
        backend_ws_channel: {
            type: String,
            required: false,
            default: null,
        },
        scenario_setting_phone: {
            type: ScenarioSettingPhone,
            required: true,
        },
        snapshot: {
            type: Object as PropType<PlayLogExtrasSnapshot>,
            required: false,
            default: null
        },
        tab: {
            type: Object as PropType<Tab>,
            required: false,
            default: null
        }
    },
    data() {
        return {
            inspector: null as Inspector
        }
    },
    computed: {
        MODAL_SHOWN() {
            return MODAL.is_shown
        },
        phone() {
            return this.scenario_setting_phone?.phone_project?.phone
        },
        phone_props() {
            return this.scenario_setting_phone.props
        },
        scenario_setting(): ScenarioSetting {
            return this.scenario_setting_phone.scenario_setting;
        },
        offset_top() {
            const top = (this.phone_props.top_left_y / this.scenario_setting.props.xvfb_height) * 100;
            return `top: ${top}%;`
        },
        offset_left() {
            const left = (this.phone_props.top_left_x / this.scenario_setting.props.xvfb_width) * 100;
            return `left: ${left}%;`
        },
        width_percentage() {
            return (this.phone_props.downscaled_width / this.scenario_setting.props.xvfb_width);
        },
        width_px() {
            return this.width_percentage * this.canvas_width;
        },
        width() {
            return `width: ${this.width_percentage * 100}%;`
        },
        height() {
            const height = (this.phone_props.downscaled_height / this.scenario_setting.props.xvfb_height) * 100;
            return `height: ${height}%;`
        },
        style() {
            return `${this.offset_left}${this.offset_top}${this.width}${this.height}`
        },
        vertical_scale() {
            if (this.canvas_height == null || this.scenario_setting.props.xvfb_height == null) return 0
            return this.canvas_height / this.scenario_setting.props.xvfb_height;
        },
        horizontal_scale() {
            if (this.canvas_width == null || this.scenario_setting.props.xvfb_width == null) return 0
            return this.canvas_width / this.scenario_setting.props.xvfb_width;
        },
    },
    beforeMount() {
        if (this.is_live) {
            this.inspector = this.scenario_setting_phone.state.inspector
        } else {
            this.inspector = Inspector.new(this.scenario_setting_phone, false)
            this.inspector.add_snapshot(this.snapshot)
        }
    },
    watch: {
    },
    methods: {
    },
})
</script>

<style lang="scss" scoped>
.screen-overlay {
  position: absolute;
  pointer-events: none;
}
</style>
