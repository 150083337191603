import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row align-start" }
const _hoisted_2 = { class: "col s6 vertical" }
const _hoisted_3 = { class: "col s6 vertical" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col s12" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col s12" }
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col s12" }
const _hoisted_12 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_ColorPicker = _resolveComponent("ColorPicker")
  const _component_Select2 = _resolveComponent("Select2")
  const _component_SettingsItem = _resolveComponent("SettingsItem")

  return (_openBlock(), _createBlock(_component_SettingsItem, {
    id: _ctx.setting_items.user.preferences.id,
    title: _ctx.setting_items.user.preferences.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Checkbox, {
            id: "decouple_streams_checkbox",
            modelValue: _ctx.user_props.decouple_streams,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.user_props.decouple_streams) = $event)),
            label: "Decouple streams from reports"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_Checkbox, {
            id: "snippet_line_wrap_checkbox",
            modelValue: _ctx.user_props.snippet_line_wrap,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.user_props.snippet_line_wrap) = $event)),
            label: "Wrap snippet lines"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_Checkbox, {
            id: "show_hints_as_you_type_checkbox",
            modelValue: _ctx.user_props.show_hints_as_you_type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.user_props.show_hints_as_you_type) = $event)),
            label: "Show hits as you type"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_Checkbox, {
            id: "dark_mode_checkbox",
            modelValue: _ctx.user_props.dark_mode,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.user_props.dark_mode) = $event)),
            label: "Dark mode"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_Checkbox, {
            id: "hover_zoom_checkbox",
            modelValue: _ctx.user_props.hover_zoom_enabled,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.user_props.hover_zoom_enabled) = $event)),
            label: "Hover zoom"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_Checkbox, {
            id: "auto_expand_play_scenarios_checkbox",
            modelValue: _ctx.user_props.auto_expand_play_scenarios,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.user_props.auto_expand_play_scenarios) = $event)),
            label: "Auto-expand play scenarios"
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ColorPicker, {
                modelValue: _ctx.user_setting_props.subtitle_color,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.user_setting_props.subtitle_color) = $event)),
                label: "Subtitle color",
                colors: _ctx.Enum.User.Setting.Subtitle.COLORS,
                allow_blank: false,
                validator: _ctx.form_validator.register('subtitle_color', _ctx.UserSetting.field_validators.subtitle_color)
              }, null, 8 /* PROPS */, ["modelValue", "colors", "validator"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Select2, {
                id: "subtitle_position_select",
                modelValue: _ctx.user_setting_props.subtitle_position,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.user_setting_props.subtitle_position) = $event)),
                label: "Subtitle Position",
                validator: _ctx.form_validator.register('subtitle_position', _ctx.UserSetting.field_validators.subtitle_position),
                dropdown_parent: "#vue_settings_modal"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("option", {
                    value: _ctx.Enum.User.Setting.Subtitle.Position.TOP
                  }, _toDisplayString(_ctx.Enum.User.Setting.Subtitle.Position.TOP), 9 /* TEXT, PROPS */, _hoisted_8),
                  _createElementVNode("option", {
                    value: _ctx.Enum.User.Setting.Subtitle.Position.BOTTOM
                  }, _toDisplayString(_ctx.Enum.User.Setting.Subtitle.Position.BOTTOM), 9 /* TEXT, PROPS */, _hoisted_9)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "validator"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Select2, {
                id: "subtitle_size_select",
                modelValue: _ctx.user_setting_props.subtitle_size,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.user_setting_props.subtitle_size) = $event)),
                label: "Subtitle Size",
                validator: _ctx.form_validator.register('subtitle_size', _ctx.UserSetting.field_validators.subtitle_size),
                dropdown_parent: "#vue_settings_modal"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subtitle_size_range, (index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: index
                    }, _toDisplayString(index), 9 /* TEXT, PROPS */, _hoisted_12))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "validator"])
            ])
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id", "title"]))
}