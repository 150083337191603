import { Data } from "faye";
import { FileFolderProps } from "../../vue_record/models/file_folder";
import { FileProps } from "../../vue_record/models/file";

type Resource = "file_folders" | "files" | "image_folders" | "images"

function get_resource_starting_dir(resource: Resource) {
    switch (resource) {
        case "file_folders":
        case "files":
            return "/files"
        case "image_folders":
        case "images":
            return "/images"
        default:
            throw new Error(`Unknown resource ${resource}`)
    }
}

function directory_to_path(directory: string, resource: Resource) {
    let file_folder_path: string = directory
    const starting_dir = get_resource_starting_dir(resource) + "/"
    if (file_folder_path.startsWith(starting_dir)) {
        file_folder_path = file_folder_path.replace(starting_dir, "")
    }
    if (file_folder_path == "" || file_folder_path == "/") file_folder_path = null
    if (file_folder_path != null && !file_folder_path.startsWith("/")) file_folder_path = `/${file_folder_path}`
    if (file_folder_path != null && file_folder_path.endsWith("/")) file_folder_path = file_folder_path.slice(0, -1)
    return file_folder_path
}

function get_old_path(sync_data: Data) {
    const data: any = sync_data.data;

    let old_directory = data.old_directory;
    let old_name = data.old_name;
    if (old_directory == null && old_name == null) return null;

    if (old_directory == null) old_directory = data.directory
    if (old_name == null) old_name = data.name

    const old_directory_path = directory_to_path(old_directory, sync_data.resource)
    return `${old_directory_path == null ? "" : old_directory_path}/${old_name}`
}

function convert_file_folders_sync_data(data: Data) {
    const file_folder_path = directory_to_path(data.data.directory, "file_folders")
    const __old_path = get_old_path(data);
    const name = data.data.name
    const params: Partial<FileFolderProps> = {
        __old_path,
        file_folder_path,
        name,
        path: `${file_folder_path == null ? "" : file_folder_path}/${name}`,
        project_version_id: parseInt(data.data.project_version_id),
        size: data.data.size,
        type: Enum.File.Type.FOLDER
    }
    return {
        resource: data.resource,
        action: data.action,
        data: params,
        extras: data.extras,
    }
}

function convert_files_sync_data(data: Data) {
    const file_folder_path = directory_to_path(data.data.directory, "files")
    const __old_path = get_old_path(data);
    const name = data.data.name
    const params: Partial<FileProps> = {
        __old_path,
        file_folder_path,
        name,
        path: `${file_folder_path == null ? "" : file_folder_path}/${name}`,
        project_version_id: parseInt(data.data.project_version_id),
        size: data.data.size,
        type: Enum.File.Type.FILE
    }
    return {
        resource: data.resource,
        action: data.action,
        data: params,
        extras: data.extras,
    }
}

function convert_image_folders_sync_data(data: Data) {
    const image_folder_path = directory_to_path(data.data.directory, "image_folders")
    const __old_path = get_old_path(data);
    const name = data.data.name
    const params: Partial<FileFolderProps> = {
        __old_path,
        image_folder_path,
        name,
        path: `${image_folder_path == null ? "" : image_folder_path}/${name}`,
        project_version_id: parseInt(data.data.project_version_id),
        size: data.data.size,
        type: Enum.File.Type.FOLDER
    }
    return {
        resource: data.resource,
        action: data.action,
        data: params,
        extras: data.extras,
    }
}

function convert_images_sync_data(data: Data) {
    const image_folder_path = directory_to_path(data.data.directory, "images")
    const __old_path = get_old_path(data);
    const name = data.data.name
    const params: Partial<FileProps> = {
        __old_path,
        image_folder_path,
        name,
        path: `${image_folder_path == null ? "" : image_folder_path}/${name}`,
        project_version_id: parseInt(data.data.project_version_id),
        size: data.data.size,
        type: Enum.File.Type.FILE
    }
    return {
        resource: data.resource,
        action: data.action,
        data: params,
        extras: data.extras,
    }
}

export function convert_sync_data_to_new_format(data: Data) {
    const resource: Resource = data.resource;
    switch (resource) {
        case "file_folders":
            return convert_file_folders_sync_data(data)
        case "files":
            return convert_files_sync_data(data)
        case "image_folders":
            return convert_image_folders_sync_data(data)
        case "images":
            return convert_images_sync_data(data)
        default:
            throw new Error(`Unknown resource ${resource}`)
    }
}
