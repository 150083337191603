<template>
  <SettingsItem id="workers"
                :title="items.superadmin.workers.title">
    <div class="row"
         style="display: flex; align-items: center">
      <div class="col s4"
           style="display: flex">
        <Button
            id="worker_config_button"
            text="Config"
            @click="show_spinner_settings = true"
        />
        <Button
            v-if="deletion_markers.marked().length > 0"
            id="delete_workers_button"
            text="Delete"
            color_class="red"
            @click="delete_workers"
        />
      </div>
      <div class="col s4 center">
        <div class="worker-count-controls-container">
          <ActionIcon
              id="decrease_worker_count_action"
              icon_class="fa fa-minus"
              color_class="red"
              title="Decrease worker count"
              @click="change_worker_count(false)"
          />
          <div class="count-tag-display">
            <div>
              <span id="standby_workers_count">
                {{ standby_workers_count }}
              </span>
              /
              <span id="target_workers_count">
                {{ spinner_setting?.props?.workers }}
              </span>
            </div>
            <div>{{ spinner_setting?.props?.dj_tag }}</div>
          </div>
          <ActionIcon
              id="increase_worker_count_action"
              icon_class="fa fa-plus"
              color_class="green"
              title="Increase worker count"
              @click="change_worker_count(true)"
          />
        </div>
      </div>
      <div class="col s4">
        <Input
            id="workers_filter"
            v-model="filter"
            placeholder="Filter"
            :throttle_time="200"
        />
      </div>
    </div>
    <Table
        v-if="loaded"
        id="workers_table"
    >
      <template #thead>
        <tr>
          <template v-for="(col, key) in visible_columns"
                    :key="key">
            <th v-if="key == 'delete'"
                :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                @click.stop="all_marked = !all_marked">
              <Checkbox
                  v-model="all_marked"
                  label=""
                  :for_table="true"
                  color_class="red"
              />
            </th>
            <th v-else
                :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                :data-priority="orders.priority(key)"
                @click="orders.toggle(key)">
              {{ col.name }}
            </th>
          </template>
        </tr>
      </template>

      <template #tbody>
        <template v-for="row in ordered_rows"
                  :key="row.record.key()">
          <tr
              class="worker-row"
              @click="expanded_markers.marker(row.record).toggle()"
          >
            <template v-for="(col, index) in row.cols"
                      :key="index">
              <td v-if="col.column_key == 'delete'"
                  :class="col.classes"
                  @click.stop="deletion_markers.marker(row.record).toggle()">
                <Checkbox
                    v-model="deletion_markers.marker(row.record).value"
                    label=""
                    :for_table="true"
                    color_class="red"
                />
              </td>
              <td v-else
                  v-moment="col.moment"
                  :title="col.title"
                  :class="col.classes"
                  :style="col.style"
                  v-html="col.html"/>
            </template>
          </tr>
          <template v-if="expanded_markers.marker(row.record).value">
            <tr v-for="log in row.record.state.logs"
                :key="log.key"
                class="worker-log-row"
            >
              <td :colspan="row.cols.length">
                {{ log.log }}
              </td>
            </tr>
          </template>
        </template>
      </template>
    </Table>
    <Loading
        v-else
        type="rotating_plane"
        :inflate="true"
        :size="5"
    />
    <SpinnerSettingsEditModal
        v-if="show_spinner_settings"
        :spinner_setting="spinner_setting"
        @exit="show_spinner_settings = false"
    />
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Table from "../../../testa/Table.vue";
import Input from "../../../testa/Input.vue";
import SettingsItem from "../../SettingsItem.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Button from "../../../testa/Button.vue"
import moment from "moment/moment";
import { SpinnerSetting } from "../../../../vue_record/models/spinner_setting";
import SpinnerSettingsEditModal from "./SpinnerSettingsEditModal.vue";
import { PropType } from "vue";
import ActionIcon from "../../../testa/ActionIcon.vue";
import Loading from "../../../testa/Loading.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { limit_str_length } from "../../../../helpers/pretty/limit_str_length";
import { Worker } from "../../../../vue_record/models/./non_db/worker"
import { WorkersInfo } from "../../../../vue_record/models/./non_db/worker";
import { Consoler } from "../../../../helpers/api_wrappers/consoler";
import { interpolate_color } from "../../../../helpers/generic/interpolate_color";
import { safe_stringify } from "../../../../helpers/generic/safe_stringify";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";


interface SyncMessage {
    data: WorkersInfo
}

const console = new Consoler("debug")
export default defineComponent({
    components: {
        Loading,
        ActionIcon,
        SpinnerSettingsEditModal,
        Checkbox,
        SettingsItem,
        Input,
        Table,
        Button,
    },
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            authenticity_token,
            loaded: false,
            show_spinner_settings: false,
            filter: "",
            all_marked: false,
            all_workers_count: 0,
            standby_workers_count: 0,
            spinner_setting: null as SpinnerSetting,
            deletion_markers: new RecordMarker([]),
            expanded_markers: new RecordMarker([]),
            columns: {
                delete: {
                    filterable: false,
                    orderable: false,
                    action: true,
                    classes: "red",
                },
                name: {
                    name: "Name",
                    html: (record) => record.props.name,
                    title: (record) => record.props.name,
                    classes: (record) => {
                        return {
                            red: record.props.jobless,
                            green: !record.props.jobless,
                        }
                    },
                },
                memory_used: {
                    name: "Memory used",
                    html: (record) => `${record.props.resources.memory_used}/${record.props.resources.memory_limit}`,
                    title: () => `Memory used`,
                    order_value: (record) => record.props.resources.memory_used,
                    style: (record) => {
                        return { background: this.memory_background(record) }
                    }
                },
                cpu_used: {
                    name: "CPU used",
                    html: (record) => `${record.props.resources.cpu_used}/${record.props.resources.cpu_limit}`,
                    title: () => `CPU used`,
                    order_value: (record) => record.props.resources.cpu_used,
                    style: (record) => {
                        return { background: this.cpu_background(record) }
                    }
                },
                tag: {
                    name: "Tag",
                    html: (record) => limit_str_length(record.props.dj_tag, 8),
                    title: (record) => record.props.dj_tag,
                },
                node: {
                    name: "Node",
                    html: (record) => limit_str_length(record.props.node_name, 8),
                    title: (record) => record.props.node_name,
                },
                status: {
                    name: "Status",
                    html: (record) => record.props.status,
                    classes: (record) => this.status_color(record),
                },
                ready: {
                    name: "Ready",
                    html: (record) => {
                        return record.props.ready
                    },
                },
                age: {
                    name: "Age",
                    html: (record) => {
                        return moment(record.props.created, "YYYY-MM-DD HH:mm:ss ZZ").fromNow()
                    },
                    order_value: (record) => {
                        return new Date(record.props.created)
                    },
                },
            } as RecordColumns<Worker>,
            orders: new RecordOrder([["status", "desc"], ["age", "asc"]]),
        }
    },
    computed: {
        JSON() {
            return JSON
        },
        items() {
            return setting_items
        },
        visible_columns() {
            return RecordsTable.visible_columns<Worker>(this.columns);
        },
        workers() {
            return Worker.get_scope().toArray();
        },
        rows() {
            return RecordsTable.generate_rows<Worker>(this.visible_columns, this.workers as Worker[])
        },
        filtered_rows() {
            return RecordsTable.filter<Worker>({
                columns: this.visible_columns,
                rows: this.rows,
                filter: this.filter,
            })
        },
        ordered_rows() {
            return RecordsTable.order<Worker>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    watch: {
        'filtered_rows'() {
            this.deletion_markers.set_records(this.filtered_rows.map(c => c.record) as Worker[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
    },
    mounted() {
        this.$emit("setting-item-mounted", this)
        faye.subscribe("/sync/workers", (message: SyncMessage) => {
            console.debug(message);
            const reported_workers: Worker[] = []
            message.data.all_workers.forEach(worker_props => {
                // just for testing purposes
                // worker_props.resources.cpu_used = `${Math.random().toFixed(1)}`
                // worker_props.resources.memory_used = `${(Math.random() * 4).toFixed(1)}Gi`
                reported_workers.push(Worker.new(worker_props))
            })
            Worker.get_scope().not({ private_sha: reported_workers.map(w => w.key()) }).each(w => w.unload())
            this.all_workers_count = message.data.worker_count.all_workers_count
            this.standby_workers_count = message.data.worker_count.standby_workers_count
        })
        this.load()
    },
    unmounted() {
        faye.unsubscribe("/sync/workers")
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        safe_stringify,
        delete_workers() {
            Worker.delete(this.deletion_markers.marked().map(r => r.key()))
                  .then((success) => {
                      if (success) {
                          this.deletion_markers.set_all(false)
                      }
                  })
        },
        status_color(worker: Worker) {
            if (["terminating", "error"].includes(worker.props.status)) return "red"
            if (worker.props.status == "working") return "yellow"
            if (worker.props.status == "standby") return "green"
            return ""
        },
        change_worker_count(up: boolean) {
            if (!up && this.spinner_setting.props.workers == 0) return;
            Worker.ClientClass.change_count(up)
        },
        load() {
            Worker.ClientClass.index().then(() => {
                this.loaded = true
                this.spinner_setting = SpinnerSetting.get_scope().first()
            })
        },
        memory_background(worker: Worker) {
            const used = parseFloat(worker.props.resources.memory_used)
            const limit = parseFloat(worker.props.resources.memory_limit);
            return this.resource_background((used / limit) * 100)
        },
        cpu_background(worker: Worker) {
            const used = parseFloat(worker.props.resources.cpu_used)
            const limit = parseFloat(worker.props.resources.cpu_limit);
            return this.resource_background((used / limit) * 100)
        },
        resource_background(percentage: number) {
            if (percentage > 100) percentage = 100
            const color = interpolate_color('rgba(66, 165, 245, 0.6)', 'rgba(196,66,63,0.8)', percentage / 100)
            return `linear-gradient(90deg, ${color} 0 ${percentage}%, transparent ${percentage}% 100%)`
        },
    },
})
</script>

<style lang="scss" scoped>

.worker-row {
  cursor: pointer;

  td {
    &.green {
      color: var(--button-green);
    }

    &.red {
      color: var(--button-red);

    }

    &.yellow {
      color: var(--button-yellow);
    }
  }
}

.worker-log-row {
  font-size: 0.8em;
}

.worker-count-controls-container {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;

  .count-tag-display {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--font-color-secondary);
    margin-inline: 10px;
  }

  .count-control {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    border-radius: 50%;

    &:hover,
    &:focus {
      background-color: var(--primary-background-color);
      filter: brightness(1.2);
    }

    &.red {
      color: var(--button-red);
    }

    &.green {
      color: var(--button-green);
    }
  }
}
</style>
