<template>
  <div class="title-container">
    <div class="title-primary-info">
      <div class="name">
        <span>{{ app.props.name }}</span>
      </div>
    </div>
    <div v-if="!App.state.order_by_version"
         class="title-secondary-info">
      <span>Uploaded at </span>
      <span v-datetime="app.props.created_at"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { App } from "../../vue_record/models/app";
export default defineComponent({
    components: {},
    // <editor-fold desc="PROPS">
    props: {
        app: {
            type: Object as PropType<App>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        App() {
            return App
        }

    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 2px;
  padding-bottom: 2px;

  .title-primary-info {
    display: flex;
    flex-direction: row;
    font-size: 1.1em;
    align-items: baseline;

    .play-type-icon-container {
      font-size: 0.9em;
      margin-right: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    div.name {
      color: var(--font-color);
      margin-right: 5px;
      white-space: nowrap;
      flex-shrink: 0;

      span.highlight {
        background: var(--sidebar-selected-contrast);
      }
    }
  }

  .title-secondary-info {
    color: var(--font-color-secondary);
    font-size: 0.9em;
  }
}

</style>
