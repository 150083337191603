<template>
  <div class="diff-container">
    <div class="labels-container">
      <div
          class="snippet-label"
          :title="snippet_label"
      >
        {{ snippet_label }}
      </div>
      <div class="gap"/>
      <div
          class="other-label"
          :title="other_label"
      >
        {{ other_label }}
      </div>
    </div>
    <div ref="diff"
         class="diff"
    />
    <div v-if="show_apply_buttons"
         class="buttons-container">
      <div class="left">
        <Button
            text="Apply"
            :click_action="apply_current"
        />
      </div>
      <div class="gap"/>
      <div class="right">
        <Button
            text="Apply Right"
            :click_action="apply_right"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Snippet, SnippetEditorActionItem } from "../../../../../vue_record/models/snippet";
import { PropType } from "vue";
import { init_diff_two_way_codemirror } from "../../../../../helpers/codemirror/init_codemirror";
import { generate_uuid } from "../../../../../helpers/generate/generate_uuid";
import { MergeView } from "codemirror/addon/merge/merge";
import CodeMirror from "codemirror";
import Button from "../../../Button.vue";
import { color_changed_lines } from "../../../../../helpers/codemirror/color_changed_lines";
import {
    attach_usage_click_listener
} from "../../../../../helpers/codemirror/attach_usage_click_listener";
import { attach_codemirror_contextmenu } from "../../../../../helpers/codemirror/attach_codemirror_contextmenu";
import { debouce_changes } from "../../../../../helpers/codemirror/debounced_changes";
import { highlight_merged_code } from "../../../../../helpers/codemirror/highlight_merged_code";
import { track_local_changes } from "../../../../../helpers/codemirror/track_local_changes";
import { track_not_committed } from "../../../../../helpers/codemirror/track_not_committed";
import { align_chained_methods_on_change } from "../../../../../helpers/codemirror/align_chained_methods";
import { watch_style_changes } from "../../../../../helpers/codemirror/watch_style_changes";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";

export default defineComponent({
    components: { Button },
    // <editor-fold desc="PROPS">
    props: {
        snippet: {
            type: Object as PropType<Snippet>,
            required: true,
        },
        other_code: {
            type: String,
            required: true
        },
        snippet_label: {
            type: String,
            required: false,
            default: "Current"
        },
        other_label: {
            type: String,
            required: false,
            default: null,
        },
        show_apply_buttons: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    // </editor-fold>
    emits: ['applied'],
    // <editor-fold desc="DATA">
    data() {
        return {
            instance_id: generate_uuid(),
            amo: null as AllMightyObserver,
            style_watcher_stop_handler: null as Function,
            mv: null as MergeView
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        project_version() {
            return this.snippet.project_version
        },
        project() {
            return this.project_version.project
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'current.theme'() {
            const mv = this.mv
            const snippet_cm = mv.editor();
            const savepoint_cm = mv.right.orig;
            snippet_cm.setOption("theme", this.current.theme)
            savepoint_cm.setOption("theme", this.current.theme)
        },
        'snippet.computed.role_is_viewer'() {
            const mv = this.mv
            const snippet_cm = mv.editor();
            snippet_cm.setOption("readOnly", this.snippet.computed.role_is_viewer)
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const diff = this.$refs.diff as HTMLElement
        if (!this.show_apply_buttons) {
            diff.style.height = `${diff.clientHeight + 35}px`;
        }
        const mv = init_diff_two_way_codemirror(diff, this.project, {
            history: this.snippet.state.history_not_committed,
            readOnly: this.snippet.computed.role_is_viewer,
            orig: this.other_code,
            value: this.snippet.state.code_not_committed,
            revertChunk: (mv: MergeView,
                                  from: CodeMirror.Editor,
                                  fromStart: CodeMirror.Position,
                                  fromEnd: CodeMirror.Position,
                                  to: CodeMirror.Editor,
                                  toStart: CodeMirror.Position,
                                  toEnd: CodeMirror.Position) => {
                if (!this.snippet.computed.role_is_viewer) {
                    to.replaceRange(from.getRange(fromStart, fromEnd), toStart, toEnd);
                }
            }
        })
        this.mv = mv

        const snippet_cm = mv.editor();
        const savepoint_cm = mv.right.orig;

        const cms = this.snippet.state.codemirrors
        if (cms.length > 0) {
            snippet_cm.swapDoc(cms[0].getDoc().linkedDoc({ sharedHist: true }))
        }

        this.snippet.state.codemirrors.push(snippet_cm)
        color_changed_lines(snippet_cm, ["merge"])
        attach_usage_click_listener(snippet_cm, this.snippet)
        attach_usage_click_listener(savepoint_cm, this.snippet)
        highlight_merged_code(snippet_cm)
        align_chained_methods_on_change(snippet_cm);

        track_not_committed(snippet_cm, this.snippet)
        track_local_changes(snippet_cm, this.snippet)

        this.style_watcher_stop_handler = watch_style_changes(snippet_cm)

        // after every changes event, capture current code and history
        // then set timeout to save that code and history after the delay
        // NOTE: capturing code and history after delay might be too late if user closes codemirror or tab
        debouce_changes(snippet_cm, 1000, (cm) => {
                const props = this.snippet._extract_codemirror_props(cm);
                return () => {
                    this.snippet._save_codemirror_code(props)
                }
            },
            (timeout) => {
                this.snippet.state.autosave = timeout
            },
            ["merge"]
        )

        const exclude_actions_in_snippet: SnippetEditorActionItem[] = ["history", "run", "fullscreen", "quick_fix"]
        const exclude_actions_in_savepoint: SnippetEditorActionItem[] = ["save", "history", "format", "run", "cut", "paste", "fullscreen", "show", "quick_fix"]

        attach_codemirror_contextmenu(snippet_cm, (cm, e) => {
            return this.snippet._editor_contextmenu(cm, e, exclude_actions_in_snippet)
        })
        attach_codemirror_contextmenu(savepoint_cm, (cm, e) => {
            return this.snippet._editor_contextmenu(cm, e, exclude_actions_in_savepoint)
        })

        snippet_cm.addKeyMap(this.snippet._editor_keymap(snippet_cm, exclude_actions_in_snippet))
        savepoint_cm.addKeyMap(this.snippet._editor_keymap(savepoint_cm, exclude_actions_in_savepoint))

        this.fix_dv_size(diff.clientWidth, diff.clientHeight)
        this.amo = AllMightyObserver.new({
            element_after_resize: true,
            after_resize: true,
            element_visible: true,
            target_element: diff,
            callback: () => {
                this.fix_dv_size(diff.clientWidth, diff.clientHeight)
            }
        })
    },
    unmounted() {
        this.snippet.state.codemirrors = this.snippet.state.codemirrors.filter(cm => cm != this.mv.editor())
        this.amo?.stop();
        if (this.style_watcher_stop_handler != null) this.style_watcher_stop_handler()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        fix_dv_size(width: number, height: number) {
            const mv = this.mv;
            if (mv == null) return;
            mv.right.orig.setSize("100%", `${height}px`)
        },
        apply_current() {
            return this.snippet._save_codemirror_code(this.snippet._extract_codemirror_props(this.mv.editor()))
                       .then(() => {
                           this.$emit("applied")
                       })
        },
        apply_right() {
            return this.snippet
                       ._save_codemirror_code(this.snippet._extract_codemirror_props(this.mv.right.orig as CodeMirror.Editor))
                       .then(() => {
                           this.$emit("applied")
                       })
        },

    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.diff-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  $labels-height: 17px;
  $labels-border-bottom-width: 1px;
  $buttons-height: 33px;
  $buttons-border-top-width: 1px;

  .labels-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    font-family: monospace;
    font-size: 13px;
    height: $labels-height;
    border-bottom: $labels-border-bottom-width solid var(--secondary-background-color);
    align-items: center;

    .snippet-label {
      padding-left: 5px;
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .gap {
      // probably more. but should be ok
      width: 50px;
    }

    .other-label {
      width: 50%;
      text-align: right;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .diff {
    height: calc(100% - calc($labels-height + $labels-border-bottom-width + $buttons-border-top-width + $buttons-height));
    width: 100%;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    height: $buttons-height;
    border-top: $buttons-border-top-width solid var(--secondary-background-color);
    align-items: center;

    .left {
      width: 50%;
    }

    .gap {
      width: 50px;
    }

    .right {
      width: 50%;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
</style>
