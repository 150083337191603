
import { Phone as ScenarioSettingPhone } from "../../models/scenario_setting/phone";
import { PhoneProject } from "../../models/phone_project";
import { ScenarioSetting } from "../../models/scenario_setting";
import { PhoneAppScope } from "../../scopes/scenario_setting/phone_app_scope";
import { PhoneApp } from "../../models/scenario_setting/phone_app";

declare module "../../models/scenario_setting/phone" {

    interface Phone {
        scenario_setting: ScenarioSetting
        phone_project: PhoneProject
        apps: PhoneAppScope
    }
}

// belongs to
ScenarioSettingPhone.belongs_to('scenario_setting', ScenarioSetting, 'scenario_setting_id')
ScenarioSettingPhone.belongs_to("phone_project", PhoneProject, "phone_project_id")

// has many
ScenarioSettingPhone.has_many("apps", PhoneApp, "scenario_setting_phone_id")
