
import { ScenarioNode } from "../models/scenario_node";
import { Scenario } from "../models/scenario";
import { Snippet } from "../models/snippet";

declare module "../models/scenario_node" {
    interface ScenarioNode {
        in_scenario: Scenario
        scenario: Scenario
        snippet: Snippet
        previous_scenario_node: ScenarioNode
        next_scenario_node: ScenarioNode
    }
}

ScenarioNode.belongs_to("in_scenario", Scenario, "scenario_id")
ScenarioNode.belongs_to("scenario", Scenario, "scenario_node_scenario_id")
ScenarioNode.belongs_to("snippet", Snippet, "scenario_node_snippet_id")
ScenarioNode.belongs_to("previous_scenario_node", ScenarioNode, "previous_scenario_node_id")
ScenarioNode.belongs_to("next_scenario_node", ScenarioNode, "next_scenario_node_id")

