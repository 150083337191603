export {}
declare global {
    interface JQuery {
        wiggle(count?: number): JQuery
    }
}
jQuery.fn.wiggle = function(count = 3) {
    this.each(function() {
        const ele = this;

        function animate(ele: HTMLElement) {
            for (var x = 1; x <= count; x++) {
                $(ele).animate({
                        myRotationProperty: -7
                    },
                    {
                        duration: 20,
                        step: function(now, tween) {
                            if (tween.prop === "myRotationProperty") {
                                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                                // add Opera, MS etc. variants
                                $(this).css('transform', 'rotate(' + now + 'deg)');
                            }
                        },
                        complete: function() {
                            $(this).css('-webkit-transform', "");
                            $(this).css('-moz-transform', "");
                            $(this).css("transform", "")
                        }
                    });

                $(ele).animate({
                        myRotationProperty: 0
                    },
                    {
                        duration: 75,
                        step: function(now, tween) {
                            if (tween.prop === "myRotationProperty") {
                                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                                // add Opera, MS etc. variants
                                $(this).css('transform', 'rotate(' + now + 'deg)');
                            }
                        },
                        complete: function() {
                            $(this).css('-webkit-transform', "");
                            $(this).css('-moz-transform', "");
                            $(this).css("transform", "")
                        }
                    });

                $(ele).animate({
                        myRotationProperty: 7
                    },
                    {
                        duration: 20,
                        step: function(now, tween) {
                            if (tween.prop === "myRotationProperty") {
                                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                                // add Opera, MS etc. variants
                                $(this).css('transform', 'rotate(' + now + 'deg)');
                            }
                        },
                        complete: function() {
                            $(this).css('-webkit-transform', "");
                            $(this).css('-moz-transform', "");
                            $(this).css("transform", "")
                        }
                    });

                $(ele).animate({
                        myRotationProperty: 0
                    },
                    {
                        duration: 75,
                        step: function(now, tween) {
                            if (tween.prop === "myRotationProperty") {
                                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                                // add Opera, MS etc. variants
                                $(this).css('transform', 'rotate(' + now + 'deg)');
                            }
                        },
                        complete: function() {
                            $(this).css('-webkit-transform', "");
                            $(this).css('-moz-transform', "");
                            $(this).css("transform", "")
                        }
                    });
            }
        }
        animate(ele);
    });
    return this;
};
