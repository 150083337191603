<template>
  <label class="custom-field vue-label"
         :class="{invalid: validator?.invalid}"
  >
    <textarea
        :id="id"
        ref="textarea"
        :value="modelValue"
        placeholder=" "
        :disabled="disabled"
        class="vue-input no-default-unfocus"
        :class="{dirty: modelValue}"
        @input="on_input"
        @blur="on_blur"
        @focus="show_errors = true"
    />
    <span class="placeholder">
      {{ label }}
    </span>
    <ValidationErrors v-if="show_errors && validator?.invalid"
                      :errors="validator.errors"/>
  </label>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import BlurEvent = JQuery.BlurEvent;
import { PropType } from "vue";
import ValidationErrors from "./ValidationErrors.vue";
import { Validator } from "../../helpers/validator/validator";
import { observe_input_value } from "../../helpers/dom/observe_input_value";
import { Value } from "../../helpers/validator/validator";

export default defineComponent({
    components: { ValidationErrors },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        id: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        validator: {
            type: Object as PropType<Validator>,
            required: false,
            default: null,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            show_errors: false,
        }
    },
    computed: {},
    watch: {
        modelValue: {
            handler() {
                this.validator?.run(this.modelValue);
            },
            immediate: true,
        },
        'validator.invalid'() {
            if (this.validator.invalid) {
                if ($(this.$refs.textarea as HTMLTextAreaElement).is(":focus")) {
                    this.show_errors = true
                }
            }
        }
    },
    mounted() {
        this.set_height(this.modelValue?.toString());
        // INPUT UPDATES THROUGH JAVASCRIPT input.value = "" is not detected any other way than this custom method
        observe_input_value(this.$refs.textarea as HTMLElement, (_old_value, new_value) => {
            this.set_height(new_value);
            this.$emit('update:modelValue', new_value)
        })
        this.validator?.bind_element(() => { return this.$refs.vue_label as HTMLElement })
        this.validator?.bind_value(() => { return this.modelValue as Value })
    },
    unmounted() {
        this.validator?.unregister()
    },
    methods: {
        on_blur(event: BlurEvent) {
            this.check_dirty(event);
            this.show_errors = false
        },
        check_dirty(event: BlurEvent) {
            if (event.target.value) {
                event.target.classList.add('dirty');
            } else {
                event.target.classList.remove('dirty');
            }
        },
        on_input() {
            const textarea = this.$refs.textarea as HTMLTextAreaElement
            this.set_height(textarea.value);
            this.$emit('update:modelValue', textarea.value)
        },
        calc_height(value: string) {
            const line_height = 16
            const number_of_line_breaks = (value.match(/\n/g) || []).length;
            // min-height + lines x line-height + padding + border
            return line_height * 3 + number_of_line_breaks * line_height + 12 + 2;
        },
        set_height(value: string) {
            const textarea = this.$refs.textarea as HTMLTextAreaElement
            textarea.style.height = `${this.calc_height(value)}px`;
        },
    },
})
</script>

<style lang="scss" scoped>
label {
  &.custom-field {
    position: relative;
    font-size: 14px;
    display: flex;
    $field-padding: 12px;
    width: 100%;

    $background-color: var(--ternary-background-color);

    textarea {
      -webkit-appearance: none;
      -ms-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: $background-color;
      padding: $field-padding;
      border-width: 1px;
      border-style: solid;

      border-radius: 5px;
      width: 100%;
      color: var(--font-color);
      outline: none;
      line-height: normal;
      font-size: 14px;
      transition: border-color 0.3s ease;
      max-height: 500px;
      resize: none;
    }

    .placeholder {
      background-color: $background-color;
      position: absolute;
      left: $field-padding;
      width: calc(100% - ($field-padding * 2));
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      top: 20px;
      line-height: 100%;
      transform: translateY(-50%);
      transition: top 0.3s ease,
      color 0.3s ease,
      font-size 0.3s ease;
    }

    textarea + .placeholder {
      left: 8px;
      padding: 0 5px;
    }

    // enabled
    textarea:not(:focus).dirty {
      transition-delay: 0.1s
    }

    textarea:not(:placeholder-shown),
    textarea:focus {
      transition-delay: 0.1s
    }

    textarea.dirty + .placeholder,
    textarea:not(:placeholder-shown) + .placeholder,
    textarea:focus + .placeholder {
      top: 0;
      font-size: 10px;
      width: auto
    }


    // disabled
    textarea:disabled.dirty,
    textarea:disabled:not(:placeholder-shown),
    textarea:disabled:focus {
      border-color: var(--font-color-secondary);
      transition-delay: 0.1s
    }

    textarea:disabled.dirty + .placeholder,
    textarea:disabled:not(:placeholder-shown) + .placeholder,
    textarea:disabled:focus + .placeholder {
      top: 0;
      font-size: 10px;
      width: auto
    }
  }

  // <editor-fold desc="VALID COLORS">
  &:not(.invalid) {
    &.custom-field {
      textarea {
        border-color: var(--secondary-background-color)
      }

      .placeholder {
        color: var(--font-color-secondary);
      }

      textarea:not(:focus).dirty {
        border-color: var(--secondary-background-color);
      }

      textarea:not(:placeholder-shown),
      textarea:focus {
        border-color: var(--focus-color);
      }

      textarea.dirty + .placeholder,
      textarea:not(:placeholder-shown) + .placeholder,
      textarea:focus + .placeholder {
        color: var(--focus-color);
      }

      textarea:disabled.dirty + .placeholder,
      textarea:disabled:not(:placeholder-shown) + .placeholder,
      textarea:disabled:focus + .placeholder {
        color: var(--font-color-secondary);
      }
    }
  }

  // </editor-fold>

  // <editor-fold desc="INVALID COLORS">
  &.invalid {
    &.custom-field {
      input {
        border-color: var(--button-red);
      }

      .placeholder {
        color: var(--button-red);
      }

      textarea:disabled.dirty + .placeholder,
      textarea:disabled:not(:placeholder-shown) + .placeholder,
      textarea:disabled:focus + .placeholder {
        color: var(--button-red);
      }
    }
  }

  // </editor-fold>
}


</style>
