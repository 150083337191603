import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ScenarioHistory } from "../models/scenario_history";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ScenarioHistoryClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ScenarioHistory
    declare record: ScenarioHistory

}
