import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-766ec829"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ValidationErrors = _resolveComponent("ValidationErrors")

  return (_openBlock(), _createElementBlock("label", {
    ref: "vue_label",
    class: _normalizeClass(["custom-field vue-label", {invalid: _ctx.validator?.invalid}])
  }, [
    _createElementVNode("span", {
      id: _ctx.id,
      ref: "editable",
      class: _normalizeClass(["editable", {invalid: _ctx.validator?.invalid}]),
      contenteditable: "true",
      tabindex: "0"
    }, null, 10 /* CLASS, PROPS */, _hoisted_1),
    (_ctx.validator?.invalid && _ctx.show_errors)
      ? (_openBlock(), _createBlock(_component_ValidationErrors, {
          key: 0,
          errors: _ctx.validator.errors
        }, null, 8 /* PROPS */, ["errors"]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}