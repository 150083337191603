import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { UserClient } from "../clients/user_client";
import { UserScope } from "../scopes/user_scope";
import { EnumUserRole } from "../../auto_generated/enums";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { show_global_loader } from "../../components/testa/global_loader/global_loader";
import { EMPTY_ARRAY } from "../base/vue_record_scope";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import SyncSignInData = UiSync.SyncSignInData;
import { computed } from "../../helpers/vue/computed";
import { Storager } from "../../helpers/api_wrappers/storager";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { UserProject } from "./user_project";
import { RecordOpts } from "../base/vue_record";
import { ProjectScope } from "../scopes/project_scope";
import { Project } from "./project";

// <editor-fold desc="TYPES">
export interface UserProps extends Props {
    id: number
    email: string
    reset_password_sent_at: Date
    remember_created_at: Date
    sign_in_count: number
    current_sign_in_at: Date
    last_sign_in_at: Date
    current_sign_in_ip: string
    last_sign_in_ip: string
    confirmed_at: Date
    confirmation_sent_at: Date
    unconfirmed_email: string
    failed_attempts: number
    locked_at: Date
    username: string
    first_name: string
    last_name: string
    project_id: number
    project_version_id: number
    created_at: Date
    updated_at: Date
    codemirror_dark_theme: string
    dark_mode: boolean
    codemirror_light_theme: string
    snippet_line_wrap: boolean
    show_hints_as_you_type: boolean
    hover_zoom_enabled: boolean
    superadmin: boolean
    decouple_streams: boolean
    auto_expand_play_scenarios: boolean
    password: string // should never be in frontend! this field is only for validations and creating new users


    extras?: {
        sign_out?: boolean
        sign_out_reason?: string
    }
}
export type QuerifiedUserProps = QuerifyProps<UserProps>
export interface UserCreateProps extends QuerifiedUserProps {
    password?: string
    confirm_password?: string
}
export interface UserUpdateProps extends Partial<UserProps> {
    change_password?: boolean
    current_password?: string
    password?: string
    confirm_password?: string
    locked?: boolean
    confirmed?: boolean
}
export type UserSignInProps = {
    email: string,
    password: string
}


export interface UserState extends State {}
export interface UserComputed extends Computed {}
export interface UserStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class User extends VueRecord {
    ['constructor']: typeof User

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = UserClient
    static ScopeClass = UserScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: UserStaticState = reactive<UserStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof User> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof User>> = {}

    static field_validators: ModelValidatorOpts<UserProps> = {
        first_name: { length: { max: 100 } },
        last_name: { length: { max: 100 } },
        username: {
            presence: true,
            length: {
                min: 1,
                max: 100,
            },
            alphanumerical: true,
        },
        email: {
            presence: true,
            email: true,
            length: { max: 200 },
        },
        password: {
            presence: true,
            length: {
                min: 8,
                max: 200,
            },
            contains: {
                lowercase: true,
                uppercase: true,
            },
        },
    }

    static resource_name = Enum.Resource.Label.USER
    static resource_id = Enum.Resource.Id.USER
    static icon_class = "fa-solid fa-user"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: UserClient
    declare props: UserProps;
    declare state: UserState;
    declare computed: UserComputed;
    declare storager: Storager;
    declare web_type_storager: Storager
    // </editor-fold>

    constructor(props: UserProps, opts: RecordOpts) {
        super(props, opts);
        this.storager = Storager.global.new_scope("user", this.key());
        this.web_type_storager = Storager.web_type.new_scope("user", this.key())
    }

    name() {
        return this.props.email
    }

    is_superadmin() {
        return this.props.superadmin
    }

    // <editor-fold desc="COMPUTED">
    projects: ProjectScope = computed(() => {
        if (this.is_superadmin()) {
            return Project.get_scope()
        } else {
            const projects = this.get_reactive_object().user_projects?.projects
            if (projects == null) {
                return Project.to_scope([])
            } else return projects
        }
    })
    // </editor-fold>

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    static delete(ids: number | Array<number>) {
        if (!Array.isArray(ids)) ids = [ids]
        const users_scope = this.where({ id: ids })
        return ConfirmDialogue.show({
            html: users_scope.delete_warning_text(),
            confirm_color_class: "red",
            confirm_action: () => {
                return this.ClientClass.delete(ids)
            }
        })
    }

    invalidate_ci_tokens() {
        const content_text = `You are about to invalidate CI tokens for user <strong>${this.props.email}</strong>`
        const promise = ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
        })
        promise.then((is_confirmed: Boolean) => {
            if (is_confirmed) this.client.invalidate_ci_tokens()
        })
        return promise
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
User.register_resource(User)
UserClient.ModelClass = User
UserScope.ModelClass = User

// @ts-ignore
if (globalThis.current_role == "") globalThis.current_role = null
current.role = computed(() => current.project?.computed.role) as any as EnumUserRole

if (globalThis.current_user_props?.id) {
    current.user = User.new(current_user_props)
}

if (globalThis.users_props) {
    users_props.forEach(user_props => User.new(user_props))
}


ui_sync.register_sync_task("user_signed_in", null, (sender, data: SyncSignInData) => {
    (document.querySelector("[name='csrf-token']") as HTMLMetaElement).content = data.authenticity_token
    authenticity_token = data.authenticity_token
    show_global_loader()
    window.location.reload()
    // load_project_version_resources(read_project_id_params_from_url(), read_project_version_id_params_from_url())
})

ui_sync.register_sync_task("user_signed_out", null, () => {
    show_global_loader()
    window.location.reload()
})

on_dom_content_loaded(() => {
    watch(
        [() => current.user_project?.props?.user_id, () => current.user?.props?.superadmin, () => current.user_project?.props?.role, () => current.user?.props?.project_id, () => {
            if (current.user == null) return EMPTY_ARRAY
            const project_ids = UserProject.where({ user_id: current.user.props.id }).pluck("props").pluck("project_id")
            if (project_ids.length == 0) return EMPTY_ARRAY
            return project_ids
        }],
        ([user_id, superadmin, role, project_id, project_ids]) => {
            User.unsync()

            // sync current user
            if (user_id != null) User.sync(`/sync/user/${user_id}`);

            if (superadmin != null && superadmin) {
                // superadmin sync all users
                User.sync(`/sync/users`);
            } else if (role != null && role == Enum.User.Role.ADMIN && project_ids != null) {
                // admin sync users on projects that the admin is assigned to
                project_ids.forEach(project_id => {
                    User.sync(`/sync/project/${project_id}/users`);
                })
            } if (user_id != null && project_id != null) {
                // user and viewer, sync users only on current project
                User.sync(`/sync/project/${project_id}/users`);
            }
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    var current_user_props: UserProps
    var current_role: EnumUserRole

    /** Pre-populated list of users on available projects (for superadmin it is all users) */
    var users_props: UserProps[]
    var cognito_login_path: string

    interface Window {
        User: typeof User
    }

    interface Current {
        user: User
        theme: string
        role: EnumUserRole
        role_is_viewer: boolean
    }
}
window.User = User
// </editor-fold>

