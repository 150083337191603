<template>
  <Modal
      :id="id"
      :dismissible="true"
      :show_confirm="false"
      :backdrop_dim_level="0"
      :padding="0"
      :cancel_on_escape="true"
      :focus_last_active_on_unmount="true"
      @cancel="cancel"
  >
    <template #body>
      <div ref="search_record_container"
           class="search-record-container"
           :style="container_style"
      >
        <div class="search-scope-container no-scrollbar">
          <template v-for="scope in available_scopes"
                    :key="scope">
            <SearchRecordScope
                :name="scope"
                :selected="selected_scope == scope"
                @select="select_scope(scope)"
            />
          </template>
        </div>
        <div class="search-input-container">
          <div class="search-input-overlay">
            <Loading
                v-if="loading"
                color="white"
                type="fading_circle"/>
          </div>
          <Input
              id="search_record_input"
              ref="input"
              v-model="search_query"
              :throttle_time="500"
              :focus="true"
              placeholder="Search"
              :no_round_corners="true"
              @keydown="on_keydown"
          />
        </div>
        <div class="search-result-container">
          <template v-for="result in results"
                    :key="result.stage_key">
            <SearchRecordResult
                :record="result"
                :active="active_key == result.stage_key"
                :query="search_query"
                @click="() => open(result)"
            />
          </template>
          <div v-if="!all_loaded"
               class="load-more"
               :class="{active: load_more_active}"
               @click="load_more"
          >
            Load more...
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../Modal.vue";
import Input from "../Input.vue";
import { KeyCode } from "../../../types/globals";
import SearchRecordScope from "./SearchRecordScope.vue";
import _ from "lodash";
import { Snippet } from "../../../vue_record/models/snippet";
import SearchRecordResult from "./SearchRecordResult.vue";
import { SnippetFolder } from "../../../vue_record/models/snippet_folder";
import Loading from "../Loading.vue";
import { ScenarioFolder } from "../../../vue_record/models/scenario_folder";
import { Scenario } from "../../../vue_record/models/scenario";
import { GroupFolder } from "../../../vue_record/models/group_folder";
import { Group } from "../../../vue_record/models/group";
import { SearchableRecord } from "../../../helpers/client/core/search_records";
import { SearchableRecordModel } from "../../../helpers/client/core/search_records";
import { search_records } from "../../../helpers/client/core/search_records";
import { ModelWithTimestamp } from "../../../helpers/client/core/search_records";
import { SearchableModelString } from "../../../helpers/client/core/search_records";
import { FileFolder } from "../../../vue_record/models/file_folder";
import { File } from "../../../vue_record/models/file"
import { search_record_modal_id } from "../../../helpers/vue/show_search_record";
import { ImageFolder } from "../../../vue_record/models/image_folder";
import { Image } from "../../../vue_record/models/image"
import { Phone } from "../../../vue_record/models/phone";
import { App } from "../../../vue_record/models/app";
import { Schedule } from "../../../vue_record/models/schedule";
import { Play } from "../../../vue_record/models/play/play";
import { EnumResourceId } from "../../../auto_generated/enums";
import { PropType } from "vue";
import { ProjectVersion } from "../../../vue_record/models/project_version";
import { AllMightyObserver } from "../../../helpers/dom/all_mighty_observer";
import { Consoler } from "../../../helpers/api_wrappers/consoler";

type Scope = "All" | "Project" | "Snippet" | "Scenario" | "Group" | "File" | "Image" | "Device" | "App" | "Schedule" | "Report"
const scopes: Scope[] = ["All", "Project", "Snippet", "Scenario", "Group", "File", "Image", "Device", "App", "Schedule", "Report"]


type ScopeState = {
    records: SearchableRecord[],
    all_loaded: boolean,
    last_updated_at: Date
    query: string
}

type ModelStates = {
    [key in EnumResourceId]?: ScopeState
}

type ModelQueryLoadingStatuses= {
    [key in EnumResourceId]?: string[]
}

const console = new Consoler("warn")
const storager_search_record_height_key = "search_record_height"
const storager_search_record_width_key = "search_record_width"
const storager_search_record_query_key = "search_record_query"
const storager_search_record_scope = "search_record_scope"
export default defineComponent({
    components: { Loading, SearchRecordResult, SearchRecordScope, Input, Modal },
    // <editor-fold desc="PROPS">
    props: {
        query: {
            type: String,
            required: true
        },
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            id: search_record_modal_id,
            width: "50vw",
            height: "50vh",
            amo: null as AllMightyObserver,
            search_query: this.query,
            model_states: {} as ModelStates,
            model_loading_statuses: {} as ModelQueryLoadingStatuses,
            active_key: null as string,
            previous_active_element: null as HTMLElement,
            selected_scope: "Project" as Scope,
            load_more_active: false,
            scopes
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        storager() {
            return current.storagers.user
        },
        available_scopes() {
            return this.scopes.filter(scope => {
                switch (scope) {
                    case "Group":
                        return current.project_version_setting?.props?.group_module_enabled
                    case "File":
                        return current.project_version_setting?.props?.file_module_enabled
                    case "Image":
                        return current.project_version_setting?.props?.sikuli_module_enabled
                    case "Device":
                    case "App":
                        return current.project_version_setting?.props?.android_module_enabled
                    case "Schedule":
                        return current.project_version_setting?.props?.schedule_module_enabled
                    default:
                        return true
                }
            })
        },
        selected_models() {
            return this.models_for_scope(this.selected_scope)
        },
        container_style() {
            return {
                width: this.width,
                height: this.height
            }
        },
        loading() {
            return Object.values(this.model_loading_statuses).some(s => s.length > 0)
        },
        results() {
            const models: SearchableRecordModel[] = this.selected_models
            let model_states: ScopeState[] = []
            models.forEach(model => {
                model_states.push(this.model_states[model.resource_id] as ScopeState)
            })
            model_states = model_states.filter(state => state != null)
            return _.orderBy(model_states.map(scope_state => {
                return scope_state.records
            }).flat(), (obj: SearchableRecord) => obj.props.updated_at).reverse()
        },
        active_record() {
            return this.results.find(r => r.stage_key == this.active_key)
        },
        all_loaded() {
            let all_loaded = true
            const models = this.models_for_scope(this.selected_scope)
            models.forEach(model => {
                all_loaded = all_loaded && this.model_states[model.resource_id]?.all_loaded
            })
            return all_loaded || this.results.length == 0
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        search_query: {
            handler() {
                this.active_key = null
                this.do_search_record()
            },
            immediate: true
        },
        available_scopes() {
            if (!this.available_scopes.includes(this.selected_scope)) this.selected_scope = "Project"
        },
        selected_scope() {
            if (this.selected_scope == null) this.selected_scope = "Project"
        },
        selected_models() {
            this.do_search_record()
        },
        results() {
            if (this.results.length > 0) {
                if (this.active_key == null) {
                    this.active_key = this.results[0].stage_key
                    return
                }
                if (!this.results.some(r => r.stage_key == this.active_key)) {
                    this.active_key = this.results[0].stage_key
                    return
                }
            }
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMount() {
        this.load_saved_scopes();
    },
    mounted() {
        const height = this.storager.get<string>(storager_search_record_height_key, null);
        const width = this.storager.get<string>(storager_search_record_width_key, null);
        const query = this.storager.get<string>(storager_search_record_query_key, "")
        if (this.search_query == null || this.search_query == "") this.search_query = query

        if (height != null) this.height = height
        if (width != null) this.width = width;
        this.amo = AllMightyObserver.new({
            element_after_resize: true,
            target_element: this.$refs.search_record_container as HTMLElement,
            element_resize_delay: 500,
            callback: () => this.save_size()
        })
    },
    beforeUnmount() {
        this.save_size();
        this.storager.set(storager_search_record_scope, this.selected_scope)
    },
    unmounted() {
        this.amo?.stop();
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        load_saved_scopes() {
            const saved_scope = this.storager.get<Scope>(storager_search_record_scope, null)
            if (saved_scope == null) return;

            try {
                if (this.available_scopes.includes(saved_scope)) {
                    this.selected_scope = saved_scope;
                }
            } catch (e) {
                console.warn("Failed to load saved scope", e);
            }
        },
        cancel() {
            this.$.appContext.app.unmount();
        },
        save_size() {
            const container = this.$refs.search_record_container as HTMLElement
            if (container != null) {
                this.height = `${container.clientHeight}px`
                this.width = `${container.clientWidth}px`
                this.storager.set(storager_search_record_height_key, this.height)
                this.storager.set(storager_search_record_width_key, this.width)
            }
        },
        activate_next() {
            if (this.load_more_active) return;
            const active_index = this.results.findIndex(r => r.stage_key == this.active_key)
            if (active_index == -1) return;
            if (active_index + 1 == this.results.length) {
                this.load_more_active = true;
                this.active_key = null;
                return
            }
            this.active_key = this.results[active_index + 1].stage_key
        },
        activate_previous() {
            if (this.load_more_active) {
                this.active_key = this.results[this.results.length - 1].stage_key
                this.load_more_active = false;
                return;
            }
            const active_index = this.results.findIndex(r => r.stage_key == this.active_key)
            if (active_index == -1) return;
            if (active_index == 0) return
            this.active_key = this.results[active_index - 1].stage_key
        },
        do_search_record() {
            if (this.search_query == null || this.search_query.trim() == "") return;
            this.storager.set(storager_search_record_query_key, this.search_query)

            this.do_search_scope(this.selected_scope)
        },
        do_search_scope(scope: Scope) {
            const query = this.search_query
            const models = this.models_for_scope(scope);

            const set_initial_scope = (model: SearchableRecordModel) => {
                this.model_states[model.resource_id] = {
                    last_updated_at: null,
                    all_loaded: false,
                    records: [],
                    query: this.search_query
                } as ScopeState
            }

            const get_model_state = (model: SearchableRecordModel): ScopeState => this.model_states[model.resource_id] as ScopeState

            models.forEach(model => {
                if (get_model_state(model) == null) set_initial_scope(model)
            })
            if (query == "") return;
            const models_with_timestamps = this.get_models_with_timestamp(scope)
            if (Object.keys(models_with_timestamps).length == 0) return;

            this.set_loading(models, true, query)

            this.separate_models_with_timestamps(models_with_timestamps)
                .forEach(separated => {
                    if (Object.keys(separated).length == 0) return;

                    search_records(this.project_version.key(), query, separated).then(results => {
                        console.log("response");
                        // invalidate if query changed by the time ajax returns
                        if (query != this.search_query) return;

                        for (const key in results) {
                            const model_string = key as SearchableModelString
                            const model_result = results[model_string]
                            let model: SearchableRecordModel;
                            switch (model_string) {
                                case "snippet":
                                    model = Snippet
                                    break;
                                case "snippet_folder":
                                    model = SnippetFolder
                                    break;
                                case "scenario":
                                    model = Scenario
                                    break;
                                case "scenario_folder":
                                    model = ScenarioFolder
                                    break;
                                case "group":
                                    model = Group
                                    break;
                                case "group_folder":
                                    model = GroupFolder
                                    break;
                                case "file":
                                    model = File
                                    break;
                                case "file_folder":
                                    model = FileFolder
                                    break;
                                case "image":
                                    model = Image
                                    break;
                                case "image_folder":
                                    model = ImageFolder
                                    break;
                                case "phone":
                                    model = Phone
                                    break;
                                case "app":
                                    model = App
                                    break;
                                case "schedule":
                                    model = Schedule
                                    break;
                                case "play":
                                    model = Play
                                    break;
                            }
                            if (model == null) throw new Error(`Unknown model string ${model_string}`)

                            if (get_model_state(model).query != this.search_query) set_initial_scope(model)
                            if (model_result.all_loaded) get_model_state(model).all_loaded = model_result.all_loaded
                            get_model_state(model).last_updated_at = model_result.last_updated_at
                            console.log(model_result);
                            model_result.records.forEach(r => {
                                const records = get_model_state(model).records
                                if (!records.some(record => r.key() == record.key())) records.push(r)
                            });
                        }
                    }).finally(() => {
                        this.set_loading(models, false, query)
                    })
                })
        },
        separate_models_with_timestamps(models_with_timestamps: ModelWithTimestamp): ModelWithTimestamp[] {
            const groups = {
                main: {} as ModelWithTimestamp,
                filesystem: { } as ModelWithTimestamp,
                other: { } as ModelWithTimestamp,
                reports: { } as ModelWithTimestamp,
            }
            Object.keys(models_with_timestamps).forEach(key => {
                const model = key as SearchableModelString
              switch (model) {
                  case "snippet":
                  case "snippet_folder":
                  case "scenario":
                  case "scenario_folder":
                  case "group":
                  case "group_folder":
                      groups.main[model] = models_with_timestamps[model]
                      break;
                  case "file":
                  case "file_folder":
                  case "image":
                  case "image_folder":
                      groups.filesystem[model] = models_with_timestamps[model]
                      break;
                  case "app":
                  case "phone":
                      groups.other[model] = models_with_timestamps[model]
                      break;
                  case "schedule":
                  case "play":
                      groups.reports[model] = models_with_timestamps[model]
                      break;
                  default:
                      groups.other[model] = models_with_timestamps[model]
              }
            })

            return Object.values(groups)
        },
        get_models_with_timestamp(scope: Scope): ModelWithTimestamp {
            const models = this.models_for_scope(scope)
            const models_with_timestamp: ModelWithTimestamp = {}
            models.forEach(model => {
                const model_state = this.model_states[model.resource_id]
                const clear_scope = model_state.query != this.search_query;

                if (model_state.all_loaded && !clear_scope) return;

                const model_string = model.resource_name.replaceAll(" ", "_") as SearchableModelString
                if (clear_scope) {
                    models_with_timestamp[model_string] = null
                } else {
                    models_with_timestamp[model_string] = model_state.last_updated_at
                }
            })
            return models_with_timestamp
        },
        models_for_scope(scope: Scope) {
            if (!this.available_scopes.includes(scope)) return [];

            switch (scope) {
                case "All":
                    return [Snippet, SnippetFolder, Scenario, ScenarioFolder, Group, GroupFolder, File, FileFolder, Image, ImageFolder, Phone, App, Schedule, Play]
                case "Project":
                    return [Snippet, SnippetFolder, Scenario, ScenarioFolder, Group, GroupFolder, File, FileFolder, Image, ImageFolder]
                case "Snippet":
                    return [Snippet, SnippetFolder]
                case "Scenario":
                    return [Scenario, ScenarioFolder]
                case "Group":
                    return [Group, GroupFolder]
                case "File":
                    return [File, FileFolder]
                case "Image":
                    return [Image, ImageFolder]
                case "Device":
                    return [Phone]
                case "App":
                    return [App]
                case "Schedule":
                    return [Schedule]
                case "Report":
                    return [Play]
                default:
                    return [];
            }
        },
        on_keydown(e: KeyboardEvent) {
            if (e.ctrlKey) return
            if (e.altKey) return
            if (e.shiftKey) return;
            if (e.code == KeyCode.DOWN) {
                this.activate_next()
            } else if (e.code == KeyCode.UP) {
                this.activate_previous()
            } else if (e.code == KeyCode.ENTER) {
                if (this.load_more_active) {
                    this.load_more()
                    return;
                }
                if (this.active_key == null) return;
                this.open(this.active_record)
            }
        },
        select_scope(scope: Scope) {
            this.selected_scope = scope;
        },
        open(record: SearchableRecord) {
            if (record instanceof Snippet || record instanceof Scenario || record instanceof Group ||
                record instanceof File || record instanceof Image || record instanceof Play) {
                record.open()
            } else if (
                record instanceof SnippetFolder || record instanceof ScenarioFolder ||
                record instanceof GroupFolder || record instanceof FileFolder || record instanceof ImageFolder ||
                record instanceof Phone || record instanceof App || record instanceof Schedule
            ) {
                record.show_in_sidebar()
            }
            this.cancel();
        },
        load_more() {
            this.do_search_record();
        },
        set_loading(models: SearchableRecordModel[], state: boolean, query: string) {
            models.forEach(model => {
                if (this.model_loading_statuses[model.resource_id] == null) {
                    this.model_loading_statuses[model.resource_id] = []
                }

                if (state) {
                    this.model_loading_statuses[model.resource_id].push(query)
                } else {
                    const index = this.model_loading_statuses[model.resource_id].findIndex(q => q == query)
                    if (index >= 0) {
                        this.model_loading_statuses[model.resource_id].splice(index, 1)
                    }
                }
            })
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss">
.code-search-result-highlight {
  background: var(--sidebar-selected-contrast);
}
</style>

<style lang="scss" scoped>
.search-record-container {
  min-width: 100px;
  max-width: 90vw;

  min-height: 200px;
  max-height: 90vh;

  resize: both;
  overflow: auto;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  $scope_height: 40px;
  .search-scope-container {
    display: flex;
    flex-direction: row;
    overflow: auto;
    flex-shrink: 0;
    height: $scope_height;
  }

  $input_height: 30px;
  .search-input-container {
    position: relative;
    flex-shrink: 0;
    height: $input_height;

    .search-input-overlay {
      position: absolute;
      right: 0;
      height: 100%;
      justify-content: center;
      align-items: center;
      z-index: 5;
      display: flex;
      pointer-events: none;
    }
  }

  .search-result-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-shrink: 1;
    height: calc(100% - $input_height - $scope_height);

    .load-more {
      margin-top: 3px;
      font-size: 0.85em;
      padding: 3px;

      cursor: pointer;
      color: var(--font-color-secondary);

      &:hover {
        background-color: var(--ternary-background-color);
        color: var(--font-color);
      }

      &.active {
        color: var(--font-color);
        border-left: 1px solid var(--button-blue);
        background-color: var(--sidebar-selected);
      }
    }
  }
}
</style>
