import { HasManyThroughAssociations, Props } from "../../../base/vue_record";
import { QuerifyProps } from "../../../base/vue_record_scope";
import { State } from "../../../base/vue_record";
import { Computed } from "../../../base/vue_record";
import { StaticState } from "../../../base/vue_record";
import { Consoler } from "../../../../helpers/api_wrappers/consoler";
import { VueRecord } from "../../../base/vue_record";
import {
    PlayScenariosPlayWorkerGroupsScope
} from "../../../scopes/play/bridges/play_scenarios_play_worker_groups_scope";
import { reactive } from "../../../../helpers/vue/reactive";
import { HasOneAssociations } from "../../../base/vue_record";
import { HasManyAssociations } from "../../../base/vue_record";
import { BelongsToAssociations } from "../../../base/vue_record";
import {
    PlayScenariosPlayWorkerGroupsClient
} from "../../../clients/play/bridges/play_scenarios_play_worker_groups_client";
import { VueRecordIndex } from "../../../base/vue_record_index";
import { VueRecordStore } from "../../../base/vue_record_store";
import { ModelValidatorOpts } from "../../../../helpers/validator/validator";
import { get_css_var } from "../../../../helpers/generic/get_css_var";
import { TestaTree } from "../../../../components/testa/tree/tree";

// <editor-fold desc="TYPES">

export interface PlayScenariosPlayWorkerGroupsProps extends Props {
    id: number
    play_worker_group_id: number
    play_scenario_id: number
}
export type QuerifiedPlayScenariosPlayWorkerGroupsProps = QuerifyProps<PlayScenariosPlayWorkerGroupsProps>
export type PlayScenariosPlayWorkerGroupsCreateProps = Omit<PlayScenariosPlayWorkerGroupsProps, 'id'>
export type PlayScenariosPlayWorkerGroupsUpdateProps = Partial<PlayScenariosPlayWorkerGroupsProps>

export interface PlayScenariosPlayWorkerGroupsState extends State {}
export interface PlayScenariosPlayWorkerGroupsComputed extends Computed {}
export interface PlayScenariosPlayWorkerGroupsStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PlayScenariosPlayWorkerGroups extends VueRecord {
    ['constructor']: typeof PlayScenariosPlayWorkerGroups

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlayScenariosPlayWorkerGroupsClient
    static ScopeClass = PlayScenariosPlayWorkerGroupsScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlayScenariosPlayWorkerGroupsStaticState = reactive<PlayScenariosPlayWorkerGroupsStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_worker_group_id"),
        VueRecordIndex.new(this, "play_scenario_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlayScenariosPlayWorkerGroups> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlayScenariosPlayWorkerGroups>> = {}

    static field_validators: ModelValidatorOpts<PlayScenariosPlayWorkerGroupsProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_SCENARIOS_PLAY_WORKER_GROUPS
    static resource_id = Enum.Resource.Id.PLAY_SCENARIOS_PLAY_WORKER_GROUPS
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlayScenariosPlayWorkerGroupsClient
    declare props: PlayScenariosPlayWorkerGroupsProps;
    declare state: PlayScenariosPlayWorkerGroupsState;
    declare computed: PlayScenariosPlayWorkerGroupsComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlayScenariosPlayWorkerGroups.register_resource(PlayScenariosPlayWorkerGroups)
PlayScenariosPlayWorkerGroupsClient.ModelClass = PlayScenariosPlayWorkerGroups
PlayScenariosPlayWorkerGroupsScope.ModelClass = PlayScenariosPlayWorkerGroups


declare global {
    interface Window {
        PlayScenariosPlayWorkerGroups: typeof PlayScenariosPlayWorkerGroups
    }
}
window.PlayScenariosPlayWorkerGroups = PlayScenariosPlayWorkerGroups
// </editor-fold>

