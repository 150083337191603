export function get_indentation_from_spaces(cm: CodeMirror.Editor, spaces: number) {
    const tab_size = cm.getOption("tabSize")
    const indent_with_tabs = cm.getOption("indentWithTabs")
    let indentation = ""
    if (indent_with_tabs) {
        const leftover_spaces = spaces % tab_size;
        const tabs = Math.floor(spaces / tab_size);
        for (let i = 0; i < tabs; ++i) indentation += "\t"
        for (let i = 0; i < leftover_spaces; ++i) indentation += " "
    } else {
        for (let i = 0; i < spaces; ++i) indentation += " "
    }
    return indentation
}
