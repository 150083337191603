import { FlexAreaOpts } from "./flex_area";
import { FlexArea } from "./flex_area";
import { reactive } from "vue";
import { ResizableFlex } from "../resizable_flex";
import { computed } from "../../../../../helpers/vue/computed";
import { Consoler } from "../../../../../helpers/api_wrappers/consoler";
import { CSSProperties } from "vue";
import { FlexStickArea } from "./flex_stick_area";

export interface FlexContentAreaOpts extends FlexAreaOpts {
    /** when enabled, area is shown, when not the area is not shown and is excluded for scaling calculations */
    enabled?: boolean

    /**
     * Defines how much an area should expand compared to other areas.
     * The area with greater rate grows faster.
     * Must be non-negative. Default value is 1
     * */
    grow_rate?: number

    /**
     * Defines how much an area should shrink compared to other areas.
     * The area with greater rate shrinks faster
     * Must be non-negative. Default value is 1
     * */
    shrink_rate?: number

    lock_px_size?: number
    lock_ratio_size?: number

    min_px_size?: number
    min_ratio_size?: number

    max_px_size?: number
    max_ratio_size?: number

    initial_px_size?: number
    initial_ratio_size?: number
}
const DEFAULT_MIN_PX_SIZE = 30
const console = new Consoler("warn")
export class FlexContentArea extends FlexArea {
    // <editor-fold desc="PROPERTIES">
    private readonly initial_px_size: number
    private readonly initial_ratio_size: number

    grow_rate = 1
    shrink_rate = 1

    /** when enabled, area is shown, when not the area is not shown and is excluded for scaling calculations */
    enabled: boolean = true

    /** when enabled, area will not be resizable */
    private locked_px_size: number
    private locked_ratio_size: number
    min_px_size: number = DEFAULT_MIN_PX_SIZE
    min_ratio_size: number
    max_px_size: number
    max_ratio_size: number
    private _grabbable_stick: FlexStickArea = null

    // <editor-fold desc="COMPUTED">
    private requested_locked_px_size: number
    private requested_min_px_size: number
    private requested_max_px_size: number

    private resolved_min_px_size: number

    /** if requested max size is 90 out of 100, and the sum of other areas with their resolved min size have 20
     * then this max size is actually 80.
     * This resolved_max_px_size refers to that value
     */
    private resolved_max_px_size: number

    private resolved_grow_rate: number
    private resolved_shrink_rate: number

    private expandable_px_size: number
    private shrinkable_px_size: number
    private is_expandable: boolean
    private is_shrinkable: boolean

    private is_before_area: boolean
    private is_after_area: boolean

    private previous_stick: FlexStickArea
    private next_stick: FlexStickArea
    // </editor-fold>
    // </editor-fold>

    static DEFAULT_MIN_PX_SIZE = DEFAULT_MIN_PX_SIZE

    constructor(opts: FlexContentAreaOpts, resizable_flex: ResizableFlex) {
        super(opts, false, resizable_flex);

        this._check_exclusive_property(opts, "initial_px_size", "initial_ratio_size")
        this._check_exclusive_property(opts, "min_px_size", "min_ratio_size")
        this._check_exclusive_property(opts, "max_ratio_size", "max_px_size")
        this._check_exclusive_property(opts, "lock_px_size", "lock_ratio_size")

        this.initial_px_size = opts.initial_px_size
        this.initial_ratio_size = opts.initial_ratio_size
        if (opts.grow_rate != null && opts.grow_rate >= 0) this.grow_rate = opts.grow_rate
        if (opts.shrink_rate != null && opts.shrink_rate >= 0) this.shrink_rate = opts.shrink_rate
        if (opts.enabled != null) this.enabled = opts.enabled;
        if (opts.min_px_size != null) this.min_px_size = opts.min_px_size
        if (opts.max_px_size != null) this.max_px_size = opts.max_px_size
        if (opts.min_ratio_size != null) this.min_ratio_size = opts.min_ratio_size
        if (opts.max_ratio_size != null) this.max_ratio_size = opts.max_ratio_size
        if (opts.lock_px_size != null) this.locked_px_size = opts.lock_px_size
        if (opts.lock_ratio_size != null) this.locked_ratio_size = opts.lock_ratio_size
    }


    static new(opts: FlexContentAreaOpts, resizable_flex: ResizableFlex): FlexContentArea {
        const flex_area = reactive(new this(opts, resizable_flex))
        flex_area.init(opts)
        return flex_area as FlexContentArea
    }

    init(o: FlexContentAreaOpts) {
        super.init(o)

        this.requested_locked_px_size = computed(() => {
            if (this.locked_px_size != null) return this.locked_px_size
            else if (this.locked_ratio_size != null) return this.get_flex_container_size() * this.locked_ratio_size
            else return null
        })
        this.is_locked = computed(() => this.requested_locked_px_size != null)

        this.requested_min_px_size = computed(() => {
            if (this.is_locked) return this.locked_px_size
            else if (this.min_px_size != null) return this.min_px_size
            else if (this.min_ratio_size != null) return this.get_flex_container_size() * this.min_ratio_size
            else return FlexContentArea.DEFAULT_MIN_PX_SIZE
        })

        this.requested_max_px_size = computed(() => {
            if (this.is_locked) return this.locked_px_size
            else if (this.max_px_size != null) return this.max_px_size
            else if (this.max_ratio_size != null) return this.get_flex_container_size() * this.max_ratio_size
            else return this.get_flex_container_size()
        })

        this.is_before_area = computed(() => {
            return this.resizable_flex.get_enabled_areas_before_grabbed_stick().map(a => a.id).includes(this.id)
        })

        this.is_after_area = computed(() => {
            return this.resizable_flex.get_enabled_areas_after_grabbed_stick().map(a => a.id).includes(this.id)
        })

        this.resolved_min_px_size = computed(() => {
            const resolve = () => {
                let overflow: number;
                let shrink_areas: FlexContentArea[]

                if (this.resizable_flex.get_grabbed_stick() != null) {
                    overflow = 0
                } else {
                    overflow = this.resizable_flex.get_areas_with_sticks_requested_min_px_size_sum() - this.get_flex_container_size()
                }

                if (overflow > 0) {
                    // the container is to small, shrink the min sizes based on current size
                    // if we have areas that have min size 50 and 10, then shrink the area with 50 five times more
                    if (this.resizable_flex.get_grabbed_stick() != null) {
                        if (this.is_before_area) {
                            shrink_areas = this.resizable_flex.get_enabled_areas_before_grabbed_stick()
                        } else {
                            shrink_areas = this.resizable_flex.get_enabled_areas_after_grabbed_stick()
                        }
                    } else {
                        shrink_areas = this.resizable_flex.get_enabled_areas()
                    }
                    const shrink_rate_times_px = shrink_areas.map(a => a.get_size() * a.shrink_rate).sum()
                    const per_shrink_times_px = shrink_rate_times_px == 0 ? 0 : overflow / shrink_rate_times_px
                    return Math.max(this.requested_min_px_size - (per_shrink_times_px * this.get_size() * this.shrink_rate), 0)
                } else if (this.is_locked) {
                    return this.locked_px_size
                } else {
                    return this.requested_min_px_size
                }
            }
            const result = Math.floor(resolve())
            console.debug(`area(${this.id}) has resolved_min_px_size to ${result}`)
            return result
        })

        this.resolved_max_px_size = computed(() => {
            const resolve = () => {
                let other_areas: FlexContentArea[];
                let available_size: number;
                const stick_sum_size = this.resizable_flex.get_stick_sum_size()
                let expandable_space: number

                if (this.resizable_flex.get_grabbed_stick() != null) {
                    expandable_space = this.get_flex_container_size()
                } else {
                    other_areas = this.resizable_flex.get_enabled_areas().filter(a => a.id != this.id)
                    available_size = this.get_flex_container_size()
                    const others_min_sum = other_areas.map(a => a.resolved_min_px_size).sum()

                    expandable_space = available_size - others_min_sum - stick_sum_size

                    const max_sum = this.resizable_flex.get_enabled_areas().map(a => a.requested_max_px_size).sum() + stick_sum_size
                    if (max_sum < this.get_flex_container_size()) {
                        // all requested max sum is not enough to fill the container, expand the requested max sizes
                        const grow_rate_times_px = this.resizable_flex.get_enabled_areas().map(a => a.get_size() * a.grow_rate).sum()
                        const per_grow_rate_times_px = (this.get_flex_container_size() - max_sum) / grow_rate_times_px
                        return this.resolved_max_px_size + (per_grow_rate_times_px * this.get_size() * this.grow_rate)
                    }
                }

                if (expandable_space < this.requested_max_px_size) {
                    if (this.is_locked && this.locked_px_size < expandable_space) return this.locked_px_size
                    else return expandable_space
                } else {
                    if (this.is_locked) return this.locked_px_size
                    else return this.requested_max_px_size
                }
            }
            // const result = Math.floor(resolve())
            const result = resolve()
            console.debug(`area(${this.id}) has resolved_max_px_size to ${result}`)
            return result
        })

        this.expandable_px_size = computed(() => {
            if (this.is_locked) return 0;
            if (this.get_size() >= this.resolved_max_px_size) return 0
            return this.resolved_max_px_size - this.get_size()
        })

        this.shrinkable_px_size = computed(() => {
            if (this.is_locked) return 0;
            if (this.get_size() <= this.resolved_min_px_size) return 0
            return this.get_size() - this.resolved_min_px_size
        })

        this.is_expandable = computed(() => this.expandable_px_size > 0)
        this.is_shrinkable = computed(() => this.shrinkable_px_size > 0)

        this.resolved_grow_rate = computed(() => {
            if (this.resizable_flex.get_resize_behaviour() != "extra_rate_to_adjacent") return this.grow_rate
            if (this.resizable_flex.get_grabbed_stick() == null) return this.grow_rate
            if (!this.is_expandable) return this.grow_rate

            if (this.is_before_area) {
                const expandable_before_areas = this.resizable_flex.get_enabled_areas_before_grabbed_stick().filter(a => a.is_expandable)
                if (expandable_before_areas.length < 2) return this.grow_rate
                if (!this.resizable_flex.get_enabled_area_before_grabbed_stick().is_expandable) return this.grow_rate

                const other_expandable_before_areas = expandable_before_areas.filter(a => a.id != this.id)
                const extra_grow_rate = other_expandable_before_areas.map(a => a.grow_rate).sum() / 4
                if (this.id == this.resizable_flex.get_enabled_area_before_grabbed_stick().id) {
                    // this area gain extra rate
                    return this.grow_rate + extra_grow_rate
                } else {
                    // this area loses rate
                    return this.grow_rate - (extra_grow_rate / other_expandable_before_areas.length)
                }
            }

            if (this.is_after_area) {
                const expandable_after_areas = this.resizable_flex.get_enabled_areas_after_grabbed_stick().filter(a => a.is_expandable)
                if (expandable_after_areas.length < 2) return this.grow_rate
                if (!this.resizable_flex.get_enabled_area_after_grabbed_stick().is_expandable) return this.grow_rate

                const other_expandable_after_areas = expandable_after_areas.filter(a => a.id != this.id)
                const extra_grow_rate = other_expandable_after_areas.map(a => a.grow_rate).sum() / 4
                if (this.id == this.resizable_flex.get_enabled_area_after_grabbed_stick().id) {
                    // this area gain extra rate
                    return this.grow_rate + extra_grow_rate
                } else {
                    // this area loses rate
                    return this.grow_rate - (extra_grow_rate / other_expandable_after_areas.length)
                }
            }
            return this.grow_rate
        })

        this.resolved_shrink_rate = computed(() => {
            if (this.resizable_flex.get_resize_behaviour() != "extra_rate_to_adjacent") return this.shrink_rate
            if (this.resizable_flex.get_grabbed_stick() == null) return this.shrink_rate
            if (!this.is_expandable) return this.shrink_rate

            if (this.is_before_area) {
                const expandable_before_areas = this.resizable_flex.get_enabled_areas_before_grabbed_stick().filter(a => a.is_expandable)
                if (expandable_before_areas.length < 2) return this.shrink_rate
                if (!this.resizable_flex.get_enabled_area_before_grabbed_stick().is_expandable) return this.shrink_rate

                const other_expandable_before_areas = expandable_before_areas.filter(a => a.id != this.id)
                const extra_shrink_rate = other_expandable_before_areas.map(a => a.shrink_rate).sum() / 4
                if (this.id == this.resizable_flex.get_enabled_area_before_grabbed_stick().id) {
                    // this area gain extra rate
                    return this.shrink_rate + extra_shrink_rate
                } else {
                    // this area loses rate
                    return this.shrink_rate - (extra_shrink_rate / other_expandable_before_areas.length)
                }
            }

            if (this.is_after_area) {
                const expandable_after_areas = this.resizable_flex.get_enabled_areas_after_grabbed_stick().filter(a => a.is_expandable)
                if (expandable_after_areas.length < 2) return this.shrink_rate
                if (!this.resizable_flex.get_enabled_area_after_grabbed_stick().is_expandable) return this.shrink_rate

                const other_expandable_after_areas = expandable_after_areas.filter(a => a.id != this.id)
                const extra_shrink_rate = other_expandable_after_areas.map(a => a.shrink_rate).sum() / 4
                if (this.id == this.resizable_flex.get_enabled_area_after_grabbed_stick().id) {
                    // this area gain extra rate
                    return this.shrink_rate + extra_shrink_rate
                } else {
                    // this area loses rate
                    return this.shrink_rate - (extra_shrink_rate / other_expandable_after_areas.length)
                }
            }
            return this.shrink_rate
        })

        this.previous_stick = computed(() => {
            const areas_with_sticks = this.resizable_flex.get_enabled_areas_with_sticks()
            for (let i = 0; i < areas_with_sticks.length; ++i) {
                const area = areas_with_sticks[i]
                if (area.id == this.id) {
                    if (i == 0) return null
                    return areas_with_sticks[i - 1] as FlexStickArea
                }
            }
            return null
        })

        this.next_stick = computed(() => {
            const areas_with_sticks = this.resizable_flex.get_enabled_areas_with_sticks()
            for (let i = 0; i < areas_with_sticks.length; ++i) {
                const area = areas_with_sticks[i]
                if (area.id == this.id) {
                    if (i + 1 == areas_with_sticks.length) return null
                    return areas_with_sticks[i + 1] as FlexStickArea
                }
            }
            return null
        })


        this.style = computed(() => {
            const style: CSSProperties = {}
            if (this.resizable_flex.is_row_direction()) {
                style.width = `${this.width}px`
            } else {
                style.height = `${this.height}px`
            }

            if (this._grabbable_stick != null) {
                if (this.resizable_flex.is_row_direction()) {
                    style.cursor = "ew-resize"
                } else {
                    style.cursor = "ns-resize"
                }
            }


            if (!this.is_stick) {
                const box_shadow = "inset 0 0 0 1px var(--border-color)"
                if (this.is_locked && this.resizable_flex.outline_locked) {
                    style.boxShadow = box_shadow
                } else if (this.resizable_flex.get_grabbed_stick() != null) {
                    const size = this.get_size()
                    if (size - 1 < this.resolved_min_px_size || size + 1 > this.resolved_max_px_size) {
                        style.boxShadow = box_shadow
                    }
                }
            }

            style.background = this.background
            return style
        })
    }

    set_size(size: number) {
        if (isNaN(size)) throw new Error("space is NaN")

        let constrained_size = size
        if (size < this.resolved_min_px_size) constrained_size = this.resolved_min_px_size
        if (size > this.resolved_max_px_size) constrained_size = this.resolved_max_px_size

        const set_it = () => {
            if (this.resizable_flex.is_row_direction()) {
                this.width = constrained_size
            } else {
                this.height = constrained_size
            }
        }

        if (!this.resizable_flex.is_in_wrap_resize()) {
            console.debug(`area(${this.id}) setting size. `,
                `Before: ${this.get_size()}, `,
                `Delta: ${this.get_size() - constrained_size}, `,
                `Requested: ${size}${size != constrained_size ? `, Constrained: ${constrained_size}` : ''}`)
            // set size called directly. in this case first take the delta from other areas
            const delta = this.get_size() - constrained_size;
            this.resizable_flex._wrap_resize(() => {
                if (delta < 0) {
                    this.resizable_flex._shrink_areas_by(Math.abs(delta), this.resizable_flex.get_enabled_areas(), [this])
                } else {
                    this.resizable_flex._expand_areas_by(delta, this.resizable_flex.get_enabled_areas(), [this])
                }
                set_it()
            })
        } else {
            this.resizable_flex._wrap_resize(() => {
                set_it()
            })
        }
    }

    set_ratio_size(ratio: number) {
        this.set_size(this.get_flex_container_size() * ratio)
    }

    set_locked_ratio(ratio: number) {
        this.locked_px_size = null
        this.locked_ratio_size = ratio
    }

    set_locked_px(px: number) {
        this.locked_ratio_size = null
        this.locked_px_size = px
    }

    // <editor-fold desc="HELPERS">
    get_flex_container_size() {
        return this.resizable_flex.get_container_size()
    }
    // </editor-fold>

    // <editor-fold desc="GETTERS">
    get_initial_px_size() {
        return this.initial_px_size
    }

    get_initial_ratio_size() {
        return this.initial_ratio_size
    }

    get_is_locked() {
        return this.is_locked
    }

    get_is_expandable() {
        return this.is_expandable
    }

    get_is_shrinkable() {
        return this.is_shrinkable
    }

    get_requested_max_px_size() {
        return this.requested_max_px_size
    }

    get_requested_min_px_size() {
        return this.requested_min_px_size
    }

    get_locked_px_size() {
        return this.locked_px_size
    }

    get_locked_ratio_size() {
        return this.locked_ratio_size
    }

    get_requested_locked_px_size() {
        return this.requested_locked_px_size
    }

    get_shrinkable_px_size() {
        return this.shrinkable_px_size
    }

    get_expandable_px_size() {
        return this.expandable_px_size
    }

    get_resolved_min_px_size() {
        return this.resolved_min_px_size
    }

    get_resolved_max_px_size() {
        return this.resolved_min_px_size
    }

    get_resolved_grow_rate() {
        return this.resolved_grow_rate
    }

    get_resolved_shrink_rate() {
        return this.resolved_shrink_rate
    }

    get_previous_stick() {
        return this.previous_stick
    }

    get_next_stick() {
        return this.next_stick
    }

    get_grabbable_stick() {
        return this._grabbable_stick
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    handle_on_mousedown(e: MouseEvent) {
        const offsets = this.calc_grabbable_stick_offset(e)
        const next_stick_offset = offsets.next
        const prev_stick_offset = offsets.prev

        if (next_stick_offset <= prev_stick_offset) {
            if (this.resizable_flex.get_area_stick_overflow() >= next_stick_offset) {
                this.next_stick.handle_on_mousedown(e)
            }
        } else {
            if (this.resizable_flex.get_area_stick_overflow() >= prev_stick_offset) {
                this.previous_stick.handle_on_mousedown(e)
            }
        }
    }

    handle_on_mouseleave(_e: MouseEvent) {
        this._grabbable_stick = null
    }

    handle_on_mousemove(e: MouseEvent) {
        const offsets = this.calc_grabbable_stick_offset(e)
        const next_stick_offset = offsets.next
        const prev_stick_offset = offsets.prev

        if (next_stick_offset <= prev_stick_offset) {
            if (this.resizable_flex.get_area_stick_overflow() >= next_stick_offset) {
                this._grabbable_stick = this.next_stick
                e.stopImmediatePropagation()
                return;
            }
        } else {
            if (this.resizable_flex.get_area_stick_overflow() >= prev_stick_offset) {
                this._grabbable_stick = this.previous_stick
                e.stopImmediatePropagation()
                return;
            }
        }

        this._grabbable_stick = null
    }

    private calc_grabbable_stick_offset(e: MouseEvent): { prev: number, next: number } {
        let prev = this.resizable_flex.get_container_size()
        let next = this.resizable_flex.get_container_size()
        if (this.previous_stick != null) {
            const offsets = this.resizable_flex._cursor_offset_to_stick(this.previous_stick, e)
            prev = Math.abs(this.resizable_flex.is_row_direction() ? offsets.x : offsets.y)
        }
        if (this.next_stick != null) {
            const offsets = this.resizable_flex._cursor_offset_to_stick(this.next_stick, e)
            next = Math.abs(this.resizable_flex.is_row_direction() ? offsets.x : offsets.y)
        }
        return { prev, next }
    }

    _check_exclusive_property(opts: FlexContentAreaOpts, prop1: keyof FlexContentAreaOpts, prop2: keyof FlexContentAreaOpts) {
        if (opts[prop1] != null && opts[prop2] != null) {
            throw new Error(`Cannot set both ${prop1} and ${prop2}`)
        }
    }

    log() {
        return JSON.parse(JSON.stringify(this, function(key: string, value: string) {
            if (key == "resizable_flex" || key == "enabled_before_area" || key == "enabled_after_area") return;
            return value
        }))
    }
    // </editor-fold>
}

