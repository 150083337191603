import { VueRecordClient } from "../base/vue_record_client";
import { Browser } from "../models/browser";
import { BrowserScope } from "../scopes/browser_scope";
import { EnumBrowser } from "../../auto_generated/enums";
import { BrowserProps } from "../models/browser";
import { QuerifyProps } from "../base/vue_record_scope";
import { CacheStoreValue } from "../base/vue_record_client";
import { Jobberman } from "../../helpers/jobberman/jobberman";

export class BrowserClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Browser
    declare record: Browser

    static index() {
        return new Promise<BrowserScope>((resolve, reject) => {
            $.ajax({
                url: '/settings/superadmin_settings/browsers',
                type: 'GET',
                statusCode: ajax_status_codes,
                success: (data: BrowserProps[]) => {
                    const records: Browser[] = []
                    data.forEach(props => {
                        records.push(Browser.new(props))
                    })
                    resolve(Browser.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static add_by_download_link(jobberman_id: string, browser_type: EnumBrowser, driver_version: string, download_url: string) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/browsers/add_by_download_link`,
                type: 'POST',
                data: JSON.stringify({
                    authenticity_token,
                    jobberman_id,
                    browser_type,
                    driver_version,
                    download_url,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }

    stats() {
        type Response = { scenarios: number, plays: number }
        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/browsers/${this.key()}/stats`,
                type: 'GET',
                success: (data: Response) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(browser_props: QuerifyProps<BrowserProps>) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/browsers/${this.key()}`,
                type: 'PUT',
                data: JSON.stringify({
                    authenticity_token,
                    browser: browser_props,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }

    download() {
        return Jobberman.promise({
            run: (jobberman_id, _, reject) => {
                $.ajax({
                    url: `/settings/superadmin_settings/browsers/${this.key()}/download`,
                    type: 'POST',
                    data: {
                        authenticity_token,
                        jobberman_id,
                    },
                    error: (e) => {
                        reject(e)
                    },
                    statusCode: ajax_status_codes,
                })
            }
        })
    }

    static delete(ids: number | number[]) {
        return $.ajax({
            url: `/settings/superadmin_settings/browsers/destroy`,
            type: "DELETE",
            data: {
                ids,
                authenticity_token,
            },
            statusCode: ajax_status_codes,
        });
    }
}
