import { VueRecordScope } from "../../base/vue_record_scope";
import { computed } from "../../../helpers/vue/computed";
import { PlayScenario } from "../../models/play/play_scenario";

export class PlayScenarioScope extends VueRecordScope {
    ['constructor']: typeof PlayScenarioScope
    static ModelClass: typeof PlayScenario
    declare rthis: PlayScenarioScope

    main_play_scenarios = computed(() => this.rthis.where({ play_scenario_id: null }))
    started_or_pending = computed(() => this.rthis.where({ status: Enum.Play.Status.started_or_pending_statuses }))
}
