import { effectScope } from "vue";
import { VueRecordScope } from "./vue_record_scope";
import { EnumResourceLabel } from "../../auto_generated/enums";
import { EnumResourceId } from "../../auto_generated/enums";
import { WatchStopHandle } from "vue";
import _ from "lodash";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { VueRecordStore } from "./vue_record_store";
import { reactive } from "../../helpers/vue/reactive";
import { computed } from "../../helpers/vue/computed";
import { objects_diff } from "../../helpers/generic/objects_diff";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { TestaTree } from "../../components/testa/tree/tree";
import { on_shared_worker_loaded } from "../../helpers/ui_sync/on_shared_worker_loaded";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import SyncReceiveData = UiSync.SyncReceiveData;
import { OptionalProps } from "./vue_record_scope";
import { VueRecordIndex } from "./vue_record_index";
import { watch } from "vue";
import { VueRecordClient } from "./vue_record_client";
import { QuerifyProps } from "./vue_record_scope";
import { EnumUserRole } from "../../auto_generated/enums";
import { Data } from "faye";
import { safe_stringify } from "../../helpers/generic/safe_stringify";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { EnumSyncAction } from "../../auto_generated/enums";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { EventBus } from "../../helpers/event_bus";

// <editor-fold desc="TYPES">
// <editor-fold desc="ASSOCIATIONS">
export type BelongsTo = {
    relation: string,
    clazz: typeof VueRecord,
    foreign_key: string
    dependent_unload: boolean
}
export type HasMany = {
    relation: string,
    clazz: typeof VueRecord,
    foreign_key: string,
    dependent_unload: boolean
    dependent_nullify: boolean
}
export type HasOne = {
    relation: string,
    clazz: typeof VueRecord,
    foreign_key: string
    dependent_unload: boolean
    dependent_nullify: boolean
}

export type HasManyThrough = {
    relation: string,
    through: string,
    source: string
    clazz: typeof VueRecord
    scope: string
}

export type Association = BelongsTo | HasMany | HasOne | HasManyThrough

export type BelongsToAssociations = Array<BelongsTo>
export type HasManyAssociations = Array<HasMany>
export type HasOneAssociations = Array<HasOne>
export type HasManyThroughAssociations = Array<HasManyThrough>

export interface AssociationOpts {
    dependent_unload?: boolean
    dependent_nullify?: boolean
}

// </editor-fold>

// <editor-fold desc="RECORD OPTS">
export type RecordOpts = {
    is_stage?: boolean,
    stage_id?: string

    sync_action?: EnumSyncAction
}

export var default_record_opts: RecordOpts = {
    /** used to create stage records that are not updated via sync,
     * the stage records can be used when editing records and sending them to backend to update */
    is_stage: false,
}
// </editor-fold>

// <editor-fold desc="PROPS">
export type ActiveStorageBlob = {
    id: number
    key: string
    filename: string
    content_type: string
    metadata: string
    service_name: string
    byte_size: number
    checksum: string
    created_at: Date
}

export type ActiveStorageAttachment = {
    id: number
    name: string
    record_type: string
    record_id: number
    blob_id: number
    blob: ActiveStorageBlob
    created_at: Date
}
export type Props = {
    id?: number
    created_at?: Date
    updated_at?: Date // important to determine outdated sync messages
    [key: string]: any
}
// </editor-fold>


export type State = {}
export type Computed = {}

export interface ComputedRole extends Computed {
    role: EnumUserRole
    role_is_viewer: boolean
}

export type StaticState = {}
export type VueRecordEvents = "after_create"
    | "after_sync_add"
    | "after_update"
    | "before_unload"
    | "after_unload"
// </editor-fold>

const registered_resources: Record<string, typeof VueRecord> = {}

const console = new Consoler("debug")

export abstract class VueRecord {
    ['constructor']: typeof VueRecord

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static effect_scope = effectScope(true)
    static readonly primary_key: keyof Props = 'id';

    static sync_channels: string[] = []
    static state: StaticState = reactive<StaticState>({});
    static discard_outdated = true


    /** each model should overwrite this */
    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes: any[] = []
    static ScopeClass: typeof VueRecordScope
    static ClientClass: typeof VueRecordClient
    static store: VueRecordStore<typeof VueRecord>
    static stages_store: Record<string, VueRecordStore<typeof VueRecord>> = {}
    static indexed_columns: string[]

    // resource definition
    static icon_class = "fa-regular fa-note-sticky"
    static resource_name: EnumResourceLabel
    static resource_id: EnumResourceId
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    _base_event_bus: EventBus<VueRecordEvents>
    /** Unique key for stage records.
     * Is also generated for non-staged records.
     * Can be used for vue iteration key */
    stage_key: string;
    is_stage: boolean
    stage_id: string

    client: VueRecordClient
    props: Props = reactive<Props>({});
    protected props_for_watch = computed(() => Object.assign({}, this.props))
    state: State = reactive<State>({});
    watchers: WatchStopHandle[] = []
    computed: Computed;

    /** should be only updated on original record
     * key is stage_id, value is array of association names */
    staged_associations: Record<string, string[]>

    update_model_in_progress = false

    // </editor-fold>

    constructor(props: Props, opts: RecordOpts) {
        if (!((new Error()).stack.indexOf('new') > -1)) {
            throw new Error('Constructor is private. Use static method new.');
        }
        this._base_event_bus = new EventBus<VueRecordEvents>()
        opts = _.merge(_.cloneDeep(default_record_opts), opts)
        this._process_props(props)
        Object.assign(this.props, reactive(props))
        this.stage_key = generate_uuid();
        this.is_stage = opts.is_stage;
        if (this.is_stage && opts.stage_id == null) opts.stage_id = generate_uuid();
        this.stage_id = opts.stage_id
        this.computed = reactive({});
        this.staged_associations = {}
    }

    static new<T extends typeof VueRecord>(this: T, props: Props, opts: RecordOpts = default_record_opts): InstanceType<T> {
        props = _.cloneDeep(props)
        let new_record: InstanceType<T>;
        if (!opts.is_stage) {
            const old_primary_key = `__old_${this.primary_key}`
            let record: VueRecord = null;
            if (props[old_primary_key] != null) {
                record = this.find(props[old_primary_key], opts.stage_id)
            } else {
                record = this.find(props[this.primary_key], opts.stage_id)
            }

            if (record != null) {
                VueRecord.effect_scope.run(() => {
                    record._update_model(props)
                })
                return record as InstanceType<T>;
            }
        }

        VueRecord.effect_scope.run(() => {
            // @ts-ignore
            new_record = reactive(new this(props, opts) as InstanceType<T>)
            new_record._set_association_compute_refs()
            this.get_store(new_record.stage_id).add(new_record)

            // @ts-ignore
            new_record.client = new new_record.constructor.ClientClass(new_record)
            new_record.after_create()
            new_record._base_event_bus.$emit("after_create", new_record, null)

            if (opts.sync_action == Enum.Sync.Action.ADD) {
                new_record.after_sync_add()
                new_record._base_event_bus.$emit("after_sync_add", new_record, null)
            }

            new_record.watchers.push(watch(
                () => new_record.props_for_watch,
                (new_props, old_props) => {
                    if (new_record.update_model_in_progress) return
                    new_record._trigger_update_hooks(new_props, old_props)
                }, {
                    immediate: false,
                    deep: true,
                    flush: "sync"
                }
            ))
        })

        return new_record
    }

    // <editor-fold desc="ABSTRACT METHODS">
    abstract testa_tree_node_data(): TestaTree.NodeInput<any, any, any>

    abstract show_in_sidebar(tree: TestaTree.Tree): Promise<void>

    static show_in_sidebar(_id: number | string | number[] | string[], _project_version_id: number) {
        console.error("Cannot show application record in sidebar. Override this method")
    }

    // </editor-fold>

    key(): this['props'][this['constructor']['primary_key']] {
        const key = this.props[this.constructor.primary_key];
        if (key != null) return key
        if (this.is_stage) return this.stage_key as any
        return key
    }

    name(): string {
        return this.props.name
    }

    unload() {
        this.before_unload()
        this._base_event_bus.$emit("before_unload", this, null)
        this.constructor.get_store(this.stage_id).remove(this)
    }

    get_reactive_object<T extends VueRecord>(this: T): T {
        return this.constructor.find(this.key(), this.stage_id) as T
    }

    delete_warning_text() {
        return `You are about to delete <strong>${this.name()}</strong> ${this.constructor.resource_name}!`
    }

    stage(stage_id: string = null) {
        if (this.is_stage) {
            if (this.stage_id != stage_id) {
                const staged = this.constructor.find(this.key(), stage_id)
                if (staged != null) return staged as this

                const original = this.unstaged()
                return this.constructor.new(_.cloneDeep(original.props), {
                    is_stage: true,
                    stage_id
                })
            } else return this.get_reactive_object()
        } else {
            const staged = this.constructor.find(this.key(), stage_id)
            if (staged != null) return staged as this

            return this.constructor.new(_.cloneDeep(this.props), {
                is_stage: true,
                stage_id
            })
        }
    }

    unstaged(): this {
        const unstaged = this.constructor.find(this.key())

        // it is possible to have only staged records without originals.
        if (unstaged == null) return this
        return unstaged as this
    }

    static dispose_stage(stage_id: string) {
        delete this.stages_store[stage_id]
    }

    get_tabs() {
        return window.Editor
                     .get_tabs()
                     .filter(tab => tab.state.record?.constructor?.resource_id == this.constructor.resource_id)
                     .filter(tab => tab.state.record.key() == this.key())
    }

    on<T extends VueRecord>(event: VueRecordEvents, callback: (record: T, e: any) => void) {
        this._base_event_bus.$on(event, callback as any)
    }

    off<T extends VueRecord>(event: VueRecordEvents, callback: (record: T, e: any) => void) {
        this._base_event_bus.$off(event, callback as any)
    }

    // <editor-fold desc="QUERYING">
    static get_scope<T extends typeof VueRecord>(this: T, stage_id: string = null): InstanceType<T['ScopeClass']> {
        return this.get_store(stage_id).get_scope() as InstanceType<T['ScopeClass']>
    }

    static find<T extends typeof VueRecord>(this: T, primary_key: InstanceType<T>['props'][T['primary_key']], stage_id?: string): InstanceType<T>;
    static find<T extends typeof VueRecord>(this: T, primary_keys: InstanceType<T>['props'][T['primary_key']][], stage_id?: string): InstanceType<T['ScopeClass']>;
    static find<T extends typeof VueRecord>(this: T, key_or_keys: any, stage_id: string = null): any {
        if (key_or_keys instanceof Array) {
            const query_obj: any = {}
            query_obj[this.primary_key] = key_or_keys
            return this.get_scope(stage_id).where(query_obj)
        } else return this.get_store(stage_id).find(key_or_keys) as InstanceType<T>
    }

    static load<T extends typeof VueRecord>(this: T, primary_key: InstanceType<T>['props'][T['primary_key']], stage_id?: string): Promise<InstanceType<T>>;
    static load<T extends typeof VueRecord>(this: T, primary_keys: InstanceType<T>['props'][T['primary_key']][], stage_id?: string): Promise<InstanceType<T['ScopeClass']>>;
    static load<T extends typeof VueRecord>(this: T, key_or_keys: any, stage_id: string = null): any {
        let promise
        if (key_or_keys instanceof Array) {
            promise = this.ClientClass.batch_load(key_or_keys)
            if (stage_id != null) {
                promise.then(scope => scope.stage(stage_id))
            }
        } else {
            promise = this.ClientClass.load(key_or_keys)
        }

        return promise
    }

    static find_or_load<T extends typeof VueRecord>(this: T, primary_key: InstanceType<T>['props'][T['primary_key']], stage_id?: string): Promise<InstanceType<T>>;
    static find_or_load<T extends typeof VueRecord>(this: T, primary_key: InstanceType<T>['props'][T['primary_key']][], stage_id?: string): Promise<InstanceType<T['ScopeClass']>>;
    static find_or_load<T extends typeof VueRecord>(this: T, key_or_keys: any, stage_id: string = null): any {
        if (key_or_keys instanceof Array) {
            const scope = this.find(key_or_keys, stage_id)
            if (scope.count == 0) return this.load(key_or_keys, stage_id)
            else return generate_resolved_promise(scope)
        } else {
            const record = this.find(key_or_keys, stage_id)
            if (record == null) return this.load(key_or_keys, stage_id)
            else return generate_resolved_promise(record)
        }
    }

    static where<T extends typeof VueRecord>(this: T, query?: QuerifyProps<InstanceType<T>['props']>, stage_id: string = null): InstanceType<T['ScopeClass']> {
        return this.get_scope(stage_id).where(query) as InstanceType<T['ScopeClass']>
    }

    static get_store(stage_id: string = null) {
        if (stage_id == null) {
            return this.store
        } else {
            if (this.stages_store[stage_id] == null) this.stages_store[stage_id] = VueRecordStore.new(this, stage_id)
            return this.stages_store[stage_id]
        }
    }

    static to_scope<T extends typeof VueRecord>(this: T, records: InstanceType<T>[], stage_id: string = null): InstanceType<T['ScopeClass']> {
        const query: any = {}
        query[this.primary_key] = records.pluck("key").uniq()

        return this.get_scope(stage_id).where(query)
    }

    // </editor-fold>
    static register_resource(model: typeof VueRecord) {
        this.indexed_columns = (this.indexes as VueRecordIndex[]).pluck("column_index_props").flat().pluck("column").uniq() as string[]
        registered_resources[model.resource_id] = model
    }

    static toArray<T extends typeof VueRecord>(this: T, stage_id: string = null): InstanceType<T>[] {
        return this.get_store(stage_id).get_scope().toArray() as InstanceType<T>[]
    }

    static sync(channel: string) {
        if (!channel) return;

        on_shared_worker_loaded(() => {
            if (this.sync_channels.includes(channel)) return;
            this.sync_channels.push(channel)
            // tell shared worker to subscribe to this channel
            ui_sync.send_sync_subscribe_task(channel, this.resource_id)
        })

        // register a handler for sync messages
        ui_sync.register_sync_task("receive", this.resource_id, (sender: string, data: SyncReceiveData) => {
            // because we receive all messages for given resource id here, filter those that are not for this channel
            if (data.channel != channel) return;

            window.console.debug(JSON.parse(JSON.stringify(data.message)))
            this.on_sync_data_received(data.message)
        })
    }

    static on_sync_data_received(data: Data) {
        switch (data.action) {
            // because websocket can hang, we might first receive replace and then add
            case Enum.Sync.Action.ADD:
            case Enum.Sync.Action.REPLACE:
            case Enum.Sync.Action.REOPEN:
                this.new(data.data, { sync_action: data.action });
                break;

            case Enum.Sync.Action.REMOVE:
                this.find(data.data[this.primary_key])?.unload()
                break;
            case Enum.Sync.Action.VIEW_ACTIVITY:
                // do nothing here
                break;
            default:
                throw new Error(`Unsupported sync action ${data.action}`)
        }
    }

    /** if channel is not sent, all channels will be unsynched */
    static unsync(channel: string = null) {
        let unsync: string[] = []
        if (channel == null) unsync = this.sync_channels
        else unsync = [channel]

        on_shared_worker_loaded(() => {
            unsync.forEach(c => {
                // tell shared worker to unsubscribe to this channel
                ui_sync.send_unsync_subscribe_task(c, this.resource_id)
            })
            this.sync_channels = this.sync_channels.filter(c => !unsync.includes(c))
        })
    }

    /**
     * @return Immutable representation of application record object used for logging purposes
     */
    log() {
        // maybe later, also add state and computed properties
        return safe_stringify(this.props)
    }

    // <editor-fold desc="TREE">
    tree_key() {
        return this.stage_key
    }

    static tree_key(project_version_id: number) {
        return `${project_version_id}_${this.resource_name.replaceAll(" ", "_")}_root`
    }

    // </editor-fold>

    // <editor-fold desc="HOOKS">
    after_create() {
    }

    /** Called after "after_create" and only when record is created with sync action ADD */
    after_sync_add() {

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    after_update(new_props: Props, old_props: Props, _changes: (keyof Props)[]) {
    }

    before_unload() {
        this.watchers.each(stop => stop())
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    protected init_computed_role(project_id_getter: () => number) {
        (this.computed as ComputedRole).role = computed(() => current.role_for(project_id_getter())) as any as EnumUserRole
        (this.computed as ComputedRole).role_is_viewer = computed(() => current.role_is_viewer_for(project_id_getter())) as any as boolean
    }

    _set_association_compute_refs() {
        this._set_has_many_compute_refs();
        this._set_belongs_to_compute_refs();
        this._set_has_one_compute_refs();
        this._set_has_many_through_compute_refs();
    }

    _set_has_many_compute_refs() {
        this.constructor.has_many_associations.forEach(hma => {
            // @ts-ignore
            this[`_compute_${hma.relation}`]()
        })
    }

    _set_belongs_to_compute_refs() {
        this.constructor.belongs_to_associations.forEach(bta => {
            // @ts-ignore
            this[`_compute_${bta.relation}`]()
        })
    }

    _set_has_one_compute_refs() {
        this.constructor.has_one_associations.forEach(hoa => {
            // @ts-ignore
            this[`_compute_${hoa.relation}`]()
        })
    }

    _set_has_many_through_compute_refs() {
        this.constructor.has_many_through_associations.forEach(hmta => {
            // @ts-ignore
            this[`_compute_${hmta.relation}`]()
        })
    }

    /** Goes over records that have be instantiated before relations have been established
     * and then removes the associated records from props and move them to their own record instance
     */
    static _on_relations_established() {
        Object.values(registered_resources).forEach(Model => {
            Model.toArray().forEach(r => {
                Model._extract_associations(r.props)
                r._set_association_compute_refs()
                Model.relations_established = true
            })
        })
    }

    _update_model(new_props: Props): void {
        this._process_props(new_props)
        if (this.constructor.discard_outdated &&
            this.props.hasOwnProperty('updated_at') && new_props.hasOwnProperty('updated_at') &&
            this.props.updated_at.getTime() > new_props.updated_at.getTime()) {
            console.warn(`(${this.constructor.resource_id}) Message outdated! Discarding:`,
                _.clone(this.props),
                _.clone(new_props),
                `Old time: ${this.props.updated_at.getTime()}`,
                `New time: ${new_props.updated_at.getTime()}`
            )
            return;
        }

        // keep the old prop if it is missing from the new
        // that way we don't lose data when only part of the object is updated and
        // sent to frontend
        for (const key in this.props) {
            if (!new_props.hasOwnProperty(key)) {
                new_props[key] = this.props[key]
            }
        }
        const changed_keys = this._diff(new_props)
        if (changed_keys.length > 0) {
            this.update_model_in_progress = true
            const old_props = _.cloneDeep(this.props)
            try {
                const created_at = new_props.hasOwnProperty('created_at') ? new Date(new_props.created_at) : null
                const updated_at = new_props.hasOwnProperty('updated_at') ? new Date(new_props.updated_at) : null
                delete new_props.created_at
                delete new_props.updated_at
                Object.assign(this.props, new_props);
                // Date objects do not work with Object.assign
                if (created_at != null) this.props.created_at = created_at
                if (updated_at != null) this.props.updated_at = updated_at
            } finally {
                try {
                    this._trigger_update_hooks(this.props, old_props)
                } finally {
                    this.update_model_in_progress = false
                }
            }
        }
    }

    _trigger_update_hooks(new_props: Props, old_props: Props) {
        const changed_keys = VueRecord._diff(new_props, old_props)
        // console.debug("PROPS CHANGED: ", changed_keys, new_props, old_props);
        if (changed_keys.some(k => this.constructor.indexed_columns.includes(k))) {
            this.constructor.get_store(this.stage_id).update(this, old_props)
        }

        this.after_update(this.props, old_props, changed_keys)
        this._base_event_bus.$emit("after_update", this)
    }

    _process_props(props: Props) {
        props = VueRecord._type_convert_props(props)
        this.constructor._extract_associations(props)
    }

    _diff(other_props: Props): Array<string> {
        return VueRecord._diff(this.props, other_props)
    }

    static _diff(new_props: Props, old_props: Props) {
        const diff = objects_diff(old_props, new_props);
        if (diff[0] == null && diff[1] == null) return []
        return Object.keys(diff[0]).concat(Object.keys(diff[1])).uniq()
    }

    static _type_convert_props(props: Props) {
        if (props.hasOwnProperty("updated_at")) props.updated_at = new Date(props.updated_at)
        if (props.hasOwnProperty("created_at")) props.created_at = new Date(props.created_at)
        return props
    }

    static _extract_associations(props: Props) {
        const extract = (assoc: Association, props: Props) => {
            if (props.hasOwnProperty(assoc.relation)) {
                const it_is = what_is_it(props[assoc.relation])
                if (it_is == 'Object') {
                    assoc.clazz.new(props[assoc.relation])
                    delete props[assoc.relation];
                } else if (it_is == 'Array') {
                    props[assoc.relation].forEach((p: Props) => assoc.clazz.new(p))
                    delete props[assoc.relation];
                }
            }
        }
        this.belongs_to_associations.forEach((belongs_to_assoc: BelongsTo) => extract(belongs_to_assoc, props))
        this.has_many_associations.forEach((has_many_assoc: HasMany) => extract(has_many_assoc, props))
        this.has_one_associations.forEach((has_one_assoc: HasOne) => extract(has_one_assoc, props));
    }

    static stores() {
        return [this.store, ...Object.values(this.stages_store)]
    }

    // <editor-fold desc="ASSOCIATIONS">
    static has_many(relation: string, clazz: typeof VueRecord, foreign_key: string, opts: AssociationOpts = {}) {
        if (opts.dependent_unload == null) opts.dependent_unload = false
        if (opts.dependent_nullify) opts.dependent_nullify = false

        this.has_many_associations.push({
            relation,
            clazz,
            foreign_key,
            dependent_unload: opts.dependent_unload,
            dependent_nullify: opts.dependent_nullify,
        })

        // @ts-ignore
        this.prototype[`_compute_${relation}`] = function(this: VueRecord) {
            // @ts-ignore
            this[relation] = computed(() => {
                const query: Props = {}
                query[foreign_key] = this.key()


                if (this.is_stage) {
                    const original = this.unstaged()
                    if (original.staged_associations[this.stage_id] == null) original.staged_associations[this.stage_id] = reactive([])
                    if (original.staged_associations[this.stage_id].includes(relation)) {
                        return clazz.get_scope(this.stage_id).where(query)
                    } else {
                        original.staged_associations[this.stage_id].push(relation)
                        return clazz.get_scope().where(query).stage(this.stage_id)
                    }
                } else {
                    return clazz.get_scope().where(query)
                }
            })
        }
    }

    static belongs_to(relation: string, clazz: typeof VueRecord, foreign_key: string, opts: AssociationOpts = {}) {
        if (opts.dependent_unload == null) opts.dependent_unload = false
        if (opts.dependent_nullify) opts.dependent_nullify = false

        this.belongs_to_associations.push({
            relation,
            clazz,
            foreign_key,
            dependent_unload: opts.dependent_unload,
        })
        // @ts-ignore
        this.prototype[`_compute_${relation}`] = function(this: VueRecord) {
            // @ts-ignore
            this[relation] = computed(() => {
                const belongs_to_record = clazz.find(this.props[foreign_key])
                if (this.is_stage) {
                    const original = this.unstaged()
                    if (original.staged_associations[this.stage_id] == null) original.staged_associations[this.stage_id] = reactive([])
                    if (!original.staged_associations[this.stage_id].includes(relation)) {
                        original.staged_associations[this.stage_id].push(relation)
                    }

                    return belongs_to_record?.stage(this.stage_id)
                } else return belongs_to_record
            })
        }
    }

    static has_one(relation: string, clazz: typeof VueRecord, foreign_key: string, opts: AssociationOpts = {}) {
        if (opts.dependent_unload == null) opts.dependent_unload = false
        if (opts.dependent_nullify) opts.dependent_nullify = false

        this.has_one_associations.push({
            relation,
            clazz,
            foreign_key,
            dependent_unload: opts.dependent_unload,
            dependent_nullify: opts.dependent_nullify,
        })
        // @ts-ignore
        this.prototype[`_compute_${relation}`] = function(this: VueRecord) {
            // @ts-ignore
            this[relation] = computed(() => {
                const query: Props = {}
                query[foreign_key] = this.props[this.constructor.primary_key]
                if (this.is_stage) {
                    const original = this.unstaged()
                    if (original.staged_associations[this.stage_id] == null) original.staged_associations[this.stage_id] = reactive([])
                    if (original.staged_associations[this.stage_id].includes(relation)) {
                        return clazz.get_scope(this.stage_id).where(query).first()
                    } else {
                        original.staged_associations[this.stage_id].push(relation)
                        return clazz.get_scope().where(query).stage(this.stage_id).first()
                    }
                } else return clazz.get_scope().where(query).first();
            })
        }
    }

    /**
     * Example, User can have n projects, Projects can have n Users.
     * Then we have a bridge table, projects_users. In the Project we would define has_many_through for users
     * relation would be "users", through would be "projects_users" and source would be the "user" method in
     * projects_users model.
     * @param relation in other words, what will be the method name by which you access the association records
     * @param through what association method will be called first
     * @param source from the resulting association method, what method will be called next
     * @param source_class the class of the resulting records. This is used to properly insert scopes and query methods
     * to further enable method chaining
     * @param scope optional scope method what will be used to narrow the resulting data
     */
    static has_many_through(relation: string, through: string, source: string, source_class: typeof VueRecord, scope: string = null) {
        this.has_many_through_associations.push({
            relation,
            through,
            source,
            clazz: source_class,
            scope,
        })

        source_class.inverse_has_many_through.push({
            relation,
            through,
            source,
            clazz: this,
            scope,
        })

        // @ts-ignore
        this.prototype[`_compute_${relation}`] = function(this: VueRecord) {
            // @ts-ignore   define the has_many_through method
            this[relation] = computed(() => {
                if (!this.hasOwnProperty(through)) console.error(`${this.constructor.resource_id} is missing [${through}] relation`)
                // @ts-ignore   obtain the record/s by calling the other (through) association
                const collection_array = this[through].toArray() // @ts-ignore
                                                      .map((record: VueRecord) => record[source])
                                                      .filter((r: VueRecord) => typeof r !== "undefined")

                // @ts-ignore   if we get scoped array as result, extract the records from the scope and flatten the result
                const records_as_array: VueRecord[] = collection_array.map(collection => {
                    if (typeof collection.toArray === 'function') {
                        return collection.toArray();
                    } else {
                        return collection
                    }
                }).flat().filter((r: VueRecord) => typeof r !== "undefined")

                const stage_id = this.is_stage ? this.stage_id : null
                const query: OptionalProps = {}
                query[this.constructor.primary_key] = records_as_array.map(r => r.key())
                const model_scope = source_class.get_scope(stage_id).where(query)
                if (this.is_stage) {
                    model_scope.is_stage = true
                    model_scope.stage_id = this.stage_id
                }

                if (scope == null) {
                    return model_scope
                } else {
                    // @ts-ignore
                    return model_scope[scope]
                }
            })
        }
    }

    // </editor-fold>
    // </editor-fold>
}

require("./utils/to_scoped_map")
