import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f4fa733"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-priority", "onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["title", "onClick"]
const _hoisted_4 = ["title", "innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Table = _resolveComponent("Table")
  const _directive_moment = _resolveDirective("moment")

  return (_openBlock(), _createBlock(_component_Table, { id: _ctx.id }, {
    thead: _withCtx(() => [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_columns, (col, key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
            (key == 'delete')
              ? (_openBlock(), _createElementBlock("th", {
                  key: 0,
                  class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                  style: {"cursor":"pointer"},
                  onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.all_marked = !_ctx.all_marked), ["stop"]))
                }, [
                  _createVNode(_component_Checkbox, {
                    modelValue: _ctx.all_marked,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.all_marked) = $event)),
                    label: "",
                    for_table: true,
                    color_class: "red"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ], 2 /* CLASS */))
              : (_openBlock(), _createElementBlock("th", {
                  key: 1,
                  class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                  "data-priority": _ctx.orders.priority(key),
                  onClick: $event => (_ctx.orders.toggle(key))
                }, _toDisplayString(col.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    tbody: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordered_rows, (row) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: row.record.props.id,
          class: "user-row",
          onClick: $event => (_ctx.$emit('user-click', row.record))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cols, (col, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (col.column_key == 'delete')
                ? (_openBlock(), _createElementBlock("td", {
                    key: 0,
                    title: col.title,
                    class: _normalizeClass(col.classes),
                    onClick: _withModifiers($event => (_ctx.deletion_markers.marker(row.record).toggle()), ["stop"])
                  }, [
                    _createVNode(_component_Checkbox, {
                      modelValue: _ctx.deletion_markers.marker(row.record).value,
                      "onUpdate:modelValue": $event => ((_ctx.deletion_markers.marker(row.record).value) = $event),
                      label: "",
                      for_table: true,
                      color_class: "red"
                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                  ], 10 /* CLASS, PROPS */, _hoisted_3))
                : _withDirectives((_openBlock(), _createElementBlock("td", {
                    key: 1,
                    title: col.title,
                    class: _normalizeClass(col.classes),
                    innerHTML: col.html
                  }, null, 10 /* CLASS, PROPS */, _hoisted_4)), [
                    [_directive_moment, col.moment]
                  ])
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 8 /* PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["id"]))
}