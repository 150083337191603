<template>
  <Modal
      id="add_phone_modal"
      :show_cancel="true"
      :is_loading="is_loading"
      :cancel_on_escape="true"
      confirm_text="Add"
      :confirm_action="confirm"
      @cancel="cancel"
  >
    <template #header>
      <div v-if="phones_not_in_project.length == 0">
        No available phones
      </div>
      <div v-else>
        Add phone
      </div>
    </template>
    <template #body>
      <div class="container">
        <div v-if="phones_not_in_project.length == 0">
          <h4>Androids</h4>
          <ul>
            <li>Make sure USB debugging is enabled</li>
            <li>When plugged in, authorize the USB debugging connection</li>
            <li>Use a data cable (charging only cable will not work)</li>
            <li>Remove screen unlock password/pattern/pin</li>
            <li>(Recommended) In Developer options enable stay awake while charging</li>
          </ul>

          <h4>iPhones</h4>
          <ul>
            <li>Use a data cable (charging only cable will not work)</li>
            <li>If iOS version >= 16.0 Enable developer mode (Settings => Privacy & Security => Developer Mode)</li>
            <li>If developer mode is missing, plug the iphone into a mac with the latest xcode</li>
            <li>Allow pairing request</li>
          </ul>
        </div>
        <div v-else
             class="device-selector"
        >
          <Select2
              id="select_add_to_project_device"
              v-model="selected_udid"
              dropdown_parent="#add_phone_modal"
              label="Select Device"
          >
            <template v-for="phone_not_in_project in phones_not_in_project"
                      :key="phone_not_in_project.udid">
              <option :value="phone_not_in_project.udid">
                {{ phone_not_in_project.name }} {{ phone_not_in_project ? '' : ' - UNCLAIMED' }}
              </option>
            </template>
          </Select2>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../testa/Modal.vue";
import { Phone } from "../../vue_record/models/phone";
import Select2 from "../testa/Select2.vue";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { EnumPhoneType } from "../../auto_generated/enums";

export default defineComponent({
    components: { Select2, Modal },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: ['cancel'],
    // <editor-fold desc="DATA">
    data() {
        return {
            phones_not_in_project: [] as Array<{ udid: string, name: string, claimed: boolean, phone_type: EnumPhoneType }>,
            is_loading: true,
            selected_udid: null
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        Phone.ClientClass.not_in_project(current.project?.key())
             .then(data => {
                 const phones_not_in_project = data.sort_by((obj) => obj.name.toLowerCase())
                 this.phones_not_in_project = phones_not_in_project
                 if (phones_not_in_project.length > 0) {
                     this.selected_udid = phones_not_in_project[0].udid
                 }
                 this.is_loading = false
             })
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        confirm() {
            if (this.phones_not_in_project.length == 0) {
                this.cancel();
                return generate_resolved_promise()
            } else {
                return Phone.ClientClass
                            .add_to_project(current.project?.key(), this.selected_udid)
                            .then(() => this.cancel())
            }
        },
        cancel() {
            this.$emit("cancel");
            this.$.appContext.app.unmount();
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;

  .device-selector {
    min-width: 500px;
  }
}

</style>
