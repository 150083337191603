import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Scenario } from "../models/scenario";
import { ScenarioScope } from "../scopes/scenario_scope";
import _ from "lodash";
import { ScenarioSavepointScope } from "../scopes/scenario_savepoint_scope";
import { ScenarioSavepointProps } from "../models/scenario_savepoint";
import { ScenarioSavepoint } from "../models/scenario_savepoint";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { ScenarioFolderProps } from "../models/scenario_folder";
import { ScenarioFolder } from "../models/scenario_folder";
import { ScenarioProps } from "../models/scenario";
import { ScenarioCreateProps } from "../models/scenario";
import { ScenarioUpdateProps } from "../models/scenario";
import { CacheStoreValue } from "../base/vue_record_client";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { SnippetFolderProps } from "../models/snippet_folder";
import { GroupProps } from "../models/group";
import { GroupFolderProps } from "../models/group_folder";
import { Group } from "../models/group";
import { GroupFolder } from "../models/group_folder";

const console = new Consoler("warn")
export class ScenarioClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Scenario
    declare record: Scenario

    static load(id: number): Promise<Scenario> {
        return this.request<ScenarioProps>({
            url: `/scenarios/${id}`,
            type: "GET",
            cache: true
        }).then(props => Scenario.new(props))
    }

    static batch_load(ids: number[]): Promise<ScenarioScope> {
        if (ids.length == 0) return generate_resolved_promise<ScenarioScope>(Scenario.to_scope([]))

       return this.request<ScenarioProps[]>({
           cache: true,
           url: `/scenarios/batch/load`,
           type: "GET",
           data: { ids },
       }).then(props => {
           const records: Scenario[] = []
           props.each(scenario_props => {
               records.push(Scenario.new(scenario_props))
           })
           return Scenario.to_scope(records)
       })
    }

    static create(scenario: ScenarioCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/scenarios`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    scenario,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/scenarios/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(scenario: ScenarioUpdateProps) {
        type ScenarioUpdateResponse = {
            updated: boolean
            reason?: string
            scenario_props?: ScenarioProps
        }

        return new Promise<ScenarioUpdateResponse>((resolve, reject) => {
            const code_handlers = _.cloneDeep(ajax_status_codes)
            delete code_handlers[400]
            return $.ajax({
                url: `/scenarios/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    scenario,
                }),
                statusCode: code_handlers,
                success: (data: ScenarioUpdateResponse) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
            }) as JQuery.jqXHR<ScenarioUpdateResponse>
        })
    }

    load_savepoints() {
        return new Promise<ScenarioSavepointScope>((resolve, reject) => {
            $.ajax({
                url: `/scenarios/${this.key()}/savepoints`,
                type: "GET",
                statusCode: ajax_status_codes,
                success: (data: ScenarioSavepointProps[]) => {
                    data.forEach(scenario_savepoint_props => {
                        ScenarioSavepoint.new(scenario_savepoint_props)
                    })
                    resolve(this.record.scenario_savepoints)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    usages() {
        type UsagesResponse = {
            scenarios: ScenarioProps[],
            scenario_folders: SnippetFolderProps[],
            groups: GroupProps[],
            group_folders: GroupFolderProps[]
        }

        type Usages = {
            scenarios: Scenario[],
            scenario_folders: ScenarioFolder[],
            groups: Group[],
            group_folders: GroupFolder[]
        }

        return this.request<UsagesResponse>({
            url: `/scenarios/${this.key()}/usages`,
            type: "GET",
        }).then((data) => {
            return {
                scenarios: data.scenarios.map(s => Scenario.new(s)),
                scenario_folders: data.scenario_folders.map(s => ScenarioFolder.new(s)),
                groups: data.groups.map(g => Group.new(g)),
                group_folders: data.group_folders.map(g => GroupFolder.new(g))
            } as Usages
        })
    }

    static batch_path(scenario_ids: number | number[]) {
        let ids: number[];
        if (what_is_it(scenario_ids) == "Array") {
            ids = scenario_ids as number[]
        } else {
            ids = [scenario_ids as number]
        }

        type PathResponse = {
            [key: string]: {
                target: ScenarioProps,
                path: ScenarioFolderProps[]
            }
        }

        type PromiseResponse = {
            [key: string]: ScenarioFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/scenarios/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    ids,
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(scenario_id => {
                        Scenario.new(data[scenario_id].target)
                        const scenario_folders_props = data[scenario_id].path;
                        const array: ScenarioFolder[] = []
                        scenario_folders_props.forEach(scenario_folder_props => {
                            array.push(ScenarioFolder.new(scenario_folder_props))
                        })
                        promise_response[scenario_id] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static scenarios_with(snippet_ids: number[], scenario_ids: number[], except_scenario_ids: number[]) {
        return new Promise<ScenarioScope>((resolve, reject) => {
            $.ajax({
                url: `/scenarios/other_scenarios`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    snippet_ids,
                    scenario_ids,
                    except_scenario_ids
                }),
                statusCode: ajax_status_codes,
                success: (data: ScenarioProps[]) => {
                    const records: Scenario[] = [];
                    data.forEach(scenario_props => records.push(Scenario.new(scenario_props)));
                    resolve(Scenario.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                }
            })
        });
    }
}
