import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { generate_resolved_promise } from "../../../helpers/generate/generate_resolved_promise";
import { PlayScope } from "../../scopes/play/play_scope";
import _ from "lodash";
import { PlayWorkerGroup } from "../../../vue_record/models/play/play_worker_group";
import { PlayGroup } from "../../../vue_record/models/play/play_group";
import { PlayLog } from "../../../vue_record/models/play/play_log";
import { PlayScenarioModalResponse } from "../../models/play/play_scenario";
import { EnumPlayType } from "../../../auto_generated/enums";
import { PlaySettingProps } from "../../models/play/play_setting";
import { ExtendedScenarioSettingProps } from "../../models/scenario_setting";
import { PlayProps } from "../../models/play/play";
import { PlayUpdateProps } from "../../models/play/play";
import { PlayModalResponse } from "../../models/play/play";
import { Play } from "../../models/play/play";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PlayClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Play
    declare record: Play

    static load_filtered() {
        if (Play.state.filter_result_state.all_loaded ||
            Play.state.filter_result_state.loading ||
            current.user == null) {
            return generate_resolved_promise<PlayScope>(Play.to_scope([]))
        }

        Play.state.filter_result_state.loading = true
        type Response = {
            plays: PlayProps[],
            all_loaded: boolean
        }
        let project_version_ids = Play.state.filter.project_version_ids
        if (web.is_main && current.project_version != null) project_version_ids = [current.project_version.key()]

        const get_filter_params = () => {
            return {
                project_version_ids,
                project_ids: Play.state.filter.project_ids,
                last_created_at: Play.state.filter_result_state.last_created_at,
                name: Play.state.filter.name?.trim(),
                user_ids: Play.state.filter.user_ids,
                play_types: Play.state.computed_filter.play_types,
                play_statuses: Play.state.computed_filter.play_statuses,
                from_created_at: Play.state.filter.from,
                to_created_at: Play.state.computed_filter.to_until_end_of_day,
                schedule_id: Play.state.filter.schedule_id,
                group_id: Play.state.filter.group_id,
                scenario_folder_id: Play.state.filter.scenario_folder_id,
                scenario_id: Play.state.filter.scenario_id
            }
        }
        const params_before_request = get_filter_params()
        return new Promise<PlayScope>((resolve, reject) => {
            $.ajax({
                url: `/play/plays/filter`,
                type: "GET",
                data: params_before_request,
                statusCode: ajax_status_codes,
                success: (data: Response) => {
                    const records: Play[] = [];
                    let last_created_at: Date = null
                    data.plays.forEach(props => {
                        const play = Play.new(props)
                        records.push(play)

                        if (last_created_at == null || last_created_at > play.props.created_at) {
                            last_created_at = play.props.created_at
                        }
                    })

                    Play.state.filter_result_state.last_response_failed = false
                    Play.state.filter_result_state.all_loaded = data.all_loaded
                    Play.state.filter_result_state.last_created_at = last_created_at
                    resolve(Play.to_scope(records))
                },
                error: (error) => {
                    Play.state.filter_result_state.last_response_failed = true
                    reject(error)
                },
            })
        }).finally(() => {
            Play.state.filter_result_state.loading = false
        }).then((data) => {
            const params_after_request = get_filter_params();
            delete params_before_request.last_created_at
            delete params_after_request.last_created_at

            const filter_outdated = !_.isEqual(params_before_request, params_after_request)
            if (filter_outdated) {
                Play.on_filter_changed()
            }
            return data
        })
    }

    load_associated() {
        type LoadResponse = {
            play: Play,
            play_worker_groups: PlayWorkerGroup[]
            play_groups: PlayGroup[],
            play_logs: PlayLog[],
        }
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/plays/${this.key()}/associated`,
                type: 'GET',
                success: (data: LoadResponse) => {
                    data.play_worker_groups.forEach(play_worker_group => {
                        PlayWorkerGroup.new(play_worker_group)
                    })

                    data.play_groups.forEach(play_group => {
                        PlayGroup.new(play_group)
                    })

                    data.play_logs.forEach(play_log => {
                        PlayLog.new(play_log)
                    })
                    resolve()
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(play: PlayUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/play/plays/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    play
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static load(id: string | number, reload = false): Promise<Play> {
        if (reload) this.ModelClass.state.load_promises[id] = null

        if (this.ModelClass.state.load_promises[id] == null) {
            const promise = new Promise<Play>((resolve, reject) => {
                $.ajax({
                    url: `/play/plays/${id}/load`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: PlayProps) => {
                        resolve(Play.new(data))
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
            this.ModelClass.state.load_promises[id] = promise
            promise.catch(() => {
                this.ModelClass.state.load_promises[id] = null
            })
            return promise
        } else {
            return this.ModelClass.state.load_promises[id]
        }
    }

    stop() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/plays/${this.key()}/terminate`,
                type: 'POST',
                data: {
                    authenticity_token,
                },
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes
            })
        })
    }

    pause() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/plays/${this.key()}/pause`,
                type: 'POST',
                data: {
                    authenticity_token,
                },
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes
            })
        })
    }

    resume() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/plays/${this.key()}/resume`,
                type: 'POST',
                data: {
                    authenticity_token,
                },
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes
            })
        })
    }

    cycle_on_error() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/plays/${this.key()}/cycle_on_error`,
                type: 'POST',
                data: {
                    authenticity_token,
                },
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes
            })
        })
    }

    replay() {
        return new Promise<PlayModalResponse | PlayScenarioModalResponse>((resolve, reject) => {
            $.ajax({
                url: `/play/queues/replay`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    play_id: this.key(),
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PlayModalResponse | PlayScenarioModalResponse) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    }

    static add_to_queue(id: number, play_type: EnumPlayType, play_setting: PlaySettingProps, play_setting_id: number, replay_from_play_id: number = null, scenario_setting: ExtendedScenarioSettingProps = null) {
        type Response = {
            success: boolean,
            response: string,
            play_id: number
            report_link: string
        }
        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: '/play/queues/add_to_queue',
                processData: false,
                contentType: "application/json",
                type: 'POST',
                data: JSON.stringify({
                    authenticity_token,
                    play_setting_id,
                    play_setting,
                    play_type,
                    id,
                    replay_from_play_id,
                    scenario_setting,
                }),
                success: (data: Response) => resolve(data),
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        }).then(data => {
            // dont open for scheduled
            if (play_setting.perform_later) return data;

            if (data.success) Play.ClientClass.load(data.play_id).then(play => play.open_in_reports())
            return data
        })
    }

    static save_settings(savepoint_id: number, play_type: EnumPlayType, play_setting: PlaySettingProps, scenario_setting: ExtendedScenarioSettingProps = null) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/play/settings/save',
                type: 'POST',
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    play_setting,
                    scenario_setting,
                    savepoint_id,
                    play_type,
                    authenticity_token
                }),
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }

    static send_to_xray(ids: number | number[]) {
        if (!Array.isArray(ids)) ids = [ids]
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/settings/project_settings/integrations/xray/send_to_xray',
                type: 'POST',
                data: JSON.stringify({
                    ids,
                    authenticity_token,
                }),
                processData: false,
                contentType: "application/json",
                success: () => resolve(),
                error: (error) => reject(error),
            })
        })
    }
}
