import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ActionIcon = _resolveComponent("ActionIcon")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ActionIcon, {
      icon_class: "fa-solid fa-down-left-and-up-right-to-center fa-rotate-135",
      scale: 0.85,
      title: "Collapse all",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.project_tree.collapse_all()))
    }),
    _createVNode(_component_ActionIcon, {
      title: "Show active record",
      icon_class: "fa-solid fa-bullseye",
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.project_tree.show_active_tab()))
    }),
    (_ctx.current.project_version != null)
      ? (_openBlock(), _createBlock(_component_ActionIcon, {
          key: 0,
          icon_class: "fa-solid fa-magnifying-glass",
          title: "Search (double-shift)",
          onClick: _ctx.do_search_record
        }, null, 8 /* PROPS */, ["onClick"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}