import { ProjectVersion } from "../models/project_version";
import { Snippet } from "../models/snippet";
import { User } from "../models/user";
import { SnippetFolder } from "../models/snippet_folder";
import { SnippetSavepointScope } from "../scopes/snippet_savepoint_scope";
import { SnippetSavepoint } from "../models/snippet_savepoint";
import { VariableSet } from "../models/variable_set";


declare module "../models/snippet" {
    interface Snippet {
        user: User
        project_version: ProjectVersion
        snippet_folder: SnippetFolder
        current_savepoint: SnippetSavepoint

        variable_set: VariableSet

        snippet_savepoints: SnippetSavepointScope
    }
}

Snippet.belongs_to("user", User, "user_id")
Snippet.belongs_to("project_version", ProjectVersion, "project_version_id")
Snippet.belongs_to("snippet_folder", SnippetFolder, "snippet_folder_id")

Snippet.has_many("snippet_savepoints", SnippetSavepoint, "snippet_id")

Snippet.has_one("variable_set", VariableSet, "snippet_id")
Snippet.has_one("current_savepoint", SnippetSavepoint, "snippet_id")
