import { PlayGroup } from "../../models/play/play_group";
import { Play } from "../../models/play/play";
import { GroupSavepoint } from "../../models/group_savepoint";
import { ScenarioFolderSavepoint } from "../../models/scenario_folder_savepoint";
import { PlayScenarioScope } from "../../scopes/play/play_scenario_scope";
import { PlayScenario } from "../../models/play/play_scenario";
import { PlayGroupsPlayScenarios } from "../../models/play/bridges/play_groups_play_scenarios";
import { PlayGroupsPlayScenariosScope } from "../../scopes/play/bridges/play_groups_play_scenarios_scope";

declare module "../../models/play/play_group" {
    interface PlayGroup {
        play: Play
        group_savepoint: GroupSavepoint
        scenario_folder_savepoint: ScenarioFolderSavepoint

        play_groups_play_scenarios: PlayGroupsPlayScenariosScope
        play_scenarios: PlayScenarioScope
        main_play_scenarios: PlayScenarioScope
    }
}

PlayGroup.belongs_to("play", Play, "play_id")
PlayGroup.belongs_to("group_savepoint", GroupSavepoint, "group_savepoint_id")
PlayGroup.belongs_to("scenario_folder_savepoint", ScenarioFolderSavepoint, "scenario_folder_savepoint_id")

// has many
PlayGroup.has_many("play_groups_play_scenarios", PlayGroupsPlayScenarios, "play_group_id")

// has many through
PlayGroup.has_many_through("play_scenarios", "play_groups_play_scenarios", "play_scenario", PlayScenario)

PlayGroup.has_many_through(
    "main_play_scenarios",
    "play_groups_play_scenarios",
    "play_scenario",
    PlayScenario,
    "main_play_scenarios"
)

