<template>
  <div ref="container"
       class="image-capture-container">
    <div class="images-controls-container">
      <div class="images-controls">
        <span
            class="image-folder-path"
            title="Select the folder in sidebar to change">
          {{ image_folder_path == null ? '' : image_folder_path }}/
        </span>
        <Input
            v-model="image_name"
            :validator="form_validator.register('name', { excludes: { characters: forbidden_characters }, presence: true })"
            :select_on_focus="true"
            @enter="() => form_validator.valid ? save_cropped() : null"
        />
        <span class="image-extension">
          {{ extension }}
        </span>
        <div v-if="successfully_uploaded"
             ref="upload_success_icon"
             title="Screenshot successfully uploaded"
             class="images-upload-notification"
             style="color: var(--button-green);">
          <i class="fas fa-check"/>
        </div>
        <div v-if="failed_to_upload"
             ref="upload_fail_icon"
             title="Screenshot failed to upload"
             class="images-upload-notification"
             style="color: var(--button-red);">
          <i class="fas fa-minus"/>
        </div>
        <Button
            id="image_editor_save"
            text="Save"
            :form_validator="form_validator"
            min_width="fit-content"
            @click="save_cropped"
        />
      </div>
    </div>

    <div v-once
         :id="cropped_container_id"
         ref="cropper_container"
         class="images-screenshot no-default-focus">
      <img ref="the_ss"
           alt="Captured image"
           style="max-width: 100%; max-height: 100%;"
           :src="image_url"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { Image } from "../../../../../vue_record/models/image";
import { ImageTab } from "../../tabs/image_tab";
import { copy_text_to_clipboard } from "../../../../../helpers/generic/copy_to_clipboard";
import Button from "../../../Button.vue";
import Input from "../../../Input.vue";
import { generate_eid } from "../../../../../helpers/generate/generate_eid";
import { get_css_var } from "../../../../../helpers/generic/get_css_var";
import { FormValidator } from "../../../../../helpers/validator/form_validator";
import { TestaTree } from "../../../tree/tree";
import { ImageFolder } from "../../../../../vue_record/models/image_folder";
import ConfirmDialogue from "../../../confirm_dialogue/confirm_dialgue";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";

export type Cropper = {
    getCroppedCanvas(): any
    onResize(): void
    getContainerData(): { width: number, height: number }
    zoomTo(ratio: number, position?: { x: number, y: number }): void
    reset(): void
    setDragMode(mode: "none" | "crop" | "move"): void
    move(offsetX: number, offsetY: number): void
    cropper: HTMLDivElement
    dragBox: HTMLDivElement
}


export default defineComponent({
    components: { Input, Button },
    // <editor-fold desc="PROPS">
    props: {
        project_version_id: {
            type: Number,
            required: true
        },
        image: {
            type: Object as PropType<Image>,
            required: false,
            default: null
        },
        external_image_url: {
            type: String,
            required: false,
            default: null,
        },
        tab: {
            type: Object as PropType<ImageTab>,
            required: false,
            default: null
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            image_name: this.image == null ? "name" : this.image.props.name.replace(/\.png$/, ''),
            instance_id: generate_eid(),
            cropper: null as Cropper,
            successfully_uploaded: false,
            failed_to_upload: false,
            crop_mode_enabled: true,
            form_validator: new FormValidator("image_editor"),
            extension: ".png",
            amo: null as AllMightyObserver
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        image_url() {
            if (this.external_image_url != null) {
                return this.external_image_url
            } else {
                return `data:image/png;base64,${this.image.props.content}`
            }
        },
        cropped_container_id() {
            return `cropper_container_${this.instance_id}`
        },
        forbidden_characters() {
            return ['.', ' ', '\t', '/', `'`, '"', "*", "\\", "<", ">", ":", "|", "?"]
        },
        image_folder_path() {
            const active_record = TestaTree.Tree.get_project_tree().get_active_record()
            if (active_record instanceof Image) {
                return active_record.props.image_folder_path
            } else if (active_record instanceof ImageFolder) {
                return active_record.props.path
            } else {
                return null
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const $image = $(this.$refs.the_ss)
        $image.cropper({
            viewMode: 0,
            zoomable: true,
            zoomOnTouch: true,
            guides: true,
            autoCropArea: 0.3,
            responsive: true,
            ready: () => {
                const container = this.$refs.container as HTMLElement
                this.cropper = $image.data("cropper") as Cropper
                this.attach_contextmenu()
                this.amo = AllMightyObserver.new({
                        element_visible: true,
                        target_element: this.$refs.cropper_container as HTMLElement,
                        callback: () => this.cropper.reset()
                    },
                    {
                        element_after_resize: true,
                        target_element: container,
                        callback: () => this.cropper.onResize()
                    })
            }
        });

        if (this.tab != null) this.tab.set_editor_mounted(true);
    },
    unmounted() {
        this.amo?.stop()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save_cropped() {
            const name = `${this.image_name}${this.extension}`
            const params = {
                content: this.cropper.getCroppedCanvas().toDataURL("image/png"),
                name,
                project_version_id: this.project_version_id,
                image_folder_path: this.image_folder_path,
            }
            Image.ClientClass
                 .save(params)
                 .then((data) => {
                     if (data.saved) {
                         this.on_successful_upload()
                     } else {
                         if (data.ask_overwrite_confirmation) {
                             ConfirmDialogue.show({
                                                html: `<span>Image with name <strong>${name}</strong> already exists!</span>`,
                                                confirm_text: "Overwrite",
                                                confirm_color_class: "red",
                                            })
                                            .then((is_confirmed: Boolean) => {
                                                if (is_confirmed) {
                                                    Image.ClientClass
                                                         .save(params, true)
                                                         .then(data => data.saved ? this.on_successful_upload() : '')
                                                         .catch((error) => this.on_error_upload(error))
                                                }
                                            })
                         }
                     }
                 })
                 .catch((error) => this.on_error_upload(error))
        },
        on_successful_upload() {
            this.successfully_uploaded = true
            setTimeout(() => {
                this.successfully_uploaded = false
            }, 50000);
            copy_text_to_clipboard(this.image_name);
        },
        on_error_upload(error: any) {
            this.failed_to_upload = true
            setTimeout(() => {
                this.failed_to_upload = false
            }, 50000);
            console.log('Err Response:' + error.responseText + ".\nStatus:" + status + ".\nError:" + error);
        },
        attach_contextmenu() {
            $.contextMenu({
                selector: `#${this.cropped_container_id}`,
                zIndex: 10,
                events: {
                    show(opts: ContextMenu.Options): boolean | void {
                        opts.$menu.find(".context-menu-item-name").css("white-space", "nowrap")
                    }
                },
                build: (_$trigger: JQuery, _e: JQuery.MouseDownEvent) => {
                    const items: ContextMenu.Items = {}
                    items.center = {
                        name: "Reset",
                        icon: "fa-solid fa-arrows-rotate",
                        color: get_css_var("--button-white"),
                        callback: () => {
                            this.cropper.reset()
                        }
                    }

                    items.toggle_move = {
                        name: "Toggle move",
                        icon: "fa-solid fa-up-down-left-right",
                        color: get_css_var("--button-white"),
                        key: `double-click`,
                        callback: () => {
                            this.toggle_drag_mode()
                        }
                    }

                    return {
                        callback: function() {
                        },
                        items
                    }
                }
            })
        },
        toggle_drag_mode() {
            if (this.crop_mode_enabled) {
                this.enable_drag_mode_move()
            } else {
                this.enable_drag_mode_crop()
            }
        },
        enable_drag_mode_move() {
            this.cropper.setDragMode("move")
            this.crop_mode_enabled = false
        },
        enable_drag_mode_crop() {
            this.cropper.setDragMode("crop")
            this.crop_mode_enabled = true
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.image-capture-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .images-controls-container {
    padding: 10px;
    height: 40px;

    .images-controls {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      align-items: baseline;

      .image-folder-path {
        color: var(--font-color-secondary);
        margin-inline: 4px;
        white-space: nowrap;
        font-size: 0.9em;
      }

      .image-extension {
        color: var(--font-color-secondary);
        margin-inline: 4px
      }
    }
  }

  .images-upload-notification {
    cursor: pointer;
    font-size: 1.4em;
    padding-top: 5px;
    padding-left: 10px;
  }

  .images-screenshot {
    height: calc(100% - 40px);
    width: 100%;
    text-align: center;
  }
}
</style>
