import { FlexAreaOpts } from "./flex_area";
import { FlexArea } from "./flex_area";
import { reactive } from "vue";
import { ResizableFlex } from "../resizable_flex";
import { computed } from "../../../../../helpers/vue/computed";
import { CSSProperties } from "vue";
import { FlexContentArea } from "./flex_content_area";

export interface FlexStickAreaOpts extends FlexAreaOpts {
}

export class FlexStickArea extends FlexArea {
    // <editor-fold desc="PROPERTIES">
    private is_hovered_over = false

    // <editor-fold desc="COMPUTED">
    enabled_before_area: FlexContentArea
    enabled_after_area: FlexContentArea
    // </editor-fold>
    // </editor-fold>

    static new(opts: FlexStickAreaOpts, resizable_flex: ResizableFlex): FlexStickArea {
        const flex_area = reactive(new this(opts, true, resizable_flex))
        flex_area.init(opts)
        return flex_area as FlexStickArea
    }

    init(opts: FlexStickAreaOpts) {
        super.init(opts);

        this.width = computed(() => {
            if (this.resizable_flex.is_row_direction()) {
                return this.resizable_flex.get_stick_size()
            }
            return null
        })

        this.height = computed(() => {
            if (this.resizable_flex.is_row_direction()) return null
            return this.resizable_flex.get_stick_size()
        })

        this.style = computed(() => {
            const style: CSSProperties = {}
            if (this.resizable_flex.is_row_direction()) {
                style.width = `${this.width}px`
            } else {
                style.height = `${this.height}px`
            }

            if (this.is_hovered_over ||
                this.id == this.resizable_flex.get_grabbed_stick()?.id ||
                this.resizable_flex
                    .get_enabled_areas()
                    .map(a => a.get_grabbable_stick()?.id)
                    .some(id => id == this.id)
            ) {
                style.background = this.resizable_flex.get_stick_hover_color()
            }

            return style
        })
    }

    handle_on_mousedown(e: MouseEvent) {
        e.stopImmediatePropagation();
        e.preventDefault();
        this.resizable_flex.set_grabbed_stick(this)
        this.resizable_flex.start_resizing()
    }


    handle_on_mouseenter(e: MouseEvent) {
        this.is_hovered_over = true
    }

    handle_on_mouseleave(e: MouseEvent) {
        this.is_hovered_over = false
    }
}
