export function file_upload(opts: JQuery.FileUploadOpts, accept_ext = ".*", multiple = false) {
    let $file_upload: JQuery
    if (multiple) {
        $file_upload = $('#import_resources_input')
        $("#import_resources_form").attr('action', opts.url);
    } else {
        $("#import_resource_form").attr('action', opts.url);
        $file_upload = $('#import_resource_input')
    }
    $file_upload.attr("accept", accept_ext)

    if (opts.formData == null) {
        opts.formData = {}
    }
    if (opts.formData.authenticity_token == null) {
        opts.formData.authenticity_token = authenticity_token
    }

    // rely on the url set in html element attribute
    // this is done because of automation, since selenium cannot interact with file picker
    delete opts.url

    if (opts.always) {
        const original_always = opts.always
        opts.always = (e, data) => {
            $file_upload.val('');
            original_always(e, data)
        }
    } else {
        opts.always = () => {
            $file_upload.val('');
        }
    }

    $file_upload.fileupload(opts);
    $file_upload[0].click();
}
