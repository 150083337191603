/** Listens for DOMContentLoaded event if the dom is still loading.
 * If It is done loading, it will execute the callback immediately
 * @param callback
 */
export function on_dom_content_loaded(callback: () => void) {
    if (IS_WEB_WORKER || document.readyState !== "loading") {
        callback();
    } else {
        const wrapper = () => {
            try {
                callback()
            } catch (e) {
                console.error(e)
            } finally {
                document.removeEventListener("DOMContentLoaded", wrapper)
            }
        }
        document.addEventListener("DOMContentLoaded", wrapper);
    }
}

