import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ToggleIcon = _resolveComponent("ToggleIcon")
  const _component_ActionIcon = _resolveComponent("ActionIcon")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ToggleIcon, {
      modelValue: _ctx.Play.state.filter_shown,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.Play.state.filter_shown) = $event)),
      enabled_icon_class: "fa-solid fa-filter",
      enabled_color_class: "white",
      disabled_color_class: "grey",
      title: _ctx.Play.state.filter_shown ? 'Click to hide filters' : 'Click to show filters',
      no_click_focus: true,
      scale: 1
    }, null, 8 /* PROPS */, ["modelValue", "title"]),
    (_ctx.Play.state.filter_result_state.last_response_failed)
      ? (_openBlock(), _createBlock(_component_ActionIcon, {
          key: 0,
          icon_class: "fa-solid fa-arrow-rotate-right",
          color_class: "blue",
          title: "Retry",
          no_click_focus: true,
          scale: 1.1,
          allow_concurrent_click_action: false,
          onClick: _ctx.Play.ClientClass.load_filtered
        }, null, 8 /* PROPS */, ["onClick"]))
      : _createCommentVNode("v-if", true),
    (_ctx.Play.state.filter_label != null)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "play-filter-label",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.Play.state.filter_shown = !_ctx.Play.state.filter_shown))
        }, _toDisplayString(_ctx.Play.state.filter_label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_ctx.Play.state.filter_label != null)
      ? (_openBlock(), _createBlock(_component_ActionIcon, {
          key: 2,
          icon_class: "fa-solid fa-xmark",
          color_class: "red",
          title: "Clear filters",
          onClick: _ctx.Play.clear_filters
        }, null, 8 /* PROPS */, ["onClick"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}