<template>
  <Modal
      ref="modal"
      :dismissible="false"
      :show_cancel="false"
      :show_confirm="false"
      :is_loading="is_loading"
      :backdrop_dim_level="35"
  >
    <template #header>
      <div>Sign In</div>
    </template>
    <template #body>
      <div class="container">
        <div class="alternative-sign-in-container">
          <form class="alternative-sign-in">
            <Input
                id="sign_in_email"
                v-model="email"
                label="Email"
                autofocus="autofocus"
                autocomplete="email"
                :scale="1.1"
                @enter="do_login()"
            />
            <Input
                id="sign_in_password"
                v-model="password"
                label="Password"
                type="password"
                autofocus="autofocus"
                autocomplete="off"
                :scale="1.1"
                @enter="do_login()"
            />
            <ActionIcon
                icon_class="fa-solid fa-right-to-bracket"
                :scale="1.6"
                margin="5px 0 0 0"
                title="Sign In"
                @click="do_login()"
            />
          </form>
          <div class="divider">
            <div class="bar"></div>
            <div class="or">
              or
            </div>
            <div class="bar"></div>
          </div>
        </div>
        <div class="company-sign-in-container">
          <div class="company-container"
               @click="do_microsoft_login">
            <ActionIcon
                icon_class="fa-brands fa-microsoft"
                :scale="3"
                title="Sign In With Cognito"
                @click="do_microsoft_login"
            />
            <div class="name">
              Microsoft 365
            </div>
          </div>

        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../testa/Modal.vue";
import ActionIcon from "../testa/ActionIcon.vue";
import Input from "../testa/Input.vue";
import { User } from "../../vue_record/models/user";

export default defineComponent({
    components: { Input, ActionIcon, Modal },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            alternative_login: false,
            email: "",
            password: "",
            is_loading: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        "current.user"() {
            if (current.user != null) this.cancel();
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        do_login() {
            this.is_loading = true
            const query_string = window.location.search;
            const url_params = new URLSearchParams(query_string);
            const project_id_string = url_params.get("project_id")
            const project_version_id_string = url_params.get("project_version_id")
            let project_id: number = null
            let project_version_id: number = null

            if (project_id_string != null) project_id = parseInt(project_id_string)
            if (project_version_id_string != null) project_version_id = parseInt(project_version_id_string)

            User.ClientClass.sign_in({
                email: this.email,
                password: this.password
            },
                project_id, project_version_id)
                .then(() => {
                    this.is_loading = false
                    this.cancel();
                })
                .catch(() => {
                    this.is_loading = false
                })
        },
        do_microsoft_login() {
            window.open("/core/cognito_login", "CognitoLogin", "menubar=1,resizable=1,width=500,height=500")
            // window.open("/users/auth/cognito_oauth2", "CognitoLogin", "menubar=1,resizable=1,width=500,height=500")
        },
        cancel() {
            this.$.appContext.app.unmount();
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  width: 600px;
  height: calc(100% - 1px);

  .alternative-sign-in-container {
    display: flex;
    flex-direction: row;
    width: 55%;
    height: fit-content;

    .alternative-sign-in{
      display: flex;
      padding: 10px 20px 20px 20px;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .divider {
      margin-left: 10px;
      height: auto;
      margin-bottom: 30px;
      min-width: 2px;
      min-height: 100%;
      width: 2px;
      display: flex;
      flex-direction: column;

      .bar {
        height: 40%;
        min-width: 2px;
        width: 2px;
        background: var(--ternary-background-color);
      }
      .or {
        margin-block: 10px;
        margin-left: -5px;
      }
    }
  }

  .company-sign-in-container {
    display: flex;
    flex-direction: row;
    width: 45%;
    justify-content: center;
    align-items: start;
    height: 100%;

    .company-container {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .name {
        font-size: 0.8em;
      }

      &:hover {
        .name {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
