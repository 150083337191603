export function delayed_debounce<T extends(...args: any) => any>(func: T, wait: number): T {
    let timeout: NodeJS.Timeout;

    return function(...args: any): any {
        // @ts-ignore
        const context = this
        const later = function() {
            timeout = null;
            func.apply(context, args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    } as T
}
