<template>
  <div ref="container"
       class="file-editor-container no-padded-scrollbar">
    <textarea ref="textarea"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { File } from "../../../../../vue_record/models/file";
import { PropType } from "vue";
import { generate_uuid } from "../../../../../helpers/generate/generate_uuid";
import { init_codemirror } from "../../../../../helpers/codemirror/init_codemirror";
import { font_resize_on_wheel, storager_file_editor_font_size_key } from "../../../../../helpers/codemirror/font_resize_on_wheel";
import { color_changed_lines } from "../../../../../helpers/codemirror/color_changed_lines";
import { highlight_merged_code } from "../../../../../helpers/codemirror/highlight_merged_code";
import { attach_codemirror_contextmenu } from "../../../../../helpers/codemirror/attach_codemirror_contextmenu";
import { debouce_changes } from "../../../../../helpers/codemirror/debounced_changes";
import { markRaw } from "vue";
import { track_local_changes } from "../../../../../helpers/codemirror/track_local_changes";
import { FileEditorActionItem } from "../../../../../vue_record/models/file";
import { FileEditorFeature } from "../../../../../vue_record/models/file";
import { watch_style_changes } from "../../../../../helpers/codemirror/watch_style_changes";
import { FileTab } from "../../tabs/file_tab";
import { track_not_committed_file } from "../../../../../helpers/codemirror/track_not_committed";
import { get_cm_mode } from "../../../../../helpers/codemirror/helpers/get_cm_mode";
import { is_lint_supported } from "../../../../../helpers/codemirror/helpers/is_lint_supported";
import { EnumUserRole } from "../../../../../auto_generated/enums";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";
import { storager_snippet_editor_font_size_key } from "../../../../../helpers/codemirror/font_resize_on_wheel";
import { set_font_size } from "../../../../../helpers/codemirror/helpers/set_font_size";

export default defineComponent({
    components: { },
    cm: {} as { [key: string]: CodeMirror.Editor },
    // <editor-fold desc="PROPS">
    props: {
        file: {
            type: Object as PropType<File>,
            required: true
        },
        tab: {
            type: Object as PropType<FileTab>,
            required: false,
            default: null
        },
        exclude_features: {
            type: Array as PropType<FileEditorFeature[]>,
            required: false,
            default: () => {
                return [] as FileEditorFeature[]
            }
        },
        exclude_actions: {
            type: Array as PropType<FileEditorActionItem[]>,
            required: false,
            default: () => {
                return [] as FileEditorActionItem[]
            }
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            editor: undefined,
            component_id: generate_uuid(),
            amo: null as AllMightyObserver,
            style_watcher_stop_handler: null as Function,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        project_version() {
            return this.file.project_version
        },
        project() {
            return this.project_version.project
        },
        role(): EnumUserRole {
            return this.file.computed.role
        },
        is_viewer() {
            return this.file.computed.role_is_viewer
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'current.theme'() {
            this.cm().setOption("theme", this.current.theme)
        },
        'is_viewer'() {
            this.cm().setOption("readOnly", this.is_viewer)
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const textarea = this.$refs.textarea as HTMLTextAreaElement
        let content = this.file.state.content_not_committed
        if (content == null) content = ""
        textarea.innerHTML = content;
        let doc = null;
        const cms = this.file.state.codemirrors
        if (cms.length > 0 && !this.exclude_features.includes("doc_link")) {
            doc = cms[0].getDoc().linkedDoc({ sharedHist: true });
        }
        const mode = get_cm_mode(this.file.props.mediatype, this.file.props.subtype)
        const lint = is_lint_supported(mode)
        const gutters = []
        if (lint) gutters.push("CodeMirror-lint-markers")
        const options = {
            readOnly: this.file.computed.role_is_viewer,
            mode,
            lint,
            gutters,
        }
        const cm = init_codemirror(textarea, this.project, options)
        if (doc != null) cm.swapDoc(doc)

        this.$options.cm[this.component_id] = cm
        cm.setSize('100%', '100%')
        this.file.state.codemirrors.push(markRaw(cm))

        track_not_committed_file(cm, this.file)
        track_local_changes(cm, this.file)
        this.style_watcher_stop_handler = watch_style_changes(cm)

        const font_size = current.user.storager.get(storager_file_editor_font_size_key, null)
        if (font_size != null) set_font_size(cm, font_size)
        font_resize_on_wheel(cm, storager_file_editor_font_size_key, () => this.file.state.codemirrors)

        color_changed_lines(cm, ["merge"])
        highlight_merged_code(cm)

        if (!this.exclude_features.includes("contextmenu")) {
            attach_codemirror_contextmenu(cm, (cm, e) => {
                return this.file._editor_contextmenu(cm, e)
            })
        }

        if (!this.exclude_features.includes("debounced_save")) {
            // after every changes event, capture current code and history
            // then set timeout to save that code and history after the delay
            // NOTE: capturing code and history after delay might be too late if user closes codemirror or tab
            debouce_changes(cm, 1000, () => {
                    const props = this.file._extract_codemirror_props(cm);
                    return () => {
                        this.file._save_codemirror_content(props)
                    }
                },
                (timeout) => {
                    this.file.state.autosave = timeout
                },
                ["setValue", "merge"]
            )
        }

        if (!this.exclude_features.includes("keymap")) {
            cm.addKeyMap(this.file._editor_keymap(cm))
        }

        if (this.tab != null) {
            this.tab.state.cm = cm;
            this.tab.set_editor_mounted(true);
        }
        this.amo = AllMightyObserver.new(
            {
                element_visible: true,
                target_element: this.$refs.container as HTMLElement,
                callback: () => cm.refresh()
            }
        )
    },
    unmounted() {
        this.file.state.codemirrors = this.file.state.codemirrors.filter(cm => cm != this.cm())
        this.amo?.stop()
        if (this.style_watcher_stop_handler != null) this.style_watcher_stop_handler()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        cm() {
            return this.$options.cm[this.component_id]
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss">
.cm-searching::selection {
  background-color: var(--button-blue) !important;
  color: pink !important;
}
.codemirror-syntax-error {
  text-decoration: underline;
  text-decoration-color: var(--button-red);
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;
}

.codemirror-offense {
  text-decoration: underline;
  text-decoration-color: gray;
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;

}

.offense_tooltip {
  z-index: 999;
  position: absolute;
  display: inline-block;
  min-width: 13em;
  /*max-width: 26em;*/
  margin: 8px;
  padding: 8px;
  font-family: inherit;
  font-size: 12px;
  list-style-type: none;
  background-color: var(--secondary-background-color);
  border: 1px solid var(--border-contrast-color);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
}

.CodeMirror-hints {
  background-color: var(--primary-background-color) !important;
}


.merged-code-background {
  background: var(--merged-code-background-color);
  border-radius: 2px;
  animation: merged-code-background 1s 1;
  -webkit-animation: merged-code-background 1s 1;
  animation-fill-mode: forwards;

  animation-delay: 1s;
  -webkit-animation-delay: 1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}

@keyframes merged-code-background {
  from {
    background: var(--merged-code-background-color);
  }
  to {
    background: transparent;
  }
}

@-webkit-keyframes merged-code-background {
  from {
    background: var(--merged-code-background-color);
  }
  to {
    background: transparent;
  }
}



.jump-to-highlight-background {
  background: var(--jump-to-highlight-background-color);
  border-radius: 2px;
  animation: jump-to-highlight-background 1s 1;
  -webkit-animation: jump-to-highlight-background 1s 1;
  animation-fill-mode: forwards;

  animation-delay: 1s;
  -webkit-animation-delay: 1s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}
@keyframes jump-to-highlight-background {
  from {
    background: var(--jump-to-highlight-background-color);
  }
  to {
    background: transparent;
  }
}

@-webkit-keyframes jump-to-highlight-background {
  from {
    background: var(--jump-to-highlight-background-color);
  }
  to {
    background: transparent;
  }
}


.offense-gutter {
  color: yellow;
  font-size: 0.5em;
  cursor: pointer;
  padding-left: 0;
  margin-top: 0.8em;
  opacity: 0.3;
}

.offense-context-menu {
  font-size: 0.75em;

  .context-menu-list {
    font-size: 0.9em;
  }

  .context-menu-key {
    width: auto;
    padding-left: 5px;
    color: var(--button-green);
  }
}
</style>

<style lang="scss" scoped>
.file-editor-container {
  position: relative;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    right: 6px; // width of scrollbar
    top: 3px;
    z-index: 5;
  }
}
</style>
