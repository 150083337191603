<template>
  <template v-if="devices_tree.computed.children.length == 0">
    <div class="row"
         style="margin-top: 10px;">
      <div class="col s12 vertical align-center">
        <div class="row">
          <div class="col s12 center vertical align-center">
            <div>No available devices</div>
            <div v-if="!Phone.state.sidebar_show_offline && offline_count > 0">
              ({{ offline_count }} device{{ offline_count > 1 ? 's are' : ' is' }} offline)
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 center">
            <Button
                text="Add device"
                icon_class="fa-solid fa-plus"
                @click="Phone.show_add_to_project()"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <Tree
        :tree="devices_tree"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Tree from "../../../tree/Tree.vue";
import { TestaTree } from "../../../tree/tree";
import Button from "../../../Button.vue";
import { Phone } from "../../../../../vue_record/models/phone";
import { PhoneProject } from "../../../../../vue_record/models/phone_project";

export default defineComponent({
    components: { Button, Tree },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Phone() {
            return Phone
        },
        devices_tree() {
            return TestaTree.Tree.get_devices_tree(current.project_version)
        },
        offline_count() {
            return PhoneProject.where({ project_id: current.project_version?.props?.project_id })
                               .phones
                               .where({ status: Enum.Phone.Status.OFFLINE })
                               .count
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
