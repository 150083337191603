import { VueRecord } from "../base/vue_record";

export {}


require("./mobile_provisionings/device_relations")

require("./play/bridges/play_groups_play_scenarios_relations")
require("./play/bridges/play_scenarios_play_worker_groups_relations")

require("./play/play_relations")
require("./play/play_setting_relations")
require("./play/play_group_relations")
require("./play/play_worker_group_relations")
require("./play/play_log_relations")
require("./play/play_sandbox_relations")
require("./play/play_snippet_relations")
require("./play/play_scenario_relations")

require("./scenario_setting/phone_relations")
require("./scenario_setting/browser_relations")
require("./scenario_setting/phone_app_relations")

require("./app_relations")
require("./browser_driver_relations")
require("./browser_relations")
require("./delayed_job_relations")
require("./feature_flag_relations")
require("./file_relations")
require("./file_folder_relations")
require("./group_relations")
require("./group_folder_relations")
require("./group_savepoint_relations")
require("./groups_scenario_relations")
require("./image_relations")
require("./image_folder_relations")
require("./mobile_provisioning_relations")
require("./phone_relations")
require("./phonector_relations")
require("./phone_project_relations")
require("./project_relations");
require("./project_version_relations");
require("./project_version_setting_relations");
require("./proxy_relations");
require("./schedule_relations")
require("./scenario_history_relations")
require("./scenario_node_relations")
require("./scenario_relations");
require("./scenario_folder_relations");
require("./scenario_folder_savepoint_relations");
require("./scenario_savepoint_relations");
require("./scenario_setting_relations")
require("./snippet_relations");
require("./snippet_folder_relations");
require("./snippet_savepoint_relations");
require("./user_relations");
require("./user_project_relations");
require("./user_setting_relations")
require("./variable_set_relations")

require("./non_db/log_file_folder_relations")
require("./non_db/log_file_relations")

VueRecord._on_relations_established()
VueRecord.relations_established = true
global_event_bus.$emit("relations_established")
