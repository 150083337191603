import { RubocopOffense } from "../../code_lint/offense";
import _ from "lodash";
import { init_offense_contextmenu } from "./context_menu";
import { watch } from "vue";
import { nextTick } from "vue";
import { Snippet } from "../../../vue_record/models/snippet";

export function add_offenses(cm: CodeMirror.Editor, offenses: RubocopOffense[], snippet_id: number) {
    cm.clearGutter('offenses');
    if (!offenses) return;

    // underline in editor
    for (let i = 0; i < offenses.length; i++) {
        const offense = offenses[i];
        const error_on_line = offense.error_on_line;
        const error_on_char = offense.error_on_char;
        cm.markText({ line: error_on_line - 1, ch: error_on_char - 1 }, {
            line: error_on_line - 1,
            ch: (error_on_char)
        }, { className: "codemirror-offense", shared: true });
    }
    // need to group by line because of multiple offenses in line
    const offenses_by_line = _.groupBy(offenses, offense => offense.error_on_line);

    for (const line_nr in offenses_by_line) {
        const offs = offenses_by_line[line_nr];
        const marker = document.createElement("i");
        marker.setAttribute('class', 'fas fa-exclamation-triangle offense-gutter');

        const $marker = $(marker)
        $marker.data("offenses", offs)
        $marker.on("click", function(e) {
            $.contextMenu("destroy", ".offense-gutter:visible");
            init_offense_contextmenu(e.pageX, e.pageY + 2, offs, cm, snippet_id)
            $marker.trigger("contextmenu");
        })
        cm.setGutterMarker(parseInt(line_nr) - 1, "offenses", marker);
    }
}


export function add_snippet_offenses(cm: CodeMirror.Editor, snippet: Snippet) {
    return watch(() => snippet.props.offenses, () => {
        cm.clearGutter('offenses');
        cm.getDoc().getAllMarks().forEach(mark => {
            if (mark.className == "codemirror-offense") mark.clear()
        })
        nextTick(() => {
            add_offenses(cm, trim_offenses(snippet.props.offenses), snippet.key())
        })
    }, { immediate: true, deep: true })
}

/**
 * when dealing with huge lines, show only first 3 offenses of the same class for each line
 * @param offenses
 */
function trim_offenses(offenses: RubocopOffense[]) {
    if (offenses == null) return

    const line_counter: Record<number, Record<string, number>> = {}
    return offenses.filter(offense => {
        if (line_counter[offense.error_on_line] == null) line_counter[offense.error_on_line] = {}

        if (line_counter[offense.error_on_line][offense.cop_name] == null) line_counter[offense.error_on_line][offense.cop_name] = 1
        else ++line_counter[offense.error_on_line][offense.cop_name]
        return line_counter[offense.error_on_line][offense.cop_name] <= 3
    })
}
