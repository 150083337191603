<template>
  <SettingsItem
      id="feature_flag_edit"
      :title="`Edit ${feature_flag.props.name}`">
    <div class="feature-flag-edit">
      <div class="header">
        <Button
            id="feature_flag_back"
            text="Back"
            @click="back"
        />
        <Button
            id="feature_flag_save"
            text="Save"
            :form_validator="form_validator"
            :click_action="save"
        />
        <div class="flex-expander"/>
        <Button
            id="feature_flag_delete"
            text="Delete"
            color_class="red"
            @click="delete_feature_flag"
        />
      </div>
      <div class="body">
        <div class="row">
          <div class="col s12">
            <Input
                id="feature_flag_name"
                v-model="name"
                label="Name"
                :disabled="true"/>
          </div>
        </div>
        <div class="row">
          <div class="col s6">
            <Textarea
                id="feature_flag_description"
                v-model="description"
                label="Description"/>
          </div>
          <div class="col s6">
            <Checkbox
                id="feature_flag_enabled"
                v-model="enabled"
                label="Enabled"/>
          </div>
        </div>

        <div class="row"
             style="margin-top: 60px;">
          <div v-if="!enabled"
               class="col s12">
            <span style="margin-left: 8px">
              Enable for
            </span>
          </div>
          <div v-else
               class="col s12">
            <span style="margin-left: 8px">
              Disable for
            </span>
          </div>
        </div>

        <div class="row">
          <div v-if="!enabled"
               class="col s12">
            <Select2
                id="feature_flag_enabled_for_projects"
                v-model="enabled_for_projects"
                label="Projects"
                :multiple="true"
                dropdown_parent="#vue_settings_modal"
            >
              <option v-for="project in projects"
                      :key="project.props.id"
                      :value="project.props.id">
                {{ project.props.full_name }}
              </option>
            </Select2>
          </div>
          <div v-else
               class="col s12">
            <Select2
                id="feature_flag_disabled_for_projects"
                v-model="disabled_for_projects"
                label="Projects"
                :multiple="true"
                dropdown_parent="#vue_settings_modal"
            >
              <option v-for="project in projects"
                      :key="project.props.id"
                      :value="project.props.id">
                {{ project.props.full_name }}
              </option>
            </Select2>
          </div>
        </div>

        <div class="row">
          <div v-if="!enabled"
               class="col s12">
            <Select2
                id="feature_flag_enabled_for_project_versions"
                v-model="enabled_for_project_versions"
                label="Project versions"
                :multiple="true"
                dropdown_parent="#vue_settings_modal"
            >
              <option v-for="project_version in project_versions"
                      :key="project_version.props.id"
                      :value="project_version.props.id">
                {{ project_version.props.version }}
              </option>
            </Select2>
          </div>
          <div v-else
               class="col s12">
            <Select2
                id="feature_flag_disabled_for_project_versions"
                v-model="disabled_for_project_versions"
                label="Project versions"
                :multiple="true"
                dropdown_parent="#vue_settings_modal"
            >
              <option v-for="project_version in project_versions"
                      :key="project_version.props.id"
                      :value="project_version.props.id">
                {{ project_version.props.version }}
              </option>
            </Select2>
          </div>
        </div>

        <div class="row">
          <div v-if="!enabled"
               class="col s12">
            <Select2
                id="feature_flag_enabled_for_users"
                v-model="enabled_for_user"
                label="Users"
                :multiple="true"
                dropdown_parent="#vue_settings_modal"
            >
              <option v-for="user in users"
                      :key="user.props.id"
                      :value="user.props.id">
                {{ user.props.username }} - {{ user.props.email }}
              </option>
            </Select2>
          </div>
          <div v-else
               class="col s12">
            <Select2
                id="feature_flag_disabled_for_users"
                v-model="disabled_for_user"
                label="Users"
                :multiple="true"
                dropdown_parent="#vue_settings_modal"
            >
              <option v-for="user in users"
                      :key="user.props.id"
                      :value="user.props.id">
                {{ user.props.username }} - {{ user.props.email }}
              </option>
            </Select2>
          </div>
        </div>

      </div>
    </div>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { FeatureFlag } from "../../../../vue_record/models/feature_flag";
import Button from "../../../testa/Button.vue";
import Input from "../../../testa/Input.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Select2 from "../../../testa/Select2.vue";
import { Project } from "../../../../vue_record/models/project";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import { User } from "../../../../vue_record/models/user";
import SettingsItem from "../../SettingsItem.vue";
import toastr from "toastr";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import Textarea from "../../../testa/Textarea.vue";

export default defineComponent({
    components: {
        SettingsItem,
        Select2,
        Textarea,
        Checkbox,
        Input,
        Button,
    },
    props: {
        feature_flag: {
            type: Object as PropType<FeatureFlag>,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        }
    },
    emits: ['exit', 'setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            projects: Project.toArray(),
            project_versions: ProjectVersion.toArray(),
            users: User.toArray(),
            name: this.feature_flag.props.name,
            description: this.feature_flag.props.description == null ? "" : this.feature_flag.props.description,
            enabled: this.feature_flag.props.enabled,
            enabled_for_projects: this.feature_flag.props.enabled_for.project == null ? [] : this.feature_flag.props.enabled_for.project,
            enabled_for_project_versions: this.feature_flag.props.enabled_for.project_version == null ? [] : this.feature_flag.props.enabled_for.project_version,
            enabled_for_user: this.feature_flag.props.enabled_for.user == null ? [] : this.feature_flag.props.enabled_for.user,
            disabled_for_projects: this.feature_flag.props.disabled_for.project == null ? [] : this.feature_flag.props.disabled_for.project,
            disabled_for_project_versions: this.feature_flag.props.disabled_for.project_version == null ? [] : this.feature_flag.props.disabled_for.project_version,
            disabled_for_user: this.feature_flag.props.disabled_for.user == null ? [] : this.feature_flag.props.disabled_for.user,
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
        this.form_validator.unregister()
    },
    methods: {
        save() {
            const promise = this.apply()
            promise.then(() => this.back())
            return promise
        },
        apply() {
            const enabled_for = {
                project: this.enabled_for_projects,
                project_version: this.enabled_for_project_versions,
                user: this.enabled_for_user,
            }
            const disabled_for = {
                project: this.disabled_for_projects,
                project_version: this.disabled_for_project_versions,
                user: this.disabled_for_user,
            }

            return this.feature_flag.client.update({
                description: this.description,
                enabled: this.enabled,
                enabled_for,
                disabled_for
            }).catch(() => toastr.error("Failed to save feature flag"))
        },
        delete_feature_flag() {
            FeatureFlag.delete(this.feature_flag.props.id)
                       .then((result: Boolean) => {
                           if (result) {
                               this.back();
                           }
                       })
        },
        back() {
            this.$emit('exit')
        },
    },
})
</script>

<style lang="scss" scoped>
.feature-flag-edit {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .body {
    margin-top: 10px;
  }
}

</style>
