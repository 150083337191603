import { PlayScenariosPlayWorkerGroups } from "../../../models/play/bridges/play_scenarios_play_worker_groups";
import { PlayScenario } from "../../../models/play/play_scenario";
import { PlayWorkerGroup } from "../../../models/play/play_worker_group";


declare module "../../../models/play/bridges/play_scenarios_play_worker_groups" {
    interface PlayScenariosPlayWorkerGroups {
        play_worker_group: PlayWorkerGroup
        play_scenario: PlayScenario
    }
}

PlayScenariosPlayWorkerGroups.belongs_to("play_worker_group", PlayWorkerGroup, "play_worker_group_id")
PlayScenariosPlayWorkerGroups.belongs_to("play_scenario", PlayScenario, "play_scenario_id")
