<template>
  <div class="scope-container no-default-unfocus"
       :class="{selected}"
       tabindex="0"
       @keydown="on_keydown"
       @mousedown="(e) => e.preventDefault()"
       @click="select"
  >
    {{ name }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { KeyCode } from "../../../types/globals";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        name: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            required: true
        }
    },
    // </editor-fold>
    emits: ['select'],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_keydown(e: KeyboardEvent) {
            if (e.code == KeyCode.ENTER || e.code == KeyCode.SPACE) {
                this.select();
            }
        },
        select() {
            this.$emit("select")
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.scope-container {
  padding: 7px 10px;
  border-right: 1px solid var(--border-color);
  font-size: 1.1em;
  color: var(--font-color-secondary);
  cursor: pointer;

  &:hover,
  &.selected {
    color: var(--font-color);
    background-color: var(--ternary-background-color);
  }
}
</style>
