<template>
  <div ref="vue_label"
       class="vue-label color-picker"
       :class="{invalid: validator?.invalid}">
    <div ref="color_picker"
         class="color-picker-wrapper"
         :class="style"
         :style="{background:selectedColor ? selectedColor : ( validator?.invalid ? 'var(--button-red)' : '#dddddd')}"
         @click.stop="isOpen = !isOpen">
      <svg v-if="!value"
           class="color-picker-icon"
           stroke-width="1.5"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
           color="#000000">
        <path
            d="M13.879 7.697L16 9.817a1 1 0 010 1.415L8.363 18.87a1.001 1.001 0 01-.326.218L5.54 20.114c-1.233.508-2.466-.725-1.958-1.958L4.61 15.66a.999.999 0 01.218-.327l7.636-7.636a1 1 0 011.415 0zM13.879 3.454L16 5.575m4.243 4.243L18.12 7.697m-2.12-2.122l1.413-1.414a1 1 0 011.414 0l.708.707a1 1 0 010 1.414L18.12 7.697m-2.12-2.122l2.12 2.122"
            :stroke="validator?.invalid ? 'var(--font-color)' : '#000000'"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
      </svg>

      <div v-if="isOpen"
           class="color-picker-dropdown"
           @click.stop>
        <div ref="color_grid"
             class="color-picker-grid">
          <div v-for="color in colors"
               :key="color"
               class="color-picker-color"
               tabindex="0"
               :style="{background:color}"
               @click="selectedColor = color">
            <svg v-if="color === value"
                 class="color-picker-check"
                 stroke-width="1.5"
                 viewBox="0 0 24 24"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 color="#000000">
              <path d="M5 13l4 4L19 7"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div v-if="allow_blank && value !== ''"
             class="color-picker-remove"
             tabindex="0"
             @click="selectedColor = ''">
          {{ removeText }}
        </div>
      </div>
    </div>
    <span @click.stop="isOpen = !isOpen">
      {{ label }}
    </span>
    <ValidationErrors v-if="show_errors && validator?.invalid"
                      no_side="bottom"
                      :errors="validator.errors"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ClickEvent = JQuery.ClickEvent;
import ValidationErrors from "./ValidationErrors.vue";
import { PropType } from "vue";
import { Validator } from "../../helpers/validator/validator";

// https://github.com/carloscabo/jquery-palette-color-picker
export default defineComponent({
    components: { ValidationErrors },
    // <editor-fold desc="PROPS">
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        removeText: {
            type: String,
            default: 'Remove color',
        },
        style: {
            type: String,
            default: 'square',
        },
        colors: {
            type: Array,
            default: () => [
                '#3233ff', '#5151ff', '#6f70fe', '#9998fe', '#c3c2fe',
                '#fe0166', '#fe277d', '#fe4d95', '#ff80b5', '#feb3d1',
                '#e32a1d', '#f54830', '#ff6b55', '#ff9e8b', '#ffcabc',
                '#ffd148', '#ffd863', '#ffdf7b', '#ffe9a3', '#fff2c8',
                '#1e8a60', '#2db37e', '#47d49a', '#7fdfb9', '#bcefdc',
                '#080726', '#24303e', '#535b68', '#9ba2b4', '#c7cfda',
            ],
        },
        label: {
            type: String,
            required: false,
            default: ""
        },
        validator: {
            type: Object as PropType<Validator>,
            required: false,
            default: null,
        },
        allow_blank: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    // </editor-fold>
    emits: ['update:modelValue'],
    // <editor-fold desc="DATA">
    data() {
        return {
            isOpen: false,
            value: '',
            show_errors: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        selectedColor: {
            get() {
                return this.value;
            },
            set(newValue: string) {
                this.validator?.run(newValue);
                this.$emit('update:modelValue', newValue);
                this.isOpen = false;
                this.value = newValue;
            },
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        isOpen() {
            if (this.isOpen) {
                const $grid = $(this.$refs.color_grid as HTMLElement)
                $($grid.children()[0]).trigger('focus')

                this.show_errors = true
            } else {
                this.show_errors = false
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.value = this.modelValue;
        this.validator?.bind_element(() => { return this.$refs.vue_label as HTMLElement })
        this.validator?.bind_value(() => { return this.value })
        $(document).on("click", this.click_handler);
    },
    unmounted() {
        $(document).off("click", this.click_handler)
        this.validator?.unregister()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        close() {
            this.isOpen = false;
        },
        click_handler(e: ClickEvent) {
            const el = this.$refs.color_picker as HTMLElement
            if (!(el === e.target || el.contains(e.target))) {
                this.close();
            }
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
div.color-picker {
  align-items: center;

  &.invalid {
    span {
      color: var(--button-red)
    }
  }
  span {
    margin-top: 4px;
    margin-left: 3px;
    padding: 3px;
    cursor: pointer;
  }

  .color-picker-wrapper {
    width: 1.5em;
    height: 1.5em;
    position: relative;
    cursor: pointer;
    border: 1px solid #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;

    &.square {
      border-radius: 5px;
    }

    &.circle {
      border-radius: 50%;
    }
  }

  .color-picker-icon {

    margin: 2px;
  }

  .color-picker-dropdown {
    cursor: default;
    position: absolute;
    top: 1.8em;
    left: 0;
    background: var(--ternary-background-color);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--border-color-dark);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  .color-picker-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 3px;
  }

  .color-picker-color {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid var(--border-color-dark);

    &:hover,
    &:focus {
      border-color: var(--border-color-light);
    }
  }

  .color-picker-check {
    width: 18px;
    height: 18px;
    margin: 3px;
  }

  .color-picker-remove {
    font-family: sans-serif;
    font-size: 12px;
    color: var(--font-color);
    margin-top: 3px;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    background: var(--primary-background-color);

    &:hover,
    &:focus {
      filter: brightness(1.2);
    }
  }
}
</style>
