import { FlexArea } from "./area/flex_area";
import { FlexContentAreaOpts } from "./area/flex_content_area";
import { reactive } from "vue";
import { computed } from "../../../../helpers/vue/computed";
import { FlexContentArea } from "./area/flex_content_area";
import { FlexStickArea } from "./area/flex_stick_area";
import { Consoler } from "../../../../helpers/api_wrappers/consoler";
import { nextTick } from "vue";
import { AllMightyObserver } from "../../../../helpers/dom/all_mighty_observer";
import { WatchStopHandle } from "vue";
import { watch } from "vue";
import { Storager } from "../../../../helpers/api_wrappers/storager";

export type FlexDirection = "row" | "row-reverse" | "column" | "column-reverse"
export type ResizeBehaviour = "only_adjacent" | "regular" | "extra_rate_to_adjacent"

type StoredSizeRatio = {
    id: string
    size_ratio: number
    locked_px_size?: number
    locked_ratio_size?: number
}
type ResizeType = "rate" | "size" | "rate_times_size" | "equal"

export type ResizableFlexOpts = {
    id: string

    areas: FlexContentAreaOpts[]
    direction: FlexDirection

    /** size of the stick in px,
     * this area will get the stick color */
    stick_size?: number
    stick_color?: string
    /** the area that will be reserved for grabbing the stick.
     * this area consists of before, the actually stick size, and after area
     * the before area get the background color of the before content area, and the after area gets the color of after content
     */
    stick_grab_size?: number

    /**
     * when enabled, the flex will fill the container on both axis.
     * enabled by default.
     */
    fill_container?: boolean

    /** if enabled, sticks will highlight when hovered over */
    stick_hover_color?: string

    /**
     * if area is locked and this options is enabled, the area will have a outline
     * enabled by default
     */
    outline_locked?: boolean

    /**
     * when moving stick:
     *
     *  - only_adjacent: only adjacent areas will shrink / expand
     *  - regular: areas will resize bashed on their shrink_rate / grow_rate
     *  - extra_rate_to_adjacent: adjacent areas will gain shrink_rate / grow_rate [DEFAULT]
     */
    resize_behaviour?: ResizeBehaviour

    /**
     * provide a custom storager scope
     */
    storager?: Storager
}

const console = new Consoler("warn")

export class ResizableFlex {
    // <editor-fold desc="PROPERTIES">
    readonly id: string
    readonly storager_key: string
    private stick_size = 2
    private stick_grab_size = 10
    private initialized = false
    stick_color = "var(--secondary-background-color)"
    stick_hover_color = "var(--border-color)" // "var(--button-grey)" //
    outline_locked = true
    direction: FlexDirection
    resize_behaviour: ResizeBehaviour = "extra_rate_to_adjacent"
    fill_container = true

    areas: Record<string, FlexContentArea> = {}

    private width: number = 0
    private height: number = 0

    private is_resizing = false
    private grabbed_stick: FlexStickArea = null
    private container: HTMLElement
    private storager: Storager = current.storagers.user

    private _amo: AllMightyObserver
    private _watchers: WatchStopHandle[] = []
    private _resize_wrapper_depth = 0
    static _global_resize_wrapper_depth = 0
    private _after_resize_executing = false

    // <editor-fold desc="COMPUTED">
    private container_size: number = 0
    private area_stick_overflow: number
    private areas_array: FlexContentArea[]
    private enabled_areas: FlexContentArea[]
    private enabled_expandable_areas: FlexContentArea[]
    private enabled_shrinkable_areas: FlexContentArea[]
    private enabled_non_locked_areas: FlexContentArea[]
    private enabled_areas_with_sticks: FlexArea[]
    private stick_sum_size: number
    private areas_with_sticks_sum_size: number
    private areas_requested_min_px_size_sum: number
    private areas_with_sticks_requested_min_px_size_sum: number
    private excess_space: number
    private free_space: number

    private enabled_areas_before_grabbed_stick: FlexContentArea[]
    private enabled_area_before_grabbed_stick: FlexContentArea
    private enabled_expandable_areas_before_grabbed_stick: FlexContentArea[]
    private enabled_shrinkable_areas_before_grabbed_stick: FlexContentArea[]

    private enabled_areas_after_grabbed_stick: FlexContentArea[]
    private enabled_area_after_grabbed_stick: FlexContentArea
    private enabled_expandable_areas_after_grabbed_stick: FlexContentArea[]
    private enabled_shrinkable_areas_after_grabbed_stick: FlexContentArea[]
    // </editor-fold>
    // </editor-fold>

    constructor(opts: ResizableFlexOpts) {
        this.id = opts.id
        this.storager_key = `resizable_flex_sizes_${this.id} ==`
        this.direction = opts.direction

        if (opts.stick_size != null) this.stick_size = opts.stick_size
        if (opts.stick_color != null) this.stick_color = opts.stick_color
        if (opts.stick_grab_size != null) this.stick_grab_size = opts.stick_grab_size
        if (opts.resize_behaviour != null) this.resize_behaviour = opts.resize_behaviour
        if (opts.stick_hover_color != null) this.stick_hover_color = opts.stick_hover_color
        if (opts.outline_locked != null) this.outline_locked = opts.outline_locked
        if (opts.storager != null) this.storager = opts.storager
        if (opts.fill_container != null) this.fill_container = opts.fill_container
    }

    static new(opts: ResizableFlexOpts): ResizableFlex {
        const resizable = reactive(new this(opts))
        resizable.init(opts)
        return resizable as ResizableFlex
    }

    init(opts: ResizableFlexOpts) {
        opts.areas.forEach(area_input => {
            this.areas[area_input.id] = FlexContentArea.new(area_input, this)
        })

        // <editor-fold desc="COMPUTED">
        this.area_stick_overflow = computed(() => {
            return (Math.max(this.stick_size, this.stick_grab_size) - this.stick_size) / 2
        })
        this.areas_array = computed(() => Object.values(this.areas))
        this.enabled_areas = computed(() => this.areas_array.filter(a => a.enabled))
        this.enabled_non_locked_areas = computed(() => this.enabled_areas.filter(a => !a.get_is_locked()))
        this.enabled_expandable_areas = computed(() => this.enabled_areas.filter(a => a.get_is_expandable()))
        this.enabled_shrinkable_areas = computed(() => this.enabled_areas.filter(a => a.get_is_shrinkable()))

        this.enabled_areas_with_sticks = computed(() => {
            const areas: FlexArea[] = []
            this.enabled_areas.forEach((area, index) => {
                areas.push(area)
                if (index + 1 != this.enabled_areas.length) {
                    areas.push(FlexStickArea.new({
                        id: `__stick-${index}`,
                        background: this.stick_color
                    }, this))
                }
            })
            return areas
        })

        this.stick_sum_size = computed(() => Math.max(this.enabled_areas.length - 1, 0) * this.stick_size)

        this.container_size = computed(() => {
            if (this.is_row_direction()) return this.width
            else return this.height
        })

        this.areas_with_sticks_sum_size = computed(() => {
            return this.enabled_areas_with_sticks.map(a => a.get_size()).sum();
        })

        this.areas_requested_min_px_size_sum = computed(() => {
            return this.enabled_areas.map(a => a.get_requested_min_px_size()).sum()
        })
        this.areas_with_sticks_requested_min_px_size_sum = computed(() => {
            return this.areas_requested_min_px_size_sum + this.stick_sum_size
        })

        this.excess_space = computed(() => {
            const overflow = this.areas_with_sticks_sum_size - this.container_size
            if (overflow <= 0) return 0
            return overflow
        })

        this.free_space = computed(() => {
            const free = this.container_size - this.areas_with_sticks_sum_size
            if (free <= 0) return 0
            return free
        })

        this.enabled_areas_before_grabbed_stick = computed(() => {
            if (this.grabbed_stick == null) return []
            const areas = this.get_enabled_areas_before_stick(this.grabbed_stick)
            console.debug("computed enabled_areas_before_grabbed_stick")
            return areas
        })

        this.enabled_area_before_grabbed_stick = computed(() => {
            const n = this.enabled_areas_before_grabbed_stick.length
            if (n == 0) return null
            return this.enabled_areas_before_grabbed_stick[n - 1]
        })
        this.enabled_expandable_areas_before_grabbed_stick = computed(() => {
            return this.enabled_areas_before_grabbed_stick.filter(a => a.get_is_expandable())
        })
        this.enabled_shrinkable_areas_before_grabbed_stick = computed(() => {
            return this.enabled_areas_before_grabbed_stick.filter(a => a.get_is_shrinkable())
        })

        this.enabled_areas_after_grabbed_stick = computed(() => {
            if (this.grabbed_stick == null) return []

            const areas = this.get_enabled_areas_after_stick(this.grabbed_stick);
            console.debug("computing enabled_areas_after_grabbed_stick")
            return areas
        })
        this.enabled_area_after_grabbed_stick = computed(() => {
            const n = this.enabled_areas_after_grabbed_stick.length
            if (n == 0) return null
            return this.enabled_areas_after_grabbed_stick[0]
        })
        this.enabled_expandable_areas_after_grabbed_stick = computed(() => {
            return this.enabled_areas_after_grabbed_stick.filter(a => a.get_is_expandable())
        })
        this.enabled_shrinkable_areas_after_grabbed_stick = computed(() => {
            return this.enabled_areas_after_grabbed_stick.filter(a => a.get_is_shrinkable())
        })
        // </editor-fold>
    }

    set_stick_size(size: number) {
        this.stick_size = size
    }

    set_stick_color(color: string) {
        this.stick_color = color
    }

    resize_evenly() {
        const per_area = (this.container_size - this.stick_sum_size) / this.areas_array.length
        this._wrap_resize(() => {
            this.areas_array.each(a => a.set_size(per_area))
        })
    }

    // <editor-fold desc="HELPERS">
    is_row_direction() {
        return this.direction == "row" || this.direction == "row-reverse"
    }

    is_reverse() {
        return this.direction == "row-reverse" || this.direction == "column-reverse"
    }

    get_enabled_areas_before_stick(stick: FlexStickArea) {
        let stick_found = false
        return this.enabled_areas_with_sticks.filter(a => {
            if (a.is_stick) {
                if (!stick_found && a.id == stick.id) stick_found = true
                return false
            }
            if (this.is_reverse()) return stick_found
            else return !stick_found
        }) as FlexContentArea[]
    }

    get_enabled_areas_after_stick(stick: FlexStickArea) {
        let stick_found = false
        return this.enabled_areas_with_sticks.filter(a => {
            if (a.is_stick) {
                if (!stick_found && a.id == stick.id) stick_found = true
                return false
            }
            if (!this.is_reverse()) return stick_found
            else return !stick_found
        }) as FlexContentArea[]
    }

    // </editor-fold>

    // <editor-fold desc="GETTERS">
    get_width() {
        return this.width
    }

    get_height() {
        return this.height
    }

    get_grabbed_stick() {
        return this.grabbed_stick
    }

    get_stick_hover_color() {
        return this.stick_hover_color
    }

    get_container_size() {
        return this.container_size
    }

    get_enabled_area_before_grabbed_stick() {
        return this.enabled_area_before_grabbed_stick
    }

    get_enabled_area_after_grabbed_stick() {
        return this.enabled_area_after_grabbed_stick
    }

    get_enabled_areas_before_grabbed_stick() {
        return this.enabled_areas_before_grabbed_stick
    }

    get_enabled_areas_after_grabbed_stick() {
        return this.enabled_areas_after_grabbed_stick
    }

    get_areas_with_sticks_requested_min_px_size_sum() {
        return this.areas_with_sticks_requested_min_px_size_sum
    }

    get_enabled_areas() {
        return this.enabled_areas
    }

    get_stick_sum_size() {
        return this.stick_sum_size
    }

    get_stick_size() {
        return this.stick_size
    }

    get_stick_grab_size() {
        return this.stick_grab_size
    }

    get_enabled_areas_with_sticks() {
        return this.enabled_areas_with_sticks
    }

    get_resize_behaviour() {
        return this.resize_behaviour
    }

    get_area_stick_overflow() {
        return this.area_stick_overflow
    }
    // </editor-fold>

    // <editor-fold desc="SETTERS">
    set_grabbed_stick(stick: FlexStickArea) {
        this.grabbed_stick = stick
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    private bound_mousemove: (e: MouseEvent) => void

    handle_on_mousemove(e: MouseEvent) {
        if (!this.is_resizing) return;

        const offsets = this._cursor_offset_to_grabbed_stick(e)
        const delta = this.is_row_direction() ? offsets.x : offsets.y
        console.debug("on mouse move delta:", delta, "direction: ", this.direction)

        const resize = (delta: number, expandable: FlexContentArea[], shrinkable: FlexContentArea[]) => {
            delta = Math.abs(delta)
            const half_delta = delta / 2
            this._expand_areas_by(half_delta, expandable, [])
            this._shrink_areas_by(half_delta, shrinkable, [])
        }

        if (delta > 0) {
            // cursor is right or below the stick
            // areas before are expanding, areas after are shrinking
            let last_expandable_before_stick: FlexContentArea
            let first_shrinkable_after_stick: FlexContentArea
            switch (this.resize_behaviour) {
                case "only_adjacent":
                    last_expandable_before_stick = this.enabled_areas_before_grabbed_stick.filter(a => a.get_is_expandable()).last()
                    first_shrinkable_after_stick = this.enabled_areas_after_grabbed_stick.filter(a => a.get_is_shrinkable()).first()
                    if (last_expandable_before_stick != null && first_shrinkable_after_stick != null) {
                        resize(delta, [last_expandable_before_stick], [first_shrinkable_after_stick])
                    }
                    break;
                case "regular":
                    if (this.enabled_expandable_areas_before_grabbed_stick.length > 0 && this.enabled_shrinkable_areas_after_grabbed_stick.length > 0) {
                        resize(delta, this.enabled_expandable_areas_before_grabbed_stick, this.enabled_shrinkable_areas_after_grabbed_stick)
                    }
                    break;
                case "extra_rate_to_adjacent":
                    if (this.enabled_expandable_areas_before_grabbed_stick.length > 0 && this.enabled_shrinkable_areas_after_grabbed_stick.length > 0) {
                        resize(delta, this.enabled_expandable_areas_before_grabbed_stick, this.enabled_shrinkable_areas_after_grabbed_stick)
                    } else {
                        console.warn("rejecting resize, areas before should be expandable and areas after should be shrinkable",
                            "before: ", this.enabled_expandable_areas_before_grabbed_stick.map(a => a.log()),
                            "after: ", this.enabled_shrinkable_areas_after_grabbed_stick.map(a => a.log()),
                            "all: ", this.enabled_areas.map(a => a.log()))
                    }
                    break;
            }
        } else if (delta < 0) {
            let first_expandable_after_stick: FlexContentArea
            let last_shrinkable_before_stick: FlexContentArea

            // cursor is left or above the stick
            // areas before are shrinking, areas after are expanding
            switch (this.resize_behaviour) {
                case "only_adjacent":
                    last_shrinkable_before_stick = this.enabled_areas_before_grabbed_stick.filter(a => a.get_is_shrinkable()).last()
                    first_expandable_after_stick = this.enabled_areas_after_grabbed_stick.filter(a => a.get_is_expandable()).first()

                    if (last_shrinkable_before_stick != null && first_expandable_after_stick != null) {
                        resize(delta, [first_expandable_after_stick], [last_shrinkable_before_stick])
                    }
                    break;
                case "regular":
                    if (this.enabled_shrinkable_areas_before_grabbed_stick.length > 0 && this.enabled_expandable_areas_after_grabbed_stick.length > 0) {
                        resize(delta, this.enabled_expandable_areas_after_grabbed_stick, this.enabled_shrinkable_areas_before_grabbed_stick)
                    }
                    break;
                case "extra_rate_to_adjacent":
                    if (this.enabled_shrinkable_areas_before_grabbed_stick.length > 0 && this.enabled_expandable_areas_after_grabbed_stick.length > 0) {
                        resize(delta, this.enabled_expandable_areas_after_grabbed_stick, this.enabled_shrinkable_areas_before_grabbed_stick)
                    } else {
                        console.warn("rejecting resize, areas before should be shrinkable and areas after should be expandable",
                            "before: ", this.enabled_shrinkable_areas_before_grabbed_stick.map(a => a.log()),
                            "after: ", this.enabled_expandable_areas_after_grabbed_stick.map(a => a.log()),
                            "all: ", this.enabled_areas.map(a => a.log()))
                    }
                    break;
            }
        }

        const overflow = this.container_size - this.areas_with_sticks_sum_size
        if (overflow > 1) {
            console.warn(`Too much free space(${overflow}) after resize. Container: ${this.container_size}, Areas sum: ${this.areas_with_sticks_sum_size}`)
            if (overflow > 2) this._fit_container()
        } else if (overflow < 0) {
            console.warn(`Container is overflowing(${overflow}) after resize. Container: ${this.container_size}, Areas sum: ${this.areas_with_sticks_sum_size}`)
            if (overflow < 0) this._fit_container()
        }
    }

    handle_on_mouseup(_e: MouseEvent) {
        if (this.is_resizing) this._stop_resizing();
    }

    handle_on_blur(_e: FocusEvent) {
        if (this.is_resizing) this._stop_resizing();
    }

    _cursor_offset_to_stick(stick: FlexStickArea, e: MouseEvent) {
        const rect = stick.container.getBoundingClientRect();
        const x = e.clientX - (rect.left + rect.width / 2);
        const y = e.clientY - (rect.top + rect.height / 2);
        return { x, y }
    }

    private _cursor_offset_to_grabbed_stick(e: MouseEvent) {
        if (!this.is_resizing) return null
        return this._cursor_offset_to_stick(this.grabbed_stick, e)
    }

    private _stop_resizing() {
        this.is_resizing = false;
        this.grabbed_stick = null
        document.body.classList.remove("prevent-select")
        document.body.style.cursor = null
        this.container.removeEventListener("mousemove", this.bound_mousemove)
        this._exit_wrap_resize()
    }

    start_resizing() {
        this._enter_wrap_resize()
        this.is_resizing = true
        document.body.classList.add("prevent-select")
        this.bound_mousemove = this.handle_on_mousemove.bind(this)
        this.container.addEventListener("mousemove", this.bound_mousemove)
        document.body.style.cursor = this.is_row_direction() ? "col-resize" : "row-resize"
    }

    bind_container(container: HTMLElement) {
        this.container = container
        const bbox = container.getBoundingClientRect()
        this._amo = AllMightyObserver.new({
            element_resize: true,
            element_visible: true,
            target_element: this.container,
            callback: () => {
                if (this.container == null) return;

                const rect = this.container.getBoundingClientRect()
                if (rect.width == 0 || rect.height == 0) return;
                if (!this.initialized) this._initialize(rect)

                if (this.width != rect.width || this.height != rect.height) {
                    console.log(`${this.id} == container changed ->`,
                        ` width: ${rect.width} (${this.width - rect.width}), height: ${rect.height} (${this.height - rect.height})`);
                    this._wrap_resize(() => {
                        this.width = rect.width;
                        this.height = rect.height;
                        this._fit_container();
                        this._convert_px_to_integers()
                    })
                }
            }
        })
        this._wrap_resize(() => {
            if (bbox.width > 0 && bbox.height > 0) {
                this._initialize(bbox)
            }
        })
        // <editor-fold desc="WATCHERS">
        this.areas_array.each(a => {
            this._watchers.push(watch(() => a.enabled,
                () => {
                    // this will trigger fit and convert to px
                    this._wrap_resize(() => {
                        if (a.enabled) {
                            let size = a.get_size()
                            if (size < FlexContentArea.DEFAULT_MIN_PX_SIZE) size = FlexContentArea.DEFAULT_MIN_PX_SIZE
                            this._shrink_areas_by(size, this.enabled_areas, [a])
                            a.set_size(size)
                        }
                    })
                },
                {
                    flush: "sync"
                }))
            this._watchers.push(watch([
                    () => a.get_requested_min_px_size(),
                    () => a.get_requested_max_px_size(),
                ],
                () => {
                    // this will trigger fit and convert to px
                    this._wrap_resize(() => {
                    })
                }))
            this._watchers.push(watch(
                () => a.get_requested_locked_px_size(),
                (new_locked_px_size) => {
                    if (new_locked_px_size != null) {
                        this._wrap_resize(() => {
                            a.set_size(new_locked_px_size)
                        })
                    }
                }
            ))
        })
        this._watchers.push(watch(
            () => this.storager,
            (new_storager) => {
                if (new_storager != null) this._store_props()
            }
        ))

        this._watchers.push(watch([
                    () => this.stick_size,
                    () => this.direction
                ],
                () => {
                    // this will trigger fit and convert to px
                    this._wrap_resize(() => {
                    })
                })
        )
        // </editor-fold>
    }

    private _initialize(rect: DOMRect) {
        this._wrap_resize(() => {
            this.width = rect.width;
            this.height = rect.height;
            this.areas_array.forEach(area => {
                if (area.get_initial_px_size() != null) area.set_size(area.get_initial_px_size())
                if (area.get_initial_ratio_size() != null) area.set_ratio_size(area.get_initial_ratio_size())
            })
            const non_touch_areas = this.areas_array.filter(a => a.get_initial_px_size() != null || a.get_initial_ratio_size() != null)
            if (this.excess_space > 0) {
                this._shrink_areas_by(this.excess_space, this.enabled_areas, non_touch_areas)
            } else if (this.free_space > 0) {
                this._expand_areas_by(this.free_space, this.enabled_areas, non_touch_areas)
            }
            this._restore_props()
            this.initialized = true
        })
    }

    unbind() {
        this._amo?.stop()
        this._watchers.each(w => w())
        this._watchers = []
    }

    private _fit_container() {
        this._wrap_resize(() => {
            if (this.excess_space > 0) {
                this._concentrate_excess_space(this.excess_space)
            } else if (this.free_space > 0) {
                this._redistribute_free_space(this.free_space)
            }
            console.debug(
                `${this.id} == fit container finished`,
                `excess left: ${this.excess_space},`,
                `free left: ${this.free_space},`,
                `container size: ${this.container_size},`,
                `area sum size: ${this.areas_with_sticks_sum_size}`
            )
        })
    }

    private _concentrate_excess_space(space: number) {
        this._shrink_areas_by(space, this.enabled_areas, [])
    }

    private _redistribute_free_space(space: number) {
        this._expand_areas_by(space, this.enabled_areas, [])
    }

    _expand_areas_by(space: number, expandable_areas: FlexContentArea[], non_expandable_areas: FlexContentArea[]) {
        if (isNaN(space)) throw new Error("space is NaN")

        this._wrap_resize(() => {
            // if less than 1 px is free, ignore it
            if (space <= 0) return
            const non_expandable_ids = non_expandable_areas.map(a => a.id)
            const expandable_ids = expandable_areas.map(a => a.id)
            const to_expand_areas = this.enabled_expandable_areas
                                        .filter(a => expandable_ids.includes(a.id))
                                        .filter(a => !non_expandable_ids.includes(a.id))
            if (to_expand_areas.length == 0) {
                if (this.initialized && expandable_areas.length > 0) {
                    console.warn(`${this.id} == there is free space(${space}) but no expandable areas.`,
                        `enabled_areas: `, this.enabled_areas.pluck("log"))
                }
                return;
            }

            console.debug(`${this.id} == expanding by space(${space}) over ${to_expand_areas.length} areas`,
                to_expand_areas.map(a => a.id))

            const type_and_divider = this._get_resize_type_and_divider_for_expandable_areas(to_expand_areas);
            const per_area = space / type_and_divider.divider
            for (let i = 0; i < to_expand_areas.length; ++i) {
                const area = to_expand_areas[i]
                let to_expand: number
                switch (type_and_divider.type) {
                    case "equal":
                        to_expand = per_area
                        break
                    case "size":
                        to_expand = per_area * area.get_size()
                        break;
                    case "rate":
                        to_expand = per_area * area.get_resolved_grow_rate()
                        break;
                    case "rate_times_size":
                        to_expand = per_area * area.get_resolved_grow_rate() * area.get_size()
                        break
                }
                const size_before = area.get_size()
                area.set_size(size_before + to_expand)
                space -= (area.get_size() - size_before)
            }

            if (space > 0.05) this._expand_areas_by(space, to_expand_areas, non_expandable_areas)
        })
    }

    _shrink_areas_by(space: number, shrinkable_areas: FlexContentArea[], non_shrinkable_areas: FlexContentArea[]) {
        if (isNaN(space)) throw new Error("space is NaN")

        this._wrap_resize(() => {
            if (space <= 0) return
            const non_shrinkable_ids = non_shrinkable_areas.map(a => a.id)
            const shrinkable_ids = shrinkable_areas.map(a => a.id)
            const to_shrink_areas = this.enabled_shrinkable_areas
                                        .filter(a => shrinkable_ids.includes(a.id))
                                        .filter(a => !non_shrinkable_ids.includes(a.id))
            if (to_shrink_areas.length == 0) {
                if (this.initialized && shrinkable_areas.length > 0) {
                    console.warn(`${this.id} == there is excess space(${space}) but no shrinkable areas`)
                }
                return;
            }

            console.debug(`${this.id} == shrinking by space(${space}) over ${to_shrink_areas.length} areas`,
                to_shrink_areas.map(a => a.id))

            const type_and_divider = this._get_resize_type_and_divider_for_shrinkable_areas(to_shrink_areas);
            const per_area = space / type_and_divider.divider
            for (let i = 0; i < to_shrink_areas.length; ++i) {
                const area = to_shrink_areas[i]
                let to_shrink: number
                switch (type_and_divider.type) {
                    case "equal":
                        to_shrink = per_area
                        break
                    case "size":
                        to_shrink = per_area * area.get_size()
                        break;
                    case "rate":
                        to_shrink = per_area * area.get_resolved_grow_rate()
                        break;
                    case "rate_times_size":
                        to_shrink = per_area * area.get_resolved_grow_rate() * area.get_size()
                        break
                }
                const size_before = area.get_size()
                area.set_size(size_before - to_shrink)
                space -= (size_before - area.get_size())
            }

            if (space > 0.05) this._shrink_areas_by(space, to_shrink_areas, non_shrinkable_areas)
        })
    }

    private _convert_px_to_integers() {
        this._wrap_resize(() => {
            let total_diff = 0
            this.enabled_areas.forEach(area => {
                const area_size = area.get_size()
                if (total_diff > 0) {
                    const ceil = Math.ceil(area_size)
                    total_diff += area_size - ceil
                    area.set_size(ceil)
                } else {
                    const floor = Math.floor(area_size)
                    total_diff += area_size - floor
                    area.set_size(floor)
                }
            })
            const last_resizable = this.enabled_non_locked_areas.last()
            if (last_resizable != null) last_resizable.set_size(last_resizable.get_size() + total_diff)
        })
    }

    /**
     * Wrap a resize function, so that the after resize is not run after every resize action.
     * With this wrapping, after resize will be only run once the top level wrapper has finished.
     *
     * For example: If we have action that sets both container size and an area size,
     * this wrapper will ensure that after resize is only called once both actions are completed
     *
     * Because, firstly the container size will call wrap_resize, and then area set size will call it.
     * But only after all wrappers are done, the after resize will trigger.
     *
     * @param operation
     */
    _wrap_resize(operation: Function) {
        let result: any = null
        this._enter_wrap_resize()
        try {
            result = operation()
        } catch (e) {
            console.error(e)
        } finally {
            this._exit_wrap_resize()
        }

        return result
    }

    private _store_props() {
        if (!this.initialized) return;

        const store_size_ratios: StoredSizeRatio[] = this.enabled_areas.map(a => {
            return {
                id: a.id,
                size_ratio: a.get_size_ratio(),
                locked_ratio_size: a.get_locked_ratio_size(),
                locked_px_size: a.get_locked_px_size(),
            }
        })
        if (this.storager != null) this.storager.set(this.storager_key, store_size_ratios)
    }

    private _restore_props() {
        const restored_size_ratios: StoredSizeRatio[] = this.storager.get(this.storager_key, [])
        if (restored_size_ratios.length > 0) {
            console.log(`${this.id} ==  restoring sizes: `, restored_size_ratios)
            restored_size_ratios.each(v => {
                const area = this.areas[v.id]
                if (area != null) {
                    area.set_ratio_size(v.size_ratio)

                    if (v.locked_ratio_size != null) area.set_locked_ratio(v.locked_ratio_size)
                    if (v.locked_px_size != null) area.set_locked_px(v.locked_px_size)
                }
            })
        }
    }

    is_in_wrap_resize() {
        return this._resize_wrapper_depth > 0;
    }

    private _enter_wrap_resize() {
        if (ResizableFlex._global_resize_wrapper_depth == 0) {
            global_event_bus.$emit("resize-start", this.container)
        }
        ++this._resize_wrapper_depth;
        ++ResizableFlex._global_resize_wrapper_depth;
    }

    private _exit_wrap_resize() {
        --this._resize_wrapper_depth
        --ResizableFlex._global_resize_wrapper_depth
        if (this._resize_wrapper_depth < 0) this._resize_wrapper_depth = 0
        if (ResizableFlex._global_resize_wrapper_depth < 0) ResizableFlex._global_resize_wrapper_depth = 0
        if (this._resize_wrapper_depth == 0 && !this._after_resize_executing) {
            this._after_resize_executing = true
            try {
                console.debug(`${this.id} == executing after wrap resize`)

                if (this.initialized) {
                    this._fit_container();
                    this._convert_px_to_integers()
                    this._store_props()
                }
            } finally {
                this._after_resize_executing = false
                if (ResizableFlex._global_resize_wrapper_depth == 0) {
                    nextTick(() => global_event_bus.$emit("resize-end", this.container))
                }
            }
        }
    }

    private _get_resize_type_and_divider_for_shrinkable_areas(areas: FlexContentArea[]): {
        type: ResizeType,
        divider: number
    } {
        return this._get_resize_type_and_divider(areas, "get_resolved_shrink_rate")
    }

    private _get_resize_type_and_divider_for_expandable_areas(areas: FlexContentArea[]): {
        type: ResizeType,
        divider: number
    } {
        return this._get_resize_type_and_divider(areas, "get_resolved_grow_rate")
    }

    private _get_resize_type_and_divider(areas: FlexContentArea[], rate_key: "get_resolved_grow_rate" | "get_resolved_shrink_rate"): {
        type: ResizeType,
        divider: number
    } {
        const rates_times_size = areas.map(a => a[rate_key]() * a.get_size()).sum()
        if (rates_times_size != 0) return { type: "rate_times_size", divider: rates_times_size }

        const total_rates = areas.pluck(rate_key).sum()
        if (total_rates != 0) return { type: "rate", divider: total_rates }

        const total_size = areas.pluck("get_size").sum()
        if (total_size != 0) return { type: "size", divider: total_size }

        return { type: "equal", divider: areas.length }
    }

    // </editor-fold>
}
