<template>
  <label class="vue-label"
         :class="{'for-table': for_table, disabled: disabled}"
         style="padding-left: 3px"
         :for="`#${id}`"
         @click.stop.self="toggle">

    <!--suppress TypeScriptValidateTypes -->
    <input
        :id="id"
        :value="modelValue"
        type="checkbox"
        class="vue-input"
        :tabindex="for_table ? '-1' : '0'"
        :disabled="disabled ? 'disabled' : null"
        :checked="modelValue ? 'checked' : null"
        @keydown="on_keydown"
    >
    <span
        :class="color_class"
        @click.stop.self="toggle"
    >
      {{ label }}
    </span>
    <slot/>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { ColorClass } from "../../types/color_class";
import { KeyCode } from "../../types/globals";
import { generate_eid } from "../../helpers/generate/generate_eid";

export default defineComponent({
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: false,
            default: generate_eid(),
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        color_class: {
            type: String as PropType<ColorClass>,
            required: false,
            default: "blue",
        },
        for_table: {
            type: Boolean,
            require: false,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {}
    },
    computed: {},
    methods: {
        toggle(e: MouseEvent) {
            e.stopImmediatePropagation();
            if (this.disabled) return;
            this.$emit('update:modelValue', !this.modelValue)
        },
        on_keydown(e: KeyboardEvent) {
            if (this.disabled) return;
            if (e.code == KeyCode.ENTER || e.code == KeyCode.SPACE) {
                this.$emit('update:modelValue', !this.modelValue)
                return false;
            }
        },
    },
})
</script>

<style lang="scss" scoped>
label {
  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    cursor: no-drop;
    filter: grayscale(1);
  }

  span {
    // line-height: 2em;
    line-height: 2.2em;
  }

  &.vue-label {
    display: flex;
    align-content: center;

    // it align better with inputs and selects
    padding-bottom: 0.3em;

    &.for-table {
      display: inline-flex;
      min-height: auto;
      transform: scale(0.6);
      margin-top: -8px;

      span {
        height: 18px;
      }
    }
  }
}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none
}

[type="checkbox"] + span:not(.lever) {
  position: relative;
  padding-left: 35px;

  display: inline-block;
  height: 25px;
  //line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

[type="checkbox"] + span:not(.lever):before,
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid var(--button-disabled);
  border-radius: 1px;
  margin-top: 5px;
  margin-left: 5px;
  -webkit-transition: .2s;
  transition: .2s
}

[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0)
}

[type="checkbox"]:not(:checked):disabled + span:not(.lever):before {
  //border: none;
  //background-color: rgba(0, 0, 0, 0.42)
  border-color: #5b5b5b;
  background-color: var(--secondary-background-color);
}

[type="checkbox"].tabbed:focus + span:not(.lever):after {
  -webkit-transform: translate(-6px, -7px) scale(1);
  transform: translate(-6px, -7px) scale(1);
  border: 1px solid var(--focus-color);
  border-radius: 50%;
  //-webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  //box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  width: 34px;
  height: 34px;
}

[type="checkbox"]:checked + span:not(.lever):before {
  top: -7px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-width: 2px;
  border-style: solid;
  -webkit-transform: rotate(37deg);
  transform: rotate(37deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  background: transparent;
}

[type="checkbox"]:not(:disabled) + span:not(.lever):before {
  cursor: pointer;
}

[type="checkbox"]:not(:checked) + span:not(.lever):before {
  background-color: var(--ternary-background-color);
}


[type="checkbox"]:checked:disabled + span:before {
  border-right: 2px solid var(--button-disabled);
  border-bottom: 2px solid var(--button-disabled)
}

[type="checkbox"]:indeterminate + span:not(.lever):before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%
}

[type="checkbox"]:indeterminate:disabled + span:not(.lever):before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  background-color: transparent;
  cursor: no-drop;
}

[type="checkbox"].filled-in + span:not(.lever):after {
  border-radius: 2px
}

[type="checkbox"].filled-in + span:not(.lever):before, [type="checkbox"].filled-in + span:not(.lever):after {
  content: '';
  left: 0;
  position: absolute;
  -webkit-transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):before {
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid var(--button-disabled);
  top: 0px;
  z-index: 0
}

[type="checkbox"].filled-in:checked + span:not(.lever):before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  z-index: 0
}

[type="checkbox"].filled-in.tabbed:focus + span:not(.lever):after {
  border-radius: 2px;
  border-color: var(--button-disabled);
  background-color: rgba(0, 0, 0, 0.1)
}

[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border-radius: 2px;
}

[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):before {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
}

[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border-color: transparent;
  background-color: var(--button-disabled)
}

[type="checkbox"].filled-in:disabled:checked + span:not(.lever):before {
  background-color: transparent
}

[type="checkbox"].filled-in:disabled:checked + span:not(.lever):after {
  background-color: var(--button-disabled);
  border-color: var(--button-disabled)
}

// background
[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after,
[type="checkbox"].filled-in:checked + span:not(.lever):after {
  &.default {
    background-color: var(--secondary-background-color);
  }

  &.grey {
    background-color: var(--button-grey);
  }

  &.red {
    background-color: var(--button-red);
  }

  &.green {
    background-color: var(--button-green);
  }

  &.blue {
    background-color: var(--button-blue);
  }

  &.white {
    background-color: var(--button-white);
  }
}

// border
[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever),
[type="checkbox"].filled-in:checked + span:not(.lever) {
  &.default {
    &:after {
      border-color: var(--secondary-background-color);
    }
  }

  &.grey {
    &:after {
      border-color: var(--button-grey);
    }
  }

  &.red {
    &:after {
      border-color: var(--button-red);
    }
  }

  &.green {
    &:after {
      border-color: var(--button-green);
    }
  }

  &.blue {
    &:after {
      border-color: var(--button-blue);
    }
  }

  &.white {
    &:after {
      border-color: var(--button-white);
    }
  }
}

//border right
[type="checkbox"]:indeterminate + span:not(.lever),
[type="checkbox"]:checked + span:not(.lever) {
  &.default {
    &:before {
      border-right-color: var(--secondary-background-color);
    }
  }

  &.grey {
    &:before {
      border-right-color: var(--button-grey);
    }
  }

  &.red {
    &:before {
      border-right-color: var(--button-red);
    }
  }

  &.green {
    &:before {
      border-right-color: var(--button-green);
    }
  }

  &.blue {
    &:before {
      border-right-color: var(--button-blue);
    }
  }

  &.white {
    &:before {
      border-right-color: var(--button-white);
    }
  }
}

// border-bottom and border-right
[type="checkbox"]:checked + span:not(.lever) {
  &.default {
    &:before {
      border-bottom-color: var(--secondary-background-color);
      border-right-color: var(--secondary-background-color);
    }
  }

  &.grey {
    &:before {
      border-bottom-color: var(--button-grey);
      border-right-color: var(--button-grey);
    }
  }

  &.red {
    &:before {
      border-bottom-color: var(--button-red);
      border-right-color: var(--button-red);
    }
  }

  &.green {
    &:before {
      border-bottom-color: var(--button-green);
      border-right-color: var(--button-green);
    }
  }

  &.blue {
    &:before {
      border-bottom-color: var(--button-blue);
      border-right-color: var(--button-blue);
    }
  }

  &.white {
    &:before {
      border-bottom-color: var(--button-white);
      border-right-color: var(--button-white);
    }
  }
}
</style>
