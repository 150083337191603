import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { BrowserDriverClient } from "../clients/browser_driver_client";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { BrowserDriverScope } from "../scopes/browser_driver_scope";
import { Consoler } from "../../helpers/api_wrappers/consoler";

// <editor-fold desc="TYPES">
export interface BrowserDriverProps extends Props {
    id: number
    name: string
    version: string
    active: boolean
    created_at: Date
    updated_at: Date
}

export interface BrowserDriverState extends State {
}

export interface BrowserDriverComputed extends Computed {
}

export interface BrowserDriverStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class BrowserDriver extends VueRecord {
    ['constructor']: typeof BrowserDriver

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = BrowserDriverClient
    static ScopeClass = BrowserDriverScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: BrowserDriverStaticState = reactive<BrowserDriverStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "active", "name", "version"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof BrowserDriver> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof BrowserDriver>> = {}

    static field_validators: ModelValidatorOpts<BrowserDriverProps> = {}

    static resource_name = Enum.Resource.Label.BROWSER_DRIVER
    static resource_id = Enum.Resource.Id.BROWSER_DRIVER
    static icon_class = "fa-solid fa-cube"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: BrowserDriverClient
    declare props: BrowserDriverProps;
    declare state: BrowserDriverState;
    declare computed: BrowserDriverComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        return generate_resolved_promise()
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
BrowserDriver.register_resource(BrowserDriver)
BrowserDriverClient.ModelClass = BrowserDriver
BrowserDriverScope.ModelClass = BrowserDriver

on_dom_content_loaded(() => {
    watch(
        () => current.user?.props?.superadmin,
        (superadmin) => {
            BrowserDriver.unsync()
            if (superadmin != null && superadmin) BrowserDriver.sync(`/sync/browser_drivers`);
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        BrowserDriver: typeof BrowserDriver
    }
}
window.BrowserDriver = BrowserDriver
// </editor-fold>
