import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { UserSettingUpdateProps } from "../models/user_setting";
import { UserSetting } from "../models/user_setting";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class UserSettingClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof UserSetting
    declare record: UserSetting

    update(user_setting: UserSettingUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/user_settings/user_settings/${this.key()}`,
                type: "PATCH",
                data: JSON.stringify({
                    user_setting,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }
}
