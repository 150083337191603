import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Group } from "../models/group";
import _ from "lodash";
import { GroupSavepointScope } from "../scopes/group_savepoint_scope";
import { GroupSavepointProps } from "../models/group_savepoint";
import { GroupSavepoint } from "../models/group_savepoint";
import { GroupFolderProps } from "../models/group_folder";
import { GroupFolder } from "../models/group_folder";
import { GroupScope } from "../scopes/group_scope";
import { GroupProps } from "../models/group";
import { GroupCreateProps } from "../models/group";
import { GroupUpdateProps } from "../models/group";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class GroupClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Group
    declare record: Group

    static load(id: number, reload = false): Promise<Group> {
        if (reload) this.ModelClass.state.load_promises[id] = null

        if (this.ModelClass.state.load_promises[id] == null) {
            const promise = new Promise<Group>((resolve, reject) => {
                $.ajax({
                    url: `/groups/${id}`,
                    type: "GET",
                    statusCode: ajax_status_codes,
                    success: (data: GroupProps) => {
                        const group = Group.new(data)
                        resolve(group)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
            this.ModelClass.state.load_promises[id] = promise
            promise.catch(() => {
                this.ModelClass.state.load_promises[id] = null
            })
            return promise
        } else {
            return this.ModelClass.state.load_promises[id]
        }
    }

    static create(group: GroupCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/groups`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    group,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/groups/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(group: GroupUpdateProps) {
        type GroupUpdateResponse = {
            updated: boolean
            reason?: string
            group_props?: GroupProps
        }

        return new Promise<GroupUpdateResponse>((resolve, reject) => {
            const code_handlers = _.cloneDeep(ajax_status_codes)
            delete code_handlers[400]
            return $.ajax({
                url: `/groups/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    group,
                }),
                statusCode: code_handlers,
                success: (data: GroupUpdateResponse) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
            }) as JQuery.jqXHR<GroupUpdateResponse>
        })
    }

    load_savepoints() {
        return new Promise<GroupSavepointScope>((resolve, reject) => {
            $.ajax({
                url: `/groups/${this.key()}/savepoints`,
                type: "GET",
                statusCode: ajax_status_codes,
                success: (data: GroupSavepointProps[]) => {
                    data.forEach(group_savepoint_props => {
                        GroupSavepoint.new(group_savepoint_props)
                    })
                    resolve(this.record.group_savepoints)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static batch_path(group_ids: number | number[]) {
        let ids: number[];
        if (what_is_it(group_ids) == "Array") {
            ids = group_ids as number[]
        } else {
            ids = [group_ids as number]
        }

        type PathResponse = {
            [key: string]: {
                target: GroupProps,
                path: GroupFolderProps[]
            }
        }

        type PromiseResponse = {
            [key: string]: GroupFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/groups/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    ids,
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(group_id => {
                        Group.new(data[group_id].target)
                        const group_folders_props = data[group_id].path;
                        const array: GroupFolder[] = []
                        group_folders_props.forEach(group_folder_props => {
                            array.push(GroupFolder.new(group_folder_props))
                        })
                        promise_response[group_id] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static groups_with(snippet_ids: number[], group_ids: number[], except_group_ids: number[]) {
        return new Promise<GroupScope>((resolve, reject) => {
            $.ajax({
                url: `/groups/other_groups`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    snippet_ids,
                    group_ids,
                    except_group_ids
                }),
                statusCode: ajax_status_codes,
                success: (data: GroupProps[]) => {
                    const records: Group[] = [];
                    data.forEach(group_props => records.push(Group.new(group_props)));
                    resolve(Group.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                }
            })
        });
    }
}
