<template>
  <div class="attribute-item"
       @click.stop="copy"
  >
    <div class="icon">
      <template v-if="attribute.can_copy">
        <i v-if="copied"
           class="fa-solid fa-check"
           style="color: var(--button-yellow)"
        />
        <i v-else
           class="fa-solid fa-clone"/>
      </template>
    </div>
    <div class="label">
      {{ attribute.display_name }}:
    </div>
    <div v-if="show_count"
         class="count"
         :style="count_style"
         title="Number of occurrences"
    >
      ({{ count }})
    </div>
    <div class="flex-expander"/>
    <div class="value"
         :style="style"
         :title="attribute.title_value"
    >
      <template v-if="inspector.is_filtering">
        <template v-for="part in value_parts"
                  :key="part.key">
          <span :class="{highlight: part.match}">
            {{ part.text }}
          </span>
        </template>
      </template>
      <template v-else>
        {{ attribute.display_value }}
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CSSProperties } from "vue";
import { get_css_var } from "../../../../../../helpers/generic/get_css_var";
import { PropType } from "vue";
import { InspectorElementAttribute } from "../inspector_element_attribute";
import { AttributeName } from "../inspector_element_attribute";
import { split_by_query_match } from "../../../../../../helpers/pretty/split_by_query_match";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        attribute: {
            type: Object as PropType<InspectorElementAttribute>,
            required: true
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            copied: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        inspector() {
            return this.attribute.inspector_element.inspector
        },
        value_parts() {
            return split_by_query_match(this.inspector.prepared_filter_query, this.attribute.display_value)
        },
        show_count() {
            const show_count_for: Array<AttributeName> = [
                "resource-id",
                "name",
                "content-desc",
                "text",
            ]
            return show_count_for.includes(this.attribute.name) && this.count > 0 && this.attribute.value != null
        },
        count() {
            const tally = this.inspector.active_snapshot.tally_attribute_map[this.attribute.name][this.attribute.value?.toString()]
            if (tally == null) return 0
            return tally
        },
        count_style() {
            const style: CSSProperties = {}
            if (this.count == 1) style.color = get_css_var("--button-green")
            else if (this.count > 1) style.color = get_css_var("--button-red")
            return style
        },
        style() {
            const style: CSSProperties = {}
            style.color = this.attribute.value_color
            if (this.attribute.name == "xpath") {
                style.maxWidth = "50%"
            }
            return style
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        copy(e: MouseEvent) {
            if (!this.attribute.can_copy) return;

            this.attribute.copy(e.ctrlKey)

            this.copied = true
            setTimeout(() => {
                this.copied = false
            }, 1500)
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.attribute-item {
  font-size: 0.85em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 2px;
  padding-block: 1px;
  margin-block: 1px;
  background: var(--ternary-background-color);
  cursor: pointer;
  width: calc(100% - 4px);

  &:hover {
    filter: brightness(1.3);
  }

  .icon {
    margin-right: 4px;
    font-size: 0.6em;
    min-width: 10px;
  }

  .label {
    color: var(--font-color-secondary);
    margin-right: 3px;
    white-space: nowrap;
  }

  .count {
    font-size: 0.7em;
    margin-right: 3px;
    flex-shrink: 0;
  }

  .value {
    font-family: monospace;
    color: var(--font-color);
    width: 100%;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span.highlight {
      background: var(--sidebar-selected-contrast);
    }
  }
}
</style>
