import { Project } from "../models/project";
import { ProjectVersion } from "../models/project_version";
import { ProjectVersionSetting } from "../models/project_version_setting";
import { SnippetScope } from "../scopes/snippet_scope";
import { Snippet } from "../models/snippet";
import { UserSettingScope } from "../scopes/user_setting_scope";
import { UserSetting } from "../models/user_setting";
import { VariableSet } from "../models/variable_set";
import { VariableSetScope } from "../scopes/variable_set_scope";
import { User } from "../models/user";
import { PlayScope } from "../scopes/play/play_scope";
import { Play } from "../models/play/play";

declare module "../models/project_version" {
    interface ProjectVersion {
        project: Project
        user: User

        project_version_setting: ProjectVersionSetting

        plays: PlayScope
        variable_sets: VariableSetScope
        user_settings: UserSettingScope
        snippets: SnippetScope
    }
}

ProjectVersion.belongs_to("project", Project, "project_id")
ProjectVersion.belongs_to("user", User, "user_id")

ProjectVersion.has_one("project_version_setting", ProjectVersionSetting, "project_version_id")

ProjectVersion.has_many("plays", Play, "project_version_id")
ProjectVersion.has_many("variable_sets", VariableSet, "project_version_id")
ProjectVersion.has_many("user_settings", UserSetting, "project_version_id")
ProjectVersion.has_many("snippets", Snippet, "project_version_id")
