export {}

if (WEB_TYPE != "test_dom") {
    let worker
    if (is_pc_android && is_browser_chrome) {
        // android chrome does not support shared workers
        worker = new Worker("/assets/shared_worker.js");
    } else {
        worker = new SharedWorker("/assets/shared_worker.js");
        // Listen for messages from the Shared Worker
        worker.port.onmessage = function(event) {
            console.log(`Main thread received message: ${event.data}`);
        };
    }
}

