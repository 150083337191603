import { Schedule } from "../models/schedule";
import { ProjectVersion } from "../models/project_version";
import { Scenario } from "../models/scenario";
import { Group } from "../models/group";
import { ScenarioFolder } from "../models/scenario_folder";
import { User } from "../models/user";


declare module "../models/schedule" {
    interface Schedule {
        scenario: Scenario
        scenario_folder: ScenarioFolder
        group: Group
        user: User
        project_version: ProjectVersion
    }
}

Schedule.belongs_to("user", User, "user_id")
Schedule.belongs_to("scenario", Scenario, "scenario_id")
Schedule.belongs_to("group", Group, "group_id")
Schedule.belongs_to("scenario_folder_id", ScenarioFolder, "scenario_folder_id")
Schedule.belongs_to("project_version", ProjectVersion, "project_version_id")
