import { PlayScenario } from "../../models/play/play_scenario";
import { ScenarioSavepoint } from "../../models/scenario_savepoint";
import { ScenarioSetting } from "../../models/scenario_setting";
import { PlayGroupsPlayScenariosScope } from "../../scopes/play/bridges/play_groups_play_scenarios_scope";
import { PlayScenariosPlayWorkerGroupsScope } from "../../scopes/play/bridges/play_scenarios_play_worker_groups_scope";
import { PhoneScope } from "../../scopes/phone_scope";
import { PlayScenarioScope } from "../../scopes/play/play_scenario_scope";
import { PlaySnippetScope } from "../../scopes/play/play_snippet_scope";
import { PlayLogScope } from "../../scopes/play/play_log_scope";
import { DelayedJob } from "../../models/delayed_job";
import { PlayGroupScope } from "../../scopes/play/play_group_scope";
import { PlayWorkerGroupScope } from "../../scopes/play/play_worker_group_scope";
import { PlayScope } from "../../scopes/play/play_scope";
import { PlayGroupsPlayScenarios } from "../../models/play/bridges/play_groups_play_scenarios";
import { PlayScenariosPlayWorkerGroups } from "../../models/play/bridges/play_scenarios_play_worker_groups";
import { Phone } from "../../models/phone";
import { PlaySnippet } from "../../models/play/play_snippet";
import { PlayLog } from "../../models/play/play_log";
import { PlayGroup } from "../../models/play/play_group";
import { PlayWorkerGroup } from "../../models/play/play_worker_group";
import { Play } from "../../models/play/play";

declare module "../../models/play/play_scenario" {
    interface PlayScenario {
        play_scenario: PlayScenario
        scenario_savepoint: ScenarioSavepoint
        scenario_setting: ScenarioSetting

        play_groups_play_scenarios: PlayGroupsPlayScenariosScope
        play_scenarios_play_worker_groups: PlayScenariosPlayWorkerGroupsScope

        in_use_phones: PhoneScope
        play_scenario_parts: PlayScenarioScope
        play_snippets: PlaySnippetScope
        play_logs: PlayLogScope

        delayed_job: DelayedJob

        play_groups: PlayGroupScope
        play_worker_groups: PlayWorkerGroupScope
        plays: PlayScope
    }
}

PlayScenario.belongs_to("play_scenario", PlayScenario, "play_scenario_id")
PlayScenario.belongs_to("scenario_savepoint", ScenarioSavepoint, "scenario_savepoint_id")
PlayScenario.belongs_to("scenario_setting", ScenarioSetting, "scenario_setting_id")

PlayScenario.has_one("delayed_job", DelayedJob, "play_scenario_id")

// has many
PlayScenario.has_many("play_groups_play_scenarios", PlayGroupsPlayScenarios, "play_scenario_id")
PlayScenario.has_many("play_scenarios_play_worker_groups", PlayScenariosPlayWorkerGroups, "play_scenario_id")
PlayScenario.has_many("in_use_phones", Phone, "in_use_by_play_scenario_id")

PlayScenario.has_many("play_scenario_parts", PlayScenario, "play_scenario_id", { dependent_unload: true })
PlayScenario.has_many("play_snippets", PlaySnippet, "play_scenario_id", { dependent_unload: true })
PlayScenario.has_many("play_logs", PlayLog, "play_scenario_id", { dependent_unload: true })


// has many through
PlayScenario.has_many_through("play_groups", "play_groups_play_scenarios", "play_group", PlayGroup)
PlayScenario.has_many_through("play_worker_groups", "play_scenarios_play_worker_groups", "play_worker_group", PlayWorkerGroup)
PlayScenario.has_many_through("plays", "play_worker_groups", "play", Play)

