import ConfirmDialogue from "../../../components/testa/confirm_dialogue/confirm_dialgue";
import { generate_resolved_promise } from "../../generate/generate_resolved_promise";
import { ScopedRecordsMap } from "../../../vue_record/base/utils/to_scoped_map";
import { Jobberman } from "../../jobberman/jobberman";


export function multi_resource_remove(
    project_version_id: number,
    scopes: ScopedRecordsMap) {
    let content_text = `You are about to delete<br>`


    let resource_content_text = []
    resource_content_text.push(scopes.snippets.names())
    resource_content_text.push(scopes.snippet_folders.names())
    resource_content_text.push(scopes.scenarios.names())
    resource_content_text.push(scopes.scenario_folders.names())
    resource_content_text.push(scopes.groups.names())
    resource_content_text.push(scopes.group_folders.names())
    resource_content_text.push(scopes.files.names())
    resource_content_text.push(scopes.file_folders.names())
    resource_content_text.push(scopes.images.names())
    resource_content_text.push(scopes.image_folders.names())
    resource_content_text.push(scopes.phones.names())
    resource_content_text.push(scopes.apps.names())
    resource_content_text.push(scopes.schedules.names())
    resource_content_text.push(scopes.plays.names())

    resource_content_text = resource_content_text.filter(n => n != "")
    if (resource_content_text.length == 0) return generate_resolved_promise();

    content_text += resource_content_text.join("<br>")

    return ConfirmDialogue.show({
        html: content_text,
        center: true,
        confirm_color_class: "red",
        confirm_action: () => {
            return Jobberman.promise({
                run: (jobberman_id, _, reject) => {
                    return $.ajax({
                        url: `/core/multi_resource_remove`,
                        type: 'POST',
                        data: {
                            jobberman_id,
                            project_version_id,
                            snippet_ids: scopes.snippets.keys(),
                            snippet_folder_ids: scopes.snippet_folders.keys(),
                            scenario_ids: scopes.scenarios.keys(),
                            scenario_folder_ids: scopes.scenario_folders.keys(),
                            group_ids: scopes.groups.keys(),
                            group_folder_ids: scopes.group_folders.keys(),
                            file_paths: scopes.files.keys(),
                            file_folder_paths: scopes.file_folders.keys(),
                            image_paths: scopes.images.keys(),
                            image_folder_paths: scopes.image_folders.keys(),
                            phone_udids: scopes.phones.keys(),
                            app_ids: scopes.apps.keys(),
                            schedule_ids: scopes.schedules.keys(),
                            play_ids: scopes.plays.keys(),
                            authenticity_token,
                        },
                        error: (e) => reject(e),
                        statusCode: ajax_status_codes,
                    })
                },
            })
        }
    });
}
