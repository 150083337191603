import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { FileFolder } from "../models/file_folder";
import { FileFolderCreateProps } from "../models/file_folder";
import { FileFolderChildren } from "../models/file_folder";
import { FileProps } from "../models/file";
import { FileFolderProps } from "../models/file_folder";
import { File } from "../models/file"
import { FileFolderUpdateProps } from "../models/file_folder";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { FileScope } from "../scopes/file_scope";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class FileFolderClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof FileFolder
    declare record: FileFolder

    /** When copy - pasting we are duplicating to different folder */
    static copy(project_version_id: number, destination_file_folder_path: string, file_folder_paths: string[], file_paths: string[] = []) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/file_folders/copy`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id,
                    destination_file_folder_path,
                    file_paths,
                    file_folder_paths,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static create(file_folder: FileFolderCreateProps) {
        return new Promise<void>((resolve, reject) => {

            $.ajax({
                url: "/file_folders",
                type: "POST",
                data: {
                    file_folder,
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }

    children(reload = false): Promise<FileFolderChildren> {
        if (reload) FileFolder.state.children_promises[this.key()] = null

        if (FileFolder.state.children_promises[this.key()] == null) {
            return FileFolder.ClientClass.children(this.record.props.project_version_id, this.key(), reload)
        } else {
            return FileFolder.state.children_promises[this.key()]
        }
    }

    static children(project_version_id: number, file_folder_path: string, reload = false): Promise<FileFolderChildren> {
        if (reload) FileFolder.state.children_promises[file_folder_path] = null

        if (FileFolder.state.children_promises[file_folder_path] == null) {
            type ChildrenResponse = {
                files: FileProps[],
                file_folders: FileFolderProps[],
            }

            const promise: Promise<FileFolderChildren> = new Promise<FileFolderChildren>((resolve, reject) => {
                $.ajax({
                    url: `/file_folders/children`,
                    type: "GET",
                    data: {
                        project_version_id,
                        file_folder_path,
                    },
                    success: (data: ChildrenResponse) => {
                        const promise_response: FileFolderChildren = {
                            files: [],
                            file_folders: []
                        }
                        data.files.forEach(file_prop => {
                            promise_response.files.push(File.new(file_prop))
                        })
                        data.file_folders.forEach(file_folder_prop => {
                            promise_response.file_folders.push(FileFolder.new(file_folder_prop))
                        })
                        resolve(promise_response)
                    },
                    error: (error) => {
                        reject(error)
                    },
                    statusCode: ajax_status_codes,
                })
            })
            FileFolder.state.children_promises[file_folder_path] = promise
            promise.catch(() => {
                FileFolder.state.children_promises[file_folder_path] = null
            })
            return promise
        } else {
            return FileFolder.state.children_promises[file_folder_path]
        }
    }

    static move(project_version_id: number, destination_file_folder_path: string, file_folder_paths: string[], file_paths: string[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/file_folders/move`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    destination_file_folder_path,
                    file_paths,
                    file_folder_paths,
                    project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(file_folder: FileFolderUpdateProps) {
        file_folder.project_version_id = this.record.props.project_version_id

        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/file_folders/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    file_folder,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    /** Duplicate single item in current folder */
    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/file_folders/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id: this.record.props.project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static batch_path(file_paths: string | string[]) {
        let paths: string[];
        if (what_is_it(file_paths) == "Array") {
            paths = file_paths as string[]
        } else {
            paths = [file_paths as string]
        }

        type PathResponse = {
            [key: string]: FileFolderProps[]
        }

        type PromiseResponse = {
            [key: string]: FileFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/file_folders/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    paths,
                    authenticity_token,
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(file_folder_path => {
                        const file_folders_props = data[file_folder_path];
                        const array: FileFolder[] = []
                        file_folders_props.forEach(file_folder_props => {
                            array.push(FileFolder.new(file_folder_props))
                        })
                        promise_response[file_folder_path] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static batch_load_files(paths: string[] | number[]): Promise<FileScope> {
        if (paths.length == 0) return generate_resolved_promise<FileScope>(File.to_scope([]))

        return new Promise<FileScope>((resolve, reject) => {
            $.ajax({
                url: `/file_folders/load_files`,
                type: "GET",
                data: {
                    ids: paths
                },
                statusCode: ajax_status_codes,
                success: (data: FileProps[]) => {
                    const records: File[] = []
                    data.forEach(file_props => {
                        records.push(File.new(file_props))
                    })
                    resolve(File.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                },
            })
        });
    }

    static batch_download(project_version_id: number, file_folders: FileFolder[], files: File[]) {
        const search_params = new URLSearchParams({
            project_version_id: project_version_id.toString(),
            file_paths: JSON.stringify(files.map(f => f.props.path)),
            file_folder_paths: JSON.stringify(file_folders.map(ff => ff.props.path)),
        })

        return $.fileDownload(`/files/batch/download?${search_params.toString()}`)
    }

    download() {
        return $.fileDownload(this.record.computed.download_path)
    }
}
