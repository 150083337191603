import { Browser } from "../models/browser";
import { Browser as ScenarioSettingBrowser } from "../models/scenario_setting/browser"
import { BrowserScope as ScenarioSettingBrowserScope } from "../scopes/scenario_setting/browser_scope"

declare module "../models/browser" {
    interface Browser {
        scenario_setting_browsers: ScenarioSettingBrowserScope
    }
}

Browser.has_many("scenario_setting_browsers", ScenarioSettingBrowser, "browser_id")
