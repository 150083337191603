<template>
  <Modal
      ref="modal"
      :show_confirm="false"
      :is_loading="is_loading"
      :position="position"
      :backdrop_dim_level="0"
      :dismissible="true"
      :cancel_on_escape="true"
      :focus_last_active_on_unmount="true"
      padding="5px 0 5px 0"
      @cancel="on_cancel"
  >
    <template #body>
      <div ref="container"
           class="container"
           :style="container_style"
      >
        <template v-if="has_any_result">

          <div class="results">
            <template v-for="usage in in_scenarios"
                      :key="usage.vue_key">
              <VariableUsageComponent
                  :variable_usage="usage"
                  @click="on_usage_click"
              />
            </template>
          </div>

          <template v-if="in_other_scenarios.length > 0">
            <div class="divider">
              In Other Scenarios
            </div>
            <div class="results">
              <template v-for="usage in in_other_scenarios"
                        :key="usage.vue_key">
                <VariableUsageComponent
                    :variable_usage="usage"
                    @click="on_usage_click"
                />
              </template>
            </div>
          </template>

          <template v-if="in_variable_sets.length > 0">
            <div class="divider">
              In Variable Sets
            </div>
            <div class="results">
              <template v-for="usage in in_variable_sets"
                        :key="usage.vue_key">
                <VariableUsageComponent
                    :variable_usage="usage"
                    @click="on_usage_click"
                />
              </template>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="no-results">
            No Results
          </div>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import Modal from "../../../Modal.vue";
import { Coords } from "../../../../../types/globals";
import { Snippet } from "../../../../../vue_record/models/snippet";
import { generate_uuid } from "../../../../../helpers/generate/generate_uuid";
import VariableUsageComponent from "./VariableUsage.vue";
import { nextTick } from "vue";
import { ProjectVersion } from "../../../../../vue_record/models/project_version";
import { VariableUsage } from "../../../../../types/components/testa/editor/editors/snippet/variable_usage";
import { UsageExcept } from "../../../../../types/components/testa/editor/editors/snippet/usage_except";

export default defineComponent({
    components: { VariableUsageComponent, Modal },
    // <editor-fold desc="PROPS">
    props: {
        position: {
            type: Object as PropType<Coords>,
            required: true
        },
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true
        },
        variable: {
            type: String,
            required: true
        },
        except: {
            type: Object as PropType<UsageExcept>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            is_loading: true,
            in_scenarios: [] as VariableUsage[],
            in_other_scenarios: [] as VariableUsage[],
            in_variable_sets: [] as VariableUsage[],
            window_height: 0,
            window_width: 0,
            active_usage: null as VariableUsage
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        container_style() {
            return {
                maxHeight: this.window_height - this.position.y - 30,
                maxWidth: this.window_width - this.position.x - 30,
            }
        },
        has_any_result() {
            return this.in_scenarios.length > 0 || this.in_other_scenarios.length > 0 || this.in_variable_sets.length > 0
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.window_height = $(window).height();
        this.window_width = $(window).width();

        Snippet.ClientClass.variable_usage(this.project_version.key(), this.variable).then(data => {
            this.in_scenarios = data.usages_in_scenarios
                                    .filter(u => !(u.id == this.except.snippet.props.id && u.line == this.except.line))
            this.in_scenarios.forEach(u => u.vue_key = generate_uuid())

            this.in_other_scenarios = data.usages_in_other_scenarios
                                          .filter(u => !(u.id == this.except.snippet.props.id && u.line == this.except.line))
            this.in_scenarios.forEach(u => u.vue_key = generate_uuid())

            this.in_variable_sets = data.usages_in_variable_sets
                                        .filter(u => !(u.id == this.except.snippet.props.id && u.line == this.except.line))
            this.in_scenarios.forEach(u => u.vue_key = generate_uuid())

            this.is_loading = false;
            nextTick(() => {
                this.focus_first_usage()
            })
        })
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_cancel() {
            this.$.appContext.app.unmount();
        },
        on_usage_click(usage: VariableUsage) {
            Snippet.ClientClass.load(usage.id).then((snippet) => {
                snippet.open({ close_all_modals: true, jump_to: { line: usage.line, ch: 0 } })
            })
        },
        focus_first_usage() {
            const container = this.$refs.container as HTMLElement
            const usage = container.querySelector(".usage") as HTMLElement
            usage?.focus();
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  resize: both;
  max-width: 90vw;
  max-height: 90vh;

  .no-results {
    padding: 5px;
    font-family: monospace;
    font-size: 12px;
  }
}

.divider {
  border-bottom: solid 1px var(--font-color-secondary);
  border-top: solid 1px var(--font-color-secondary);
  text-align: center;
  cursor: pointer;
  color: var(--font-color-secondary);
  font-size: 12px;
  font-family: monospace;
}


</style>
