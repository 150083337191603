<template>
  <div id="main_header"
       class="header">
    <ProgressBar :progress_bar="progress_bar"/>

    <!--<editor-fold desc="LANGUAGE ICON">-->
    <div
        v-if="is_main_web_type && current.project_version_setting != null"
        class="language-icon-container"
    >
      <i v-if="current.project_version_setting.props.default_language == Enum.Language.RUBY"
         class="fa fa-gem"
         style="color: rgb(220, 74, 71);"
      />
    </div>
    <!--</editor-fold>-->

    <!--<editor-fold desc="PROJECT VERSION SELECT">-->
    <!--    <div v-if="is_main_web_type"-->
    <!--         class="project-version-select-container">-->
    <!--      <Select2-->
    <!--          v-if="selectable_projects.length > 0"-->
    <!--          id="project_version_select"-->
    <!--          v-model="selected_project_version_id"-->
    <!--          :for_header="true"-->
    <!--          placeholder="&#45;&#45; Select Project -&#45;&#45;"-->
    <!--          dropdown_parent="#main_header"-->
    <!--          :template_selection="template_select_result_string()"-->
    <!--      >-->
    <!--        <template v-for="project in selectable_projects"-->
    <!--                  :key="project.key()">-->
    <!--          <optgroup :label="project.name()">-->
    <!--            <option v-for="selected_project_version in selectable_project_versions[project.key()]"-->
    <!--                    :key="selected_project_version.props.id"-->
    <!--                    :value="selected_project_version.props.id"-->
    <!--            >-->
    <!--              {{ selected_project_version.props.version }}-->
    <!--            </option>-->
    <!--          </optgroup>-->
    <!--        </template>-->
    <!--      </Select2>-->
    <!--    </div>-->

    <!--<editor-fold desc="PROJECT SELECT">-->
    <div v-if="is_main_web_type"
         class="project-select-container">
      <Select2
          id="project_select"
          v-model="selected_project_id"
          :for_header="true"
          placeholder="-- Select Project ---"
          dropdown_parent="#main_header"
      >
        <option v-for="selected_project in selectable_projects"
                :key="selected_project.props.id"
                :value="selected_project.props.id"
        >
          {{ selected_project.props.full_name }}
        </option>
      </Select2>
    </div>
    <!--</editor-fold>-->

    <!--<editor-fold desc="PROJECT VERSION SELECT">-->
    <div v-if="is_main_web_type"
         class="project-version-select-container">
      <Select2
          id="project_version_select"
          v-model="selected_project_version_id"
          :for_header="true"
          placeholder="-- Select Project ---"
          dropdown_parent="#main_header"
          :template_result="(state) => style_project_version_option(state)"
      >
        <option v-for="selected_project_version in selectable_project_versions[selected_project_id]"
                :key="selected_project_version.props.id"
                :value="selected_project_version.props.id"
                :style="selected_project_version.props.is_private ? 'color: var(--font-color-secondary);' : ''"
        >
          {{ selected_project_version.props.version }}
        </option>
      </Select2>
    </div>
    <!--</editor-fold>-->
    <!--</editor-fold>-->

    <!--<editor-fold desc="CURRENT ROLE">-->
    <div v-if="is_main_web_type && user != null && current.role != null && current.project != null"
         class="role-container">
      <span>{{ current.role }}</span>
      <i class="fas fa-id-badge"/>
    </div>
    <!--</editor-fold>-->
    <div class="expander"/>

    <div v-if="number_of_other_windows > 0"
         class="webs-counter-container"
         title="Number of windows open"
    >
      {{ number_of_other_windows + 1 }}
      <i class="fa-solid fa-window-restore"/>
    </div>


    <div v-if="connection_offline"
         class="connection-status offline"
         :title="connection_status_message"
    >
      <i class="fa-solid fa-heart-crack"/>
    </div>
    <div v-else-if="connection_under_load"
         class="connection-status under-load"
         :title="connection_status_message"
    >
      <i class="fa-solid fa-heart"/>
    </div>
    <div v-else
         class="connection-status online"
         :title="connection_status_message"
    >
      <i class="fa-solid fa-heart"/>
    </div>

    <Time/>

    <!--<editor-fold desc="ICONS">-->
    <div class="icons-container">
      <ActionIcon
          id="help_action_icon"
          title="Help"
          icon_class="far fa-question-circle"
          margin="0px"
          @click="open_help"
      />
      <ActionIcon
          v-if="current.user != null"
          id="settings_action_icon"
          title="Settings"
          icon_class="fas fa-cog"
          margin="0px"
          @click="() => show_settings(null)"
      />
      <ActionIcon
          v-if="current.user != null"
          id="logout_action_icon"
          title="Logout"
          icon_class="fa fa-power-off"
          margin="0px"
          @click="logout"
      />
      <a id="logout"
         ref="logout"
         style="display: none"
         rel="nofollow"
         data-method="delete"
         href="/users/sign_out"/>
    </div>
    <!--</editor-fold>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Project } from "../../vue_record/models/project";
import ActionIcon from "../testa/ActionIcon.vue";
import Select2 from "../testa/Select2.vue"
import ProgressBar from "../testa/progress_bar/ProgressBar.vue";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import { User } from "../../vue_record/models/user";
import { LoadingData } from "select2";
import { GroupedDataFormat } from "select2";
import { DataFormat } from "select2";
import { ProjectVersion } from "../../vue_record/models/project_version";
import { EMPTY_ARRAY } from "../../vue_record/base/vue_record_scope";
import Time from "./header/Time.vue";
import { show_global_loader } from "../testa/global_loader/global_loader";
import { select2_option_with_icon } from "../../helpers/dom/select2_option_with_icon";
import { CSSProperties } from "vue";

export default defineComponent({
    components: {
        Time,
        ProgressBar,
        ActionIcon,
        Select2,
    },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            selected_project_id: current.project?.props?.id,
            selected_project_version_id: current.project_version?.props?.id,
            user: current.user,
            progress_bar,
            project_changed: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        UiSync() {
            return UiSync
        },
        is_main_web_type() {
            return web.is_main
        },
        Enum() {
            return Enum
        },
        current() {
            return current
        },
        selectable_projects(): Project[] {
            if (current.user == null) return EMPTY_ARRAY as Project[]
            return current.user.projects.order("full_name").toArray()
        },
        selectable_project_versions(): Record<number, ProjectVersion[]> {
            const selectable_project_versions: Record<number, ProjectVersion[]> = {}
            this.selectable_projects.each(project => {
                const project_id = project.key()
                if (project_id == null) {
                    selectable_project_versions[project_id] = EMPTY_ARRAY as ProjectVersion[]
                } else {
                    const project_versions = project.project_versions
                                                    .order("version")
                                                    .toArray()
                    selectable_project_versions[project_id] = project_versions.filter(project_version => {
                        if (current.user == null) return false
                        if (!project_version.props.is_private) return true
                        if (current.user.is_superadmin()) return true;
                        if (current.role_for(project_version.props.project_id) == Enum.User.Role.ADMIN) return true;
                        return project_version.props.user_id == current.user.key()
                    })
                }
            })
            return selectable_project_versions
        },
        number_of_other_windows() {
            return UiSync.other_webs().reject(web => web.is_web_worker).length
        },
        connection_under_load() {
            return current.websocket_status == "under-load" &&
                !this.connection_offline
        },
        connection_offline() {
            return !UiSync.computed.shared_worker_loaded || current.network_status == "offline" || current.websocket_status == "offline"
        },
        connection_status_message() {
            let message = `Shared worker: ${UiSync.computed.shared_worker_loaded ? 'online' : 'offline'}`
            message += `\nNetwork status: ${current.network_status}`
            message += `\nWebsocket status: ${current.websocket_status}`
            return message
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        selected_project_id: {
            handler() {
                console.log("PROJECT CHANGED");
                this.project_changed = true
                if (this.selected_project_id == null) {
                    this.selected_project_version_id = null
                } else {
                    this.selected_project_version_id = this.selectable_project_versions[this.selected_project_id].find(pv => pv.props.main).key()
                }

                this.on_project_or_version_change()
            },
            flush: "sync"
        },
        selected_project_version_id() {
            if (this.project_changed) return;
            if (this.selected_project_version_id == null) return;

            console.log("PROJECT VERSION CHANGED to:", this.selected_project_version_id);
            this.on_project_or_version_change()
        },
        "current.project.props.id"() {
            this.selected_project_id = current.project?.props?.id
        },
        "current.project_version.props.id"() {
            this.selected_project_version_id = current.project_version?.props?.id
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
    },
    unmounted() {
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        show_settings(item: string) {
            show_settings(item)
        },
        open_help() {
            window.open("/docs", '_blank');
        },
        logout() {
            // (this.$refs.logout as HTMLElement).click();
            User.ClientClass.sign_out()
        },
        on_project_or_version_change() {
            // load_project_version_resources(this.selected_project_id, this.selected_project_version_id)
            show_global_loader()
            window.location.href = `/?project_version_id=${this.selected_project_version_id}`
        },
        template_select_result_string() {
            return (data: LoadingData | GroupedDataFormat | DataFormat) => {
                const project_version_id = data.id
                if (project_version_id == null || project_version_id.toString().trim() == "") return "-- select project --"
                const project_version = ProjectVersion.find(project_version_id as number)
                const project = project_version?.project

                return `${project?.name()} - ${project_version?.name()}`
            }
        },
        style_project_version_option(data: LoadingData | GroupedDataFormat | DataFormat) {
            if (data.id == null) return data.text

            const project_version = this.selectable_project_versions[this.selected_project_id].find(pv => pv.key().toString() == data.id)
            const $container = $(`<span></span>`)
            const container_style: CSSProperties = {
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                color: project_version.props.is_private ? "var(--font-color-secondary)" : "var(--font-color)"
            }
            // @ts-ignore
            $container.css(container_style)
            const $text = $(`<span>${project_version.name()}</span>`)
            $container.append($text)

            return $container
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.header {
  height: 26px;
  width: 100vw;
  margin: 0;
  background-color: var(--secondary-background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.80em;
  z-index: 6; // must be bigger than z-index in scenario-editor overlay
  position: relative;

  .language-icon-container {
    margin-inline: 4px;
    font-size: 1.1em;
  }

  .project-select-container {
    margin-inline: 4px;
    width: 200px;
  }

  .project-version-select-container {
    margin-inline: 4px;
    width: 200px;
  }

  .role-container {
    margin-inline: 4px;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    span {
      margin-inline: 4px;
    }
  }

  .expander {
    width: 100%;
    flex-shrink: 9999;
  }

  .webs-counter-container {
    white-space: nowrap;
    margin-inline: 4px;
  }

  .connection-status {
    margin-inline: 4px;
    cursor: pointer;

    &.online {
      color: var(--button-green);
    }

    &.under-load {
      color: var(--button-orange);
    }

    &.offline {
      color: var(--button-red);
    }
  }

  .icons-container {
    font-size: 0.9em;
    display: flex;
    flex-direction: row;
    margin-inline: 4px;
  }
}
</style>
