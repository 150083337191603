import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d70d52b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "row",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_2 = {
  class: "col s4",
  style: {"display":"flex"}
}
const _hoisted_3 = { class: "col s4 center" }
const _hoisted_4 = { class: "worker-count-controls-container" }
const _hoisted_5 = { class: "count-tag-display" }
const _hoisted_6 = { id: "standby_workers_count" }
const _hoisted_7 = { id: "target_workers_count" }
const _hoisted_8 = { class: "col s4" }
const _hoisted_9 = ["data-priority", "onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["title", "innerHTML"]
const _hoisted_13 = ["colspan"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_ActionIcon = _resolveComponent("ActionIcon")
  const _component_Input = _resolveComponent("Input")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Table = _resolveComponent("Table")
  const _component_Loading = _resolveComponent("Loading")
  const _component_SpinnerSettingsEditModal = _resolveComponent("SpinnerSettingsEditModal")
  const _component_SettingsItem = _resolveComponent("SettingsItem")
  const _directive_moment = _resolveDirective("moment")

  return (_openBlock(), _createBlock(_component_SettingsItem, {
    id: "workers",
    title: _ctx.items.superadmin.workers.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            id: "worker_config_button",
            text: "Config",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.show_spinner_settings = true))
          }),
          (_ctx.deletion_markers.marked().length > 0)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                id: "delete_workers_button",
                text: "Delete",
                color_class: "red",
                onClick: _ctx.delete_workers
              }, null, 8 /* PROPS */, ["onClick"]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ActionIcon, {
              id: "decrease_worker_count_action",
              icon_class: "fa fa-minus",
              color_class: "red",
              title: "Decrease worker count",
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.change_worker_count(false)))
            }),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.standby_workers_count), 1 /* TEXT */),
                _createTextVNode(" / "),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.spinner_setting?.props?.workers), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, _toDisplayString(_ctx.spinner_setting?.props?.dj_tag), 1 /* TEXT */)
            ]),
            _createVNode(_component_ActionIcon, {
              id: "increase_worker_count_action",
              icon_class: "fa fa-plus",
              color_class: "green",
              title: "Increase worker count",
              onClick: _cache[2] || (_cache[2] = $event => (_ctx.change_worker_count(true)))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_Input, {
            id: "workers_filter",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.filter) = $event)),
            placeholder: "Filter",
            throttle_time: 200
          }, null, 8 /* PROPS */, ["modelValue"])
        ])
      ]),
      (_ctx.loaded)
        ? (_openBlock(), _createBlock(_component_Table, {
            key: 0,
            id: "workers_table"
          }, {
            thead: _withCtx(() => [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_columns, (col, key) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                    (key == 'delete')
                      ? (_openBlock(), _createElementBlock("th", {
                          key: 0,
                          class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                          onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.all_marked = !_ctx.all_marked), ["stop"]))
                        }, [
                          _createVNode(_component_Checkbox, {
                            modelValue: _ctx.all_marked,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.all_marked) = $event)),
                            label: "",
                            for_table: true,
                            color_class: "red"
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ], 2 /* CLASS */))
                      : (_openBlock(), _createElementBlock("th", {
                          key: 1,
                          class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                          "data-priority": _ctx.orders.priority(key),
                          onClick: $event => (_ctx.orders.toggle(key))
                        }, _toDisplayString(col.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_9))
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            tbody: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordered_rows, (row) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: row.record.key()
                }, [
                  _createElementVNode("tr", {
                    class: "worker-row",
                    onClick: $event => (_ctx.expanded_markers.marker(row.record).toggle())
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cols, (col, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (col.column_key == 'delete')
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 0,
                              class: _normalizeClass(col.classes),
                              onClick: _withModifiers($event => (_ctx.deletion_markers.marker(row.record).toggle()), ["stop"])
                            }, [
                              _createVNode(_component_Checkbox, {
                                modelValue: _ctx.deletion_markers.marker(row.record).value,
                                "onUpdate:modelValue": $event => ((_ctx.deletion_markers.marker(row.record).value) = $event),
                                label: "",
                                for_table: true,
                                color_class: "red"
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                            ], 10 /* CLASS, PROPS */, _hoisted_11))
                          : _withDirectives((_openBlock(), _createElementBlock("td", {
                              key: 1,
                              title: col.title,
                              class: _normalizeClass(col.classes),
                              style: _normalizeStyle(col.style),
                              innerHTML: col.html
                            }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_12)), [
                              [_directive_moment, col.moment]
                            ])
                      ], 64 /* STABLE_FRAGMENT */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 8 /* PROPS */, _hoisted_10),
                  (_ctx.expanded_markers.marker(row.record).value)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(row.record.state.logs, (log) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: log.key,
                          class: "worker-log-row"
                        }, [
                          _createElementVNode("td", {
                            colspan: row.cols.length
                          }, _toDisplayString(log.log), 9 /* TEXT, PROPS */, _hoisted_13)
                        ]))
                      }), 128 /* KEYED_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }))
        : (_openBlock(), _createBlock(_component_Loading, {
            key: 1,
            type: "rotating_plane",
            inflate: true,
            size: 5
          })),
      (_ctx.show_spinner_settings)
        ? (_openBlock(), _createBlock(_component_SpinnerSettingsEditModal, {
            key: 2,
            spinner_setting: _ctx.spinner_setting,
            onExit: _cache[6] || (_cache[6] = $event => (_ctx.show_spinner_settings = false))
          }, null, 8 /* PROPS */, ["spinner_setting"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}