import { DirectiveBinding } from "vue";
import { pretty_duration } from "../../helpers/pretty/pretty_duration";
import { what_is_it } from "../../helpers/generic/what_is_it";

function get_update_frequency(since: number): number {
    const current = new Date().valueOf()
    const diff = Math.abs(current - since);
    if (diff < 1000) {
        // if less than 1 second, update every 100ms
        return 100
    } else if (diff < 3600000) {
        // if less than 1 hour, update every second because up until 1 hour, we show minutes and seconds
        return 1000
    } else if (diff < 86400000) {
        // if less than 1 day, update every minute, because up until 1 day we show hours and minutes
        return 60000
    } else {
        // else update every hour
        return 3600000
    }
}

export interface VDuration {
    start: Date
    end?: Date
}

function ensure_correct_type(binding: DirectiveBinding<VDuration>) {
    if (what_is_it(binding.value.start) == "String") {
        binding.value.start = new Date(binding.value.start)
    }

    if (what_is_it(binding.value.end) == "String") {
        binding.value.end = new Date(binding.value.end)
    }
}

export var v_duration = {
    mounted(el: any, binding: DirectiveBinding<VDuration>) {
        if (binding.value == null) return;
        if (binding.value.start == null) return;
        ensure_correct_type(binding)

        const start = binding.value.start.getTime()
        if (binding.value.end != null) {
            const end = binding.value.end.getTime()
            el.innerText = pretty_duration(end - start)
        } else {
            const end = new Date().getTime();
            el.innerText = pretty_duration(end - start)
            el.duration_interval = setInterval(() => {
                const end = new Date().getTime();
                el.innerText = pretty_duration(end - start)
            }, get_update_frequency(start))
        }
    },
    updated(el: any, binding: DirectiveBinding<VDuration>, _vnode: any, _prevNode: any) {
        if (el.duration_interval) clearInterval(el.duration_interval)
        if (binding.value == null) return;
        if (binding.value.start == null) {
            el.innerText = "";
            return;
        }
        ensure_correct_type(binding)

        const start = binding.value.start.getTime()
        if (binding.value.end != null) {
            const end = binding.value.end.getTime()
            el.innerText = pretty_duration(end - start)
        } else {
            const end = new Date().getTime();
            el.innerText = pretty_duration(end - start)
            el.duration_interval = setInterval(() => {
                const end = new Date().getTime();
                el.innerText = pretty_duration(end - start)
            }, get_update_frequency(start))
        }
    },
    unmounted(el: any, _binding: any) {
        if (el.duration_interval) clearInterval(el.duration_interval)
    }
}
