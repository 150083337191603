<template>
  <div class="play-node-container"
       :class="{'in-debug': is_in_debugging()}"
       @mousedown="activate_debug_scenario"
  >
    <div ref="play_scenario"
         class="row-container"
         :style="row_container_style"
    >
      <div class="expander click-item"
           @click.stop="toggle_expanded">
        <i v-if="expanded"
           class="fas fa-chevron-down"/>
        <i v-else
           class="fas fa-chevron-right"/>
      </div>

      <div class="title-container click-item"
           @click="toggle_expanded">
        <span class="title-item icon">
          <PlayStatusIcon :play_status="props.status"/>
        </span>
        <span class="title-item icon">
          <PlayTypeIcon :play_type="Enum.Play.Type.SCENARIO"/>
        </span>
        <div
            class="title-item progress-container"
            :style="progress_background()"
        >
          <span class="title title-item">
            {{ props.name }}
          </span>
          <span class="title-item status-container">
            <span class="status-note"
                  :style="`color: ${status_color()};`"
                  style="margin-right: 4px;">
              {{ props.status_note }}
            </span>
          </span>
          <span v-if="props.status_screenshot_url"
                class="title-item status-screenshot">
            <ScreenshotIcon
                :url="props.status_screenshot_url"
                :main_web_available="main_web_available"
                :role="role"
                :project_version_setting="project_version_setting"
            />
          </span>
        </div>
        <span v-if="props.start_time"
              v-time="props.start_time"
              class="title-item start-time"/>
        <span v-if="props.start_time && props['main?']"
              v-datetime="{datetime: props.start_time, strftime: '%a, %-d %b \'%y'}"
              class="title-item start-date"/>
        <span v-if="props.start_time"
              v-duration="{start: props.start_time, end: props.end_time}"
              class="title-item duration"
              :title="moment(props.end_time).strftime('%H:%M:%S %a, %-d %b \'%y')"
        />
      </div>

      <div class="action-container">
        <ActionIcon
            v-if="props['main?'] && has_stream()"
            :icon_class="stream_shown ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
            :title="`Show ${props['finished?'] ? 'video' : 'stream'}`"
            margin="0"
            @click="toggle_stream()"
        />
        <ActionIcon
            v-if="props['main?']"
            icon_class="fa-solid fa-align-left"
            title="Download logs"
            color_class="blue"
            margin="0"
            @click="download_logs"
        />
        <ActionIcon
            v-if="props['main?'] && play.is_group_type() && !props['finished?']"
            icon_class="fa-solid fa-step-forward"
            title="Skip scenario"
            margin="0"
            @click="skip"
        />
        <ActionIcon
            v-if="props['main?'] && (!is_in_debugging() && !props['finished?']) && !is_single_worker"
            icon_class="fa-solid fa-pause"
            title="Pause execution"
            color_class="blue"
            margin="0"
            @click="pause"
        />
        <ActionIcon
            v-if="props['main?'] && is_in_debugging() && !is_single_worker"
            icon_class="fa-solid fa-play"
            title="Resume execution"
            color_class="green"
            margin="0"
            @click="pause"
        />
        <ActionIcon
            v-if="main_web_available"
            icon_class="fa-solid fa-bullseye"
            title="Show scenario in sidebar"
            color_class="var(--scenario-color)"
            margin="0"
            @click="show_target"
        />
      </div>
    </div>

    <div v-if="expanded"
         class="play-content-container"
         :class="{'in-debug': is_in_debugging()}">
      <Loading
          v-if="!data_loaded"
          type="three_bounce"
          :size="4"
      />
      <template v-else>
        <div v-if="props['main?']"
             class="collapsed-play-scenario-logs"
             style="flex-direction: row; display: flex;">
          <div v-memo="[props.status]"
               :style="`color: ${scenario_logs_collapsed_color}`"
               @click="toggle_expanded_play_scenario_logs">
            ( info )
          </div>
          <div v-show="has_performance_data"
               :style="`color: pink`"
               @click="toggle_show_mobile_performance_metrics()">
            ( memory )
          </div>
        </div>
        <PlayScenarioMobilePerformanceMetrics
            v-if="props['main?'] && data_loaded"
            v-show="show_mobile_performance_metrics"
            :metrics_shown="show_mobile_performance_metrics"
            :play_scenario="play_scenario"
            @chart-count-changed="(count) => has_performance_data = count > 0"
        />
        <div v-if="expanded_play_scenario_logs || started_play_nodes.length == 0"
             class="expanded-play-scenario-logs-info-container"
             style="cursor: pointer"
             @click="toggle_expanded_play_scenario_logs">

          <div class="play-scenario-logs">
            <PlayLogReport
                v-for="play_log in displayable_play_logs"
                :key="play_log.props.id"
                :play="play"
                :play_scenario="play_scenario"
                :play_log="play_log"
                :role="role"
                :main_web_available="main_web_available"
                :project_version_setting="project_version_setting"
                @play-log-mounted="on_play_log_mounted"
            />
          </div>
          <!--    added style="display: block" because to float into to top-->
          <!--    else it inherits display flex and floats to bottom :) -->
          <ScenarioSettingInfo v-if="scenario_setting != null && props['main?'] && data_loaded"
                               class="play-scenario-info"
                               style="display: block"
                               :scenario_setting="scenario_setting"
                               :play_setting="play_setting"
                               :status="props.status"
          />

        </div>
        <template
            v-for="play_node in started_play_nodes"
            :key="play_node_id(play_node)">

          <PlayScenarioReport
              v-if="play_node_is_scenario(play_node)"
              :ref="`play_scenario_${play_node.props.id}`"
              :play="play"
              :play_scenario="play_node_to_play_scenario(play_node)"
              :parent_play_scenario="play_scenario"
              :expanded="expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] === true"
              :play_setting="play_setting"
              :role="role"
              :is_viewer="is_viewer"
              :parents="parents + 1"
              :main_web_available="main_web_available"
              is_single_worker="is_single_worker"
              @toggle-expand="() => expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] = !expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`]"
              @play-log-mounted="on_play_log_mounted"
              @disable-sticky-scroll="$emit('disable-sticky-scroll')"
              @play-debugger-mounted="(play_snippet) => $emit('play-debugger-mounted', play_snippet)"
          />

          <PlaySnippetReport
              v-if="!play_node_is_scenario(play_node)"
              :ref="`play_snippet_${play_node.props.id}`"
              :play_scenario="play_scenario"
              :play_snippet="play_node_to_play_snippet(play_node)"
              :play="play"
              :main_web_available="main_web_available"
              :project_version_setting="project_version_setting"
              :role="role"
              :is_viewer="is_viewer"
              :parents="parents + 1"
              :expanded="expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] === true"
              @toggle-expand="() => expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] = !expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`]"
              @play-log-mounted="on_play_log_mounted"
              @disable-sticky-scroll="$emit('disable-sticky-scroll')"
              @finished="on_finish_auto_collapse"
              @play-debugger-mounted="(play_snippet) => $emit('play-debugger-mounted', play_snippet)"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<!--suppress JSCheckFunctionSignatures -->
<script lang="ts">
import PlayLogReport from '../play_log/PlayLogReport.vue';
import { defineComponent } from "vue";
import { PropType } from "vue";
import { PlayScenario } from '../../../../vue_record/models/play/play_scenario';
import { Play } from '../../../../vue_record/models/play/play';
import ScreenshotIcon from "../../other/ScreenshotIcon.vue";
import { PlaySetting } from '../../../../vue_record/models/play/play_setting';
import PlayTypeIcon from "../../other/PlayTypeIcon.vue";
import { PlaySnippet } from "../../../../vue_record/models/play/play_snippet";
import { PlayNode } from "../../../../types/play_node";
import { PlayLog } from "../../../../vue_record/models/play/play_log";
import { PlayStatus } from "../../../../helpers/play/play_status";
import { set_expanded_play_node_states } from "../../../../helpers/play/set_expanded_play_node_states";
import PlaySnippetReport from "../play_snippet/PlaySnippetReport.vue";
import ScenarioSettingInfo from "./ScenarioSettingInfo.vue";
import PlayStatusIcon from "../../other/PlayStatusIcon.vue";
import Loading from "../../../testa/Loading.vue";
import ActionIcon from "../../../testa/ActionIcon.vue";
import { EnumUserRole } from "../../../../auto_generated/enums";
import { Scenario } from "../../../../vue_record/models/scenario";
import { ScenarioSavepoint } from "../../../../vue_record/models/scenario_savepoint";
import PlayScenarioMobilePerformanceMetrics from "./PlayScenarioMobilePerformanceMetrics.vue";
import { CSSProperties } from "vue";
import { nextTick } from "vue";


export default defineComponent({
    duration_refresher: null as NodeJS.Timer,
    components: {
        PlayScenarioMobilePerformanceMetrics,
        ActionIcon,
        Loading,
        PlayStatusIcon,
        ScenarioSettingInfo,
        PlayLogReport,
        PlaySnippetReport,
        ScreenshotIcon,
        PlayTypeIcon,
    },
    props: {
        parent_play_scenario: {
            type: Object as PropType<PlayScenario>,
            required: false,
            default: null,
        },
        play_scenario: {
            type: Object as PropType<PlayScenario>,
            required: true,
        },
        play: {
            type: Object as PropType<Play>,
            required: true,
        },
        play_setting: {
            type: Object as PropType<PlaySetting>,
            required: false,
            default: null,
        },
        expanded: {
            type: Boolean,
            required: true,
        },
        is_single_worker: {
            type: Boolean,
            required: true,
        },
        main_web_available: {
            type: Boolean,
            required: true
        },
        role: {
            type: String as PropType<EnumUserRole>,
            required: false,
            default: null
        },
        is_viewer: {
            type: Boolean,
            required: true
        },
        parents: {
            type: Number,
            required: true
        }
    },
    emits: ['toggle-expand', 'play-log-mounted', 'disable-sticky-scroll', 'play-debugger-mounted'],
    data() {
        return {
            expanded_play_scenario_logs: false,
            show_mobile_performance_metrics: false,
            expanded_play_nodes: {} as Record<string, boolean>,
            data_loaded: false,
            stream_tab: null,
            has_performance_data: false,
        }
    },
    computed: {
        moment() {
            return moment
        },
        PlaySnippet() {
            return PlaySnippet
        },
        scenario_setting() {
            return this.play_scenario.scenario_setting
        },
        Enum() {
            return Enum
        },
        play_stream_manager() {
            return this.play.state.play_stream_manager
        },
        play_nodes() {
            return this.play_scenario.play_nodes
        },
        play_logs() {
            return this.play_scenario.play_logs
        },
        props() {
            return this.play_scenario.props;
        },
        project_version_setting() {
            return this.play_scenario.main_play_scenario.plays.toArray()[0].project_version.project_version_setting
        },
        started_play_nodes(): Array<PlayScenario | PlaySnippet> {
            return (this.play_nodes as PlayNode[]).filter(play_node => {
                return Enum.Play.Status.started_statuses.some(status => status === play_node.props.status)
            })
        },
        displayable_play_logs() {
            return this.play_logs
                       .only_in_play_scenario
                       .not({ type: Enum.Play.Log.COMMAND })
                       .toArray();
        },
        scenario_logs_collapsed_color(): string {
            if (this.displayable_play_logs.some(l => l.props.type == "fatal")) return "var(--log-color-fatal)"
            if (this.displayable_play_logs.some(l => l.props.type == "error")) return "var(--log-color-error)"
            if (this.displayable_play_logs.some(l => l.props.type == "warn")) return "var(--log-color-warning)"
            return "var(--log-color-verbose)"
        },
        type() {
            return this.play.props.type;
        },
        scenario_savepoint() {
            return this.play_scenario.scenario_savepoint;
        },
        stream_shown() {
            return this.play_stream_manager.is_play_scenario_stream_shown(this.play_scenario)
        },
        current() {
            return current
        },
        row_container_style() {
            const style: CSSProperties = {}

            if (this.expanded) {
                style.position = "sticky"
                style.top = this.parents * 27
                style.zIndex = Math.max(9 - this.parents, 1)
                style.borderBottom = "solid 1px var(--scenario-color)"
            }
            return style
        }
    },
    watch: {
        'play_scenario.props.private_novnc_url'(new_val, old_val) {
            if (this.props['finished?']) return;

            if (old_val == null && new_val != null && this.play_stream_manager.state.auto_show_vnc) {
                if (!this.stream_shown) this.play_scenario.show_stream(this.play_stream_manager)
            }
        },
        'play_scenario.props.public_novnc_url'(new_val, old_val) {
            if (this.props['finished?']) return;

            if (old_val == null && new_val != null && this.play_stream_manager.state.auto_show_vnc) {
                if (!this.stream_shown) this.play_scenario.show_stream(this.play_stream_manager)
            }
        },
        'play_scenario.props.finished?'(new_val, old_val) {
            if (old_val == false && new_val == true) {
                this.play_scenario.end_stream(this.play_stream_manager)
            }
        },
        expanded: {
            handler(new_val, old_val) {
                console.log(`Expanded for play scenario changed from ${old_val} to ${new_val}`);
                if (this.expanded && !this.data_loaded) this.load_data()
            },
            flush: "sync",
            immediate: true,
        }
    },
    beforeCreate() {
    },
    beforeMount() {
        this.set_expanded_play_nodes_state()
    },
    mounted() {
        console.log("Play scenario mounted");
        // @ts-ignore - is assigned to div for easier referencing
        this.$refs.play_scenario.play_scenario = this.play_scenario
        if (!this.stream_shown && this.play_stream_manager.state.auto_show_vnc && !this.play_scenario.is_finished()) {
            this.play_scenario.show_stream(this.play_stream_manager)
        }
    },
    beforeUpdate() {
        this.set_expanded_play_nodes_state()
    },
    updated() {
    },
    methods: {
        on_play_log_mounted(play_log: PlayLog) {
            this.$emit('play-log-mounted', play_log)
        },
        play_node_is_scenario(play_node: PlayNode) {
            return play_node instanceof PlayScenario;
        },
        /** Just casting the type to PlayScenario, since we cannot do any Typescript in template */
        play_node_to_play_scenario(play_node: PlayNode) {
            return play_node as PlayScenario
        },
        play_node_to_play_snippet(play_node: PlayNode): PlaySnippet {
            return play_node as PlaySnippet
        },
        play_node_id(play_node: PlayNode) {
            return `${this.play_node_is_scenario(play_node) ? 'scenario_' : 'snippet_'}${play_node.props.id}`
        },
        toggle_expanded() {
            this.$emit('disable-sticky-scroll')
            this.$emit('toggle-expand')
        },
        toggle_expanded_play_scenario_logs() {
            this.$emit('disable-sticky-scroll')
            this.expanded_play_scenario_logs = !this.expanded_play_scenario_logs;
        },
        toggle_show_mobile_performance_metrics() {
            this.$emit('disable-sticky-scroll')
            this.show_mobile_performance_metrics = !this.show_mobile_performance_metrics;
        },
        has_stream() {
            return this.play_scenario.has_stream()
        },
        toggle_stream() {
            this.play_stream_manager.toggle_stream(this.play_scenario)
        },
        download_logs() {
            $.fileDownload(`/play/reports/play_scenarios/${this.props.id}/logs/download`);
        },
        is_in_progress() {
            return this.props.status == Enum.Play.Status.IN_PROGRESS
        },
        is_in_debugging() {
            return this.props.status == Enum.Play.Status.DEBUGGING
        },
        pause() {
            this.play_scenario.pause()
        },
        resume() {
            this.play_scenario.resume();
        },
        skip() {
            this.play_scenario.skip();
        },
        show_target() {
            ScenarioSavepoint.find_or_load(this.play_scenario.props.scenario_savepoint_id)
                             .then(scenario_savepoint => {
                                 Scenario.show_in_sidebar(scenario_savepoint.props.scenario_id, this.project_version_setting.props.project_version_id)
                             })
        },
        progress_background() {
            if (this.props['finished?']) return 'background: transparent';

            const p = this.props.progress * 100
            return `background: linear-gradient(90deg, var(--play-progress-color) 0 ${p}%, transparent ${p}% 100%);`
        },
        load_data() {
            this.play_scenario
                .client
                .load_associated()
                .then(() => this.data_loaded = true)
        },
        status_color() {
            return PlayStatus.color(this.props.status);
        },
        set_expanded_play_nodes_state() {
            const play_nodes = this.started_play_nodes
            set_expanded_play_node_states(this.expanded_play_nodes, play_nodes, true);
        },
        on_finish_auto_collapse(play_node: PlayNode) {
            this.expanded_play_nodes[`${play_node.constructor.name}_${play_node.props.id}`] = false
        },
        activate_debug_scenario(e: MouseEvent) {
            if (!this.props["main?"]) return;
            if (!this.play_scenario.is_debugging()) return;

            this.play_scenario.state.debugger_last_activation = new Date();
            e.stopPropagation()
        }
    },
})
</script>

<style lang="scss">

.play-content-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  &.in-debug {
    height: 100%;
  }
}

.play-node-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--font-color);
  position: relative;

  &.in-debug {
    height: 100%;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    padding-left: 3px;
    height: 27px;
    align-items: center;
    justify-content: flex-start;
    background: var(--primary-background-color);

    .click-item {
      cursor: pointer;

      &:hover {
        color: var(--font-color-hover);
        filter: brightness(1.2);
      }
    }

    .expander {
      display: flex;
      justify-content: center;
      width: 20px;
      flex-shrink: 0;
      font-size: 0.65em;
      padding-bottom: 2px;
      color: var(--font-color-secondary)
    }

    .title-container {
      display: flex;
      flex-direction: row;
      padding-left: 2px;
      padding-right: 2px;
      width: 100%;
      flex-shrink: 1;
      align-items: center;
      justify-content: flex-start;
      min-width: 0;
      overflow: hidden;
      container-type: inline-size;

      .progress-container {
        width: 100%;
        min-width: 0;
        display: flex;

        .title {
          flex-shrink: 0;
          //width: 100%;
          display: flex;
          font-size: 1.25em;
          white-space: pre;
          max-width: 95%;
          overflow: hidden;

          &:hover {
            text-shadow: var(--text-shadow-hover);
          }
        }

        .status-container {
          flex-shrink: 2;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          //text-align: right;

          display: none;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row-reverse;

          .status-note {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 4px;
          }
        }

        .status-screenshot {
          display: none;
          align-self: center;
          font-size: 1.05em;
        }
      }

      /* If the container is larger than 500px. show status notes */
      @container (min-width: 500px) {
        .progress-container {
          .title {
            max-width: 70%
          }

          .status-container {
            display: flex;
            padding-right: 0;
          }

          .status-screenshot {
            display: flex;
          }
        }
      }

      .title-item {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 0.8em;
        min-width: 0;

        &.icon {
          flex-shrink: 0;
          font-size: 0.8em;
        }

        &.start-date {
          flex-shrink: 0;
          display: none;
        }

        /* If the container is larger than 600px. show datetime, and hide time */
        @container (min-width: 600px) {
          &.start-date {
            display: flex;
          }
        }

        &.start-time {
          flex-shrink: 0;
        }

        &.duration {
          width: 50px;
          white-space: nowrap;
          flex-shrink: 0;
          text-align: right;
          min-width: 20px;
        }

        &.flex-expander {
          width: 100%;
          flex-shrink: 999;
        }
      }
    }

    .action-container {
      flex-shrink: 0;
      padding-right: 2px;
      display: inline-flex;
      font-size: 0.7em;
      align-items: baseline;
    }
  }
}

.expanded-play-scenario-logs-info-container {
  display: flex;
  flex-direction: row;

  .play-scenario-logs {
    max-width: 70%;
    width: 70%;
    flex-shrink: 0.8;
  }

  .play-scenario-info {
    width: 100%;
    text-align: right;
  }
}

.collapsed-play-scenario-logs {
  color: var(--log-color-verbose);
  display: table-row;
  font-size: 9px;
  line-height: 13px;
  white-space: pre-wrap;
  font-family: monospace;
  letter-spacing: -0.05em;
  cursor: pointer;

  &:hover {
    color: var(--font-color-hover);
    filter: brightness(1.2);
  }
}
</style>

