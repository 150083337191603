import { ProjectVersion } from "../models/project_version";
import { FileFolder } from "../models/file_folder";
import { File } from "../models/file";


declare module "../models/file" {
    interface File {
        project_version: ProjectVersion
        file_folder: FileFolder
    }
}

File.belongs_to("project_version", ProjectVersion, "project_version_id")
File.belongs_to("file_folder", FileFolder, "file_folder_path")
