<template>
  <template v-if="show_project_edit">
    <SettingsItem
        :id="`edit_project_${project.props.full_name}`"
        :title="`Edit ${project.props.full_name}`">
      <div class="project-edit">
        <div class="header">
          <Button
              text="Back"
              tab_index="1"
              @click="back"
          />
          <Button
              v-if="!is_viewer_role && !is_user_role"
              text="Save"
              tab_index="2"
              :form_validator="form_validator"
              :click_action="save"
          />
          <div class="flex-expander"/>
          <Button
              v-if="!is_viewer_role && !is_user_role"
              text="Delete"
              tab_index="3"
              color_class="red"
              @click="delete_project"
          />
        </div>
        <div class="body">
          <div class="row">
            <div class="col s6">
              <Input id="project_edit_full_name"
                     v-model="update_project_props.full_name"
                     :validator="form_validator.register('full_name', { presence: true })"
                     label="Full Name"/>
            </div>
            <div class="col s6">
              <Input id="project_edit_short_name"
                     v-model="update_project_props.short_name"
                     :validator="form_validator.register('short_name', { presence: true })"
                     label="Short Name"/>
            </div>
          </div>
          <div class="row"
               style="margin-top: 50px">
            <div class="col s8">
              <Button
                  v-if="!is_viewer_role && !is_user_role"
                  text="New Version"
                  tab_index="1"
                  @click="new_project_version"
              />
            </div>
            <div class="col s4">
              <Input
                  v-model="versions_filter"
                  placeholder="Filter"
                  :throttle_time="200"
              />
            </div>
          </div>
          <ProjectVersionsTable
              id="project_project_versions_table"
              :project="project"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :filter="versions_filter"
              @project-version-click="project_version_click"
          />
        </div>
      </div>

      <div v-if="show_new_project_version">
        <NewProjectVersionForm
            :for_project="project"
            @close="show_new_project_version = false"/>
      </div>
    </SettingsItem>
  </template>

  <template v-else-if="edit_project_version != null">
    <ProjectVersionEdit
        :project_version="edit_project_version"
        :is_viewer_role="is_viewer_role"
        :is_user_role="is_user_role"
        :form_validator="form_validator.register_child_form('project_version_edit')"
        @exit="show_project_edit=true"
        @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
        @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
    />
  </template>


</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import Button from "../../../testa/Button.vue";
import Input from "../../../testa/Input.vue";
import { Project } from "../../../../vue_record/models/project";
import ProjectVersionsTable from "../../project_settings/./project_versions/ProjectVersionsTable.vue";
import NewProjectVersionForm from "../../project_settings/./project_versions/NewProjectVersionForm.vue";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import ProjectVersionEdit from "../../project_settings/./project_versions/ProjectVersionEdit.vue";
import SettingsItem from "../../SettingsItem.vue";
import toastr from "toastr";
import { ProjectQueryProps } from "../../../../vue_record/models/project";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        SettingsItem,
        ProjectVersionEdit,
        NewProjectVersionForm,
        ProjectVersionsTable,
        Input,
        Button,
    },
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['exit', 'setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            update_project_props: {
                id: this.project.props.id,
                full_name: this.project.props.full_name,
                short_name: this.project.props.short_name,
            } as ProjectQueryProps,
            show_new_project_version: false,
            versions_filter: "",
            edit_project_version: null,
            show_project_edit: true,
        }
    },
    computed: {
    },
    watch: {},
    mounted() {
        this.$emit("setting-item-mounted", this)
        this.project.on("before_unload", this.back)
    },
    unmounted() {
        this.form_validator.unregister()
        this.$emit("setting-item-unmounted", this)
        this.project.off("before_unload", this.back)
    },
    methods: {
        save() {
            const promise = this.apply()
            $.when(promise).then(() => {
                this.back()
            })
            return promise
        },
        apply() {
            if (!this.is_viewer_role && !this.is_user_role) return null

            return this.project
                       .client
                       .update(this.update_project_props)
                       .catch(() => {
                           toastr.error("Failed to save project")
                       })
        },
        delete_project() {
            this.project.delete().then((is_confirmed: Boolean) => {
                if (is_confirmed) this.back()
            })
        },
        back() {
            this.$emit('exit')
        },
        new_project_version() {
            this.show_new_project_version = true
        },
        project_version_click(project_version: ProjectVersion) {
            this.edit_project_version = project_version
            this.show_project_edit = false
        },
    },
})
</script>

<style lang="scss" scoped>
.project-edit {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .body {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
  }
}

</style>
