import { watch } from "vue";

export function watch_style_changes(cm: CodeMirror.Editor) {
    return watch(() => [
        current.project.props.indentation_style,
        current.project.computed.indent_unit,
        ], () => {
            cm.setOption("indentWithTabs", current.project.props.indentation_style == "tabs")
            cm.setOption("indentUnit", current.project.computed.indent_unit)
        })
}
