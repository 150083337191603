<template>
  <ActionIcon
      icon_class="fa-solid fa-down-left-and-up-right-to-center fa-rotate-135"
      :scale="0.85"
      title="Collapse all"
      @click="project_tree.collapse_all()"
  />
  <ActionIcon
      title="Show active record"
      icon_class="fa-solid fa-bullseye"
      @click="project_tree.show_active_tab()"
  />

  <ActionIcon
      v-if="current.project_version != null"
      icon_class="fa-solid fa-magnifying-glass"
      title="Search (double-shift)"
      @click="do_search_record"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ActionIcon from "../../../ActionIcon.vue";
import { show_search_record } from "../../../../../helpers/vue/show_search_record";
import { TestaTree } from "../../../tree/tree";
import { PropType } from "vue";
import { ProjectVersion } from "../../../../../vue_record/models/project_version";

export default defineComponent({
    components: { ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: false,
            default: null
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            project_tree: TestaTree.Tree.get_project_tree()
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        do_search_record() {
            show_search_record(this.project_version)
        }

    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
