import { VueRecordScope } from "../base/vue_record_scope";
import { Scenario } from "../models/scenario";
import { computed } from "../../helpers/vue/computed";
import { ScenarioSetting } from "../models/scenario_setting";

export class ScenarioScope extends VueRecordScope {
    ['constructor']: typeof ScenarioScope
    static ModelClass: typeof Scenario
    declare rthis: ScenarioScope

    scenario_settings = computed(() => {
        const scope = ScenarioSetting.where({ scenario_id: this.rthis.keys() })
        if (this.is_stage) scope.stage(this.stage_id)
        return scope
    })
}
