import { reactive } from "vue";
import { Splitter } from "./splitter";
import { Tab } from "./tab";
import { TabManager } from "./tab_manager";
import { safe_stringify } from "../../../helpers/generic/safe_stringify";
import { SplitterRegion } from "./splitter_region";
import { EventBus } from "../../../helpers/event_bus";
import _ from "lodash";
import { Size } from "../../../types/globals";
import { ScenarioBuilderTab } from "./tabs/scenario_builder_tab";
import { PlayScenarioNovncTab } from "./tabs/play_scenario_novnc_tab";
import { PlayScenarioRecordingTab } from "./tabs/play_scenario_recording_tab";
import { Storager } from "../../../helpers/api_wrappers/storager";
import { computed } from "../../../helpers/vue/computed";
import { VueRecord } from "../../../vue_record/base/vue_record";

export namespace Editor {
    export type Events = "empty" | "not-empty" | "set_size"
}
type Computed = {
    tabs: Tab[],
    active_tab: Tab,
    active_tabs: Tab[],
    storager: Storager
}

export class Editor {
    static editors: Record<string, Editor> = reactive({})
    static loading_ids: string[] = []

    id: string

    state = reactive({
        splitter: null as Splitter,
    })

    computed: Computed

    event_bus: EventBus<Editor.Events, Editor>
    splitter_event_bus: EventBus<Splitter.Events, Splitter>
    tab_manager_event_bus: EventBus<TabManager.Events, TabManager>
    tab_event_bus: EventBus<Tab.Events, Tab>

    constructor(id: string, splitter: Splitter = null) {
        this.id = id
        if (splitter == null) {
            this.state.splitter = new Splitter({
                id: `${id}_root_splitter`,
                parent: this,
            })
        } else {
            splitter.parent = this
            this.state.splitter = splitter
        }

        const storager = computed(() => current.storagers.user_web_type.new_scope("editor", this.id))

        this.event_bus = new EventBus<Editor.Events, Editor>();
        this.splitter_event_bus = new EventBus<Splitter.Events, Splitter>()
        this.tab_event_bus = new EventBus<Tab.Events, Tab>()
        this.tab_manager_event_bus = new EventBus<TabManager.Events, TabManager>();
        this._register_default_event_handlers();

        const tabs = computed(() => {
            if (this.state.splitter == null) return [];
            return this.state.splitter.computed.tabs;
        }) as Tab[]

        const active_tab = computed(() => {
            if (this.state.splitter == null) return null;
            return this.state.splitter.computed.active_tab;
        }) as Tab

        const active_tabs = computed(() => {
            if (this.state.splitter == null) return [];
            return this.state.splitter.get_tab_managers().map(tab_manager => tab_manager.state.active_tab as Tab)
        }) as Tab[]

        this.computed = reactive({
            tabs,
            active_tab,
            active_tabs,
            storager
        }) as Computed
    }

    // <editor-fold desc="EVENT HANDLERS">
    // </editor-fold>

    // <editor-fold desc="TAB MANAGEMENT">
    add_tab(tab: Tab, to_main = true) {
        this.state.splitter.add_tab(tab, to_main)
    }

    create_tab(data: Tab.CreateInput, to_main = true) {
        return this.state.splitter.create_tab(data, to_main)
    }

    find_tab(id: string) {
        return this.state.splitter.find_tab(id)
    }

    static find_tab(id: string) {
        let found;
        found = this.get_main()?.find_tab(id)
        if (found) return found

        found = this.get_footer()?.find_tab(id)
        if (found) return found

        found = this.get_scenario()?.find_tab(id)
        if (found) return found

        return null;
    }

    static active_tab() {
        const tabs: Tab[] = []
        if (this.get_main() != null && this.get_main().computed.active_tab != null) tabs.push(this.get_main().computed.active_tab)
        if (this.get_footer() != null && this.get_footer().computed.active_tab != null) tabs.push(this.get_footer().computed.active_tab)
        if (this.get_scenario() != null && this.get_scenario().computed.active_tab != null) tabs.push(this.get_scenario().computed.active_tab)

        return _.sortBy(tabs.filter(t => t.state.last_activation != null), (tab) => tab.state.last_activation).pop()
    }

    get_tabs() {
        return this.computed.tabs;
    }

    static get_tabs() {
        let tabs: Tab[] = []
        if (this.get_main() != null) tabs = tabs.concat(this.get_main().computed.tabs)
        if (this.get_footer() != null) tabs = tabs.concat(this.get_footer().computed.tabs)
        if (this.get_scenario() != null) tabs = tabs.concat(this.get_scenario().computed.tabs)

        return tabs;
    }

    static get_play_stream_tabs() {
        return Editor.get_tabs().filter(t => t instanceof PlayScenarioNovncTab || t instanceof PlayScenarioRecordingTab) as (PlayScenarioNovncTab | PlayScenarioRecordingTab)[]
    }

    on(event: Editor.Events, callback: (splitter: Editor, data: any) => any) {
        this.event_bus.$on(event, callback)
    }

    on_splitter(event: Splitter.Events, callback: (splitter: Splitter, data: any) => any) {
        this.splitter_event_bus.$on(event, callback)
    }

    on_tab(event: Tab.Events, callback: (tab: Tab, data: any) => any) {
        this.tab_event_bus.$on(event, callback)
    }

    on_tab_manager(event: TabManager.Events, callback: (tab_manager: TabManager, data: any) => any) {
        this.tab_manager_event_bus.$on(event, callback)
    }

    set_size(size: Size) {
        this.event_bus.$emit("set_size", this, size)
    }
    // </editor-fold>

    // <editor-fold desc="PERSISTENCE">
    save() {
        const state = JSON.stringify(this, function(key, value) {
            if (key == "parent") return;
            if (key == "tab_event_bus") return;
            if (key == "eventListeners") return;
            if (key == "event_bus") return;
            if (key == "splitter_event_bus") return;
            if (key == "tab_manager_event_bus") return;
            if (key == "resizable_grid") return;
            if (key == "resizable_flex") return;

            if (this instanceof Tab) {
                if (key == "input" || key == "type" || key == "id") {
                    return value
                } else {
                    return;
                }
            }
            if (this instanceof SplitterRegion) {
                if (key == "splitter") return;
            }
            if (value instanceof VueRecord) return safe_stringify(value)
            return value;
        });

        this.computed.storager.set("state", state)
        return state
    }

    static load(id: string): Editor {
        try {
            const load_tab = (tab_serialized: Tab) => {
                const TabClass = Tab.registered_tab_types.find(TabClass => TabClass.type == tab_serialized.type)

                if (TabClass != null) {
                    return TabClass.new(tab_serialized.input as any)
                } else {
                    return null
                }
            }

            const load_tab_manager = (tab_manager_serialized: TabManager, splitter_region: SplitterRegion): TabManager => {
                const tabs = tab_manager_serialized.state
                                                   .tabs
                                                   .map(t => load_tab(t))
                                                   .filter(t => t != null)
                return new TabManager({
                    max_tabs: tab_manager_serialized.state.max_tabs,
                    sort: tab_manager_serialized.state.sort,
                    id: tab_manager_serialized.id,
                    position: tab_manager_serialized.state.position,
                    single_mode: tab_manager_serialized.state.single_mode,
                    tabs,
                    active_tab_id: tab_manager_serialized.state.active_tab?.id,
                    parent: splitter_region,
                })
            }

            const load_splitter = (splitter_serialized: Splitter, parent_instance: SplitterRegion | Editor): Splitter => {
                const load_main_or_other = (main_or_other: TabManager | Splitter, splitter_instance: SplitterRegion): TabManager | Splitter => {
                    if (main_or_other != null) {
                        if ((main_or_other as TabManager).state.tabs != null) {
                            return load_tab_manager(main_or_other as TabManager, splitter_instance)
                        } else {
                            return load_splitter((main_or_other as Splitter), splitter_instance)
                        }
                    }
                    return null
                }

                const splitter = new Splitter({
                    id: splitter_serialized.id,
                    parent: parent_instance,
                    split: splitter_serialized.state.split,
                })
                splitter.state.main.object = load_main_or_other(splitter_serialized.state.main.object, splitter.state.main)
                splitter.state.other.object = load_main_or_other(splitter_serialized.state.other.object, splitter.state.other)
                return splitter
            }

            const editor = new Editor(id)
            const serialized_state = editor.computed.storager.get<string>("state")
            if (serialized_state == null) return null;

            const save = JSON.parse(serialized_state);
            editor.state.splitter = load_splitter(save.state.splitter, editor)
            return editor
        } catch (e) {
            console.error(e)
            return null;
        }
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    get_html_element() {
        return document.getElementById(this.id)
    }

    static get_scenario_builder_tab(): ScenarioBuilderTab {
        const editor = Editor.get_scenario()
        if (editor == null) return null
        const tabs = editor.get_tabs()
        if (tabs.length == 0) return null
        return tabs[0] as ScenarioBuilderTab
    }

    static get_active_editors() {
        const editors: Editor[] = []
        if (this.get_main() != null) editors.push(this.get_main())
        if (this.get_footer() != null) editors.push(this.get_footer())
        if (this.get_scenario() != null) editors.push(this.get_scenario())
        return editors
    }

    get_tab_managers() {
        return this.state.splitter.get_tab_managers();
    }

    get_or_create_tab_manager() {
        let tab_managers = this.get_tab_managers()
        if (tab_managers.length == 0) {
            this.state.splitter._ensure_region_not_null(true, false)
            tab_managers = this.get_tab_managers()
        }
        return tab_managers[0]
    }

    static get_tab_managers() {
        return this.get_active_editors()
                   .map(editor => editor.get_tab_managers())
                   .flat()
    }

    static get_active_tabs() {
        return this.get_active_editors().map(editor => editor.computed.active_tabs).flat()
    }

    is_scenario() {
        return this.id.includes("scenario_editor_")
    }
    // </editor-fold>

    // <editor-fold desc="STATIC EDITOR GETTERS">
    static get_scenario() {
        const id = `scenario_editor_${current.project_version?.key()}`
        return this.get_by_id(id)
    }

    static get_footer() {
        const id = `footer_editor_${current.project_version?.key()}`
        return this.get_by_id(id)
    }

    static get_main() {
        const id = `main_editor_${current.project_version?.key()}`
        return this.get_by_id(id)
    }

    static get_by_id(id: string) {
        let editor = this.editors[id]

        if (this.loading_ids.includes(id)) return editor

        this.loading_ids.push(id)

        if (editor == null) editor = Editor.load(id)
        if (editor == null) editor = new Editor(id)
        this.editors[id] = editor

        this.loading_ids = this.loading_ids.filter(loading_id => loading_id != id)

        return editor
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _on_tab_manager_removed() {
        if (this.state.splitter.is_useless()) {
            this._emit("empty")
        }
    }

    _on_tab_added() {
        this._emit("not-empty")
    }

    _register_default_event_handlers() {
        this.on_tab_manager("removed", this._on_tab_manager_removed.bind(this))
        this.on_tab("added", this._on_tab_added.bind(this))
    }

    _emit(event: Editor.Events, e: any = null) {
        this.event_bus.$emit(event, this, e)
    }
    // </editor-fold>
}

declare global {
    interface Window {
        Editor: typeof Editor
    }
}

window.Editor = Editor
