<template>
  <div class="snapshot-container">
    <div ref="inspector_container"
         class="snapshot-inspector-container"
         :style="inspector_container_style">
      <ScreenOverlay v-for="phone in scenario_setting_phones"
                     :key="phone.props.id"
                     :is_live="false"
                     :is_resizing="false"
                     :canvas_width="image_width"
                     :canvas_height="image_height"
                     :scenario_setting_phone="phone"
                     :tab="tab"
                     :snapshot="snapshot_for_phone(phone)"
      />
    </div>
    <img
        ref="image"
        :src="snapshots[0].screenshot_url"
        class="no-hover-zoom"
        alt="Snapshot image"
        @load="image_loaded"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { PlayLogExtrasSnapshot } from "../../../../../vue_record/models/play/play_log";
import { SnapshotTab } from "../../tabs/snapshot_tab";
import { Inspector } from "../../../../play/reports/play_scenario/inspector/inspector";
import { Phone } from "../../../../../vue_record/models/phone";
import { Phone as ScenarioSettingPhone } from "../../../../../vue_record/models/scenario_setting/phone"
import { CSSProperties } from "vue";
import { ScenarioSetting } from "../../../../../vue_record/models/scenario_setting";
import ScreenOverlay from "../../../../play/reports/play_scenario/ScreenOverlay.vue";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";

export default defineComponent({
    components: { ScreenOverlay },
    // <editor-fold desc="PROPS">
    props: {
        snapshots: {
            type: Array as PropType<PlayLogExtrasSnapshot[]>,
            required: true
        },
        phones: {
            type: Array as PropType<Phone[]>,
            required: true
        },
        scenario_setting: {
            type: Object as PropType<ScenarioSetting>,
            required: true,
        },
        tab: {
            type: Object as PropType<SnapshotTab>,
            required: false,
            default: null
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            inspectors: [] as Inspector[],
            image_height: 0,
            image_width: 0,
            image_natural_height: 1,
            image_natural_width: 1,
            amo: null as AllMightyObserver,
            is_loaded: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        MODAL_SHOWN() {
            return MODAL.is_shown
        },
        horizontal_scale() {
            // similar to canvas_height / xvfb_height
            return this.image_width / this.image_natural_width
        },
        vertical_scale() {
            // similar to canvas_height / xvfb_height
            return this.image_height / this.image_natural_height
        },
        inspector_container_style() {
            const style: CSSProperties = {}
            style.height = `${this.image_height}px`
            style.width = `${this.image_width}px`
            return style
        },
        scenario_setting_phones() {
            return this.scenario_setting.phones.toArray()
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        if (this.tab != null) this.tab.set_editor_mounted(true);
        console.log(JSON.parse(JSON.stringify(this.snapshots)));
    },
    unmounted() {
        this.amo?.stop();
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        image_loaded() {
            const image = this.$refs.image as HTMLImageElement
            this.image_natural_width = image.naturalWidth
            this.image_natural_height = image.naturalHeight
            this.image_height = image.height
            this.image_width = image.width;
            this.amo = AllMightyObserver.new({
                element_resize: true,
                target_element: this.$refs.image as HTMLImageElement,
                callback: () => {
                    this.image_height = image.height
                    this.image_width = image.width;
                }
            })
            this.is_loaded = true
        },
        snapshot_for_phone(phone: ScenarioSettingPhone) {
            const phone_project = phone.phone_project
            return this.snapshots.find(s => s.udid == phone_project.props.udid)
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.snapshot-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;

  .snapshot-inspector-container {
    position: absolute;
    margin: auto;
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}
</style>
