<template>
  <div class="vue-tabs-and-content-container">
    <div class="vue-tabs-container"
         :class="{round}"
    >
      <template v-for="tab in tabs"
                :key="tab.id">
        <div :id="tab.id"
             class="vue-tab"
             :tabindex="tab.disabled ? '' : '0'"
             :class="{selected: active_tab?.id == tab.id, tabDisabled: tab.disabled}"
             @keydown="click_on_enter"
             @click="(e) => on_tab_click(e, tab)"
        >
          {{ tab.title }}
        </div>
      </template>
    </div>
    <div class="vue-tabs-content-container">
      <template v-for="tab in tabs"
                :key="tab.id">
        <div v-show="active_tab?.id == tab.id">
          <slot :name="tab.id"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import ClickEvent = JQuery.ClickEvent;
import { click_on_enter } from "../../helpers/events/click_on_enter";

export interface VueTab {
    title: string
    id: string
    disabled?: boolean
    on_activate?(): void
}

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        tabs: {
            type: Array as PropType<VueTab[]>,
            required: true,
        },
        round: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            active_tab: null as VueTab
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    created() {
        if (this.active_tab == null) this.active_tab = Object.values(this.tabs).filter(t => !t.disabled)[0];
        this.activate_tab(this.active_tab)
    },
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        click_on_enter,
        on_tab_click(e: ClickEvent, tab: VueTab) {
            if (tab.disabled) {
                e.preventDefault();
                e.stopImmediatePropagation();
                return;
            }

            this.activate_tab(tab)
        },
        activate_tab(tab: VueTab) {
            this.active_tab = tab;
            if (tab.on_activate != null) tab.on_activate();
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
$min-height: 30px;
$max-height: 35px;
.vue-tabs-and-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .vue-tabs-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: $max-height;
    overflow-x: auto;
    overflow-y: visible;
    flex-shrink: 0;
    align-items: end;

    &.round {
      .vue-tab {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .vue-tab {
      min-width: 50px;
      height: $min-height;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-shrink: 1;
      color: var(--font-color-secondary);
      cursor: pointer;
      background-color: var(--ternary-background-color);

      border-bottom: 2px solid var(--secondary-background-color) !important;
      transition: filter 0.25s, background-color 0.25s, height 0.25s;

      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      &.tabDisabled {
        cursor: not-allowed;

      }

      &.selected {
        background: var(--secondary-background-color);
        height: $max-height;
      }

      &.selected,
      &:not(.tabDisabled):hover {
        color: var(--font-color);
        //border-bottom: 1px solid var(--button-white);
      }

      &:not(.tabDisabled):not(.selected):hover {
        filter: brightness(1.2);
      }

      &:focus {
        border-left: 1px solid var(--focus-color);
        border-right: 1px solid var(--focus-color);
        border-top: 1px solid var(--focus-color);
      }
    }
  }

  .vue-tabs-content-container {
    min-width: 0;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    overflow: auto;
  }
}
</style>
