import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { ActiveStorageAttachment } from "../base/vue_record";
import { ProjectScope } from "../scopes/project_scope";
import { Storager } from "../../helpers/api_wrappers/storager";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { ProjectClient } from "../clients/project_client";
import { RecordOpts } from "../base/vue_record";
import { computed } from "../../helpers/vue/computed";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ComputedRole } from "../base/vue_record";
import { WithRequired } from "../base/utils/with_required";
import { QuerifyProps } from "../base/vue_record_scope";

// <editor-fold desc="TYPES">
export interface MobileProps extends Props {
    apple_team_id: string
}

export interface ProjectProps extends Props, MobileProps {
    id: number
    full_name: string
    short_name: string
    created_at: Date
    updated_at: Date
    vcs_module_enabled: boolean
    encrypted_apple_certificate_password: string
    webdriver_mobile_provisioning_id: number
    gid: number

    resigned_wda: ActiveStorageAttachment
    apple_certificate_signing_request: ActiveStorageAttachment
    apple_certificate_key: ActiveStorageAttachment
    apple_certificate_pem: ActiveStorageAttachment
    apple_certificate_cer: ActiveStorageAttachment
    apple_certificate_p12: ActiveStorageAttachment

    rubocop_config?: string

    indentation_style: "spaces" | "tabs"
    indentation_width: number
}
export type ProjectQueryProps = QuerifyProps<ProjectProps>
export type MobileUpdateProps = WithRequired<Partial<MobileProps>, "id">

export interface ProjectState extends State {
}

export interface ProjectComputed extends ComputedRole {
    indent_unit: number
}

export interface ProjectStaticState extends StaticState {
}

// </editor-fold>

const console = new Consoler("warn")
export class Project extends VueRecord {
    ['constructor']: typeof Project

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ProjectClient
    static ScopeClass = ProjectScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ProjectStaticState = reactive<ProjectStaticState>({
        types: [],
        order_by_version: true,
        packages_per_type: {},
        upload_progresses: [],
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes: VueRecordIndex<Project>[] = [
        VueRecordIndex.new(this),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Project> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Project>> = {}

    static field_validators: ModelValidatorOpts<ProjectProps> = {
        full_name: { presence: true, length: { min: 1, max: 100 } },
    }

    static resource_name = Enum.Resource.Label.PROJECT
    static resource_id = Enum.Resource.Id.PROJECT
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ProjectClient
    declare props: ProjectProps;
    declare state: ProjectState;
    declare computed: ProjectComputed;
    declare storager: Storager;
    declare web_type_storager: Storager;
    // </editor-fold>

    constructor(props: ProjectProps, opts: RecordOpts) {
        // TODO: sync these with rubocop config
        // NOTE: Layout/IndentationWidth -> Width: 2 wins over Layout/IndentationStyle -> IndentationWidth: 4 in style: "tabs" or "spaces"
        // NOTE: rubocop treats \t as a space when counting indentation
        // IN CONCLUSION: we should align with rubocop -> if it says indentation width 4 that means its either 4 spaces or 4 tabs -> determine that from style
        props.indentation_style = "spaces"

        // represents number of spaces or tabs to insert before code
        props.indentation_width = 2
        const tab_size = 4
        super(props, opts);

        this.computed = reactive({
            // represents number of spaces to insert before code
            indent_unit: computed(() => {
                if (this.props.indentation_style == "tabs") return tab_size * this.props.indentation_width;
                else return this.props.indentation_width
            }),
            role: computed(() => current.role_for(this.key())),
            role_is_viewer: computed(() => current.role_is_viewer_for(this.key()))
        })

        this.storager = computed(() => current.storagers.user.new_scope("project", this.key()))

        this.web_type_storager = computed(() => {
            return current.storagers.user_web_type.new_scope("project", this.key())
        })
    }

    name() {
        return this.props.full_name
    }

    duplicate() {
        // do nothing here
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    show_in_sidebar(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    delete() {
        const content_text = this.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => this.client.delete()
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
Project.register_resource(Project)
ProjectClient.ModelClass = Project
ProjectScope.ModelClass = Project

if (globalThis.current_project_props?.id) {
    current.project = Project.new(current_project_props)
}

if (globalThis.projects_props) projects_props.forEach(project_props => Project.new(project_props))

on_dom_content_loaded(() => {
    watch(
        [() => current.project?.props?.id, () => current.user?.props.superadmin],
        ([project_id, superadmin]) => {
            Project.unsync()
            if (superadmin != null && superadmin) Project.sync(`/sync/projects`);
            else if (project_id != null) {
                Project.sync(`/sync/project/${project_id}`);
            }
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    var current_project_props: ProjectProps

    /** Projects available for user to switch */
    var projects_props: ProjectProps[]
    var projects: Project[]

    interface Window {
        Project: typeof Project
    }

    interface Current {
        project: Project
    }
}
window.Project = Project
// </editor-fold>
