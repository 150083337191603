<template>
  <div
      class="result"
      :class="{active}"
  >
    <div class="icon"
         :style="icon_style">
      <i :class="icon_class"/>
    </div>
    <div class="name">
      <template v-for="part in name_parts"
                :key="part.key">
        <span :class="{highlight: part.match}">
          {{ part.text }}
        </span>
      </template>
    </div>

    <div class="info">
      <span v-if="record instanceof Play"
            v-moment="record.props.created_at"/>
      <span v-else-if="record instanceof Schedule">
        <span :style="record.props.status == 'Finished' ? `color: ${get_css_var('--play-color')}` : ''">
          {{ record.props.status }}
        </span>
        <span> - </span>
        <span v-moment="record.props.next_run_time"/>
        <template v-if="record.props.run_type != 'Once'">
          <span> - </span>
          <span>
            {{ record.props.run_type }}
          </span>
        </template>

      </span>
      <span v-else>
        {{ record.path() }}
      </span>
    </div>

    <div class="flex-expander"/>

    <div v-if="record instanceof Play"
         style="margin-right: 5px;">
      <PlayStatusIcon :play_status="record.props.status"
                      :scale="0.85"/>
    </div>


    <div v-if="record instanceof Play"
         style="margin-right: 5px;">
      <PlayTypeIcon :play_type="record.props.type"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { SnippetFolder } from "../../../vue_record/models/snippet_folder";
import { Snippet } from "../../../vue_record/models/snippet";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Scenario } from "../../../vue_record/models/scenario";
import { ScenarioFolder } from "../../../vue_record/models/scenario_folder";
import { Group } from "../../../vue_record/models/group";
import { GroupFolder } from "../../../vue_record/models/group_folder";
import { SearchableRecord } from "../../../helpers/client/./core/search_records";
import { File } from "../../../vue_record/models/file"
import { Image } from "../../../vue_record/models/image"
import { Phone } from "../../../vue_record/models/phone";
import { FileFolder } from "../../../vue_record/models/file_folder";
import { App } from "../../../vue_record/models/app";
import { ImageFolder } from "../../../vue_record/models/image_folder";
import { Schedule } from "../../../vue_record/models/schedule";
import { Play } from "../../../vue_record/models/play/play";
import PlayStatusIcon from "../../play/other/PlayStatusIcon.vue";
import PlayTypeIcon from "../../play/other/PlayTypeIcon.vue";
import { split_by_query_match } from "../../../helpers/pretty/split_by_query_match";

export default defineComponent({
    components: { PlayTypeIcon, PlayStatusIcon },
    // <editor-fold desc="PROPS">
    props: {
        record: {
            type: Object as PropType<SearchableRecord>,
            required: true,
        },
        query: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Schedule() {
            return Schedule
        },
        Play() {
            return Play
        },
        name_parts() {
            return split_by_query_match(this.query, this.record.name())
        },
        icon_color() {
            if (this.record instanceof Snippet || this.record instanceof SnippetFolder) {
                return get_css_var("--snippet-color")
            }
            if (this.record instanceof Scenario || this.record instanceof ScenarioFolder) {
                return get_css_var("--scenario-color")
            }
            if (this.record instanceof Group || this.record instanceof GroupFolder) {
                return get_css_var("--group-color")
            }

            if (this.record instanceof File || this.record instanceof FileFolder) {
                return get_css_var("--file-color")
            }

            if (this.record instanceof Image || this.record instanceof ImageFolder) {
                return get_css_var("--image-color")
            }

            if (this.record instanceof Phone) {
                return get_css_var("--phone-color")
            }

            if (this.record instanceof App) {
                return get_css_var("--app-color")
            }

            if (this.record instanceof Schedule) {
                return get_css_var("--schedule-color")
            }

            if (this.record instanceof Play) {
                return get_css_var("--play-color")
            }
            return "white"
        },
        icon_class() {
            if (this.record instanceof File) {
                return "fa-solid fa-file"
            } else {
                return this.record.constructor.icon_class
            }
        },
        icon_style() {
            return {
                color: this.icon_color
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: { get_css_var },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.result {
  height: 20px;
  display: flex;
  padding: 2px 4px;
  font-size: 0.9em;
  cursor: pointer;
  align-items: center;
  border-left: 1px solid transparent;
  flex-shrink: 0;


  &:hover {
    background-color: var(--ternary-background-color);
  }

  &.active {
    border-left: 1px solid var(--button-blue);
    background-color: var(--sidebar-selected);
  }

  div.icon {
    margin-right: 8px;
    padding-left: 3px;
    font-size: 0.85em;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-width: 15px;
  }

  div.name {
    color: var(--font-color);
    margin-right: 5px;
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;

    span.highlight {
      background: var(--sidebar-selected-contrast);
    }
  }

  div.info {
    font-size: 0.9em;
    font-family: monospace;
    color: var(--font-color-secondary);
    white-space: nowrap;
    flex-shrink: 1;
    min-width: 0;
    overflow: hidden;
    //text-overflow: ellipsis;
  }
}
</style>
