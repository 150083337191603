import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c684974"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "disabled"]
const _hoisted_2 = { class: "placeholder" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ValidationErrors = _resolveComponent("ValidationErrors")

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["custom-field vue-label", {invalid: _ctx.validator?.invalid}])
  }, [
    _createElementVNode("textarea", {
      id: _ctx.id,
      ref: "textarea",
      value: _ctx.modelValue,
      placeholder: " ",
      disabled: _ctx.disabled,
      class: _normalizeClass(["vue-input no-default-unfocus", {dirty: _ctx.modelValue}]),
      onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.on_input && _ctx.on_input(...args))),
      onBlur: _cache[1] || (_cache[1] = (...args) => (_ctx.on_blur && _ctx.on_blur(...args))),
      onFocus: _cache[2] || (_cache[2] = $event => (_ctx.show_errors = true))
    }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
    (_ctx.show_errors && _ctx.validator?.invalid)
      ? (_openBlock(), _createBlock(_component_ValidationErrors, {
          key: 0,
          errors: _ctx.validator.errors
        }, null, 8 /* PROPS */, ["errors"]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}