import { generate_uuid } from "../generate/generate_uuid";
import { escape_regex } from "../generic/escape_regex";

/** Splits the value into parts that match or do not match a word in the query string */
export function split_by_query_match(query: string | string[], value: string) {
    type NamePart = {
        match: boolean,
        text: string
        key: string
    }
    let words: string[]
    if (query instanceof Array) {
        words = query as string[]
    } else {
        words = (query as string).split(/ +/).filter(w => w.trim() != "")
    }

    let name_parts: NamePart[] = [{
        match: false,
        text: value,
        key: generate_uuid()
    }]

    words.forEach(word => {
        const new_name_parts: NamePart[] = []
        name_parts.forEach(name_part => {
            if (name_part.match) {
                new_name_parts.push(name_part)
                return;
            }

            const reg = new RegExp(escape_regex(word), "ig")
            let m: RegExpExecArray;
            let last_index = 0;
            // eslint-disable-next-line no-cond-assign
            while (m = reg.exec(name_part.text)) {
                new_name_parts.push({
                    match: false,
                    text: name_part.text.substring(last_index, m.index),
                    key: generate_uuid(),
                })
                new_name_parts.push({
                    match: true,
                    text: m[0],
                    key: generate_uuid()
                })
                last_index = m.index + m[0].length
            }
            new_name_parts.push({
                match: false,
                text: name_part.text.substring(last_index, name_part.text.length),
                key: generate_uuid(),
            })
        })
        name_parts = new_name_parts;
    })

    return name_parts.filter(r => r.text != "")
}
