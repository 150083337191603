globalThis.IS_WEB_WORKER = false
globalThis.WEB_TYPE = "Main"

require("../auto_generated/enums")
require("../libs/testa/testa_error")
require("../types/globals")
require("../helpers/ui_sync/ui_sync")
require("./common/init_shared_worker")
require("./application/libs")
if (process.env.NODE_ENV == "development") require("../helpers/reloading")
require("./application/index")
require("./common/login")
require("./../helpers/events/global_keybinds")
require("./../helpers/settings")

export {}
