import { Tab } from "../tab";
import { Group } from "../../../../vue_record/models/group";
import { markRaw } from "vue";
import { reactive } from "vue";
import { PlayLogExtrasSnapshot } from "../../../../vue_record/models/play/play_log";
import SnapshotEditor from "../editors/snapshot/SnapshotEditor.vue";
import { computed } from "../../../../helpers/vue/computed";
import { Phone } from "../../../../vue_record/models/phone";
import { ScenarioSetting } from "../../../../vue_record/models/scenario_setting";

namespace SnapshotTab {
    export interface Input extends Tab.Input {
        snapshots: PlayLogExtrasSnapshot[]
        scenario_setting_id: number
    }

    export interface State extends Tab.State {
        record: Group,
    }
}

export class SnapshotTab extends Tab {
    static type = "SnapshotTab"

    type: string
    snapshots: PlayLogExtrasSnapshot[]
    scenario_setting: ScenarioSetting
    phones: Phone[]

    declare state: SnapshotTab.State

    constructor(data: SnapshotTab.Input) {
        super(data)
        this.type = SnapshotTab.type
        this.snapshots = data.snapshots;
        this.state.icon.class = "fa-solid fa-hdd"
        this.component = markRaw(SnapshotEditor);
        this._set_and_call_load_function(() => {
            const promises: Promise<Phone>[] = []
            this.snapshots.forEach(snapshot => {
                promises.push(Phone.ClientClass.load(snapshot.udid))
            })
            const phones_promise = Promise.all(promises).then((phones) => this.phones = phones)
            const scenario_setting_promise = ScenarioSetting.ClientClass.load(data.scenario_setting_id).then((scenario_setting) => this.scenario_setting = scenario_setting)

            return Promise.all([phones_promise, scenario_setting_promise])
                        .then(() => {
                            this.state.icon.color = computed(() => `var(--button-blue)`)
                            this.computed.title = computed(() => "Snapshot")
                            this.component_props = {
                                snapshots: this.snapshots,
                                phones: this.phones,
                                scenario_setting: this.scenario_setting,
                                tab: this
                            }
                        })
                        .catch((e) => {
                            this._on_load_error(e)
                        })
                        .finally(() => {
                            this.set_loaded(true)
                        })
        })
        this.dnd = reactive({})
    }

    _register_default_event_handlers() {
        super._register_default_event_handlers();
    }
}

Tab.register_tab_type(SnapshotTab)
