import { VueRecordClient } from "../base/vue_record_client";
import { BrowserDriver } from "../models/browser_driver";
import { BrowserDriverScope } from "../scopes/browser_driver_scope";
import { BrowserDriverProps } from "../models/browser_driver";
import { file_upload } from "../../helpers/generic/file_upload";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class BrowserDriverClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof BrowserDriver
    declare record: BrowserDriver

    static index() {
        return new Promise<BrowserDriverScope>((resolve, reject) => {
            $.ajax({
                url: '/settings/superadmin_settings/browser_drivers',
                type: 'GET',
                success: (data: BrowserDriverProps[]) => {
                    const records: BrowserDriver[] = []
                    data.forEach(props => {
                        records.push(BrowserDriver.new(props))
                    })
                    resolve(BrowserDriver.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static upload() {
        type Response = {
            status: string
        }
        return new Promise<Response>((resolve, reject) => {
            file_upload({
                url: "/settings/superadmin_settings/browser_drivers/upload",
                progressall: (e, data) => {
                    progress_bar.go(data.loaded / data.total * 100);
                },
                fail: (e, data) => {
                    reject(data)
                },
                done: (e, data) => {
                    resolve(data.jqXHR.responseJSON as Response)
                },
            })
        })
    }
}
