import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ImageFolder } from "../models/image_folder";
import { ImageProps } from "../models/image";
import { Image } from "../models/image";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { ImageScope } from "../scopes/image_scope";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { ImageFolderCreateProps } from "../models/image_folder";
import { ImageFolderChildren } from "../models/image_folder";
import { ImageFolderProps } from "../models/image_folder";
import { ImageFolderUpdateProps } from "../models/image_folder";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ImageFolderClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ImageFolder
    declare record: ImageFolder

    /** When copy - pasting we are duplicating to different folder */
    static copy(project_version_id: number, destination_image_folder_path: string, image_folder_paths: string[], image_paths: string[] = []) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/image_folders/copy`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id,
                    destination_image_folder_path,
                    image_paths,
                    image_folder_paths,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static create(image_folder: ImageFolderCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: "/image_folders",
                type: "POST",
                data: {
                    image_folder,
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }

    children(reload = false): Promise<ImageFolderChildren> {
        if (reload) ImageFolder.state.children_promises[this.key()] = null

        if (ImageFolder.state.children_promises[this.key()] == null) {
            return ImageFolder.ClientClass.children(this.record.props.project_version_id, this.key(), reload)
        } else {
            return ImageFolder.state.children_promises[this.key()]
        }
    }

    static children(project_version_id: number, image_folder_path: string, reload = false): Promise<ImageFolderChildren> {
        if (reload) ImageFolder.state.children_promises[image_folder_path] = null

        if (ImageFolder.state.children_promises[image_folder_path] == null) {
            type ChildrenResponse = {
                images: ImageProps[],
                image_folders: ImageFolderProps[],
            }

            const promise: Promise<ImageFolderChildren> = new Promise<ImageFolderChildren>((resolve, reject) => {
                $.ajax({
                    url: `/image_folders/children`,
                    type: "GET",
                    data: {
                        project_version_id,
                        image_folder_path,
                    },
                    success: (data: ChildrenResponse) => {
                        const promise_response: ImageFolderChildren = {
                            images: [],
                            image_folders: []
                        }
                        data.images.forEach(image_prop => {
                            promise_response.images.push(Image.new(image_prop))
                        })
                        data.image_folders.forEach(image_folder_prop => {
                            promise_response.image_folders.push(ImageFolder.new(image_folder_prop))
                        })
                        resolve(promise_response)
                    },
                    error: (error) => {
                        reject(error)
                    },
                    statusCode: ajax_status_codes,
                })
            })
            ImageFolder.state.children_promises[image_folder_path] = promise
            promise.catch(() => {
                ImageFolder.state.children_promises[image_folder_path] = null
            })
            return promise
        } else {
            return ImageFolder.state.children_promises[image_folder_path]
        }
    }

    static move(project_version_id: number, destination_image_folder_path: string, image_folder_paths: string[], image_paths: string[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/image_folders/move`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    destination_image_folder_path,
                    image_paths,
                    image_folder_paths,
                    project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(image_folder: ImageFolderUpdateProps) {
        image_folder.project_version_id = this.record.props.project_version_id

        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/image_folders/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    image_folder,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    /** Duplicate single item in current folder */
    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/image_folders/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id: this.record.props.project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static batch_path(image_paths: string | string[]) {
        let paths: string[];
        if (what_is_it(image_paths) == "Array") {
            paths = image_paths as string[]
        } else {
            paths = [image_paths as string]
        }

        type PathResponse = {
            [key: string]: ImageFolderProps[]
        }

        type PromiseResponse = {
            [key: string]: ImageFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/image_folders/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    paths,
                    authenticity_token,
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(image_folder_path => {
                        const image_folders_props = data[image_folder_path];
                        const array: ImageFolder[] = []
                        image_folders_props.forEach(image_folder_props => {
                            array.push(ImageFolder.new(image_folder_props))
                        })
                        promise_response[image_folder_path] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    static batch_load_images(paths: string[] | number[]): Promise<ImageScope> {
        if (paths.length == 0) return generate_resolved_promise<ImageScope>(Image.to_scope([]))

        return new Promise<ImageScope>((resolve, reject) => {
            $.ajax({
                url: `/image_folders/load_images`,
                type: "GET",
                data: {
                    ids: paths
                },
                statusCode: ajax_status_codes,
                success: (data: ImageProps[]) => {
                    const records: Image[] = []
                    data.forEach(image_props => {
                        records.push(Image.new(image_props))
                    })
                    resolve(Image.to_scope(records))
                },
                error: (error) => {
                    reject(error)
                },
            })
        });
    }
}
