import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre"
import edgehandles from 'cytoscape-edgehandles';
import klay from 'cytoscape-klay';
// require("./cytoscape-node-html-label.cjs")

const navigator = require('cytoscape-navigator');
navigator(cytoscape);
const nodeHtmlLabel = require('cytoscape-node-html-label');
nodeHtmlLabel(cytoscape);
cytoscape.use(dagre)
// @ts-ignore
cytoscape.use(edgehandles)
cytoscape.use(klay)
