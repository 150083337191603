<template>
  <div class="controls"
       :class="{down: is_down, up: !is_down}">
    <div
        v-if="is_down && !is_hidden"
        class="control"
        title="Move control to the top"
        @click="toggle_is_down">
      <i class="fas fa-angle-up"/>
    </div>

    <div
        v-if="!is_down && !is_hidden"
        class="control"
        title="Move controls to the bottom"
        @click="toggle_is_down">
      <i class="fas fa-angle-down"/>
    </div>

    <div
        v-if="!is_hidden"
        class="control"
        title="Hide the inspector"
        @click="inspector.is_hidden = !inspector.is_hidden">
      <i class="fas fa-minus"/>
    </div>

    <div
        v-if="is_hidden"
        class="control"
        title="Show the inspector"
        @click="inspector.is_hidden = !inspector.is_hidden">
      <i class="fas fa-expand"/>
    </div>

    <div
        v-if="!is_hidden && inspector.is_live && !is_loading"
        class="control"
        title="Refresh current screen elements"
        @click="refresh">
      <i class="fas fa-sync"/>
    </div>

    <div
        v-if="is_loading"
        class="control"
        title="Screen snapshot is being generated">
      <i class="fas fa-spinner fa-spin"/>
    </div>

    <div
        v-if="!is_hidden && inspector.is_live"
        ref="load_previous_snapshot"
        class="control"
        :class="{disabled: !can_load_previous_snapshot}"
        title="Load previous snapshot"
        @click="load_previous_snapshot">
      <i class="fas fa-caret-left"/>
    </div>

    <div
        v-if="!is_hidden && inspector.is_live"
        ref="load_next_snapshot"
        class="control"
        :class="{disabled: !can_load_next_snapshot}"
        title="Load next snapshot"
        @click="load_next_snapshot">
      <i class="fas fa-caret-right"/>
    </div>

    <div
        v-if="!is_hidden && inspector.is_live"
        ref="save_screenshot_image"
        class="control"
        :class="{disabled: (inspector.active_snapshot_index == 0 || inspector.get_snapshots_count() == 0)}"
        title="Save screenshot"
        @click="save_screenshot_image">
      <i class="fas fa-camera"/>
    </div>

    <div
        v-if="!is_hidden && inspector.hidden_element_ids.length > 0"
        class="control"
        title="Click to show hidden elements"
        @click="show_hidden_elements">
      <i class="fas fa-ghost"/>
    </div>

    <div
        v-if="!is_hidden && inspector.selected_element_id != null"
        class="control"
        title="Deselect active element"
        @click="deselect_selected_element">
      <i class="fas fa-ban"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { PropType } from "vue";
import { send_ws } from "../../../../../helpers/generic/send_ws";
import { Inspector } from "./inspector";

export default defineComponent({
    props: {
        inspector: {
            type: Object as PropType<Inspector>,
            required: true,
        },
    },
    emits: [],
    data() {
        return {
            is_down: true,
        }
    },
    computed: {
        scenario_setting_phone() {
            return this.inspector.scenario_setting_phone
        },
        is_loading() {
            return this.inspector.is_loading
        },
        play_scenario() {
            return this.scenario_setting_phone.scenario_setting.play_scenario;
        },
        is_hidden() {
            return this.inspector.is_hidden
        },
        can_load_previous_snapshot() {
            return (this.inspector.get_snapshots_count() > 1 && this.inspector.active_snapshot_index != 0) ||
                (this.inspector.active_snapshot_index == null)
        },
        can_load_next_snapshot() {
            return this.inspector.active_snapshot_index != null
        }
    },
    mounted() {
        send_ws(this.play_scenario.state.backend_ws_channel, {
            action: Enum.Play.Action.INSPECTOR,
            extras: {
                udid: this.scenario_setting_phone.phone_project.phone.props.udid,
                action: "status"
            }
        });
    },
    methods: {
        toggle_is_down() {
            this.is_down = !this.is_down;
        },
        refresh() {
            this.scenario_setting_phone.refresh_inspector();
        },
        save_screenshot_image() {
            this.scenario_setting_phone.save_screenshot();
        },
        load_previous_snapshot() {
            if (!this.can_load_previous_snapshot) return;

            const current_index = this.inspector.active_snapshot_index
            if (current_index == null) {
                this.inspector.active_snapshot_id = this.inspector.sorted_snapshots[this.inspector.sorted_snapshots.length - 1]?.id
            } else {
                this.inspector.active_snapshot_id = this.inspector.sorted_snapshots[current_index - 1]?.id
            }
        },
        load_next_snapshot() {
            if (!this.can_load_next_snapshot) return;


            const current_index = this.inspector.active_snapshot_index
            if (current_index + 1 == this.inspector.get_snapshots_count()) {
                this.inspector.active_snapshot_id = null;
            } else {
                this.inspector.active_snapshot_id = this.inspector.sorted_snapshots[current_index + 1]?.id
            }
        },
        show_hidden_elements() {
            this.inspector.show_hidden_elements();
        },
        deselect_selected_element() {
            this.inspector.select_element(null)
        },
    }
})
</script>

<style lang="scss" scoped>
.controls {
  position: absolute;
  display: flex;

  // inspector side-kit is 9997
  // inspector contextmenu-layer is 9998
  // inspector contextmenu is 9999
  z-index: 9996;
  outline: 1px solid var(--border-color);
  background: var(--primary-background-color);
  left: -1px;
  color: var(--font-color);
  font-size: 12px;
  pointer-events: all;
  width: calc(100% + 2px);

  &.down {
    bottom: -3px;
  }

  &.up {
    top: 0;
  }

  .control {
    width: 100%;
    max-width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.disabled {
      color: var(--button-disabled);
    }

    &:not(.disabled):hover {
      background: var(--ternary-background-color);
    }
  }
}
</style>
