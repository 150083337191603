<template>
  <SettingsItem
      :id="setting_items.project.gemfile.id"
      :title="setting_items.project.gemfile.title"
      padding="10px 0 0 0"
  >
    <div class="row align-start"
         style="height: 100%">
      <div class="col s8"
           style="height: 100%; padding: 0">
        <textarea id="gemfile_codemirror"
                  ref="gemfile_codemirror"
                  v-model="gemfile"
                  name="gemfile"/>
      </div>
      <div class="col s4 vertical"
           style="padding-top: 20px; max-height: 100%;">
        <div v-if="!is_viewer_role"
             class="row">
          <div class="col s12 align-center">
            <Button
                text="Install"
                tab_index="2"
                :click_action="install_gemfile"
            />
          </div>
        </div>
        <div class="row"
             style="overflow: auto; max-height: 100%;">
          <div ref="action_response"
               class="col s12 vertical job-statuses-container">
            <template v-for="job_status in job_statuses"
                      :key="job_status.vue_key">
              <span :class="{red: job_status.is_error}">
                {{ job_status.message }}
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Button from "../../../testa/Button.vue";
import SettingsItem from "../../SettingsItem.vue";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";
import { init_codemirror } from "../../../../helpers/codemirror/init_codemirror";
import { Jobberman } from "../../../../helpers/jobberman/jobberman";

export default defineComponent({
    components: {
        SettingsItem,
        Button,
    },
    cm: {} as { [key: string]: CodeMirror.Editor },
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    emits: ['setting-item-mounted'],
    data() {
        return {
            component_id: generate_uuid(),
            loaded: false,
            install_in_progress: false,
            gemfile: "",
            job_statuses: [] as Array<{vue_key: string, message: string, extras: any, is_error: boolean}>,
        }
    },
    computed: {
        setting_items() {
            return setting_items
        }
    },
    mounted() {
        this.load();
        this.init_codemirror();
        this.$emit("setting-item-mounted", this)
    },
    methods: {
        cm() {
            return this.$options.cm[this.component_id]
        },
        load() {
            if (this.loaded) return;
            this.project.client.load_gemfile().then(data => {
                this.gemfile = data.gemfile
                if (this.cm()) {
                    this.cm().setValue(this.gemfile)
                }
                this.loaded = true
            })
        },
        init_codemirror() {
            this.$options.cm[this.component_id] = init_codemirror(this.$refs.gemfile_codemirror as HTMLTextAreaElement, this.project, {
                scrollPastEnd: false,
                readOnly: this.is_viewer_role,
            })
        },
        install_gemfile() {
            this.job_statuses = []
            this.install_in_progress = true;
            return this.project.client.install_gemfile(this.cm().getValue(),
                (level: Jobberman.LogLevel, message: string, extras: any) => {
                    const data = {
                        vue_key: generate_uuid(),
                        message,
                        extras,
                        is_error: level == "error" || level == "fatal"
                    }

                    this.job_statuses.push(data)
                },
                (success, error) => {
                    this.install_in_progress = false;
                    if (error) error.show_toaster()
                })
        },
        save() {
            return this.apply();
        },
        apply() {
            return this.project
                       .client
                       .update_gemfile(this.cm().getValue())
                       .catch(() => toastr.error("Failed to save gemfile"))
        },
    },
})
</script>

<style lang="scss">
.job-statuses-container {
  font-size: 0.75em;
}
</style>
