import { UiSync } from "./ui_sync";
import { watch } from "vue";

export function on_shared_worker_loaded(callback: () => void) {
    if (UiSync.computed.shared_worker_loaded) {
        callback();
        return;
    }
    let stop_handler: Function;
    // eslint-disable-next-line prefer-const
    stop_handler = watch(() => UiSync.computed.shared_worker_loaded, () => {
        if (UiSync.computed.shared_worker_loaded) {
            try {
                callback()
            } catch (e) {
                console.error(e)
            }
            stop_handler()
        }
    }, {
        flush: "sync"
    })
}
