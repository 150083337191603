import { reactive } from "vue";
import { Phone as ScenarioSettingPhone } from "../../../../../vue_record/models/scenario_setting/phone";
import { PlayLogExtrasSnapshot } from "../../../../../vue_record/models/play/play_log";
import { xml_to_json } from "../../../../../helpers/generic/xml_to_json";
import { parse_xml } from "../../../../../helpers/generic/xml_to_json";
import { InspectorElementJsonSource } from "./inspector_element";
import { IphoneInspectorElementJsonSource } from "./inspector_element";
import { AndroidInspectorRootElementJsonSource } from "./inspector_element";
import { InspectorElement } from "./inspector_element";
import { AndroidInspectorHierarchyElementJsonSource } from "./inspector_element";
import { Coords } from "../../../../../types/globals";
import { Phone } from "../../../../../vue_record/models/phone";
import { position_relative_to_element } from "../../../../../helpers/dom/position_relative_to_element";
import { ref } from "vue";
import { Ref } from "vue";
import _ from "lodash";
import { TestaTree } from "../../../../testa/tree/tree";
import { nextTick } from "vue";
import { AttributeName } from "./inspector_element_attribute";
import { computed } from "../../../../../helpers/vue/computed";
import { generate_eid } from "../../../../../helpers/generate/generate_eid";
import { PlayLogExtrasSnapshotSourceError } from "../../../../../vue_record/models/play/play_log";
import { computed_ref } from "../../../../../helpers/vue/computed";
import { FeatureFlag } from "../../../../../vue_record/models/feature_flag";
import { watch } from "vue";
import { on_dom_content_loaded } from "../../../../../helpers/events/dom_content_loaded";

export type AttributeMap = Partial<Record<AttributeName, any[]>>
export type GroupedAttributeMap = Partial<Record<AttributeName, Record<string, number>>>

export interface Snapshot extends PlayLogExtrasSnapshot {
    json_source: AndroidInspectorRootElementJsonSource | IphoneInspectorElementJsonSource
    root_inspector_element: InspectorElement
    tree: TestaTree.Tree
    attribute_map: AttributeMap,
    tally_attribute_map: GroupedAttributeMap
    unique_attribute_map: AttributeMap
    is_error: boolean
    error_message: string
    aut_y_offset: number
    aut_x_offset: number
    aut_scale: number
}

export type HoveringTarget = "tree" | "side-kit-info" | "inspector" | "contextmenu"

export class Inspector {
    static active_inspector_id: Ref<string> = ref(null)
    private snapshots: Snapshot[] = []
    readonly scenario_setting_phone: ScenarioSettingPhone;
    readonly id: string;
    phone: Phone

    constructor(is_live: boolean, scenario_setting_phone: ScenarioSettingPhone) {
        this.is_live = is_live
        this.scenario_setting_phone = scenario_setting_phone
        this.id = generate_eid()
    }

    static new<T extends typeof Inspector = typeof Inspector>(this: T, scenario_setting_phone: ScenarioSettingPhone, is_live = true): InstanceType<T> {
        const thiz = reactive(new this(is_live, scenario_setting_phone)) as InstanceType<T>
        thiz.init()

        // @ts-ignore
        window.inspector = thiz
        return thiz
    }

    is_hidden: boolean
    is_loading: boolean
    active_snapshot_id: string
    filter = ""

    hovering_in: HoveringTarget

    vertical_scale = 1
    horizontal_scale = 1

    /** NOTE:
     * When user clicks on element, that will set clicked_element_id.
     * On context menu, user can hover over parent or child elements, which will trigger highlighted_element_id.
     * Upon selecting one of parent/child elements, that will trigger selected_element_id.
     */
    /** Element that is being focused / hovered */
    hovered_element_id: string
    /** Currently selected element */
    selected_element_id: string

    hidden_element_ids: string[] = []

    side_kit_closed = false
    side_kit_close_timeout: NodeJS.Timeout = null

    throttled_hover_element: (inspector_element: InspectorElement, hovering_in: HoveringTarget) => void

    cursor_coordinates: Coords
    clicked_coordinates: Coords

    // <editor-fold desc="COMPUTED">
    is_filtering: boolean
    prepared_filter_query: string[]
    /** only 1 inspector can be active, prevents overlapping elements over side-kit */
    is_active: boolean

    /** when test is running, we can request new snapshots and load previous ones. */
    is_live: boolean

    /** live_background is true when active_snapshot_id is set to null, then we use last snapshot and no screenshot
     * if it is set to false, we use have a active_snapshot_id and we set a screenshot as background */
    live_background: boolean
    screenshot_url: string
    class_key: "class" | "type"
    id_key: "resource-id" | "name"
    phone_max_y_coordinate: number
    phone_max_x_coordinate: number

    phone_vertical_scale: number
    phone_horizontal_scale: number

    packages: string[]
    sorted_snapshots: Snapshot[]
    active_snapshot: Snapshot
    active_snapshot_index: number
    selected_element: InspectorElement
    hovered_element: InspectorElement
    hidden_elements: InspectorElement[]
    scaled_clicked_coordinates: Coords
    scaled_cursor_coordinates: Coords
    intersecting_elements: InspectorElement[]
    filtered_elements: InspectorElement[]

    // </editor-fold>

    init() {
        this.hovering_in = null
        this.is_loading = false;

        this.throttled_hover_element = _.throttle(this.hover_element, 100)

        // <editor-fold desc="COMPUTED">
        // has to be computed because inspector is created before the any_android / any_ios is resolved
        this.phone = computed(() => {
            return this.scenario_setting_phone.phone_project.phone;
        })

        this.is_active = computed(() => Inspector.active_inspector_id.value == this.id) as any as boolean

        this.class_key = computed(() => {
            switch (this.phone.props.phone_type) {
                case Enum.Phone.Type.ANDROID:
                    return "class"
                case Enum.Phone.Type.IPHONE:
                case Enum.Phone.Type.SIMULATOR:
                    return "type"
                default:
                    console.error(`Unknown inspector phone type: ${this.phone.props.phone_type}`)
                    return "class"
            }
        })

        this.id_key = computed(() => {
            switch (this.phone.props.phone_type) {
                case Enum.Phone.Type.ANDROID:
                    return "resource-id"
                case Enum.Phone.Type.IPHONE:
                case Enum.Phone.Type.SIMULATOR:
                    return "name"
                default:
                    console.error(`Unknown inspector phone type: ${this.phone.props.phone_type}`)
                    return "class"
            }
        }) as any as "resource-id" | "name"

        this.phone_vertical_scale = computed(() => {
            return (this.scenario_setting_phone.props.downscaled_height / this.phone.props.display_height) * this.vertical_scale;
        })

        this.phone_horizontal_scale = computed(() => {
            return (this.scenario_setting_phone.props.downscaled_width / this.phone.props.display_width) * this.horizontal_scale;
        })

        this.phone_max_x_coordinate = computed(() => this.phone.props.display_width / this.phone.props.pixel_ratio)
        this.phone_max_y_coordinate = computed(() => this.phone.props.display_height / this.phone.props.pixel_ratio)

        this.sorted_snapshots = computed(() => {
            return this.snapshots.sort_by(s => s.created_at)
        })


        this.live_background = computed(() => this.active_snapshot_id == null)
        this.screenshot_url = computed(() => this.active_snapshot?.screenshot_url)
        this.active_snapshot = computed(() => {
            let active_snapshot: Snapshot
            if (this.active_snapshot_id == null) {
               active_snapshot = this.sorted_snapshots[this.sorted_snapshots.length - 1]
            } else {
                active_snapshot = this.sorted_snapshots.find(s => s.id == this.active_snapshot_id)
            }
            return active_snapshot
        })

        watch(
            () => this.active_snapshot,
            () => {
                this.set_snapshot_state(this.active_snapshot, this.selected_element_id, this.hovered_element_id, this.hidden_element_ids)
            },
            {
                flush: "pre"
            }
        )

        this.active_snapshot_index = computed(() => {
            if (this.active_snapshot_id == null) return null
            return this.snapshots.findIndex(s => s.id == this.active_snapshot_id)
        })

        this.scaled_clicked_coordinates = computed(() => {
            if (this.clicked_coordinates == null) return null;
            return {
                x: (this.clicked_coordinates.x / this.phone_horizontal_scale) / this.phone.props.pixel_ratio,
                y: (this.clicked_coordinates.y / this.phone_vertical_scale) / this.phone.props.pixel_ratio
            }
        })

        this.scaled_cursor_coordinates = computed(() => {
            if (this.cursor_coordinates == null) return null;
            return {
                x: this.cursor_coordinates.x / this.phone_horizontal_scale / this.phone.props.pixel_ratio,
                y: this.cursor_coordinates.y / this.phone_vertical_scale / this.phone.props.pixel_ratio
            }
        })

        this.intersecting_elements = computed(() => {
            if (this.scaled_clicked_coordinates == null) return [];

            return this.active_snapshot
                       .root_inspector_element
                       .elements_from_point(this.scaled_clicked_coordinates, true)
                       .flat(Infinity as 10) as InspectorElement[]
        })

        this.hidden_elements = computed(() => {
            const elements: InspectorElement[] = []
            this.hidden_element_ids.forEach(id => {
                const ele = this.active_snapshot.root_inspector_element.find(id)
                if (ele != null) elements.push(ele)
            })
            return elements
        })

        this.hovered_element = computed(() => {
            if (this.hovered_element_id == null) return null
            return this.active_snapshot.root_inspector_element.find(this.hovered_element_id)
        })

        this.selected_element = computed(() => {
            if (this.selected_element_id == null) return null
            return this.active_snapshot.root_inspector_element.find(this.selected_element_id)
        })

        this.packages = computed(() => {
            const packages = this.scenario_setting_phone.apps?.pluck("props")?.pluck("package")
            if (packages == null) return []
            return packages
        })

        this.is_filtering = computed(() => this.filter != null && this.filter.length >= 1)

        this.prepared_filter_query = computed(() => {
            let filter_string = this.filter
            if (filter_string == null) filter_string = ""
            filter_string = filter_string.toLowerCase()

            return filter_string.split(/ +/)
        })

        this.filtered_elements = computed(() => {
            if (!this.is_filtering) return []

            return this.active_snapshot
                       .root_inspector_element
                       .self_and_descendants
                       .filter(inspector_element => inspector_element.is_filter_match)
        })
        // </editor-fold>
    }

    // <editor-fold desc="HELPERS">
    get_html_element() {
        return document.getElementById(this.id)
    }

    set_vertical_scale(vertical_scale: number) {
        this.vertical_scale = vertical_scale
    }

    set_horizontal_scale(horizontal_scale: number) {
        this.horizontal_scale = horizontal_scale
    }

    get_snapshots_count() {
        return this.snapshots.length
    }

    set_snapshot_state(snapshot: Snapshot, selected_element_id: string, hovered_element_id: string, hidden_element_ids: string[]) {
        if (snapshot == null) return;

        snapshot.root_inspector_element?.self_and_descendants?.forEach(ie => {
            ie.is_selected = ie.id == selected_element_id
            ie.is_hovered = ie.id == hovered_element_id
            ie.is_hidden = hidden_element_ids.includes(ie.id)
        })
    }

    set_side_kit_close_timeout() {
        if (FeatureFlag.is_enabled("auto-close-inspector-side-kit", current.user, current.project_version)) {
            this.side_kit_close_timeout = setTimeout(() => {
                this.side_kit_closed = true;
            }, 500)
        }
    }
    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    flash(flash_class: "flash-blue" | "flash-white") {
        const ele = this.get_html_element()
        if (ele == null) return;

        ele.classList.add(flash_class)
        setTimeout(() => {
            ele.classList.add("fade")
            setTimeout(() => {
                ele.classList.remove("fade")
                ele.classList.remove(flash_class)
            }, 750)
        }, 750)
    }

    hide_element(inspector_element: InspectorElement) {
        const id = inspector_element.id
        inspector_element.is_hidden = true;
        if (this.hidden_element_ids.includes(id)) return;

        this.hidden_element_ids.push(id);
        this.flash("flash-blue")
        if (this.selected_element != null) {
            if (this.selected_element.parents.some(p => p.is_hidden)) {
                this.select_element(null)
            }
        }
    }

    show_hidden_elements() {
        this.hidden_elements.forEach(hidden_element => {
            hidden_element.is_hidden = false
        })
        this.hidden_element_ids = []
    }

    add_snapshot(play_log_extras_snapshot: PlayLogExtrasSnapshot) {
        if (this.snapshots.some(s => s.id == play_log_extras_snapshot.id)) return;

        let is_error = false
        let error_message = ""
        let json_source: InspectorElementJsonSource = null
        let root_element_json: AndroidInspectorHierarchyElementJsonSource | IphoneInspectorElementJsonSource = null
        let root_element: InspectorElement = null
        let attribute_map: AttributeMap = null
        let tree: TestaTree.Tree = null

        const source_error = play_log_extras_snapshot.source as PlayLogExtrasSnapshotSourceError
        play_log_extras_snapshot.source = source_error
        if (source_error.error != null) {
            is_error = true
            error_message = source_error.message
            console.error(source_error.message)
        } else {
            try {
                json_source = this.parse_snapshot(play_log_extras_snapshot)
                root_element_json = Inspector.find_root_node(json_source)
                root_element = InspectorElement.new(this, null, null, root_element_json)
                attribute_map = this.generate_attribute_map(root_element)
                tree = new TestaTree.Tree(`${this.id}_tree`, null, computed_ref(() => {
                    return [root_element.testa_tree_node_data()]
                }), false)
            } catch (e: any) {
                console.error(e)
                is_error = true
                error_message = e.message
            }
        }

        this.snapshots.push({
            ...play_log_extras_snapshot,
            json_source,
            root_inspector_element: root_element,
            tree,
            attribute_map,
            unique_attribute_map: this.generate_unique_attribute_map(attribute_map),
            tally_attribute_map: this.generate_tally_attribute_map(attribute_map),
            is_error,
            error_message,
            aut_y_offset: play_log_extras_snapshot.aut_y_offset,
            aut_x_offset: play_log_extras_snapshot.aut_x_offset,
            aut_scale: play_log_extras_snapshot.aut_scale
        });
        this.flash("flash-white")
        // this.active_snapshot_id = play_log_extras_snapshot.id
    }

    hover_element(inspector_element: InspectorElement, hovering_in: HoveringTarget) {
        if (this.hovered_element != null) this.hovered_element.is_hovered = false

        this.hovered_element_id = inspector_element?.id
        if (inspector_element != null) inspector_element.is_hovered = true
        this.hovering_in = hovering_in
    }

    select_element(inspector_element: InspectorElement, clicked_coordinates: Coords = null) {
        if (this.selected_element != null) this.selected_element.is_selected = false

        this.selected_element_id = inspector_element?.id
        if (inspector_element != null) inspector_element.is_selected = true
        if (this.selected_element != null) {
            nextTick(() => {
                const node = this.active_snapshot.tree.get_node_by_key(this.selected_element?.tree_id)
                node.tree.set_selected([node])
                node.scroll_into_view({ behavior: "smooth", block: "center", inline: "center" })
            })

            if (clicked_coordinates == null && inspector_element != null) {
                const x = inspector_element.attributes.center.value.x
                const y = inspector_element.attributes.center.value.y
                clicked_coordinates = { x, y }
            }
            this.clicked_coordinates = clicked_coordinates
        }
    }

    // </editor-fold>

    // <editor-fold desc="HANDLERS">
    on_click(event: MouseEvent, inspector_element: InspectorElement) {
        this.select_element(inspector_element, this.extract_coords_from_event(event))
    }

    on_hover(event: MouseEvent, inspector_element: InspectorElement, hovering_in: HoveringTarget) {
        this.throttled_hover_element(inspector_element, hovering_in)
    }

    on_mouse_move(event: MouseEvent) {
        this.cursor_coordinates = this.extract_coords_from_event(event)
    }

    on_mouse_enter(_event: MouseEvent) {
        this.activate_inspector()
    }

    activate_inspector() {
        Inspector.active_inspector_id.value = this.id;
        this.side_kit_closed = false;
        clearTimeout(this.side_kit_close_timeout)
    }

    on_mouse_leave(_event: MouseEvent) {
        this.throttled_hover_element(null, null)
        this.set_side_kit_close_timeout()
    }

    // </editor-fold>


    // <editor-fold desc="INTERNAL">
    extract_coords_from_event(event: MouseEvent) {
        let x, y;
        if (event.pageX == 0 && event.pageY == 0) {
            const $target = $(event.target)
            const offset = $target.offset()
            x = offset.left + ($target.width() / 2)
            y = offset.top + ($target.height() / 2)
        } else {
            x = event.pageX;
            y = event.pageY;
        }

        return position_relative_to_element(this.get_html_element(), x, y)
    }

    static find_root_node(json_source: InspectorElementJsonSource): AndroidInspectorHierarchyElementJsonSource | IphoneInspectorElementJsonSource {
        if (json_source == null) {
            console.warn("JSON is null");
            return null
        }

        const find_root = function(root: any): AndroidInspectorHierarchyElementJsonSource | IphoneInspectorElementJsonSource {
            const key = Object.keys(root)[0]
            if (key) {
                const element_json = root[key]
                if (element_json.hasOwnProperty("@attributes")) {
                    return element_json
                } else {
                    return find_root(element_json)
                }
            } else return null;
        }
        return find_root(json_source)
    }

    private parse_snapshot(play_log_extras_snapshot: PlayLogExtrasSnapshot): InspectorElementJsonSource {
        return xml_to_json(parse_xml(play_log_extras_snapshot.source as string)) as any as InspectorElementJsonSource
    }

    private generate_attribute_map(root_element: InspectorElement): AttributeMap {
        const all_elements = root_element.self_and_descendants;
        const attribute_map: Record<string, any[]> = {}
        all_elements.forEach(element => {
            Object.keys(element.attributes).forEach(key => {
                const attribute = key as AttributeName
                const value = element.attributes[attribute].value
                if (value == null) return;

                if (attribute_map[attribute] == null) attribute_map[attribute] = []
                attribute_map[attribute].push(value)
            })
        })
        return attribute_map as AttributeMap
    }

    private generate_unique_attribute_map(attribute_map: AttributeMap) {
        if (attribute_map == null) return null

        const unique = _.cloneDeep(attribute_map)
        for (const key in unique) {
            const attribute = key as AttributeName
            unique[attribute] = unique[attribute].uniq()
        }
        return unique
    }

    private generate_tally_attribute_map(attribute_map: AttributeMap) {
        if (attribute_map == null) return null

        const tally: GroupedAttributeMap = {}
        for (const key in attribute_map) {
            const attribute = key as AttributeName
            const values = attribute_map[attribute]
            tally[attribute] = values.tally()
        }
        return tally
    }

    // </editor-fold>
}

on_dom_content_loaded(() => {
})
