/** Used for coloring buttons, checkbox, etc */
export type ColorClass = "red" | "default" | "blue" | "green" | "grey" | "white" | "primary"

export function color_class_to_var(color_class: ColorClass | string) {
    switch (color_class) {
        case "blue":
            return "var(--button-blue)"
        case "green":
            return "var(--button-green)"
        case "default":
            return "var(--secondary-background-color)"
        case "grey":
            return "var(--button-grey)"
        case "red":
            return "var(--button-red)"
        case "white":
            return "var(--button-white)"
        case "purple":
            return "var(--button-purple)"
        case "primary":
            return "var(--primary-background-color)"
        default:
            return color_class
    }
}
