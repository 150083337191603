import _ from "lodash";
import { EditorConfiguration } from "codemirror";
import CodeMirror from "codemirror";
import { ch_of_code_start } from "./helpers/ch_of_code_start";
import { get_indentation_spaces } from "./helpers/get_indentation_spaces";
import { get_indentation_from_spaces } from "./helpers/get_indentation_from_spaces";
import { apply_indentation } from "./align_chained_methods";
import { create_vue_app } from "../vue/create_vue_app";
import CodeMirrorSearch from "../../components/testa/editor/editors/codemirror/CodeMirrorSearch.vue";
import { markRaw } from "vue";
import { Project } from "../../vue_record/models/project";
import { User } from "../../vue_record/models/user";
import { Consoler } from "../api_wrappers/consoler";
import { home } from "./keybinds/home";
import { end } from "./keybinds/end";
import { tab } from "./keybinds/tab";
require("../../libs/codemirror/index.js")

const console = new Consoler("warn")
const extra_keys = () => {
    let Ctrl_or_Cmd = "Ctrl"
    if (is_pc_mac) Ctrl_or_Cmd = "Cmd"
    const extra_keys: CodeMirror.KeyMap = {
        Tab: tab,
        Home: home,
        End: end,
    }
    extra_keys[`${Ctrl_or_Cmd}-Space`] = "autocomplete"
    extra_keys[`${Ctrl_or_Cmd}-Backspace`] = function(cm: CodeMirror.Editor) {
        const cursor = cm.getCursor()
        const line = cm.getLine(cursor.line)
        const indentation_spaces = get_indentation_spaces(cm, cursor.line)
        const indentation = get_indentation_from_spaces(cm, indentation_spaces)
        const code_start_ch = ch_of_code_start(line)
        const default_action = () => {
            cm.execCommand("delGroupBefore")
        }

        if (code_start_ch > indentation.length) {
            apply_indentation(cm, indentation, cursor.line)
        } else {
            default_action()
        }
    }
    extra_keys[`Shift-${Ctrl_or_Cmd}-F`] = () => {
        // prevent default replace action
    }
    extra_keys[`${Ctrl_or_Cmd}-F`] = (cm) => {
        const wrapper = cm.display.wrapper
        const search_input = wrapper.querySelector(".codemirror-search-teleport-container input") as HTMLInputElement
        if (search_input != null) {
            search_input.focus();
        } else {
            create_vue_app(CodeMirrorSearch, {
                cm,
                search: cm.getSelection()
            })
        }
    }
    return extra_keys
}
const default_cm_options = (project: Project, user: User): EditorConfiguration => {
    let indentWithTabs = true
    let lineWrapping = false
    let indentUnit = 4 * 2

    if (project != null) {
        indentWithTabs = project.props.indentation_style == "tabs"
        indentUnit = project.computed.indent_unit
    }
    if (user != null) lineWrapping = user.props.snippet_line_wrap
    return {
        // viewportMargin: "Infinity",
        rulers: [{ color: "var(--codemirror-ruler-color)", column: 80, lineStyle: "dashed" }],
        styleActiveLine: true,
        scrollPastEnd: true,
        lineNumbers: true,
        firstLineNumber: 1,
        lint: false,
        showCursorWhenSelecting: true,
        mode: 'text/x-ruby',
        autoCloseTags: true,
        autoRefresh: false,
        keyMap: 'sublime',
        theme: "testa",
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        smartIndent: true,
        indentWithTabs,
        lineWrapping,

        // used when cm.indentLine(line_number)
        indentUnit,
        autoCloseBrackets: true,
        matchBrackets: true,
        extraKeys: extra_keys(),
        configureMouse: function(_cm, _repeat, _event) {
            return { addNew: false }
        },
    } as EditorConfiguration
}
const default_diff_two_way_cm_options = (project: Project, user: User): EditorConfiguration => {
    return _.merge(_.cloneDeep(default_cm_options(project, user)), {
        styleActiveLine: false,
        lineNumbers: true,
        revertButtons: true,
        mode: "text/x-ruby",
        highlightDifferences: true,
        connect: "align",
        collapseIdentical: 3, // When true (default is false), stretches of unchanged text will be collapsed.
        allowEditingOriginals: false, // Determines whether the original editor allows editing. Defaults to false.
        scrollPastEnd: false,
    })
};

const default_diff_three_way_cm_options = (project: Project, user: User): EditorConfiguration => {
    return _.merge(_.cloneDeep(default_cm_options(project, user)), {
        styleActiveLine: false,
        lineNumbers: true,
        revertButtons: true,
        mode: "text/x-ruby",
        highlightDifferences: true,
        connect: "align",
        collapseIdentical: 3, // When true (default is false), stretches of unchanged text will be collapsed.
        allowEditingOriginals: false, // Determines whether the original editor allows editing. Defaults to false.
        scrollPastEnd: false,
    })
}
require("./helpers/get_indentation_spaces")

export function init_codemirror(textarea: HTMLTextAreaElement, project: Project, options = {}) {
    const opts = _.merge(_.cloneDeep(default_cm_options(project, current.user)), options)
    if (current.theme != null) opts.theme = current.theme;
    console.log("init codemirror with opts: ", opts, "on target: ", textarea, "for project: ", project)

    const cm = markRaw(CodeMirror.fromTextArea(textarea, opts));
    cm.on("dragstart", function(cm, e) {
        if (dnd.state.is_dragging) e.preventDefault();
    });
    cm.on("dragenter", function(cm, e) {
        if (dnd.state.is_dragging) e.preventDefault();
    });
    cm.on("dragover", function(cm, e) {
        if (dnd.state.is_dragging) e.preventDefault();
    });
    cm.on("drop", function(cm, e) {
        if (dnd.state.is_dragging) e.preventDefault();
    });

    window.cm = cm

    return cm
}

export function init_diff_two_way_codemirror(target: HTMLElement, project: Project, options = {}) {
    const opts = _.merge(_.cloneDeep(default_diff_two_way_cm_options(project, current.user)), options)
    opts.theme = current.theme;
    const mv = markRaw(CodeMirror.MergeView(target, opts))
    window.mv = mv
    return mv
}

export function init_diff_three_way_codemirror(target: HTMLElement, project: Project, options = {}) {
    const opts = _.merge(_.cloneDeep(default_diff_three_way_cm_options(project, current.user)), options)
    opts.theme = current.theme;

    const mv = markRaw(CodeMirror.MergeView(target, opts))
    window.mv = mv
    return mv
}
