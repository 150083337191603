import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { User } from "../models/user";
import { QuerifiedUserProjectProps } from "../models/user_project";
import toastr from "toastr";
import { UserCreateProps } from "../models/user";
import { UserUpdateProps } from "../models/user";
import { UserSignInProps } from "../models/user";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")

export class UserClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof User
    declare record: User

    static create(user_props: UserCreateProps, user_projects: QuerifiedUserProjectProps[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/user_settings/users`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    user: user_props,
                    user_projects,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        });
    }

    update(user_props: UserUpdateProps, user_projects: QuerifiedUserProjectProps[] = null) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/user_settings/users/${this.key()}`,
                type: "PATCH",
                data: JSON.stringify({
                    user: user_props,
                    user_projects,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        });
    }

    static delete(ids: number | Array<number>) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/user_settings/users/destroy`,
                type: "DELETE",
                data: {
                    authenticity_token,
                    ids,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }

    static check_uniqueness(params: { user_id?: number, username?: string, email?: string }) {
        type Response = { username: boolean, email: boolean }
        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: "/settings/user_settings/users/check_uniqueness",
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    username: params.username,
                    id: params.user_id,
                    email: params.email
                }),
                success: (data: Response) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    invalidate_ci_tokens() {
        $.ajax({
            url: '/api/invalidate_tokens',
            type: 'DELETE',
            data: {
                user_id: this.key(),
                authenticity_token,
            },
            success: () => {
            },
            error: () => {
                toastr.error('Failed invalidated all tokens');
            },
            statusCode: ajax_status_codes,
        })
    }

    static sign_in(user: UserSignInProps, project_id: number = null, project_version_id: number = null) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/users/sign_in`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    user,
                    project_id,
                    project_version_id
                }),
                statusCode: ajax_status_codes,
                success: () => {
                    ui_sync.send_sync_user_signed_in_task(authenticity_token)
                    resolve(null)
                },
                error: (error) => reject(error)
            })
        })
    }

    static sign_out() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/users/sign_out`,
                type: "DELETE",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token
                }),
                success: () => {
                    ui_sync.send_sync_user_signed_out_task()
                    resolve(null)
                },
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }
}
