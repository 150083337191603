import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a2b21b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vue-tabs-and-content-container" }
const _hoisted_2 = ["id", "tabindex", "onClick"]
const _hoisted_3 = { class: "vue-tabs-content-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["vue-tabs-container", {round: _ctx.round}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab.id,
          id: tab.id,
          class: _normalizeClass(["vue-tab", {selected: _ctx.active_tab?.id == tab.id, tabDisabled: tab.disabled}]),
          tabindex: tab.disabled ? '' : '0',
          onKeydown: _cache[0] || (_cache[0] = (...args) => (_ctx.click_on_enter && _ctx.click_on_enter(...args))),
          onClick: (e) => _ctx.on_tab_click(e, tab)
        }, _toDisplayString(tab.title), 43 /* TEXT, CLASS, PROPS, NEED_HYDRATION */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: tab.id
        }, [
          _renderSlot(_ctx.$slots, tab.id, {}, undefined, true)
        ], 512 /* NEED_PATCH */)), [
          [_vShow, _ctx.active_tab?.id == tab.id]
        ])
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}