<template>
  <div
      :id="tab_manager.id"
      class="tab-manager-container"
      :style="style"
  >
    <div class="tabs no-scrollbar"
         :class="tabs_class"
         @wheel.passive="on_tabs_wheel"
    >
      <template v-for="tab in tab_manager.computed.sorted_tabs"
                :key="tab.id">
        <Tab :tab="tab"/>
      </template>
    </div>
    <div class="content"
         @dragenter="(e) => tab_manager._emit('dragenter', e)"
         @dragover="(e) => tab_manager._emit('dragover', e)"
         @dragleave="(e) => tab_manager._emit('dragleave', e)"
    >
      <div
          v-if="display_dnd_drop"
          class="dnd-drop-areas-container"
          :class="{'scenario-builder-tab_manager': is_scenario_builder_tab_manager}"
          :style="dnd_style">
        <div class="dnd-over-drop-area main"
             :class="{single: !dnd_show_right_and_bottom_area && !dnd_show_right_and_bottom_area}"
             @dragenter="(e) => tab_manager._emit('dragenter', e)"
             @dragover="(e) => tab_manager._emit('dragover', e)"
             @dragleave="(e) => tab_manager._emit('dragleave', e)"
             @drop="(e) => tab_manager.state.active_tab.on_drag_drop(e, 'main')"
        />
        <div v-if="dnd_show_right_and_bottom_area"
             class="dnd-over-drop-area right"
             @dragenter="(e) => tab_manager._emit('dragenter', e)"
             @dragover="(e) => tab_manager._emit('dragover', e)"
             @dragleave="(e) => tab_manager._emit('dragleave', e)"
             @drop="(e) => tab_manager.state.active_tab.on_drag_drop(e, 'right')"
        />
        <div v-if="dnd_show_right_and_bottom_area"
             class="dnd-over-drop-area down"
             @dragenter="(e) => tab_manager._emit('dragenter', e)"
             @dragover="(e) => tab_manager._emit('dragover', e)"
             @dragleave="(e) => tab_manager._emit('dragleave', e)"
             @drop="(e) => tab_manager.state.active_tab.on_drag_drop(e, 'down')"
        />
      </div>
      <template v-for="tab in tab_manager.computed.sorted_tabs"
                :key="tab.id">
        <TabContent
            v-show="tab.instance_id == tab_manager.state.active_tab?.instance_id"
            :tab="tab"
            @dragenter="(e) => tab._emit('dragenter', e)"
            @dragover="(e) => tab._emit('dragover', e)"
            @dragleave="(e) => tab._emit('dragleave', e)"
            @drop="(e) => tab._emit('drop', e)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { TabManager } from "./tab_manager";
import Tab from "./Tab.vue";
import TabContent from "./TabContent.vue";
import { Editor } from "./editor";
import { EnumResourceId } from "../../../auto_generated/enums";
import { filter_records_that_can_be_tabbed } from "../../../vue_record/base/utils/record_tabber";


export default defineComponent({
    components: { TabContent, Tab },
    // <editor-fold desc="PROPS">
    props: {
        tab_manager: {
            type: Object as PropType<TabManager>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        dnd() {
            return window.dnd
        },
        is_scenario_builder_tab_manager() {
            return this.tab_manager.id == Editor.get_scenario_builder_tab()?.tab_manager?.id
        },
        display_dnd_drop() {
            const acceptable_records = filter_records_that_can_be_tabbed(dnd.state.records)
            const acceptable_records_without_scenarios = acceptable_records.filter(r => r.constructor.resource_id != Enum.Resource.Id.SCENARIO)
            const scenario_builder_acceptable_resources = [Enum.Resource.Id.SNIPPET, Enum.Resource.Id.SCENARIO] as EnumResourceId[]

            return (this.tab_manager.state.is_drag_over && dnd.state.is_dragging &&
                    (dnd.state.tabs.length > 0 || acceptable_records_without_scenarios.length > 0)) ||
                (this.is_scenario_builder_tab_manager && acceptable_records.some(r => scenario_builder_acceptable_resources.includes(r.constructor.resource_id)))
        },
        grid_template_columns() {
            const def = "100%"
            switch (this.tab_manager.state.position) {
                case "top":
                case "bottom":
                    return def
                case "left":
                    return "min-content auto"
                case "right":
                    return "auto min-content";
                default:
                    return def
            }
        },
        grid_template_rows() {
            const def = `${TabManager.height}px calc(100% - ${TabManager.height}px)`
            switch (this.tab_manager.state.position) {
                case "top":
                    return def;
                case "bottom":
                    return `calc(100% - ${TabManager.height}px) ${TabManager.height}px`
                case "left":
                case "right":
                    return "100%";
                default:
                    return def
            }
        },
        grid_template_areas() {
            const def = "'tabs' 'content'";
            switch (this.tab_manager.state.position) {
                case "top":
                    return def
                case "right":
                    return "'content tabs'";
                case "bottom":
                    return "'content' 'tabs'";
                case "left":
                    return "'tabs content'";
                default:
                    return def
            }
        },
        style() {
            return {
                gridTemplateAreas: this.grid_template_areas,
                gridTemplateColumns: this.grid_template_columns,
                gridTemplateRows: this.grid_template_rows,
            }
        },
        dnd_show_right_and_bottom_area() {
            return (this.tab_manager.get_tabs().length > 1 || dnd.state.tabs.every(t => t.tab_manager.id != this.tab_manager.id)) &&
                !this.tab_manager.state.single_mode
        },
        dnd_style() {
            if (!this.dnd_show_right_and_bottom_area) {
                return {
                    gridTemplateAreas: "'main'",
                    gridTemplateColumns: "100%",
                    gridTemplateRows: "100%"
                }
            }
            return {}
        },
        tabs_class() {
            const clazz: Record<string, boolean> = {
                "single-mode": this.tab_manager.state.single_mode
            }
            clazz[`${this.tab_manager.state.position}`] = true
            return clazz
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        on_tabs_wheel(e: WheelEvent) {
            if (!e.shiftKey) {
                (e.currentTarget as HTMLElement).scrollLeft += e.deltaY + e.deltaX;
                // e.preventDefault(); // error: unable to preventDefault inside passive event listener
            }
        }
    },
    // </editor-fold>
})
</script>
<style lang="scss">
.tab-manager-container {
  .tabs {
    &.single-mode {
      .tab {
        width: 100% !important;

        .title {
          max-width: 100% !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.tab-manager-container {
  width: 100%;
  height: calc(100% - 2px);
  background-color: var(--secondary-background-color);
  display: grid;

  .tabs {
    grid-area: tabs;
    background-color: var(--secondary-background-color);
    display: flex;
    border: 1px solid var(--secondary-background-color);
    overflow-x: auto;

    &.top,
    &.bottom {
      height: calc(100% - 2px);
      width: calc(100% - 2px);
      flex-direction: row;
    }

    &.left,
    &.right {
      height: 100%; // will fill available that is set in TabManager.height
      width: fit-content;
      flex-direction: column;
    }
  }

  .content {
    grid-area: content;
    position: relative;
    background-color: var(--primary-background-color);
    width: 100%;
    height: 100%;
    overflow: auto;

    .dnd-drop-areas-container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 75% 25%;
      grid-auto-columns: 70% 30%;
      grid-template-areas: "main right" "down right";
      z-index: 9;

      &.scenario-builder-tab_manager {
        pointer-events: none;
      }

      .dnd-over-drop-area {
        outline: none;
        border: 2px solid var(--button-blue);

        &.main {
          grid-area: main;
          border-right-width: 0;
          border-bottom-width: 0;

          &.single {
            //height: calc(100% - 6px);
            //width: calc(100% - 6px);
            border-right-width: 2px;
            border-bottom-width: 2px;
          }
        }


        &.right {
          grid-area: right;
        }

        &.down {
          border-right-width: 0;
          grid-area: down;
        }
      }
    }
  }
}
</style>
