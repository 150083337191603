import { DelayedJob } from "../models/delayed_job";
import { User } from "../models/user";
import { Play } from "../models/play/play";
import { PlayScenario } from "../models/play/play_scenario";

declare module "../models/delayed_job" {
    interface DelayedJob {
        user: User

        play: Play
        play_scenario: PlayScenario
    }
}

DelayedJob.belongs_to("user", User, "user_id")
DelayedJob.belongs_to("play", Play, "play_id")
DelayedJob.belongs_to("play_scenario", PlayScenario, "play_scenario_id")
