import { effectScope } from "vue";
import { Props } from "./vue_record";
import { ValueIteratee } from "lodash";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { markRaw } from "vue";
import { VueRecord } from "./vue_record";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { reactive } from "../../helpers/vue/reactive";
import { computed } from "../../helpers/vue/computed";
import { Dictionary } from "../../libs/monkey_patches/array";
import { pluralize_record_name } from "./utils/pluralize_record_name";
import { Consoler } from "../../helpers/api_wrappers/consoler";

// <editor-fold desc="TYPES">
type VueRecordScopeClass = typeof VueRecordScope

export type OptionalProps = Partial<Props>
export type QuerifyProps<Props> = {
    [P in keyof Props]?: Props[P] | Array<Props[P]> | { [key: string]: QuerifyProps<Props> } | RegExp
}


type OrderDirection = "asc" | "desc"
type OrderTarget = "insensitive" | "sensitive" | "version"
type OrderRule<TQueryProps = OptionalProps> = [keyof TQueryProps, OrderDirection, OrderTarget]
type OrderPartialRule<TQueryProps = OptionalProps> = [keyof TQueryProps, OrderDirection]
export type Order = OrderRule[]
export type OrderInput<TQueryProps = OptionalProps> = keyof TQueryProps | OrderRule | OrderPartialRule | Order
export type ScopeCreateInput = {
    parent_scope: VueRecordScope
    query_link?: QueryLink
    order_input?: OrderInput
    is_stage?: boolean
    stage_id?: string
}

export type QueryLinkType = "equal" | "not_equal" | "greater" | "greater_or_equal" | "lesser" | "lesser_or_equal" | "match"
export type QueryLink = {
    type: QueryLinkType
    value: any
}
export type QueryChain = Array<QueryLink>
// </editor-fold>

export const EMPTY_OBJECT: any = Object.freeze({})
export const EMPTY_ARRAY: readonly any[] = Object.freeze([])

const console = new Consoler("warn")
export class VueRecordScope {
    ['constructor']: typeof VueRecordScope

    static effect_scope = effectScope(true)
    static ModelClass: typeof VueRecord

    order_config: Order = null
    query_chain: QueryChain = []

    is_stage: boolean = false
    stage_id: string
    rthis: VueRecordScope

    scope_dirty_counter: { value: number }
    unordered_records: InstanceType<this['constructor']['ModelClass']>[]
    records: InstanceType<this['constructor']['ModelClass']>[]

    length: number
    count: number // same as length

    // <editor-fold desc="CONSTRUCTOR">
    constructor(input: ScopeCreateInput = { parent_scope: null }) {
        this.set_default_create_opts(input)

        this.is_stage = input.is_stage
        if (this.is_stage && input.stage_id == null) {
            input.stage_id = generate_uuid();
        }
        this.stage_id = input.stage_id

        this.order_config = markRaw(this._parse_order_input(input.order_input))
        if (input.query_link != null) {
            if (input.parent_scope == null) {
                this.query_chain = [input.query_link]
            } else {
                this.query_chain = [...input.parent_scope.query_chain, input.query_link]
            }
        } else if (input.parent_scope != null) {
            this.query_chain = [...input.parent_scope.query_chain]
        }
        this.scope_dirty_counter = reactive({ value: 0 })
        this.rthis = reactive(this)
        this.unordered_records = computed(() => {
            const array = this.constructor.ModelClass.get_store(this.stage_id).execute_query_chain(this.query_chain)
            if (array.length == 0) return EMPTY_ARRAY
            return array
        }) as InstanceType<this['constructor']['ModelClass']>[]

        this.records = computed(() => {
            console.log(`COMPUTING records order ${this.constructor.ModelClass.name}`);
            return this._apply_order(this.rthis.unordered_records as InstanceType<this['constructor']['ModelClass']>[])
        }) as InstanceType<this['constructor']['ModelClass']>[]

        this.length = computed(() => this.rthis.unordered_records.length)
        this.count = this.length
    }

    static new<T extends VueRecordScopeClass>(this: T, input: ScopeCreateInput = { parent_scope: null }): InstanceType<T> {
        let scope;
        this.effect_scope.run(() => {
            scope = (new this(input)).rthis
        })
        return scope
    }

    // </editor-fold>

    keys() {
        return this.toArray().pluck("key")
    }

    names(limit = 3) {
        const pluralize_if_needed = (record_name: string, labels: string[]) => {
            if (labels.length > 1) return pluralize_record_name(record_name)
            return record_name
        }

        const names = this.toArray().map(r => r.name())

        let text = ""
        if (names.length > 0) {
            text += names.slice(0, limit).map(n => `<strong>${n}</strong>`).join(", ")
            if (names.length > limit) {
                text += ` and <strong>${names.length - limit}</strong> more`
            }
            text += ` ${pluralize_if_needed(this.constructor.ModelClass.resource_name, names)}`
        }
        return text
    }

    delete_warning_text() {
        return `You are about to delete<br>${this.names()}`
    }

    unload() {
        this.each(r => r.unload())
    }

    /**
     * Default dir is asc
     *
     * Default how is insensitive
     * @example order(".phone.props.name") - to order by name in phone association
     * @example order("created_at", "desc") - to order descending by date
     * @example order("version_name", "asc", "version") - to order by version value, ascending
     * @example order("name", "asc", "sensitive") - to order by name, case sensitive
     */
    order(by: OrderInput<InstanceType<this['constructor']['ModelClass']>['props']>, dir: "asc" | "desc" = null, how: "insensitive" | "sensitive" | "version" = null) {
        if (what_is_it(by) == "String") {
            if (dir == null) dir = "asc"
            if (how == null) how = "insensitive"
            // @ts-ignore
            by = [by, dir, how]
        }
        const final_order = [...this.order_config]
        final_order.push(by as OrderRule)
        return this.constructor.new({
            parent_scope: this,
            order_input: final_order as OrderInput,
            stage_id: this.stage_id,
            is_stage: this.is_stage
        }) as InstanceType<this['constructor']['ModelClass']['ScopeClass']>
    }

    toArray(): InstanceType<this['constructor']['ModelClass']>[] {
        return this.records
    }

    stage(stage_id: string = null): this {
        if (stage_id == null) stage_id = generate_uuid();
        this.records.each(r => r.stage(stage_id))
        let scope = this.constructor.ModelClass.get_scope(stage_id) as any
        this.query_chain.each(query_link => {
            scope = scope._new_scope(query_link)
        })
        return scope
    }

    /** disposes the whole store for this stage_id */
    dispose_stage() {
        if (!this.is_stage) return;

        this.constructor.ModelClass.dispose_stage(this.stage_id)
    }

    revert_staged() {
        if (this.is_stage) {
            this.constructor.ModelClass.get_store(this.stage_id).revert_staged()
        }
    }

    find(primary_key: this['constructor']['ModelClass']['primary_key']): InstanceType<this['constructor']['ModelClass']> {
        return this.constructor.ModelClass.get_store(this.stage_id).find(primary_key) as InstanceType<this['constructor']['ModelClass']>
    }

    where(query?: QuerifyProps<InstanceType<this['constructor']['ModelClass']>['props']>): InstanceType<this['constructor']['ModelClass']['ScopeClass']> {
        if (query == null) return this as InstanceType<this['constructor']['ModelClass']['ScopeClass']>

        return this._new_scope({
            type: "equal",
            value: query
        })
    }

    not(query?: QuerifyProps<InstanceType<this['constructor']['ModelClass']>['props']>): InstanceType<this['constructor']['ModelClass']['ScopeClass']> {
        return this._new_scope({
            type: "not_equal",
            value: query
        })
    }

    greater(query?: QuerifyProps<InstanceType<this['constructor']['ModelClass']>['props']>): InstanceType<this['constructor']['ModelClass']['ScopeClass']> {
        return this._new_scope({
            type: "greater",
            value: query
        })
    }

    greater_or_equal(query?: QuerifyProps<InstanceType<this['constructor']['ModelClass']>['props']>): InstanceType<this['constructor']['ModelClass']['ScopeClass']> {
        return this._new_scope({
            type: "greater_or_equal",
            value: query
        })
    }

    lesser(query?: QuerifyProps<InstanceType<this['constructor']['ModelClass']>['props']>): InstanceType<this['constructor']['ModelClass']['ScopeClass']> {
        return this._new_scope({
            type: "lesser",
            value: query
        })
    }

    lesser_or_equal(query?: QuerifyProps<InstanceType<this['constructor']['ModelClass']>['props']>): InstanceType<this['constructor']['ModelClass']['ScopeClass']> {
        return this._new_scope({
            type: "lesser_or_equal",
            value: query
        })
    }

    match(query?: QuerifyProps<InstanceType<this['constructor']['ModelClass']>['props']>): InstanceType<this['constructor']['ModelClass']['ScopeClass']> {
        return this._new_scope({
            type: "match",
            value: query
        })
    }

    // <editor-fold desc="SHORTHANDS TO ARRAY">
    first() {
        return this.toArray().first()
    }

    last() {
        return this.toArray().last()
    }

    each(callbackFn: (value: InstanceType<this['constructor']['ModelClass']>, index: number, array: InstanceType<this['constructor']['ModelClass']>[]) => void): Array<InstanceType<this['constructor']['ModelClass']>> {
        return this.toArray().each(callbackFn)
    }

    reverse_each(callbackFn: (value: InstanceType<this['constructor']['ModelClass']>, index: number, array: InstanceType<this['constructor']['ModelClass']>[]) => void): Array<InstanceType<this['constructor']['ModelClass']>> {
        return this.toArray().reverse_each(callbackFn)
    }

    pluck<U extends keyof InstanceType<this['constructor']['ModelClass']>>(attr: Array<U extends ((...args: any[]) => infer R) ? never : U>): Array<{ [K in U]: InstanceType<this['constructor']['ModelClass']>[K] }[U] extends ((...args: any[]) => infer R) ? R[] : { [K in U]: InstanceType<this['constructor']['ModelClass']>[K] }[U][]>;
    pluck<U extends keyof InstanceType<this['constructor']['ModelClass']>>(attr: U extends ((...args: any[]) => infer R) ? never : U): { [K in U]: InstanceType<this['constructor']['ModelClass']>[K] }[U] extends ((...args: any[]) => infer R) ? R[] : { [K in U]: InstanceType<this['constructor']['ModelClass']>[K] }[U][];
    pluck(attr: any | any[]): any[] | any[][] {
        return this.toArray().pluck(attr)
    }

    map<U>(callbackfn: (value: InstanceType<this['constructor']['ModelClass']>, index: number, array: InstanceType<this['constructor']['ModelClass']>[]) => U, thisArg?: any): U[] {
        return this.toArray().map(callbackfn, thisArg)
    }

    some(callbackfn: (value: InstanceType<this['constructor']['ModelClass']>, index: number, array: InstanceType<this['constructor']['ModelClass']>[]) => any, thisArg?: any) {
        return this.toArray().some(callbackfn, thisArg)
    }

    every(callbackfn: (value: InstanceType<this['constructor']['ModelClass']>, index: number, array: InstanceType<this['constructor']['ModelClass']>[]) => any, thisArg?: any) {
        return this.toArray().every(callbackfn, thisArg)
    }

    filter<S extends InstanceType<this['constructor']['ModelClass']>>(predicate: (value: InstanceType<this['constructor']['ModelClass']>, index: number, array: InstanceType<this['constructor']['ModelClass']>[]) => unknown): S[] {
        return this.toArray().filter(predicate) as S[]
    }

    reject(predicate: (value: InstanceType<this['constructor']['ModelClass']>, index: number, array: InstanceType<this['constructor']['ModelClass']>[]) => any): InstanceType<this['constructor']['ModelClass']>[] {
        return this.toArray().reject(predicate)
    }

    group(): Dictionary<InstanceType<this['constructor']['ModelClass']>[]> {
        return this.toArray().group()
    }

    group_by(iteratee?: ValueIteratee<InstanceType<this['constructor']['ModelClass']>>): Dictionary<InstanceType<this['constructor']['ModelClass']>[]> {
        return this.toArray().group_by(iteratee)
    }

    uniq_by(iteratee?: ValueIteratee<InstanceType<this['constructor']['ModelClass']>>): Array<InstanceType<this['constructor']['ModelClass']>> {
        return this.toArray().uniq_by(iteratee)
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    private set_default_create_opts(input: ScopeCreateInput) {
        if (!input.hasOwnProperty("is_stage")) input.is_stage = false
    }

    private _new_scope(query_link: QueryLink) {
        return this.constructor.new({
            parent_scope: this,
            query_link,
            stage_id: this.stage_id,
            is_stage: this.is_stage
        }) as InstanceType<this['constructor']['ModelClass']['ScopeClass']>
    }

    private _apply_order(records: InstanceType<this['constructor']['ModelClass']>[]) {
        if (records.length == 0) return records

        type Model = InstanceType<this['constructor']['ModelClass']>

        let ordered = records
        for (let i = this.order_config.length - 1; i >= 0; --i) {
            const order = this.order_config[i];
            let order_fixer = 1
            if (order[1] == "desc") {
                order_fixer = -1
            }
            const property_key = order[0]
            // key can be chain of methods if it starts with .
            const keys = (property_key as string).split(".")

            const get_value_for_record = (record: Model, keys: string[], target: OrderTarget) => {
                let value: any = record;
                if (keys[0] != "") {
                    value = record.props;
                }
                keys.forEach(key => {
                    if (value != null) value = value[key]
                })
                return this._convert_order_value_to_order_target_value(value, target)
            }

            const get_compare_value_for_array = (a_array: any[], b_array: any[]) => {
                if (a_array.length > b_array.length) {
                    while (a_array.length > b_array.length) {
                        b_array.push(0)
                    }
                } else if (a_array.length < b_array.length) {
                    while (a_array.length < b_array.length) {
                        a_array.push(0)
                    }
                }
                for (let i = 0; i < a_array.length; ++i) {
                    if (a_array[i] < b_array[i]) return -1 * order_fixer
                    if (a_array[i] > b_array[i]) return order_fixer
                }
                return 0
            }

            // negative if a is less than b,
            // positive if a is greater than b,
            // and zero if they are equal
            ordered = ordered.sort((a_record: Model, b_record: Model) => {
                const a_value = get_value_for_record(a_record, keys, order[2])
                const b_value = get_value_for_record(b_record, keys, order[2])

                if (a_value instanceof Array) {
                    if (b_value instanceof Array) {
                        return get_compare_value_for_array(a_value, b_value)
                    } else {
                        return get_compare_value_for_array(a_value, [b_value])
                    }
                } else {
                    if (b_value instanceof Array) {
                        return get_compare_value_for_array([a_value], b_value)
                    } else {
                        if (a_value < b_value) return -1 * order_fixer
                        if (a_value > b_value) return order_fixer
                        return 0
                    }
                }
            })
        }
        return ordered
    }

    private _convert_order_value_to_order_target_value(value: any, target: OrderTarget) {
        switch (target) {
            case "insensitive":
                if (value instanceof Date) return value
                return value?.toString()?.toLowerCase()
            case "sensitive":
                return value
            case "version":
                return this._extract_version_parts(value, target)
            default:
                return value?.toString()
        }
    }

    private _extract_version_parts(value: any, target: OrderTarget) {
        let version_parts: any;
        const it_is = what_is_it(value)
        if (it_is == "String") {
            version_parts = (value as string).split(".").map(version_part => {
                let number = Number(version_part.replace(/\D/g, ''))
                if (isNaN(number)) number = 0
                return number
            })
        } else if (it_is == "Number") {
            version_parts = [value as number]
        } else if (value instanceof Array) {
            version_parts = value.map(v => this._extract_version_parts(v, target))
            version_parts = version_parts.map((value: any) => {
                return this._convert_order_value_to_order_target_value(value, target)
            })
        } else {
            version_parts = [0]
        }

        return version_parts
    }

    private _parse_order_input(order_input: OrderInput) {
        const order: Order = []
        if (order_input != null) {
            const _process_single_input = (input: any) => {
                if (what_is_it(input) == "String") {
                    order.push([input, "asc", "insensitive"])
                } else if (what_is_it(input) == "Array") {
                    if (input.length == 2) {
                        order.push([input[0], input[1], "insensitive"] as OrderRule)
                    } else {
                        order.push(input as OrderRule)
                    }
                } else {
                    console.error("Don't know how to parse order input:", order_input)
                }
            }

            // string
            if (what_is_it(order_input) == "String") {
                _process_single_input(order_input)
            } else if (what_is_it(order_input) == "Array") {
                if (what_is_it((order_input as any)[0]) == "Array") {
                    // this is complete rule, array in array
                    (order_input as any).forEach((input: any) => {
                        _process_single_input(input)
                    })
                } else {
                    // this is single rule
                    _process_single_input(order_input)
                }
            }
        }
        return order
    }
    // </editor-fold>
}
