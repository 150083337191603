<template>
  <SettingsItem
      :id="setting_items.project.mobile.id"
      :title="setting_items.project.mobile.title"
  >
    <div v-if="loaded"
         class="container">
      <div class="row">
        <div class="col s6">
          <Input
              id="appium_command_timeout_input"
              v-model="mobile_props.appium_command_timeout"
              label="Appium command timeout (in seconds) | Implicit wait"
              :disabled="is_viewer_role || is_user_role"
              :validator="form_validator.register('appium_command_timeout', ProjectVersionSetting.field_validators.appium_command_timeout)"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          iOS setup
        </div>
      </div>

      <!--<editor-fold desc="1. Team ID">-->
      <div class="row align-start">
        <div class="col s4">
          1. Enter your apple developer Team ID
        </div>

        <div class="col s8">
          <Input
              id="appium_command_timeout_input"
              v-model="project_mobile_props.apple_team_id"
              :disabled="is_viewer_role || is_user_role"
              placeholder="Team ID"
          />
        </div>
      </div>
      <!--</editor-fold>-->

      <!--<editor-fold desc="2. CSR">-->
      <div v-if="step_2"
           class="row align-start">
        <div class="col s4">
          2. Generate Certificate Signing Request
        </div>

        <div v-if="project.props.apple_certificate_signing_request == null"
             class="col s8 align-center">
          <Button
              id="generate_csr_button"
              :min_width="100"
              :disabled="is_viewer_role || is_user_role"
              text="Generate"
              @click="generate_csr"
          />

          <template v-if="current.user.is_superadmin()">
            <span> or </span>

            <Button
                id="upload_csr_button"
                text="Upload"
                :min_width="100"
                @click="show_upload_csr = true"
            />
          </template>
        </div>

        <div v-else
             class="col s8 align-center">
          <Button
              id="download_csr_button"
              text="Download csr"
              :disabled="is_viewer_role || is_user_role"
              :min_width="100"
              @click="download_csr"
          />
          <Button
              id="download_key_button"
              :disabled="is_viewer_role || is_user_role"
              text="Download key"
              :min_width="100"
              @click="download_key"
          />
          <ActionIcon
              id="purge_csr_action"
              icon_class="fa fa-times"
              color_class="red"
              title="Purge Certificate Signing Request"
              :disabled="is_viewer_role || is_user_role"
              @click="purge_csr"
          />
          <span class="file-info">
            Generated on:
            <br>
            {{ project.props.apple_certificate_signing_request.created_at }}
          </span>
        </div>
      </div>
      <!--</editor-fold>-->

      <!--<editor-fold desc="3. CER">-->
      <div v-if="step_3"
           class="row align-start">
        <div class="col s4">
          3. Upload the certificate
        </div>

        <div v-if="project.props.apple_certificate_p12 == null"
             class="col s8">
          <Button
              id="upload_certificate_button"
              text="Upload"
              :min_width="100"
              :disabled="is_viewer_role || is_user_role"
              @click="upload_cer"
          />
        </div>
        <div v-else
             class="col s8 align-center">
          <Button
              id="download_cer_button"
              :disabled="is_viewer_role || is_user_role"
              text="Download"
              :min_width="100"
              @click="download_cer"
          />
          <ActionIcon
              id="purge_cer_action"
              icon_class="fa fa-times"
              color_class="red"
              :disabled="is_viewer_role || is_user_role"
              title="Purge Certificate"
              @click="purge_cer"
          />
          <span class="file-info">
            Uploaded on:
            <br>
            {{ project.props.apple_certificate_p12.created_at }}
          </span>
        </div>
      </div>
      <!--</editor-fold>-->


      <!--<editor-fold desc="4. WDA Profile">-->
      <div v-if="step_4"
           class="row align-start">
        <div class="col s4"
             style="overflow-wrap: anywhere"
        >
          4. Upload WebDriver provisioning profile<br>
          (com.facebook.WebDriverAgentRunner*)
        </div>

        <div v-if="project.props.webdriver_mobile_provisioning_id == null"
             class="col s8">
          <Button
              id="upload_webdriver_provisioning_profile_button"
              :min_width="100"
              text="Upload"
              :disabled="is_viewer_role || is_user_role"
              @click="upload_wda_provisioning"
          />
        </div>
        <div v-else
             class="col s8 align-center">
          <Button
              id="download_wda_provisioning_button"
              text="Download"
              :min_width="100"
              :disabled="is_viewer_role || is_user_role"
              @click="download_wda_provisioning"
          />
          <ActionIcon
              id="purge_wda_profile_action"
              icon_class="fa fa-times"
              color_class="red"
              title="Purge WebDriver Provisioning"
              :disabled="is_viewer_role || is_user_role"
              @click="purge_wda_provisioning"
          />
          <span class="file-info">
            Uploaded on:
            <br>
            {{ project.webdriver_mobile_provisioning?.props?.created_at }}
          </span>
        </div>
      </div>
      <!--</editor-fold>-->

      <!--<editor-fold desc="5. APP Profile">-->
      <div v-if="step_5"
           class="row align-start">
        <div class="col s4">
          5. (optional) Upload additional app provisioning profiles
        </div>

        <div class="col s4">
          <Button
              id="upload_app_provisioning_profile_button"
              :min_width="100"
              text="Upload"
              :disabled="is_viewer_role || is_user_role"
              @click="upload_app_provisioning"
          />
          <Button
              v-if="deletion_markers.marked().length > 0 && !is_viewer_role && !is_user_role"
              id="delete_selected_mobile_provisionings"
              text="Delete"
              color_class="red"
              @click="delete_marked_mobile_provisionings"
          />
        </div>

        <div class="col s4">
          <Input
              id="mobile_provisionings_filter_input"
              v-model="filter"
              placeholder="Filter"
              :throttle_time="200"
          />
        </div>
      </div>
      <!--</editor-fold>-->


      <!--<editor-fold desc="MOBILE PROVISIONINGS">-->
      <div v-if="step_5"
           class="row">
        <div class="col s12 table-wrapper">
          <Table id="mobile_provisionings_table">
            <template #thead>
              <tr>
                <template v-for="(col, key) in visible_columns"
                          :key="key">
                  <th v-if="key == 'delete'"
                      :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                      @click.stop="all_marked = !all_marked">
                    <Checkbox
                        v-model="all_marked"
                        label=""
                        :for_table="true"
                        color_class="red"
                    />
                  </th>
                  <th v-else
                      :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                      :data-priority="orders.priority(key)"
                      @click="orders.toggle(key)">
                    {{ col.name }}
                  </th>
                </template>
              </tr>
            </template>

            <template #tbody>
              <tr v-for="row in ordered_rows"
                  :key="`${row.record.props.id}_${row.reactor.value}`"
                  class="mobile-provisioning-row"
              >
                <template v-for="(col, index) in row.cols"
                          :key="index">
                  <td v-if="col.column_key == 'delete'"
                      :title="col.title"
                      :class="col.classes"
                      @click.stop="deletion_markers.marker(row.record).toggle()">
                    <Checkbox
                        v-model="deletion_markers.marker(row.record).value"
                        label=""
                        :for_table="true"
                        color_class="red"
                    />
                  </td>
                  <td v-else
                      v-moment="col.moment"
                      :title="col.title"
                      :class="col.classes"
                      v-html="col.html"/>
                </template>
              </tr>
            </template>
          </Table>
        </div>
      </div>
      <!--</editor-fold>-->


    </div>
    <Loading
        v-else
        type="rotating_plane"
        :inflate="true"
        :size="5"
    />
    <UploadCsr v-if="show_upload_csr"
               :project="project"
               @exit="show_upload_csr = false"
    />
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsItem from "../../SettingsItem.vue";
import { PropType } from "vue";

import { ProjectVersion } from "../../../../vue_record/models/project_version";
import { Project } from "../../../../vue_record/models/project";
import Input from "../../../testa/Input.vue";
import { ProjectVersionSetting } from "../../../../vue_record/models/project_version_setting";
import { MobileUpdateProps } from "../../../../vue_record/models/project_version_setting";
import { MobileUpdateProps as ProjectMobileUpdateProps } from "../../../../vue_record/models/project";
import Button from "../../../testa/Button.vue";
import Table from "../../../testa/Table.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import UploadCsr from "./UploadCsr.vue";
import ActionIcon from "../../../testa/ActionIcon.vue";
import Loading from "../../../testa/Loading.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { MobileProvisioning } from "../../../../vue_record/models/mobile_provisioning";
import { Validator } from "../../../../helpers/validator/validator";
import { click_on_enter } from "../../../../helpers/events/click_on_enter";
import ConfirmDialogue from "../../../testa/confirm_dialogue/confirm_dialgue";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";

export default defineComponent({
    components: {
        Loading,
        ActionIcon,
        UploadCsr,
        Checkbox,
        Table,
        Button,
        Input,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true,
        },
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            show_upload_csr: false,
            mobile_props: {
                appium_command_timeout: this.project_version_setting.props.appium_command_timeout,
            } as MobileUpdateProps,

            project_mobile_props: {
                apple_team_id: this.project.props.apple_team_id,
            } as ProjectMobileUpdateProps,
            filter: "",
            loaded: false,
            deletion_markers: new RecordMarker([]),
            all_marked: false,
            columns: {
                delete: {
                    filterable: false,
                    orderable: false,
                    action: true,
                    hidden: this.is_viewer_role || this.is_user_role
                },
                name: {
                    name: "Name",
                    html: (record) => record.props.name,
                },
                team_id: {
                    name: "Team ID",
                    html: (record) => record.props.team_identifier,
                },
                app_id: {
                    name: "App ID",
                    html: (record) => record.props.application_identifier,
                },
                expiring: {
                    name: "Expiring",
                    moment: (record) => record.props.expiration_date,
                    order_value: (record) => record.props.expiration_date,
                },
                devices: {
                    name: "Devices (in testa)",
                    orderable: false,
                    html: (record) => {
                        return `${record.devices.toArray().length} (${record.testa_phones.toArray().length})`
                    },
                },
                warning: {
                    filterable: false,
                    orderable: false,
                    action: true,
                },
            } as RecordColumns<MobileProvisioning>,
            orders: new RecordOrder([["name", "asc"]]),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Validator() {
            return Validator
        },
        current() {
            return current
        },
        ProjectVersionSetting() {
            return ProjectVersionSetting
        },
        setting_items() {
            return setting_items
        },
        step_2() {
            return Validator.is_present(this.project_mobile_props.apple_team_id) || this.project.props.apple_certificate_signing_request
        },
        step_3() {
            return this.project.props.apple_certificate_signing_request ||
                this.project.props.apple_certificate_p12
        },
        step_4() {
            return this.project.props.webdriver_mobile_provisioning_id || this.project.props.apple_certificate_p12
        },
        step_5() {
            return this.project.props.webdriver_mobile_provisioning_id
        },
        records() {
            return this.project.mobile_provisionings.app_provisionings.toArray()
        },
        visible_columns() {
            return RecordsTable.visible_columns<MobileProvisioning>(this.columns);
        },
        rows() {
            return RecordsTable.generate_rows<MobileProvisioning>(this.visible_columns, this.records as MobileProvisioning[])
        },
        filtered_rows() {
            return RecordsTable.filter<MobileProvisioning>({
                columns: this.visible_columns,
                rows: this.rows,
                filter: this.filter,
            })
        },
        ordered_rows() {
            return RecordsTable.order<MobileProvisioning>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'filtered_rows'() {
            this.deletion_markers.set_records(this.filtered_rows.map(c => c.record) as MobileProvisioning[])
        },
        'all_marked'() {
            this.deletion_markers.set_all(this.all_marked)
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    created() {

    },
    beforeMount() {
    },
    mounted() {
        this.$emit("setting-item-mounted", this);
        MobileProvisioning.ClientClass.index()
            .then(() => {
                this.loaded = true
            })
            .catch(() => {
                toastr.error("Failed to load mobile provisionings")
            })
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        click_on_enter,
        save() {
            return this.apply()
        },
        apply() {
            if (this.is_viewer_role || this.is_user_role) return null;

            const project_version_setting_promise = this.project_version_setting.client.update(this.mobile_props)
            const project_promise = this.project.client.update(this.project_mobile_props)
            return $.when(project_version_setting_promise, project_promise).fail(() => {
                toastr.error("Failed to save mobile settings")
            })
        },
        generate_csr() {
            return this.project.client.generate_apple_certificate_signing_request()
        },
        download_csr() {
            return this.project.client.download_apple_certificate_signing_request()
        },
        purge_csr() {
            this.project.client.purge_apple_certificate_signing_request()
        },
        upload_cer() {
            this.project.client.upload_apple_certificate()
        },
        download_cer() {
            this.project.client.download_apple_certificate()
        },
        download_key() {
            this.project.client.download_apple_key()
        },
        purge_cer() {
            ConfirmDialogue.show({
                html: "You are about to delete apple certificate. This will also remove all app provisioning profiles!",
                confirm_color_class: "red",
            }).then((is_confirmed: Boolean) => {
                if (is_confirmed) {
                    this.project.client.purge_apple_certificate();
                }
            })
        },
        upload_wda_provisioning() {
            MobileProvisioning.ClientClass.upload({
                type: Enum.MobileProvisioning.Type.WEBDRIVER,
                project_id: this.project.props.id,
            }).catch((error) => toastr.error(error.message))
        },
        download_wda_provisioning() {
            this.project.webdriver_mobile_provisioning.client.download()
        },

        purge_wda_provisioning() {
            MobileProvisioning.delete(this.project.props.webdriver_mobile_provisioning_id)
        },
        upload_app_provisioning() {
            MobileProvisioning.ClientClass.upload({
                type: Enum.MobileProvisioning.Type.APP,
                project_id: this.project.props.id,
            }).catch((error) => toastr.error(error.message))
        },
        delete_marked_mobile_provisionings() {
            MobileProvisioning.delete(this.deletion_markers.marked().map(r => r.props.id))
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
}

.table-wrapper {
  // to ensure that table is still shown when overflowing
  min-height: fit-content;
}

.file-purge-icon {
  font-size: 1.1em;
  color: var(--button-red);
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  &:hover,
  &:focus {
    background-color: var(--primary-background-color);
    filter: brightness(1.2);
  }
}

.file-info {
  font-size: 0.7em;
}
</style>
