export function generate_eid() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = characters.charAt(Math.floor(Math.random() * (characters.length - 10))); // Avoid numbers as first character

    for (let i = 1; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}
