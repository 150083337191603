import { ScenarioSavepoint } from "../models/scenario_savepoint";
import { Scenario } from "../models/scenario";
import { User } from "../models/user";
import { PlayScenarioScope } from "../scopes/play/play_scenario_scope";
import { PlayScenario } from "../models/play/play_scenario";


declare module "../models/scenario_savepoint" {
    interface ScenarioSavepoint {
        play_scenarios: PlayScenarioScope;
        scenario: Scenario
        user: User
    }
}

ScenarioSavepoint.belongs_to("scenario", Scenario, "scenario_id")
ScenarioSavepoint.belongs_to("user", User, "user_id")

ScenarioSavepoint.has_many("play_scenarios", PlayScenario, "scenario_savepoint_id")
