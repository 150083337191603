import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d67d30a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = {
  ref: "content",
  class: "content"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SettingsSidebar = _resolveComponent("SettingsSidebar")
  const _component_UserEdit = _resolveComponent("UserEdit")
  const _component_Preferences = _resolveComponent("Preferences")
  const _component_Variables = _resolveComponent("Variables")
  const _component_Gemfile = _resolveComponent("Gemfile")
  const _component_Modules = _resolveComponent("Modules")
  const _component_WebSettings = _resolveComponent("WebSettings")
  const _component_MobileSettings = _resolveComponent("MobileSettings")
  const _component_Integrations = _resolveComponent("Integrations")
  const _component_Proxies = _resolveComponent("Proxies")
  const _component_CodeLint = _resolveComponent("CodeLint")
  const _component_Users = _resolveComponent("Users")
  const _component_ProjectVersions = _resolveComponent("ProjectVersions")
  const _component_Projects = _resolveComponent("Projects")
  const _component_Browsers = _resolveComponent("Browsers")
  const _component_Phonectors = _resolveComponent("Phonectors")
  const _component_Workers = _resolveComponent("Workers")
  const _component_DelayedJobs = _resolveComponent("DelayedJobs")
  const _component_FeatureFlags = _resolveComponent("FeatureFlags")
  const _component_Button = _resolveComponent("Button")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "vue_settings_modal",
    ref: "modal",
    show_cancel: false,
    show_deny: false,
    confirm_text: "Save",
    dismissible: false,
    focus_last_active_on_unmount: true,
    form_validator: _ctx.form_validator,
    padding: 0,
    confirm_action: _ctx.save
  }, {
    sidebar: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SettingsSidebar, {
            selected_item_id: _ctx.current_item_id,
            onItemClick: _ctx.change_item
          }, null, 8 /* PROPS */, ["selected_item_id", "onItemClick"])
        ])
      ])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createCommentVNode("<editor-fold desc=\"USER_SETTINGS\">"),
          (_ctx.visited_item_ids.includes(_ctx.items.user.account.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_UserEdit, {
                key: 0,
                user: _ctx.current.user,
                navigated_from_table: false,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('account', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.user.account.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["user", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.user.account.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.user.preferences.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Preferences, {
                key: 1,
                project_version_setting: _ctx.project_version_setting,
                user_setting: _ctx.user_setting,
                form_validator: _ctx.form_validator.register_child_form('preferences', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.user.preferences.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project_version_setting", "user_setting", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.user.preferences.id]
              ])
            : _createCommentVNode("v-if", true),
          _createCommentVNode("</editor-fold>"),
          _createCommentVNode("<editor-fold desc=\"PROJECT SETTINGS\">"),
          (_ctx.visited_item_ids.includes(_ctx.items.project.variables.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Variables, {
                key: 2,
                project_version: _ctx.project_version,
                form_validator: _ctx.form_validator.register_child_form('variables', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.variables.id) }}),
                is_viewer_role: _ctx.is_viewer_role,
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project_version", "form_validator", "is_viewer_role", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.variables.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.gemfile.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Gemfile, {
                key: 3,
                project: _ctx.project,
                is_viewer_role: _ctx.is_viewer_role,
                form_validator: _ctx.form_validator.register_child_form('gemfile', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.gemfile.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project", "is_viewer_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.gemfile.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.modules.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Modules, {
                key: 4,
                project: _ctx.project,
                project_version_setting: _ctx.project_version_setting,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('modules', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.modules.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project", "project_version_setting", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.modules.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.web.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_WebSettings, {
                key: 5,
                project_version_setting: _ctx.project_version_setting,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('web_settings', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.web.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project_version_setting", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.web.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.mobile.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_MobileSettings, {
                key: 6,
                project_version_setting: _ctx.project_version_setting,
                project_version: _ctx.project_version,
                project: _ctx.project,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('mobile_settings', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.mobile.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project_version_setting", "project_version", "project", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.mobile.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.integrations.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Integrations, {
                key: 7,
                project_version: _ctx.project_version,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('integrations', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.integrations.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project_version", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.integrations.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.proxies.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Proxies, {
                key: 8,
                project: _ctx.project,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('proxies', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.proxies.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.proxies.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.code_lint.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_CodeLint, {
                key: 9,
                project: _ctx.project,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('code_lint', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.code_lint.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.code_lint.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.users.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Users, {
                key: 10,
                project: _ctx.project,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('users', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.users.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.users.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.project.project_versions.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_ProjectVersions, {
                key: 11,
                project: _ctx.project,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('project_versions', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.project.project_versions.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["project", "is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.project.project_versions.id]
              ])
            : _createCommentVNode("v-if", true),
          _createCommentVNode("</editor-fold>"),
          _createCommentVNode("<editor-fold desc=\"SUPERADMIN SETTINGS\">"),
          (_ctx.visited_item_ids.includes(_ctx.items.superadmin.all_users.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Users, {
                key: 12,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('all_users', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.superadmin.all_users.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.superadmin.all_users.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.superadmin.all_projects.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Projects, {
                key: 13,
                is_viewer_role: _ctx.is_viewer_role,
                is_user_role: _ctx.is_user_role,
                form_validator: _ctx.form_validator.register_child_form('all_projects', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.superadmin.all_projects.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["is_viewer_role", "is_user_role", "form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.superadmin.all_projects.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.superadmin.browsers.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Browsers, {
                key: 14,
                form_validator: _ctx.form_validator.register_child_form('browsers', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.superadmin.browsers.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.superadmin.browsers.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.superadmin.phonectors.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Phonectors, {
                key: 15,
                form_validator: _ctx.form_validator.register_child_form('phonectors', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.superadmin.phonectors.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.superadmin.phonectors.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.superadmin.workers.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_Workers, {
                key: 16,
                form_validator: _ctx.form_validator.register_child_form('workers', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.superadmin.workers.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.superadmin.workers.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.superadmin.delayed_jobs.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_DelayedJobs, {
                key: 17,
                form_validator: _ctx.form_validator.register_child_form('delayed_jobs', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.superadmin.delayed_jobs.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.superadmin.delayed_jobs.id]
              ])
            : _createCommentVNode("v-if", true),
          (_ctx.visited_item_ids.includes(_ctx.items.superadmin.feature_flags.id))
            ? _withDirectives((_openBlock(), _createBlock(_component_FeatureFlags, {
                key: 18,
                form_validator: _ctx.form_validator.register_child_form('feature_flags', { on_invalid_submit: () => { _ctx.change_item(_ctx.items.superadmin.feature_flags.id) }}),
                onSettingItemMounted: _ctx.on_item_mounted,
                onSettingItemUnmounted: _ctx.on_item_unmounted
              }, null, 8 /* PROPS */, ["form_validator", "onSettingItemMounted", "onSettingItemUnmounted"])), [
                [_vShow, _ctx.current_item_id == _ctx.items.superadmin.feature_flags.id]
              ])
            : _createCommentVNode("v-if", true),
          _createCommentVNode("</editor-fold>")
        ], 512 /* NEED_PATCH */)
      ])
    ]),
    extra_buttons_right: _withCtx(() => [
      _createVNode(_component_Button, {
        id: "apply_settings_button",
        text: "Apply",
        style: _normalizeStyle(_ctx.form_validator.valid ? '' : 'cursor: not-allowed'),
        form_validator: _ctx.form_validator,
        click_action: _ctx.apply
      }, null, 8 /* PROPS */, ["style", "form_validator", "click_action"]),
      _createVNode(_component_Button, {
        id: "cancel_settings_button",
        text: "Cancel",
        color_class: "grey",
        onClick: _ctx.cancel
      }, null, 8 /* PROPS */, ["onClick"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["form_validator", "confirm_action"]))
}