import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { PlayModalResponse } from "../../../vue_record/models/play/play";
import { PlayGroupProps } from "../../../vue_record/models/play/play_group";
import { BrowserGrouping } from "../../../vue_record/models/play/play_group";
import { PhoneGrouping } from "../../../vue_record/models/play/play_group";
import { AppGrouping } from "../../../vue_record/models/play/play_group";
import { ProxyGrouping } from "../../../vue_record/models/play/play_group";
import { PlayGroup } from "../../models/play/play_group";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PlayGroupClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlayGroup
    declare record: PlayGroup

    load_associated() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/play_groups/${this.key()}/associated`,
                type: 'GET',
                success: (data: PlayGroupProps) => {
                    PlayGroup.new(data);
                    resolve()
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes
            })
        })
    }

    static apply_browser_mappings(browser_grouping: BrowserGrouping) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/play/settings/apply_browser_mappings',
                type: 'POST',
                data: JSON.stringify({
                    browser_id: browser_grouping.replace_id,
                    browser_type: browser_grouping.replace_type,
                    is_mobile: browser_grouping.replace_is_mobile,
                    emulation_profile_name: browser_grouping.replace_emulation_profile_name,
                    width: browser_grouping.replace_width,
                    height: browser_grouping.replace_height,
                    scenario_setting_browser_ids: browser_grouping.browsers.map(s => s.props.id),
                    authenticity_token,
                }),
                processData: false,
                contentType: "application/json",
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }

    static apply_phone_mappings(phone_grouping: PhoneGrouping) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/play/settings/apply_phone_mappings',
                type: 'POST',
                data: JSON.stringify({
                    phone_project_id: phone_grouping.replace_id,
                    phone_type: phone_grouping.replace_type,
                    scenario_setting_phone_ids: phone_grouping.phones.map(s => s.props.id),
                    authenticity_token,
                }),
                processData: false,
                contentType: "application/json",
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }

    static apply_app_mappings(app_grouping: AppGrouping) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/play/settings/apply_app_mappings',
                type: 'POST',
                data: JSON.stringify({
                    app_id: app_grouping.replace_id,
                    app_package: app_grouping.replace_package,
                    scenario_setting_app_ids: app_grouping.apps.map(s => s.props.id),
                    authenticity_token,
                }),
                processData: false,
                contentType: "application/json",
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }

    static apply_proxy_mappings(proxy_grouping: ProxyGrouping) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/play/settings/apply_proxy_mappings',
                type: 'POST',
                data: JSON.stringify({
                    proxy_id: proxy_grouping.replace_id,
                    scenario_setting_ids: proxy_grouping.scenario_settings.map(s => s.props.id),
                    authenticity_token,
                }),
                processData: false,
                contentType: "application/json",
                success: () => resolve(),
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }

    static play_scenarios_modal(scenario_ids: number[], scenario_folder_ids: number[] = []) {
        return this._play_modal({ scenario_ids, scenario_folder_ids })
    }

    static play_scenario_folder_modal(scenario_folder_id: number) {
        return this._play_modal({ scenario_folder_id })
    }

    static play_group_modal(group_id: number) {
        return this._play_modal({ group_id })
    }

    private static _play_modal(target: {
        scenario_folder_id?: number,
        group_id?: number,
        scenario_ids?: number[],
        scenario_folder_ids?: number[]
    }) {
        let url: string
        let data: any;
        if (target.hasOwnProperty("group_id")) {
            url = `/play/queues/play_group`
            data = { group_id: target.group_id, authenticity_token }
        } else if (target.hasOwnProperty("scenario_folder_id")) {
            url = `/play/queues/play_scenario_folder`
            data = { scenario_folder_id: target.scenario_folder_id, authenticity_token }
        } else if (target.hasOwnProperty("scenario_ids") || target.hasOwnProperty("scenario_folder_ids")) {
            url = `/play/queues/play_scenarios`
            data = { scenario_ids: target.scenario_ids, scenario_folder_ids: target.scenario_folder_ids, authenticity_token }
        } else {
            throw new Error("No parameters for play modal")
        }
        return new Promise<PlayModalResponse>((resolve, reject) => {
            $.ajax({
                url,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify(data),
                statusCode: ajax_status_codes,
                success: (data: PlayModalResponse) => resolve(data),
                error: (error) => reject(error)
            })
        })
    }

}
