import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { GroupSavepointClient } from "../clients/group_savepoint_client";
import { GroupSavepointScope } from "../scopes/group_savepoint_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface GroupSavepointProps extends Props {
    id: number
    group_id: number
    user_id: number
    amendable: boolean
    save_id: string
    archived: boolean
    name: string
    group_scenarios: any
    created_at: Date
    updated_at: Date
}
export type QuerifiedGroupSavepointProps = QuerifyProps<GroupSavepointProps>
export type GroupSavepointCreateProps = Omit<GroupSavepointProps, 'id'>
export type GroupSavepointUpdateProps = Partial<GroupSavepointProps>

export interface GroupSavepointState extends State {}
export interface GroupSavepointComputed extends Computed {}
export interface GroupSavepointStaticState extends StaticState {
    load_promises: Record<number | string, Promise<GroupSavepoint>>
}

// </editor-fold>

const console = new Consoler("warn")
export class GroupSavepoint extends VueRecord {
    ['constructor']: typeof GroupSavepoint

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = GroupSavepointClient
    static ScopeClass = GroupSavepointScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: GroupSavepointStaticState = reactive<GroupSavepointStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "group_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof GroupSavepoint> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof GroupSavepoint>> = {}

    static field_validators: ModelValidatorOpts<GroupSavepointProps> = {}

    static resource_name = Enum.Resource.Label.GROUP_SAVEPOINT
    static resource_id = Enum.Resource.Id.GROUP_SAVEPOINT
    static icon_class = "fa-solid fa-grip-vertical"
    static color = () => get_css_var("--group-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: GroupSavepointClient
    declare props: GroupSavepointProps;
    declare state: GroupSavepointState;
    declare computed: GroupSavepointComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
GroupSavepoint.register_resource(GroupSavepoint)
GroupSavepointClient.ModelClass = GroupSavepoint
GroupSavepointScope.ModelClass = GroupSavepoint

global_event_bus.$on("after_project_version_unload", () => {
    GroupSavepoint.get_scope().unload()
})


on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            GroupSavepoint.unsync()
            if (project_version_id != null) GroupSavepoint.sync(`/sync/project_version/${project_version_id}/scenario_savepoints`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        GroupSavepoint: typeof GroupSavepoint
    }
}
window.GroupSavepoint = GroupSavepoint
// </editor-fold>

