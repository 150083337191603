// <editor-fold desc="TYPES">
import { BelongsToAssociations, HasManyAssociations, HasOneAssociations, RecordOpts, VueRecord } from "../../base/vue_record";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { VueRecordStore } from "../../base/vue_record_store";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { computed } from "../../../helpers/vue/computed";
import { fix_file_paths } from "../../../helpers/files/fix_file_paths";
import { TestaTree } from "../../../components/testa/tree/tree";
import { what_is_it } from "../../../helpers/generic/what_is_it";
import { Section } from "../../../components/testa/section_toggler/section";
import { StaticState } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumFileType } from "../../../auto_generated/enums";
import { Computed } from "../../base/vue_record";
import { LogFile } from "./log_file";
import { Props } from "../../base/vue_record";
import { LogFileFolderScope } from "../../scopes/non_db/log_file_folder_scope";
import { LogFileFolderClient } from "../../clients/non_db/log_file_folder_client";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { LogFileScope } from "../../scopes/non_db/log_file_scope";

export interface LogFileFolderProps extends Props {
    __old_path?: string
    path: string
    name: string
    size: number
    log_file_folder_path: string
    type: EnumFileType
    updated_at: Date
    created_at: Date
}
export type QuerifiedLogFileFolderProps = QuerifyProps<LogFileFolderProps>
export type LogFileFolderCreateProps = Partial<LogFileFolderProps>
export type LogFileFolderUpdateProps = Partial<LogFileFolderProps>
export interface LogFileFolderState extends State {}
export interface LogFileFolderComputed extends Computed {
    download_path: string
}
export interface LogFileFolderStaticState extends StaticState {
    children_promises: Record<string, Promise<LogFileFolderChildren>>
}

export type LogFileFolderChildren = {
    files: LogFile[],
    file_folders: LogFileFolder[]
}
// </editor-fold>

const console = new Consoler("info")
export class LogFileFolder extends VueRecord {
    ['constructor']: typeof LogFileFolder

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static discard_outdated = false
    static ClientClass = LogFileFolderClient
    static ScopeClass = LogFileFolderScope
    static readonly primary_key = "path"
    static sync_channels: string[] = []
    static state: LogFileFolderStaticState = reactive<LogFileFolderStaticState>({
        children_promises: {},
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof LogFileFolder> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof LogFileFolder>> = {}

    static field_validators: ModelValidatorOpts<LogFileFolderProps> = {}

    static resource_name = Enum.Resource.Label.LOG_FILE_FOLDER
    static resource_id = Enum.Resource.Id.LOG_FILE_FOLDER
    static icon_class = "fa-solid fa-folder-open"
    static color = () => get_css_var("--file-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: LogFileFolderClient
    declare props: LogFileFolderProps;
    declare state: LogFileFolderState;
    declare computed: LogFileFolderComputed;

    // </editor-fold>

    // <editor-fold desc="HOOKS">
    after_update(new_props: LogFileFolderProps, old_props: LogFileFolderProps, changes: (keyof LogFileFolderProps)[]) {
        super.after_update(new_props, old_props, changes);

        // <editor-fold desc="PATH">
        if (changes.includes("path")) {
            fix_file_paths(this, old_props.path, new_props.path)
        }
        // </editor-fold>
    }
    // </editor-fold>

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_logs_tree()) {
        return LogFileFolder.show_in_sidebar(this.key(), null, tree);
    }

    static async show_in_sidebar(file_folder_paths: string | string[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_logs_tree()) {
        let ids: string[];
        if (what_is_it(file_folder_paths) == "Array") {
            ids = file_folder_paths as string[]
        } else {
            ids = [file_folder_paths as string]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(file_folder_paths).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).forEach(file_folder_path => {
                    const file_folders = promise_response[file_folder_path]
                    const keys = [
                        LogFileFolder.tree_key(project_version_id),
                        ...file_folders.map(sf => sf.tree_key()),
                        LogFileFolder.find(file_folder_path).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: LogFileFolder.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        if (include_name) {
            return this.props.path
        } else {
            if (this.props.file_folder_path == null) return "/"
            return this.props.file_folder_path
        }
    }
    // </editor-fold>

    // <editor-fold desc="TREE">
    // <editor-fold desc="CONTEXT MENU">

    static _testa_tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<LogFileScope, LogFileFolderScope, LogFileFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}
                return { ...items, ...default_items }
            }
        }
    }

    _testa_tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<LogFileScope, LogFileFolderScope, LogFileFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                items.download = {
                    name: "Download",
                    icon: "fa-solid fa-download",
                    color: get_css_var("--button-blue"),
                    callback: () => {
                        const records_scope = node.tree.get_selected_records()
                        if (records_scope.log_files.count + records_scope.log_file_folders.count > 1) {
                            LogFileFolder.ClientClass.batch_download(records_scope.log_file_folders.toArray(), records_scope.log_files.toArray())
                        } else {
                            this.client.download();
                        }
                    }
                }

                items.reload = {
                    name: "Reload",
                    icon: "fa-solid fa-repeat",
                    color: get_css_var("--button-blue"),
                    callback: () => {
                        this.client.children(true)
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<LogFileScope, LogFileFolderScope, LogFileFolder> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.FILE_FOLDER,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            icon: computed(() => {
                return {
                    color: get_css_var("--file-color"),
                }
            }),

            folder: {
                open_fn: () => {
                    return this.client.children()
                },
                folder_scope: computed(() => this.log_file_folders.order("name")),
                file_scope: computed(() => this.log_files.order("name"))
            },
            contextmenu: this._testa_tree_contextmenu(),
        }
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
LogFileFolder.register_resource(LogFileFolder)
LogFileFolderClient.ModelClass = LogFileFolder
LogFileFolderScope.ModelClass = LogFileFolder
LogFileFolder.new({ path: "/backendoor", name: "backendoor", log_file_folder_path: null })
LogFileFolder.new({ path: "/core", name: "core", log_file_folder_path: null })
LogFileFolder.new({ path: "/dj", name: "dj", log_file_folder_path: null })
LogFileFolder.new({ path: "/filewatcher", name: "filewatcher", log_file_folder_path: null })
LogFileFolder.new({ path: "/spinner", name: "spinner", log_file_folder_path: null })
LogFileFolder.new({ path: "/web", name: "web", log_file_folder_path: null })
LogFileFolder.new({ path: "/ws", name: "ws", log_file_folder_path: null })

global_event_bus.$on("after_project_version_unload", () => {
    LogFileFolder.state.children_promises = {}
    LogFileFolder.get_scope().unload()
})

declare global {
    interface Window {
        LogFileFolder: typeof LogFileFolder
    }
}
window.LogFileFolder = LogFileFolder
// </editor-fold>

