import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumPlayStatus } from "../../../auto_generated/enums";
import { PhoneAppProps as ScenarioSettingPhoneAppProps } from "../../models/scenario_setting/phone_app";
import { EnumPlayType } from "../../../auto_generated/enums";
import { PlayScenarioClient } from "../../clients/play/play_scenario_client";
import { PlayScenarioScope } from "../../scopes/play/play_scenario_scope";
import { computed } from "../../../helpers/vue/computed";
import { PlayStreamManager } from "../../../components/play/reports/play/play_stream_manager";
import { send_ws } from "../../../helpers/generic/send_ws";
import { PlayNode } from "../../../types/play_node";
import _ from "lodash";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";
import { PhoneProps } from "../scenario_setting/phone";
import { ExtendedScenarioSettingProps } from "../scenario_setting";
import { BrowserProps } from "../scenario_setting/browser";
import { ScenarioProps } from "../scenario";
import { PlaySettingProps } from "./play_setting";
import { RecordOpts } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export interface PlayScenarioProps extends Props {
    id: number
    play_scenario_id: number
    scenario_savepoint_id: number
    delayed_job_id: number
    scenario_setting_id: number
    private_novnc_url: string
    public_novnc_url: string
    start_time: Date
    end_time: Date
    duration: number
    status: EnumPlayStatus
    error_message: string
    status_note: string
    status_screenshot_url: string
    execution_index: number
    current_line_number: number
    total_lines_number: number
    created_at: Date
    updated_at: Date
    keep_alive_requested_at: Date
    job_started_at: Date
    name: string
    scenario_worker_log_path: string
    watir_log_path: string
    selenium_log_path: string

    // from methods
    "main?": boolean
    progress: number
    "running?": boolean
    "finished?": boolean
    "faulty?": boolean
    backend_ws_channel: string
    video_url: string
}
export type QuerifiedPlayScenarioProps = QuerifyProps<PlayScenarioProps>
export type PlayScenarioCreateProps = Omit<PlayScenarioProps, 'id'>
export type PlayScenarioUpdateProps = Partial<PlayScenarioProps>

export interface PlayScenarioState extends State {
    command_history: string[]
    backend_ws_channel: string
    debug_command_index: number
    debugger_container: HTMLElement
    debugger_last_activation: Date
    debugger_frame_id: number

    /** should the inspector overlay be rendered */
    is_inspector_shown: boolean
}
export interface PlayScenarioComputed extends Computed {}
export interface PlayScenarioStaticState extends StaticState {
    load_promises: Record<number | string, Promise<PlayScenario>>
}


export interface PlayModalBrowserProps extends BrowserProps {
    vue_key: string
}

export interface PlayModalPhoneAppProps extends ScenarioSettingPhoneAppProps {
    vue_key: string
}

export interface PlayModalPhoneProps extends PhoneProps {
    apps: PlayModalPhoneAppProps[]
    vue_key: string
}

export interface PlayModalExtendedScenarioSettingProps extends ExtendedScenarioSettingProps {
    browsers: PlayModalBrowserProps[]
    phones: PlayModalPhoneProps[]
}

export type PlayScenarioModalResponse = {
    scenario_setting: PlayModalExtendedScenarioSettingProps,
    scenario: ScenarioProps,
    id: number,
    play_setting: PlaySettingProps,
    play_type: EnumPlayType
    replay_play_id?: number
}
// </editor-fold>

const console = new Consoler("warn")
export class PlayScenario extends VueRecord {
    ['constructor']: typeof PlayScenario

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlayScenarioClient
    static ScopeClass = PlayScenarioScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlayScenarioStaticState = reactive<PlayScenarioStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_scenario_id", "status"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlayScenario> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlayScenario>> = {}

    static field_validators: ModelValidatorOpts<PlayScenarioProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_SCENARIO
    static resource_id = Enum.Resource.Id.PLAY_SCENARIO
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlayScenarioClient
    declare props: PlayScenarioProps;
    declare state: PlayScenarioState;
    declare computed: PlayScenarioComputed;

    // </editor-fold>

    constructor(props: Props, opts: RecordOpts) {
        super(props, opts);
        this.state.command_history = []
        this.state.debug_command_index = -1
        this.state.backend_ws_channel = `/backend/play_scenario/${this.props.id}`;
        this.state.is_inspector_shown = computed(() => this.props.status == Enum.Play.Status.DEBUGGING)
    }

    // <editor-fold desc="ACTIONS">
    show_stream(play_stream_manager: PlayStreamManager) {
        play_stream_manager.show_stream(this)
    }

    end_stream(play_stream_manager: PlayStreamManager) {
        play_stream_manager.end_stream(this)
    }

    resume() {
        send_ws(this.state.backend_ws_channel, { action: Enum.Play.Action.CONTINUE });
    }

    pause() {
        send_ws(this.state.backend_ws_channel, { action: Enum.Play.Action.PAUSE });
    }

    skip() {
        send_ws(this.state.backend_ws_channel, { action: Enum.Play.Action.NEXT });
    }

    request_execution_data() {
        send_ws(this.state.backend_ws_channel, { action: Enum.Play.Action.DATA_REQUEST })
    }

    execute_command(cmd: string, debug_frame_id: number = null): void {
        const $frame_selector = $(`#frame_selector_${this.props.id}`)
        if ($frame_selector.is(":visible")) {
            debug_frame_id = parseInt(($frame_selector[0] as HTMLSelectElement).value)
        }

        send_ws(this.state.backend_ws_channel, {
            action: Enum.Play.Action.DEBUG,
            command: cmd,
            type: this.plays.first().project_version.project_version_setting.props.default_language,
            debug_frame_id
        });
        if (cmd != '') this.state.command_history.unshift(cmd);
        this.state.debug_command_index = -1;
    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    is_success() {
        return this.props.status == "success"
    }

    is_faulty() {
        return this.props["faulty?"]
    }

    is_debugging() {
        return this.props.status == Enum.Play.Status.DEBUGGING
    }

    is_warned() {
        return this.props.status == "warned"
    }

    is_skipped() {
        return this.props.status == "skipped"
    }

    is_finished() {
        return this.props["finished?"]
    }

    has_recording() {
        return this.props.video_url != null
    }

    has_vnc() {
        return ((this.props['running?'] || this.props.status == Enum.Play.Status.BOOTING) && this.vnc_url() != null)
    }

    has_stream() {
        return this.is_finished() ? this.has_recording() : this.has_vnc()
    }

    vnc_url() {
        return !this.plays.first()?.computed?.role_is_viewer ? this.props.private_novnc_url : this.props.public_novnc_url
    }
    // </editor-fold>

    // <editor-fold desc="COMPUTED">
    play_nodes: PlayNode[] = computed(() => {
        const rthis = this.get_reactive_object()
        const play_scenario_parts: PlayNode[] = rthis.play_scenario_parts.toArray();
        const play_snippets: PlayNode[] = rthis.play_snippets.toArray();
        const play_nodes: PlayNode[] = play_scenario_parts.concat(play_snippets).map(pn => pn.get_reactive_object())

        return _.orderBy(play_nodes, "props.execution_index") as PlayNode[]
    }) as PlayNode[]

    main_play_scenario: PlayScenario = computed(() => {
        const rthis = this.get_reactive_object()
        if (rthis.props["main?"]) return rthis;

        return rthis.play_scenario.main_play_scenario.get_reactive_object()
    }) as any as PlayScenario
    // </editor-fold>

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlayScenario.register_resource(PlayScenario)
PlayScenarioClient.ModelClass = PlayScenario
PlayScenarioScope.ModelClass = PlayScenario


declare global {
    interface Window {
        PlayScenario: typeof PlayScenario
    }
}
window.PlayScenario = PlayScenario
// </editor-fold>

