import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06cf94d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "confirmation_dialogue",
    ref: "modal",
    focus_confirm: true,
    show_cancel: _ctx.show_cancel,
    show_deny: _ctx.show_deny,
    cancel_text: _ctx.cancel_text,
    deny_text: _ctx.deny_text,
    confirm_text: _ctx.confirm_text,
    confirm_color_class: _ctx.confirm_color_class,
    deny_color_class: _ctx.deny_color_class,
    show_on_mounted: false,
    cancel_on_escape: true,
    confirm_action: _ctx.confirm_action,
    deny_action: _ctx.deny_action,
    dismissible: _ctx.dismissible,
    focus_last_active_on_unmount: true,
    onCancel: _ctx._cancel,
    onConfirm: _ctx._confirm,
    onDeny: _ctx._deny,
    onConfirmSuccess: _ctx._confirm_success,
    onConfirmError: _ctx._confirm_error,
    onConfirmDone: _ctx._confirm_success,
    onDenyDone: _ctx._deny_done
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["body", {center: _ctx.center}]),
        innerHTML: _ctx.html
      }, null, 10 /* CLASS, PROPS */, _hoisted_2)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show_cancel", "show_deny", "cancel_text", "deny_text", "confirm_text", "confirm_color_class", "deny_color_class", "confirm_action", "deny_action", "dismissible", "onCancel", "onConfirm", "onDeny", "onConfirmSuccess", "onConfirmError", "onConfirmDone", "onDenyDone"]))
}