<template>
  <Overflowable>
    <ul
        ref="error_info"
        class="error-info"
        :class="{center: center, top: side == 'top', right: side == 'right', bottom: side == 'bottom', left: side == 'left'}">
      <li v-for="error in errors"
          :key="error">
        {{ error }}
      </li>
    </ul>
  </Overflowable>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { nextTick } from "vue";
import Overflowable from "./Overflowable.vue";
import { PropType } from "vue";

export default defineComponent({
    components: { Overflowable },
    props: {
        errors: {
            type: Array,
            required: true
        },
        center: {
            type: Boolean,
            required: false,
            default: false
        },
        no_side: {
            type: String as PropType<"top" | "right" | "bottom" | "left">,
            require: false,
            default: null
        }
    },
    data() {
        return {
            window_height: 0,
            window_width: 0,
            side: "bottom"
        }
    },
    computed: {},
    mounted() {
        nextTick(() => {
            this.position_tooltip()
        })
    },
    beforeUpdate() {
        this.position_tooltip()
    },
    methods: {
        position_tooltip() {
            const $info = $(this.$refs.error_info as HTMLElement);
            if ($info.length > 0) {
                const rect = $info[0].getBoundingClientRect();
                const $relative = $info.parent();
                const rel_rect = $relative[0].getBoundingClientRect();
                this.window_height = document.body.clientHeight
                this.window_width = document.body.clientWidth
                const width = rect.width;
                const height = rect.height;
                const offset_bottom = this.window_height - rel_rect.bottom;
                const offset_right = this.window_width - rel_rect.right;
                if (offset_bottom > height && this.no_side != "bottom") {
                    this.side = "bottom"
                    // we can display at bottom
                } else if (rel_rect.top > height && this.no_side != "top") {
                    this.side = "top"
                    // we can display at top
                } else if (offset_right > width && this.no_side != "right") {
                    this.side = "right"
                    // we can display at right
                } else if (this.no_side != "left") {
                    this.side = "left"
                    // display at left
                }
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.error-info {
  pointer-events: all;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: var(--ternary-background-color);
  color: var(--font-color);
  border: 1px solid var(--button-red);
  padding: 5px 5px 5px 20px;
  border-radius: 5px;
  font-size: 14px;
  min-width: 120px;

  // testing, so far it works
  z-index: 1;

  &.top,
  &.bottom {
    left: 0;

    &.center {
      &:before {
        left: 50%;
      }

      &:after {
        left: 50%;
      }
    }

    &:not(.center) {
      &:before {
        left: 10px;
      }
    }

    &:not(.center) {
      &:after {
        left: 10px;
      }
    }
  }

  &.right,
  &.left {
    top: 0;

    &.center {
      &:before {
        top: 50%;
      }

      &:after {
        top: 50%;
      }
    }

    &:not(.center) {
      &:before {
        top: 7px;
      }
    }

    &:not(.center) {
      &:after {
        top: 7px;
      }
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 10px solid transparent;
  }

  &.bottom {
    margin-top: 0;
    top: calc(100% + 10px);

    &:before {
      bottom: 100%;
      border-bottom-color: var(--button-red);
    }

    &:after {
      bottom: calc(100% - 1px);
      border-bottom-color: var(--ternary-background-color);
    }
  }

  &.top {
    margin-bottom: 0;
    bottom: calc(100% + 10px);

    &:before {
      top: 100%;
      border-top-color: var(--button-red);
    }

    &:after {
      top: calc(100% - 1px);
      border-top-color: var(--ternary-background-color);
    }
  }

  &.right {
    margin-top: 0;
    left: calc(100% + 10px);

    &:before {
      right: 100%;
      border-right-color: var(--button-red);
    }

    &:after {
      right: calc(100% - 1px);
      border-right-color: var(--ternary-background-color);
    }
  }

  &.left {
    margin-top: 0;
    right: calc(100% + 10px);

    &:before {
      left: 100%;
      border-left-color: var(--button-red);
    }

    &:after {
      left: calc(100% - 1px);
      border-left-color: var(--ternary-background-color);
    }
  }
}
</style>
