globalThis.get_ruby_methods = () => {
    return `
    blank?
    clone
    displayed?
    dup
    hash
    include?
    inspect
    instance_of?
    instance_variable_defined
    instance_variable_get
    instance_variable_names
    instance_variable_set
    instance_variables
    import
    match
    match?
    methods
    kind_of?
    load
    nil?
    object_id
    present?
    private_methods
    protected_methods
    public_methods
    reload
    singleton_methods
    to_s
    to_i
    to_f  
    `;
}

globalThis.get_ruby_variables = () => {
    return `
    @app_package
    @b.
    @d.
    @i.
    @download_dir
    @files
    @images
    @phone
    @tmp_dir
    `;
}

globalThis.get_ruby_keywords = () => {
    return `
    BEGIN
    END
    alias
    and
    begin
    break
    case
    class
    def
    defined?
    do
    else
    elsif
    end
    ensure
    fail
    false
    for
    if
    in
    module
    next
    nil
    not
    or
    puts
    redo
    rescue
    retry
    return
    screenshot
    self
    sleep
    snapshot
    super
    then
    true
    undef
    unless
    until
    warning
    when
    while
    yield
    `;
}
globalThis.get_ruby_classes = () => {
    return `
    ArgumentError
    Array
    BasicObject
    Binding
    Class
    Dir
    Encoding
    EncodingError
    Enumerator
    Errno
    Exception
    File
    Float
    FloatDomainError
    FrozenError
    Hash
    Integer
    IndexError
    Interrupt
    IO
    KeyError
    LoadError
    NameError
    Module
    NoMemoryError
    NoMethodError
    NotImplementedError
    Numeric
    Object
    Random
    Range
    RangeError
    Regexp
    RegexpError
    RuntimeError
    ScriptError
    SecurityError
    Set
    SignalException
    StandardError
    String
    Struct
    Symbol
    SyntaxError
    SystemCallError
    SystemExit
    Thread    
    Time
    TypeError
    URI
    UncaughtThrowError
    ZeroDivisionError`;
}
