import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumScenarioSettingPhoneScope } from "../../../auto_generated/enums";
import { EnumPhoneType } from "../../../auto_generated/enums";
import { Inspector } from "../../../components/play/reports/play_scenario/inspector/inspector";
import { PhoneScope } from "../../scopes/scenario_setting/phone_scope";
import { PhoneClient } from "../../clients/scenario_setting/phone_client";
import { send_ws } from "../../../helpers/generic/send_ws";
import { on_dom_content_loaded } from "../../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { RecordOpts } from "../../base/vue_record";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export interface PhoneProps extends Props {
    id: number
    scenario_setting_id: number
    index: number
    scope: EnumScenarioSettingPhoneScope
    phone_project_id: number
    top_left_x: number
    top_left_y: number
    downscaled_height: number
    downscaled_width: number
    scale: number
    created_at?: Date
    updated_at?: Date
    locale: string
    type: EnumPhoneType
    phone_log_path: string
    driver_log_path: string
}
export type QuerifiedPhoneProps = QuerifyProps<PhoneProps>
export type PhoneCreateProps = Omit<PhoneProps, 'id'>
export type PhoneUpdateProps = Partial<PhoneProps>

export interface PhoneState extends State {
    inspector: Inspector
}
export interface PhoneComputed extends Computed {}
export interface PhoneStaticState extends StaticState {
    load_promises: Record<number | string, Promise<Phone>>
}

// </editor-fold>

const console = new Consoler("warn")
export class Phone extends VueRecord {
    ['constructor']: typeof Phone

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PhoneClient
    static ScopeClass = PhoneScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PhoneStaticState = reactive<PhoneStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Phone> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Phone>> = {}

    static field_validators: ModelValidatorOpts<PhoneProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_SETTING_PHONE
    static resource_id = Enum.Resource.Id.SCENARIO_SETTING_PHONE
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PhoneClient
    declare props: PhoneProps;
    declare state: PhoneState;
    declare computed: PhoneComputed;

    // </editor-fold>

    after_create() {
        super.after_create();

        this.state.inspector = Inspector.new(this)
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    pause_inspector() {
        send_ws(this.scenario_setting.play_scenario.state.backend_ws_channel, {
            action: Enum.Play.Action.INSPECTOR,
            extras: {
                udid: this.phone_project.phone.props.udid,
                action: "pause",
            }
        })
    }

    resume_inspector() {
        send_ws(this.scenario_setting.play_scenario.state.backend_ws_channel, {
            action: Enum.Play.Action.INSPECTOR,
            extras: {
                udid: this.phone_project.phone.props.udid,
                action: "resume",
            }
        })
    }

    refresh_inspector() {
        send_ws(this.scenario_setting.play_scenario.state.backend_ws_channel, {
                action: Enum.Play.Action.INSPECTOR,
                extras: {
                    udid: this.phone_project.phone.props.udid,
                    action: "capture"
                }
            }
        )
    }

    save_screenshot() {
        send_ws(this.scenario_setting.play_scenario.state.backend_ws_channel, {
                action: Enum.Play.Action.INSPECTOR,
                tab_id: TAB_ID,
                extras: {
                    udid: this.phone_project.phone.props.udid,
                    action: "save_screenshot",
                }
            }
        )
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
Phone.register_resource(Phone)
PhoneClient.ModelClass = Phone
PhoneScope.ModelClass = Phone

global_event_bus.$on("after_project_version_unload", () => {
    Phone.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            Phone.unsync()
            if (project_version_id != null) Phone.sync(`/sync/project_version/${project_version_id}/scenario_setting_phones`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})


declare global {
    interface Window {
        ScenarioSettingPhone: typeof Phone
    }
}
window.ScenarioSettingPhone = Phone
// </editor-fold>

