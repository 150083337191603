import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { ScenarioHistoryClient } from "../clients/scenario_history_client";
import { ScenarioHistoryScope } from "../scopes/scenario_history_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface ScenarioHistoryProps extends Props {
    id: number
    scenario_id: number
    scenario_nodes_data: any
    change_number: number
    reverted: boolean
    created_at: Date
    updated_at: Date
}
export type QuerifiedScenarioHistoryProps = QuerifyProps<ScenarioHistoryProps>
export type ScenarioHistoryCreateProps = Omit<ScenarioHistoryProps, 'id'>
export type ScenarioHistoryUpdateProps = Partial<ScenarioHistoryProps>

export interface ScenarioHistoryState extends State {}
export interface ScenarioHistoryComputed extends Computed {}
export interface ScenarioHistoryStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class ScenarioHistory extends VueRecord {
    ['constructor']: typeof ScenarioHistory

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScenarioHistoryClient
    static ScopeClass = ScenarioHistoryScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScenarioHistoryStaticState = reactive<ScenarioHistoryStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "scenario_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ScenarioHistory> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ScenarioHistory>> = {}

    static field_validators: ModelValidatorOpts<ScenarioHistoryProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_HISTORY
    static resource_id = Enum.Resource.Id.SCENARIO_HISTORY
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => get_css_var("--scenario-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScenarioHistoryClient
    declare props: ScenarioHistoryProps;
    declare state: ScenarioHistoryState;
    declare computed: ScenarioHistoryComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
ScenarioHistory.register_resource(ScenarioHistory)
ScenarioHistoryClient.ModelClass = ScenarioHistory
ScenarioHistoryScope.ModelClass = ScenarioHistory

global_event_bus.$on("after_project_version_unload", () => {
    ScenarioHistory.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            ScenarioHistory.unsync()
            if (project_version_id != null) ScenarioHistory.sync(`/sync/project_version/${project_version_id}/scenario_histories_v2`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        ScenarioHistory: typeof ScenarioHistory
    }
}
window.ScenarioHistory = ScenarioHistory
// </editor-fold>

