<template>
  <Modal
      ref="modal"
      confirm_text="Submit"
      :form_validator="form_validator"
      :confirm_action="confirm_action"
      deny_text="Abort job"
      :show_deny="true"
      :deny_action="abort_job"
      initial_width="500px"
  >
    <template #header>
      <div class="header">
        Background job requires action
      </div>
    </template>

    <template #body>
      <div class="row">
        <div class="col s12">
          <div
              class="jobberman-input-request-message"
              v-html="message"/>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <Input
              v-model="text_input"
              placeholder="Enter text here"
              :focus="true"
              :validator="form_validator.register('text_input', validator_opts)"
              @enter="confirm_action"
          />
        </div>
      </div>
    </template>

    <template #footer_right_box>
      <div style="margin-right: 15px">
        <span>Timeout in </span>
        <span v-timeout="timeout_at"/>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../Modal.vue";
import { PropType } from "vue";
import { Jobberman } from "../../../helpers/jobberman/jobberman";
import Input from "../Input.vue";
import { ValidatorOpts } from "../../../helpers/validator/validator";
import { FormValidator } from "../../../helpers/validator/form_validator";

export default defineComponent({
    components: { Input, Modal },
    // <editor-fold desc="PROPS">
    props: {
        jobberman: {
            type: Object as PropType<Jobberman>,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        timeout_at: {
            type: Date,
            required: true
        },
        validator_opts: {
            type: Object as PropType<ValidatorOpts>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            text_input: "",
            form_validator: new FormValidator()
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.jobberman.promise.finally(() => {
            (this.$refs.modal as typeof Modal).close()
        })
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        confirm_action() {
            return this.jobberman.respond(this.text_input)
        },
        abort_job() {
            return this.jobberman.abort_job()
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss">
.jobberman-input-request-message {
  display: block;
  width: 100%;
  height: 100%;

  strong {
    padding-inline: 0.2em;
    white-space: nowrap;
  }

  pre {
    margin-inline: 0.2em;
    margin-block: 0;
    display: inline-block;
    padding: 0.3em;
    background: var(--ternary-background-color);
    border-radius: 5px;
    user-select: none;
  }
}
</style>
