<template>
  <div v-if="section_toggler.shown_sections().length > 0"
       :id="`${section_toggler.id}_toggler`"
       class="section-toggler"
       :class="{vertical: section_toggler.is_vertical(), left: section_toggler.position() == 'left', right: section_toggler.position() == 'right' }">
    <div v-for="section in shown_start_sections"
         :key="section.id"
         class="section prevent-select"
         :class="section_class(section)"
         tabindex="0"
         @keydown="click_on_enter"
         @mousedown.prevent
         @click="section.toggle()">
      {{ section.label }}
    </div>

    <div class="flex-expander"/>

    <div v-for="section in shown_end_sections"
         :key="section.id"
         class="section prevent-select"
         :class="section_class(section)"
         tabindex="0"
         @keydown="click_on_enter"
         @mousedown.prevent
         @click="section.toggle()">
      {{ section.label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { SectionToggler } from "./section_toggler";
import { click_on_enter } from "../../../helpers/events/click_on_enter";
import { Section } from "./section";
import { UnwrapNestedRefs } from "vue";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        section_toggler: {
            type: Object as PropType<SectionToggler>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        shown_start_sections() {
            return this.section_toggler.start_sections().filter(s => s.is_shown())
        },
        shown_end_sections() {
            return this.section_toggler.end_sections().filter(s => s.is_shown())
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        click_on_enter,
        section_class(section: UnwrapNestedRefs<Section>) {
            return {
                vertical: this.section_toggler.is_vertical(),
                enabled: section.is_enabled(),
                left: this.section_toggler.position() == 'left',
                right: this.section_toggler.position() == 'right'
            }
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
$item_size: 25px; // make sure to also update in SectionTogglerContents.vue
$sections-toggler-border: 1px;
.section-toggler {
  display: flex;
  flex-direction: row;
  width: calc(100% - calc($sections-toggler-border * 2));
  flex-shrink: 0;
  height: $item_size;
  border-bottom: $sections-toggler-border solid var(--secondary-background-color);

  &.vertical {
    flex-direction: column;
    height: calc(100% - calc($sections-toggler-border * 2));
    width: $item_size;

    &.left {
      border-right: $sections-toggler-border solid var(--secondary-background-color);
    }

    &.right {
      border-left: $sections-toggler-border solid var(--secondary-background-color);
    }
  }

  .section {
    background-color: var(--primary-background-color);
    padding-inline: 8px;
    font-size: 0.8em;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: brightness(1.2);
    }

    &.enabled {
      background-color: var(--secondary-background-color);
    }

    &.vertical {
      &.left {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg); /* Safari */
        -moz-transform: rotate(180deg); /* Firefox */
        -ms-transform: rotate(180deg); /* IE */
        -o-transform: rotate(180deg); /* Opera */
      }

      &.right {
        writing-mode: vertical-lr;
      }
    }
  }
}
</style>
