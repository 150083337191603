<template>
  <div>
    <Modal
        id="add_browser_form"
        ref="modal"
        :show_cancel="true"
        confirm_text="Add"
        :focus_confirm="false"
        :cancel_on_escape="true"
        :focus_last_active_on_unmount="true"
        :form_validator="form_validator"
        :confirm_action="confirm"
        @cancel="cancel">
      <template #header>
        <div class="title">
          Add Browser
        </div>
      </template>

      <template #body="modal">
        <div class="row">
          <div class="col s6">
            <Select2
                id="add_browser_type"
                v-model="browser_type"
                label="Browser"
                dropdown_parent="#add_browser_form">
              <option :value="Enum.Browser.CHROME">Chrome</option>
              <option :value="Enum.Browser.FIREFOX">Firefox</option>
            </Select2>
          </div>
          <div class="col s6">
            <Select2
                id="add_browser_driver_version"
                v-model="driver_version"
                dropdown_parent="#add_browser_form"
                label="Driver version"
                :validator="form_validator.register('driver_version', { presence: true })">
              <option value="">Not selected</option>
              <template v-for="driver in drivers_for(browser_type)"
                        :key="driver.props.id">
                <option :value="driver.props.version">{{ driver.props.version }}</option>
              </template>
            </Select2>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <Input
                id="add_browser_download_url"
                v-model="download_url"
                label="Download url"
                placeholder="chrome*.deb or firefox*.rar.bz2"
                :validator="form_validator.register('download_url', { presence: true })"
                @enter="modal.confirm()"
            />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Select2 from "../../../testa/Select2.vue";
import { PropType } from "vue";
import { EnumBrowser } from "../../../../auto_generated/enums";
import { BrowserDriver } from "../../../../vue_record/models/browser_driver";
import Input from "../../../testa/Input.vue";
import { Browser } from "../../../../vue_record/models/browser";
import { BrowserDriverScope } from "../../../../vue_record/scopes/browser_driver_scope";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Input,
        Select2,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        browser_drivers: {
            type: Object as PropType<BrowserDriverScope>,
            required: true,
        },
        jobberman_id: {
            type: String,
            required: true,
        }
    },
    // </editor-fold>
    emits: ['close', 'cancel'],
    // <editor-fold desc="DATA">
    data() {
        return {
            form_validator: new FormValidator(),
            browser_type: Enum.Browser.CHROME,
            driver_version: null,
            download_url: "",
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Enum() {
            return Enum
        },
        geckodrivers() {
            return this.browser_drivers.geckodrivers.toArray()
        },
        chromedrivers() {
            return this.browser_drivers.chromedrivers.toArray()
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        drivers_for(browser_type: EnumBrowser): BrowserDriver[] {
            switch (browser_type) {
                case Enum.Browser.CHROME:
                    return this.chromedrivers
                case Enum.Browser.FIREFOX:
                    return this.geckodrivers;
                default:
                    return [];
            }
        },
        confirm() {
            return Browser.ClientClass.add_by_download_link(
                this.jobberman_id,
                this.browser_type,
                this.driver_version,
                this.download_url
            ).then(() => this.close())
        },
        close() {
            this.$emit("close");

            (this.$refs.modal as typeof Modal).close()
        },
        cancel() {
            this.$emit("cancel");

            (this.$refs.modal as typeof Modal).close()
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.title {
  width: 400px;
}
</style>
