import { ComponentInternalInstance } from "vue";

declare global {
    interface Element {
        __vueParentComponent: ComponentInternalInstance
    }
}
export function unmount_all_modals() {
    const modals_container = document.getElementById("modals")
    const modals: HTMLElement[] = [];
    for (let i = 0; i < modals_container.children.length; ++i) {
        modals.push(modals_container.children[i] as HTMLElement)
    }
    modals.forEach(modal => modal.__vueParentComponent.appContext.app.unmount());
}
