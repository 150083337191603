import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { SnippetFolder } from "../models/snippet_folder";
import { SnippetFolderCreateProps } from "../models/snippet_folder";
import { SnippetFolderChildren } from "../models/snippet_folder";
import { generate_resolved_promise } from "../../helpers/generate/generate_resolved_promise";
import { SnippetProps } from "../models/snippet";
import { SnippetFolderProps } from "../models/snippet_folder";
import { Snippet } from "../models/snippet";
import { SnippetFolderUpdateProps } from "../models/snippet_folder";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class SnippetFolderClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof SnippetFolder
    declare record: SnippetFolder

    /** When copy - pasting we are duplicating to different folder */
    static copy(project_version_id: number, destination_snippet_folder_id: number, snippet_folder_ids: number[], snippet_ids: number[] = []) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/snippet_folders/copy`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_version_id,
                    destination_snippet_folder_id,
                    snippet_ids,
                    snippet_folder_ids,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static create(snippet_folder: SnippetFolderCreateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: "/snippet_folders",
                type: "POST",
                data: {
                    snippet_folder,
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }

    children(reload = false): Promise<SnippetFolderChildren> {
        return SnippetFolder.ClientClass.children(this.record.props.project_version_id, this.key(), reload)
    }

    static children(project_version_id: number, snippet_folder_id: number, reload = false): Promise<SnippetFolderChildren> {
        const params_hash = hash({ project_version_id, snippet_folder_id })
        if (reload) SnippetFolder.state.children_promises[params_hash] = null
        if (project_version_id == null) {
            return generate_resolved_promise({
                snippets: [],
                snippet_folders: []
            })
        }

        if (SnippetFolder.state.children_promises[params_hash] == null) {
            type ChildrenResponse = {
                snippets: SnippetProps[],
                snippet_folders: SnippetFolderProps[],
            }

            const promise: Promise<SnippetFolderChildren> = new Promise<SnippetFolderChildren>((resolve, reject) => {
                $.ajax({
                    url: `/snippet_folders/children`,
                    type: "GET",
                    data: {
                        project_version_id,
                        snippet_folder_id,
                    },
                    success: (data: ChildrenResponse) => {
                        const promise_response: SnippetFolderChildren = {
                            snippets: [],
                            snippet_folders: []
                        }
                        data.snippets.forEach(snippet_prop => {
                            promise_response.snippets.push(Snippet.new(snippet_prop))
                        })
                        data.snippet_folders.forEach(snippet_folder_prop => {
                            promise_response.snippet_folders.push(SnippetFolder.new(snippet_folder_prop))
                        })
                        resolve(promise_response)
                    },
                    error: (error) => {
                        reject(error)
                    },
                    statusCode: ajax_status_codes,
                })
            })
            SnippetFolder.state.children_promises[params_hash] = promise
            promise.catch(() => {
                SnippetFolder.state.children_promises[params_hash] = null
            })
            return promise
        } else {
            return SnippetFolder.state.children_promises[params_hash]
        }
    }

    static move(project_version_id: number, destination_snippet_folder_id: number, snippet_folder_ids: number[], snippet_ids: number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/snippet_folders/move`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    destination_snippet_folder_id,
                    snippet_ids,
                    snippet_folder_ids,
                    project_version_id,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    update(snippet_folder: SnippetFolderUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            return $.ajax({
                url: `/snippet_folders/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    snippet_folder,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    /** Duplicate single item in current folder */
    duplicate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/snippet_folders/${this.key()}/duplicate`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static batch_path(snippet_ids: number | number[]) {
        let ids: number[];
        if (what_is_it(snippet_ids) == "Array") {
            ids = snippet_ids as number[]
        } else {
            ids = [snippet_ids as number]
        }

        type PathResponse = {
            [key: string]: {
                target: SnippetFolderProps,
                path: SnippetFolderProps[]
            }
        }

        type PromiseResponse = {
            [key: string]: SnippetFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            $.ajax({
                url: `/snippet_folders/batch/path`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    ids,
                    authenticity_token
                }),
                statusCode: ajax_status_codes,
                success: (data: PathResponse) => {
                    const promise_response: PromiseResponse = {}
                    Object.keys(data).forEach(snippet_folder_id => {
                        SnippetFolder.new(data[snippet_folder_id].target)
                        const snippet_folders_props = data[snippet_folder_id].path;
                        const array: SnippetFolder[] = []
                        snippet_folders_props.forEach(snippet_folder_props => {
                            array.push(SnippetFolder.new(snippet_folder_props))
                        })
                        promise_response[snippet_folder_id] = array
                    })
                    resolve(promise_response)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }
}
