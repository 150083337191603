<template>
  <div ref="canvas_holder"
       class="container">
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Chart from 'chart.js/auto';
import { markRaw } from "vue";
import zoomPlugin from 'chartjs-plugin-zoom';
import { PlaySnippet } from "../../../../vue_record/models/play/play_snippet";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import { Phone } from "../../../../vue_record/models/phone";
import { PropType } from "vue";
Chart.register(zoomPlugin);

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        play_scenario: {
            type: Object as PropType<PlayScenario>,
            required: true
        },
        metrics_shown: {
            type: Boolean,
            required: true
        }
    },
    // </editor-fold>
    emits: ['chart-count-changed'],
    // <editor-fold desc="DATA">
    data() {
        return {
            perf_charts: markRaw({}) as Record<string, any>
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        chart_count() {
            return this.performance_chart_configs.length
        },
        performance_chart_configs() {
            if (!this.play_scenario.props["main?"] ||
                !this.play_scenario.scenario_setting.props.mobile_module_active ||
                !this.metrics_shown // for better performance, do not update chart unless it is shown
            ) {
                return []
            }
            const play_snippets: PlaySnippet[] = []
            const load_snippets = (play_scenario: PlayScenario) => {
                play_scenario.play_snippets.toArray().forEach(play_snippet => play_snippets.push(play_snippet))
                play_scenario.play_scenario_parts.toArray().forEach(play_scenario => load_snippets(play_scenario))
            }

            load_snippets(this.play_scenario)

            const mpd = play_snippets.map(ps => ps.play_logs.toArray())
                                     .flat()
                                     .filter(pl => pl.props.mobile_performance_data != null)

            const messages = mpd.map(pl => pl.props.message)
            const perf_data = mpd.map(pl => pl.props.mobile_performance_data)

            // get distinct phone udids
            const distinct_phone_udids = perf_data.reduce((keys, item) => {
                const itemKeys = Object.keys(item);
                itemKeys.forEach(key => {
                    if (!keys.includes(key)) {
                        keys.push(key);
                    }
                });
                return keys;
            }, [] as string[]);

            const configs = [] as any[];
            distinct_phone_udids.forEach(udid => {
                // <editor-fold desc="memories">

                // const totalPrivateDirtyValues = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"totalPrivateDirty":"(\d+)"/)![1])/1024)
                const totalPrivateDirty = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"totalPrivateDirty":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const nativePrivateDirty = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"nativePrivateDirty":"(\d+)"/)![1])/1024)
                const nativePrivateDirty = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"nativePrivateDirty":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const dalvikPrivateDirty = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"dalvikPrivateDirty":"(\d+)"/)![1])/1024)
                const dalvikPrivateDirty = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"dalvikPrivateDirty":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const eglPrivateDirty = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"eglPrivateDirty":"(\d+)"/)![1])/1024)
                const eglPrivateDirty = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"eglPrivateDirty":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const glPrivateDirty = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"glPrivateDirty":"(\d+)"/)![1])/1024)
                const glPrivateDirty = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"glPrivateDirty":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const totalPss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"totalPss":"(\d+)"/)![1])/1024)
                const totalPss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"totalPss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const nativePss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"nativePss":"(\d+)"/)![1])/1024)
                const nativePss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"nativePss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const dalvikPss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"dalvikPss":"(\d+)"/)![1])/1024)
                const dalvikPss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"dalvikPss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const eglPss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"eglPss":"(\d+)"/)![1])/1024)
                const eglPss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"eglPss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const glPss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"glPss":"(\d+)"/)![1])/1024)
                const glPss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"glPss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const nativeHeapAllocatedSize = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"nativeHeapAllocatedSize":"(\d+)"/)![1])/1024)
                const nativeHeapAllocatedSize = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"nativeHeapAllocatedSize":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const nativeHeapSize = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"nativeHeapSize":"(\d+)"/)![1])/1024)
                const nativeHeapSize = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"nativeHeapSize":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const nativeRss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"nativeRss":"(\d+)"/)![1])/1024)
                const nativeRss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"nativeRss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const dalvikRss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"dalvikRss":"(\d+)"/)![1])/1024)
                const dalvikRss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"dalvikRss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // const totalRss = perf_data.map((item) => parseInt(item[udid].memory_info.match(/"totalRss":"(\d+)"/)![1])/1024)
                const totalRss = perf_data.map((item) => {
                    const memoryInfoMatch = item[udid]?.memory_info?.match(/"totalRss":"(\d+)"/);
                    return memoryInfoMatch ? parseInt(memoryInfoMatch[1]) / 1024 : 0;
                });
                // </editor-fold>
                const data = {
                    labels: messages,
                    datasets: [
                        {
                            label: 'totalPrivateDirty',
                            pointHitRadius: 20,
                            data: totalPrivateDirty,
                            fill: false,
                            borderColor: 'rgb(31, 119, 180)',
                            tension: 0.1
                        },
                        {
                            label: 'nativePrivateDirty',
                            pointHitRadius: 20,
                            data: nativePrivateDirty,
                            fill: false,
                            borderColor: 'rgb(255, 127, 14)',
                            tension: 0.1
                        },
                        {
                            label: 'dalvikPrivateDirty',
                            pointHitRadius: 20,
                            data: dalvikPrivateDirty,
                            fill: false,
                            borderColor: 'rgb(44, 160, 44)',
                            tension: 0.1
                        },
                        {
                            label: 'eglPrivateDirty',
                            pointHitRadius: 20,
                            data: eglPrivateDirty,
                            fill: false,
                            borderColor: 'rgb(214, 39, 40)',
                            tension: 0.1
                        },
                        {
                            label: 'glPrivateDirty',
                            pointHitRadius: 20,
                            data: glPrivateDirty,
                            fill: false,
                            borderColor: 'rgb(148, 103, 189)',
                            tension: 0.1
                        },
                        {
                            label: 'totalPss',
                            pointHitRadius: 20,
                            data: totalPss,
                            fill: false,
                            borderColor: 'rgb(140, 86, 75)',
                            tension: 0.1
                        },
                        {
                            label: 'nativePss',
                            pointHitRadius: 20,
                            data: nativePss,
                            fill: false,
                            borderColor: 'rgb(227, 119, 194)',
                            tension: 0.1
                        },
                        {
                            label: 'dalvikPss',
                            pointHitRadius: 20,
                            data: dalvikPss,
                            fill: false,
                            borderColor: 'rgb(127, 127, 127)',
                            tension: 0.1
                        },
                        {
                            label: 'eglPss',
                            pointHitRadius: 20,
                            data: eglPss,
                            fill: false,
                            borderColor: 'rgb(188, 189, 34)',
                            tension: 0.1
                        },
                        {
                            label: 'glPss',
                            pointHitRadius: 20,
                            data: glPss,
                            fill: false,
                            borderColor: 'rgb(23, 190, 207)',
                            tension: 0.1
                        },
                        {
                            label: 'nativeHeapAllocatedSize',
                            pointHitRadius: 20,
                            data: nativeHeapAllocatedSize,
                            fill: false,
                            borderColor: 'rgb(65, 74, 76)',
                            tension: 0.1
                        },
                        {
                            label: 'nativeHeapSize',
                            pointHitRadius: 20,
                            data: nativeHeapSize,
                            fill: false,
                            borderColor: 'rgb(23, 190, 207)',
                            tension: 0.1
                        },
                        {
                            label: 'nativeRss',
                            pointHitRadius: 20,
                            data: nativeRss,
                            fill: false,
                            borderColor: 'rgb(44, 160, 44)',
                            tension: 0.1
                        },
                        {
                            label: 'dalvikRss',
                            pointHitRadius: 20,
                            data: dalvikRss,
                            fill: false,
                            borderColor: 'rgb(227, 119, 194)',
                            tension: 0.1
                        },
                        {
                            label: 'totalRss',
                            pointHitRadius: 20,
                            data: totalRss,
                            fill: false,
                            borderColor: 'rgb(148, 103, 189)',
                            tension: 0.1
                        }
                    ]
                };
                const config = {
                    udid,
                    type: 'line',
                    data,
                    options: {
                        tooltips: {
                            mode: 'nearest',
                            intersect: false
                        },
                        animations: {
                            animation: false
                        },
                        scales: {
                            y: {
                                ticks: {
                                    callback: function(value: any, _index: any, _ticks: any) {
                                        return value + "Mb";
                                    }
                                }
                            },
                            x: {
                                ticks: {
                                    display: false // This will hide the X-axis labels
                                }
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            zoom: {
                                zoom: {
                                    wheel: {
                                        enabled: true,
                                    },
                                    pinch: {
                                        enabled: true
                                    },
                                    mode: 'x', // maybe add y as well

                                }
                            },
                            title: {
                                display: true,
                                text: Phone.find(udid)?.name()
                            },
                            legend: {
                                display: true,
                                position: "left"
                            },
                        }
                    }
                };
                configs.push(config)
            });
            return configs
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        performance_chart_configs: {
            deep: true,
            handler() {
                this.update_performance_charts();
            }
        },
        chart_count: {
            immediate: true,
            handler() {
                this.$emit("chart-count-changed", this.chart_count)
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.performance_chart_configs.forEach(performance_chart_config => {
            const chart = this.perf_charts[performance_chart_config.udid];
            if (chart == null) {
                this.create_chart(performance_chart_config)
            } else {
                this.update_chart(performance_chart_config)
            }
        });
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        create_chart(performance_chart_config: any) {
            const single_canvas: HTMLDivElement = document.createElement("div");
            single_canvas.style.height = "300px"
            single_canvas.style.width = "100%";
            const canvas: HTMLCanvasElement = document.createElement("canvas");
            // canvas.height = 65;
            // canvas.style.width = "100%";
            // const right_side = document.getElementById("right_side")
            // right_side.appendChild(canvas);

            const canvas_holder = this.$refs.canvas_holder as HTMLElement
            single_canvas.appendChild(canvas);
            canvas_holder.appendChild(single_canvas);

            this.perf_charts[performance_chart_config.udid] = markRaw(new Chart(canvas, performance_chart_config))
            // this.chart_map[performance_chart_config.udid] = new Chart(canvas, performance_chart_config)
        },
        update_chart(performance_chart_config: any) {
            const chart = this.perf_charts[performance_chart_config.udid]
            chart.data.labels = performance_chart_config.data.labels
            chart.data.datasets = performance_chart_config.data.datasets
            chart.update();
        },
        update_performance_charts() {
            this.performance_chart_configs.forEach(performance_chart_config => {
                const chart = this.perf_charts[performance_chart_config.udid]
                if (chart == null) {
                    this.create_chart(performance_chart_config)
                } else {
                    this.update_chart(performance_chart_config)
                }
            });
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
