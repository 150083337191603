import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { generate_uuid } from "../../../helpers/generate/generate_uuid";
import { Worker } from "../../models/non_db/worker"
import { WorkersInfo } from "../../models/non_db/worker";
import { WorkerScope } from "../../scopes/non_db/worker_scope";
import { SpinnerSetting } from "../../models/spinner_setting";
import { SpinnerSettingProps } from "../../models/spinner_setting";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class WorkerClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Worker
    declare record: Worker

    static index() {
        return new Promise<WorkerScope>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/workers`,
                type: 'GET',
                success: (workers_info: WorkersInfo) => {
                    SpinnerSetting.new(workers_info.spinner_setting)
                    this.ModelClass.state.all_workers_count = workers_info.worker_count.all_workers_count
                    this.ModelClass.state.standby_workers_count = workers_info.worker_count.standby_workers_count
                    const records: Worker[] = []
                    workers_info.all_workers.forEach(worker_props => {
                        records.push(Worker.new(worker_props))
                    })
                    Worker.get_scope().not({ private_sha: records.map(w => w.key()) }).each(w => w.unload())
                    resolve(Worker.to_scope(records))
                },
                error: (e) => reject(e),
                statusCode: ajax_status_codes,
            })
        })
    }

    static change_count(up: boolean) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/settings/superadmin_settings/workers/change_count',
                type: 'POST',
                data: JSON.stringify({
                    up,
                    authenticity_token,
                }),
                success: (spinner_setting: SpinnerSettingProps) => {
                    SpinnerSetting.new(spinner_setting)
                    resolve()
                },
                error: (e) => reject(e),
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            });
        })
    }

    static delete(ids: string | string[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/workers/destroy`,
                type: "DELETE",
                data: {
                    private_shas: ids,
                    authenticity_token,
                },
                statusCode: ajax_status_codes,
                success: () => resolve(),
                error: (e) => reject(e)
            });
        })
    }

    load_logs() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/workers/${this.key()}/logs`,
                type: 'GET',
                success: (logs: string[]) => {
                    this.record.state.logs = logs.map(log => {
                        return {
                            log,
                            key: generate_uuid(),
                        }
                    })
                    resolve(null)
                },
                error: (e) => reject(e),
                statusCode: ajax_status_codes,
            })
        })
    }
}
