import { create_vue_app } from "../../helpers/vue/create_vue_app";
import Login from "../../components/layout/Login.vue";

window.addEventListener("load", () => {
    if (current.user == null) {
        create_vue_app(Login, {})
    } else {
        global_event_bus.$emit("after_project_version_load", current.project_version?.key())
        // if user is signed in, then backend should automatically provide frontend with all project version data
        // load_project_version_resources(read_project_id_params_from_url(), read_project_version_id_params_from_url())
    }
})
