import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { RubocopOffense } from "../../helpers/code_lint/offense";
import { SyntaxError } from "../../helpers/codemirror/code_lint/syntax_error";
import { TabTargetOpts } from "../../components/testa/editor/tab";
import CodeMirror from "codemirror";
import { Timeout } from "../../helpers/generic/create_timeout";
import { Chunk } from "../../helpers/codemirror/merge";
import { SnippetClient } from "../clients/snippet_client";
import { SnippetScope } from "../scopes/snippet_scope";
import { watch } from "vue";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { Editor } from "../../components/testa/editor/editor";
import { copy_text_to_clipboard } from "../../helpers/generic/copy_to_clipboard";
import { update_url_parameters } from "../../helpers/generic/update_url_parameter";
import { nextTick } from "vue";
import { merge_snippets } from "../../helpers/codemirror/merge";
import { SnippetTab } from "../../components/testa/editor/tabs/snippet_tab";
import JumpToModal from "../../components/testa/editor/editors/codemirror/JumpToModal.vue";
import { create_vue_app } from "../../helpers/vue/create_vue_app";
import SnippetHistory from "../../components/testa/editor/editors/snippet/SnippetHistory.vue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { UiSync } from "../../helpers/ui_sync/ui_sync";
import UiShowInSidebarData = UiSync.UiShowInSidebarData;
import UiOpenData = UiSync.UiOpenData;
import { SnippetFolder } from "./snippet_folder";
import HotkeysCallback = TestaTree.HotkeysCallback;
import { SnippetFolderScope } from "../scopes/snippet_folder_scope";
import { computed } from "../../helpers/vue/computed";
import { get_selected_or_line_code } from "../../helpers/codemirror/helpers/get_selected_or_line_code";
import { Play } from "./play/play";
import { build_offense_contextmenu_items } from "../../helpers/codemirror/code_lint/context_menu";
import { event_target_coords } from "../../helpers/events/event_target_coords";
import { ComputedRole } from "../base/vue_record";
import { RecordOpts } from "../base/vue_record";
import { cm_set_value } from "../../helpers/codemirror/cm_set_value";
import { init_offense_contextmenu } from "../../helpers/codemirror/code_lint/context_menu";
import { element_coords } from "../../helpers/dom/element_coords";
import { resolve_tab_target } from "../../components/testa/editor/tab";
import { unmount_all_modals } from "../../helpers/vue/unmount_all_modals";
import { jump_to_line } from "../../helpers/codemirror/helpers/jump_to_line";
import { Coords } from "../../types/globals";
import VariableUsages from "../../components/testa/editor/editors/snippet/VariableUsages.vue";
import { ProjectVersion } from "./project_version";
import SnippetProjectCodeSearch from "../../components/testa/editor/editors/snippet/SnippetProjectCodeSearch.vue";
import { UsagesTab } from "../../components/testa/editor/tabs/usages_tab";

// <editor-fold desc="TYPES">
export interface SnippetProps extends Props {
    id: number
    user_id: number
    name: string
    code: string
    type: string
    project_version_id: number
    breakpoints: number[]
    history: string
    created_at: Date
    updated_at: Date
    tab_id: string
    snippet_folder_id: number
    archived: boolean
    git_id: string
    version_variables: boolean
    save_id: number

    color: string
    offenses: RubocopOffense[]
    syntax_error: SyntaxError
}

export type QuerifiedSnippetProps = QuerifyProps<SnippetProps>
export type SnippetCreateProps = Omit<SnippetProps, 'id'>
export type SnippetUpdateProps = Partial<SnippetProps>

export interface SnippetState extends State {
    codemirrors: any[]
    autosave: Timeout
    has_conflicts: boolean
    local_changes: CodeMirror.EditorChange[]
    conflict_chunks: Chunk[]
    conflict_remote_code: string
    other_cursors: Record<string, SnippetCursorData>
    code_before_conflict: string,
    history_before_conflict: any

    /** not committed or current code */
    code_not_committed: string
    history_not_committed: any
}

export interface SnippetComputed extends ComputedRole {
}

export interface SnippetStaticState extends StaticState {
    load_promises: Record<number | string, Promise<Snippet>>
}

export interface SnippetOpenOpts extends TabTargetOpts {
    select_range?: { from: CodeMirror.Position, to: CodeMirror.Position }
    jump_to?: CodeMirror.Position
    focus?: boolean
    close_all_modals?: boolean
}

export interface SnippetCursorData {
    user: {
        id: number
        email: string
        username: string
        first_name: string
        last_name: string
    }
    tab_id: string
    pos: CodeMirror.Position
    action: "update" | "remove"
}

export type SnippetEditorActionItem =
    "copy"
    | "cut"
    | "paste"
    | "history"
    | "search_project"
    | "quick_fix"
    | "usage"
    | "undo"
    | "redo"
    | "fullscreen"
    | "run"
    | "format"
    | "show"
    | "save"
    | "jump"

export type SnippetEditorFeature =
    "doc_link"
    | "hints"
    | "align_chained_methods"
    | "offenses"
    | "syntax_errors"
    | "sync_cursor"
    | "usage"
    | "contextmenu"
    | "debounced_save"
    | "keymap"
    | "auto_add_end"


export var code_search_modal_id = "code_search_modal"
// </editor-fold>

const console = new Consoler("warn")

export class Snippet extends VueRecord {
    ['constructor']: typeof Snippet

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = SnippetClient
    static ScopeClass = SnippetScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: SnippetStaticState = reactive<SnippetStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "snippet_folder_id", "version_variables"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Snippet> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Snippet>> = {}

    static field_validators: ModelValidatorOpts<SnippetProps> = {}

    static resource_name = Enum.Resource.Label.SNIPPET
    static resource_id = Enum.Resource.Id.SNIPPET
    static icon_class = "fa-solid fa-pencil"
    static color = () => get_css_var("--snippet-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: SnippetClient
    declare props: SnippetProps;
    declare state: SnippetState;
    declare computed: SnippetComputed;

    // </editor-fold>

    constructor(props: Props, opts: RecordOpts) {
        super(props, opts);
        this.state.codemirrors = [];
        this.state.autosave = null
        this.state.has_conflicts = false
        this.state.local_changes = [];
        this.state.conflict_chunks = [];
        this.state.other_cursors = {};
        this.state.code_not_committed = this.props.code;
        if (this.state.code_not_committed == null) this.state.code_not_committed = ""
        this.state.history_not_committed = this.props.history;

        watch(() => this.state.conflict_chunks, () => {
            if (this.state.conflict_chunks.length > 0) {
                this.get_tabs().forEach(t => t.set_error_indicator());
            } else {
                this.get_tabs().forEach(t => {
                    if (t.state.active_indicator == "error") t.clear_indicator()
                });
            }
        })
    }

    after_create() {
        super.after_create();

        this.init_computed_role(() => this.project_version?.props?.project_id)
    }

    after_sync_add() {
        super.after_sync_add();

        if (this.props.tab_id == TAB_ID) {
            nextTick(() => {
                const tree = TestaTree.Tree.get_project_tree()
                if (tree?.is_visible()) {
                    const node = tree.get_node_by_key(this.tree_key())
                    if (node?.is_visible()) {
                        node?.edit_start()
                    }
                }
            })
        }
    }

    after_update(new_props: SnippetProps, old_props: SnippetProps, changes: (keyof SnippetProps)[]) {
        super.after_update(new_props, old_props, changes);

        // <editor-fold desc="CODE">
        if (changes.includes("code")) {
            if (this.state.code_not_committed == null) {
                this.state.code_not_committed = this.props.code
                this.state.history_not_committed = this.props.history
            } else if (!this.state.has_conflicts &&
                TAB_ID.toString() != this.props.tab_id) {
                if (this.state.codemirrors.length > 0) {
                    const cm = this.state.codemirrors[0]

                    this.state.codemirrors.forEach(cm => {
                        cm.getAllMarks().forEach((m: any) => m.clear());
                    })

                    // we have changes in our code, and  someone else made changes, merge them
                    const has_conflicts = merge_snippets(cm, this, this.props.code.slice());

                    if (!has_conflicts && this.state.local_changes.length > 0) {
                        this._save_codemirror_code(this._extract_codemirror_props(cm));
                    } else {
                        this.state.code_before_conflict = old_props.code
                        this.state.history_before_conflict = old_props.history
                    }
                } else {
                    this.state.code_not_committed = this.props.code
                    this.state.history_not_committed = this.props.history
                }
            }
        }

        // </editor-fold>
    }

    before_unload() {
        super.before_unload();

        this.get_tabs().forEach(tab => tab.close())
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return Snippet.show_in_sidebar(this.props.id, this.props.project_version_id, tree);
    }

    static async show_in_sidebar(snippet_ids: number | number[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: number[];
        if (what_is_it(snippet_ids) == "Array") {
            ids = snippet_ids as number[]
        } else {
            ids = [snippet_ids as number]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(snippet_ids).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).each(snippet_id_string => {
                    const snippet_id = parseInt(snippet_id_string)
                    const snippet_folders = promise_response[snippet_id]
                    const keys = [
                        SnippetFolder.tree_key(project_version_id),
                        ...snippet_folders.map(sf => sf.tree_key()),
                        Snippet.find(snippet_id as number).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: Snippet.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="TREE">

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, HotkeysCallback<SnippetScope, SnippetFolderScope, VueRecord>> = {}
            if (current_role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    Snippet.ClientClass.create({
                        snippet_folder_id: this.snippet_folder?.key(),
                        project_version_id: this.props.project_version_id,
                        type: this.project_version.project_version_setting.props.default_language
                    })
                }

                keys["ctrl++"] = () => {
                    SnippetFolder.ClientClass.create({
                        snippet_folder_id: this.snippet_folder?.key(),
                        project_version_id: this.props.project_version_id,
                    })
                }

                keys.insert = () => {
                    const scenario_builder_tab = Editor.get_scenario_builder_tab();
                    if (scenario_builder_tab != null && scenario_builder_tab.state.scenario_builder != null) {
                        const scenario_builder = scenario_builder_tab.state.scenario_builder
                        scenario_builder.import_new_node(this, null, null)
                    }
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const snippet_folder_ids = nodes.map(n => n.record)
                                                .filter(r => r instanceof SnippetFolder)
                                                .map(r => r.key())
                const snippet_ids = nodes.map(n => n.record)
                                         .filter(r => r instanceof Snippet)
                                         .map(r => r.key())

                if (type == "copy") {
                    SnippetFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.snippet_folder?.key(),
                        snippet_folder_ids,
                        snippet_ids
                    )
                } else if (type == "cut") {
                    SnippetFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.snippet_folder?.key(),
                        snippet_folder_ids,
                        snippet_ids
                    )
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXTMENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<SnippetScope, SnippetFolderScope, Snippet>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: SnippetFolder._tree_new_snippet_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.open = {
                    name: "Open",
                    icon: Snippet.icon_class,
                    color: get_css_var("--button-white"),
                    key: `enter`,
                    callback: () => {
                        this.open();
                    },
                }

                items.find_usages = {
                    name: "Find usages",
                    icon: "fa-solid fa-people-carry-box",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        this.find_usages();
                    },
                }

                items.history = {
                    name: "History",
                    icon: "fas fa-history",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        this.show_history();
                    }
                }

                const scenario_builder_tab = Editor.get_scenario_builder_tab();
                if (scenario_builder_tab != null && scenario_builder_tab.state.scenario_builder != null) {
                    items.insert = {
                        name: "Insert to builder",
                        icon: "fa-solid fa-circle-plus",
                        color: get_css_var("--button-green"),
                        key: "insert",
                        callback: () => {
                            const scenario_builder = scenario_builder_tab.state.scenario_builder
                            scenario_builder.import_new_node(this, null, null)
                        }
                    }
                }

                const more_options: ContextMenu.Items = {}
                more_options.export = TestaTree.Tree.contextmenu_export_item(node)
                more_options.import = TestaTree.Tree.contextmenu_import_item(node)

                more_options.copy_link = {
                    name: "Copy link",
                    icon: "fa-solid fa-link",
                    color: get_css_var("--button-green"),
                    callback: () => {
                        const params = new URLSearchParams()
                        params.set("project_version_id", this.props.project_version_id.toString())
                        params.set("snippet_id", this.key().toString())
                        copy_text_to_clipboard(update_url_parameters(window.location.href, params))
                    }
                }

                return {
                    ...items,
                    ...default_items,
                    more_options: {
                        name: "More",
                        icon: "fa-solid fa-ellipsis",
                        items: more_options
                    }
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<SnippetScope, SnippetFolderScope, Snippet> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.SNIPPET,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    return this.open()
                }
            },
            icon: computed(() => {
                return {
                    color: get_css_var("--snippet-color"),
                }
            }),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: true,
                is_drop_area: false,
            },
            clipboard: this._testa_tree_clipboard(),
            contextmenu: this._tree_contextmenu(),
        }
    }

    // </editor-fold>

    // <editor-fold desc="EDITOR">
    _editor_contextmenu(cm: CodeMirror.Editor, e: MouseEvent, exclude_items: SnippetEditorActionItem[] = []) {
        const doc = cm.getDoc();
        const cursor = doc.getCursor();
        const is_selected = doc.getSelection() != "";

        const code = get_selected_or_line_code(cm)

        const token_type = cm.getTokenTypeAt(cursor);
        const token = cm.getTokenAt(cursor);

        const line = cursor.line
        const items: ContextMenu.Items = {}
        const history_size = doc.historySize();

        if (code.trim() != "") {
            if (!exclude_items.includes("run")) {
                items.run = {
                    name: "Run",
                    icon: "fas fa-terminal",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-r`,
                    callback: () => {
                        Play.execute_code(code)
                        cm.focus()
                    }
                }
            }
        }

        if (!exclude_items.includes("search_project")) {
            items.search_project = {
                name: "Search Project",
                icon: "fas fa-search",
                color: get_css_var("--button-white"),
                key: `${ctrl_or_meta}-shift-f`,
                callback: () => {
                    Snippet.show_project_code_search(this.project_version, code.trimStart())
                }
            }
        }

        if (!exclude_items.includes("jump")) {
            items.jump = {
                name: "Jump",
                icon: "fa-solid fa-code-pull-request fa-flip-vertical",
                color: get_css_var("--button-white"),
                key: `${ctrl_or_meta}-g`,
                callback: () => {
                    this.show_jump_to(cm)
                }
            }
        }

        if (!exclude_items.includes("quick_fix")) {
            const lineInfo = cm.lineInfo(cm.getCursor().line)
            const offense_ele = lineInfo?.gutterMarkers?.offenses;
            if (offense_ele) {
                items.search = {
                    name: "Quick Fix",
                    icon: "fa-solid fa-hammer",
                    color: get_css_var("--button-green"),
                    key: `alt-enter`,
                    items: build_offense_contextmenu_items(this.props.offenses, cm, this.key(), true)
                }
            }
        }

        if (token_type == "variable-2" || token_type == "variable") {
            if (!exclude_items.includes("usage")) {
                items.usage = {
                    name: "Usage",
                    icon: "fas fa-code",
                    color: get_css_var("--button-blue"),
                    // key: `${ctrl_or_meta}-click`,
                    key: `${ctrl_or_meta}-alt-w`,
                    callback: () => {
                        this.show_variable_usages(event_target_coords(e, "center", "end"), token.string, line)
                    }
                }
            }

            // if (current.role != Enum.User.Role.VIEWER) {
            //     items.rename = {
            //         name: "Rename",
            //         icon: "fas fa-pen",
            //         color: get_css_var("--button-blue"),
            //         key: `${ctrl_or_meta}-e`,
            //         callback: () => {
            //             window.show_rename_modal(e, token, this.snippet.props.id)
            //         }
            //     }
            // }
        }

        if (!exclude_items.includes("history")) {
            items.history = {
                name: "History",
                icon: "fas fa-history",
                color: get_css_var("--button-green"),
                callback: () => {
                    this.show_history();
                }
            }
        }

        if (code.trim() != "" && !this.computed.role_is_viewer) {
            if (!exclude_items.includes("cut")) {
                items.cut = {
                    name: "Cut",
                    icon: "fas fa-cut",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-x`,
                    callback: () => {
                        if (is_selected) {
                            cm.replaceSelection("", null, "cut")
                        } else {
                            cm.replaceRange("", { line: cursor.line, ch: 0 }, { line: cursor.line, ch: 99999 }, "cut")
                        }
                        cm.focus();
                    }
                }
            }

            if (!exclude_items.includes("copy")) {
                items.copy = {
                    name: "Copy",
                    icon: "fas fa-copy",
                    color: get_css_var("--button-yellow"),
                    key: `${ctrl_or_meta}-c`,
                    callback: () => {
                        copy_text_to_clipboard(code, false);
                        cm.focus();
                    }
                }
            }
        }

        if (!this.computed.role_is_viewer) {
            if (!exclude_items.includes("paste")) {
                items.paste = {
                    name: "Paste",
                    icon: "fas fa-paste",
                    color: get_css_var("--button-blue"),
                    key: `${ctrl_or_meta}-v`,
                    callback: () => {
                        if (window.isSecureContext) {
                            navigator.clipboard.readText()
                                     .then(text => {
                                         cm.replaceRange(text, doc.getCursor(true), doc.getCursor(false))
                                     })
                                     .catch(() => {
                                         toastr.error('Failed to paste');
                                     });
                        } else {
                            toastr.error("Cannot paste without https")
                        }
                        cm.focus();
                    }
                }
            }

            if (!exclude_items.includes("format")) {
                items.format = {
                    name: "Format",
                    icon: "fas fa-align-justify",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-${alt_or_option}-l`,
                    callback: () => {
                        this.client.format_code(this.state.code_not_committed).then(code => cm_set_value(cm, code, "format"))
                    }
                }
            }
        }

        if (!exclude_items.includes("show")) {
            items.show = {
                name: "Show",
                icon: "fas fa-bullseye",
                color: get_css_var("--snippet-color"),
                callback: () => {
                    this.show_in_sidebar();
                }
            }
        }

        if (!this.computed.role_is_viewer) {
            if (!exclude_items.includes("undo")) {
                if (history_size.undo > 0) {
                    items.undo = {
                        name: "Undo",
                        icon: "fas fa-undo",
                        color: get_css_var("--button-white"),
                        key: `${ctrl_or_meta}-z`,
                        callback: () => {
                            doc.undo();
                            cm.focus();
                        }
                    }
                }
            }

            if (!exclude_items.includes("redo")) {
                if (history_size.redo > 0) {
                    items.redo = {
                        name: "Redo",
                        icon: "fas fa-redo",
                        color: get_css_var("--button-white"),
                        key: `${ctrl_or_meta}-y`,
                        callback: () => {
                            doc.redo();
                            cm.focus();
                        }
                    }
                }
            }

            if (!exclude_items.includes("save")) {
                items.save = {
                    name: "Save",
                    icon: "far fa-save",
                    color: get_css_var("--button-green"),
                    key: `${ctrl_or_meta}-s`,
                    callback: () => {
                        this._save_codemirror_code(this._extract_codemirror_props(cm))
                    }
                }
            }

            if (!exclude_items.includes("fullscreen")) {
                items.fullscreen = {
                    name: "Fullscreen",
                    icon: "fa-solid fa-expand",
                    color: get_css_var("--button-white"),
                    key: "f11",
                    callback: () => {
                        cm.setOption("fullScreen", !cm.getOption("fullScreen"))
                    }
                }
            }
        }
        return items
    }

    _editor_keymap(cm: CodeMirror.Editor, exclude_items: SnippetEditorActionItem[] = []) {
        let Ctrl_or_Cmd = "Ctrl"
        if (is_pc_mac) Ctrl_or_Cmd = "Cmd"
        const key_map: Record<string, (cm: CodeMirror.Editor) => void> = {}
        if (!exclude_items.includes("fullscreen")) {
            key_map.F11 = () => {
                cm.setOption("fullScreen", !cm.getOption("fullScreen"))
            }
        }
        if (!exclude_items.includes("save")) {
            key_map[`${Ctrl_or_Cmd}-S`] = () => {
                if (!this.computed.role_is_viewer) this._save_codemirror_code(this._extract_codemirror_props(cm))
            }
        }
        if (!exclude_items.includes("quick_fix")) {
            key_map["Alt-Enter"] = () => {
                const offense_ele = cm.lineInfo(cm.getCursor().line).gutterMarkers?.offenses;
                if (offense_ele) {
                    const $marker = $(offense_ele)
                    const coords = cm.cursorCoords(true)
                    $.contextMenu("destroy", ".offense-gutter:visible");
                    const offs = $marker.data("offenses")
                    init_offense_contextmenu(coords.left, coords.top, offs, cm, this.props.id)
                    $marker.trigger("contextmenu");
                }
            }
        }

        if (!exclude_items.includes("format")) {
            key_map[`${Ctrl_or_Cmd}-Alt-L`] = () => {
                this.client.format_code(this.state.code_not_committed).then(code => cm_set_value(cm, code, "format"))
            }
        }

        if (!exclude_items.includes("usage")) {
            key_map[`${Ctrl_or_Cmd}-Alt-W`] = (cm) => {
                const doc = cm.getDoc();
                const cursor = doc.getCursor();
                const token_type = cm.getTokenTypeAt(cursor);
                const token = cm.getTokenAt(cursor);
                const coords = cm.cursorCoords(cursor, "page")
                const line_height = parseInt($(cm.display.wrapper).css("line-height"))
                const target_element = document.elementFromPoint(coords.left, coords.top + line_height / 2) as HTMLElement
                if (token_type == "variable-2" || token_type == "variable") {
                    this.show_variable_usages(element_coords(target_element, "center", "end"), token.string, cursor.line)
                }
            }
        }

        if (!exclude_items.includes("jump")) {
            key_map[`${Ctrl_or_Cmd}-G`] = (cm) => {
                this.show_jump_to(cm)
            }
        }

        if (!exclude_items.includes("run")) {
            key_map[`${Ctrl_or_Cmd}-R`] = () => {
                Play.execute_code(get_selected_or_line_code(cm))
            }
        }

        key_map[`Shift-${Ctrl_or_Cmd}-F`] = () => {
            // prevent default replace action
        }
        return key_map
    }

    // </editor-fold>

    // <editor-fold desc="ACTIONS">
    open_in_main(opts: SnippetOpenOpts = {}) {
        const web = ui_sync.web_for_main(this.props.project_version_id)
        ui_sync.send_ui_open_task(web, {
            id: this.key(),
            resource_id: Snippet.resource_id,
            open_opts: opts,
        })
    }

    open(opts: SnippetOpenOpts = {}) {
        const tab_target = resolve_tab_target(opts)
        if (opts.close_all_modals) unmount_all_modals();

        const tab = this.create_tab(false)
        tab_target.add_tab(tab)

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => {
                resolve(null)
                const cm = tab.state.cm

                if (opts.select_range) {
                    cm.setSelection(opts.select_range.from, opts.select_range.to)
                }

                if (opts.jump_to) {
                    jump_to_line(cm, opts.jump_to.line, opts.jump_to.ch)
                }

                if (opts.focus) {
                    cm.focus();
                }
            })
        })
    }

    find_usages(opts: TabTargetOpts = {}) {
        const tab_target = resolve_tab_target(opts)

        const tab = UsagesTab.new({
            id: `usages_tab_snippet_${this.props.id}`,
            snippet_id: this.props.id,
            type: UsagesTab.type,
        })
        tab_target.add_tab(tab)

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => resolve(null))
        })
    }

    show_variable_usages(position: Coords, variable: string, line: number) {
        create_vue_app(VariableUsages, {
            project_version: this.project_version,
            position,
            variable,
            except: { snippet: this, line }
        })
    }

    show_history() {
        create_vue_app(SnippetHistory, {
            snippet: this
        })
    }

    show_jump_to(cm: CodeMirror.Editor) {
        create_vue_app(JumpToModal, {
            cm
        })
    }

    static show_project_code_search(project_version: ProjectVersion, query: string) {
        if (document.getElementById(code_search_modal_id) != null) return;
        create_vue_app(SnippetProjectCodeSearch, { query, project_version })
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    create_tab(allow_duplicate: boolean) {
        return SnippetTab.new({
            id: `snippet_tab_${this.props.id}`,
            snippet_id: this.props.id,
            type: SnippetTab.type,
            allow_duplicate
        })
    }

    path(include_name = false) {
        const names: string[] = []
        let current_folder = this.snippet_folder
        while (current_folder != null) {
            names.push(current_folder.name())
            current_folder = current_folder.parent_folder
        }
        if (include_name) names.push(this.name())
        return `/${names.join("/")}`
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _last_save_id() {
        return this.props.updated_at.getTime();
    }

    _extract_codemirror_props(cm: CodeMirror.Editor) {
        const snippet_props: SnippetUpdateProps = {
            type: this.project_version.project_version_setting.props.default_language,
            tab_id: TAB_ID.toString(),
            code: cm.getValue(),
        }
        try {
            snippet_props.history = JSON.stringify(cm.getDoc().getHistory());
        } catch (e) {
            console.error(e);
            snippet_props.history = null;
        }
        return snippet_props
    }

    _save_codemirror_code(snippet_props: SnippetUpdateProps, force = false, is_retry = false) {
        this.state.autosave?.clear()
        this.state.autosave = null

        if (this.state.has_conflicts && !force) return;

        const promise = this.client.update(snippet_props)
        promise.then(() => {
                       const tabs = this.get_tabs();
                       tabs.forEach(t => t.clear_indicator())
                       tabs.forEach(t => t.set_saved_indicator());
                       this.state.local_changes = []

                       this.state.codemirrors.forEach(cm => {
                           for (let j = 0; j <= cm.lastLine(); ++j) {
                               cm.removeLineClass(j, "gutter", "CodeMirror-linechange")
                           }
                       })
                       // tabs.forEach(t => t.getAction("snippet_conflict_" + this.props.id).hide())
                   })
                   .catch((data: JQuery.jqXHR) => {
                       if (data.status == 400 && data.responseJSON.hasOwnProperty("snippet_props")) {
                           this._update_model(data.responseJSON.snippet_props)
                           if (!is_retry) {
                               setTimeout(() => {
                                   if (!this.state.has_conflicts) {
                                       snippet_props.code = this.state.code_not_committed
                                       snippet_props.history = this.state.history_not_committed
                                       this._save_codemirror_code(snippet_props, false, true)
                                   }
                               }, 100)
                           } else {
                               this.get_tabs().forEach(t => t.set_error_indicator());
                           }
                       } else {
                           this.get_tabs().forEach(t => t.set_error_indicator());
                       }
                   })
        return promise
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
Snippet.register_resource(Snippet)
SnippetClient.ModelClass = Snippet
SnippetScope.ModelClass = Snippet

global_event_bus.$on("after_project_version_unload", () => {
    Snippet.get_scope().where({ version_variables: false }).unload()

    // const snippets = Snippet.get_scope().where({ version_variables: false }).toArray()
    // _.defer(() => snippets.forEach(snippet => snippet._unload()))
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            Snippet.unsync()
            if (project_version_id != null) Snippet.sync(`/sync/project_version/${project_version_id}/snippets`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

if (web.is_main) {
    ui_sync.register_ui_task("open", Enum.Resource.Id.SNIPPET, (_sender: string, data: UiOpenData) => {
        Snippet.ClientClass.load(data.id).then(snippet => snippet.open(data.open_opts))
    })
    ui_sync.register_ui_task("show_in_sidebar", Snippet.resource_id, (sender: string, data: UiShowInSidebarData) => {
        Snippet.show_in_sidebar(data.id as number, current.project_version.key())
    })
}

declare global {
    interface Window {
        Snippet: typeof Snippet
    }
}
window.Snippet = Snippet
// </editor-fold>

