import { ScopedIdsMap } from "../../../vue_record/base/utils/to_scoped_map";

export function export_resources(project_version_id: number, scope: ScopedIdsMap) {
    const params = {
        snippet_ids: scope.snippet_ids,
        snippet_folder_ids: scope.snippet_folder_ids,
        scenario_ids: scope.scenario_ids,
        scenario_folder_ids: scope.scenario_folder_ids,
        group_ids: scope.group_ids,
        group_folder_ids: scope.group_folder_ids,
        project_version_id
    }
    $.fileDownload(`/core/export_resources?params=${JSON.stringify(params)}`)
    // $("#export_resources_link").attr("href", '/testa/export?params=' + JSON.stringify(params));
    // $("#export_resources_link")[0].click();
}
