import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { GroupsScenario } from "../models/groups_scenario";
import { GroupsScenarioUpdateProps } from "../models/groups_scenario";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class GroupsScenarioClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof GroupsScenario
    declare record: GroupsScenario

    static batch_update(groups_scenarios: GroupsScenarioUpdateProps[]) {
        const old_value_map: Record<number, number> = {}
        GroupsScenario.where({ id: groups_scenarios.map(gs => gs.id) })
                      .each(groups_scenario => {
                          old_value_map[groups_scenario.key()] = groups_scenario.props.real_order
                          groups_scenario.props.real_order = groups_scenarios.find(gs => gs.id == groups_scenario.key()).real_order
                      })

        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/groups_scenarios/batch/update`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    groups_scenarios,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    Object.keys(old_value_map).forEach(groups_scenario_id => {
                        // @ts-ignore
                        GroupsScenario.find(groups_scenario_id).props.real_order = old_value_map[groups_scenario_id]
                    })
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }

    destroy() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/groups_scenarios/${this.key()}`,
                type: "DELETE",
                data: {
                    authenticity_token,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            });
        })
    }
}
