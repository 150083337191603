import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumFileType } from "../../auto_generated/enums";
import { File } from "./file";
import { what_is_it } from "../../helpers/generic/what_is_it";
import { Section } from "../../components/testa/section_toggler/section";
import { computed } from "../../helpers/vue/computed";
import { FileScope } from "../scopes/file_scope";
import { FileFolderClient } from "../clients/file_folder_client";
import { FileFolderScope } from "../scopes/file_folder_scope";
import { fix_file_paths } from "../../helpers/files/fix_file_paths";
import { RecordOpts } from "../base/vue_record";
import { FileProps } from "./file";
import { ComputedRole } from "../base/vue_record";
import { get_files_webkit_data_transfer_items } from "../../helpers/files/get_files_ewebkit_data_transfer_list";

// <editor-fold desc="TYPES">
export interface FileFolderProps extends Props {
    __old_path?: string
    path: string
    project_version_id: number
    name: string
    size: number
    file_folder_path: string
    type: EnumFileType
    updated_at: Date
    created_at: Date
}
export type QuerifiedFileFolderProps = QuerifyProps<FileFolderProps>
export type FileFolderCreateProps = Partial<FileFolderProps>
export type FileFolderUpdateProps = Partial<FileFolderProps>
export interface FileFolderState extends State {}
export interface FileFolderComputed extends ComputedRole {
    download_path: string
}
export interface FileFolderStaticState extends StaticState {
    children_promises: Record<string, Promise<FileFolderChildren>>
}

export type FileFolderChildren = {
    files: File[],
    file_folders: FileFolder[]
}
// </editor-fold>

const console = new Consoler("info")
export class FileFolder extends VueRecord {
    ['constructor']: typeof FileFolder

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static discard_outdated = false
    static ClientClass = FileFolderClient
    static ScopeClass = FileFolderScope
    static readonly primary_key = "path"
    static sync_channels: string[] = []
    static state: FileFolderStaticState = reactive<FileFolderStaticState>({
        children_promises: {},
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "file_folder_path"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof FileFolder> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof FileFolder>> = {}

    static field_validators: ModelValidatorOpts<FileFolderProps> = {}

    static resource_name = Enum.Resource.Label.FILE_FOLDER
    static resource_id = Enum.Resource.Id.FILE_FOLDER
    static icon_class = "fa-solid fa-folder-open"
    static color = () => get_css_var("--file-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: FileFolderClient
    declare props: FileFolderProps;
    declare state: FileFolderState;
    declare computed: FileFolderComputed;

    // </editor-fold>

    constructor(props: FileFolderProps, opts: RecordOpts) {
        super(props, opts);

        this.computed.download_path = computed(() => `/file_folders/${this.key()}/download?project_version_id=${this.props.project_version_id}`)
    }

    // <editor-fold desc="HOOKS">
    after_update(new_props: FileFolderProps, old_props: FileFolderProps, changes: (keyof FileFolderProps)[]) {
        super.after_update(new_props, old_props, changes);

        // <editor-fold desc="PATH">
        if (changes.includes("path")) {
            fix_file_paths(this, old_props.path, new_props.path)
        }
        // </editor-fold>
    }
    // </editor-fold>

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        return FileFolder.show_in_sidebar(this.key(), this.props.project_version_id, tree);
    }

    static async show_in_sidebar(file_folder_paths: string | string[], project_version_id: number, tree: TestaTree.Tree = TestaTree.Tree.get_project_tree()) {
        let ids: string[];
        if (what_is_it(file_folder_paths) == "Array") {
            ids = file_folder_paths as string[]
        } else {
            ids = [file_folder_paths as string]
        }

        if (web.is_main) {
            Section.get_project_section().enable()
            this.ClientClass.batch_path(file_folder_paths).then((promise_response) => {
                const all_keys: string[][] = []
                Object.keys(promise_response).forEach(file_folder_path => {
                    const file_folders = promise_response[file_folder_path]
                    const keys = [
                        FileFolder.tree_key(project_version_id),
                        ...file_folders.map(sf => sf.tree_key()),
                        FileFolder.find(file_folder_path).tree_key()
                    ]
                    all_keys.push(keys)
                })
                tree.expand_paths(all_keys)
            })
        } else {
            const web = ui_sync.web_for_main(project_version_id)
            ui_sync.send_ui_show_in_sidebar_task(web, ids.map(id => {
                return {
                    resource_id: FileFolder.resource_id,
                    id
                }
            }))
        }
    }

    // <editor-fold desc="HELPERS">
    path(include_name = false) {
        if (include_name) {
            return this.props.path
        } else {
            if (this.props.file_folder_path == null) return "/"
            return this.props.file_folder_path
        }
    }

    static read_dropped_files_and_upload(project_version_id: number, file_folder_path: string, items: DataTransferItemList) {
        console.log("items", items);
        const base_path = file_folder_path
        get_files_webkit_data_transfer_items(items, "", null)
            .then(files => {
                console.log("files", files);
                let count = 0;
                for (const folder_id in files) {
                    if (!files.hasOwnProperty(folder_id)) continue;

                    let path = base_path
                    if (path == null) path = ""
                    if (folder_id != null || folder_id != "") path = `${path}/${folder_id}`
                    files[folder_id].forEach(file => File.ClientClass.upload(project_version_id, path, file, count++))
                }
            })
        // track progress for all uploading files, so we can have single progress_bar
        File.state.upload_progresses = []
    }
    // </editor-fold>

    // <editor-fold desc="TREE">
    // <editor-fold desc="HOVER_ACTION">
    static _hover_action_data(project_version_id: number): TestaTree.HoverAction<FileScope, FileFolderScope, FileFolder> {
        return {
            icon: {
                class: "fa-solid fa-plus"
            },
            callback: (event, node) => {
                node.set_expanded(true);
                node.show_context_menu(event, FileFolder._tree_new_file_contextmenu_items(project_version_id).build);
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<FileScope, FileFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    File.ClientClass.create({
                        file_folder_path: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    FileFolder.ClientClass.create({
                        file_folder_path: this.key(),
                        project_version_id: this.props.project_version_id
                    })
                }
            }
            return keys;
        })
    }

    static _testa_tree_hotkeys(project_version_id: number) {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<FileScope, FileFolderScope, VueRecord>> = {}
            if (current.role != Enum.User.Role.VIEWER) {
                keys["+"] = () => {
                    File.ClientClass.create({
                        file_folder_path: null,
                        project_version_id
                    })
                }

                keys[`${ctrl_or_meta}++`] = () => {
                    FileFolder.ClientClass.create({
                        file_folder_path: null,
                        project_version_id
                    })
                }
            }
            return keys;
        })
    }

    // </editor-fold>

    // <editor-fold desc="CONTEXT MENU">
    static _tree_new_file_contextmenu_items(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<FileScope, FileFolderScope, FileFolder | File>) => {
                const items: ContextMenu.Items = {}
                items.new_file = {
                    name: "File",
                    icon: "fas fa-pencil-alt",
                    color: get_css_var("--button-white"),
                    key: "+",
                    callback: () => {
                        node.set_expanded(true);
                        File.ClientClass.create({
                            file_folder_path: node.record?.key(),
                            project_version_id
                        });
                    },
                }
                items.new_file_folder = {
                    name: "Folder",
                    icon: "fa fa-folder folder",
                    color: get_css_var("--button-white"),
                    key: `${ctrl_or_meta}-+`,
                    callback: () => {
                        node.set_expanded(true);
                        FileFolder.ClientClass.create({
                            file_folder_path: node.record?.key(),
                            project_version_id
                        });
                    },
                }
                return items
            },
        }
    }

    static _testa_tree_contextmenu(project_version_id: number) {
        return {
            build: (node: TestaTree.Node<FileScope, FileFolderScope, FileFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: FileFolder._tree_new_file_contextmenu_items(project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.upload = File._upload_contextmenu_item(node)

                return { ...items, ...default_items }
            }
        }
    }

    _testa_tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<FileScope, FileFolderScope, FileFolder>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (current_role != Enum.User.Role.VIEWER) {
                    items.new = {
                        name: "New",
                        items: FileFolder._tree_new_file_contextmenu_items(this.props.project_version_id).build(node),
                        icon: "fa-solid fa-plus",
                        color: get_css_var("--button-white"),
                    }
                }

                items.upload = File._upload_contextmenu_item(node)
                items.download = {
                    name: "Download",
                    icon: "fa-solid fa-download",
                    color: get_css_var("--button-blue"),
                    callback: () => {
                        const records_scope = node.tree.get_selected_records()
                        if (records_scope.files.count + records_scope.file_folders.count > 1) {
                            FileFolder.ClientClass.batch_download(node.tree.project_version.key(), records_scope.file_folders.toArray(), records_scope.files.toArray())
                        } else {
                            this.client.download();
                        }
                    }
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="DND">
    _testa_tree_node_dnd(project_version_id: number): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => true),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return true;
                return dnd.state.records.some(r => r instanceof File || r instanceof FileFolder)
            }),
            on_drop: (e: DragEvent) => {
                if (dnd.state.is_files) {
                    FileFolder.read_dropped_files_and_upload(project_version_id, this.props.path, e.dataTransfer.items)
                } else {
                    const file_folders = dnd.state.records.filter(r => r instanceof FileFolder)
                    const files = dnd.state.records.filter(r => r instanceof File)
                    FileFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.key(),
                        file_folders.map(s => s.key()),
                        files.map(s => s.key()))
                }
            },
            on_over: (e: DragEvent) => {
                if (!dnd.state.is_files) e.dataTransfer.dropEffect = "move"
            }
        }
    }

    static _testa_tree_node_dnd(project_version_id: number): TestaTree.DragAndDrop {
        return {
            is_draggable: computed(() => false),
            is_drop_area: computed(() => {
                if (!dnd.state.is_dragging) return false;
                if (dnd.state.is_files) return true;
                return dnd.state.records.some(r => r instanceof File || r instanceof FileFolder)
            }),
            on_drop: (e: DragEvent) => {
                if (dnd.state.is_files) {
                    FileFolder.read_dropped_files_and_upload(project_version_id, null, e.dataTransfer.items)
                } else {
                    const file_folders = dnd.state.records.filter(r => r instanceof FileFolder)
                    const files = dnd.state.records.filter(r => r instanceof File)
                    FileFolder.ClientClass.move(
                        project_version_id,
                        null,
                        file_folders.map(s => s.key()),
                        files.map(s => s.key())
                    )
                }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="CLIPBOARD">
    _testa_tree_node_clipboard(): TestaTree.Clipboard {
        return {
            can_copy: computed(() => current.role != Enum.User.Role.VIEWER),
            can_cut: computed(() => current.role != Enum.User.Role.VIEWER),
            on_paste: (type, nodes) => {
                const file_folder_paths = nodes.map(n => n.record)
                                               .filter(r => r instanceof FileFolder)
                                               .map(r => r.key())
                const file_paths = nodes.map(n => n.record)
                                        .filter(r => r instanceof File)
                                        .map(r => r.key())

                if (type == "copy") {
                    FileFolder.ClientClass.copy(
                        this.props.project_version_id,
                        this.key(),
                        file_folder_paths,
                        file_paths
                    )
                } else if (type == "cut") {
                    FileFolder.ClientClass.move(
                        this.props.project_version_id,
                        this.key(),
                        file_folder_paths,
                        file_paths
                    )
                }
            }
        }
    }

    static _testa_tree_node_clipboard(project_version_id: number): TestaTree.Clipboard {
        return {
            on_paste: (type, nodes) => {
                const file_folder_paths = nodes.map(n => n.record)
                                               .filter(r => r instanceof FileFolder)
                                               .map(r => r.key())
                const file_paths = nodes.map(n => n.record)
                                        .filter(r => r instanceof File)
                                        .map(r => r.key())

                if (type == "copy") {
                    FileFolder.ClientClass.copy(
                        project_version_id,
                        null,
                        file_folder_paths,
                        file_paths
                    )
                } else if (type == "cut") {
                    FileFolder.ClientClass.move(
                        project_version_id,
                        null,
                        file_folder_paths,
                        file_paths
                    )
                }
            }
        }
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<FileScope, FileFolderScope, FileFolder> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.FILE_FOLDER,
            record: this,
            title: computed(() => {
                return { template: this.props.name }
            }),
            duplicable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            deletable: (computed(() => current.role != Enum.User.Role.VIEWER)),
            icon: computed(() => {
                return {
                    color: get_css_var("--file-color"),
                }
            }),
            renaming: {
                renameable: computed(() => current.role != Enum.User.Role.VIEWER),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            folder: {
                open_fn: () => {
                    return this.client.children()
                },
                folder_scope: computed(() => this.file_folders.order("name")),
                file_scope: computed(() => this.files.order("name"))
            },
            hover_action: computed(() => FileFolder._hover_action_data(this.props.project_version_id)),
            dnd: this._testa_tree_node_dnd(this.props.project_version_id),
            contextmenu: this._testa_tree_contextmenu(),
            clipboard: this._testa_tree_node_clipboard(),
            hotkeys: this._testa_tree_hotkeys(),
        }
    }

    static testa_tree_node_data(project_version_id: number): TestaTree.NodeInput<FileScope, FileFolderScope, FileFolder> {
        return {
            resource_id: Enum.Resource.Id.FILE_FOLDER,
            folder: {
                open_fn: () => {
                    return FileFolder.ClientClass.children(project_version_id, null)
                },
                file_scope: computed(() => File.where({ file_folder_path: null, project_version_id }).order("name")),
                folder_scope: computed(() => FileFolder.where({ file_folder_path: null, project_version_id }).order("name"))
            },
            icon: computed(() => {
                return {
                    class: FileFolder.icon_class,
                    color: FileFolder.color(),
                    scale: 0.9,
                }
            }),
            key: this.tree_key(project_version_id),
            title: computed(() => {
                return { template: "Files" }
            }),
            hover_action: computed(() => FileFolder._hover_action_data(project_version_id)),
            dnd: FileFolder._testa_tree_node_dnd(project_version_id),
            clipboard: FileFolder._testa_tree_node_clipboard(project_version_id),
            hotkeys: FileFolder._testa_tree_hotkeys(project_version_id),
            contextmenu: FileFolder._testa_tree_contextmenu(project_version_id)
        }
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
FileFolder.register_resource(FileFolder)
FileFolderClient.ModelClass = FileFolder
FileFolderScope.ModelClass = FileFolder

global_event_bus.$on("after_project_version_unload", () => {
    FileFolder.state.children_promises = {}
    FileFolder.get_scope().unload()
})

declare global {
    interface Window {
        FileFolder: typeof FileFolder
    }
}
window.FileFolder = FileFolder
// </editor-fold>

