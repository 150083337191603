<template>
  <div
      :id="id"
      ref="slider"
      :class="klass"
      :style="style"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import noUiSlider from 'nouislider';
import { CSSProperties } from "vue";
import { throttle } from "lodash";
import { debounce } from "lodash";
import { delayed_debounce } from "../../helpers/generic/delayed_debounce";
import { PropType } from "vue";

// DOCS: https://refreshless.com/nouislider
export default defineComponent({
    props: {
        modelValue: {
            type: Array as PropType<Array<Date | number | string>>,
            required: true,
        },
        min: {
            type: [Date, Number],
            required: true,
        },
        max: {
            type: [Date, Number],
            required: true,
        },
        tooltips: {
            type: Boolean,
            required: false,
            default: false
        },
        scale: {
            type: [Number, String],
            required: false,
            default: 1
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        // styling options
        no_overlap: {
            type: Boolean,
            required: false,
            default: true,
        },
        fit_handles_within_slider: {
            type: Boolean,
            required: false,
            default: true,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: false
        },
        // delaying
        delayed_debounce_time: {
            type: Number,
            required: false,
            default: 0
        },
        debounce_time: {
            type: Number,
            required: false,
            default: 0,
        },
        /** Throttle how often should the value update event be emitted. Useful when the value is used
         * to filter large collection of records */
        throttle_time: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            is_date_value: this.max instanceof Date,
            debounced_on_value_update: undefined,
            throttled_on_value_update: undefined,
            delayed_debounced_on_value_update: undefined,
            preparsed_value: null
        }
    },
    computed: {
        scale_number(): number {
            if (typeof this.scale == "string") {
                return parseFloat(this.scale)
            } else return this.scale
        },
        klass() {
            return {
                "custom-noUi-default": true,
                "custom-noUi-no-overlap": this.no_overlap,
                "custom-noUi-fit-handles-within-slider": this.fit_handles_within_slider,
                "custom-noUi-rounded": this.rounded
            }
        },
        style() {
            const style: CSSProperties = {}
            return style
        },
        normalized_min() {
            if (this.min instanceof Date) return this.min.getTime()
            return this.min
        },
        normalized_max() {
            if (this.max instanceof Date) return this.max.getTime()
            return this.max
        },
        normalized_value() {
            return this.modelValue.map(v => {
                if (v == null) return this.normalized_min
                if (v instanceof Date) return v.getTime()
                return v
            })
        }
    },
    watch: {
        normalized_value(new_value, old_value) {
            console.log("normalized v 1");
            if (!_.isEqual(new_value, old_value)) {
                console.log("normalized v 2");
                this.set_value(this.normalized_value)
            }
        },
        normalized_min() {
            this.update_options()
        },
        normalized_max() {
            this.update_options()
        },
        tooltips() {
            this.update_options()
        },
        throttle_time: {
            immediate: true,
            handler() {
                this.throttled_on_value_update = throttle(function() {
                    // @ts-ignore
                    this.emit(this.parse_val(this.preparsed_value))
                }, this.throttle_time)
            },
        },
        debounce_time: {
            immediate: true,
            handler() {
                this.debounced_on_value_update = debounce(function() {
                    // @ts-ignore
                    this.emit(this.parse_val(this.preparsed_value))
                }, this.debounce_time)
            },
        },
        delayed_debounce_time: {
            immediate: true,
            handler() {
                this.delayed_debounced_on_value_update = delayed_debounce(function() {
                    // @ts-ignore
                    this.emit(this.parse_val(this.preparsed_value))
                }, this.delayed_debounce_time)
            }
        },
    },
    created() {
    },
    mounted() {
        var slider = this.$refs.slider as HTMLElement

        noUiSlider.create(slider, {
            start: this.normalized_value,
            connect: true,
            range: {
                min: this.normalized_min,
                max: this.normalized_max
            },
            tooltips: this.tooltips
        });

        this.preparsed_value = slider.noUiSlider.get();

        slider.noUiSlider.on('update', (values, _handle) => {
            this.on_value_updated(values)
        });
    },
    methods: {
        parse_val(value: Array<number | string>): Array<number | string | Date> {
            if (this.is_date_value) return value.map(v => new Date(parseInt(v as string)))
            return value
        },
        set_value(value: Array<string | number>) {
            const slider = this.$refs.slider as HTMLElement
            if (slider == null) return
            slider.noUiSlider.set(value);
        },
        update_options() {
            const slider = this.$refs.slider as HTMLElement
            if (slider == null) return
            slider.noUiSlider.updateOptions({
                range: {
                    min: this.normalized_min,
                    max: this.normalized_max
                },
                tooltips: this.tooltips
            }, false);
        },
        on_value_updated(value: any) {
            console.log("on value updated: ", value);
            this.preparsed_value = value
            if (this.throttle_time > 0) {
                this.throttled_on_value_update()
            } else if (this.debounce_time > 0) {
                this.debounced_on_value_update()
            } else if (this.delayed_debounce_time > 0) {
                this.delayed_debounced_on_value_update()
            } else {
                this.emit(this.parse_val(this.preparsed_value))
            }
        },
        emit(value: any) {
            console.log("emitting ", value);
            this.$emit('update:modelValue', value)
        }
    },
})
</script>

<style lang="scss">
.custom-noUi-default {
  width: 100%;

  .noUi-connect {
    background: var(--primary-background-color);
  }
}

.custom-noUi-no-overlap {
  .noUi-handle-lower {
    right: 0
  }
}

.custom-noUi-fit-handles-within-slider {
  padding: 0 16px;
  &.custom-noUi-rounded {
    padding: 0 3px;
  }
}
.custom-noUi-rounded {
  .noUi-handle {
    height: 18px;
    width: 18px;
    top: -1px;
    right: -9px; /* half the width */
    border-radius: 9px;
    background: var(--primary-background-color);
  }

  .noUi-handle::before,
  .noUi-handle::after {
    display: none;
  }
}

// <editor-fold desc="DEFAULT">
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px;
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

// </editor-fold>


</style>
