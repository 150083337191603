import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { FeatureFlag } from "../models/feature_flag";
import { QuerifiedFeatureFlagProps } from "../models/feature_flag";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class FeatureFlagClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof FeatureFlag
    declare record: FeatureFlag

    static create(name: string, description: string = null, project_version_id: number = null) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: '/settings/superadmin_settings/feature_flags',
                data: {
                    project_version_id,
                    authenticity_token,
                    name,
                    description,
                },
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                type: 'POST',
                statusCode: ajax_status_codes,
            })
        })
    }

    update(feature_flag_props: QuerifiedFeatureFlagProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/feature_flags/${this.key()}`,
                type: 'PUT',
                data: JSON.stringify({
                    authenticity_token,
                    feature_flag: feature_flag_props,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }

    static delete(ids: number | number[]) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/feature_flags/destroy`,
                type: "DELETE",
                data: {
                    ids,
                    authenticity_token,
                },
                statusCode: ajax_status_codes,
                success: () => resolve(),
                error: (e) => reject(e)
            });
        })
    }
}
