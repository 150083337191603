import { EnumResourceId } from "../../../auto_generated/enums";
import { VueRecord } from "../vue_record";

const acceptable_resource_ids: EnumResourceId[] = [
    Enum.Resource.Id.SNIPPET,
    Enum.Resource.Id.SCENARIO,
    Enum.Resource.Id.GROUP,
    Enum.Resource.Id.FILE,
    Enum.Resource.Id.IMAGE,
    Enum.Resource.Id.PLAY
]
export function filter_records_that_can_be_tabbed(records: VueRecord[]) {
    return records.filter(r => acceptable_resource_ids.includes(r.constructor.resource_id))
}
