<template>
  <Modal
      id="new_project_form"
      ref="modal"
      :show_cancel="true"
      :focus_confirm="false"
      initial_width="600px"
      :cancel_on_escape="true"
      :focus_last_active_on_unmount="true"
      confirm_text="Create"
      :form_validator="form_validator"
      :confirm_action="confirm"
      @cancel="cancel"
  >
    <template #header>
      <div class="title">
        New Project
      </div>
    </template>

    <template #body="modal">
      <div class="row">
        <div class="col s12">
          <Input
              id="new_project_name"
              v-model="project_props.full_name"
              label="Name"
              :focus="true"
              :validator="form_validator.register('name', Project.field_validators.full_name)"/>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <Input
              id="new_project_version"
              v-model="project_version_props.version"
              label="Version"
              :validator="form_validator.register('version', ProjectVersion.field_validators.version)"
              @enter="modal.confirm()"
          />
        </div>
      </div>
    </template>

  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Input from "../../../testa/Input.vue";
import { ProjectQueryProps } from "../../../../vue_record/models/project";
import { Project } from "../../../../vue_record/models/project";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { QuerifiedProjectVersionProps } from "../../../../vue_record/models/project_version";

export default defineComponent({
    components: {
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: ['close'],
    // <editor-fold desc="DATA">
    data() {
        return {
            form_validator: new FormValidator(),
            project_props: {} as ProjectQueryProps,
            project_version_props: {} as QuerifiedProjectVersionProps,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        ProjectVersion() {
            return ProjectVersion
        },
        Project() {
            return Project
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        'project_props.full_name'() {
            this.project_props.short_name = this.project_props.full_name
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        confirm() {
            return Project.ClientClass
                          .create(this.project_props, this.project_version_props)
                          .then(() => this.cancel())
        },
        cancel() {
            (this.$refs.modal as typeof Modal).close()
            this.$emit("close")
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
