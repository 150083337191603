<template>
  <Modal
      :id="id"
      :dismissible="true"
      :show_confirm="false"
      :backdrop_dim_level="0"
      :padding="0"
      :cancel_on_escape="true"
      :focus_last_active_on_unmount="true"
      @cancel="cancel"
  >
    <template #body>
      <div ref="code_search_container"
           class="code-search-container"
           :style="container_style"
      >
        <RFlex :rflex="rflex">
          <template #search>
            <div style="display: flex; flex-direction: column; width: 100%; padding-bottom: 3px;">
              <div class="row"
                   style="margin-bottom: 5px;">
                <div class="col s12 full">
                  <Input
                      ref="input"
                      v-model="search_query"
                      :throttle_time="500"
                      :focus="true"
                      :no_round_corners="true"
                      placeholder="Search Code"
                      @keydown="on_keydown"
                  />
                </div>
              </div>
              <div ref="results_container"
                   class="results-container"
                   @scroll="on_results_scroll"
              >
                <template v-if="results.length > 0">
                  <template v-for="result in results"
                            :key="result.vue_key">
                    <div
                        :id="result.vue_key"
                        :ref="active_key == result.vue_key ? `active_element` : `dummy`"
                        class="result"
                        :class="active_key == result.vue_key ? 'active' : ''"
                        @click="on_result_click(result)"
                        @dblclick="on_result_dbl_click(result)"
                    >
                      <div class="code"
                           v-html="result.html_code"/>
                      <div class="flex-expander"/>
                      <div class="name">
                        {{ result.name }}:{{ result.line }}
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="no-results-container">
                    No results
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template
              v-if="active_result != null"
              #code
          >
            <template v-if="loading || snippet_loading">
              <Loading type="wandering_cubes"/>
            </template>
            <template v-else-if="active_snippet != null">
              <SnippetEditor
                  id="code_search_snippet_editor"
                  :snippet="active_snippet"
                  @cm="(cm) => active_cm = cm"
              />
            </template>
          </template>
        </RFlex>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../Modal.vue";
import Input from "../../../Input.vue";
import { Snippet } from "../../../../../vue_record/models/snippet";
import Loading from "../../../Loading.vue";
import { nextTick } from "vue";
import SnippetEditor from "./SnippetEditor.vue";
import { KeyCode } from "../../../../../types/globals";
import { code_search_modal_id } from "../../../../../vue_record/models/snippet";
import { jump_to_line } from "../../../../../helpers/codemirror/helpers/jump_to_line";
import CodeMirror from "codemirror";
import { PropType } from "vue";
import { ProjectVersion } from "../../../../../vue_record/models/project_version";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";
import { ResizableFlex } from "../../../resizable/resizable_flex/resizable_flex";
import RFlex from "../../../resizable/resizable_flex/RFlex.vue";
import { CodeSearchResult } from "../../../../../types/components/testa/editor/editors/snippet/code_search_result";

const storager_height_key = "code_search_height"
const storager_width_key = "code_search_width"
export default defineComponent({
    components: { RFlex, SnippetEditor, Loading, Input, Modal },
    // <editor-fold desc="PROPS">
    props: {
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true
        },
        query: {
            type: String,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            id: code_search_modal_id,
            width: "50vw",
            height: "50vh",
            amo: null as AllMightyObserver,
            search_query: this.query,
            last_timestamp: null as Date,
            all_loaded: false,
            results: [] as CodeSearchResult[],
            loading: false,
            snippet_loading: false,
            active_key: null as string,
            active_snippet: null as Snippet,
            previous_active_element: null as HTMLElement,
            active_cm: null as CodeMirror.Editor,
            rflex: ResizableFlex.new({
                id: "code_search_rflex",
                fill_container: true,
                direction: "column",
                areas: [
                    {
                        id: "search",
                        min_px_size: 100
                    },
                    {
                        id: "code",
                        min_px_size: 100
                    }
                ]
            })
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        storager() {
            return current.storagers.user
        },
        container_style() {
            return {
                width: this.width,
                height: this.height
            }
        },
        active_result() {
            return this.results.find(r => r.vue_key == this.active_key)
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        search_query: {
            handler() {
                this.all_loaded = false
                this.results = []
                this.active_key = null
                this.do_code_search()
            },
            immediate: true
        },
        active_key() {
            nextTick(() => {
                const active = (this.$refs.active_element as HTMLElement[])[0]
                if (active == null) return;
                console.log(active);
                active.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest" })
            })

            if (this.active_key == null) this.active_snippet = null;
            else {
                if (this.active_result == null) this.active_snippet = null;
                else {
                    this.snippet_loading = true
                    Snippet.ClientClass.load(this.active_result.id).then(snippet => {
                        // @ts-ignore
                        this.active_snippet = snippet
                    }).finally(() => {
                        this.snippet_loading = false;
                    })
                }
            }
        },
        active_cm() {
            if (this.active_cm == null) return;
            if (this.active_result == null) return;
            jump_to_line(this.active_cm as CodeMirror.Editor, this.active_result.line, 0)
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMount() {
        const height = this.storager.get<string>(storager_height_key, null);
        const width = this.storager.get<string>(storager_width_key, null)
        if (height != null) this.height = height
        if (width != null) this.width = width;
    },
    mounted() {
        this.amo = AllMightyObserver.new({
            element_after_resize: true,
            element_resize_delay: 500,
            target_element: this.$refs.code_search_container as HTMLElement,
            callback: () => this.save_size()
        })
    },
    beforeUnmount() {
        this.save_size();
    },
    unmounted() {
        this.amo?.stop();
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        cancel() {
            this.$.appContext.app.unmount();
        },
        save_size() {
            const container = this.$refs.code_search_container as HTMLElement
            if (container != null) {
                this.height = `${container.clientHeight}px`
                this.width = `${container.clientWidth}px`
                this.storager.set(storager_height_key, this.height)
                this.storager.set(storager_width_key, this.width)
            }
        },
        do_code_search() {
            this.loading = true
            const query = this.search_query
            Snippet.ClientClass
                   .code_search(this.project_version.key(), this.search_query, this.last_timestamp)
                   .then(response => {
                if (query != this.search_query) return;

                this.all_loaded = response.all_loaded;
                this.results.forEach(result => {
                    if (this.last_timestamp == null || this.last_timestamp > result.created_at) this.last_timestamp = result.created_at;
                })

                this.results = this.results.concat(response.results)
            }).finally(() => {
                this.loading = false;
                if (this.active_key == null && this.results.length > 0) this.active_key = this.results[0].vue_key
            })
        },
        on_keydown(e: KeyboardEvent) {
            if (e.ctrlKey) return
            if (e.altKey) return
            if (e.shiftKey) return;
            if (e.code == KeyCode.DOWN) {
                this.activate_next()
            } else if (e.code == KeyCode.UP) {
                this.activate_previous()
            } else if (e.code == KeyCode.ENTER) {
                if (this.active_key == null) return;
                Snippet.ClientClass.load(this.active_result.id).then(snippet => {
                    this.cancel();
                    snippet.open({
                        jump_to: { line: this.active_result.line, ch: 0 },
                        focus: true,
                        close_all_modals: true
                    })
                })
            }
        },
        activate_next() {
            const active_index = this.results.findIndex(r => r.vue_key == this.active_key)
            if (active_index == -1) return;
            if (active_index + 1 == this.results.length) return
            this.active_snippet = null
            this.active_key = this.results[active_index + 1].vue_key
        },
        activate_previous() {
            const active_index = this.results.findIndex(r => r.vue_key == this.active_key)
            if (active_index == -1) return;
            if (active_index == 0) return
            this.active_snippet = null
            this.active_key = this.results[active_index - 1].vue_key
        },
        on_results_scroll() {
            if (this.loading) return;
            if (this.all_loaded) return;

            const results_container = this.$refs.results_container as HTMLElement;
            const scroll_bottom = results_container.scrollTop + results_container.clientHeight;
            const diff = results_container.scrollHeight - scroll_bottom
            if (diff < 50) this.do_code_search()
        },
        on_result_click(result: CodeSearchResult) {
            this.active_snippet = null;
            this.active_key = result.vue_key;
        },
        on_result_dbl_click(result: CodeSearchResult) {
            Snippet.ClientClass.load(result.id).then(snippet => {
                this.cancel();
                snippet.open({ jump_to: { line: result.line, ch: 0 }, focus: true })
            })
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss">
.code-search-result-highlight {
  background: var(--sidebar-selected-contrast);
}
</style>

<style lang="scss" scoped>
.code-search-container {
  min-width: 100px;
  max-width: 90vw;

  min-height: 200px;
  max-height: 90vh;

  resize: both;
  overflow: auto;
}

.results-container {
  display: flex;
  font-size: 13px;
  padding: 2px 4px;
  font-family: monospace;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 39px);

  .result {
    height: 20px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    align-items: center;
    border-left: 1px solid transparent;

    &:hover {
      background-color: var(--ternary-background-color);
    }

    &.active {
      box-sizing: border-box;
      border-left: 1px solid var(--button-blue);
      background-color: var(--sidebar-selected);
    }

    .code {
      text-align: left;
      display: flex;

      white-space: pre;
      margin-right: 10px;
      overflow: hidden;
      max-width: 75%;
      min-width: 25%;
    }

    .name {
      justify-content: flex-end;
      font-size: 10px;
      display: flex;
      white-space: nowrap;
      max-width: 75%;
      min-width: 25%;
      margin-left: auto;
      overflow: hidden;
      color: var(--font-color-secondary);
    }
  }

  .no-results-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.7em;
  }
}
</style>
