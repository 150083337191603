const string_constructor = 'test'.constructor;
const array_constructor = [].constructor;
const object_constructor = ({}).constructor;
const function_constructor = Function.constructor;
const number_constructor = Number;
const boolean_constructor = true.constructor;

export function what_is_it(object: any) {
    if (object === null) {
        return 'null';
    }
    if (object === undefined) {
        return 'undefined';
    }
    if (object.constructor === string_constructor) {
        return 'String';
    }
    if (object.constructor === array_constructor) {
        return 'Array';
    }

    if (object.constructor === boolean_constructor) {
        return "Boolean";
    }

    if (object.constructor === object_constructor) {
        return 'Object';
    }
    if (object.constructor === function_constructor) {
        return "Function"
    }
    if (object.constructor == number_constructor) {
        return "Number"
    }

    return 'don\'t know';
}
