<!--suppress BadExpressionStatementJS -->
<template>
  <template v-if="show_table">
    <SettingsItem
        id="all_projects"
        :title="setting_items.superadmin.all_projects.title">
      <div class="row"
           style="display: flex;">
        <div class="col s8"
             style="display: flex;">
          <Button
              v-if="!is_viewer_role && !is_user_role"
              text="New Project"
              @click="new_project"
          />
        </div>
        <div class="col s4">
          <Input
              v-model="filter"
              placeholder="Filter"
              :throttle_time="200"
          />
        </div>
      </div>
      <Table id="projects_table">
        <template #thead>
          <tr>
            <template v-for="(col, key) in visible_columns"
                      :key="key">
              <th :class="{asc: orders.is_asc(key), desc: orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}"
                  :data-priority="orders.priority(key)"
                  @click="orders.toggle(key)">
                {{ col.name }}
              </th>
            </template>
          </tr>
        </template>

        <template #tbody>
          <tr v-for="row in ordered_rows"
              :key="row.record.props.id"
              class="project-row"
              @click="show_project(row.record)">
            <template v-for="(col, index) in row.cols"
                      :key="index">
              <td
                  v-moment="col.moment"
                  :title="col.title"
                  :class="col.classes"
                  v-html="col.html"/>
            </template>
          </tr>
        </template>
      </Table>
      <div v-if="show_new_project">
        <NewProjectForm @close="show_new_project = false"/>
      </div>
    </SettingsItem>
  </template>
  <template v-else>
    <ProjectEdit :project="edit_project"
                 :is_viewer_role="is_viewer_role"
                 :is_user_role="is_user_role"
                 :form_validator="form_validator.register_child_form('project_edit')"
                 @exit="show_table=true"
                 @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
                 @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Table from "../../../testa/Table.vue";
import Input from "../../../testa/Input.vue";
import { Project } from "../../../../vue_record/models/project";
import Button from "../../../testa/Button.vue";
import SettingsItem from "../../SettingsItem.vue";
import { PropType } from "vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import ProjectEdit from "./ProjectEdit.vue";
import NewProjectForm from "./NewProjectForm.vue";
import { RecordColumns } from "../../../../vue_record/base/utils/records_table";
import { RecordOrder } from "../../../../vue_record/base/utils/record_order";
import { RecordsTable } from "../../../../vue_record/base/utils/records_table";


export default defineComponent({
    components: {
        ProjectEdit,
        NewProjectForm,
        Button,
        Input,
        SettingsItem,
        Table,
    },
    props: {
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            show_table: true,
            edit_project: null as Project,
            show_new_project: false,
            filter: "",
            columns: {
                name: {
                    name: "Name",
                    html: (record) => {
                        return record.props.full_name
                    },
                },
                created_at: {
                    name: "Created at",
                    moment: (record) => record.props.created_at,
                    order_value: (record) => record.props.created_at,
                },
                users: {
                    name: "Users",
                    html: (record) => {
                        return record.user_projects.toArray().length
                    },
                    order_value: (record) => { return record.user_projects.toArray().length }
                },
                versions: {
                    name: "Versions",
                    html: (record) => { return record.project_versions.toArray().length },
                    order_value: (record) => { return record.project_versions.toArray().length }
                },
            } as RecordColumns<Project>,
            orders: new RecordOrder([["name", "asc"]]),
        }
    },
    computed: {
        setting_items() {
            return setting_items
        },
        visible_columns() {
            return RecordsTable.visible_columns<Project>(this.columns);
        },
        records() {
            return Project.get_scope().toArray()
        },
        rows() {
            return RecordsTable.generate_rows<Project>(this.visible_columns, this.records as Project[])
        },
        filtered_rows() {
            return RecordsTable.filter<Project>({
                columns: this.visible_columns,
                filter: this.filter,
                rows: this.rows,
            })
        },
        ordered_rows() {
            return RecordsTable.order<Project>(this.visible_columns, this.filtered_rows, this.orders)
        },
    },
    watch: {},
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        show_project(project: Project) {
            this.edit_project = project
            this.show_table = false
        },
        delete_project(project: Project) {
            project.delete()
        },
        new_project() {
            this.show_new_project = true
        },
    },
})
</script>

<style lang="scss" scoped>
.project-row {
  cursor: pointer;

  .action {
    cursor: pointer;
    width: 10px;
    display: flex;
    justify-content: center;

    &.delete {
      color: var(--button-red)
    }
  }
}
</style>
