import { SyntaxError } from "./syntax_error";
import { Snippet } from "../../../vue_record/models/snippet";
import { watch } from "vue";
import { nextTick } from "vue";
import _ from "lodash";

const gutter_id = "syntax-error"
export function handle_syntax_error(cm: CodeMirror.Editor, data: SyntaxError, snippet_id: number) {
    if (data != null && data.error) {
        data.line = data.line - 1;
        if (data.char === 0) {
            cm.markText({ line: data.line, ch: 0 }, { line: data.line, ch: 99999 }, {
                className: "codemirror-syntax-error",
                shared: true
            });
        } else {
            data.char = data.char - 1;
            cm.markText({ line: data.line, ch: data.char }, {
                line: data.line,
                ch: (data.char + 1)
            }, { className: "codemirror-syntax-error", shared: true });
        }
        const marker = document.createElement("i");
        marker.setAttribute('class', 'fas fa-exclamation-triangle');
        // marker.setAttribute('style', 'color: red; font-size: 8px; cursor: pointer; margin-left: -11px; margin-top: 6.5px; opacity: 1');
        marker.setAttribute('style', 'color: red; font-size: 0.5em; cursor: pointer; margin-left: -0.4em; margin-top: 0.8em; opacity: 0.8');

        const tooltip_id = `error_tooltip_${snippet_id}`
        const off_tooltip = $(`<tbody id="${tooltip_id}"
                    style="display: none" 
                    class="offense_tooltip offense_tooltip_${snippet_id}"></tbody>`)
        const line_error = $(`<tr><td>${data.message}</td>

                                </tr>`)
        off_tooltip.append(line_error)
        off_tooltip.appendTo('body');
        marker.setAttribute('title', data.message);

        cm.setGutterMarker(data.line, gutter_id, marker);
    }
}


export function handle_snippet_syntax_error(cm: CodeMirror.Editor, snippet: Snippet) {
    return watch(() => snippet.props.syntax_error, () => {
       cm.clearGutter(gutter_id);
       cm.getDoc().getAllMarks().forEach(mark => {
           if (mark.className == "codemirror-syntax-error") mark.clear()
       })
       nextTick(() => {
           handle_syntax_error(cm, _.cloneDeep(snippet.props.syntax_error), snippet.key())
       })
    }, { immediate: true, deep: true })
}
