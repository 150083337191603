<template>
  <template v-if="show_table">
    <SettingsItem
        id="all_users"
        :title="setting_items.superadmin.all_users.title">
      <div class="row"
           style="display: flex;">
        <div class="col s8 inline-flex"
             style="display: flex;">
          <Button
              v-if="!is_viewer_role && !is_user_role"
              id="new_user_button"
              text="New User"
              @click="new_user"
          />
          <Button
              v-if="deletion_markers.marked().length > 0 && !is_viewer_role && !is_user_role"
              id="delete_users_button"
              text="Delete"
              color_class="red"
              @click="delete_users"
          />
        </div>
        <div class="col s4">
          <Input
              id="users_table_filter"
              v-model="filter"
              placeholder="Filter"
              :throttle_time="200"
          />
        </div>
      </div>
      <UsersTable
          id="all_users_table"
          :users="users"
          :filter="filter"
          :project="project"
          :is_viewer_role="is_viewer_role"
          :is_user_role="is_user_role"
          :deletion_markers="deletion_markers"
          @user-click="(user) => show_user(user)"
      />

      <div v-if="show_new_user">
        <UserNew
            :project="project"
            :is_viewer_role="is_viewer_role"
            :is_user_role="is_user_role"
            @exit="show_new_user = false"/>
      </div>
    </SettingsItem>
  </template>
  <template v-else>
    <UserEdit :user="edit_user"
              :navigated_from_table="true"
              :is_viewer_role="is_viewer_role"
              :is_user_role="is_user_role"
              :form_validator="form_validator.register_child_form(`user_edit`)"
              @exit="show_table=true"
              @setting-item-mounted="(component) => $emit('setting-item-mounted', component)"
              @setting-item-unmounted="(component) => $emit('setting-item-unmounted', component)"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Button from "../../../testa/Button.vue";
import Input from "../../../testa/Input.vue";
import SettingsItem from "../../SettingsItem.vue";
import { User } from "../../../../vue_record/models/user";
import { PropType } from "vue";
import { Project } from "../../../../vue_record/models/project";
import UserEdit from "./UserEdit.vue";
import UserNew from "./UserNew.vue";
import UsersTable from "./UsersTable.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { RecordMarker } from "../../../../vue_record/base/utils/record_marker";


export default defineComponent({
    components: {
        UserNew,
        UserEdit,
        UsersTable,
        SettingsItem,
        Input,
        Button,
    },
    // <editor-fold desc="PROPS">
    props: {
        project: {
            type: Object as PropType<Project>,
            required: false,
            default: null,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            filter: "",
            show_table: true,
            show_new_user: false,
            edit_user: null as User,
            deletion_markers: new RecordMarker([]),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        setting_items() {
            return setting_items
        },
        users() {
            return this.project == null ? User.toArray() : this.project.users.toArray()
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        new_user() {
          this.show_new_user = true;
        },
        show_user(user: User) {
            this.show_table = false
            this.edit_user = user
        },
        delete_users() {
            User.delete(this.deletion_markers.marked().map(ff => ff.props.id))
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
