<template>
  <div>
    <Modal id="spinner_settings_modal"
           :show_cancel="true"
           confirm_text="Save"
           :cancel_on_escape="true"
           :focus_last_active_on_unmount="true"
           :confirm_action="save_spinner_settings"
           @cancel="$emit('exit')"
    >
      <template #header>
        <div class="title">
          Spinner Config
        </div>
      </template>

      <template #body="modal">
        <div class="container">
          <div class="row">
            <div class="col s12">
              <Input
                  id="spinner_setting_dj_tag_input"
                  v-model="update_spinner_setting.props.dj_tag"
                  label="DJ tag"
                  :validator="form_validator.register('dj_tag', SpinnerSetting.field_validators.dj_tag)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s12">
              <Input
                  id="spinner_setting_sikuli_tag_input"
                  v-model="update_spinner_setting.props.sikuli_tag"
                  label="Sikuli tag"
                  :validator="form_validator.register('sikuli_tag', SpinnerSetting.field_validators.sikuli_tag)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s12">
              <Input
                  id="spinner_setting_workers_input"
                  v-model="update_spinner_setting.props.workers"
                  label="Number of workers"
                  type="number"
                  :validator="form_validator.register('workers', SpinnerSetting.field_validators.workers)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s3">
              <Input
                  id="spinner_setting_memory_request_input"
                  v-model="update_spinner_setting.props.memory_request"
                  label="Memory request"
                  :validator="form_validator.register('memory_request', SpinnerSetting.field_validators.memory_request)"
              />
            </div>
            <div class="col s3">
              <Input
                  id="spinner_setting_memory_limit_input"
                  v-model="update_spinner_setting.props.memory_limit"
                  label="Memory limit"
                  :validator="form_validator.register('memory_limit', SpinnerSetting.field_validators.memory_limit)"
              />
            </div>
            <div class="col s3">
              <Input
                  id="spinner_setting_cpu_request_input"
                  v-model="update_spinner_setting.props.cpu_request"
                  label="CPU request"
                  :validator="form_validator.register('cpu_request', SpinnerSetting.field_validators.cpu_request)"
              />
            </div>
            <div class="col s3">
              <Input
                  id="spinner_setting_cpu_limit_input"
                  v-model="update_spinner_setting.props.cpu_limit"
                  label="CPU limit"
                  :validator="form_validator.register('cpu_limit', SpinnerSetting.field_validators.cpu_limit)"
                  @enter="modal.confirm()"
              />
            </div>
          </div>
        </div>

      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import Modal from "../../../testa/Modal.vue";
import Input from "../../../testa/Input.vue";
import { SpinnerSetting } from "../../../../vue_record/models/spinner_setting";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        spinner_setting: {
            type: Object as PropType<SpinnerSetting>,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['exit'],
    // <editor-fold desc="DATA">
    data() {
        return {
            update_spinner_setting: this.spinner_setting.stage(),
            form_validator: new FormValidator(),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        SpinnerSetting() {
            return SpinnerSetting
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save_spinner_settings() {
            return SpinnerSetting.ClientClass.update(this.update_spinner_setting.props).then(() => this.$emit("exit"))
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  max-width: 500px;
}
</style>
