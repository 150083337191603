import { LogFileFolder } from "../../models/non_db/log_file_folder";

import { LogFileScope } from "../../scopes/non_db/log_file_scope";
import { LogFileFolderScope } from "../../scopes/non_db/log_file_folder_scope";
import { LogFile } from "../../models/non_db/log_file";

declare module "../../models/non_db/log_file_folder" {
    interface LogFileFolder {
        log_file_folder: LogFileFolder

        log_files: LogFileScope
        log_file_folders: LogFileFolderScope
    }
}

LogFileFolder.belongs_to("log_file_folder", LogFileFolder, "log_file_folder_path")

LogFileFolder.has_many("log_files", LogFile, "log_file_folder_path")
LogFileFolder.has_many("log_file_folders", LogFileFolder, "log_file_folder_path")
