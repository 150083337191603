import { computed } from "../vue/computed";
import { reactive } from "vue";
import { Consoler } from "./consoler";
import { safe_stringify } from "../generic/safe_stringify";

type StoreObject = {
    value: any | any[]
}


const console = new Consoler("warn")

/**
 * Storager is a wrapper around localStorage API. It enables:
 * - storing non-string values.
 * - default value if key is not found
 * - same key item under different scopes
 * - easy storage cleaning
 *
 * How it works:
 * under global scope we create a user scope so each user has his own stored items under the same key.
 * User scope can be further scoped down to project and project version
 *
 * Therefore if we have the following scope global -> user -> project
 * and we store a key "show_sidebar" then that key will be stored in the specified scope.
 * Meaning the same user can have different value for each project.
 *
 * This is done by prefixing the y "show_sidebar" key with "user[user_id]__project[project_id]=="
 */
const keep_key = ".keep"
export class Storager {
    static global: Storager = null
    static web_type: Storager = null

    readonly scope_name: string;
    readonly scope_key: string;
    readonly parent: Storager;

    constructor(scope_name: string, scope_key: string, parent: Storager) {
        this.scope_name = scope_name
        this.scope_key = scope_key
        this.parent = parent
    }

    new_scope(scope_name: string, scope_key: string | number) {
        scope_key = this._key_to_string(scope_key)
        console.log(`Creating new scope with name=${scope_name} and key=${scope_key}`,
            `Under parent scope with name=${this.scope_name} and key=${this.scope_key}`)
        return new Storager(scope_name, scope_key, this)
    }

    set(key: string, value: any | any[]) {
        // first check if the keep item is still present.
        // if it is not it means the user cleared localStorage -> then do not update storage until next page reload
        if (key != keep_key && Storager.global.get(keep_key) == null) {
            console.warn("LocalStorage is cleared. Further items are not saved until page is reloaded")
        } else {
            const prefixed_key = this._prefix_it(key)
            console.debug("Setting item under key: ", prefixed_key, "value: ", value)

            // wrap the value in a stringified object so that the value type is preserved
            localStorage.setItem(prefixed_key, this._to_store_value(value))
        }
    }

    get<T = any>(key: string, default_value: T = null): T {
        const prefixed_key = this._prefix_it(key)
        const value = localStorage.getItem(prefixed_key)
        if (key != keep_key) {
            console.debug(`accessing key: `, prefixed_key,
                `got value: `, value, `default_value: `, default_value)
        }
        if (value == null) return default_value
        // all stored items are stringified to preserve type. parse it and return the result
        const store_object = JSON.parse(value) as StoreObject
        return store_object.value
    }

    remove(key: string) {
        const prefixed_key = this._prefix_it(key)
        console.debug("removing key ", prefixed_key)
        localStorage.removeItem(prefixed_key)
    }

    /** @note: clears all storage, not just this scope */
    clear(reload_page = false) {
        console.log("clearing storage")
        localStorage.clear()
        if (reload_page) {
            if (confirm("LocalStorage is cleared. Reload page to take full effect?")) {
                console.log("Reloading page")
                location.reload()
            }
        }
    }

    // <editor-fold desc="PROTECTED">
    _key_to_string(val: any): string {
        if (val == null) return ""
        return val.toString()
    }

    _full_key_prefix(): string {
        if (this.parent == null) {
            if (this.scope_name == "" && this.scope_key == "") return ""
            else return `${this.scope_name}[${this.scope_key}]`
        } else {
            let parent_prefix = `${this.parent._full_key_prefix()}__`
            if (parent_prefix == "__") parent_prefix = ""
            return `${parent_prefix}${this.scope_name}[${this.scope_key}]`
        }
    }

    _prefix_it(key: string) {
        return `${this._full_key_prefix()}==${key}`
    }

    _to_store_value(value: any | any[]): string {
        return safe_stringify({ value })
    }
    // </editor-fold>
}

Storager.global = new Storager("", "", null)
Storager.web_type = Storager.global.new_scope("web_type", web.type)

// put 1 item on page load.
// then if user does localStorage.clear -> that will clear this item
// if this item exists, then further storage saving is enabled
// we do this to prevent saving items on page unload when user wants to clear local storage and refresh the page
Storager.global.set(keep_key, ".")


declare global {
    interface Window {
        Storager: typeof Storager
    }

    interface Current {
        storagers: {
            user: Storager
            user_web_type: Storager
            project: Storager
            project_web_type: Storager
            project_version: Storager
            project_version_web_type: Storager
        }
    }
}
window.Storager = Storager

// @ts-ignore
current.storagers = reactive({})
current.storagers.user = computed(() => {
    if (current.user == null) return Storager.global
    else return current.user.storager
})

current.storagers.user_web_type = computed(() => {
    if (current.user == null) return Storager.web_type
    else return current.user.web_type_storager
})

current.storagers.project = computed(() => {
    if (current.project == null) return Storager.global
    else return current.project.storager
})

current.storagers.project_web_type = computed(() => {
    if (current.project == null) return Storager.web_type
    else return current.project.web_type_storager
})

current.storagers.project_version = computed(() => {
    if (current.project_version == null) return Storager.global
    else return current.project_version.storager
})

current.storagers.project_version_web_type = computed(() => {
    if (current.project_version == null) return Storager.web_type
    else return current.project_version.web_type_storager
})
