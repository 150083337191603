// action of the same type should be batched
// -- such as (mouse up, down, move), (wheel), (keydown, keyup)
// -- once mouse is down, the coordinates should be collected every 100ms
// -- 200ms after blur, mouse leave or mouse up events if there are no other events, the chain is finished
// -- special actions (right click -> navigate back), (middle click -> navigate to home)


import { throttle } from "lodash";
import { Phone } from "../../../vue_record/models/phone";
import { Phone as ScenarioSettingPhone } from "../../../vue_record/models/scenario_setting/phone"
import { AscMouseAction } from "./asc_mouse_action";
import { AscSpecialAction } from "./asc_special_action";
import { AscWheelAction } from "./asc_wheel_action";
import { AscKeyboardAction } from "./asc_keyboard_action";
import { Consoler } from "../../api_wrappers/consoler";
import { computed } from "../../vue/computed";
import { position_relative_to_element } from "../../dom/position_relative_to_element";
import { reactive } from "vue";
import { ScenarioSetting } from "../../../vue_record/models/scenario_setting";

export type ASCAction = AscMouseAction | AscSpecialAction | AscWheelAction | AscKeyboardAction

const console = new Consoler("debug")

export class AppiumScreenControl {
    perform_timeout: NodeJS.Timeout = null;
    backend_ws_channel: string
    action: ASCAction
    scenario_setting_phone: ScenarioSettingPhone
    scenario_setting: ScenarioSetting
    phone: Phone
    id: string
    initialized = false
    $container: JQuery
    horizontal_scale = 1
    vertical_scale = 1

    // <editor-fold desc="COMPUTED">
    phone_vertical_scale = 1
    phone_horizontal_scale = 1
    // </editor-fold>


    throttled_mouse_move: (e: MouseEvent, time: Date) => void

    constructor(backend_ws_channel: string, scenario_setting_phone: ScenarioSettingPhone) {
        this.backend_ws_channel = backend_ws_channel;
        this.action = null;
        this.scenario_setting_phone = scenario_setting_phone
        this.scenario_setting = scenario_setting_phone.scenario_setting
        this.phone = scenario_setting_phone.phone_project.phone
        this.throttled_mouse_move = throttle(this.on_mouse_move, 100);
    }

    static new<T extends typeof AppiumScreenControl = typeof AppiumScreenControl>(this: T, backend_ws_channel: string, scenario_setting_phone: ScenarioSettingPhone): InstanceType<T> {
        let thiz
        global_effect_scope.run(() => {
            thiz = reactive(new this(backend_ws_channel, scenario_setting_phone)) as InstanceType<T>
            thiz.init()
        })
        return thiz
    }

    init() {
        if (this.initialized) return;
        this.phone_vertical_scale = computed(() => {
            return (this.scenario_setting_phone.props.downscaled_height / this.phone.props.display_height) * this.vertical_scale;
        })

        this.phone_horizontal_scale = computed(() => {
            return (this.scenario_setting_phone.props.downscaled_width / this.phone.props.display_width) * this.horizontal_scale;
        })

        this.initialized = true;
    }

    schedule_perform(timeout = 500) {
        clearTimeout(this.perform_timeout);
        if (this.action != null) {
            this.perform_timeout = setTimeout(() => this.perform_action(), timeout)
        }
    }

    on_mousedown(e: MouseEvent) {
        if (this.action != null) this.perform_action();
        switch (e.button) {
            case 0:
                this.action = new AscMouseAction(this, this.retrieve_coordinates(e));
                break
            case 1:
                this.action = new AscSpecialAction(this, Enum.Play.ScreenAction.HOME);
                this.perform_action();
                break;
            case 2:
                this.action = new AscSpecialAction(this, Enum.Play.ScreenAction.BACK);
                this.perform_action();
                break;
        }
    }

    on_mouseenter(_e: MouseEvent) {
        clearTimeout(this.perform_timeout)
    }

    on_mouseup(e: MouseEvent) {
        if (this.action == null) return;
        if (this.action.type != Enum.Play.ScreenAction.MOUSE) return;
        (this.action as AscMouseAction).add_up(this.retrieve_coordinates(e));
        this.perform_action();
    }

    on_wheel(e: WheelEvent) {
        if (this.action != null && this.action.type != Enum.Play.ScreenAction.WHEEL) this.perform_action();
        let delta_x = 0;
        let delta_y = 0;
        if (e.wheelDeltaX == 0) {
            if (e.shiftKey) {
                // noinspection JSSuspiciousNameCombination
                delta_x = e.wheelDeltaY;
            } else {
                delta_y = e.wheelDeltaY;
            }
        } else {
            delta_y = e.wheelDeltaY;
            delta_x = e.wheelDeltaX;
        }
        const coords = this.retrieve_coordinates(e);
        if (this.action == null) this.action = new AscWheelAction(this, delta_x, delta_y, coords);
        else (this.action as AscWheelAction).add_wheel_action(AscWheelAction.generate_action_object(delta_x, delta_y, coords));
        this.schedule_perform(500);
    }

    on_keydown(e: KeyboardEvent) {
        this.handle_key_event(e, true)
    }

    perform_action() {
        clearTimeout(this.perform_timeout);
        if (this.action != null) {
            console.log("performing:", this.action?.log(), "vertical_scale:", this.vertical_scale,
                "horizontal_scale:", this.horizontal_scale);
            this.action.perform();
        }
        this.action = null;
    }


    on_mouse_move(e: MouseEvent, time: Date) {
        if (this.action == null) return;
        if (this.action.type != Enum.Play.ScreenAction.MOUSE) return;

        (this.action as AscMouseAction).add_move(this.retrieve_coordinates(e), time)
    }

    handle_key_event(event: KeyboardEvent, down: boolean) {
        // @ts-ignore
        if (AscKeyboardAction.no_handle_key[event.keyCode] != null) return;
        if (this.action != null && this.action.type != Enum.Play.ScreenAction.KEYBOARD) this.perform_action();
        if (this.action == null) this.action = new AscKeyboardAction(this, event.keyCode, event.shiftKey, event.getModifierState("CapsLock"));
        else (this.action as AscKeyboardAction).add_key(event.keyCode, down, event.shiftKey, event.getModifierState("CapsLock"))
        this.schedule_perform(100);
    }

    retrieve_coordinates(event: MouseEvent) {
        const coords = this.extract_coords_from_event(event)

        const scaled_coords = { x: coords.x, y: coords.y }
        scaled_coords.x = coords.x / this.phone_horizontal_scale / this.phone.props.pixel_ratio
        scaled_coords.y = coords.y / this.phone_vertical_scale / this.phone.props.pixel_ratio
        console.debug("Retrieved relative coordinates (unscaled: ", coords, ") (scaled: ", scaled_coords, ")");
        return scaled_coords
    }

    extract_coords_from_event(event: MouseEvent) {
        let x, y;
        if (event.pageX == 0 && event.pageY == 0) {
            const $target = $(event.target)
            const offset = $target.offset()
            x = offset.left + ($target.width() / 2)
            y = offset.top + ($target.height() / 2)
        } else {
            x = event.pageX;
            y = event.pageY;
        }

        return position_relative_to_element(this.$container[0], x, y)
    }

    set_vertical_scale(vertical_scale: number) {
        this.vertical_scale = vertical_scale
    }

    set_horizontal_scale(horizontal_scale: number) {
        this.horizontal_scale = horizontal_scale
    }

    set_container(container: HTMLElement) {
        this.$container = $(container)
    }
}
