import CodeMirror from "codemirror";

export function tab(cm: CodeMirror.Editor) {
    if (cm.somethingSelected()) {
        cm.indentSelection("add");
    } else {
        const replace = cm.getOption("indentWithTabs") ? "\t" : Array(cm.getOption("indentUnit") + 1).join(" ")
        cm.replaceSelection(replace, "end", "+input");
    }
}
