import { ProjectVersion } from "../models/project_version";
import { ImageFolder } from "../models/image_folder";
import { Image } from "../models/image";


declare module "../models/image" {
    interface Image {
        project_version: ProjectVersion
        image_folder: ImageFolder
    }
}

Image.belongs_to("project_version", ProjectVersion, "project_version_id")
Image.belongs_to("image_folder", ImageFolder, "image_folder_path")
