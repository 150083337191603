import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "row",
  style: {"display":"flex"}
}
const _hoisted_2 = {
  class: "col s8 inline-flex",
  style: {"display":"flex"}
}
const _hoisted_3 = { class: "col s4" }
const _hoisted_4 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_Input = _resolveComponent("Input")
  const _component_UsersTable = _resolveComponent("UsersTable")
  const _component_UserNew = _resolveComponent("UserNew")
  const _component_SettingsItem = _resolveComponent("SettingsItem")
  const _component_UserEdit = _resolveComponent("UserEdit")

  return (_ctx.show_table)
    ? (_openBlock(), _createBlock(_component_SettingsItem, {
        key: 0,
        id: "all_users",
        title: _ctx.setting_items.superadmin.all_users.title
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.is_viewer_role && !_ctx.is_user_role)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    id: "new_user_button",
                    text: "New User",
                    onClick: _ctx.new_user
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true),
              (_ctx.deletion_markers.marked().length > 0 && !_ctx.is_viewer_role && !_ctx.is_user_role)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    id: "delete_users_button",
                    text: "Delete",
                    color_class: "red",
                    onClick: _ctx.delete_users
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Input, {
                id: "users_table_filter",
                modelValue: _ctx.filter,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.filter) = $event)),
                placeholder: "Filter",
                throttle_time: 200
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _createVNode(_component_UsersTable, {
            id: "all_users_table",
            users: _ctx.users,
            filter: _ctx.filter,
            project: _ctx.project,
            is_viewer_role: _ctx.is_viewer_role,
            is_user_role: _ctx.is_user_role,
            deletion_markers: _ctx.deletion_markers,
            onUserClick: _cache[1] || (_cache[1] = (user) => _ctx.show_user(user))
          }, null, 8 /* PROPS */, ["users", "filter", "project", "is_viewer_role", "is_user_role", "deletion_markers"]),
          (_ctx.show_new_user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_UserNew, {
                  project: _ctx.project,
                  is_viewer_role: _ctx.is_viewer_role,
                  is_user_role: _ctx.is_user_role,
                  onExit: _cache[2] || (_cache[2] = $event => (_ctx.show_new_user = false))
                }, null, 8 /* PROPS */, ["project", "is_viewer_role", "is_user_role"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["title"]))
    : (_openBlock(), _createBlock(_component_UserEdit, {
        key: 1,
        user: _ctx.edit_user,
        navigated_from_table: true,
        is_viewer_role: _ctx.is_viewer_role,
        is_user_role: _ctx.is_user_role,
        form_validator: _ctx.form_validator.register_child_form(`user_edit`),
        onExit: _cache[3] || (_cache[3] = $event => (_ctx.show_table=true)),
        onSettingItemMounted: _cache[4] || (_cache[4] = (component) => _ctx.$emit('setting-item-mounted', component)),
        onSettingItemUnmounted: _cache[5] || (_cache[5] = (component) => _ctx.$emit('setting-item-unmounted', component))
      }, null, 8 /* PROPS */, ["user", "is_viewer_role", "is_user_role", "form_validator"]))
}