export {}

export class TestaError extends Error {
    readonly user_message: string
    readonly advice: string

    constructor(message: string = null, user_message: string = null, advice: string = null) {
        super(message);
        this.message = message
        this.user_message = user_message
        this.advice = advice
    }

    show_toaster(overrides: ToastrOptions = {}) {
        console.log("showing: ", this.advice, this.user_message, overrides);
        toastr.error(this.advice, this.user_message, overrides)
    }
}

declare global {
    // @ts-ignore
    let TestaError: typeof TestaError
    interface Window {
        TestaError: typeof TestaError
    }
}

window.TestaError = TestaError
