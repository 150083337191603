import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { PlaySandbox } from "../../models/play/play_sandbox";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PlaySandboxClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlaySandbox
    declare record: PlaySandbox

}
