import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dbcd5334"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "tabindex"]
const _hoisted_2 = {
  key: 1,
  class: "loading-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading")

  return (_openBlock(), _createElementBlock("button", {
    id: _ctx.id,
    ref: "button",
    tabindex: _ctx.tab_index,
    class: _normalizeClass(["vue-button no-default-unfocus", _ctx.clazz]),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.on_click && _ctx.on_click(...args)), ["stop"])),
    onKeydown: _cache[1] || (_cache[1] = (...args) => (_ctx.click_on_enter && _ctx.click_on_enter(...args)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1 /* TEXT */),
    (_ctx.icon_class != null)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(_ctx.icon_class)
        }, null, 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.action_in_progress)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_Loading, {
            size: 0.75,
            fadein: true,
            margin: "0",
            color: "white"
          })
        ]))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
}