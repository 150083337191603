import { EnumPlayStatus } from "../../auto_generated/enums";
import { LoadingData } from "select2";
import { get_css_var } from "../generic/get_css_var";
import { DataFormat } from "select2";
import { GroupedDataFormat } from "select2";
import { select2_option_with_icon } from "../dom/select2_option_with_icon";

export type PlayStatusGroupId = "in_progress" | "success" |
    "warned" | "failed" |
    "terminated" | "not_started" |
    "started" | "finished" | "skipped" | "not_finished"

export type PlayStatusGroup = {
    id: PlayStatusGroupId,
    label: string,
    play_statuses: EnumPlayStatus[],
    icon_class?: string
    icon_color?: () => string
}

export class PlayStatus {
    static readonly filter_groups: PlayStatusGroup[] = [
        {
            id: "in_progress",
            label: "In Progress",
            play_statuses: [
                Enum.Play.Status.IN_PROGRESS,
                Enum.Play.Status.DEBUGGING,
                Enum.Play.Status.BOOTING,
            ],
            icon_class: "fa-solid fa-circle-notch",
            icon_color: () => get_css_var("--play-status-in-progress")
        },
        {
            id: "success",
            label: "Success",
            play_statuses: [
                Enum.Play.Status.SUCCESS
            ],
            icon_class: "fa-solid fa-check",
            icon_color: () => get_css_var("--play-status-success")
        },
        {
            id: "warned",
            label: "Warned",
            play_statuses: [
                Enum.Play.Status.WARNED,
                Enum.Play.Status.SKIPPED,
            ],
            icon_class: "fa-solid fa-check",
            icon_color: () => get_css_var("--play-status-warned")
        },
        {
            id: "failed",
            label: "Failed",
            play_statuses: [
                Enum.Play.Status.FAILED
            ],
            icon_class: "fa-solid fa-minus",
            icon_color: () => get_css_var("--play-status-failed")
        },
        {
            id: "terminated",
            label: "Terminated",
            play_statuses: [
                Enum.Play.Status.TERMINATED,
                Enum.Play.Status.DEBUGGER_TIMEOUT,
            ],
            icon_class: "fa-solid fa-times",
            icon_color: () => get_css_var("--play-status-terminated")
        },
        {
            id: "not_started",
            label: "Not Started",
            play_statuses: [
                Enum.Play.Status.FINISHED_NOT_STARTED,
                Enum.Play.Status.NOT_STARTED,
                Enum.Play.Status.PENDING,
                Enum.Play.Status.SCHEDULED
            ],
            icon_class: "fa-solid fa-hourglass-start",
            icon_color: () => get_css_var("--play-status-not-started")
        },
        {
            id: "started",
            label: "Started",
            play_statuses: Enum.Play.Status.started_statuses
        },
        {
            id: "finished",
            label: "Finished",
            play_statuses: Enum.Play.Status.finished_statuses
        },
    ]

    static readonly replay_groups: PlayStatusGroup[] = [
        {
            id: "success",
            label: "Successful",
            play_statuses: [Enum.Play.Status.SUCCESS],
            icon_class: "fa-solid fa-check",
            icon_color: () => get_css_var("--play-status-success")
        },
        {
            id: "warned",
            label: "Warned",
            play_statuses: [Enum.Play.Status.WARNED],
            icon_class: "fa-solid fa-check",
            icon_color: () => get_css_var("--play-status-warned")
        },
        {
            id: "skipped",
            label: "Skipped",
            play_statuses: [Enum.Play.Status.SKIPPED],
            icon_class: "fa-solid fa-minus",
            icon_color: () => get_css_var("--play-status-skipped")
        },
        {
            id: "failed",
            label: "Failed",
            play_statuses: [
                Enum.Play.Status.FAILED,
                Enum.Play.Status.ERROR,
                Enum.Play.Status.TERMINATED,
                Enum.Play.Status.DEBUGGER_TIMEOUT,
            ],
            icon_class: "fa-solid fa-minus",
            icon_color: () => get_css_var("--play-status-failed")
        },
        {
            id: "not_finished",
            label: "Not Finished",
            play_statuses: Enum.Play.Status.values.filter(s => Enum.Play.Status.finished_statuses.some(fs => fs == s)),
            icon_class: "fa-solid fa-hourglass-start",
            icon_color: () => get_css_var("--play-status-not-started")
        }
    ]

    static get_filter_groups(play_status: EnumPlayStatus) {
        return this.filter_groups.filter(group => {
            return group.play_statuses.some(s => s == play_status)
        })
    }

    static get_statuses_for_filter_group(play_status_group_id: PlayStatusGroupId) {
        return this.filter_groups.find(group => group.id == play_status_group_id)?.play_statuses
    }

    static filter_group_option_template(data: LoadingData | GroupedDataFormat | DataFormat) {
        if (data.id == null) return data.text

        const play_status_group = this.replay_groups.find(fg => fg.id == data.id)
        return select2_option_with_icon(
            data.text,
            play_status_group?.icon_class,
            play_status_group?.icon_color()
        )
    }

    static replay_group_option_template(data: LoadingData | GroupedDataFormat | DataFormat) {
        if (data.id == null) return data.text

        const play_status_group = this.replay_groups.find(fg => fg.id == data.id)
        return select2_option_with_icon(
            data.text,
            play_status_group?.icon_class,
            play_status_group?.icon_color()
        )
    }

    static icon_class(play_status: EnumPlayStatus) {
        switch (play_status) {
            case Enum.Play.Status.PENDING:
                return "fa-regular fa-clock"
            case Enum.Play.Status.SCHEDULED:
                return "fa-regular fa-clock"
            case Enum.Play.Status.FAILED:
            case Enum.Play.Status.DEBUGGER_TIMEOUT:
                return "fa-solid fa-minus"
            case Enum.Play.Status.TERMINATED:
            case Enum.Play.Status.ERROR:
                return "fa-solid fa-exclamation-circle"
            case Enum.Play.Status.SUCCESS:
                return "fa-solid fa-check"
            case Enum.Play.Status.IN_PROGRESS:
                return "fa-solid fa-circle-notch fa-spin"
            case Enum.Play.Status.DEBUGGING:
                return "fa-solid fa-pause"
            case Enum.Play.Status.SKIPPED:
                return "fa-solid fa-minus"
            case Enum.Play.Status.WARNED:
                return "fa-solid fa-check"
            case Enum.Play.Status.FINISHED_NOT_STARTED:
            case Enum.Play.Status.NOT_STARTED:
            case Enum.Play.Status.BOOTING:
                return "fa-solid fa-hourglass-start"
            default:
                throw new Error(`No icon for play status: ${play_status}`)
        }
    }

    static color(play_status: EnumPlayStatus) {
        switch (play_status) {
            case Enum.Play.Status.PENDING:
                return get_css_var("--play-status-pending")
            case Enum.Play.Status.SCHEDULED:
                return get_css_var("--play-status-scheduled")
            case Enum.Play.Status.FAILED:
            case Enum.Play.Status.DEBUGGER_TIMEOUT:
                return get_css_var("--play-status-failed")
            case Enum.Play.Status.TERMINATED:
            case Enum.Play.Status.ERROR:
                return get_css_var("--play-status-terminated")
            case Enum.Play.Status.SUCCESS:
                return get_css_var("--play-status-success")
            case Enum.Play.Status.IN_PROGRESS:
                return get_css_var("--play-status-in-progress")
            case Enum.Play.Status.DEBUGGING:
                return get_css_var("--play-status-debugging")
            case Enum.Play.Status.SKIPPED:
                return get_css_var("--play-status-skipped")
            case Enum.Play.Status.WARNED:
                return get_css_var("--play-status-warned")
            case Enum.Play.Status.FINISHED_NOT_STARTED:
            case Enum.Play.Status.NOT_STARTED:
            case Enum.Play.Status.BOOTING:
                return get_css_var("--play-status-not-started")
            default:
                throw new Error(`No icon for play status: ${play_status}`)
        }
    }

    static label(play_status: EnumPlayStatus) {
        switch (play_status) {
            case Enum.Play.Status.PENDING:
                return "Pending"
            case Enum.Play.Status.SCHEDULED:
                return "Scheduled"
            case Enum.Play.Status.FAILED:
                return "Failed"
            case Enum.Play.Status.TERMINATED:
            case Enum.Play.Status.DEBUGGER_TIMEOUT:
                return "Terminated"
            case Enum.Play.Status.ERROR:
                return "Error"
            case Enum.Play.Status.SUCCESS:
                return "Success"
            case Enum.Play.Status.IN_PROGRESS:
                return "In Progress"
            case Enum.Play.Status.DEBUGGING:
                return "Debugging"
            case Enum.Play.Status.SKIPPED:
                return "Skipped"
            case Enum.Play.Status.WARNED:
                return "Warned"
            case Enum.Play.Status.FINISHED_NOT_STARTED:
                return "Not Executing"
            case Enum.Play.Status.NOT_STARTED:
                return "Not Started"
            case Enum.Play.Status.BOOTING:
                return "Booting"
            default:
                throw new Error(`No label for play status: ${play_status}`)
        }
    }
}
