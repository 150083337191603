import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-211e514e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InspectorElement = _resolveComponent("InspectorElement", true)

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.inspector_element.is_hidden && _ctx.inspector_element.in_viewport)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: _ctx.inspector_element.inspector_id,
          ref: "element",
          class: _normalizeClass(["element", _ctx.klass]),
          style: _normalizeStyle(_ctx.style),
          onClick: _cache[0] || (_cache[0] = _withModifiers((e) => _ctx.inspector_element.on_click(e), ["stop"])),
          onContextmenu: _cache[1] || (_cache[1] = (...args) => (_ctx.on_contextmenu && _ctx.on_contextmenu(...args))),
          onMouseenter: _cache[2] || (_cache[2] = _withModifiers((e) => _ctx.inspector_element.on_hover(e, 'inspector'), ["stop"]))
        }, null, 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    (!_ctx.inspector_element.is_hidden)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.inspector_element.children, (child_element) => {
          return (_openBlock(), _createBlock(_component_InspectorElement, {
            key: child_element.id,
            inspector_element: child_element
          }, null, 8 /* PROPS */, ["inspector_element"]))
        }), 128 /* KEYED_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}