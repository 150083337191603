import { Phone } from "../../models/scenario_setting/phone";
import { App } from "../../models/app";
import { PhoneApp } from "../../models/scenario_setting/phone_app";

declare module "../../models/scenario_setting/phone_app" {

    interface PhoneApp {
        phone: Phone
        app: App
    }
}

PhoneApp.belongs_to('phone', Phone, 'scenario_setting_phone_id')
PhoneApp.belongs_to("app", App, "app_id")
