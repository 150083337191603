/**
 * According to this MDN documentation, an element's offsetParent property will return null whenever it,
 * or any of its parents, is hidden via the display style property. Just make sure that the element isn't fixed.
 * @param el
 */
export function is_visible(el: HTMLElement) {
    if (el == null) return false

    return !(el.offsetParent === null)
}
