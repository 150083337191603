import { VueRecordClient } from "../base/vue_record_client";
import { Project } from "../models/project";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { file_upload } from "../../helpers/generic/file_upload";
import { Editor } from "../../components/testa/editor/editor";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { Snippet } from "../models/snippet";
import { ProjectQueryProps } from "../models/project";
import { QuerifiedProjectVersionProps } from "../models/project_version";
import { CacheStoreValue } from "../base/vue_record_client";
import { Jobberman } from "../../helpers/jobberman/jobberman";

const console = new Consoler("warn")
export class ProjectClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof Project
    declare record: Project

    static create(project_props: ProjectQueryProps, project_version_props: QuerifiedProjectVersionProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/projects`,
                type: 'POST',
                data: JSON.stringify({
                    authenticity_token,
                    project: project_props,
                    project_version: project_version_props,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }

    update(project_props: ProjectQueryProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/projects/${this.key()}`,
                type: 'PATCH',
                data: JSON.stringify({
                    authenticity_token,
                    project: project_props,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }

    delete() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/projects/${this.key()}`,
                type: "DELETE",
                data: { authenticity_token },
                statusCode: ajax_status_codes,
                success: () => {
                    ConfirmDialogue.show({
                        title: "Deletion scheduled",
                        show_cancel: false,
                    })
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
            });
        })
    }

    // <editor-fold desc="IOS_SETUP">
    // <editor-fold desc="CSR">
    generate_apple_certificate_signing_request() {
        return $.fileDownload(
            `/settings/superadmin_settings/projects/${this.key()}/generate_apple_certificate_signing_request`
        )
    }

    download_apple_certificate_signing_request() {
        return $.fileDownload(
            `/settings/superadmin_settings/projects/${this.key()}/download_apple_certificate_signing_request`
        )
    }

    purge_apple_certificate_signing_request() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/projects/${this.key()}/purge_apple_certificate_signing_request`,
                type: "DELETE",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    upload_apple_certificate_signing_request() {
        return new Promise<void>((resolve, reject) => {
            file_upload({
                    url: `/settings/superadmin_settings/projects/${this.key()}/upload_apple_certificate_signing_request`,
                    progressall: (e, data) => {
                        progress_bar.go(data.loaded / data.total * 100);
                    },
                    fail: () => reject(new Error("Failed to upload csr")),
                    done: () => resolve(),
                },
                ".csr,.certificateSigningRequest,.certSigningRequest")
        });
    }

    upload_apple_certificate_signing_request_key() {
        return new Promise<void>((resolve, reject) => {
            file_upload({
                    url: `/settings/superadmin_settings/projects/${this.key()}/upload_apple_certificate_signing_request_key`,
                    progressall: (e, data) => {
                        progress_bar.go(data.loaded / data.total * 100);
                    },
                    fail: () => reject(new Error("Failed to upload csr key")),
                    done: () => resolve(),
                },
                ".key")
        });
    }
    // </editor-fold>

    // <editor-fold desc="CERT">
    upload_apple_certificate() {
        return new Promise<void>((resolve, reject) => {
            file_upload({
                    url: `/settings/superadmin_settings/projects/${this.key()}/upload_apple_certificate`,
                    progressall: (e, data) => {
                        progress_bar.go(data.loaded / data.total * 100);
                    },
                    fail: () => reject(new Error("Failed to upload certificate")),
                    done: () => resolve(),
                },
                ".cer")
        });
    }

    download_apple_certificate() {
        return $.fileDownload(
            `/settings/superadmin_settings/projects/${this.key()}/download_apple_certificate`
        )
    }
    download_apple_key() {
        return $.fileDownload(
            `/settings/superadmin_settings/projects/${this.key()}/download_apple_key`
        )
    }

    purge_apple_certificate() {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/superadmin_settings/projects/${this.key()}/purge_apple_certificate`,
                type: "DELETE",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }
    // </editor-fold>
    // </editor-fold>

    // <editor-fold desc="CODE LINT">
    /** code lint is not synced, therefore we have to load it manually */
    load_code_lint() {
        interface Response {
            rubocop_config: string
        }

        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/code_lint`,
                type: "GET",
                data: {
                    project_id: this.record.props.id,
                },
                statusCode: ajax_status_codes,
                success: (data: Response) => {
                    this.record.props.rubocop_config = data.rubocop_config
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                }
            })
        })
    }

    update_code_lint(code_lint: string) {
        return new Promise<void>((resolve, reject) => {
            const snippet_ids = Editor.get_tabs().filter(tab => tab.state.record instanceof Snippet)
                                      .map(s => s.state.record.key())

            $.ajax({
                url: `/settings/project_settings/code_lint`,
                type: "PUT",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    code_lint,
                    snippet_ids,
                    project_id: this.record.props.id
                }),
                success: () => {
                    this.record.props.rubocop_config = code_lint;
                    resolve()
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    disable_rubocop_rule(cop_name: string) {
        const snippet_ids = Editor.get_tabs().filter(tab => tab.state.record instanceof Snippet)
                                  .map(s => s.state.record.key())

        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/code_lint/disable_rule`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_id: this.record.props.id,
                    snippet_ids,
                    cop_name,
                    authenticity_token
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    rubocop_cop_yml(cop_name: string) {
        return new Promise<string>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/code_lint/cop_yml`,
                type: "GET",
                data: {
                    project_id: this.record.props.id,
                    cop_name,
                },
                success: (data: string) => resolve(data),
                error: (error) => reject(error),
                statusCode: ajax_status_codes,
            })
        })
    }

    code_lint_revert_to_default() {
        interface Response {
            rubocop_config: string
        }

        return new Promise<Response>((resolve, reject) => {
            const snippet_ids = Editor.get_tabs().filter(tab => tab.state.record instanceof Snippet)
                                      .map(s => s.state.record.key())
            $.ajax({
                url: `/settings/project_settings/code_lint/revert_to_default`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    project_id: this.record.props.id,
                    snippet_ids,
                    authenticity_token
                }),
                success: (data: Response) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    // </editor-fold>

    // <editor-fold desc="GEMFILE">
    load_gemfile() {
        interface Response {
            gemfile: string
        }

        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/gemfiles/${this.key()}`,
                type: 'GET',
                statusCode: ajax_status_codes,
                success: (data: Response) => {
                    resolve(data)
                },
                error: (error) => {
                    reject(error)
                },
            })
        })
    }

    install_gemfile(gemfile: string, job_message_callback: Jobberman.Callbacks.Log, done_cb: Jobberman.Callbacks.Done) {
        return Jobberman.promise({
            run: (jobberman_id, _resolve, reject) => {
                $.ajax({
                    url: `/settings/project_settings/gemfiles/${this.key()}/install/`,
                    type: 'POST',
                    data: {
                        jobberman_id,
                        gemfile,
                        authenticity_token,
                    },
                    error: (e) => {
                        reject(e)
                    },
                    statusCode: ajax_status_codes,
                })
            },
            log: job_message_callback,
            done: done_cb
        })
    }

    update_gemfile(gemfile: string) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/gemfiles/${this.key()}`,
                type: "PATCH",
                data: JSON.stringify({
                    gemfile,
                    authenticity_token,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                processData: false,
                contentType: 'application/json',
                statusCode: ajax_status_codes,
            })
        })
    }

    // </editor-fold>
}
