<template>
  <div class="row">

    <div class="col s3">
      <table class="details-table">
        <tbody>
          <template v-if="!web.is_main">
            <tr>
              <td>Project:</td>
              <td>
                <span>{{ play.project_version.project.name() }}</span>
              </td>
            </tr>
            <tr>
              <td>Version:</td>
              <td>{{ play.project_version.name() }}</td>
            </tr>
            <tr>
              <td>User:</td>
              <td>{{ play.user?.props?.email }}</td>
            </tr>
          </template>
          <tr>
            <td>Status:</td>
            <td>
              <span style="display: flex; flex-direction: row;">
                <PlayStatusIcon :play_status="play.props.status"/>
                <span
                    style="margin-left: 5px;"
                    :style="`color: ${PlayStatus.color(play.props.status)};`">
                  {{ play.props.status }}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td>Total tests:</td>
            <td>{{ main_play_scenarios_array.length }}</td>
          </tr>
          <tr
              v-if="not_started_main_play_scenarios.length > 0"
              :style="{color: PlayStatus.color(Enum.Play.Status.NOT_STARTED)}"
          >
            <td>Not Started:</td>
            <td>{{ not_started_main_play_scenarios.length }} ({{ not_started_percentage }}%)</td>
          </tr>
          <tr
              v-if="running_main_play_scenarios.length > 0"
              :style="{color: PlayStatus.color(Enum.Play.Status.DEBUGGING)}"
          >
            <td>Running:</td>
            <td>{{ running_main_play_scenarios.length }}</td>
          </tr>
          <tr
              v-if="warned_main_play_scenarios.length > 0"
              :style="{color: PlayStatus.color(Enum.Play.Status.WARNED)}"
          >
            <td>Warned:</td>
            <td>{{ warned_main_play_scenarios.length }} ({{ warned_percentage }}%)</td>
          </tr>
          <tr
              v-if="skipped_main_play_scenarios.length > 0"
              :style="{color: PlayStatus.color(Enum.Play.Status.SKIPPED)}"
          >
            <td>Skipped:</td>
            <td>{{ skipped_main_play_scenarios.length }} ({{ skipped_percentage }}%)</td>
          </tr>
          <tr
              :style="{color: PlayStatus.color(Enum.Play.Status.SUCCESS)}"
          >
            <td>Success:</td>
            <td>{{ success_main_play_scenarios.length }} ({{ success_percentage }}%)</td>
          </tr>
          <tr
              :style="{color: PlayStatus.color(Enum.Play.Status.FAILED)}"
          >
            <td>Failed:</td>
            <td>{{ faulty_main_play_scenarios.length }} ({{ faulty_percentage }}%)</td>
          </tr>
          <tr>
            <td>Finished:</td>
            <td>{{ finished_main_play_scenarios.length }} ({{ finished_percentage }}%)</td>
          </tr>
          <tr>
            <td>Progress:</td>
            <td>{{ (play.props.progress * 100).toFixed(2) }}%</td>
          </tr>
          <tr>
            <td>Duration:</td>
            <td>{{ pretty_duration(play.props.duration * 1000) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="right_side" ref="right_side" class="col s9">
      <div :id="pie_id"/>
      <div id="right_side">

      </div>
    </div>


  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import PlayStatusIcon from "../../other/PlayStatusIcon.vue";
import { Play } from "../../../../vue_record/models/play/play";
import { PlayScenarioScope } from "../../../../vue_record/scopes/play/play_scenario_scope";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";
import { generate_eid } from "../../../../helpers/generate/generate_eid";
import { PlayStatus } from "../../../../helpers/play/play_status";
import { pretty_duration } from "../../../../helpers/pretty/pretty_duration";


export default defineComponent({
    d3pies: {} as { [key: string]: d3pie.ID3PieClass },
    components: { PlayStatusIcon },
    // <editor-fold desc="PROPS">
    props: {
        play: {
            type: Object as PropType<Play>,
            required: true,
        },
        main_play_scenarios: {
            type: Object as PropType<PlayScenarioScope>,
            required: true
        },
        main_web_available: {
            type: Boolean,
            required: true
        },
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            id: generate_uuid(),
            pie_id: generate_eid(),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Enum() {
            return Enum
        },
        web() {
            return web
        },
        PlayStatus() {
            return PlayStatus
        },
        main_play_scenarios_array() {
            return this.main_play_scenarios.toArray();
        },
        finished_main_play_scenarios() {
            return this.main_play_scenarios_array.filter(mps => mps.props["finished?"]);
        },
        warned_main_play_scenarios() {
            return this.main_play_scenarios_array.filter(mps => mps.props.status == Enum.Play.Status.WARNED)
        },
        skipped_main_play_scenarios() {
            return this.main_play_scenarios_array.filter(mps => mps.props.status == Enum.Play.Status.SKIPPED)
        },
        success_main_play_scenarios() {
            return this.main_play_scenarios_array.filter(mps => mps.props.status == Enum.Play.Status.SUCCESS)
        },
        faulty_main_play_scenarios() {
            return this.main_play_scenarios_array.filter(mps => mps.props["faulty?"])
        },
        not_started_main_play_scenarios() {
            return this.main_play_scenarios_array.filter(mps => mps.props.status == Enum.Play.Status.NOT_STARTED)
        },
        running_main_play_scenarios() {
            return this.main_play_scenarios_array.filter(mps => mps.props.status == Enum.Play.Status.IN_PROGRESS ||
                mps.props.status == Enum.Play.Status.DEBUGGING ||
                mps.props.status == Enum.Play.Status.BOOTING ||
                mps.props.status == Enum.Play.Status.PENDING
            )
        },
        success_percentage() {
            if (this.main_play_scenarios_array.length == 0) return 0
            return ((this.success_main_play_scenarios.length / this.main_play_scenarios_array.length) * 100).toFixed(2)
        },
        finished_percentage() {
            if (this.main_play_scenarios_array.length == 0) return 0
            return ((this.finished_main_play_scenarios.length / this.main_play_scenarios_array.length) * 100).toFixed(2)
        },
        warned_percentage() {
            if (this.main_play_scenarios_array.length == 0) return 0
            return ((this.warned_main_play_scenarios.length / this.main_play_scenarios_array.length) * 100).toFixed(2)
        },
        skipped_percentage() {
            if (this.main_play_scenarios_array.length == 0) return 0
            return ((this.skipped_main_play_scenarios.length / this.main_play_scenarios_array.length) * 100).toFixed(2)
        },
        faulty_percentage() {
            if (this.main_play_scenarios_array.length == 0) return 0
            return ((this.faulty_main_play_scenarios.length / this.main_play_scenarios_array.length) * 100).toFixed(2)
        },
        not_started_percentage() {
            if (this.main_play_scenarios_array.length == 0) return 0
            return ((this.not_started_main_play_scenarios.length / this.main_play_scenarios_array.length) * 100).toFixed(2)
        },
        pie_data() {
            return [
                {
                    label: "Success",
                    value: this.success_main_play_scenarios.length,
                    color: PlayStatus.color(Enum.Play.Status.SUCCESS)
                },
                {
                    label: "Failed",
                    value: this.faulty_main_play_scenarios.length,
                    color: PlayStatus.color(Enum.Play.Status.FAILED)
                },
                {
                    label: "Skipped",
                    value: this.skipped_main_play_scenarios.length,
                    color: PlayStatus.color(Enum.Play.Status.SKIPPED)
                },
                {
                    label: "Warning",
                    value: this.warned_main_play_scenarios.length,
                    color: PlayStatus.color(Enum.Play.Status.WARNED)
                },
                {
                    label: "Not Started",
                    value: this.not_started_main_play_scenarios.length,
                    color: PlayStatus.color(Enum.Play.Status.NOT_STARTED)
                },
                {
                    label: "Running",
                    value: this.running_main_play_scenarios.length,
                    color: PlayStatus.color(Enum.Play.Status.DEBUGGING)
                }
            ]
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        pie_data: {
            deep: true,
            handler(new_data, old_data) {
                if (!_.isEqual(new_data, old_data)) this.update_pie();
            }
        },
    },
    mounted() {
        const pie_config: d3pie.ID3PieOptions = {
            size: {
                canvasWidth: 330,
                canvasHeight: 200,
                pieOuterRadius: "95%"
            },
            data: {
                sortOrder: "value-desc",
                content: this.pie_data
            },
            labels: {
                outer: {
                    pieDistance: 15
                },
                inner: {
                    format: "value",
                    hideWhenLessThanPercentage: 3
                },
                mainLabel: {
                    color: "#ffffff",
                    font: "arial",
                    fontSize: 14
                },
                percentage: {
                    color: "#ffffff",
                    decimalPlaces: 0
                },
                value: {
                    color: "#ffffff",
                    fontSize: 13
                },
                lines: {
                    enabled: true
                },
                truncation: {
                    enabled: true
                }
            },
            effects: {
                pullOutSegmentOnClick: {
                    speed: 400,
                    size: 8
                }
            },
            misc: {
                gradient: {
                    enabled: true,
                    percentage: 100
                }
            }
        }
        this.$options.d3pies[this.id] = new d3pie(this.pie_id, pie_config)
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        pretty_duration,
        update_pie() {
            this.$options.d3pies[this.id].updateProp("data.content", this.pie_data)
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
