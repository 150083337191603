<template>
  <SettingsItem
      id="browser_edit"
      :title="`Edit ${browser.props.name} ${browser.props.label}`">
    <div class="browser-edit">
      <div class="header">
        <Button
            id="browser_back"
            text="Back"
            @click="back"
        />
        <Button
            id="browser_save"
            text="Save"
            :click_action="save"
        />
        <div class="flex-expander"/>
        <Button
            id="browser_delete"
            text="Delete"
            color_class="red"
            @click="delete_browser"
        />
      </div>
      <div class="body">
        <div class="row">
          <div class="col s4">
            <Input
                v-model="type"
                :disabled="true"
                label="Type"
            />
          </div>
          <div class="col s4">
            <Input
                v-model="label"
                :disabled="true"
                label="Label"
            />
          </div>
          <div class="col s4">
            <Input
                v-model="version"
                :disabled="true"
                label="Version"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s4">
            <Checkbox
                id="browser_edit_active_checkbox"
                v-model="browser_update.active"
                label="Active"
            />
          </div>
          <div class="col s4">
            <Select2
                id="browser_edit_driver_version_select"
                v-model="browser_update.driver_version"
                dropdown_parent="#vue_settings_modal"
                label="Driver version"
            >
              <option value="">Not selected</option>
              <template v-for="driver in drivers_for(type)"
                        :key="driver.props.id">
                <option :value="driver.props.version">{{ driver.props.version }}</option>
              </template>
            </Select2>

          </div>
          <div class="col s3"
               style="display: flex; flex-direction: row;">
            <Button text="Download"
                    :click_action="() => browser.download() "
            />
          </div>

        </div>
        <div class="row">
          <div class="col s6">
            Executions: {{ in_plays }}
          </div>
          <div class="col s4">
            In Scenarios: {{ in_scenarios }}
          </div>
        </div>
      </div>
    </div>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import Button from "../../../testa/Button.vue";
import Input from "../../../testa/Input.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Select2 from "../../../testa/Select2.vue";
import SettingsItem from "../../SettingsItem.vue";
import { Browser } from "../../../../vue_record/models/browser";
import { BrowserDriver } from "../../../../vue_record/models/browser_driver";
import { EnumBrowser } from "../../../../auto_generated/enums";
import toastr from "toastr";
import { BrowserDriverScope } from "../../../../vue_record/scopes/browser_driver_scope";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { Marker } from "../../../../vue_record/base/utils/record_marker";

export default defineComponent({
    components: {
        SettingsItem,
        Select2,
        Checkbox,
        Input,
        Button,
    },
    props: {
        browser: {
            type: Object as PropType<Browser>,
            required: true,
        },
        browser_drivers: {
            type: Object as PropType<BrowserDriverScope>,
            required: true,
        },
        loading_marker: {
            type: Object as PropType<Marker>,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
    },
    emits: ['exit', 'setting-item-mounted', 'setting-item-unmounted'],
    data() {
        return {
            type: this.browser.props.name,
            label: this.browser.props.label,
            version: this.browser.props.version,
            in_scenarios: null,
            in_plays: null,
            browser_update: {
                active: this.browser.props.active,
                driver_version: this.browser.props.driver_version,
            },
        }
    },
    computed: {
        geckodrivers() {
            return this.browser_drivers.geckodrivers.order("version", "desc", "version").toArray()
        },
        chromedrivers() {
            return this.browser_drivers.chromedrivers.order("version", "desc", "version").toArray()
        },
    },
    watch: {},
    mounted() {
        this.$emit("setting-item-mounted", this)
        this.browser.client.stats().then((data) => {
            this.in_scenarios = data.scenarios
            this.in_plays = data.plays
        })
    },
    unmounted() {
        this.form_validator.unregister()
        this.$emit("setting-item-unmounted", this)
    },
    methods: {
        save() {
            const promise = this.apply()
            promise.then(() => this.back())
            return promise
        },
        apply() {
            return this.browser.client.update(this.browser_update)
                       .catch(() => toastr.error("Failed to save browser"))
        },
        delete_browser() {
            Browser.delete(this.browser.props.id)
                   .then((result: Boolean) => {
                       if (result) {
                           this.back();
                       }
                   })
        },
        back() {
            this.$emit('exit')
        },
        drivers_for(browser_type: EnumBrowser): BrowserDriver[] {
            switch (browser_type) {
                case Enum.Browser.CHROME:
                    return this.chromedrivers
                case Enum.Browser.FIREFOX:
                    return this.geckodrivers;
                default:
                    return [];
            }
        },
    },
})
</script>

<style lang="scss" scoped>
.browser-edit {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .body {
    margin-top: 10px;
  }
}

</style>
