<template>
  <ActionIcon
      icon_class="fa-solid fa-plus"
      color_class="white"
      title="Add device"
      :no_click_focus="true"
      :scale="1.1"
      :allow_concurrent_click_action="false"
      @click="Phone.show_add_to_project()"
  />
  <ActionIcon
      :icon_class="Phone.state.sidebar_show_offline ? 'fa-solid fa-slash' : 'fa-solid fa-circle'"
      title="Toggle show offline phones"
      :scale="0.9"
      @click="() => Phone.state.sidebar_show_offline = !Phone.state.sidebar_show_offline"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ActionIcon from "../../../ActionIcon.vue";
import { Phone } from "../../../../../vue_record/models/phone";

export default defineComponent({
    components: { ActionIcon },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Phone() {
            return Phone
        },
        Enum() {
            return Enum
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
