<template>
  <template v-if="schedules_tree.computed.children.length == 0">
    <div class="row"
         style="margin-top: 10px;">
      <div class="col s12 vertical align-center">
        <div class="row">
          <div class="col s12 center vertical align-center">
            <span>No scheduled plays</span>
          </div>
        </div>
        <div class="row">
          <div class="col s12 center align-center vertical">
            <span style="text-align: center">
              Plays can be scheduled in Play Modal advanced settings
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <Tree
        :tree="schedules_tree"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Tree from "../../../tree/Tree.vue";
import { TestaTree } from "../../../tree/tree";
import Button from "../../../Button.vue";

export default defineComponent({
    components: { Tree },
    // <editor-fold desc="PROPS">
    props: {},
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        schedules_tree() {
            return TestaTree.Tree.get_schedules_tree(current.project_version)
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
