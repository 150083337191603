import { SnippetFolder } from "../../../vue_record/models/snippet_folder";
import { Snippet } from "../../../vue_record/models/snippet";
import { Scenario } from "../../../vue_record/models/scenario";
import { ScenarioFolder } from "../../../vue_record/models/scenario_folder";
import { Group } from "../../../vue_record/models/group";
import { GroupFolder } from "../../../vue_record/models/group_folder";
import { FileFolder } from "../../../vue_record/models/file_folder";
import { File } from "../../../vue_record/models/file"
import { ImageFolder } from "../../../vue_record/models/image_folder";
import { Image } from "../../../vue_record/models/image"
import { Phone } from "../../../vue_record/models/phone";
import { App } from "../../../vue_record/models/app";
import { Schedule } from "../../../vue_record/models/schedule";
import { Play } from "../../../vue_record/models/play/play";
import { Props } from "../../../vue_record/base/vue_record";


// NOTE: this must match the resource_id in Enum
export type SearchableFolderModelString =
    "snippet_folder"
    | "scenario_folder"
    | "group_folder"
    | "file_folder"
    | "image_folder"
export type SearchableModelString =
    "snippet"
    | "scenario"
    | "group"
    | "file"
    | "image"
    | "app"
    | "phone"
    | "schedule"
    | "play"
    | SearchableFolderModelString
export type ModelWithTimestamp = {
    [key in SearchableModelString]?: Date
}

export type SearchableRecordModel =
    typeof Snippet | typeof SnippetFolder | typeof Scenario | typeof ScenarioFolder |
    typeof Group | typeof GroupFolder | typeof File | typeof FileFolder | typeof Image |
    typeof ImageFolder | typeof Phone | typeof App | typeof Schedule | typeof Play
export type SearchableRecord = Snippet | SnippetFolder | Scenario | ScenarioFolder | Group |
    GroupFolder | File | FileFolder | Image | ImageFolder | Phone | App | Schedule | Play

export function search_records(project_version_id: number, query: string, models_with_last_timestamps: ModelWithTimestamp) {
    type SearchResponse = {
        all_loaded: boolean
        records: Props[],
        containing_folders?: Props[]
    }
    type SearchResponses = {
        [key in SearchableModelString]?: SearchResponse
    }

    type SearchResult = {
        records: SearchableRecord[]
        all_loaded: boolean
        last_updated_at: Date
    }

    type SearchResults = {
        [key in SearchableModelString]?: SearchResult
    }

    return new Promise<SearchResults>((resolve, reject) => {
        $.ajax({
            // url: `/core/search`,
            url: `/elixir-api/search`,
            type: "POST",
            processData: false,
            contentType: "application/json",
            data: JSON.stringify({
                query,
                models_with_last_timestamps,
                project_version_id,
                authenticity_token
            }),
            success: (data: SearchResponses) => {
                const results: SearchResults = {}
                Object.keys(data).forEach(key => {
                    const model = key as SearchableModelString
                    const model_results = data[model] as SearchResponse
                    const records: SearchableRecord[] = [];
                    let last_updated_at: Date = null;
                    switch (model) {
                        case "snippet":
                            model_results.containing_folders.forEach(folder_props => SnippetFolder.new(folder_props))
                            model_results.records.forEach(props => {
                                const snippet = Snippet.new(props)
                                if (last_updated_at == null || last_updated_at > snippet.props.updated_at) {
                                    last_updated_at = snippet.props.updated_at
                                }
                                records.push(snippet)
                            })
                            break;
                        case "snippet_folder":
                            model_results.containing_folders.forEach(folder_props => SnippetFolder.new(folder_props))
                            model_results.records.forEach(props => {
                                const snippet_folder = SnippetFolder.new(props)
                                if (last_updated_at == null || last_updated_at > snippet_folder.props.updated_at) {
                                    last_updated_at = snippet_folder.props.updated_at
                                }
                                records.push(snippet_folder)
                            })
                            break;
                        case "scenario":
                            model_results.containing_folders.forEach(folder_props => ScenarioFolder.new(folder_props))
                            model_results.records.forEach(props => {
                                const scenario = Scenario.new(props)
                                if (last_updated_at == null || last_updated_at > scenario.props.updated_at) {
                                    last_updated_at = scenario.props.updated_at
                                }
                                records.push(scenario)
                            })
                            break;
                        case "scenario_folder":
                            model_results.containing_folders.forEach(folder_props => ScenarioFolder.new(folder_props))
                            model_results.records.forEach(props => {
                                const scenario_folder = ScenarioFolder.new(props)
                                if (last_updated_at == null || last_updated_at > scenario_folder.props.updated_at) {
                                    last_updated_at = scenario_folder.props.updated_at
                                }
                                records.push(scenario_folder)
                            })
                            break;
                        case "group":
                            model_results.containing_folders.forEach(folder_props => ScenarioFolder.new(folder_props))
                            model_results.records.forEach(props => {
                                const group = Group.new(props)
                                if (last_updated_at == null || last_updated_at > group.props.updated_at) {
                                    last_updated_at = group.props.updated_at
                                }
                                records.push(group)
                            })
                            break;
                        case "group_folder":
                            model_results.containing_folders.forEach(folder_props => GroupFolder.new(folder_props))
                            model_results.records.forEach(props => {
                                const group_folder = GroupFolder.new(props)
                                if (last_updated_at == null || last_updated_at > group_folder.props.updated_at) {
                                    last_updated_at = group_folder.props.updated_at
                                }
                                records.push(group_folder)
                            })
                            break;
                        case "file":
                            model_results.records.forEach(props => {
                                const file = File.new(props)
                                if (last_updated_at == null || last_updated_at > file.props.updated_at) {
                                    last_updated_at = file.props.updated_at
                                }
                                records.push(file)
                            })
                            break;
                        case "file_folder":
                            model_results.records.forEach(props => {
                                const file_folder = FileFolder.new(props)
                                if (last_updated_at == null || last_updated_at > file_folder.props.updated_at) {
                                    last_updated_at = file_folder.props.updated_at
                                }
                                records.push(file_folder)
                            })
                            break;
                        case "image":
                            model_results.records.forEach(props => {
                                const image = Image.new(props)
                                if (last_updated_at == null || last_updated_at > image.props.updated_at) {
                                    last_updated_at = image.props.updated_at
                                }
                                records.push(image)
                            })
                            break;
                        case "image_folder":
                            model_results.records.forEach(props => {
                                const image_folder = ImageFolder.new(props)
                                if (last_updated_at == null || last_updated_at > image_folder.props.updated_at) {
                                    last_updated_at = image_folder.props.updated_at
                                }
                                records.push(image_folder)
                            })
                            break;
                        case "phone":
                            model_results.records.forEach(props => {
                                const phone = Phone.new(props)
                                if (last_updated_at == null || last_updated_at > phone.props.updated_at) {
                                    last_updated_at = phone.props.updated_at
                                }
                                records.push(phone)
                            })
                            break;
                        case "app":
                            model_results.records.forEach(props => {
                                const app = App.new(props)
                                if (last_updated_at == null || last_updated_at > app.props.updated_at) {
                                    last_updated_at = app.props.updated_at
                                }
                                records.push(app)
                            })
                            break;
                        case "schedule":
                            model_results.records.forEach(props => {
                                const schedule = Schedule.new(props)
                                if (last_updated_at == null || last_updated_at > schedule.props.updated_at) {
                                    last_updated_at = schedule.props.updated_at
                                }
                                records.push(schedule)
                            })
                            break;
                        case "play":
                            model_results.records.forEach(props => {
                                const schedule = Play.new(props)
                                if (last_updated_at == null || last_updated_at > schedule.props.updated_at) {
                                    last_updated_at = schedule.props.updated_at
                                }
                                records.push(schedule)
                            })
                            break;
                    }
                    results[model] = {
                        all_loaded: model_results.all_loaded,
                        records,
                        last_updated_at
                    }
                })

                resolve(results)
            },
            error: (error) => {
                reject(error)
            },
            statusCode: ajax_status_codes,
        })
    })
}
