import { GroupSavepoint } from "../models/group_savepoint";
import { Group } from "../models/group";
import { User } from "../models/user";
import { PlayGroup } from "../models/play/play_group";
import { PlayGroupScope } from "../scopes/play/play_group_scope";


declare module "../models/group_savepoint" {
    interface GroupSavepoint {
        play_groups: PlayGroupScope;
        group: Group
        user: User
    }
}

GroupSavepoint.belongs_to("group", Group, "group_id")
GroupSavepoint.belongs_to("user", User, "user_id")

GroupSavepoint.has_many("play_groups", PlayGroup, "group_savepoint_id")
