<template>
  <span class="play-status-icon-container"
        :title="title"
        :style="style"
  >
    <i
        :class="icon_class"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { PlayStatus } from "../../../helpers/play/play_status";
import { EnumPlayStatus } from "../../../auto_generated/enums";
import { CSSProperties } from "vue";

export default defineComponent({
    // <editor-fold desc="PROPS">
    props: {
        play_status: {
            type: String as PropType<EnumPlayStatus>,
            required: true
        },
        scale: {
            type: [String, Number],
            required: false,
            default: 1
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        scale_number(): number {
            if (typeof this.scale == "string") {
                return parseFloat(this.scale)
            } else return this.scale
        },
        icon_class() {
            return PlayStatus.icon_class(this.play_status)
        },
        title() {
            return PlayStatus.label(this.play_status)
        },
        style() {
            let scale = this.scale_number;
            if (this.play_status == Enum.Play.Status.TERMINATED) {
                // because the terminated icon is large by default
                scale = scale - 0.1
            }
            const style: CSSProperties = {
                color: PlayStatus.color(this.play_status),
                fontSize: `${scale}em`
            }
            return style
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.play-status-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
}
</style>
