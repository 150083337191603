import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumScenarioSettingBrowserScope } from "../../../auto_generated/enums";
import { EnumBrowserValues } from "../../../auto_generated/enums";
import { BrowserScope } from "../../scopes/scenario_setting/browser_scope";
import { BrowserClient } from "../../clients/scenario_setting/browser_client";
import { on_dom_content_loaded } from "../../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export interface BrowserProps extends Props {
    id: number
    scenario_setting_id: number
    index: number
    scope: EnumScenarioSettingBrowserScope
    browser_id: number
    width: number
    height: number
    is_mobile: boolean
    emulation_profile_name: string
    user_agent: string
    top_left_x: number
    top_left_y: number
    pixel_ratio: number
    created_at?: Date
    updated_at?: Date
    network_log_path: string
    javascript_log_path: string
    locale: string
    type: EnumBrowserValues
    driver_log_path: string
    console_log_path: string
}
export type QuerifiedBrowserProps = QuerifyProps<BrowserProps>
export type BrowserCreateProps = Omit<BrowserProps, 'id'>
export type BrowserUpdateProps = Partial<BrowserProps>

export interface BrowserState extends State {}
export interface BrowserComputed extends Computed {}
export interface BrowserStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class Browser extends VueRecord {
    ['constructor']: typeof Browser

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = BrowserClient
    static ScopeClass = BrowserScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: BrowserStaticState = reactive<BrowserStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "browser_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Browser> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Browser>> = {}

    static field_validators: ModelValidatorOpts<BrowserProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_SETTING_BROWSER
    static resource_id = Enum.Resource.Id.SCENARIO_SETTING_BROWSER
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: BrowserClient
    declare props: BrowserProps;
    declare state: BrowserState;
    declare computed: BrowserComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
Browser.register_resource(Browser)
BrowserClient.ModelClass = Browser
BrowserScope.ModelClass = Browser

global_event_bus.$on("after_project_version_unload", () => {
    Browser.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            Browser.unsync()
            if (project_version_id != null) Browser.sync(`/sync/project_version/${project_version_id}/scenario_setting_browsers`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        ScenarioSettingBrowser: typeof Browser
    }
}
window.ScenarioSettingBrowser = Browser
// </editor-fold>

