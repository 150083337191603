<!--suppress RedundantIfStatementJS -->
<template>
  <div :id="container_id"
       class="container">
    <div class="actions">
      <ActionIcon
          title="Show info toolbar"
          icon_class="fa-solid fa-circle-info"
          :color_class="toolbar == 'info' ? 'white' : 'grey'"
          @click=" () => toolbar = 'info'"
      />

      <ActionIcon
          v-if="loaded_chunks.length > 0"
          title="Show time window"
          icon_class="fa-solid fa-timeline"
          :color_class="toolbar == 'time' ? 'white' : 'grey'"
          @click=" () => toolbar = 'time'"
      />

      <ActionIcon
          icon_class="fa-solid fa-filter"
          title="Show filter toolbar"
          :color_class="toolbar == 'filter' ? 'white' : 'grey'"
          @click=" () => toolbar = 'filter'"
      />

      <ActionIcon
          icon_class="fa-solid fa-filter-circle-xmark"
          title="Show reject toolbar"
          :color_class="toolbar == 'reject' ? 'white' : 'grey'"
          @click=" () => toolbar = 'reject'"
      />

      <ActionIcon
          icon_class="fa-solid fa-rotate-right"
          color_class="blue"
          title="Reload"
          @click="reload"
      />
      <div style="height: 100%; flex-shrink: 999"/>

      <ActionIcon
          icon_class="fa-solid fa-sort-amount-up"
          title="Scroll to top"
          @click="scroll_to_top"
      />
      <ActionIcon
          icon_class="fa-solid fa-sort-amount-down-alt"
          :title="sticky_scroll_enabled ? 'Disable sticky scroll' : 'Enable sticky scroll'"
          :color_class="sticky_scroll_enabled ? get_css_var('--button-white') : get_css_var('--button-disabled')"
          @click="scroll_to_bottom"
      />
    </div>

    <div class="content-wrapper">
      <div class="logs-toolbar">
        <!--<editor-fold desc="INFO">-->
        <div v-if="toolbar == 'info'"
             class="info-toolbar">
          <template v-if="!log_file.is_live()">
            <div class="load-progress">
              {{ byte_information }} {{ percentage }}%
            </div>
            <div class="load-bar">
              <template v-for="(chunk, i) in log_file.state.chunk_data">
                <div class="load-bar-chunk"
                     :style="chunk_progress_style(i)"/>
              </template>
            </div>
          </template>
          <template v-else>
            <div>
              {{ lines_count }}
            </div>
          </template>
        </div>
        <!--</editor-fold>-->

        <!--<editor-fold desc="FILTER">-->
        <div v-if="toolbar == 'filter'"
             class="filter-toolbar">
          <span style="margin-right: 5px;">
            Filter:
          </span>
          <Select2
              v-model="level_filter"
              :for_report_filter="true"
              style="width: 200px; flex-shrink: 0"
          >
            <option value="0"> DEBUG</option>
            <option value="1"> INFO</option>
            <option value="2"> WARN</option>
            <option value="3"> ERROR</option>
            <option value="4"> FATAL</option>
            <option value="5"> UNKNOWN</option>
          </Select2>

          <Select2
              v-model="tags_filter"
              :multiple="true"
              :for_report_filter="true"
              placeholder="tags"
              @search-enter="(query) => custom_tags.push(query)"
          >
            <template v-for="tag in log_file.state.tags.concat(custom_tags)"
                      :key="tag">
              <option :value="tag">{{ tag }}</option>
            </template>
          </Select2>
          <Input
              v-model="message_filter"
              placeholder="message"
              :delayed_debounce_time="300"
              :scale="0.8"
          />
        </div>
        <!--</editor-fold>-->

        <!--<editor-fold desc="REJECT">-->
        <div v-if="toolbar == 'reject'"
             class="reject-toolbar">
          <span style="margin-right: 5px;">
            Reject:
          </span>
          <Select2
              v-model="level_reject"
              :for_report_filter="true"
              style="width: 200px; flex-shrink: 0"
          >
            <option value="0"> DEBUG</option>
            <option value="1"> INFO</option>
            <option value="2"> WARN</option>
            <option value="3"> ERROR</option>
            <option value="4"> FATAL</option>
            <option value="5"> UNKNOWN</option>
          </Select2>

          <Select2
              v-model="tags_reject"
              :multiple="true"
              :for_report_filter="true"
              placeholder="tags"
              @search-enter="(query) => custom_tags.push(query)"
          >
            <template v-for="tag in log_file.state.tags.concat(custom_tags)"
                      :key="tag">
              <option :value="tag">{{ tag }}</option>
            </template>
          </Select2>
          <Input
              v-model="message_reject"
              placeholder="message"
              :delayed_debounce_time="300"
              :scale="0.8"
          />
        </div>
        <!--</editor-fold>-->

        <!--<editor-fold desc="TIME">-->
        <div v-if="toolbar == 'time' && loaded_chunks.length > 0"
             class="time-toolbar">
          <span
              v-datetime="{datetime: time_filter[0], strftime: '%Y-%m-%d %H:%M:%S %z'}"
              style="white-space: nowrap; margin-right: 5px;"
          />
          <NoUiSlider
              v-if="log_file.state.min_datetime != null && log_file.state.max_datetime != null"
              v-model="time_filter"
              :min="log_file.state.min_datetime"
              :max="log_file.state.max_datetime"
              :rounded="true"
              :delayed_debounce_time="300"
          />
          <span
              v-datetime="{datetime: time_filter[1], strftime: '%Y-%m-%d %H:%M:%S %z'}"
              style="white-space: nowrap; margin-left: 5px;"
          />
        </div>
        <!--</editor-fold>-->

      </div>
      <div v-once
           ref="content"
           class="content no-padded-scrollbar">
        <textarea ref="textarea"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { init_codemirror } from "../../../../../helpers/codemirror/init_codemirror";
import { EditorConfiguration } from "codemirror";
import { delayed_debounce } from "../../../../../helpers/generic/delayed_debounce";
import { is_visible } from "../../../../../helpers/dom/is_visible";
import { AllMightyObserver } from "../../../../../helpers/dom/all_mighty_observer";
import { LogFile } from "../../../../../vue_record/models/non_db/log_file";
import { CSSProperties } from "vue";
import { ansi_objects } from "../../../../../libs/ansispan";
import { ansi_object_css } from "../../../../../libs/ansispan";
import ActionIcon from "../../../ActionIcon.vue";
import { get_css_var } from "../../../../../helpers/generic/get_css_var";
import { CHUNK_SIZE } from "../../../../../vue_record/models/non_db/log_file";
import { Log } from "../../../../logs/log";
import { ChunkData } from "../../../../../vue_record/models/non_db/log_file";
import Select2 from "../../../Select2.vue";
import { generate_eid } from "../../../../../helpers/generate/generate_eid";
import Input from "../../../Input.vue";
import { LineData } from "../../../../../vue_record/models/non_db/log_file";
import NoUiSlider from "../../../NoUiSlider.vue";
import { nextTick } from "vue";
import { storager_snippet_editor_font_size_key } from "../../../../../helpers/codemirror/font_resize_on_wheel";
import { set_font_size } from "../../../../../helpers/codemirror/helpers/set_font_size";
import { font_resize_on_wheel } from "../../../../../helpers/codemirror/font_resize_on_wheel";
import { storager_log_viewer_font_size_key } from "../../../../../helpers/codemirror/font_resize_on_wheel";

export default defineComponent({
    components: { NoUiSlider, Input, Select2, ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        log_file: {
            type: Object as PropType<LogFile>,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            container_id: generate_eid(),
            cm: null as CodeMirror.Editor,
            amo: null as AllMightyObserver,
            sticky_scroll_enabled: true,
            toolbar: "info" as 'info' | 'filter' | 'reject' | 'time',
            time_filter: [new Date(0), new Date(8640000000000000)],
            level_filter: "0",
            tags_filter: [],
            custom_tags: [],
            message_filter: "",
            level_reject: "5",
            tags_reject: [],
            message_reject: "",
            lines_count: 0,
            chunks_replaced: {} as Record<number, boolean>
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        loaded_chunks() {
            return this.log_file.state.chunk_data.filter(d => d.loaded)
        },
        percentage() {
            return Math.round((this.loaded_chunks.length / this.log_file.state.chunk_data.length) * 100)
        },
        byte_information() {
            const total_kilobytes = this.log_file.props.size / 1024
            const loaded_kilobytes = Math.min((this.loaded_chunks.length * CHUNK_SIZE) / 1024, total_kilobytes)
            if (total_kilobytes >= 512) {
                const total_megabytes = total_kilobytes / 1024
                const loaded_megabytes = loaded_kilobytes / 1024
                return `${loaded_megabytes.toFixed(1)} / ${total_megabytes.toFixed(1)} MB`
            } else {
                return `${loaded_kilobytes.toFixed(1)} / ${total_kilobytes.toFixed(1)} kB`
            }
        },
        level_filter_int() {
            return parseInt(this.level_filter)
        },
        level_reject_int() {
            return parseInt(this.level_reject)
        },
        message_filter_lower_case() {
            return this.message_filter.toLowerCase()
        },
        message_reject_lower_case() {
            return this.message_reject.toLowerCase()
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        percentage() {
            if (this.percentage == 100 && this.toolbar == 'info') this.toolbar = "filter"
        },
        level_filter() {
            this.set_value()
        },
        tags_filter(new_value, old_value) {
            if (!_.isEqual(new_value, old_value)) this.set_value();
        },
        message_filter() {
            this.set_value();
        },
        level_reject() {
            this.set_value()
        },
        tags_reject() {
            this.set_value();
        },
        message_reject() {
            this.set_value();
        },
        time_filter() {
            this.set_value();
        }
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const textarea = this.$refs.textarea as HTMLTextAreaElement
        const options: EditorConfiguration = {
            lineNumbers: true,
            viewportMargin: 20,
            lineWrapping: false,
            mode: "null",
            readOnly: true,
            rulers: false,
            scrollPastEnd: false,
            styleActiveLine: false
        }

        let doc = null;
        const cms = this.log_file.state.codemirrors
        if (cms.length > 0) {
            doc = cms[0].getDoc().linkedDoc({ sharedHist: false });
        }

        const cm = init_codemirror(textarea, current.project, options)
        if (doc != null) cm.swapDoc(doc)
        this.cm = cm
        this.log_file.state.codemirrors.push(cm)

        this.log_file.on_chunk_loaded(this.set_value_for_chunk)
        this.log_file.on_live_line_data(this.add_live_line_data)

        // this.cm.on("viewportChange", (cm, from, to) => {
        //     console.log(`last line: ${cm.lastLine()}. to: ${to}`);
        //     if ((cm.lastLine() + 1) == to) {
        //         this.sticky_scroll_enabled = true
        //     } else this.sticky_scroll_enabled = false
        // })
        //
        const content = this.$refs.content as HTMLDivElement
        const scroll_container = content.querySelector(".CodeMirror-scroll") as HTMLDivElement
        scroll_container.addEventListener("wheel", (event) => {
            if (event.deltaY > 0 && !this.sticky_scroll_enabled) {
                if (scroll_container.scrollHeight - scroll_container.scrollTop - scroll_container.clientHeight <= 0) {
                    this.sticky_scroll_enabled = true
                }
            }

            if (this.sticky_scroll_enabled && event.deltaY < 0) {
                const is_scrollable = scroll_container.scrollHeight > scroll_container.offsetHeight;
                if (is_scrollable) this.sticky_scroll_enabled = false
            }
        }, {
            passive: true
        })

        const font_size = current.user.storager.get(storager_log_viewer_font_size_key, null)
        if (font_size != null) set_font_size(cm, font_size)
        font_resize_on_wheel(cm, storager_log_viewer_font_size_key, () => this.log_file.state.codemirrors)

        cm.on("beforeChange", (cm, change) => {
            const is_added_chunk = change.origin.indexOf("added-chunk") != -1
            const is_added_live = change.origin.indexOf("added-live") != -1
            if (is_added_chunk || is_added_live) {
                let chunk_index: number = null
                let chunk_data: ChunkData = null
                let live_index: number = null

                if (is_added_chunk) {
                    chunk_index = parseInt(change.origin.replace("added-chunk-", ""))
                    chunk_data = this.log_file.state.chunk_data[chunk_index]
                }
                if (is_added_live) {
                    live_index = parseInt(change.origin.replace("added-live-", ""))
                }


                change.text.each((line, i) => {
                    let chunk_line_data: LineData = null
                    if (is_added_chunk) {
                        if (chunk_data.lines == null) {
                            chunk_data.lines = Array(change.text.length).fill(null);
                        }
                        chunk_line_data = chunk_data.lines[i]
                    } else {
                        if (line == "") return

                        chunk_line_data = this.log_file.state.live_data.lines[live_index + i]
                    }

                    if (chunk_line_data == null) {
                        const ao = ansi_objects(line);
                        let plain = ao.map(a => a.str).join("")

                        // text must not be empty. because if it is empty then we cannot apply CodeMirror markText to it
                        if (plain == "") plain = " "

                        change.text[i] = plain
                        const log = Log.from_string(plain)
                        if (plain != " ") {
                            if (log.datetime != null) {
                                if (this.log_file.state.min_datetime == null || this.log_file.state.min_datetime.getTime() > log.datetime.getTime()) {
                                    this.log_file.state.min_datetime = log.datetime
                                }

                                if (this.log_file.state.max_datetime == null || this.log_file.state.max_datetime.getTime() < log.datetime.getTime()) {
                                    this.log_file.state.max_datetime = log.datetime
                                }
                            }
                            log.tags.each(tag => {
                                if (!this.log_file.state.tags.includes(tag)) this.log_file.state.tags.push(tag)
                            })
                        }


                        chunk_line_data = {
                            ansi_objects: ao,
                            log,
                            raw: line,
                            plain,
                            is_rendered: null
                        }
                        chunk_data.lines[i] = chunk_line_data
                    } else {
                        change.text[i] = chunk_line_data.plain
                    }

                    const on_change = () => {
                        if (chunk_line_data.log.datetime == null || (this.is_filtered(chunk_line_data) && !this.is_rejected(chunk_line_data) && this.in_time_window(chunk_line_data))) {
                            let ch = 0
                            chunk_line_data.ansi_objects.each(a => {
                                if (a.text_decoration != null || a.font_style != null ||
                                    a.font_weight != null || a.background_color != null || a.color != null) {
                                    const css = ansi_object_css(a)
                                    cm.markText({ line: change.from.line + i, ch }, {
                                        line: change.from.line + i,
                                        ch: ch + a.str.length
                                    }, { css });
                                }

                                ch += a.str.length
                            })
                            chunk_line_data.is_rendered = true
                        } else {
                            cm.markText({ line: change.from.line + i, ch: 0 }, {
                                line: change.from.line + i,
                                ch: chunk_line_data.plain.length
                            }, { css: "display: none;", inclusiveRight: true, inclusiveLeft: true, collapsed: true })
                            chunk_line_data.is_rendered = false
                        }

                        cm.off("change", on_change)
                    }
                    cm.on("change", on_change)
                })
            }
        })


        const delayed_debouce_refresh = delayed_debounce(() => {
            if (is_visible(this.$refs.content as HTMLElement)) cm.refresh();
        }, 200)

        this.amo = AllMightyObserver.new(
            {
                element_visible: true,
                after_resize: true,
                element_after_resize: true,
                target_element: this.$refs.content as HTMLElement,
                callback: () => delayed_debouce_refresh()
            }
        )

        this.set_value()
        this.load_content();
    },
    unmounted() {
        this.log_file.state.codemirrors = this.log_file.state.codemirrors.filter(cm => cm != this.cm)
        this.amo?.stop()
        this.log_file.off_chunk_loaded(this.set_value_for_chunk)
        this.log_file.off_live_line_data(this.add_live_line_data)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        get_css_var,
        chunk_progress_style(index: number) {
            const style: CSSProperties = {}
            style.width = `${100 / this.log_file.state.chunk_data.length}%`
            if (this.log_file.state.chunk_data[index].loading) {
                style.backgroundColor = "var(--button-grey)"
            } else if (this.log_file.state.chunk_data[index].loaded) {
                style.backgroundColor = "var(--button-white)"
            }
            return style
        },
        set_value_for_chunk(chunk: ChunkData) {
            const lines_before = this.log_file
                                     .state
                                     .chunk_data
                                     .filter(c => c.index < chunk.index)
                                     .map(c => this.chunks_replaced[c.index] ? c.lines.filter(l => l.is_rendered).length : 1)
                                     .sum()
            const line = Math.max(lines_before - this.log_file.state.chunk_data.length - 1, 0)
            const cursor = this.cm.getSearchCursor(`Chunk Placeholder ${chunk.index}\n`, { line, ch: 0 }, true);
            if (cursor.find(false)) {
                const from = cursor.from();
                const to = cursor.to()
                this.cm.replaceRange(
                    chunk.raw_data,
                    from,
                    to,
                    `added-chunk-${chunk.index}`
                )
                this.lines_count = this.cm.lastLine()
                this.chunks_replaced[chunk.index] = true
            } else {
                console.error(`couldn't find chunk ${chunk.index}. lines before: ${lines_before - 1}`, JSON.parse(JSON.stringify(this.chunks_replaced)))
            }
        },
        add_live_line_data(data_lines: LineData[], start_index: number) {
            this.cm.replaceRange(
                data_lines.map(dl => dl.raw).join(""),
                { line: Infinity, ch: Infinity },
                { line: Infinity, ch: Infinity },
                `added-live-${start_index}`);
            this.lines_count = this.cm.lastLine()
            if (this.sticky_scroll_enabled) {
                nextTick(() => {
                    this.scroll_to_bottom();
                })
            }
        },
        set_value() {
            // this.cm.getDoc().getAllMarks().forEach(mark => mark.clear())

            this.cm.setValue(this.log_file.state.chunk_data.map(d => `Chunk Placeholder ${d.index}\n`).join(""))
            this.chunks_replaced = {}

            this.log_file
                .state
                .chunk_data
                .filter(d => d.loaded)
                .each(d => this.set_value_for_chunk(d))

            this.add_live_line_data(this.log_file.state.live_data.lines, 0)
        },
        scroll_to_top() {
            this.sticky_scroll_enabled = false;
            this.cm.scrollIntoView({ line: 0, ch: 0 })
        },
        scroll_to_bottom() {
            this.sticky_scroll_enabled = true;
            this.cm.scrollIntoView({ line: this.cm.lastLine(), ch: 0 })
        },
        toggle_sticky_scroll() {
            this.sticky_scroll_enabled = !this.sticky_scroll_enabled
            this.scroll_to_bottom()
        },
        is_filtered(chunk_line_data: LineData) {
            if (this.level_filter_int > chunk_line_data.log.level) return false

            if (this.tags_filter != null && this.tags_filter.length != 0 && this.tags_filter.every(t => chunk_line_data.log.tags.every(tt => tt != t))) {
                return false
            }

            if (this.message_filter != "" && (chunk_line_data.plain == "" || chunk_line_data.plain.toLowerCase().indexOf(this.message_filter_lower_case) == -1)) {
                return false
            }

            return true
        },
        is_rejected(chunk_line_data: LineData) {
            if (this.level_reject_int <= chunk_line_data.log.level) return true

            if (this.tags_reject != null && this.tags_reject.length != 0 && this.tags_reject.some(t => chunk_line_data.log.tags.includes(t))) {
                return true
            }

            if (this.message_reject != "" && (chunk_line_data.plain == "" || chunk_line_data.plain.toLowerCase().indexOf(this.message_reject_lower_case) != -1)) {
                return true
            }

            return false
        },
        in_time_window(chunk_line_data: LineData) {
            if (chunk_line_data.log.datetime == null && this.time_filter[0] != this.log_file.state.min_datetime) return false
            if (chunk_line_data.log.datetime == null && this.time_filter[1] != this.log_file.state.max_datetime) return false

            if (this.time_filter[0] != null && this.time_filter[0].getTime() > chunk_line_data.log.datetime?.getTime()) {
                return false
            }
            if (this.time_filter[1] != null && this.time_filter[1].getTime() < chunk_line_data.log.datetime?.getTime()) {
                return false
            }

            return true
        },
        reload() {
            this.toolbar = 'info'
            this.log_file.init_chunk_data()

            this.log_file.init_live_data()
            this.set_value()
            this.load_content()
            this.sticky_scroll_enabled = true
        },
        load_content() {
            if (this.log_file.is_live()) {
                this.log_file.subscribe_to_logs_live()
            } else {
                this.log_file.load_content_inc(0)
                // this.log_file.load_content_deinc(this.log_file.state.chunk_data.length - 1)
            }
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  height: 100%;

  .actions {
    width: 28px;
    font-size: 0.8em;
    border-right: 2px solid var(--secondary-background-color);
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .content-wrapper {
    width: calc(100% - 29px);
    display: flex;
    flex-direction: column;

    .logs-toolbar {
      height: 28px;
      flex-shrink: 0;
      width: 100%;
      border-bottom: 2px solid var(--secondary-background-color);
      align-items: center;
      display: flex;

      .info-toolbar,
      .filter-toolbar,
      .reject-toolbar,
      .time-toolbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-inline: 5px;
        width: 100%;
      }

      .info-toolbar {
        .load-progress {
          margin-inline: 5px;
          display: flex;
          white-space: nowrap;
        }

        .load-bar:first-child {
          border-left: 1px solid var(--button-grey);
        }

        .load-bar {
          border-right: 1px solid var(--button-grey);
          border-top: 1px solid var(--button-grey);
          border-bottom: 1px solid var(--button-grey);
          width: 100%;
          height: 10px;
          display: flex;
          flex-direction: row;

          .load-bar-chunk {
            border: 1px solid var(--button-grey);
          }
        }
      }
    }

    .content {
      width: 100%;
      height: calc(100% - 31px);
      overflow: hidden;
    }
  }
}


</style>

<style lang="scss">
::-webkit-scrollbar-thumb {
  background-color: white;
}
</style>
