export function send_ws(channel: string, data: { [key: string]: any }) {
    const postData = {
        channel,
        data,
        ext: { auth_token: FAYE_TOKEN }
    };

    return new Promise<void>((resolve, reject) => {
        $.ajax({
            url: WEB_SOCKET_URL,
            type: 'POST',
            data: JSON.stringify(postData),
            processData: false,
            contentType: 'application/json',
            success: () => resolve(),
            error: function(request, status, error) {
                console.log('Response:' + request.responseText + '.\nStatus:' + status + '.\nError:' + error);
                reject(request)
            },
            statusCode: ajax_status_codes
        });
    })
}
