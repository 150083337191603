import { PlayWorkerGroup } from "../../models/play/play_worker_group";
import { Play } from "../../models/play/play";
import { PlayScenariosPlayWorkerGroupsScope } from "../../scopes/play/bridges/play_scenarios_play_worker_groups_scope";
import { PlayScenarioScope } from "../../scopes/play/play_scenario_scope";
import { PhoneScope } from "../../scopes/phone_scope";
import { PlayScenariosPlayWorkerGroups } from "../../models/play/bridges/play_scenarios_play_worker_groups";
import { Phone } from "../../models/phone";
import { PlayScenario } from "../../models/play/play_scenario";
import { PlaySandboxScope } from "../../scopes/play/play_sandbox_scope";
import { PlaySandbox } from "../../models/play/play_sandbox";


declare module "../../models/play/play_worker_group" {
    interface PlayWorkerGroup {
        play: Play

        play_scenarios_play_worker_groups: PlayScenariosPlayWorkerGroupsScope
        play_sandboxes: PlaySandboxScope
        play_scenarios: PlayScenarioScope
        main_play_scenarios: PlayScenarioScope
        claimed_phones: PhoneScope
    }
}


PlayWorkerGroup.belongs_to("play", Play, "play_id")

// has many
PlayWorkerGroup.has_many(
    "play_scenarios_play_worker_groups",
    PlayScenariosPlayWorkerGroups,
    "play_worker_group_id",
    { dependent_unload: true }
)

PlayWorkerGroup.has_many("claimed_phones", Phone, "claimed_by_play_worker_group_id")

PlayWorkerGroup.has_many(
    "play_sandboxes",
    PlaySandbox,
    "play_worker_group_id",
    { dependent_unload: true }
)


// has many through
PlayWorkerGroup.has_many_through(
    "play_scenarios",
    "play_scenarios_play_worker_groups",
    "play_scenario",
    PlayScenario
)
PlayWorkerGroup.has_many_through(
    "main_play_scenarios",
    "play_scenarios_play_worker_groups",
    "play_scenario",
    PlayScenario,
    "main_play_scenarios"
)
