import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["scope-container no-default-unfocus", {selected: _ctx.selected}]),
    tabindex: "0",
    onKeydown: _cache[0] || (_cache[0] = (...args) => (_ctx.on_keydown && _ctx.on_keydown(...args))),
    onMousedown: _cache[1] || (_cache[1] = (e) => e.preventDefault()),
    onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.select && _ctx.select(...args)))
  }, _toDisplayString(_ctx.name), 35 /* TEXT, CLASS, NEED_HYDRATION */))
}