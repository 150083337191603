<template>
  <Modal
      id="upload_app_from_url"
      :form_validator="form_validator"
      :confirm_text="confirm_button_text"
      :show_cancel="true"
      :cancel_on_escape="true"
      :confirm_action="upload"
      initial_width="500px"
      @cancel="cancel"
  >
    <template #header>
      <div>Upload app from web url</div>
    </template>

    <template #body="modal">
      <div class="row">
        <div class="col s12">
          <Input
              id="upload_app_from_url_input"
              v-model="app_url"
              :validator="form_validator.register('url', { presence: true })"
              label="URL"
              :focus="true"
              @enter="modal.confirm()"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../testa/Modal.vue";
import Input from "../testa/Input.vue";
import { PropType } from "vue";
import { Project } from "../../vue_record/models/project";
import { App } from "../../vue_record/models/app";
import { FormValidator } from "../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['cancel'],
    // <editor-fold desc="DATA">
    data() {
        return {
            confirm_button_text: "Upload",
            form_validator: new FormValidator(),
            app_url: "",
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        upload() {
            this.confirm_button_text = "Uploading"
            return App.ClientClass.upload_from_url(this.project.props.id, this.app_url).then(() => {
                this.cancel();
            }).catch((e: Error) => {
                e.show_toaster()
            })
        },
        cancel() {
            this.$emit("cancel");
            this.$.appContext.app.unmount();
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
