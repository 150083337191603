<template>
  <div class="sections-content">
    <RFlex :rflex="rflex"
           style="width: 100%;">
      <template v-for="enabled_section in enabled_sections"
                :key="enabled_section.id"
                #[enabled_section.id]>
        <div class="row full"
             style="height: 100%;">
          <div class="col s12 section-header align-center">
            <ActionIcon
                icon_class="fa-solid fa-minus"
                @click="enabled_section.toggle()"
            />

            <ActionIcon
                v-if="rflex.areas[enabled_section.id].get_is_locked()"
                icon_class="fa-solid fa-lock"
                title="Unlock size"
                @click="rflex.areas[enabled_section.id].set_locked_px(null)"
            />
            <ActionIcon
                v-else
                icon_class="fa-solid fa-unlock"
                color_class="grey"
                title="Lock size"
                @click="rflex.areas[enabled_section.id].set_locked_px(rflex.areas[enabled_section.id].get_size())"
            />
            <slot :name="`${enabled_section.id}_actions`">
              <component :is="enabled_section.actions.component"
                         v-bind="enabled_section.actions.props"/>
            </slot>
            <div class="flex-expander"/>
            <div class="section-title">
              {{ enabled_section.label }}
            </div>
          </div>
          <div class="col s12 section-body">
            <slot :name="enabled_section.id">
              <component :is="enabled_section.content.component"
                         v-bind="enabled_section.content.props"/>
            </slot>
          </div>
        </div>
      </template>
    </RFlex>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ActionIcon from "../ActionIcon.vue";
import { PropType } from "vue";
import { SectionToggler } from "./section_toggler";
import { ResizableFlex } from "../resizable/resizable_flex/resizable_flex";
import { computed } from "../../../helpers/vue/computed";
import RFlex from "../resizable/resizable_flex/RFlex.vue";
import { CSSProperties } from "vue";
import ToggleIcon from "../ToggleIcon.vue";

export default defineComponent({
    components: { ToggleIcon, RFlex, ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        section_toggler: {
            type: Object as PropType<SectionToggler>,
            required: true,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {
            rflex: ResizableFlex.new({
                id: `section_toggler_${this.section_toggler.id}_rflex`,
                fill_container: true,
                resize_behaviour: "only_adjacent",
                direction: computed(() => {
                    const pos = this.section_toggler.position()
                    return (pos == "left" || pos == "right") ? "column" : "row"
                }),
                areas: [
                    ...this.section_toggler.start_sections().map(s => {
                        return {
                            id: s.id,
                            min_px_size: 75,
                            enabled: computed(() => s.is_shown() && s.is_enabled()),
                            initial_ratio_size: 0.25
                        }
                    }),
                    ...this.section_toggler.end_sections().map(s => {
                        return {
                            id: s.id,
                            min_px_size: 75,
                            enabled: computed(() => s.is_shown() && s.is_enabled()),
                            initial_ratio_size: 0.25
                        }
                    })
                ]
            })
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        rflex_style() {
            const style: CSSProperties = {}
            if (this.section_toggler.position() == "left" || this.section_toggler.position() == "right") {
                style.width = "100%"
            } else {
                style.height = "100%"
            }
            return style
        },
        enabled_sections() {
            return this.section_toggler.enabled_sections()
        },
        grid_rows() {
            if (this.section_toggler.is_vertical()) {
                const row = 100.0 / this.enabled_sections.length
                return this.enabled_sections.map(_ => `${row}%`).join(" ")
            } else {
                return "100%;"
            }
        },
        grid_areas() {
            if (this.section_toggler.is_vertical()) {
                return this.enabled_sections.map(s => `'${s.id}'`).join(" ")
            } else {
                return `'${this.enabled_sections.map(s => s.id).join(" ")}'`
            }
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
$item_size: 25px; // make sure to also update in SectionToggler.vue
.sections-content {
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 0;

  .section-header {
    display: flex;
    flex-direction: row-reverse;
    height: $item_size !important;
    font-size: 0.75em;
    border-bottom: 1px solid var(--secondary-background-color);;

    .section-title {
      margin-left: 4px;
      font-size: 1.1em;
    }
  }

  .section-body {
    overflow: auto;
    width: 100%;
    height: calc(100% - $item_size) !important;
  }
}
</style>
