import _ from "lodash"
import { iteratee } from "lodash";


export {}

export interface Dictionary<T> {
    [index: string]: T;
}

type NotVoid = unknown;
type PropertyName = string | number | symbol;
type PartialShallow<T> = {
    [P in keyof T]?: T[P] extends object ? object : T[P]
};
type IterateeShorthand<T> = PropertyName | [PropertyName, any] | PartialShallow<T>;
type ValueIteratee<T> = ((value: T) => NotVoid) | IterateeShorthand<T>;

declare global {
    interface Array<T> {
        /** @return this array */
        each(callbackFn: (value: T, index: number, array: T[]) => void): Array<T>

        /** @return this array */
        reverse_each(callbackFn: (value: T, index: number, array: T[]) => void): Array<T>

        /** shorthand of map function for mapping single object key or function */
        pluck<U extends keyof T>(attr: U extends ((...args: any[]) => infer R) ? never : U): { [K in U]: T[K] }[U] extends ((...args: any[]) => infer R) ? R[] : { [K in U]: T[K] }[U][];

        pluck<U extends keyof T>(attr: Array<U extends ((...args: any[]) => infer R) ? never : U>): Array<{ [K in U]: T[K] }[U] extends ((...args: any[]) => infer R) ? R[] : { [K in U]: T[K] }[U][]>;

        first(): T

        last(): T

        uniq(): Array<T>

        uniq_by(iteratee?: ValueIteratee<T>): Array<T>

        reject(predicate: (value: T, index: number, array: T[]) => any, thisArg?: any): T[];

        tally(): Dictionary<number>

        group(): Dictionary<T[]>

        group_by(iteratee?: ValueIteratee<T>): Dictionary<T[]>;

        group_by(iteratee?: ValueIteratee<T[keyof T]>): Dictionary<Array<T[keyof T]>>;

        sort_by(iteratee?: ValueIteratee<T>): T[]

        sum(): T

        clone(deep?: boolean): T[]

        /** Return the array without null and undefined elements */
        compact(): T[]

        max(): number

        min(): number

        sample(): T
    }
}

Array.prototype.pluck = function(attr: any | any[]): any[] | any[][] {
    return this.map((v) => {
        const get_value = (v: any, a: any) => {
            const r = v[a]
            if (r instanceof Function) {
                return v[a]()
            } else return r
        }
        if (attr instanceof Array) {
            return attr.map(a => get_value(v, a))
        } else {
            return get_value(v, attr)
        }
    })
}


Array.prototype.first = function <T>(this: T[]) {
    return this[0]
}

Array.prototype.last = function <T>(this: T[]) {
    return this[this.length - 1]
}

Array.prototype.each = function <T>(this: T[], callbackFn: (value: T, index: number, array: T[]) => void): T[] {
    this.forEach(callbackFn)
    return this
}

Array.prototype.reverse_each = function <T>(this: T[], predicate: (value: T, index: number, array: T[]) => void): T[] {
    this.slice().reverse().forEach(predicate)
    return this
}

Array.prototype.uniq = function() {
    return _.uniq(this)
}

Array.prototype.uniq_by = function <T>(iteratee: ValueIteratee<T>) {
    return _.uniqBy(this, iteratee)
}

Array.prototype.reject = function <T>(this: T[], predicate: (value: T, index: number, array: T[]) => any): T[] {
    return this.filter((value, index, array) => !predicate(value, index, array));
}

Array.prototype.tally = function <T>() {
    const grouped = this.group()
    const tally: Dictionary<number> = {}
    for (const key in grouped) {
        tally[key] = grouped[key].length
    }
    return tally
}

Array.prototype.group = function <T>() {
    return _.groupBy(this, (obj: ValueIteratee<T>) => obj) as Dictionary<T>
}

Array.prototype.group_by = function <T>(iteratee: ValueIteratee<T>) {
    return _.groupBy<T>(this, iteratee) as Dictionary<T>
}

Array.prototype.sort_by = function <T>(this: T[], by: (obj: T) => any): T[] {
    return _.sortBy(this, by)
}

Array.prototype.clone = function <T>(deep: boolean = false): T[] {
    if (deep) {
        return _.cloneDeep(this)
    } else {
        return _.clone(this)
    }
}

Array.prototype.sum = function <T>(): T {
    return this.reduce((total, current) => total + current, 0)
}

Array.prototype.compact = function <T>(): T[] {
    return this.filter(item => item != null)
}


Array.prototype.max = function(): number {
    return Math.max.apply(null, this);
}

Array.prototype.min = function() {
    return Math.min.apply(null, this);
};

Array.prototype.sample = function <T>(): T {
    return this[Math.floor(Math.random() * this.length)]
}
