<template>
  <div ref="loading_wrapper"
       class="loading-wrapper"
       :class="{inflate, fadein}"
       :style="style"
  >
    <!--<editor-fold desc="rotating_plane">-->
    <template v-if="type == 'rotating_plane'">
      <div class="rotating-plane"
           :class="color_class"
      />
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="double_bounce">-->
    <template v-if="type == 'double_bounce'">
      <div class="double-bounce">
        <div class="child double-bounce-1"
             :class="color_class"/>
        <div class="child double-bounce-2"
             :class="color_class"/>
      </div>
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="wave">-->
    <template v-if="type == 'wave'">
      <div class="wave">
        <template v-for="i in 5"
                  :key="i">
          <div :class="`rect-${i} rect ${color_class}`"/>
        </template>
      </div>
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="wandering_cubes">-->
    <template v-if="type == 'wandering_cubes'">
      <div class="wandering-cubes">
        <div class="cube cube-1"
             :class="color_class"/>
        <div class="cube cube-2"
             :class="color_class"/>
      </div>
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="spinner_pulse">-->
    <template v-if="type == 'spinner_pulse'">
      <div class="spinner spinner-pulse"
           :class="color_class"/>
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="chasing_dots">-->
    <template v-if="type == 'chasing_dots'">
      <div class="chasing-dots">
        <div class="child dot-1"
             :class="color_class"/>
        <div class="child dot-2"
             :class="color_class"/>
      </div>
    </template>
    <!--</editor-fold>-->

    <!--<editor-fold desc="three_bounce">-->
    <template v-if="type == 'three_bounce'">
      <div class="three-bounce">
        <template
            v-for="i in 3"
            :key="i">
          <div :class="`bounce-${i} child ${color_class}`"/>
        </template>
      </div>
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="circle_bounce">-->
    <template v-if="type == 'circle_bounce'">
      <div class="circle-bounce">
        <template v-for="i in 12"
                  :key="i">
          <div :class="`circle-${i} child ${color_class_before}`"/>
        </template>
      </div>
    </template>
    <!--</editor-fold>-->

    <!--<editor-fold desc="cube_grid">-->
    <template v-if="type == 'cube_grid'">
      <div class="cube-grid">
        <template v-for="i in 9"
                  :key="i">
          <div :class="`cube-${i} cube ${color_class}`"/>
        </template>
      </div>
    </template>
    <!--</editor-fold>-->

    <!--<editor-fold desc="fading_circle">-->
    <template v-if="type == 'fading_circle'">
      <div class="fading-circle">
        <template v-for="i in 12"
                  :key="i">
          <div :class="`circle-${i} circle ${color_class_before}`"/>
        </template>
      </div>
    </template>
    <!--</editor-fold>-->


    <!--<editor-fold desc="folding_cube">-->
    <template v-if="type == 'folding_cube'">
      <div class="folding-cube">
        <div class="cube cube-1"
             :class="color_class_before"/>
        <div class="cube cube-2"
             :class="color_class_before"/>
        <div class="cube cube-4"
             :class="color_class_before"/>
        <div class="cube cube-3"
             :class="color_class_before"/>
      </div>
    </template>
    <!--</editor-fold>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import { ColorClass } from "../../types/color_class";

// https://codepen.io/ibanez182/pen/YyJKde
export var Animation = {
    rotating_plane: "rotating_plane",
    double_bounce: "double_bounce",
    wave: "wave",
    wandering_cubes: "wandering_cubes",
    spinner_pulse: "spinner_pulse",
    chasing_dots: "chasing_dots",
    three_bounce: "three_bounce",
    circle_bounce: "circle_bounce",
    cube_grid: "cube_grid",
    fading_circle: "fading_circle",
    folding_cube: "folding_cube",
}

export type AnimationType =
    "rotating_plane"
    | "double_bounce"
    | "wave"
    | "wandering_cubes"
    | "spinner_pulse"
    | "chasing_dots"
    | "three_bounce"
    | "circle_bounce"
    | "cube_grid"
    | "fading_circle"
    | "folding_cube"

export default defineComponent({
    components: {},
    // <editor-fold desc="PROPS">
    props: {
        type: {
            type: String as PropType<AnimationType>,
            required: false,
            default: Animation.chasing_dots,
        },
        size: {
            type: Number,
            required: false,
            default: 1,
        },
        color: {
            type: String as PropType<ColorClass | string>,
            required: false,
            default: "default",
        },
        inflate: {
            type: Boolean,
            required: false,
            default: false,
        },
        fadein: {
            type: Boolean,
            required: false,
            default: true,
        },
        margin: {
            type: String,
            require: false,
            default: null,
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        style() {
            return { fontSize: `calc(1em * ${this.size})`, margin: this.margin }
        },
        color_class() {
            let clazz = "coloring "
            switch (this.color) {
                case "red":
                    clazz += "red";
                    break
                case "default":
                    clazz += "default"
                    break;
                case "blue":
                    clazz += "blue"
                    break;
                case "green":
                    clazz += "green"
                    break;
                case "grey":
                    clazz += "grey"
                    break;
                case "white":
                    clazz += "white"
                    break;
            }
            return clazz;
        },
        color_class_before() {
            return this.color_class.replace("coloring ", "coloring_before ")
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {},
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
$size: 1.3em;
$border-width: 0.03em;

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-inline: 5px;

  &.inflate {
    width: 100%;
    height: 100%;
    flex-shrink: 999;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.fadein {
    opacity: 0;
    animation: fadeInUp 1s ease-in-out 0s forwards;
  }

  .coloring_before {
    &::before {
      border: $border-width solid var(--primary-background-color);
      background-color: var(--secondary-background-color);
    }

    &.primary {
      &::before {
        border: $border-width solid var(--secondary-background-color);
        background-color: var(--primary-background-color);
      }
    }

    &.default {
      &::before {
        background-color: var(--secondary-background-color);
      }
    }

    &.grey {
      &::before {
        background-color: var(--button-grey);
      }
    }

    &.red {
      &::before {
        background-color: var(--button-red);
      }
    }

    &.green {
      &::before {
        background-color: var(--button-green);
      }
    }

    &.blue {
      &::before {
        background-color: var(--button-blue);
      }
    }

    &.white {
      &::before {
        background-color: var(--button-white) !important;
      }
    }
  }

  .coloring {
    border: $border-width solid var(--primary-background-color);
    background-color: var(--secondary-background-color);

    &.primary {
      background-color: var(--primary-background-color);
      border: $border-width solid var(--secondary-background-color);
    }

    &.default {
      background-color: var(--secondary-background-color);
    }

    &.grey {
      background-color: var(--button-grey);
    }

    &.red {
      background-color: var(--button-red);
    }

    &.green {
      background-color: var(--button-green);
    }

    &.blue {
      background-color: var(--button-blue);
    }

    &.white {
      background-color: var(--button-white) !important;
    }
  }


  // <editor-fold desc="rotating_plane">
  .rotating-plane {
    width: calc(calc($size / 1.2) - calc($border-width * 2));
    aspect-ratio: 1/1;
    animation: rotating-plane 1.2s infinite ease-in-out;
  }

  @keyframes rotating-plane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  // </editor-fold>

  // <editor-fold desc="double_bounce">
  .double-bounce {
    width: $size;
    aspect-ratio: 1/1;
    position: relative;
    margin: auto;

    .child {
      width: calc(100% - calc($border-width * 2));
      aspect-ratio: 1/1;
      border-radius: 50%;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      animation: double-bounce 2.0s infinite ease-in-out;
    }

    .double-bounce-2 {
      animation-delay: -1.0s;
    }
  }

  @keyframes double-bounce {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.0);
    }
  }
  // </editor-fold>

  // <editor-fold desc="wave">
  .wave {
    $rectCount: 5;
    $animationDuration: 1.2s;
    $delayRange: 0.4s;

    width: calc($size * 1.5);
    height: $size;
    margin: auto;
    text-align: center;
    font-size: 1em;

    .rect {
      height: calc(100% - calc($border-width * 2));
      aspect-ratio: 1/9;
      display: inline-block;
      animation: wave-stretch-delay $animationDuration infinite ease-in-out;
    }

    @for $i from 1 through $rectCount {
      .rect-#{$i} {
        animation-delay: - calc($animationDuration + $delayRange / ($rectCount - 1) * calc($i - 1));
      }
    }
  }

  @keyframes wave-stretch-delay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
    }
  }

  // </editor-fold>

  // <editor-fold desc="wandering_cubes">
  .wandering-cubes {
    $animationDuration: 1.8s;

    width: $size;
    aspect-ratio: 1/1;
    position: relative;
    margin: auto;

    .cube {
      width: calc(calc($size - calc($border-width * 2)) / 4);
      aspect-ratio: 1/1;
      position: absolute;
      top: 0;
      left: 0;
      animation: wandering-cubes $animationDuration ease-in-out #{-$animationDuration} infinite both;
    }

    .cube-2 {
      animation-delay: - calc($animationDuration / 2);
    }
  }

  @keyframes wandering-cubes {
    $cubeDistance: calc(calc($size - calc($border-width * 2)) / 2);
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: translateX($cubeDistance) rotate(-90deg) scale(0.5);
    }
    50% {
      /* Hack to make FF rotate in the right direction */
      transform: translateX($cubeDistance) translateY($cubeDistance) rotate(-179deg);
    }
    50.1% {
      transform: translateX($cubeDistance) translateY($cubeDistance) rotate(-180deg);
    }
    75% {
      transform: translateX(0) translateY($cubeDistance) rotate(-270deg) scale(0.5);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  // </editor-fold>

  // <editor-fold desc="spinner_pulse">
  .spinner-pulse {
    width: calc($size - calc($border-width * 2));
    aspect-ratio: 1/1;
    margin: auto;
    border-radius: 100%;
    animation: spinner-pulse 1.5s infinite ease-in-out;
    box-shadow: var(--secondary-background-color) 0 0 0 0;
  }

  @keyframes spinner-pulse {
    0% {
      transform: scale(0);
      box-shadow: #ff69b400 0 0 50px 100px;
      opacity: 0.75;
    }

    100% {
      transform: scale(1.0);
      box-shadow: var(--secondary-background-color) 0 0 0 0;
      opacity: 0;
    }
  }
  // </editor-fold>

  // <editor-fold desc="chasing_dots">
  .chasing-dots {
    $animationDuration: 2.0s;

    width: $size;
    aspect-ratio: 1/1;
    position: relative;
    margin: auto;
    text-align: center;
    animation: chasing-dots-rotate $animationDuration infinite linear;

    .child {
      width: calc(calc($size - calc($border-width * 2)) / 2);
      aspect-ratio: 1/1;
      display: inline-block;
      position: absolute;
      top: 0;
      border-radius: 100%;
      animation: chasing-dots-bounce $animationDuration infinite ease-in-out;
    }

    .dot-2 {
      top: auto;
      bottom: 0;
      animation-delay: - calc($animationDuration / 2);
    }
  }

  @keyframes chasing-dots-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes chasing-dots-bounce {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.0);
    }
  }

  // </editor-fold>

  // <editor-fold desc="three_bounce">
  .three-bounce {
    $animationDuration: 1.4s;
    $delayRange: 0.32s;
    width: calc($size * 2);
    margin: auto;
    text-align: center;

    .child {
      width: calc(calc($size - calc($border-width * 2)) / 2);
      aspect-ratio: 1/1;
      border-radius: 100%;
      display: inline-block;
      animation: three-bounce $animationDuration ease-in-out 0s infinite both;
    }

    .bounce-1 {
      animation-delay: -$delayRange;
    }

    .bounce-2 {
      animation-delay: - calc($delayRange / 2);
    }
  }

  @keyframes three-bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.0);
    }
  }
  // </editor-fold>

  // <editor-fold desc="circle_bounce">
  .circle-bounce {
    $circleCount: 12;
    $animationDuration: 1.2s;

    width: $size;
    aspect-ratio: 1/1;
    position: relative;
    margin: auto;

    .child {
      width: calc(100% - calc($border-width * 2));
      aspect-ratio: 1/1;
      position: absolute;
      left: 0;
      top: 0;
    }

    .child:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: calc(15% - calc($border-width * 2));
      aspect-ratio: 1/1;
      border-radius: 100%;
      animation: circle-bounce-delay $animationDuration infinite ease-in-out both;
    }

    @for $i from 2 through $circleCount {
      .circle-#{$i} {
        transform: rotate(calc(360deg / $circleCount * ($i - 1)));
      }
    }

    @for $i from 2 through $circleCount {
      .circle-#{$i}:before {
        animation-delay: -$animationDuration + calc($animationDuration / $circleCount * ($i - 1));
      }
    }
  }

  @keyframes circle-bounce-delay {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.0);
    }
  }

  // </editor-fold>

  // <editor-fold desc="cube_grid">
  .cube-grid {
    $delayRange: 0.4s;

    width: $size;
    aspect-ratio: 1/1;
    margin: auto;

    .cube {
      width: calc(33.3334% - calc($border-width * 4));
      aspect-ratio: 1/1;
      float: left;
      animation: cube-grid-scale-delay 1.3s infinite ease-in-out;
    }

    /*
     * Spinner positions
     * 1 2 3
     * 4 5 6
     * 7 8 9
     */

    .cube-1 {
      animation-delay: calc($delayRange * 0.50);
    }

    .cube-2 {
      animation-delay: calc($delayRange * 0.75);
    }

    .cube-3 {
      animation-delay: $delayRange;
    }

    .cube-4 {
      animation-delay: calc($delayRange * 0.25);
    }

    .cube-5 {
      animation-delay: calc($delayRange * 0.50);
    }

    .cube-6 {
      animation-delay: calc($delayRange * 0.75);
    }

    .cube-7 {
      animation-delay: 0s;
    }

    .cube-8 {
      animation-delay: calc($delayRange * 0.25);
    }

    .cube-9 {
      animation-delay: calc($delayRange * 0.50);
    }
  }

  @keyframes cube-grid-scale-delay {
    0%, 70%, 100% {
      transform: scale3D(1, 1, 1);
    }
    35% {
      transform: scale3D(0, 0, 1);
    }
  }

  // </editor-fold>

  // <editor-fold desc="fading_circle">
  .fading-circle {
    $circleCount: 12;
    $animationDuration: 1.6s;

    width: $size;
    aspect-ratio: 1/1;
    position: relative;
    margin: auto;

    .circle {
      width: calc(100% - calc($border-width * 2));
      aspect-ratio: 1/1;
      position: absolute;
      left: 0;
      top: 0;
    }

    .circle:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: calc(20% - calc($border-width * 2));
      aspect-ratio: 1/1;
      border-radius: 100%;
      animation: fading-circle-delay $animationDuration infinite ease-in-out both;
    }

    @for $i from 2 through $circleCount {
      .circle-#{$i} {
        transform: rotate(calc(360deg / $circleCount * ($i - 1)));
      }
    }

    @for $i from 2 through $circleCount {
      .circle-#{$i}:before {
        animation-delay: -$animationDuration + calc($animationDuration / $circleCount * ($i - 1));
      }
    }

  }

  @keyframes fading-circle-delay {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  // </editor-fold>

  // <editor-fold desc="folding_cube">
  .folding-cube {
    $cubeCount: 4;
    $animationDuration: 2.4s;
    $delayRange: calc($animationDuration / 2);

    width: $size;
    aspect-ratio: 1/1;
    position: relative;
    margin: auto;
    transform: rotateZ(45deg);

    .cube {
      float: left;
      width: calc(50% - calc($border-width * 2));
      aspect-ratio: 1/1;
      position: relative;
      transform: scale(1.1);
    }

    .cube:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - calc($border-width * 2));
      aspect-ratio: 1/1;
      animation: folding-cube-angle $animationDuration infinite linear both;
      transform-origin: 100% 100%;
    }

    // Rotation / angle
    @for $i from 2 through $cubeCount {
      .cube-#{$i} {
        transform: scale(1.1) rotateZ((90deg * ($i - 1)));
      }
    }

    @for $i from 2 through $cubeCount {
      .cube-#{$i}:before {
        animation-delay: calc($delayRange / $cubeCount * ($i - 1));
      }
    }
  }

  @keyframes folding-cube-angle {
    0%, 10% {
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }
    25%, 75% {
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }
    90%, 100% {
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }

  // </editor-fold>
}
</style>
