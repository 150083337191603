<template>
  <Modal
      id="new_proxy_modal"
      confirm_text="Create"
      :show_cancel="true"
      :focus_confirm="false"
      :cancel_on_escape="true"
      :form_validator="form_validator"
      :confirm_action="create_proxy"
      :focus_last_active_on_unmount="true"
      @cancel="$emit('exit')"
  >
    <template #header>
      <div class="title">
        New proxy
      </div>
    </template>

    <template #body="modal">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <Input
                id="proxy_name_input"
                v-model="proxy_props.name"
                :validator="form_validator.register('name', Proxy.field_validators.name)"
                :focus="true"
                label="Name"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s6">
            <Input
                id="proxy_ip_input"
                v-model="proxy_props.proxy_ip"
                :validator="form_validator.register('proxy_ip', Proxy.field_validators.proxy_ip)"
                label="Proxy IP"
            />
          </div>

          <div class="col s6">
            <Input
                id="proxy_port_input"
                v-model="proxy_props.proxy_port"
                :validator="form_validator.register('proxy_port', Proxy.field_validators.proxy_port)"
                label="Proxy Port"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s6">
            <Input
                id="proxy_username_input"
                v-model="proxy_props.username"
                label="Username"
            />
          </div>

          <div class="col s6">
            <Input
                id="password_input"
                v-model="proxy_props.password"
                label="Password"
                @enter="modal.confirm()"
            />
          </div>
        </div>
      </div>
    </template>

    <template #extra_buttons_left>
      <Button
          :form_validator="form_validator"
          text="Test"
          :min_width="70"
          @click="test_proxy"
      />
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Modal from "../../../testa/Modal.vue";
import Input from "../../../testa/Input.vue";
import Button from "../../../testa/Button.vue";
import { Project } from "../../../../vue_record/models/project";
import { PropType } from "vue";
import { ProxyCreateProps } from "../../../../vue_record/models/proxy";
import { Proxy } from "../../../../vue_record/models/proxy";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Button,
        Input,
        Modal,
    },
    // <editor-fold desc="PROPS">
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['exit'],
    // <editor-fold desc="DATA">
    data() {
        return {
            proxy_props: {
                name: "",
                proxy_ip: "",
                proxy_port: "",
                username: "",
                password: "",
                project_id: this.project.props.id,
            } as ProxyCreateProps,
            form_validator: new FormValidator(),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Proxy() {
            return Proxy
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {

    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        create_proxy() {
            return Proxy.ClientClass.create(this.proxy_props)
                        .then(() => this.$emit("exit"))
        },
        test_proxy() {
            Proxy.ClientClass.test(this.proxy_props, true)
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
