
require("codemirror/addon/comment/comment")

require("codemirror/addon/dialog/dialog")

require("codemirror/addon/display/fullscreen")
require("codemirror/addon/display/placeholder")
require("codemirror/addon/display/rulers")

require("codemirror/addon/edit/closebrackets")
require("codemirror/addon/edit/closetag")
require("codemirror/addon/edit/continuelist")
require("codemirror/addon/edit/matchbrackets")
require("codemirror/addon/edit/matchtags")
require("codemirror/addon/edit/trailingspace")

require("codemirror/addon/fold/brace-fold")
require("codemirror/addon/fold/comment-fold")
require("codemirror/addon/fold/foldcode")
require("codemirror/addon/fold/foldgutter")
require("codemirror/addon/fold/indent-fold")
require("codemirror/addon/fold/xml-fold")

require("codemirror/addon/hint/anyword-hint")

require("codemirror/addon/hint/show-hint")
require("./hint/ruby_watir_keywords.cjs")
require("./hint/ruby_appium_keywords.cjs")
require("./hint/ruby_keywords.cjs")
require("./hint/custom-hint.cjs")


require("codemirror/addon/lint/lint.js")
// require("codemirror/addon/lint/html-lint.js")
require("codemirror/addon/lint/css-lint.js")

// require("codemirror/addon/lint/javascript-lint.js")
require("./lint/jsonlint.js")
require("codemirror/addon/lint/json-lint.js")
window.jsyaml = require('js-yaml');
require("codemirror/addon/lint/yaml-lint.js")

require("codemirror/addon/merge/merge")
require("../../google-diff-match-patch.js")

require("codemirror/addon/scroll/simplescrollbars")
require("codemirror/addon/scroll/scrollpastend")

require("codemirror/addon/search/jump-to-line")
//require("codemirror/addon/search/search")
require("codemirror/addon/search/searchcursor")

require("codemirror/addon/selection/active-line")
require("codemirror/addon/selection/mark-selection")
