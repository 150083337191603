export type Order = [string, "asc" | "desc"]
export type Orders = Array<Order>

export class RecordOrder {
    orders: Orders
    single: boolean

    constructor(orders: Orders, single: boolean = null) {
        this.orders = orders;
        if (single == null) single = orders.length <= 1;
        this.single = single;
    }

    toggle(key: string) {
        const order = this.get(key);
        if (order == null) {
            const order: Order = [key, "asc"]
            if (this.single) {
                this.orders = [order]
            } else {
                this.orders.push(order)
            }
        } else if (order[1] == "asc") {
            order[1] = "desc"
        } else {
            this.orders = this.orders.filter(order => order[0] != key)
        }
    }

    get(key: string) {
        return this.orders.find(order => order[0] == key);
    }

    is_asc(key: string) {
        const order = this.get(key);
        if (order == null) return false
        return order[1] == "asc"
    }

    priority(key: string) {
        if (this.single) return ""
        for (let i = 0; i < this.orders.length; ++i) {
            if (this.orders[i][0] == key) return i + 1
        }
        return null
    }

    is_desc(key: string) {
        const order = this.get(key);
        if (order == null) return false
        return order[1] == "desc"
    }

    forEach(callback: (element: Order, index: number, array: Order[]) => void): void {
        for (let i = 0; i < this.orders.length; i++) {
            callback(this.orders[i], i, this.orders);
        }
    }
}
