export function get_selected_or_line_code(cm: CodeMirror.Editor) {
    const doc = cm.getDoc();
    const cursor = doc.getCursor();
    let code = doc.getSelection();
    const is_selected = code != "";
    if (!is_selected) {
        code = doc.getLine(cursor.line);
    }
    return code
}
