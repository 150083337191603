export function update_url_parameter(url: string, key: string, value: string | number) {
    let newAdditionalURL = "";
    let tempArray = url.split("?");
    const baseURL = tempArray[0];
    const additionalURL = tempArray[1];
    let temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] != key) {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }

    const rows_txt = temp + "" + key + "=" + value;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}

export function update_url_parameters(url: string, parameters: URLSearchParams) {
    const full_pathname = url.split("?")[0]
    if (parameters.size == 0) return full_pathname

    return `${full_pathname}?${parameters.toString()}`
}
