<template>
  <SettingsItem
      id="user_account"
      :title="is_my_account ? 'My Account' : `Edit ${user.props.first_name} ${user.props.last_name}`">
    <div id="user_edit"
         class="user-edit">
      <div v-if="navigated_from_table"
           class="header">
        <Button
            id="user_edit_back_button"
            text="Back"
            tab_index="1"
            @click="back"
        />
        <Button
            v-if="is_my_account || (!is_viewer_role && !is_user_role)"
            id="user_edit_save_button"
            text="Save"
            tab_index="2"
            :form_validator="form_validator"
            :click_action="save"
        />
        <div class="flex-expander"/>
        <Button
            v-if="is_my_account || (!is_viewer_role && !is_user_role)"
            id="user_edit_delete_button"
            text="Delete"
            tab_index="3"
            color_class="red"
            @click="delete_user"
        />
      </div>
      <div class="body">
        <div class="row">
          <div class="col s6">
            <Input
                id="user_account_first_name"
                v-model="update_user_props.first_name"
                label="First name"
                :disabled="(is_viewer_role || is_user_role) && !is_my_account"
                :validator="form_validator.register('user_first_name', User.field_validators.first_name)"
            />
          </div>
          <div class="col s6">
            <Input
                id="user_account_last_name"
                v-model="update_user_props.last_name"
                label="Last name"
                :disabled="(is_viewer_role || is_user_role) && !is_my_account"
                :validator="form_validator.register('user_last_name', User.field_validators.last_name)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s6">
            <Input
                id="user_account_username"
                v-model="update_user_props.username"
                label="Username"
                :disabled="(is_viewer_role || is_user_role) && !is_my_account"
                :validator="form_validator.register('user_username', { ...User.field_validators.username, callback: check_username })"
                :throttle_time="500"
            />
          </div>
          <div class="col s6">
            <Input
                id="user_account_email"
                v-model="update_user_props.email"
                label="Email"
                :disabled="(is_viewer_role || is_user_role) && !is_my_account"
                :validator="form_validator.register('user_email', {...User.field_validators.email, callback: check_email })"
                :throttle_time="500"
            />
          </div>
        </div>
        <div class="row">
          <div v-if="is_my_account || current.user.is_superadmin()"
               class="col s6">
            <Button
                id="invalidate_ci_tokens_button"
                color_class="red"
                text="Invalidate CI tokens"
                :disabled="(is_viewer_role || is_user_role) && !is_my_account"
                @click="() => user.invalidate_ci_tokens()"/>
          </div>
          <div v-if="is_my_account || current.user.is_superadmin()"
               class="col s6">
            <Checkbox id="change_user_password"
                      v-model="update_user_props.change_password"
                      label="Change password"
            />
          </div>
        </div>
        <div v-if="update_user_props.change_password"
             class="row">
          <div v-if="(current.user.is_superadmin() && is_my_account) || !current.user.is_superadmin()"
               class="col s4">
            <Input
                id="user_account_current_password"
                v-model="update_user_props.current_password"
                label="Current password"
                type="password"/>
          </div>
          <div class="col s4">
            <Input
                id="user_account_new_password"
                v-model="update_user_props.password"
                label="New password"
                type="password"
            />
          </div>
          <div class="col s4">
            <Input
                id="user_account_confirm_password"
                v-model="update_user_props.confirm_password"
                label="Confirm password"
                type="password"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s6">
            <UserProjectsTable
                v-if="!update_user_props.superadmin"
                :user_projects="update_user_projects"
                :user_props="user.props"
                :is_viewer_role="is_viewer_role"
                :is_user_role="is_user_role"
                :stage_id="stage_id"
                dropdown_parent="#vue_settings_modal"
            />
          </div>

          <div class="col s6">
            <Checkbox v-if="!is_my_account || update_user_props.locked"
                      v-model="update_user_props.locked"
                      :disabled="is_viewer_role || is_user_role || is_my_account"
                      label="Locked"/>
            <Checkbox v-if="user.props.confirmed_at == null && !is_my_account"
                      v-model="update_user_props.confirmed"
                      :disabled="is_viewer_role || is_user_role"
                      label="Confirmed"/>
            <Checkbox v-if="current.user.is_superadmin()"
                      v-model="update_user_props.superadmin"
                      label="Superadmin"/>
          </div>
        </div>
      </div>
    </div>
  </SettingsItem>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import toastr from "toastr";
import UserProjectsTable from "./UserProjectsTable.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Button from "../../../testa/Button.vue";
import Input from "../../../testa/Input.vue";
import SettingsItem from "../../SettingsItem.vue";
import { User } from "../../../../vue_record/models/user";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { UserUpdateProps } from "../../../../vue_record/models/user";
import { UserProjectProps } from "../../../../vue_record/models/user_project";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";
import { UserProject } from "../../../../vue_record/models/user_project";


export default defineComponent({
    components: {
        UserProjectsTable,
        Checkbox,
        Button,
        Input,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        user: {
            type: Object as PropType<User>,
            required: true,
        },
        navigated_from_table: {
            type: Boolean,
            required: false,
            default: false,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'exit'],
    // <editor-fold desc="DATA">
    data() {
        const stage_id = generate_uuid();
        return {
            stage_id,
            update_user_props: {
                id: this.user.props.id,
                first_name: this.user.props.first_name,
                last_name: this.user.props.last_name,
                username: this.user.props.username,
                email: this.user.props.email,
                current_password: "",
                password: "",
                confirm_password: "",
                change_password: false,
                superadmin: this.user.props.superadmin,
                locked: this.user.props.locked_at != null,
                confirmed: this.user.props.confirmed_at != null,
            } as UserUpdateProps,
            user_projects_scope: this.user.user_projects.stage(stage_id).order("created_at", "asc", "sensitive"),
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        User() {
            return User
        },
        current() {
            return current
        },
        update_user_projects() {
            return this.user_projects_scope.toArray();
        },
        is_my_account() {
            return current.user.props.id == this.user.props.id
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.user_projects_scope.dispose_stage()
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save() {
            const promise = this.apply()
            promise.then(() => {
                this.back()
            })
            return promise
        },
        apply() {
            if ((this.is_viewer_role || this.is_user_role) && !this.is_my_account) return null;

            let user_projects: UserProjectProps[] = null
            if (!this.is_my_account) user_projects = this.update_user_projects.map(up => up.props)

            return this.user
                       .client
                       .update(this.update_user_props, user_projects)
                       .catch(() => {
                           if (this.navigated_from_table) {
                               toastr.error("Failed to save user account")
                           } else {
                               toastr.error("Failed to save my account")
                           }
                       })
        },
        back() {
            this.$emit('exit')
        },
        delete_user() {
            User.delete(this.user.props.id).then((is_confirmed: Boolean) => {
                if (is_confirmed) this.back()
            })
        },
        check_username(username: string) {
            if (username == "") return null
            return new Promise<string>((resolve, reject) => {
                User.ClientClass.check_uniqueness({
                    user_id: this.user.props.id,
                    username,
                }).then((data: { username: boolean, email: boolean }) => {
                    if (!data.username) {
                        resolve("Username is already taken")
                    } else {
                        resolve(null)
                    }
                }).catch(() => {
                    reject(new Error("Ajax failed"))
                })
            })
        },
        check_email(email: string) {
            if (email == "") return null
            return new Promise<string>((resolve, reject) => {
                User.ClientClass.check_uniqueness({
                    user_id: this.user.props.id,
                    email,
                }).then((data: { username: boolean, email: boolean }) => {
                    if (!data.email) {
                        resolve("User with the given email already exists")
                    } else {
                        resolve(null)
                    }
                }).catch(() => {
                    reject(new Error("Ajax failed"))
                })
            })
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>

.user-edit {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;

  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .body {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
}
</style>
