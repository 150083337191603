import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a55b25fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  class: "scenario-editor-container"
}
const _hoisted_2 = {
  key: 0,
  class: "overlay"
}
const _hoisted_3 = { class: "left-corner" }
const _hoisted_4 = { class: "right-corner" }
const _hoisted_5 = {
  ref: "canvas_target",
  class: "canvas-target no-default-focus",
  tabindex: "-1"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading")
  const _component_ActionIcon = _resolveComponent("ActionIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.scenario_builder != null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.scenario_builder.computed.loading)
              ? (_openBlock(), _createBlock(_component_Loading, {
                  key: 0,
                  type: "fading_circle",
                  color: "white",
                  size: 1.1,
                  margin: "4px"
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ActionIcon, {
              margin: "0",
              icon_class: "fa-solid fa-rotate-left",
              title: "Undo",
              disabled: !_ctx.scenario_builder.allow_undo(),
              click_action: () => _ctx.scenario_builder.undo()
            }, null, 8 /* PROPS */, ["disabled", "click_action"]),
            _createVNode(_component_ActionIcon, {
              margin: "0",
              icon_class: "fa-solid fa-rotate-right",
              title: "Redo",
              disabled: !_ctx.scenario_builder.allow_redo(),
              click_action: () => _ctx.scenario_builder.redo()
            }, null, 8 /* PROPS */, ["disabled", "click_action"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_5, null, 512 /* NEED_PATCH */)
  ], 512 /* NEED_PATCH */))
}