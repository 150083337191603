import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ProjectVersionSetting } from "../models/project_version_setting";
import toastr from "toastr";
import { ProjectVersionSettingUpdateProps } from "../models/project_version_setting";
import { XrayUpdateProps } from "../models/project_version_setting";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ProjectVersionSettingClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ProjectVersionSetting
    declare record: ProjectVersionSetting

    update(project_version_setting: ProjectVersionSettingUpdateProps) {
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/project_version_settings/${this.key()}`,
                type: "PATCH",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    project_version_setting,
                }),
                success: () => {
                    resolve(null)
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes,
            })
        })
    }

    static test_xray(xray_props: XrayUpdateProps, show_notification = false) {
        interface Response {
            success: boolean
            error: string
            tests_retrieved: { [key: string]: number }
        }

        let notification: JQuery;
        if (show_notification) {
            notification = toastr.info("Testing...", null, {
                timeOut: 60000,
                progressBar: true,
            })
        }
        return new Promise<Response>((resolve, reject) => {
            $.ajax({
                url: `/settings/project_settings/integrations/xray/test`,
                type: "POST",
                processData: false,
                contentType: "application/json",
                data: JSON.stringify({
                    authenticity_token,
                    xray: xray_props,
                }),
                success(test: Response) {
                    resolve(test)
                    if (!show_notification) return;
                    notification.remove();
                    if (test.success) {
                        toastr.success("Xray is working!")
                    } else {
                        toastr.error("Xray is not working!")
                    }
                },
                error(error) {
                    reject(error)
                    if (!show_notification) return;
                    notification.remove();
                    toastr.error("Xray is not working!")
                },
                statusCode: ajax_status_codes,
            })
        })
    }
}
