import { Tab } from "../tab";
import ScenarioBuilderVue from "../editors/scenario/ScenarioBuilder.vue";
import { Scenario } from "../../../../vue_record/models/scenario";
import { computed } from "vue";
import { UnwrapNestedRefs } from "vue";
import { markRaw } from "vue";
import { reactive } from "vue";
import { get_css_var } from "../../../../helpers/generic/get_css_var";
import { ScenarioBuilder } from "../editors/scenario/scenario_builder";
import { BoundingBoxWH } from "cytoscape";
import { Snippet } from "../../../../vue_record/models/snippet";
import { Coords } from "../../../../types/globals";
import { on_dom_content_loaded } from "../../../../helpers/events/dom_content_loaded";
import { Editor } from "../editor";
import { ProjectVersion } from "../../../../vue_record/models/project_version";

namespace ScenarioBuilderTab {
    export interface Input extends Tab.Input {
        project_version_id: number
        scenario_ids: number[]
        merged: boolean
    }

    export interface State extends Tab.State {
        records: Scenario[],
        scenario_builder: ScenarioBuilder
    }
}

export class ScenarioBuilderTab extends Tab {
    static type = "ScenarioBuilderTab"

    type: string
    scenario_ids: number[]
    project_version_id: number

    declare state: ScenarioBuilderTab.State


    constructor(data: ScenarioBuilderTab.Input) {
        super(data)
        this.type = ScenarioBuilderTab.type
        this.scenario_ids = data.scenario_ids;
        this.project_version_id = data.project_version_id
        this.state.icon.class = Scenario.icon_class
        this.state.icon.color = get_css_var("--scenario-color")

        this.component = markRaw(ScenarioBuilderVue);
        this._set_and_call_load_function(() => {
            const project_version = ProjectVersion.find(this.project_version_id)
            return Scenario.ClientClass
                           .batch_load(this.scenario_ids)
                           .then(scenarios => {
                               this.state.records = scenarios.toArray();

                               this.computed.title = computed(() => {
                                   if (this.state.records.length > 1) {
                                       return "Multiple scenarios"
                                   } else if (this.state.records.length == 1) {
                                       return this.state.records[0].props.name
                                   } else {
                                       return "No Scenarios"
                                   }
                               }) as unknown as UnwrapNestedRefs<string>
                               this.component_props = reactive({ scenarios, tab: this, merged: data.merged, project_version })
                           })
                           .catch((error) => {
                               this._on_load_error(error)
                           })
                           .finally(() => {
                               this.set_loaded(true)
                           })
        })

        this.dnd = reactive({})
        this.on("editor_mounted", () => {
            this.state.scenario_builder.on("scenario_added", (sb) => {
                this.input.scenario_ids = sb.state.scenarios.map(s => s.key())
                this.state.records = sb.state.scenarios as Scenario[]
            })
            this.state.scenario_builder.on("scenario_removed", (sb) => {
                this.input.scenario_ids = sb.state.scenarios.map(s => s.key())
                this.state.records = sb.state.scenarios as Scenario[]
            })
            this.state.scenario_builder.on("merge_changed", (sb) => {
                this.input.merged = sb.options.merged
            })

            this.state.scenario_builder.on("layoutstop", (sb: ScenarioBuilder, box: BoundingBoxWH) => {
                if (this.state.scenario_builder.nodes_count() > 0) {
                    const width = box.w + cy_cfg.node.width / 2 + 5;
                    this.get_editor()?.set_size({
                        height: null,
                        width
                    })
                }
            })
        })
    }

    _register_default_event_handlers() {
        super._register_default_event_handlers();
        this.on("dragover", (tab: Tab, e: DragEvent) => {
            if (this.state.scenario_builder == null) return

            const snippets = dnd.state.records.filter(r => r instanceof Snippet)
            const scenarios = dnd.state.records.filter(r => r instanceof Scenario)
            if (snippets.length + scenarios.length == 1) {
                const coords: Coords = this.state.scenario_builder.get_coords_from_event(e)
                this.state.scenario_builder.show_dragover_autoconnect_dot(coords)
            }
        })

        this.on("dragleave", (_tab: Tab, _e: DragEvent) => {
            if (this.state.scenario_builder == null) return

            this.state.scenario_builder.remove_autoconnect_dot()
        })

        this.on("drop", (_tab: Tab, e: DragEvent) => {
            if (this.state.scenario_builder == null) return

            const snippets = dnd.state.records.filter(r => r instanceof Snippet) as Snippet[]
            const scenarios = dnd.state.records.filter(r => r instanceof Scenario) as Scenario[]
            if (snippets.length + scenarios.length == 1) {
                const coords: Coords = this.state.scenario_builder.get_coords_from_event(e)
                this.state.scenario_builder.import_new_node(snippets[0], scenarios[0], coords)
            }
        })
    }
}

Tab.register_tab_type(ScenarioBuilderTab)

on_dom_content_loaded(() => {
    let resize_timeout: NodeJS.Timeout = null
    window.addEventListener("resize", () => {
        clearTimeout(resize_timeout)
        resize_timeout = setTimeout(() => {
            const tab = Editor.get_scenario_builder_tab()
            if (tab == null) return;

            const box = tab.state.scenario_builder.get_rendered_bounding_box();
            let width = box.w + cy_cfg.node.width / 4;
            if (width < 150) width = 150
            tab.get_editor()?.set_size({
                height: null,
                width
            })
        }, 300)
    })
})
