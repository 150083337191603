import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { PhoneProject } from "../models/phone_project";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class PhoneProjectClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PhoneProject
    declare record: PhoneProject

}
