import { VueRecordClient } from "../base/vue_record_client";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { ScenarioSetting } from "../models/scenario_setting";
import { ScenarioSettingProps } from "../models/scenario_setting";
import { CacheStoreValue } from "../base/vue_record_client";

const console = new Consoler("warn")
export class ScenarioSettingClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof ScenarioSetting
    declare record: ScenarioSetting

    static load(id: number): Promise<ScenarioSetting> {
        return this.request<ScenarioSettingProps>({
            url: `/scenario_settings/${id}`,
            type: "GET",
            cache: true
        }).then(props => ScenarioSetting.new(props))
    }
}
