import CodeMirror from "codemirror";
import { Snippet } from "../../vue_record/models/snippet";
import { File } from "../../vue_record/models/file";

export function track_local_changes(cm: CodeMirror.Editor, snippet_or_file: Snippet | File) {
    cm.on("changes", (cm, changes) => {
        changes.filter(c => c.origin != "setValue")
               .filter(c => c.origin != "merge")
               .forEach(c => {
                   snippet_or_file.state.local_changes.push(c)
               })
    })
}
