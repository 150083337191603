import { Tab } from "../tab";
import FileEditor from "../editors/file/FileEditor.vue";
import { File } from "../../../../vue_record/models/file";
import { markRaw } from "vue";
import { reactive } from "vue";
import { WatchStopHandle } from "vue";
import CodeMirror from "codemirror";
import { watch } from "vue";
import { computed } from "../../../../helpers/vue/computed";

namespace FileTab {
    export interface Input extends Tab.Input {
        file_path: string
    }

    export interface State extends Tab.State {
        record: File,
        cm: CodeMirror.Editor
        key_change_watcher: WatchStopHandle
    }
}

export class FileTab extends Tab {
    static type = "FileTab"

    type: string
    file_path: string

    declare state: FileTab.State

    constructor(data: FileTab.Input) {
        super(data)
        this.type = FileTab.type
        this.file_path = data.file_path;
        this.state.icon.class = "fa-regular fa-file"
        this.component = markRaw(FileEditor);

        // if title is known, set it before we load, so that tabs are sorted correctly
        const file = File.find(this.file_path)
        if (file != null) {
            this.computed.title = computed(() => file.props.name)
        }

        this._set_and_call_load_function(() => {
            return File.ClientClass
                       .load(this.file_path)
                       .then(file => {
                           this.state.record = file

                           this.computed.title = computed(() => file.props.name)
                           this.component_props = { file }
                           this.state.key_change_watcher = watch(() => file.props.path, () => {
                               this.id = file._file_tab_id()
                               this.input.id = file._file_tab_id();
                               this.file_path = file.key();
                               this.input.file_path = file.key();
                           })

                           this.on("before_close", () => this.state.key_change_watcher())
                       })
                       .catch((error) => {
                           this._on_load_error(error)
                       })
                       .finally(() => {
                           this.set_loaded(true)
                       })
        })
        this.dnd = reactive({})
    }
}

Tab.register_tab_type(FileTab)
