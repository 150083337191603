import { create_vue_app } from "../../helpers/vue/create_vue_app";
import Application from "../../components/layout/Application.vue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { update_url_parameter } from "../../helpers/generic/update_url_parameter";

on_dom_content_loaded(() => {
    require('../../vue_record/relations/index')
    require('../../vue_record/relations/index')
})

window.addEventListener("load", () => {
    create_vue_app(Application, {}, document.getElementById("application"))
})

watch(() => current.project_version?.props?.id,
    (project_version_id) => {
    if (project_version_id != null) {
        const url = update_url_parameter(window.location.href, "project_version_id", project_version_id)
        window.history.pushState('', '', url)
    } else {
        window.history.pushState('', '', window.location.href)
    }
    },
    {
        immediate: true
    }
)

export {}
