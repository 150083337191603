export {}

declare global {
    interface Error {
        show_toaster(overrides?: ToastrOptions): void
    }
}

Error.prototype.show_toaster = function(overrides: ToastrOptions = {}) {
    const message = this.message == null ? "Internal error" : this.message
    toastr.error(null, message, overrides)
}
