export type Timeout = {
    clear: () => void
    trigger: () => void
}

export function create_timeout(timeoutHandler: () => void, delay: number): Timeout {
    const timeoutId = setTimeout(timeoutHandler, delay);
    let cleared = false
    return {
        clear: function() {
            cleared = true;
            clearTimeout(timeoutId);
        },
        trigger: function() {
            clearTimeout(timeoutId);
            if (cleared) return;

            return timeoutHandler();
        }
    };
}
