import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28803b9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "dnd-drop-areas-container"
}
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Splitter = _resolveComponent("Splitter", true)
  const _component_TabManager = _resolveComponent("TabManager")
  const _component_RFlex = _resolveComponent("RFlex")

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.splitter.id,
    class: "splitter-container"
  }, [
    (_ctx.display_dnd_drop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "dnd-over-drop-area main",
            onDragenter: _cache[0] || (_cache[0] = (e) => _ctx.splitter._emit('dragenter', e)),
            onDragover: _cache[1] || (_cache[1] = (e) => _ctx.splitter._emit('dragover', e)),
            onDragleave: _cache[2] || (_cache[2] = (e) => _ctx.splitter._emit('dragleave', e)),
            onDrop: _cache[3] || (_cache[3] = (e) => _ctx.splitter._on_drag_drop(_ctx.splitter, e))
          }, null, 32 /* NEED_HYDRATION */)
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_RFlex, { rflex: _ctx.rflex }, _createSlots({ _: 2 /* DYNAMIC */ }, [
      (_ctx.splitter.state.main.object != null)
        ? {
            name: "main",
            fn: _withCtx(() => [
              _createElementVNode("div", {
                id: _ctx.splitter.state.main.id,
                style: {"width":"100%","height":"100%"}
              }, [
                (_ctx.splitter.state.main.object instanceof _ctx.Splitter)
                  ? (_openBlock(), _createBlock(_component_Splitter, {
                      key: 0,
                      splitter: _ctx.splitter.state.main.object
                    }, null, 8 /* PROPS */, ["splitter"]))
                  : (_ctx.splitter.state.main.object instanceof _ctx.TM )
                    ? (_openBlock(), _createBlock(_component_TabManager, {
                        key: 1,
                        tab_manager: _ctx.splitter.state.main.object
                      }, null, 8 /* PROPS */, ["tab_manager"]))
                    : _createCommentVNode("v-if", true)
              ], 8 /* PROPS */, _hoisted_3)
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.splitter.state.other.object != null)
        ? {
            name: "other",
            fn: _withCtx(() => [
              _createElementVNode("div", {
                id: _ctx.splitter.state.other.id,
                style: {"width":"100%","height":"100%"}
              }, [
                (_ctx.splitter.state.other.object instanceof _ctx.Splitter)
                  ? (_openBlock(), _createBlock(_component_Splitter, {
                      key: 0,
                      splitter: _ctx.splitter.state.other.object
                    }, null, 8 /* PROPS */, ["splitter"]))
                  : (_ctx.splitter.state.other.object instanceof _ctx.TM )
                    ? (_openBlock(), _createBlock(_component_TabManager, {
                        key: 1,
                        tab_manager: _ctx.splitter.state.other.object
                      }, null, 8 /* PROPS */, ["tab_manager"]))
                    : _createCommentVNode("v-if", true)
              ], 8 /* PROPS */, _hoisted_4)
            ]),
            key: "1"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["rflex"])
  ], 8 /* PROPS */, _hoisted_1))
}