import { StaticState, VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { WorkerClient } from "../../clients/non_db/worker_client";
import { WorkerScope } from "../../scopes/non_db/worker_scope";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { RecordOpts } from "../../base/vue_record";
import { SpinnerSettingProps } from "../spinner_setting";
import ConfirmDialogue from "../../../components/testa/confirm_dialogue/confirm_dialgue";

// <editor-fold desc="TYPES">
export interface WorkerProps extends Props {
    private_sha: string
    dj_tag: string
    sikuli_tag: string
    name: string
    node_name: string
    jobless: boolean
    status: string
    ready: string
    created: string
    resources: {
        memory_request: string
        memory_limit: string
        memory_used: string
        cpu_request: string
        cpu_limit: string
        cpu_used: string
    }
}
export type QuerifiedWorkerProps = QuerifyProps<WorkerProps>
export type WorkerCreateProps = Omit<WorkerProps, 'private_sha'>
export type WorkerUpdateProps = Partial<WorkerProps>

export interface WorkerState extends State {
    logs: Array<{ log: string, key: string }>
}
export interface WorkerComputed extends Computed {}
export interface WorkerStaticState extends StaticState {
    all_workers_count: number
    standby_workers_count: number
}

export interface WorkersInfo {
    all_workers: WorkerProps[]
    worker_count: {
        all_workers_count: number
        standby_workers_count: number
    }
    spinner_setting?: SpinnerSettingProps
}
// </editor-fold>

export class Worker extends VueRecord {
    ['constructor']: typeof Worker

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = WorkerClient
    static ScopeClass = WorkerScope
    static readonly primary_key = "private_sha"
    static sync_channels: string[] = []
    static state: WorkerStaticState = reactive<WorkerStaticState>({
        all_workers_count: 0,
        standby_workers_count: 0
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "status"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Worker> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Worker>> = {}

    static field_validators: ModelValidatorOpts<WorkerProps> = {}

    static resource_name = Enum.Resource.Label.WORKER
    static resource_id = Enum.Resource.Id.WORKER
    static icon_class = "fa-regular fa-note-sticky"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: WorkerClient
    declare props: WorkerProps;
    declare state: WorkerState;
    declare computed: WorkerComputed;

    // </editor-fold>

    constructor(props: Props, opts: RecordOpts) {
        super(props, opts);
        this.state.logs = []
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    static delete(ids: string | string[]) {
        if (!Array.isArray(ids)) ids = [ids]
        const scope = this.where({ private_sha: ids })
        const content_text = scope.delete_warning_text()
        return new Promise<boolean>((resolve, reject) => {
            ConfirmDialogue.show({
                html: content_text,
                confirm_color_class: "red",
                confirm_action: () => {
                    return this.ClientClass.delete(ids).then(() => resolve(true)).catch((e) => reject(e))
                },
                abort_job: () => {
                    resolve(false)
                    return null
                }
            })
        })
    }
}

// <editor-fold desc="INIT">
Worker.register_resource(Worker)
WorkerClient.ModelClass = Worker
WorkerScope.ModelClass = Worker


declare global {
    interface Window {
        TestaWorker: typeof Worker
    }
}
window.TestaWorker = Worker
// </editor-fold>

