<template>
  <div class="tab">
    <div class="row">
      <div class="col s12">
        <Checkbox
            id="xray_enabled_checkbox"
            v-model="xray_params.xray_enabled"
            :disabled="is_viewer_role || is_user_role"
            label="Enabled"
        />
      </div>
    </div>

    <template v-if="xray_params.xray_enabled">
      <div class="row">
        <div class="col s12">
          <Input
              id="xray_client_id_input"
              v-model="xray_params.xray_client_id"
              label="Client ID"
              :disabled="is_viewer_role || is_user_role"
              :validator="form_validator.register('xray_client_id', ProjectVersionSetting.field_validators.xray_client_id)"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <Input
              id="xray_client_id_input"
              v-model="xray_params.xray_client_secret"
              label="Client Secret"
              :disabled="is_viewer_role || is_user_role"
              :validator="form_validator.register('xray_client_secret', ProjectVersionSetting.field_validators.xray_client_secret)"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <Input
              id="xray_project_keys_input"
              v-model="xray_params.xray_project_keys"
              label="Project keys (separated by ;)"
              :disabled="is_viewer_role || is_user_role"
              :validator="form_validator.register('xray_project_keys', ProjectVersionSetting.field_validators.xray_project_keys)"
          />
        </div>

        <div class="col s6">
          <Select2
              id="xray_automatic_report_generation_select"
              v-model="xray_params.xray_automatic_report_generation"
              :validator="form_validator.register('xray_automatic_report_generation', ProjectVersionSetting.field_validators.xray_automatic_report_generation)"
              :disabled="is_viewer_role || is_user_role"
              label="Automatic reports"
          >
            <option :value="Enum.XrayAutomaticReportGeneration.SCENARIO">Scenarios and groups</option>
            <option :value="Enum.XrayAutomaticReportGeneration.GROUP"
                    selected="selected">
              Only for groups
            </option>
            <option :value="Enum.XrayAutomaticReportGeneration.DISABLED">Disabled</option>
          </Select2>
        </div>
      </div>

      <div class="row">
        <div class="col s12 align-center">
          <Button
              id="xray_test_integration_button"
              :form_validator="form_validator"
              text="Test Integration"
              @click="test"
          />

          <div class="integration-test-status">
            <Loading
                v-if="test_in_progress"
                color="white"/>
            <template v-else-if="tested && test_response.success">
              <i class="fas fa-check"
                 style="color: var(--button-green)"/>
            </template>
            <template v-else-if="tested && !test_response.success">
              <i class="fas fa-times"
                 style="color: var(--button-red)"/>
            </template>
          </div>
        </div>
      </div>
      <div v-if="tested"
           class="row">
        <div class="col s12 vertical">
          <template v-if="test_response.success">
            <template v-for="(tests, project) in test_response.tests_retrieved"
                      :key="project">
              <div>{{ tests }} for project {{ project }}</div>
            </template>
          </template>
          <template v-else>
            {{ test_response.error }}
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Select2 from "../../../testa/Select2.vue";
import Button from "../../../testa/Button.vue";
import Checkbox from "../../../testa/Checkbox.vue";
import Input from "../../../testa/Input.vue";
import { PropType } from "vue";
import { ProjectVersionSetting } from "../../../../vue_record/models/project_version_setting";
import { XrayUpdateProps } from "../../../../vue_record/models/project_version_setting";
import Loading from "../../../testa/Loading.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";

export default defineComponent({
    components: {
        Loading,
        Input,
        Checkbox,
        Button,
        Select2,
    },
    // <editor-fold desc="PROPS">
    props: {
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        },
        is_user_role: {
            type: Boolean,
            required: true,
        },
    },
    // </editor-fold>
    emits: ['setting-item-unmounted', 'setting-item-mounted'],
    // <editor-fold desc="DATA">
    data() {
        return {
            xray_params: {
                id: this.project_version_setting.props.id,
                xray_enabled: this.project_version_setting.props.xray_enabled,
                xray_client_id: this.project_version_setting.props.xray_client_id,
                xray_client_secret: this.project_version_setting.props.xray_client_secret,
                xray_project_keys: this.project_version_setting.props.xray_project_keys,
                xray_automatic_report_generation: this.project_version_setting.props.xray_automatic_report_generation,
            } as XrayUpdateProps,
            tested: false,
            test_in_progress: false,
            test_response: null,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        Enum() {
            return Enum
        },
        ProjectVersionSetting() {
            return ProjectVersionSetting
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save() {
            return this.apply()
        },
        apply() {
            return this.project_version_setting
                       .client
                       .update(this.xray_params)
                       .catch(() => toastr.error("Failed to save xray settings"))
        },
        test() {
            if (this.test_in_progress) return;
            this.test_in_progress = true;
            ProjectVersionSetting.ClientClass
                                 .test_xray(this.xray_params, false)
                                 .then((data) => {
                                     this.test_response = data
                                 })
                                 .finally(() => {
                                     this.tested = true;
                                     this.test_in_progress = false
                                 })
        },
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.integration-test-status {
  margin-left: 15px;
  font-size: 1.3em;
  display: flex;
  align-items: center;
}
</style>
