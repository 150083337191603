import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { get_css_var } from "../../helpers/generic/get_css_var";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumScenarioSettingSelectedNoReset } from "../../auto_generated/enums";
import { ScenarioSettingClient } from "../clients/scenario_setting_client";
import { ScenarioSettingScope } from "../scopes/scenario_setting_scope";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { PhoneProps } from "./scenario_setting/phone";
import { BrowserProps } from "./scenario_setting/browser";
import { ProxyProps } from "./proxy";

// <editor-fold desc="TYPES">
export interface ScenarioSettingProps extends Props {
    id: number
    scenario_id: number
    web_module_active: boolean
    mobile_module_active: boolean
    proxy_id: number
    selected_no_reset: EnumScenarioSettingSelectedNoReset
    xvfb_width: number
    xvfb_height: number
    scenario_savepoint_id: number
}
export type QuerifiedScenarioSettingProps = QuerifyProps<ScenarioSettingProps>
export type ScenarioSettingCreateProps = Omit<ScenarioSettingProps, 'id'>
export type ScenarioSettingUpdateProps = Partial<ScenarioSettingProps>

export interface ScenarioSettingState extends State {}
export interface ScenarioSettingComputed extends Computed {}
export interface ScenarioSettingStaticState extends StaticState {
    load_promises: Record<number | string, Promise<ScenarioSetting>>
}

export interface ExtendedScenarioSettingProps extends ScenarioSettingProps {
    phones: PhoneProps[]
    browsers: BrowserProps[]
    proxy: ProxyProps
}
// </editor-fold>

const console = new Consoler("warn")
export class ScenarioSetting extends VueRecord {
    ['constructor']: typeof ScenarioSetting

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = ScenarioSettingClient
    static ScopeClass = ScenarioSettingScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: ScenarioSettingStaticState = reactive<ScenarioSettingStaticState>({
        load_promises: {}
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "scenario_id"),
        VueRecordIndex.new(this, "scenario_savepoint_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof ScenarioSetting> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof ScenarioSetting>> = {}

    static field_validators: ModelValidatorOpts<ScenarioSettingProps> = {}

    static resource_name = Enum.Resource.Label.SCENARIO_SETTING
    static resource_id = Enum.Resource.Id.SCENARIO_SETTING
    static icon_class = "fa-solid fa-wrench"
    static color = () => get_css_var("--scenario-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: ScenarioSettingClient
    declare props: ScenarioSettingProps;
    declare state: ScenarioSettingState;
    declare computed: ScenarioSettingComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
ScenarioSetting.register_resource(ScenarioSetting)
ScenarioSettingClient.ModelClass = ScenarioSetting
ScenarioSettingScope.ModelClass = ScenarioSetting

global_event_bus.$on("after_project_version_unload", () => {
    ScenarioSetting.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project_version?.props?.id,
        (project_version_id) => {
            ScenarioSetting.unsync()
            if (project_version_id != null) ScenarioSetting.sync(`/sync/project_version/${project_version_id}/scenario_settings`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        ScenarioSetting: typeof ScenarioSetting
    }
}
window.ScenarioSetting = ScenarioSetting
// </editor-fold>

