import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36152728"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-container" }
const _hoisted_2 = { class: "title-primary-info" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "name" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: "no-default-focus",
        href: _ctx.image.computed.download_path,
        style: {"width":"100%","text-decoration":"none"},
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.image.props.name), 1 /* TEXT */)
        ])
      ], 8 /* PROPS */, _hoisted_3)
    ])
  ]))
}