import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { LogFileFolder } from "../../models/non_db/log_file_folder";
import { CacheStoreValue } from "../../base/vue_record_client";
import { LogFileFolderChildren } from "../../models/non_db/log_file_folder";
import { LogFileFolderProps } from "../../models/non_db/log_file_folder";
import { LogFileScope } from "../../scopes/non_db/log_file_scope";
import { LogFile } from "../../models/non_db/log_file";
import { generate_resolved_promise } from "../../../helpers/generate/generate_resolved_promise";
import { LogFileProps } from "../../models/non_db/log_file";
import { what_is_it } from "../../../helpers/generic/what_is_it";

const console = new Consoler("warn")
export class LogFileFolderClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof LogFileFolder
    declare record: LogFileFolder


    children(reload = false): Promise<LogFileFolderChildren> {
        if (reload) LogFileFolder.state.children_promises[this.key()] = null

        if (LogFileFolder.state.children_promises[this.key()] == null) {
            return LogFileFolder.ClientClass.children(this.key(), reload)
        } else {
            return LogFileFolder.state.children_promises[this.key()]
        }
    }

    static children(log_file_folder_path: string, reload = false): Promise<LogFileFolderChildren> {
        if (reload) LogFileFolder.state.children_promises[log_file_folder_path] = null

        if (LogFileFolder.state.children_promises[log_file_folder_path] == null) {
            type ChildrenResponse = {
                files: LogFileProps[],
                file_folders: LogFileFolderProps[],
            }

            const promise: Promise<LogFileFolderChildren> = new Promise<LogFileFolderChildren>((resolve, reject) => {
                $.ajax({
                    url: `/testa/logs/children`,
                    data: {
                        log_file_folder_path
                    },
                    type: 'GET',
                    success: (data: ChildrenResponse) => {
                        const promise_response: LogFileFolderChildren = {
                            files: [],
                            file_folders: []
                        }
                        data.files.forEach(file_prop => {
                            promise_response.files.push(LogFile.new(file_prop))
                        })
                        data.file_folders.forEach(file_folder_prop => {
                            promise_response.file_folders.push(LogFileFolder.new(file_folder_prop))
                        })
                        resolve(promise_response)
                    },
                    error: (error) => {
                        reject(error)
                    },
                    statusCode: ajax_status_codes,
                })
            })
            LogFileFolder.state.children_promises[log_file_folder_path] = promise
            promise.catch(() => {
                LogFileFolder.state.children_promises[log_file_folder_path] = null
            })
            return promise
        } else {
            return LogFileFolder.state.children_promises[log_file_folder_path]
        }
    }

    static batch_path(file_paths: string | string[]) {
        let paths: string[];
        if (what_is_it(file_paths) == "Array") {
            paths = file_paths as string[]
        } else {
            paths = [file_paths as string]
        }

        type PathResponse = {
            [key: string]: LogFileFolderProps[]
        }

        type PromiseResponse = {
            [key: string]: LogFileFolder[]
        }

        return new Promise<PromiseResponse>((resolve, reject) => {
            // $.ajax({
            //     url: `/file_folders/batch/path`,
            //     type: "POST",
            //     processData: false,
            //     contentType: "application/json",
            //     data: JSON.stringify({
            //         paths,
            //         authenticity_token,
            //     }),
            //     statusCode: ajax_status_codes,
            //     success: (data: PathResponse) => {
            //         const promise_response: PromiseResponse = {}
            //         Object.keys(data).forEach(file_folder_path => {
            //             const file_folders_props = data[file_folder_path];
            //             const array: LogFileFolder[] = []
            //             file_folders_props.forEach(file_folder_props => {
            //                 array.push(LogFileFolder.new(file_folder_props))
            //             })
            //             promise_response[file_folder_path] = array
            //         })
            //         resolve(promise_response)
            //     },
            //     error: (error) => {
            //         reject(error)
            //     },
            // })
        })
    }


    static batch_download(log_file_folders: LogFileFolder[], log_files: LogFile[]) {
        const search_params = new URLSearchParams({
            file_paths: JSON.stringify(log_files.map(f => f.props.path)),
            file_folder_paths: JSON.stringify(log_file_folders.map(ff => ff.props.path)),
        })

        // TODO: return $.fileDownload(`/log_files/batch/download?${search_params.toString()}`)
    }

    download() {
        return $.fileDownload(this.record.computed.download_path)
    }
}
