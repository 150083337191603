import { VueRecord } from "../base/vue_record";
import { Props } from "../base/vue_record";
import { State } from "../base/vue_record";
import { StaticState } from "../base/vue_record";
import { BelongsToAssociations } from "../base/vue_record";
import { HasManyAssociations } from "../base/vue_record";
import { HasOneAssociations } from "../base/vue_record";
import { HasManyThroughAssociations } from "../base/vue_record";
import { ModelValidatorOpts } from "../../helpers/validator/validator";
import { Computed } from "../base/vue_record";
import { VueRecordStore } from "../base/vue_record_store";
import { VueRecordIndex } from "../base/vue_record_index";
import { reactive } from "../../helpers/vue/reactive";
import { TestaTree } from "../../components/testa/tree/tree";
import { Consoler } from "../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../base/vue_record_scope";
import { EnumMobileProvisioningType } from "../../auto_generated/enums";
import { MobileProvisioningClient } from "../clients/mobile_provisioning_client";
import { MobileProvisioningScope } from "../scopes/mobile_provisioning_scope";
import ConfirmDialogue from "../../components/testa/confirm_dialogue/confirm_dialgue";
import { on_dom_content_loaded } from "../../helpers/events/dom_content_loaded";
import { watch } from "vue";

// <editor-fold desc="TYPES">
export interface MobileProvisioningProps extends Props {
    id: number
    name: string
    team_identifier: string
    application_identifier: string
    embedded: boolean
    expiration_date: Date
    project_id: number
    created_at: Date
    updated_at: Date
    type: EnumMobileProvisioningType
}
export type QuerifiedMobileProvisioningProps = QuerifyProps<MobileProvisioningProps>
export type MobileProvisioningCreateProps = Omit<MobileProvisioningProps, 'id'>
export type MobileProvisioningUpdateProps = Partial<MobileProvisioningProps>

export interface MobileProvisioningState extends State {}
export interface MobileProvisioningComputed extends Computed {}
export interface MobileProvisioningStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class MobileProvisioning extends VueRecord {
    ['constructor']: typeof MobileProvisioning

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = MobileProvisioningClient
    static ScopeClass = MobileProvisioningScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: MobileProvisioningStaticState = reactive<MobileProvisioningStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_id", "app_type", "package"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof MobileProvisioning> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof MobileProvisioning>> = {}

    static field_validators: ModelValidatorOpts<MobileProvisioningProps> = {}

    static resource_name = Enum.Resource.Label.MOBILE_PROVISIONING
    static resource_id = Enum.Resource.Id.MOBILE_PROVISIONING
    static icon_class = "fa-solid fa-cube"
    static color = () => "white"
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: MobileProvisioningClient
    declare props: MobileProvisioningProps;
    declare state: MobileProvisioningState;
    declare computed: MobileProvisioningComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = null): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="ACTIONS">
    static delete(ids: number | number[]) {
        if (!Array.isArray(ids)) ids = [ids]
        const mobile_provisions_scope = this.where({ id: ids })
        const content_text = mobile_provisions_scope.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => {
                return this.ClientClass.delete(ids)
            }
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
MobileProvisioning.register_resource(MobileProvisioning)
MobileProvisioningClient.ModelClass = MobileProvisioning
MobileProvisioningScope.ModelClass = MobileProvisioning

global_event_bus.$on("after_project_version_unload", () => {
    MobileProvisioning.get_scope().unload()
})

on_dom_content_loaded(() => {
    watch(
        () => current.project?.props?.id,
        (project_id) => {
            MobileProvisioning.unsync()
            if (project_id != null) MobileProvisioning.sync(`/sync/project/${project_id}/mobile_provisionings`)
        },
        {
            flush: "sync",
            immediate: true
        }
    )
})

declare global {
    interface Window {
        MobileProvisioning: typeof MobileProvisioning
    }
}
window.MobileProvisioning = MobileProvisioning
// </editor-fold>

