import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c4ddacb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "row",
  style: {"display":"flex"}
}
const _hoisted_2 = {
  class: "col s8",
  style: {"display":"flex"}
}
const _hoisted_3 = { class: "col s4" }
const _hoisted_4 = ["data-priority", "onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["title", "innerHTML"]
const _hoisted_7 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_Input = _resolveComponent("Input")
  const _component_Table = _resolveComponent("Table")
  const _component_NewProjectForm = _resolveComponent("NewProjectForm")
  const _component_SettingsItem = _resolveComponent("SettingsItem")
  const _component_ProjectEdit = _resolveComponent("ProjectEdit")
  const _directive_moment = _resolveDirective("moment")

  return (_ctx.show_table)
    ? (_openBlock(), _createBlock(_component_SettingsItem, {
        key: 0,
        id: "all_projects",
        title: _ctx.setting_items.superadmin.all_projects.title
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.is_viewer_role && !_ctx.is_user_role)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    text: "New Project",
                    onClick: _ctx.new_project
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Input, {
                modelValue: _ctx.filter,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.filter) = $event)),
                placeholder: "Filter",
                throttle_time: 200
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _createVNode(_component_Table, { id: "projects_table" }, {
            thead: _withCtx(() => [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_columns, (col, key) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: key,
                    class: _normalizeClass({asc: _ctx.orders.is_asc(key), desc: _ctx.orders.is_desc(key), orderable: col.orderable, 'action-width': col.action}),
                    "data-priority": _ctx.orders.priority(key),
                    onClick: $event => (_ctx.orders.toggle(key))
                  }, _toDisplayString(col.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_4))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            tbody: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ordered_rows, (row) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: row.record.props.id,
                  class: "project-row",
                  onClick: $event => (_ctx.show_project(row.record))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cols, (col, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("td", {
                      key: index,
                      title: col.title,
                      class: _normalizeClass(col.classes),
                      innerHTML: col.html
                    }, null, 10 /* CLASS, PROPS */, _hoisted_6)), [
                      [_directive_moment, col.moment]
                    ])
                  }), 128 /* KEYED_FRAGMENT */))
                ], 8 /* PROPS */, _hoisted_5))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.show_new_project)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_NewProjectForm, {
                  onClose: _cache[1] || (_cache[1] = $event => (_ctx.show_new_project = false))
                })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["title"]))
    : (_openBlock(), _createBlock(_component_ProjectEdit, {
        key: 1,
        project: _ctx.edit_project,
        is_viewer_role: _ctx.is_viewer_role,
        is_user_role: _ctx.is_user_role,
        form_validator: _ctx.form_validator.register_child_form('project_edit'),
        onExit: _cache[2] || (_cache[2] = $event => (_ctx.show_table=true)),
        onSettingItemMounted: _cache[3] || (_cache[3] = (component) => _ctx.$emit('setting-item-mounted', component)),
        onSettingItemUnmounted: _cache[4] || (_cache[4] = (component) => _ctx.$emit('setting-item-unmounted', component))
      }, null, 8 /* PROPS */, ["project", "is_viewer_role", "is_user_role", "form_validator"]))
}