import { CSSProperties } from "vue";

export function select2_option_with_icon(text: string, icon: string, color: string = null) {
    const icon_style: CSSProperties = {
        margin: "2px",
        fontSize: "0.9em",
        color,
    }

    const icon_container_style: CSSProperties = {
        display: "flex",
        flexDirection: "row",
        width: "1.3em",
        justifyContent: "center",
        alignItems: "center",
    }

    const $icon = $(`<i class="${icon}"></i>`)
    // @ts-ignore
    $icon.css(icon_style)
    const $icon_container = $(`<span></span>`)
    $icon_container.append($icon)
    // @ts-ignore
    $icon_container.css(icon_container_style)

    const $container = $(`<span></span>`)
    const container_style: CSSProperties = {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline"
    }
    // @ts-ignore
    $container.css(container_style)
    $container.append($icon_container)

    const $text = $(`<span>${text}</span>`)
    $container.append($text)

    return $container
}
