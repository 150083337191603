import _ from "lodash";
import { reactive } from "vue";
import { on_dom_content_loaded } from "../../../helpers/events/dom_content_loaded";

export type ProgressBarCreateOpts = {
    bar?: {
        height?: number
        color?: string
        finished_delay_ms?: number
    },
    progress?: {
        value?: number
        color?: string
    }

}

const default_create_opts: ProgressBarCreateOpts = {
    bar: {
        height: 3,
        color: "transparent",
        finished_delay_ms: 3000,
    },
    progress: {
        color: "rgba(71, 170, 70, 0.76)",
        value: 0
    }
}

export class ProgressBar {
    bar: {
        color: string
        height: number
        finished_delay_ms: number
    }

    progress: {
        color: string,
        value: number
    }

    finished_timeout: NodeJS.Timeout

    constructor(opts: ProgressBarCreateOpts = {}) {
        opts = _.merge(_.cloneDeep(default_create_opts), opts)
        this.progress = {
            color: opts.progress.color,
            value: opts.progress.value
        }

        this.bar = {
            color: opts.bar.color,
            height: opts.bar.height,
            finished_delay_ms: opts.bar.finished_delay_ms,
        }

        this.finished_timeout = null
    }

    go(percentage: number) {
        this.progress.value = percentage

        if (this.progress.value >= 100) {
            clearTimeout(this.finished_timeout)
            this.finished_timeout = setTimeout(() => {
                this.progress.value = 0
            }, this.bar.finished_delay_ms)
        }
    }

    stop() {
        clearTimeout(this.finished_timeout)
        this.progress.value = 0
    }

    static new(opts: ProgressBarCreateOpts = {}): ProgressBar {
        return reactive(new this(opts))
    }
}

declare global {
    let progress_bar: ProgressBar

    interface Window {
        progress_bar: ProgressBar
    }
}
window.progress_bar = null;


on_dom_content_loaded(() => {
    window.progress_bar = ProgressBar.new()
})
