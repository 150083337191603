import { reactive as vue_reactive } from "vue";
import { UnwrapNestedRefs } from "vue";


export function reactive<T extends object>(target: T): T {
    return vue_reactive(target) as any as T
}

export function reactive_unwrap<T extends object>(target: T): UnwrapNestedRefs<T> {
    return vue_reactive(target)
}
