<template>
  <SettingsItem
      :id="setting_items.project.variables.id"
      :title="setting_items.project.variables.title"
      padding="10px 0 0 0"
  >
    <div class="row nowrap"
         style="margin-top: -20px; margin-bottom: 0;">
      <div class="col s3">
        <Select2
            id="variable_sets_select"
            v-model="active_variable_set_id"
            dropdown_parent="#vue_settings_modal"
            label="Variable set"
        >
          <option :value="default_variable_set.props.id">{{ default_variable_set.props.name }}</option>
          <template v-for="variable_set in variable_sets"
                    :key="variable_set.props.id"
          >
            <option :value="variable_set.props.id">{{ variable_set.props.name }}</option>
          </template>
        </Select2>
      </div>
      <div class="col s6">

        <ActionIcon
            id="add_variable_set_action"
            icon_class="fas fa-plus-circle"
            color_class="green"
            title="Add Variable Set"
            @click="show_create_modal = true"
        />
        <template v-if="!active_variable_set?.props.default">
          <ActionIcon
              id="rename_variable_set_action"
              icon_class="fas fa-pencil-alt"
              color_class="white"
              title="Rename Variable Set"
              @click="show_rename_modal = true"
          />

          <ActionIcon
              id="remove_variable_set_action"
              icon_class="fas fa-trash-alt"
              color_class="red"
              title="Delete Variable Set"
              @click="remove_variable_set"
          />
        </template>
      </div>
      <div class="col s3">
        <template v-if="current.user.props.dark_mode">
          <Select2
              id="codemirror_themes_select"
              v-model="user_props.codemirror_dark_theme"
              dropdown_parent="#vue_settings_modal"
              label="Theme"
          >
            <template v-for="theme in Enum.User.CodeMirrorThemes.DARK"
                      :key="theme.id">
              <option :value="theme.id">{{ theme.name }}</option>
            </template>
          </Select2>
        </template>
        <template v-else>
          <Select2
              id="codemirror_themes_select"
              v-model="user_props.codemirror_light_theme"
              dropdown_parent="#vue_settings_modal"
              label="Theme"
          >
            <template v-for="theme in Enum.User.CodeMirrorThemes.LIGHT"
                      :key="theme.id">
              <option :value="theme.id">{{ theme.name }}</option>
            </template>
          </Select2>
        </template>
      </div>
    </div>
    <div class="row"
         style="height: calc(100% - 45px);"
    >
      <template v-for="variable_set in all_variable_sets"
                :key="variable_set.props.id">
        <div v-show="variable_set.props.id == active_variable_set_id"
             v-if="visited_variable_set_ids.includes(variable_set.props.id)"
             class="col s12 vertical"
             style="height: 100%; width: 100%; padding: 0;">
          <SnippetEditor
              v-if="variable_set.snippet != null"
              :snippet="variable_set.snippet"
              :exclude_features="['debounced_save']"
              :exclude_actions="['save']"
          />
        </div>
      </template>

    </div>
    <CreateVariableSetModal
        v-if="show_create_modal"
        :project_version="project_version"
        @exit="on_create_exit"
    />
    <RenameVariableSetModal
        v-if="show_rename_modal"
        :variable_set="active_variable_set"
        @exit="show_rename_modal = false"
    />
  </SettingsItem>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";
import SettingsItem from "../../SettingsItem.vue";
import { ProjectVersion } from "../../../../vue_record/models/project_version";
import Select2 from "../../../testa/Select2.vue";
import ActionIcon from "../../../testa/ActionIcon.vue";
import { generate_uuid } from "../../../../helpers/generate/generate_uuid";
import RenameVariableSetModal from "./RenameVariableSetModal.vue";
import CreateVariableSetModal from "./CreateVariableSetModal.vue";
import { FormValidator } from "../../../../helpers/validator/form_validator";
import { VariableSet } from "../../../../vue_record/models/variable_set";
import SnippetEditor from "../../../testa/editor/editors/snippet/SnippetEditor.vue";
import { VariableSetScope } from "../../../../vue_record/scopes/variable_set_scope";
import { nextTick } from "vue";


export default defineComponent({
    components: {
        SnippetEditor,
        RenameVariableSetModal,
        CreateVariableSetModal,
        ActionIcon,
        Select2,
        SettingsItem,
    },
    // <editor-fold desc="PROPS">
    props: {
        project_version: {
            type: Object as PropType<ProjectVersion>,
            required: true,
        },
        form_validator: {
            type: Object as PropType<FormValidator>,
            required: true,
        },
        is_viewer_role: {
            type: Boolean,
            required: true,
        }
    },
    // </editor-fold>
    emits: ['setting-item-mounted', 'setting-item-unmounted'],
    // <editor-fold desc="DATA">
    data() {
        const stage_id = generate_uuid()
        return {
            component_id: generate_uuid(),
            stage_id,
            loaded: false,
            active_variable_set_id: null as number,
            user_props: {
                codemirror_dark_theme: current.user.props.codemirror_dark_theme,
                codemirror_light_theme: current.user.props.codemirror_light_theme,
            },
            visited_variable_set_ids: [] as number[],
            show_create_modal: false,
            show_rename_modal: false,
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        Enum() {
            return Enum
        },
        setting_items() {
            return setting_items
        },
        default_variable_set(): VariableSet {
            return this.staged_variable_sets.where({ default: true }).first();
        },
        staged_variable_sets(): VariableSetScope {
            return this.project_version.variable_sets.stage(this.stage_id)
        },
        all_variable_sets(): VariableSet[] {
            return this.staged_variable_sets.toArray()
        },
        variable_sets(): VariableSet[] {
            return this.staged_variable_sets.where({ default: false }).order("name").toArray();
        },
        active_variable_set() {
            return this.all_variable_sets.find(vs => vs.key() == this.active_variable_set_id)
        },
        visited_variable_sets() {
            return this.all_variable_sets.filter(vs => vs.key() == this.active_variable_set_id)
        }
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {
        "active_variable_set_id"() {
            this.visit_variable_set(this.active_variable_set_id);
        },
        'user_props.codemirror_dark_theme'() {
            if (current.user.props.dark_mode) {
                this.visited_variable_sets.forEach(vs => {
                    vs.snippet.state.codemirrors.forEach(cm => cm.setOption("theme", this.user_props.codemirror_dark_theme))
                })
            }
        },
        'user_props.codemirror_light_theme'() {
            if (!current.user.props.dark_mode) {
                this.visited_variable_sets.forEach(vs => {
                    vs.snippet.state.codemirrors.forEach(cm => cm.setOption("theme", this.user_props.codemirror_light_theme))
                })
            }
        },
    },
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeMount() {
        this.visit_variable_set(this.default_variable_set?.key())
    },
    mounted() {
        this.$emit("setting-item-mounted", this)
    },
    unmounted() {
        this.$emit("setting-item-unmounted", this)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        visit_variable_set(variable_set_id: number) {
            if (variable_set_id == null) return;

            this.active_variable_set_id = variable_set_id
            if (!this.visited_variable_set_ids.includes(variable_set_id)) {
                this.visited_variable_set_ids.push(variable_set_id);
            }
        },
        save() {
            return this.apply()
        },
        apply() {
            const user_update_promise = current.user.client.update(this.user_props, null)
            const variable_set_promises: Promise<any>[] = []
            if (!this.is_viewer_role) {
                this.all_variable_sets
                    .filter(vs => this.visited_variable_set_ids.includes(vs.props.id))
                    .forEach(vs => {
                        variable_set_promises.push(vs.client.update({
                            id: vs.props.id,
                            snippet_attributes: {
                                id: vs.props.snippet_id,
                                code: vs.snippet.state.code_not_committed
                            },
                        }))
                    })
            }
            return Promise.all([variable_set_promises, user_update_promise].flat()).catch(() => {
                toastr.error("Failed to save variable sets")
            })
        },
        remove_variable_set() {
            this.active_variable_set.destroy()
        },
        on_create_exit(variable_set: VariableSet) {
            this.show_create_modal = false
            if (variable_set != null) {
                variable_set.stage(this.stage_id)
                nextTick(() => {
                    this.active_variable_set_id = variable_set.props.id
                })
            }
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
</style>
