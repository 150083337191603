import { GroupScope } from "../scopes/group_scope";
import { GroupFolderScope } from "../scopes/group_folder_scope";
import { User } from "../models/user";
import { ProjectVersion } from "../models/project_version";
import { GroupFolder } from "../models/group_folder";
import { Group } from "../models/group";

declare module "../models/group_folder" {
    interface GroupFolder {
        user: User
        project_version: ProjectVersion
        parent_folder: GroupFolder

        groups: GroupScope
        group_folders: GroupFolderScope
    }
}

GroupFolder.belongs_to("user", User, "user_id")
GroupFolder.belongs_to("project_version", ProjectVersion, "project_version_id")
GroupFolder.belongs_to("parent_folder", GroupFolder, "group_folder_id")


GroupFolder.has_many("groups", Group, "group_folder_id")
GroupFolder.has_many("group_folders", GroupFolder, "group_folder_id")
