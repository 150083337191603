import { VueRecordScope } from "../base/vue_record_scope";
import { VariableSet } from "../models/variable_set";
import { computed } from "../../helpers/vue/computed";

export class VariableSetScope extends VueRecordScope {
    ['constructor']: typeof VariableSetScope
    static ModelClass: typeof VariableSet
    declare rthis: VariableSetScope

    default = computed(() => this.rthis.where({ default: true }))
}
