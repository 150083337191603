// noinspection JSStringConcatenationToES6Template

const foregroundColors = {
    '30': 'black',
    '31': 'red',
    '32': 'green',
    '33': 'yellow',
    '34': 'var(--log-color-debug)',
    '35': 'purple',
    '36': 'cyan',
    '37': 'white'
};

const COMMANDS = {
    '0': () => {
        return { color: null, background_color: null, text_decoration: null, font_weight: null, font_style: null }
    },
    '1': () => {
        return { font_weight: "bold" }
    },
    '2': () => {
        return {}
    }, // dark / dim
    '3': () => {
        return { font_style: "italic" }
    },
    '4': () => {
        return { text_decoration: "underline" }
    },
    '7': () => {
        return {}
    }, // inverse
    '8': () => {
        return {}
    }, // hidden
    '9': () => {
        return { text_decoration: "line-through" }
    },

    // colors
    '30': () => {
        return { color: "var(--ansi-black)" }
    },
    '31': () => {
        return { color: "var(--ansi-red)" }
    },
    '32': () => {
        return { color: "var(--ansi-green)" }
    },
    '33': () => {
        return { color: "var(--ansi-yellow)" }
    },
    '34': () => {
        return { color: "var(--ansi-blue)" }
    },
    '35': () => {
        return { color: "var(--ansi-magenta)" }
    },
    '36': () => {
        return { color: "var(--ansi-cyan)" }
    },
    '37': () => {
        return { color: "var(--ansi-white)" }
    },

    '90': () => {
        return { color: "var(--ansi-bright-black)" }
    },
    '91': () => {
        return { color: "var(--ansi-bright-red)" }
    },
    '92': () => {
        return { color: "var(--ansi-bright-green)" }
    },
    '93': () => {
        return { color: "var(--ansi-bright-yellow)" }
    },
    '94': () => {
        return { color: "var(--ansi-bright-blue)" }
    },
    '95': () => {
        return { color: "var(--ansi-bright-magenta)" }
    },
    '96': () => {
        return { color: "var(--ansi-bright-cyan)" }
    },
    '97': () => {
        return { color: "var(--ansi-bright-white)" }
    },

    // background
    '40': () => {
        return { background_color: "var(--ansi-black)" }
    },
    '41': () => {
        return { background_color: "var(--ansi-red)" }
    },
    '42': () => {
        return { background_color: "var(--ansi-green)" }
    },
    '43': () => {
        return { background_color: "var(--ansi-yellow)" }
    },
    '44': () => {
        return { background_color: "var(--ansi-blue)" }
    },
    '45': () => {
        return { background_color: "var(--ansi-magenta)" }
    },
    '46': () => {
        return { background_color: "var(--ansi-cyan)" }
    },
    '47': () => {
        return { background_color: "var(--ansi-white)" }
    },

    '100': () => {
        return { background_color: "var(--ansi-bright-black)" }
    },
    '101': () => {
        return { background_color: "var(--ansi-bright-red)" }
    },
    '102': () => {
        return { background_color: "var(--ansi-bright-green)" }
    },
    '103': () => {
        return { background_color: "var(--ansi-bright-yellow)" }
    },
    '104': () => {
        return { background_color: "var(--ansi-bright-blue)" }
    },
    '105': () => {
        return { background_color: "var(--ansi-bright-magenta)" }
    },
    '106': () => {
        return { background_color: "var(--ansi-bright-cyan)" }
    },
    '107': () => {
        return { background_color: "var(--ansi-bright-white)" }
    },
} as Record<string, () => Partial<AnsiObject>>

const ANSI_ESCAPE_SEQUENCE = '\x1B'
const ANSI_COMMAND_START = '\\['
const ANSI_COMMAND_END = 'm'

export type AnsiObject = {
    str: string,
    color: string,
    font_weight: string
    font_style: string
    text_decoration: string
    background_color: string
}

const regex = new RegExp(`${ANSI_ESCAPE_SEQUENCE}${ANSI_COMMAND_START}`)
const commands_regex = new RegExp(`(\\d+)(?:;(\\d+))*${ANSI_COMMAND_END}`)

export function ansi_objects(str: string): AnsiObject[] {
    let ansi_objects: AnsiObject[] = []
    let current_ansi: AnsiObject = {
        str,
        color: null,
        font_weight: null,
        font_style: null,
        text_decoration: null,
        background_color: null
    }
    ansi_objects.push(current_ansi)

    let match;
    while ((match = regex.exec(current_ansi.str)) !== null) {
        const remaining_string = current_ansi.str.substring(match.index + match[0].length)
        current_ansi.str = current_ansi.str.substring(0, match.index)

        const commands = commands_regex.exec(remaining_string)
        if (commands != null && commands.length > 1) {
            const ansi_commands = commands.slice(1).filter(s => s != null)
            let new_ansi: AnsiObject = { ...current_ansi }
            new_ansi.str = remaining_string.substring(commands[0].length)
            ansi_commands.each(command => {
                if (COMMANDS[command] == null) {
                    console.warn(`missing ansi command for code ${command}`)
                } else {
                    new_ansi = _.merge(new_ansi, COMMANDS[command]())
                }
            })
            current_ansi = new_ansi
            ansi_objects.push(new_ansi)
        } else break;
    }
    return ansi_objects
}

export function ansi_object_css(ao: AnsiObject) {
    let style = ""
    if (ao.color != null) style += `color: ${ao.color};`
    if (ao.background_color != null) style += `background-color: ${ao.background_color};`
    if (ao.font_weight != null) style += `font-weight: ${ao.font_weight};`
    if (ao.font_style != null) style += `font-style: ${ao.font_style}`;
    if (ao.text_decoration != null) style += `text-decoration: ${ao.text_decoration};`
    return style
}

export function ansispan(str: string) {
    return ansi_objects(str)
        .filter(ao => ao.str.length > 0)
        .map(ao => {
            const style = ansi_object_css(ao)
            return `<span style="${style}">${ao.str}</span>`
        }).join("")
}
