import { LogFileFolder } from "../../models/non_db/log_file_folder";
import { LogFile } from "../../models/non_db/log_file";

declare module "../../models/non_db/log_file" {
    interface LogFile {
        log_file_folder: LogFileFolder
    }
}

LogFile.belongs_to("log_file_folder", LogFileFolder, "log_file_folder_path")
