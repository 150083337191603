<template>
  <div ref="groups_scenario"
       class="groups-scenario">
    <ActionIcon
        icon_class="fa-solid fa-times"
        color_class="red"
        title="Remove"
        :click_action="() => groups_scenario.client.destroy()"
    />

    <span class="real-order-text">
      {{ index + 1 }}.
    </span>
    <span
        class="scenario-name"
        @click="groups_scenario.scenario.open()"
    >
      {{ groups_scenario.scenario.name() }}
    </span>

    <div class="flex-expander"/>
    <div class="actions">
      <ActionIcon
          icon_class="fa-solid fa-caret-up fa-xl"
          color_class="white"
          title="Move Up"
          :scale="0.8"
          padding="3px 0 0 0"
          :disabled="is_first"
          :click_action="() => groups_scenario.move_up()"
      />

      <ActionIcon
          icon_class="fa-solid fa-caret-up fa-rotate-180 fa-xl"
          color_class="white"
          title="Move Down"
          :scale="0.8"
          padding="0 0 3px 0"
          :disabled="is_last"
          :click_action="() => groups_scenario.move_down()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GroupsScenario } from "../../../../../vue_record/models/groups_scenario";
import { PropType } from "vue";
import ActionIcon from "../../../ActionIcon.vue";

export default defineComponent({
    components: { ActionIcon },
    // <editor-fold desc="PROPS">
    props: {
        groups_scenario: {
            type: Object as PropType<GroupsScenario>,
            required: true
        },
        is_first: {
            type: Boolean,
            required: true,
        },
        is_last: {
            type: Boolean,
            required: true,
        },
        index: {
            type: Number,
            required: true
        }
    },
    // </editor-fold>
    emits: [],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        const groups_scenario_element = this.$refs.groups_scenario as HTMLElement
        groups_scenario_element.dataset.groups_scenario_id = this.groups_scenario.key().toString();
    },
    unmounted() {

    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {},
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.groups-scenario {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;
  cursor: pointer;

  .real-order-text {
    margin-right: 5px;
  }


  .scenario-name {
    color: var(--button-blue);
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    display: flex;
    flex-direction: row;
    pointer-events: none;
    opacity: 0;
    flex-shrink: 0;
  }

  &:hover {
    .actions {
      pointer-events: auto;
      opacity: 1;
    }

    background-color: var(--ternary-background-color);

    .scenario-name {
      text-decoration: underline;
    }
  }
}
</style>
