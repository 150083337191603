import { Tab } from "./tab";
import { computed, reactive } from "vue";
import _ from "lodash";
import { SplitterRegion } from "./splitter_region";
import { Splitter } from "./splitter";
import { EventBus } from "../../../helpers/event_bus";
import { Editor } from "./editor";
import { generate_eid } from "../../../helpers/generate/generate_eid";
import { safe_stringify } from "../../../helpers/generic/safe_stringify";

export namespace TabManager {
    export type Position = "top" | "right" | "bottom" | "left"
    export type Input = {
        id: string
        parent: SplitterRegion
        tabs?: Tab[]
        position?: Position
        single_mode?: boolean
        max_tabs?: number
        sort?: "alphabetical" | "recent"
        active_tab_id?: string
    }

    export type State = {
        tabs: Tab[]
        is_drag_over: boolean
        position: Position,
        single_mode: boolean
        max_tabs: number
        sort: "alphabetical" | "recent",
        active_tab: Tab
    }

    export type Events = "removed" | "active_tab_change" | "dragenter" | "dragover" | "dragleave" | "drop"
}


export class TabManager {
    parent: SplitterRegion
    static height = 22
    id: string

    event_bus: EventBus<TabManager.Events, TabManager>
    tab_event_bus: EventBus<Tab.Events, Tab>

    state = reactive<TabManager.State>({
        tabs: [],
        is_drag_over: true,
        position: "top",
        single_mode: false,
        max_tabs: 20,
        sort: "alphabetical",
        active_tab: null,
    }) as TabManager.State

    computed = reactive({
        sorted_tabs: computed(() => {
            if (this.state.sort == "alphabetical") {
                return _.sortBy(this.state.tabs, (obj) => obj.computed.title)
            } else if (this.state.sort == "recent") {
                return _.sortBy(this.state.tabs, (obj) => obj.state.last_activation)
            } else return this.state.tabs
        }),
        activity_sorted_tabs: computed(() => {
            return _.sortBy(this.state.tabs, (obj) => obj.state.last_activation)
        })
    })

    constructor(data: TabManager.Input) {
        this.id = data.id
        this.set_parent(data.parent)
        this.event_bus = new EventBus<TabManager.Events, TabManager>()
        this.tab_event_bus = new EventBus<Tab.Events, Tab>()
        this._register_default_event_handlers()

        if (data.position) this.state.position = data.position
        if (data.single_mode != null) this.state.single_mode = data.single_mode
        if (this.state.single_mode) {
            if (this.state.position == "left" || this.state.position == "right") {
                this.state.position = "top"
            }
            this.state.max_tabs = 1;
        }
        if (data.tabs && data.tabs.length >= 0) {
            data.tabs.forEach(t => this.add_tab(t))
        }

        if (this.state.tabs.length > 0) {
            const to_activate = this.state.tabs.find(t => t.id == data.active_tab_id)
            if (data.active_tab_id != null && to_activate != null) {
                this.set_active_tab(to_activate);
            } else {
                this.set_active_tab(this.state.tabs[0])
            }
        }
    }

    // <editor-fold desc="ACTIONS">
    remove() {
        this.parent.splitter.remove_region(this)
    }

    add_tab(tab: Tab) {
        if (tab.tab_manager?.id == this.id) return;

        // when we have max_tabs == 1,
        // we must first add tab, then close, because, otherwise on tab removal,
        // tab manager would be removed from splitter region
        tab.tab_manager?.remove_tab(tab)
        tab._set_tab_manager(this);
        this.state.tabs.push(tab)

        while (this.state.max_tabs < this.state.tabs.length) {
            console.log(this.computed.activity_sorted_tabs[this.state.max_tabs - 1].computed.title);
            this.computed.activity_sorted_tabs[this.state.max_tabs - 1].close();
        }

        this.set_active_tab(tab)
    }

    create_tab(data: Tab.CreateInput) {
        data = JSON.parse(safe_stringify(data))
        const TabClass = Tab.registered_tab_types.find(tc => tc.type == data.type)
        if (TabClass == null) throw new Error(`${data.type} tab type not found`)
        delete data.type

        const tab = TabClass.new(data)
        if (tab != null && !this.state.tabs.includes(tab)) this.add_tab(tab)
        return tab
    }

    remove_tab(tab: Tab) {
        this.state.tabs = this.state.tabs.filter(t => t.instance_id != tab.instance_id)

        tab._emit("before_remove")
        if (this.state.tabs.length == 0) {
            this.remove();
        } else {
            const sorted_by_last_active = _.sortBy(this.state.tabs, (tab) => tab.state.last_activation).reverse()
            this.set_active_tab(sorted_by_last_active[0])
        }
    }

    set_active_tab(tab: Tab) {
        if (tab?.instance_id != this.state.active_tab?.instance_id) {
            this.state.active_tab?._emit("deactivated")
            this.state.active_tab = tab
            tab?._emit("activated")
            this._emit("active_tab_change")
        } else if(tab != null) {
            tab?._emit("activated")
        }
    }

    find_tab(id: string): Tab {
        return this.state.tabs.find(t => t.id == id)
    }

    get_up_tab_manager(look_in_parents: boolean) {
        return this.parent.get_up_tab_manager(look_in_parents);
    }

    get_right_tab_manager(look_in_parents: boolean) {
        return this.parent.get_right_tab_manager(look_in_parents);
    }

    get_down_tab_manager(look_in_parents: boolean) {
        return this.parent.get_down_tab_manager(look_in_parents)
    }

    get_left_tab_manager(look_in_parents: boolean) {
        return this.parent.get_left_tab_manager(look_in_parents);
    }

    get_or_create_right_tab_manager(look_in_parents: boolean) {
        const region = this.parent.get_right_region(look_in_parents)
        if (region == null) {
            // there is no right region, we need to create it
            const new_splitter = this.split("left_right", true)
            return new_splitter.state.other.object as TabManager
        } else {
            const object = region.object;
            if (object instanceof TabManager) return object
            if (object instanceof Splitter) {
                const tm = object._find_a_tab_manager();
                if (tm != null) return tm
                region.object = region.splitter._generate_tab_manager(region)
                return region.object
            }
            if (object == null) {
                region.object = region.splitter._generate_tab_manager(region)
                return region.object
            }
        }
    }


    get_or_create_down_tab_manager(look_in_parents: boolean) {
        const region = this.parent.get_down_region(look_in_parents)
        if (region == null) {
            // there is no down region, we need to create it
            const new_splitter = this.split("top_bottom", true)
            return new_splitter.state.other.object as TabManager
        } else {
            const object = region.object;
            if (object instanceof TabManager) return object
            if (object instanceof Splitter) {
                const tm = object._find_a_tab_manager();
                if (tm != null) return tm
                region.object = region.splitter._generate_tab_manager(region)
                return region.object
            }
            if (object == null) {
                region.object = region.splitter._generate_tab_manager(region)
                return region.object
            }
        }
    }

    split(how: Splitter.SplitHow, keep_contents_in_main = true) {
        const new_parent_splitter = new Splitter({
            id: generate_eid(),
            parent: this.parent,
            split: how
        })

        if (keep_contents_in_main) {
            new_parent_splitter.state.other.object = new_parent_splitter._generate_tab_manager(new_parent_splitter.state.other)
            this.set_parent(new_parent_splitter.state.main)
        } else {
            new_parent_splitter.state.main.object = new_parent_splitter._generate_tab_manager(new_parent_splitter.state.main)
            this.set_parent(new_parent_splitter.state.other)
        }

        return new_parent_splitter
    }
    // </editor-fold>

    // <editor-fold desc="EVENT HANDLERS">
    on(event: TabManager.Events, callback: (tab_manager: TabManager, e: any) => void) {
        this.event_bus.$on(event, callback)
    }

    on_tab(event: Tab.Events, callback: (tab: Tab, e: any) => void) {
        this.tab_event_bus.$on(event, callback)
    }
    // </editor-fold>

    // <editor-fold desc="STATE MANAGEMENT">
    set_drag_over(state: boolean) {
        this.state.is_drag_over = state;
    }
    // </editor-fold>

    // <editor-fold desc="HELPERS">
    set_parent(parent: SplitterRegion) {
        if (this.parent != null && this.parent.object != null && this.parent.object.id == this.id) {
            this.parent.object = null
        }
        if (parent != null) parent.object = this
        this.parent = parent
    }

    get_tabs() {
        return this.state.tabs;
    }

    get_editor() {
        return this.parent?.get_editor()
    }
    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    _get_html_element() {
        return document.getElementById(this.id)
    }

    _get_tabs_html_element(): HTMLElement {
        return this._get_html_element()?.querySelector(".tabs")
    }

    _emit(event: TabManager.Events, e: any = null) {
        this.event_bus.$emit(event, this, e)
        let splitter = this.parent?.splitter
        let editor: Editor;
        while (splitter != null) {
            splitter.tab_manager_event_bus.$emit(event, this, e);
            const parent = splitter.parent
            if (parent instanceof Editor) {
                splitter = null
                editor = parent
            }
            splitter = (parent as SplitterRegion).splitter
        }
        editor?.tab_manager_event_bus?.$emit(event, this, e);
    }

    // <editor-fold desc="INTERNAL EVENT HANDLING">
    _on_drag_enter(tab_manager: TabManager, e: DragEvent) {
        e.stopImmediatePropagation()
        this.set_drag_over(true)
    }

    _on_drag_over(tab_manager: TabManager, e: DragEvent) {
        this.set_drag_over(true)
        e.preventDefault();
        e.stopImmediatePropagation()
    }

    _on_drag_leave(tab_manager: TabManager, e: DragEvent) {
        // this.set_drag_over(false)
        e.stopImmediatePropagation()
    }

    _on_removed() {
        // NOTE: do not remove reference to old parent splitter, to allow callback hooks to execute properly
        // this.set_parent(null)
    }

    _on_active_tab_change() {

    }

    _register_default_event_handlers() {
       this.on("removed", this._on_removed.bind(this))
       this.on("active_tab_change", this._on_active_tab_change.bind(this))
       this.on("dragenter", this._on_drag_enter.bind(this))
       this.on("dragover", this._on_drag_over.bind(this))
       this.on("dragleave", this._on_drag_leave.bind(this))
    }
    // </editor-fold>
    // </editor-fold>
}
