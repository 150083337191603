import { VueRecordClient } from "../../base/vue_record_client";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { PlaySnippet } from "../../models/play/play_snippet";
import { PlayLogProps } from "../../models/play/play_log";
import { PlayLog } from "../../models/play/play_log";
import { PlaySnippetProps } from "../../models/play/play_snippet";
import { CacheStoreValue } from "../../base/vue_record_client";

const console = new Consoler("warn")
export class PlaySnippetClient extends VueRecordClient {
    static cache_store: Record<string, CacheStoreValue> = {}
    static ModelClass: typeof PlaySnippet
    declare record: PlaySnippet

    load_associated() {
        interface LoadResponse {
            play_snippet: PlaySnippetProps,
            play_logs: PlayLogProps[]
        }
        return new Promise<void>((resolve, reject) => {
            $.ajax({
                url: `/play/play_snippets/${this.key()}/associated`,
                type: 'GET',
                success: (data: LoadResponse) => {
                    PlaySnippet.new(data.play_snippet);
                    data.play_logs.forEach(play_log => PlayLog.new(play_log))
                    resolve()
                },
                error: (error) => {
                    reject(error)
                },
                statusCode: ajax_status_codes
            })
        })
    }
}
