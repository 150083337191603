import { SnippetSavepoint } from "../models/snippet_savepoint";
import { Snippet } from "../models/snippet";
import { User } from "../models/user";
import { PlaySnippetScope } from "../scopes/play/play_snippet_scope";
import { PlaySnippet } from "../models/play/play_snippet";


declare module "../models/snippet_savepoint" {
    interface SnippetSavepoint {
        play_snippets: PlaySnippetScope;
        snippet: Snippet
        user: User
    }
}

SnippetSavepoint.belongs_to("snippet", Snippet, "snippet_id")
SnippetSavepoint.belongs_to("user", User, "user_id")

SnippetSavepoint.has_many("play_snippets", PlaySnippet, "snippet_savepoint_id")
