import _ from "lodash";

interface LimitStrOpts {
    on_end?: boolean
    show_ellipsis?: boolean
}

export function limit_str_length(string: string, length: number, opts: LimitStrOpts = {}) {
    opts = _.merge({
        on_end: true,
        show_ellipsis: true,
    }, opts) as LimitStrOpts
    let ellipsis_length = 0
    if (opts.show_ellipsis) {
        ellipsis_length = 2; // we will add 3 dot characters, but use 2 regardless
    }
    if ((string.length - ellipsis_length) > length) {
        if (opts.on_end) {
            return `...${string.substring(string.length - length)}`
        } else {
            return `${string.substring(0, length)}...`
        }
    } else {
        return string
    }
}
