import { ProjectVersion } from "../models/project_version";
import { User } from "../models/user";
import { ScenarioFolder } from "../models/scenario_folder";
import { ScenarioSavepointScope } from "../scopes/scenario_savepoint_scope";
import { ScenarioSavepoint } from "../models/scenario_savepoint";
import { Scenario } from "../models/scenario";
import { ScenarioNodeScope } from "../scopes/scenario_node_scope";
import { ScenarioNode } from "../models/scenario_node";
import { ScenarioHistory } from "../models/scenario_history";
import { ScenarioHistoryScope } from "../scopes/scenario_history_scope";
import { ScenarioSetting } from "../models/scenario_setting";


declare module "../models/scenario" {
    interface Scenario {
        user: User
        project_version: ProjectVersion
        scenario_folder: ScenarioFolder
        scenario_setting: ScenarioSetting
        current_savepoint: ScenarioSavepoint

        histories: ScenarioHistoryScope
        scenario_savepoints: ScenarioSavepointScope

        /** All nodes in this scenario */
        nodes: ScenarioNodeScope

        /** All scenario type nodes */
        scenario_nodes: ScenarioNodeScope
    }
}

Scenario.belongs_to("user", User, "user_id")
Scenario.belongs_to("project_version", ProjectVersion, "project_version_id")
Scenario.belongs_to("scenario_folder", ScenarioFolder, "scenario_folder_id")

Scenario.has_many("scenario_savepoints", ScenarioSavepoint, "scenario_id")
Scenario.has_many("nodes", ScenarioNode, "scenario_id")
Scenario.has_many("scenario_nodes", ScenarioNode, "scenario_node_scenario_id")
Scenario.has_many("histories", ScenarioHistory, "scenario_id")

Scenario.has_one("scenario_setting", ScenarioSetting, "scenario_id")
Scenario.has_one("current_savepoint", ScenarioSavepoint, "scenario_id")
