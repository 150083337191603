<template>
  <div :id="editor.id"
       class="editor-container">
    <Splitter :splitter="editor.state.splitter"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Editor } from "./editor";
import { PropType } from "vue";
import Splitter from "./Splitter.vue";
import { Size } from "../../../types/globals";

export default defineComponent({
    components: { Splitter },
    // <editor-fold desc="PROPS">
    props: {
        editor: {
            type: Object as PropType<Editor>,
            required: true,
        }
    },
    // </editor-fold>
    emits: ['set-size'],
    // <editor-fold desc="DATA">
    data() {
        return {}
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {},
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    mounted() {
        global_event_bus.$on("before_project_version_unload", () => this.save_editor())
        window.addEventListener("beforeunload", this.save_editor)
        this.editor.on("set_size", (_editor: Editor, size: Size) => {
            this.$emit("set-size", size)
        })
    },
    unmounted() {
        this.save_editor();
        window.removeEventListener("beforeunload", this.save_editor)
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        save_editor() {
            this.editor.save()
        }
    },
    // </editor-fold>
})
</script>

<style lang="scss" scoped>
.editor-container {
  width: 100%;
  height: 100%;
}
</style>
