import { on_dom_content_loaded } from "./dom_content_loaded";
import { Snippet } from "../../vue_record/models/snippet";
import { KeyCode } from "../../types/globals";
import { show_search_record } from "../vue/show_search_record";
import { Play } from "../../vue_record/models/play/play";

// NOTE: to prevent global keybind, simple call e.stopPropagation()

on_dom_content_loaded(() => {
    if (current.project_version != null) {
        document.addEventListener("keydown", (e: KeyboardEvent) => {
            const ctrl_or_meta = (is_pc_mac && e.metaKey) || (!is_pc_mac && e.ctrlKey)

            if (!e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey && e.code == KeyCode.F1) {
                window.open("/docs", "_blank")
                e.preventDefault()
                e.stopPropagation()
            }

            if (ctrl_or_meta && !e.altKey && e.shiftKey && e.code == KeyCode.F) {
                const selected_text = window.getSelection().toString();
                Snippet.show_project_code_search(current.project_version, selected_text)
                e.preventDefault();
                e.stopPropagation();
            }

            if (e.code == KeyCode.R && ctrl_or_meta && !e.shiftKey && !e.altKey) {
                e.preventDefault();
                const selected_text = window.getSelection().toString()
                const cm_has_focus = document.activeElement?.parentElement?.parentElement?.CodeMirror?.hasFocus()
                if (selected_text != "" && !cm_has_focus) {
                    Play.execute_code(selected_text)
                    e.stopImmediatePropagation();
                }
            }
        })

        let shift_count = 0;
        let shift_timeout: NodeJS.Timeout = null;
        let is_left_shift_down = false
        let is_right_shift_down = false

        document.addEventListener("keyup", (e: KeyboardEvent) => {
            if (e.code == KeyCode.LSHIFT) is_left_shift_down = false
            if (e.code == KeyCode.RSHIFT) is_right_shift_down = false
        })
        document.addEventListener("keydown", (e: KeyboardEvent) => {
            // when key is held down, some operating systems will repeatedly fire keydown
            // trigger keydown only if it is not down already
            if (e.code == KeyCode.LSHIFT && is_left_shift_down) return;
            if (e.code == KeyCode.RSHIFT && is_right_shift_down) return;

            if (e.code == KeyCode.LSHIFT || e.code == KeyCode.RSHIFT) {
                if (e.code == KeyCode.LSHIFT) is_left_shift_down = true
                if (e.code == KeyCode.RSHIFT) is_right_shift_down = true
                if (shift_count == 0) {
                    ++shift_count;
                    shift_timeout = setTimeout(() => {
                        clearTimeout(shift_timeout);
                        shift_timeout = null;
                        shift_count = 0;
                    }, 500)
                } else if (shift_count == 1) {
                    show_search_record(current.project_version)
                    e.preventDefault();
                    e.stopPropagation();
                }
            } else {
                // if we get any other key between to shift keys, interrupt the search function
                clearTimeout(shift_timeout)
                shift_count = 0;
                shift_timeout = null;
            }
        })
    }
})
