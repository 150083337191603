<template>
  <div :id="element_id"
       ref="play_log"
       class="play-log-container"
  >
    <div v-if="collapsible"
         class="collapser click-item"
         @click.stop="toggle_collapse">
      <i v-if="!collapsed"
         class="fas fa-chevron-down"/>
      <i v-else
         class="fas fa-chevron-right"/>
    </div>

    <div v-if="collapsed"
         class="play-log-content-container"
    >
      <div class="message click-item"
           @click.stop="toggle_collapse">
        <span :class="type_class"
              v-html="collapsed_rich_message"></span>
      </div>
    </div>
    <div v-else
         class="play-log-content-container">
      <div class="message">
        <span :class="type_class"
              v-html="rich_message"/>
        <template v-if="play_log.props.screenshot_urls">
          <span v-for="screenshot_url in play_log.props.screenshot_urls"
                :key="screenshot_url"
                class="icon">
            <ScreenshotIcon
                :url="screenshot_url"
                :main_web_available="main_web_available"
                :role="role"
                :project_version_setting="project_version_setting"
            />
          </span>
        </template>
        <template v-if="play_log.props.extras['snapshot_icon']">
          <SnapshotIcon
              :scenario_setting="scenario_setting"
              :snapshots="snapshots"
          />
        </template>
        <span v-if="play_log.props.extras?.backtrace?.length > 0"
              class="icon"
              @click.stop="toggle_backtrace"
        >
          <i class="fas fa-layer-group"/>
        </span>

        <span v-if="main_web_available && has_error_link"
              class="icon"
              @click.stop="error_link_click"
        >
          <i class="fas fa-code"/>
        </span>
      </div>
      <div v-if="show_backtrace && play_log.props.extras?.backtrace?.length > 0"
           class="backtrace">
        <div v-for="trace in rich_backtrace"
             :key="trace">
          <span v-if="trace.type == 'snippet_savepoint' || trace.type == 'debugger_context'"
                class="trace-link"
                @click.stop="trace_click(trace)">
            {{ trace.trace }}
          </span>
          <span v-else>
            {{ trace.trace }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import anchorme from "anchorme";
import { PlaySnippet } from "../../../../vue_record/models/play/play_snippet";
import { PropType } from "vue";
import { PlayScenario } from "../../../../vue_record/models/play/play_scenario";
import { PlaySandbox } from "../../../../vue_record/models/play/play_sandbox";
import { Play } from "../../../../vue_record/models/play/play";
import { PlayLog } from "../../../../vue_record/models/play/play_log";
import { escape_html } from "../../../../helpers/dom/escape_html";
import { get_css_var } from "../../../../helpers/generic/get_css_var";
import { SnippetSavepoint } from "../../../../vue_record/models/snippet_savepoint";
import { Snippet } from "../../../../vue_record/models/snippet";
import SnapshotIcon from "../../other/SnapshotIcon.vue";
import ScreenshotIcon from "../../other/ScreenshotIcon.vue";
import { ProjectVersionSetting } from "../../../../vue_record/models/project_version_setting";
import { EnumUserRole } from "../../../../auto_generated/enums";

export type RichTraceType = "snippet_savepoint" | "debugger_context" | "non_testa_trace"

export interface RichTrace {
    trace: string
    snippet_savepoint_id: number
    line: number
    type: RichTraceType
}

export default defineComponent({
    components: { SnapshotIcon, ScreenshotIcon },
    // <editor-fold desc="PROPS">
    props: {
        play_snippet: {
            type: Object as PropType<PlaySnippet>,
            required: false,
            default: null
        },
        play_scenario: {
            type: Object as PropType<PlayScenario>,
            required: false,
            default: null,
        },
        play_sandbox: {
            type: Object as PropType<PlaySandbox>,
            required: false,
            default: null,
        },
        play: {
            type: Object as PropType<Play>,
            required: true
        },
        play_log: {
            type: Object as PropType<PlayLog>,
            required: true
        },
        error_line: {
            type: Number,
            required: false,
            default: null
        },
        main_web_available: {
            type: Boolean,
            required: true
        },
        project_version_setting: {
            type: Object as PropType<ProjectVersionSetting>,
            required: true
        },
        role: {
            type: String as PropType<EnumUserRole>,
            required: false,
            default: null
        },
    },
    // </editor-fold>
    emits: ['play-log-mounted', 'debug-trace-click'],
    // <editor-fold desc="DATA">
    data() {
        return {
            show_backtrace: false,
            collapsed: false
        }
    },
    // </editor-fold>
    // <editor-fold desc="COMPUTED">
    computed: {
        current() {
            return current
        },
        scenario_setting() {
            return this.play_scenario.main_play_scenario.scenario_setting
        },
        snapshots() {
            if (!this.play_log.props.extras.snapshot) return []
            return Object.values(this.play_log.props.extras.snapshot).map(snapshot_array => snapshot_array[0])
        },
        collapsible() {
            if (this.play_log.props.message == null) return false
            return this.play_log.props.message.split("\n").length > 3
        },
        rich_message() {
            let rich_message = escape_html(this.play_log.props.message);
            switch (this.play_log.props.type) {
                case Enum.Play.Log.VERBOSE:
                case Enum.Play.Log.WARN:
                case Enum.Play.Log.ERROR:
                case Enum.Play.Log.FATAL:
                    rich_message = anchorme({
                        input: rich_message,
                        options: {
                            exclude: function(string, _props) {
                                // include only http(s) links
                                return string.indexOf('https://') === -1 && string.indexOf('http://') === -1
                            },
                            attributes: (_arg) => {
                                return {
                                    target: "_blank",
                                    class: 'detected-link'
                                };
                            },
                            specialTransform: [
                                {
                                    test: /https:\/\/.*/,
                                    transform: string => {
                                        let trailing = ""
                                        // remove trailing "
                                        if (string.endsWith("&quot")) {
                                            string = string.replace(/&quot$/, '');
                                            trailing = "\""
                                        }
                                        return `<a href="${string}" target="_blank" class="detected-link">${string}</a>${trailing}`
                                    }
                                }
                            ]
                        },
                    });
            }
            if (this.error_line != null) {
                const lines = rich_message.split("\n")
                if (lines.length > this.error_line) {
                    lines[this.error_line] = `<span style="color: var(--log-color-error);">${lines[this.error_line]}</span>`
                }
                rich_message = lines.join("\n")
            }
            return rich_message
        },
        collapsed_rich_message() {
            return `${this.rich_message.split("\n")[0]}\n( ... )`
        },
        element_id() {
            return `play_log_${this.play_log.props.id}`
        },
        rich_backtrace(): RichTrace[] {
            if (this.play_log.props.extras?.backtrace?.length > 0) {
                return this.play_log.props.extras.backtrace.map(trace => this.rich_trace(trace))
            } else {
                return []
            }
        },
        has_error_link() {
            return this.rich_backtrace.some(trace => trace.type == "snippet_savepoint")
        },
        type_class() {
            switch (this.play_log.props.type) {
                case Enum.Play.Log.VERBOSE:
                    return 'verbose'
                case Enum.Play.Log.INFO:
                    return 'info'
                case Enum.Play.Log.WARN:
                    return 'warn'
                case Enum.Play.Log.ERROR:
                    return 'error'
                case Enum.Play.Log.FATAL:
                    return 'fatal'
                case Enum.Play.Log.LINK:
                    return 'link'
                default:
                    return ''
            }
        },
    },
    // </editor-fold>
    // <editor-fold desc="WATCH">
    watch: {},
    // </editor-fold>
    // <editor-fold desc="HOOKS">
    beforeCreate() {
    },
    beforeMount() {
        // if (current_role == Enum.User.Role.SUPERADMIN) this.show_backtrace = true;
    },
    mounted() {
        // @ts-ignore - is assigned to div for easier referencing
        this.$refs.play_log.play_log = this.play_log
        this.$emit("play-log-mounted", this.play_log)
    },
    updated() {
        this.$emit("play-log-mounted", this.play_log)
    },
    unmounted() {
    },
    // </editor-fold>
    // <editor-fold desc="METHODS">
    methods: {
        toggle_collapse() {
            this.collapsed = !this.collapsed;
        },
        // NOTE: when too many logs are shown, there is significant delay to showing ANY contextmenu
        // attach_context_menu() {
        //     $.contextMenu({
        //         selector: `#${this.element_id}`,
        //         build: ($trigger, e) => {
        //             const offset = $trigger.offset();
        //             offset.left = e.pageX - offset.left;
        //             offset.top = e.pageY - offset.top;
        //             offset.left = offset.left / $trigger.width();
        //             offset.top = offset.top / $trigger.height();
        //             const items: ContextMenu.Items = {
        //                 delete: {
        //                     icon: "fa fa-times",
        //                     name: "Delete",
        //                     color: get_css_var("--button-red"),
        //                     callback: () => {
        //                         this.play_log.delete();
        //                     }
        //                 }
        //             }
        //             return {
        //                 items,
        //                 callback() {
        //                 }
        //             }
        //         }
        //     })
        // },
        error_link_click() {
            const trace: RichTrace = this.rich_backtrace.find((trace: RichTrace) => trace.snippet_savepoint_id != null)
            this.trace_click(trace);
        },
        trace_click(trace: RichTrace) {
            if (trace.type == "snippet_savepoint") {
                const snippet_savepoint = SnippetSavepoint.find(trace.snippet_savepoint_id)
                if (snippet_savepoint == null) {
                    SnippetSavepoint.ClientClass.load(trace.snippet_savepoint_id).then(() => this.trace_click(trace))
                } else {
                    Snippet.ClientClass.load(snippet_savepoint.props.snippet_id).then(snippet => {
                        snippet.open_in_main({ jump_to: { line: trace.line, ch: null} })
                    })
                }
            } else {
                this.$emit("debug-trace-click", this.play_log.props.extras?.command_play_log_id, trace.line)
            }
        },
        toggle_backtrace() {
            this.show_backtrace = !this.show_backtrace;
        },
        rich_trace(trace: string): RichTrace {
            let snippet_savepoint_id: number = null;
            let line: number = null;
            let type: RichTraceType = "non_testa_trace";
            const matched = trace.match(/snippet_savepoint:(\d+):[^:]+:(\d+):.*/)
            if (matched?.length == 3) {
                snippet_savepoint_id = parseInt(matched[1])
                line = parseInt(matched[2]) - 1 // -1 to align with codemirror. Lines in cm start with 0, in ruby it is 1
                type = "snippet_savepoint"
            }

            const matched_debugger = trace.match(/debugger_context:(\d+):.*/)
            if (matched_debugger?.length == 2) {
                snippet_savepoint_id = null
                line = parseInt(matched_debugger[1]) - 1 // -1 to align with codemirror. Lines in cm start with 0, in ruby it is 1
                type = "debugger_context"
            }

            return {
                trace,
                snippet_savepoint_id,
                line,
                type
            }
        },
    }
    // </editor-fold>

})
</script>

<style lang="scss" scoped>
$collapser_width: 20px;

.play-log-container {
  margin-left: 5px;
  display: flex;
  width: calc(100% - 5px);
  flex-direction: row;
  flex-shrink: 0;

  .click-item {
    cursor: pointer;

    &:hover {
      color: var(--font-color-hover);
      filter: brightness(1.2);
    }
  }

  .collapser {
    display: flex;
    justify-content: center;
    width: $collapser_width;
    flex-shrink: 0;
    font-size: 0.5em;
    padding-top: 5px;
    line-height: 17px;
    padding-bottom: 2px;
    margin-left: -$collapser_width;
    color: var(--font-color-secondary);
  }

  .play-log-content-container {
    flex-direction: column;
    flex-shrink: 0;
    display: flex;
    width: 100%;

    .message {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: baseline;

      overflow-wrap: anywhere;

      .verbose {
        color: var(--log-color-verbose);
        display: table-row;
        font-size: 11px;
        line-height: 17px;
        white-space: pre-wrap;
        font-family: monospace;
        letter-spacing: -0.05em;
        margin-right: 5px;
      }

      .info {
        color: var(--log-color-info);
        display: table-row;
        font-size: 16px;
        line-height: 20px;
        margin-right: 5px;
        white-space: pre-wrap;
      }

      .warn {
        color: var(--log-color-warning);
        display: table-row;
        font-size: 13px;
        line-height: 18px;
        margin-right: 5px;
        white-space: pre-wrap;
      }

      .error {
        color: var(--log-color-error);
        display: table-row;
        font-size: 15px;
        line-height: 19.5px;
        margin-right: 5px;
        white-space: pre-wrap;
      }

      .fatal {
        color: var(--log-color-fatal);
        display: table-row;
        font-size: 15px;
        line-height: 19.5px;
        margin-right: 5px;
        white-space: pre-wrap;
      }

      .link {
        color: var(--log-color-link);
        cursor: pointer;
        font-size: 16px;
        line-height: 21px;
        margin-right: 5px;
      }
    }

    .backtrace {
      color: var(--log-color-error);
      display: table-row;
      font-size: 14px;
      line-height: 19.5px;
      margin-right: 5px;

      .trace-link {
        color: var(--button-blue);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .icon {
      color: var(--button-blue);
      margin-right: 4px;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.detected-link {
  color: var(--button-blue);
}
</style>
