<template>
  <div ref="breadcrumb"/>
  <teleport to="body">
    <div v-if="show"
         ref="container"
         class="overflowable"
         :style="{zIndex: z_index, top: y, left: x, width: width, height: height}">
      <slot/>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { generate_uuid } from "../../helpers/generate/generate_uuid";
import { Validator } from "../../helpers/validator/validator";
import { AllMightyObserver } from "../../helpers/dom/all_mighty_observer";
import { nextTick } from "vue";

export default defineComponent({
    props: {
        auto_z_index: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            instance_id: generate_uuid(),
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            z_index: "auto",
            show: true,
            timeout: null,
            handler: null as () => void,
            is_resizing: false,
            amo: null as AllMightyObserver
        }
    },
    computed: {},
    mounted() {
        const $relative = this.find_relative();
        if (this.auto_z_index) {
            this.z_index = this.find_z_index();
        } else {
            this.z_index = null
        }

        this.set_relative_values($relative, true)

        this.amo = AllMightyObserver.new({
                element_before_resize: true,
                element_after_resize: true,
                after_resize: true,
                before_resize: true,
                target_element: $relative[0],
                element_resize_delay: 100,
                callback: (data) => {
                    const overflowable_container = this.$refs.container as HTMLElement
                    if (!overflowable_container?.contains(data.container)) {
                        this.show = data.event != "before_resize" && data.event != "element_before_resize"
                        this.is_resizing = data.event != "after_resize" && data.event != "element_after_resize"
                        if (data.event == "after_resize" || data.event == "element_after_resize") {
                            nextTick(() => this.set_relative_values($relative, true))
                        }
                    }
                },
            },
            {
                scrollend: true,
                callback: () => {
                    this.set_relative_values($relative, true)
                }
            })
    },
    unmounted() {
        this.amo?.stop()
    },
    methods: {
        find_z_index(): string {
            let $current_element = $(this.$refs.breadcrumb as HTMLElement);
            let z_index = "auto";
            while ($current_element.length > 0) {
                // @ts-ignore
                if ($current_element[0] == document) break;

                z_index = $current_element.css("z-index")
                if (Validator.is_numeric(z_index)) return z_index
                $current_element = $current_element.parent();
            }
            return z_index
        },
        find_relative(): JQuery {
            let $current_element = $(this.$refs.breadcrumb as HTMLElement);
            let is_relative = false
            while (!is_relative) {
                $current_element = $current_element.parent();
                const position = $current_element.css("position")
                is_relative = position == "relative" || position == "absolute" || $current_element.length == 0 || $current_element[0].tagName == "BODY"
            }
            return $current_element
        },
        set_relative_values($relative: JQuery, run_now = false) {
            const set = () => {
                this.show = !this.is_resizing
                if ($relative.length == 0) return;

                const rect = $relative[0].getBoundingClientRect();
                this.x = rect.x;
                this.y = rect.y;
                this.width = rect.width;
                this.height = rect.height;
            }

            if (run_now) {
                clearTimeout(this.timeout)
                set()
            } else {
                clearTimeout(this.timeout)
                this.show = false
                this.timeout = setTimeout(set, 50)
            }
        }
    }
})
</script>

<style lang="scss" scoped>
.overflowable {
  pointer-events: none;
  position: absolute;
}
</style>
