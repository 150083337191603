import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { PhoneProps as ScenarioSettingPhoneProps } from "../../models/scenario_setting/phone";
import { BrowserProps as ScenarioSettingBrowserProps } from "../../models/scenario_setting/browser";
import { EnumPlayLog } from "../../../auto_generated/enums";
import { PlayLogClient } from "../../clients/play/play_log_client";
import { PlayLogScope } from "../../scopes/play/play_log_scope";
import ConfirmDialogue from "../../../components/testa/confirm_dialogue/confirm_dialgue";
import { Phone as ScenarioSettingPhone } from "../../models/scenario_setting/phone";
import { DebugFrame } from "./play_snippet";
import { ScenarioSettingProps } from "../scenario_setting";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export interface PlayLogExtrasSnapshotSourceError {
    error: string, message: string, stacktrace: string
}

export interface PlayLogExtrasSnapshot {
    id: string
    created_at: Date
    source: string | PlayLogExtrasSnapshotSourceError
    screenshot_url: string
    udid: string
    aut_y_offset: number
    aut_x_offset: number
    aut_scale: number
}

interface PlayLogExtras {
    command?: string
    backtrace?: string[]
    command_history?: string[]
    debug_frames?: DebugFrame[]
    command_play_log_id: number
    scenario_setting?: ScenarioSettingProps
    scenario_setting_phones?: ScenarioSettingPhoneProps[]
    scenario_setting_browsers?: ScenarioSettingBrowserProps[]

    udid?: string
    snapshot?: { [key: string]: PlayLogExtrasSnapshot[] }
    snapshot_icon?: boolean
    status?: string
    is_looping?: boolean
}

export interface PerformanceData{
    cpu_info: string
    memory_info: string
}

export interface PlayLogProps extends Props {
    id: number
    play_id: number
    play_snippet_id: number
    play_scenario_id: number
    play_sandbox_id: number
    type: EnumPlayLog
    message: string
    extras: PlayLogExtras
    created_at: Date
    updated_at: Date
    screenshot_urls: string[]
    mobile_performance_data: {[key: string]: PerformanceData}
}
export type QuerifiedPlayLogProps = QuerifyProps<PlayLogProps>
export type PlayLogCreateProps = Omit<PlayLogProps, 'id'>
export type PlayLogUpdateProps = Partial<PlayLogProps>

export interface PlayLogState extends State {}
export interface PlayLogComputed extends Computed {}
export interface PlayLogStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PlayLog extends VueRecord {
    ['constructor']: typeof PlayLog

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlayLogClient
    static ScopeClass = PlayLogScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlayLogStaticState = reactive<PlayLogStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "play_id", "play_scenario_id", "play_snippet_id", "type"),
        VueRecordIndex.new(this, "play_id", "play_sandbox_id", "type"),
        VueRecordIndex.new(this, "play_scenario_id", "type"),
        VueRecordIndex.new(this, "play_snippet_id", "type"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlayLog> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlayLog>> = {}

    static field_validators: ModelValidatorOpts<PlayLogProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_LOG
    static resource_id = Enum.Resource.Id.PLAY_LOG
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlayLogClient
    declare props: PlayLogProps;
    declare state: PlayLogState;
    declare computed: PlayLogComputed;

    // </editor-fold>

    after_create() {
        super.after_create();
        this._check_for_snapshots()
    }

    after_update(_new_props: Props, _old_props: Props, _changes: (keyof Props)[]) {
        super.after_update(_new_props, _old_props, _changes);
        this._check_for_snapshots()
    }

    name() {
        return "";
    }

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }

    delete() {
        return PlayLog.delete(this.key())
    }

    static delete(ids: number | number[]) {
        if (!Array.isArray(ids)) ids = [ids]
        const scope = this.where({ id: ids })
        const content_text = scope.delete_warning_text()
        return ConfirmDialogue.show({
            html: content_text,
            confirm_color_class: "red",
            confirm_action: () => {
                return this.ClientClass.delete(ids)
            }
        })
    }

    // <editor-fold desc="_INTERNAL">
    _get_scenario_setting_phone_by_udid(target_udid: string): ScenarioSettingPhone {
        return this.play_scenario
                   .main_play_scenario
                   .scenario_setting
                   .phones
                   .toArray()
                   .find(scenario_setting_phone => {
                       const udid = scenario_setting_phone.phone_project.props.udid
                       return udid == target_udid
                   })
    }

    _check_for_snapshots() {
        if (!this.props.extras.hasOwnProperty("snapshot")) return

        Object.keys(this.props.extras.snapshot).forEach(udid => {
            const phone_snapshots = this.props.extras.snapshot[udid]
            const phone = this._get_scenario_setting_phone_by_udid(udid)
            phone_snapshots.forEach(phone_snapshot => phone.state.inspector.add_snapshot(phone_snapshot))
        })
    }
    // </editor-fold>
}

// <editor-fold desc="INIT">
PlayLog.register_resource(PlayLog)
PlayLogClient.ModelClass = PlayLog
PlayLogScope.ModelClass = PlayLog


declare global {
    interface Window {
        PlayLog: typeof PlayLog
    }
}
window.PlayLog = PlayLog
// </editor-fold>

